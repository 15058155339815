import React from 'react'
import { ListItemIcon, Tooltip, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { getDeviceStatusColor } from 'utils/deviceUtils'
import NetworkCloseIcon from 'components/Icons/NetworkCloseIcon'

const useStyles = makeStyles(() => ({
  actionBtnIcon: {
    fontSize: '24px',
    color: '#74809a'
  },
  wrapperIconClass: {
    width: 24,
    height: 24
  },
  networkGlobeIcon: {
    fontSize: '20px'
  },
  networkCloseIcon: {
    top: '-6px',
    right: '-5px'
  }
}))

const DeviceConnectivityIcon = ({
  status,
  lastCheckInUTC,
  iconWrapperClass,
  iconClass
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isActive = status === 'Active'

  return (
    <Tooltip
      arrow
      title={
        status === 'Active'
          ? t('Device is currently active and playing content')
          : t('Inactive Device Card playing content')
      }
    >
      <ListItemIcon className={iconWrapperClass}>
        {isActive ? (
          <i
            className={classNames(
              classes.actionBtnIcon,
              iconClass,
              'icon-network-check'
            )}
            style={{ color: getDeviceStatusColor({ status, lastCheckInUTC }) }}
          />
        ) : (
          <NetworkCloseIcon
            wrapperClassName={classes.wrapperIconClass}
            generalStyles={{
              color: getDeviceStatusColor({ status, lastCheckInUTC })
            }}
            mainClassName={classes.networkGlobeIcon}
            secondClassName={classes.networkCloseIcon}
          />
        )}
      </ListItemIcon>
    </Tooltip>
  )
}

DeviceConnectivityIcon.propTypes = {
  status: PropTypes.string,
  iconWrapperClass: PropTypes.string,
  iconClass: PropTypes.string
}

export default DeviceConnectivityIcon
