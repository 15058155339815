import React, { useState, useEffect, useCallback, useMemo, memo } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from '@reduxjs/toolkit'
import { withSnackbar } from 'notistack'
import classNames from 'classnames'
import { createSelector } from 'reselect'
import {
  withStyles,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import { BlueButton, CircleIconButton } from 'components/Buttons'
import AlertMediaLibrary from './AlertMediaLibrary'
import { CircularLoader } from 'components/Loaders'

import {
  getDeviceMediaEmergencyAlert,
  clearGetDeviceMediaEmergencyAlertInfo
} from 'actions/alertActions'
import { useCustomSnackbar } from 'hooks/index'
import { alertTypesSelectors } from 'selectors/configSelectors'
import { deviceMediaEmergencyAlertSelector } from 'selectors/alertSelectors'
import { isEmpty, isEqual, isFalsy } from 'utils/generalUtils'
import RemoveAlertMediaType from './RemoveAlertMediaType'
import Scrollbars from 'components/Scrollbars'
import { TextWithTooltip } from 'components/Typography'

const styles = ({ type, palette, colors }) => ({
  table: {
    width: '100%'
  },
  tableHead: {
    background: palette[type].table.head.background
  },
  tableHeadText: {
    fontWeight: 600,
    color: palette[type].table.head.color
  },
  tableRow: {
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  tableCell: {
    border: 'none',
    borderColor: palette[type].table.head.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    color: colors.title.primary[type],
    width: '33.3%',
    height: 45,
    maxHeight: 45,
    padding: '0 0 0 24px',
    display: 'flex',
    alignItems: 'center',

    '&:last-child': {
      borderRight: 'none'
    }
  },
  tableCellFullWidth: {
    width: '100%'
  },
  noFoundText: {
    width: '100%',
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette[type].table.body.cell.color
  },
  circleIcon: {
    padding: 10,
    color: '#afb7c7',
    transform: 'scale(0.9)'
  },
  content: {
    background: palette[type].dialog.background,
    padding: '10px 20px 0px'
  },
  actionBar: {
    display: 'flex',
    background: palette[type].dialog.header.background,
    borderTop: `solid 1px ${palette[type].dialog.border}`,
    height: 55,
    padding: '0 20px',
    margin: '0px'
  },
  scrollbarRoot: {
    maxHeight: 'calc(100vh - 310px)'
  },
  deletedTitle: {
    color: 'red'
  }
})

const selector = createSelector(
  alertTypesSelectors,
  deviceMediaEmergencyAlertSelector,
  (alertTypes, emergencyAlertRequest) => [
    alertTypes.response,
    emergencyAlertRequest
  ]
)

function SingleRow({
  classes,
  id,
  name,
  getTitle,
  disabled,
  onClick,
  onClickRemoveBtn,
  t
}) {
  const title = getTitle(id)

  const handleClick = useCallback(() => {
    onClick(id)
  }, [onClick, id])

  const handleClickRemoveBtn = useCallback(() => {
    onClickRemoveBtn(id, name)
  }, [onClickRemoveBtn, id, name])
  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell} align="center">
        {name}
      </TableCell>
      <TableCell
        className={classNames(classes.tableCell, {
          [classes.deletedTitle]: title === null
        })}
        align="center"
      >
        {title === null ? (
          t('Deleted Media')
        ) : (
          <TextWithTooltip title={title} maxWidth={250}>
            {title}
          </TextWithTooltip>
        )}
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <CircleIconButton
          disabled={disabled}
          className={classNames('hvr-grow', classes.circleIcon)}
          onClick={handleClick}
        >
          <i className="fa-light fa-pencil" />
        </CircleIconButton>
        {title !== 'N/A' && (
          <CircleIconButton
            className={classNames('hvr-grow', classes.circleIcon)}
            onClick={handleClickRemoveBtn}
          >
            <i className="fa-regular fa-trash-can" />
          </CircleIconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

const SingleRowMemoized = memo(SingleRow)

function EmergencyAlert({
  t,
  id,
  classes,
  disabled,
  enqueueSnackbar,
  closeSnackbar,
  handleClose
}) {
  const dispatch = useDispatch()
  const [alertTypes, emergencyAlertRequest] = useSelector(selector)
  const showSnackbar = useCustomSnackbar(t, enqueueSnackbar, closeSnackbar)
  const [items, setItems] = useState([])
  const [dialog, setDialog] = useState(false)
  const [removeDialog, setRemoveDialog] = useState(false)
  const [alertId, setAlertId] = useState(null)
  const [, setAlertName] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(getDeviceMediaEmergencyAlert(id))
    return () => {
      dispatch(clearGetDeviceMediaEmergencyAlertInfo())
    }
  }, [id, dispatch])

  useEffect(() => {
    const { response } = emergencyAlertRequest
    if (response) {
      setLoading(false)
      setItems(response)
    }
  }, [emergencyAlertRequest, setItems, setLoading])

  const getMediaTitle = useCallback(
    id => {
      const item = items.find(item => isEqual(item.alertType.id, id))
      if (item && !item.media?.title) {
        return null
      }
      return item && item.media?.title ? item.media.title : 'N/A'
    },
    [items]
  )

  const getMediaId = useCallback(
    id => {
      const item = items.find(item => isEqual(item.alertType.id, id))
      return item && item.media?.id ? item.media.id : null
    },
    [items]
  )

  const openDialog = useCallback(
    id => {
      setAlertId(id)
      setDialog(true)
    },
    [setAlertId, setDialog]
  )

  const openRemoveDialog = useCallback(
    (id, alertName) => {
      setAlertId(id)
      setRemoveDialog(true)
      setAlertName(alertName)
    },
    [setAlertId, setRemoveDialog]
  )

  const renderRows = useMemo(() => {
    if (isEmpty(alertTypes)) {
      return (
        <TableRow className={classes.tableRow}>
          <TableCell
            className={classNames(
              classes.tableCell,
              classes.tableCellFullWidth
            )}
          >
            <Typography className={classes.noFoundText}>
              {t('No Records Found')}
            </Typography>
          </TableCell>
        </TableRow>
      )
    }
    return alertTypes.map(({ id, name }) => (
      <SingleRowMemoized
        key={id}
        id={id}
        name={name}
        disabled={disabled}
        classes={classes}
        getTitle={getMediaTitle}
        onClick={openDialog}
        onClickRemoveBtn={openRemoveDialog}
        t={t}
      />
    ))
  }, [
    t,
    alertTypes,
    getMediaTitle,
    openDialog,
    openRemoveDialog,
    disabled,
    classes
  ])

  const closeDialog = useCallback(() => {
    setDialog(false)
  }, [setDialog])

  const closeRemoveDialog = useCallback(() => {
    setRemoveDialog(false)
  }, [setRemoveDialog])

  const handleFail = useCallback(() => {
    showSnackbar('Error', 'error')
  }, [showSnackbar])

  const handleSuccess = useCallback(() => {
    showSnackbar('Successfully added', 'success')
    closeDialog()
  }, [showSnackbar, closeDialog])

  const handleSuccessRemoveAlertMediaType = useCallback(() => {
    showSnackbar('Successfully removed', 'success')
    closeRemoveDialog()
  }, [showSnackbar, closeRemoveDialog])

  const renderDialog = useMemo(() => {
    if (isFalsy(dialog)) return null
    return (
      <AlertMediaLibrary
        id={alertId}
        deviceId={id}
        open={dialog}
        selectedMediaId={getMediaId(alertId)}
        handleClose={closeDialog}
        onFail={handleFail}
        onSuccess={handleSuccess}
      />
    )
  }, [dialog, alertId, id, getMediaId, closeDialog, handleFail, handleSuccess])

  const renderRemoveDialog = useMemo(() => {
    if (isFalsy(removeDialog)) return null
    return (
      <RemoveAlertMediaType
        id={alertId}
        typeName={getMediaTitle(alertId)}
        deviceId={id}
        open={removeDialog}
        onClose={closeRemoveDialog}
        onFail={handleFail}
        onSuccess={handleSuccessRemoveAlertMediaType}
      />
    )
  }, [
    alertId,
    getMediaTitle,
    id,
    removeDialog,
    closeRemoveDialog,
    handleFail,
    handleSuccessRemoveAlertMediaType
  ])

  const renderLoader = useMemo(() => {
    if (isFalsy(isLoading)) return null
    return <CircularLoader />
  }, [isLoading])

  return (
    <Grid container direction="column">
      <DialogContent className={classes.content}>
        {renderLoader}
        <Grid container direction="column">
          <Scrollbars
            className={classes.scrollbarRoot}
            style={{ height: (alertTypes.length + 1) * 45 }}
          >
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell} align="center">
                    <Typography className={classes.tableHeadText}>
                      {t('Alert type')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <Typography className={classes.tableHeadText}>
                      {t('Media')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>{renderRows}</TableBody>
            </Table>
          </Scrollbars>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionBar}>
        <Grid container justifyContent="flex-end">
          <BlueButton
            onClick={handleClose}
            iconClassName="fa-regular fa-circle-check"
          >
            {t('OK')}
          </BlueButton>
        </Grid>
      </DialogActions>
      {renderDialog}
      {renderRemoveDialog}
    </Grid>
  )
}

export default memo(
  compose(
    withTranslation('translations'),
    withStyles(styles),
    withSnackbar
  )(EmergencyAlert)
)
