import React from 'react'
import { Route } from 'react-router'
import classNames from 'classnames'

import { routeByName } from 'constants/index'
import DeviceNOC from './DeviceNOC'
import Header from './Header'
import { withStyles } from '@material-ui/core'
import { getPublicRoute } from 'constants/routes'

const styles = ({ breakpoints }) => ({
  mainContainer: {
    margin: '16px 12px 0',
    paddingTop: 80,
    paddingBottom: 20
  },

  [breakpoints.down('sm')]: {
    mainContainer: {
      paddingTop: 50
    }
  },
  [breakpoints.only('md')]: {
    mainContainer: {
      paddingTop: 60
    }
  },
  [breakpoints.only('lg')]: {
    mainContainer: {
      paddingTop: 70
    }
  }
})

const PublicPages = ({ currentTheme, handleThemeChange, classes }) => {
  return (
    <>
      <Header
        currentTheme={currentTheme}
        handleThemeChange={handleThemeChange}
      />
      <div
        className={classNames(classes.mainContainer, {
          DarkTheme: currentTheme === 'dark'
        })}
      >
        <Route
          path={getPublicRoute(routeByName.public.deviceNoc.root)}
          component={DeviceNOC}
        />
      </div>
    </>
  )
}

export default withStyles(styles)(PublicPages)
