import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'

import { SideModal } from 'components/Modal'
import { isFeatureAvailable } from 'utils/api/featureAvailability'
import { DEVICE_CEC_FEATURE } from 'constants/featureConstants'
import { TabToggleButton, TabToggleButtonGroup } from 'components/Buttons'
import DevicePower from './DevicePower'
import {
  clearGetDeviceRebootInfo,
  clearPutDeviceRebootInfo,
  clearPutScreenPowerSettingsInfo,
  getDeviceReboot,
  getScreenPowerSettings,
  putDevicePeriodicalReboot,
  putDeviceReboot,
  putScreenPowerSettings
} from 'actions/deviceActions'
import ScreenPower from './ScreenPower'

const useStyles = makeStyles(() => ({
  tabsWrapper: {
    paddingBottom: 20
  }
}))

const modalTabs = {
  DEVICE_POWER: 'DEVICE_POWER',
  SCREEN_POWER: 'SCREEN_POWER'
}

const DeviceRebootModal = ({
  device,
  handleClose,
  rebootReducer,
  putRebootReducer,
  screenPowerReducer,
  putScreenPowerReducer,
  getDeviceReboot,
  putDeviceReboot,
  putDevicePeriodicalReboot,
  clearGetDeviceRebootInfo,
  clearPutDeviceRebootInfo,
  getScreenPowerSettings,
  putScreenPowerSettings,
  clearPutScreenPowerSettingsInfo
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [selectedTab, setSelectedTab] = useState(modalTabs.DEVICE_POWER)

  const handleTabChange = (event, tab) => {
    if (tab) {
      setSelectedTab(tab)
    }
  }

  const handleCloseCb = useCallback(() => {
    handleClose()
    clearGetDeviceRebootInfo()
  }, [handleClose, clearGetDeviceRebootInfo])

  const tabs = useMemo(
    () => (
      <Grid container justifyContent="center" className={classes.tabsWrapper}>
        <TabToggleButtonGroup
          exclusive
          value={selectedTab}
          onChange={handleTabChange}
        >
          <TabToggleButton
            key={modalTabs.DEVICE_POWER}
            value={modalTabs.DEVICE_POWER}
          >
            {t('Device Reboot')}
          </TabToggleButton>
          <TabToggleButton
            key={modalTabs.SCREEN_POWER}
            value={modalTabs.SCREEN_POWER}
          >
            {t('Screen Power')}
          </TabToggleButton>
        </TabToggleButtonGroup>
      </Grid>
    ),
    [selectedTab, classes.tabsWrapper, t]
  )

  useEffect(() => {
    if (device?.id) {
      getScreenPowerSettings(device.id)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <SideModal
      width="55%"
      title={
        isFeatureAvailable(DEVICE_CEC_FEATURE)
          ? t('Control Settings')
          : t('Power Settings')
      }
      handleClose={handleCloseCb}
    >
      {modalTabs.DEVICE_POWER === selectedTab && (
        <DevicePower
          device={device}
          handleClose={handleCloseCb}
          tabs={tabs}
          rebootReducer={rebootReducer}
          getDeviceReboot={getDeviceReboot}
          putDeviceReboot={putDeviceReboot}
          putRebootReducer={putRebootReducer}
          putDevicePeriodicalReboot={putDevicePeriodicalReboot}
          clearGetDeviceRebootInfo={clearGetDeviceRebootInfo}
          clearPutDeviceRebootInfo={clearPutDeviceRebootInfo}
        />
      )}

      {modalTabs.SCREEN_POWER === selectedTab && (
        <ScreenPower
          device={device}
          handleClose={handleCloseCb}
          tabs={tabs}
          screenPowerReducer={screenPowerReducer}
          putScreenPowerReducer={putScreenPowerReducer}
          putAction={putScreenPowerSettings}
          onClear={clearPutScreenPowerSettingsInfo}
        />
      )}
    </SideModal>
  )
}

const mapStateToProps = ({ device }) => ({
  rebootReducer: device.reboot,
  putRebootReducer: device.putReboot,
  screenPowerReducer: device.screenPower,
  putScreenPowerReducer: device.putScreenPower
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceReboot,
      putDeviceReboot,
      clearPutDeviceRebootInfo,
      clearGetDeviceRebootInfo,
      putDevicePeriodicalReboot,
      getScreenPowerSettings,
      putScreenPowerSettings,
      clearPutScreenPowerSettingsInfo
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRebootModal)
