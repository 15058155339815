import api from './api'

import { errorHandler } from '../utils'

const getItems = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/client/user',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const impersonateUser = async id => {
  try {
    const { data } = await api({
      method: 'POST',
      url: `/client/user/${id}/impersonate`
    })

    return data
  } catch (e) {
    throw errorHandler(e)
  }
}

const resellerClientUserService = {
  getItems,
  impersonateUser
}

export default resellerClientUserService
