import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getConfigClientType } from 'actions/configActions'

const useClientTypeOptions = (options = {}) => {
  const { impersonated, noInitialFetch = false } = options
  const dispatch = useDispatch()

  const clientTypes = useSelector(
    ({
      config: {
        clientTypes: { response: clientTypes }
      }
    }) => clientTypes
  ).map(({ id: value, title: label, alias }) => ({
    value,
    label: label === 'Oem Client' ? 'OEM Client' : label,
    alias
  }))

  useEffect(
    () => {
      if (!noInitialFetch) {
        dispatch(getConfigClientType(impersonated))
      }
    },
    // eslint-disable-next-line
    [dispatch]
  )

  return clientTypes
}

export default useClientTypeOptions
