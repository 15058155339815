import React, { memo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { zIndexes } from 'constants/stylesConstants'
import MaterialPopup from 'components/Popup/MaterialPopup'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 315,
    animation: 'fade-in 200ms',
    zIndex: zIndexes.tableHeaderFilterPopup,
    position: 'relative'
  }
}))

const FilterPopup = ({ children, rootClassName, ...props }) => {
  const classes = useStyles()
  const [sideOpen, setSideOpen] = useState(false)

  return (
    <MaterialPopup
      rootClassName={classNames(classes.root, rootClassName)}
      on="click"
      placement="bottom-end"
      {...props}
    >
      {close =>
        React.cloneElement(children, {
          setSideState: setSideOpen,
          sideOpen,
          close
        })
      }
    </MaterialPopup>
  )
}

export default memo(FilterPopup)
