import React from 'react'
import classNames from 'classnames'
import { getBezierPath } from 'react-flow-renderer'

export default function CustomEdgeType({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  labelStyle,
  label,
  markerEnd,
  animated = false
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  return (
    <g
      className={classNames(
        'react-flow__edge react-flow__edge-simplebezier nopan',
        {
          animated
        }
      )}
    >
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        stroke-dasharray="5"
      />
      <text dy={-5}>
        <textPath
          href={`#${id}`}
          style={labelStyle}
          startOffset="50%"
          textAnchor="middle"
        >
          {label}
        </textPath>
      </text>
    </g>
  )
}
