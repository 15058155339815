import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { KeyboardArrowDown, Settings } from '@material-ui/icons'
import Popper from '@material-ui/core/Popper'
import classNames from 'classnames'

import { WhiteButton, CircleIconButton } from 'components/Buttons'
import ActionLinksDropdown from 'components/Dropdowns/ActionLinksDropdown'
import Tooltip from 'components/Tooltip'
import {
  usePopupState,
  bindHover,
  bindPopper
} from 'hooks/mui/muiPopupState/muiPopupState'
import { zIndexes } from 'constants/stylesConstants'
import { _get } from 'utils/lodash'

const styles = ({ palette, type, colors }) => ({
  rowActionDropdown: {
    width: '170px'
  },
  rowActionBtn: {
    minWidth: '61px',
    paddingLeft: '10px',
    paddingRight: '10px',
    boxShadow: `0 1px 0 0 ${palette[type].buttons.white.shadow}`,

    '&:hover': {
      borderColor: '#1c5dca',
      backgroundColor: '#1c5dca',
      color: '#fff'
    }
  },
  faded: {
    borderColor: 'rgba(3, 120, 186, 0.5) !important',
    '& svg': {
      color: 'rgba(3, 120, 186, 0.5)'
    }
  },
  rowActionBtnIcon: {
    width: 18,
    height: 18,
    color: colors.error
  },
  singleIcon: {
    minWidth: '32px'
  },
  circleIconButton: {
    width: 30,
    height: 30
  },
  circleIcon: {
    fontSize: 16
  },
  circleIconFaded: {
    opacity: 0.5
  },
  // Popup
  root: {
    zIndex: zIndexes.materialPopup
  },
  content: {
    backgroundColor: palette[type].dropdown.background,
    boxShadow: palette[type].dropdown.shadow,
    borderRadius: 5,
    zIndex: zIndexes.materialPopup
  },
  topOffset: {
    paddingTop: 10,
    '& > #arrow': {
      top: 5,
      transform: 'rotate(-45deg)'
    }
  },
  bottomOffset: {
    paddingBottom: 10,
    '& > #arrow': {
      bottom: 5,
      right: 10,
      transform: 'rotate(135deg)'
    }
  },
  leftOffset: {
    paddingLeft: 10,
    '& > #arrow': {
      left: 5,
      transform: 'rotate(225deg)'
    }
  },
  rightOffset: {
    paddingRight: 10,
    '& > #arrow': {
      right: 5,
      transform: 'rotate(45deg)'
    }
  },
  startOffset: {
    '& > #arrow': {
      left: '10px !important'
    }
  },
  endOffset: {
    '& > #arrow': {
      left: 'unset !important',
      right: 10
    }
  },
  arrow: {
    position: 'absolute',
    borderColor: `transparent ${palette[type].dropdown.background} transparent transparent`,
    borderWidth: '0px 10px 10px 0px',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    boxShadow: `${palette[type].dropdown.shadowColor} 1px -1px 3px -2px`
  }
})

const TableLibraryRowActionButton = forwardRef(
  (
    {
      classes,
      actionLinks = [],
      disabled,
      faded,
      activeValueClass,
      circleIconButtonClass,
      hideArrowDownIcon = false,
      circleButton = false,
      tooltipText,
      tooltipPlacement,
      rootClass = '',
      icon = 'fas fa-ellipsis-h',
      rowActionBtnClass = '',
      ...props
    },
    ref
  ) => {
    const isEmpty = useMemo(
      () =>
        !actionLinks.filter(
          action =>
            !action.divider &&
            (action.hasOwnProperty('render') ? action.render : true)
        ).length,
      [actionLinks]
    )

    const [arrowRef, setArrowRef] = useState(null)
    const handleArrowRef = useCallback(node => {
      setArrowRef(node)
    }, [])

    const popupState = usePopupState({
      variant: 'popper',
      popupId: 'tableRowActionPopper'
    })

    const trigger = useMemo(() => {
      const { trigger, onClick } = props

      if (!trigger) {
        return circleButton ? (
          <CircleIconButton
            {...bindHover(popupState)}
            {...(onClick && { onClick })}
            className={classNames(
              'hvr-grow',
              classes.circleIconButton,
              circleIconButtonClass,
              {
                [classes.circleIconFaded]: faded
              }
            )}
            disabled={disabled || isEmpty}
          >
            <i className={classNames(icon, classes.circleIcon)} />
          </CircleIconButton>
        ) : (
          <WhiteButton
            {...bindHover(popupState)}
            {...(onClick && { onClick })}
            className={classNames(classes.rowActionBtn, rowActionBtnClass, {
              [classes.faded]: faded,
              [classes.singleIcon]: hideArrowDownIcon
            })}
            disabled={disabled || isEmpty}
            ref={ref}
          >
            <Settings className={classes.rowActionBtnIcon} />
            {!hideArrowDownIcon && (
              <KeyboardArrowDown className={classes.rowActionBtnIcon} />
            )}
          </WhiteButton>
        )
      }

      return (
        <div {...bindHover(popupState)} ref={ref}>
          {trigger}
        </div>
      )
    }, [
      props,
      ref,
      circleButton,
      popupState,
      rowActionBtnClass,
      classes.circleIconButton,
      classes.circleIconFaded,
      classes.circleIcon,
      classes.rowActionBtn,
      classes.faded,
      classes.singleIcon,
      classes.rowActionBtnIcon,
      circleIconButtonClass,
      faded,
      disabled,
      isEmpty,
      icon,
      hideArrowDownIcon
    ])

    return (
      <Tooltip
        title={tooltipText || ''}
        placement={tooltipPlacement || 'top'}
        disableHoverListener={!tooltipText}
      >
        <div>
          {trigger}
          <Popper
            {...bindPopper(popupState)}
            placement="bottom"
            modifiers={{
              flip: {
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: 'viewport',
                  padding: 8
                }
              },
              arrow: {
                enabled: true,
                element: arrowRef
              }
            }}
            className={classNames(classes.root, rootClass)}
          >
            {popperProps => {
              return (
                <div
                  onClick={popupState.close}
                  className={classNames(classes.container, {
                    [classes.topOffset]:
                      popperProps.placement.split('-')[0] === 'bottom',
                    [classes.bottomOffset]:
                      popperProps.placement.split('-')[0] === 'top',
                    [classes.rightOffset]:
                      popperProps.placement.split('-')[0] === 'left',
                    [classes.leftOffset]:
                      popperProps.placement.split('-')[0] === 'right',
                    [classes.startOffset]:
                      ['bottom', 'top'].includes(
                        popperProps.placement.split('-')[0]
                      ) &&
                      _get(popperProps.placement.split('-'), '[1]') === 'start',
                    [classes.endOffset]:
                      ['bottom', 'top'].includes(
                        popperProps.placement.split('-')[0]
                      ) &&
                      _get(popperProps.placement.split('-'), '[1]') === 'end'
                  })}
                >
                  <span
                    id="arrow"
                    className={classes.arrow}
                    ref={handleArrowRef}
                  />
                  <div className={classes.content}>
                    <ActionLinksDropdown
                      actionLinks={actionLinks}
                      activeValueClass={activeValueClass}
                    />
                  </div>
                </div>
              )
            }}
          </Popper>
        </div>
      </Tooltip>
    )
  }
)

export default withStyles(styles)(TableLibraryRowActionButton)
