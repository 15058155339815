import * as types from './index'

export const getMenuItems = params => ({
  type: types.GET_MENU_ITEMS,
  params
})

export const postMenuItem = data => ({
  type: types.POST_MENU_ITEM,
  data
})

export const postMenuItemBulk = ({ data, onSuccess, onError }) => ({
  type: types.POST_MENU_ITEM_BULK,
  data,
  onSuccess,
  onError
})

export const deleteMenuItem = id => ({
  type: types.DELETE_MENU_ITEM,
  id
})

export const getMenuItemById = id => ({
  type: types.GET_MENU_ITEM_BY_ID,
  id
})

export const putMenuItem = (id, data) => ({
  type: types.PUT_MENU_ITEM,
  id,
  data
})

export const clearMenuItemResponseInfo = () => ({
  type: types.CLEAR_MENU_ITEM_RESPONSE_INFO
})

export const deleteSelectedMenuItems = ids => ({
  type: types.DELETE_SELECTED_MENU_ITEMS,
  ids
})

export const getMenuLocations = params => ({
  type: types.GET_MENU_LOCATIONS,
  params
})

export const postMenuLocation = data => ({
  type: types.POST_MENU_LOCATION,
  data
})

export const deleteMenuLocation = (id, data, name) => ({
  type: types.DELETE_MENU_LOCATION,
  id,
  data,
  name
})

export const getMenuLocationById = id => ({
  type: types.GET_MENU_LOCATION_BY_ID,
  id
})

export const putMenuLocation = (id, data) => ({
  type: types.PUT_MENU_LOCATION,
  id,
  data
})

export const clearMenuLocationResponseInfo = () => ({
  type: types.CLEAR_MENU_LOCATION_RESPONSE_INFO
})

export const deleteSelectedMenuLocations = (ids, data) => ({
  type: types.DELETE_SELECTED_MENU_LOCATIONS,
  ids,
  data
})

export const getMenuStations = params => ({
  type: types.GET_MENU_STATIONS,
  params
})

export const postMenuStation = data => ({
  type: types.POST_MENU_STATION,
  data
})

export const deleteMenuStation = id => ({
  type: types.DELETE_MENU_STATION,
  id
})

export const getMenuStationById = id => ({
  type: types.GET_MENU_STATION_BY_ID,
  id
})

export const putMenuStation = (id, data) => ({
  type: types.PUT_MENU_STATION,
  id,
  data
})

export const clearMenuStationResponseInfo = () => ({
  type: types.CLEAR_MENU_STATION_RESPONSE_INFO
})

export const deleteSelectedMenuStations = ids => ({
  type: types.DELETE_SELECTED_MENU_STATIONS,
  ids
})

export const postMenuIntegrationConnect = data => ({
  type: types.POST_MENU_INTEGRATION_CONNECT,
  data
})

export const postMenuIntegrationDisconnect = id => ({
  type: types.POST_MENU_INTEGRATION_DISCONNECT,
  id
})

export const postMenuIntegrationSync = data => ({
  type: types.POST_MENU_INTEGRATION_SYNC,
  data
})

export const getMenuIntegrations = () => ({
  type: types.GET_MENU_INTEGRATIONS
})

export const getMenuMakerProviders = () => ({
  type: types.GET_MENU_MAKER_PROVIDERS
})

export const clearMenuIntegrationInfo = () => ({
  type: types.CLEAR_MENU_INTEGRATION_INFO
})

export const postMenuMakerItemBulk = payload => ({
  type: types.POST_MENU_MAKER_ITEM_BULK,
  payload
})

export const clearPostMenuMakerItemBulkInfo = () => ({
  type: types.CLEAR_POST_MENU_MAKER_ITEM_BULK_INFO
})
