import { createSelector } from 'reselect'
import { whiteLabelSelector } from 'selectors/whiteLabelSelectors'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { isAccessDenied, parseReducer } from 'utils/whiteLabelUtils'
import useIpAddress from 'hooks/useIpAddress'

const getWhiteLabel = createSelector(whiteLabelSelector, data =>
  parseReducer(data)
)

const getObjectFromString = data =>
  typeof data === 'string' ? JSON.parse(data) || {} : data

function useWhiteLabel() {
  const { ipAddress } = useIpAddress()
  const { privacyPolicy, termsCondition, aboutPage, ...data } = useSelector(
    getWhiteLabel
  )

  const { ipRestriction } = data

  return useMemo(
    () => ({
      privacyPolicy: getObjectFromString(privacyPolicy),
      termsCondition: getObjectFromString(termsCondition),
      aboutPage: getObjectFromString(aboutPage),
      ...data,
      isAccessDenied: ipRestriction
        ? isAccessDenied(ipAddress, ipRestriction)
        : false
    }),
    [privacyPolicy, termsCondition, aboutPage, data, ipAddress, ipRestriction]
  )
}

export default useWhiteLabel
