import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
  withTheme
} from '@material-ui/core'
import { DragDrop, ProgressBar } from '@uppy/react'
import { Close as CloseIcon, Folder } from '@material-ui/icons'
import EditImageControl from 'components/Uppy/partials/EditImageControl'
import Icon from 'components/Pages/Profile/Icon'
import useProgressStyle from './progressStyle'
import useUppyProgress from 'hooks/useUppyProgress'
import { isFileData, simulateEvent } from 'utils'
import { _isString } from 'utils/lodash'
import { defaultAvatar } from '../ProfileImage'

const styles = ({ palette, type, colors }) => ({
  dragDropWrap: {
    position: 'relative',
    width: '100%',
    height: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: '1px',
    borderStyle: 'dashed',
    color: colors.highlight,
    outline: 'none',
    transition: 'border .2s ease-in-out',
    '& .uppy': {
      '&-Root': {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        cursor: 'pointer',
        zIndex: 1,
        background: 'transparent',
        border: 0,
        outline: 'none',
        '& .uppy-DragDrop-inner': {
          display: 'none'
        },
        '& button': {
          cursor: 'pointer',
          background: 'transparent',
          outline: 'none',
          border: 0
        }
      }
    }
  },
  filesItemRemove: {
    marginRight: 0,
    marginLeft: 'auto',
    cursor: 'pointer',
    color: palette[type].formControls.label.color,
    '&:hover': {
      color: colors.highlight
    }
  },
  dropzoneText: {
    marginTop: '20px',
    fontSize: '0.8125rem',
    color: colors.highlight
  },
  previewImage: {
    maxWidth: 256,
    maxHeight: 256
  },
  listItemText: {
    color: palette[type].formControls.label.color
  },
  error: {
    color: colors.error,
    fontSize: 14,
    marginTop: '5px'
  },
  content: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  }
})

const ImageUpload = ({
  t,
  showPreview = true,
  uppy,
  name,
  handleDropzoneRef = () => ({}),
  handleProgress = () => ({}),
  customAvatarSelected = null,
  uploadIcon = null,
  showName = true,
  isReset = false,
  handleReset = () => ({}),
  onRemoveFileCb = () => ({}),
  classes,
  error = null,
  touched = false,
  theme,
  onImageData = () => ({}),
  onChange = () => ({}),
  onBlur = () => ({}),
  value = {},
  handleSetImageSrc = () => ({}),
  reactCropProps = {},
  aspectRatio,
  hideCrop = false,
  enableEditInError = false,
  defaultImagePreview = null,
  dragDropWrapperClass,
  previewImageClass,
  imageControlDialogClass,
  messageComponent
}) => {
  const [customError, setCustomError] = useState(null)
  const progress = useUppyProgress(uppy)
  const [imgSrc, setImgSrc] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const dragDropRef = useRef(null)
  const progressStyles = useProgressStyle()
  const currentValue = useMemo(() => value || {}, [value])

  const hasValue = useMemo(() => {
    return (
      !customError &&
      (!error || enableEditInError) &&
      showName &&
      currentValue.data?.type &&
      !currentValue.remote
    )
  }, [currentValue, showName, error, enableEditInError, customError])

  const changeFile = useCallback(
    file => {
      setCustomError(null)
      const url = URL.createObjectURL(file.data)
      const img = new Image()
      img.src = url
      img.onload = () => {
        onChange(
          {
            target: {
              name,
              value: file
            }
          },
          file
        )
        onImageData({
          width: img.width,
          height: img.height,
          fileSize: file.data?.size || 0
        })
        URL.revokeObjectURL(url)
      }

      img.onerror = () => {
        setCustomError(t('Image is invalid'))
        uppy.removeFile(file.id)
        URL.revokeObjectURL(url)
      }
    },
    [onChange, name, onImageData, setCustomError, t, uppy]
  )

  const onComplete = useCallback(
    result => {
      const file = result.successful?.[0]
      if (file) changeFile(result.successful?.[0])
    },
    [changeFile]
  )

  const onRemoveFile = useCallback(() => {
    onChange({ target: { name, value: null } })
    uppy.cancelAll()
    onRemoveFileCb()
  }, [onChange, uppy, name, onRemoveFileCb])

  const onCroppedImage = useCallback(
    file => {
      const data = {
        ...currentValue.data,
        data: file
      }

      const newFile = new File([file], `${currentValue.data.name}`)
      const url = URL.createObjectURL(newFile)
      const img = new Image()
      img.src = url
      img.onload = () => {
        onImageData({
          width: img.width,
          height: img.height,
          fileSize: newFile?.size || 0
        })
        URL.revokeObjectURL(url)
      }
      if (uppy.getFile(currentValue.id)) {
        uppy.setFileState(currentValue.id, data)
      } else {
        try {
          uppy.addFile(data)
        } catch (err) {
          console.error(err)
        }
      }
      setCroppedImage(file)
    },
    [currentValue, uppy, setCroppedImage, onImageData]
  )

  useEffect(() => {
    if (!dragDropRef.current) return
    handleDropzoneRef(
      dragDropRef.current?.container?.querySelector?.('.uppy-Root')
    )
  }, [dragDropRef, handleDropzoneRef])

  useEffect(() => {
    handleProgress(progress)
  }, [progress, handleProgress])

  useEffect(() => {
    handleSetImageSrc(imgSrc)
  }, [imgSrc, handleSetImageSrc])

  useEffect(() => {
    uppy.on('restriction-failed', changeFile)
    uppy.on('complete', onComplete)
    uppy.on('file-added', changeFile)
    uppy.on('file-removed', onRemoveFile)
    return () => {
      uppy.off('file-added', changeFile)
      uppy.off('complete', onComplete)
      uppy.off('restriction-failed', changeFile)
      uppy.off('file-removed', onRemoveFile)
    }
  }, [uppy, changeFile, onComplete, onRemoveFile])

  useEffect(() => {
    uppy.cancelAll()
    if (!customAvatarSelected || customAvatarSelected === defaultAvatar) return
    const url = URL.createObjectURL(customAvatarSelected)
    setImgSrc(url)
    try {
      uppy.addFile({
        name: customAvatarSelected.name,
        type: customAvatarSelected.type,
        data: customAvatarSelected,
        remote: true
      })
    } catch (err) {
      console.error(err)
    }
    return () => {
      URL.revokeObjectURL(url)
    }
    // eslint-disable-next-line
  }, [customAvatarSelected])

  useEffect(() => {
    if (currentValue.data) {
      setCroppedImage(currentValue.data)
    } else {
      setCroppedImage(null)
    }
  }, [currentValue.data])

  useEffect(() => {
    let url
    if (isFileData(croppedImage)) {
      url = URL.createObjectURL(croppedImage)
      setImgSrc(url)
    } else {
      setImgSrc(null)
    }

    return () => {
      URL.revokeObjectURL(url)
    }
    // eslint-disable-next-line
  }, [croppedImage])

  useEffect(() => {
    if (isReset) {
      uppy.cancelAll()
      setImgSrc(null)
      onRemoveFile()
      setCroppedImage(null)
      handleReset()
    }
    // eslint-disable-next-line
  }, [isReset])

  const { palette, type } = theme

  const textError = useMemo(() => {
    if (customError || (error && touched)) {
      if (touched && _isString(error)) {
        return error
      }
      if (customError) {
        return customError
      }
      return t('Error')
    }
    return null
  }, [error, touched, customError, t])

  const handleBlur = useCallback(() => {
    onBlur(simulateEvent(name, true))
  }, [onBlur, name])

  return (
    <>
      <div
        className={classNames(classes.dragDropWrap, dragDropWrapperClass)}
        onBlur={handleBlur}
      >
        <div className={progressStyles.wrap}>
          <ProgressBar uppy={uppy} />
        </div>
        <div className={classes.content}>
          {(imgSrc || (!customAvatarSelected && defaultImagePreview)) &&
          showPreview ? (
            <img
              src={imgSrc || defaultImagePreview}
              alt={name}
              className={classNames(classes.previewImage, previewImageClass)}
            />
          ) : (
            <>
              {uploadIcon || <Icon fill={palette[type].sideModal.background} />}
              <Typography className={classes.dropzoneText}>
                {t('Drag and Drop your image here')}
              </Typography>
            </>
          )}
          {!hideCrop && hasValue && !progress && (
            <EditImageControl
              onCropedImageChange={onCroppedImage}
              value={currentValue.data}
              error={error}
              enableEditInError={enableEditInError}
              reactCropProps={reactCropProps}
              aspectRatio={aspectRatio}
              imageControlDialogClass={imageControlDialogClass}
            />
          )}
        </div>

        {messageComponent && messageComponent}

        <DragDrop ref={dragDropRef} uppy={uppy} />
      </div>
      {hasValue && (
        <>
          <List>
            <ListItem>
              <ListItemIcon className={classes.listItemText}>
                <Folder />
              </ListItemIcon>
              <ListItemText
                primary={currentValue.name || currentValue.path}
                classes={{ primary: classes.listItemText }}
              />
              <ListItemIcon
                className={classes.filesItemRemove}
                onClick={onRemoveFile}
              >
                <CloseIcon />
              </ListItemIcon>
            </ListItem>
          </List>
        </>
      )}
      {textError && (
        <Typography className={classes.error}>{textError}</Typography>
      )}
    </>
  )
}

ImageUpload.propTypes = {
  uppy: PropTypes.object.isRequired,
  isReset: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  touched: PropTypes.bool,
  showPreview: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onImageData: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  handleDropzoneRef: PropTypes.func,
  handleReset: PropTypes.func,
  handleSetImageSrc: PropTypes.func,
  handleProgress: PropTypes.func,
  uploadIcon: PropTypes.node,
  showName: PropTypes.bool,
  hideCrop: PropTypes.bool,
  enableEditInError: PropTypes.bool,
  defaultImagePreview: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  customAvatarSelected: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.instanceOf(Blob),
    PropTypes.string
  ])
}

export default withTranslation('translations')(
  withStyles(styles)(withTheme(ImageUpload))
)
