import React, { useCallback, useEffect } from 'react'
import { _get } from 'utils/lodash'
import { compose } from '@reduxjs/toolkit'
import { withStyles } from '@material-ui/core'
import { useFormik } from 'formik'

import { FormControlInput, FormControlReactSelect } from 'components/Form'
import { BlueButton, WhiteButton } from 'components/Buttons'
import DefaultModal from 'components/Modal/DefaultModal'
import Scrollbars from 'components/Scrollbars'
import Spacing from 'components/Containers/Spacing'
import Container from 'components/Containers/Container'
import { CheckboxSwitcher } from 'components/Checkboxes'
import { saveDialogValidationnnSchema } from 'utils/emailNotificationUtils'
import { frequencyOptions } from 'utils/deviceNocUtils'

const styles = () => ({
  buttonSpacing: {
    margin: '0 16px'
  },
  scrollbarRoot: {
    height: '195px !important',
    maxHeight: 'calc(100vh - 260px)'
  },
  mainContentWrap: {
    maxWidth: '480px'
  },
  contentWrap: {
    padding: '32px 30px'
  }
})

const statusReturnValues = {
  Active: true,
  Inactive: false
}

const SaveDialog = ({
  t,
  classes,
  open,
  onClose,
  onSave,
  isEdit = false,
  values: defaultValues,
  errors: responseErrors
}) => {
  const handleFormSubmit = useCallback(
    values => {
      onSave(values)
    },
    [onSave]
  )

  const {
    isValid,
    values,
    errors,
    touched,
    setValues,
    setErrors,
    handleSubmit,
    resetForm,
    validateForm,
    handleChange,
    handleBlur
  } = useFormik({
    initialValues: {
      name: defaultValues.name,
      status: defaultValues.status,
      frequency: defaultValues.frequency
    },
    validationSchema: saveDialogValidationnnSchema(t),
    onSubmit: handleFormSubmit
  })

  useEffect(
    () => {
      validateForm()
    },
    //eslint-disable-next-line
    []
  )

  useEffect(
    () => {
      resetForm()
      setValues(defaultValues)
    },
    // eslint-disable-next-line
    [defaultValues]
  )

  useEffect(
    () => {
      if (responseErrors) {
        setErrors(responseErrors)
      }
    },
    // eslint-disable-next-line
    [responseErrors]
  )

  return (
    <DefaultModal
      containerClassName={classes.mainContentWrap}
      contentClass={classes.contentWrap}
      modalTitle={t('Save Alert Alarm')}
      open={open}
      onCloseModal={onClose}
      actions={
        <>
          <WhiteButton
            onClick={onClose}
            iconClassName="fa-regular fa-circle-arrow-left"
            variant="danger"
          >
            {t('Cancel')}
          </WhiteButton>
          <BlueButton
            opaque={!isValid}
            iconClassName="fa-regular fa-circle-plus"
            onClick={handleSubmit}
          >
            {isEdit ? t('Update') : t('Add')}
          </BlueButton>
        </>
      }
    >
      <Scrollbars className={classes.scrollbarRoot}>
        <form onSubmit={onSave}>
          <Spacing>
            <Container cols="1">
              <FormControlInput
                label={t('Alert Alarm Name')}
                fullWidth
                value={values.name}
                error={_get(errors, 'name', '')}
                touched={_get(touched, 'name', false)}
                name="name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                marginBottom={false}
              />
              <FormControlReactSelect
                label={t('Frequency')}
                fullWidth
                value={values.frequency}
                error={_get(errors, 'frequency', '')}
                touched={_get(touched, 'frequency', false)}
                name="frequency"
                handleChange={handleChange}
                handleBlur={handleBlur}
                options={frequencyOptions(t)}
                withPortal
              />
              <CheckboxSwitcher
                returnValues={statusReturnValues}
                label={t('Status')}
                labelPlacement="start"
                value={values.status}
                name="status"
                handleChange={handleChange}
                error={_get(errors, 'status', '')}
                touched={_get(touched, 'status', false)}
              />
            </Container>
          </Spacing>
        </form>
      </Scrollbars>
    </DefaultModal>
  )
}

export default compose(withStyles(styles))(SaveDialog)
