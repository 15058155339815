import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useTimeout from 'hooks/useTimeout'
import { useUserRole } from 'hooks/tableLibrary'
import useUserDetails from 'hooks/useUserDetails'
import { topBannerPreviewSelector } from 'selectors/appSelectors'
import { hideTopBannerPreview } from 'slices/appSlice'

export default function useTopBanner() {
  const dispatch = useDispatch()
  const role = useUserRole()
  const userDetails = useUserDetails()
  const topBannerPreview = useSelector(topBannerPreviewSelector)
  const [setTimeout, clearTimeout] = useTimeout()

  const data = useMemo(
    () => (role.system ? topBannerPreview : userDetails?.client || {}),
    [role, userDetails, topBannerPreview]
  )

  const hidePreview = useCallback(() => {
    if (role.system) {
      clearTimeout()
      setTimeout(() => dispatch(hideTopBannerPreview()), 10000)
    }
  }, [role, setTimeout, clearTimeout, dispatch])

  return useMemo(
    () => ({
      visible: !!data.deactivateNotificationType,
      data,
      hidePreview
    }),
    [data, hidePreview]
  )
}
