import React, { useMemo } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import { withStyles } from '@material-ui/styles'

import activeWifi from 'common/assets/icons/wifi-active.svg'
import activeWireless from 'common/assets/icons/wireless-active.svg'
import activeWired from 'common/assets/icons/wired-active.svg'
import activeDevice from 'common/assets/icons/device-node-active.svg'
import inactiveDevice from 'common/assets/icons/device-node-inactive.svg'
import { Text } from 'components/Typography'
import { truncateWithEllipsis } from 'utils/truncateStringUtils'
import DevicePopup from './components/DevicePopup'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 100
  },
  imgRoot: {
    position: 'relative'
  },
  connectionType: {
    position: 'absolute',
    top: -10,
    right: -10
  },
  text: {
    textAlign: 'center',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    whiteSpace: 'unset',
    fontSize: 11,
    lineHeight: '14px',
    margin: '3px 0px',
    maxWidth: 100,
    padding: '0 6px'
  }
})

const DeviceNodeType = ({ data: { rowData, ...data }, classes }) => {
  const connectionImg = useMemo(() => {
    if (!data.networkConnectionType) {
      return null
    }
    if (
      ['wifi', 'wireless', 'wired'].includes(
        data.networkConnectionType.toLowerCase()
      )
    ) {
      return data.networkConnectionType.toLowerCase() === 'wifi'
        ? activeWifi
        : data.networkConnectionType.toLowerCase() === 'wireless'
        ? activeWireless
        : activeWired
    }
    return null
  }, [data])

  return (
    <DevicePopup device={rowData}>
      <div className={classes.root}>
        <div className={classes.imgRoot}>
          {connectionImg && (
            <img
              src={connectionImg}
              alt="connectiontype"
              width="15"
              height="15"
              className={classes.connectionType}
            />
          )}
          <img
            src={data.active ? activeDevice : inactiveDevice}
            alt="device"
            width="20"
            height="20"
          />
        </div>
        <Text rootClassName={classes.text} variant="smaller">
          {truncateWithEllipsis(data.label, 20)}
        </Text>
        <Handle type="source" position={Position.Right} />
      </div>
    </DevicePopup>
  )
}

export default withStyles(styles)(DeviceNodeType)
