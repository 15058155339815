import {
  AUTO_TAG_BACKGROUND_COLOR,
  colorPalette,
  defaultTag,
  oldMappingValues
} from 'constants/chipsColorPalette'
import { searchCategoryType, searchTypeColorMapping } from 'constants/library'

export const tagTypeOptions = t => [
  {
    label: t('Device'),
    value: 'device',
    color: searchTypeColorMapping[searchCategoryType.device],
    iconHelperClass: 'fa-regular fa-desktop'
  },
  {
    label: t('User'),
    value: 'user',
    color: searchTypeColorMapping[searchCategoryType.user],
    iconHelperClass: 'fa-regular fa-user'
  },
  {
    label: t('Media'),
    value: 'media',
    color: searchTypeColorMapping[searchCategoryType.media],
    iconHelperClass: 'icon-files-landscape-video'
  },
  {
    label: t('Playlist'),
    value: 'playlist',
    color: searchTypeColorMapping[searchCategoryType.playlist],
    iconHelperClass: 'icon-playlist-2'
  },
  {
    label: t('Template'),
    value: 'template',
    color: searchTypeColorMapping[searchCategoryType.template],
    iconHelperClass: 'fa-sharp fa-light fa-pause fa-rotate-90 fa-lg'
  },
  {
    label: t('Schedule'),
    value: 'schedule',
    color: searchTypeColorMapping[searchCategoryType.schedule],
    iconHelperClass: 'icon-cloud-downloading-2'
  }
]

const paletteBackgroundToTextColor = colorPalette.reduce(
  (acc, { base, light }) => ({ ...acc, [base]: light }),
  {}
)

const getTextColor = ({ textColor, backgroundColor }) => {
  if (!textColor) {
    return paletteBackgroundToTextColor[backgroundColor] || defaultTag.textColor
  }
  return textColor
}

export const prepareTagData = (data = {}) => {
  const { tag, backgroundColor } = data

  return {
    tag,
    textColor: getTextColor(data),
    backgroundColor
  }
}

export const swapTagData = (tag = {}) => {
  const { textColor, backgroundColor, isAutoTag } = tag

  if (isAutoTag) {
    return {
      ...tag,
      backgroundColor: AUTO_TAG_BACKGROUND_COLOR
    }
  }

  if (
    !backgroundColor ||
    backgroundColor.replaceAll(' ', '') === 'rgba(255,255,255,1)'
  ) {
    const background = textColor?.replaceAll(' ', '')

    if (!oldMappingValues.hasOwnProperty(background)) {
      return tag
    }

    return {
      ...tag,
      backgroundColor: background,
      textColor: oldMappingValues[background] || defaultTag.backgroundColor
    }
  }

  return tag
}

export const swapTagsData = (tags = []) => {
  return tags.map(swapTagData)
}
