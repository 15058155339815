import { call, put } from 'redux-saga/effects'
import { transformMeta } from 'utils/tableUtils'

import * as types from '../actions'

import { mediaService, menuDesignService } from '../services'

function* getMenuPresets({ params }) {
  try {
    const { data, meta } = yield call(menuDesignService.getMenuPresets, params)
    yield put({
      type: types.GET_MENU_PRESETS_SUCCESS,
      payload: {
        data,
        meta: transformMeta(meta)
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_MENU_PRESETS_ERROR,
      payload: error
    })
  }
}

function* getStockMenuPresets({ params }) {
  try {
    const { data, meta } = yield call(
      menuDesignService.getStockMenuPresets,
      params
    )
    yield put({
      type: types.GET_STOCK_MENU_PRESETS_SUCCESS,
      payload: {
        data,
        meta: transformMeta(meta)
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_STOCK_MENU_PRESETS_ERROR,
      payload: error
    })
  }
}

function* getSharedMenuPresets({ params }) {
  try {
    const { data, meta } = yield call(
      menuDesignService.getSharedMenuPresets,
      params
    )
    yield put({
      type: types.GET_SHARED_MENU_PRESETS_SUCCESS,
      payload: {
        data,
        meta: transformMeta(meta)
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_SHARED_MENU_PRESETS_ERROR,
      payload: error
    })
  }
}

function* getMenuPresetItem({ id, isEdit }) {
  try {
    const response = yield call(menuDesignService.getMenuPresetItem, id)
    yield put({
      type: types.GET_MENU_PRESET_ITEM_SUCCESS,
      payload: response,
      isEdit
    })
  } catch (error) {
    yield put({
      type: types.GET_MENU_PRESET_ITEM_ERROR,
      payload: error
    })
  }
}

function* postMenuPreset({ data }) {
  try {
    const response = yield call(menuDesignService.postMenuPreset, data)
    yield put({
      type: types.POST_MENU_PRESET_SUCCESS,
      payload: { status: 'success', presetId: response.id }
    })
  } catch (error) {
    yield put({
      type: types.POST_MENU_PRESET_ERROR,
      payload: error
    })
  }
}

function* putMenuPreset({ id, data }) {
  try {
    yield call(menuDesignService.putMenuPreset, id, data)
    yield put({
      type: types.PUT_MENU_PRESET_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_MENU_PRESET_ERROR,
      payload: error
    })
  }
}

function* deleteMenuPreset({ id }) {
  try {
    yield call(menuDesignService.deleteMenuPreset, id)
    yield put({
      type: types.DELETE_MENU_PRESET_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_MENU_PRESET_ERROR,
      payload: error
    })
  }
}

function* shareMenuPreset({ id, data }) {
  try {
    yield call(menuDesignService.shareMenuPreset, id, data)
    yield put({
      type: types.SHARE_MENU_PRESET_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.SHARE_MENU_PRESET_ERROR,
      payload: error
    })
  }
}

function* getMenuComponents({ params }) {
  try {
    const { data, meta } = yield call(
      menuDesignService.getMenuComponents,
      params
    )
    yield put({
      type: types.GET_MENU_COMPONENTS_SUCCESS,
      payload: {
        data,
        meta: transformMeta(meta)
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_MENU_COMPONENTS_ERROR,
      payload: error
    })
  }
}

function* getStockMenuComponents({ params }) {
  try {
    const { data, meta } = yield call(
      menuDesignService.getStockMenuComponents,
      params
    )
    yield put({
      type: types.GET_STOCK_MENU_COMPONENTS_SUCCESS,
      payload: {
        data,
        meta: transformMeta(meta)
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_STOCK_MENU_COMPONENTS_ERROR,
      payload: error
    })
  }
}

function* getSharedMenuComponents({ params }) {
  try {
    const { data, meta } = yield call(
      menuDesignService.getSharedMenuComponents,
      params
    )
    yield put({
      type: types.GET_SHARED_MENU_COMPONENTS_SUCCESS,
      payload: {
        data,
        meta: transformMeta(meta)
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_SHARED_MENU_COMPONENTS_ERROR,
      payload: error
    })
  }
}

function* getMenuComponentItem({ id, isEdit }) {
  try {
    const response = yield call(menuDesignService.getMenuComponentItem, id)
    yield put({
      type: types.GET_MENU_COMPONENT_ITEM_SUCCESS,
      payload: response,
      isEdit
    })
  } catch (error) {
    yield put({
      type: types.GET_MENU_COMPONENT_ITEM_ERROR,
      payload: error
    })
  }
}

function* postMenuComponent({ data }) {
  try {
    yield call(menuDesignService.postMenuComponent, data)
    yield put({
      type: types.POST_MENU_COMPONENT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_MENU_COMPONENT_ERROR,
      payload: error
    })
  }
}

function* putMenuComponent({ id, data }) {
  try {
    yield call(menuDesignService.putMenuComponent, id, data)
    yield put({
      type: types.PUT_MENU_COMPONENT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_MENU_COMPONENT_ERROR,
      payload: error
    })
  }
}

function* deleteMenuComponent({ id }) {
  try {
    yield call(menuDesignService.deleteMenuComponent, id)
    yield put({
      type: types.DELETE_MENU_COMPONENT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_MENU_COMPONENT_ERROR,
      payload: error
    })
  }
}

function* shareMenuComponent({ id, data }) {
  try {
    yield call(menuDesignService.shareMenuComponent, id, data)
    yield put({
      type: types.SHARE_MENU_COMPONENT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.SHARE_MENU_COMPONENT_ERROR,
      payload: error
    })
  }
}

function* getMenuBoard({ params }) {
  try {
    const response = yield call(mediaService.getMediaLibraryItems, params)

    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_MENU_BOARD_SUCCESS,
      payload: { response, params },
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_MENU_BOARD_ERROR, payload: error })
  }
}

function* postMenuBoard({ payload }) {
  try {
    const response = yield call(mediaService.addMedia, payload)
    yield put({
      type: types.POST_MENU_BOARD_SUCCESS,
      payload: { status: 'success', mediaId: response.id }
    })
    yield put({
      type: types.SET_MEDIA_DEPENDENCY,
      payload: { error: null }
    })
  } catch (error) {
    yield put({
      type: types.POST_MENU_BOARD_ERROR,
      payload: error
    })
    yield put({
      type: types.SET_MEDIA_DEPENDENCY,
      payload: { error }
    })
  }
}

function* putMenuBoard({ payload: { id, mediaData, isClone } }) {
  try {
    if (isClone) {
      yield call(mediaService.cloneMediaAndUpdate, { id, data: mediaData })
    } else {
      yield call(mediaService.putMediaItemById, { id, data: mediaData })
    }
    yield put({
      type: types.PUT_MENU_BOARD_SUCCESS,
      payload: { status: 'success' }
    })
    yield put({
      type: types.SET_MEDIA_DEPENDENCY,
      payload: { error: null }
    })
  } catch (error) {
    yield put({
      type: types.PUT_MENU_BOARD_ERROR,
      payload: error
    })
    yield put({
      type: types.SET_MEDIA_DEPENDENCY,
      payload: { error }
    })
  }
}

// images

function* getStockImages({ params }) {
  try {
    const { data, meta } = yield call(menuDesignService.getStockImages, params)
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_MENU_MAKER_STOCK_IMAGES_SUCCESS,
      payload: data,
      meta: modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_MENU_MAKER_STOCK_IMAGES_ERROR, payload: error })
  }
}

function* getPatterns(action) {
  try {
    const response = yield call(menuDesignService.getPatterns, action.data)

    yield put({
      type: types.GET_MENU_MAKER_PATTERNS_SUCCESS,
      meta: action.data,
      payload: response
    })
  } catch (e) {
    yield put({
      type: types.GET_MENU_MAKER_PATTERNS_ERROR,
      payload: e
    })
  }
}

function* getBackgroundImages(action) {
  try {
    const response = yield call(
      menuDesignService.getBackgroundImages,
      action.data
    )

    yield put({
      type: types.GET_MENU_MAKER_BACKGROUND_IMAGES_SUCCESS,
      action,
      payload: response
    })
  } catch (e) {
    yield put({
      type: types.GET_MENU_MAKER_BACKGROUND_IMAGES_ERROR,
      payload: e
    })
  }
}

function* getShapes(action) {
  try {
    const response = yield call(menuDesignService.getShapes, action.data)
    yield put({
      type: types.GET_MENU_MAKER_SHAPES_SUCCESS,
      meta: action.data,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({
      type: types.GET_MENU_MAKER_SHAPES_ERROR,
      payload: e
    })
  }
}

function* getObjects(action) {
  try {
    const response = yield call(menuDesignService.getObjects, action.data)
    yield put({
      type: types.GET_MENU_MAKER_OBJECTS_SUCCESS,
      meta: action.data,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({
      type: types.GET_MENU_MAKER_OBJECTS_ERROR,
      e
    })
  }
}

function* getIcons(action) {
  try {
    const response = yield call(menuDesignService.getIcons, action.data)
    yield put({
      type: types.GET_MENU_MAKER_ICONS_SUCCESS,
      meta: action.data,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({
      type: types.GET_MENU_MAKER_ICONS_ERROR,
      e
    })
  }
}

function* getIconsGroup(action) {
  try {
    const response = yield call(menuDesignService.getIconsGroup, action.data)
    yield put({
      type: types.GET_MENU_MAKER_ICONS_GROUP_SUCCESS,
      action,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: types.GET_MENU_MAKER_ICONS_GROUP_ERROR,
      e
    })
  }
}

function* getEmojis(action) {
  const response = yield call(menuDesignService.getEmojis, action.data)
  try {
    yield put({
      type: types.GET_MENU_MAKER_EMOJIS_SUCCESS,
      meta: action.data,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({ type: types.GET_MENU_MAKER_EMOJIS_ERROR, payload: e })
  }
}

function* getMenuDesignPreview(action) {
  try {
    const response = yield call(mediaService.generateMediaPreview, action.data)
    yield put({
      type: types.GET_MENU_DESIGN_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_MENU_DESIGN_PREVIEW_ERROR, payload: error })
  }
}

const menuDesignSaga = {
  getMenuPresets,
  getStockMenuPresets,
  getSharedMenuPresets,
  getMenuPresetItem,
  postMenuPreset,
  putMenuPreset,
  deleteMenuPreset,
  shareMenuPreset,
  getMenuComponents,
  getStockMenuComponents,
  getSharedMenuComponents,
  getMenuComponentItem,
  postMenuComponent,
  putMenuComponent,
  deleteMenuComponent,
  shareMenuComponent,
  getMenuBoard,
  postMenuBoard,
  putMenuBoard,
  getBackgroundImages,
  getEmojis,
  getIcons,
  getIconsGroup,
  getObjects,
  getPatterns,
  getShapes,
  getStockImages,
  getMenuDesignPreview
}

export default menuDesignSaga
