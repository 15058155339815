import React, { memo, useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from '@reduxjs/toolkit'

import FormControlCopyInput from 'components/Form/FormControlCopyInput'
import DefaultModal from 'components/Modal/DefaultModal'
import getVirtualDeviceLink from 'utils/getVirtualDeviceLink'

const DeviceVirtualPlayerModal = ({
  device,
  open = false,
  handleClose = f => f,
  t
}) => {
  const { virtualDevice } = device

  const virtualDeviceLink = useMemo(
    () => getVirtualDeviceLink(device) || t('N/A'),
    [device, t]
  )

  const handleCloseModal = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <DefaultModal
      modalTitle={t('Virtual Player')}
      onCloseModal={handleCloseModal}
      open={open}
      maxWidth="xs"
      hasSaveBtn={false}
    >
      <FormControlCopyInput
        label={t('Virtual Device Link')}
        value={virtualDeviceLink}
        disabled={!virtualDevice?.subdomain}
      />
    </DefaultModal>
  )
}

export default compose(
  withTranslation('translations'),
  memo
)(DeviceVirtualPlayerModal)
