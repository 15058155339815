import React, { useEffect } from 'react'

//we dont need to handle anything in this case, but we need to prevent redirect for some time and close popup window
const InstagramLoginProcessing = () => {
  useEffect(() => {
    setTimeout(() => window.close(), 2000)
  }, [])

  return <div>Processing...</div>
}

export default InstagramLoginProcessing
