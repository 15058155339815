import update from 'immutability-helper'

import * as types from 'actions'

const initialState = {
  isOpenLeftSidebar: true,
  isOpenRightSidebar: true,
  designGalleryItem: {
    status: null,
    response: null,
    error: null,
    meta: null
  },
  filter: {},
  changeObjectTracking: {}
}

const designGalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DESIGN_GALLERY_SUCCESS:
      return update(state, {
        designGalleryItem: {
          response: { $set: action.payload },
          isEdit: { $set: action.isEdit }
        }
      })
    case types.GET_DESIGN_GALLERY_ERROR:
      return update(state, {
        designGalleryItem: {
          error: { $set: action.payload }
        }
      })

    case types.POST_DESIGN_GALLERY_SUCCESS:
    case types.PUT_DESIGN_GALLERY_SUCCESS:
      return update(state, {
        designGalleryItem: {
          status: { $set: 'successfully' },
          response: { $set: action.payload.data },
          meta: { $set: action.meta }
        }
      })
    case types.POST_DESIGN_GALLERY_ERROR:
    case types.PUT_DESIGN_GALLERY_ERROR:
      return update(state, {
        designGalleryItem: {
          status: { $set: 'error' },
          error: { $set: action.payload },
          meta: { $set: action.meta }
        }
      })

    case types.CLEAR_DESIGN_GALLERY:
      return update(state, {
        designGalleryItem: {
          $set: {
            status: null,
            response: null,
            error: null,
            meta: null
          }
        }
      })

    case types.CLEAR_DESIGN_GALLERY_STATUS:
      return update(state, {
        designGalleryItem: {
          status: { $set: null },
          error: { $set: null }
        }
      })

    case types.SET_OPEN_LEFT_SIDEBAR:
      return {
        ...state,
        isOpenLeftSidebar: action.data
      }

    case types.SET_OPEN_RIGHT_SIDEBAR:
      return {
        ...state,
        isOpenRightSidebar: action.data
      }
    case types.DG_OBJECT_CHANGED:
      return update(state, {
        changeObjectTracking: { $set: {} }
      })
    case types.PUT_DESIGN_GALLERY_FILTER:
      return update(state, {
        filter: { $set: action.payload }
      })
    default:
      return state
  }
}

export default designGalleryReducer
