import api from 'services/api'
import { errorHandler } from 'utils'

const getPreferenceByEntity = async entity => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/preference',
      params: {
        entity
      }
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putPreferenceByEntity = async (entity, data) => {
  try {
    await api({
      method: 'PUT',
      url: `/preference/${entity}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getPreferencePublicNoc = async token => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/public/noc/preference?token=${token}&feature_name=DeviceNoc`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putPreferencePublicNoc = async data => {
  try {
    await api({
      method: 'PUT',
      url: `/public/noc/preference`,
      data: {
        feature_name: 'DeviceNoc',
        ...data
      }
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const preferenceService = {
  getPreferenceByEntity,
  putPreferenceByEntity,
  getPreferencePublicNoc,
  putPreferencePublicNoc
}

export default preferenceService
