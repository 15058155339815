export const getOptimalChunkSize = (array, min, max) => {
  const chunks = Math.ceil(array.length / max)
  let chunkSize = max

  while (
    chunkSize >= min &&
    Math.ceil(array.length / (chunkSize - 1)) === chunks
  ) {
    chunkSize--
  }

  return chunkSize
}

export const getIncrementedOrMissingIndex = indexList => {
  const set = new Set(indexList)

  for (let i = 0; i < indexList.length; i++) {
    if (!set.has(i)) {
      return i
    }
  }

  return indexList.length
}
