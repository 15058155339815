import * as types from './index'

const getItems = params => ({
  type: types.GET_HELPS,
  params
})

const getItem = page => ({
  type: types.GET_HELP,
  page
})

const clearGetHelpItemInfo = () => ({
  type: types.CLEAR_GET_HELP_INFO
})

const putItem = (id, data) => ({ type: types.PUT_HELP, id, data })

const clearPutHelpItemInfo = () => ({
  type: types.CLEAR_PUT_HELP_INFO
})

const handleScrolling = isScrolling => ({
  type: types.HANDLE_IS_SCROLLING,
  isScrolling
})

export {
  getItems,
  getItem,
  clearGetHelpItemInfo,
  putItem,
  clearPutHelpItemInfo,
  handleScrolling
}
