import React, { useCallback, useMemo } from 'react'
import { Grid, withStyles, Typography } from '@material-ui/core'
import {
  AddOutlined as AddOutlinedIcon,
  RemoveOutlined as RemoveOutlinedIcon
} from '@material-ui/icons'
import update from 'immutability-helper'
import { _get } from 'utils/lodash'

import Text from 'components/Typography/Text'
import CheckboxSwitcher from 'components/Checkboxes/Switcher'
import { FormControlInput, FormControlTelInput } from 'components/Form'
import { CircleIconButton } from 'components/Buttons'
import { simulateEvent } from 'utils'
import { alarmDetailsInitialValue } from 'utils/deviceNocUtils'
import { alertAlarmViaType } from 'constants/deviceNoc'

const styles = theme => {
  const { palette, type } = theme
  return {
    listContentWrap: {
      display: 'grid',
      gridColumnGap: 6,
      gridTemplateColumns: '1.5fr 1fr 6fr 2fr',
      alignItems: 'center'
    },
    viaItemName: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: type === 'dark' ? '#fff' : '#2C2D3A'
    },
    icon: {
      color: '#74809A'
    },
    agreeMessageWrapper: {
      display: 'grid',
      gridColumnGap: 6,
      gridTemplateColumns: '1.5fr 1fr 6fr 2fr'
    },
    agreeMessage: {
      color: palette[type].messageArea.color,
      fontSize: 11,
      paddingBottom: 10,
      gridColumnStart: 3
    },
    rowInput: {
      gridColumnStart: 3
    },
    circleButton: {
      padding: 5,
      maxWidth: 30,
      maxHeight: 30
    }
  }
}

const SendAlertViaRow = ({
  type,
  value: { toggle = false, data = [] } = {},
  errors,
  touched,
  onChange = f => f,
  classes,
  t
}) => {
  const translate = useMemo(
    () => ({
      [alertAlarmViaType.SMS]: {
        alias: t('SMS'),
        inputLabel: t('Phone Number')
      },
      [alertAlarmViaType.EMAIL]: {
        alias: t('Email'),
        inputLabel: t('Email')
      },
      [alertAlarmViaType.NOC]: {
        alias: t('NOC')
      }
    }),
    [t]
  )

  const handleAddItem = useCallback(() => {
    onChange(
      simulateEvent(`${type}.data`, [...data, { ...alarmDetailsInitialValue }])
    )
  }, [onChange, data, type])

  const handleRemoveItem = useCallback(
    index => () => {
      onChange(
        simulateEvent(
          `${type}.data`,
          update(data, {
            $splice: [[index, 1]]
          })
        )
      )
    },
    [onChange, data, type]
  )

  const addRemoveControls = useCallback(
    index => (
      <div>
        {index > 0 && (
          <CircleIconButton
            className={`hvr-grow ${classes.circleButton}`}
            onClick={handleRemoveItem(index)}
          >
            <RemoveOutlinedIcon className={classes.icon} />
          </CircleIconButton>
        )}
        {index === data.length - 1 && (
          <CircleIconButton
            className={`hvr-grow ${classes.circleButton}`}
            onClick={handleAddItem}
          >
            <AddOutlinedIcon className={classes.icon} />
          </CircleIconButton>
        )}
      </div>
    ),
    [data, classes.circleButton, classes.icon, handleAddItem, handleRemoveItem]
  )

  return (
    <>
      <Grid item className={classes.listContentWrap}>
        <Text className={classes.viaItemName}>
          {_get(translate, `${type}.alias`, '')}
        </Text>
        <div className={classes.checkboxWrapper}>
          <CheckboxSwitcher
            switchStrictWidth
            name={`${type}.toggle`}
            value={toggle}
            handleChange={onChange}
          />
        </div>
        {data.map((item, index) =>
          type === alertAlarmViaType.SMS ? (
            <React.Fragment key={`send-alert-via-${type}-${index}`}>
              <div className={classes.rowInput}>
                <FormControlTelInput
                  label={_get(translate, `${type}.inputLabel`, '')}
                  name={`${type}.data.${index}.value`}
                  value={item.value}
                  error={_get(errors, `data.${index}.value`, '')}
                  touched={_get(touched, `data.${index}.value`, false)}
                  onChange={onChange}
                  disabled={!toggle}
                />
              </div>
              <>{addRemoveControls(index)}</>
            </React.Fragment>
          ) : type === alertAlarmViaType.EMAIL ? (
            <React.Fragment key={`send-alert-via-${type}-${index}`}>
              <div className={classes.rowInput}>
                <FormControlInput
                  label={_get(translate, `${type}.inputLabel`, '')}
                  name={`${type}.data.${index}.value`}
                  value={item.value}
                  error={_get(errors, `data.${index}.value`, '')}
                  touched={_get(touched, `data.${index}.value`, false)}
                  handleChange={onChange}
                  disabled={!toggle}
                />
              </div>
              <>{addRemoveControls(index)}</>
            </React.Fragment>
          ) : null
        )}
      </Grid>
      <Grid className={classes.agreeMessageWrapper}>
        {toggle && type === alertAlarmViaType.SMS && (
          <Typography className={classes.agreeMessage}>
            {t(
              'You agree to receive automated messages from Mvix Digital Signage'
            )}
          </Typography>
        )}
      </Grid>
    </>
  )
}

export default withStyles(styles)(SendAlertViaRow)
