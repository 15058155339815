import React from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles, Typography, Grid, List } from '@material-ui/core'
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from '@material-ui/icons'

import { WhiteButton } from 'components/Buttons'
import {
  DropdownHover,
  DropdownHoverListItem,
  DropdownHoverListItemText
} from 'components/Dropdowns'
import BasePaginate from 'components/BasePaginate'
import FormControlInput from 'components/Form/FormControlInput'
import 'styles/pagination/_pagination.scss'
import { Text } from 'components/Typography'

const styles = ({ palette, type, typography }) => ({
  root: {
    height: '50px',
    paddingRight: '15px'
  },
  paginationInputWraps: {
    width: '63px',
    marginRight: '13px'
  },
  paginationWrap: {
    marginRight: '18px',
    paddingRight: '5px',
    borderRight: `1px solid ${palette[type].tableLibrary.footer.pagination.border}`
  },
  goToWrap: {
    marginRight: '20px',
    borderRight: `1px solid ${palette[type].tableLibrary.footer.pagination.border}`
  },
  paginationText: {
    marginRight: '10px',
    ...typography.lightText[type]
  },
  rowActionBtn: {
    minWidth: '61px',
    paddingLeft: '10px',
    paddingRight: '10px',
    boxShadow: `0 1px 0 0 ${palette[type].tableLibrary.footer.pagination.button.shadow}`,
    color: palette[type].tableLibrary.footer.pagination.button.color,
    backgroundColor:
      palette[type].tableLibrary.footer.pagination.button.background,
    borderColor: palette[type].tableLibrary.footer.pagination.button.border,

    '&:hover': {
      borderColor: '#1c5dca',
      backgroundColor: '#1c5dca',
      color: '#fff'
    }
  },
  rowActionBtnIcon: {
    width: 18,
    height: 18
  },
  totalItemsWrap: {
    marginRight: '18px',
    paddingRight: '18px',
    borderRight: `1px solid ${palette[type].tableLibrary.footer.pagination.border}`
  },
  totalItemsLabel: {
    ...typography.darkText[type],
    fontSize: '18px',
    fontWeight: 'bold',
    color: typography.darkText[type].color
  },
  textLight: {
    ...typography.lightText[type]
  },
  paginationGrid: {
    width: 'auto'
  }
})

const TableLibraryPagination = ({
  t,
  classes,
  pageCount = 0,
  previousLabel = <KeyboardArrowLeft />,
  nextLabel = <KeyboardArrowRight />,
  marginPagesDisplayed = 1,
  pageRangeDisplayed = 5,
  onPageChange,
  page = 1,
  onPressJumper,
  perPage,
  perPageOptions,
  onChangeRowsPerPage,
  displayPaginationOptions = true,
  totalCount,
  paginationClasses = {},
  handlePaginationChange,
  currentPage
}) => {
  const calculatedPageRangeDisplayed =
    page < parseInt(pageCount / 2) + 2
      ? pageRangeDisplayed - 1
      : pageRangeDisplayed
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      className={classNames(classes.root, paginationClasses.root)}
    >
      {totalCount !== undefined && totalCount !== 0 ? (
        <Grid
          item
          className={classNames(
            classes.totalItemsWrap,
            paginationClasses.totalItemsWrap
          )}
        >
          <Text
            rootClassName={paginationClasses.totalItemsLabel}
            variant="big"
            color="title.primary"
            weight="bold"
          >
            {totalCount} {t('Total Items')}
          </Text>
        </Grid>
      ) : null}
      <Grid
        item
        className={classNames(
          classes.paginationWrap,
          paginationClasses.paginationWrap
        )}
      >
        <BasePaginate
          previousLabel={previousLabel}
          nextLabel={nextLabel}
          page={page}
          pageCount={pageCount}
          marginPagesDisplayed={marginPagesDisplayed}
          pageRangeDisplayed={calculatedPageRangeDisplayed}
          onPageChange={onPageChange}
          containerClassName={classes.textLight}
        />
      </Grid>
      <Grid item container className={classes.paginationGrid}>
        <Grid
          item
          className={classNames({
            [classes.goToWrap]: displayPaginationOptions
          })}
        >
          <Grid container alignItems="center">
            <Grid item>
              <Typography className={classes.paginationText}>
                {t('Pagination Go to page')}
              </Typography>
            </Grid>
            <Grid item className={classes.paginationInputWraps}>
              <FormControlInput
                fullWidth
                type="text"
                onKeyPress={onPressJumper}
                marginBottom={false}
                {...(typeof currentPage === 'string'
                  ? {
                      value: currentPage,
                      handleChange: handlePaginationChange
                    }
                  : {
                      defaultValue: ''
                    })}
              />
            </Grid>
          </Grid>
        </Grid>
        {displayPaginationOptions && (
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Typography className={classes.paginationText}>
                  {t('Pagination Show')}
                </Typography>
              </Grid>
              <Grid item className={classes.paginationInputWraps}>
                <DropdownHover
                  dropSide="topCenter"
                  buttonHoverColored
                  ButtonComponent={
                    <WhiteButton className={classes.rowActionBtn}>
                      {perPage}
                      <KeyboardArrowDown className={classes.rowActionBtnIcon} />
                    </WhiteButton>
                  }
                  MenuComponent={
                    <List component="nav" disablePadding>
                      {perPageOptions.map((item, index) => (
                        <DropdownHoverListItem
                          key={`perPageItem-${index}`}
                          onClick={() => onChangeRowsPerPage(item)}
                        >
                          <DropdownHoverListItemText primary={item} />
                        </DropdownHoverListItem>
                      ))}
                    </List>
                  }
                />
              </Grid>
              <Grid item>
                <Typography className={classes.paginationText}>
                  {t('Pagination Per page')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

TableLibraryPagination.propTypes = {
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onPressJumper: PropTypes.func,
  displayPaginationOptions: PropTypes.bool
}

export default withTranslation('translations')(
  withStyles(styles)(TableLibraryPagination)
)
