export const DEFAULT_REBOOT_AFTER_VALUE = 300
export const DEFAULT_REBOOT_AFTER_UNIT = 'second'

export const REBOOT_KEY = 'rebootDeviceList'

export const daysData = {
  Sun: 'Sundays',
  Mon: 'Mondays',
  Tue: 'Tuesdays',
  Wed: 'Wednesdays',
  Thu: 'Thursdays',
  Fri: 'Fridays',
  Sat: 'Saturdays'
}
