import React, { memo } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'

import { Card } from 'components/Card'

const styles = ({ palette, type }) => ({
  root: {
    padding: '0 10px',
    height: '100%'
  },
  header: {
    paddingLeft: 0,
    marginBottom: 20,
    borderRadius: 5,
    border: `solid 1px ${palette[type].sideModal.content.border}`,
    backgroundColor: palette[type].pages.media.general.card.header.background
  },
  headerText: {
    fontWeight: 'bold',
    lineHeight: 1,
    padding: 20,
    color: palette[type].card.greyHeader.color
  },
  headerTextWithError: {
    paddingRight: 10
  }
})

const BaseSection = ({
  title,
  iconButtonComponent,
  rootClassName = '',
  children,
  headerClasses = [],
  icon = false,
  error,
  classes
}) => {
  return (
    <Card
      icon={icon}
      grayHeader
      shadow={false}
      radius={false}
      headerSidePaddings
      rootClassName={classNames(classes.root, rootClassName)}
      removeNegativeHeaderSideMargins
      title={title}
      headerClasses={[classes.header, headerClasses]}
      headerTextClasses={[
        classes.headerText,
        error ? [classes.headerTextWithError] : []
      ]}
      iconButtonComponent={iconButtonComponent}
      dropdown={false}
      error={error}
    >
      {children}
    </Card>
  )
}

export default withStyles(styles)(memo(BaseSection))
