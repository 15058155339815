import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const MemoryContentLoader = ({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 306
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect x="72" y="30" rx="6" ry="6" width="700" height="90" />
    <rect x="72" y="170" rx="6" ry="6" width="700" height="90" />
    <rect x="812" y="10" rx="6" ry="6" width="362" height="140" />
    <rect x="812" y="180" rx="6" ry="6" width="362" height="140" />
    <rect x="1222" y="94" rx="68" ry="68" width="136" height="136" />
    <rect x="1377" y="94" rx="68" ry="68" width="136" height="136" />
  </ContentLoader>
)

export default withTheme(MemoryContentLoader)
