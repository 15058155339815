import { useEffect } from 'react'
import { notificationAnalyzer } from 'utils'
import isEmpty from 'lodash/isEmpty'

const useNotifyAnalyzer = (
  fetcher = f => f,
  cleaner = f => f,
  showSnackbar = f => f,
  closeSnackbar = f => f,
  keyWord = '',
  watchArray = [],
  item = {},
  clearItemToRemove = f => f,
  params = {},
  hideErrorNotification = false,
  stopAnalyzing = false
) => {
  useEffect(() => {
    if (!stopAnalyzing) {
      const wasNotify = notificationAnalyzer(
        showSnackbar,
        watchArray,
        keyWord,
        closeSnackbar,
        item,
        clearItemToRemove,
        hideErrorNotification
      )

      const isEmptyErrors = watchArray.every(({ error }) => isEmpty(error))

      if (wasNotify) {
        cleaner()
        // updates list of items only in there are no errors from the server.
        isEmptyErrors && fetcher(params)
      }
    }
    // eslint-disable-next-line
  }, watchArray)
}

export default useNotifyAnalyzer
