import { useCallback, useEffect, useMemo } from 'react'
import _get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import { getItemSuccessMessage } from 'utils/generalUtils'
import useSnackbar from './useSnackbar'

const initialNotificationOptions = {
  success: true,
  error: true
}

export default function useMutationResultHandler({
  result,
  keyWord,
  actionText = 'created',
  namePath = 'title',
  plural = false,
  onSuccess = f => f,
  onError = f => f,
  notificationOptions: notificationOptionsProp = {}
}) {
  const { showSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const notificationOptions = useMemo(
    () => ({
      ...initialNotificationOptions,
      ...notificationOptionsProp
    }),
    [notificationOptionsProp]
  )

  const handleSuccess = useCallback(
    result => {
      if (notificationOptions.success) {
        const message = getItemSuccessMessage(
          _get(result.originalArgs, namePath, keyWord || t('Item')),
          t(typeof actionText === 'function' ? actionText(result) : actionText),
          plural
        )
        showSnackbar(message, 'success')
      }
      result.reset()
      onSuccess(result)
    },
    [
      keyWord,
      namePath,
      plural,
      actionText,
      onSuccess,
      notificationOptions,
      showSnackbar,
      t
    ]
  )

  const handleError = useCallback(
    result => {
      if (
        notificationOptions.error &&
        result.error.code !== 409 &&
        !result.error.errorFields?.length
      ) {
        const message = `${t(
          `${_get(result.originalArgs, namePath, keyWord || t('Item'))} ${
            plural ? 'were' : 'was'
          } not ${t(
            typeof actionText === 'function' ? actionText(result) : actionText
          )}`
        )}: ${result.error.message}`
        showSnackbar(message, 'error')
      }
      result.reset()
      onError(result)
    },
    [
      actionText,
      keyWord,
      namePath,
      notificationOptions,
      onError,
      plural,
      showSnackbar,
      t
    ]
  )

  useEffect(() => {
    if (result.isSuccess) {
      handleSuccess(result)
    } else if (result.isError) {
      handleError(result)
    }
    // eslint-disable-next-line
  }, [result])
}
