import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import useTimeout from 'hooks/useTimeout'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import Icon from 'components/Icons/Icon'
import Tooltip from 'components/Tooltip'
import { ORG_ROLE } from 'constants/api'
import { DEVICE_PREVIEW_UPDATE_HISTORY } from 'constants/devicePreviewRefresh'

const styles = ({ palette, type }) => ({
  refreshIcon: {
    cursor: 'pointer',
    color: palette[type].deviceCardPreview.dateColor,
    fontSize: 16,
    opacity: 1,
    transition: '1s'
  },
  refreshIconDisabled: {
    cursor: 'default',
    opacity: 0.3
  }
})

const RESTRICTION_DURATION = 60000

const DevicePreviewRefreshIcon = ({
  deviceId,
  onUpdate = f => f,
  disabled = false,
  iconClass = '',
  classes,
  t
}) => {
  const [refreshDisabled, setRefreshDisabled] = useState(false)
  const [setTimeout, clearTimeout] = useTimeout()
  const role = getUserRoleLevel()
  const updateHistory = useRef({})

  const clearDeviceHistory = () => {
    delete updateHistory.current[deviceId]
    localStorage.setItem(
      DEVICE_PREVIEW_UPDATE_HISTORY,
      JSON.stringify(updateHistory.current)
    )
  }

  const disableRefresh = milliseconds => {
    setRefreshDisabled(true)
    setTimeout(() => {
      setRefreshDisabled(false)
      clearDeviceHistory()
    }, milliseconds)
  }

  const getUpdatedHistory = () => {
    const savedUpdateHistory =
      JSON.parse(localStorage.getItem(DEVICE_PREVIEW_UPDATE_HISTORY)) || {}
    const updatedHistory = {}
    Object.keys(savedUpdateHistory).forEach(id => {
      if (Date.now() - savedUpdateHistory[id] < RESTRICTION_DURATION) {
        updatedHistory[id] = savedUpdateHistory[id]
      }
    })
    updateHistory.current = updatedHistory
    localStorage.setItem(
      DEVICE_PREVIEW_UPDATE_HISTORY,
      JSON.stringify(updatedHistory)
    )

    return updatedHistory
  }

  useEffect(() => {
    if (role === ORG_ROLE && deviceId) {
      const updatedHistory = getUpdatedHistory()
      const lastPreviewTimestamp = updatedHistory[deviceId]
      if (lastPreviewTimestamp) {
        const leftMilliseconds =
          RESTRICTION_DURATION - (Date.now() - lastPreviewTimestamp)
        if (leftMilliseconds > 0) disableRefresh(leftMilliseconds)
      }
    }
    return () => clearTimeout()
    // eslint-disable-next-line
  }, [])

  const handleUpdate = () => {
    if (disabled || refreshDisabled) return
    onUpdate()
    if (role === ORG_ROLE && deviceId) {
      const newPreviewHistory = {
        ...updateHistory.current,
        [deviceId]: Date.now()
      }
      updateHistory.current = newPreviewHistory
      localStorage.setItem(
        DEVICE_PREVIEW_UPDATE_HISTORY,
        JSON.stringify(newPreviewHistory)
      )
      disableRefresh(RESTRICTION_DURATION)
    }
  }

  return (
    <Tooltip
      withHeader
      headerText={t('Refresh Preview')}
      title={t(
        refreshDisabled
          ? 'Refresh available once per minute'
          : 'Generate and fetch the latest preview of device playback'
      )}
    >
      <Icon
        container={false}
        icon="fa fa-refresh"
        color="light"
        onClick={handleUpdate}
        rootClassName={classNames(classes.refreshIcon, iconClass, {
          [classes.refreshIconDisabled]: disabled || refreshDisabled
        })}
      />
    </Tooltip>
  )
}

DevicePreviewRefreshIcon.propTypes = {
  deviceId: PropTypes.number,
  onUpdate: PropTypes.func,
  disabled: PropTypes.bool,
  iconClass: PropTypes.string
}

export default withTranslation('translations')(
  withStyles(styles)(DevicePreviewRefreshIcon)
)
