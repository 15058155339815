import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  withStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  withTheme,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import DrawerIcon from 'common/icons/index'
import { DropdownHover } from 'components/Dropdowns'
import Scrollbars from 'components/Scrollbars'
import { colors } from 'theme'
import HoverMenuList from 'components/Account/HoverMenuList'
import { Brightness4, BrightnessAuto } from '@material-ui/icons'

const styles = theme => {
  const { palette, type, typography, fontSize, colors, breakpoints } = theme
  return {
    iconButtonWrapper: {
      marginRight: '1rem',
      paddingRight: '0.5rem',
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: palette[type].secondary
    },
    navigationSubMenu: {
      top: '80%',
      transform: 'rotateX(90deg)',
      transformOrigin: 'top center'
    },
    navigationHoverCard: {
      backgroundColor: palette[type].body.background,
      borderRadius: '10px',
      position: 'absolute',
      top: 0,
      left: 0,
      transitionDuration: '0.7s',
      width: '255px'
    },
    listContainer: {
      padding: '0px',
      position: 'relative',
      overflowY: 'visible',
      display: 'grid',
      gridGap: '6px'
    },
    textRoot: {
      margin: '0px'
    },
    text: {
      ...typography.lightText[type],
      fontSize: fontSize.primary,
      lineHeight: '1.5rem',
      fontWeight: 600,
      transition: 'opacity .2s',
      whiteSpace: 'nowrap',
      color: colors.highlight
    },
    description: {
      ...typography.lightText[type],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: 2,
      boxOrient: 'vertical'
    },
    listItem: {
      padding: 10,
      maxHeight: 90,
      alignItems: 'flex-start'
    },
    scrollbarRoot: {
      height: '85px !important',
      maxHeight: 'calc(100vh - 100px)'
    },
    navigationSubMegaMenu: {
      padding: '20px'
    },
    subItemRow: {
      gridColumn: '1/3',
      display: 'flex',
      gridGap: 6
    },
    actionBtnThemeIcon: {
      padding: '3px 0'
    },
    drawerIcon: {
      width: 55
    },
    icon: {
      marginRight: 16
    },
    [breakpoints.down('sm')]: {
      drawerIcon: {
        width: 45
      },
      navigationSubMegaMenu: {
        padding: 10
      },
      scrollbarRoot: {
        height: '64px !important'
      }
    },
    [breakpoints.only('md')]: {
      drawerIcon: {
        width: 48
      },
      navigationSubMegaMenu: {
        padding: 13
      },
      scrollbarRoot: {
        height: '70px !important'
      }
    },
    [breakpoints.down('lg')]: {
      listItem: {
        padding: '10px 5px'
      },
      icon: {
        marginRight: 5
      },
      text: {
        fontSize: '12px'
      }
    }
  }
}

const dynamicStyles = makeStyles({
  listContainer: {
    gridTemplateColumns: ({ navCols }) =>
      `repeat(${navCols},calc((100% - 12px)/${navCols}))`
  },
  text: {
    color: ({ navColor }) => navColor + ' !important'
  },
  scrollbarRoot: ({ navCols, breakpoints }) => ({
    width: `${280 * navCols}px !important`,
    [breakpoints.down('sm')]: {
      width: `${170 * navCols}px !important`
    }
  })
})

const dropdownFadeTransition = 500

const Drawer = ({
  t,
  classes,
  customClass,
  color = colors.highlight,
  currentTheme,
  handleThemeChange,
  theme
}) => {
  const [hidingDropdown, setHidingDropdown] = useState(false)
  const [isHovering, setHoverState] = useState(false)
  const [themeHoverCard, setThemeHoverCard] = useState({
    height: 46,
    transform: 'translate(0px, 0px)'
  })
  const xsBreakpint = useMediaQuery(theme.breakpoints.down('sm'))

  const dynamicClasses = dynamicStyles({
    navColor: color,
    navCols: 2,
    ...theme
  })

  const onMouseOver = useCallback(
    (index, subIndex = -1) => {
      if (subIndex >= 0) {
        const x = (subIndex % 3) * (xsBreakpint ? 105 : 170)
        setThemeHoverCard({
          height: 46,
          width: xsBreakpint ? 105 : 170,
          transform: `translate(${x}px, 0px)`
        })
        return
      }
    },
    [xsBreakpint]
  )

  const themeMenuItems = useMemo(() => {
    return [
      {
        type: 'row',
        render: true,
        items: [
          {
            onLinkClick: () => handleThemeChange('light', true),
            text: t(`Light Theme`),
            theme: 'light',
            icon: (
              <Brightness4
                style={{ color }}
                className={classes.actionBtnThemeIcon}
              />
            ),
            closeDropDownOnClick: false
          },
          {
            onLinkClick: () => handleThemeChange('dark', true),
            text: t(`Dark Theme`),
            theme: 'dark',
            icon: (
              <Brightness4
                style={{ color }}
                className={classes.actionBtnThemeIcon}
              />
            ),
            closeDropDownOnClick: false
          },
          {
            onLinkClick: () => handleThemeChange('auto', true),
            text: t(`Auto Theme`),
            theme: 'auto',
            icon: (
              <BrightnessAuto
                style={{ color }}
                className={classes.actionBtnThemeIcon}
              />
            ),
            closeDropDownOnClick: false
          }
        ]
      }
    ]
  }, [t, classes.actionBtnThemeIcon, handleThemeChange, color])

  const onResetThemeMouseHover = useCallback(() => {
    setThemeHoverCard({
      height: '0px',
      transform: `translate(0px, 0px)`
    })
  }, [])

  const setDefaultMouseHoverPosition = useCallback(() => {
    const themeSubIndex = themeMenuItems[0].items.findIndex(
      item => item.theme === currentTheme
    )

    if (currentTheme) {
      onMouseOver(null, themeSubIndex)
    } else {
      onResetThemeMouseHover()
    }
  }, [onMouseOver, onResetThemeMouseHover, currentTheme, themeMenuItems])

  useEffect(() => {
    setDefaultMouseHoverPosition()
  }, [onMouseOver, currentTheme, setDefaultMouseHoverPosition])

  const onLinkClick = useCallback(
    (onClick, closeDropDown, isLink) => e => {
      !isLink && e.preventDefault()
      onClick && onClick()
      if (closeDropDown) {
        setHidingDropdown(true)
        setTimeout(() => {
          setHidingDropdown(false)
        }, 2 * dropdownFadeTransition)
      }
    },
    []
  )

  const renderListItem = useCallback(
    ({
      type,
      text,
      index,
      url,
      subIndex,
      printEnterprise,
      onClick,
      closeDropDownOnClick,
      icon,
      description
    }) => {
      const onMouseOverByType = () => {
        return type === 'row'
          ? onMouseOver(index, subIndex)
          : onMouseOver(index)
      }
      return (
        <ListItem
          key={text + index}
          className={classNames(classes.listItem)}
          component={RouterLink}
          to={url || '#'}
          onClick={onLinkClick(onClick, closeDropDownOnClick, !!url)}
          disableGutters
          onMouseOver={() => onMouseOverByType(type)}
        >
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          <ListItemText
            classes={{
              root: classes.textRoot,
              primary: classNames(classes.text, dynamicClasses.text),
              secondary: classes.description
            }}
            primary={text}
            secondary={
              <>
                {description && description}
                {printEnterprise && <b> ({t('Enterprise')}).</b>}
              </>
            }
          />
        </ListItem>
      )
    },
    [classes, dynamicClasses, onMouseOver, t, onLinkClick]
  )

  return (
    <div
      className={classNames(classes.iconButtonWrapper, customClass)}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <DropdownHover
        dropSide="bottomRight"
        forceHidden={hidingDropdown}
        menuContainerClassName={classes.navigationSubMenu}
        dropdownContainerClassName={classes.dropdownContainer}
        buttonWrapClassName={classes.dropdownButton}
        ButtonComponent={
          <IconButton className={classes.drawerIcon}>
            <DrawerIcon hovering={isHovering} />
          </IconButton>
        }
        MenuComponent={
          <Scrollbars
            className={classNames(
              classes.scrollbarRoot,
              dynamicClasses.scrollbarRoot
            )}
            onMouseLeave={setDefaultMouseHoverPosition}
          >
            <div className={classes.navigationSubMegaMenu}>
              <List
                onMouseLeave={setDefaultMouseHoverPosition}
                component="nav"
                className={classNames(
                  classes.listContainer,
                  dynamicClasses.listContainer
                )}
              >
                <div
                  className={classes.navigationHoverCard}
                  style={{
                    ...(themeHoverCard.width
                      ? { width: themeHoverCard.width }
                      : {}),
                    height: themeHoverCard.height,
                    transform: themeHoverCard.transform
                  }}
                />
                <HoverMenuList
                  classes={classes}
                  menuItems={themeMenuItems}
                  renderListItem={renderListItem}
                />
              </List>
            </div>
          </Scrollbars>
        }
      />
    </div>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(withTheme(Drawer))
)
