import React, { useMemo } from 'react'
import { Table, TableBody, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { TableLibraryHead } from 'components/TableLibrary'
import ScheduleSimpleTableRow from 'components/CommonRows/ScheduleSimpleTableRow'
import { Text } from 'components/Typography'
import MessageCard from 'components/Card/MessageCard'

const styles = ({ type, colors }) => ({
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  },
  subtitle: {
    marginBottom: 10
  }
})

const initialColumns = [
  { id: 'title', label: 'Title' },
  { id: 'workingDays', label: 'Days', align: 'center' },
  { id: 'time', label: 'Time', align: 'center' }
]

const columnWidth = {
  title: 200,
  workingDays: 300,
  time: 150
}

const MultipleScheduleAssociationContent = ({ error, classes, t }) => {
  const schedules = useMemo(() => error.data, [error])

  return (
    <>
      <Text color="light" weight="bold" rootClassName={classes.subtitle}>
        {t(
          'This new schedule cannot be assigned to the device due to time overlap with an existing schedule.'
        )}
      </Text>

      <Table className={classes.table}>
        <TableLibraryHead
          noType={true}
          editRows={false}
          disableSort
          rowCount={schedules.length}
          columns={initialColumns}
          columnWidth={columnWidth}
          headerCellClassName={classes.tableHeaderCell}
        />
        <TableBody>
          {schedules.map(row => (
            <ScheduleSimpleTableRow data={row} key={row.id} />
          ))}
        </TableBody>
      </Table>

      <MessageCard
        icon="fa-regular fa-circle-info"
        message={t(
          'Only one schedule can be assigned to the device for a specific time period. New schedules cannot over-ride existing when schedules are assigned to a device-group.'
        )}
        marginBottom
      />
    </>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(MultipleScheduleAssociationContent)
)
