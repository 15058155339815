import React, { forwardRef } from 'react'
import { withStyles, Grid } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'
import classNames from 'classnames'
import FormControlLabel from 'components/Form/FormControlLabel'

const styles = ({
  palette,
  type,
  colors,
  typography,
  shapes,
  fontSize,
  lineHeight,
  fontWeight,
  formControls,
  spacing
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: shapes.height.primary,
    borderRadius: 4,
    padding: '0px 6px',
    paddingLeft: formControls.input.paddingLeft,
    border: `1px solid ${palette[type].formControls.input.border}`,
    background: palette[type].formControls.input.background,
    color: palette[type].formControls.input.color,
    fontFamily: typography.fontFamily,
    fontSize: fontSize.primary,
    lineHeight: lineHeight.primary,
    fontWeight: fontWeight.normal,

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  error: {
    borderColor: colors.error
  },
  disabled: {
    color: palette[type].formControls.disabled.color,
    background: palette[type].formControls.disabled.background,

    '&:focus': {
      border: `1px solid ${palette[type].formControls.input.border}`,
      boxShadow: 'initial'
    }
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: '0px !important',
    marginRight: 6,
    fontSize: '20px'
  },
  noLabel: {
    top: '20%'
  },
  marginBottom: {
    marginBottom: spacing(2)
  },
  clearIcon: {
    position: 'absolute',
    top: '50%',
    fontSize: '20px',
    right: 30,
    cursor: 'pointer'
  }
})

const CustomInputDatePicker = (
  {
    label,
    value,
    onClick,
    classes,
    alert,
    disabled,
    isOptional,
    rootClassName = '',
    disabledClassName = '',
    iconClassName = '',
    marginBottom,
    isClearable = false,
    onClear
  },
  ref
) => (
  <Grid
    container
    direction="column"
    ref={ref}
    className={classNames({
      [classes.marginBottom]: marginBottom
    })}
  >
    <FormControlLabel isOptional={isOptional} error={alert} label={label} />
    <button
      className={classNames(classes.root, rootClassName, {
        [classes.error]: alert,
        [classes.disabled]: disabled,
        [disabledClassName]: disabled
      })}
      onClick={onClick}
      type="button"
    >
      {value}
      {value && isClearable && (
        <ClearIcon
          className={classNames(classes.clearIcon, {
            [classes.noLabel]: !label,
            [classes.icon]: !iconClassName
          })}
          onClick={onClear}
        />
      )}
      {iconClassName && (
        <i
          className={classNames(iconClassName, classes.icon, {
            [classes.noLabel]: !label
          })}
        />
      )}
    </button>
  </Grid>
)

export default withStyles(styles)(forwardRef(CustomInputDatePicker))
