import React, { forwardRef, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { Avatar, withStyles, withTheme } from '@material-ui/core'
import classNames from 'classnames'

const styles = ({ type, palette }) => ({
  placeholder: {
    position: 'absolute',
    height: 45,
    width: 45,
    backgroundColor: palette[type].loader.background,
    foregroundColor: palette[type].loader.foreground,
    zIndex: 2,
    borderRadius: '50%',
    top: 0,
    left: 0
  },
  avatar: {
    width: 45,
    height: 45,
    cursor: 'pointer'
  },
  selected: {
    boxShadow: '0 0 0 3px green',
    padding: '3px'
  },
  avatarWrapper: {
    position: 'relative'
  }
})

const AvatarWithPlaceholder = forwardRef(
  ({ classes, index, avatar, setShowTooltip, selected, setSelected }, ref) => {
    const [loading, setLoading] = useState(true)

    const handleLoadCompleted = () => {
      setLoading(false)
    }

    return (
      <div className={classes.avatarWrapper}>
        <Avatar
          ref={ref}
          onMouseOver={() => {
            setShowTooltip(index)
          }}
          onMouseOut={() => {
            setShowTooltip(false)
          }}
          src={avatar.profile}
          className={classNames(classes.avatar, {
            [classes.selected]: selected === avatar.id
          })}
          onClick={() => setSelected(avatar.id)}
          alt={`Default avatar ${index}`}
          onLoad={handleLoadCompleted}
          onError={handleLoadCompleted}
        />

        {loading && (
          <ContentLoader className={classes.placeholder}>
            <rect x="0" y="0" width="100%" height="100%" />
          </ContentLoader>
        )}
      </div>
    )
  }
)

export default withStyles(styles)(withTheme(AvatarWithPlaceholder))
