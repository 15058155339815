import { createSelector } from 'reselect'

const _loadedFontsSelectors = ({ fonts }) => fonts.loadedFonts
const _fontsSelectors = ({ fonts }) => fonts.library
const _fontOptionsSelectors = ({ fonts }) => fonts.fontOptions

export const loadedFontsSelector = createSelector(
  _loadedFontsSelectors,
  loadedFonts => loadedFonts
)

export const fontsSelector = createSelector(_fontsSelectors, fonts => fonts)

export const fontOptionsSelector = createSelector(
  _fontOptionsSelectors,
  fontOptions => fontOptions
)
