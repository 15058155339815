import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'

import IconStack from 'components/Icons/IconStack'

const useStyles = makeStyles(() => ({
  smallIcon: {
    lineHeight: 'initial',
    top: '-11px',
    right: '-7px'
  }
}))

const EmailSettingsIcon = ({ wrapperStyles, generalStyles }) => {
  const classes = useStyles()

  return (
    <IconStack
      mainClassName="fa-regular fa-envelope"
      secondClassName={classNames('fa-solid fa-gear', classes.smallIcon)}
      wrapperStyles={wrapperStyles}
      generalStyles={generalStyles}
    />
  )
}

export default EmailSettingsIcon
