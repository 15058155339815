import React, { memo, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker'
import classNames from 'classnames'

import MaterialPopup from 'components/Popup/MaterialPopup'
import { rgbToRgba } from 'utils/rgbaToRgb'
import { COLOR_PICKER_MODAL_ID } from './constants'

const useStyles = makeStyles(({ typography, colors }) => ({
  colorPickerRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '320px !important',
    padding: '13px',
    '& input': {
      fontFamily: `${typography.fontFamily} !important`,
      color: `${colors.light} !important`
    },
    '& div': {
      fontFamily: `${typography.fontFamily} !important`,
      color: `${colors.light} !important`
    }
  }
}))

const ColorPickerModal = ({
  trigger,
  on = 'click',
  placement = 'top',
  onChange,
  value,
  onChangeDelay = 500,
  hideInputs = false,
  hideEyeDrop = true,
  hideAdvancedSliders = true,
  hideColorGuide = true,
  hidePresets = false,
  hideInputType = true,
  hideColorTypeBtns = false,
  hideControls = false,
  hideOpacity = false,
  disablePortal = false,
  presets,
  materialRootClass,
  rootClass = '',
  changeOnClose
}) => {
  const _classes = useStyles()
  const [color, setColor] = useState(value)

  useEffect(() => {
    setColor(value)
  }, [value])

  const { getGradientObject, isGradient } = useColorPicker(color, setColor)

  const debounced = useDebouncedCallback(() => {
    const data = {
      color: color,
      gradientObject: getGradientObject(),
      isGradient: isGradient
    }

    onChange(data)
  }, onChangeDelay)

  const handleChange = useCallback(
    async color => {
      try {
        await setColor(rgbToRgba(color).replaceAll(' ', ''))
      } catch (e) {
        await setColor(color)
      }
      if (!changeOnClose) {
        debounced.callback()
      }
    },
    [debounced, changeOnClose]
  )

  return (
    <MaterialPopup
      disablePortal={disablePortal}
      placement={placement}
      on={on}
      trigger={trigger}
      hasArrow={false}
      id={COLOR_PICKER_MODAL_ID}
      rootClassName={materialRootClass}
      {...(changeOnClose && { onClose: () => debounced.callback() })}
    >
      <ColorPicker
        className={classNames(_classes.colorPickerRoot, rootClass)}
        hideInputs={hideInputs}
        hideEyeDrop={hideEyeDrop}
        hideAdvancedSliders={hideAdvancedSliders}
        hideColorGuide={hideColorGuide}
        hidePresets={hidePresets}
        hideInputType={hideInputType}
        hideColorTypeBtns={hideColorTypeBtns}
        hideControls={hideControls}
        value={color}
        onChange={handleChange}
        presets={presets}
        hideOpacity={hideOpacity}
      />
    </MaterialPopup>
  )
}
ColorPickerModal.propTypes = {
  on: PropTypes.oneOf(['click', 'hover']),
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  onChangeDelay: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  hideInputs: PropTypes.bool,
  hideEyeDrop: PropTypes.bool,
  hideAdvancedSliders: PropTypes.bool,
  hideColorGuide: PropTypes.bool,
  hidePresets: PropTypes.bool,
  hideInputType: PropTypes.bool,
  hideColorTypeBtns: PropTypes.bool,
  hideControls: PropTypes.bool,
  disablePortal: PropTypes.bool,
  presets: PropTypes.array,
  changeOnClose: PropTypes.bool
}

export default memo(ColorPickerModal)
