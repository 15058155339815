import React, { memo } from 'react'
import { CircularProgress, useMediaQuery, withStyles } from '@material-ui/core'
import classNames from 'classnames'

import { FabBlueButton } from 'components/Buttons'

function styles({ spacing }) {
  return {
    submitButton: {
      minWidth: 120,
      borderRadius: 4,
      width: '100%',
      padding: '0 40px',
      height: 40
    },
    submitButtonSm: {
      padding: spacing(0, 2)
    },
    circularProgress: {
      width: '20px !important',
      height: '20px !important',
      color: '#fff'
    }
  }
}

function createAttrs(className, opaque, type, variant, onClick, disabled) {
  return {
    className,
    opaque,
    type,
    variant,
    onClick,
    disabled
  }
}

function SubmitButton({
  classes,
  opaque,
  onClick,
  isLoading,
  disabled,
  rootClass = '',
  children
}) {
  const matchesSm = useMediaQuery('(max-width:800px)')

  const attrs = createAttrs(
    classNames(classes.submitButton, rootClass, {
      [classes.submitButtonSm]: matchesSm
    }),
    opaque,
    'submit',
    'extended',
    onClick,
    disabled
  )

  if (isLoading) {
    return (
      <FabBlueButton {...attrs} disabled>
        <CircularProgress className={classes.circularProgress} />
      </FabBlueButton>
    )
  }

  return <FabBlueButton {...attrs}>{children}</FabBlueButton>
}

export default memo(withStyles(styles)(SubmitButton))
