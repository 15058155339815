import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getClientPackages } from 'actions/clientPackagesActions'
import { BIG_NUMBER } from 'constants/library'

const useClientPackagesOptions = () => {
  const [fetched, setFetched] = useState(false)
  const dispatch = useDispatch()

  const clientPackages = useSelector(
    ({
      clientPackage: {
        items: { response: clientPackages }
      }
    }) => clientPackages
  )

  const transformClientPackage = useMemo(
    () =>
      clientPackages.map(({ id: value, title: label }) => ({
        value,
        label
      })),
    [clientPackages]
  )

  const getFeatureIds = useCallback(
    findId => {
      const clientPackage = clientPackages.find(
        ({ id }) => id === Number.parseInt(findId)
      )

      return clientPackage ? clientPackage.feature.map(({ id }) => id) : []
    },
    [clientPackages]
  )

  const getFeatureSettings = useCallback(
    findId => {
      const clientPackage = clientPackages.find(
        ({ id }) => id === Number.parseInt(findId)
      )
      return clientPackage ? clientPackage.featureSetting : []
    },
    [clientPackages]
  )

  const meta = useSelector(
    ({
      clientPackage: {
        items: { meta }
      }
    }) => meta
  )

  useEffect(() => {
    if (meta.total > BIG_NUMBER && fetched) {
      dispatch(
        getClientPackages({
          limit: meta.total
        })
      )
    }
  }, [dispatch, fetched, meta.total])

  useEffect(() => {
    dispatch(
      getClientPackages({
        limit: BIG_NUMBER
      })
    )
    setFetched(true)
  }, [dispatch])

  return { values: transformClientPackage, getFeatureIds, getFeatureSettings }
}

export default useClientPackagesOptions
