import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'

import IconStack from 'components/Icons/IconStack'

const useStyles = makeStyles(() => ({
  smallIcon: {
    top: '-1px',
    right: '-3px'
  }
}))

const NetworkCloseIcon = ({
  wrapperClassName,
  generalStyles,
  mainClassName,
  secondClassName
}) => {
  const classes = useStyles()

  return (
    <IconStack
      mainClassName={classNames('fa-regular fa-globe', mainClassName)}
      wrapperClassName={wrapperClassName}
      generalStyles={generalStyles}
      secondClassName={classNames(
        'fa-solid fa-xmark',
        secondClassName,
        classes.smallIcon
      )}
    />
  )
}

export default NetworkCloseIcon
