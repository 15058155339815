import moment from 'moment/moment'

import { numberToString } from 'utils/numbers'
import {
  AM_PM_FORMAT,
  AM_PM_S_FORMAT,
  TIME_S_FORMAT
} from 'constants/dateTimeFormats'

export function convertMS(milliseconds) {
  let day, hour, minute, seconds
  seconds = Math.floor(milliseconds / 1000)
  minute = Math.floor(seconds / 60)
  seconds = seconds % 60
  hour = Math.floor(minute / 60)
  minute = minute % 60
  day = Math.floor(hour / 24)
  hour = hour % 24
  return {
    day: day,
    hour: hour,
    minute: minute,
    second: seconds
  }
}

export function secToMs(sec) {
  return sec * 1000
}

export function hoursToSec(hours) {
  return hours * 3600
}

export function hoursToMin(hours) {
  return hours * 60
}

export function minutesToHours(minutes) {
  return minutes / 60
}

export function minutesToSec(minutes, floor = false) {
  return floor ? Math.floor(minutes * 60) : minutes * 60
}

export function secToMin(sec, floor = false) {
  return floor ? Math.floor(sec / 60) : sec / 60
}

export function secToString(sec, seconds = true) {
  const { hour, minute, second } = convertMS(secToMs(sec))
  if (seconds) {
    return hmsToString(hour, minute, second)
  }
  return hmsToString(hour, minute)
}

export function stringToHms(str) {
  if (!str) {
    return []
  }
  return str.split(':').map(Number)
}

export function hmsToSec(hours, minutes, seconds) {
  return hoursToSec(hours) + minutesToSec(minutes) + seconds
}

export function hmsToString(...args) {
  return args.map(numberToString).join(':')
}

export function stringToSec(string = '00:00:00') {
  const [hours, minutes, seconds] = string.split(':')
  if (!seconds) {
    return hmsToSec(+hours, +minutes, 0)
  } else {
    return hmsToSec(+hours, +minutes, +seconds)
  }
}

export function secondsToDurationString(
  sec,
  options = {
    hourFormat: 'h',
    minuteFormat: 'm',
    secondFormat: 's',
    truncated: false,
    formatString: false
  }
) {
  const config = {
    hourFormat: 'h',
    minuteFormat: 'm',
    secondFormat: 's',
    ...options
  }
  const { day, hour, minute, second } = convertMS(secToMs(sec))

  if (!sec || Number.isNaN(sec)) {
    return 'N/A'
  }

  const d = day ? `${day}d` : ''
  const h = hour ? `${hour}${config.hourFormat}` : ''
  const m = minute ? `${minute}${config.minuteFormat}` : ''
  const s = second ? `${second}${config.secondFormat}` : ''

  if (config.truncated) {
    if (day >= 1) {
      return [d, h].join(' ')
    } else if (hour >= 1) {
      return [h, m].join(' ')
    } else {
      return [m, s].join(' ')
    }
  }
  if (config.formatString) {
    const durationDimension = [d, h, m, s].filter(dimension => {
      return dimension.length >= 1
    })
    return (
      durationDimension.slice(0, -1).join(', ') +
      (durationDimension.length > 1 ? ' and ' : '') +
      durationDimension.slice(-1)
    )
  } else {
    return [d, h, m, s].join(' ')
  }
}

const getMinuteOptions = () => {
  return Array.from({ length: 60 }, (_, i) => i + 1).map((time, idx) => ({
    index: idx,
    label: time,
    value: time
  }))
}

export const minuteOptions = getMinuteOptions()

export const getTimeOptions = ({ showSeconds = false }) => {
  const start = moment().set('hour', 0).set('minute', 0).set('second', 0)
  const options = [...Array(48)].map((_, index) => {
    const timeOption = index === 0 ? start : start.add(30, 'minutes')
    return {
      label: timeOption.format(showSeconds ? AM_PM_S_FORMAT : AM_PM_FORMAT),
      value: timeOption.format(showSeconds ? AM_PM_S_FORMAT : AM_PM_FORMAT)
    }
  })
  return [
    ...options,
    {
      label: moment('23:59:59', TIME_S_FORMAT).format(
        showSeconds ? AM_PM_S_FORMAT : AM_PM_FORMAT
      ),
      value: moment('23:59:59', TIME_S_FORMAT).format(
        showSeconds ? AM_PM_S_FORMAT : AM_PM_FORMAT
      )
    }
  ]
}

export const timeOptions = getTimeOptions({})
