import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles, Link } from '@material-ui/core'
import classNames from 'classnames'

import ErrorPage from './ErrorPage'
import { Text } from 'components/Typography'

import { ReactComponent as TimeoutImage } from './assets/timeoutError.svg'

const useStyles = makeStyles(theme => ({
  linkText: {
    cursor: 'pointer',
    color: 'white'
  },
  marginRight: {
    marginRight: 5
  }
}))

const ExpiredPassworldessLoginErrorPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <ErrorPage
      ImageComponent={TimeoutImage}
      title={t('Expired Link')}
      DescriptionComponent={({ className, ...props }) => (
        <Grid container item justifyContent="center">
          <Text
            rootClassName={classNames(className, classes.marginRight)}
            {...props}
          >
            {t(
              'The passwordless login link has expired. Please request a new link from the'
            )}
          </Text>
          <Link
            className={classNames(className, classes.linkText)}
            href="/sign-in"
          >
            {t('sign in page')}
          </Link>
          <Text rootClassName={className} {...props}>
            .
          </Text>
        </Grid>
      )}
      autoPing={false}
    />
  )
}

export default ExpiredPassworldessLoginErrorPage
