import { _get } from 'utils/lodash'

import { isString } from 'utils/generalUtils'

export function takeByTruth(obj = {}) {
  const copy = { ...obj }

  Object.keys(copy).forEach(key => {
    if (!copy[key]) delete copy[key]
  })

  return copy
}

export function getContentSourceId({ content_source_id }) {
  return content_source_id
}

export function getResponse({ response }) {
  return response
}

export function getError({ error }) {
  return error
}

export function getMessage(data) {
  if (isString(data)) {
    return _get(JSON.parse(data), 'message')
  } else if (data) {
    return data.message
  }
}

export function getException(data) {
  if (isString(data)) {
    return _get(JSON.parse(data), 'exception')
  } else if (data) {
    return data.exception
  }
}

export function getStatus({ status }) {
  return status
}

export function getData({ data }) {
  return data
}

export function getCode({ code }) {
  return code
}

export function getConfig({ config }) {
  return config
}

export function getErrors(data) {
  if (data) {
    return data.errors
  }
}

export function getAttributes({ attributes }) {
  return attributes
}

export function getId({ id }) {
  return id
}

export function getTitle({ title }) {
  return title
}

export function getMediaInfo({ mediaInfo }) {
  return mediaInfo
}

export function getCurrent({ current }) {
  return current
}

export function getValue({ value }) {
  return value
}

export function getName({ name }) {
  return name
}

export function getLogTime({ logTime }) {
  return logTime
}

export function getDeviceScreenConnection({ deviceScreenConnection }) {
  return deviceScreenConnection
}

export function getTarget({ target }) {
  return target
}

export function getMatch({ match }) {
  return match
}

export function getCurrentTab({ currentTab }) {
  return currentTab
}

export function getParams({ params }) {
  return params
}

export function getThemeId({ themeId }) {
  return themeId
}

export function getRoot({ root }) {
  return root
}

export function getContent({ content }) {
  return content
}

export function getHeader({ header }) {
  return header
}

export function getRadius({ radius }) {
  return radius
}

export function getSsoSetting({ sso }) {
  return sso?.ssoSetting
}

export function getFeature({ feature }) {
  return feature
}
