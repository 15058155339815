import { makeStyles } from '@material-ui/styles'

const useProgressStyle = makeStyles({
  wrap: {
    '& .uppy': {
      '&-ProgressBar': {
        position: 'absolute!important',
        top: 0,
        left: 0,
        width: '100%',
        height: 3,
        zIndex: 15,
        transition: 'height 0.2s',
        '&[aria-hidden=true]': {
          height: 0
        },
        '&-inner': {
          backgroundColor: '#2275d7',
          boxShadow: '0 0 10px rgba(34, 117, 215, 0.7)',
          height: '100%',
          width: 0,
          transition: 'width 0.4s ease'
        },
        '&-percentage': {
          display: 'none',
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#fff'
        }
      }
    }
  }
})

export default useProgressStyle
