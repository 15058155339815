import React from 'react'
import { Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const TextSizeTooltip = ({ maxTitleWidth, title, width = 150 }) => {
  return (
    <>
      {title && title.length >= maxTitleWidth ? (
        <Tooltip arrow title={title}>
          <Typography noWrap variant="inherit" style={{ width: width }}>
            {title}
          </Typography>
        </Tooltip>
      ) : (
        title
      )}
    </>
  )
}

export default TextSizeTooltip
