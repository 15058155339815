import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'

import { userProfileSelector } from 'selectors/userSelectors'
import { getUserProfileAction } from 'actions/userActions'
import usePermissions from './api/usePermissions'
import {
  ENTERPRISE_USER_PROFILE_IMAGE_READ,
  ORG_USER_PROFILE_IMAGE_READ,
  SYSTEM_USER_PROFILE_IMAGE_READ
} from 'constants/permissionNames'

const useUserProfile = () => {
  const dispatch = useDispatch()
  const { response } = useSelector(userProfileSelector)
  const { getPermissionByName } = usePermissions()

  const hasProfileImagePermissions = useMemo(
    () =>
      getPermissionByName(
        ORG_USER_PROFILE_IMAGE_READ,
        ENTERPRISE_USER_PROFILE_IMAGE_READ,
        SYSTEM_USER_PROFILE_IMAGE_READ
      ),
    [getPermissionByName]
  )

  useEffect(() => {
    if (hasProfileImagePermissions && !response) {
      dispatch(getUserProfileAction())
    }
    // eslint-disable-next-line
  }, [hasProfileImagePermissions])

  return useMemo(
    () => ({
      avatars: response,
      hasProfileImagePermissions
    }),
    [response, hasProfileImagePermissions]
  )
}

export default useUserProfile
