import * as types from 'actions'
import { putInitialState } from 'constants/initialLibraryState'

const initialState = {
  response: {},
  error: {},
  put: putInitialState
}

const ipProviders = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_IP_PROVIDERS_SUCCESS:
      return { ...state, response: action.payload }
    case types.GET_IP_PROVIDERS_ERROR:
      return { ...state, error: action.payload }
    case types.PUT_IP_PROVIDERS_SUCCESS:
      return { ...state, put: { ...putInitialState, response: action.payload } }
    case types.PUT_IP_PROVIDERS_ERROR:
      return { ...state, put: { ...putInitialState, error: action.payload } }
    case types.CLEAR_IP_PROVIDERS_RESPONSE:
      return { ...state, put: initialState.put }
    default:
      return state
  }
}

export default ipProviders
