import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { TableLibraryCell } from './index'
import { allergenIcons } from 'constants/menuMakerConstants'
import { Text } from '../Typography'

const TableLibraryAllergensCell = ({ allergens }) => {
  const { t } = useTranslation()

  const validAllergenIcons = allergenIcons(t).filter(({ name }) =>
    allergens?.includes(name)
  )

  return (
    <TableLibraryCell align="center">
      {allergens && validAllergenIcons?.length ? (
        validAllergenIcons.map(({ name, icon }, index) => (
          <Tooltip arrow key={index} title={name} placement="top">
            <img src={icon} alt={name} width={32} height={32} />
          </Tooltip>
        ))
      ) : (
        <Tooltip
          arrow
          title={t('Currently, allergens are unknown for this menu item')}
          placement="top"
        >
          <Text>{t('N/A')}</Text>
        </Tooltip>
      )}
    </TableLibraryCell>
  )
}

TableLibraryAllergensCell.propTypes = {
  allergens: PropTypes.string
}

export default TableLibraryAllergensCell
