import api from './api'

import { errorHandler, queryParamsHelper } from 'utils'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { isVueV3Preview } from 'utils/apiUtils'

const getBasePlaylistUrl = isPlaylist =>
  isPlaylist === 'Interactive'
    ? '/interactivePlaylist'
    : isPlaylist === 'Smart'
    ? '/smartplaylist'
    : '/playlist'

const addPlaylist = async (data, isPlaylist) => {
  try {
    return await api({
      method: 'POST',
      url: getBasePlaylistUrl(isPlaylist),
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const putPlaylistById = async ({ id, data }, isPlaylist) => {
  try {
    return await api({
      method: 'PUT',
      url: `${getBasePlaylistUrl(isPlaylist)}/${id}`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const deletePlaylistById = async ({ id, data }) => {
  try {
    await api({
      method: 'DELETE',
      url: `/playlist/${id}`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const deleteSelectedPlaylist = async ({ ids, data }) => {
  try {
    await api({
      method: 'DELETE',
      url: `/playlist/bulk`,
      params: {
        ids: ids.join(',')
      },
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}
const clonePlaylist = async data => {
  try {
    await api({
      method: 'POST',
      url: `/playlist/clone`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getPlaylistById = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/playlist/${id}`
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getItems = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/playlist',
      params: queryParamsHelper(
        params,
        ['tag', 'group', 'mediaFeatureNames', 'status'],
        [],
        ['playlistType']
      )
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getPreference = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/preference',
      params: {
        entity: 'PlaylistLibrary'
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putPreference = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/preference/PlaylistLibrary',
      data: {
        recordsPerPage: 10,
        gridColumn: data
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGroupItems = async (id, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/playlist/group/${id}`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postGroupItem = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/playlist/group/${data.groupId}`,
      data: {
        playlistId: data.playlistId
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteGroupItem = async data => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/playlist/group/${data.groupId}/${data.playlistId}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getPreview = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/playlist/preview/${id}`,
      params: { ...(isVueV3Preview() && { vue3: 1 }) },
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const generatePreview = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `${getBasePlaylistUrl(data.playlistType)}/preview`,
      data,
      params: { ...(isVueV3Preview() && { vue3: 1 }) },
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const sharePlaylist = async ({ playlistId, data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/playlist/${playlistId}/share`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const copyPlaylist = async data => {
  const { id, impersonated, ...rest } = data
  try {
    const response = await api({
      method: 'POST',
      url: `/playlist/${id}/copy`,
      data: rest,
      ...(impersonated && getApiParamsByOriginalUser(`/playlist/${id}/copy`))
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getPlaylistAssociations = async (id, params) => {
  try {
    return await api({
      method: 'GET',
      url: `/playlist/${id}/association`,
      params
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const postPlaylistBulk = async ({ playlistIds, data }) => {
  try {
    await api({
      method: 'POST',
      url: `/playlist/bulkAssignGroupsAndTags`,
      data: {
        playlistIds,
        ...data
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const playlistService = {
  addPlaylist,
  putPlaylistById,
  getPlaylistById,
  getItems,
  getPreference,
  putPreference,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  deletePlaylistById,
  deleteSelectedPlaylist,
  clonePlaylist,
  getPreview,
  generatePreview,
  sharePlaylist,
  copyPlaylist,
  getPlaylistAssociations,
  postPlaylistBulk
}

export default playlistService
