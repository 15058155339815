/* global google */
import { Paper, withStyles } from '@material-ui/core'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Marker, InfoWindow } from '@react-google-maps/api'
import { withTranslation } from 'react-i18next'

import deviceActiveIcon from 'common/assets/icons/device-active.svg'
import deviceInactiveIcon from 'common/assets/icons/device-inactive.svg'
import MarkerInfoContent from './MarkerInfoContent'
import { getMostPopulatedRegion } from 'utils/media/mapUtils'
import { LIGHT } from 'theme'
import GoogleMapWrapper from 'components/GoogleMapWrapper'

const styles = theme => {
  const { palette, type, colors } = theme
  return {
    root: {
      padding: '22px 32px',
      borderRadius: '6px',
      boxShadow: `-2px 0 4px 0 ${palette[type].card.shadow}`,
      position: 'relative',
      minHeight: '390px',
      overflow: 'hidden'
    },

    map: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,

      '& .content-auto': {
        padding: '10px'
      },
      '& .gm-ui-hover-effect': {
        display: 'none !important'
      },

      '& .gm-style-iw': {
        maxHeight: '350px !important',
        background: colors.background.primary[type],
        padding: '0',
        minHeight: '280px'
      },

      '& .gm-style-iw-d': {
        background: colors.background.primary[type]
      },

      '& .gm-style-iw-d::-webkit-scrollbar-track-piece': {
        background: colors.background.primary[type]
      },

      '& .gm-style-iw-d::-webkit-scrollbar-thumb': {
        backgroundColor:
          (type === LIGHT ? 'rgba(0,0,0,0.12)' : 'rgba(255,255,255,0.12)') +
          ' !important',
        minHeight: '65px'
      }
    },
    hidden: {
      border: 'none',
      background: 'none'
    }
  }
}

const MAX_POPUP_HEIGHT = 400
// button prevents content from being dragged
const MapsCard = ({ classes, rootClassName = '', markers = [] }) => {
  const [pixelOffset, setPixelOffset] = React.useState(null)
  const [selected, setSelected] = useState(null)
  const infoOptions = useMemo(
    () => ({
      ...(pixelOffset && { pixelOffset }),
      maxWidth: 400,
      minWidth: 350
    }),
    [pixelOffset]
  )

  const infoWindowRef = useRef()

  const { center, zoom } = useMemo(() => getMostPopulatedRegion(markers), [
    markers
  ])

  const selectedDevices = useMemo(() => {
    return selected
      ? markers
          .filter(
            ({ lat, long }) => lat === selected.lat && long === selected.long
          )
          .map(({ device }) => device)
      : []
  }, [selected, markers])

  const handleMarkerClick = useCallback(m => {
    setSelected(m)
  }, [])

  const handleMarkerSetting = useCallback(() => {
    const popupContainer =
      infoWindowRef?.current?.containerElement?.parentElement
    const cards = infoWindowRef?.current?.containerElement?.children[1]
    const cardsCount = cards?.childElementCount
    const firstCardElHeight = cards?.children[0]?.clientHeight
    if (popupContainer) {
      if (cardsCount === 1) {
        popupContainer.style.minHeight = `${Math.min(
          firstCardElHeight + 48,
          MAX_POPUP_HEIGHT
        )}px`
      } else {
        popupContainer.style.minHeight = `${Math.min(
          firstCardElHeight + 38,
          MAX_POPUP_HEIGHT
        )}px`
      }
    }
  }, [])

  return (
    <Paper className={[classes.root, rootClassName].join(' ')}>
      <button className={classes.hidden}>
        <GoogleMapWrapper
          center={center}
          zoom={zoom}
          mapContainerClassName={classes.map}
          onLoad={() => {
            if (window.google?.maps) {
              setPixelOffset(new window.google.maps.Size(0, -15))
            }
          }}
          onClick={() => setSelected(null)}
        >
          {markers.map((m, index) => (
            <Marker
              key={index}
              icon={{
                url:
                  m.device.status === 'Active'
                    ? deviceActiveIcon
                    : deviceInactiveIcon,
                scaledSize: google?.maps?.hasOwnProperty('Size')
                  ? new google.maps.Size(32, 32)
                  : {
                      width: 32,
                      height: 32
                    }
              }}
              position={{ lat: m.lat, lng: m.long }}
              animation={
                google?.maps?.hasOwnProperty('Animation') &&
                google.maps.Animation.DROP
              }
              onClick={() => handleMarkerClick(m)}
              onMouseOver={() => handleMarkerClick(m)}
            />
          ))}
          {selected && (
            <InfoWindow
              ref={infoWindowRef}
              position={{
                lat: selected.lat,
                lng: selected.long
              }}
              onDomReady={handleMarkerSetting}
              onPositionChanged={handleMarkerSetting}
              options={infoOptions}
            >
              <MarkerInfoContent
                paddingBottom={selectedDevices?.length > 1 ? 1.5 : 0}
                devices={selectedDevices}
              />
            </InfoWindow>
          )}
        </GoogleMapWrapper>
      </button>
    </Paper>
  )
}

export default withTranslation('translations')(
  withStyles(styles, { withTheme: true })(MapsCard)
)
