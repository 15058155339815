export const paginationViews = {
  listView: 'listView',
  gridView: 'gridView',
  mediaGrid: 'mediaGrid',
  locationView: 'locationView',
  tagGrid: 'tagGrid',
  scheduleTimeline: 'scheduleTimeline'
}

export const paginationValuesByView = {
  [paginationViews.listView]: [5, 10, 25, 50, 100],
  [paginationViews.gridView]: [4, 12, 24, 48, 100],
  [paginationViews.mediaGrid]: [6, 12, 18, 48, 96],
  [paginationViews.locationView]: [4, 12, 24, 48, 100],
  [paginationViews.tagGrid]: [50, 100],
  [paginationViews.scheduleTimeline]: [10, 15, 20, 25]
}

export const systemPaginationValuesByView = {
  [paginationViews.listView]: [5, 10, 25, 50, 100, 250],
  [paginationViews.gridView]: [4, 12, 24, 48, 100, 240],
  [paginationViews.mediaGrid]: [6, 12, 18, 48, 96, 240],
  [paginationViews.locationView]: [4, 12, 24, 48, 100, 240],
  [paginationViews.tagGrid]: [50, 100, 250],
  [paginationViews.scheduleTimeline]: [10, 15, 20, 25]
}

export const MEDIA_DEFAULT_GRID_LIMIT = 18

export const viewTypes = {
  TABLE: 'table',
  GRID: 'grid',
  LOCATION: 'location'
}

export const MAX_LOCATION_NESTING_LEVEL = 7
export const DEFAULT_LOCATION_NAME = 'Location'
export const DEFAULT_LOCATION_ICON = 'fa-regular fa-folders'
export const DEFAULT_LOCATION_DESCRIPTION =
  'Choose a description to meaningfully describe this location'
export const UNGROUPED_LOCATION_ID = '0'
export const DEFAULT_LOCATION_NAME_COLOR = 'rgba(116, 128, 154, 1)'
export const PLACEHOLDER_LOCATION_NAME_COLOR = 'rgba(116, 128, 154, 0.62)'

export const defaultLocationNames = [
  'City',
  'Building',
  'Floor',
  'Department',
  'Station',
  'Location',
  'Sublocation',
  ...new Array(MAX_LOCATION_NESTING_LEVEL).fill(DEFAULT_LOCATION_NAME)
]

export const defaultLocationIcons = [
  'fa-regular fa-buildings',
  'fa-regular fa-building',
  'fa-regular fa-elevator',
  'icon-sign-transfer',
  'fa-regular fa-table-layout',
  'fa-regular fa-map-location-dot',
  'icon-location-pin-home-2',
  ...new Array(MAX_LOCATION_NESTING_LEVEL).fill(DEFAULT_LOCATION_ICON)
]

export const defaultLocationDescriptions = [
  'Our world HQ, located in the smartest city of all',
  'Situated on the corner of Main Street and Franklin Blvd',
  '13th floor, restricted to office personnel only',
  'HR & Marketing, focused on ensuring team communication matters',
  'Smoothie counter, open from noon to 5pm everyday',
  'Choose a description to meaningfully describe this location',
  ...new Array(MAX_LOCATION_NESTING_LEVEL).fill(DEFAULT_LOCATION_DESCRIPTION)
]

export const preferenceStatus = {
  NOT_RECEIVED: 'not received',
  RECEIVED: 'received',
  EMPTY: 'empty',
  ERROR: 'error'
}

export const filterStatuses = {
  any: 'any',
  active: 'Active',
  inactive: 'Inactive',
  disabled: 'Disabled'
}

export const viewTypesKeys = {
  Media: 'MediaLibrary',
  Playlist: 'PlaylistLibrary',
  Template: 'TemplateLibrary',
  Schedule: 'ScheduleLibrary',
  Device: 'DeviceLibrary'
}

export const locationPlaceholders = [
  {
    id: -1,
    name: 'City',
    icon: 'icon-building-1',
    parentLocation: null,
    childLocations: [-2]
  },
  {
    id: -2,
    name: 'Location',
    icon: 'icon-location-pin-home-2',
    parentLocation: -1,
    childLocations: [-3]
  },
  {
    id: -3,
    name: 'Building',
    icon: 'icon-building-5',
    parentLocation: -2,
    childLocations: []
  },
  {
    id: -4,
    name: 'High School 1',
    icon: 'icon-building-5',
    parentLocation: null,
    childLocations: [-5, -6]
  },
  {
    id: -5,
    name: 'Cafetaria',
    icon: 'icon-coffee-cup-1',
    parentLocation: -4,
    childLocations: []
  },
  {
    id: -6,
    name: 'Rec Room A',
    icon: 'icon-book-shelf',
    parentLocation: -4,
    childLocations: [-7]
  },
  {
    id: -7,
    name: 'Upstairs',
    icon: 'icon-move-up-1',
    parentLocation: -6,
    childLocations: []
  },
  {
    id: -8,
    name: 'Campus',
    icon: 'icon-location-map-pin',
    parentLocation: null,
    childLocations: [-9]
  },
  {
    id: -9,
    name: 'Building',
    icon: 'icon-building-5',
    parentLocation: -8,
    childLocations: [-10]
  },
  {
    id: -10,
    name: 'Floor',
    icon: 'icon-content-view-agenda',
    parentLocation: -9,
    childLocations: [-11]
  },
  {
    id: -11,
    name: 'Room',
    icon: 'icon-business-whiteboard',
    parentLocation: -10,
    childLocations: []
  },
  {
    id: -12,
    name: 'City',
    icon: 'icon-building-1',
    parentLocation: null,
    childLocations: [-13, -16]
  },
  {
    id: -13,
    name: 'Mall A',
    icon: 'icon-building-5',
    parentLocation: -12,
    childLocations: [-14, -15]
  },
  {
    id: -14,
    name: 'Store Front',
    icon: 'icon-picture-layer-2',
    parentLocation: -13,
    childLocations: []
  },
  {
    id: -15,
    name: 'Stock Room',
    icon: 'icon-box-2',
    parentLocation: -13,
    childLocations: []
  },
  {
    id: -16,
    name: 'Mall B',
    icon: 'icon-building-5',
    parentLocation: -12,
    childLocations: [-17, -18]
  },
  {
    id: -17,
    name: 'Store Front',
    icon: 'icon-picture-layer-2',
    parentLocation: -16,
    childLocations: []
  },
  {
    id: -18,
    name: 'Stock Room',
    icon: 'icon-box-2',
    parentLocation: -16,
    childLocations: []
  }
]
