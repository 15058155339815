import { createSelector } from 'reselect'

function _deviceHealthSelector(state) {
  return state.new.device.health
}

function _deviceTriggerHealthSelector(state) {
  return state.new.device.triggerHealth
}

function _deviceRefreshHealthSelector(state) {
  return state.new.device.refreshHealth
}

function _devicePreviewsSelector(state) {
  return state.new.device.previews
}

function _devicePreviewSelector(state) {
  return state.new.device.preview
}

function _devicePreviewModalSelector(state) {
  return state.new.device.previewModal
}

export const deviceHealthSelector = createSelector(
  _deviceHealthSelector,
  health => health
)

export const deviceTriggerHealthSelector = createSelector(
  _deviceTriggerHealthSelector,
  triggerHealth => triggerHealth
)

export const deviceRefreshHealthSelector = createSelector(
  _deviceRefreshHealthSelector,
  refreshHealth => refreshHealth
)

export const devicePreviewsSelector = createSelector(
  _devicePreviewsSelector,
  previews => previews
)

export const devicePreviewSelector = createSelector(
  _devicePreviewSelector,
  preview => preview
)

export const devicePreviewModalSelector = createSelector(
  _devicePreviewModalSelector,
  previewModal => previewModal
)
