import React from 'react'
import classNames from 'classnames'
import { CheckboxSelectAll } from '../Checkboxes'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'

const styles = () => {
  return {
    tableFooterCheckboxSelectAll: {
      marginRight: '10px'
    },
    disabled: {
      '& span svg': {
        fill: '#f5f3f0'
      }
    }
  }
}

const TableLibrarySelectAllCheckbox = ({
  isSelectAllDisabled = false,
  allSelected = false,
  onSelectAllClick = f => f,
  classes
}) => {
  return (
    <CheckboxSelectAll
      disabled={isSelectAllDisabled}
      className={classNames(classes.tableFooterCheckboxSelectAll, {
        [classes.disabled]: isSelectAllDisabled
      })}
      indeterminate={false}
      checked={allSelected}
      onChange={onSelectAllClick}
    />
  )
}

TableLibrarySelectAllCheckbox.propTypes = {
  allSelected: PropTypes.bool,
  isSelectAllDisabled: PropTypes.bool,
  onSelectAllClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
}

export default withStyles(styles)(TableLibrarySelectAllCheckbox)
