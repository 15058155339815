import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Spacing from 'components/Containers/Spacing'

const MessageWithList = ({ message, list, limit = 10 }) => {
  const { t } = useTranslation()

  return (
    <Spacing variant={0}>
      <div>{message}</div>
      <Spacing variant={0} paddingTop={1}>
        {list.slice(0, limit).map((item, index) => (
          <div key={item + index}>
            <b>{item}</b>
          </div>
        ))}
        {list.length > limit && (
          <div>{t('And count more', { count: list.length - limit })}</div>
        )}
      </Spacing>
    </Spacing>
  )
}

MessageWithList.propTypes = {
  message: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  limit: PropTypes.number
}

export default MessageWithList
