import api from 'services/api'
import { errorHandler } from 'utils'

export const requestHandler = async config => {
  try {
    const response = await api(config)

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}
