import api from './api'

import { errorHandler } from '../utils'
import { PEXEL_API_URL, PIXABAY_API_URL } from 'constants/api'

const postDesignGallery = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/designGallery/design',
      data
    })

    return response
  } catch (e) {
    throw errorHandler(e)
  }
}

const putDesignGallery = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/designGallery/design/${id}`,
      data
    })

    return response
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDesignGallery = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/designGallery/design/${id}`
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

// images

const getPatterns = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/designGallery/pattern',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getBackgroundImages = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/designGallery/background',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getPexelBackgroundImages = async (
  { query, photosPage, perPage },
  config
) => {
  try {
    const response = await api({
      method: 'GET',
      url: `${PEXEL_API_URL}/v1/search`,
      params: {
        per_page: perPage,
        page: photosPage,
        query: (query || '').replace(/[^a-zA-Z,]/gm, '')
      },
      headers: {
        Authorization: config.PEXEL_API_KEY
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getObjects = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/designGallery/object',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getShapes = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/designGallery/shape',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getIcons = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/config/designGallery/icon/${params.style}`,
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getIconsGroup = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/config/designGallery/icon`,
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getEmojis = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/designGallery/emoji',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getStockImages = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/designGallery/stockImage',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getFavoriteDesign = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/designGallery/favoriteDesign',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getImageLabels = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/imageLabel',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getRoyaltyImages = async (
  { query, royaltyImagesPage, perPage, orientation },
  appConfig
) => {
  try {
    const response = await api({
      method: 'GET',
      url: PIXABAY_API_URL,
      withoutAuth: true,
      params: {
        key: appConfig.PIXABAY_API_KEY,
        q: (query || '').replace(/\W/gm, ''),
        per_page: perPage,
        lang: 'en',
        safesearch: true,
        page: royaltyImagesPage,
        ...(orientation && { orientation })
      }
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDesigns = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/designGallery/design',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getStockDesigns = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/designGallery/stockDesign',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getSharedDesigns = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/designGallery/sharedDesign',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const addDesignToFavorite = async designGalleryId => {
  try {
    const response = await api({
      method: 'POST',
      url: `/designGallery/favorite/${designGalleryId}`
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const removeDesignFromFavorite = async designGalleryId => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/designGallery/favorite/${designGalleryId}`
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const designGalleryService = {
  postDesignGallery,
  putDesignGallery,
  getDesignGallery,

  // images
  getPatterns,
  getBackgroundImages,
  getPexelBackgroundImages,
  getShapes,
  getObjects,
  getIcons,
  getIconsGroup,
  getEmojis,
  getStockImages,
  getImageLabels,
  getRoyaltyImages,

  // designs
  getDesigns,
  getStockDesigns,
  getSharedDesigns,
  getFavoriteDesign,
  addDesignToFavorite,
  removeDesignFromFavorite
}

export default designGalleryService
