import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { hexToRgb } from '@material-ui/core'

import { featureNames } from 'constants/featureConstants'
import useFeaturesAvailability from 'hooks/api/useFeaturesAvailability'
import { userClientDetailsSelector } from 'selectors/userSelectors'
import { getRandomColor } from 'utils/color'
import { clientSettingsSelector } from 'selectors/new/client'
import { _isEmpty } from 'utils/lodash'
import { rgbToRgba } from 'utils/rgbaToRgb'
import { removeSpacing } from 'utils/generalUtils'

const useMediaBrandGuide = () => {
  const [isAvailable] = useFeaturesAvailability(featureNames.BrandGuide)
  const client = useSelector(userClientDetailsSelector)
  const clientSettings = useSelector(clientSettingsSelector)

  const featureSetting = useMemo(() => {
    if (!_isEmpty(clientSettings?.data?.featuresSetting)) {
      return clientSettings?.data?.featuresSetting
    }
    return client?.featureSetting || []
  }, [clientSettings?.data?.featuresSetting, client?.featureSetting])

  const feature = useMemo(() => {
    if (!_isEmpty(clientSettings?.data?.feature)) {
      return clientSettings?.data?.feature
    }

    return client?.feature || []
  }, [clientSettings?.data?.feature, client?.feature])

  const brandGuideSettings = useMemo(() => {
    if (isAvailable) {
      const brandGuide = feature.find(
        ({ name }) => name === featureNames.BrandGuide
      )
      return (
        featureSetting.find(({ id }) => id === brandGuide?.id)?.settings || {}
      )
    }
    return {}
  }, [isAvailable, feature, featureSetting])

  const isEnabled = useMemo(
    () => isAvailable && brandGuideSettings.status === 'Active',
    [isAvailable, brandGuideSettings.status]
  )

  const colors = useMemo(() => {
    return Array(7)
      .fill()
      .map((_, index) =>
        brandGuideSettings?.colors?.[index]
          ? removeSpacing(brandGuideSettings?.colors?.[index])
          : removeSpacing(rgbToRgba(hexToRgb(getRandomColor())))
      )
  }, [brandGuideSettings?.colors])

  const brandColors = useMemo(
    () => brandGuideSettings?.colors?.map(color => removeSpacing(color)),
    [brandGuideSettings?.colors]
  )

  return useMemo(
    () => ({
      brandGuideSettings,
      brandGuideColors: isEnabled ? brandColors : colors,
      isEnabledBrandGuide: isEnabled
    }),
    [brandGuideSettings, brandColors, colors, isEnabled]
  )
}

export default useMediaBrandGuide
