import update from 'immutability-helper'

import * as types from 'actions'
import { shapeOfBody } from 'constants/initialLibraryState'

const initialState = {
  tableauLogin: {
    ...shapeOfBody
  },
  views: {
    ...shapeOfBody,
    isFetching: false
  },
  workbooks: {
    ...shapeOfBody,
    isFetching: false
  }
}

const tableauReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TABLEAU_LOGIN_SUCCESS:
      return update(state, {
        tableauLogin: {
          $set: {
            response: action.payload
          }
        }
      })
    case types.TABLEAU_LOGIN_FAILURE:
      return update(state, {
        tableauLogin: {
          error: {
            $set: action.error
          }
        }
      })
    case types.TABLEAU_LOGOUT_FAILURE:
    case types.TABLEAU_LOGOUT_SUCCESS:
      return update(state, {
        tableauLogin: {
          $set: {
            siteId: null,
            serverAddress: null,
            ...shapeOfBody
          }
        }
      })
    case types.GET_TABLEAU_VIEWS:
      return update(state, {
        views: {
          isFetching: {
            $set: true
          }
        }
      })
    case types.GET_TABLEAU_VIEWS_SUCCESS:
      return update(state, {
        views: {
          $set: {
            isFetching: false,
            response: action.payload
          }
        }
      })
    case types.GET_TABLEAU_VIEWS_ERROR:
      return update(state, {
        views: {
          isFetching: {
            $set: false
          },
          error: {
            $set: action.payload
          }
        }
      })
    case types.GET_TABLEAU_WORKBOOK:
      return update(state, {
        workbooks: {
          isFetching: {
            $set: true
          }
        }
      })
    case types.GET_TABLEAU_WORKBOOK_SUCCESS:
      return update(state, {
        workbooks: {
          $set: {
            isFetching: false,
            response: action.payload
          }
        }
      })
    case types.GET_TABLEAU_WORKBOOK_ERROR:
      return update(state, {
        workbooks: {
          isFetching: {
            $set: false
          },
          error: {
            $set: action.payload
          }
        }
      })
    case types.CLEAR_TABLEAU_RESPONSE_INFO:
      return update(state, {
        tableauLogin: {
          response: {
            $set: {}
          },
          error: {
            $set: null
          }
        },
        views: {
          $set: {
            ...shapeOfBody,
            isFetching: false
          }
        },
        workbooks: {
          $set: {
            ...shapeOfBody,
            isFetching: false
          }
        }
      })
    default:
      return state
  }
}

export default tableauReducer
