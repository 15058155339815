import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import { TableLibraryCell, TableLibraryRow } from 'components/TableLibrary'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { checkData } from 'utils/tableUtils'
import ScheduleWorkingDays from 'components/Pages/Schedule/ScheduleWorkingDays'
import { scheduleConstants } from 'constants/index'
import { TextWithTooltip } from 'components/Typography'

const useStyles = makeStyles(() => ({
  cellPaddings: {
    padding: '10px 8px !important'
  },
  name: {
    marginLeft: 7
  }
}))

const ScheduleSimpleTableRow = ({ data }) => {
  const { id, title, startTime, endTime, workingDays = '' } = data

  const classes = useStyles()

  const scheduleTypeInfo =
    scheduleConstants.scheduleTypes[data.scheduleType] ||
    scheduleConstants.scheduleTypes.Timed

  return (
    <TableLibraryRow key={id} hover tabIndex={-1}>
      <TableLibraryCell className={classes.cellPaddings}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <LibraryTypeIcon
              color={scheduleTypeInfo.color}
              icon={scheduleTypeInfo.icon}
              iconHelperClass={scheduleTypeInfo.iconHelperClass}
            />
          </Grid>
          <Grid item>
            <TextWithTooltip
              rootClassName={classes.name}
              weight="bold"
              color="title.primary"
              maxWidth={157}
            >
              {checkData(title)}
            </TextWithTooltip>
          </Grid>
        </Grid>
      </TableLibraryCell>

      <TableLibraryCell className={classes.cellPaddings} align="center">
        <ScheduleWorkingDays workingDays={workingDays.split(',')} />
      </TableLibraryCell>
      <TableLibraryCell className={classes.cellPaddings} align="center">
        {`${startTime} - ${endTime}`}
      </TableLibraryCell>
    </TableLibraryRow>
  )
}

export default ScheduleSimpleTableRow
