export const urlDecode = str => {
  try {
    return decodeURIComponent(str.replace(/\+/g, ' '))
  } catch (e) {
    console.error(e)
  }
}

export const parseTxtForMedia = str => {
  try {
    return JSON.parse(urlDecode(str))
  } catch (e) {
    console.error(e)
  }
}
