import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { queryParamsHelper, TextSizeTooltip } from 'utils'
import { CircularLoader } from 'components/Loaders'
import { getScheduleItemsAction } from 'actions/scheduleActions'
import Scrollbars from 'components/Scrollbars'
import handleBottomScroll from 'utils/handleBottomScroll'
import { Text } from '../../../../../Typography'

const styles = ({ palette, type, typography, colors }) => ({
  deviceListRoot: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    overflow: 'hidden'
  },
  deviceListRow: {
    display: 'flex',
    alignItems: 'center',
    height: '33px',
    margin: '0 7.5px 0 10px',
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`,
    '& i': {
      marginRight: 5,
      color: colors.highlight
    },
    '&:nth-child(even)': {
      margin: '0 10px 0 7.5px'
    }
  },
  itemDarkTitle: {
    ...typography.darkText[type],
    whiteSpace: 'nowrap'
  },
  scrollbarRoot: {
    maxHeight: 'calc((26px * 5) + 3px) !important'
  },
  noSelectedDevices: {
    borderRadius: '4px',
    backgroundColor: palette[type].pages.devices.alerts.content.background,
    fontSize: '14px',
    lineHeight: '65px',
    color: '#f5a623',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentsCardWrap: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'column',
    borderRadius: '4px',
    border: `solid 5px ${palette[type].sideModal.content.border}`,
    background: palette[type].sideModal.groups.header.background,
    position: 'relative'
  },
  contentsCardHeader: {
    padding: '15px',
    borderBottom: `solid 1px ${palette[type].sideModal.content.border}`,
    display: 'flex',
    justifyContent: 'space-between'
  },
  boldTitle: {
    fontWeight: 'bold',
    fontSize: '15px',
    color: palette[type].pages.schedule.boldTitle
  },
  contentsTotal: {
    display: 'flex',
    alignItems: 'center',
    '& i': {
      marginRight: '4px',
      fontSize: '14px'
    }
  }
})

const DeviceCardScheduleContent = ({ device, classes, t }) => {
  const [scheduleData] = useSelector(({ schedule }) => [schedule.items])
  const {
    response,
    meta: { isLoading, currentPage, lastPage, total }
  } = scheduleData
  const [isFirstLoad, setFirstLoad] = useState(true)
  const dispatch = useDispatch()

  const [scheduleList, setScheduleList] = useState([])

  useEffect(() => {
    if (response) {
      if (isFirstLoad) {
        setFirstLoad(false)
      } else {
        if (
          (currentPage > 1 && currentPage <= lastPage) ||
          currentPage < lastPage
        ) {
          setScheduleList(scheduleList.concat(response))
        } else {
          setScheduleList(response)
        }
      }
    }
    // eslint-disable-next-line
  }, [response, currentPage, lastPage])

  const fetchSchedules = useCallback(
    (params = {}) => {
      dispatch(
        getScheduleItemsAction(
          queryParamsHelper({
            page: 1,
            limit: 10,
            status: 'Active',
            sort: 'title',
            order: 'asc',
            ...params
          }),
          false,
          false
        )
      )
    },
    [dispatch]
  )

  useEffect(() => {
    if (device.id) {
      fetchSchedules({
        'device[exact]': device.id
      })
    }
    // eslint-disable-next-line
  }, [device])

  const scheduleListData = useMemo(
    () => (
      <div className={classes.deviceListRoot}>
        {!!scheduleList.length &&
          scheduleList.map(({ id, title }) => (
            <div key={id} className={classNames(classes.deviceListRow)}>
              <Typography className={classes.itemDarkTitle}>
                <TextSizeTooltip title={title} maxTitleWidth={18} width={120} />
              </Typography>
            </div>
          ))}
      </div>
    ),
    [
      classes.deviceListRoot,
      classes.deviceListRow,
      classes.itemDarkTitle,
      scheduleList
    ]
  )

  const scheduleScrollbarStyle = useMemo(
    () => ({
      height: `max(165px, ${Math.ceil((response.length / 2) * 33)}px)`
    }),
    [response]
  )

  const handleFetchMore = useCallback(() => {
    if (currentPage < lastPage) {
      fetchSchedules({
        'device[exact]': device.id,
        page: currentPage + 1
      })
    }
    // eslint-disable-next-line
  }, [currentPage, lastPage])

  return (
    <div className={classNames(classes.contentsCardWrap)}>
      <div className={classes.contentsCardHeader}>
        <Text weight="bold" color="title.primary" variant="secondary">
          {t('Active Schedule')}
        </Text>
        <Text
          weight="bold"
          color="title.primary"
          variant="smaller"
          rootClassName={classes.contentsTotal}
        >
          {total}
        </Text>
      </div>
      {isLoading && <CircularLoader />}
      <Scrollbars
        className={classes.scrollbarRoot}
        style={scheduleScrollbarStyle}
        onUpdate={handleBottomScroll(handleFetchMore)}
      >
        {!response.length && (
          <Typography className={classes.noSelectedDevices}>
            {t('No Records Found')}
          </Typography>
        )}
        {scheduleListData}
      </Scrollbars>
    </div>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(DeviceCardScheduleContent)
)
