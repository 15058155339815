import React, { useMemo, memo } from 'react'

import { withTranslation } from 'react-i18next'

import FormControlChips from './FormControlChips'
import useMediaFeatureOptions from 'hooks/options/useMediaFeatureOptions'
import { featuresToExclude } from 'constants/media'

const getStyles = {
  multiValue: ({ data }) => ({
    borderColor: data.color || '#fd7b25',
    background: data.background || 'transparent'
  }),
  multiValueLabel: ({ data }) => ({
    color: data.color || '#fd7b25'
  }),
  multiValueRemove: ({ data }) => ({
    'svg path': {
      fill: data.color || '#fd7b25'
    },
    ':hover': {
      backgroundColor: data.color || '#fd7b25',
      'svg path': {
        fill: '#fff'
      }
    }
  })
}

const FormControlMediaTypeChips = ({
  t,
  styles = {},
  noOptionsMessage = '',
  ...props
}) => {
  const { featureOptions, isFetching } = useMediaFeatureOptions(
    featuresToExclude
  )

  const customStyles = useMemo(() => {
    return { ...getStyles, ...styles }
  }, [styles])

  return (
    <FormControlChips
      noOptionsMessage={noOptionsMessage}
      styles={customStyles}
      hasDynamicChipsCreation={false}
      options={featureOptions}
      isLoading={isFetching}
      {...props}
    />
  )
}

export default withTranslation('translations')(memo(FormControlMediaTypeChips))
