import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'redux'
import { withStyles, Grid } from '@material-ui/core'
import classesNames from 'classnames'
import { withTranslation } from 'react-i18next'

import { Text } from 'components/Typography'
import { getUrlPrefix, getUserType } from 'utils'
import { BlueButton } from 'components/Buttons'
import { apiConstants, permissionNames } from 'constants/index'
import usePermissions from 'hooks/api/usePermissions'

const styles = ({ palette, type, lineHeight }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16
  },
  sideModalWrapFullScreen: {
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    height: '100vh',
    zIndex: 11
  },
  sideModal: {
    height: '100vh',
    background: palette[type].sideModal.background,
    borderRadius: '0 8px 8px 0',
    marginLeft: 'auto',
    opacity: 0.9,
    position: 'fixed',
    justifyContent: 'center'
  },
  icon: {
    color: palette[type].sideModal.header.titleColor,
    fontSize: 125
  },
  titleText: {
    fontSize: 24,
    fontWeight: 700,
    marginTop: 16,
    lineHeight: lineHeight.big
  },
  helperText: {
    lineHeight: lineHeight.big,
    fontSize: 20
  },
  goBackbtn: {
    marginTop: '1rem',
    '&> span': {
      textTransform: 'none'
    }
  }
})

const AccessDeniedModal = ({ classes, width = '95%', t }) => {
  const type = getUserType()
  const system = type === apiConstants.SYSTEM_USER

  const { getPermissionByName } = usePermissions()

  const hasDashboardAccess = getPermissionByName(
    permissionNames.CLIENT_DASHBOARD_ACCESS,
    permissionNames.SYSTEM_DASHBOARD_ACCESS,
    permissionNames.ENTERPRISE_DASHBOARD_ACCESS
  )

  return (
    <Grid
      className={classes.sideModalWrapFullScreen}
      style={{ width: width || '100%' }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        wrap="nowrap"
        className={classes.sideModal}
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={classes.wrapper}
        >
          <i
            className={classesNames(
              'fa-sharp fa-regular fa-octagon-exclamation',
              classes.icon
            )}
          />
          <Text rootClassName={classes.titleText}>Permission Denied</Text>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            className={classes.wrapper}
          >
            <Text rootClassName={classes.helperText}>
              {t('Sorry. You do not have permission for this feature.')}
            </Text>
            {!system && (
              <Text rootClassName={classes.helperText}>
                {t('Please contact your account administrator.')}
              </Text>
            )}
          </Grid>

          {hasDashboardAccess && (
            <BlueButton
              component={Link}
              className={classes.goBackbtn}
              to={getUrlPrefix('dashboard')}
            >
              {t('Go to the Dashboard')}
            </BlueButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withTranslation('translations')(
  compose(withRouter, withStyles(styles))(AccessDeniedModal)
)
