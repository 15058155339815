import React, { useCallback } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import UrlCard from './UrlCard'
import { withTranslation } from 'react-i18next'

const styles = ({ palette, type, typography }) => ({
  root: {
    width: '100%'
  },
  add: {
    color: '#2ad03a'
  },
  margin: {
    marginBottom: '16px'
  },
  categoriesHeader: {
    padding: '0 15px',
    opacity: 0.64,
    margin: '10px 0',
    border: `solid 1px ${palette[type].pages.rss.addRss.manage.border}`,
    backgroundColor: palette[type].pages.rss.addRss.manage.background
  },
  categoriesHeaderText: {
    ...typography.header[type],
    fontSize: '15px',
    lineHeight: '50px'
  }
})

const IPRestrictions = ({ t, classes, values, deleteItem, updateItem }) => {
  const handleClickDelete = useCallback(
    deleteIndex => {
      deleteItem({ id: deleteIndex })
    },
    [deleteItem]
  )

  const handleClickUpdate = useCallback(
    updateObj => {
      updateItem(updateObj)
    },
    [updateItem]
  )

  return (
    <div className={classes.root}>
      <header className={classes.categoriesHeader}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.categoriesHeaderText}>
              {t('Whitelisted IP Addresses')}
            </Typography>
          </Grid>
        </Grid>
      </header>
      {values.map(({ ip, id }, index) => (
        <UrlCard
          key={`IPWhiteListCard-${ip}-${index}`}
          values={ip}
          index={id}
          onDelete={handleClickDelete}
          onUpdate={handleClickUpdate}
        />
      ))}
    </div>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(IPRestrictions)
)
