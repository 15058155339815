import api from './api'
import { errorHandler } from 'utils'

const validateEnvs = () => {
  if (!!process.env.REACT_APP_POSTHOG_PROJECT_ID) {
    return true
  }

  throw new Error('PostHog Environments not available')
}

const appendProjectUrl = (url = '') => {
  if (!process.env.REACT_APP_POSTHOG_PROJECT_ID) {
    return url
  }

  return `/postHog/projects/${process.env.REACT_APP_POSTHOG_PROJECT_ID}/${url}`
}

const getPostHogEvents = async params => {
  try {
    validateEnvs()
    const { data } = await api({
      method: 'GET',
      url: appendProjectUrl('events'),
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postHogService = {
  getPostHogEvents
}

export default postHogService
