import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { InfoTwoTone } from '@material-ui/icons'

import Popup from 'components/Popup'

const styles = {
  indicator: {
    color: '#0A83C8',
    verticalAlign: 'sub',
    fontSize: '1.125rem',
    marginLeft: '2px'
  },
  gridIndicator: {
    fontSize: '1.325rem',
    marginLeft: '6px'
  },
  content: {
    margin: '10px',
    color: '#74809A',
    fontSize: '13px',
    lineHeight: '15px',
    fontFamily: [
      '"Product Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}

const LegacyIndicator = ({ classes, isLegacy = false, isGrid = false }) => {
  return (
    <>
      {isLegacy && (
        <Popup
          on="hover"
          trigger={
            <div>
              <InfoTwoTone
                className={`${classes.indicator} ${
                  isGrid ? classes.gridIndicator : ''
                }`}
              />
            </div>
          }
          position="right center"
        >
          <Typography className={classes.content}>
            Modern content app themes and transitions
            <br />
            require up-to-date device hardware and firmware.
            <br />
            This device may not support these features.
          </Typography>
        </Popup>
      )}
    </>
  )
}

LegacyIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  isLegacy: PropTypes.bool,
  isGrid: PropTypes.bool
}

export default withStyles(styles)(LegacyIndicator)
