import React, { createContext, useCallback, useMemo, useState } from 'react'

export const PreviewSettingsContext = createContext({
  content: null,
  setContent: f => f,
  handleShowPreview: f => f,
  setPreviewHandler: f => f,
  resetContent: f => f,
  isRefreshRequired: false,
  setRefreshRequired: f => f
})

const MediaPreviewSettingsProvider = ({ children }) => {
  const [content, setContent] = useState(null)
  const [isRefreshRequired, setRefreshRequired] = useState(false)
  const [previewHandler, setPreviewHandler] = useState(f => f)

  const resetContent = useCallback(() => {
    setContent(null)
    setRefreshRequired(false)
  }, [])

  const handleShowPreview = useCallback(() => {
    previewHandler()
    setRefreshRequired(false)
  }, [previewHandler])

  const context = useMemo(
    () => ({
      content,
      setContent,
      resetContent,
      handleShowPreview,
      setPreviewHandler,
      isRefreshRequired,
      setRefreshRequired
    }),
    [
      content,
      setContent,
      resetContent,
      handleShowPreview,
      setPreviewHandler,
      isRefreshRequired,
      setRefreshRequired
    ]
  )

  return (
    <PreviewSettingsContext.Provider value={context}>
      {children}
    </PreviewSettingsContext.Provider>
  )
}

export default MediaPreviewSettingsProvider
