const entityGroupsConstants = {
  User: 'User',
  Media: 'Media',
  Device: 'Device',
  Client: 'Client',
  Playlist: 'Playlist',
  Schedule: 'Schedule',
  Template: 'Template',
  Report: 'Report'
}

export default entityGroupsConstants
