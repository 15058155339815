import api from '../api'
import { errorHandler, queryParamsHelper } from '../../utils'

export const getPublicToken = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/public/token',
      params: queryParamsHelper(params)
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const postPublicToken = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/public/token',
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const deletePublicToken = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/public/token/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}
