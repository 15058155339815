import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from '@reduxjs/toolkit'
import { _isEmpty } from 'utils/lodash'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import SliderWrapper from './SliderWrapper'
import { getDeviceNocHealthItems } from 'actions/deviceNocActions'
import DeviceBox from './DeviceBox'
import GridLoader from './GridLoader'
import { useUserRole } from 'hooks/tableLibrary'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import Container from 'components/Containers/Container'
import { queryParamsHelper } from 'utils'

const styles = makeStyles({
  container: {
    padding: 16,
    gridTemplateColumns: ({ cols }) => `repeat(${cols}, 1fr)`
  },
  slickWrapper: {
    '& .slick-dots': {
      bottom: '-36px'
    }
  }
})

const ITEM_HEIGHT = 304
const ITEM_WIDTH = 250
const PADDING = 16

const DEFAULT_NUMBER_OF_COLS = 6
const DEFAULT_NUMBER_OF_ROWS = 3

const DeviceHealth = ({
  t,
  library,
  filterParams,
  searchParams,
  getDeviceNocHealthItems,
  fetcher: fetchItems,
  itemReducer,
  isPublic,
  isFetchAllowed,
  preferenceParams
}) => {
  const [data, setData] = useState({})
  const [initialized, setInitialized] = useState(false)
  const role = useUserRole()
  const timerInterval = useRef()

  const [fetchRequestAt, setFetchRequestAt] = useState(0)
  const [isAllDataFetched, setAllDataFetched] = useState(false)
  const [matrix, setMatrix] = useState({
    rows: DEFAULT_NUMBER_OF_ROWS,
    cols: DEFAULT_NUMBER_OF_COLS,
    itemHeight: ITEM_HEIGHT,
    itemWidth: ITEM_WIDTH
  })
  const classes = styles({
    cols: matrix.cols
  })

  const sliderRef = useRef(null)
  const containrRef = useRef()

  const { response: items, isFetching = true, meta } = useMemo(
    () => (itemReducer ? itemReducer : library),
    [itemReducer, library]
  )

  const fetcher = useCallback(
    (params = {}) => {
      const { filters, ...restPreferenceParams } = preferenceParams
      fetchItems
        ? fetchItems(
            queryParamsHelper({
              ...restPreferenceParams,
              ...searchParams,
              ...filterParams,
              limit: matrix.rows * matrix.cols,
              ...params
            })
          )
        : getDeviceNocHealthItems(
            queryParamsHelper({
              ...searchParams,
              ...filterParams,
              sort: 'updatedAt',
              order: 'desc',
              limit: matrix.rows * matrix.cols,
              ...params
            })
          )
      if (params.page !== 2) {
        setFetchRequestAt(Date.now())
      }
    },
    [
      getDeviceNocHealthItems,
      filterParams,
      fetchItems,
      searchParams,
      matrix.rows,
      matrix.cols,
      preferenceParams
    ]
  )

  const handleResizeWindow = useCallback(() => {
    const { top } = containrRef.current
      ? containrRef.current.getBoundingClientRect()
      : { top: 0 }
    let itemHeight = ITEM_HEIGHT,
      itemWidth = ITEM_WIDTH

    let cols = DEFAULT_NUMBER_OF_COLS
    if (isPublic) {
      const totalCols = Math.floor(window.innerWidth / itemWidth)
      cols = totalCols < 1 ? 1 : totalCols
    }

    const remainingHeight = window.innerHeight - top
    const totalRows = Math.floor(remainingHeight / itemHeight)
    const rows =
      totalRows < 1 ? 1 : isPublic ? totalRows : totalRows > 3 ? 3 : totalRows

    setMatrix({
      rows,
      cols,
      itemHeight,
      itemWidth,
      refresh:
        rows === DEFAULT_NUMBER_OF_ROWS && cols === DEFAULT_NUMBER_OF_COLS
    })
  }, [isPublic])

  useEffect(() => {
    if (initialized && isFetchAllowed) {
      clearInterval(timerInterval.current)
      setData({})
      fetcher({
        limit: matrix.rows * matrix.cols,
        page: 1
      })
    }
    // eslint-disable-next-line
  }, [
    matrix.rows,
    matrix.cols,
    matrix.refresh,
    filterParams,
    initialized,
    isFetchAllowed
  ])

  useEffect(() => {
    handleResizeWindow()
    setInitialized(true)

    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
  }, [handleResizeWindow])

  useEffect(() => {
    if (initialized && !isFetching && !_isEmpty(items) && meta.total > 0) {
      if (_isEmpty(data) && meta.currentPage === 1) {
        setData({
          ...(meta.lastPage > 1
            ? Object.assign(
                {},
                Array(Math.ceil(meta.total / (matrix.rows * matrix.cols))).fill(
                  []
                )
              )
            : {}),
          [meta.currentPage - 1]: items
        })

        meta.lastPage >= meta.currentPage + 1 &&
          fetcher({ page: meta.currentPage + 1 })
        meta.lastPage >= meta.currentPage + 2 &&
          fetcher({ page: meta.currentPage + 2 })
      } else {
        setData({
          ...data,
          [meta.currentPage - 1]: items
        })
      }
    }
    // eslint-disable-next-line
  }, [items])

  const beforeChange = useCallback(
    (current, next) => {
      if (current !== next) {
        fetcher({
          page: ((next + 2) % Object.keys(data).length) + 1
        })
      }
    },
    [fetcher, data]
  )

  const settings = useMemo(
    () => ({
      beforeChange,
      dots: false,
      autoplay: false
    }),
    [beforeChange]
  )

  const dataLength = useMemo(() => {
    return Object.keys(data).filter(key => data[key].length).length
  }, [data])

  useEffect(() => {
    if (dataLength > 1 && !isAllDataFetched) {
      const diffInMilliseconds = Date.now() - fetchRequestAt
      const timeLeft = Math.max(10000 - diffInMilliseconds, 0)

      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)

        sliderRef.current?.next()

        if (dataLength && meta.lastPage && dataLength === meta.lastPage) {
          setAllDataFetched(true)
          timerInterval.current = setInterval(() => {
            sliderRef.current?.next()
          }, 10000)
        }
      }, timeLeft)
    }

    return () => {
      clearInterval(timerInterval.current)
    }
    // eslint-disable-next-line
  }, [dataLength])

  return (
    <div ref={containrRef}>
      {isFetching && !Object.keys(data).length ? (
        <GridLoader
          cols={matrix.cols}
          rows={matrix.rows}
          boxHeight={matrix.itemHeight}
        />
      ) : _isEmpty(data) && !meta.total ? (
        <EmptyPlaceholder text={t('No Device Health Available')} />
      ) : (
        <>
          <div
            className={classes.slickWrapper}
            style={{
              height:
                matrix.rows * matrix.itemHeight +
                PADDING * 2 +
                PADDING * (matrix.rows - 1)
            }}
          >
            <SliderWrapper ref={sliderRef} customSettings={settings}>
              {Object.entries(data).map(([key, values]) =>
                isFetching && !values.length ? (
                  <GridLoader
                    key={`device-health-loader-${key}`}
                    cols={matrix.cols}
                    rows={matrix.rows}
                    boxHeight={matrix.itemHeight}
                  />
                ) : (
                  <Container key={key} customClass={classes.container}>
                    {values.map(device => (
                      <DeviceBox key={device.id} data={device} role={role} />
                    ))}
                  </Container>
                )
              )}
            </SliderWrapper>
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({ deviceNoc }) => ({
  library: deviceNoc.health
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceNocHealthItems
    },
    dispatch
  )

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(DeviceHealth)
)
