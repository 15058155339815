import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

import { withStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Container from 'components/Containers/Container'
import TrainingInfo from './TrainingInfo'
import GoogleCalendarIframeLoader from '../../Loaders/GoogleCalendarIframeLoader'

const styles = ({ palette, type }) => {
  return {
    container: {
      width: '100%',
      height: '100%',
      padding: '10px 20px 0px'
    },
    close: {
      width: '46px',
      height: '46px',
      margin: '0px',
      padding: '0px',
      color: palette[type].dialog.closeButton
    },
    infoWrapper: {
      background: '#011875',
      position: 'relative'
    },
    webpageWrapper: {
      position: 'relative',
      height: '100%',
      overflow: 'hidden'
    },
    closeBtn: {
      height: '44px',
      width: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '25px',
      right: '25px',
      cursor: 'pointer'
    },
    mainContainerWrapper: {
      gap: '0',
      gridTemplateColumns: '1fr 420px'
    },
    iframeWrapper: {
      height: '100%',
      width: '100%'
    }
  }
}

const TrainingForm = ({ t, classes, closeModal, url }) => {
  const [iframeReady, setIframeReady] = useState(false)

  const handleIframeLoaded = () => {
    setTimeout(() => {
      setIframeReady(true)
    }, 3000)
  }

  return (
    <Container customClass={classes.mainContainerWrapper} cols={'2-1'}>
      <div className={classes.webpageWrapper}>
        {iframeReady ? (
          <div className={classes.closeBtn} onClick={closeModal}>
            <Close />
          </div>
        ) : (
          <GoogleCalendarIframeLoader />
        )}
        <iframe
          title="screenPreviewModal"
          src={url}
          frameBorder="0"
          className={classes.iframeWrapper}
          onLoad={handleIframeLoaded}
        />
      </div>
      <TrainingInfo />
    </Container>
  )
}
export default withTranslation('translations')(withStyles(styles)(TrainingForm))
