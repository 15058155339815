import React from 'react'

const LockCheckIcon = () => (
  <svg
    fill="#AFB7C7"
    width="16px"
    height="13px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="lock-check"
    className="icon glyph"
  >
    <path d="M18,8H17V7A5,5,0,0,0,7,7V8H6a2,2,0,0,0-2,2V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V10A2,2,0,0,0,18,8ZM9,7a3,3,0,0,1,6,0V8H9Zm6.71,6.71-4,4a1,1,0,0,1-1.42,0l-2-2a1,1,0,0,1,1.42-1.42L11,15.59l3.29-3.3a1,1,0,0,1,1.42,1.42Z" />
  </svg>
)

export default LockCheckIcon
