import * as Yup from 'yup'
import { numbersField } from 'constants/validationMessages'

function isNumbers(string) {
  return /^\d+$/.test(string)
}

Yup.addMethod(Yup.mixed, 'numbers', function () {
  return this.test({
    name: 'numbers',
    exclusive: true,
    message: numbersField,
    test: isNumbers
  })
})
