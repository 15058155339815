import { call, put } from 'redux-saga/effects'

import { postHogService } from 'services'
import * as types from '../actions'

function* getPostHogEvents({ params }) {
  try {
    const { results = [] } = yield call(postHogService.getPostHogEvents, params)

    yield put({
      type: types.GET_POSTHOG_EVENTS_SUCCESS,
      payload: {
        response: results
      }
    })
  } catch (error) {
    yield put({ type: types.GET_POSTHOG_EVENTS_ERROR, payload: error })
  }
}

const postHogSaga = {
  getPostHogEvents
}

export default postHogSaga
