import React, { useCallback, useMemo, useState } from 'react'
import { List, withStyles } from '@material-ui/core'
import { _intersection, _isEmpty } from 'utils/lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { DropdownHover } from 'components/Dropdowns'
import Scrollbars from 'components/Scrollbars'
import { FormControlInput } from 'components/Form'
import SearchList from './SearchList'
import { searchCategoryMapping } from 'constants/library'
import { isEven } from 'utils'
import { getGlobalSearchItems } from 'actions/configActions'
import { globalSearchSelector } from 'selectors/configSelectors'
import { withTranslation } from 'react-i18next'
import GlobalSearchLoader from 'components/Loaders/GlobalSearchLoader'
import useUserPermissionGroupsByType from 'hooks/api/useUserPermissionGroupsByType'
import { permissionTypes } from 'constants/permissionGroups'
import { permissionGroups } from 'constants/index'
import EmptyPlaceholder from 'components/EmptyPlaceholder'

const dropdownFadeTransition = 500

const styles = ({ palette, type }) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch'
  },
  menuRoot: {
    top: '80%',
    transform: 'rotateX(90deg)',
    transformOrigin: 'top center'
  },
  navigationSubMegaMenu: {
    width: 549
  },
  listContainer: {
    margin: '0px 20px',
    padding: 0,
    paddingBottom: 20,
    position: 'relative',
    overflowY: 'visible',
    display: 'grid',
    gridGap: '6px'
  },
  navigationHoverCard: {
    backgroundColor: palette[type].body.background,
    borderRadius: '10px',
    position: 'absolute',
    top: 0,
    left: 0,
    transitionDuration: '0.7s',
    width: '255px'
  },
  searchRoot: {
    padding: '20px',
    paddingBottom: 16,
    position: 'relative',
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`
  },
  searchIcon: {
    position: 'absolute',
    top: 2,
    left: 30,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,

    '& i': {
      fontSize: 22,
      color: palette[type].header.rightAction.iconColor
    }
  },
  searchInput: {
    height: 40,
    paddingLeft: 40
  },
  listHeader: {
    padding: '8px 22px',
    backgroundColor: palette[type].searchDropdown.header.background,
    borderBottom: `1px solid ${palette[type].searchDropdown.header.border}`,
    borderTop: `1px solid ${palette[type].searchDropdown.header.border}`,
    color: palette[type].searchDropdown.header.color,
    textTransform: 'uppercase',
    margin: '0px -20px',
    zIndex: 1
  },
  userPicAvatar: {
    width: 36,
    height: 36
  },
  listContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  emptyContentWrap: {
    height: 250
  }
})

const entityPermissionMapping = {
  [permissionGroups.DEVICE]: 'Device',
  [permissionGroups.MEDIA]: 'Media',
  [permissionGroups.PLAYLIST]: 'Playlist',
  [permissionGroups.TEMPLATE]: 'Template',
  [permissionGroups.SCHEDULE]: 'Schedule',
  [permissionGroups.TAG]: 'Tag',
  [permissionGroups.USER]: 'User'
}

const MAX_NO_OF_ITEMS = 6

const GlobalSearch = ({ trigger, classes, t }) => {
  const dispatch = useDispatch()
  const [hidingDropdown, setHidingDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const [hoverCard, setHoverCard] = useState({
    height: '0px',
    transform: 'translate(0px, 0px)'
  })

  const readGroups = useUserPermissionGroupsByType(permissionTypes.read)

  const { response, isFetching } = useSelector(globalSearchSelector)

  const parsdData = useMemo(() => {
    if (!search || isFetching || _isEmpty(response)) return {}

    return Object.entries(response).reduce((a, [key, value]) => {
      if (value && value.data.length) {
        const noOfItems = isEven(value.data.length)
          ? value.data.length - 1
          : value.data.length
        a[searchCategoryMapping[key]] =
          value.meta.total <= MAX_NO_OF_ITEMS
            ? value.data
            : [
                ...value.data.slice(
                  0,
                  noOfItems < MAX_NO_OF_ITEMS ? noOfItems : MAX_NO_OF_ITEMS - 1
                ),
                {
                  isViewMore: true
                }
              ]
      }
      return a
    }, {})
  }, [response, isFetching, search])

  const handleMouseOver = useCallback(
    (elementIndex, categoryIndex = 0) => {
      const index =
        Object.values(parsdData)
          .splice(0, categoryIndex)
          ?.reduce(
            (a, b) => a + (isEven(b.length) ? b.length : b.length + 1),
            0
          ) + elementIndex
      const cols = 2
      const extraY = (categoryIndex + 1) * 45 + categoryIndex * 6
      const x = (index % cols) * 255
      const y = Math.floor(index / cols) * 66 + extraY
      setHoverCard({
        height: 66,
        transform: `translate(${x}px, ${y}px)`
      })
    },
    [parsdData]
  )

  const onResetMouseHover = useCallback(() => {
    setHoverCard({
      height: '0px',
      transform: `translate(0px, 0px)`
    })
  }, [])

  const debounceSearchChange = useDebouncedCallback(value => {
    dispatch(
      getGlobalSearchItems({
        title: value,
        cancelable: true,
        entity: Object.entries(entityPermissionMapping)
          .reduce((a, [key, value]) => {
            readGroups.includes(key) && a.push(value)
            return a
          }, [])
          .join(',')
      })
    )
  }, 300)

  const handleSearchChange = useCallback(
    ({ target: { value } }) => {
      if (value && value.trim() && value.trim() !== search.trim()) {
        debounceSearchChange.callback(value.trim())
      }
      setSearch(value)
    },
    [debounceSearchChange, search]
  )

  const handleLinkClick = useCallback(() => {
    setHidingDropdown(true)
    setTimeout(() => {
      setHidingDropdown(false)
    }, 2 * dropdownFadeTransition)
  }, [])

  const searchVisible = useMemo(
    () =>
      !!_intersection(readGroups, Object.keys(entityPermissionMapping)).length,
    [readGroups]
  )

  const renderSearchList = useMemo(
    () => (
      <SearchList
        t={t}
        classes={classes}
        onMouseOver={handleMouseOver}
        onLinkClick={handleLinkClick}
        data={parsdData}
        search={search}
      />
    ),
    [t, classes, parsdData, search, handleMouseOver, handleLinkClick]
  )

  return searchVisible ? (
    <div className={classes.root}>
      <DropdownHover
        forceHidden={hidingDropdown}
        menuContainerClassName={classes.menuRoot}
        ButtonComponent={trigger}
        showBackdrop
        MenuComponent={
          <div className={classes.navigationSubMegaMenu}>
            <div className={classes.searchRoot}>
              <div className={classes.searchIcon}>
                <i className="fa-regular fa-magnifying-glass" />
              </div>
              <FormControlInput
                name="search"
                value={search}
                handleChange={handleSearchChange}
                placeholder={t('Search Title')}
                formControlInputClass={classes.searchInput}
                marginBottom={false}
                autocomplete="off"
              />
            </div>
            {isFetching ? (
              <GlobalSearchLoader />
            ) : search.trim() && !_isEmpty(parsdData) ? (
              <Scrollbars
                autoHeight
                autoHeightMax="calc(100vh - 170px)"
                onMouseLeave={onResetMouseHover}
              >
                <List component="nav" className={classes.listContainer}>
                  <div
                    className={classes.navigationHoverCard}
                    style={{
                      ...(hoverCard.width ? { width: hoverCard.width } : {}),
                      height: hoverCard.height,
                      transform: hoverCard.transform
                    }}
                  />

                  {renderSearchList}
                </List>
              </Scrollbars>
            ) : search ? (
              <EmptyPlaceholder
                text={t('No results')}
                rootClassName={classes.emptyContentWrap}
              />
            ) : null}
          </div>
        }
      />
    </div>
  ) : null
}

export default withTranslation('translations')(withStyles(styles)(GlobalSearch))
