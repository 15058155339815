import { useSnackbar as _useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { useCustomSnackbar } from './index'
import { useEffect } from 'react'

export default function useSmallScreenWarning(isNotificationAllowed = true) {
  const { enqueueSnackbar, closeSnackbar } = _useSnackbar()
  const { t } = useTranslation('translations')

  const showSnackbar = useCustomSnackbar(
    t,
    enqueueSnackbar,
    closeSnackbar,
    30000
  )

  useEffect(
    () => {
      if (isNotificationAllowed && window.innerWidth < 1600) {
        showSnackbar(
          'We have detected that you are accessing the CMS using a device with a lower than recommended display resolution. For the best experience, we recommend using a device with a resolution of 1600x900 or higher.',
          'warning',
          'OK'
        )
      }
    },
    // eslint-disable-next-line
    [isNotificationAllowed]
  )
}
