import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { userDetailsSelectorMemoized } from 'selectors/userSelectors'
import { useUserRole } from '../tableLibrary'
import { useGetClientFeaturesQuery } from 'api/configApi'

export default function useFeaturesId(...featureNames) {
  const role = useUserRole()
  const { response } = useSelector(userDetailsSelectorMemoized)
  const { data: systemFeatures } = useGetClientFeaturesQuery(
    {},
    { skip: !role.system }
  )

  return useMemo(() => {
    const features = response?.client?.feature || systemFeatures || []
    return featureNames.map(
      featureName => features.find(({ name }) => name === featureName)?.id
    )
  }, [response, featureNames, systemFeatures])
}
