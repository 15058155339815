import update from 'immutability-helper'

import * as types from '../../actions/public'

import {
  shapeOfBody,
  shapeOfBodyWithMeta
} from '../../constants/initialLibraryState'

const initialState = {
  general: {
    ...shapeOfBodyWithMeta
  },
  health: {
    ...shapeOfBodyWithMeta
  },
  network: {
    ...shapeOfBodyWithMeta
  },
  login: {
    ...shapeOfBody
  }
}

const deviceNoc = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PUBLIC_NOC_GENERAL_ITEMS:
      return update(state, {
        general: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_PUBLIC_NOC_GENERAL_ITEMS_SUCCESS:
      return update(state, {
        general: {
          response: { $set: action.response },
          isFetching: { $set: false },
          meta: { $set: action.modifiedMeta }
        }
      })
    case types.GET_PUBLIC_NOC_GENERAL_ITEMS_ERROR:
      return update(state, {
        general: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_PUBLIC_NOC_HEALTH_ITEMS:
      return update(state, {
        health: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_PUBLIC_NOC_HEALTH_ITEMS_SUCCESS:
      return update(state, {
        health: {
          response: { $set: action.response },
          isFetching: { $set: false },
          meta: { $set: action.modifiedMeta }
        }
      })
    case types.GET_PUBLIC_NOC_HEALTH_ITEMS_ERROR:
      return update(state, {
        health: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_PUBLIC_NOC_NETWORK_ITEMS:
      return update(state, {
        network: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_PUBLIC_NOC_NETWORK_ITEMS_SUCCESS:
      return update(state, {
        network: {
          response: { $set: action.response },
          meta: { $set: action.modifiedMeta },
          isFetching: { $set: false }
        }
      })
    case types.GET_PUBLIC_NOC_NETWORK_ITEMS_ERROR:
      return update(state, {
        network: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_PUBLIC_NOC_ACCESS_TOKEN:
      return update(state, {
        login: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_PUBLIC_NOC_ACCESS_TOKEN_SUCCESS:
      return update(state, {
        login: {
          response: { $set: action.response },
          isFetching: { $set: false }
        }
      })
    case types.GET_PUBLIC_NOC_ACCESS_TOKEN_ERROR:
      return update(state, {
        login: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })

    default:
      return state
  }
}

export default deviceNoc
