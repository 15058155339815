import React, { memo, useCallback } from 'react'
import {
  LocalOffer as LocalOfferIcon,
  ArrowForward as ArrowForwardIcon
} from '@material-ui/icons'
import { useLocation } from 'react-router'

import SearchRow from './SearchRow'
import {
  libraryViews,
  searchCategoryType,
  searchTypeColorMapping
} from 'constants/library'
import { getIconInfo } from 'utils/libraryUtils'
import { getUrlPrefix } from 'utils'
import { isFixedDuration } from 'utils/mediaUtils'
import { routeByName } from 'constants/index'
import { SCHEDULE_MAX_TIME, SCHEDULE_MIN_TIME } from 'constants/schedule'
import { LIBRARY_VIEW_SETTINGS } from 'constants/localStorage'
import { storageGetItem } from 'utils/localStorage'
import { getLibraryView } from 'utils/generalUtils'

const SearchList = ({
  t,
  classes,
  data = {},
  onMouseOver,
  onLinkClick,
  search
}) => {
  const location = useLocation()

  const getSubTitle = useCallback(
    (row, type) => {
      const {
        description,
        tag,
        group,
        duration,
        resolution,
        allDay,
        startTime,
        endTime,
        status,
        role
      } = row

      if (description) {
        return description
      }

      if (tag && tag.length) {
        return `${t('Tag', { count: tag.length })}: ${tag
          .map(({ title }) => title)
          .join(', ')}`
      }

      if (group && group.length) {
        return `${t('Group', { count: group.length })}: ${group
          .map(({ title }) => title)
          .join(', ')}`
      }

      if (type === searchCategoryType.schedule && (startTime || endTime)) {
        return allDay
          ? `${SCHEDULE_MIN_TIME} - ${SCHEDULE_MAX_TIME}`
          : `${startTime} - ${endTime}`
      }

      if (type === searchCategoryType.user && role) {
        return `${t('Role')}: ${role.displayName}`
      }

      if (duration && isFixedDuration(row)) {
        return `${t('Duration')}: ${duration}`
      }

      if (resolution && resolution !== '0x0') {
        return `${t('Resolution')}: ${
          resolution === 'x' ? t('Responsive') : resolution
        }`
      }

      if (status) {
        return `${t('Status')}: ${status}`
      }

      return ''
    },
    [t]
  )

  const viewSettings = JSON.parse(storageGetItem(LIBRARY_VIEW_SETTINGS))

  const getUrlFromType = useCallback(
    (row, type) => {
      const route =
        routeByName[type.toLowerCase()][
          getLibraryView({ viewSettings, type, defaultView: libraryViews.list })
        ]
      const { isViewMore } = row
      switch (type) {
        case searchCategoryType.media:
          return `${getUrlPrefix(route)}?title=${
            isViewMore ? search : row.title
          }`
        case searchCategoryType.playlist:
          return `${getUrlPrefix(routeByName.playlist.root)}?title=${
            isViewMore ? search : row.title
          }`
        case searchCategoryType.template:
          return `${getUrlPrefix(route)}?title=${
            isViewMore ? search : row.title
          }`
        case searchCategoryType.schedule:
          return `${getUrlPrefix(route)}?title=${
            isViewMore ? search : row.title
          }`
        case searchCategoryType.device:
          return `${getUrlPrefix(route)}?name=${isViewMore ? search : row.name}`
        case searchCategoryType.user:
          return isViewMore
            ? `${getUrlPrefix(routeByName.users.root)}?firstName=${search}`
            : `${getUrlPrefix(routeByName.users.root)}?firstName=${
                row.firstName
              }&lastName=${row.lastName}`
        case searchCategoryType.tag:
          return `${getUrlPrefix(routeByName.tag.root)}?tag=${
            isViewMore ? search : row.tag
          }`
        default:
          return ''
      }
    },
    [search, viewSettings]
  )

  const getParsedRow = useCallback(
    (row, type) => {
      const icon = getIconInfo(row, type)

      if (row.isViewMore) {
        return {
          isViewMore: true,
          title: t('View More'),
          icon: {
            icon: props => <ArrowForwardIcon {...props} />
          },
          url: getUrlFromType(row, type)
        }
      }

      switch (type) {
        case searchCategoryType.media:
          return {
            icon: icon,
            title: row.title,
            subTitle: getSubTitle(row, type),
            url: getUrlFromType(row, type)
          }
        case searchCategoryType.playlist:
          return {
            icon: icon,
            subTitle: getSubTitle(row, type),
            title: row.title,
            url: getUrlFromType(row, type)
          }
        case searchCategoryType.template:
          return {
            icon: icon,
            subTitle: getSubTitle(row, type),
            title: row.title,
            url: getUrlFromType(row, type)
          }
        case searchCategoryType.schedule:
          return {
            icon,
            title: row.title,
            subTitle: getSubTitle(row, type),
            url: getUrlFromType(row, type)
          }
        case searchCategoryType.device:
          return {
            icon: {
              iconHelperClass: 'fa-regular fa-desktop'
            },
            title: row.name,
            subTitle: row.alias,
            url: getUrlFromType(row, type)
          }
        case searchCategoryType.user:
          return {
            icon: {
              iconHelperClass: 'fa-regular fa-user'
            },
            subTitle: getSubTitle(row, type),
            title: `${row.firstName} ${row.lastName}`,
            url: getUrlFromType(row, type)
          }
        case searchCategoryType.tag:
          return {
            title: row.tag,
            subTitle: `${t('Created By')}: ${row.createdBy?.firstName} ${
              row.createdBy?.lastName
            }`,
            icon: {
              icon: props => <LocalOfferIcon {...props} />
            },
            url: getUrlFromType(row, type)
          }
        default:
          return {
            icon
          }
      }
    },
    [getSubTitle, getUrlFromType, t]
  )

  return (
    <>
      {Object.entries(data).map(([key, rows], mainIndex) => (
        <React.Fragment key={`search-list-header-${key}`}>
          <div className={classes.listHeader}>{key}</div>
          <div className={classes.listContent}>
            {rows.map((row, index) => (
              <SearchRow
                key={`search-list-row-${key}-${index}`}
                item={getParsedRow(row, key)}
                onMouseOver={() => onMouseOver(index, mainIndex)}
                onLinkClick={onLinkClick}
                color={searchTypeColorMapping[key]}
                pathname={location.pathname}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
    </>
  )
}

export default memo(SearchList)
