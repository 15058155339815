import { call, put } from 'redux-saga/effects'

import * as types from '../actions'
import { ipService } from '../services'

function* getIpProviders({ params }) {
  try {
    const response = yield call(ipService.getIpProviders, params)
    yield put({ type: types.GET_IP_PROVIDERS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_IP_PROVIDERS_ERROR, payload: error })
  }
}

function* putIpProviders({ data }) {
  try {
    yield call(ipService.putIpProviders, data)
    yield put({
      type: types.PUT_IP_PROVIDERS_SUCCESS,
      payload: { status: 'OK' }
    })
  } catch (error) {
    yield put({ type: types.PUT_IP_PROVIDERS_ERROR, payload: error })
  }
}

const ipProvidersSaga = {
  putIpProviders,
  getIpProviders
}

export default ipProvidersSaga
