import React from 'react'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'

import ImageWithPlaceholder from 'components/Loaders/ImageWithPlaceholder'
import LibraryGridCard from 'components/Card/LibraryGridCard'

const useStyles = makeStyles(({ spacing, colors, type }) => ({
  root: {
    marginBottom: spacing(2),
    width: 245
  },
  previewRoot: {
    textAlign: 'center',
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 243,
    cursor: 'pointer',
    position: 'relative',
    height: 135,
    transition: 'height 0.5s'
  },
  previewImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  singlePreviewImg: {
    borderRadius: '0 0 7px 7px'
  },
  cardHeaderWrap: {
    width: '100%',
    maxWidth: '100% !important'
  },
  cardHeaderWrapSmall: {
    '& p': {
      fontSize: '0.9rem'
    }
  },
  iconMedia: {
    color: colors.other.color2[type]
  },
  iconPlaylist: {
    color: colors.other.color3[type]
  },
  iconTemplate: {
    color: colors.other.color4[type]
  },
  iconSchedule: {
    color: colors.schedule[type]
  },
  previewIcon: {
    fontSize: 48
  }
}))

const iconByType = {
  Media: 'icon-files-landscape-video',
  Playlist: 'icon-playlist-2',
  Template: 'fa-regular fa-pause fa-rotate-90 fa-lg',
  Schedule: 'icon-cloud-downloading-2'
}

const PreviewCard = ({
  id,
  contentType,
  thumbUrl,
  title,
  selected,
  onImageClick,
  onSelect,
  singleContent
}) => {
  const classes = useStyles()
  const matches = useMediaQuery('(max-width:1600px)')

  const handleSelect = e => {
    e.stopPropagation()
    onSelect({ id, contentType, thumbUrl, title })
  }

  const handleImageClick = e => {
    e.stopPropagation()
    onImageClick(thumbUrl)
  }

  return (
    <LibraryGridCard
      cardRootClassName={classes.root}
      disableHoverEffect
      withShowMore={false}
      title={title}
      titleComponentWrapClassName={classNames(classes.cardHeaderWrap, {
        [classes.cardHeaderWrapSmall]: matches
      })}
      withFooter={!singleContent}
      handleSelect={handleSelect}
      onCardClick={handleSelect}
      isSelected={selected}
      titleIcon={iconByType[contentType]}
      titleIconClassName={classes[`icon${contentType}`]}
    >
      <div className={classes.previewRoot}>
        {thumbUrl ? (
          <ImageWithPlaceholder
            src={thumbUrl}
            className={classNames(classes.previewImg, {
              [classes.singlePreviewImg]: singleContent
            })}
            onClick={handleImageClick}
          />
        ) : (
          <i
            className={classNames(
              iconByType[contentType],
              classes[`icon${contentType}`],
              classes.previewIcon
            )}
          />
        )}
      </div>
    </LibraryGridCard>
  )
}

export default PreviewCard
