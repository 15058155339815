import * as types from './index'

export const getDeviceNocGeneralItems = params => ({
  type: types.GET_DEVICE_NOC_GENERAL_ITEMS,
  params
})

export const getDeviceNocHealthItems = params => ({
  type: types.GET_DEVICE_NOC_HEALTH_ITEMS,
  params
})

export const getDeviceNocNetworkItems = params => ({
  type: types.GET_DEVICE_NOC_NETWORK_ITEMS,
  params
})

export const getDeviceAlarmItems = params => ({
  type: types.GET_DEVICE_ALARM_ITEMS,
  params
})

export const getDeviceAlarmById = id => ({
  type: types.GET_DEVICE_ALARM_ITEM_BY_ID,
  id
})

export const postDeviceAlarm = data => ({
  type: types.POST_DEVICE_ALARM,
  data
})

export const putDeviceAlarm = (id, data) => ({
  type: types.PUT_DEVICE_ALARM,
  id,
  data
})

export const deleteDeviceAlarm = id => ({
  type: types.DELETE_DEVICE_ALARM,
  id
})

export const getDeviceAlarmColumnList = () => ({
  type: types.GET_DEVICE_ALARM_COLUMN_LIST
})

export const clearDeviceAlarmResponseInfo = () => ({
  type: types.CLEAR_DEVICE_ALARM_RESPONSE_INFO
})
