import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useTimeout from 'hooks/useTimeout'
import { useLazyPingQuery } from 'api/appApi'
import { routeByName } from 'constants/index'

const ApiErrorHandler = () => {
  const history = useHistory()
  const [setTimeout, clearTimeout] = useTimeout()
  const [setAbortTimeout, clearAbortTimeout] = useTimeout()
  const [ping, result] = useLazyPingQuery()

  const checkHealth = () => {
    clearTimeout()
    clearAbortTimeout()
    setTimeout(() => {
      const request = ping()

      setAbortTimeout(() => {
        request?.abort()
      }, 60000)
    }, 1000)
  }

  useEffect(() => {
    checkHealth()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (history.location.pathname?.includes(routeByName.signIn.root)) {
      checkHealth()
    }
    // eslint-disable-next-line
  }, [history.location])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      return
    }

    if (result.isError || result.isSuccess) {
      clearAbortTimeout()
    }

    if (result.isError) {
      if (result.error.name === 'AbortError') {
        history.push(`${routeByName.timeoutError}?code=${408}`)
        return
      }

      switch (result.error?.code) {
        case 400:
        case 403:
        case 404:
          history.push(
            `${routeByName.maintenanceError}?code=${result.error.code}`
          )
          break
        case 408:
        case 504:
          history.push(`${routeByName.timeoutError}?code=${result.error.code}`)
          break
        case 500:
        case 502:
        case 503:
          history.push(`${routeByName.serverError}?code=${result.error.code}`)
          break
        default:
          history.push(
            `${routeByName.maintenanceError}?code=${result.error?.code || 400}`
          )
      }
    }
    // eslint-disable-next-line
  }, [result])

  return <React.Fragment />
}

export default ApiErrorHandler
