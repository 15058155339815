import React from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  ClassName,
  Color,
  FontSize,
  FontStyle,
  FontWeight,
  whiteSpace,
  WhiteSpace
} from 'utils/styles'

function styles({ fontSize, fontWeight, lineHeight, colors, type }) {
  function _color(variant) {
    return {
      color: get(colors, variant)
    }
  }

  function _variant(variant) {
    return {
      fontSize: fontSize[variant],
      lineHeight: lineHeight[variant]
    }
  }

  function _fontWeight(variant) {
    return {
      fontWeight: fontWeight[variant]
    }
  }

  function _fontStyle(variant) {
    return {
      fontStyle: variant
    }
  }

  return {
    'font-style-normal': _fontStyle('normal'),
    'font-style-italic': _fontStyle('italic'),
    'font-weight-normal': _fontWeight('normal'),
    'font-weight-bold': _fontWeight('bold'),
    'variant-primary': _variant('primary'),
    'variant-secondary': _variant('secondary'),
    'variant-small': _variant('small'),
    'variant-smaller': _variant('smaller'),
    'variant-smallest': _variant('smallest'),
    'variant-big': _variant('big'),
    'variant-bigger': _variant('bigger'),
    'variant-biggest': _variant('biggest'),
    'color-inherit': _color('inherit'),
    'color-light': _color('light'),
    'color-highlight': _color('highlight'),
    'color-title.primary': _color(`title.primary[${type}]`),
    'color-warning': _color(`warning`),
    'color-white': _color(`white`),
    'color-needsAttentionStatus': _color(`needsAttentionStatus`),
    'white-space-normal': whiteSpace('normal'),
    'white-space-pre': whiteSpace('pre'),
    'white-space-no-wrap': whiteSpace('nowrap'),
    'white-space-pre-line': whiteSpace('pre-line')
  }
}

const Text = React.forwardRef(
  (
    {
      classes,
      variant = 'primary',
      fontStyle = 'normal',
      weight = 'normal',
      color = 'light',
      children,
      whiteSpace = 'normal',
      rootClassName,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <Typography
        ref={ref}
        className={classNames(
          classes[`variant-${variant}`],
          classes[`font-weight-${weight}`],
          classes[`font-style-${fontStyle}`],
          classes[`color-${color}`],
          classes[`white-space-${whiteSpace}`],
          rootClassName
        )}
        {...props}
      >
        {children}
      </Typography>
    )
  }
)

Text.propTypes = {
  variant: FontSize,
  weight: FontWeight,
  fontStyle: FontStyle,
  color: Color,
  rootClassName: ClassName,
  whiteSpace: WhiteSpace
}

export default withStyles(styles)(Text)
