import { call, put, all } from 'redux-saga/effects'

import * as types from '../actions'

import { emailNotificationService } from '../services'

import { transformMeta } from 'utils/tableUtils'

function* getEmailNotificationItems({ payload: params }) {
  try {
    const response = yield call(
      emailNotificationService.getEmailNotificationItems,
      params
    )
    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_EMAIL_NOTIFICATION_ITEMS_SUCCESS,
      response,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_EMAIL_NOTIFICATION_ITEMS_ERROR,
      payload: error
    })
  }
}

function* getEmailNotificationById({ id }) {
  try {
    const response = yield call(
      emailNotificationService.getEmailNotificationById,
      id
    )
    yield put({
      type: types.GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_EMAIL_NOTIFICATION_BY_ID_ERROR,
      payload: error
    })
  }
}

function* postEmailNotificationItem({ payload }) {
  try {
    yield call(emailNotificationService.postEmailNotificationItem, payload)
    yield put({
      type: types.POST_EMAIL_NOTIFICATION_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_EMAIL_NOTIFICATION_ITEM_ERROR,
      payload: error
    })
  }
}

function* putEmailNotificationItem({ payload, id }) {
  try {
    yield call(emailNotificationService.putEmailNotificationItem, id, payload)
    yield put({
      type: types.PUT_EMAIL_NOTIFICATION_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_EMAIL_NOTIFICATION_ITEM_ERROR,
      payload: error
    })
  }
}

function* deleteEmailNotificationItem({ id }) {
  try {
    yield call(emailNotificationService.deleteEmailNotificationItem, id)
    yield put({
      type: types.DELETE_EMAIL_NOTIFICATION_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_EMAIL_NOTIFICATION_ITEM_ERROR,
      payload: error
    })
  }
}

function* deleteSelectedEmailNotification({ ids }) {
  try {
    yield all(
      ids.map(id =>
        call(emailNotificationService.deleteEmailNotificationItem, id)
      )
    )
    yield put({
      type: types.DELETE_SELECTED_EMAIL_NOTIFICATION_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_EMAIL_NOTIFICATION_ERROR,
      payload: error
    })
  }
}

function* getEmailBuilderData() {
  try {
    const response = yield call(emailNotificationService.getEmailBuilderData)
    yield put({
      type: types.GET_EMAIL_BUILDER_DATA_SUCCESS,
      response
    })
  } catch (error) {
    yield put({
      type: types.GET_EMAIL_BUILDER_DATA_ERROR,
      payload: error
    })
  }
}

function* getEmailReceiverData({ payload, params }) {
  try {
    const response = yield call(
      emailNotificationService.getEmailReceiverData,
      payload,
      params
    )
    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_EMAIL_RECEIVER_DATA_SUCCESS,
      response,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_EMAIL_RECEIVER_DATA_ERROR,
      payload: error
    })
  }
}

const emailNotificationSaga = {
  getEmailNotificationItems,
  postEmailNotificationItem,
  getEmailBuilderData,
  getEmailReceiverData,
  getEmailNotificationById,
  putEmailNotificationItem,
  deleteEmailNotificationItem,
  deleteSelectedEmailNotification
}

export default emailNotificationSaga
