import { call, put } from 'redux-saga/effects'

import * as types from 'actions/index'
import { designGalleryService, fontsService } from 'services/index'
import { transformMeta } from 'utils/tableUtils'
import _get from 'lodash/get'
import { mediaService } from '../services'

function* postDesignGallery(action) {
  try {
    const response = yield call(
      designGalleryService.postDesignGallery,
      action.data
    )
    yield put({
      type: types.POST_DESIGN_GALLERY_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.POST_DESIGN_GALLERY_ERROR,
      payload: error
    })
  }
}

function* putDesignGallery({ payload: { autoSave, ...payload } }) {
  const meta = { autoSave }
  try {
    let response = []
    if (payload.isClone) {
      response = yield call(mediaService.cloneMediaAndUpdate, {
        data: { ...payload.data, mediaId: payload.mediaId },
        id: payload.id
      })
    } else {
      response = yield call(designGalleryService.putDesignGallery, payload)
    }
    yield put({
      type: types.PUT_DESIGN_GALLERY_SUCCESS,
      payload: response,
      meta
    })
  } catch (error) {
    yield put({
      type: types.PUT_DESIGN_GALLERY_ERROR,
      payload: error,
      meta
    })
  }
}

function* getDesignGallery({ data, isEdit }) {
  try {
    const response = yield call(designGalleryService.getDesignGallery, data)
    yield put({
      type: types.GET_DESIGN_GALLERY_SUCCESS,
      payload: response,
      isEdit
    })
  } catch (error) {
    yield put({
      type: types.GET_DESIGN_GALLERY_ERROR,
      payload: error
    })
  }
}

// images

function* getPatterns(action) {
  try {
    const response = yield call(designGalleryService.getPatterns, action.data)

    yield put({
      type: types.GET_PATTERNS_SUCCESS,
      meta: action.data,
      payload: response
    })
  } catch (e) {
    yield put({
      type: types.GET_PATTERNS_ERROR,
      payload: e
    })
  }
}

function* getBackgroundImages(action) {
  try {
    const response = yield call(
      designGalleryService.getBackgroundImages,
      action.data
    )

    yield put({
      type: types.GET_BACKGROUND_IMAGES_SUCCESS,
      action,
      payload: response
    })
  } catch (e) {
    yield put({
      type: types.GET_BACKGROUND_IMAGES_ERROR,
      payload: e
    })
  }
}

function* getShapes(action) {
  try {
    const response = yield call(designGalleryService.getShapes, action.data)
    yield put({
      type: types.GET_SHAPES_SUCCESS,
      meta: action.data,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({
      type: types.GET_SHAPES_ERROR,
      payload: e
    })
  }
}

function* getObjects(action) {
  try {
    const response = yield call(designGalleryService.getObjects, action.data)
    yield put({
      type: types.GET_OBJECTS_SUCCESS,
      meta: action.data,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({
      type: types.GET_OBJECTS_ERROR,
      e
    })
  }
}

function* getIcons(action) {
  try {
    const response = yield call(designGalleryService.getIcons, action.data)
    yield put({
      type: types.GET_ICONS_SUCCESS,
      meta: action.data,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({
      type: types.GET_ICONS_ERROR,
      e
    })
  }
}

function* getIconsGroup(action) {
  try {
    const response = yield call(designGalleryService.getIconsGroup, action.data)
    yield put({
      type: types.GET_ICONS_GROUP_SUCCESS,
      action,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: types.GET_ICONS_GROUP_ERROR,
      e
    })
  }
}

function* getEmojis(action) {
  const response = yield call(designGalleryService.getEmojis, action.data)
  try {
    yield put({
      type: types.GET_EMOJIS_SUCCESS,
      meta: action.data,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({ type: types.GET_EMOJIS_ERROR, payload: e })
  }
}

function* getPexelBackgroundImages(action) {
  try {
    const { photos, page } = yield call(
      designGalleryService.getPexelBackgroundImages,
      action.data,
      action.meta
    )

    yield put({
      type: types.GET_PEXEL_BACKGROUND_IMAGES_SUCCESS,
      meta: action.data,
      payload: {
        response: photos,
        meta: { currentPage: page }
      }
    })
  } catch (error) {
    yield put({ type: types.GET_PEXEL_BACKGROUND_IMAGES_ERROR, payload: error })
  }
}

function* setSelectedBg(action) {
  yield put({
    type: types.SET_SELECTED_BG_SUCCESS,
    data: action.data
  })
}

function* getStockImages({ params }) {
  try {
    const { data, meta } = yield call(
      designGalleryService.getStockImages,
      params
    )
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_STOCK_IMAGES_SUCCESS,
      payload: data,
      meta: modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_STOCK_IMAGES_ERROR, payload: error })
  }
}

function* getRoyaltyImages(action) {
  try {
    const response = yield call(
      designGalleryService.getRoyaltyImages,
      action.data,
      action.appConfig
    )
    yield put({
      type: types.GET_ROYALTY_IMAGES_SUCCESS,
      action,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_ROYALTY_IMAGES_ERROR, payload: error })
  }
}

function* getDesigns(action) {
  try {
    const response = yield call(designGalleryService.getDesigns, action.data)
    yield put({
      type: types.GET_DESIGNS_SUCCESS,
      payload: {
        data: response.data,
        meta: transformMeta(response.meta)
      }
    })
  } catch (e) {
    yield put({ type: types.GET_DESIGNS_ERROR })
  }
}

function* getFavoriteDesign(action) {
  try {
    const response = yield call(
      designGalleryService.getFavoriteDesign,
      action.data
    )
    yield put({
      type: types.GET_FAVORITE_SUCCESS,
      payload: {
        data: response.data,
        meta: transformMeta(response.meta)
      }
    })
  } catch (e) {
    yield put({ type: types.GET_FAVORITE_ERROR })
  }
}

function* getStockDesigns(action) {
  try {
    const response = yield call(
      designGalleryService.getStockDesigns,
      action.data
    )
    yield put({
      type: types.GET_STOCK_DESIGNS_SUCCESS,
      payload: {
        data: response.data,
        meta: transformMeta(response.meta)
      }
    })
  } catch (e) {
    yield put({ type: types.GET_STOCK_DESIGNS_ERROR })
  }
}

function* getSharedDesigns(action) {
  try {
    const response = yield call(
      designGalleryService.getSharedDesigns,
      action.data
    )
    yield put({
      type: types.GET_SHARED_DESIGNS_SUCCESS,
      payload: {
        data: _get(response, 'data', []),
        meta: transformMeta(_get(response, 'meta', {}))
      }
    })
  } catch (e) {
    yield put({ type: types.GET_SHARED_DESIGNS_ERROR })
  }
}

function* getFonts({ payload }) {
  try {
    const response = yield call(fontsService.getFonts, payload)

    //apply loaded fonts
    yield put({ type: types.GET_FONTS_SUCCESS, payload: response })
    yield put({
      type: types.GET_DESIGN_GALLERY_FONTS_SUCCESS,
      meta: payload,
      payload: {
        response: response.data,
        meta: response.meta
      }
    })
  } catch (e) {
    yield put({ type: types.GET_DESIGN_GALLERY_FONTS_ERROR })
  }
}

const designGallerySaga = {
  postDesignGallery,
  putDesignGallery,
  getDesignGallery,

  // images
  getPatterns,
  getPexelBackgroundImages,
  getBackgroundImages,
  setSelectedBg,
  getShapes,
  getObjects,
  getIcons,
  getEmojis,
  getStockImages,
  getRoyaltyImages,
  getIconsGroup,
  // designs
  getDesigns,
  getStockDesigns,
  getSharedDesigns,
  getFonts,
  getFavoriteDesign
}

export default designGallerySaga
