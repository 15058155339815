import update from 'immutability-helper'

import * as types from '../actions'
import {
  initialState as defaultInitialState,
  putInitialState,
  postInitialState,
  deleteInitialState,
  shapeOfBody
} from '../constants/initialLibraryState'
import { MENU_ITEMS, STATIONS, LOCATIONS } from 'constants/menuMakerConstants'

const initialState = {
  [MENU_ITEMS]: {
    ...defaultInitialState,
    postBulk: postInitialState
  },
  [LOCATIONS]: {
    ...defaultInitialState
  },
  [STATIONS]: {
    ...defaultInitialState
  },
  integration: {
    items: {
      ...shapeOfBody,
      isFetching: false
    },
    connect: {
      ...shapeOfBody
    },
    disconnect: {
      ...shapeOfBody
    },
    sync: {
      ...shapeOfBody
    }
  },
  providers: {
    ...shapeOfBody,
    isFetching: false
  }
}

const menuMakerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MENU_ITEMS:
      return update(state, {
        [MENU_ITEMS]: {
          items: {
            meta: {
              isLoading: { $set: true }
            }
          }
        }
      })
    case types.GET_MENU_ITEMS_SUCCESS:
      return update(state, {
        [MENU_ITEMS]: {
          items: {
            response: { $set: action.response },
            meta: {
              $set: action.modifiedMeta
            }
          }
        }
      })
    case types.GET_MENU_ITEMS_ERROR:
      return update(state, {
        [MENU_ITEMS]: {
          items: {
            error: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_ITEM_SUCCESS:
      return update(state, {
        [MENU_ITEMS]: {
          post: {
            response: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_ITEM_ERROR:
      return update(state, {
        [MENU_ITEMS]: {
          post: {
            error: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_ITEM_SUCCESS:
    case types.DELETE_SELECTED_MENU_ITEMS_SUCCESS:
      return update(state, {
        [MENU_ITEMS]: {
          del: {
            response: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_ITEM_ERROR:
    case types.DELETE_SELECTED_MENU_ITEMS_ERROR:
      return update(state, {
        [MENU_ITEMS]: {
          del: {
            error: { $set: action.payload }
          }
        }
      })
    case types.GET_MENU_ITEM_BY_ID_SUCCESS:
      return update(state, {
        [MENU_ITEMS]: {
          item: {
            response: { $set: action.payload }
          }
        }
      })
    case types.GET_MENU_ITEM_BY_ID_ERROR:
      return update(state, {
        [MENU_ITEMS]: {
          item: {
            error: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_ITEM_SUCCESS:
      return update(state, {
        [MENU_ITEMS]: {
          put: {
            response: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_ITEM_ERROR:
      return update(state, {
        [MENU_ITEMS]: {
          put: {
            error: { $set: action.payload }
          }
        }
      })
    case types.CLEAR_MENU_ITEM_RESPONSE_INFO:
      return update(state, {
        [MENU_ITEMS]: {
          put: {
            $set: {
              ...putInitialState
            }
          },
          post: {
            $set: {
              ...postInitialState
            }
          },
          del: {
            $set: {
              ...deleteInitialState
            }
          }
        }
      })
    case types.POST_MENU_MAKER_ITEM_BULK_SUCCESS:
      return update(state, {
        [MENU_ITEMS]: {
          postBulk: {
            response: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_MAKER_ITEM_BULK_ERROR:
      return update(state, {
        [MENU_ITEMS]: {
          postBulk: {
            error: { $set: action.payload }
          }
        }
      })
    case types.CLEAR_POST_MENU_MAKER_ITEM_BULK_INFO:
      return update(state, {
        [MENU_ITEMS]: {
          postBulk: {
            $set: postInitialState
          }
        }
      })
    case types.GET_MENU_LOCATIONS:
      return update(state, {
        [LOCATIONS]: {
          items: {
            meta: {
              isLoading: { $set: true }
            }
          }
        }
      })
    case types.GET_MENU_LOCATIONS_SUCCESS:
      return update(state, {
        [LOCATIONS]: {
          items: {
            response: { $set: action.response },
            meta: {
              $set: action.modifiedMeta
            }
          }
        }
      })
    case types.GET_MENU_LOCATIONS_ERROR:
      return update(state, {
        [LOCATIONS]: {
          items: {
            error: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_LOCATION_SUCCESS:
      return update(state, {
        [LOCATIONS]: {
          post: {
            response: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_LOCATION_ERROR:
      return update(state, {
        [LOCATIONS]: {
          post: {
            error: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_LOCATION:
      return update(state, {
        [LOCATIONS]: {
          del: {
            id: { $set: action.id },
            name: { $set: action.name }
          }
        }
      })
    case types.DELETE_MENU_LOCATION_SUCCESS:
    case types.DELETE_SELECTED_MENU_LOCATIONS_SUCCESS:
      return update(state, {
        [LOCATIONS]: {
          del: {
            response: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_LOCATION_ERROR:
    case types.DELETE_SELECTED_MENU_LOCATIONS_ERROR:
      return update(state, {
        [LOCATIONS]: {
          del: {
            error: { $set: action.payload },
            ids: { $set: action.ids }
          }
        }
      })
    case types.GET_MENU_LOCATION_BY_ID_SUCCESS:
      return update(state, {
        [LOCATIONS]: {
          item: {
            response: { $set: action.payload }
          }
        }
      })
    case types.GET_MENU_LOCATION_BY_ID_ERROR:
      return update(state, {
        [LOCATIONS]: {
          item: {
            error: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_LOCATION_SUCCESS:
      return update(state, {
        [LOCATIONS]: {
          put: {
            response: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_LOCATION_ERROR:
      return update(state, {
        [LOCATIONS]: {
          put: {
            error: { $set: action.payload }
          }
        }
      })
    case types.CLEAR_MENU_LOCATION_RESPONSE_INFO:
      return update(state, {
        [LOCATIONS]: {
          put: {
            $set: {
              ...putInitialState
            }
          },
          post: {
            $set: {
              ...postInitialState
            }
          },
          del: {
            $set: {
              ...deleteInitialState
            }
          }
        }
      })

    case types.GET_MENU_STATIONS:
      return update(state, {
        [STATIONS]: {
          items: {
            meta: {
              isLoading: { $set: true }
            }
          }
        }
      })
    case types.GET_MENU_STATIONS_SUCCESS:
      return update(state, {
        [STATIONS]: {
          items: {
            response: { $set: action.response },
            meta: {
              $set: action.modifiedMeta
            }
          }
        }
      })
    case types.GET_MENU_STATIONS_ERROR:
      return update(state, {
        [STATIONS]: {
          items: {
            error: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_STATION_SUCCESS:
      return update(state, {
        [STATIONS]: {
          post: {
            response: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_STATION_ERROR:
      return update(state, {
        [STATIONS]: {
          post: {
            error: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_STATION_SUCCESS:
    case types.DELETE_SELECTED_MENU_STATIONS_SUCCESS:
      return update(state, {
        [STATIONS]: {
          del: {
            response: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_STATION_ERROR:
    case types.DELETE_SELECTED_MENU_STATIONS_ERROR:
      return update(state, {
        [STATIONS]: {
          del: {
            error: { $set: action.payload }
          }
        }
      })
    case types.GET_MENU_STATION_BY_ID_SUCCESS:
      return update(state, {
        [STATIONS]: {
          item: {
            response: { $set: action.payload }
          }
        }
      })
    case types.GET_MENU_STATION_BY_ID_ERROR:
      return update(state, {
        [STATIONS]: {
          item: {
            error: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_STATION_SUCCESS:
      return update(state, {
        [STATIONS]: {
          put: {
            response: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_STATION_ERROR:
      return update(state, {
        [STATIONS]: {
          put: {
            error: { $set: action.payload }
          }
        }
      })
    case types.CLEAR_MENU_STATION_RESPONSE_INFO:
      return update(state, {
        [STATIONS]: {
          put: {
            $set: {
              ...putInitialState
            }
          },
          post: {
            $set: {
              ...postInitialState
            }
          },
          del: {
            $set: {
              ...deleteInitialState
            }
          }
        }
      })
    case types.POST_MENU_INTEGRATION_CONNECT_SUCCESS:
      return update(state, {
        integration: {
          connect: {
            $set: {
              response: action.payload
            }
          }
        }
      })
    case types.POST_MENU_INTEGRATION_CONNECT_ERROR:
      return update(state, {
        integration: {
          connect: {
            error: {
              $set: action.payload
            }
          }
        }
      })
    case types.POST_MENU_INTEGRATION_DISCONNECT_SUCCESS:
      return update(state, {
        integration: {
          disconnect: {
            $set: {
              response: action.payload
            }
          }
        }
      })
    case types.POST_MENU_INTEGRATION_DISCONNECT_ERROR:
      return update(state, {
        integration: {
          disconnect: {
            error: {
              $set: action.payload
            }
          }
        }
      })
    case types.POST_MENU_INTEGRATION_SYNC_SUCCESS:
      return update(state, {
        integration: {
          sync: {
            $set: {
              response: action.payload
            }
          }
        }
      })
    case types.POST_MENU_INTEGRATION_SYNC_ERROR:
      return update(state, {
        integration: {
          sync: {
            error: {
              $set: action.payload
            }
          }
        }
      })
    case types.GET_MENU_INTEGRATIONS:
      return update(state, {
        integration: {
          items: {
            isFetching: {
              $set: true
            }
          }
        }
      })
    case types.GET_MENU_INTEGRATIONS_SUCCESS:
      return update(state, {
        integration: {
          items: {
            $set: {
              response: action.payload,
              isFetching: false
            }
          }
        }
      })
    case types.GET_MENU_INTEGRATIONS_ERROR:
      return update(state, {
        integration: {
          items: {
            error: {
              $set: action.payload
            },
            isFetching: {
              $set: false
            }
          }
        }
      })
    case types.GET_MENU_MAKER_PROVIDERS:
      return update(state, {
        providers: {
          isFetching: {
            $set: true
          }
        }
      })
    case types.GET_MENU_MAKER_PROVIDERS_SUCCESS:
      return update(state, {
        providers: {
          $set: {
            response: action.payload,
            isFetching: false
          }
        }
      })
    case types.GET_MENU_MAKER_PROVIDERS_ERROR:
      return update(state, {
        providers: {
          error: {
            $set: action.payload
          },
          isFetching: {
            $set: false
          }
        }
      })
    case types.CLEAR_MENU_INTEGRATION_INFO:
      return update(state, {
        integration: {
          connect: {
            $set: {
              ...shapeOfBody
            }
          },
          disconnect: {
            $set: {
              ...shapeOfBody
            }
          },
          sync: {
            $set: {
              ...shapeOfBody
            }
          }
        }
      })
    default:
      return state
  }
}

export default menuMakerReducer
