import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import Container from 'components/Containers/Container'
import UpgradeInfo from './UpgradeInfo'
import { ReactComponent as CloseIcon } from 'common/assets/icons/closeIcon.svg'
import { ReactComponent as LineIcon } from 'common/assets/icons/lineIcons.svg'
import { ReactComponent as ContentModerationIcon } from 'common/assets/icons/contentModeration.svg'
import { ReactComponent as CameraFeedIcon } from 'common/assets/icons/cameraFeed.svg'
import { ReactComponent as BellIcon } from 'common/assets/icons/bellIcon.svg'
import { ReactComponent as SignageNOCIcon } from 'common/assets/icons/deviceNOC.svg'
import { ReactComponent as ContentApprovalIcon } from 'common/assets/icons/contentApproval.svg'
import { ReactComponent as GroupIcon } from 'common/assets/icons/group.svg'
import { ReactComponent as EmailToScreenIcon } from 'common/assets/icons/emailToScreen.svg'
import { ReactComponent as ForexBoardIcon } from 'common/assets/icons/forexBoard.svg'
import { ReactComponent as SmartPlaylistIcon } from 'common/assets/icons/smartPlaylist.svg'
import { ReactComponent as UserActivityicon } from 'common/assets/icons/userActivity.svg'
import { ReactComponent as BrandGuideControlIcon } from 'common/assets/icons/brandGuideControl.svg'
import { ReactComponent as SecureFTPIcon } from 'common/assets/icons/secureFTP.svg'
import { ReactComponent as ReportsIcon } from 'common/assets/icons/reports.svg'
import { ReactComponent as CourtDocketsIcon } from 'common/assets/icons/courtDocket.svg'
import backgroundBottomImage from 'common/assets/images/upgradeBottomImg.png'

const styles = ({ palette, type }) => {
  return {
    container: {
      width: '100%',
      height: '100%',
      padding: '10px 20px 0px'
    },
    close: {
      width: '46px',
      height: '46px',
      margin: '0px',
      padding: '0px',
      cursor: 'pointer',
      color: palette[type].dialog.closeButton
    },
    infoWrapper: {
      background: '#011875',
      position: 'relative'
    },
    webpageWrapper: {
      position: 'relative',
      height: '100%',
      overflow: 'hidden',
      backgroundColor: '#fff'
    },
    webPlanData: {
      padding: '32px',
      background: 'linear-gradient(180deg, #F0F3FF 0%, #FFEFF7 100%)',
      position: 'relative'
    },
    mainWrapper: {
      position: 'relative'
    },
    svgIcon: {
      position: 'absolute',
      cursor: 'pointer',
      top: '16px',
      right: '16px'
    },
    webTitle: {
      fontSize: '16px',
      lineHeight: '22px',
      FontWeight: 300,
      color: '#011875',
      margin: '0',
      paddingBottom: '12px'
    },
    mainHeading: {
      fontSize: '24px',
      lineHeight: '22px',
      FontWeight: 700,
      color: '#011875',
      margin: '0'
    },
    closeBtn: {
      height: '44px',
      width: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '25px',
      right: '25px',
      cursor: 'pointer'
    },
    mainContainerWrapper: {
      gap: '0',
      gridTemplateColumns: '1fr 398px',
      gridTemplateRows: '640px'
    },
    iframeWrapper: {
      height: '100%',
      width: '100%'
    },
    webdataDetails: {
      padding: ' 20px 32px',
      display: 'flex',
      gap: '32px'
    },
    webAllDataWrap: {
      flex: '1'
    },
    headingRequire: {
      fontSize: '12px',
      lineHeight: '16px',
      FontWeight: 400,
      color: '#011875',
      margin: '0',
      paddingTop: '30px'
    },
    svgBOx: {
      width: '32px',
      height: '32px',
      // background: 'linear-gradient(180deg, #7A43B4 0%, #60348D 100%)',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    heading: {
      fontSize: '14px',
      lineHeight: '22px',
      FontWeight: 400,
      color: '#011875',
      margin: '0'
    },
    bgImages: {
      position: 'absolute',
      left: '0',
      bottom: '0',
      zIndex: '0',
      backgroundImage: `url(${backgroundBottomImage})`,
      width: '100%',
      height: '100%',
      backgroundPosition: '-10px 100% ',
      backgroundRepeat: 'no-repeat'
    },
    svgDataWrap: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
      borderBottom: '1px solid #bcbcbc57',
      padding: '8px 0px',
      '& svg': {
        width: '16px',
        heading: '16px'
      }
    }
  }
}

const UpgradeForm = ({ t, classes, closeModal }) => {
  const leftListColumnItems = [
    {
      icon: <LineIcon />,
      iconBgColor: 'linear-gradient(180deg, #7A43B4 0%, #60348D 100%)',
      text: t('Application Health')
    },
    {
      icon: <BrandGuideControlIcon />,
      iconBgColor:
        'linear-gradient(180deg, #6011F2 0%, rgba(76, 13, 195, 0.94902) 100%)',
      text: t('Brand Guide Controls')
    },
    {
      icon: <CameraFeedIcon />,
      iconBgColor: 'linear-gradient(180deg, #FFE271 0%, #EBC944 100%)',
      text: t('Camera Feed Integration')
    },
    {
      icon: <ContentApprovalIcon />,
      iconBgColor: 'linear-gradient(180deg, #E4EAFE 0%, #D7DDF4 100%)',
      text: t('Content Approval')
    },
    {
      icon: <ContentModerationIcon />,
      iconBgColor: 'linear-gradient(180deg, #FFCBAE 0%, #FFAF83 100%)',
      text: 'Content Moderation'
    },
    {
      icon: <CourtDocketsIcon />,
      iconBgColor: 'linear-gradient(180deg, #D6FFFF 0%, #B2EBF2 100%)',
      text: t('Court Dockets')
    },
    {
      icon: <GroupIcon />,
      iconBgColor: 'linear-gradient(180deg, #FFE636 0%, #FBC02D 100%)',
      text: t('Donor Walls')
    },
    {
      icon: <BellIcon />,
      iconBgColor: 'linear-gradient(180deg, #60B391 0%, #439F83 100%)',
      text: t('Device Offline Notification')
    }
  ]

  const rightListColumnItems = [
    {
      icon: <EmailToScreenIcon />,
      iconBgColor: 'linear-gradient(180deg, #FF591E 0%, #E74B1A 100%)',
      text: t('Email to Screen')
    },
    {
      icon: <ForexBoardIcon />,
      iconBgColor: 'linear-gradient(180deg, #7396A7 0%, #607D8B 100%)',
      text: t('FOREX Board')
    },
    {
      icon: <ReportsIcon />,
      iconBgColor: 'linear-gradient(180deg, #37474F 0%, #2E3C43 100%)',
      text: t('Proof of Play Reports')
    },
    {
      icon: <SecureFTPIcon />,
      iconBgColor: 'linear-gradient(180deg, #D61B3C 0.52%, #BB1936 100%)',
      text: t('Secure FTP (SFTP)')
    },
    {
      icon: <SignageNOCIcon />,
      iconBgColor: 'linear-gradient(180deg, #FF7634 0%, #FF4D02 100%)',
      text: t('Signage Network NOC')
    },
    {
      icon: <SmartPlaylistIcon />,
      iconBgColor: 'linear-gradient(180deg, #60FFE9 0%, #50E3C2 100%)',
      text: t('Smart Playlists')
    },
    {
      icon: <UserActivityicon />,
      iconBgColor:
        'linear-gradient(180deg, #EC5FA2 0%, rgba(210, 84, 144, 0.94902) 100%)',
      text: t('User Activity Logs')
    }
  ]

  return (
    <>
      <div className={classes.mainWrapper}>
        <Container customClass={classes.mainContainerWrapper} cols={'2-1'}>
          <div className={classes.webpageWrapper}>
            <div className={classes.bgImages}></div>
            <div className={classes.webPlanData}>
              <h4 className={classes.webTitle}>
                {t(`You are on the Standard Plan`)}
              </h4>
              <h2 className={classes.mainHeading}>
                {t(`Discover our Premium Features`)}
              </h2>
              <div className={classes.svgIcon} onClick={closeModal}>
                <CloseIcon />
              </div>
            </div>
            <div className={classes.webdataDetails}>
              <div className={classes.webAllDataWrap}>
                {leftListColumnItems.map((listItem, index) => (
                  <div key={index} className={classes.svgDataWrap}>
                    <div
                      style={{
                        background: listItem.iconBgColor,
                        color: listItem.color
                      }}
                      className={classes.svgBOx}
                    >
                      {listItem.icon}
                    </div>
                    <h5 className={classes.heading}>{listItem.text}</h5>
                  </div>
                ))}
              </div>
              <div className={classes.webAllDataWrap}>
                {rightListColumnItems.map((listItem, index) => (
                  <div key={index} className={classes.svgDataWrap}>
                    <div
                      style={{
                        background: listItem.iconBgColor,
                        color: listItem.color
                      }}
                      className={classes.svgBOx}
                    >
                      {listItem.icon}
                    </div>
                    <h5 className={classes.heading}>{listItem.text}</h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <UpgradeInfo closeModal={closeModal} />
        </Container>
      </div>
    </>
  )
}
export default withTranslation('translations')(withStyles(styles)(UpgradeForm))
