import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from '@reduxjs/toolkit'
import { withStyles } from '@material-ui/core'

import DefaultModal from './DefaultModal'
import BackgroundImageCard from 'components/Card/BackgroundImageCard'
import Container from 'components/Containers/Container'
import Spacing from 'components/Containers/Spacing'
import BasePaginate from 'components/BasePaginate'
import { CircularLoader } from 'components/Loaders'
import Scrollbars from 'components/Scrollbars'
import useConfigFeatureMedia from 'hooks/api/useConfigFeatureMedia'
import { getThumbnail } from 'utils/mediaUtils'
import { useLazyGetMediaGridEmbeddedItemsQuery } from 'api/mediaApi'
import { IMAGE_FEATURE_ID } from 'constants/featureConstants'
import { approveStatuses } from 'constants/library'
import { initialMeta } from 'constants/api'

const styles = () => ({
  root: {
    paddingRight: 20
  },
  footer: {
    display: 'none'
  },
  imageWrapper: {
    height: 170
  },
  modalContent: {
    padding: '10px 0px 0px 20px'
  }
})

const SelectMediaImageDialog = ({
  open,
  onSelect,
  onCloseModal,
  classes,
  t
}) => {
  const [
    getBackgroundImagesFromMedia,
    mediaQuery
  ] = useLazyGetMediaGridEmbeddedItemsQuery()
  const { response, meta, isLoading } = useMemo(
    () => ({
      response: mediaQuery.data?.data || [],
      meta: mediaQuery.data?.meta || initialMeta,
      isLoading: mediaQuery.isFetching || mediaQuery.isUninitialized
    }),
    [mediaQuery]
  )

  const configFeatureMedia = useConfigFeatureMedia(false, [], { includeAll: 1 })

  const imageFeatureId = useMemo(
    () =>
      configFeatureMedia?.response?.find(({ name }) => name === 'Image')?.id ||
      IMAGE_FEATURE_ID,
    [configFeatureMedia]
  )

  const handlePageChange = useCallback(
    ({ selected }) => {
      getBackgroundImagesFromMedia({
        limit: 6,
        page: selected + 1,
        featureId: imageFeatureId,
        approvedStatus: approveStatuses.approve
      })
    },
    [getBackgroundImagesFromMedia, imageFeatureId]
  )

  const handleSelectImage = useCallback(
    ({ target: { value } }) => {
      const media = response.find(media => media.id === value)
      if (media) {
        onSelect(media)
      }
    },
    [onSelect, response]
  )

  useEffect(
    () => {
      if (!response.length) {
        getBackgroundImagesFromMedia({
          limit: 6,
          featureId: imageFeatureId,
          approvedStatus: approveStatuses.approve
        })
      }
    },
    // eslint-disable-next-line
    [imageFeatureId]
  )

  return (
    <DefaultModal
      open={open}
      onCloseModal={onCloseModal}
      maxWidth="md"
      modalTitle={t('Select Media Image')}
      footerClassName={classes.footer}
      contentClass={classes.modalContent}
    >
      <Spacing relative>
        {!response.length && isLoading && <CircularLoader />}
        <Scrollbars autoHeight autoHeightMax={'calc(100vh - 200px)'}>
          <Container cols="3" customClass={classes.root}>
            {response.map(item => (
              <BackgroundImageCard
                key={item.id}
                title={item.title}
                src={getThumbnail(item)}
                id={item.id}
                onSelectImage={handleSelectImage}
                imageWrapperClass={classes.imageWrapper}
                isLoading={isLoading}
                maxTitleWidth={170}
              />
            ))}
          </Container>
        </Scrollbars>
      </Spacing>
      {meta.lastPage > 1 && (
        <Spacing alignItems="center">
          <BasePaginate
            page={meta.currentPage || 1}
            pageCount={meta.lastPage}
            onPageChange={handlePageChange}
          />
        </Spacing>
      )}
    </DefaultModal>
  )
}

SelectMediaImageDialog.propTypes = {
  open: PropTypes.bool,
  onSelect: PropTypes.func,
  onCloseModal: PropTypes.func
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(SelectMediaImageDialog)
