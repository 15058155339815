import classNames from 'classnames'
import React, { Component } from 'react'

import { CircleIconButton } from '../Buttons'
import Tooltip from 'components/Tooltip'

class AccountNavigationLink extends Component {
  constructor(props) {
    super(props)
    this.linkRef = React.createRef()
  }
  state = {
    anchorEl: null
  }

  handleHover = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const {
      linkIconClassName,
      iconButtonClassName,
      handleClick = f => f,
      tooltipTitle,
      tooltipHeader
    } = this.props

    const circleButton = (
      <CircleIconButton
        className={classNames('hvr-grow', iconButtonClassName)}
        ref={this.linkRef}
      >
        <i className={linkIconClassName} />
      </CircleIconButton>
    )

    return (
      <div
        onClick={() => {
          handleClick()
          this.linkRef.current?.blur()
        }}
        onMouseOver={this.handleHover}
        onMouseLeave={this.handleClose}
      >
        {tooltipTitle && tooltipHeader ? (
          <Tooltip
            title={tooltipTitle}
            headerText={tooltipHeader}
            arrowWithHeaderColor
            placement="bottom"
            withHeader
            arrow
          >
            {circleButton}
          </Tooltip>
        ) : (
          circleButton
        )}
      </div>
    )
  }
}

export default AccountNavigationLink
