import { getFormattedDateWithTimeZone } from '../date'

export function dataToPreviews(previews) {
  if (!previews) {
    return previews
  }
  return previews.map(({ lastUpdate, thumbUri, uri, ...preview }) => ({
    ...preview,
    lastUpdate,
    uri: uri || thumbUri,
    lastUpdateFormatted: lastUpdate
      ? getFormattedDateWithTimeZone(lastUpdate, 'MMM DD, YYYY hh:mmA')
      : 'N/A'
  }))
}
