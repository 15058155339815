import { _concat, _get } from 'utils/lodash'
import update from 'immutability-helper'

import * as types from 'actions'
import {
  DEFAULT_FONT_SIZE,
  DESIGN_TYPES,
  TABS_NAMES,
  previewGridTypes
} from 'constants/canvasConstants'
import { shapeOfBodyWithMeta } from 'constants/initialLibraryState'
import {
  convertDataToBackgroundImages,
  convertDataToEmojis,
  convertDataToIconGroups,
  convertDataToIcons,
  convertDataToObjects,
  convertDataToPattern,
  convertDataToShape,
  convertToPreviewData
} from 'utils/designGalleryUtils'
import {
  GET_DESIGNS_ERROR,
  GET_EMOJIS_ERROR,
  GET_SHARED_DESIGNS_ERROR,
  GET_STOCK_DESIGNS_ERROR
} from 'actions'

const shape = {
  response: [],
  meta: {},
  isFetching: true
}

const initialState = {
  patterns: {
    ...shape,
    filter: {
      name: ''
    }
  },
  pexelBackgroundImages: {
    ...shape,
    filter: {
      query: 'business, coffee,'
    }
  },
  backgroundImages: {
    ...shape
  },
  photosPage: 0,
  shapes: {
    ...shape
  },
  icons: {
    ...shape
  },
  iconsGroup: {
    ...shape
  },
  emojis: {
    ...shape
  },
  objects: {
    ...shape
  },
  fonts: {
    ...shape,
    filter: {
      family: ''
    }
  },
  stockImages: {
    ...shapeOfBodyWithMeta
  },
  royaltyImages: [],
  royaltyImagesPage: 1,
  royaltyImagesLastPage: 1,
  libraryImages: [],
  libraryImagesPage: 0,
  designs: [],
  designsPage: 1,
  designsFilter: '',
  shapesTabQuery: '',
  objectsTabQuery: '',
  backgroundsTabQuery: '',
  fontsTabQuery: '',
  imagesTabQuery: '',
  designsTabQuery: '',
  perPage: 40,
  isFetching: false,
  total_results: 0,
  myDesigns: shapeOfBodyWithMeta,
  stockDesigns: shapeOfBodyWithMeta,
  sharedDesigns: shapeOfBodyWithMeta,
  favoriteDesign: shapeOfBodyWithMeta,
  selectedDesign: undefined,
  missedFonts: []
}

// const setPreviousPage = (payload = [], page) => {
//   return !payload.length ? page - 1 : page
// }

const setSelectedById = (array, id) => {
  return array.map(el => ({ ...el, selected: id ? el.id === id : false }))
}
const leftSidebarReducer = (
  state = initialState,
  { action, data = {}, type, payload, meta: actionMeta }
) => {
  switch (type) {
    // PATTERNS
    case types.GET_PATTERNS:
      return update(state, {
        patterns: {
          isFetching: { $set: true },
          ...((data.name || '') !== state.patterns.filter.name && {
            response: { $set: [] }
          })
        }
      })
    case types.GET_PATTERNS_SUCCESS: {
      const patterns = convertDataToPattern(payload.data)

      const patternsArr =
        payload.meta.currentPage === 1
          ? patterns
          : _concat(state.patterns.response, patterns)

      return update(state, {
        patterns: {
          response: { $set: patternsArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false },
          filter: { $set: { name: actionMeta.name || '' } }
        }
      })
    }
    case types.GET_PATTERNS_ERROR:
      return update(state, {
        patterns: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    // SHAPES
    case types.GET_SHAPES:
      return update(state, {
        shapes: {
          isFetching: { $set: true },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_SHAPES_SUCCESS:
      const shapes = convertDataToShape(payload.response)

      const shapesArr =
        payload.meta.currentPage === 1
          ? shapes
          : _concat(state.shapes.response, shapes)
      return update(state, {
        shapes: {
          response: { $set: shapesArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_SHAPES_ERROR:
      return update(state, {
        shapes: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    case types.GET_OBJECTS:
      return update(state, {
        objects: {
          isFetching: { $set: true },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_OBJECTS_SUCCESS:
      const objects = convertDataToObjects(payload.response)

      const objectsArr =
        payload.meta.currentPage === 1
          ? objects
          : _concat(state.objects.response, objects)
      return update(state, {
        objects: {
          response: { $set: objectsArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_OBJECTS_ERROR:
      return update(state, {
        objects: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    // ICONS
    case types.GET_ICONS:
      return update(state, {
        icons: {
          isFetching: { $set: true },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_ICONS_SUCCESS:
      const icons = convertDataToIcons(payload.response)

      const iconsArr =
        payload.meta.currentPage === 1
          ? icons
          : _concat(state.icons.response, icons)

      return update(state, {
        icons: {
          response: { $set: iconsArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false }
        }
      })

    case types.GET_ICONS_ERROR:
      return update(state, {
        icons: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })
    case types.GET_ICONS_GROUP:
      return update(state, {
        iconsGroup: {
          isFetching: { $set: true }
        }
      })
    case types.GET_ICONS_GROUP_SUCCESS: {
      const iconsData = convertDataToIconGroups(payload)
      return update(state, {
        iconsGroup: {
          response: { $set: iconsData },
          isFetching: { $set: false }
        }
      })
    }
    case types.GET_ICONS_GROUP_ERROR:
      return update(state, {
        iconsGroup: {
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_ICONS:
      return update(state, {
        icons: { $set: shape }
      })

    // EMOJIS
    case types.GET_EMOJIS:
      return update(state, {
        emojis: {
          isFetching: { $set: true },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_EMOJIS_SUCCESS:
      const emojis = convertDataToEmojis(payload.response)

      const emojisArr =
        payload.meta === 1 ? emojis : _concat(state.emojis.response, emojis)

      return update(state, {
        emojis: {
          response: { $set: emojisArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_EMOJIS_ERROR:
      return update(state, {
        emojis: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    // FONTS
    case types.GET_DESIGN_GALLERY_FONTS:
      return update(state, {
        fonts: {
          isFetching: { $set: true },
          ...((data.family || '') !== state.fonts.filter.family && {
            response: { $set: [] }
          })
        }
      })
    case types.GET_DESIGN_GALLERY_FONTS_SUCCESS:
      const convertedFonts = payload.response.map(item => ({
        ...item,
        text: 'Text',
        fontSize: DEFAULT_FONT_SIZE,
        showAs: previewGridTypes.font
      }))

      const fonts =
        _get(payload, 'meta.currentPage') === 1
          ? convertedFonts
          : _concat(state.fonts.response, convertedFonts)

      return update(state, {
        fonts: {
          response: { $set: fonts },
          meta: { $set: payload.meta },
          isFetching: { $set: false },
          filter: { $set: { family: actionMeta.family || '' } }
        }
      })

    case types.GET_DESIGN_GALLERY_FONTS_ERROR:
      return update(state, {
        fonts: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    //DESIGNS
    case types.GET_DESIGNS:
      return update(state, {
        myDesigns: {
          meta: { $set: { isLoading: true } },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })

    case types.GET_FAVORITE_DESIGNS:
      return update(state, {
        favoriteDesign: {
          meta: { $set: { isLoading: true } },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })

    case types.GET_FAVORITE_SUCCESS: {
      const data = convertToPreviewData(
        payload.data,
        previewGridTypes.template,
        false,
        DESIGN_TYPES.Stock
      )
      return update(state, {
        favoriteDesign: {
          response: {
            $set:
              payload.meta?.currentPage > 1
                ? [...state.favoriteDesign.response, ...data]
                : data
          },
          meta: { $set: payload.meta }
        },
        isFetching: { $set: false }
      })
    }

    case types.GET_FAVORITE_ERROR:
      return update(state, {
        favoriteDesign: {
          meta: { $set: { isLoading: false } }
        }
      })

    case types.GET_STOCK_DESIGNS:
      return update(state, {
        stockDesigns: {
          meta: { $set: { isLoading: true } },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_SHARED_DESIGNS:
      return update(state, {
        sharedDesigns: {
          meta: { $set: { isLoading: true } },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_DESIGNS_SUCCESS: {
      const data = convertToPreviewData(
        payload.data,
        previewGridTypes.myDesign,
        false,
        DESIGN_TYPES.My
      )
      return update(state, {
        myDesigns: {
          response: {
            $set:
              payload.meta?.currentPage > 1
                ? [...state.myDesigns.response, ...data]
                : data
          },
          meta: { $set: payload.meta }
        },
        isFetching: { $set: false }
      })
    }
    case types.GET_STOCK_DESIGNS_SUCCESS: {
      const data = convertToPreviewData(
        _get(payload, 'data', []),
        previewGridTypes.template,
        false,
        DESIGN_TYPES.Stock
      )
      return update(state, {
        stockDesigns: {
          response: {
            $set:
              payload.meta?.currentPage > 1
                ? [...state.stockDesigns.response, ...data]
                : data
          },
          meta: { $set: payload.meta }
        },
        isFetching: { $set: false }
      })
    }
    case types.GET_SHARED_DESIGNS_SUCCESS: {
      const data = convertToPreviewData(
        _get(payload, 'data', []),
        previewGridTypes.template,
        false,
        DESIGN_TYPES.Shared
      )
      return update(state, {
        sharedDesigns: {
          response: {
            $set:
              payload.meta?.currentPage > 1
                ? [...state.sharedDesigns.response, ...data]
                : data
          },
          meta: { $set: payload.meta }
        },
        isFetching: { $set: false }
      })
    }
    case GET_DESIGNS_ERROR:
      return update(state, {
        myDesigns: {
          meta: { $set: { isLoading: false } }
        }
      })
    case GET_STOCK_DESIGNS_ERROR:
      return update(state, {
        stockDesigns: {
          meta: { $set: { isLoading: false } }
        }
      })
    case GET_SHARED_DESIGNS_ERROR:
      return update(state, {
        sharedDesigns: {
          meta: { $set: { isLoading: false } }
        }
      })
    case GET_EMOJIS_ERROR:
      return update(state, {
        isFetching: { $set: false }
      })
    // BACKGROUND IMAGES
    case types.GET_BACKGROUND_IMAGES:
      return {
        ...state,
        isFetching: true
      }
    case types.GET_BACKGROUND_IMAGES_SUCCESS:
      const backgroundImages = convertDataToBackgroundImages(payload.data)

      return {
        ...state,
        backgroundImages: {
          ...payload,
          data: backgroundImages
        },
        isFetching: false
      }
    case types.GET_BACKGROUND_IMAGES_ERROR:
      return {
        ...state,
        backgroundImages: {
          ...payload,
          data: []
        },
        isFetching: false
      }
    case types.GET_PEXEL_BACKGROUND_IMAGES:
      return update(state, {
        pexelBackgroundImages: {
          isFetching: { $set: true },
          ...((data.query || '') !==
            state.pexelBackgroundImages.filter.query && {
            response: { $set: [] }
          })
        }
      })
    case types.GET_PEXEL_BACKGROUND_IMAGES_SUCCESS: {
      const images = payload.response.map(el => ({
        ...el,
        type: TABS_NAMES.background,
        selected: false,
        showAs: 'bg',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        external: true
      }))
      const photos =
        payload.meta.currentPage === 1
          ? images
          : _concat(state.pexelBackgroundImages.response, images)

      return update(state, {
        pexelBackgroundImages: {
          response: { $set: photos },
          isFetching: { $set: false },
          meta: { $set: payload.meta },
          filter: { $set: { query: actionMeta.query || '' } }
        }
      })
    }
    case types.GET_PEXEL_BACKGROUND_IMAGES_ERROR:
      return update(state, {
        pexelBackgroundImages: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    // STOCK IMAGES
    case types.GET_STOCK_IMAGES:
      return update(state, {
        stockImages: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_STOCK_IMAGES_SUCCESS:
      return update(state, {
        stockImages: {
          response: {
            $set: payload
          },
          meta: {
            $set: actionMeta
          }
        }
      })
    case types.GET_STOCK_IMAGES_ERROR:
      return update(state, {
        stockImages: {
          error: {
            $set: action.payload
          }
        }
      })

    // Royalty Images
    case types.GET_ROYALTY_IMAGES:
      return {
        ...state,
        ...(data.royaltyImagesPage === 1 && { royaltyImages: [] }),
        imagesTabQuery: data.query,
        royaltyImagesPage: data.royaltyImagesPage,
        perPage: data.perPage,
        isFetching: true
      }
    case types.GET_ROYALTY_IMAGES_SUCCESS:
      const royaltyImagesNormalize = payload.hits.map(el => ({
        ...el,
        src: {
          small: el.webformatURL,
          original: el.largeImageURL || el.imageURL
        },
        type: TABS_NAMES.royaltyFreeImages,
        showAs: 'image',
        external: true
      }))
      const royaltyImages = !(state.royaltyImagesPage - 1)
        ? royaltyImagesNormalize
        : _concat(state.royaltyImages, royaltyImagesNormalize)
      return {
        ...state,
        royaltyImages,
        royaltyImagesLastPage: Math.ceil(payload.total / state.perPage),
        isFetching: false
      }
    case types.GET_ROYALTY_IMAGES_ERROR:
      return {
        ...state,
        isFetching: false
      }

    // LIBRARY IMAGES
    case types.GET_LIBRARY_IMAGES:
      return {
        ...state,
        imagesTabQuery: data.query,
        libraryImagesPage: data.libraryImagesPage,
        perPage: data.perPage,
        isFetching: true
      }
    case types.GET_LIBRARY_IMAGES_SUCCESS:
      const libraryImages = !state.libraryImagesPage
        ? payload
        : _concat(state.libraryImages, payload)
      return {
        ...state,
        libraryImages,
        isFetching: false
      }

    // SELECTED BG
    case types.SET_SELECTED_BG_SUCCESS:
      return update(state, {
        pexelBackgroundImages: {
          response: {
            $set: setSelectedById(state.pexelBackgroundImages.response, data.id)
          }
        },
        patterns: {
          response: {
            $set: setSelectedById(state.patterns.response, data.id)
          }
        }
      })

    case types.REMOVE_SELECTED_BG_SUCCESS:
      return update(state, {
        pexelBackgroundImages: {
          response: {
            $set: setSelectedById(state.pexelBackgroundImages.response, false)
          }
        },
        patterns: {
          response: {
            $set: setSelectedById(state.patterns.response, false)
          }
        }
      })
    case types.CLEAR_DG_SIDEBAR:
      return {
        ...state,
        ...initialState
      }
    case types.SET_SELECTED_DESIGN:
      return update(state, {
        selectedDesign: { $set: payload }
      })
    case types.SET_MISSED_FONTS:
      return update(state, {
        missedFonts: { $set: payload }
      })
    default:
      return state
  }
}

export default leftSidebarReducer
