import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Button, withStyles } from '@material-ui/core'
import { ReactComponent as LeftTriangle } from 'common/assets/icons/training-info-left-triangle.svg'
import topRightBanner from 'common/assets/images/upgradeTopRightBanner.png'
import backgroundBottomRight from 'common/assets/images/upgradeBottomRightBanner.png'
import { useFormik } from 'formik'
import { useUpdateMutation } from 'api/upgradeApi'
import UpgradeRequestModal from './UpgradeRequestModal'
import { requiredField } from 'constants/validationMessages'

const styles = ({ colors }) => {
  return {
    infoWrapper: {
      background: '#011875',
      position: 'relative'
    },
    webpageWrapper: {
      position: 'relative',
      height: '100%',
      overflow: 'hidden'
    },
    closeBtn: {
      height: '44px',
      width: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '25px',
      right: '25px'
    },
    mainContainerWrapper: {
      gap: '0',
      gridTemplateColumns: '1fr 420px'
    },
    topLeftIcon: {
      position: 'absolute',
      top: '0',
      left: '0',
      '& svg': {
        width: '180px',
        height: '180px'
      }
    },
    topHeaderBg: {
      width: '100%',
      objectFit: 'cover'
    },
    bottomfooterBg: {
      width: '100%',
      objectFit: 'cover'
    },
    topRightIcon: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      transform: 'rotate(180deg)',
      '& svg': {
        width: '160px',
        height: '160px'
      }
    },
    bottomImagesWrap: {
      position: 'absolute',
      bottom: '0',
      left: '0'
    },
    listItemIcon: {
      fontSize: '1.5em',
      color: colors.white
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '24px'
    },
    textTitle: {
      margin: '0',
      fontSize: '24px',
      fontWeight: '700',
      color: colors.white,
      lineHeight: '30px'
    },
    textparagraphWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 20px'
    },
    textparagraph: {
      margin: '0',
      paddingTop: '12px',
      fontSize: '14px',
      fontWeight: '400',
      color: colors.white
    },
    textparagraphData: {
      margin: '0',
      paddingTop: '12px',
      fontSize: '14px',
      fontWeight: '400',
      color: colors.white,
      display: 'flex',
      flexDirection: 'column'
    },
    thankyouPage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'calc(100% - 170px)',
      justifyContent: 'center',
      padding: '20px'
    },
    contactSalesButton: {
      background: '#EA8537',
      color: colors.white,
      padding: '8px 16px',
      textTransform: 'capitalize',
      fontSize: '16px',
      fontWeight: 700,
      border: '1px solid transparent',
      position: 'relative',
      zIndex: '1',
      '&:hover': {
        background: 'transparent',
        border: '1px solid #EA8537'
      }
    }
  }
}

const UpgradeInfo = ({ t, classes, closeModal }) => {
  const [openRequestModal, setRequestModal] = useState(false)
  const [openThankYouModal, setOpenThankyouModal] = useState(false)
  const [upgradeRequestApi] = useUpdateMutation()
  const onSubmit = ({ message }) => {
    upgradeRequestApi({ message })
    setOpenThankyouModal(true)
  }
  const form = useFormik({
    initialValues: {
      message: ''
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().restrictedCharacters().required(requiredField)
    }),
    onSubmit
  })

  return (
    <div className={classes.infoWrapper}>
      <div className={classes.topLeftIcon}>
        <LeftTriangle />
      </div>
      <div className={classes.bannerRight}>
        <img
          className={classes.topHeaderBg}
          src={topRightBanner}
          alt="training-icon-top"
        ></img>
      </div>

      {!openThankYouModal ? (
        <UpgradeRequestModal
          form={form}
          setRequestModal={setRequestModal}
          openRequestModal={openRequestModal}
        />
      ) : (
        <div className={classes.thankyouPage}>
          <h2 className={classes.textTitle}>{t(`Thank You!`)}</h2>
          <div className={classes.textparagraphWrapper}>
            <p className={classes.textparagraph}>
              {t(`Your Solutions Consultant will be in touch shortly to discuss
              your interest to upgrade.`)}
            </p>
            <p className={classes.textparagraphData}>
              <span>Call 866.310.4923 (M-F 8am to 5pm ET)</span>
            </p>
          </div>

          <div className={classes.buttonWrap}>
            <Button className={classes.contactSalesButton} onClick={closeModal}>
              {t(`Back to Dashboard`)}
            </Button>
          </div>
        </div>
      )}
      <div className={classes.bottomImagesWrap}>
        <img
          className={classes.topHeaderBg}
          src={backgroundBottomRight}
          alt="training-icon-top"
        ></img>
      </div>
      <div className={classes.topRightIcon}>
        <LeftTriangle />
      </div>
    </div>
  )
}

export default withTranslation('translations')(withStyles(styles)(UpgradeInfo))
