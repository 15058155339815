import { createContext, useMemo, useState } from 'react'

export const AddEditMediaContext = createContext({
  hasSchedules: false,
  hasPlaylists: false,
  setHasSchedules: hasSchedules => {},
  setHasPlaylists: hasPlaylists => {}
})

export const AddEditMediaContextProvider = ({ children }) => {
  const [hasSchedules, setHasSchedules] = useState(false)
  const [hasPlaylists, setHasPlaylists] = useState(false)

  const contextValue = useMemo(
    () => ({
      hasSchedules,
      setHasSchedules,
      hasPlaylists,
      setHasPlaylists
    }),
    [hasPlaylists, hasSchedules, setHasSchedules, setHasPlaylists]
  )

  return (
    <AddEditMediaContext.Provider value={contextValue}>
      {children}
    </AddEditMediaContext.Provider>
  )
}
