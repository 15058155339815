import { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import moment from 'moment'

import MaterialPopup from 'components/Popup/MaterialPopup'
import Container from 'components/Containers/Container'
import {
  DATE_VIEW_FORMAT_2,
  MEDIA_DATE_FORMAT
} from 'constants/dateTimeFormats'
import getStyles from './styles'
import PropTypes from 'prop-types'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Text } from 'components/Typography'
import DateField from './components/DateField'

const useStyles = makeStyles(({ palette, type, spacing }) => ({
  container: ({ fullWidth }) => ({
    alignItems: 'flex-end',
    width: fullWidth ? '100%' : 'auto'
  }),
  noSeparate: {
    gap: '0px'
  },
  marginBottom: {
    marginBottom: spacing(2)
  },
  endAdornment: {
    position: 'absolute',
    right: '12px',
    height: 'calc(100% - 3px)',
    padding: '6px 8px',
    marginLeft: 4,
    display: 'grid',
    placeItems: 'center',
    marginRight: '-10px',
    background: palette[type].formControls.multipleDatesPicker.input.background,
    color: palette[type].formControls.multipleDatesPicker.input.color,
    borderLeft: `1px solid ${palette[type].formControls.multipleDatesPicker.input.border}`
  },
  dateRangeRoot: {
    ...getStyles({ palette, type }, { onlyDate: true })
  },
  popupContent: {
    borderRadius: 4,
    overflow: 'hidden'
  },
  footerRoot: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: 10
  },
  timeBoldText: {
    fontWeight: 'bold'
  },
  endDateWrapper: {
    position: 'relative'
  },
  clearDateIcon: {
    position: 'absolute',
    right: 37,
    bottom: 8,
    color: 'rgb(204, 204, 204)',

    '&:hover': {
      color: 'rgb(153, 153, 153)'
    }
  }
}))

const defaultStaticRanges = [
  {
    label: 'This Week',
    startDate: moment().startOf('isoWeek'),
    endDate: moment().endOf('isoWeek')
  },
  {
    label: 'Next Week',
    startDate: moment().add(1, 'weeks').startOf('isoWeek'),
    endDate: moment().add(1, 'weeks').endOf('isoWeek')
  },
  {
    label: 'Next 7 Days',
    startDate: moment(),
    endDate: moment().add(7, 'days')
  },
  {
    label: 'Current Month',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
  },
  {
    label: 'Next Month',
    startDate: moment().add(1, 'months').startOf('month'),
    endDate: moment().add(1, 'months').endOf('month')
  }
]

const FormControlDateRangePickerNew = ({
  startDateName = 'startDate',
  endDateName = 'endDate',
  startDateLabel,
  endDateLabel,
  values = {},
  errors = {},
  touched = {},
  separateFields = true,
  formControlContainerClass,
  isSingleField = false,
  withPortal = false,
  marginBottom = true,
  fullWidth = false,
  onFocus = f => f,
  onBlur = f => f,
  maskValue = MEDIA_DATE_FORMAT,
  separatorText = '-',
  minDate = moment().format(MEDIA_DATE_FORMAT),
  maxDate,
  labelPosition = 'top',
  disabled = false,
  disabledEndDate,
  disabledStartDate,
  inputProps = {},
  onDoubleClick,
  tooltip,
  isBottomError,
  showErrorText = true,
  hideStaticRanges = false,
  staticRanges,
  showFooter = true,
  footerComponent,
  displayFormat = MEDIA_DATE_FORMAT,
  isEndDateClearable = false,
  isEndDateOptional = false,
  isEndDateNullable,
  dateRangePickerRootClass,
  editableDateInputs = false,
  handleChangeStartDate,
  handleChangeEndDate
}) => {
  const classes = useStyles({
    fullWidth,
    hideStaticRanges
  })
  const [focusedInputs, setFocusedInputs] = useState({
    [startDateName]: false,
    ...(isSingleField ? {} : { [endDateName]: false })
  })
  const [localValues, setLocalValues] = useState([])
  const [isDatepickerEdit, setIsDatePickerEdit] = useState(false)

  useEffect(() => {
    setLocalValues([
      {
        startDate:
          disabledEndDate || disabledStartDate
            ? values[endDateName] &&
              moment(values[endDateName], maskValue).isValid()
              ? moment(values[endDateName], maskValue).toDate()
              : new Date()
            : values[startDateName] &&
              moment(values[startDateName], maskValue).isValid()
            ? moment(values[startDateName], maskValue).toDate()
            : new Date(),
        endDate:
          values[endDateName] &&
          moment(values[endDateName], maskValue).isValid()
            ? moment(values[endDateName], maskValue).toDate()
            : isEndDateNullable
            ? null
            : new Date(),
        key: 'selection'
      }
    ])
    // eslint-disable-next-line
  }, [values, disabledEndDate, disabledStartDate])

  const validateDateInputs = date => {
    if (!date) return false
    return !moment(date, maskValue, true).isValid()
  }
  const handleChange = useCallback(
    ({ selection }) => {
      const { startDate, endDate } = values
      const { startDate: selectedStart, endDate: selectedEnd } = selection

      const startMoment = moment(startDate, maskValue)
      const endMoment = moment(endDate, maskValue)
      const selectionStartMoment = moment(selectedStart)
      const selectionEndMoment = moment(selectedEnd)
      const isSelectionDefinedRange = selectedStart.isDefinedRange

      if (
        (startDate &&
          endDate &&
          selectionEndMoment.isAfter(endMoment) &&
          !disabledStartDate &&
          !isSelectionDefinedRange &&
          !disabledEndDate) ||
        (startDate &&
          !endDate &&
          !isSelectionDefinedRange &&
          selectionEndMoment.isAfter(startMoment))
      ) {
        handleChangeStartDate(startMoment.format(maskValue))
      } else {
        const value =
          disabledStartDate || disabledEndDate
            ? selectionEndMoment.format(maskValue)
            : selectionStartMoment.format(maskValue)

        handleChangeStartDate(value)
      }
      handleChangeEndDate(selectionEndMoment.format(maskValue))
    },
    [
      maskValue,
      values,
      disabledEndDate,
      disabledStartDate,
      handleChangeEndDate,
      handleChangeStartDate
    ]
  )

  const handleFocus = useCallback(
    event => {
      const {
        target: { name }
      } = event
      setFocusedInputs(f => ({
        ...f,
        [name]: true
      }))

      onFocus(event)
      if (editableDateInputs) {
        setIsDatePickerEdit(name)
      }
    },
    [onFocus, editableDateInputs]
  )

  const handleBlur = useCallback(
    event => {
      const {
        target: { name, value }
      } = event
      setFocusedInputs(f => ({
        ...f,
        [name]: false
      }))
      const parsedMoment = moment(value, maskValue)
      const formattedValue = parsedMoment.format(maskValue)
      if (parsedMoment.isValid() && value.length > 1) {
        if (name === startDateName) {
          handleChangeStartDate(formattedValue)
        } else {
          handleChangeEndDate(formattedValue)
        }
      } else {
        if (name === startDateName) {
          handleChangeStartDate(moment().format(maskValue))
        } else {
          handleChangeEndDate(null)
        }
      }
      setIsDatePickerEdit('')
      onBlur(event)
    },
    [
      onBlur,
      maskValue,
      startDateName,
      handleChangeEndDate,
      handleChangeStartDate
    ]
  )
  const parseEndDateValue = useMemo(
    () =>
      isDatepickerEdit === endDateName
        ? values[endDateName]
        : values[endDateName] &&
          moment(values[endDateName], maskValue).isValid()
        ? moment(values[endDateName], maskValue).format(displayFormat)
        : values[endDateName] || '',
    [values, endDateName, maskValue, displayFormat, isDatepickerEdit]
  )
  const handleChangeInput = (name, value) => {
    if (name === startDateName) {
      handleChangeStartDate(value)
    } else {
      handleChangeEndDate(value)
    }
  }

  const parseStartDateValue = useMemo(() => {
    const startDate =
      values[startDateName] &&
      moment(values[startDateName], maskValue).isValid()
        ? moment(values[startDateName], maskValue).format(displayFormat)
        : values[startDateName]
    return isDatepickerEdit === startDateName
      ? values[startDateName]
      : disabledEndDate || disabledStartDate
      ? parseEndDateValue
      : isSingleField
      ? [startDate, parseEndDateValue].join(` ${separatorText} `)
      : startDate
  }, [
    values,
    startDateName,
    isSingleField,
    separatorText,
    disabledEndDate,
    disabledStartDate,
    parseEndDateValue,
    displayFormat,
    maskValue,
    isDatepickerEdit
  ])

  const isErrorIcon = name =>
    !isBottomError && showErrorText && !!errors[name] && touched[name]

  const parsedStaticRanges = useMemo(() => {
    return createStaticRanges(
      (staticRanges || defaultStaticRanges).map(
        ({ label, startDate, endDate }) => ({
          label,
          range: () => {
            const newStartDate = startDate.isBefore(moment(minDate, maskValue))
              ? moment(minDate, maskValue).toDate()
              : startDate.toDate()
            newStartDate.isDefinedRange = true
            return {
              startDate: newStartDate,
              endDate: endDate.isBefore(moment(minDate, maskValue))
                ? moment(minDate, maskValue).toDate()
                : endDate.toDate()
            }
          }
        })
      )
    )
  }, [minDate, maskValue, staticRanges])

  const handleClearEndDate = useCallback(
    e => {
      e.stopPropagation()
      handleChangeEndDate(null)
    },
    [handleChangeEndDate]
  )

  return (
    <MaterialPopup
      open={
        Object.values(focusedInputs).some(b => b === true) === true
          ? true
          : undefined
      }
      on="click"
      placement={'bottom'}
      trigger={
        <Container
          customClass={classNames(
            classes.container,
            formControlContainerClass,
            {
              [classes.noSeparate]: !separateFields,
              [classes.marginBottom]: marginBottom
            }
          )}
          cols={isSingleField ? '1' : '2'}
        >
          <>
            <DateField
              onChange={handleChangeInput}
              labelPosition={labelPosition}
              label={startDateLabel}
              endAdornment={
                isErrorIcon(startDateName) ||
                validateDateInputs(values[startDateName]) ? (
                  <div className={classes.endAdornment}>
                    <i className={'fa-regular fa-exclamation-triangle'} />
                  </div>
                ) : (
                  <div className={classes.endAdornment}>
                    <i className={'fa-regular fa-calendar-days'} />
                  </div>
                )
              }
              name={startDateName}
              placeholder={maskValue}
              value={parseStartDateValue}
              onFocusEvent={handleFocus}
              onBlurEvent={handleBlur}
              fullWidth={fullWidth}
              error={errors[startDateName]}
              touched={touched[startDateName]}
              marginBottom={false}
              disabled={disabled || disabledStartDate}
              onDoubleClick={onDoubleClick}
              tooltip={tooltip}
              formControlRootClass={inputProps.formControlRootClass}
              showErrorText={showErrorText}
              readOnly={!editableDateInputs}
              autocomplete="off"
              mask={maskValue}
              isDatepickerEdit={isDatepickerEdit}
              {...inputProps}
            />
            {!isSingleField && (
              <div className={classes.endDateWrapper}>
                <DateField
                  labelPosition={labelPosition}
                  label={endDateLabel}
                  endAdornment={
                    isErrorIcon(endDateName) ? null : (
                      <div className={classes.endAdornment}>
                        <i className={'fa-regular fa-calendar-days'} />
                      </div>
                    )
                  }
                  name={endDateName}
                  value={parseEndDateValue}
                  onFocusEvent={handleFocus}
                  onBlurEvent={handleBlur}
                  fullWidth={fullWidth}
                  error={errors[endDateName]}
                  touched={touched[endDateName]}
                  marginBottom={false}
                  disabled={disabled || disabledEndDate}
                  readOnly={!editableDateInputs}
                  onChange={handleChangeInput}
                  onDoubleClick={onDoubleClick}
                  autocomplete="off"
                  tooltip={tooltip}
                  formControlRootClass={inputProps.formControlRootClass}
                  showErrorText={showErrorText}
                  isOptional={isEndDateOptional}
                  mask={maskValue}
                  isDatepickerEdit={isDatepickerEdit}
                  {...inputProps}
                />
                {!!parseEndDateValue && isEndDateClearable && (
                  <i
                    onClick={handleClearEndDate}
                    className={classNames(
                      'fa-solid fa-xmark',
                      classes.clearDateIcon
                    )}
                  />
                )}
              </div>
            )}
          </>
        </Container>
      }
      preventOverflow={
        withPortal
          ? {
              enabled: true,
              boundariesElement: 'viewport'
            }
          : {}
      }
      disabled={disabled}
      contentClassName={classes.popupContent}
    >
      <DateRangePicker
        className={classNames(classes.dateRangeRoot, dateRangePickerRootClass)}
        onChange={handleChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={localValues}
        inputRanges={[]}
        staticRanges={hideStaticRanges ? [] : parsedStaticRanges}
        direction="horizontal"
        showDateDisplay={false}
        minDate={
          minDate && moment(minDate, maskValue).isValid()
            ? moment(minDate, maskValue).toDate()
            : undefined
        }
        maxDate={
          maxDate && moment(maxDate, maskValue).isValid()
            ? moment(maxDate, maskValue).toDate()
            : undefined
        }
        weekStartsOn={1}
      />
      {showFooter && (
        <div className={classes.footerRoot}>
          {footerComponent || (
            <Text classes={classes.footerText}>
              Scheduled from&nbsp;
              <span className={classes.timeBoldText}>
                {!!values[startDateName] &&
                  moment(values[startDateName], maskValue).format(
                    DATE_VIEW_FORMAT_2
                  )}
              </span>
              &nbsp;to&nbsp;
              <span className={classes.timeBoldText}>
                {!!values[endDateName] &&
                  moment(values[endDateName], maskValue).format(
                    DATE_VIEW_FORMAT_2
                  )}
              </span>
            </Text>
          )}
        </div>
      )}
    </MaterialPopup>
  )
}

FormControlDateRangePickerNew.propTypes = {
  startDateName: PropTypes.string,
  endDateName: PropTypes.string,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  separateFields: PropTypes.bool,
  formControlContainerClass: PropTypes.string,
  isSingleField: PropTypes.bool,
  withPortal: PropTypes.bool,
  marginBottom: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  maskValue: PropTypes.string,
  displayFormat: PropTypes.string,
  separatorText: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  staticRanges: PropTypes.array,
  hideStaticRanges: PropTypes.bool,
  dateRangePickerRootClass: PropTypes.string,
  isEndDateClearable: PropTypes.bool,
  isEndDateOptional: PropTypes.bool
}

export default FormControlDateRangePickerNew
