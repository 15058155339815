import React, { useMemo } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { BlueButton, WhiteButton } from 'components/Buttons'
import { withTranslation } from 'react-i18next'

const styles = () => ({
  reset: {
    marginRight: 16
  }
})

const Footer = ({
  t,
  classes,
  rootClass,
  onSubmit = f => f,
  onReset = f => f
}) => {
  const translate = useMemo(
    () => ({
      action: t('Search Action'),
      reset: t('Search Reset Action')
    }),
    [t]
  )
  return (
    <Grid className={rootClass} container justifyContent={'flex-end'}>
      <WhiteButton
        className={classes.reset}
        onClick={onReset}
        iconClassName="fa-regular fa-circle-arrow-left"
        variant="danger"
      >
        {translate.reset}
      </WhiteButton>
      <BlueButton
        type="submit"
        iconClassName="fa-regular fa-magnifying-glass"
        onClick={onSubmit}
      >
        {translate.action}
      </BlueButton>
    </Grid>
  )
}

export default withTranslation('translations')(withStyles(styles)(Footer))
