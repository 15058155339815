import React, { forwardRef, useCallback, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { withTheme, withStyles } from '@material-ui/core'
import classNames from 'classnames'

const styles = ({ type, palette }) => ({
  placeholder: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    backgroundColor: palette[type].loader.background,
    foregroundColor: palette[type].loader.foreground,
    zIndex: 2,
    borderRadius: 4
  },
  withoutRadius: {
    borderRadius: 0
  }
})

const ImageWithPlaceholder = forwardRef(
  (
    {
      theme,
      classes,
      src,
      alt,
      onLoad,
      onError,
      externalLoading,
      withoutRadius,
      minHeight,
      placeHolderClass,
      ...props
    },
    ref
  ) => {
    const [loading, setLoading] = useState(true)

    const handleLoadCompleted = useCallback(
      callback => () => {
        callback && callback()
        setLoading(false)
      },
      []
    )

    return (
      <div className={placeHolderClass}>
        <img
          ref={ref}
          onLoad={handleLoadCompleted(onLoad)}
          onError={handleLoadCompleted(onError)}
          src={src}
          alt={alt}
          {...props}
        />
        {(externalLoading || loading) && (
          <ContentLoader
            className={classNames(classes.placeholder, {
              [classes.withoutRadius]: withoutRadius
            })}
            backgroundColor={theme.palette[theme.type].loader.background}
            foregroundColor={theme.palette[theme.type].loader.foreground}
          >
            <rect x="0" y="0" width="100%" height="100%" />
          </ContentLoader>
        )}
      </div>
    )
  }
)

export default withStyles(styles)(withTheme(ImageWithPlaceholder))
