import { apiConstants } from '../constants'
import { ENTERPRISE_TOKEN, SYSTEM_TOKEN } from 'constants/api'

const getUserType = () => {
  const system = !!localStorage.getItem(SYSTEM_TOKEN)
  const enterprise = !!localStorage.getItem(ENTERPRISE_TOKEN)

  return system
    ? apiConstants.SYSTEM_USER
    : enterprise
    ? apiConstants.ENTERPRISE_USER
    : apiConstants.ORG_USER
}

export default getUserType
