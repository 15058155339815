import { getUserType } from 'utils/index'
import { apiConstants } from 'constants/index'
import { ENTERPRISE_TOKEN, ORG_TOKEN, SYSTEM_TOKEN } from 'constants/api'

const getTokenName = () => {
  const userType = getUserType()
  return userType === apiConstants.SYSTEM_USER
    ? SYSTEM_TOKEN
    : userType === apiConstants.ENTERPRISE_USER
    ? ENTERPRISE_TOKEN
    : ORG_TOKEN
}

export default getTokenName
