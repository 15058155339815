import React, { useMemo } from 'react'
import { Table, TableBody, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import _get from 'lodash/get'
import classNames from 'classnames'

import {
  TableLibraryCell,
  TableLibraryHead,
  TableLibraryRow
} from 'components/TableLibrary'
import Scrollbars from 'components/Scrollbars'
import DeviceTypeIconCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceTypeIconCell'
import DeviceNameViewCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceNameViewCell'
import { useUserRole } from 'hooks/tableLibrary'
import MessageCard from 'components/Card/MessageCard'
import { TextWithTooltip } from 'components/Typography'
import Tooltip from 'components/Tooltip'

const styles = ({ type, typography, colors, fontSize, spacing }) => ({
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  },
  name: {
    ...typography.darkAccent[type]
  },
  aliasWrapContent: {
    minWidth: '190px'
  },
  badgeWrapper: {
    width: 0,
    margin: '0px auto'
  },
  tooltipScheduleName: {
    color: '#fff'
  },
  icon: {
    fontSize: fontSize.big,
    marginLeft: spacing(2)
  }
})

const initialColumns = [
  { id: 'deviceTypeLogo', label: 'Logo', align: 'left' },
  { id: 'deviceType', label: 'Type', align: 'left' },
  { id: 'deviceName', label: 'Device Name', align: 'left' },
  { id: 'scheduleName', label: 'Schedule(s)', align: 'left' }
]

const columnWidth = {
  deviceTypeLogo: 96,
  deviceType: 120,
  deviceName: 190,
  scheduleName: 140
}

const MAX_SCHEDULES_PER_ROW = 3

const DeviceHasAutoAssignedScheduleConflictContent = ({ error, classes }) => {
  const { t } = useTranslation('translations')
  const role = useUserRole()

  const schedules = useMemo(() => error?.data?.schedule || [], [error])

  return (
    <Scrollbars autoHeight autoHeightMax="calc(100vh - 190px)">
      <div>
        <MessageCard
          icon="fa-regular fa-circle-info"
          message={`${t(
            'Device has schedule(s) assigned to it as a Group / Tag / Location. The schedule(s) cannot be removed from the device.'
          )}:`}
          marginBottom
        />
        <Table className={classes.table}>
          <TableLibraryHead
            noType={true}
            editRows={false}
            disableSort
            rowCount={1}
            columns={initialColumns}
            columnWidth={columnWidth}
            headerCellClassName={classes.tableHeaderCell}
          />
          <TableBody>
            <TableLibraryRow hover tabIndex={-1}>
              <DeviceTypeIconCell item={error?.data} />
              <TableLibraryCell align="left">
                {_get(error?.data.deviceType, 'name', t('N/A'))}
              </TableLibraryCell>
              <TableLibraryCell
                className={classNames(classes.name, classes.aliasWrapContent)}
              >
                <DeviceNameViewCell
                  role={role}
                  classes={classes}
                  row={error?.data}
                />
              </TableLibraryCell>
              <TableLibraryCell>
                {schedules.length <= MAX_SCHEDULES_PER_ROW ? (
                  schedules.map(schedule => (
                    <TextWithTooltip key={schedule.id} maxWidth={140}>
                      {schedule.title}
                    </TextWithTooltip>
                  ))
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    withHeader
                    headerText={`${t('Schedules')}:`}
                    title={schedules.map(schedule => (
                      <TextWithTooltip
                        rootClassName={classes.tooltipScheduleName}
                        key={schedule.id}
                        maxWidth={140}
                      >
                        {schedule.title}
                      </TextWithTooltip>
                    ))}
                  >
                    <i
                      className={classNames(
                        classes.icon,
                        'fa-sharp fa-regular fa-list-dropdown'
                      )}
                    />
                  </Tooltip>
                )}
              </TableLibraryCell>
            </TableLibraryRow>
          </TableBody>
        </Table>
      </div>
    </Scrollbars>
  )
}

export default withStyles(styles)(DeviceHasAutoAssignedScheduleConflictContent)
