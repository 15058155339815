import classNames from 'classnames'
import React, { useMemo } from 'react'
import { getBezierPath } from 'react-flow-renderer'

export default function ArcEdgeType({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  labelStyle,
  label,
  markerEnd,
  animated = false
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  const curEdgePath = useMemo(() => {
    const pathSplit = edgePath.replace('C', '').split(' ')
    const [sourceControlX, sourceControlY] = pathSplit[1].split(',')
    const [targetControlX, targetControlY] = pathSplit[2].split(',')

    return `M${sourceX},${sourceY} C${Number(sourceControlX) - 30},${
      Number(sourceControlY) - 50
    } ${Number(targetControlX) + 30},${
      Number(targetControlY) - 50
    } ${targetX},${targetY}`
  }, [edgePath, sourceX, sourceY, targetX, targetY])

  const midX = (sourceX + targetX) / 2
  const midY = (sourceY + targetY) / 2

  return (
    <g
      className={classNames(
        'react-flow__edge react-flow__edge-simplebezier nopan',
        {
          animated
        }
      )}
    >
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={curEdgePath}
        markerEnd={markerEnd}
      />
      <text x={midX} y={midY - 20} textAnchor="middle" style={labelStyle}>
        {label}
      </text>
    </g>
  )
}
