const getStyles = (
  { palette, type },
  { onlyDate, hideStaticRanges, showSeconds, noRange } = {}
) => ({
  background: `${palette[type].formControls.multipleDatesPicker.popup.background} !important`,
  flexDirection: 'column',

  '& .rdrDefinedRangesWrapper, & .rdrCalendarWrapper': {
    background: `${palette[type].formControls.multipleDatesPicker.popup.background} !important`
  },

  '& .rdrDefinedRangesWrapper': {
    width: '100%',
    border: 'none',
    ...(hideStaticRanges ? { display: 'none' } : {}),

    '& .rdrStaticRanges': {
      width: '100%',
      flexDirection: 'row',
      gap: 10,
      margin: 10,
      marginBottom: 0,

      '& .rdrStaticRange': {
        border: 'none',
        background:
          palette[type].formControls.multipleDatesPicker.popup
            .disabledBackground,
        borderRadius: 30,
        color: palette[type].formControls.multipleDatesPicker.popup.color,
        transitionDuration: '0.1s',

        '&:hover, &:active, &:focus': {
          color: 'rgb(61, 145, 255)',

          '& .rdrStaticRangeLabel': {
            background: 'transparent'
          }
        }
      }
    }
  },

  '& .rdrInputRanges': {
    padding: 0
  },

  '& .rdrMonthAndYearWrapper': {
    paddingTop: noRange ? 0 : 10,
    '& .rdrNextPrevButton': {
      background: `${palette[type].formControls.multipleDatesPicker.popup.disabledBackground} !important`
    },
    '& .rdrPprevButton i': {
      borderColor: `transparent ${palette[type].formControls.multipleDatesPicker.popup.disabledColor} transparent transparent !important`
    },
    '& .rdrNextButton i': {
      borderColor: `transparent transparent transparent ${palette[type].formControls.multipleDatesPicker.popup.disabledColor} !important`
    }
  },

  '& .rdrMonthAndYearPickers': {
    '& select': {
      color: `${palette[type].formControls.multipleDatesPicker.popup.color} !important`,
      '& option': {
        background: `${palette[type].formControls.multipleDatesPicker.popup.background} !important`
      }
    }
  },

  '& .rdrMonth': {
    margin: '0 0.833em 1.666em 0.833em',
    padding: 0,
    boxShadow: 'rgba(128, 128, 128, 0.45) 0px 0px 8px',
    borderRadius: 4,
    background: `${palette[type].formControls.multipleDatesPicker.popup.background} !important`
  },

  '& .rdrDayHovered': {
    '& .rdrDayNumber::after': {
      borderRadius: '4px !important'
    }
  },

  '& .rdrYearPicker, & .rdrMonthPicker': {
    '& select': {
      background:
        palette[type].formControls.multipleDatesPicker.popup.background,
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette[type].scrollbar.background,
        borderRadius: '5px'
      }
    }
  },

  '& .rdrMonths': {
    ...(onlyDate
      ? {}
      : {
          gap: showSeconds ? 110 : 90,
          marginRight: showSeconds ? 115 : 95
        }),

    '& .rdrDayDisabled': {
      background: `${palette[type].formControls.multipleDatesPicker.popup.disabledBackground} !important`,
      '& span': {
        color: `${palette[type].formControls.multipleDatesPicker.popup.disabledColor} !important`
      }
    },

    '& .rdrDayWeekend': {
      // background: `${palette[type].formControls.multipleDatesPicker.popup.disabledBackground} !important`
      // '& .rdrDayNumber span': {
      //   color: `${palette[type].formControls.multipleDatesPicker.popup.disabledColor} !important`
      // }
    },

    '& .rdrDay .rdrDayNumber span': {
      color: `${palette[type].formControls.multipleDatesPicker.popup.color}`
    },

    '& .rdrMonthName': {
      fontSize: 13
    },

    '& .rdrDay ': {
      '& .rdrStartEdge, & .rdrDayStartPreview': {
        borderRadius: 0,
        borderTopLeftRadius: '4px !important',
        borderBottomLeftRadius: '4px !important'
      },
      '& .rdrEndEdge, & .rdrDayEndPreview': {
        borderRadius: 0,
        borderTopRightRadius: '4px !important',
        borderBottomRightRadius: '4px !important'
      },
      '& .rdrInRange, & .rdrDayInPreview': {
        borderRadius: '0px'
      },
      '& .rdrSelected': {
        borderRadius: 4
      }
    }
  },

  '& .rdrDateDisplayWrapper': {
    ...(noRange ? { display: 'none' } : {})
  },

  '& .rdrCalendarWrapper': {
    position: 'relative',

    '& .rdrMonthAndYearWrapper': {
      height: '0px',

      '& .rdrMonthAndYearPickers': {
        display: 'none'
      },

      '& .rdrPprevButton': {
        position: 'absolute',
        top: 17,
        left: 7,
        background: 'transparent !important'
      },

      '& .rdrNextButton': {
        position: 'absolute',
        top: 17,
        right: onlyDate ? 7 : showSeconds ? 135 : 105,
        background: 'transparent !important'
      }
    },

    '& .rdrMonths': {
      '& .rdrMonth:first-child .rdrMonthName': {
        paddingLeft: 38
      }
    }
  }
})

export default getStyles
