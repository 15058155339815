export const USERS_PENDING = 'SEARCH_MODULE/USERS_PENDING'
export const USERS_SUCCESS = 'SEARCH_MODULE/USERS_SUCCESS'
export const USERS_FAILURE = 'SEARCH_MODULE/USERS_FAILURE'
export const USERS_FILTER_PENDING = 'SEARCH_MODULE/USERS_FILTER_PENDING'
export const USERS_FILTER_SUCCESS = 'SEARCH_MODULE/USERS_FILTER_SUCCESS'
export const USERS_FILTER_FAILURE = 'SEARCH_MODULE/USERS_FILTER_FAILURE'
export const CLEAR_USERS_FILTER = 'SEARCH_MODULE/CLEAR_USERS_FILTER'

export function fetchUsers({ limit }) {
  return {
    type: USERS_PENDING,
    limit
  }
}

export function usersSuccess({ data, meta }) {
  return {
    type: USERS_SUCCESS,
    data,
    meta
  }
}

export function usersFailure() {
  return {
    type: USERS_FAILURE
  }
}

export function fetchUsersFilter({ limit, firstName }) {
  return {
    type: USERS_FILTER_PENDING,
    firstName,
    limit
  }
}

export function usersFilterSuccess({ data, meta }) {
  return {
    type: USERS_FILTER_SUCCESS,
    data,
    meta
  }
}

export function usersFilterFailure() {
  return {
    type: USERS_FILTER_FAILURE
  }
}

export function clearUsersFilter() {
  return {
    type: CLEAR_USERS_FILTER
  }
}
