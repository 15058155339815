import { useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { AssignCreatedEntityContext } from 'contexts'
import {
  mediaService,
  playlistService,
  scheduleService,
  templateService
} from 'services'
import useMediaProcessedSocket from 'hooks/socket/useMediaProcessedSocket'
import useSnackbar from 'hooks/useSnackbar'
import { getMediaDuration } from 'utils/mediaUtils'
import { resourceTypes } from 'constants/api'
import {
  MEDIA_FALLBACK_TIME,
  MEDIA_MAX_TIME,
  MEDIA_MIN_TIME,
  mediaStatus,
  SCHEDULE_FALLBACK_TRANSITION_ID
} from 'constants/media'
import { getValidDate } from 'utils/scheduleUtils'
import { BACKEND_DATE_FORMAT } from 'constants/dateTimeFormats'

export default function useAssignCreatedEntity() {
  const { showSnackbar } = useSnackbar()
  const { t } = useTranslation('translations')

  const {
    createdItem,
    setCreatedItem,
    getStateByResource,
    setAssignedPlaylists
  } = useContext(AssignCreatedEntityContext)

  const assignDataList = useRef([])

  const showMessage = useCallback(
    (item, status, resourceType, targetCount = 1, items = []) => {
      const targetResourceMessagePart =
        resourceType === resourceTypes.PlaylistResource
          ? t('Playlist', { count: targetCount })
          : t('Schedule', { count: targetCount })

      const targetMessagePart = items.length
        ? `: ${items.join(', ')}`
        : `${t('the selected')} ${targetResourceMessagePart}`

      const message =
        status === 'error'
          ? t('name has not been added to target', {
              name: item.title,
              target: targetMessagePart
            })
          : t('name successfully added to target', {
              name: item.title,
              target: targetMessagePart
            })

      showSnackbar(message, status)
    },
    [showSnackbar, t]
  )

  const assignToPlaylists = useCallback(
    async (playlists, createdItem) => {
      try {
        await mediaService.addMediaToPlaylists({
          mediaIds: [createdItem.id],
          playlistIds: playlists.map(({ id }) => id)
        })
        showMessage(
          createdItem,
          'success',
          playlists[0].resource,
          playlists.length
        )
        setAssignedPlaylists(playlists)
        setTimeout(() => {
          setAssignedPlaylists([])
        }, 1000)
      } catch (e) {
        showMessage(
          createdItem,
          'error',
          playlists[0].resource,
          playlists.length
        )
      }
    },
    [showMessage, setAssignedPlaylists]
  )

  const assignToSchedule = useCallback(async (schedule, createdItem) => {
    try {
      const fetchedSchedule = await scheduleService.getSchedule(schedule.id)
      if (!fetchedSchedule) {
        throw new Error(schedule.title)
      }

      const playbackContentByResource = {
        [resourceTypes.MediaResource]: 'Media',
        [resourceTypes.PlaylistResource]: 'Playlist',
        [resourceTypes.TemplateResource]: 'Template'
      }

      await scheduleService.editSchedule({
        id: fetchedSchedule.id,
        data: {
          ...fetchedSchedule,
          startDate: getValidDate(
            fetchedSchedule.startDate,
            BACKEND_DATE_FORMAT
          ),
          endDate: getValidDate(fetchedSchedule.endDate, BACKEND_DATE_FORMAT),
          deviceList: (fetchedSchedule.deviceList || []).map(({ id }) => id),
          deviceLocations: (fetchedSchedule.deviceLocations || []).map(
            ({ id }) => id
          ),
          deviceTags: (fetchedSchedule.deviceTags || []).map(({ id }) => id),
          deviceGroups: (fetchedSchedule.deviceGroups || []).map(
            ({ id }) => id
          ),
          scheduleContent: [
            ...(fetchedSchedule.scheduleContent || []).map(content => {
              if (content.transition?.id) {
                return { ...content, transitionId: content.transition.id }
              }
              return content
            }),
            {
              ...createdItem,
              playbackContent: playbackContentByResource[createdItem.resource],
              playbackContentId: createdItem.id,
              duration: getMediaDuration(
                createdItem,
                true,
                MEDIA_FALLBACK_TIME
              ),
              durationTotal: getMediaDuration(
                createdItem,
                false,
                MEDIA_FALLBACK_TIME
              ),
              daypartStartTime: MEDIA_MIN_TIME,
              daypartEndTime: MEDIA_MAX_TIME,
              repeatTime: 1,
              sortOrder: fetchedSchedule.scheduleContent?.length || 0,
              transitionId: SCHEDULE_FALLBACK_TRANSITION_ID
            }
          ]
        }
      })
      return fetchedSchedule.title
    } catch (e) {
      throw new Error(schedule.title)
    }
  }, [])

  const assignToSchedules = useCallback(
    async (schedules, createdItem) => {
      try {
        const getItemByResource = {
          [resourceTypes.MediaResource]: mediaService.getMediaItemById,
          [resourceTypes.PlaylistResource]: playlistService.getPlaylistById,
          [resourceTypes.TemplateResource]: templateService.getTemplate
        }

        const item =
          createdItem.processingStatus === mediaStatus.processing
            ? await getItemByResource[createdItem.resource](createdItem.id)
            : createdItem

        const assignResults = await Promise.allSettled(
          schedules.map(schedule => assignToSchedule(schedule, item))
        )

        const rejectedSchedules = assignResults
          .filter(({ status }) => status === 'rejected')
          .map(({ reason }) => reason.message)

        const fulfilledSchedules = assignResults
          .filter(({ status }) => status === 'fulfilled')
          .map(({ value }) => value)

        if (!fulfilledSchedules.length) {
          showMessage(
            createdItem,
            'error',
            schedules[0].resource,
            rejectedSchedules.length
          )
        } else if (!rejectedSchedules.length) {
          showMessage(
            createdItem,
            'success',
            schedules[0].resource,
            schedules.length
          )
        } else {
          showMessage(
            createdItem,
            'success',
            schedules[0].resource,
            fulfilledSchedules.length,
            fulfilledSchedules
          )
          showMessage(
            createdItem,
            'error',
            schedules[0].resource,
            rejectedSchedules.length,
            rejectedSchedules
          )
        }
      } catch (e) {
        showMessage(
          createdItem,
          'error',
          schedules[0].resource,
          schedules.length
        )
      }
    },
    [assignToSchedule, showMessage]
  )

  const createAssignData = useCallback(
    (targets, createdItem) => ({
      targets,
      createdItem,
      callback: () => {
        const playlists = targets.filter(
          ({ resource }) => resource === resourceTypes.PlaylistResource
        )
        const schedules = targets.filter(
          ({ resource }) => resource === resourceTypes.ScheduleResource
        )

        if (playlists.length) {
          assignToPlaylists(playlists, createdItem)
        }
        if (schedules.length) {
          assignToSchedules(schedules, createdItem)
        }
      }
    }),
    [assignToPlaylists, assignToSchedules]
  )

  const processAssignData = useCallback(
    (id, status) => {
      const assignData = assignDataList.current.find(
        ({ createdItem }) => createdItem.id === id
      )
      if (
        assignData &&
        [mediaStatus.successful, mediaStatus.failed].includes(status)
      ) {
        if (status === mediaStatus.successful) {
          assignData.callback()
        } else if (status === mediaStatus.failed) {
          showMessage(
            assignData.createdItem,
            'error',
            assignData.targets[0].resource,
            assignData.targets.length
          )
        }
        assignDataList.current = assignDataList.current.filter(
          ({ createdItem }) => createdItem.id !== id
        )
      }
    },
    [showMessage]
  )

  useEffect(() => {
    const { setTargets, targets } = getStateByResource(createdItem?.resource)

    if (targets.length && createdItem) {
      if (createdItem.processingStatus === mediaStatus.processing) {
        assignDataList.current.push(createAssignData(targets, createdItem))
      } else {
        const playlists = targets.filter(
          ({ resource }) => resource === resourceTypes.PlaylistResource
        )
        const schedules = targets.filter(
          ({ resource }) => resource === resourceTypes.ScheduleResource
        )

        if (playlists.length) {
          assignToPlaylists(playlists, createdItem)
        }
        if (schedules.length) {
          assignToSchedules(schedules, createdItem)
        }
      }
      setTargets([])
      setCreatedItem(null)
    }
    // eslint-disable-next-line
  }, [createdItem])

  useMediaProcessedSocket(processAssignData)
}
