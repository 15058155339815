import { call, put, all } from 'redux-saga/effects'
import { transformMeta } from 'utils/tableUtils'

import * as types from '../actions'

import { menuMakerService } from '../services'

function* getMenuItems({ params }) {
  try {
    const { data, meta } = yield call(menuMakerService.getMenuItems, params)

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_MENU_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_MENU_ITEMS_ERROR, payload: error })
  }
}

function* postMenuItem({ data }) {
  try {
    yield call(menuMakerService.postMenuItem, data)
    yield put({
      type: types.POST_MENU_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_MENU_ITEM_ERROR, payload: error })
  }
}

function* postMenuItemBulk({ data, onSuccess, onError }) {
  try {
    yield call(menuMakerService.postMenuItemBulk, data)
    onSuccess()
  } catch (error) {
    onError(error)
  }
}

function* deleteMenuItem({ id }) {
  try {
    yield call(menuMakerService.deleteMenuItem, id)
    yield put({
      type: types.DELETE_MENU_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.DELETE_MENU_ITEM_ERROR, payload: error })
  }
}

function* getMenuItem({ id }) {
  try {
    const response = yield call(menuMakerService.getMenuItem, id)
    yield put({ type: types.GET_MENU_ITEM_BY_ID_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_MENU_ITEM_BY_ID_ERROR, payload: error })
  }
}

function* putMenuItem({ id, data }) {
  try {
    yield call(menuMakerService.putMenuItem, id, data)
    yield put({
      type: types.PUT_MENU_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_MENU_ITEM_ERROR, payload: error })
  }
}

function* deleteSelectedMenuItems({ ids }) {
  try {
    yield all(ids.map(id => call(menuMakerService.deleteMenuItem, id)))
    yield put({
      type: types.DELETE_SELECTED_MENU_ITEMS_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_MENU_ITEMS_ERROR,
      payload: error
    })
  }
}

function* getMenuLocations({ params }) {
  try {
    const { data, meta } = yield call(menuMakerService.getMenuLocations, params)

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_MENU_LOCATIONS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_MENU_LOCATIONS_ERROR, payload: error })
  }
}

function* postMenuLocation({ data }) {
  try {
    yield call(menuMakerService.postMenuLocation, data)
    yield put({
      type: types.POST_MENU_LOCATION_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_MENU_LOCATION_ERROR, payload: error })
  }
}

function* deleteMenuLocation({ id, data }) {
  try {
    yield call(menuMakerService.deleteMenuLocation, id, data)
    yield put({
      type: types.DELETE_MENU_LOCATION_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.DELETE_MENU_LOCATION_ERROR, payload: error })
  }
}

function* getMenuLocation({ id }) {
  try {
    const response = yield call(menuMakerService.getMenuLocation, id)
    yield put({
      type: types.GET_MENU_LOCATION_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_MENU_LOCATION_BY_ID_ERROR, payload: error })
  }
}

function* putMenuLocation({ id, data }) {
  try {
    yield call(menuMakerService.putMenuLocation, id, data)
    yield put({
      type: types.PUT_MENU_LOCATION_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_MENU_LOCATION_ERROR, payload: error })
  }
}

function* deleteSelectedMenuLocations({ ids, data }) {
  try {
    const errorIds = []
    let errorData = []
    yield all(
      ids.map(id =>
        (function* () {
          try {
            return yield call(menuMakerService.deleteMenuLocation, id, data)
          } catch (e) {
            if (e.code !== 409) throw e
            errorIds.push(id)
            errorData = errorData.concat(e.data)
            return e
          }
        })()
      )
    )

    if (errorIds.length) {
      yield put({
        type: types.DELETE_SELECTED_MENU_LOCATIONS_ERROR,
        payload: {
          code: 409,
          data: errorData
        },
        ids: errorIds
      })
    } else {
      yield put({
        type: types.DELETE_SELECTED_MENU_LOCATIONS_SUCCESS,
        payload: { status: 'success' }
      })
    }
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_MENU_LOCATIONS_ERROR,
      payload: error
    })
  }
}

function* getMenuStations({ params }) {
  try {
    const { data, meta } = yield call(menuMakerService.getMenuStations, params)

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_MENU_STATIONS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_MENU_STATIONS_ERROR, payload: error })
  }
}

function* postMenuStation({ data }) {
  try {
    yield call(menuMakerService.postMenuStation, data)
    yield put({
      type: types.POST_MENU_STATION_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_MENU_STATION_ERROR, payload: error })
  }
}

function* deleteMenuStation({ id }) {
  try {
    yield call(menuMakerService.deleteMenuStation, id)
    yield put({
      type: types.DELETE_MENU_STATION_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.DELETE_MENU_STATION_ERROR, payload: error })
  }
}

function* getMenuStation({ id }) {
  try {
    const response = yield call(menuMakerService.getMenuStation, id)
    yield put({ type: types.GET_MENU_STATION_BY_ID_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_MENU_STATION_BY_ID_ERROR, payload: error })
  }
}

function* putMenuStation({ id, data }) {
  try {
    yield call(menuMakerService.putMenuStation, id, data)
    yield put({
      type: types.PUT_MENU_STATION_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_MENU_STATION_ERROR, payload: error })
  }
}

function* deleteSelectedMenuStations({ ids }) {
  try {
    yield all(ids.map(id => call(menuMakerService.deleteMenuStation, id)))
    yield put({
      type: types.DELETE_SELECTED_MENU_STATIONS_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_MENU_STATIONS_ERROR,
      payload: error
    })
  }
}

function* getMenuIntegrations() {
  try {
    const response = yield call(menuMakerService.getMenuIntegrations)
    yield put({
      type: types.GET_MENU_INTEGRATIONS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_MENU_INTEGRATIONS_ERROR,
      payload: error
    })
  }
}

function* getMenuMakerProviders() {
  try {
    const response = yield call(menuMakerService.getMenuMakerProviders)
    yield put({
      type: types.GET_MENU_MAKER_PROVIDERS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_MENU_MAKER_PROVIDERS_ERROR,
      payload: error
    })
  }
}

function* postMenuIntegrationConnect({ data }) {
  try {
    yield call(menuMakerService.postMenuIntegrationConnect, data)
    yield put({
      type: types.POST_MENU_INTEGRATION_CONNECT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_MENU_INTEGRATION_CONNECT_ERROR,
      payload: error
    })
  }
}

function* postMenuIntegrationDisconnect({ id }) {
  try {
    yield call(menuMakerService.postMenuIntegrationDisconnect, id)
    yield put({
      type: types.POST_MENU_INTEGRATION_DISCONNECT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_MENU_INTEGRATION_DISCONNECT_ERROR,
      payload: error
    })
  }
}

function* postMenuIntegrationSync({ data }) {
  try {
    yield call(menuMakerService.postMenuIntegrationSync, data)
    yield put({
      type: types.POST_MENU_INTEGRATION_SYNC_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_MENU_INTEGRATION_SYNC_ERROR, payload: error })
  }
}

function* postMenuMakerItemBulk({ payload }) {
  try {
    yield call(menuMakerService.postMenuMakerItemBulk, payload)
    yield put({
      type: types.POST_MENU_MAKER_ITEM_BULK_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_MENU_MAKER_ITEM_BULK_ERROR, payload: error })
  }
}

const menuMakerSaga = {
  getMenuItems,
  getMenuItem,
  postMenuItem,
  putMenuItem,
  deleteMenuItem,
  deleteSelectedMenuItems,
  postMenuItemBulk,
  getMenuLocations,
  getMenuLocation,
  postMenuLocation,
  putMenuLocation,
  deleteMenuLocation,
  deleteSelectedMenuLocations,
  getMenuStations,
  getMenuStation,
  postMenuStation,
  putMenuStation,
  deleteMenuStation,
  deleteSelectedMenuStations,
  getMenuIntegrations,
  postMenuIntegrationConnect,
  postMenuIntegrationDisconnect,
  postMenuIntegrationSync,
  getMenuMakerProviders,
  postMenuMakerItemBulk
}

export default menuMakerSaga
