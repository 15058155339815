import { errorHandler } from '../utils'
import ipApi from './ipApi'
import api from 'services/api'

const getIp = async () => {
  try {
    const response = await ipApi({
      method: 'GET',
      url: '/',
      params: {
        format: 'json'
      }
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getIpProviders = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/ipprovider`,
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putIpProviders = async data => {
  try {
    await api({
      method: 'PUT',
      url: `/ipprovider`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const ipService = {
  getIp,
  getIpProviders,
  putIpProviders
}

export default ipService
