import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withTheme } from '@material-ui/core'
import classNames from 'classnames'

import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { TableLibraryCell } from 'components/TableLibrary'
import DeviceTableIcon from '../DeviceTableIcon'
import DeviceMoreInfoCard from 'components/Card/DeviceMoreInfoCard'
import { getDeviceCardPopupSize } from 'utils/deviceUtils'
import { columnWidths } from 'components/TableLibrary/TableLibraryHead'
import MaterialPopup from 'components/Popup/MaterialPopup'

const useStyles = makeStyles({
  cellWrapper: {
    padding: '0 10px'
  },
  portalContainer: ({ theme: { palette, type }, popupSize }) => ({
    position: 'absolute',
    width: popupSize.width,
    height: popupSize.height,
    zIndex: 112,
    borderRadius: 7,
    backgroundColor: palette[type].loader.background
  }),
  shadowLight: {
    boxShadow: 'rgb(182 186 198) 0px 2px 4px 0px'
  },
  shadowDark: {
    boxShadow: 'rgb(0 0 0) 0px 2px 4px 0px'
  },
  disabled: {
    cursor: 'unset'
  }
})

const DeviceTypeIconCell = ({
  item,
  paddingVariant = 'none',
  align = 'right',
  wrapHelperClass,
  isTableLibraryCell = true,
  theme,
  isPopupDisabled = false,
  wrapperClass
}) => {
  const [isPortraitImg, setIsPortraitImg] = useState(false)
  const popupSize = getDeviceCardPopupSize(isPortraitImg)

  const classes = useStyles({ theme, popupSize })
  const { color, icon = '' } = item?.deviceType || {}

  const libraryIcon = useMemo(
    () =>
      icon
        ? null
        : () => (
            <DeviceTableIcon
              deviceType={item.deviceType}
              className={classNames({
                [classes.disabled]: isPopupDisabled
              })}
            />
          ),
    [item.deviceType, icon, isPopupDisabled, classes.disabled]
  )

  const renderCell = useMemo(
    () => (
      <div>
        <MaterialPopup
          placement="right"
          on="hover"
          trigger={
            <LibraryTypeIcon
              color={color || 'transparent'}
              style={color ? {} : undefined}
              iconHelperClass={icon}
              wrapHelperClass={wrapHelperClass}
              icon={libraryIcon}
            />
          }
          disabled={isPopupDisabled}
        >
          <DeviceMoreInfoCard
            device={item}
            isPortraitImg={isPortraitImg}
            setIsPortraitImg={setIsPortraitImg}
            loaderSize={popupSize}
          />
        </MaterialPopup>
      </div>
    ),
    [
      color,
      icon,
      libraryIcon,
      item,
      wrapHelperClass,
      isPortraitImg,
      popupSize,
      isPopupDisabled
    ]
  )

  return isTableLibraryCell ? (
    <TableLibraryCell
      align={align}
      paddingVariant={paddingVariant}
      width={columnWidths.feature}
    >
      {renderCell}
    </TableLibraryCell>
  ) : (
    <div className={classNames(classes.cellWrapper, wrapperClass)}>
      {renderCell}
    </div>
  )
}

DeviceTypeIconCell.propTypes = {
  item: PropTypes.object,
  onPreviewClick: PropTypes.func,
  padding: PropTypes.string,
  align: PropTypes.string,
  wrapperClass: PropTypes.string
}

export default withTheme(DeviceTypeIconCell)
