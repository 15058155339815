import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ palette, type }) => ({
  container: {
    backgroundColor: palette[type].formControls.disabled.background
  },
  counterIcon: {
    color: '#74809a'
  },
  counterValue: {
    width: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: palette[type].formControls.input.color
  }
})

const FormControlCounter = ({
  value = 0,
  rootClassName,
  inputValueClassName,
  name,
  id,
  classes,
  onChange = () => {},
  min,
  max,
  step = 1,
  disabled
}) => {
  const onInc = useCallback(() => {
    const newValue = Number.isFinite(max)
      ? Math.min(value + step, max)
      : value + step

    if (!disabled) {
      if (name) {
        onChange({
          target: {
            value: newValue,
            name
          }
        })
      } else {
        onChange(newValue)
      }
    }
  }, [max, name, onChange, step, value, disabled])

  const onDec = useCallback(() => {
    const newValue = Number.isFinite(min)
      ? Math.max(value - step, min)
      : value - step

    if (!disabled) {
      if (name) {
        onChange({
          target: {
            value: newValue,
            name
          }
        })
      } else {
        onChange(newValue)
      }
    }
  }, [min, name, onChange, step, value, disabled])

  return (
    <Grid
      id={id}
      container
      className={
        disabled ? classNames(rootClassName, classes.container) : rootClassName
      }
    >
      <Grid className={classes.counterIcon} item onClick={onDec}>
        <i className="fa-regular fa-square-minus" />
      </Grid>
      <Grid item>
        <Typography
          className={classNames(classes.counterValue, inputValueClassName)}
        >
          {value}
        </Typography>
      </Grid>
      <Grid className={classes.counterIcon} item onClick={onInc}>
        <i className="fa-regular fa-square-plus" />
      </Grid>
    </Grid>
  )
}

FormControlCounter.propTypes = {
  value: PropTypes.number,
  handleChange: PropTypes.func,
  onChange: PropTypes.func,
  rootClassName: PropTypes.string,
  inputValueClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool
}

export default withStyles(styles)(FormControlCounter)
