import React, { useState, useRef } from 'react'
import { CircularLoader } from 'components/Loaders'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  transparentDiv: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  iframeheight: {
    height: '186px'
  }
}))

const VirtualDevicePreview = ({ virtualDeviceLink }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)

  const handleIframeLoad = () => {
    setIsLoading(false)
  }

  const iframe = useRef()
  iframe.muted = true

  return (
    <>
      {isLoading && <CircularLoader />}
      <div className={classes.transparentDiv}></div>
      <iframe
        ref={iframe}
        className={classes.iframeheight}
        src={virtualDeviceLink}
        width="100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="local-preview"
        muted
        onLoad={handleIframeLoad}
      />
    </>
  )
}

export default VirtualDevicePreview
