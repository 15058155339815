import { createSelector } from 'reselect'
import { _get } from 'utils/lodash'

function _clientSettingsSelector(state) {
  return state.new.client.settings
}

export const clientSettingsSelector = createSelector(
  _clientSettingsSelector,
  settings => settings
)

export const enableFeaturesSelector = createSelector(
  _clientSettingsSelector,
  settings => _get(settings, 'data.feature', [])
)
