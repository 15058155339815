import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import useUrlSearchParams from 'hooks/useUrlSearchParams'
import useSnackbar from 'hooks/useSnackbar'
import { storageSetToken } from 'utils/localStorage'
import { getUrlPrefix } from 'utils/permissionUrls'
import { ORG_TOKEN } from 'constants/api'

const SsoLogin = () => {
  const history = useHistory()
  const params = useUrlSearchParams()
  const { showSnackbar } = useSnackbar()

  useEffect(
    () => {
      const { token, expiresIn, error } = params

      if (error) {
        showSnackbar(decodeURI(error), 'error')
      }
      if (token && expiresIn) {
        storageSetToken(ORG_TOKEN, 'Bearer', token, expiresIn)
        history.push(getUrlPrefix('dashboard'))
      } else {
        history.push('/sign-in')
      }
    },
    // eslint-disable-next-line
    []
  )

  return <></>
}

export default SsoLogin
