import React, { memo } from 'react'
import ContentLoader from 'react-content-loader'
import { makeStyles, withTheme } from '@material-ui/core'

import Container from 'components/Containers/Container'

const styles = makeStyles({
  container: {
    padding: 15,
    gridTemplateColumns: ({ cols }) => `repeat(${cols}, 1fr)`
  }
})

const DeviceGridLoader = ({ theme, cols = 4, rows = 2, boxHeight = 300 }) => {
  const classes = styles({
    cols
  })

  return (
    <Container cols={String(cols)} customClass={classes.container}>
      {Array(rows * cols)
        .fill()
        .map((a, index) => (
          <ContentLoader
            style={{
              width: '100%',
              height: boxHeight
            }}
            key={`device-loader${index}`}
            backgroundColor={theme.palette[theme.type].loader.background}
            foregroundColor={theme.palette[theme.type].loader.foreground}
          >
            <rect x="0" y="0" rx="6" ry="6" width="100%" height={boxHeight} />
          </ContentLoader>
        ))}
    </Container>
  )
}

export default withTheme(memo(DeviceGridLoader))
