import { call, put } from 'redux-saga/effects'
import { alertServices } from '../services'
import * as types from '../actions'
import {
  associateCapAlertSuccess,
  associateCapAlertError
} from 'actions/alertActions'

function* getAlertDevicesById(action) {
  try {
    const response = yield call(
      alertServices.getAlertDevicesById,
      action.payload
    )
    yield put({
      type: types.GET_ALERT_DEVICES_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_ALERT_DEVICES_BY_ID_ERROR, payload: error })
  }
}

function* postAlertTrigger(action) {
  try {
    yield call(alertServices.postAlertTrigger, action.payload)
    yield put({
      type: types.POST_ALERT_TRIGGER_SUCCESS,
      payload: { isSuccess: true }
    })
    yield put({
      type: types.SET_ALERT_TRIGGER_DATA,
      payload: action.payload.alertDevices
    })
  } catch (error) {
    yield put({ type: types.POST_ALERT_TRIGGER_ERROR, payload: error })
  }
}

function* getDeviceActiveEmergencyAlert(action) {
  try {
    const response = yield call(
      alertServices.getDeviceActiveEmergencyAlert,
      action.payload
    )
    yield put({
      type: types.GET_DEVICE_ACTIVE_EMERGENCY_ALERT_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_ACTIVE_EMERGENCY_ALERT_ERROR,
      payload: error
    })
  }
}

function* getDeviceMediaEmergencyAlert(action) {
  try {
    const response = yield call(
      alertServices.getDeviceMediaEmergencyAlert,
      action.payload
    )
    yield put({
      type: types.GET_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR,
      payload: error
    })
  }
}

function* putDeviceMediaEmergencyAlert(action) {
  try {
    const response = yield call(
      alertServices.putDeviceMediaEmergencyAlert,
      action.payload
    )
    yield put({
      type: types.PUT_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR,
      payload: error
    })
  }
}

function* removeDeviceMediaEmergencyAlert(action) {
  try {
    const response = yield call(
      alertServices.removeDeviceMediaEmergencyAlert,
      action.payload
    )
    yield put({
      type: types.REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR,
      payload: error
    })
  }
}

function* getDeviceMediaCapAlert(action) {
  try {
    const response = yield call(
      alertServices.getDeviceMediaCapAlert,
      action.payload
    )
    yield put({
      type: types.GET_DEVICE_MEDIA_CAP_ALERT_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_MEDIA_CAP_ALERT_ERROR, payload: error })
  }
}

function* removeDeviceMediaCapAlert(action) {
  try {
    const response = yield call(
      alertServices.removeDeviceMediaCapAlert,
      action.payload
    )
    yield put({
      type: types.REMOVE_DEVICE_MEDIA_CAP_ALERT_SUCCESS,
      payload: response
    })
    yield put({ type: types.REQUEST_CAP_ALERT_DEVICES })
  } catch (error) {
    yield put({
      type: types.REMOVE_DEVICE_MEDIA_CAP_ALERT_ERROR,
      payload: error
    })
  }
}

function* putDeviceMediaCapAlert(action) {
  try {
    const response = yield call(
      alertServices.putDeviceMediaCapAlert,
      action.payload
    )
    yield put({
      type: types.PUT_DEVICE_MEDIA_CAP_ALERT_SUCCESS,
      payload: response
    })
    yield put({ type: types.REQUEST_CAP_ALERT_DEVICES })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_MEDIA_CAP_ALERT_ERROR,
      payload: error || 'Error'
    })
  }
}

function* disableAlert() {
  try {
    yield call(alertServices.disableAlert)
    yield put({ type: types.DISABLE_ALERT_SUCCESS, payload: { status: 'ok' } })
  } catch (error) {
    yield put({ type: types.DISABLE_ALERT_ERROR, payload: error })
  }
}

function* disableDeviceAlert(action) {
  try {
    yield call(alertServices.disableDeviceAlert, action.payload)
    yield put({
      type: types.DISABLE_DEVICE_ALERT_SUCCESS,
      payload: { status: 'ok' }
    })
  } catch (error) {
    yield put({ type: types.DISABLE_DEVICE_ALERT_ERROR, payload: error })
  }
}

function* associateCapAlertWorker({ payload }) {
  try {
    const { data } = yield call(alertServices.associateCapAlert, payload)
    yield put(associateCapAlertSuccess(data))
    yield put({ type: types.REQUEST_CAP_ALERT_DEVICES })

    yield put({
      type: types.SET_CAP_ALERT_DATA,
      payload: payload.alertDevices
    })
  } catch (error) {
    yield put(associateCapAlertError(error))
  }
}

function* getIpawsAlertDevices() {
  try {
    const data = yield call(alertServices.getIpawsAlertDevices)
    yield put({
      type: types.GET_IPAWS_ALERT_DEVICES_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_IPAWS_ALERT_DEVICES_ERROR,
      payload: error
    })
  }
}

function* associateIpawsAlert({ payload }) {
  try {
    const { data } = yield call(alertServices.associateIpawsAlert, payload)
    yield put({
      type: types.ASSOCIATE_IPAWS_ALERT_SUCCESS,
      payload: data
    })
    yield put({ type: types.GET_IPAWS_ALERT_DEVICES })
    yield put({
      type: types.SET_IPAWS_ALERT_DATA,
      payload: payload.alertDevices
    })
  } catch (error) {
    yield put({
      type: types.ASSOCIATE_IPAWS_ALERT_ERROR,
      payload: error
    })
  }
}

function* removeDeviceMediaIpawsAlert(action) {
  try {
    const response = yield call(
      alertServices.removeDeviceMediaIpawsAlert,
      action.payload
    )
    yield put({
      type: types.REMOVE_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS,
      payload: response
    })
    yield put({ type: types.GET_IPAWS_ALERT_DEVICES })
  } catch (error) {
    yield put({
      type: types.REMOVE_DEVICE_MEDIA_IPAWS_ALERT_ERROR,
      payload: error
    })
  }
}

function* getDeviceMediaIpawsAlert(action) {
  try {
    const response = yield call(
      alertServices.getDeviceMediaIpawsAlert,
      action.payload
    )
    yield put({
      type: types.GET_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_MEDIA_IPAWS_ALERT_ERROR,
      payload: error
    })
  }
}

function* putDeviceMediaIpawsAlert(action) {
  try {
    const response = yield call(
      alertServices.putDeviceMediaIpawsAlert,
      action.payload
    )
    yield put({
      type: types.PUT_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS,
      payload: response
    })
    yield put({ type: types.GET_IPAWS_ALERT_DEVICES })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_MEDIA_IPAWS_ALERT_ERROR,
      payload: error || 'Error'
    })
  }
}

const alertSaga = {
  getAlertDevicesById,
  postAlertTrigger,
  getDeviceActiveEmergencyAlert,
  getDeviceMediaEmergencyAlert,
  putDeviceMediaEmergencyAlert,
  removeDeviceMediaEmergencyAlert,
  getDeviceMediaCapAlert,
  removeDeviceMediaCapAlert,
  putDeviceMediaCapAlert,
  disableAlert,
  disableDeviceAlert,
  associateCapAlertWorker,
  getIpawsAlertDevices,
  associateIpawsAlert,
  removeDeviceMediaIpawsAlert,
  getDeviceMediaIpawsAlert,
  putDeviceMediaIpawsAlert
}

export default alertSaga
