import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { Text } from 'components/Typography'
import { DARK } from '../../theme'

const useStyles = makeStyles(({ type, palette }) => ({
  root: {
    position: 'absolute',
    ...(type === DARK
      ? { left: 5, right: 4, bottom: 5 }
      : { left: 0, right: 0, bottom: 0 }),
    backgroundColor: palette[type].helperCard.background,
    padding: 14
  }
}))

const DashboardCardBottomText = ({ text }) => {
  const classes = useStyles()

  return (
    <Text
      rootClassName={classes.root}
      variant="small"
      fontStyle="italic"
      color="highlight"
      align="center"
    >
      {text}
    </Text>
  )
}

DashboardCardBottomText.propTypes = {
  text: PropTypes.string
}

export default DashboardCardBottomText
