import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { withTheme } from '@material-ui/core'
import classNames from 'classnames'

import WrapperDatePicker from './WrapperDatePicker'
import { getDateRangeInfo } from 'utils'
import Container from 'components/Containers/Container'
import Error from 'components/Form/Error'
import Spacing from 'components/Containers/Spacing'
import { DATE_PICKER_VIEW_FORMAT } from 'constants/dateTimeFormats'

const popperModifiers = {
  flip: {
    enabled: false
  },
  preventOverflow: {
    enabled: true,
    escapeWithReference: false
  }
}

const FormControlCustomDateRangePickers = ({
  startLabel = 'Start Date',
  endLabel = 'End Date',
  startDate,
  endDate,
  handleStartChange,
  handleEndChange,
  disabled,
  disabledEndDate,
  disabledStartDate,
  isOptional,
  dateFormat = DATE_PICKER_VIEW_FORMAT,
  popperStartPlacement = 'bottom-start',
  popperEndPlacement = 'bottom-end',
  iconClassName = '',
  inputClassName = '',
  startDateName,
  endDateName,
  fromCurrentDate = true,
  multiline,
  errors = {},
  t,
  limitByStartDate = true,
  limitByEndDate = true,
  isClearable = false,
  theme
}) => {
  const handleStartDateChange = useCallback(
    value => {
      handleStartChange(value ? moment(value) : value)
    },
    [handleStartChange]
  )

  const handleEndDateChange = useCallback(
    value => {
      handleEndChange(value ? moment(value) : value)
    },
    [handleEndChange]
  )

  const handleClearStartDate = useCallback(
    e => {
      e.stopPropagation()
      handleStartDateChange('')
    },
    [handleStartDateChange]
  )

  const handleClearEndDate = useCallback(
    e => {
      e.stopPropagation()
      handleEndDateChange('')
    },
    [handleEndDateChange]
  )

  const dateInfo = useMemo(() => {
    return getDateRangeInfo({ startDate, endDate })
  }, [startDate, endDate])

  return (
    <Spacing
      relative
      direction="row"
      variant={0}
      rootClassName={classNames({
        DarkTheme: theme.type === 'dark'
      })}
    >
      <Container cols={multiline ? '1' : '2'}>
        <Grid container>
          <WrapperDatePicker
            isOptional={isOptional}
            label={t(startLabel)}
            disabled={disabled || disabledStartDate}
            selected={dateInfo.startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={dateInfo.startDate}
            endDate={dateInfo.endDate}
            minDate={fromCurrentDate ? dateInfo.minStartDate : false}
            {...(limitByEndDate && { maxDate: dateInfo.maxStartDate })}
            dateFormat={dateFormat}
            popperPlacement={popperStartPlacement}
            popperModifiers={popperModifiers}
            alert={errors[startDateName]}
            iconClassName={iconClassName}
            inputClassName={inputClassName}
            isClearable={isClearable}
            onClear={handleClearStartDate}
          />
          <Error
            absolute
            error={errors[startDateName]}
            condition={!!errors[startDateName]}
          />
        </Grid>
        <Grid container>
          <WrapperDatePicker
            isOptional={isOptional}
            label={t(endLabel)}
            disabled={disabled || disabledEndDate}
            selected={dateInfo.endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={dateInfo.startDate}
            endDate={dateInfo.endDate}
            {...(limitByStartDate && { minDate: dateInfo.minEndDate })}
            dateFormat={dateFormat}
            alert={errors[endDateName]}
            popperPlacement={popperEndPlacement}
            popperModifiers={popperModifiers}
            iconClassName={iconClassName}
            inputClassName={inputClassName}
            isClearable={isClearable}
            onClear={handleClearEndDate}
          />
          <Error
            absolute
            error={errors[endDateName]}
            condition={!!errors[endDateName]}
          />
        </Grid>
      </Container>
    </Spacing>
  )
}

FormControlCustomDateRangePickers.propTypes = {
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  handleStartChange: PropTypes.func.isRequired,
  handleEndChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  popperStartPlacement: PropTypes.string,
  popperEndPlacement: PropTypes.string,
  iconClassName: PropTypes.string,
  errors: PropTypes.object,
  startDateName: PropTypes.string,
  endDateName: PropTypes.string,
  fromCurrentDate: PropTypes.bool,
  multiline: PropTypes.bool,
  disabledEndDate: PropTypes.bool,
  disabledStartDate: PropTypes.bool
}

export default withTranslation('translations')(
  withTheme(FormControlCustomDateRangePickers)
)
