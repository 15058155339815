import React, { useCallback } from 'react'
import moment from 'moment/moment'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
// CONSTANTS
import { templateStatus } from 'constants/template'
import { playlistStatus } from 'constants/playlist'
import { mediaStatus } from 'constants/media'
// UTILS
import { getPlaylistStatus } from 'utils/playlistUtils'
import { getTemplateStatus } from 'utils/templateUtils'
import { getMediaStatus } from 'utils/mediaUtils'
// COMPONENTS
import { DateTimeView } from 'components/TableLibrary'

const useStyles = makeStyles(() => ({
  dateText: {
    '&:nth-child(2)': {
      marginLeft: 0
    }
  },
  tooltipTextClass: {
    color: 'white !important',
    marginLeft: '2px'
  }
}))

const useStatusInfo = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getPlaylistStatusInfo = useCallback((playlist = {}) => {
    const status = getPlaylistStatus(playlist)

    switch (status) {
      case playlistStatus.expired:
        return {
          status,
          label: t('Expired on date', { date: '' }),
          content: (
            <DateTimeView
              withSeparator
              separator={','}
              direction={'row'}
              textClass={classes.tooltipTextClass}
              textSmallClass={classes.tooltipTextClass}
              date={moment(playlist.expireOn)}
            />
          ),
          text: (
            <Grid container alignItems={'baseline'}>
              {t('Expired on date', { date: '' })}
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.dateText}
                date={moment(playlist.expireOn)}
              />
            </Grid>
          )
        }
      case playlistStatus.expires:
        return {
          status,
          label: t('Expires on date', { date: '' }),
          content: (
            <DateTimeView
              withSeparator
              separator={','}
              direction={'row'}
              textClass={classes.tooltipTextClass}
              textSmallClass={classes.tooltipTextClass}
              date={moment(playlist.expireOn)}
            />
          ),
          text: (
            <Grid container alignItems={'baseline'}>
              {t('Expires on date', { date: '' })}
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.dateText}
                date={moment(playlist.expireOn)}
              />
            </Grid>
          )
        }
      case playlistStatus.activates:
        return {
          status,
          label: t('Active on date', { date: '' }),
          content: (
            <DateTimeView
              withSeparator
              separator={','}
              direction={'row'}
              textClass={classes.tooltipTextClass}
              textSmallClass={classes.tooltipTextClass}
              date={moment(playlist.activateOn)}
            />
          ),
          text: (
            <Grid container alignItems={'baseline'}>
              {t('Active on date', { date: '' })}
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.dateText}
                date={moment(playlist.activateOn)}
              />
            </Grid>
          )
        }
      case playlistStatus.notApproved:
        return {
          status,
          label: '',
          content: t('Awaiting Approval'),
          text: t('Awaiting Approval')
        }
      default:
        return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTemplateStatusInfo = useCallback(
    (template = {}) => {
      const status = getTemplateStatus(template)

      switch (status) {
        case templateStatus.expired:
          return {
            status,
            label: t('Expired on date', { date: '' }),
            content: (
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.tooltipTextClass}
                textSmallClass={classes.tooltipTextClass}
                date={moment(template.expireOn)}
              />
            ),
            text: (
              <Grid container alignItems={'baseline'}>
                {t('Expired on date', { date: '' })}
                <DateTimeView
                  withSeparator
                  separator={','}
                  direction={'row'}
                  textClass={classes.dateText}
                  date={moment(template.expireOn)}
                />
              </Grid>
            )
          }
        case templateStatus.expires:
          return {
            status,
            label: t('Expires on date', { date: '' }),
            content: (
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.tooltipTextClass}
                textSmallClass={classes.tooltipTextClass}
                date={moment(template.expireOn)}
              />
            ),
            text: (
              <Grid container alignItems={'baseline'}>
                {t('Expires on date', { date: '' })}
                <DateTimeView
                  withSeparator
                  separator={','}
                  direction={'row'}
                  textClass={classes.dateText}
                  date={moment(template.expireOn)}
                />
              </Grid>
            )
          }
        case templateStatus.activates:
          return {
            status,
            label: t('Active on date', { date: '' }),
            content: (
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.tooltipTextClass}
                textSmallClass={classes.tooltipTextClass}
                date={moment(template.activateOn)}
              />
            ),
            text: (
              <Grid container alignItems={'baseline'}>
                {t('Active on date', { date: '' })}
                <DateTimeView
                  withSeparator
                  separator={','}
                  direction={'row'}
                  textClass={classes.dateText}
                  date={moment(template.activateOn)}
                />
              </Grid>
            )
          }
        case templateStatus.notApproved:
          return {
            status,
            label: '',
            content: t('Awaiting Approval'),
            text: t('Awaiting Approval')
          }
        default:
          return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const getMediaStatusInfo = useCallback(media => {
    const status = getMediaStatus(media)

    switch (status) {
      case mediaStatus.expired:
        return {
          status,
          label: t('Expired on date', { date: '' }),
          content: (
            <DateTimeView
              withSeparator
              separator={','}
              direction={'row'}
              textClass={classes.tooltipTextClass}
              textSmallClass={classes.tooltipTextClass}
              date={moment(media.expireOn)}
            />
          ),
          text: (
            <Grid container alignItems={'baseline'}>
              {t('Expired on date', { date: '' })}
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.dateText}
                date={moment(media.expireOn)}
              />
            </Grid>
          )
        }
      case mediaStatus.expires:
        return {
          status,
          label: t('Expires on date', { date: '' }),
          content: (
            <DateTimeView
              withSeparator
              separator={','}
              direction={'row'}
              textClass={classes.tooltipTextClass}
              textSmallClass={classes.tooltipTextClass}
              date={moment(media.expireOn)}
            />
          ),
          text: (
            <Grid container alignItems={'baseline'}>
              {t('Expires on date', { date: '' })}
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.dateText}
                date={moment(media.expireOn)}
              />
            </Grid>
          )
        }
      case mediaStatus.pending:
        return {
          status,
          label: t('Active on date', { date: '' }),
          content: (
            <DateTimeView
              direction={'row'}
              withSeparator
              separator={','}
              textClass={classes.tooltipTextClass}
              textSmallClass={classes.tooltipTextClass}
              date={moment(media.activateOn)}
            />
          ),
          text: (
            <Grid container alignItems={'baseline'}>
              {t('Active on date', { date: '' })}
              <DateTimeView
                direction={'row'}
                withSeparator
                separator={','}
                textClass={classes.dateText}
                date={moment(media.activateOn)}
              />
            </Grid>
          )
        }
      case mediaStatus.active:
        return {
          status,
          label: t('Active on date', { date: '' }),
          content: (
            <DateTimeView
              withSeparator
              separator={','}
              direction={'row'}
              textClass={classes.tooltipTextClass}
              textSmallClass={classes.tooltipTextClass}
              date={moment(media.activateOn)}
            />
          ),
          text: (
            <Grid container alignItems={'baseline'}>
              {t('Active on date', { date: '' })}
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.dateText}
                date={moment(media.activateOn)}
              />
            </Grid>
          )
        }
      case mediaStatus.notApproved:
        return {
          status,
          label: t('Moderated Content'),
          content: t(
            'This media is awaiting approval from your account administrator and cannot be scheduled for Playback at this time'
          ),
          text: t('Awaiting Approval')
        }
      case mediaStatus.rejected:
        return {
          status,
          label: '',
          content: t('Rejected'),
          text: t('Rejected')
        }
      case mediaStatus.disabled:
        return {
          status,
          label: t('Disabled on date', { date: '' }),
          content: (
            <Grid container alignItems={'baseline'}>
              {t('Disabled on date', { date: '' })}
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.tooltipTextClass}
                textSmallClass={classes.tooltipTextClass}
                date={media.feature?.disabledAt}
              />
            </Grid>
          ),
          text: (
            <Grid container alignItems={'baseline'}>
              {t('Disabled on date', { date: '' })}
              <DateTimeView
                withSeparator
                separator={','}
                direction={'row'}
                textClass={classes.dateText}
                date={media.feature?.disabledAt}
              />
            </Grid>
          )
        }
      case mediaStatus.failed:
        return {
          status,
          label: '',
          content: t(media.failedReason || 'Failed'),
          text: t(media.failedReason || 'Failed')
        }
      case mediaStatus.processing:
        return {
          status,
          label: '',
          content: t('Processing'),
          text: t('Processing')
        }
      case mediaStatus.deauthorized:
        return {
          status,
          label: '',
          content: t('Deauthorized Media - Logged out from 3rd party source'),
          text: t('Deauthorized Media - Logged out from 3rd party source')
        }
      default:
        return ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    getPlaylistStatusInfo,
    getTemplateStatusInfo,
    getMediaStatusInfo
  }
}

export default useStatusInfo
