import { fabric } from 'fabric'
import { gifToSprite } from './gifToSprite'

const [PLAY, PAUSE, STOP] = [0, 1, 2]

export const fabricGif = async ({
  gif,
  maxWidth,
  maxHeight,
  maxDuration,
  showSnackbar,
  t
}) => {
  const { error, dataUrl, delay, frameWidth, framesLength } = await gifToSprite(
    {
      gif,
      maxWidth,
      maxHeight,
      maxDuration,
      showSnackbar,
      t
    }
  )

  if (error) return { error }

  if (!dataUrl.includes('data:image')) {
    showSnackbar(
      t(
        `The 'gif' file added to the canvas exceeds the maximum size that the browser can handle`
      ),
      'error'
    )

    return null
  }
  return new Promise(resolve => {
    fabric.Image.fromURL(dataUrl, img => {
      const sprite = img.getElement()
      let framesIndex = 0
      let start = performance.now()
      let status

      img.width = frameWidth
      img.height = sprite.naturalHeight
      img.mode = 'image'

      img._render = function (ctx) {
        if (status === PAUSE || (status === STOP && framesIndex === 0)) return
        const now = performance.now()
        const delta = now - start
        if (delta > delay) {
          start = now
          framesIndex++
        }
        if (framesIndex === framesLength || status === STOP) framesIndex = 0
        ctx.drawImage(
          sprite,
          frameWidth * framesIndex,
          0,
          frameWidth,
          sprite.height,
          -this.width / 2,
          -this.height / 2,
          frameWidth,
          sprite.height
        )
      }
      img.play = function () {
        status = PLAY
        this.dirty = true
      }
      img.pause = function () {
        status = PAUSE
        this.dirty = false
      }
      img.stop = function () {
        status = STOP
        this.dirty = false
      }
      img.getStatus = () => ['Playing', 'Paused', 'Stopped'][status]

      img.play()
      resolve(img)
    })
  })
}
