import React from 'react'
import ContentLoader from 'react-content-loader'
import { withTheme } from '@material-ui/core'

const RectLoader = ({
  theme,
  height = '100%',
  width = '100%',
  radius = 6,
  padding = 0,
  ...styleProps
}) => (
  <ContentLoader
    style={{
      width,
      height,
      padding,
      ...styleProps
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect x="0" y="0" rx={radius} ry={radius} width="100%" height="100%" />
  </ContentLoader>
)

export default withTheme(RectLoader)
