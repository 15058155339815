import update from 'immutability-helper'

import { shapeOfBody } from 'constants/initialLibraryState'
import * as types from '../actions'

const initialState = {
  remote: {
    ...shapeOfBody,
    isFetching: false
  }
}

const calendarAggregatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REMOTE_CALENDARS:
      return update(state, {
        remote: {
          response: { $set: [] },
          isFetching: {
            $set: true
          }
        }
      })
    case types.GET_REMOTE_CALENDARS_SUCCESS:
      return update(state, {
        remote: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_REMOTE_CALENDARS_ERROR:
      return update(state, {
        remote: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })

    default:
      return state
  }
}

export default calendarAggregatorReducer
