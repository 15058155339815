import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withStyles, Paper } from '@material-ui/core'
import classNames from 'classnames'

import CheckboxSwitcher from 'components/Checkboxes/Switcher'
import { truncateWithEllipsis } from 'utils/truncateStringUtils'
import { Text } from 'components/Typography'
import { getContrastTextFromRgb } from 'utils/color'
import Tooltip from 'components/Tooltip'

const styles = ({ palette, type, colors }) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    background: palette[type].card.background,
    border: `1px solid ${palette[type]?.card?.greyHeader?.border}`,
    transition: 'all .05s ease-in-out'
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    fontSize: 24
  },
  youtube: {
    color: colors.youtube[type]
  },
  pointer: {
    cursor: 'pointer'
  },
  info: {
    display: 'flex',
    alignItems: 'center'
  }
})

const FavoriteFeatureCard = ({
  id,
  alias,
  description,
  icon,
  color,
  selected,
  onToggle,
  withToggle = true,
  cardClassName,
  classes,
  t
}) => {
  const handleToggle = useCallback(() => {
    onToggle(!selected, id)
  }, [onToggle, selected, id])

  const paperProps = useMemo(
    () =>
      !withToggle
        ? {
            onClick: handleToggle,
            ...(selected && {
              style: {
                backgroundColor: color,
                borderColor: color
              }
            })
          }
        : {},
    [withToggle, handleToggle, selected, color]
  )

  const textProps = useMemo(
    () =>
      !withToggle && selected
        ? {
            style: { color: getContrastTextFromRgb(color) }
          }
        : {},
    [withToggle, selected, color]
  )

  const iconStyle = useMemo(
    () => ({
      color:
        icon === 'fa-brands fa-youtube'
          ? undefined
          : !withToggle && selected
          ? getContrastTextFromRgb(color)
          : color
    }),
    [withToggle, selected, color, icon]
  )

  return (
    <Tooltip
      arrow
      arrowWithHeaderColor={!!description}
      withHeader={!!description}
      headerText={t(alias)}
      title={description || t(alias)}
    >
      <Paper
        className={classNames(classes.card, cardClassName, {
          [classes.pointer]: !withToggle
        })}
        {...paperProps}
      >
        <div className={classes.info}>
          <div
            className={classNames(classes.icon, {
              [classes.youtube]: icon === 'fa-brands fa-youtube'
            })}
            style={iconStyle}
          >
            <i
              className={
                icon === 'fa-brands fa-youtube' ? 'fa fa-youtube-play' : icon
              }
            />
          </div>

          <Text className={classes.pointer} {...textProps}>
            {truncateWithEllipsis(t(alias), 25)}
          </Text>
        </div>

        {withToggle && (
          <CheckboxSwitcher
            id={id}
            value={selected}
            handleChange={handleToggle}
          />
        )}
      </Paper>
    </Tooltip>
  )
}

FavoriteFeatureCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  alias: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  withToggle: PropTypes.bool,
  cardClassName: PropTypes.string
}

export default withTranslation('translations')(
  withStyles(styles)(FavoriteFeatureCard)
)
