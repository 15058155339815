import { createAppApi } from 'services/api'
import sortConstants from 'constants/sortConstants'
import apiTags from 'constants/apiTags'
import { createSuccessInvalidator } from 'utils/apiUtils'

export const roomsApi = createAppApi('roomsQuery', {
  tagTypes: [apiTags.room],
  endpoints: builder => ({
    getRooms: builder.query({
      providesTags: [apiTags.room],
      query: (params = {}) => ({
        url: '/spaceBooking/room',
        params: {
          ...sortConstants.Room,
          ...params
        }
      })
    }),
    getRoom: builder.query({
      query: id => ({
        url: `/spaceBooking/room/${id}`
      })
    }),
    postRoom: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.room]),
      query: data => ({
        method: 'POST',
        url: '/spaceBooking/room',
        data
      })
    }),
    putRoom: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.room]),
      query: ({ id, ...data }) => ({
        method: 'PUT',
        url: `/spaceBooking/room/${id}`,
        data
      })
    }),
    deleteRoom: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.room]),
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/spaceBooking/room/${id}`
      })
    }),
    deleteRooms: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.room]),
      query: ids => ({
        method: 'DELETE',
        url: `/spaceBooking/room/bulk`,
        data: {
          ids: ids.join(',')
        }
      })
    })
  })
})

export const {
  useLazyGetRoomsQuery,
  useLazyGetRoomQuery,
  useGetRoomsQuery,
  usePostRoomMutation,
  usePutRoomMutation,
  useDeleteRoomMutation,
  useDeleteRoomsMutation,
  util
} = roomsApi
