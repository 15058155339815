const convertToSizes = (
  value,
  convertToSize,
  pointDigits = 2,
  returnLabel = true,
  sizes
) => {
  const _sizes = sizes ? sizes : ['MB', 'GB', 'TB', 'PB']
  const sizeIndex = convertToSize ? _sizes.indexOf(convertToSize) : -1

  let i = 0,
    n = parseInt(value, 10) || 0

  while ((n >= 1024 || (sizeIndex > -1 && i < sizeIndex)) && ++i) {
    n = n / 1024
  }

  if (!returnLabel) {
    return n.toFixed(pointDigits)
  }

  return { size: n.toFixed(pointDigits), label: _sizes[i] }
}

const mbToSize = convertToSizes

export const gbToSize = (
  gb,
  convertToSize,
  pointDigits = 2,
  returnLabel = true
) => {
  return convertToSizes(gb, convertToSize, pointDigits, returnLabel, [
    'GB',
    'TB',
    'PB'
  ])
}

export const bytesToString = bytes => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

export default mbToSize
