export const MEDIA_DATE_FORMAT = 'YYYY-MM-DD'
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD'
export const MEDIA_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const MEDIA_DATE_TIME_S_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const MEDIA_DATE_LOCAL_TIME_FORMAT = 'YYYY-MM-DD LT'
export const TIME_FORMAT = 'HH:mm'
export const TIME_S_FORMAT = 'HH:mm:ss'
export const AP_TIME_FORMAT = 'hh:mm a'
export const LOCAL_TIME_FORMAT = 'LT'
export const LOCAL_TIME_S_FORMAT = 'LTS'
export const AM_PM_FORMAT = 'hh:mm A'
export const AM_PM_S_FORMAT = 'hh:mm:ss A'
export const DAY_SHORT_FORMAT = 'ddd'
export const DAY_LONG_FORMAT = 'dddd'
export const DATE_VIEW_FORMAT = 'MMM Do, YYYY'
export const DATE_TIME_VIEW_FORMAT = 'MMM Do, YYYY, h:mmA'
export const DATE_PICKER_VIEW_FORMAT = 'MMM do, yyyy'
export const APP_HEALTH_TIME_FORMAT = 'YYYY-MM-DD LT'
export const DATE_VIEW_FORMAT_2 = 'Do MMM YYYY'
export const NORMAL_DATE_TIME_AP_FORMAT = 'YYYY-MM-DD hh:mm A'
export const NORMAL_DATE_TIME_S_AP_FORMAT = 'YYYY-MM-DD hh:mm:ss A'
export const DATE_TIME_S_VIEW_FORMAT_2 = 'Do MMM YYYY, h:mm:ssa'
export const DATE_TIME_VIEW_FORMAT_2 = 'Do MMM YYYY, h:mma'
