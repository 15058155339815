import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Calendar } from 'react-date-range'
import PropTypes from 'prop-types'
import moment from 'moment'

import MaterialPopup from 'components/Popup/MaterialPopup'
import FormControlInput from '../FormControlInput'
import { MEDIA_DATE_FORMAT } from 'constants/dateTimeFormats'
import { simulateEvent } from 'utils/formik'
import getStyles from './styles'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const useStyles = makeStyles(({ palette, type, typography }) => ({
  endAdornment: {
    position: 'absolute',
    right: '12px',
    height: 'calc(100% - 3px)',
    padding: '6px 10px',
    marginLeft: 4,
    display: 'grid',
    placeItems: 'center',
    marginRight: '-10px',
    background: palette[type].formControls.multipleDatesPicker.input.background,
    color: palette[type].formControls.multipleDatesPicker.input.color,
    borderLeft: `1px solid ${palette[type].formControls.multipleDatesPicker.input.border}`
  },
  clearIcon: {
    cursor: 'pointer',
    color: typography.lightAccent[type].color,
    marginTop: 3,
    zIndex: 5,

    '&:hover': {
      color: typography.darkAccent[type].color
    }
  },
  dateRangeRoot: {
    ...getStyles({ palette, type }, { onlyDate: true, noRange: true })
  }
}))

const FormControlDatePickerNew = ({
  name,
  label,
  value,
  error,
  touched,
  formControlContainerClass,
  formControlRootClass,
  withPortal = false,
  marginBottom = true,
  fullWidth = false,
  onFocus = f => f,
  onBlur = f => f,
  onChange,
  maskValue = MEDIA_DATE_FORMAT,
  minDate = moment().format(MEDIA_DATE_FORMAT),
  maxDate,
  labelPosition = 'top',
  disabled = false,
  inputProps = {},
  hideIcon = false,
  isClearable = false,
  fullHeight = false,
  onDoubleClick,
  autoFocus,
  startAdornmentIcon,
  readOnlyWithoutSelection,
  tooltip,
  isBottomError,
  showErrorText = true,
  displayFormat = MEDIA_DATE_FORMAT
}) => {
  const classes = useStyles({
    fullWidth
  })
  const [isFocusedInput, setFocusedInput] = useState(false)

  const inputRef = useRef()

  const showError = useMemo(() => {
    return !!(showErrorText && error && touched)
  }, [showErrorText, error, touched])

  const isErrorIcon = !isBottomError && showError

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.click()
    }
  }, [autoFocus])

  const handleChange = useCallback(
    closePopup => date => {
      onChange(simulateEvent(name, moment(date).format(maskValue)))
      closePopup()
    },
    [onChange, maskValue, name]
  )

  const handleFocus = useCallback(
    event => {
      setFocusedInput(true)

      onFocus(event)
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    event => {
      setFocusedInput(false)

      onBlur(event)
    },
    [onBlur]
  )

  const handleClearDate = useCallback(
    event => {
      event.stopPropagation()
      onChange(simulateEvent(name, ''))
    },
    [onChange, name]
  )

  const handleClose = useCallback(() => {
    if (!isFocusedInput) {
      onBlur(simulateEvent(name, value))
    }
  }, [onBlur, value, name, isFocusedInput])

  const handleCalendarClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const parseDateValue = useMemo(
    () =>
      value && moment(value, maskValue).isValid()
        ? moment(value, maskValue).format(displayFormat)
        : value || '',
    [value, maskValue, displayFormat]
  )

  return (
    <MaterialPopup
      open={isFocusedInput === true ? true : undefined}
      on="click"
      placement={'bottom'}
      onClose={handleClose}
      trigger={
        <FormControlInput
          inputRef={inputRef}
          labelPosition={labelPosition}
          label={label}
          endAdornment={
            (isClearable && value) || (!hideIcon && !isErrorIcon) ? (
              <>
                {isClearable && value ? (
                  <i
                    className={'fa-regular fa-xmark'}
                    onClick={handleClearDate}
                  />
                ) : null}
                {hideIcon || isErrorIcon ? null : (
                  <div
                    className={classes.endAdornment}
                    onClick={handleCalendarClick}
                  >
                    <i className={'fa-regular fa-calendar-days'} />
                  </div>
                )}
              </>
            ) : null
          }
          name={name}
          value={parseDateValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          fullWidth={fullWidth}
          error={error}
          touched={touched}
          marginBottom={marginBottom}
          disabled={disabled}
          formControlContainerClass={formControlContainerClass}
          formControlRootClass={formControlRootClass}
          readOnly
          fullHeight={fullHeight}
          onDoubleClick={onDoubleClick}
          startAdornmentIcon={startAdornmentIcon}
          readOnlyWithoutSelection={readOnlyWithoutSelection}
          tooltip={tooltip}
          showErrorText={showErrorText}
          {...inputProps}
        />
      }
      preventOverflow={
        withPortal
          ? {
              enabled: true,
              boundariesElement: 'viewport'
            }
          : {}
      }
      disabled={disabled}
    >
      {close => (
        <Calendar
          className={classes.dateRangeRoot}
          onChange={handleChange(close)}
          date={
            value && moment(value, maskValue).isValid()
              ? moment(value, maskValue).toDate()
              : null
          }
          minDate={
            minDate && moment(minDate, maskValue).isValid()
              ? moment(minDate, maskValue).toDate()
              : undefined
          }
          maxDate={
            maxDate && moment(maxDate, maskValue).isValid()
              ? moment(maxDate, maskValue).toDate()
              : undefined
          }
          weekStartsOn={1}
        />
      )}
    </MaterialPopup>
  )
}

FormControlDatePickerNew.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  formControlContainerClass: PropTypes.string,
  withPortal: PropTypes.bool,
  marginBottom: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  maskValue: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  hideIcon: PropTypes.bool,
  isClearable: PropTypes.bool,
  fullHeight: PropTypes.bool,
  displayFormat: PropTypes.string
}

export default FormControlDatePickerNew
