import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import InfoIcon from 'components/Icons/InfoIcon'

const StatusInfoIcon = ({
  data,
  getStatusInfo,
  popupPosition,
  className,
  typographyComponent = 'div'
}) => {
  const { t } = useTranslation()
  const status = getStatusInfo(data, t)

  return (
    status && (
      <InfoIcon
        text={status.text}
        statusInfo={status}
        popupPosition={popupPosition}
        className={className}
        typographyComponent={typographyComponent}
      />
    )
  )
}

StatusInfoIcon.propTypes = {
  data: PropTypes.object,
  getStatusInfo: PropTypes.func.isRequired,
  popupPosition: PropTypes.string,
  className: PropTypes.string,
  typographyComponent: PropTypes.string
}

export default StatusInfoIcon
