import { createAppApi } from 'services/api'

export const tutorialVideosApi = createAppApi('tutorialVideosQuery', {
  endpoints: builder => ({
    getVideoTutorials: builder.query({
      query: (params = {}) => ({
        url: '/videoTutorials',
        params
      })
    }),
    getVideoCategories: builder.query({
      query: () => ({ url: `/videoTutorials/categories` })
    }),
    getVideoTutorialInformation: builder.query({
      query: url => ({
        url: `https://vimeo.com/api/oembed.json?url=${url}`,
        withoutAuth: true
      })
    })
  })
})

export const {
  useLazyGetVideoTutorialsQuery,
  useLazyGetVideoCategoriesQuery,
  useLazyGetVideoTutorialInformationQuery
} = tutorialVideosApi
