import React, { useEffect } from 'react'

import { FormControlReactSelect } from './index'
import { timezoneOptions } from 'constants/commonOptions'
import { simulateEvent } from 'utils'

const FormControlSelectTimezone = ({
  value,
  name,
  onChange,
  menuWidth = 220,
  options = timezoneOptions,
  defaultValue = 'America/New_York',
  ...props
}) => {
  useEffect(() => {
    if (!options.some(option => option.value === value)) {
      onChange(simulateEvent(name, defaultValue))
    }
    // eslint-disable-next-line
  }, [value])

  return (
    <FormControlReactSelect
      value={value}
      name={name}
      onChange={onChange}
      options={options}
      isSearchable
      isSort={false}
      menuWidth={menuWidth}
      {...props}
    />
  )
}

export default FormControlSelectTimezone
