import { call, put, all } from 'redux-saga/effects'
import * as types from 'actions'
import { resellerClientsService } from 'services'
import { transformMeta } from 'utils/tableUtils'

function* getItems({ params }) {
  try {
    const { data, meta } = yield call(resellerClientsService.getItems, params)

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_RESELLER_CLIENTS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_RESELLER_CLIENTS_ERROR, payload: error })
  }
}

function* getAssociatedClients({ resellerId }) {
  try {
    const { data, meta } = yield call(
      resellerClientsService.getAssociatedClients,
      resellerId
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_ASSOCIATED_CLIENTS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_ASSOCIATED_CLIENTS_ERROR, payload: error })
  }
}

function* postAssociatedClients({ resellerId, clientIds, removeClientedIds }) {
  try {
    yield all([
      ...clientIds.map(id =>
        call(resellerClientsService.postAssociatedClients, resellerId, id)
      ),
      ...removeClientedIds.map(id =>
        call(resellerClientsService.putAssociatedClients, resellerId, id)
      )
    ])

    yield put({
      type: types.POST_ASSOCIATED_CLIENTS_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_ASSOCIATED_CLIENTS_ERROR, payload: error })
  }
}

const resellerClientsSaga = {
  getItems,
  getAssociatedClients,
  postAssociatedClients
}

export default resellerClientsSaga
