import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'

import { Checkbox } from 'components/Checkboxes'
import { TableLibraryCell } from './index'
import { useUserRole } from 'hooks/tableLibrary'

const useStyles = makeStyles(({ palette, type }) => ({
  sticky: {
    position: 'sticky',
    background: palette[type].tableLibrary.head.background,
    left: 0
  }
}))

const TableLibraryCheckboxCell = ({
  padding = 'checkbox',
  onClick,
  selected,
  paddingVariant,
  disabled,
  className,
  ...props
}) => {
  const classes = useStyles()
  const role = useUserRole()

  const withHorizontalScroll = role.system
  return (
    <TableLibraryCell
      padding={padding}
      onClick={onClick}
      paddingVariant={paddingVariant}
      className={classNames('TableLibraryCheckboxCellClass', className, {
        [classes.sticky]: withHorizontalScroll
      })}
      {...props}
    >
      <Checkbox disabled={disabled} checked={selected} />
    </TableLibraryCell>
  )
}

export default TableLibraryCheckboxCell
