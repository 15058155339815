import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import DeviceCard from 'components/Card/DeviceCard'
import { useUserRole } from 'hooks/tableLibrary'
import Spacing from 'components/Containers/Spacing'

const styles = ({ type, colors, typography }) => ({
  deviceLabel: {
    ...typography.darkAccent[type],
    fontSize: '1rem',
    fontWeight: '700',
    textAlign: 'left',
    marginBottom: '3px',
    marginLeft: '3px',
    position: 'absolute',
    top: '0',
    display: 'flex',
    width: '95%',
    padding: '5px 12px',
    background: colors.background.primary[type],
    zIndex: '2'
  }
})

const isFireFox = navigator.userAgent.includes('Firefox')

const MarkerInfoContent = ({ devices, classes, paddingBottom = 1.5 }) => {
  const { t } = useTranslation()
  const role = useUserRole()

  return (
    <>
      <Typography className={classes.deviceLabel}>
        {`${devices.length} ${t('Device', { count: devices.length })}`}
      </Typography>
      <Spacing variant={0} paddingTop={4.5}>
        {devices.map((device, index) => (
          <Spacing
            variant={0}
            paddingBottom={paddingBottom}
            paddingLeft={1.5}
            paddingRight={isFireFox ? 1.5 : 0}
            key={`map-device-${index}`}
          >
            <DeviceCard
              device={device}
              role={role}
              withShowMore={false}
              variant="auto"
              infoIconsPosition="top"
              disableHoverEffect
              withFooter={false}
            />
          </Spacing>
        ))}
      </Spacing>
    </>
  )
}

export default withStyles(styles)(MarkerInfoContent)
