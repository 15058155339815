import { isFileData } from 'utils'

const readFile = (file, isImage) => {
  if (!isFileData(file)) return Promise.reject(file)

  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      resolve(e.target.result)
    }

    reader.onerror = reject

    if (isImage) {
      reader.readAsDataURL(file)
      return
    }
    reader.readAsText(file)
  })
}

export default readFile
