import * as types from './index'

export const tableauLogin = data => {
  return {
    type: types.TABLEAU_LOGIN,
    payload: data
  }
}

export const tableauLoginSuccess = data => {
  return {
    type: types.TABLEAU_LOGIN_SUCCESS,
    payload: data
  }
}

export const tableauLoginFailure = error => {
  return {
    type: types.TABLEAU_LOGIN_FAILURE,
    error
  }
}

export const tableauLogout = ({
  tokenId,
  onLogoutSuccess,
  onLogoutError,
  data
}) => {
  return {
    type: types.TABLEAU_LOGOUT,
    tokenId,
    data,
    onLogoutSuccess,
    onLogoutError
  }
}

export const tableauLogoutSuccess = () => {
  return {
    type: types.TABLEAU_LOGOUT_SUCCESS
  }
}

export const tableauLogoutFailure = error => {
  return {
    type: types.TABLEAU_LOGOUT_FAILURE,
    error
  }
}

export const getTableauViews = params => ({
  type: types.GET_TABLEAU_VIEWS,
  params
})

export const getTableauWorkbooks = params => ({
  type: types.GET_TABLEAU_WORKBOOK,
  params
})

export const clearTableauResponseInfo = () => ({
  type: types.CLEAR_TABLEAU_RESPONSE_INFO
})
