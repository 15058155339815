import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import cl from 'classnames'

import { COLORS } from './constants'

export const MuiInputSliderProps = {
  color: PropTypes.oneOf([COLORS.BLUE, COLORS.GREEN])
}

const useStyles = makeStyles(theme => {
  return {
    container: {
      opacity: '1'
    },
    containerDisabled: {
      opacity: '.3'
    },
    root: {
      color: props => {
        const color = props.color === COLORS.BLUE ? 'highlight' : props.color
        return `${theme.colors?.[color] ?? props.color} !important`
      }
    },
    disabled: {
      color: props => {
        const color = props.color === COLORS.BLUE ? 'highlight' : props.color
        return theme.colors?.[color] ?? props.color
      }
    },
    thumb: {
      height: '14px !important',
      width: '14px !important',
      marginTop: '-5px !important',
      '&:focus, &:hover': {
        boxShadow: 'none'
      }
    },
    track: {
      height: '4px !important',
      borderRadius: '2px'
    },
    rail: {
      color: '#e8eaee',
      opacity: 1,
      height: 4,
      borderRadius: '2px'
    },
    mark: {
      backgroundColor: '#bfbfbf',
      height: 3,
      width: 3
    }
  }
})

const MuiInputSlider = ({
  classes = {
    root: '',
    thumb: '',
    track: '',
    rail: '',
    railClass: '',
    mark: '',
    valueLabel: '',
    active: ''
  },
  step = 1,
  min,
  max,
  color = COLORS.BLUE,
  disabled,
  ...sliderProps
}) => {
  const _classes = useStyles({ color })

  const containerClass = cl({
    [_classes.container]: true,
    [_classes.containerDisabled]: disabled
  })

  const rootClass = cl({
    [_classes.root]: true,
    [classes.root]: classes.root
  })

  const thumbClass = cl({
    [_classes.thumb]: true,
    [classes.thumb]: classes.thumb
  })

  const trackClass = cl({
    [_classes.track]: true,
    [classes.track]: classes.track
  })

  const railClass = cl({
    [_classes.rail]: true,
    [classes.rail]: classes.rail
  })

  const railClassClass = cl({
    [_classes.railClass]: true,
    [classes.railClass]: classes.railClass
  })

  const markClass = cl({
    [_classes.mark]: true,
    [classes.mark]: classes.mark
  })

  const valueLabelClass = cl({
    [classes.valueLabel]: classes.valueLabel
  })

  const activeClass = cl({
    [classes.active]: classes.active
  })

  return (
    <div className={containerClass}>
      <Slider
        classes={{
          root: rootClass,
          thumb: thumbClass,
          track: trackClass,
          rail: railClass,
          disabled: railClassClass,
          mark: markClass,
          valueLabel: valueLabelClass,
          active: activeClass
        }}
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        {...sliderProps}
      />
    </div>
  )
}

MuiInputSlider.MuiInputSlider = MuiInputSliderProps

export default MuiInputSlider
