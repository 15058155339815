import React from 'react'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Spacing from 'components/Containers/Spacing'
import NormalPlaceholderContent from './NormalPlaceholderContent'
import SmallPlaceholderContent from './SmallPlaceholderContent'

const styles = () => ({
  root: {
    height: '70vh'
  },
  fullHeight: {
    height: '100%'
  }
})

const EmptyPlaceholder = ({
  classes,
  text,
  rootClassName,
  requestText,
  fullHeight,
  variant = 'normal',
  onClick,
  smallAlignment,
  customIcon
}) => {
  return (
    <Spacing
      variant={0}
      justifyContent="center"
      alignItems="center"
      rootClassName={classNames(classes.root, rootClassName, {
        [classes.fullHeight]: fullHeight
      })}
    >
      {variant === 'normal' && (
        <NormalPlaceholderContent
          text={text}
          requestText={requestText}
          customIcon={customIcon}
        />
      )}
      {variant === 'small' && (
        <SmallPlaceholderContent
          text={text}
          requestText={requestText}
          onClick={onClick}
          itemsAlignment={smallAlignment}
        />
      )}
    </Spacing>
  )
}

EmptyPlaceholder.propTypes = {
  variant: PropTypes.oneOf(['normal', 'small']),
  fullHeight: PropTypes.bool,
  onClick: PropTypes.func
}

export default withStyles(styles)(EmptyPlaceholder)
