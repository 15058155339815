export const TAG = 'Tag'
export const TAGS = 'Tags'

export const GROUP = 'Group'
export const GROUPS = 'Groups'

export const TAG_AND_GROUP = 'Tag & Group'
export const TAGS_AND_GROUP = 'Tags & Group'
export const TAG_AND_GROUPS = 'Tag & Groups'

export const TAGS_AND_GROUPS = 'Tags & Groups'
