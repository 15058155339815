import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'
import { FirmwareChip } from 'components/Chip'

const DeviceVersionCell = ({
  version,
  latestVersion,
  latestVersionTooltip,
  t
}) => {
  return (
    <>
      {version || t('N/A')}
      {version !== latestVersion && (
        <div>
          <Tooltip
            arrow
            withHeader
            headerText={t('Update Available')}
            title={latestVersionTooltip}
          >
            <FirmwareChip label={latestVersion} />
          </Tooltip>
        </div>
      )}
    </>
  )
}

DeviceVersionCell.propTypes = {
  version: PropTypes.string,
  latestVersion: PropTypes.string,
  latestVersionTooltip: PropTypes.string
}

export default withTranslation('translations')(DeviceVersionCell)
