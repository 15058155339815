import React, { useCallback, useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { _get } from 'utils/lodash'

import ProtectedDeviceNOC from 'components/Pages/Admin/DeviceNOC'
import { routeByName } from 'constants/index'
import { getPublicRoute } from 'constants/routes'
import { getPublicUrl } from 'utils/permissionUrls'
import {
  getPublicNocAccessToken,
  getPublicNocGeneralItems,
  getPublicNocHealthItems,
  getPublicNocNetworkItems
} from 'actions/public/deviceNoc'
import { featureNames } from 'constants/featureConstants'
import './deviceNoc.scss'
import { deviceNocView } from 'constants/deviceNoc'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import socket, { publicPageSocket } from 'services/socket'

const styles = ({ breakpoints, palette, type }) => ({
  pageHeader: {
    lineHeight: '65px',
    paddingLeft: '27px'
  },
  pageHeaderTitle: {
    fontSize: 22,
    lineHeight: '30px'
  },
  subHeaderRoot: {
    padding: '5px 0px'
  },
  subHeaderRightAction: {
    position: 'fixed',
    right: '0px',
    top: '15px',
    zIndex: '1402',
    maxWidth: '60%',
    flexBasis: '60%'
  },
  circleIconButton: {
    padding: 12,
    fontSize: 24,
    '& svg': {
      width: '1em'
    }
  },
  overviewTableRoot: {
    minWidth: 'auto',

    '& th, & td': {
      padding: '0px 20px'
    }
  },
  overviewTablePaperWrapper: {
    '&::-webkit-scrollbar': {
      height: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: palette[type].scrollbar.background,
      borderRadius: '3px'
    }
  },
  overviewTableFooterWrap: {
    paddingLeft: 21
  },
  overviewPaginationRoot: {
    height: 50,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    gridRowGap: '0px',
    padding: '0px'
  },
  overviewPagTotalItemsWrap: {
    borderRight: `1px solid ${palette[type].tableLibrary.footer.pagination.border}`,
    marginRight: '18px',
    paddingRight: '18px'
  },
  overviewPagTotalItemsLabel: {
    fontSize: '1.125rem'
  },
  overviewPaginationWrap: {
    borderRight: `1px solid ${palette[type].tableLibrary.footer.pagination.border}`,
    marginRight: '18px',
    paddingRight: '5px',

    '& .TableLibraryPagination li:not(.previous):not(.next) a': {
      fontSize: '0.8125rem',
      lineHeight: '1.375rem',
      minWidth: '2rem !important',
      height: '2rem'
    }
  },
  [breakpoints.down('xs')]: {
    overviewTableFooterWrap: {
      justifyContent: 'center'
    },
    overviewPaginationRoot: {
      flexDirection: 'column'
    },
    overviewPagTotalItemsWrap: {
      borderRight: `none`,
      marginRight: '0px',
      paddingRight: '0px'
    },
    overviewPaginationWrap: {
      borderRight: `none`,
      marginRight: '0px',
      paddingRight: '0px',

      '& .TableLibraryPagination li:not(.previous):not(.next) a': {
        minWidth: '1.7rem !important',
        height: '1.7rem'
      }
    }
  },
  [breakpoints.down('sm')]: {
    pageHeader: {
      lineHeight: '45px',
      paddingLeft: '14px'
    },
    pageHeaderTitle: {
      fontSize: 15,
      lineHeight: '15px'
    },
    subHeaderRightAction: {
      top: '9px'
    },
    circleIconButton: {
      padding: 7,
      fontSize: 21,
      '& svg': {
        width: 18
      }
    },
    overviewPagTotalItemsLabel: {
      fontSize: '1rem'
    },
    overviewTableFooterWrap: {
      padding: '0px 5px'
    },
    overviewPaginationRoot: {
      height: 'auto',
      justifyContent: 'center',
      gridRowGap: '5px',
      padding: '10px 0px'
    },
    overviewPaginationWrap: {
      borderRight: `none`,
      marginRight: '0px',
      paddingRight: '0px'
    }
  },
  [breakpoints.only('md')]: {
    pageHeader: {
      lineHeight: '51px',
      paddingLeft: '18px'
    },
    pageHeaderTitle: {
      fontSize: 17,
      lineHeight: '20px'
    },
    subHeaderRightAction: {
      top: '13px'
    },
    circleIconButton: {
      padding: 9,
      fontSize: 22,
      '& svg': {
        width: 20
      }
    },
    overviewTableFooterWrap: {
      padding: '0px 5px'
    },
    overviewPaginationRoot: {
      height: 'auto',
      justifyContent: 'center',
      gridRowGap: '5px',
      padding: '10px 0px'
    },
    overviewPaginationWrap: {
      borderRight: `none`,
      marginRight: '0px',
      paddingRight: '0px'
    }
  },
  [breakpoints.only('lg')]: {
    pageHeader: {
      lineHeight: '58px',
      paddingLeft: '22px'
    },
    pageHeaderTitle: {
      fontSize: 20,
      lineHeight: '25px'
    },
    subHeaderRightAction: {
      top: '13px'
    },
    circleIconButton: {
      padding: 10,
      fontSize: 23,
      '& svg': {
        width: 22
      }
    }
  },
  [breakpoints.down('lg')]: {
    overviewTableRoot: {
      '& th, & td': {
        padding: '0px 10px'
      },
      '& tr td:first-child, & tr th:first-child': {
        paddingLeft: 15
      }
    }
  }
})

const DeviceNOC = ({ classes }) => {
  const { token } = useParams()
  const [isError, setError] = useState(false)
  const dispatch = useDispatch()
  const deviceNoc = useSelector(({ public: { deviceNoc } }) => deviceNoc)
  const { isFetching, response, error } = deviceNoc.login
  const authTimer = useRef()

  useEffect(() => {
    if (token) {
      dispatch(getPublicNocAccessToken(token))
    }

    return () => {
      publicPageSocket()
      socket.disconnect()
      if (authTimer.current) {
        clearTimeout(authTimer.current)
      }
    }
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (!isFetching && response.accessToken) {
      publicPageSocket(response.accessToken)
      socket.connect()

      if (authTimer.current) {
        clearTimeout(authTimer.current)
      }
      authTimer.current = setTimeout(() => {
        if (token) {
          socket.disconnect()
          dispatch(getPublicNocAccessToken(token))
        }
      }, (response.expiresIn - 300) * 1000)
    }
    // eslint-disable-next-line
  }, [response])

  const fetcher = useCallback(
    view => params => {
      switch (view) {
        case deviceNocView.OVERVIEW:
        case deviceNocView.PREVIEW:
          return dispatch(
            getPublicNocGeneralItems({
              ...params,
              token,
              feature_name: featureNames.DeviceNoc
            })
          )
        case deviceNocView.HEALTH:
          return dispatch(
            getPublicNocHealthItems({
              ...params,
              token,
              feature_name: featureNames.DeviceNoc
            })
          )
        case deviceNocView.DIAGRAM:
          return dispatch(
            getPublicNocNetworkItems({
              ...params,
              token,
              feature_name: featureNames.DeviceNoc
            })
          )
        case deviceNocView.UPTIME:
          return dispatch(
            getPublicNocNetworkItems({
              ...params,
              token,
              feature_name: featureNames.DeviceNoc
            })
          )

        default:
          return
      }
    },
    [dispatch, token]
  )

  const getItemReducer = useCallback(
    view => {
      switch (view) {
        case deviceNocView.OVERVIEW:
        case deviceNocView.PREVIEW:
        case deviceNocView.UPTIME:
          return deviceNoc.general
        case deviceNocView.HEALTH:
          return deviceNoc.health
        case deviceNocView.DIAGRAM:
          return deviceNoc.network

        default:
          return deviceNoc.general
      }
    },
    [deviceNoc]
  )

  useEffect(() => {
    if (
      _get(deviceNoc, 'general.error.code') === 403 ||
      _get(deviceNoc, 'health.error.code') === 403 ||
      _get(deviceNoc, 'network.error.code') === 403 ||
      error.code === 403
    ) {
      setError(true)
    }
  }, [deviceNoc, error])

  return isError ? (
    <EmptyPlaceholder text={'Link expired'} />
  ) : (
    <ProtectedDeviceNOC
      isPublic={true}
      parentClasses={{
        ...classes,
        overviewPagination: {
          root: classes.overviewPaginationRoot,
          totalItemsWrap: classes.overviewPagTotalItemsWrap,
          totalItemsLabel: classes.overviewPagTotalItemsLabel,
          paginationWrap: classes.overviewPaginationWrap
        }
      }}
      routes={{
        root: getPublicRoute(routeByName.public.deviceNoc.root),
        overview: getPublicRoute(routeByName.public.deviceNoc.overview),
        preview: getPublicRoute(routeByName.public.deviceNoc.preview),
        health: getPublicRoute(routeByName.public.deviceNoc.health),
        diagram: getPublicRoute(routeByName.public.deviceNoc.diagram),
        uptime: getPublicRoute(routeByName.public.deviceNoc.uptime)
      }}
      redirectLinks={{
        overview: getPublicUrl(routeByName.public.deviceNoc.overview, token),
        preview: getPublicUrl(routeByName.public.deviceNoc.preview, token),
        health: getPublicUrl(routeByName.public.deviceNoc.health, token),
        diagram: getPublicUrl(routeByName.public.deviceNoc.diagram, token),
        uptime: getPublicUrl(routeByName.public.deviceNoc.uptime, token)
      }}
      fetcher={fetcher}
      getItemReducer={getItemReducer}
    />
  )
}

export default withStyles(styles)(DeviceNOC)
