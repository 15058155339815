export const overlayStyles = {
  opacity: 1,
  zIndex: 1,
  transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  background: 'rgba(90, 90, 90, 0.2)',
  position: 'fixed',
  animation: 'fade-in 400ms',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}
