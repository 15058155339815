import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addLoadedFont } from 'actions/fontsActions'
import { fontsSelector, loadedFontsSelector } from 'selectors/fontsSelectors'
import { combineFontName, getFontDescriptors } from 'utils/fontUtils'

const isFireFox = navigator.userAgent.includes('Firefox')
const defaultVariantsToLoad = ['regular', '400']

export default function useCustomFontsLoader(
  targetVariants = defaultVariantsToLoad,
  callback
) {
  const dispatch = useDispatch()
  const { response } = useSelector(fontsSelector)
  const loadedFonts = useSelector(loadedFontsSelector)

  const isLoaded = useCallback(
    combinedFontName => {
      return loadedFonts.includes(combinedFontName)
    },
    [loadedFonts]
  )

  useEffect(
    () => {
      if (response) {
        response.data.forEach(({ family, variants }) => {
          const allowedVariants = variants.filter(({ variant }) =>
            targetVariants.includes(variant)
          )

          const variantsToLoad =
            allowedVariants?.length && !targetVariants.includes('*')
              ? allowedVariants
              : variants

          variantsToLoad.forEach(({ file, variant }) => {
            if (!isLoaded(combineFontName(family, variant))) {
              new FontFace(
                isFireFox ? `"${family}"` : family,
                `url(${file})`,
                getFontDescriptors(variant)
              )
                .load()
                .then(fontFace => {
                  document.fonts.add(fontFace)
                  if (callback) {
                    callback()
                  }
                })
                .catch(e => {
                  if (callback) {
                    callback({ error: e })
                  }
                })
                .finally(() => {
                  dispatch(addLoadedFont(combineFontName(family, variant)))
                })
            }
          })
        })
      }
    },
    // eslint-disable-next-line
    [response]
  )
}
