import { createAppApi } from 'services/api'
import sortConstants from 'constants/sortConstants'
import apiTags from 'constants/apiTags'
import { createSuccessInvalidator } from 'utils/apiUtils'

export const tagsApi = createAppApi('tagsQuery', {
  tagTypes: [apiTags.tag],
  endpoints: builder => ({
    getTags: builder.query({
      providesTags: [apiTags.tag],
      query: (params = {}) => ({
        url: '/tag',
        params: {
          ...sortConstants.Tag,
          ...params
        }
      })
    }),
    getTag: builder.query({
      query: id => ({
        url: `/tag/${id}`
      })
    }),
    postTag: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.tag]),
      query: data => ({
        method: 'POST',
        url: '/tag',
        data
      })
    }),
    putTag: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.tag]),
      query: ({ id, ...data }) => ({
        method: 'PUT',
        url: `/tag/${id}`,
        data
      })
    }),
    deleteTag: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.tag]),
      query: ({ tag: { id }, data }) => ({
        method: 'DELETE',
        url: `/tag/${id}`,
        data
      })
    }),
    deleteTags: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.tag]),
      query: ({ ids, data }) => ({
        method: 'DELETE',
        url: `/tag/bulk`,
        params: {
          ids: ids.join(',')
        },
        data
      })
    })
  })
})

export const {
  useLazyGetTagsQuery,
  useLazyGetTagQuery,
  useGetTagsQuery,
  usePostTagMutation,
  usePutTagMutation,
  useDeleteTagMutation,
  useDeleteTagsMutation
} = tagsApi
