import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { _get } from 'utils/lodash'
import { zIndexes } from 'constants/stylesConstants'
import {
  usePopupState,
  bindHover,
  bindPopper
} from 'hooks/mui/muiPopupState/muiPopupState'

const useStyles = makeStyles(({ palette, type }) => ({
  materialPopupV2: {
    width: 'fit-content'
  },
  root: {
    zIndex: zIndexes.materialPopup
  },
  content: {
    backgroundColor: palette[type].dropdown.background,
    boxShadow: palette[type].dropdown.shadow,
    borderRadius: 5,
    zIndex: zIndexes.materialPopup
  },
  topOffset: {
    paddingTop: 10,
    '& > #arrow': {
      top: 5,
      transform: 'rotate(-45deg)'
    }
  },
  bottomOffset: {
    paddingBottom: 10,
    '& > #arrow': {
      bottom: 5,
      right: 10,
      transform: 'rotate(135deg)'
    }
  },
  leftOffset: {
    paddingLeft: 10,
    '& > #arrow': {
      left: 5,
      transform: 'rotate(225deg)'
    }
  },
  rightOffset: {
    paddingRight: 10,
    '& > #arrow': {
      right: 5,
      transform: 'rotate(45deg)'
    }
  },
  startOffset: {
    '& > #arrow': {
      left: '10px !important'
    }
  },
  endOffset: {
    '& > #arrow': {
      left: 'unset !important',
      right: 10
    }
  },
  arrow: {
    position: 'absolute',
    borderColor: `transparent ${palette[type].dropdown.background} transparent transparent`,
    borderWidth: '0px 10px 10px 0px',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    boxShadow: `${palette[type].dropdown.shadowColor} 1px -1px 3px -2px`
  }
}))

const MaterialPopupV2 = ({
  children,
  trigger,
  placement = 'bottom',
  on = 'hover',
  innerClasses = {},
  disabled,
  hasArrow = true,
  offset = 10,
  rootClassName,
  preventOverflow,
  modifiers,
  onOpen = f => f,
  onClose = f => f,
  style = {},
  overlayStyles = {},
  closeOnClick,
  ...props
}) => {
  const _classes = useStyles()
  const [arrowRef, setArrowRef] = useState(null)

  const handleArrowRef = useCallback(node => {
    setArrowRef(node)
  }, [])

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'tableRowActionPopper',
    handleOpen: onOpen,
    handleClose: onClose
  })

  const mode = useMemo(() => {
    return disabled
      ? {}
      : on === 'click'
      ? {
          onClick: popupState.toggle,
          ref: popupState.setAnchorEl
        }
      : bindHover(popupState)
  }, [disabled, on, popupState])

  const memoTrigger = useMemo(() => React.cloneElement(trigger, mode), [
    mode,
    trigger
  ])

  const handleClickAway = useCallback(() => {
    if (popupState.isOpen && on === 'click') {
      popupState.close()
    }
  }, [on, popupState])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classNames(_classes.materialPopupV2)}>
        {memoTrigger}
        <Popper
          style={style}
          {...bindPopper(popupState)}
          placement={placement}
          modifiers={{
            flip: {
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'viewport',
                padding: 8
              }
            },
            arrow: {
              enabled: hasArrow,
              element: arrowRef
            },
            ...(preventOverflow && preventOverflow),
            ...(modifiers && modifiers)
          }}
          className={classNames(_classes.root, rootClassName)}
          {...props}
        >
          {popperProps => (
            <div
              onClick={popupState.close}
              className={classNames(_classes.container, {
                [_classes.topOffset]:
                  popperProps.placement.split('-')[0] === 'bottom',
                [_classes.bottomOffset]:
                  popperProps.placement.split('-')[0] === 'top',
                [_classes.rightOffset]:
                  popperProps.placement.split('-')[0] === 'left',
                [_classes.leftOffset]:
                  popperProps.placement.split('-')[0] === 'right',
                [_classes.startOffset]:
                  ['bottom', 'top'].includes(
                    popperProps.placement.split('-')[0]
                  ) &&
                  _get(popperProps.placement.split('-'), '[1]') === 'start',
                [_classes.endOffset]:
                  ['bottom', 'top'].includes(
                    popperProps.placement.split('-')[0]
                  ) && _get(popperProps.placement.split('-'), '[1]') === 'end'
              })}
            >
              {hasArrow ? (
                <span
                  id="arrow"
                  className={_classes.arrow}
                  ref={handleArrowRef}
                />
              ) : null}
              <div className={_classes.content}>
                {typeof children === 'function'
                  ? children(popupState.close)
                  : children}
              </div>
            </div>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

MaterialPopupV2.propTypes = {
  on: PropTypes.oneOf(['click', 'hover']).isRequired,
  innerClasses: PropTypes.object,
  hasArrow: PropTypes.bool.isRequired
}

export default MaterialPopupV2
