function isShadeOfWhite(value) {
  if (value) {
    const checkMarkColors = value
      .substring(value.indexOf('(') + 1, value.lastIndexOf(')'))
      .split(/,\s*/)
    const [r, g, b] = checkMarkColors

    return [r, g, b].every(color => color > 230)
  }
}

export default isShadeOfWhite
