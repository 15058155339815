import React, { memo } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const styles = ({
  typography,
  type,
  fontSize,
  lineHeight,
  fontWeight,
  colors
}) => ({
  mainFooterText: {
    transform: 'translate(0, 1.5px) scale(0.9)',
    color: colors.footer[type],
    verticalAlign: 'middle',
    fontSize: fontSize.small,
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.normal,
    '&:hover': {
      textShadow: `0 0 1px ${typography.lightText[type].color}`
    }
  },
  mainFooterNavigationLink: {
    textDecoration: 'none',
    display: 'inline-block'
  },
  textContainer: {
    '& > p': {
      display: 'inline'
    }
  }
})

const LinkItem = ({
  link,
  linkText,
  text,
  fallbackLink,
  preferred,
  classes
}) => {
  return preferred === 'text' ? (
    <span
      className={classes.textContainer}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ) : (
    <a
      href={link || fallbackLink}
      className={classes.mainFooterNavigationLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography component="span" className={classes.mainFooterText}>
        {linkText}
      </Typography>
    </a>
  )
}

export default withStyles(styles)(memo(LinkItem))
