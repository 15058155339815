import React, { memo } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import InfoIcon from 'components/Icons/InfoIcon'
import { mediaStatus } from 'constants/media'
import useStatusInfo from 'hooks/statusInfo/useStatusInfo'
import { Grid, Typography, withStyles } from '@material-ui/core'
import Spacing from 'components/Containers/Spacing'
import Tooltip from 'components/Tooltip'
import { compose } from '@reduxjs/toolkit'

const defaultContentStyle = {
  maxWidth: 288
}

const styles = ({ fontSize, colors }) => {
  function _fontSize(size) {
    return { fontSize: fontSize[size] }
  }

  return {
    icon: {
      color: colors.warning
    },
    'size-normal': _fontSize('secondary'),
    'size-small': _fontSize('small'),
    'size-big': _fontSize('big')
  }
}

const iconStatusList = [
  mediaStatus.expired,
  mediaStatus.expires,
  mediaStatus.pending,
  mediaStatus.notApproved,
  mediaStatus.disabled,
  mediaStatus.failed,
  mediaStatus.processing,
  mediaStatus.rejected,
  mediaStatus.deauthorized
]

const MediaInfoIcon = ({
  media,
  popupPosition,
  className,
  contentStyle = {},
  classes
}) => {
  const { getMediaStatusInfo } = useStatusInfo()
  const statusInfo = getMediaStatusInfo(media)
  const isStatusWithIcon = iconStatusList.includes(statusInfo.status)

  if (isStatusWithIcon && statusInfo) {
    if (statusInfo.status === mediaStatus.notApproved) {
      return (
        <Grid className={className}>
          <Spacing
            variant={0}
            paddingHor={1}
            paddingVert={0}
            justifyContent="center"
          >
            <Tooltip
              title={
                <Typography component={'div'}>{statusInfo?.content}</Typography>
              }
              headerText={statusInfo?.label}
              arrowWithHeaderColor={statusInfo?.label}
              placement="bottom"
              withHeader={statusInfo?.label}
              arrow
            >
              <i
                className={`fa-sharp fa-solid fa-shield-exclamation ${classes.icon} ${classes['size-normal']}`}
              />
            </Tooltip>
          </Spacing>
        </Grid>
      )
    }

    return (
      <InfoIcon
        text={statusInfo.text}
        statusInfo={statusInfo}
        popupPosition={popupPosition}
        className={className}
        contentStyle={{ ...defaultContentStyle, ...contentStyle }}
        typographyComponent={'div'}
      />
    )
  }

  return null
}

MediaInfoIcon.propTypes = {
  contentStyle: PropTypes.object
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(memo(MediaInfoIcon))
