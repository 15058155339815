import React, { memo, useMemo } from 'react'
import propTypes from 'prop-types'
import { truncateStringUtils, TextSizeTooltip } from 'utils'

const UserNameView = ({
  firstName = '',
  lastName = '',
  maxLength = 15,
  width = 150,
  maxTitleWidth = 22
}) => {
  const name = useMemo(
    () =>
      `${truncateStringUtils.truncateWithEllipsis(
        firstName,
        maxLength
      )} ${truncateStringUtils.truncateWithEllipsis(lastName, maxLength)}`,
    [firstName, lastName, maxLength]
  )

  return (
    <TextSizeTooltip title={name} maxTitleWidth={maxTitleWidth} width={width} />
  )
}

UserNameView.propTypes = {
  firstName: propTypes.string,
  lastName: propTypes.string,
  maxLength: propTypes.number
}

export default memo(UserNameView)
