import React from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(({ colors }) => ({
  iconStack: {
    position: 'relative',
    width: 24,
    height: 20
  },
  text: {
    justifyContent: 'center',
    display: 'flex',
    color: colors.light,
    fontWeight: 'bold',
    fontSize: '12px',
    position: 'relative',
    top: '-1px'
  },
  iconStack3x: {
    position: 'absolute',
    left: 0,
    width: '100%',
    color: colors.light,
    fontSize: '19px'
  }
}))

const Icon4K = ({
  wrapperClassName = '',
  mainClassName = '',
  secondClassName = '',
  wrapperStyles,
  generalStyles
}) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(classes.iconStack, wrapperClassName)}
      style={wrapperStyles}
    >
      <i
        className={classNames(
          classes.iconStack3x,
          'fa-regular fa-tv',
          mainClassName
        )}
        style={generalStyles}
      />
      <span
        className={classNames(classes.text, secondClassName)}
        style={generalStyles}
      >
        4K
      </span>
    </div>
  )
}

export default Icon4K
