import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'

import Text from 'components/Typography/Text'
import Error from 'components/Form/Error'
import Spacing from 'components/Containers/Spacing'
import {
  getRoot,
  getRadius,
  getHeader,
  getContent,
  getTitle
} from 'utils/object'

function styles() {
  function _radius(variant) {
    return {
      borderRadius: variant,
      '& $header': {
        borderRadius: variant
      },
      '& $headerBorder': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      },
      '& $content': {
        borderBottomLeftRadius: variant,
        borderBottomRightRadius: variant
      }
    }
  }

  return {
    header: { overflowX: 'hidden' },
    headerBorder: {},
    content: {},
    'radius-0': _radius(0),
    'radius-1': _radius(1),
    'radius-2': _radius(2),
    'radius-4': _radius(4)
  }
}

function GreyCard({
  classes,
  title,
  titleComponent,
  subTitle,
  header = true,
  error,
  rootClassName,
  headerClassName,
  headerComponent,
  marginBottom,
  headerComponentContainerProps = {},
  variant = 1,
  titleLight = false,
  rootSpacingProps = {},
  contentSpacingProps = {},
  headerSpacingProps = {},
  contentClassName,
  children
}) {
  const variantProps = getVariantProps(variant, !!children, titleLight)
  return (
    <Spacing
      {...getRoot(variantProps)}
      direction="row"
      variant={marginBottom ? 2 : 0}
      rootClassName={classNames(
        classes[`radius-${getRadius(getRoot(variantProps))}`],
        rootClassName
      )}
      {...rootSpacingProps}
    >
      {header && (
        <Spacing
          {...getHeader(variantProps)}
          variant={0}
          direction="row"
          component="header"
          justifyContent="space-between"
          alignItems="center"
          rootClassName={classNames(classes.header, headerClassName, {
            [classes.headerBorder]: !!children
          })}
          {...headerSpacingProps}
        >
          <Grid item>
            {titleComponent ? (
              React.cloneElement(titleComponent, { ...getTitle(variantProps) })
            ) : (
              <Text {...getTitle(variantProps)}>{title}</Text>
            )}

            {subTitle && <Text>{subTitle}</Text>}
            {error && <Error error={error} condition={!!error} />}
          </Grid>
          {headerComponent && (
            <Grid
              container={!title && !titleComponent}
              item
              {...headerComponentContainerProps}
            >
              {headerComponent}
            </Grid>
          )}
        </Spacing>
      )}
      {children && (
        <Spacing
          {...getContent(variantProps)}
          relative
          variant={0}
          direction="row"
          rootClassName={classNames(classes.content, contentClassName)}
          {...contentSpacingProps}
        >
          {children}
        </Spacing>
      )}
    </Spacing>
  )
}

GreyCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  rootClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  headerComponent: PropTypes.node,
  marginBottom: PropTypes.bool,
  header: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  headerComponentContainerProps: PropTypes.object,
  variant: PropTypes.oneOf([1, 2, 3, 4]),
  rootSpacingProps: PropTypes.object,
  headerSpacingProps: PropTypes.object,
  contentSpacingProps: PropTypes.object
}

function getVariantProps(variant, isChildren, titleLight) {
  switch (variant) {
    case 1:
      return {
        root: {
          borderTop: 1,
          borderBottom: 1,
          borderRight: 1,
          borderLeft: 1,
          background: 'secondary',
          radius: 4
        },
        title: {
          weight: 'bold'
        },
        header: {
          paddingHor: 2,
          paddingVert: 1,
          background: 'third',
          borderBottom: isChildren ? 1 : 0
        },
        content: {
          paddingHor: 2,
          paddingVert: 2
        }
      }
    case 2:
      return {
        root: {
          borderTop: 1,
          background: 'primary',
          radius: 0
        },
        header: {
          paddingHor: 4,
          paddingVert: 1.5,
          background: 'third',
          borderBottom: isChildren ? 1 : 0
        },
        title: {
          weight: 'bold',
          ...(titleLight ? {} : { color: 'title.primary' })
        },
        content: {
          paddingHor: 0,
          paddingVert: 0
        }
      }
    case 3:
      return {
        root: {
          background: 'primary',
          radius: 0
        },
        header: {
          background: 'primary',
          paddingHor: 4,
          paddingTop: 4,
          paddingBottom: 4
        },
        title: {
          variant: 'big',
          ...(titleLight ? {} : { color: 'title.primary' })
        },
        content: {
          paddingHor: 4,
          paddingTop: 2,
          paddingBottom: 4
        }
      }
    case 4:
      return {
        root: {
          borderTop: 1,
          borderBottom: 1,
          borderRight: 1,
          borderLeft: 1,
          background: 'secondary',
          radius: 4
        },
        title: {
          weight: 'bold'
        },
        header: {
          paddingHor: 2.5,
          paddingVert: 2,
          background: 'third',
          borderBottom: isChildren ? 1 : 0
        },
        content: {
          paddingHor: 2,
          paddingVert: 2
        }
      }
    default:
      return {
        root: {},
        header: {},
        title: {},
        content: {}
      }
  }
}

export default withStyles(styles)(GreyCard)
