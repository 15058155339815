import { call, put } from 'redux-saga/effects'

import { dashboardService, scheduleService } from '../services'

import * as types from '../actions'
import { transformMeta } from '../utils/tableUtils'

function* getInfo(action) {
  try {
    const response = yield call(dashboardService.getInfo, action.params)
    yield put({ type: types.GET_DASHBOARD_INFO_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_DASHBOARD_INFO_ERROR, payload: error })
  }
}

function* putInfo(action) {
  try {
    const response = yield call(dashboardService.putInfo, action.payload)
    yield put({ type: types.PUT_DASHBOARD_INFO_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.PUT_DASHBOARD_INFO_ERROR, payload: error })
  }
}

function* getNewsInfo() {
  try {
    const response = yield call(dashboardService.getNewsInfo)
    yield put({
      type: types.GET_NEWS_INFO_FOR_DASHBOARD_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_NEWS_INFO_FOR_DASHBOARD_ERROR, payload: error })
  }
}

function* getSupportInfo() {
  try {
    const response = yield call(dashboardService.getSupportInfo)
    yield put({
      type: types.GET_SUPPORT_INFO_FOR_DASHBOARD_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_SUPPORT_INFO_FOR_DASHBOARD_ERROR,
      payload: error
    })
  }
}
function* getDashboardDeviceLocations() {
  try {
    const response = yield call(dashboardService.getDashboardDeviceLocations)
    yield put({
      type: types.GET_DASHBOARD_DEVICE_LOCATIONS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DASHBOARD_DEVICE_LOCATIONS_ERROR,
      payload: error
    })
  }
}

function* getWeatherInfo({ payload }) {
  try {
    const response = yield call(dashboardService.getWeatherInfo, payload)
    yield put({
      type: types.GET_WEATHER_INFO_FOR_DASHBOARD_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_WEATHER_INFO_FOR_DASHBOARD_ERROR,
      payload: error
    })
  }
}

function* getTodaySchedules({ payload }) {
  try {
    const response = yield call(scheduleService.getItems, payload)
    yield put({
      type: types.GET_DASHBOARD_TODAY_SCHEDULES_SUCCESS,
      payload: {
        response: response.data,
        meta: transformMeta(response.meta)
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_DASHBOARD_TODAY_SCHEDULES_ERROR,
      payload: error
    })
  }
}

const dashboardSaga = {
  getInfo,
  putInfo,
  getNewsInfo,
  getSupportInfo,
  getDashboardDeviceLocations,
  getWeatherInfo,
  getTodaySchedules
}

export default dashboardSaga
