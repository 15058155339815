import socket, { SOCKET_HEALTH } from 'services/socket'
import { useEffect } from 'react'

export default function useDeviceHealthSocket(listener) {
  useEffect(() => {
    socket.on(SOCKET_HEALTH, listener)

    return () => {
      socket.off(SOCKET_HEALTH, listener)
    }
  }, [listener])
}
