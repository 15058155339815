import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const PlaceholderRow = memo(({ leftColY, rowY, dividerY, lineHeight }) => (
  <>
    <rect x="20" y={leftColY} rx="5" ry="5" width="40" height={lineHeight} />
    <rect
      x="80"
      y={rowY}
      rx="5"
      ry="5"
      width="200"
      height={lineHeight / 2 - 3}
    />
    <rect
      x="80"
      y={rowY + lineHeight / 2 + 6}
      rx="5"
      ry="5"
      width="200"
      height={lineHeight / 2 - 3}
    />
    <rect
      x="calc(100% - 200px)"
      y={rowY}
      rx="5"
      ry="5"
      width="180"
      height={lineHeight / 2 - 3}
    />
    <rect
      x="calc(100% - 200px)"
      y={rowY + lineHeight / 2 + 6}
      rx="5"
      ry="5"
      width="180"
      height={lineHeight / 2 - 3}
    />

    <rect
      x="20"
      y={dividerY}
      rx="5"
      ry="5"
      width="calc(100% - 40px)"
      height="5"
    />
  </>
))

const LibraryLoader = ({
  theme,
  rowCount = 10,
  rowSpacing = 70,
  lineHeight = 40
}) => (
  <ContentLoader
    style={{
      width: '100%',
      height: rowCount * rowSpacing
    }}
    backgroundColor={theme.palette[theme.type].loader.foreground}
    foregroundColor={theme.palette[theme.type].loader.background}
  >
    {new Array(rowCount).fill(0).map((a, i) => (
      <PlaceholderRow
        key={i}
        leftColY={i * rowSpacing + (rowSpacing - lineHeight) / 2}
        rowY={i * rowSpacing + (rowSpacing - lineHeight) / 2}
        dividerY={(i + 1) * rowSpacing}
        lineHeight={lineHeight}
      />
    ))}
  </ContentLoader>
)

LibraryLoader.propTypes = {
  rowCount: PropTypes.number,
  rowSpacing: PropTypes.number,
  lineHeight: PropTypes.number
}

export default withTheme(LibraryLoader)
