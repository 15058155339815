import React from 'react'
import { useHistory } from 'react-router-dom'
import useUrlSearchParams from 'hooks/useUrlSearchParams'

const ItsaCheckmateRedirect = () => {
  const history = useHistory()
  const { code } = useUrlSearchParams()
  history.replace('/org/menu-maker-library/integrations/itsacheckmate', {
    auth_code: code
  })

  return <div>Processing...</div>
}

export default ItsaCheckmateRedirect
