import * as R from 'ramda'
import _get from 'lodash/get'

import {
  paginationViews,
  paginationValuesByView,
  systemPaginationValuesByView,
  PLACEHOLDER_LOCATION_NAME_COLOR,
  locationPlaceholders
} from 'constants/libraryConstants'
import {
  approveStatuses,
  dependencyType,
  libraryViews
} from 'constants/library'
import {
  templateConstants,
  scheduleConstants,
  playlistConstants
} from 'constants/index'
import { getUrlPrefix } from './permissionUrls'
import { librarySearchType } from 'constants/librarySearch'
import { SYSTEM_ROLE } from 'constants/api'
import { featuresToExclude } from 'constants/media'

export const sortByTitle = R.compose(
  R.sortBy(R.compose(R.toLower, R.defaultTo(''), R.prop('title'))),
  R.defaultTo([])
)
export const sortByAlias = R.compose(
  R.sortBy(R.compose(R.toLower, R.defaultTo(''), R.prop('alias'))),
  R.defaultTo([])
)
export const sortByTitleDesc = R.compose(
  R.sortWith([
    R.descend(R.compose(R.toLower, R.defaultTo(''), R.prop('title')))
  ]),
  R.defaultTo([])
)
export const sortByLabel = R.compose(
  R.sortBy(R.compose(R.toLower, R.defaultTo(''), R.prop('label'))),
  R.defaultTo([])
)
export const sortByName = R.compose(
  R.sortBy(R.compose(R.toLower, R.defaultTo(''), R.prop('name'))),
  R.defaultTo([])
)
export const sortByFirstName = R.compose(
  R.sortBy(R.compose(R.toLower, R.defaultTo(''), R.prop('firstName'))),
  R.defaultTo([])
)
export const sortBySortOrder = R.compose(
  R.sortBy(R.prop('sortOrder')),
  R.defaultTo([])
)
export const sortByFullName = R.compose(
  R.sortBy(
    R.compose(R.toLower, R.join(' '), R.props(['firstName', 'lastName']))
  ),
  R.defaultTo([])
)

export const isAllowedGridViewPerPage = value => parseInt(value, 10) % 6 === 0

export const isAllowedListViewPerPage = value => parseInt(value, 10) % 5 === 0

export const isAllowedLocationsViewPerPage = value =>
  parseInt(value, 10) % 4 === 0

export const getAllowedPerPage = (value, targetValues, fromValueArrays) => {
  try {
    if (!value) {
      return targetValues[1]
    }

    let index = 1

    fromValueArrays.some(array => {
      const itemIndex = array.indexOf(parseInt(value, 10))
      if (itemIndex !== -1) {
        index = itemIndex
        return true
      } else {
        return false
      }
    })

    return _get(targetValues, `[${index}]`, targetValues[1])
  } catch (e) {
    return 10
  }
}

export const getPaginationValues = (view, role) => {
  if (!paginationViews.hasOwnProperty(view)) {
    return paginationValuesByView[paginationViews.listView]
  }

  switch (role) {
    case SYSTEM_ROLE:
      return systemPaginationValuesByView[view]
    default:
      return paginationValuesByView[view]
  }
}

export const getAllowedGridViewPerPage = (currentValue, role) =>
  getAllowedPerPage(
    currentValue,
    getPaginationValues(paginationViews.gridView, role),
    [
      getPaginationValues(paginationViews.listView, role),
      getPaginationValues(paginationViews.locationView, role)
    ]
  )

export const getAllowedListViewPerPage = (currentValue, role) =>
  getAllowedPerPage(
    currentValue,
    getPaginationValues(paginationViews.listView, role),
    [
      getPaginationValues(paginationViews.gridView, role),
      getPaginationValues(paginationViews.locationView, role)
    ]
  )

export const getAllowedLocationsViewPerPage = (currentValue, role) =>
  getAllowedPerPage(
    currentValue,
    getPaginationValues(paginationViews.locationView, role),
    [
      getPaginationValues(paginationViews.gridView, role),
      getPaginationValues(paginationViews.listView, role)
    ]
  )

export function getIconInfo(row, type) {
  switch (type) {
    case librarySearchType.media:
    case dependencyType.media:
      return {
        color: _get(row, 'feature.color'),
        iconHelperClass: _get(row, 'feature.icon'),
        title: _get(row, 'feature.alias', 'Media')
      }
    case librarySearchType.playlist:
    case dependencyType.playlist:
      return playlistConstants.playlistTypes[
        _get(row, 'playlistType', 'Standard')
      ]
    case librarySearchType.template:
    case dependencyType.template:
      return templateConstants.templateTypes[
        _get(row, 'templateType', 'Standard')
      ]
    case librarySearchType.schedule:
    case dependencyType.schedule:
      return scheduleConstants.scheduleTypes[_get(row, 'scheduleType', 'Timed')]
    case librarySearchType.report:
      return {
        color: 'rgb(144, 164, 174)',
        iconHelperClass: 'fa-solid fa-note-sticky',
        title: 'Report'
      }
    default:
      return {
        title: 'Unknown'
      }
  }
}

export const getLibraryRoute = (allowed, rootRoute, view, fallback) => {
  if (allowed) {
    if (Object.values(libraryViews).includes(view)) {
      return getUrlPrefix(`${rootRoute}/${view}`)
    } else {
      return getUrlPrefix(`${rootRoute}/${libraryViews.list}`)
    }
  } else if (fallback) {
    return fallback
  }

  return '#'
}

export const getBulkSelectionPermissions = (selectedIds, items) => {
  if (!selectedIds?.length) {
    return {
      delete: false,
      write: false
    }
  } else {
    let permissions = {
      delete: true,
      write: true
    }

    selectedIds.some(mediaId => {
      const media = items.find(media => media.id === mediaId)

      if (permissions.delete || permissions.write) {
        permissions = {
          delete: permissions.delete && _get(media, 'permission.delete', false),
          write: permissions.write && _get(media, 'permission.write', false)
        }
      }

      return !permissions.delete && !permissions.write
    })

    return permissions
  }
}

export const getBulkExcludedIds = (selectedIds, items) => {
  if (!selectedIds?.length) {
    return {
      isExcluded: false
    }
  } else {
    let permissions = {
      isExcluded: true
    }

    selectedIds.some(mediaId => {
      const media = items.find(item => item.id === mediaId)
      if (permissions.isExcluded) {
        if (
          _get(media, 'approvedStatus') !== approveStatuses.approve ||
          featuresToExclude.includes(_get(media, 'feature.name'))
        ) {
          permissions = {
            isExcluded: false
          }
        }
      }
      return !permissions.isExcluded
    })

    return permissions
  }
}

export const isFeatureExcluded = ({ media }) =>
  !getBulkExcludedIds([media.id], [media])?.isExcluded

export const getSelectedItems = (selectedIds = [], items = []) =>
  items.filter(({ id }) => selectedIds.includes(id))

export const getItemsWithNoDeletePermission = items =>
  items.filter(({ permission }) => !permission.delete)

export const getPlaceholderRowsCount = (limit, rowHeight, minFallback = 5) =>
  Math.min(
    limit || 10,
    Math.max(Math.floor(window.innerHeight / rowHeight), minFallback)
  )

export const isApproved = item => {
  return item.hasOwnProperty('approvedStatus')
    ? item.approvedStatus === approveStatuses.approve
    : item?.approved
}

export const isRejected = item => {
  return item.approvedStatus === approveStatuses.disapprove
}

export const getSize = item => item.size && +parseFloat(item.size).toFixed(2)

export const getLocationPlaceholder = () =>
  locationPlaceholders.map(location => {
    return {
      ...location,
      nameColor: PLACEHOLDER_LOCATION_NAME_COLOR,
      shared: true,
      isPlaceholderValue: true
    }
  })
