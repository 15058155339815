import * as Yup from 'yup'

import {
  alarmRuleValues,
  ALARM_ALERT_COLORS,
  HORIZONTAL_SPACING,
  NODE_HEIGHT,
  PADDING_LEFT,
  VERTICAL_SPACING
} from 'constants/deviceNoc'
import { isLast } from './generalUtils'
import { emailField, requiredField } from 'constants/validationMessages'
import i18next from 'i18next'

export const getServerId = id => `server-${id}`
export const getDeviceId = id => `device-${id}`
export const getRouterId = id => `router-${id}`
export const getEdgeId = id => `edge-${id}`

export const getServerNodeObj = ({ id, label, position }) => ({
  id: getServerId(id),
  type: 'server',
  data: {
    label
  },
  position
})

export const getDeviceNodeObj = ({
  id,
  label,
  isActive,
  connectionType,
  rowData,
  position
}) => ({
  id: getDeviceId(id),
  type: 'device',
  data: {
    active: isActive,
    networkConnectionType: connectionType,
    label,
    rowData
  },
  position
})

export const getRouterNodeObj = ({
  id,
  label,
  position,
  ip,
  isActive,
  showMapPopup
}) => ({
  id: getRouterId(id),
  type: 'router',
  data: {
    label,
    isActive,
    ip,
    showMapPopup
  },
  position
})

export const getWrongNodeObj = ({ id, position }) => ({
  id: getRouterId(id),
  type: 'wrong',
  data: {
    tooltipText: i18next.t(
      'Network route data is not available at this time. Please check device network connectivity'
    )
  },
  position
})

export const getEdgeObj = ({ id, source, target, label, textColor }) => ({
  id: getEdgeId(id),
  source,
  target,
  animated: true,
  style: { stroke: '#0a83c8' },
  label,
  labelStyle: {
    fill: textColor,
    fontWeight: 900
  },
  labelShowBg: false,
  type: 'arc'
})

export const getEmptyEdgeObj = ({ id, source, target }) => ({
  id: getEdgeId(id),
  source,
  target,
  animated: false,
  labelStyle: {
    fill: 'red',
    fontWeight: 900,
    fontSize: 16
  },
  labelShowBg: false,
  type: 'custom'
})

export const convertIpToId = ip => {
  if (!ip) {
    return ip
  }

  return ip.replaceAll(':', '-')
}

export const prepareFlowData = (
  data,
  deviceConfig,
  containerWidth,
  isPublic = false
) => {
  const serverNodes = {}
  const deviceNodes = []
  const tracerouteNodes = {}
  const edges = []

  const prevDevicesHeight = deviceConfig.length * NODE_HEIGHT

  data.forEach((rowData, index) => {
    const {
      id,
      traceroute,
      status,
      alias,
      networkConnectionType,
      reachedToServer
    } = rowData
    deviceNodes.push(
      getDeviceNodeObj({
        id,
        isActive: status === 'Active',
        connectionType: status === 'Active' && networkConnectionType,
        label: alias,
        rowData,
        position: {
          x: PADDING_LEFT,
          y: prevDevicesHeight + VERTICAL_SPACING + index * NODE_HEIGHT
        }
      })
    )
    if (traceroute && traceroute.length) {
      traceroute.forEach(({ domain, ip, delay, uid }, innIndex) => {
        if (isLast(innIndex, traceroute) && reachedToServer) {
          serverNodes[uid] = getServerNodeObj({
            id: uid,
            label: domain,
            position: {
              x:
                (innIndex + 1) *
                  Math.floor(
                    (containerWidth - HORIZONTAL_SPACING - PADDING_LEFT) /
                      traceroute.length
                  ) +
                PADDING_LEFT,
              y: prevDevicesHeight + VERTICAL_SPACING + index * NODE_HEIGHT
            }
          })
        } else {
          tracerouteNodes[`${id}-${ip}`] = getRouterNodeObj({
            id: `${id}-${convertIpToId(ip)}`,
            label: domain,
            ip,
            showMapPopup: !isPublic,
            position: {
              x:
                (innIndex + 1) *
                  Math.floor(
                    (containerWidth - HORIZONTAL_SPACING - PADDING_LEFT) /
                      traceroute.length
                  ) +
                PADDING_LEFT,
              y: prevDevicesHeight + VERTICAL_SPACING + index * NODE_HEIGHT
            },
            isActive: !isLast(innIndex, traceroute) || reachedToServer
          })
        }

        edges.push(
          getEdgeObj({
            id: `${id}-${convertIpToId(ip)}`,
            source:
              innIndex === 0
                ? getDeviceId(id)
                : getRouterId(
                    `${id}-${convertIpToId(traceroute[innIndex - 1]?.ip)}`
                  ),
            target:
              isLast(innIndex, traceroute) && reachedToServer
                ? getServerId(uid)
                : getRouterId(`${id}-${convertIpToId(ip)}`),
            label: `${delay} ms`,
            textColor: delay >= 800 ? 'red' : delay >= 300 ? 'yellow' : 'green'
          })
        )
      })
    } else {
      tracerouteNodes[`${id}-empty`] = getWrongNodeObj({
        id: `${id}-empty`,
        position: {
          x:
            3 *
              Math.floor(
                (containerWidth - HORIZONTAL_SPACING - PADDING_LEFT) / 3
              ) +
            60,
          y: prevDevicesHeight + VERTICAL_SPACING + index * NODE_HEIGHT
        }
      })

      edges.push(
        getEmptyEdgeObj({
          id: `${id}-empty`,
          source: getDeviceId(id),
          target: getRouterId(`${id}-empty`)
        })
      )
    }
  })

  return {
    nodes: [
      ...Object.values(serverNodes),
      ...deviceNodes,
      ...Object.values(tracerouteNodes)
    ],
    edges
  }
}

export const getRandomColor = () => {
  return ALARM_ALERT_COLORS[
    Math.floor(Math.random() * ALARM_ALERT_COLORS.length)
  ]
}

export const alarmConditionInitialValue = {
  columnName: '',
  operator: '',
  value: '',
  color: getRandomColor()
}

export const alarmDetailsInitialValue = {
  value: ''
}

export const ruleOptions = t => [
  {
    name: alarmRuleValues.AND,
    label: t('AND')
  },
  {
    name: alarmRuleValues.OR,
    label: t('OR')
  }
]

export const alertAlarmValidationSchema = t =>
  Yup.object().shape({
    deviceIds: Yup.array().min(1, t('Choose at least 1 device')),
    rules: Yup.array(),
    conditions: Yup.array()
      .of(
        Yup.object().shape({
          columnName: Yup.string()
            .restrictedCharacters()
            .required(t(requiredField)),
          operator: Yup.string().required(t(requiredField)),
          value: Yup.string().restrictedCharacters().required(t(requiredField))
        })
      )
      .min(1, t(requiredField)),
    details: Yup.object().shape({
      sms: Yup.object().shape({
        toggle: Yup.boolean(),
        data: Yup.array().when('toggle', {
          is: true,
          then: Yup.array()
            .of(
              Yup.object().shape({
                value: Yup.string()
                  .restrictedCharacters()
                  .required(t(requiredField))
              })
            )
            .min(1, t(requiredField))
        })
      }),
      email: Yup.object().shape({
        toggle: Yup.boolean(),
        data: Yup.array().when('toggle', {
          is: true,
          then: Yup.array()
            .of(
              Yup.object().shape({
                value: Yup.string()
                  .required(t(requiredField))
                  .email(t(emailField))
              })
            )
            .min(1, t(requiredField))
        })
      }),
      noc: Yup.object().shape({
        toggle: Yup.boolean()
      }),
      root: Yup.string()
        .when(['sms', 'email', 'noc'], {
          is: (sms, email, noc) => !(sms.toggle || email.toggle || noc.toggle),
          then: Yup.string().required(t('Select at least 1 option'))
        })
        .nullable()
    })
  })

export const frequencyOptions = t => [
  { label: t('Once an hour'), value: 3600 },
  { label: t('Once a day'), value: 86400 },
  { label: t('Once a week'), value: 604800 },
  { label: t('As it happens'), value: 0 },
  { label: t('Never'), value: -1 }
]
