import React, { useCallback } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import compareVersions from 'compare-versions'

import PulseDot from './PulseDot/PulseDot'
import DeviceNameViewCell from './DeviceNameViewCell'
import { Card, DeviceMoreInfoCard } from 'components/Card'
import { TextWithTooltip } from 'components/Typography'
import DeviceRamViewCell from '../DeviceLibrary/DeviceTable/DeviceRamViewCell'

const styles = theme => {
  const { palette, type, typography } = theme
  return {
    cardRoot: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      border: `solid 1px ${palette[type].deviceCard.border}`,
      boxShadow: `0 2px 4px 0 ${palette[type].deviceCard.shadow}`,
      borderRadius: '7px',
      position: 'relative'
    },
    moreInfoMenuDropdownContainer: {
      width: '360px'
    },
    cardHeader: {
      padding: '15px 20px 15px',
      marginBottom: 0,
      backgroundColor: palette[type].deviceCard.header.background,
      borderRadius: '7px 7px 0 0'
    },
    cardHeaderText: {
      fontSize: '16px'
    },
    detailRow: {
      padding: '0 12px',
      minHeight: '28px',
      borderBottom: `1px solid ${palette[type].deviceCard.row.background}`
    },
    detailLabel: {
      ...typography.lightText[type]
    },
    detailValue: {
      ...typography.darkAccent[type]
    },
    footer: {
      padding: '8px 8px',
      backgroundColor: palette[type].deviceCard.footer.background,
      borderRadius: '0 0 7px 7px',
      ...typography.darkAccent[type]
    },
    footerCheckbox: {
      marginRight: '10px'
    },
    actionDropdown: {
      overflow: 'hidden'
    },
    rowActionBtn: {
      minWidth: '32px',
      paddingLeft: '5px',
      paddingRight: '5px',
      boxShadow: '0 1px 0 0 rgba(216, 222, 234, 0.5)',
      color: palette[type].deviceCard.button.color,

      '&:hover': {
        borderColor: '#1c5dca',
        backgroundColor: '#1c5dca',
        color: '#f5f6fa'
      }
    },
    rowActionBtnIcon: {
      width: 18,
      height: 18
    },
    cardActionList: {
      width: '485px',
      display: 'flex',
      flexDirection: 'row'
    },
    actionBtnLink: {
      flex: '1 1 auto',
      flexDirection: 'column',
      width: 100,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 15,
      minWidth: '60px',

      '&:not(:last-child)': {
        borderRight: `1px solid ${palette[type].deviceCard.border}`
      }
    },
    actionBtnIconWrap: {
      margin: '15px 0 10px 0'
    },
    statusActionWrap: {
      padding: '3px 4px'
    },
    actionBtnIcon: {
      fontSize: '24px'
    },
    rebootDeviceColor: {
      color: '#d31712'
    },
    actionBtnText: {
      ...typography.lightText[type],
      textAlign: 'center'
    },
    cardTitle: {
      ...typography.darkAccent[type],
      fontSize: '1.125rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    cardSubTitle: {
      ...typography.lightText[type],
      fontSize: '0.9rem'
    },
    headerWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '54px'
    },
    activeStatusColor: {
      color: '#36b5cb'
    },
    inActiveStatusColor: {
      color: 'rgba(148, 148, 148, 1)'
    },
    text: {
      ...typography.darkAccent[type]
    },
    textSmall: {
      ...typography.subtitle[type],
      textAlign: 'end'
    },
    contentWrapAlign: {
      textAlign: 'end'
    },
    aliasWrapContent: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      minWidth: '190px',
      ...typography.darkAccent[type]
    },
    deviceNameWrap: {
      textAlign: 'center',
      display: 'flex',
      width: 'max-content'
    },
    header: {
      backgroundColor: palette[type].deviceCard.header.background,
      padding: '4px'
    },
    deviceNameRow: {
      padding: '6px'
    },
    detailValueRed: {
      color: 'red'
    },
    label: {
      ...typography.subtitle[type]
    },
    aliasWrap: {
      display: 'flex'
    }
  }
}

const getUtilizationPercentage = (utilizationStorage, totalStorage) => {
  const utilizationDataRegex = /[+-]?\d+(\.\d+)?/g
  if (
    !utilizationStorage ||
    !totalStorage ||
    !(
      utilizationDataRegex.test(utilizationStorage) ||
      utilizationDataRegex.test(totalStorage)
    )
  ) {
    return 'N/A'
  }

  const value = utilizationStorage
    .match(utilizationDataRegex)
    .map(v => parseFloat(v))[0]
  const totalValue = totalStorage
    .match(utilizationDataRegex)
    .map(v => parseFloat(v))[0]

  return `${((value / totalValue) * 100)
    .toFixed(2)
    .replace(/0{1,2}$/, '')
    .replace(/\.$/, '')}%`
}

const DeviceBox = ({ t, classes, data, role }) => {
  const newFirmwareVersion = useCallback(() => {
    if (data.availableFirmwareList.length) {
      const firmware = data.availableFirmwareList
        .filter(a => compareVersions.validate(a.version))
        .sort((a, b) => compareVersions(b.version, a.version))

      return (firmware && firmware.length && firmware[0].version) || ''
    }

    return ''
  }, [data.availableFirmwareList])

  return (
    <Card
      rootStyle={
        data?.deviceType?.color
          ? {
              borderLeft: `2px solid ${data?.deviceType?.color}`
            }
          : {}
      }
      menuDropdownContainerClassName={classes.moreInfoMenuDropdownContainer} // This is for More Info dropdown
      menuDropdownComponent={<DeviceMoreInfoCard device={data} />}
      popupPlacement="bottom"
      rootClassName={classes.cardRoot}
      headerClasses={[classes.cardHeader]}
      headerTextClasses={[classes.cardHeaderText]}
    >
      <Grid
        className={classNames(classes.header)}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <PulseDot
          color={data.status === 'Active' ? 'green' : 'red'}
          styles={{
            margin: '5px auto 7px auto',
            width: '28px',
            height: '28px'
          }}
        />
      </Grid>
      <Grid
        className={classes.detailRow}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.detailLabel}>
            {t('Network Speed')}
          </Typography>
        </Grid>
        <Grid item>
          <TextWithTooltip rootClassName={classes.detailValue} maxWidth={95}>
            {data?.networkSpeed || t('N/A')}
          </TextWithTooltip>
        </Grid>
      </Grid>
      <Grid
        className={classes.detailRow}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.detailLabel}>
            {t('Storage Utilized')}
          </Typography>
        </Grid>
        <Grid item>
          <TextWithTooltip rootClassName={classes.detailValue} maxWidth={95}>
            {data?.storageUtilization && data?.totalStorage
              ? getUtilizationPercentage(
                  data.storageUtilization,
                  data.totalStorage
                )
              : t('N/A')}
          </TextWithTooltip>
        </Grid>
      </Grid>
      <Grid
        className={classes.detailRow}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.detailLabel}>
            {t('CPU Temp')}
          </Typography>
        </Grid>
        <Grid item>
          <TextWithTooltip rootClassName={classes.detailValue} maxWidth={95}>
            {data?.processorTemperature || t('N/A')}
          </TextWithTooltip>
        </Grid>
      </Grid>
      <Grid
        className={classes.detailRow}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.detailLabel}>
            {t('RAM Usage')}
          </Typography>
        </Grid>
        <Grid item>
          <TextWithTooltip rootClassName={classes.detailValue} maxWidth={95}>
            <DeviceRamViewCell
              memory={data.memoryUtilization}
              osVersion={data.osVersion}
              totalMemory={data.totalMemory}
              avgMemory={data.avgMemory}
            />
          </TextWithTooltip>
        </Grid>
      </Grid>
      <Grid
        className={classes.detailRow}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.detailLabel}>
            {t('Last Update')}
          </Typography>
        </Grid>
        <Grid item>
          <TextWithTooltip rootClassName={classes.detailValue} maxWidth={95}>
            {data.updatedAt ? moment(data.updatedAt).fromNow() : t('N/A')}
          </TextWithTooltip>
          <Typography className={classNames(classes.detailValue)}></Typography>
        </Grid>
      </Grid>
      <Grid
        className={classes.detailRow}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.detailLabel}>
            {t('Last Reboot')}
          </Typography>
        </Grid>
        <Grid item>
          <TextWithTooltip rootClassName={classes.detailValue} maxWidth={95}>
            {data.lastReboot ? moment(data.lastReboot).fromNow() : t('N/A')}
          </TextWithTooltip>
        </Grid>
      </Grid>
      <Grid
        className={classes.detailRow}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className={classes.detailLabel}>
            {t('Device Application')}
          </Typography>
        </Grid>
        <Grid item>
          <TextWithTooltip
            rootClassName={classNames(classes.detailValue, {
              [classes.detailValueRed]:
                data?.availableFirmwareList?.length > 0 &&
                newFirmwareVersion() !== data?.firmware
            })}
            maxWidth={90}
          >
            {data?.firmware || t('N/A')}
          </TextWithTooltip>
        </Grid>
      </Grid>
      <Grid
        className={classNames(classes.footer)}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <DeviceNameViewCell
          role={role}
          classes={classes}
          notes={0}
          row={data}
        />
      </Grid>
    </Card>
  )
}

DeviceBox.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired
}

export default withTranslation('translations')(withStyles(styles)(DeviceBox))
