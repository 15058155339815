import * as types from 'actions'
import update from 'immutability-helper'
import { putInitialState } from 'constants/initialLibraryState'
import entityConstants from 'constants/entityConstants'
import { preferenceStatus } from 'constants/libraryConstants'

const structurePreferences = Object.keys(entityConstants).reduce(
  (accum, key) => ({
    ...accum,
    [key]: {
      status: preferenceStatus.NOT_RECEIVED,
      response: {},
      error: {}
    }
  }),
  {}
)

const initialState = {
  ...structurePreferences,
  put: putInitialState
}

const preferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PREFERENCE_BY_ENTITY_SUCCESS:
    case types.GET_PREFERENCE_PUBLIC_NOC_SUCCESS:
      return update(state, {
        [action.entity]: {
          response: { $set: action.response },
          status: { $set: action.status }
        }
      })
    case types.GET_PREFERENCE_BY_ENTITY_ERROR:
    case types.GET_PREFERENCE_PUBLIC_NOC_ERROR:
      return update(state, {
        [action.entity]: {
          error: { $set: action.payload },
          status: { $set: preferenceStatus.ERROR }
        }
      })
    case types.PUT_PREFERENCE_BY_ENTITY_SUCCESS:
    case types.PUT_PREFERENCE_PUBLIC_NOC_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_PREFERENCE_BY_ENTITY_ERROR:
    case types.PUT_PREFERENCE_PUBLIC_NOC_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PREFERENCE_BY_ENTITY:
      return update(state, {
        [action.entity]: {
          $set: {
            status: 'not received',
            response: {},
            error: {}
          }
        }
      })
    default:
      return state
  }
}

export default preferenceReducer
