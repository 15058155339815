import { call, put } from 'redux-saga/effects'

import * as types from '../../actions/public'

import * as authService from '../../services/public/auth'
import { transformMeta } from 'utils/tableUtils'

function* getPublicToken({ params }) {
  try {
    const { meta, data } = yield call(authService.getPublicToken, params)

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_PUBLIC_TOKEN_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_PUBLIC_TOKEN_ERROR,
      payload: error
    })
  }
}

function* postPublicToken({ data }) {
  try {
    yield call(authService.postPublicToken, data)

    yield put({
      type: types.POST_PUBLIC_TOKEN_SUCCESS,
      response: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_PUBLIC_TOKEN_ERROR, payload: error })
  }
}

function* deletePublicToken({ id }) {
  try {
    yield call(authService.deletePublicToken, id)

    yield put({
      type: types.DELETE_PUBLIC_TOKEN_SUCCESS,
      response: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_PUBLIC_TOKEN_ERROR,
      payload: error
    })
  }
}

const auth = {
  getPublicToken,
  postPublicToken,
  deletePublicToken
}

export default auth
