import React, { useCallback, useMemo, useState } from 'react'
import { Avatar, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-block'
  },
  status: {
    '&:hover': {
      cursor: 'pointer'
    },

    '&::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: '5px',
      right: '-2px',
      width: 12,
      height: 12,
      border: '2px solid #fff',
      borderRadius: '100%',
      zIndex: 3
    }
  },
  userOffline: {
    '&::after': {
      backgroundColor: 'gray'
    }
  },
  userOnline: {
    '&::after': {
      backgroundColor: '#3cd480'
    }
  },
  loginWithinWeek: {
    '&::after': {
      backgroundColor: '#D35E37'
    }
  },

  typedAvatar: {
    width: 52,
    height: 52,
    border: '3px solid',
    borderColor: props => props.roleColor
  },
  smallTypedAvatar: {
    width: 42,
    height: 42
  },

  avatar: {
    width: 57,
    height: 57
  },
  smallAvatar: {
    width: 47,
    height: 47
  },
  clientAdmin: {
    borderColor: '#ff7b25'
  },
  superAdmin: {
    borderColor: '#3983ff'
  },
  isChange: {
    cursor: 'pointer'
  },
  isDisabled: {
    cursor: 'not-allowed'
  },
  avatarColoredCircle: {
    position: 'absolute',
    borderRadius: '100%',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  avatarRole: {
    margin: 5,
    width: 42,
    height: 42
  },
  smallAvatarRole: {
    width: 32,
    height: 32
  }
})

const onlineStatuses = {
  online: 'online',
  offline: 'offline',
  weeklyOnline: 'weeklyOnline'
}

const UserPic = props => {
  const {
    lastLogin,
    role,
    userName = '',
    imgSrc,
    isChange,
    isDisabled,
    noStatus = false,
    roleDisplayName,
    onClick = () => ({}),
    avatarClassName,
    small = false
  } = props

  const classes = useStyles(props)
  const { t } = useTranslation('translations')

  const [error, setError] = useState(false)

  const onlineStatus = useMemo(() => {
    if (!lastLogin || noStatus) {
      return onlineStatuses.offline
    }

    const currentDate = moment()
    const lastLoginDate = moment(lastLogin)

    if (currentDate.diff(lastLoginDate, 'days') <= 7) {
      return currentDate.diff(lastLoginDate, 'hours') <= 1
        ? onlineStatuses.online
        : onlineStatuses.weeklyOnline
    } else {
      return onlineStatuses.offline
    }
  }, [lastLogin, noStatus])

  const statusText = useMemo(() => {
    switch (onlineStatus) {
      case onlineStatuses.offline:
        return t('Offline')
      case onlineStatuses.online:
        return t('Online')
      case onlineStatuses.weeklyOnline:
        return t('Login within week')
      default:
        return t('Offline')
    }
  }, [onlineStatus, t])

  const handleClick = useCallback(() => {
    !isDisabled && onClick()
  }, [onClick, isDisabled])

  return (
    <div
      onClick={handleClick}
      className={classNames(classes.root, {
        [classes.isChange]: isChange,
        [classes.isDisabled]: isDisabled
      })}
    >
      {!noStatus && (
        <Tooltip arrow title={statusText} placement="top">
          <div
            className={classNames({
              [classes.status]: !noStatus,
              [classes.userOffline]: onlineStatus === onlineStatuses.offline,
              [classes.userOnline]: onlineStatus === onlineStatuses.online,
              [classes.loginWithinWeek]:
                onlineStatus === onlineStatuses.weeklyOnline
            })}
          />
        </Tooltip>
      )}
      {role && (
        <Tooltip arrow title={roleDisplayName} placement="top">
          <div
            className={classNames(classes.avatarColoredCircle, {
              [classes.typedAvatar]: role,
              [classes.smallTypedAvatar]: small
            })}
          />
        </Tooltip>
      )}
      <Avatar
        alt={typeof userName === 'string' ? userName : 'Profile image'}
        src={imgSrc?.includes('user1.png') || error ? null : imgSrc}
        onError={() => setError(true)}
        children={
          typeof userName === 'string'
            ? userName[0]?.toUpperCase()
            : userName.props?.firstName[0]?.toUpperCase()
        }
        className={classNames(avatarClassName, {
          [classes.avatar]: !role,
          [classes.avatarRole]: role,
          [classes.smallAvatarRole]: small && role,
          [classes.smallAvatar]: small && !role
        })}
      />
    </div>
  )
}

UserPic.propTypes = {
  status: PropTypes.string,
  roleColor: PropTypes.string,
  onClick: PropTypes.func,
  isChange: PropTypes.bool
}

export default UserPic
