import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FormControlAutocompleteNew } from './index'
import * as selectUtils from 'utils/select'
import { tagDefaultColor } from 'constants/chipsColorPalette'
import { getGroupOptions } from 'services/getOptionsWithMeta'
import useDeterminePermissions from 'hooks/useDeterminePermissions'
import { CLIENT_GROUP, GROUP } from 'constants/permissionGroups'
import { clearPostGroupInfoAction, postGroupAction } from 'actions/groupActions'
import { entityGroupsConstants } from 'constants/index'
import { parseNestedError } from 'utils/errorHandler'

const getStyles = {
  multiValue: ({ data }) => ({
    borderColor: data.color || '#fd7b25',
    background: data.background || 'transparent'
  }),
  multiValueLabel: ({ data }) => ({
    color: data.color || '#fd7b25'
  }),
  multiValueRemove: ({ data }) => ({
    'svg path': {
      fill: data.color || '#fd7b25'
    },
    ':hover': {
      backgroundColor: data.color || '#fd7b25',
      'svg path': {
        fill: '#fff'
      }
    }
  })
}

const determinePermissions = [GROUP, CLIENT_GROUP]

const FormControlSelectGroup = ({
  getOptions,
  hasDynamicChipsCreation = true,
  entity,
  styles = {},
  t,
  error: rawError,
  hasWritePermission,
  impersonated,
  ...props
}) => {
  const dispatch = useDispatch()
  const postInfo = useSelector(({ group }) => group.post)

  const [groupPermission, entGroupPermission] = useDeterminePermissions(
    determinePermissions
  )

  const allGroupPermission = useMemo(
    () => ({
      read: groupPermission.read || entGroupPermission.read,
      create: groupPermission.create || entGroupPermission.create
    }),
    [groupPermission, entGroupPermission]
  )

  const [createdGroup, setCreatedGroup] = useState({})

  useEffect(() => {
    const { response, error } = postInfo

    if (!_isEmpty(response.data)) {
      const newGroup = selectUtils.toGroupChipObj(postInfo.response.data)
      setCreatedGroup({ data: newGroup })
    } else if (!_isEmpty(error)) {
      setCreatedGroup({ error: error })
    }
    // eslint-disable-next-line
  }, [postInfo])

  const customStyles = useMemo(() => {
    return { ...getStyles, ...styles }
  }, [styles])

  const handleCreateGroup = useCallback(
    title => {
      if (entity) {
        const data = {
          title: title,
          entity: entity,
          color: tagDefaultColor
        }
        dispatch(postGroupAction(data))
      }
    },
    [entity, dispatch]
  )

  const handleClearCreatedGroup = useCallback(
    () => dispatch(clearPostGroupInfoAction()),
    [dispatch]
  )

  const isCreationAllowed = useMemo(() => {
    return allGroupPermission.create && hasDynamicChipsCreation
  }, [allGroupPermission, hasDynamicChipsCreation])

  const noOptionsMessage = useMemo(() => {
    if (!allGroupPermission.read) {
      return t('No permissions available')
    } else if (!isCreationAllowed) {
      return value => (value ? `No Options for "${value}"` : t('No Options'))
    } else {
      return t('Press Enter to add new Group')
    }
  }, [allGroupPermission, isCreationAllowed, t])

  const optionsGetter = useMemo(
    () =>
      getOptions
        ? getOptions
        : getGroupOptions(entity, hasWritePermission, impersonated),
    [getOptions, entity, hasWritePermission, impersonated]
  )

  return (
    <FormControlAutocompleteNew
      isResettable
      isMulti
      styles={customStyles}
      noOptionsMessage={noOptionsMessage}
      createdValue={createdGroup}
      createNewValue={handleCreateGroup}
      clearCreatedValue={handleClearCreatedGroup}
      hasDynamicChipsCreation={isCreationAllowed}
      getOptions={optionsGetter}
      error={parseNestedError(rawError)}
      {...props}
    />
  )
}

FormControlSelectGroup.propTypes = {
  name: PropTypes.string.isRequired,
  entity: PropTypes.oneOf(Object.values(entityGroupsConstants)).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  styles: PropTypes.object,
  hasDynamicChipsCreation: PropTypes.bool,
  filterByWritePermission: PropTypes.bool,
  getOptions: PropTypes.func,
  impersonated: PropTypes.bool
}

export default withTranslation('translations')(memo(FormControlSelectGroup))
