import { Grid, Tooltip, withStyles, withTheme } from '@material-ui/core'
import { PlaylistAdd } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { memo, useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { _get } from 'utils/lodash'
import Popup from '../../components/Popup'
import { CircleIconButton } from '../Buttons'
import { TableLibraryPagination } from './index'
import AddTagsAndGroupsPopupContent from './FooterComponents/AddTagsAndGroupsPopupContent'
import { compose } from '@reduxjs/toolkit'
import TableLibrarySelectAllCheckbox from './TableLibrarySelectAllCheckbox'
import classNames from 'classnames'
import { getPaginationValues } from '../../utils/libraryUtils'
import { paginationViews } from '../../constants/libraryConstants'
import { useUserRole } from '../../hooks/tableLibrary'
import {
  librariesWithBulkEditSupport,
  librariesWithoutGroupsSupport,
  libraryTypes
} from 'constants/library'

const styles = theme => {
  const { palette, type, colors } = theme
  return {
    tableFooterWrap: {
      paddingLeft: 10,
      backgroundColor: palette[type].tableLibrary.footer.background,
      borderRadius: '0 0 4px 4px'
    },
    tableFooterCircleIcon: {
      fontSize: '18px',
      color: colors.title.primary[type]
    },
    tableFooterTagsIcon: {
      paddingTop: '1px'
    },
    tableFooterPlaylistIcon: {
      fontSize: '20px',
      padding: '10px',
      color: colors.title.primary[type]
    },
    footerIconSize: {
      fontSize: '18px'
    }
  }
}

const entities = {
  device: 'Devices',
  media: 'Media',
  playlist: 'Playlists',
  template: 'Templates',
  schedule: 'Schedules',
  report: 'Reports',
  list: 'template_library',
  users: 'users_page',
  clients: 'Clients'
}

const TableLibraryFooter = ({
  t,
  page = 1,
  data = [],
  selected = [],
  setSelected,
  clearSelected,
  classes,
  perPage,
  pageCount = 0,
  allSelected = false,
  onSelectAllClick,
  onPageChange = f => f,
  perPageOptions,
  handleChangeRowsPerPage = f => f,
  handleClickDeleteSelectedItems = f => f,
  onPressJumper = f => f,
  libraryType,
  storeType,
  libraryTypeStorePath,
  theme: { palette, type },
  hasDelete = true,
  noBulkTagsAssign,
  fetcher = f => f,
  meta = {},
  hasMultiSelect = true,
  footerActionsComponent,
  openAddToPlaylistModal = f => f,
  openAddMediaToScheduleOpen,
  tagTooltipTitle,
  totalCount,
  hasPagination = true,
  isSelectAllDisabled = false,
  displayPaginationOptions,
  tableFooterWrapClasName = '',
  paginationClasses = {},
  canDelete = true,
  canEdit = true,
  headSection,
  bulkExcludedIds,
  hideGroupEntity,
  currentPage,
  handlePaginationChange
}) => {
  const location = useLocation()
  const role = useUserRole()

  const deleteSelected = useCallback(
    () => selected.length > 0 && handleClickDeleteSelectedItems(),
    [handleClickDeleteSelectedItems, selected]
  )

  const selectedNone = useMemo(() => {
    return !selected.length
  }, [selected])

  const isDeleteDisabled = useMemo(() => {
    return !canDelete || selectedNone
  }, [canDelete, selectedNone])

  const isEditDisabled = useMemo(() => {
    return !canEdit || selectedNone
  }, [canEdit, selectedNone])

  const tagGroupTooltipTitleText = useMemo(() => {
    if (
      librariesWithoutGroupsSupport.includes(libraryType) ||
      hideGroupEntity
    ) {
      return t('Set Tags')
    }

    const entity = location.pathname
      .substr(location.pathname.lastIndexOf('/') + 1)
      .replace('-library', '')
    return tagTooltipTitle
      ? `${t('Set Tags & Groups To Selected')} ${tagTooltipTitle}`
      : `${t('Set Tags & Groups To Selected')} ${t(
          _get(entities, entity, 'Media')
        )}`
  }, [location.pathname, t, tagTooltipTitle, libraryType, hideGroupEntity])

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classNames(classes.tableFooterWrap, tableFooterWrapClasName)}
    >
      <Grid item>
        {hasMultiSelect && onSelectAllClick ? (
          <TableLibrarySelectAllCheckbox
            isSelectAllDisabled={isSelectAllDisabled}
            allSelected={allSelected}
            onSelectAllClick={onSelectAllClick}
          />
        ) : null}
        {hasDelete && (
          <CircleIconButton
            disabled={isDeleteDisabled}
            className={`hvr-grow ${classes.tableFooterCircleIcon}`}
            onClick={deleteSelected}
          >
            <i className="fa-regular fa-trash-can" />
          </CircleIconButton>
        )}
        {librariesWithBulkEditSupport.includes(libraryType) &&
          !noBulkTagsAssign && (
            <Popup
              on="click"
              position={headSection ? 'right top' : 'right bottom'}
              disabled={isEditDisabled}
              trigger={
                <Tooltip arrow title={tagGroupTooltipTitleText}>
                  <span>
                    <CircleIconButton
                      disabled={isEditDisabled}
                      className={`hvr-grow ${classes.tableFooterCircleIcon}`}
                    >
                      <i
                        className={classNames(
                          'fa-regular fa-tags',
                          classes.tableFooterTagsIcon
                        )}
                      />
                    </CircleIconButton>
                  </span>
                </Tooltip>
              }
              contentStyle={{
                zIndex: 1000
              }}
              arrowStyle={{
                background: palette[type].sideModal.action.background
              }}
            >
              {close => (
                <AddTagsAndGroupsPopupContent
                  type={libraryType}
                  storeType={storeType}
                  storePath={libraryTypeStorePath}
                  data={data}
                  close={close}
                  selectedIds={selected}
                  setSelected={setSelected}
                  clearSelected={clearSelected}
                  hideGroupEntity={hideGroupEntity}
                  fetcher={fetcher}
                  meta={meta}
                />
              )}
            </Popup>
          )}
        {footerActionsComponent && footerActionsComponent}
        {libraryType === libraryTypes.media && (
          <Tooltip arrow title={t('Add Selected Media to Playlist')}>
            <span>
              <CircleIconButton
                disabled={selectedNone || !bulkExcludedIds}
                onClick={openAddToPlaylistModal}
                className={`hvr-grow ${classes.tableFooterPlaylistIcon}`}
              >
                <PlaylistAdd />
              </CircleIconButton>
            </span>
          </Tooltip>
        )}
        {libraryType === libraryTypes.media && role.org && (
          <Tooltip arrow title={t('Add Selected Media to Schedule')}>
            <span>
              <CircleIconButton
                disabled={selectedNone || !bulkExcludedIds}
                onClick={openAddMediaToScheduleOpen}
                className={`hvr-grow fa-regular fa-light fa-calendar-clock  ${classes.tableFooterPlaylistIcon} ${classes.footerIconSize}`}
              ></CircleIconButton>
            </span>
          </Tooltip>
        )}
      </Grid>
      {hasPagination && (
        <Grid item>
          <TableLibraryPagination
            pageCount={pageCount}
            perPageOptions={
              perPageOptions ||
              getPaginationValues(paginationViews.listView, role.role)
            }
            component="div"
            perPage={perPage}
            page={page}
            onPageChange={onPageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onPressJumper={onPressJumper}
            totalCount={totalCount}
            displayPaginationOptions={displayPaginationOptions}
            paginationClasses={paginationClasses}
            currentPage={currentPage}
            handlePaginationChange={handlePaginationChange}
          />
        </Grid>
      )}
    </Grid>
  )
}

TableLibraryFooter.propTypes = {
  t: PropTypes.func,
  page: PropTypes.number,
  data: PropTypes.array,
  selected: PropTypes.arrayOf(PropTypes.number),
  classes: PropTypes.object,
  perPage: PropTypes.number,
  pageCount: PropTypes.number,
  allSelected: PropTypes.bool,
  onSelectAllClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onPageChange: PropTypes.func,
  perPageOptions: PropTypes.array,
  handleChangeRowsPerPage: PropTypes.func,
  handleClickDeleteSelectedItems: PropTypes.func,
  onPressJumper: PropTypes.func,
  theme: PropTypes.object,
  hasDelete: PropTypes.bool,
  noBulkTagsAssign: PropTypes.bool,
  fetcher: PropTypes.func,
  meta: PropTypes.object,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  hasMultiSelect: PropTypes.bool,
  footerActions: PropTypes.node,
  openAddToPlaylistModal: PropTypes.func,
  tagTooltipTitle: PropTypes.string,
  totalCount: PropTypes.number,
  hasPagination: PropTypes.bool,
  isSelectAllDisabled: PropTypes.bool
}

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  withTheme,
  memo
)(TableLibraryFooter)
