import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { FormControlReactSelect } from 'components/Form'
import { LIGHT } from 'theme'

const useStyles = makeStyles(() => ({
  root: {
    width: '150px !important',
    margin: '0 3px 6px 0'
  }
}))

const CustomBlockTypeSelect = ({
  currentState,
  onChange,
  editorRef,
  ...props
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const toggleBLockType = ({ target: { value } }) => {
    onChange(value)
  }

  const handleMenuClose = () => {
    if (editorRef.current.focusEditor) {
      editorRef.current.focusEditor()
    }
  }

  const optionsData = useMemo(() => {
    return props.config.options.map(option => ({
      label: option,
      value: option
    }))
  }, [props.config.options])

  return (
    <FormControlReactSelect
      name="blockType"
      value={currentState.blockType}
      onChange={toggleBLockType}
      marginBottom={false}
      options={optionsData}
      placeholder="Block Type"
      formControlContainerClass={classes.root}
      onMenuClose={handleMenuClose}
      isSort={false}
      styles={{
        control: {
          minHeight: 30,
          height: 30,
          border: `1px solid ${
            theme.type === LIGHT ? '#F1F1F1' : theme.colors.light
          }`,
          borderRadius: '2px'
        }
      }}
    />
  )
}

export default CustomBlockTypeSelect
