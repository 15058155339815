import { errorHandler } from '../utils'
import api from './api'

const getMenuPresets = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/menuMaker/presets',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getStockMenuPresets = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/menuMaker/presets/stock',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getSharedMenuPresets = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/menuMaker/presets/shared',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuPresetItem = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/presets/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuPreset = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/menuMaker/presets`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putMenuPreset = async (id, data) => {
  try {
    return await api({
      method: 'PUT',
      url: `/menuMaker/presets/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteMenuPreset = async id => {
  try {
    return await api({
      method: 'DELETE',
      url: `/menuMaker/presets/${id}`
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const shareMenuPreset = async (id, data) => {
  try {
    return await api({
      method: 'POST',
      url: `/menuMaker/presets/${id}/share`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuComponents = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/menuMaker/components',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getStockMenuComponents = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/menuMaker/components/stock',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getSharedMenuComponents = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/menuMaker/components/shared',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuComponentItem = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/components/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuComponent = async data => {
  try {
    return await api({
      method: 'POST',
      url: '/menuMaker/components',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const putMenuComponent = async (id, data) => {
  try {
    return await api({
      method: 'PUT',
      url: `/menuMaker/components/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteMenuComponent = async id => {
  try {
    return await api({
      method: 'DELETE',
      url: `/menuMaker/components/${id}`
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const shareMenuComponent = async (id, data) => {
  try {
    return await api({
      method: 'POST',
      url: `/menuMaker/components/${id}/share`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getStockImages = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/menuMaker/config/designGallery/stockImage',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

// images

const getPatterns = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/menuMaker/config/designGallery/pattern',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getBackgroundImages = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/menuMaker/config/designGallery/background',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getObjects = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/menuMaker/config/designGallery/object',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getShapes = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/menuMaker/config/designGallery/shape',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getIcons = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/menuMaker/config/designGallery/icon/${params.style}`,
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getIconsGroup = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/menuMaker/config/designGallery/icon`,
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const getEmojis = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/menuMaker/config/designGallery/emoji',
      params
    })

    return response.data
  } catch (e) {
    errorHandler(e)
  }
}

const menuDesignService = {
  getMenuPresets,
  getStockMenuPresets,
  getSharedMenuPresets,
  getMenuPresetItem,
  postMenuPreset,
  putMenuPreset,
  deleteMenuPreset,
  shareMenuPreset,
  getMenuComponents,
  getStockMenuComponents,
  getSharedMenuComponents,
  getMenuComponentItem,
  postMenuComponent,
  putMenuComponent,
  deleteMenuComponent,
  shareMenuComponent,

  getStockImages,
  getBackgroundImages,
  getEmojis,
  getIcons,
  getIconsGroup,
  getObjects,
  getPatterns,
  getShapes
}

export default menuDesignService
