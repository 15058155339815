import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withStyles, LinearProgress, Grid } from '@material-ui/core'
import { ExitToApp, Done } from '@material-ui/icons'

import DefaultModal from './DefaultModal'
import { logoutUserAction } from 'actions/authenticationActions'
import { BlueButton, WhiteButton } from 'components/Buttons'
import Text from 'components/Typography/Text'
import Spacing from 'components/Containers/Spacing'
import { ACTIVITY_CONFIRMATION_DURATION } from 'constants/api'
import { resetAllFilters } from 'actions/filtersActions'

function styles() {
  return {
    container: {
      padding: 15
    },
    progress: {
      width: '100%'
    },
    button: {
      marginRight: 10
    },
    buttonIcon: {
      fontSize: 15,
      marginRight: 5
    }
  }
}

function InactivityModal({ t, classes, onConfirmActive, open }) {
  const dispatch = useDispatch()
  const [seconds, setSeconds] = useState(0)

  const handleLogout = useCallback(() => {
    dispatch(logoutUserAction())
    dispatch(resetAllFilters())
  }, [dispatch])

  useEffect(() => {
    let interval
    if (open) {
      if (seconds !== ACTIVITY_CONFIRMATION_DURATION) {
        setSeconds(ACTIVITY_CONFIRMATION_DURATION)
      }
      interval = setInterval(
        () => setSeconds(prevState => Math.max(prevState - 1, 0)),
        1000
      )
    } else {
      //without timeout second reset is visible for user
      setTimeout(() => setSeconds(ACTIVITY_CONFIRMATION_DURATION), 100)
    }

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [open])

  const progress = Math.min(
    ((ACTIVITY_CONFIRMATION_DURATION - seconds) /
      ACTIVITY_CONFIRMATION_DURATION) *
      100,
    100
  )

  return (
    <DefaultModal
      open={open}
      modalTitle={t('session_timeout')}
      onCloseModal={onConfirmActive}
      actions={
        <Fragment>
          <BlueButton
            className={classes.button}
            onClick={onConfirmActive}
            icon={<Done className={classes.buttonIcon} />}
          >
            {t('Continue')}
          </BlueButton>
          <WhiteButton
            onClick={handleLogout}
            icon={<ExitToApp className={classes.buttonIcon} />}
            variant="danger"
          >
            {t('Logout')}
          </WhiteButton>
        </Fragment>
      }
    >
      <Grid container className={classes.container}>
        <Spacing variant={2.5}>
          <Text>{t('session_timeout_text')}</Text>
        </Spacing>

        <Text>
          {t('session_timeout_seconds_left', { secondsLeft: seconds })}
        </Text>
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={progress}
        />
      </Grid>
    </DefaultModal>
  )
}

export default withStyles(styles)(
  withTranslation('translations')(InactivityModal)
)
