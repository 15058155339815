import React from 'react'
import PropTypes from 'prop-types'
import Spacing from 'components/Containers/Spacing'
import { TabToggleButtonGroup, TabToggleStaticButton } from 'components/Buttons'
import { MarginBottom } from 'utils/styles'
import Tooltip from 'components/Tooltip'
import { simulateEvent } from '../../utils'

export function createTab(label, value, tooltip) {
  return { value, label, tooltip }
}

const TooltipButton = tooltip =>
  React.forwardRef(({ ...props }, ref) => (
    <Tooltip {...tooltip}>
      <button ref={ref} {...props} type="button" />
    </Tooltip>
  ))

function TabsGroup({
  value,
  onChange,
  tabs,
  tabWidth,
  spacingVariant = 2,
  name,
  nullable = true
}) {
  const handleChange = (e, value) => {
    if (name) {
      if (nullable) {
        onChange(simulateEvent(name, value))
      } else if (value) {
        onChange(simulateEvent(name, value))
      }
    } else {
      if (nullable) {
        onChange(e, value)
      } else if (value) {
        onChange(e, value)
      }
    }
  }

  return (
    <Spacing alignItems="center" variant={spacingVariant}>
      <TabToggleButtonGroup exclusive value={value} onChange={handleChange}>
        {tabs.map(({ value, label, tooltip }, index) => (
          <TabToggleStaticButton
            key={index}
            value={value}
            width={tabWidth}
            component={tooltip ? TooltipButton(tooltip) : 'button'}
          >
            {label}
          </TabToggleStaticButton>
        ))}
      </TabToggleButtonGroup>
    </Spacing>
  )
}

const Value = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool
])

TabsGroup.propTypes = {
  value: Value,
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: Value
    })
  ),
  tabWidth: PropTypes.number,
  spacingVariant: MarginBottom
}

export default TabsGroup
