import getTokenName from 'utils/getTokenName'

function removePrefix(token = '') {
  return token ? token.replace('Bearer ', '') : ''
}

export default function getToken(withPrefix = true) {
  const token = localStorage.getItem(getTokenName())
  return withPrefix ? token : removePrefix(token)
}
