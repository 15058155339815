import React from 'react'
import { FormControlAutocompleteQuery } from 'components/Form/index'
import { components } from 'react-select'
import { useLazyDeviceListQuery } from 'api/deviceApi'

const NameOption = ({ classes, label, children, data, ...props }) => {
  return (
    <components.Option {...props}>
      <span
        style={{
          display: 'block',
          color: '#74809A'
        }}
      >
        {data.alias || data.title || label}
      </span>
      <span
        style={{
          display: 'block',
          fontSize: '0.75em'
        }}
      >
        {data.title || label}
      </span>
    </components.Option>
  )
}

const SingleValue = (field = 'value') => ({
  classes,
  children,
  data,
  ...props
}) => (
  <components.SingleValue {...props}>
    <input value={data[field]} readOnly size={data[field].length} />
  </components.SingleValue>
)

const FormControlSelectDevice = ({
  onChange,
  returnId,
  isCreatable = true,
  staticOptions = [],
  useLabel,
  useLazyQuery = useLazyDeviceListQuery,
  requestParams = {},
  ...props
}) => {
  const staticRequestParams = {
    fields: 'id,name,alias',
    sort: 'alias',
    order: 'asc',
    ...requestParams
  }

  const transformDeviceToOptions = data =>
    data?.map(({ alias, name, id }) => ({
      value: returnId ? id : name,
      label: useLabel ? name : `${name} ${alias}`,
      alias: alias,
      title: name
    }))

  return (
    <FormControlAutocompleteQuery
      {...props}
      responseParser={transformDeviceToOptions}
      onChange={onChange}
      isCreatable={isCreatable}
      useLazyQuery={useLazyQuery}
      searchField={'name'}
      staticRequestParams={staticRequestParams}
      components={{
        Option: NameOption,
        SingleValue: useLabel ? SingleValue('label') : SingleValue()
      }}
    />
  )
}

export default FormControlSelectDevice
