import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router-dom'

import { getToken } from '../../utils'
import { getUrlPrefix } from 'utils/permissionUrls'
import useUserDetails from 'hooks/useUserDetails'

const UnauthorizedRoute = ({ component: Component, ...rest }) => {
  const [logged, setLogged] = useState(!!getToken())
  const userDetails = useUserDetails()

  useEffect(() => {
    setLogged(!!getToken())
  }, [userDetails])

  return (
    <Route
      {...rest}
      render={props =>
        !logged ? (
          <Component {...props} />
        ) : (
          <Redirect to={getUrlPrefix('dashboard')} />
        )
      }
    />
  )
}

UnauthorizedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object
  ])
}

export default UnauthorizedRoute
