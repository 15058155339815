import _get from 'lodash/get'
import { dependencyType } from 'constants/library'
import { isValidUrl } from 'utils/urlUtils'

export const mediaDependencyTypes = {
  ADD_MEDIA: 'ADD_MEDIA',
  EDIT_MEDIA: 'EDIT_MEDIA',
  ADD_DESIGN_GALLERY: 'ADD_DESIGN_GALLERY',
  EDIT_DESIGN_GALLERY: 'EDIT_DESIGN_GALLERY',
  DELETE_MEDIA: 'DELETE_MEDIA',
  ADD_MEDIA_TAGS_BULK: 'ADD_MEDIA_TAGS_BULK',
  DELETE_MEDIA_BULK: 'DELETE_MEDIA_BULK',
  CLONE_MEDIA: 'CLONE_MEDIA',
  APPROVE_MEDIA: 'APPROVE_MEDIA',
  ADD_MENU_MAKER: 'ADD_MENU_MAKER',
  EDIT_MENU_MAKER: 'EDIT_MENU_MAKER'
}
export const messageTypes = {
  UNABLE_MESSAGE: 'Unable to assign tag as playlist contains only single media',
  MODEL_USED_MESSAGE: 'Model already used!',
  AFFECT_PLAYLISTS: 'This media will affect those playlists?',
  AFFECT_ENTITIES: 'This media will affect those entities'
}

export const contentMap = t => ({
  [mediaDependencyTypes.ADD_MEDIA]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable assign selected tags as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    [messageTypes.AFFECT_PLAYLISTS]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    modalTitle: t('Selected Tag(s) is in use')
  },
  [mediaDependencyTypes.EDIT_MEDIA]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable assign selected tags as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    [messageTypes.AFFECT_PLAYLISTS]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    modalTitle: t('Selected Tag(s) is in use')
  },
  [mediaDependencyTypes.ADD_DESIGN_GALLERY]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable assign selected tags as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    [messageTypes.AFFECT_PLAYLISTS]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    modalTitle: t('Selected Tag(s) is in use')
  },
  [mediaDependencyTypes.EDIT_DESIGN_GALLERY]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable assign selected tags as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    [messageTypes.AFFECT_PLAYLISTS]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    modalTitle: t('Selected Tag(s) is in use')
  },
  [mediaDependencyTypes.DELETE_MEDIA]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable delete the media as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: `${t(
      'Click on YES button to delete media as well from their respective playlist'
    )}:`,
    [messageTypes.AFFECT_PLAYLISTS]: `${t(
      'Click on YES button to delete media as well from their respective playlist'
    )}:`,
    [messageTypes.AFFECT_ENTITIES]: `${t(
      'Click on YES button to delete media as well from their respective entities'
    )}:`,
    modalTitle: t('Delete Media action')
  },
  [mediaDependencyTypes.ADD_MEDIA_TAGS_BULK]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable assign selected tags as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: `${t(
      'Selected tags accosicated with this media are currently part of a smart playlist. The following smart playlists will be affected'
    )}:`,
    [messageTypes.AFFECT_PLAYLISTS]: `${t(
      'Selected tags accosicated with this media are currently part of a smart playlist. The following smart playlists will be affected'
    )}:`,
    modalTitle: t('Selected Tag(s) is in use')
  },
  [mediaDependencyTypes.DELETE_MEDIA_BULK]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable delete following media as smart playlist must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: `${t(
      'Click on YES button to delete media as well from their respective playlist'
    )}:`,
    [messageTypes.AFFECT_PLAYLISTS]: `${t(
      'Click on YES button to delete media as well from their respective playlist'
    )}:`,
    modalTitle: t('Delete Media action')
  },
  [mediaDependencyTypes.CLONE_MEDIA]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable clone selected media as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: `${t(
      'Cloning the selected media will affect the following smart playlists'
    )}:`,
    [messageTypes.AFFECT_PLAYLISTS]: `${t(
      'Cloning the selected media will affect the following smart playlists'
    )}:`,
    modalTitle: t('Selected Tag(s) is in use')
  },
  [mediaDependencyTypes.APPROVE_MEDIA]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable assign selected tags as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: `${t(
      'Selected tags accosicated with this media are currently part of a smart playlist. The following smart playlists will be affected'
    )}:`,
    [messageTypes.AFFECT_PLAYLISTS]: `${t(
      'Selected tags accosicated with this media are currently part of a smart playlist. The following smart playlists will be affected'
    )}:`,
    modalTitle: t('Selected Tag(s) is in use')
  },
  [mediaDependencyTypes.ADD_MENU_MAKER]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable assign selected tags as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    [messageTypes.AFFECT_PLAYLISTS]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    modalTitle: t('Selected Tag(s) is in use')
  },
  [mediaDependencyTypes.EDIT_MENU_MAKER]: {
    [messageTypes.UNABLE_MESSAGE]: `${t(
      'Unable assign selected tags as following smart playlists must contain at least one media'
    )}:`,
    [messageTypes.MODEL_USED_MESSAGE]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    [messageTypes.AFFECT_PLAYLISTS]: {
      [dependencyType.attachPlaylist]: `${t(
        'Assigning selected tags will affect the following smart playlists'
      )}:`,
      [dependencyType.detachPlaylist]: `${t(
        'Removing selected tags will affect the following smart playlists'
      )}:`
    },
    modalTitle: t('Selected Tag(s) is in use')
  }
})

const getBulkIds = (url, params) => {
  const [, value] = [...url.searchParams].find(([key]) => key === 'ids') || []
  if (!value) {
    const { ids } = params
    return ids ? ids.split(',') : []
  }
  return value.split(',')
}
const getRequestId = (url, lastParam = 1) => {
  const tokens = url.pathname.split('/')
  if (!tokens.length) {
    return null
  }
  return tokens[tokens.length - lastParam]
}

const getActionTypeByRequestSignature = (config, request, meta) => {
  const method = config.method.toLowerCase()
  const url = isValidUrl(config.url)
    ? new URL(config.url)
    : new URL(`${config.baseURL}${config.url}`)
  const { params } = config

  const requestMethod =
    request instanceof FormData ? request.get('_method') : request._method

  const isBulk = url.pathname.includes('/bulk')
  const isDesignGallery = url.pathname.includes('designGallery')
  const isMenuMaker = url.pathname.includes('menu-maker')
  const isClone = url.pathname.includes('clone')
  const isApprove =
    url.pathname.includes('Approve') || url.pathname.includes('approve')

  let action = isBulk
    ? mediaDependencyTypes.ADD_MEDIA_TAGS_BULK
    : isDesignGallery
    ? mediaDependencyTypes.ADD_DESIGN_GALLERY
    : !meta
    ? mediaDependencyTypes.ADD_MENU_MAKER
    : mediaDependencyTypes.ADD_MEDIA

  if (method === 'delete') {
    action = isBulk
      ? mediaDependencyTypes.DELETE_MEDIA_BULK
      : mediaDependencyTypes.DELETE_MEDIA
  } else if (isClone) {
    action = mediaDependencyTypes.CLONE_MEDIA
  } else if (isApprove) {
    action = mediaDependencyTypes.APPROVE_MEDIA
  } else if (
    method === 'put' ||
    (requestMethod && requestMethod.toLowerCase() === 'put')
  ) {
    action = isDesignGallery
      ? mediaDependencyTypes.EDIT_DESIGN_GALLERY
      : isMenuMaker
      ? mediaDependencyTypes.EDIT_MENU_MAKER
      : mediaDependencyTypes.EDIT_MEDIA
  }

  return {
    type: action,
    id: isBulk ? getBulkIds(url, params) : getRequestId(url, isApprove ? 2 : 1)
  }
}

export const mediaDependencySourceResolver = data => {
  try {
    const { config, meta } = data
    const requestData = !config.data
      ? {}
      : config.data instanceof FormData
      ? config.data
      : JSON.parse(config.data)
    const { type, id } = getActionTypeByRequestSignature(
      config,
      requestData,
      meta
    )
    const requestParams = config.params
    return {
      type: type,
      id: id,
      body: requestData,
      params: requestParams,
      meta
    }
  } catch {
    return {
      type: null,
      id: null,
      body: {},
      params: {},
      meta: {}
    }
  }
}
export const mediaDependencyDataResolver = (error, { type }, t) => {
  const defaultContent = error && error.innerMessage ? error.innerMessage : ''

  if (!error) {
    return defaultContent
  }
  return _get(contentMap(t), [type, error.innerMessage], defaultContent)
}
