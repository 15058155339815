export function hasHTTPS(value) {
  return Boolean(value.match(/^(https:\/\/)/))
}

export function hasHTTPSOrHTTP(value) {
  return Boolean(value.match(/^(https:\/\/|http:\/\/)/))
}

export function completeUrl(value) {
  return value ? (hasHTTPSOrHTTP(value) ? value : `https://${value}`) : ''
}

export const isValidUrl = string => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const removeSearchParamsWithoutRefresh = newSearchParams => {
  window.history.pushState(
    null,
    '',
    `${window.location.pathname}${newSearchParams ? newSearchParams : ''}`
  )
}

export const getSearchParamsObject = search => {
  const paramsObj = {}
  const urlParams = new URLSearchParams(search)
  urlParams.forEach((value, key) => {
    paramsObj[key] = value
  })
  return paramsObj
}

export const getUnknownSearchParams = (
  searchParamsObject,
  initialFilterState
) => {
  const unknownParams = Object.entries(searchParamsObject)
    .filter(([key]) => !initialFilterState.hasOwnProperty(key))
    .map(([key, value]) => `${key}=${value}`)

  return unknownParams.length ? `?${unknownParams.join('&')}` : ''
}

export const dataURLFromURL = async src => {
  const blob = await fetch(src).then(r => r.blob())
  return await new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const getMediaCategoryUrlName = (categoryName = '') =>
  categoryName.toLowerCase().replaceAll(' ', '_')
