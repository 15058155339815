import { createAppApi } from 'services/api'
import { getApiParamsByOriginalUser } from 'utils/localStorage'

export const schedulesApi = createAppApi('schedulesQuery', {
  endpoints: builder => ({
    copySchedules: builder.mutation({
      query: (data = {}) => {
        const { impersonated, ...restData } = data
        return {
          url: '/schedule/bulkCopy',
          method: 'POST',
          data: restData,
          ...(impersonated && getApiParamsByOriginalUser('/schedule/bulkCopy'))
        }
      }
    })
  })
})

export const { useCopySchedulesMutation } = schedulesApi
