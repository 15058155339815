import io from 'socket.io-client'

import getToken from 'utils/getToken'
import { SOCKET_URL } from 'config/index'

export const SOCKET_CONNECT = 'connect'
export const SOCKET_AUTHENTICATE = 'authenticate'
export const SOCKET_HEALTH = 'health'
export const SOCKET_THUMBNAIL = 'thumbnail'
export const SOCKET_DISCONNECT = 'disconnect'
export const SOCKET_CONNECT_ERROR = 'connect_error'
export const SOCKET_MEDIA_PROCESSED = 'mediaProcessed'
export const SOCKET_DEVICE_META = 'meta'
export const SOCKET_DEVICE_TYPE_APP_VERSION_PROCESSED =
  'deviceTypeAppVersionProcessed'
export const SOCKET_NOC_ALARM = 'alarm'
export const SOCKET_MENU_BOARD_PROCESSED = 'menuBoardProcessed'
export const SOCKET_MENU_PRESET_PROCESSED = 'menuPresetProcessed'
export const MENU_PROVIDER_SYNC_STATUS = 'menuProviderSyncStatus'
export const SOCKET_AI_VIDEO_PROCESSED = 'aiVideoProcessed'

export const SOCKET_TIMEOUT = 15000

const socket = io(SOCKET_URL, {
  transports: ['websocket'],
  autoConnect: false
})

socket.on(SOCKET_CONNECT, () => {
  socket.emit(SOCKET_AUTHENTICATE, {
    token: getToken(false)
  })
})

socket.on(SOCKET_CONNECT_ERROR, error => {
  console.error('SOCKET CONNECT ERROR!', error)
})

socket.on(SOCKET_DISCONNECT, () => {
  if (getToken(false)) {
    console.error('SOCKET DISCONNECT!')
    socket.connect()
  }
})

export const publicPageSocket = token => {
  socket.off(SOCKET_CONNECT)

  if (token) {
    socket.on(SOCKET_CONNECT, () => {
      socket.emit(SOCKET_AUTHENTICATE, {
        token
      })
    })
  } else {
    socket.on(SOCKET_CONNECT, () => {
      socket.emit(SOCKET_AUTHENTICATE, {
        token: getToken(false)
      })
    })
  }
}

export const socketEmit = (eventName, eventData = {}) => {
  socket.emit(eventName, {
    token: getToken(false),
    ...eventData
  })
}

export default socket
