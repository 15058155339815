import moment from 'moment-timezone'

import { getTimezone } from 'utils/deviceUtils'

export const getDeviceTimeWithOffset = (device, t) => {
  if (!getTimezone(device)) {
    return ''
  }

  const { timezoneName } = device
  const timezone = getTimezone(device)

  let timezoneShortName = ''

  if (timezoneName && timezoneName.split(' ').length === 1) {
    timezoneShortName = timezoneName
  } else if (timezoneName) {
    timezoneShortName = timezoneName
      .split(' ')
      .reduce((acc, next) => (acc += next[0]), '')
  }

  return `${moment()
    .utcOffset(timezone)
    .format(t('Device time format'))} ${timezoneShortName}`
}
