import React, { useMemo, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { compose } from '@reduxjs/toolkit'
import { _filter } from 'utils/lodash'

import useUserRole from 'hooks/tableLibrary/useUserRole'
import {
  FormControlReactSelect,
  FormControlAutocomplete,
  FormControlSelectGroup,
  FormControlSelectTag,
  FormControlMediaTypeChips,
  FormControlAutocompleteQuery
} from 'components/Form'
import Footer from 'components/Filter/Footer'
import { getDeviceLocationViewOptions } from 'services/getOptions'
import { groupTitleRegExp } from 'constants/regExp'
import useUserDetails from 'hooks/useUserDetails'
import { entityGroupsConstants } from 'constants/index'
import useMediaFeatureOptions from 'hooks/options/useMediaFeatureOptions'
import { featuresToExclude } from 'constants/media'
import { useLazyDeviceListQuery } from 'api/deviceApi'

const styles = () => ({
  root: {
    padding: '25px 17px'
  },
  searchAction: {
    width: '90%'
  }
})

const statusOptions = t => [
  { label: t('Any'), value: 'any' },
  { label: t('Active'), value: 'Active' },
  { label: t('Inactive'), value: 'Inactive' },
  { label: t('Disabled'), value: 'Disabled' }
]

const DeviceFilter = ({
  values = {},
  onChange,
  onSubmit,
  onReset,
  t,
  classes,
  withoutGroups,
  hasLocationField,
  close
}) => {
  const role = useUserRole()
  const {
    client: { id }
  } = useUserDetails()

  const translations = useMemo(
    () => ({
      deviceType: t('Device Type'),
      locationView: t('Location View'),
      status: t('Device search status'),
      mediaFeature: t('Scheduled Media Types'),
      group: t('Groups'),
      tags: t('Tags')
    }),
    [t]
  )

  const { featureOptions, isFetching } = useMediaFeatureOptions(
    featuresToExclude
  )

  const getDeviceTypeOptionResponseParser = useCallback(
    data =>
      data?.map(({ deviceType }) => ({
        label: deviceType.name || deviceType.alias,
        value: deviceType.name
      })),
    []
  )

  const isOrgOrEnterprise = role.org || role.enterprise
  const getDeviceTypeParams = {
    fields: isOrgOrEnterprise ? 'deviceType,deviceTypeId' : 'name',
    sort: isOrgOrEnterprise ? 'deviceType' : 'name',
    order: 'asc',
    distinct: true,
    ...(!isOrgOrEnterprise && { client_id: id })
  }

  const handleSubmit = useCallback(() => {
    onSubmit()
    close()
  }, [onSubmit, close])

  const handleReset = useCallback(() => {
    onReset()
    close()
  }, [onReset, close])

  const getDeviceLocation = useCallback(
    async (value, limit) => await getDeviceLocationViewOptions(limit),
    []
  )

  return (
    <div className={classes.root}>
      {role.role.length > 0 && (
        <FormControlAutocompleteQuery
          fullWidth
          isClearable
          name="deviceType"
          role={role.role}
          label={translations.deviceType}
          values={values.deviceType}
          responseParser={getDeviceTypeOptionResponseParser}
          onChange={onChange}
          isCreatable
          staticRequestParams={getDeviceTypeParams}
          useLazyQuery={useLazyDeviceListQuery}
        />
      )}

      {hasLocationField && (
        <FormControlAutocomplete
          fullWidth
          name="deviceLocations"
          label={translations.locationView}
          value={values.deviceLocations}
          handleChange={onChange}
          getOptions={getDeviceLocation}
          isClearable
          isMulti
          hasDynamicChipsCreation={false}
        />
      )}

      <FormControlReactSelect
        fullWidth
        label={translations.status}
        options={
          role.org
            ? _filter(statusOptions(t), item => {
                return item.label !== 'Disabled'
              })
            : statusOptions(t)
        }
        name="status"
        value={values.status}
        handleChange={onChange}
        isClearable
        marginBottom={16}
      />
      <FormControlMediaTypeChips
        name="mediaFeature"
        options={featureOptions}
        values={values.mediaFeature}
        handleChange={onChange}
        label={translations.mediaFeature}
        isLoading={isFetching}
        isClearable
        fullWidth
      />
      {role.org && !withoutGroups && (
        <FormControlSelectGroup
          fullWidth
          isClearable
          name="group"
          label={translations.group}
          values={values.group}
          regExp={groupTitleRegExp}
          onChange={onChange}
          hasDynamicChipsCreation={false}
          entity={entityGroupsConstants.Device}
        />
      )}
      {role.org && (
        <FormControlSelectTag
          fullWidth
          name="tag"
          label={translations.tags}
          values={values.tag}
          onChange={onChange}
          isClearable
          hasDynamicChipsCreation={false}
          withPortal
        />
      )}

      <Footer onSubmit={handleSubmit} onReset={handleReset} />
    </div>
  )
}

DeviceFilter.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  values: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  memo
)(DeviceFilter)
