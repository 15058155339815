import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { FormControlAutocompleteNew } from './index'
import { getClientsOptions } from 'services/getOptionsWithMeta'
import { exactIdSearchRegExp } from 'constants/regExp'

const FormControlSelectClient = ({
  value,
  nameValue,
  idValue,
  getOptionsParams = {},
  resellerClients = false,
  onChange,
  hideClients = [],
  setClients,
  limit = 20,
  isCreatable = true,
  ...props
}) => {
  const handleChange = useCallback(
    ({ target }) => {
      const isExactSearch = exactIdSearchRegExp.test(target.value)
      onChange({
        target: {
          ...target,
          //trigger exact search
          __isNew__: isExactSearch ? false : target.__isNew__
        }
      })
    },
    [onChange]
  )

  return (
    <FormControlAutocompleteNew
      {...props}
      limit={limit}
      isCreatable={isCreatable}
      onChange={handleChange}
      value={idValue || nameValue || value}
      getOptions={getClientsOptions(getOptionsParams, resellerClients)}
      isClearable
      hasDynamicChipsCreation={false}
      initialFetchValue={idValue}
      hideOptions={hideClients}
      setClients={setClients}
    />
  )
}

FormControlSelectClient.propTypes = {
  nameValue: PropTypes.string,
  idValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limit: PropTypes.number,
  name: PropTypes.string.isRequired,
  isCreatable: PropTypes.bool,
  getOptionsParams: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default FormControlSelectClient
