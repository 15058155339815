import React from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core'

import useWhiteLabel from 'hooks/useWhiteLabel'
import LinkItem from './LinkItem'

const styles = ({
  fontSize,
  lineHeight,
  fontWeight,
  typography,
  type,
  colors
}) => ({
  mainFooter: {
    marginTop: 24,
    marginBottom: 70,
    padding: '0 8px'
  },
  mainFooterText: {
    transform: 'translate(0, 1.5px) scale(0.9)',
    color: colors.footer[type],
    verticalAlign: 'middle',
    fontSize: fontSize.small,
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.normal,
    '&:hover': {
      textShadow: `0 0 1px ${typography.lightText[type].color}`
    }
  },
  footerCopyright: {
    marginRight: 10,
    transform: 'unset',
    '&:hover': {
      textShadow: 'unset'
    }
  },
  footerRightContainer: {
    display: 'flex',
    alignItems: 'center'
  }
})

const Footer = ({ t, classes, dark }) => {
  const {
    privacyPolicy,
    termsCondition,
    aboutPage,
    copyrightText,
    footerLogo,
    darkFooterLogo
  } = useWhiteLabel()

  return (
    <footer className={classes.mainFooter}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <nav>
            <LinkItem
              {...privacyPolicy}
              linkText={t('Privacy Policy footer link')}
            />
            <LinkItem
              {...termsCondition}
              linkText={t('Term and Conditions footer link')}
            />
            <LinkItem {...aboutPage} linkText={t('About Mvix footer link')} />
          </nav>
        </Grid>
        <Grid item className={classes.footerRightContainer}>
          <Typography
            className={classNames(
              classes.mainFooterText,
              classes.footerCopyright
            )}
            component="p"
            variant="body1"
          >
            {copyrightText}
          </Typography>
          <img src={dark ? darkFooterLogo : footerLogo} alt="Footer Logo" />
        </Grid>
      </Grid>
    </footer>
  )
}

export default withTranslation('translations')(withStyles(styles)(Footer))
