import { memo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'

import {
  getDeviceMediaCapAlertAction,
  removeDeviceMediaCapAlertAction,
  clearRemoveDeviceMediaCapAlertInfoAction
} from 'actions/alertActions'
import useConfirmation from 'hooks/useConfirmation'

const RemoveCapAlert = ({
  t,
  capAlertName,
  deviceId,
  open = false,
  onClose = f => f,
  onSuccess = f => f,
  onFail = f => f,
  removeDeviceMediaCapAlertAction,
  getDeviceMediaCapAlertAction,
  removeDeviceMediaCapAlertReducer,
  clearRemoveDeviceMediaCapAlertInfoAction,
  isNew,
  setNewSelectedMedia
}) => {
  const handleDelete = useCallback(() => {
    if (isNew) {
      setNewSelectedMedia({})
      onClose()
    } else removeDeviceMediaCapAlertAction(deviceId)
  }, [
    isNew,
    removeDeviceMediaCapAlertAction,
    deviceId,
    setNewSelectedMedia,
    onClose
  ])

  const handleClose = useCallback(() => onClose(), [onClose])

  const { showConfirmation } = useConfirmation({
    onCancel: handleClose
  })

  useEffect(() => {
    open &&
      showConfirmation(
        t('remove_modal_text_interpolated', { item: capAlertName }),
        handleDelete
      )
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (removeDeviceMediaCapAlertReducer.response) {
      getDeviceMediaCapAlertAction(deviceId)
      clearRemoveDeviceMediaCapAlertInfoAction()
      onSuccess()
    } else if (removeDeviceMediaCapAlertReducer.error) {
      clearRemoveDeviceMediaCapAlertInfoAction()
      onFail()
    }
    // eslint-disable-next-line
  }, [removeDeviceMediaCapAlertReducer])

  return null
}

RemoveCapAlert.propTypes = {
  capAlertName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  deviceId: PropTypes.number,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
}

const mapStateToProps = ({ alert }) => ({
  removeDeviceMediaCapAlertReducer: alert.removeDeviceMediaCapAlert
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceMediaCapAlertAction,
      clearRemoveDeviceMediaCapAlertInfoAction,
      removeDeviceMediaCapAlertAction
    },
    dispatch
  )

export default memo(
  compose(
    withTranslation('translations'),
    connect(mapStateToProps, mapDispatchToProps)
  )(RemoveCapAlert)
)
