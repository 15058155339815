import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { getCRUDEndpoints } from '../utils/apiUtils'

export const groupsApi = createAppApi('groupsQuery', {
  tagTypes: [apiTags.group],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      name: 'Group',
      url: '/group',
      builder,
      tags: [apiTags.group]
    })
  })
})

export const {
  useLazyGetGroupQuery,
  useLazyGetGroupsQuery,
  useDeleteGroupMutation,
  usePutGroupMutation,
  usePostGroupMutation
} = groupsApi
