import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import {
  GET_OAUTH2_TOKENS_BY_FEATURE,
  GET_OAUTH2_TOKENS,
  POST_OAUTH2_TOKEN,
  DELETE_OAUTH2_TOKEN,
  getOauth2TokensByFeatureSuccess,
  getOauth2TokensByFeatureError,
  getOauth2TokensSuccess,
  getOauth2TokensError,
  postOauth2TokenSuccess,
  postOauth2TokenError,
  deleteOauth2TokenSuccess,
  deleteOauth2TokenError
} from 'actions/new/oauth2'
import { oauth2Service } from 'services'
import { showMediaAssociation } from '../../slices/oauth2Slice'

export default function* oauth2Watcher() {
  yield all([
    takeLatest(GET_OAUTH2_TOKENS, getOauth2TokensWorker),
    takeEvery(GET_OAUTH2_TOKENS_BY_FEATURE, getOauth2TokensWorker),
    takeLatest(POST_OAUTH2_TOKEN, postOauth2TokenWorker),
    takeLatest(DELETE_OAUTH2_TOKEN, deleteOauth2TokenWorker)
  ])
}

function* getOauth2TokensWorker({ payload: { oauth2Feature, params }, meta }) {
  try {
    const data = yield call(
      oauth2Service.getOauth2Tokens,
      oauth2Feature,
      params
    )
    yield put(
      oauth2Feature
        ? getOauth2TokensByFeatureSuccess(
            { response: data.data, meta: data.meta },
            meta
          )
        : getOauth2TokensSuccess({ response: data.data, meta: data.meta }, meta)
    )
  } catch (error) {
    yield put(
      oauth2Feature
        ? getOauth2TokensByFeatureError(error, meta)
        : getOauth2TokensError(error, meta)
    )
  }
}

function* postOauth2TokenWorker({
  payload: { oauth2Feature, code, name, isPrivate },
  meta
}) {
  try {
    const response = yield call(
      oauth2Service.postOauth2Token,
      oauth2Feature,
      isPrivate,
      code,
      name
    )
    yield put(
      postOauth2TokenSuccess({ status: 'success', data: response }, meta)
    )
    if (response?.tokenId && response?.mediaToAssociate?.length) {
      yield put(
        showMediaAssociation({
          ...response,
          feature: oauth2Feature
        })
      )
    }
  } catch (error) {
    yield put(postOauth2TokenError(error, meta))
  }
}

function* deleteOauth2TokenWorker({ payload }) {
  try {
    yield call(oauth2Service.deleteOauth2Token, payload)
    yield put(deleteOauth2TokenSuccess({ status: 'success', meta: payload }))
  } catch (error) {
    yield put(deleteOauth2TokenError(error))
  }
}
