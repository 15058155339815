import React, { useCallback, useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import SideModal from 'components/Modal/SideModal'
import { BlueButton, WhiteButton } from 'components/Buttons'
import { compose } from '@reduxjs/toolkit'
import { FormControlInput } from 'components/Form'
import { useFormik } from 'formik'
import { ipRegExp } from 'constants/regExp'
import {
  useDeleteSupportTokenAllowedIpMutation,
  useGetSupportTokenAllowedIpsQuery,
  usePostSupportTokenAllowedIpMutation,
  usePutSupportTokenAllowedIpMutation
} from 'api/supportToken'
import IPWhitelistController from 'components/IPWhitelistController'
import useSnackbar from 'hooks/useSnackbar'
import { useSelector } from 'react-redux'

const styles = () => ({
  actions: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: '10px'
  },
  addBtn: {
    marginTop: 7,
    width: 62
  },
  controlInput: {
    flexGrow: 2,
    marginRight: 10
  },
  controlSelect: {
    flexGrow: 1,
    marginRight: 10
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 30px',
    height: 'inherit'
  },
  wrapperControlElements: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectIcon: {
    marginRight: 10
  }
})

const IPWhitelist = ({ t, classes }) => {
  // API DEPRECATION

  const [fetchedPermissions] = useSelector(
    ({
      role: {
        permissionById: { response: permissionsResponse }
      }
    }) => {
      const fetchedPermissions =
        permissionsResponse && Object.keys(permissionsResponse).length > 0
          ? permissionsResponse?.filter(({ checkIp }) => checkIp)
          : null
      return [fetchedPermissions]
    }
  )

  const [postItem, postResult] = usePostSupportTokenAllowedIpMutation()
  const {
    data: { data: whitelistIPAddress = [] } = {},
    isFetching
  } = useGetSupportTokenAllowedIpsQuery({ limit: 9999 })

  const [updateItem, updateResult] = usePutSupportTokenAllowedIpMutation()
  const [deleteItem, deleteResult] = useDeleteSupportTokenAllowedIpMutation()
  const { showSnackbar } = useSnackbar()

  const [newIP, setNewIP] = useState('')
  const [error, setError] = useState('')
  const [whitelistIPList, setWhitelistIPList] = useState([])
  const handleChangeNewIP = useCallback(({ target: { value } }) => {
    setNewIP(value)
  }, [])

  useEffect(() => {
    const handleResult = (result, operation) => {
      const message = t('IP o Successfully', { operation })
      if (result?.status !== 'fulfilled') return
      if (result?.isSuccess) {
        showSnackbar(message, 'success')
      } else {
        showSnackbar(t('Oops.. Something went wrong'), 'error')
      }
      result.reset()
    }

    handleResult(postResult, 'Added')
    handleResult(deleteResult, 'Removed')
    handleResult(updateResult, 'Updated')
  }, [postResult, updateResult, deleteResult, showSnackbar, t])

  useEffect(() => {
    if (isFetching) return
    setWhitelistIPList(whitelistIPAddress)
  }, [isFetching, whitelistIPAddress])

  const initialValues = useRef({
    whitelistIP: ''
  })

  const form = useFormik({
    initialValues: initialValues.current,
    onSubmit: ({ whitelistIP }) => {
      postItem({
        permissionId: fetchedPermissions?.[0].id,
        title: `Title_${whitelistIP}`,
        ip: whitelistIP
      })
    }
  })

  const handleClickAdd = useCallback(() => {
    if (whitelistIPAddress.includes(newIP)) return setError('URL already exist')

    if (newIP && newIP.match(ipRegExp)) {
      form.setFieldValue('whitelistIP', newIP)
      setNewIP('')
      setError('')
      form.handleSubmit()
    } else {
      setError('Please enter valid IP Address')
    }
  }, [form, newIP, whitelistIPAddress])

  const handleReset = useCallback(() => {
    form.setValues(initialValues.current)
    setNewIP('')
    setError('')
  }, [form])

  return (
    <SideModal
      width="30%"
      title={t('IP Whitelist | Support Token')}
      closeLink={'/system/roles-permissions'}
      footerLayout={
        <div className={classes.actions}>
          <WhiteButton
            variant="danger"
            onClick={handleReset}
            iconClassName="fa-regular fa-circle-arrow-left"
          >
            {t('Reset')}
          </WhiteButton>
        </div>
      }
    >
      <div className={classes.wrapper}>
        <div className={classes.wrapperControlElements}>
          <FormControlInput
            label={t('IP Address')}
            error={error}
            touched
            formControlContainerClass={classes.controlInput}
            handleChange={handleChangeNewIP}
            value={newIP}
          />
          <BlueButton
            fullWidth
            className={classes.addBtn}
            onClick={handleClickAdd}
            disabled={false}
            iconClassName="fa-regular fa-circle-plus"
          >
            {t('Add')}
          </BlueButton>
        </div>

        <IPWhitelistController
          values={whitelistIPList}
          deleteItem={deleteItem}
          updateItem={updateItem}
        />
      </div>
    </SideModal>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(IPWhitelist)
