import {
  CLEAR_USERS_FILTER,
  USERS_FAILURE,
  USERS_FILTER_FAILURE,
  USERS_FILTER_PENDING,
  USERS_FILTER_SUCCESS,
  USERS_PENDING,
  USERS_SUCCESS
} from 'actions/new/search'

const initialState = {
  users: {
    isPending: false,
    isSuccess: false,
    isFailure: false,
    data: [],
    meta: {
      limit: 0,
      total: 0
    },
    isFilters: false,
    filters: {
      isPending: false,
      value: '',
      data: [],
      meta: {
        limit: 10,
        total: 0
      }
    }
  }
}

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case USERS_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          isPending: true,
          meta: {
            ...state.users.meta,
            limit: action.limit
          }
        }
      }
    case USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          isPending: false,
          isSuccess: true,
          data: action.data,
          meta: {
            ...state.users.meta,
            total: action.meta.total,
            limit: action.meta.count
          }
        }
      }
    case USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isPending: false,
          isSuccess: true
        }
      }
    case USERS_FILTER_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          isFilters: true,
          filters: {
            ...state.users.filters,
            isPending: true,
            value: action.firstName,
            meta: {
              ...state.users.filters.meta,
              limit: action.limit
            }
          }
        }
      }
    case USERS_FILTER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          filters: {
            ...state.users.filters,
            isPending: false,
            data: action.data,
            meta: {
              ...state.users.filters.meta,
              total: action.meta.total,
              limit: action.meta.count
            }
          }
        }
      }
    case USERS_FILTER_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isPending: false
        }
      }
    case CLEAR_USERS_FILTER:
      return {
        ...state,
        users: {
          ...state.users,
          isFilters: false,
          filters: initialState.users.filters
        }
      }
    default:
      return state
  }
}
