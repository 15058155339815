import React, { forwardRef, useCallback } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Card } from 'components/Card'
import Spacing from 'components/Containers/Spacing'
import Scrollbars from 'components/Scrollbars'
import DashboardCardBottomText from './DashboardCardBottomText'

const useStyles = makeStyles(({ typography, type, palette }) => ({
  cardWrapper: {
    width: '100%'
  },
  cardRoot: {
    paddingTop: 15,
    paddingBottom: 0
  },
  cardHeader: {
    marginBottom: 13
  },
  cardHeaderText: {
    ...typography.dashboardTitle[type]
  },
  contentRoot: {
    border: '5px solid ' + palette[type].card.background,
    borderRightWidth: '4px',
    backgroundColor: palette[type].tableLibrary.body.row.background,
    flex: 1
  },
  contentWrap: {
    padding: '15px 22px'
  }
}))

const DashboardCardBase = forwardRef(
  (
    {
      title,
      titleComponent,
      headerTextClasses = [],
      children,
      scrollbarRootClassName,
      removeSidePaddings = false,
      removeScrollbar = false,
      contentWrapClassName,
      rootClassName,
      headerClasses = [],
      iconButtonComponent,
      cardWrapperClassName,
      contentRootClassName,
      bottomMessageComponent: BottomMessageComponent = DashboardCardBottomText,
      bottomMessageText,
      ...props
    },
    ref
  ) => {
    const classes = useStyles()

    const ApplyScrollbar = useCallback(
      ({ children: _children }) =>
        removeScrollbar ? (
          _children
        ) : (
          <Scrollbars className={scrollbarRootClassName}>
            {_children}
          </Scrollbars>
        ),
      [removeScrollbar, scrollbarRootClassName]
    )

    return (
      <Grid
        item
        className={classNames(classes.cardWrapper, cardWrapperClassName)}
      >
        <Card
          removeSidePaddings
          title={title}
          headerClasses={[classes.cardHeader, ...headerClasses]}
          headerTextClasses={[classes.cardHeaderText, ...headerTextClasses]}
          dropdown={false}
          titleComponent={titleComponent}
          rootClassName={classNames(classes.cardRoot, rootClassName)}
          icon={!!iconButtonComponent}
          iconButtonComponent={iconButtonComponent}
          {...props}
        >
          <Spacing
            variant={0}
            rootClassName={classNames(
              classes.contentRoot,
              contentRootClassName
            )}
            ref={ref}
          >
            <ApplyScrollbar>
              <Spacing
                variant={0}
                rootClassName={classNames(
                  {
                    [classes.contentWrap]: !removeSidePaddings
                  },
                  contentWrapClassName
                )}
              >
                {children}
              </Spacing>
            </ApplyScrollbar>
            {bottomMessageText && (
              <BottomMessageComponent text={bottomMessageText} />
            )}
          </Spacing>
        </Card>
      </Grid>
    )
  }
)

DashboardCardBase.propTypes = {
  bottomMessageComponent: PropTypes.elementType,
  bottomMessageText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default DashboardCardBase
