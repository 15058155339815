import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { Text } from 'components/Typography'
import {
  Logo,
  PageWrapper,
  CodeText,
  ShadowedErrorText,
  ErrorSubDescription
} from './components'
import { ReactComponent as TimeoutImage } from './assets/timeoutError.svg'

const useStyles = makeStyles(() => ({
  image: {
    position: 'absolute',
    top: '15%'
  },
  logoImageWrapper: {
    position: 'absolute',
    top: '12%'
  },
  errorTextWrapper: {
    position: 'absolute',
    top: '65%',
    minHeight: 242
  },
  text: {
    fontFamily: 'Montserrat'
  },
  errorText: {
    fontSize: '56px',
    lineHeight: '68px',
    fontWeight: 300
  },
  errorDescriptionText: {
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'center',
    maxWidth: '70%'
  }
}))

const TimeoutErrorPage = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <Grid
        container
        item
        justifyContent="center"
        className={classes.logoImageWrapper}
      >
        <Logo />
      </Grid>
      <CodeText />
      <TimeoutImage className={classes.image} />
      <Grid
        container
        item
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.errorTextWrapper}
      >
        <Grid container item justifyContent="center">
          <ShadowedErrorText>{t('Time Out')}</ShadowedErrorText>
          <Text
            rootClassName={classNames(classes.errorText, classes.text)}
            color="white"
          >
            {t('Error')}
          </Text>
        </Grid>
        <Text
          rootClassName={classNames(classes.errorDescriptionText, classes.text)}
          color="white"
        >
          {t(
            "We're sorry, it seems our system is experiencing a timeout issue. Please refresh the page or try accessing it again later. Our team is actively investigating and working to resolve this. Thank you for your patience."
          )}
        </Text>
        <ErrorSubDescription />
      </Grid>
    </PageWrapper>
  )
}

export default TimeoutErrorPage
