import api from 'services/api'
import { errorHandler, queryParamsHelper } from 'utils'
import sortConstants from 'constants/sortConstants'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { conflictForceTypes } from 'constants/api'

const getItems = async (params, silent) => {
  try {
    const response = await api({
      method: 'GET',
      url: '/device',
      params: queryParamsHelper(
        {
          ...params,
          ...(!!params.dormant && { status: null }),
          ...(!!params.firmware && { exact: 1 })
        },
        ['group', 'tag'],
        [],
        ['mediaFeature', 'deviceTypeId']
      ),
      silent
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceLocationCategories = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/deviceLocation/categories'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getItem = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

// Add device is not implemented on backend yet
const addDeviceLibraryItem = async request => {
  try {
    const { deviceId } = request

    const response = await api({
      method: 'POST',
      url: `/device/${deviceId}`
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}
const deleteItem = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/device/${id}`
    })
    return response.data
  } catch (error) {
    throw new Error(error)
  }
}
const setDeviceLibraryItem = async request => {
  try {
    const { deviceId } = request

    const response = await api({
      method: 'PUT',
      url: `/device/${deviceId}`
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const deleteDeviceGroupItems = async request => {
  try {
    const { groupId, deviceId } = request

    const response = await api({
      method: 'DELETE',
      url: `/device/group/${groupId}/${deviceId}`
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const getDeviceReboot = async request => {
  try {
    const { deviceId } = request

    const response = await api({
      method: 'GET',
      url: `/device/${deviceId}/reboot`
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const getPreference = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/preference',
      params: {
        entity: 'DeviceLibrary'
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putPreference = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/preference/DeviceLibrary',
      data: {
        recordsPerPage: 10,
        gridColumn: data
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGroups = async (data, silent) => {
  try {
    const response = await api(
      {
        method: 'GET',
        url: '/group',
        params: {
          ...sortConstants.Group,
          entity: 'device',
          ...data
        }
      },
      silent
    )

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putItem = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${data.id}`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putItemImpersonated = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${data.id}/impersonated`,
      data: data.data,
      ...getApiParamsByOriginalUser(`/device/${data.id}/impersonated`)
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postItem = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getPreview = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/device/${id}/preview`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGroupItems = async (id, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/group/${id}`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postGroupItem = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/group/${data.groupId}`,
      data: {
        deviceId: data.deviceId,
        ...(data[conflictForceTypes.confirmAutoAddDeviceToSchedule] && {
          [conflictForceTypes.confirmAutoAddDeviceToSchedule]: 1
        }),
        ...(data[conflictForceTypes.forceAssociateDevices] && {
          [conflictForceTypes.forceAssociateDevices]: 1
        })
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteGroupItem = async data => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/device/group/${data.groupId}/${data.deviceId}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getReboot = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/reboot`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putReboot = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${data.id}/reboot`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putPeriodicalReboot = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `device/${data.id}/periodicalReboot`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getScreenPowerSettings = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/screenPower`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putScreenPowerSettings = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `device/${data.deviceId}/screenPower`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putBulkReboot = async ({ ...data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/bulkReboot`,
      ...data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putBulkPeriodicalReboot = async ({ ...data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/bulkPeriodicalReboot`,
      ...data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceNotes = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/note`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postDeviceNote = async (id, data) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/${id}/note`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

async function getCapAlertDevices() {
  try {
    const response = await api({
      method: 'GET',
      url: '/capAlert/device'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function getDevicePreview(params) {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/device/preview',
      params: queryParamsHelper(params, ['tag', 'group'])
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceTypes = async (params, silent, isOrg) => {
  try {
    const response = await api({
      method: 'GET',
      url: `${isOrg ? '/config/device/type' : '/deviceType'}`,
      params,
      silent
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceType = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/deviceType/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putDeviceType = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/deviceType/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postDeviceType = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/deviceType`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceModels = async (params, silent, isOrg) => {
  try {
    const response = await api({
      method: 'GET',
      url: `${isOrg ? '/config/device/model' : '/deviceModel'}`,
      params,
      silent
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceModel = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/deviceModel/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putDeviceModel = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/deviceModel/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postDeviceModel = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/deviceModel`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAppVersions = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/appVersion`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getReleaseAppVersions = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/releaseAppVersions`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAppVersion = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/appVersion/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putAppVersion = async ({ id, data, method }) => {
  try {
    const response = await api({
      method: method || 'PUT',
      url: `/device/appVersion/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postAppVersion = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/appVersion`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteAppVersion = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/device/appVersion/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postAssignAppVersion = async ({ isSuperAdmin, ...data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: isSuperAdmin
        ? `/device/assignAppVersion`
        : `/device/applyAppVersion`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postAssignAppVersionToDeviceType = async ({
  appVersionId,
  deviceTypeId
}) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/deviceType/${deviceTypeId}/assignAppVersion`,
      data: { appVersionId }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function getDeviceHealth(id) {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/device/${id}/health`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getOfflineNotificationDevices = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/device/offlineNotification`,
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getOfflineNotificationDeviceItem = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/device/offlineNotification/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postOfflineNotificationDevice = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/offlineNotification`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteOfflineNotificationDevice = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/device/offlineNotification/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postBulkUpdateOfflineNotificationDevices = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/offlineNotification/bulkUpdate`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postBulkCreateOfflineNotificationDevices = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/offlineNotification/bulkCreate`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function triggerDeviceHealth(id) {
  try {
    await api({
      method: 'GET',
      url: `/device/${id}/health/triggerDevice`
    })

    return true
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function triggerDevicePreview(id) {
  try {
    await api({
      method: 'GET',
      url: `/device/${id}/preview/triggerDevice`
    })

    return true
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function getPreviewThumbnail(id) {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/preview`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postDeviceBulk = async ({ ids, data }) => {
  try {
    await api({
      method: 'POST',
      url: `/device/bulk?ids=${ids}`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDeviceLogs = async (id, params, logType) => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/device/${id}/errorLog/${logType}`,
      params
    })
    return data
  } catch (e) {
    throw errorHandler(e)
  }
}

const cloneDeviceItem = async (deviceId, payload) => {
  try {
    const { data } = await api({
      method: 'POST',
      url: `/device/clone`,
      data: {
        deviceId,
        ...payload
      }
    })
    return data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDeviceUpdatesAvailable = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/dashboard/deviceUpdateAvailable`
    })
    return data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDeviceResolutions = async () => {
  try {
    return await api({
      method: 'GET',
      url: '/config/device/screenResolution'
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDeviceLocations = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/device/location`,
      params
    })
    return data
  } catch (e) {
    throw errorHandler(e)
  }
}

const postDeviceLocation = async data => {
  try {
    await api({
      method: 'POST',
      url: '/device/location',
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const putDeviceLocation = async (data, id) => {
  try {
    await api({
      method: 'PUT',
      url: `/device/location/${id}`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const deleteDeviceLocation = async id => {
  try {
    await api({
      method: 'DELETE',
      url: `/device/location/${id}`
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDevicesByLocation = async (id, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/location/${id}/recursiveDevices`,
      params: { ...params, ...(!!params.dormant && { status: null }) }
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDevicesWithoutLocation = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/withoutLocation`,
      params: { ...params, ...(!!params.dormant && { status: null }) }
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const updateScheduleDevice = async (deviceId, data) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${deviceId}/updateSchedule`,
      data
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getScheduledDeviceIds = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/deviceIdsHasSchedule`,
      params
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDeviceCecSettings = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/cec`
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const putDeviceCecSettings = async (id, data) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${id}/cec`,
      data
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDeviceNetworkConfig = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/network`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putDeviceNetworkConfig = async ({ id, data }) => {
  try {
    await api({
      method: 'PUT',
      url: `/device/${id}/network`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getVirtualPlayerStats = async ({ id, params }) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/virtualPlayerStats`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postIpCIDR = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/device/ipCidr',
      data
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const putIpCIDR = async ({ id, ...data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/ipCidr/${id}`,
      data
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const deleteIpCIDR = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/device/ipCidr/${id}`
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getIpCIDR = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/device/ipCidr',
      params
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getDeviceDependencyPackages = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: `/config/package/version`
    })

    return response
  } catch (error) {
    throw new Error(error)
  }
}

const getDeviceGlobalConfig = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/deviceGlobalConfig`
    })

    return response
  } catch (error) {
    throw errorHandler(error)
  }
}

const putDeviceGlobalConfig = async data => {
  try {
    await api({
      method: 'PUT',
      url: `/deviceGlobalConfig`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const featureDeviceAssociate = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/feature/device/associate',
      data
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

const getPowerSchedule = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/powerSchedule`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putPowerSchedule = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${data.id}/powerSchedule`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deviceService = {
  getItem,
  getItems,
  getDeviceLocationCategories,
  deleteItem,
  getDeviceReboot,
  addDeviceLibraryItem,
  setDeviceLibraryItem,
  deleteDeviceGroupItems,
  getPreference,
  putPreference,
  getGroups,
  putItem,
  putItemImpersonated,
  postItem,
  getPreview,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  getReboot,
  putReboot,
  putPeriodicalReboot,
  getScreenPowerSettings,
  putScreenPowerSettings,
  putBulkReboot,
  putBulkPeriodicalReboot,
  getDeviceNotes,
  postDeviceNote,
  getCapAlertDevices,
  getDeviceTypes,
  getDeviceType,
  putDeviceType,
  postDeviceType,
  getDeviceModels,
  getDeviceModel,
  putDeviceModel,
  postDeviceModel,
  getAppVersions,
  getReleaseAppVersions,
  getAppVersion,
  putAppVersion,
  postAppVersion,
  postAssignAppVersionToDeviceType,
  deleteAppVersion,
  postAssignAppVersion,
  getDeviceHealth,
  getOfflineNotificationDevices,
  getOfflineNotificationDeviceItem,
  postOfflineNotificationDevice,
  deleteOfflineNotificationDevice,
  postBulkUpdateOfflineNotificationDevices,
  postBulkCreateOfflineNotificationDevices,
  postDeviceBulk,
  getDeviceLogs,
  cloneDeviceItem,
  getDeviceUpdatesAvailable,
  updateScheduleDevice,
  getDeviceResolutions,
  getDeviceLocations,
  postDeviceLocation,
  putDeviceLocation,
  deleteDeviceLocation,
  getDevicesByLocation,
  getDevicesWithoutLocation,
  getScheduledDeviceIds,
  getDeviceCecSettings,
  putDeviceCecSettings,
  getDeviceNetworkConfig,
  putDeviceNetworkConfig,
  getVirtualPlayerStats,
  postIpCIDR,
  putIpCIDR,
  deleteIpCIDR,
  getIpCIDR,
  getDeviceDependencyPackages,
  getDeviceGlobalConfig,
  putDeviceGlobalConfig,
  featureDeviceAssociate,
  getPowerSchedule,
  putPowerSchedule
}

export default deviceService
