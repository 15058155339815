import React, { useEffect, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { withStyles, Typography, Link, Grid } from '@material-ui/core'
import classNames from 'classnames'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { FormControlInput } from 'components/Form'
import { FabBlueButton } from 'components/Buttons'
import { emailField, requiredField } from 'constants/validationMessages'
import { ORG_ROLE, SYSTEM_ROLE } from 'constants/api'
import useSnackbar from 'hooks/useSnackbar'
import { useRecoveryMutation } from 'api/authenticationApi'

const styles = theme => {
  const { palette, type } = theme
  return {
    form: {
      width: '569px',
      padding: '0 65px'
    },
    formTitle: {
      fontSize: '30px',
      fontWeight: 'bold',
      marginBottom: '15px',
      textAlign: 'center',
      color: palette[type].pages.singIn.color
    },
    formSubTitle: {
      marginBottom: 50,
      color: palette[type].pages.singIn.color,
      textAlign: 'center'
    },
    formSubTitleWithSuccessResponse: {
      marginBottom: 95
    },
    formSuccessMessage: {
      fontSize: '20px',
      marginBottom: '15px',
      textAlign: 'center',
      color: palette[type].pages.singIn.color
    },
    inputWrap: {
      marginBottom: 10
    },
    formControlInput: {
      height: '50px',
      fontSize: '18px'
    },
    formControlContainer: {
      marginBottom: '10px'
    },
    actionLink: {
      marginTop: '30px',
      width: '100%',
      borderRadius: 4,
      height: 40,
      paddingLeft: 15,
      paddingRight: 15
    },
    backToText: {
      padding: '40px 0 20px',
      color: '#888996',
      textAlign: 'center'
    },
    backToTextWithSuccessResponse: {
      padding: '65px 0 170px'
    },
    backToLink: {
      color: '#0076b9'
    },
    reminderMessage: {
      textAlign: 'center',
      padding: '50px 0',
      fontStyle: 'italic',
      fontSize: 14,
      color: theme.colors.light
    },
    actionsWrap: {
      paddingBottom: 80
    }
  }
}

function RecoveryForm({ t, classes, match }) {
  const { showSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [recovery, recoveryResult] = useRecoveryMutation()

  const form = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(requiredField).email(emailField)
    }),
    enableReinitialize: true,
    onSubmit: async ({ email }) => {
      if (!executeRecaptcha) {
        showSnackbar(t('Recaptcha is not yet available'), 'error')
        return
      }

      try {
        const recaptchaToken = await executeRecaptcha('resetPassword')

        recovery({
          role:
            match.path === '/system/forgot-password' ? SYSTEM_ROLE : ORG_ROLE,
          email,
          recaptchaToken
        })
      } catch (e) {
        console.error(e)
      }
    }
  })

  useEffect(
    () => {
      form.validateForm()
    },
    //eslint-disable-next-line
    []
  )

  const signInPath =
    match.path === '/system/forgot-password' ? '/system/sign-in' : '/sign-in'

  useEffect(() => {
    return () => {
      recoveryResult.reset()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(
    () => {
      if (recoveryResult.isError) {
        const { error } = recoveryResult
        if (error) {
          form.setFieldError('email', ' ')
          form.setSubmitting(false)
          showSnackbar(
            error.code === 404
              ? t(
                  'Your password cannot be reset. Either there is no such account or this account is disabled or inactive.'
                )
              : error.message,
            'error'
          )
        }
      }
    },
    // eslint-disable-next-line
    [recoveryResult]
  )

  useEffect(
    () => {
      if (recoveryResult.isSuccess) {
        form.setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [recoveryResult]
  )

  const backToSignInSection = useMemo(
    () => (
      <Typography
        className={classNames(classes.backToText, {
          [classes.backToTextWithSuccessResponse]: recoveryResult.isSuccess
        })}
      >
        {t('Back to')}{' '}
        <Link
          to={signInPath}
          component={RouterLink}
          className={classes.backToLink}
        >
          {t('Sign In')}
        </Link>
      </Typography>
    ),
    [signInPath, recoveryResult, classes, t]
  )

  return (
    <form className={classes.form} onSubmit={form.handleSubmit}>
      <header>
        <Typography className={classes.formTitle} gutterBottom>
          {t('Forgot your password title')}
        </Typography>
        <Typography
          className={classNames(classes.formSubTitle, {
            [classes.formSubTitleWithSuccessResponse]: recoveryResult.isSuccess
          })}
        >
          {t('Forgot your password subtitle')}
        </Typography>
      </header>
      {recoveryResult.isSuccess ? (
        <>
          <Typography className={classes.formSuccessMessage} gutterBottom>
            {recoveryResult.data?.message ||
              t(
                'You will receive a password reset email at email if you’ve registered your account with that email address.',
                { email: recoveryResult.originalArgs?.email }
              )}
          </Typography>
          <Grid item xs={12}>
            {backToSignInSection}
          </Grid>
        </>
      ) : (
        <>
          <div className={classes.inputWrap}>
            <FormControlInput
              id="email"
              type="text"
              value={form.values.email}
              handleChange={form.handleChange}
              error={form.errors.email}
              handleBlur={form.handleBlur}
              touched={form.touched.email}
              placeholder={t('Email')}
              formControlInputClass={classes.formControlInput}
              formControlContainerClass={classes.formControlContainer}
              fullWidth
            />
          </div>
          <Grid container className={classes.actionsWrap}>
            <Grid item xs={6}>
              <FabBlueButton
                className={classes.actionLink}
                variant="extended"
                type="submit"
                opaque={!form.isValid}
                disabled={form.isSubmitting}
              >
                {t('Request reset link action')}
              </FabBlueButton>
            </Grid>
            <Grid item xs={6}>
              {backToSignInSection}
            </Grid>
          </Grid>
          <Grid container>
            <Typography className={classes.reminderMessage}>
              {t(
                'If you do not receive within the next few minutes, please be sure to check your spam / junk folder'
              )}
            </Typography>
          </Grid>
        </>
      )}
    </form>
  )
}

export default withTranslation('translations')(withStyles(styles)(RecoveryForm))
