import useSnackbar from '../useSnackbar'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { userDetailsSelector } from 'selectors/userSelectors'
import { useEffect, useState } from 'react'
import { getOriginalUser } from 'utils/localStorage'

export default function usePasswordReminder() {
  const { response } = useSelector(userDetailsSelector)
  const { showSnackbar } = useSnackbar()
  const { t } = useTranslation('translations')
  const [isShowed, setStatus] = useState(false)

  useEffect(() => {
    if (!response || isShowed) return
    const {
      passwordReminder: { alert, daysUntilPasswordExpires }
    } = response

    if (alert && !getOriginalUser()) {
      if (daysUntilPasswordExpires > 0) {
        showSnackbar(
          t('Password will expire', { days: daysUntilPasswordExpires }),
          'warning'
        )
      } else {
        showSnackbar(t('Password was expire'), 'warning')
      }

      setStatus(true)
    }
    // eslint-disable-next-line
  }, [response])
}
