import React from 'react'
import { Typography } from '@material-ui/core'

import { TextSizeTooltip } from 'utils'
import LegacyIndicator from '../DeviceLibrary/LegacyIndicator'
import NoteIndicator from 'components/NoteIndicator'

const DeviceNameViewCell = ({ row, role, classes, notes }) => {
  return role.org ? (
    <>
      <div className={classes.aliasWrap}>
        <TextSizeTooltip title={row.alias} maxTitleWidth={23} />{' '}
        {row.browserVersion && (
          <LegacyIndicator browserVersion={row.browserVersion} />
        )}
      </div>
      <Typography className={classes.label}>
        <TextSizeTooltip title={row.name} maxTitleWidth={25} />
      </Typography>
    </>
  ) : (
    <>
      <div className={classes.deviceNameWrap}>
        <TextSizeTooltip
          title={row.name}
          width={role.system && notes > 0 ? 160 : 150}
          maxTitleWidth={role.system && notes > 0 ? 24 : 23}
        />
        {role.system && notes > 0 && <NoteIndicator notes={notes} />}
      </div>
      <Typography className={classes.label}>
        <TextSizeTooltip title={row.alias} maxTitleWidth={23} />
      </Typography>
    </>
  )
}

export default DeviceNameViewCell
