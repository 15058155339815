import api from './api'

import { errorHandler } from '../utils'

const getInfo = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/dashboard',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putInfo = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/dashboard',
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getNewsInfo = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/dashboard/news'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getSupportInfo = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/dashboard/support'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}
const getDashboardDeviceLocations = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/dashboard/deviceLocations'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getWeatherInfo = async lang => {
  try {
    const response = await api({
      method: 'GET',
      url: `/dashboard/weather/${lang}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const dashboardService = {
  getInfo,
  putInfo,
  getNewsInfo,
  getSupportInfo,
  getDashboardDeviceLocations,
  getWeatherInfo
}

export default dashboardService
