import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from '../constants/apiTags'

export const workplacePosterApi = createAppApi('workplacePoster', {
  tagTypes: [apiTags.workplacePoster, apiTags.workplacePosterTags],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      tags: [apiTags.workplacePoster],
      name: 'WorkplacePoster',
      url: '/workplacePoster'
    }),
    ...getCRUDEndpoints({
      builder,
      tags: [apiTags.workplacePosterTags],
      name: 'WorkplacePosterTag',
      url: '/workplacePosterTag'
    })
  })
})

export const {
  useLazyGetWorkplacePosterQuery,
  useLazyGetWorkplacePostersQuery,
  usePutWorkplacePosterMutation,
  usePostWorkplacePosterMutation,
  useDeleteWorkplacePosterMutation,
  useLazyGetWorkplacePosterTagsQuery,
  useDeleteWorkplacePosterTagMutation,
  usePostWorkplacePosterTagMutation
} = workplacePosterApi
