import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Typography, withStyles } from '@material-ui/core'
import { _difference, _get, _capitalize } from 'utils/lodash'
import { compose } from '@reduxjs/toolkit'
import { withSnackbar } from 'notistack'
import { useCustomSnackbar } from 'hooks'
import { useLocation } from 'react-router'

import { postMediaBulk, clearPostMediaBulkInfo } from 'actions/mediaActions'
import { postDeviceBulk, clearPostDeviceBulkInfo } from 'actions/deviceActions'
import { FormControlSelectGroup, FormControlSelectTag } from 'components/Form'
import { MessageWithList } from 'components/ToastNotificationMessages'
import { BlueButton, WhiteButton } from 'components/Buttons'
import ConflictsModal from 'components/Modal/ConflictsModal'
import useNotifyAnalyzer from 'hooks/tableLibrary/useNotifyAnalyzer'
import useConfirmation from 'hooks/useConfirmation'
import {
  conflictForceTypes,
  conflictSources,
  conflictTypes
} from 'constants/api'
import { serviceEntities } from 'services/getOptionsWithMeta'
import { entityGroupsConstants } from '../../../constants'
import {
  TAG,
  TAGS,
  GROUP,
  GROUPS,
  TAG_AND_GROUP,
  TAG_AND_GROUPS,
  TAGS_AND_GROUP,
  TAGS_AND_GROUPS
} from 'constants/tagsAndGroupsContants'
import { librariesBulkEditArrayIds, libraryTypes } from 'constants/library'
import { isHandledConflict } from 'utils/deviceUtils'
import BulkEditPopupContent from './BulkEditPopupContent'
import { clearPostUserBulkInfo, postUserBulk } from 'actions/userActions'
import {
  clearPostPlaylistBulkInfo,
  postPlaylistBulk
} from 'actions/playlistActions'
import {
  clearPostTemplateBulkInfo,
  postTemplateBulk
} from 'actions/templateActions'
import {
  clearPostScheduleBulkInfo,
  postScheduleBulk
} from 'actions/scheduleActions'
import {
  clearPostMenuMakerItemBulkInfo,
  postMenuMakerItemBulk
} from 'actions/menuMakerActions'
import { clearPostClientBulkInfo, postClientBulk } from 'actions/clientActions'
import { getCommonBulkData } from 'utils/getPreparedDataUtils'
import { getGroupTagHideOptions } from 'utils/generalUtils'

const style = ({ palette, type }) => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: palette[type].dialog.title
  },
  reset: {
    marginRight: '12px'
  },
  iconColor: {
    fontSize: '12px'
  }
})

const entitiesActions = [
  {
    name: serviceEntities.media,
    clearPostBulkInfo: clearPostMediaBulkInfo,
    postBulk: postMediaBulk
  },
  {
    name: serviceEntities.device,
    clearPostBulkInfo: clearPostDeviceBulkInfo,
    postBulk: postDeviceBulk
  },
  {
    name: serviceEntities.user,
    clearPostBulkInfo: clearPostUserBulkInfo,
    postBulk: postUserBulk
  },
  {
    name: serviceEntities.client,
    clearPostBulkInfo: clearPostClientBulkInfo,
    postBulk: postClientBulk
  },
  {
    name: serviceEntities.playlist,
    clearPostBulkInfo: clearPostPlaylistBulkInfo,
    postBulk: postPlaylistBulk
  },
  {
    name: serviceEntities.template,
    clearPostBulkInfo: clearPostTemplateBulkInfo,
    postBulk: postTemplateBulk
  },
  {
    name: serviceEntities.schedule,
    clearPostBulkInfo: clearPostScheduleBulkInfo,
    postBulk: postScheduleBulk
  },
  {
    name: serviceEntities.menuItem,
    clearPostBulkInfo: clearPostMenuMakerItemBulkInfo,
    postBulk: postMenuMakerItemBulk
  }
]

const payloadIdsNaming = {
  [serviceEntities.media]: 'mediaIds',
  [serviceEntities.device]: 'ids',
  [serviceEntities.user]: 'userIds',
  [serviceEntities.playlist]: 'playlistIds',
  [serviceEntities.template]: 'templateIds',
  [serviceEntities.schedule]: 'scheduleIds',
  [serviceEntities.menuItem]: 'menuItemIds',
  [serviceEntities.client]: 'clientIds'
}

const AddTagsAndGroupsPopupContent = props => {
  const {
    fetcher = f => f,
    data = [],
    selectedIds = [],
    setSelected,
    clearSelected,
    close = f => f,
    classes,
    t,
    meta = {},
    enqueueSnackbar,
    closeSnackbar,
    type = libraryTypes.media,
    storeType = '',
    storePath = '',
    hideGroupEntity = false
  } = props

  const selectedItems = useMemo(() => {
    return data.filter(({ id }) => selectedIds.includes(id))
  }, [data, selectedIds])

  const [commonTags, commonGroups] = useMemo(() => {
    const commonIds = [[], []]

    if (selectedItems.length) {
      ;['tag', 'group'].forEach((property, idx) => {
        const firstItemTags = selectedItems[0][property]
        firstItemTags?.forEach(({ id, title }) => {
          if (
            selectedItems.every(item =>
              item[property]?.find(tag => tag.id === id || tag.title === title)
            )
          ) {
            commonIds[idx].push({ label: title, value: id })
          }
        })
      })
    }

    return commonIds
  }, [selectedItems])

  const dispatch = useDispatch()
  const location = useLocation()
  const showSnackbar = useCustomSnackbar(t, enqueueSnackbar, closeSnackbar)
  const { showConfirmation } = useConfirmation({
    confirmButtonText: t('Yes'),
    cancelButtonText: t('No')
  })
  const postBulkReducer = useSelector(store => {
    if (storeType) {
      return store[storeType].postBulk
    }
    return !!storePath ? store[storePath][type].postBulk : store[type].postBulk
  })
  const entityActions = useMemo(() => {
    return entitiesActions.find(({ name }) => name === type) || {}
  }, [type])

  const [defaultSelectedData, setDefaultSelectedData] = useState({})
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [initialLocation] = useState(location)
  const [activeForces, setActiveForces] = useState({})

  const notifyKeyword = useMemo(() => {
    const tl = selectedTags.length
    const gl = selectedGroups.length
    if (tl && !gl) return tl === 1 ? TAG : TAGS
    if (!tl && gl) return gl === 1 ? GROUP : GROUPS
    if (tl && gl) {
      if (tl === 1 && gl === 1) {
        return TAG_AND_GROUP
      } else if (tl === 1) {
        return TAG_AND_GROUPS
      } else if (gl === 1) {
        return TAGS_AND_GROUP
      }
    }
    return TAGS_AND_GROUPS
  }, [selectedTags, selectedGroups])

  const hideErrorNotification = useMemo(
    () => isHandledConflict(postBulkReducer),
    [postBulkReducer]
  )

  useNotifyAnalyzer(
    fetcher,
    entityActions.clearPostBulkInfo,
    enqueueSnackbar,
    closeSnackbar,
    notifyKeyword,
    [postBulkReducer],
    {},
    f => f,
    {
      page: meta.currentPage || 1
    },
    hideErrorNotification
  )

  useEffect(() => {
    if (_get(postBulkReducer.response, 'status') === 'success') {
      setActiveForces({})
      if (clearSelected) {
        clearSelected()
      }
      if (setSelected) {
        setSelected([])
      }
      close()
    }
    dispatch(entityActions.clearPostBulkInfo())
    // eslint-disable-next-line
  }, [postBulkReducer.response])

  useEffect(() => {
    const { code, message } = postBulkReducer.error
    if (code !== 409 && message) {
      showSnackbar(message, 'error')
    }
    dispatch(entityActions.clearPostBulkInfo())
    // eslint-disable-next-line
  }, [postBulkReducer.error])

  useEffect(
    () => {
      if (initialLocation !== location) {
        setActiveForces({})
        close()
      }
    },
    // eslint-disable-next-line
    [location]
  )

  useEffect(() => {
    setActiveForces({})
  }, [selectedTags, selectedGroups])

  const handleChange = useCallback(({ target: { name, value } }) => {
    name === 'tags' ? setSelectedTags(value) : setSelectedGroups(value)
  }, [])

  const onReset = useCallback(() => {
    const { tags, groups } = defaultSelectedData

    setSelectedTags(tags)
    setSelectedGroups(groups)
  }, [defaultSelectedData])

  const emptySelection = useMemo(
    () => selectedTags.length === 0 && selectedGroups.length === 0,
    [selectedTags, selectedGroups]
  )

  const prepareData = useCallback(selectedItems => {
    return selectedItems.map(({ label, value }) => ({
      id: value,
      title: label
    }))
  }, [])

  const [detachTagIds, detachGroupIds, hasDetachedItems] = useMemo(() => {
    const tagIds = _difference(defaultSelectedData.tags, selectedTags).map(
      ({ value }) => value
    )
    const groupIds = _difference(
      defaultSelectedData.groups,
      selectedGroups
    ).map(({ value }) => value)
    const hasDetachedItems = tagIds.length || groupIds.length

    return [tagIds, groupIds, hasDetachedItems]
  }, [defaultSelectedData, selectedTags, selectedGroups])

  const onSubmit = useCallback(
    ({
      forceAssociateDevices = false,
      confirmAutoAddDeviceToSchedule = false
    }) => {
      if (emptySelection && !hasDetachedItems) {
        showSnackbar('Please select at least one tag or group', 'warning')
        return
      }

      const dispatchPostAction = ids => {
        dispatch(
          entityActions.postBulk({
            [payloadIdsNaming[type]]: librariesBulkEditArrayIds.includes(type)
              ? ids || selectedIds
              : (ids || selectedIds).join(','),
            data: {
              ...(selectedTags.length && { tag: prepareData(selectedTags) }),
              ...(selectedGroups.length && {
                group: prepareData(selectedGroups)
              }),
              ...(detachTagIds.length && { detachTagIds }),
              ...(detachGroupIds.length && { detachGroupIds }),
              ...(forceAssociateDevices && { forceAssociateDevices }),
              ...(confirmAutoAddDeviceToSchedule && {
                confirmAutoAddDeviceToSchedule
              })
            }
          })
        )
      }

      if (type === libraryTypes.media) {
        const disabledFeatureMediaList = selectedItems.filter(
          ({ feature }) => feature.status === 'Inactive'
        )

        if (disabledFeatureMediaList?.length) {
          if (disabledFeatureMediaList?.length === selectedItems?.length) {
            showSnackbar(
              t('Feature is not enabled for all selected Media'),
              'warning'
            )
          } else {
            showConfirmation(
              <MessageWithList
                message={t(
                  'Bulk edit media feature not enabled confirmation message'
                )}
                list={disabledFeatureMediaList.map(({ title }) => title)}
              />,
              () => {
                const validMediaIds = selectedItems
                  .filter(({ feature }) => feature.status !== 'Inactive')
                  .map(({ id }) => id)
                dispatchPostAction(validMediaIds)
              }
            )
          }
          return
        }
      }

      dispatchPostAction()
    },
    [
      t,
      dispatch,
      entityActions,
      selectedTags,
      selectedGroups,
      selectedIds,
      emptySelection,
      prepareData,
      showSnackbar,
      showConfirmation,
      selectedItems,
      detachTagIds,
      detachGroupIds,
      hasDetachedItems,
      type
    ]
  )

  const groupEntity = useMemo(() => {
    const entity = _capitalize(type)
    return hideGroupEntity ? null : entityGroupsConstants[entity]
  }, [type, hideGroupEntity])

  const submitConflictConfirm = useCallback(
    ({ forceType }) => {
      onSubmit({
        ...activeForces,
        [forceType]: true
      })
      setActiveForces(prevState => ({
        ...prevState,
        [forceType]: true
      }))
    },
    [onSubmit, activeForces]
  )

  const handleConflictConfirm = useCallback(
    ({ conflictType }) => {
      if (type === 'device') {
        if (
          conflictType === conflictTypes.confirmAutoAddDeviceToScheduleException
        ) {
          submitConflictConfirm({
            forceType: conflictForceTypes.confirmAutoAddDeviceToSchedule
          })
        } else if (conflictType === conflictTypes.deviceHasSchedule) {
          submitConflictConfirm({
            forceType: conflictForceTypes.forceAssociateDevices
          })
        }
      }
    },
    [submitConflictConfirm, type]
  )

  const headerContent = useMemo(() => {
    return (
      <Typography className={classes.title} component="h2">
        {groupEntity ? t('Set Tags & Groups') : t('Set Tags')}
      </Typography>
    )
  }, [classes.title, groupEntity, t])

  const content = useMemo(() => {
    const [hideTagOptions, hideGroupOptions] = getGroupTagHideOptions({
      defaultSelectedData,
      selectedGroups,
      selectedTags,
      commonGroups,
      commonTags
    })

    return (
      <>
        <FormControlSelectTag
          name="tags"
          label={t('Tags')}
          values={selectedTags}
          onChange={handleChange}
          hideOptionsStrict={hideTagOptions}
          isClearable
          withPortal
        />
        {groupEntity && (
          <FormControlSelectGroup
            hasWritePermission
            name="groups"
            label={t('Groups')}
            values={selectedGroups}
            entity={groupEntity}
            onChange={handleChange}
            hideOptionsStrict={hideGroupOptions}
            isClearable
            withPortal
          />
        )}
      </>
    )
  }, [
    defaultSelectedData,
    commonGroups,
    commonTags,
    groupEntity,
    handleChange,
    selectedGroups,
    selectedTags,
    t
  ])

  const footerContent = useMemo(() => {
    return (
      <>
        <WhiteButton
          className={classes.reset}
          onClick={onReset}
          classes={{ iconColor: classes.iconColor }}
          iconClassName="fa-regular fa-circle-arrow-left"
          variant="danger"
        >
          {t('Reset')}
        </WhiteButton>
        <BlueButton
          opaque={emptySelection && !hasDetachedItems}
          classes={{ iconColor: classes.iconColor }}
          iconClassName="fa-regular fa-circle-plus fa-xs"
          onClick={onSubmit}
        >
          {t('Set')}
        </BlueButton>
      </>
    )
  }, [classes, emptySelection, hasDetachedItems, onReset, onSubmit, t])

  useEffect(() => {
    const { tags, groups } = getCommonBulkData({ data, selectedIds })
    setSelectedTags(tags)
    setSelectedGroups(groups)
    setDefaultSelectedData({ tags, groups })
  }, [data, selectedIds])

  return (
    <>
      <BulkEditPopupContent
        headerContent={headerContent}
        content={content}
        footerContent={footerContent}
        contentMaxHeight={265}
      />
      <ConflictsModal
        conflictSource={conflictSources.bulkTagsAssign}
        errors={[postBulkReducer.error]}
        onConfirm={handleConflictConfirm}
      />
    </>
  )
}

AddTagsAndGroupsPopupContent.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  close: PropTypes.func
}

export default compose(
  withTranslation('translations'),
  withSnackbar,
  withStyles(style)
)(AddTagsAndGroupsPopupContent)
