import React, { useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { LocalOffer } from '@material-ui/icons'

import { withStyles, Grid, Paper, makeStyles } from '@material-ui/core'

import { Checkbox } from '../Checkboxes'
import { TableLibraryRowActionButton } from '../TableLibrary'

import PropTypes from 'prop-types'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import { getUrlPrefix } from 'utils/permissionUrls'
import { routeByName } from 'constants/index'
import { getDeleteConfirmationMessage } from 'utils/generalUtils'
import { swapTagData } from 'utils/tagUtils'
import { defaultTag } from 'constants/chipsColorPalette'
import { TextWithTooltip } from 'components/Typography'
import { tagToChipObj } from 'utils/select'
import { storageGetItem } from '../../utils/localStorage'
import { LIBRARY_VIEW_SETTINGS } from 'constants/localStorage'
import entityConstants from 'constants/entityConstants'

const styles = theme => {
  const { palette, type, fontSize, lineHeight, fontWeight } = theme
  return {
    root: {
      padding: 10,
      margin: '5px 10px',
      borderRadius: 6,
      borderLeft: '5px solid #3983ff',
      border: `1px solid ${palette[type].tagCard.border}`,
      backgroundColor: palette[type].tagCard.background,
      boxShadow: `0 2px 4px 0 ${palette[type].tagCard.shadow}`
    },
    checkboxWrap: {
      marginRight: 20
    },
    label: {
      fontSize: fontSize.primary,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight.primary,
      color: palette[type].tagCard.label.color
    },
    itemCount: {
      width: '37px',
      marginRight: '35px',
      fontSize: '14px',
      lineHeight: '24px',
      textAlign: 'center',
      color: palette[type].tagCard.item.color,
      backgroundColor: palette[type].tagCard.item.background
    },
    actionBtn: {
      minWidth: '61px',
      paddingLeft: '10px',
      paddingRight: '10px',
      color: palette[type].tagCard.button.color,
      boxShadow: '0 1px 0 0 rgba(216, 222, 234, 0.5)',

      '&:hover': {
        borderColor: '#1c5dca',
        backgroundColor: '#1c5dca',
        color: '#fff'
      }
    },
    actionBtnIcon: {
      width: 18,
      height: 18
    },
    iconLocalOffer: {
      color: palette[type].buttons.iconButton.color,
      cursor: 'pointer',
      marginRight: '26px',
      '&:hover': {
        color: '#1C5DCA'
      }
    }
  }
}

const dynamicStyles = makeStyles({
  actionValue: ({ max }) => ({
    width: 5 + max * 7
  })
})

const TagCard = ({
  t,
  tag,
  classes,
  selected,
  onToggleSelect,
  onDelete,
  permissions,
  showConfirmation
}) => {
  const handleDeleteClick = useCallback(() => {
    showConfirmation(getDeleteConfirmationMessage(tag.tag, t), () => {
      onDelete(tag)
    })
  }, [showConfirmation, tag, onDelete, t])

  const handleClickSelect = useCallback(() => {
    onToggleSelect(tag.id)
  }, [tag.id, onToggleSelect])

  const maxDigitValue = useMemo(
    () =>
      Math.max(
        ...(Object.values(tag.report).map(v => v.toString().length) || [])
      ),
    [tag.report]
  )

  const dynamicClasses = dynamicStyles({
    max: maxDigitValue
  })

  const renderReportList = useMemo(() => {
    if (tag.report === undefined) return null
    const viewSettings = JSON.parse(storageGetItem(LIBRARY_VIEW_SETTINGS))

    const parsedTag = tagToChipObj(tag)

    return (
      <Grid item>
        <TableLibraryRowActionButton
          actionLinks={[
            {
              label: t('Device', { count: tag.report.device || 0 }),
              to: `${getUrlPrefix('device-library')}/${
                viewSettings?.[entityConstants.DeviceLibrary] ?? 'list'
              }`,
              data: (tag.report.device || tag.report.device === 0) && {
                tag: parsedTag
              },
              value: tag.report.device || 0
            },
            { divider: true },
            {
              label: t('User', { count: tag.report.user || 0 }),
              to: getUrlPrefix('users-library'),
              data: (tag.report.user || tag.report.user === 0) && {
                tag: parsedTag
              },
              value: tag.report.user || 0
            },
            { divider: true },
            {
              label: t('Media', { count: tag.report.media || 0 }),
              to: getUrlPrefix(
                routeByName.media[
                  `${viewSettings?.[entityConstants.MediaLibrary] ?? 'root'}`
                ]
              ),
              data: (tag.report.media || tag.report.media === 0) && {
                tag: parsedTag
              },
              value: tag.report.media || 0
            },
            { divider: true },
            {
              label: t('Playlist', { count: tag.report.playlist || 0 }),
              to: getUrlPrefix(routeByName.playlist.root),
              data: (tag.report.playlist || tag.report.playlist === 0) && {
                tag: parsedTag
              },
              value: tag.report.playlist || 0
            },
            { divider: true },
            {
              label: t('Template', { count: tag.report.template || 0 }),
              to: getUrlPrefix(
                routeByName.template[
                  `${viewSettings?.[entityConstants.TemplateLibrary] ?? 'list'}`
                ]
              ),
              data: (tag.report.template || tag.report.template === 0) && {
                tag: parsedTag
              },
              value: tag.report.template || 0
            },
            { divider: true },
            {
              label: t('Schedule', { count: tag.report.schedule || 0 }),
              to: getUrlPrefix(
                routeByName.schedule[
                  `${viewSettings?.[entityConstants.ScheduleLibrary] ?? 'list'}`
                ]
              ),
              data: (tag.report.schedule || tag.report.schedule === 0) && {
                tag: parsedTag
              },
              value: tag.report.schedule || 0
            }
          ]}
          trigger={<LocalOffer className={classes.iconLocalOffer} />}
          activeValueClass={dynamicClasses.actionValue}
        />
      </Grid>
    )
  }, [classes, tag, t, dynamicClasses])

  const cardStyle = useMemo(
    () => ({
      borderLeftColor:
        swapTagData(tag).backgroundColor || defaultTag.backgroundColor
    }),
    [tag]
  )

  return (
    <Paper className={classes.root} style={cardStyle}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <Grid
              item
              className={classes.checkboxWrap}
              onClick={handleClickSelect}
            >
              <Checkbox checked={selected} />
            </Grid>
            <Grid item>
              <TextWithTooltip rootClassName={classes.label} maxWidth={100}>
                {tag.tag}
              </TextWithTooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            {renderReportList}
            <Grid item>
              <TableLibraryRowActionButton
                actionLinks={[
                  {
                    label: t('Edit action'),
                    to: `/${getUserRoleLevel()}/tags-library/${tag.id}/edit`,
                    data: { tag },
                    render: permissions.update && !tag.isAutoTag
                  },
                  { divider: true },
                  {
                    label: t('Delete Tag action'),
                    icon: 'fa-regular fa-trash-can',
                    clickAction: handleDeleteClick,
                    render: permissions.delete
                  }
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

TagCard.propTypes = {
  tag: PropTypes.object.isRequired
}

export default withTranslation('translations')(
  withStyles(styles)(withSnackbar(TagCard))
)
