import { call, put } from 'redux-saga/effects'

import * as types from '../actions'

import { scheduleService } from '../services'
import { transformMeta } from 'utils/tableUtils'
import { approveContent } from 'services/approveService'

function* postSchedule(action) {
  try {
    yield call(scheduleService.postSchedule, action.data)
    yield put({
      type: types.POST_SCHEDULE_SUCCESS,
      payload: { status: 'successfully' },
      meta: action.meta
    })
  } catch (error) {
    yield put({
      type: types.POST_SCHEDULE_ERROR,
      payload: error,
      meta: action.meta
    })
  }
}

function* getSchedule(action) {
  try {
    const response = yield call(scheduleService.getSchedule, action.data)
    yield put({
      type: types.GET_SCHEDULE_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_SCHEDULE_BY_ID_ERROR, payload: error })
  }
}

function* editSchedule(action) {
  try {
    yield call(scheduleService.editSchedule, {
      id: action.meta.id,
      data: action.data
    })
    yield put({
      type: types.PUT_SCHEDULE_SUCCESS,
      payload: { status: 'successfully', name: action.meta.name }
    })
  } catch (error) {
    yield put({ type: types.PUT_SCHEDULE_ERROR, payload: error })
  }
}

function* getItems({ params, storeFetchParams, isLibrary }) {
  try {
    const { data, meta } = yield call(scheduleService.getItems, params)
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_SCHEDULE_ITEMS_SUCCESS,
      payload: {
        response: data,
        params,
        modifiedMeta,
        storeFetchParams
      },
      isLibrary
    })
  } catch (error) {
    yield put({
      type: types.GET_SCHEDULE_ITEMS_ERROR,
      payload: error,
      isLibrary
    })
  }
}

function* getListSchedule({ params }) {
  try {
    const { data, meta } = yield call(scheduleService.getItems, params)
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_SCHEDULE_LISTS_SUCCESS,
      payload: {
        response: data,
        params,
        modifiedMeta
      }
    })
  } catch (error) {
    yield put({ type: types.GET_SCHEDULE_LISTS_ERROR, payload: error })
  }
}

function* getTimeline({ params }) {
  try {
    const { data, meta } = yield call(scheduleService.getTimeline, params)
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_SCHEDULE_TIMELINE_SUCCESS,
      payload: {
        data,
        params,
        meta: modifiedMeta
      }
    })
  } catch (error) {
    yield put({ type: types.GET_SCHEDULE_TIMELINE_ERROR, payload: error })
  }
}

function* getPreference() {
  try {
    const response = yield call(scheduleService.getPreference)
    yield put({
      type: types.GET_SCHEDULE_PREFERENCE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_SCHEDULE_PREFERENCE_ERROR, payload: error })
  }
}

function* putPreference(action) {
  try {
    yield call(scheduleService.putPreference, action.payload)
    yield put({ type: types.GET_SCHEDULE_PREFERENCE })
  } catch (error) {
    yield put({ type: types.PUT_SCHEDULE_PREFERENCE_ERROR, payload: error })
  }
}

function* getGroupItems(action) {
  try {
    const response = yield call(
      scheduleService.getGroupItems,
      action.payload.id,
      action.payload.params
    )
    yield put({
      type: types.GET_SCHEDULE_GROUP_ITEMS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_SCHEDULE_GROUP_ITEMS_ERROR, payload: error })
  }
}

function* postGroupItem(action) {
  try {
    const response = yield call(scheduleService.postGroupItem, action.payload)
    yield put({
      type: types.POST_SCHEDULE_GROUP_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.POST_SCHEDULE_GROUP_ITEM_ERROR, payload: error })
  }
}

function* deleteGroupItem(action) {
  try {
    const response = yield call(scheduleService.deleteGroupItem, action.payload)
    yield put({
      type: types.DELETE_SCHEDULE_GROUP_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.DELETE_SCHEDULE_GROUP_ITEM_ERROR, payload: error })
  }
}

function* cloneSchedule({ data }) {
  try {
    yield call(scheduleService.cloneSchedule, data)
    yield put({
      type: types.CLONE_SCHEDULE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.CLONE_SCHEDULE_ERROR, payload: error })
  }
}

function* copySchedule({ data }) {
  try {
    yield call(scheduleService.copySchedule, data)
    yield put({
      type: types.COPY_SCHEDULE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.COPY_SCHEDULE_ERROR, payload: error })
  }
}

function* deleteSelectedSchedules({ ids }) {
  try {
    yield call(scheduleService.deleteSelectedSchedules, ids)
    yield put({
      type: types.DELETE_SELECTED_SCHEDULES_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_SCHEDULES_ERROR,
      payload: error
    })
  }
}

function* deleteScheduleById({ id }) {
  try {
    yield call(scheduleService.deleteScheduleById, id)
    yield put({
      type: types.DELETE_SCHEDULE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SCHEDULE_ERROR,
      payload: error
    })
  }
}

function* approveSchedule({ id }) {
  try {
    yield call(approveContent, 'schedule', id)
    yield put({
      type: types.APPROVE_SCHEDULE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.APPROVE_SCHEDULE_ERROR,
      payload: error
    })
  }
}

function* getSchedulePreview(action) {
  try {
    const response = yield call(scheduleService.getPreview, action.data.id)
    yield put({
      type: types.GET_SCHEDULE_PREVIEW_SUCCESS,
      payload: response,
      meta: action.meta
    })
  } catch (error) {
    yield put({ type: types.GET_SCHEDULE_PREVIEW_ERROR, payload: error })
  }
}

function* generateSchedulePreview(action) {
  try {
    const response = yield call(scheduleService.generatePreview, action.data)
    yield put({
      type: types.GENERATE_SCHEDULE_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GENERATE_SCHEDULE_PREVIEW_ERROR, payload: error })
  }
}

function* getGroupSchedules({ params }) {
  try {
    const { data, meta } = yield call(scheduleService.getItems, params)
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_SCHEDULE_GROUP_SCHEDULES_SUCCESS,
      payload: {
        response: data,
        params: params,
        meta: modifiedMeta
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_SCHEDULE_GROUP_SCHEDULES_ERROR,
      payload: error
    })
  }
}

function* assignDevicesSchedule(action) {
  try {
    yield call(scheduleService.assignDevicesSchedule, {
      id: action.meta.id,
      data: action.data
    })
    yield put({ type: types.ASSIGN_DEVICES_SCHEDULE_SUCCESS })
  } catch (error) {
    yield put({ type: types.ASSIGN_DEVICES_SCHEDULE_ERROR, payload: error })
  }
}

function* getAssignableSchedule() {
  try {
    const { data } = yield call(scheduleService.getItems, { limit: 1 })

    yield put({
      type: types.GET_ASSIGNABLE_SCHEDULE_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_ASSIGNABLE_SCHEDULE_ERROR,
      payload: error
    })
  }
}

function* patchScheduleItem({ meta: { id, name }, data }) {
  try {
    yield call(scheduleService.patchScheduleItem, id, data, true)

    yield put({
      type: types.PATCH_SCHEDULE_ITEM_SUCCESS,
      payload: { status: 'successfully', name }
    })
  } catch (error) {
    yield put({
      type: types.PATCH_SCHEDULE_ITEM_ERROR,
      payload: error
    })
  }
}

function* cloneScheduleTimeline({ id, data: { title, deviceList, ...rest } }) {
  try {
    const { id: clonedId } = yield call(scheduleService.cloneSchedule, {
      id,
      scheduleId: id,
      title
    })
    yield call(scheduleService.assignDevicesSchedule, {
      id: clonedId,
      data: {
        deviceList
      }
    })

    yield call(scheduleService.patchScheduleItem, clonedId, rest)

    yield put({
      type: types.CLONE_SCHEDULE_TIMELINE_SUCCESS,
      payload: { status: 'successfully' }
    })
  } catch (error) {
    yield put({
      type: types.CLONE_SCHEDULE_TIMELINE_ERROR,
      payload: error
    })
  }
}

function* getCalendarSchedules({ payload }) {
  try {
    const response = yield call(scheduleService.getCalendarSchedules, payload)

    yield put({
      type: types.GET_CALENDAR_SCHEDULES_SUCCESS,
      payload: { response }
    })
  } catch (error) {
    yield put({ type: types.GET_CALENDAR_SCHEDULES_ERROR, payload: error })
  }
}

function* postScheduleBulk({ payload }) {
  try {
    yield call(scheduleService.postScheduleBulk, payload)
    yield put({
      type: types.POST_SCHEDULE_BULK_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_SCHEDULE_BULK_ERROR, payload: error })
  }
}

const scheduleSaga = {
  getTimeline,
  approveSchedule,
  postSchedule,
  getSchedule,
  editSchedule,
  getItems,
  getListSchedule,
  getPreference,
  putPreference,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  cloneSchedule,
  copySchedule,
  deleteSelectedSchedules,
  deleteScheduleById,
  getSchedulePreview,
  generateSchedulePreview,
  getGroupSchedules,
  assignDevicesSchedule,
  getAssignableSchedule,
  patchScheduleItem,
  cloneScheduleTimeline,
  getCalendarSchedules,
  postScheduleBulk
}

export default scheduleSaga
