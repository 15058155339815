import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientSettingsSelector } from 'selectors/new/client'
import { fetchClientSettings } from 'actions/new/client'
import { getFeature, getSsoSetting } from 'utils/object'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import { getUserDetailsAction } from 'actions/userActions'
import { isFeatureAvailable } from 'utils/api/featureAvailability'

//allowed for AccountSettings page only
export default function useClientSettings(noAutoFetch = false) {
  const dispatch = useDispatch()
  const userRole = useUserRole()
  const { isSuccess, isPending, data } = useSelector(clientSettingsSelector)

  useEffect(() => {
    if (
      !isSuccess &&
      !isPending &&
      (userRole.org || userRole.enterprise) &&
      !noAutoFetch
    ) {
      dispatch(fetchClientSettings())
    }
    // eslint-disable-next-line
  }, [isSuccess, isPending, userRole, dispatch])

  const updateClientSettings = useCallback(() => {
    dispatch(fetchClientSettings())
    dispatch(getUserDetailsAction())
  }, [dispatch])

  return useMemo(
    () => ({
      isPending,
      data: {
        ...data,
        feature: (data.feature || []).filter(feature =>
          isFeatureAvailable(feature.name)
        )
      },
      ssoSetting: getSsoSetting(data),
      feature: getFeature(data),
      updateClientSettings
    }),
    [isPending, data, updateClientSettings]
  )
}
