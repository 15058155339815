import { useEffect } from 'react'

import socket from 'services/socket'
import {
  storageGetItem,
  storageRemoveItem,
  storageSetItem
} from 'utils/localStorage'
import { REBOOT_KEY } from 'constants/reboot'

function useDeviceReboot() {
  useEffect(() => {
    socket.on('reboot', ({ data: { deviceId } }) => {
      const storageData = storageGetItem(REBOOT_KEY)
      const rebootRequestData = storageData ? JSON.parse(storageData) : []
      const filteredDevices = rebootRequestData.filter(
        device => device.deviceId !== deviceId
      )
      if (filteredDevices.length) {
        storageSetItem(REBOOT_KEY, JSON.stringify(filteredDevices))
      } else {
        storageRemoveItem(REBOOT_KEY)
      }
    }) // eslint-disable-next-line
  }, [])
}

export default useDeviceReboot
