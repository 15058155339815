import * as types from './index'

const postData = (data, role) => ({
  type: types.POST_FEEDBACK,
  payload: data,
  role
})

const clearPostData = () => ({ type: types.CLEAR_POST_FEEDBACK })

export { postData, clearPostData }
