import React, { useMemo } from 'react'
import { Table, TableBody, withStyles } from '@material-ui/core'

import { TableLibraryHead } from 'components/TableLibrary'
import Scrollbars from 'components/Scrollbars'
import MessageCard from 'components/Card/MessageCard'
import ScheduleSimpleTableRow from 'components/CommonRows/ScheduleSimpleTableRow'

const styles = ({ type, colors }) => ({
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  }
})

const initialColumns = [
  { id: 'title', label: 'Title' },
  { id: 'workingDays', label: 'Days', align: 'center' },
  { id: 'time', label: 'Time', align: 'center' }
]

const columnWidth = {
  title: 200,
  workingDays: 300,
  time: 150
}

const EntityHasAssignedScheduleConflictContent = ({ error, classes }) => {
  const schedules = useMemo(() => error?.data || [], [error])

  return (
    <Scrollbars autoHeight autoHeightMax="calc(100vh - 190px)">
      <div>
        {error.innerMessage && (
          <MessageCard
            icon="fa-regular fa-circle-info"
            message={error.innerMessage}
            marginBottom
          />
        )}
        <Table className={classes.table}>
          <TableLibraryHead
            noType={true}
            editRows={false}
            disableSort
            rowCount={1}
            columns={initialColumns}
            columnWidth={columnWidth}
            headerCellClassName={classes.tableHeaderCell}
          />
          <TableBody>
            {schedules.map(schedule => (
              <ScheduleSimpleTableRow key={schedule.id} data={schedule} />
            ))}
          </TableBody>
        </Table>
      </div>
    </Scrollbars>
  )
}

export default withStyles(styles)(EntityHasAssignedScheduleConflictContent)
