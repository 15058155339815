import React, {
  Fragment,
  useCallback,
  useMemo,
  useState,
  memo,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Grid from '@material-ui/core/es/Grid'
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip'
import Popover from '@material-ui/core/es/Popover/Popover'
import { ChromePicker } from 'react-color'
import withStyles from '@material-ui/core/styles/withStyles'
import { rgbaObjectToString } from 'utils/color'

function styles({ palette, type, typography, colors }) {
  return {
    root: {
      width: 'fit-content',
      height: 15,
      cursor: 'default',
      position: 'relative',
      border: `1px solid ${palette[type].formControls.input.border}`
    },
    rootEditable: {
      cursor: 'pointer'
    },
    rootSelect: {
      margin: '4px 24px',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: -20,
        transform: 'translate(0, -50%)',
        height: 14,
        width: 14,
        border: '6px solid #535d73',
        borderRadius: '50%',
        background: '#d3d6dd',
        cursor: 'pointer'
      },
      '&:hover': {
        '&:before': {
          borderColor: '#41cb71'
        }
      }
    },
    rootSelectActive: {
      '&:before': {
        borderColor: '#41cb71'
      }
    },
    section: {
      width: 32,
      height: '100%',
      borderRight: `1px solid ${palette[type].formControls.input.border}`,
      '&:last-child': {
        borderRight: 'none'
      }
    },
    popoverRoot: {
      zIndex: '1400 !important'
    },
    popoverPaper: {
      borderRadius: 4,
      transform: 'translateY(5px) !important',
      padding: 5,
      background: colors.background.primary[type],

      '& .chrome-picker': {
        boxShadow: 'none !important',
        width: '200px !important',
        background: `${colors.background.primary[type]} !important`,
        '& > div > div': {
          borderRadius: 3,
          '& svg': {
            fill: `${palette[type].formControls.input.color} !important`,
            background: 'transparent !important'
          },
          '& > div > div > div ': {
            '& > input': {
              fontSize: '13px !important',
              lineHeight: '22px',
              fontFamily: typography.fontFamily,
              backgroundColor: palette[type].formControls.input.background,
              border: `1px solid ${palette[type].formControls.input.border} !important`,
              color: `${palette[type].formControls.input.color} !important`,
              boxShadow: 'none !important',
              textTransform: 'lowercase',
              height: '32px !important'
            },
            '& > span': {
              fontSize: '12px !important',
              color: `${palette[type].formControls.input.color}  !important`,
              fontFamily: typography.fontFamily
            }
          }
        }
      }
    }
  }
}

function ColorSection({
  name,
  tooltip,
  value,
  editable,
  onChange,
  onChangeComplete,
  customSection,
  classes
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = useCallback(
    ({ currentTarget }) => {
      setAnchorEl(currentTarget)
    },
    [setAnchorEl]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleChange = useCallback(
    ({ rgb }) => {
      onChange(name, rgb)
    },
    [onChange, name]
  )

  const handleChangeComplete = useCallback(
    ({ rgb }) => {
      onChangeComplete(name, rgb)
    },
    [onChangeComplete, name]
  )

  const open = useMemo(() => Boolean(anchorEl), [anchorEl])

  if (!editable) {
    return (
      <Tooltip arrow title={tooltip}>
        <div
          className={classNames(classes.section, customSection)}
          style={{ backgroundColor: value }}
        />
      </Tooltip>
    )
  }

  return (
    <Fragment>
      <Tooltip arrow title={tooltip}>
        <div
          className={classNames(classes.section, customSection)}
          style={{ backgroundColor: value }}
          onClick={handleOpen}
        />
      </Tooltip>
      <Popover
        id={name}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popoverPaper
        }}
      >
        <ChromePicker
          color={value}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
        />
      </Popover>
    </Fragment>
  )
}

const ColorSectionMemoized = memo(ColorSection)

function updatePaletteObject(palette, name, value) {
  return {
    ...palette,
    [name]: {
      ...palette[name],
      value
    }
  }
}

function FormControlPalette({
  editable,
  selected,
  classes,
  id = 0,
  index = 0,
  palette = {},
  onChange = f => f,
  onSelect = f => f,
  customSection = ''
}) {
  const [colors, setColors] = useState(palette)

  const handleChangeComplete = useCallback(
    (name, newValue) => {
      onChange({
        id,
        palette: updatePaletteObject(
          palette,
          name,
          rgbaObjectToString(newValue)
        )
      })
    },
    [onChange, palette, id]
  )

  const handleChange = useCallback(
    (name, newValue) => {
      setColors(prevState => {
        return updatePaletteObject(
          prevState,
          name,
          rgbaObjectToString(newValue)
        )
      })
    },
    [setColors]
  )

  const handleSelect = useCallback(() => {
    if (!editable && onSelect) {
      onSelect(
        {
          id,
          palette
        },
        index
      )
    }
  }, [onSelect, id, palette, index, editable])

  useEffect(() => {
    setColors(palette)
  }, [palette])

  return (
    <Grid
      container
      className={classNames(classes.root, {
        [classes.rootSelect]: !editable,
        [classes.rootSelectActive]: selected,
        [classes.rootEditable]: editable
      })}
      onClick={handleSelect}
    >
      {Object.keys(colors).map((key, index) => {
        return (
          <ColorSectionMemoized
            key={index}
            name={key}
            editable={editable}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
            classes={classes}
            customSection={customSection}
            {...colors[key]}
          />
        )
      })}
    </Grid>
  )
}

FormControlPalette.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  palette: PropTypes.object,
  editable: PropTypes.bool,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  customSection: PropTypes.string
}

export default withStyles(styles)(memo(FormControlPalette))
