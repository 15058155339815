import { takeTruth } from '../utils/generalUtils'

export function mediaCapAlertSelector({ media }) {
  return media.capAlert
}

export function mediaIpawsAlertSelector({ media }) {
  return media.ipawsAlert
}

export function mediaPreviewSelector({ media }) {
  return media.preview
}

export function mediaGroupsSelector({ media }) {
  return media.groups
}

export function addMediaLoadingSelector({ config, media }) {
  return takeTruth(
    config.configMediaCategory.isFetching,
    media.mediaItem.isFetching
  )
}

export function mediaAssociationsSelector({ media }) {
  return media.associations
}

export function mediaItemSelector({ media }) {
  return media.mediaItem
}

export function addItemSelector({ addMedia }) {
  return addMedia.addItem
}
