import update from 'immutability-helper'

import * as types from '../../actions/public'

import {
  shapeOfBodyWithMeta,
  postInitialState,
  deleteInitialState
} from '../../constants/initialLibraryState'

const initialState = {
  items: {
    ...shapeOfBodyWithMeta
  },
  post: {
    ...postInitialState
  },
  del: {
    ...deleteInitialState
  }
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PUBLIC_TOKEN:
      return update(state, {
        items: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_PUBLIC_TOKEN_SUCCESS:
      return update(state, {
        items: {
          response: { $set: action.response },
          isFetching: { $set: false },
          meta: { $set: action.modifiedMeta }
        }
      })
    case types.GET_PUBLIC_TOKEN_ERROR:
      return update(state, {
        items: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.POST_PUBLIC_TOKEN_SUCCESS:
      return update(state, {
        post: {
          response: { $set: action.response }
        }
      })
    case types.POST_PUBLIC_TOKEN_ERROR:
      return update(state, {
        post: {
          error: { $set: action.payload }
        }
      })
    case types.DELETE_PUBLIC_TOKEN_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.response }
        }
      })
    case types.DELETE_PUBLIC_TOKEN_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUBLIC_TOKEN_RESPONSE_INFO:
      return update(state, {
        post: {
          $set: {
            ...postInitialState
          }
        },
        del: {
          $set: {
            ...deleteInitialState
          }
        }
      })

    default:
      return state
  }
}

export default auth
