import { ENTERPRISE_ROLE, ORG_ROLE, SYSTEM_ROLE } from './api'

export const librarySearchType = {
  media: 'media',
  playlist: 'playlist',
  template: 'template',
  schedule: 'schedule',
  device: 'device',
  user: 'user',
  clientUser: 'clientUser',
  tag: 'tag',
  report: 'report',
  client: 'client',
  room: 'room'
}

export const librarySearchEndpointType = {
  media: 'media',
  mediaGroup: 'mediaGroup',
  mediaTag: 'mediaTag',
  playlist: 'playlist',
  playlistGroup: 'playlistGroup',
  playlistTag: 'playlistTag',
  template: 'template',
  templateGroup: 'templateGroup',
  templateTag: 'templateTag',
  schedule: 'schedule',
  scheduleGroup: 'scheduleGroup',
  scheduleTag: 'scheduleTag',
  deviceName: 'deviceName',
  device: 'device',
  deviceGroup: 'deviceGroup',
  deviceTag: 'deviceTag',
  deviceType: 'deviceType',
  client: 'client',
  user: 'user',
  userGroup: 'userGroup',
  userTag: 'userTag',
  clientUser: 'clientUser',
  tag: 'tag',
  report: 'report',
  clientName: 'clientName',
  clientPackage: 'clientPackage',
  clientGroup: 'clientGroup',
  clientTag: 'clientTag'
}

export const librarySearchCategoryType = {
  title: 'title',
  libraryTag: 'libraryTag',
  libraryGroup: 'libraryGroup',
  mediaType: 'mediaType',
  playlistType: 'playlistType',
  contains: 'contains',
  templateType: 'templateType',
  scheduleType: 'scheduleType',
  scheduleDevice: 'scheduleDevice',
  deviceName: 'deviceName',
  deviceType: 'deviceType',
  deviceLocation: 'deviceLocation',
  deviceClient: 'deviceClient',
  userFullName: 'userFullName',
  userEmail: 'userEmail',
  userRole: 'userRole',
  userClient: 'userClient',
  tagName: 'tagName',
  tagType: 'tagType',
  reportTable: 'reportTable',
  clientName: 'clientName',
  clientPackage: 'clientPackage',
  clientType: 'clientType'
}

export const librarySearchFieldMapping = {
  [librarySearchType.media]: [
    {
      column: librarySearchCategoryType.title,
      endpoint: librarySearchEndpointType.media,
      label: 'Title',
      viewMoreKey: 'title'
    },
    {
      column: librarySearchCategoryType.mediaType,
      staticOptions: true,
      label: 'Type'
    },
    {
      column: librarySearchCategoryType.libraryGroup,
      label: 'Group',
      endpoint: librarySearchEndpointType.mediaGroup,
      forRoles: [ORG_ROLE, ENTERPRISE_ROLE]
    },
    {
      column: librarySearchCategoryType.libraryTag,
      endpoint: librarySearchEndpointType.mediaTag,
      label: 'Tag'
    }
  ],
  [librarySearchType.playlist]: [
    {
      column: librarySearchCategoryType.title,
      label: 'Title',
      endpoint: librarySearchEndpointType.playlist,
      viewMoreKey: 'title'
    },
    {
      column: librarySearchCategoryType.contains,
      label: 'Contains',
      staticOptions: true
    },
    {
      column: librarySearchCategoryType.playlistType,
      label: 'Type',
      staticOptions: true
    },
    {
      column: librarySearchCategoryType.libraryGroup,
      forRoles: [ORG_ROLE, ENTERPRISE_ROLE],
      label: 'Group',
      endpoint: librarySearchEndpointType.playlistGroup
    },
    {
      column: librarySearchCategoryType.libraryTag,
      label: 'Tag',
      endpoint: librarySearchEndpointType.playlistTag
    }
  ],
  [librarySearchType.template]: [
    {
      column: librarySearchCategoryType.title,
      label: 'Title',
      endpoint: librarySearchEndpointType.template,
      viewMoreKey: 'title'
    },
    {
      column: librarySearchCategoryType.contains,
      label: 'Contains',
      staticOptions: true
    },
    {
      column: librarySearchCategoryType.templateType,
      label: 'Type',
      staticOptions: true
    },
    {
      column: librarySearchCategoryType.libraryGroup,
      forRoles: [ORG_ROLE, ENTERPRISE_ROLE],
      label: 'Group',
      endpoint: librarySearchEndpointType.templateGroup
    },
    {
      column: librarySearchCategoryType.libraryTag,
      label: 'Tag',
      endpoint: librarySearchEndpointType.templateTag
    }
  ],
  [librarySearchType.schedule]: [
    {
      column: librarySearchCategoryType.title,
      label: 'Title',
      endpoint: librarySearchEndpointType.schedule,
      viewMoreKey: 'title'
    },
    {
      column: librarySearchCategoryType.scheduleType,
      label: 'Type',
      staticOptions: true
    },
    {
      column: librarySearchCategoryType.scheduleDevice,
      label: 'Device',
      endpoint: librarySearchEndpointType.deviceName,
      compareKey: 'id',
      viewMoreKey: 'device'
    },
    {
      column: librarySearchCategoryType.libraryGroup,
      forRoles: [ORG_ROLE],
      label: 'Group',
      endpoint: librarySearchEndpointType.scheduleGroup
    },
    {
      column: librarySearchCategoryType.libraryTag,
      label: 'Tag',
      endpoint: librarySearchEndpointType.scheduleTag
    }
  ],
  [librarySearchType.device]: [
    {
      column: librarySearchCategoryType.deviceName,
      label: 'Name',
      endpoint: librarySearchEndpointType.device,
      viewMoreKey: 'name'
    },
    {
      column: librarySearchCategoryType.deviceType,
      label: 'Type',
      endpoint: librarySearchEndpointType.deviceType
    },
    {
      column: librarySearchCategoryType.deviceClient,
      label: 'Client',
      forRoles: [ENTERPRISE_ROLE, SYSTEM_ROLE],
      endpoint: librarySearchEndpointType.client,
      viewMoreKey: 'client'
    },
    {
      column: librarySearchCategoryType.deviceLocation,
      forRoles: [ORG_ROLE],
      label: 'Location',
      endpoint: librarySearchEndpointType.device
    },
    {
      column: librarySearchCategoryType.libraryGroup,
      forRoles: [ORG_ROLE, ENTERPRISE_ROLE],
      label: 'Group',
      endpoint: librarySearchEndpointType.deviceGroup
    },
    {
      column: librarySearchCategoryType.libraryTag,
      forRoles: [ORG_ROLE],
      label: 'Tag',
      endpoint: librarySearchEndpointType.deviceTag
    }
  ],
  [librarySearchType.user]: [
    {
      column: librarySearchCategoryType.userFullName,
      label: 'Name',
      endpoint: librarySearchEndpointType.user,
      compareKey: 'id'
    },
    {
      column: librarySearchCategoryType.userEmail,
      label: 'Email',
      endpoint: librarySearchEndpointType.user,
      viewMoreKey: 'email'
    },
    {
      column: librarySearchCategoryType.userRole,
      label: 'Role',
      staticOptions: true
    },
    {
      column: librarySearchCategoryType.libraryGroup,
      forRoles: [ORG_ROLE],
      label: 'Group',
      endpoint: librarySearchEndpointType.userGroup
    },
    {
      column: librarySearchCategoryType.libraryTag,
      forRoles: [ORG_ROLE],
      label: 'Tag',
      endpoint: librarySearchEndpointType.userTag
    }
  ],
  [librarySearchType.clientUser]: [
    {
      column: librarySearchCategoryType.userFullName,
      label: 'Name',
      endpoint: librarySearchEndpointType.clientUser,
      compareKey: 'id'
    },
    {
      column: librarySearchCategoryType.userEmail,
      label: 'Email',
      endpoint: librarySearchEndpointType.clientUser,
      viewMoreKey: 'email'
    },
    {
      column: librarySearchCategoryType.userClient,
      label: 'Client',
      endpoint: librarySearchEndpointType.client
    },
    {
      column: librarySearchCategoryType.userRole,
      label: 'Role'
    },
    {
      column: librarySearchCategoryType.libraryTag,
      label: 'Tag',
      endpoint: librarySearchEndpointType.tag
    }
  ],
  [librarySearchType.tag]: [
    {
      column: librarySearchCategoryType.tagName,
      label: 'Name',
      endpoint: librarySearchEndpointType.tag,
      viewMoreKey: 'tag'
    },
    {
      column: librarySearchCategoryType.tagType,
      label: 'Type',
      staticOptions: true
    }
  ],
  [librarySearchType.report]: [
    {
      column: librarySearchCategoryType.title,
      endpoint: librarySearchEndpointType.report,
      label: 'Title',
      viewMoreKey: 'title'
    },
    {
      column: librarySearchCategoryType.reportTable,
      label: 'Table'
    }
  ],
  [librarySearchType.client]: [
    {
      column: librarySearchCategoryType.clientName,
      endpoint: librarySearchEndpointType.clientName,
      label: 'Client',
      viewMoreKey: 'clientName'
    },
    {
      column: librarySearchCategoryType.clientPackage,
      endpoint: librarySearchEndpointType.clientPackage,
      label: 'Package',
      forRoles: [SYSTEM_ROLE]
    },
    {
      column: librarySearchCategoryType.clientType,
      label: 'Client Type'
    },
    {
      column: librarySearchCategoryType.libraryGroup,
      label: 'Group',
      endpoint: librarySearchEndpointType.clientGroup
    },
    {
      column: librarySearchCategoryType.libraryTag,
      endpoint: librarySearchEndpointType.clientTag,
      label: 'Tag'
    }
  ]
}
