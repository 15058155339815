import React from 'react'
import { makeStyles } from '@material-ui/core'

import { Text } from 'components/Typography'

const useStyles = makeStyles(({ spacing }) => ({
  reasonText: {
    fontFamily: 'Montserrat',
    fontSize: '56px',
    lineHeight: '68px',
    fontWeight: 700,
    textShadow:
      '0px 8px 0px #FFFFFF3D, 0px 16px 0px #FFFFFF1F, 0px 24px 0px #FFFFFF0F, 0px 32px 0px #FFFFFF08',
    marginRight: spacing(2)
  }
}))

const ShadowedErrorText = ({ children }) => {
  const classes = useStyles()

  return (
    <Text rootClassName={classes.reasonText} color="white">
      {children}
    </Text>
  )
}

export default ShadowedErrorText
