import update from 'immutability-helper'
import {
  shapeOfBodyWithMeta,
  postInitialState,
  putInitialState,
  deleteInitialState
} from '../constants/initialLibraryState'

import * as types from '../actions'

const quoteInitialState = {
  items: {
    isPending: true,
    ...shapeOfBodyWithMeta
  },
  post: { ...postInitialState },
  put: { ...putInitialState },
  delete: { ...deleteInitialState },
  categories: {
    ...shapeOfBodyWithMeta
  }
}

const quoteReducer = (state = quoteInitialState, action) => {
  switch (action.type) {
    case types.GET_QUOTES:
      return update(state, {
        items: {
          isPending: { $set: true },
          error: { $set: {} }
        }
      })
    case types.GET_QUOTES_SUCCESS:
      const data =
        action.response.meta.currentPage !== 1
          ? [...state.items.response, ...action.response.data]
          : action.response.data

      return update(state, {
        items: {
          isPending: { $set: false },
          response: { $set: data },
          meta: {
            $set: action.response.meta
          }
        }
      })
    case types.GET_QUOTES_ERROR:
      return update(state, {
        items: {
          $set: {
            response: [],
            isPending: false,
            error: action.error
          }
        }
      })
    case types.CLEAR_GET_QUOTES_INFO:
      return update(state, {
        response: {
          $set: {}
        }
      })

    case types.POST_QUOTE_SUCCESS:
      return update(state, {
        post: {
          response: { $set: action.payload }
        }
      })
    case types.POST_QUOTE_ERROR:
      return update(state, {
        post: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_QUOTE_INFO:
      return update(state, {
        response: {
          $set: {}
        }
      })
    case types.PUT_QUOTE_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_QUOTE_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload }
        }
      })
    case types.DELETE_QUOTE_SUCCESS:
      return update(state, {
        delete: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_QUOTE_ERROR:
      return update(state, {
        delete: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_QUOTE_INFO:
      return update(state, {
        post: { $set: postInitialState },
        put: { $set: putInitialState },
        delete: { $set: deleteInitialState }
      })

    case types.GET_QUOTE_CATEGORIES_SUCCESS:
      return update(state, {
        categories: {
          response: { $set: action.response.data },
          meta: {
            $set: action.response.meta
          }
        }
      })
    case types.GET_QUOTE_CATEGORIES_ERROR:
      return update(state, {
        categories: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_QUOTE_CATEGORIES_INFO:
      return update(state, {
        categories: {
          $set: {}
        }
      })
    default:
      return state
  }
}

export default quoteReducer
