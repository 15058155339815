import { createAppApi } from 'services/api'

export const googleDocsApi = createAppApi('googleDocsQuery', {
  endpoints: builder => ({
    getGoogleDocs: builder.query({
      query: ({ breakCache, ...params }) => {
        return {
          url: '/googleDocs/documents',
          params
        }
      }
    })
  })
})

export const { useLazyGetGoogleDocsQuery } = googleDocsApi
