import { getFieldNameFromPath } from 'utils/validationUtils'

export const requiredField = 'This field is required'
export const emailField = 'Must be an email'
export const numberField = 'Must be a number'
export const integerField = 'Must be an integer'
export const positiveIntegerField = 'Must be a positive integer'
export const NonZeroIntegerField = 'Must be a non zero integer'
export const invalidValue = 'Invalid Value'
export const passwordMin = min => `Password must be at least ${min} characters`
export const passwordFormat =
  'Must Contain 8 Characters, at least one UPPERCASE letter, and at least one number'
export const validUrl = 'Enter valid URL'
export const validFileUrl =
  'This file format is not supported for playback. Please try uploading a valid file format'
export const reachableUrlMessage = 'The specified URL does not contain the file'
export const dataParsing = 'Unable to parse data'
export const numbersField = 'Only numbers'
export const requiredTheme = 'Select a theme'
export const requiredCity = 'Select a city'
export const requiredLocation = 'Select a location'
export const requiredBackground = 'Select a background'
export const commaSeparatedMacAddress =
  'Enter valid, comma separated MAC Address(es)'
export const validMacAddress = 'Enter valid MAC Address'
export const validActivationCode = 'Enter valid Activation Code'
export const validIpAddress = 'Enter a valid IP address'
export const validDefaultGateway = 'A valid IP address or 0.0.0.0 is required'
export const validTimeserver = 'Enter a valid timeserver'
export const validSubnetMask = 'Enter a valid Subnet Mask'
export const validDNS = 'Enter a valid DNS'
export const validProxyAddress = 'Enter a valid Proxy Address'
export const requiredFile = 'A file is required'
export const phoneMinLength = 'Phone is too short'
export const phoneMaxLength = 'Phone is too long'
export const phoneValidFormat = 'Invalid phone format'
export const groupField =
  "This field may only contain letters, numbers, space and the following special characters: (.!&@#$%-_()')"
export const groupTitleMax =
  'A Valid group title should have a max of 25 characters'
export const unableToGetData = 'Unable to get data'
export const invalidDataType = 'Invalid data type'
export const roleTitle = "The name can only contain alphabet and '-'"
export const tagNameMin = 'A Valid tag name should have a min of 3 characters'
export const groupRequiredField = 'One or more groups are required'
export const validHostname = 'Enter a valid hostname'
export const isDurationGreaterThanAnimationDuration =
  'Duration should be greater than animation duration'
export const isDurationGreaterThanPageDuration =
  'Duration should be greater than page scroll duration'
export const exceedCharacters = (field, t) => ({ max }) =>
  t('Field must not exceed characters', {
    field,
    max
  })
export const minStringLength = (field, t) => ({ min }) =>
  t('The field must be greater than or equal to min characters', {
    field,
    min
  })
export const maxStringLength = t => ({ path, max }) =>
  t('The field may not be greater than max characters', {
    max,
    field: t(getFieldNameFromPath(path))
  })
export const numberMinimum = (min, t) =>
  t('Must be greater than or equal to n', { min })
export const numberMaximum = (max, t) =>
  t('Must be less than or equal to n', { max })
export const maxArrayLength = (t, entity) => ({ path, max }) =>
  t('The field must contain a maximum of max entity', {
    max,
    entity: entity || t(getFieldNameFromPath(path))
  })

export const maxFiles = t => ({ max }) =>
  t('A maximum of count files are allowed', {
    count: max
  })
export const hasRestrictedCharacters = 'Field contains restricted characters'
