import api from './api'

import { errorHandler } from '../utils'

const getAlertDevicesById = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/alert/${id}/device`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postAlertTrigger = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/alert/${data.id}/trigger`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceActiveEmergencyAlert = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/device/activeEmergencyAlert'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceMediaEmergencyAlert = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/media/emergencyAlert`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putDeviceMediaEmergencyAlert = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${data.deviceId}/media/emergencyAlert/${data.alertId}`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const removeDeviceMediaEmergencyAlert = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${data.deviceId}/media/emergencyAlert/${data.alertId}/remove`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceMediaCapAlert = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/media/capAlert`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const removeDeviceMediaCapAlert = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/device/${id}/media/capAlert`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putDeviceMediaCapAlert = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${data.deviceId}/media/capAlert`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const disableAlert = async () => {
  try {
    const response = await api({
      method: 'POST',
      url: '/alert/disable'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const disableDeviceAlert = async deviceId => {
  try {
    const response = await api({
      method: 'POST',
      url: `/device/${deviceId}/alert/disable`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

async function associateCapAlert({ association, password }) {
  try {
    const response = await api({
      method: 'PUT',
      url: '/capAlert/device',
      data: {
        association,
        password
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

async function getIpawsAlertDevices() {
  try {
    const response = await api({
      method: 'GET',
      url: '/ipaws/device'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

async function associateIpawsAlert({ association, password }) {
  try {
    const response = await api({
      method: 'PUT',
      url: '/ipaws/device',
      data: {
        association,
        password
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const removeDeviceMediaIpawsAlert = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/device/${id}/media/ipaws`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getDeviceMediaIpawsAlert = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/device/${id}/media/ipaws`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putDeviceMediaIpawsAlert = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/device/${data.deviceId}/media/ipaws`,
      data: data.data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const alertServices = {
  getAlertDevicesById,
  postAlertTrigger,
  getDeviceActiveEmergencyAlert,
  getDeviceMediaEmergencyAlert,
  putDeviceMediaEmergencyAlert,
  removeDeviceMediaEmergencyAlert,
  getDeviceMediaCapAlert,
  removeDeviceMediaCapAlert,
  putDeviceMediaCapAlert,
  disableAlert,
  disableDeviceAlert,
  associateCapAlert,
  getIpawsAlertDevices,
  associateIpawsAlert,
  removeDeviceMediaIpawsAlert,
  getDeviceMediaIpawsAlert,
  putDeviceMediaIpawsAlert
}

export default alertServices
