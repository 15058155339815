import React, { useCallback, memo, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import NumericInput from 'react-numeric-input'
import InputBase from '@material-ui/core/InputBase'
import { _toNumber } from 'utils/lodash'
import { withStyles, InputLabel, FormControl } from '@material-ui/core'

import { simulateEvent } from 'utils/formik'
import { isString } from 'utils/generalUtils'
import 'styles/forms/_number-input.scss'
import Error from 'components/Form/Error'
import Tooltip from 'components/Tooltip'

const styles = ({
  palette,
  colors,
  type,
  spacing,
  transitions,
  typography,
  shapes,
  fontSize,
  lineHeight,
  fontWeight,
  formControls
}) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  rootContainer: {
    width: '100%'
  },
  formControlRoot: {
    width: '100%',
    position: 'relative'
  },
  formControlMargin: {
    marginBottom: spacing(2)
  },
  bootstrapRootWithoutMargin: {
    marginTop: 'unset !important',
    lineHeight: '1em'
  },
  bootstrapInput: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: palette[type].formControls.input.background,
    border: `1px solid ${palette[type].formControls.input.border}`,
    color: palette[type].formControls.input.color,
    fontSize: fontSize.small,
    padding: `0 ${spacing(1)}px`,
    paddingLeft: formControls.input.paddingLeft,
    transition: transitions.create(['border-color', 'box-shadow']),
    fontFamily: typography.fontFamily,
    height: shapes.height.primary,

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  inputSmall: {
    fontSize: fontSize.small
  },
  inputSmallest: {
    fontSize: fontSize.smallest
  },
  labelSmall: {
    fontSize: `${fontSize.small}px !important`
  },
  labelSmallest: {
    fontSize: `${fontSize.smallest}px !important`
  },
  bootstrapTextAreaHeight: {
    height: '87px',
    padding: `${spacing(1)}px ${spacing(1)}px 3px ${spacing(1)}px`,

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: palette[type].scrollbar.background,
      borderRadius: '5px'
    }
  },
  bootstrapFormLabel: {
    fontSize: fontSize.primary,
    lineHeight: lineHeight.primary,
    fontWeight: fontWeight.normal,
    color: palette[type].formControls.label.color,
    transform: 'none'
  },
  labelRightComponentContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  bootstrapFormLabelFocus: {
    color: `${palette[type].formControls.label.color} !important`
  },
  error: {
    color: colors.error,
    fontSize: 9,
    lineHeight: '12px',
    position: 'absolute',
    left: 5
  },
  disabled: {
    cursor: 'default',
    backgroundColor: palette[type].formControls.disabled.background,
    textShadow: 'unset'
  },
  rightLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center'
  },
  topLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  leftLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bottomLabel: {
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  label: {
    position: 'unset !important'
  },
  alignLabel: {
    alignSelf: 'flex-start'
  },
  shrink: {
    flexGrow: 1
  },
  labelLink: {
    position: 'unset !important',
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: colors.highlight,
    textUnderlineOffset: '2px',
    '&:hover': {
      cursor: 'pointer',
      textDecorationStyle: 'solid'
    }
  },
  numericInputContainer: {
    '& .react-numeric-input b': {
      borderLeft: `1px solid ${palette[type].formControls.input.border} !important`,
      '&:nth-of-type(1)': {
        borderBottom: `1px solid ${palette[type].formControls.input.border} !important`
      }
    }
  },
  numericInputFullWidth: {
    '& .react-numeric-input': {
      width: '100%'
    }
  },
  errorField: {
    borderBottom: `2px solid ${colors.error}`
  },
  optionalErrorField: {
    borderBottom: `2px solid ${colors.optionalError}`
  },
  multiline: {
    padding: 0
  },
  errorWithoutIcon: {
    marginLeft: 5
  }
})

const numericInputUnsetStyles = {
  'input:disabled': {
    textShadow: null
  },
  'input:not(.form-control)': {
    fontSize: null,
    paddingRight: 20,
    paddingLeft: null,
    border: null,
    borderRadius: null
  }
}

const FormControlInput = ({
  t,
  classes,
  id,
  type = 'text',
  autocomplete = 'new-password',
  label = '',
  value = '',
  maskValue = '',
  isOptional = false,
  fullWidth = false,
  placeholder = null,
  formikMode = false,
  min = 0,
  max = 9999999,
  step = 1,
  precision,
  formControlContainerClass = '',
  formControlRootClass = '',
  formControlLabelClass = '',
  formControlInputRootClass = '',
  formControlInputClass = '',
  errorTextClass = '',
  multiline = false,
  handleChange = f => f,
  name,
  strict = false,
  disabled = false,
  custom = false,
  icon = null,
  error = '',
  touched = false,
  handleBlur = f => f,
  labelRightComponent = null,
  showErrorText = true,
  marginBottom = true,
  customiseDisabled = true,
  pattern,
  onClickLabel,
  numberFormat,
  labelPosition = 'top',
  tooltip = '',
  tooltipHeaderText,
  defaultValue,
  fontSizeVariant = 'primary',
  labelFontSizeVariant = 'primary',
  absoluteErrorText = true,
  inputRef,
  tReady,
  blurMin = 0,
  skipBlurChange = false,
  parseNumber,
  ...props
}) => {
  const handleChangeNumericInput = useCallback(
    value => {
      if (formikMode) {
        handleChange(simulateEvent(name, value))
      } else {
        handleChange(value, name)
      }
    },
    [formikMode, handleChange, name]
  )

  const handleNumericBlur = useCallback(
    ({ target }) => {
      const { value, name } = target
      const parsedValue = parseNumber ? parseNumber(value) : value
      let num = parsedValue
      const minimum = blurMin || min
      if (parsedValue > max) num = max
      if (parsedValue < minimum) num = minimum
      handleBlur(simulateEvent(name, null))
      if (formikMode) {
        return handleChange(
          simulateEvent(name, _toNumber(skipBlurChange ? value : num))
        )
      }
      handleChange(_toNumber(num), name)
    },
    [
      max,
      min,
      formikMode,
      handleBlur,
      handleChange,
      blurMin,
      parseNumber,
      skipBlurChange
    ]
  )

  const isUncontrolled = isString(defaultValue)
  const inputValue = !isUncontrolled
    ? maskValue
      ? moment(value).format(maskValue)
      : value
    : undefined

  const inputClassName = classNames(
    classes.bootstrapInput,
    formControlInputClass,
    {
      [classes.errorField]: !!(showErrorText && error && touched),
      [classes.optionalErrorField]: !!(
        showErrorText &&
        error &&
        touched &&
        isOptional
      ),
      [classes.bootstrapTextAreaHeight]: multiline,
      [classes.disabled]: disabled && customiseDisabled,
      [classes.inputSmall]: fontSizeVariant === 'small',
      [classes.inputSmallest]: fontSizeVariant === 'smallest'
    }
  )

  const inputRootClassName = classNames(
    classes.bootstrapRoot,
    formControlInputRootClass,
    {
      [classes.bootstrapRootWithoutMargin]: labelPosition !== 'top',
      [classes.shrink]: labelPosition !== 'top' && !custom
    }
  )

  const showError = useMemo(() => {
    return !!(showErrorText && error && touched)
  }, [showErrorText, error, touched])

  return (
    <div
      className={classNames(classes.root, formControlContainerClass, {
        [classes.rootContainer]: fullWidth
      })}
    >
      <FormControl
        className={classNames(classes.formControlRoot, formControlRootClass, {
          [classes.formControlMargin]:
            !absoluteErrorText && showError ? false : marginBottom,
          [classes.leftLabel]: labelPosition === 'left',
          [classes.topLabel]: labelPosition === 'top',
          [classes.bottomLabel]: labelPosition === 'bottom',
          [classes.rightLabel]: labelPosition === 'right'
        })}
      >
        {labelRightComponent && (
          <div className={classes.labelRightComponentContainer}>
            {labelRightComponent}
          </div>
        )}

        {label && (
          <Tooltip
            arrow
            title={tooltip}
            disableHoverListener={!tooltip}
            withHeader={!!tooltipHeaderText}
            headerText={tooltipHeaderText}
            placement="top"
          >
            <InputLabel
              shrink
              htmlFor={id}
              className={classNames(
                classes.bootstrapFormLabel,
                formControlLabelClass,
                {
                  [classes.alignLabel]:
                    labelPosition === 'top' || labelPosition === 'bottom',
                  [classes.labelSmall]: labelFontSizeVariant === 'small',
                  [classes.labelSmallest]: labelFontSizeVariant === 'smallest'
                }
              )}
              classes={{
                focused: classes.bootstrapFormLabelFocus,
                root:
                  tooltip || !!onClickLabel ? classes.labelLink : classes.label
              }}
              onClick={() => onClickLabel && onClickLabel()}
            >
              {label} {isOptional && <i>({t('optional')})</i>}
            </InputLabel>
          </Tooltip>
        )}

        {custom ? (
          <div
            className={classNames(
              classes.numericInputContainer,
              inputRootClassName,
              {
                [classes.numericInputFullWidth]: fullWidth
              }
            )}
          >
            <NumericInput
              strict={strict}
              min={min}
              max={max}
              step={step}
              value={value}
              format={numberFormat}
              parse={parseNumber}
              disabled={disabled}
              name={name}
              onChange={handleChangeNumericInput}
              onBlur={handleNumericBlur}
              className={inputClassName}
              style={numericInputUnsetStyles}
              precision={precision}
            />
          </div>
        ) : (
          <InputBase
            id={id}
            type={type}
            value={inputValue}
            defaultValue={defaultValue}
            name={name}
            fullWidth={fullWidth}
            placeholder={placeholder}
            multiline={multiline}
            disabled={disabled}
            pattern={pattern}
            autoComplete={autocomplete}
            inputProps={{ step }}
            classes={{
              root: inputRootClassName,
              input: inputClassName,
              multiline: classes.multiline
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            inputRef={inputRef}
            {...props}
          />
        )}

        {!!icon && icon}

        <Error
          isOptional={isOptional}
          absolute={absoluteErrorText}
          condition={showError}
          error={error}
          rootClassName={classNames(
            {
              [classes.errorWithoutIcon]: !icon
            },
            errorTextClass
          )}
        />
      </FormControl>
    </div>
  )
}

FormControlInput.propTypes = {
  type: PropTypes.string,
  autocomplete: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskValue: PropTypes.string,
  isOptional: PropTypes.bool,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  formikMode: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  precision: PropTypes.number,
  numberFormat: PropTypes.func,
  formControlContainerClass: PropTypes.string,
  formControlRootClass: PropTypes.string,
  formControlLabelClass: PropTypes.string,
  formControlInputRootClass: PropTypes.string,
  formControlInputClass: PropTypes.string,
  errorTextClass: PropTypes.string,
  multiline: PropTypes.bool,
  handleChange: PropTypes.func,
  strict: PropTypes.bool,
  disabled: PropTypes.bool,
  custom: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.string,
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  labelRightComponent: PropTypes.node,
  showErrorText: PropTypes.bool,
  marginBottom: PropTypes.bool,
  customiseDisabled: PropTypes.bool,
  labelPosition: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  pattern: PropTypes.string,
  onClickLabel: PropTypes.func,
  defaultValue: PropTypes.string,
  absoluteErrorText: PropTypes.bool,
  fontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  parseNumber: PropTypes.func
}

export default withTranslation('translations')(
  withStyles(styles)(memo(FormControlInput))
)
