import { useDispatch, useSelector } from 'react-redux'
import { configFeatureMediaSelector } from 'selectors/configSelectors'
import { useCallback, useEffect, useMemo } from 'react'
import { getConfigFeatureMedia } from '../../actions/configActions'
import { featuresToExclude } from 'constants/media'

export default function useConfigFeatureMedia(
  excludeAlertAndEmail = false,
  exclude = [],
  params
) {
  const dispatch = useDispatch()
  const { response, error, isFetching } = useSelector(
    configFeatureMediaSelector
  )

  const excludeFeatures = useMemo(() => {
    if (excludeAlertAndEmail) {
      return featuresToExclude
    }
    if (exclude && exclude.length) {
      return exclude
    }
    return []
  }, [excludeAlertAndEmail, exclude])

  const filteredResponse = useMemo(() => {
    if (!response || !response.length) {
      return []
    }
    if (!excludeFeatures || !excludeFeatures.length) {
      return response
    }
    return response.filter(({ name }) => !excludeFeatures.includes(name))
  }, [response, excludeFeatures])

  const fetcher = useCallback(
    p => {
      dispatch(getConfigFeatureMedia(p))
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (!response.length && !isFetching) {
        fetcher(params)
      }
    },
    // eslint-disable-next-line
    []
  )

  return useMemo(
    () => ({
      response: filteredResponse,
      error,
      isFetching,
      getConfigFeatureMedia: fetcher
    }),
    [filteredResponse, error, isFetching, fetcher]
  )
}
