import { errorHandler } from 'utils'
import api from 'services/api'

const getGoogleDocuments = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/googleDocs/documents',
      params
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const googleDocumentService = {
  getGoogleDocuments
}

export default googleDocumentService
