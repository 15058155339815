import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { Grid, withStyles } from '@material-ui/core'
import _sortBy from 'lodash/sortBy'
import _isEmpty from 'lodash/isEmpty'
import { compose } from '@reduxjs/toolkit'

import DefaultModal from 'components/Modal/DefaultModal'
import Scrollbars from 'components/Scrollbars'
import { FavoriteFeatureCard } from 'components/Card'
import SingleGridItemLoader from 'components/Loaders/SingleGridItemLoader'
import useConfigMediaCategory from 'hooks/api/useConfigMediaCategory'
import { userDetailsSelector } from 'selectors/userSelectors'
import useIds from 'hooks/tableLibrary/useIds'
import useSelectedList from 'hooks/tableLibrary/useSelectedList'
import { isMediaFeatureAvailable } from 'utils/mediaUtils'
import { useUserRole } from 'hooks/tableLibrary'
import { featureNames } from 'constants/featureConstants'
import { postFavoriteMedia, clearPostFavoriteMedia } from 'actions/mediaActions'
import { BlueButton } from 'components/Buttons'
import useNotifyAnalyzer from 'hooks/tableLibrary/useNotifyAnalyzer'
import { MIN_FAVORITE_FEATURES, ORG_ROLE } from 'constants/api'
import usePermissions from 'hooks/api/usePermissions'
import { permissionNames } from 'constants/index'
import { getOriginalUser } from 'utils/localStorage'

const styles = ({ spacing }) => ({
  cardsWrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: spacing(1),
    padding: 4,
    paddingBottom: 14
  },
  cardClassName: {
    padding: 4
  }
})

const NEW_USER_FROM_DATE = '2022-05-12'

const renderLoader = Array(65)
  .fill('')
  .map((item, index) => (
    <SingleGridItemLoader key={index} height={54} width={240} />
  ))

const FavoriteFeaturesSplashModal = ({ classes, t }) => {
  const dispatch = useDispatch()

  const { response: userResponse } = useSelector(userDetailsSelector)
  const postFavorite = useSelector(({ media }) => media.postFavorite)

  const role = useUserRole()
  const originalUser = getOriginalUser()
  const { getPermissionByName } = usePermissions()

  const [isLoading, setLoading] = useState(undefined)

  const isUserAllowed = useMemo(() => {
    const isNewUser =
      userResponse.createdAt &&
      moment(userResponse.createdAt).isAfter(NEW_USER_FROM_DATE)
    const isAllowedRole = userResponse?.role?.level === ORG_ROLE
    const hasPermission = getPermissionByName(
      permissionNames.CLIENT_MEDIA_STORE
    )

    return isNewUser && isAllowedRole && hasPermission && _isEmpty(originalUser)
  }, [userResponse, getPermissionByName, originalUser])

  const {
    response: mediaResponse,
    isFetching,
    getConfigMediaCategory
  } = useConfigMediaCategory(undefined, {
    preventInitialFetch: !isUserAllowed
  })

  const featuresByCategory = useMemo(
    () =>
      mediaResponse.reduce(
        (acc, { feature, name }) => ({ ...acc, [name]: feature }),
        {}
      ),
    [mediaResponse]
  )

  const { Favorite = [], ...restCategories } = featuresByCategory

  const features = useMemo(
    () =>
      _sortBy(
        Object.values(restCategories)
          .reduce((acc, features) => [...acc, ...features], [])
          .filter(({ name }) => {
            return isMediaFeatureAvailable(name, userResponse, role)
          }),
        ['alias']
      ),
    [restCategories, role, userResponse]
  )

  const ids = useIds(features)
  const selectedFeatures = useSelectedList(ids)

  const handleToggle = useCallback(
    (value, id) => {
      selectedFeatures.toggle(id)
    },
    [selectedFeatures]
  )

  const handleSave = useCallback(() => {
    dispatch(
      postFavoriteMedia(
        selectedFeatures.selectedIds.map(featureId => ({
          featureId
        }))
      )
    )
  }, [dispatch, selectedFeatures])

  const clearNotification = useCallback(() => {
    dispatch(clearPostFavoriteMedia())
  }, [dispatch])

  useNotifyAnalyzer(
    getConfigMediaCategory,
    clearNotification,
    () => {},
    () => {},
    'Favorites',
    [postFavorite]
  )

  useEffect(() => {
    if (Favorite.length) {
      selectedFeatures.selectIds(Favorite.map(({ id }) => id))
    } else {
      const fileFeatureId = features.find(
        ({ name }) => name === featureNames.File
      )?.id
      if (fileFeatureId) {
        selectedFeatures.toggle(fileFeatureId)
      }
    }
    //eslint-disable-next-line
  }, [Favorite])

  useEffect(
    () => {
      if (isFetching && isLoading !== false) {
        setLoading(true)
      } else if (!isFetching && isLoading === true) {
        setLoading(false)
      }
    },
    // eslint-disable-next-line
    [isFetching]
  )

  const isModalVisible = useMemo(
    () =>
      isUserAllowed &&
      Favorite.length < MIN_FAVORITE_FEATURES &&
      features.length >= MIN_FAVORITE_FEATURES,
    [isUserAllowed, Favorite, features]
  )

  return (
    <div>
      <DefaultModal
        modalTitle={t('Select at least count content apps to get started ...', {
          count: MIN_FAVORITE_FEATURES
        })}
        open={isModalVisible}
        hasCloseIcon={false}
        maxWidth="lg"
        actions={
          <BlueButton
            onClick={handleSave}
            iconClassName="fa-regular fa-circle-check"
            disabled={selectedFeatures.count < MIN_FAVORITE_FEATURES}
          >
            {t('Save')}
          </BlueButton>
        }
      >
        <Grid>
          <Scrollbars
            autoHeight
            autoHeightMin={200}
            autoHeightMax="calc(100vh - 400px)"
          >
            <Grid className={classes.cardsWrap}>
              {isLoading && renderLoader}
              {features.map(({ id, alias, description, icon, color }) => (
                <FavoriteFeatureCard
                  key={id}
                  id={id}
                  alias={alias}
                  description={description}
                  icon={icon}
                  color={color}
                  selected={selectedFeatures.isSelect(id)}
                  onToggle={handleToggle}
                  withToggle={false}
                  cardClassName={classes.cardClassName}
                />
              ))}
            </Grid>
          </Scrollbars>
        </Grid>
      </DefaultModal>
    </div>
  )
}
export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(FavoriteFeaturesSplashModal)
