import { useEffect, useMemo, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { Base64 } from 'js-base64'

import { useGetFeatureAccountsQuery } from 'api/featureApi'

export default function useAppConfig() {
  const [config, setConfig] = useState({})

  const { data } = useGetFeatureAccountsQuery({})

  useEffect(() => {
    if (!_isEmpty(data)) {
      const decodedConfig = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [key, Base64.decode(value)])
      )
      setConfig(decodedConfig)
    }
  }, [data])

  return useMemo(() => config || {}, [config])
}
