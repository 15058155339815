import update from 'immutability-helper'

import { shapeOfBody } from 'constants/initialLibraryState'
import * as types from 'actions'

const initialState = {
  data: {
    ...shapeOfBody,
    isFetching: false
  }
}

const googleDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GOOGLE_DOCUMENTS:
      return update(state, {
        data: {
          response: { $set: [] },
          isFetching: {
            $set: true
          }
        }
      })
    case types.GET_GOOGLE_DOCUMENTS_SUCCESS:
      return update(state, {
        data: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_GOOGLE_DOCUMENTS_ERROR:
      return update(state, {
        data: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_GOOGLE_DOCUMENTS:
      return update(state, {
        data: {
          $set: initialState.data
        }
      })

    default:
      return state
  }
}

export default googleDocumentReducer
