import React from 'react'
import { Grid, withStyles } from '@material-ui/core'

import DeviceNameViewCell from './DeviceTable/DeviceNameViewCell'
import { useUserRole } from 'hooks/tableLibrary'

function styles({ type, typography }) {
  return {
    deviceCellWrapper: {
      padding: '5px 0'
    },
    aliasWrap: {
      display: 'flex',
      color: '#2C2D3A',
      fontSize: '0.8125rem',
      fontWeight: 700,
      lineHeight: '1.667em'
    },
    label: {
      ...typography.subtitle[type]
    }
  }
}

const DeviceNameCell = ({ item, classes }) => {
  const role = useUserRole()
  return (
    <Grid item xs className={classes.deviceCellWrapper}>
      <DeviceNameViewCell role={role} classes={classes} row={item} />
    </Grid>
  )
}

export default withStyles(styles)(DeviceNameCell)
