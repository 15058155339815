import { createSelector } from 'reselect'

const _confirmationSelector = ({ appReducer }) =>
  appReducer.isConfirmationRequired

export const confirmationSelector = createSelector(
  _confirmationSelector,
  isConfirmationRequired => isConfirmationRequired
)

export const navigationStateSelector = ({ appReducer }) =>
  appReducer.isNavigationDisabled

const _lastDndDropIdSelector = ({ appReducer }) => appReducer.dndLastDropId

export const lastDndDropIdSelector = createSelector(
  _lastDndDropIdSelector,
  dndLastDropId => dndLastDropId
)

const _tooltipStateSelector = ({ appReducer }) => appReducer.openedTooltips

export const tooltipStateSelector = createSelector(
  _tooltipStateSelector,
  openedTooltips => openedTooltips
)

export const topBannerPreviewSelector = ({ appSliceReducer }) =>
  appSliceReducer.topBannerPreview

export const viewSettingsSelector = ({ appSliceReducer }) =>
  appSliceReducer.viewSettings
