import { createSelector } from 'reselect'

export function capAlertDevicesSelector({ device }) {
  return device.capAlertDevices
}

const _deviceLibrarySelector = ({ device }) => ({
  ...device.library,
  meta: device.meta
})

export const deviceLibrarySelector = createSelector(
  _deviceLibrarySelector,
  deviceLibrary => deviceLibrary
)

export const deviceLocationGroupDevicesSelector = ({ device }) =>
  device.locationGroupDevices

export const deleteDevice = ({ device }) => device.delete
export const postDeviceBulk = ({ device }) => device.postBulk

const _deviceLocationsSelector = ({ device }) => device.deviceLocations

export const deviceLocationsSelector = createSelector(
  _deviceLocationsSelector,
  deviceLocations => deviceLocations
)

const _postDeviceLocationsSelector = ({ device }) => device.postDeviceLocation
const _putDeviceLocationsSelector = ({ device }) => device.putDeviceLocation
const _deleteDeviceLocationsSelector = ({ device }) =>
  device.deleteDeviceLocation

export const deviceLocationsItemStateSelector = createSelector(
  _postDeviceLocationsSelector,
  _putDeviceLocationsSelector,
  _deleteDeviceLocationsSelector,
  (post, put, del) => ({ post, put, del })
)

const _deviceGroupsSelector = ({ device }) => device.groups

export const deviceGroupsSelector = createSelector(
  _deviceGroupsSelector,
  groups => groups
)

const _deviceGroupItemsSelector = ({ device }) => device.groupItems

export const deviceGroupItemsSelector = createSelector(
  _deviceGroupItemsSelector,
  groupItems => groupItems
)

export const locationCategoriesSelector = ({ device }) =>
  device.locationCategories

export const scheduledDeviceIdsSelector = ({ device }) =>
  device.scheduledDeviceIds

export const deviceLogsSelector = ({ device }) => device.deviceLogs

export const deviceCecSettingsSelector = ({ device }) => device.cecSettings

export const devicePutCecSettingsSelector = ({ device }) =>
  device.putCecSettings

const _deviceVirtualPlayerStatsSelector = ({ device }) =>
  device.virtualPlayerStats

export const deviceVirtualPlayerStatsSelector = createSelector(
  _deviceVirtualPlayerStatsSelector,
  state => state
)

export const postIpCIDRSelector = ({ device }) => device.postIpCIDR

export const putIpCIDRSelector = ({ device }) => device.putIpCIDR

export const deleteIpCIDRSelector = ({ device }) => device.deleteIpCIDR

export const ipCIDRItemsSelector = ({ device }) => device.ipCIDRItems
