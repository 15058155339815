import React from 'react'
import { Grid } from '@material-ui/core'

import DeviceRamViewCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceRamViewCell'

const DeviceRamAgCell = ({ data }) => {
  const { memoryUtilization, osVersion, totalMemory, avgMemory } = data

  return (
    <Grid container justifyContent="center">
      <DeviceRamViewCell
        memory={memoryUtilization}
        osVersion={osVersion}
        totalMemory={totalMemory}
        avgMemory={avgMemory}
      />
    </Grid>
  )
}

export default DeviceRamAgCell
