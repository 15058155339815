import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import PopupRow from './PopupRow'
import PopupButton from './PopupButton'
import MaterialPopup from 'components/Popup/MaterialPopup'
import Spacing from 'components/Containers/Spacing'
import { FormControlInput } from 'components/Form'
import Scrollbars from 'components/Scrollbars'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import { simulateEvent } from 'utils'
import { sortByLabel } from 'utils/libraryUtils'
import isObject from 'lodash/isObject'
import { isNumber } from 'utils/generalUtils'

const useStyles = makeStyles(({ colors, spacing }) => ({
  icon: {
    color: colors.light,
    marginRight: 4,
    minWidth: 16
  },
  popupContainer: ({ customWidth }) => ({
    width: customWidth ? customWidth : 250
  }),
  'horizontalMargin-0': {
    marginLeft: 0,
    marginRight: 0
  },
  'horizontalMargin-0.5': {
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5)
  }
}))

const FormControlButtonSelect = ({
  onChange,
  value,
  name,
  label,
  options,
  isMulti,
  searchable,
  customWidth,
  horizontalMargin
}) => {
  const classes = useStyles({ customWidth })
  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const getChangedValues = option =>
    Array.isArray(value)
      ? value.some(item => item.value === option.value)
        ? value.filter(item => item.value !== option.value)
        : [...value, option]
      : isNumber(value)
      ? options.filter(({ value }) => option.value === value)
      : options.filter(({ label }) => option.label === label)

  const handleSelect = option => {
    onChange(
      simulateEvent(name, isMulti ? getChangedValues(option) : option.value)
    )
  }

  const handleClear = () => {
    onChange(simulateEvent(name, isMulti ? [] : ''))
    setSearch('')
  }

  const updatedValue =
    isMulti && !isObject(value)
      ? isNumber(value)
        ? options.filter(option => option.value === value)
        : options.filter(option => option.label === value)
      : value

  const sortedOptions = sortByLabel(options)

  const filteredOptions = sortedOptions.filter(({ label }) =>
    label.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <MaterialPopup
      on="click"
      placement="bottom-start"
      trigger={
        <span className={classes[`horizontalMargin-${horizontalMargin}`]}>
          <PopupButton
            value={updatedValue}
            isMulti={isMulti}
            label={label}
            options={options}
            onClear={handleClear}
          />
        </span>
      }
    >
      <Spacing variant={0} rootClassName={classes.popupContainer}>
        {searchable && (
          <Spacing variant={0} paddingHor={2} paddingVert={3} borderBottom={1}>
            <FormControlInput
              placeholder={t('Search')}
              name={name}
              fullWidth
              value={search}
              handleChange={({ target: { value } }) => {
                setSearch(value)
              }}
              marginBottom={false}
            />
          </Spacing>
        )}
        <Spacing variant={3} paddingTop={searchable ? 0 : 3}>
          <Scrollbars autoHeight autoHeightMax={300}>
            {filteredOptions.map((option, index, array) => {
              const selected = isMulti
                ? updatedValue?.some(item => item.value === option.value)
                : updatedValue === option.value

              return (
                <PopupRow
                  key={option.value}
                  selected={selected}
                  option={option}
                  onSelect={handleSelect}
                />
              )
            })}
            {!filteredOptions.length && (
              <EmptyPlaceholder text={t('No Results Found')} fullHeight />
            )}
          </Scrollbars>
        </Spacing>
      </Spacing>
    </MaterialPopup>
  )
}

FormControlButtonSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  searchable: PropTypes.bool,
  horizontalMargin: PropTypes.oneOf([0, 0.5])
}

export default FormControlButtonSelect
