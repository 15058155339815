import { _concat } from 'utils/lodash'
import update from 'immutability-helper'

import * as types from 'actions'
import { shapeOfBodyWithMeta } from 'constants/initialLibraryState'
import {
  convertDataToBackgroundImages,
  convertDataToEmojis,
  convertDataToIconGroups,
  convertDataToIcons,
  convertDataToObjects,
  convertDataToPattern,
  convertDataToShape
} from 'utils/designGalleryUtils'

const shape = {
  response: [],
  meta: {},
  isFetching: true
}

const initialState = {
  patterns: {
    ...shape,
    filter: {
      name: ''
    }
  },
  backgroundImages: {
    ...shape
  },
  shapes: {
    ...shape
  },
  icons: {
    ...shape
  },
  iconsGroup: {
    ...shape
  },
  emojis: {
    ...shape
  },
  objects: {
    ...shape
  },
  stockImages: {
    ...shapeOfBodyWithMeta
  }
}

const leftSidebarReducer = (
  state = initialState,
  { action, data = {}, type, payload, meta: actionMeta }
) => {
  switch (type) {
    // PATTERNS
    case types.GET_MENU_MAKER_PATTERNS:
      return update(state, {
        patterns: {
          isFetching: { $set: true },
          ...((data.name || '') !== state.patterns.filter.name && {
            response: { $set: [] }
          })
        }
      })
    case types.GET_MENU_MAKER_PATTERNS_SUCCESS: {
      const patterns = convertDataToPattern(payload.data)

      const patternsArr =
        payload.meta.currentPage === 1
          ? patterns
          : _concat(state.patterns.response, patterns)

      return update(state, {
        patterns: {
          response: { $set: patternsArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false },
          filter: { $set: { name: actionMeta.name || '' } }
        }
      })
    }
    case types.GET_MENU_MAKER_PATTERNS_ERROR:
      return update(state, {
        patterns: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    // SHAPES
    case types.GET_MENU_MAKER_SHAPES:
      return update(state, {
        shapes: {
          isFetching: { $set: true },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_MENU_MAKER_SHAPES_SUCCESS:
      const shapes = convertDataToShape(payload.response)

      const shapesArr =
        payload.meta.currentPage === 1
          ? shapes
          : _concat(state.shapes.response, shapes)
      return update(state, {
        shapes: {
          response: { $set: shapesArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_MENU_MAKER_SHAPES_ERROR:
      return update(state, {
        shapes: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    case types.GET_MENU_MAKER_OBJECTS:
      return update(state, {
        objects: {
          isFetching: { $set: true },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_MENU_MAKER_OBJECTS_SUCCESS:
      const objects = convertDataToObjects(payload.response)

      const objectsArr =
        payload.meta.currentPage === 1
          ? objects
          : _concat(state.objects.response, objects)
      return update(state, {
        objects: {
          response: { $set: objectsArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_MENU_MAKER_OBJECTS_ERROR:
      return update(state, {
        objects: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    // ICONS
    case types.GET_MENU_MAKER_ICONS:
      return update(state, {
        icons: {
          isFetching: { $set: true },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_MENU_MAKER_ICONS_SUCCESS:
      const icons = convertDataToIcons(payload.response)

      const iconsArr =
        payload.meta.currentPage === 1
          ? icons
          : _concat(state.icons.response, icons)

      return update(state, {
        icons: {
          response: { $set: iconsArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false }
        }
      })

    case types.GET_MENU_MAKER_ICONS_ERROR:
      return update(state, {
        icons: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })
    case types.GET_MENU_MAKER_ICONS_GROUP:
      return update(state, {
        iconsGroup: {
          isFetching: { $set: true }
        }
      })
    case types.GET_MENU_MAKER_ICONS_GROUP_SUCCESS: {
      const iconsData = convertDataToIconGroups(payload)
      return update(state, {
        iconsGroup: {
          response: { $set: iconsData },
          isFetching: { $set: false }
        }
      })
    }
    case types.GET_MENU_MAKER_ICONS_GROUP_ERROR:
      return update(state, {
        iconsGroup: {
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_MENU_MAKER_ICONS:
      return update(state, {
        icons: { $set: shape }
      })

    // EMOJIS
    case types.GET_MENU_MAKER_EMOJIS:
      return update(state, {
        emojis: {
          isFetching: { $set: true },
          ...(data.page === 1 && { response: { $set: [] } })
        }
      })
    case types.GET_MENU_MAKER_EMOJIS_SUCCESS:
      const emojis = convertDataToEmojis(payload.response)

      const emojisArr =
        payload.meta === 1 ? emojis : _concat(state.emojis.response, emojis)

      return update(state, {
        emojis: {
          response: { $set: emojisArr },
          meta: { $set: payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_MENU_MAKER_EMOJIS_ERROR:
      return update(state, {
        emojis: {
          isFetching: { $set: false },
          error: { $set: payload }
        }
      })

    // BACKGROUND IMAGES
    case types.GET_MENU_MAKER_BACKGROUND_IMAGES:
      return {
        ...state,
        isFetching: true
      }
    case types.GET_MENU_MAKER_BACKGROUND_IMAGES_SUCCESS:
      const backgroundImages = convertDataToBackgroundImages(payload.data)

      return {
        ...state,
        backgroundImages: {
          ...payload,
          data: backgroundImages
        },
        isFetching: false
      }
    case types.GET_MENU_MAKER_BACKGROUND_IMAGES_ERROR:
      return {
        ...state,
        backgroundImages: {
          ...payload,
          data: []
        },
        isFetching: false
      }

    // STOCK IMAGES
    case types.GET_MENU_MAKER_STOCK_IMAGES:
      return update(state, {
        stockImages: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_MENU_MAKER_STOCK_IMAGES_SUCCESS:
      return update(state, {
        stockImages: {
          response: {
            $set: payload
          },
          meta: {
            $set: actionMeta
          }
        }
      })
    case types.GET_MENU_MAKER_STOCK_IMAGES_ERROR:
      return update(state, {
        stockImages: {
          error: {
            $set: action.payload
          }
        }
      })

    case types.CLEAR_MM_SIDEBAR:
      return {
        ...state,
        ...initialState
      }

    default:
      return state
  }
}

export default leftSidebarReducer
