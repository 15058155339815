import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { ClickAwayListener, Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import MenuItem from './MenuItem'
import { ContextMenuContext } from 'contexts'
import { zIndexes } from 'constants/stylesConstants'

const MENU_ITEM_HEIGHT = 38
const MENU_WIDTH = 225

const useStyles = makeStyles(({ colors, type, spacing }) => ({
  root: {
    width: MENU_WIDTH,
    position: 'absolute',
    top: ({ position }) => position.y,
    left: ({ position }) => position.x,
    zIndex: zIndexes.materialPopup,
    display: ({ open }) => (open ? 'block' : 'none'),
    backgroundColor: colors.background.primary[type],
    borderRadius: 4,
    boxShadow: '0 0 8px rgba(0,0,0,0.3)',
    padding: `${spacing(1)}px 0`
  },
  flipHorizontal: {
    transform: 'translateY(-100%)'
  },
  flipVertical: {
    transform: 'translateX(-100%)'
  },
  flipBoth: {
    transform: 'translate(-100%, -100%)'
  }
}))

const ContextMenu = () => {
  const { open, position, items, closeContextMenu } = useContext(
    ContextMenuContext
  )
  const classes = useStyles({ position, open, itemsLength: items.length })

  const menuItems = useMemo(
    () =>
      items.map((item, index) => (
        <MenuItem key={index} item={item} closeContextMenu={closeContextMenu} />
      )),
    [items, closeContextMenu]
  )

  const handleContextMenuEvent = useCallback(
    e => {
      if (!e.defaultPrevented) {
        closeContextMenu()
      }
    },
    [closeContextMenu]
  )

  useEffect(() => {
    window.addEventListener('contextmenu', handleContextMenuEvent)

    return () => {
      window.removeEventListener('contextmenu', handleContextMenuEvent)
    }
  }, [handleContextMenuEvent])

  if (!menuItems.length) {
    return null
  }

  const flipHorizontal =
    window.innerHeight < position.y + items.length * MENU_ITEM_HEIGHT
  const flipVertical = window.innerWidth < position.x + MENU_WIDTH

  return (
    <ClickAwayListener onClickAway={closeContextMenu}>
      <Grid
        container
        direction="column"
        className={classNames(classes.root, {
          [classes.flipHorizontal]: flipHorizontal && !flipVertical,
          [classes.flipVertical]: flipVertical && !flipHorizontal,
          [classes.flipBoth]: flipHorizontal && flipVertical
        })}
        onContextMenu={e => e.preventDefault()}
      >
        {menuItems}
      </Grid>
    </ClickAwayListener>
  )
}

export default ContextMenu
