export const popupPosition = {
  BOTTOM_CENTER: 'bottom center',
  BOTTOM_LEFT: 'bottom left',
  BOTTOM_RIGHT: 'bottom right',
  TOP_CENTER: 'top center',
  TOP_LEFT: 'top left',
  TOP_RIGHT: 'top right',
  LEFT_CENTER: 'left center',
  LEFT_TOP: 'left top',
  LEFT_BOTTOM: 'left bottom',
  RIGHT_CENTER: 'right center',
  RIGHT_TOP: 'right top',
  RIGHT_BOTTOM: 'right bottom'
}

const basicZIdexes = {
  elevated: 1,
  sideModal: 11,
  confirmation: 1400,
  materialPopup: 2000,
  selectMenu: 9999,
  tooltip: 99999
}

export const zIndexes = {
  confirmation: basicZIdexes.confirmation,
  elevated: basicZIdexes.elevated,
  stickyTableHeaderColumn: basicZIdexes.elevated + 1,
  tableHeaderFilterPopup: basicZIdexes.elevated + 2,
  materialPopup: basicZIdexes.materialPopup,
  sideModal: basicZIdexes.sideModal,
  selectMenu: basicZIdexes.selectMenu,
  tooltip: basicZIdexes.tooltip
}

export const getEulaIframeStyles = isTextContent => `
  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: "Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",
          Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji",
          "Segoe UI Emoji","Segoe UI Symbol";
  }
  .htmlContent {
     overflow-y: auto;
     max-height: 100%;
     ${isTextContent ? 'overflow-wrap: break-word;' : ''}
  }
  .htmlContent::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .htmlContent::-webkit-scrollbar-thumb {
    background-color: #c4cad9;
    border-radius: 5px;
  }
  @font-face {
    font-family: 'Nunito Sans';
    src: url('${
      process.env.PUBLIC_URL
    }/assets-fe/fonts/NunitoSans/NunitoSans-Regular.woff2')
    format('woff2'),
    url('${
      process.env.PUBLIC_URL
    }/assets-fe/fonts/NunitoSans/NunitoSans-Regular.woff')
    format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nunito Sans';
    src: url('${
      process.env.PUBLIC_URL
    }/assets-fe/fonts/NunitoSans/NunitoSans-Bold.woff2')
    format('woff2'),
    url('${
      process.env.PUBLIC_URL
    }/assets-fe/fonts/NunitoSans/NunitoSans-Bold.woff')
    format('woff');
    font-weight: bold;
    font-style: normal;
  }`

export const filterContentWidth = 281

export const tagCustomStyles = {
  multiValue: ({ data }) => ({
    borderColor: data.border || '#fd7b25',
    background: data.background || '#fd7b25'
  }),
  multiValueLabel: ({ data }) => ({
    color: data.color || 'rgba(255,255,255,1)'
  }),
  multiValueRemove: ({ data }) => ({
    'svg path': {
      fill: data.color || '#fd7b25'
    },
    ':hover': {
      backgroundColor: data.color || '#fd7b25',
      'svg path': {
        fill: data.background
      }
    }
  })
}

export const PAGE_CONTAINER_CLASS_NAME = 'pageContainer'

export const createTemplateSVGTransition = { duration: 0.3, ease: 'easeOut' }

const SELECT_ARROW_INDICATOR_CONTAINER_WIDTH = 20
const SELECT_ARROW_INDICATOR_WIDTH = 12
export const selectControlHorizontalPadding =
  (SELECT_ARROW_INDICATOR_CONTAINER_WIDTH - SELECT_ARROW_INDICATOR_WIDTH) / 2
