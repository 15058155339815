import React, { useMemo } from 'react'
import { BlueButton, WhiteButton } from 'components/Buttons'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = () => ({
  actionCancel: {
    marginRight: 16
  },
  footerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const FooterLayout = ({
  t,
  classes,
  disabledSubmit = false,
  disabledReset = false,
  opaqueSubmit = false,
  isUpdate = false,
  isClone = false,
  isPending = false,
  onSubmit = f => f,
  onReset = f => f,
  submitButtonProps = {},
  submitIconName = 'fa-regular fa-circle-plus',
  resetIconName = 'fa-regular fa-circle-arrow-left',
  submitLabel
}) => {
  const translate = useMemo(
    () => ({
      update: t('Update'),
      pending: t('Pending'),
      add: t('Add'),
      reset: t('Reset'),
      clone: t('Clone')
    }),
    [t]
  )

  return (
    <div className={classes.footerContainer}>
      <WhiteButton
        className={classes.actionCancel}
        onClick={onReset}
        classes={{ iconColor: classes.iconColor }}
        iconClassName={resetIconName}
        variant="danger"
        disabled={disabledReset}
      >
        {translate.reset}
      </WhiteButton>
      <BlueButton
        classes={{ iconColor: classes.iconColor }}
        type="submit"
        onClick={onSubmit}
        opaque={opaqueSubmit}
        disabled={isPending || disabledSubmit}
        iconClassName={submitIconName}
        {...submitButtonProps}
      >
        {submitLabel ||
          (isPending
            ? translate.pending
            : isUpdate
            ? translate.update
            : isClone
            ? translate.clone
            : translate.add)}
      </BlueButton>
    </div>
  )
}

FooterLayout.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  isClone: PropTypes.bool,
  isPending: PropTypes.bool,
  titleSubmit: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  opaqueSubmit: PropTypes.bool,
  submitIconName: PropTypes.string,
  resetIconName: PropTypes.string
}

export default withTranslation('translations')(withStyles(styles)(FooterLayout))
