import { call, put } from 'redux-saga/effects'

import * as types from '../actions'

import { calendarAggregatorService } from '../services'

function* getRemoteCalendars({ params }) {
  try {
    const response = yield call(
      calendarAggregatorService.getRemoteCalendars,
      params
    )

    yield put({
      type: types.GET_REMOTE_CALENDARS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_REMOTE_CALENDARS_ERROR,
      payload: error
    })
  }
}

const calendarAggregatorSaga = {
  getRemoteCalendars
}

export default calendarAggregatorSaga
