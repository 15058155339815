import React, { useCallback, useContext, useState } from 'react'
import { withStyles } from '@material-ui/core/index'
import classNames from 'classnames'

import Spacing from 'components/Containers/Spacing'
import { PreviewSettingsContext } from 'components/Media/MediaPreviewSettingsContext'
import { SETTINGS_WIDTH } from 'constants/previewModal'

const styles = ({ palette, type }) => ({
  root: {
    zIndex: 9,
    height: '100%',
    width: SETTINGS_WIDTH,
    minWidth: SETTINGS_WIDTH,
    backgroundColor:
      palette[type].pages.createTemplate.settings.expansion.body.background,
    transition: 'margin 0.4s ease',
    marginRight: -SETTINGS_WIDTH,
    border: `1px solid rgba(0, 0, 0, 0)` //width artifacts fix
  },
  rootOpen: {
    marginRight: 0
  },
  toggleButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-20px, -100%)',
    content: '',
    color: palette[type].pages.createTemplate.settings.expansion.header.color,
    backgroundColor:
      palette[type].pages.createTemplate.settings.expansion.body.background,
    zIndex: 10,
    height: 60,
    width: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    boxShadow: '-3px 0px 4px -2px rgba(0, 0, 0, 0.0898984)'
  },
  sidebarWrap: {
    height: '100%'
  },
  contentWrapper: {
    overflow: 'hidden',
    overflowY: 'scroll',
    height: '100%',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      width: 0
    }
  }
})

const ThemeSettings = ({ classes, onOpenChange }) => {
  const { content } = useContext(PreviewSettingsContext)

  const [open, setOpen] = useState(false)

  const toggleSidebar = useCallback(() => {
    onOpenChange(!open)
    setOpen(prevState => !prevState)
  }, [open, onOpenChange])

  return (
    <>
      {content && (
        <div
          className={classNames(classes.root, {
            [classes.rootOpen]: open
          })}
        >
          <div className={classes.toggleButton} onClick={toggleSidebar}>
            {open ? (
              <i className="fa fa-chevron-right" />
            ) : (
              <i className="fa fa-chevron-left" />
            )}
          </div>
          <Spacing
            variant={0}
            justifyContent="space-between"
            className={classes.sidebarWrap}
            height="full"
          >
            <div className={classes.contentWrapper}>{content}</div>
          </Spacing>
        </div>
      )}
    </>
  )
}

export default withStyles(styles)(ThemeSettings)
