export const CLIENT_SETTINGS_PENDING = 'CLIENT_MODULE/CLIENT_SETTINGS_PENDING'
export const CLIENT_SETTINGS_WITHOUT_PENDING =
  'CLIENT_MODULE/CLIENT_SETTINGS_WITHOUT_PENDING'
export const CLIENT_SETTINGS_SUCCESS = 'CLIENT_MODULE/CLIENT_SETTINGS_SUCCESS'
export const CLIENT_SETTINGS_FAILURE = 'CLIENT_MODULE/CLIENT_SETTINGS_FAILURE'
export const CLEAR_CLIENT_STORE = 'CLIENT_MODULE/CLEAR_CLIENT_STORE'

export function fetchClientSettings() {
  return {
    type: CLIENT_SETTINGS_PENDING
  }
}

export function fetchClientSettingsWithoutPending() {
  return {
    type: CLIENT_SETTINGS_WITHOUT_PENDING
  }
}

export function clientSettingsSuccess(data) {
  return {
    type: CLIENT_SETTINGS_SUCCESS,
    data
  }
}

export function clientSettingsFailure() {
  return {
    type: CLIENT_SETTINGS_FAILURE
  }
}

export function clearClientStore() {
  return {
    type: CLEAR_CLIENT_STORE
  }
}
