import { createAppApi } from 'services/api'

export const upgradeRequestApi = createAppApi('upgradeRequestApi', {
  endpoints: builder => ({
    update: builder.mutation({
      query: data => ({
        method: 'POST',
        url: '/upgradePlanRequest',
        data: data
      })
    })
  })
})

export const { useUpdateMutation } = upgradeRequestApi
