import { number, object, mixed, string } from 'yup'
import update from 'immutability-helper'

import { hex2rgba } from 'components/Pages/DesignGallery/utils'
import { numbersToPx } from './numbers'
import { createTemplateConstants } from '../constants'
import {
  backgroundTypes,
  DEFAULT_BACKGROUND_COLOR,
  MIN_TOUCH_CONTENT_DURATION,
  POPUP_BUBBLE_HEIGHT,
  POPUP_BUBBLE_WIDTH
} from 'constants/createTemplate'
import { requiredField } from 'constants/validationMessages'
import { touchSettingsBubbleLocations } from 'constants/media'

export const calculateSizeForRender = ({
  width = 0,
  height = 0,
  multiplier,
  videoWall = { active: false }
}) => {
  if (!videoWall.active) {
    return {
      width: Math.round(width / multiplier),
      height: Math.round(height / multiplier)
    }
  } else {
    const w1 = Math.round(width / multiplier)
    const h1 = Math.round(height / multiplier)

    return {
      width: w1 * videoWall.props.x,
      height: h1 * videoWall.props.y
    }
  }
}

export const calculateSizeForConfig = ({
  width = 0,
  height = 0,
  multiplier
}) => ({
  width: Math.floor(width * multiplier),
  height: Math.floor(height * multiplier)
})

export const calculatePositionForRender = ({ x = 0, y = 0, multiplier }) => ({
  x: Math.round(x / multiplier),
  y: Math.round(y / multiplier)
})

export const calculatePositionForConfig = ({ x, y, multiplier }) => ({
  x: Math.round(x * multiplier),
  y: Math.round(y * multiplier)
})

export const calculateMultiplier = (size, videoWall, orientation) => {
  const defaultMult =
    Math.max(size.width, size.height) /
    (orientation === createTemplateConstants.LANDSCAPE
      ? createTemplateConstants.MAX_WIDTH_LANDSCAPE
      : createTemplateConstants.MAX_HEIGHT_PORTRAIT)
  const { x, y } = videoWall.props

  let multiplier = defaultMult

  if (!videoWall.active || videoWall.zoomed) return multiplier

  if (orientation === createTemplateConstants.LANDSCAPE) {
    if (x === 1 && y === 1) {
      multiplier = defaultMult
    } else if (x <= 2 && y <= 2) {
      multiplier = defaultMult * 2.0513
    } else if (x <= 3 && y <= 3) {
      multiplier = defaultMult * 3
    } else if (x <= 4 && y <= 4) {
      multiplier = defaultMult * 4
    } else if (x <= 5 && y <= 5) {
      multiplier = defaultMult * 5
    } else if (x <= 6 && y <= 6) {
      multiplier = defaultMult * 6
    } else if (x <= 7 && y <= 7) {
      multiplier = defaultMult * 7
    } else if (x <= 8 && y <= 8) {
      multiplier = defaultMult * 8
    } else if (x <= 9 && y <= 9) {
      multiplier = defaultMult * 9
    } else if (x <= 10 && y <= 10) {
      multiplier = defaultMult * 10
    } else if (x <= 11 && y <= 11) {
      multiplier = defaultMult * 11
    } else if (x <= 12 && y <= 12) {
      multiplier = defaultMult * 12.05
    }
  } else {
    if (x === 1 && y === 1) {
      multiplier = defaultMult
    } else if (x === 1 && y === 2) {
      multiplier = defaultMult * 2.43
    } else if (x === 2 && y === 1) {
      multiplier = defaultMult * 1.02
    } else if (
      (x === 2 && y === 2) ||
      (x === 3 && y === 2) ||
      (x === 4 && y === 2)
    ) {
      multiplier = defaultMult * 2
    } else if (
      (x === 1 && y === 3) ||
      (x === 2 && y === 3) ||
      (x === 3 && y === 3) ||
      (x === 4 && y === 3)
    ) {
      multiplier = defaultMult * 3.64
    } else if (x === 3 && y === 1) {
      if (size.width === 1280 && size.height === 768) {
        multiplier = 1.8
      } else if (size.width === 1280 && size.height === 728) {
        multiplier = 1.7
      } else if (size.width === 1024 && size.height === 768) {
        multiplier = 1.79
      } else if (size.width === 960 && size.height === 600) {
        multiplier = 1.45
      } else if (size.width === 800 && size.height === 600) {
        multiplier = 1.4
      } else if (size.width === 700 && size.height === 450) {
        multiplier = 1.05
      } else {
        multiplier = defaultMult * 1.12
      }
    } else if (
      (x === 1 && y === 4) ||
      (x === 2 && y === 4) ||
      (x === 3 && y === 4)
    ) {
      multiplier = defaultMult * 4.87
    } else if (x === 4 && y === 1) {
      if (
        (size.width === 1280 && size.height === 768) ||
        (size.width === 1024 && size.height === 768)
      ) {
        multiplier = 2.4
      } else if (
        (size.width === 960 && size.height === 600) ||
        (size.width === 800 && size.height === 600)
      ) {
        multiplier = 1.87
      } else if (size.width === 700 && size.height === 450) {
        multiplier = 1.4
      } else {
        multiplier = defaultMult * 1.5
      }
    } else if ((x === 1 && y === 5) || (x === 2 && y === 5)) {
      multiplier = defaultMult * 6.11
    } else if ((x === 5 && y === 1) || (x === 5 && y === 2)) {
      if (
        (size.width === 1280 && size.height === 768) ||
        (size.width === 1024 && size.height === 768)
      ) {
        multiplier = 3
      } else if (
        (size.width === 960 && size.height === 600) ||
        (size.width === 800 && size.height === 600)
      ) {
        multiplier = 2.34
      } else if (size.width === 700 && size.height === 450) {
        multiplier = 1.75
      } else {
        multiplier = defaultMult * 1.87
      }
    } else if ((x === 1 && y === 6) || (x === 2 && y === 6)) {
      multiplier = defaultMult * 7.35
    } else if ((x === 6 && y === 1) || (x === 6 && y === 2)) {
      if (
        (size.width === 1280 && size.height === 768) ||
        (size.width === 1024 && size.height === 768)
      ) {
        multiplier = 3.6
      } else if (
        (size.width === 960 && size.height === 600) ||
        (size.width === 800 && size.height === 600)
      ) {
        multiplier = 2.81
      } else if (size.width === 700 && size.height === 450) {
        multiplier = 2.1
      } else {
        multiplier = defaultMult * 2.24
      }
    } else if (x === 1 && y === 8) {
      multiplier = defaultMult * 9.77
    } else if (x === 8 && y === 1) {
      if (
        (size.width === 1280 && size.height === 768) ||
        (size.width === 1024 && size.height === 768)
      ) {
        multiplier = 4.8
      } else if (
        (size.width === 960 && size.height === 600) ||
        (size.width === 800 && size.height === 600)
      ) {
        multiplier = 3.75
      } else if (size.width === 700 && size.height === 450) {
        multiplier = 2.81
      } else {
        multiplier = defaultMult * 3
      }
    } else if (x === 1) {
      multiplier = defaultMult * (1.21 * y)
    } else if (y === 1) {
      if (
        (size.width === 1280 && size.height === 768) ||
        (size.width === 1024 && size.height === 768)
      ) {
        multiplier = 0.6 * x
      } else if (
        (size.width === 960 && size.height === 600) ||
        (size.width === 800 && size.height === 600)
      ) {
        multiplier = 0.47 * x
      } else if (size.width === 700 && size.height === 450) {
        multiplier = 0.36 * x
      } else {
        multiplier = defaultMult * (0.38 * x)
      }
    }
  }

  return multiplier
}

export const touchContentValidationSchema = object().shape({
  popupHeight: number().when(['playbackLocation', 'windowSize'], {
    is: (playbackLocation, windowSize) => {
      return playbackLocation === 'popupWindow'
        ? windowSize === 'custom'
        : false
    },
    then: number()
      .min(1, 'Number must be positive')
      .required('Field is required')
  }),
  popupWidth: number().when(['playbackLocation', 'windowSize'], {
    is: (playbackLocation, windowSize) => {
      return playbackLocation === 'popupWindow'
        ? windowSize === 'custom'
        : false
    },
    then: number()
      .min(1, 'Number must be positive')
      .required('Field is required')
  }),
  targetZone: mixed().when('playbackLocation', {
    is: playbackLocation => playbackLocation === 'existingZone',
    then: mixed().required('Select one of the existing zones')
  }),
  duration: string().test(
    'min_duration',
    'Duration must be at least 1 second',
    duration => duration !== '00:00:00'
  )
})

export const parseZoneSettings = (
  {
    styles: {
      borderWidth,
      borderColor,
      background,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      isZoomToFit,
      is_zone_background_color,
      rotate,
      hFlip,
      vFlip
    },
    zoneSettings,
    touchSettingJson = {}
  },
  disabledBorder = false,
  disableRotation = false
) => {
  return {
    ...(!disabledBorder && {
      zone_border_width: numbersToPx(borderWidth),
      zone_border_style: 'solid',
      zone_border_color: borderColor,
      zone_top_left_border_radius: numbersToPx(borderTopLeftRadius),
      zone_top_right_border_radius: numbersToPx(borderTopRightRadius),
      zone_bottom_left_border_radius: numbersToPx(borderBottomLeftRadius),
      zone_bottom_right_border_radius: numbersToPx(borderBottomRightRadius)
    }),
    ...(!disableRotation && {
      rotate: rotate,
      hFlip: hFlip,
      vFlip: vFlip
    }),
    is_zone_background_color: is_zone_background_color,
    zone_background_color: background,
    is_touch_zone: !!touchSettingJson.playback_content_id,
    is_zoom_to_fit: isZoomToFit,
    locked: !!zoneSettings.locked
  }
}

const getSettingsByLocation = ({ touchSettingJson: touch = {} }) => {
  switch (touch.playback_content_location) {
    case 'popup_window': {
      const isCustom = touch.playback_content_option === 'custom'
      return {
        playback_content_option: touch.playback_content_option,
        popup_height: isCustom ? touch.popup_height : null,
        popup_width: isCustom ? touch.popup_width : null
      }
    }
    case 'popup_bubble':
      return {
        bubble_theme: touch.bubble_theme,
        bubble_location: touch.bubble_location
      }
    case 'existing_zone':
      return {
        target_zone_no: touch.target_zone_no
      }
    default:
      return {}
  }
}

export const parseTouchSettings = item => {
  const { touchSettingJson: touch = {} } = item

  if (!touch.playback_content_id) {
    return null
  }

  return {
    playback_content_type: touch.playback_content_type,
    playback_content_id: touch.playback_content_id,
    playback_content_location: touch.playback_content_location,
    ...getSettingsByLocation(item),
    playback_reset_duration:
      touch.playback_reset_duration || MIN_TOUCH_CONTENT_DURATION,
    playback_transition_name: touch.playback_transition_name,
    closeButton: touch.closeButton
  }
}

export const templatePreviewItemCompare = (item1, item2) => {
  return (
    item1.id === item2.id &&
    item1.type === item2.type &&
    item1.isZoomToFit === item2.isZoomToFit
  )
}

export const getBackgroundData = ({
  bg_type,
  background,
  image_id,
  image_size,
  pattern
}) => {
  const backgroundColor = background
    ? background.includes('#')
      ? hex2rgba(background)
      : background
    : DEFAULT_BACKGROUND_COLOR

  const defaultData = {
    backgroundType: backgroundTypes.DEFAULT,
    backgroundColor: DEFAULT_BACKGROUND_COLOR
  }

  switch (bg_type) {
    case backgroundTypes.DEFAULT:
      return defaultData
    case backgroundTypes.NONE:
      return {
        backgroundType: bg_type,
        backgroundColor
      }
    case backgroundTypes.IMAGE:
      return image_id
        ? {
            backgroundType: bg_type,
            backgroundImageSetting: {
              image_id,
              image_size
            }
          }
        : defaultData
    case backgroundTypes.PATTERN:
      return pattern.id
        ? {
            backgroundType: bg_type,
            backgroundPatternId: pattern.id,
            backgroundColor
          }
        : defaultData
    default:
      return defaultData
  }
}

export const getTouchSettingsValidationSchema = t =>
  object().shape({
    playback_reset_duration: number().min(
      1,
      t('Reset Duration must be greater than or equal to 1')
    ),
    popup_height: number().when(
      ['playback_content_option', 'playback_content_location'],
      {
        is: (option, location) =>
          option === 'custom' && location === 'popup_window',
        then: number().nullable().min(200).required(t(requiredField)),
        otherwise: number().nullable()
      }
    ),
    popup_width: number().when(
      ['playback_content_option', 'playback_content_location'],
      {
        is: (option, location) =>
          option === 'custom' && location === 'popup_window',
        then: number().nullable().min(300).required(t(requiredField)),
        otherwise: number().nullable()
      }
    ),
    playback_content_id: number().required(t('Please select content'))
  })

export const getTransformScale = (origin, scaled) => {
  return origin && scaled ? scaled / origin : 1
}

export const canPopupFit = (positionHelper, containerSize, direction) => {
  switch (direction) {
    case touchSettingsBubbleLocations.TOP:
      return positionHelper.top >= POPUP_BUBBLE_HEIGHT
    case touchSettingsBubbleLocations.BOTTOM:
      return positionHelper.bottom <= containerSize.height - POPUP_BUBBLE_HEIGHT
    case touchSettingsBubbleLocations.LEFT:
      return positionHelper.left >= POPUP_BUBBLE_WIDTH
    case touchSettingsBubbleLocations.RIGHT:
      return positionHelper.right <= containerSize.width - POPUP_BUBBLE_WIDTH
    default:
      break
  }
  return true
}

export const bulkUpdateTemplateItemSuccess = (
  items = {},
  updatedMedias = []
) => {
  const _items = {}
  Object.entries(items).forEach(([key, value]) => {
    const media = updatedMedias.find(
      ({ id, title }) => id === value.media.id && title === value.media.title
    )
    if (media) {
      _items[key] = update(items[key], {
        media: {
          $merge: {
            ...media,
            updateRequired: false
          }
        }
      })
    }
  })

  return _items
}

export const bulkUpdateTemplateItemError = (
  items = {},
  payload = [],
  updateRequired = false
) => {
  const _items = {}
  const { mediaIds = [], playlistIds = [] } = payload

  Object.entries(items).forEach(([key, value]) => {
    const media = mediaIds.find(
      id => id === value.media.id && value.type !== 'Playlist'
    )
    const playlist =
      !media &&
      playlistIds.find(id => id === value.media.id && value.type === 'Playlist')

    if (media || playlist) {
      _items[key] = update(items[key], {
        media: {
          updateRequired: { $set: updateRequired }
        }
      })
    }
  })

  return _items
}

export const calculateSizeForVidowall = ({
  width = 0,
  height = 0,
  videoWall = { active: false }
}) => {
  if (!videoWall.active) {
    return {
      width,
      height
    }
  } else {
    return {
      width: width * videoWall.props.x,
      height: height * videoWall.props.y
    }
  }
}
