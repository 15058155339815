import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import moment from 'moment'

import MaterialPopup from 'components/Popup/MaterialPopup'
import FormControlInput from '../FormControlInput'
import Container from 'components/Containers/Container'
import {
  DATE_TIME_S_VIEW_FORMAT_2,
  DATE_TIME_VIEW_FORMAT_2,
  NORMAL_DATE_TIME_AP_FORMAT,
  NORMAL_DATE_TIME_S_AP_FORMAT,
  TIME_S_FORMAT
} from 'constants/dateTimeFormats'
import { simulateEvent } from 'utils/formik'
import getStyles from './styles'
import PropTypes from 'prop-types'
import TimePicker from './components/TimePickerNew'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Text } from 'components/Typography'

const useStyles = makeStyles(({ palette, type, spacing }) => ({
  container: ({ fullWidth }) => ({
    alignItems: 'flex-end',
    width: fullWidth ? '100%' : 'auto'
  }),
  noSeparate: {
    gap: '0px'
  },
  marginBottom: {
    marginBottom: spacing(2)
  },
  endAdornment: {
    position: 'absolute',
    right: '12px',
    height: 'calc(100% - 3px)',
    padding: '6px 10px',
    marginLeft: 4,
    display: 'grid',
    placeItems: 'center',
    marginRight: '-10px',
    background: palette[type].formControls.multipleDatesPicker.input.background,
    color: palette[type].formControls.multipleDatesPicker.input.color,
    borderLeft: `1px solid ${palette[type].formControls.multipleDatesPicker.input.border}`
  },
  dateRangeRoot: localValues => ({
    ...getStyles({ palette, type }, localValues)
  }),
  startTimeRoot: ({ hideStaticRanges }) => ({
    position: 'absolute',
    top: hideStaticRanges ? 20 : 68,
    left: 357,
    boxShadow: 'rgb(128 128 128 / 45%) 0px 0px 8px',
    borderRadius: '4px',
    height: hideStaticRanges
      ? 'calc(100% - 82px) !important'
      : 'calc(100% - 120px) !important',
    background: `${palette[type].formControls.multipleDatesPicker.popup.background}`
  }),
  endTimeRoot: ({ hideStaticRanges }) => ({
    position: 'absolute',
    top: hideStaticRanges ? 20 : 68,
    right: 10,
    boxShadow: 'rgb(128 128 128 / 45%) 0px 0px 8px',
    borderRadius: '4px',
    height: hideStaticRanges
      ? 'calc(100% - 82px) !important'
      : 'calc(100% - 120px) !important',
    background: `${palette[type].formControls.multipleDatesPicker.popup.background}`
  }),
  popupContent: {
    borderRadius: 4,
    overflow: 'hidden'
  },
  footerRoot: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: 10
  },
  timeBoldText: {
    fontWeight: 'bold'
  }
}))

const defaultStaticRanges = [
  {
    label: 'This Week',
    startDate: moment().startOf('isoWeek'),
    endDate: moment().endOf('isoWeek')
  },
  {
    label: 'Next Week',
    startDate: moment().add(1, 'weeks').startOf('isoWeek'),
    endDate: moment().add(1, 'weeks').endOf('isoWeek')
  },
  {
    label: 'Next 7 Days',
    startDate: moment(),
    endDate: moment().add(7, 'days')
  },
  {
    label: 'Current Month',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
  },
  {
    label: 'Next Month',
    startDate: moment().add(1, 'months').startOf('month'),
    endDate: moment().add(1, 'months').endOf('month')
  }
]

const FormControlDateTimeRangePickerNew = ({
  startDateTimeName = 'startDate',
  endDateTimeName = 'endDate',
  startDateTimeLabel,
  endDateTimeLabel,
  values = {},
  errors = {},
  touched = {},
  separateFields = true,
  formControlContainerClass,
  isSingleField = false,
  withPortal = false,
  marginBottom = true,
  fullWidth = false,
  onFocus = f => f,
  onBlur = f => f,
  onChange,
  maskValue: dateTimeMaskFormat,
  separatorText = '-',
  minDate = moment().format(NORMAL_DATE_TIME_AP_FORMAT),
  maxDate,
  labelPosition = 'top',
  disabled = false,
  disabledEndDate,
  disabledStartDate,
  inputProps = {},
  showSeconds = false,
  onDoubleClick,
  onBlurAll = f => f,
  autoFocus,
  tooltip,
  isBottomError,
  showErrorText = true,
  hideStaticRanges = false,
  staticRanges,
  footerComponent,
  showFooter = true,
  displayFormat: dateTimeDisplayFormat,
  isEndDateNullable
}) => {
  const classes = useStyles({
    fullWidth,
    showSeconds,
    hideStaticRanges
  })
  const [focusedInputs, setFocusedInputs] = useState({
    [startDateTimeName]: false,
    ...(isSingleField ? {} : { [endDateTimeName]: false })
  })
  const [localValues, setLocalValues] = useState([])
  const inputRef = useRef()

  const maskValue = useMemo(
    () =>
      dateTimeMaskFormat ||
      (showSeconds ? NORMAL_DATE_TIME_S_AP_FORMAT : NORMAL_DATE_TIME_AP_FORMAT),
    [showSeconds, dateTimeMaskFormat]
  )

  const displayFormat = useMemo(
    () =>
      dateTimeDisplayFormat ||
      (showSeconds ? NORMAL_DATE_TIME_S_AP_FORMAT : NORMAL_DATE_TIME_AP_FORMAT),
    [showSeconds, dateTimeDisplayFormat]
  )

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.click()
    }
  }, [autoFocus])

  useEffect(() => {
    setLocalValues([
      {
        startDate:
          disabledEndDate || disabledStartDate
            ? values[endDateTimeName] &&
              moment(values[endDateTimeName], maskValue).isValid()
              ? moment(values[endDateTimeName], maskValue).toDate()
              : new Date()
            : values[startDateTimeName] &&
              moment(values[startDateTimeName], maskValue).isValid()
            ? moment(values[startDateTimeName], maskValue).toDate()
            : new Date(),
        endDate:
          values[endDateTimeName] &&
          moment(values[endDateTimeName], maskValue).isValid()
            ? moment(values[endDateTimeName], maskValue).toDate()
            : isEndDateNullable
            ? null
            : new Date(),
        key: 'selection'
      }
    ])
    // eslint-disable-next-line
  }, [values])

  const handleChange = useCallback(
    ({ selection }) => {
      onChange(
        simulateEvent(
          startDateTimeName,
          moment(selection.startDate)
            .set({ hour: 0, minute: 0, second: 0 })
            .format(maskValue)
        )
      )
      onChange(
        simulateEvent(
          endDateTimeName,
          moment(selection.endDate)
            .set({ hour: 23, minute: 59, second: 59 })
            .format(maskValue)
        )
      )
    },
    [onChange, maskValue, startDateTimeName, endDateTimeName]
  )

  // end date is before start date issue after selecting time from time picker
  const handleChangeTime = useCallback(
    (t, name) => {
      const time = moment(t, TIME_S_FORMAT)
      onChange(
        simulateEvent(
          name,
          (values[name] ? moment(values[name], maskValue) : moment())
            .set({
              hour: time.get('hour'),
              minute: time.get('minute'),
              second: time.get('second')
            })
            .format(maskValue)
        )
      )
    },
    [onChange, values, maskValue]
  )

  const handleChangeStartTime = useCallback(
    time => {
      handleChangeTime(time, startDateTimeName)
    },
    [startDateTimeName, handleChangeTime]
  )

  const handleChangeEndTime = useCallback(
    time => {
      handleChangeTime(time, endDateTimeName)
    },
    [endDateTimeName, handleChangeTime]
  )

  const handleFocus = useCallback(
    event => {
      const {
        target: { name }
      } = event
      setFocusedInputs(f => ({
        ...f,
        [name]: true
      }))

      onFocus(event)
    },
    [onFocus]
  )

  const handleBlurStartDateTime = useCallback(
    value => {
      const startTimeObj = moment(value)
      const endTimeObj = moment(values[endDateTimeName], maskValue)
      if (!startTimeObj.isValid() || startTimeObj.isAfter(endTimeObj)) {
        onChange(simulateEvent(startDateTimeName, values[endDateTimeName]))
      } else {
        onChange(
          simulateEvent(startDateTimeName, startTimeObj.format(maskValue))
        )
      }
    },
    [onChange, values, startDateTimeName, endDateTimeName, maskValue]
  )

  const handleBlurEndDateTime = useCallback(
    value => {
      const endTimeObj = moment(value)
      const startTimeObj = moment(values[startDateTimeName], maskValue)
      if (!endTimeObj.isValid() || endTimeObj.isBefore(startTimeObj)) {
        onChange(simulateEvent(endDateTimeName, values[startDateTimeName]))
      } else {
        onChange(simulateEvent(endDateTimeName, endTimeObj.format(maskValue)))
      }
    },
    [onChange, values, startDateTimeName, endDateTimeName, maskValue]
  )

  const handleBlur = useCallback(
    event => {
      const {
        target: { name, value }
      } = event
      setFocusedInputs(f => ({
        ...f,
        [name]: false
      }))
      if (name === startDateTimeName) handleBlurStartDateTime(value)
      else if (name === endDateTimeName) handleBlurEndDateTime(value)
      onBlur(event)
    },
    [
      onBlur,
      startDateTimeName,
      endDateTimeName,
      handleBlurStartDateTime,
      handleBlurEndDateTime
    ]
  )

  const parsedEndDateValue = useMemo(
    () =>
      values[endDateTimeName] &&
      moment(values[endDateTimeName], maskValue).isValid()
        ? moment(values[endDateTimeName], maskValue).format(displayFormat)
        : values[endDateTimeName] || '',
    [values, endDateTimeName, maskValue, displayFormat]
  )

  const parseStartDateValue = useMemo(() => {
    const startDate =
      values[startDateTimeName] &&
      moment(values[startDateTimeName], maskValue).isValid()
        ? moment(values[startDateTimeName], maskValue).format(displayFormat)
        : values[startDateTimeName]
    return disabledEndDate || disabledStartDate
      ? parsedEndDateValue
      : isSingleField
      ? [startDate, parsedEndDateValue].join(` ${separatorText} `)
      : startDate
  }, [
    values,
    startDateTimeName,
    isSingleField,
    separatorText,
    disabledEndDate,
    disabledStartDate,
    displayFormat,
    maskValue,
    parsedEndDateValue
  ])

  const handleClose = useCallback(() => {
    if (!Object.values(focusedInputs).some(b => b === true)) {
      onBlurAll(values)
    }
  }, [onBlurAll, focusedInputs, values])

  const isErrorIcon = name =>
    !isBottomError && showErrorText && !!errors[name] && touched[name]

  const parsedStaticRanges = useMemo(() => {
    return createStaticRanges(
      (staticRanges || defaultStaticRanges).map(
        ({ label, startDate, endDate }) => ({
          label,
          range: () => ({
            startDate: startDate.isBefore(moment(minDate, maskValue))
              ? moment(minDate, maskValue).toDate()
              : startDate.toDate(),
            endDate: endDate.isBefore(moment(minDate, maskValue))
              ? moment(minDate, maskValue).toDate()
              : endDate.toDate()
          })
        })
      )
    )
  }, [minDate, maskValue, staticRanges])

  const handleChangeInput = useCallback(
    ({ target: { name, value } }) => {
      onChange(simulateEvent(name, value))
    },
    [onChange]
  )

  return (
    <MaterialPopup
      open={
        Object.values(focusedInputs).some(b => b === true) === true
          ? true
          : undefined
      }
      on="click"
      placement={'bottom'}
      onClose={handleClose}
      trigger={
        <Container
          customClass={classNames(
            classes.container,
            formControlContainerClass,
            {
              [classes.noSeparate]: !separateFields,
              [classes.marginBottom]: marginBottom
            }
          )}
          cols={isSingleField ? '1' : '2'}
        >
          <>
            <FormControlInput
              labelPosition={labelPosition}
              label={startDateTimeLabel}
              endAdornment={
                isErrorIcon(startDateTimeName) ? null : (
                  <div className={classes.endAdornment}>
                    <i className={'fa-regular fa-calendar-days'} />
                  </div>
                )
              }
              name={startDateTimeName}
              value={parseStartDateValue}
              onFocus={handleFocus}
              onChange={handleChangeInput}
              onBlur={handleBlur}
              fullWidth={fullWidth}
              error={errors[startDateTimeName]}
              touched={touched[startDateTimeName]}
              marginBottom={false}
              disabled={disabled || disabledStartDate}
              onDoubleClick={onDoubleClick}
              inputRef={inputRef}
              tooltip={tooltip}
              formControlRootClass={inputProps.formControlRootClass}
              showErrorText={showErrorText}
              {...inputProps}
            />
            {!isSingleField && (
              <FormControlInput
                labelPosition={labelPosition}
                label={endDateTimeLabel}
                endAdornment={
                  isErrorIcon(endDateTimeName) ? null : (
                    <div className={classes.endAdornment}>
                      <i className={'fa-regular fa-calendar-days'} />
                    </div>
                  )
                }
                name={endDateTimeName}
                value={parsedEndDateValue}
                onFocus={handleFocus}
                onChange={handleChangeInput}
                onBlur={handleBlur}
                fullWidth={fullWidth}
                error={errors[endDateTimeName]}
                touched={touched[endDateTimeName]}
                marginBottom={false}
                disabled={disabled || disabledEndDate}
                onDoubleClick={onDoubleClick}
                tooltip={tooltip}
                formControlRootClass={inputProps.formControlRootClass}
                showErrorText={showErrorText}
                {...inputProps}
              />
            )}
          </>
        </Container>
      }
      preventOverflow={
        withPortal
          ? {
              enabled: true,
              boundariesElement: 'viewport'
            }
          : {}
      }
      disabled={disabled}
      contentClassName={classes.popupContent}
    >
      <DateRangePicker
        className={classes.dateRangeRoot}
        onChange={handleChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={localValues}
        inputRanges={[]}
        staticRanges={hideStaticRanges ? [] : parsedStaticRanges}
        direction="horizontal"
        showDateDisplay={false}
        minDate={
          minDate && moment(minDate, maskValue).isValid()
            ? moment(minDate, maskValue).toDate()
            : undefined
        }
        maxDate={
          maxDate && moment(maxDate, maskValue).isValid()
            ? moment(maxDate, maskValue).toDate()
            : undefined
        }
        weekStartsOn={1}
      />
      <TimePicker
        rootClassName={classes.startTimeRoot}
        maskValue={TIME_S_FORMAT}
        value={moment(values[startDateTimeName], maskValue).format(
          TIME_S_FORMAT
        )}
        onChange={handleChangeStartTime}
        showSeconds={showSeconds}
        label={'Start Time'}
      />
      <TimePicker
        rootClassName={classes.endTimeRoot}
        maskValue={TIME_S_FORMAT}
        value={moment(values[endDateTimeName], maskValue).format(TIME_S_FORMAT)}
        onChange={handleChangeEndTime}
        showSeconds={showSeconds}
        label={'End Time'}
      />
      {showFooter && (
        <div className={classes.footerRoot}>
          {footerComponent || (
            <Text classes={classes.footerText}>
              Scheduled from&nbsp;
              <span className={classes.timeBoldText}>
                {!!values[startDateTimeName] &&
                  moment(values[startDateTimeName], maskValue).format(
                    showSeconds
                      ? DATE_TIME_S_VIEW_FORMAT_2
                      : DATE_TIME_VIEW_FORMAT_2
                  )}
              </span>
              &nbsp;to&nbsp;
              <span className={classes.timeBoldText}>
                {!!values[endDateTimeName] &&
                  moment(values[endDateTimeName], maskValue).format(
                    showSeconds
                      ? DATE_TIME_S_VIEW_FORMAT_2
                      : DATE_TIME_VIEW_FORMAT_2
                  )}
              </span>
            </Text>
          )}
        </div>
      )}
    </MaterialPopup>
  )
}

FormControlDateTimeRangePickerNew.propTypes = {
  startDateTimeName: PropTypes.string,
  endDateTimeName: PropTypes.string,
  startDateTimeLabel: PropTypes.string,
  endDateTimeLabel: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  separateFields: PropTypes.bool,
  formControlContainerClass: PropTypes.string,
  isSingleField: PropTypes.bool,
  withPortal: PropTypes.bool,
  marginBottom: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  maskValue: PropTypes.string,
  separatorText: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  showSeconds: PropTypes.bool,
  staticRanges: PropTypes.array,
  hideStaticRanges: PropTypes.bool,
  displayFormat: PropTypes.string
}

export default FormControlDateTimeRangePickerNew
