import update from 'immutability-helper'
import * as types from 'actions'
import { shapeOfBodyWithMeta } from 'constants/initialLibraryState'

const initialState = {
  library: shapeOfBodyWithMeta
}

const resellerClientUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESELLER_CLIENT_USERS:
      return update(state, {
        library: {
          meta: {
            isLoading: {
              $set: true
            }
          }
        }
      })
    case types.GET_RESELLER_CLIENT_USERS_SUCCESS:
      return update(state, {
        library: {
          response: {
            $set: action.response
          },
          meta: {
            $set: action.modifiedMeta
          }
        }
      })
    case types.GET_RESELLER_CLIENT_USERS_ERROR:
      return update(state, {
        library: {
          error: { $set: action.payload }
        }
      })
    default:
      return state
  }
}

export default resellerClientUserReducer
