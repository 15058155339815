import React, { useCallback, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { _get } from 'utils/lodash'
import update from 'immutability-helper'

import { Card } from 'components/Card'
import Scrollbars from 'components/Scrollbars'
import AlertRule from './AlertRule'
import BaseSection from '../BaseSection'
import { BlueButton } from 'components/Buttons'
import {
  alarmConditionInitialValue,
  getRandomColor
} from 'utils/deviceNocUtils'
import { useSelector } from 'react-redux'
import { alarmRuleValues } from 'constants/deviceNoc'

const styles = ({ palette, type }) => ({
  root: {
    margin: '0 0 15px',
    padding: 0,
    border: `5px solid ${palette[type].sideModal.content.border}`,
    background: palette[type].card.greyHeader.background,
    borderRadius: 4,
    maxHeight: 'calc(100% - 16px)',
    height: 'calc(100% - 78px)'
  },
  rulesListWrapper: {
    padding: '10px 20px'
  },
  addRuleIconWrapper: {
    marginRight: 25
  }
})

const AlertRuleSection = ({
  values,
  errors,
  touched,
  setFieldValue = f => f,
  classes,
  t
}) => {
  const { response: columns, isFetching } = useSelector(
    ({ deviceNoc: { alarm } }) => alarm.columns
  )
  const handleAddRule = useCallback(() => {
    setFieldValue('conditions', [
      ...values.conditions,
      {
        ...alarmConditionInitialValue,
        color: getRandomColor()
      }
    ])
    setFieldValue('rules', [...values.rules, alarmRuleValues.AND])
  }, [setFieldValue, values.conditions, values.rules])

  const renderAddRuleIcon = useMemo(
    () => (
      <div className={classes.addRuleIconWrapper}>
        <BlueButton
          onClick={handleAddRule}
          iconClassName="fa-regular fa-circle-plus"
        >
          {t('Add Rule')}
        </BlueButton>
      </div>
    ),
    [classes.addRuleIconWrapper, t, handleAddRule]
  )

  const handleChangeCondition = useCallback(
    index => ({ target: { name, value } }) => {
      setFieldValue(`conditions.${index}.${name}`, value)
    },
    [setFieldValue]
  )

  const handleChangeRule = useCallback(
    index => value => {
      if (index >= 0) {
        setFieldValue(`rules.${index}`, value)
      }
    },
    [setFieldValue]
  )

  const handleRemoveCondition = useCallback(
    index => () => {
      setFieldValue(
        'conditions',
        update(values.conditions, {
          $splice: [[index, 1]]
        })
      )

      setFieldValue(
        'rules',
        update(values.rules, {
          $splice: [[index - 1, 1]]
        })
      )
    },
    [setFieldValue, values.conditions, values.rules]
  )

  return (
    <BaseSection
      title={t('Rules')}
      iconButtonComponent={renderAddRuleIcon}
      icon
    >
      <Card
        icon={false}
        grayHeader
        shadow={false}
        radius={false}
        removeSidePaddings
        headerSidePaddings
        removeNegativeHeaderSideMargins
        rootClassName={classes.root}
      >
        <Scrollbars>
          <div className={classes.rulesListWrapper}>
            {values.conditions.map((condition, index) => (
              <AlertRule
                key={`alert-rule-section-${index}`}
                condition={condition}
                rule={index > 0 && values.rules[index - 1]}
                onChangeCondition={handleChangeCondition(index)}
                onChangeRule={handleChangeRule(index - 1)}
                index={index}
                errors={_get(errors, `conditions.${index}`, {})}
                touched={_get(touched, `conditions.${index}`, {})}
                conditionLength={values.conditions.length}
                onRemove={handleRemoveCondition(index)}
                isLoading={isFetching}
                columns={columns}
                t={t}
              />
            ))}
          </div>
        </Scrollbars>
      </Card>
    </BaseSection>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(AlertRuleSection)
)
