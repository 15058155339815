import React, { memo } from 'react'
import { withStyles, Link } from '@material-ui/core'

import { CircleIconButton } from 'components/Buttons'

const styles = theme => {
  const { palette, type } = theme
  return {
    circleIcon: {
      color: palette[type].pageContainer.header.infoIcon.color,
      fontSize: '20px'
    }
  }
}

const HelpIconLink = ({ classes, link, replaceInfoIcon = '' }) => {
  return (
    <Link target="_blank" rel="noopener" underline="none" href={link}>
      <CircleIconButton className={`hvr-grow ${classes.circleIcon}`}>
        <i className={replaceInfoIcon || 'fa-regular fa-circle-question'} />
      </CircleIconButton>
    </Link>
  )
}

export default withStyles(styles)(memo(HelpIconLink))
