import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  imagePickerModal: {
    open: false,
    currentChoice: null
  }
}

export const surveySlice = createSlice({
  name: 'surveySlice',
  initialState,
  reducers: {
    setImagePickerModal: (state, action) => {
      state.imagePickerModal = {
        ...state.imagePickerModal,
        ...action.payload
      }
    }
  }
})

export const { setImagePickerModal } = surveySlice.actions

export default surveySlice.reducer
