const snakeCaseToSplitCapitalize = name => {
  if (!name) {
    return ''
  }
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export default snakeCaseToSplitCapitalize
