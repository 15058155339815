import { call, put } from 'redux-saga/effects'

import * as types from 'actions'
import googleDocumentService from 'services/googleDocumentService'

function* getGoogleDocuments({ params }) {
  try {
    const response = yield call(
      googleDocumentService.getGoogleDocuments,
      params
    )
    yield put({
      type: types.GET_GOOGLE_DOCUMENTS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_GOOGLE_DOCUMENTS_ERROR,
      payload: error
    })
  }
}

const getGoogleDocumentSaga = {
  getGoogleDocuments
}

export default getGoogleDocumentSaga
