import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { TableLibraryCell, TableLibraryRow } from 'components/TableLibrary'
import { TextWithTooltip } from 'components/Typography'
import { ActiveStatus, InactiveStatus } from 'components/StatusText'

const NotificationSimpleTableRow = ({ data }) => {
  const { t } = useTranslation()
  const { name, status, createdBy } = data

  return (
    <TableLibraryRow hover tabIndex={-1}>
      <TableLibraryCell>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <TextWithTooltip weight="bold" color="title.primary" maxWidth={220}>
              {name}
            </TextWithTooltip>
          </Grid>
        </Grid>
      </TableLibraryCell>

      <TableLibraryCell align="center">
        {status === 'Active' ? (
          <ActiveStatus title={t('Active')} />
        ) : (
          <InactiveStatus title={t('Inactive')} />
        )}
      </TableLibraryCell>
      <TableLibraryCell align="center">
        {`${createdBy?.firstName} ${createdBy?.lastName}`}
      </TableLibraryCell>
    </TableLibraryRow>
  )
}

export default NotificationSimpleTableRow
