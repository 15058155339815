export const USER_TABLE = 'user'
export const DEVICE_TABLE = 'device'
export const CLIENT_TABLE = 'client'
export const DEVICE_CONNECT_TABLE = 'deviceConnect'
export const DEVICE_LOG_TABLE = 'deviceLog'
export const MEDIA_TABLE = 'media'
export const PLAYLIST_TABLE = 'playlist'
export const PLAYLIST_MEDIA_TABLE = 'playlistMedia'
export const USER_ACTIVITY_TABLE = 'userActivity'
export const USER_LOGIN_TABLE = 'userLogin'
export const CLIENT_PACKAGE_TABLE = 'clientPackage'
export const DEVICE_PACKAGE_TABLE = 'devicePackage'
export const BANDWIDTH_USAGE_TABLE = 'bandwidthUsage'
export const CONFIG_CLIENT_TYPE = 'configClientTypes'
export const CONFIG_FEATURE_DEVICE = 'configFeatureDevice'
export const CONFIG_FEATURE_CLIENT = 'configFeatureClient'

export const NAME_FIELD = 'name'
export const EMAIL_FIELD = 'email'
export const ALIAS_FIELD = 'alias'
export const PHONE_FIELD = 'phone'
export const TYPE_FIELD = 'type'
export const PACKAGE_NAME_FIELD = 'packageName'
export const DESCRIPTION_FIELD = 'description'
export const ADDRESS_FIELD = 'address'
export const ZIP_FIELD = 'zip'
export const CITY_FIELD = 'city'
export const STATE_FIELD = 'state'
export const COUNTRY_FIELD = 'country'
export const TIMEZONE_FIELD = 'timezone'
export const CONNECT_MAC_FIELD = 'connectMac'
export const DEVICE_NAME_FIELD = 'deviceName'
export const DEVICE_ALIAS_FIELD = 'deviceAlias'
export const DEVICE_DESCRIPTION_FIELD = 'deviceDescription'
export const DEVICE_ADDRESS_FIELD = 'deviceAddress'
export const DEVICE_ZIP_FIELD = 'deviceZip'
export const DEVICE_CITY_FIELD = 'deviceCity'
export const DEVICE_STATE_FIELD = 'deviceState'
export const DEVICE_COUNTRY_FIELD = 'deviceCountry'
export const STATUS_FIELD = 'status'
export const TITLE_FIELD = 'title'
export const MEDIA_TITLE_FIELD = 'mediaTitle'
export const PLAYLIST_TITLE_FIELD = 'playlistTitle'
export const CLIENT_NAME_FIELD = 'clientName'

export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const USER_NAME = 'userName'
export const USER_EMAIL = 'userEmail'
export const PHONE_NO_1 = 'phoneNo1'
export const ADDRESS_1 = 'address1'
export const TIMEZONE_NAME = 'timezoneName'
export const FEATURE_PACKAGE = 'featurePackage'

export const CLIENT_FEATURE_IDS_FIELD = 'clientFeatureIds'

export const havingFilters = {
  client: ['clientFeatureIds']
}

export const fieldTypes = {
  string: 'string',
  int: 'int',
  bool: 'bool',
  boolean: 'boolean',
  date: 'date',
  datetime: 'datetime',
  time: 'time',
  json: 'json',
  having: 'having'
}

export const OPERATOR_OPTIONS = {
  equals: {
    label: 'equals',
    value: 'equals'
  },
  notEqual: {
    label: 'not equal to',
    value: 'not equal to'
  },
  contains: {
    label: 'contains',
    value: 'contains'
  },
  having: {
    label: 'having',
    value: 'having'
  },
  notHaving: {
    label: 'not having',
    value: 'not having'
  },
  lessThan: {
    label: 'less than',
    value: 'less than'
  },
  greaterThan: {
    label: 'greater than',
    value: 'greater than'
  },
  lessOrEquals: {
    label: 'less than or equal to',
    value: 'less than equal to'
  },
  greaterOrEquals: {
    label: 'greater than or equal to',
    value: 'greater than equal to'
  },
  between: {
    label: 'between',
    value: 'between'
  },
  before: {
    label: 'before',
    value: 'less than'
  },
  after: {
    label: 'after',
    value: 'greater than'
  },
  dateRange: {
    label: 'date range',
    value: 'date range'
  }
}

export const REPORT_SCHEDULE_MIN_TIME = '00:00:00'
export const REPORT_SCHEDULE_MAX_TIME = '23:59:59'
export const REPORT_SCHEDULE_DEFAULT_END_DATE = null

export const workingDays = {
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun'
}

export const allWorkingDays = [
  workingDays.Mon,
  workingDays.Tue,
  workingDays.Wed,
  workingDays.Thu,
  workingDays.Fri,
  workingDays.Sat,
  workingDays.Sun
]

export const recurrenceIntervals = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year'
}

export const recurrenceMonthlyIntervals = {
  day: 'day',
  week: 'week'
}

export const recurrenceTypes = {
  advanced: 'advanced',
  custom: 'custom',
  none: 'none',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
  weekday: 'weekday',
  weekend: 'weekend'
}

export const recurrenceEndTypes = {
  never: 'never',
  on: 'on',
  after: 'after'
}

export const reportScheduleFields = {
  workingDays: 'workingDays',
  startDate: 'startDate',
  endDate: 'endDate'
}

export const recurrenceTabs = {
  recurring: 'Recurring',
  specific: 'Specific'
}

export const fileFormatOptions = [
  { value: 'xls', label: 'XLS' },
  { value: 'pdf', label: 'PDF' }
]

export const advancedRecurrenceOnOptions = t => [
  { value: 'every', label: t('Every') },
  { value: 'first', label: t('First') },
  { value: 'second', label: t('Second') },
  { value: 'third', label: t('Third') },
  { value: 'last', label: t('Last') }
]

export const DATE_RANGE = {
  LAST_24_HRS: 'last 24 hrs',
  LAST_7_DAYS: 'last 7 days',
  THIS_WEEK: 'this week',
  LAST_WEEK: 'last week',
  LAST_30_DAYS: 'last 30 days',
  THIS_MONTH: 'this month',
  LAST_MONTH: 'last month',
  THIS_QTR: 'this qtr',
  LAST_QTR: 'last qtr',
  THIS_YEAR: 'this year'
}

export const dateRangeOptions = t => [
  { value: DATE_RANGE.LAST_24_HRS, label: t('Last 24 hrs') },
  { value: DATE_RANGE.LAST_7_DAYS, label: t('Last 7 days') },
  { value: DATE_RANGE.THIS_WEEK, label: t('This week') },
  { value: DATE_RANGE.LAST_WEEK, label: t('Last week') },
  { value: DATE_RANGE.LAST_30_DAYS, label: t('Last 30 days') },
  { value: DATE_RANGE.THIS_MONTH, label: t('This Month') },
  { value: DATE_RANGE.LAST_MONTH, label: t('Last Month') },
  { value: DATE_RANGE.THIS_QTR, label: t('This Qtr') },
  { value: DATE_RANGE.LAST_QTR, label: t('Last Qtr') },
  { value: DATE_RANGE.THIS_YEAR, label: t('This Year') }
]

export const timelineColors = [
  'lime',
  'orange',
  'lightblue',
  'pink',
  'lavender',
  'yellow',
  'violet',
  'red',
  'green',
  'grey',
  'maroon',
  'darkcyan',
  'purple',
  'navy',
  'blue',
  'black'
]
