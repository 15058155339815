import React from 'react'

import IconStack from 'components/Icons/IconStack'

const CreditCardSettingsIcon = ({ wrapperStyles, generalStyles }) => (
  <IconStack
    mainClassName="fa-sharp fa-regular fa-credit-card"
    secondClassName="fa-solid fa-gear"
    wrapperStyles={wrapperStyles}
    generalStyles={generalStyles}
  />
)

export default CreditCardSettingsIcon
