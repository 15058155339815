import React, { useCallback } from 'react'
import defaultHeaderLogo from 'common/icons/mvix-logo-dark.svg'
import defaultDarkHeaderLogo from 'common/icons/mvix-logo-light.svg'
import { MVIX_URL } from 'constants/api'

const LogoImage = ({
  isLightLogo,
  darkHeaderLogo,
  headerLogo,
  logoImageClassName
}) => {
  const handleLoadLogoError = useCallback(
    ({ currentTarget }) => {
      if (currentTarget) {
        currentTarget.onerror = null
        currentTarget.src = isLightLogo
          ? defaultDarkHeaderLogo
          : defaultHeaderLogo
      }
    },
    [isLightLogo]
  )

  return (
    <a
      href={MVIX_URL}
      //className={classes.mainFooterNavigationLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className={logoImageClassName}
        src={isLightLogo ? darkHeaderLogo : headerLogo}
        alt="Logo"
        onError={handleLoadLogoError}
      />
    </a>
  )
}

export default LogoImage
