import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useWhiteLabel from 'hooks/useWhiteLabel'
import { setTitle } from 'utils/windowUtils'

function useWindowTitle() {
  const { pathname } = useLocation()
  const { windowTitle } = useWhiteLabel()

  useEffect(() => {
    setTitle(pathname, windowTitle)
  }, [pathname, windowTitle])
}

export default useWindowTitle
