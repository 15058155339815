import { useEffect, useMemo } from 'react'
import { _has } from 'utils/lodash'

const useFormErrorHandler = ({
  setFieldError,
  initialFormValues,
  t,
  errorFields,
  showAllErrors,
  transformError
}) => {
  const fields = useMemo(() => Object.keys(initialFormValues), [
    initialFormValues
  ])

  useEffect(() => {
    if (errorFields?.length) {
      errorFields.forEach(({ name, value }) => {
        if (
          showAllErrors ||
          fields.includes(name) ||
          _has(initialFormValues, name)
        ) {
          const errorMessage = transformError
            ? transformError(name, value[0])
            : t(value[0])
          setFieldError(name, errorMessage)
        }
      })
    }
    // eslint-disable-next-line
  }, [errorFields])
}

export default useFormErrorHandler
