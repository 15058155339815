import React, { useMemo } from 'react'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { Grid, withStyles } from '@material-ui/core'
import moment from 'moment'

import UserPic from '../UserPic'
import { ClientSettingsAccountInfoLoader } from '../Loaders'
import Spacing from 'components/Containers/Spacing'
import Text from 'components/Typography/Text'
import Icon from 'components/Icons/Icon'
import { DATE_VIEW_FORMAT } from 'constants/dateTimeFormats'
import classNames from 'classnames'

const styles = ({ palette, typography, type }) => ({
  licenseIcon: {
    fontSize: '26px',
    padding: '3px 0'
  },
  licenseWrapper: {
    fontSize: '12px',
    color: palette[type].formControls.input.color
  },
  expireInfoWrap: {
    marginTop: '6px'
  },
  infoMainWrap: {
    paddingTop: '22px',
    paddingBottom: '22px'
  },
  subtitleRoot: {
    display: 'flex',
    gap: 5
  },
  subtitleValue: {
    color: typography.darkText[type].color
  }
})

const AccountInfoWithStatus = ({
  t,
  loading,
  clientId,
  accountStatus = '',
  userName = 'Lorem Ipsum',
  userId = '',
  imgSrc = '',
  serviceLevel = '',
  isEdit,
  statusComponent,
  classes,
  expirationDate,
  changeAvatar = () => ({}),
  isChangeAvatar = false,
  isDisabledAvatar = false,
  noStatus,
  lastLogin,
  rootClassName,
  smallAvatar = false,
  subtitleInSingleLine = false,
  leftContentClassName,
  rightContentClassName,
  rightContentGrids = {},
  boldValue = false
}) => {
  const licenseIconColor = useMemo(() => {
    if (!expirationDate) {
      return 'light'
    }
    return moment().isAfter(moment(expirationDate)) ? 'danger' : 'success'
  }, [expirationDate])

  const expiryDate = useMemo(() => {
    const text = expirationDate
      ? moment(expirationDate).format(DATE_VIEW_FORMAT)
      : t('Lifetime License')
    return (
      <span>
        <strong>{text}</strong>
      </span>
    )
  }, [t, expirationDate])

  if (loading) {
    return <ClientSettingsAccountInfoLoader />
  }

  return (
    <Spacing
      direction="row"
      alignItems="center"
      variant={0}
      paddingHor={4}
      paddingVert={3}
      rootClassName={classNames(classes.infoMainWrap, rootClassName)}
    >
      <Grid item xs={2} className={leftContentClassName}>
        <UserPic
          isChange={isChangeAvatar}
          isDisabled={isDisabledAvatar}
          onClick={changeAvatar}
          status={accountStatus}
          userName={userName}
          imgSrc={imgSrc}
          noStatus={noStatus}
          lastLogin={lastLogin}
          small={smallAvatar}
        />
      </Grid>
      <Grid item xs={10} className={rightContentClassName}>
        <Spacing variant={0}>
          <Grid container alignItems="center">
            <Grid item xs={rightContentGrids.left || 7}>
              <Text color="title.primary" variant="secondary" weight="bold">
                {userName}
              </Text>
              <div
                className={classNames({
                  [classes.subtitleRoot]: subtitleInSingleLine
                })}
              >
                {isEdit && (
                  <>
                    <Text color="light">{t('Account Number', { userId })}</Text>
                    {subtitleInSingleLine && (clientId || serviceLevel) && (
                      <Text color="light">|</Text>
                    )}
                  </>
                )}
                {clientId && (
                  <>
                    <Text color="light">
                      {t('Client Id')}:{' '}
                      <span
                        className={classNames({
                          [classes.subtitleValue]: boldValue
                        })}
                      >
                        {t(clientId)}
                      </span>
                    </Text>
                    {subtitleInSingleLine && serviceLevel && (
                      <Text color="light">|</Text>
                    )}
                  </>
                )}
                {serviceLevel && (
                  <Text color="light">
                    {t('Service Level')}:{' '}
                    <span
                      className={classNames({
                        [classes.subtitleValue]: boldValue
                      })}
                    >
                      {t(serviceLevel)}
                    </span>
                  </Text>
                )}
              </div>
            </Grid>
            <Grid
              item
              container
              xs={rightContentGrids.right || 5}
              className={classes.expireInfoWrap}
            >
              <Grid item xs={3}>
                <Icon
                  icon="fa-solid fa-key"
                  color={licenseIconColor}
                  rootClassName={classes.licenseIcon}
                />
              </Grid>
              <Grid item xs={9}>
                <div className={classes.licenseWrapper}>
                  <span>
                    <strong>{t('License Expiration')}:</strong>
                  </span>
                  <div>{expiryDate}</div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Spacing>
      </Grid>

      {statusComponent && statusComponent}
    </Spacing>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(AccountInfoWithStatus)
