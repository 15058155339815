import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core'

import Card from './Card'
import { Text } from 'components/Typography'
import Tooltip from 'components/Tooltip'
import { Link } from 'react-router-dom'
import DashboardCardBase from 'components/Dashboard/DashboardCardBase'
import { LIGHT } from 'theme'
import ReportPieChart from 'components/Charts/ReportPieChart'

const useStyles = makeStyles(
  ({ palette, type, typography, fontSize, lineHeight, colors }) => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    menuContainer: {
      whiteSpace: 'nowrap'
    },
    cardContent: {
      display: 'flex',
      gridColumnGap: 15,
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.5s',
      flexGrow: 1
    },
    cardContentText: {
      color: colors.title.primary[type],
      fontSize: '50px',
      lineHeight: '67px',
      paddingRight: '8px',
      textAlign: 'center'
    },
    cardSuffixLabelText: {
      color: colors.title.primary[type],
      fontSize: '18px',
      lineHeight: '24px',
      paddingRight: '8px',
      textAlign: 'center'
    },
    cardTextRoot: {
      display: 'flex',
      flexDirection: 'row'
    },
    cardIcon: ({ iconColor }) => ({
      fontSize: '48px',
      color: iconColor
    }),
    cardFooter: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '20px',
      marginBottom: 0,
      justifyContent: 'space-between',
      opacity: 1,
      transition: '0.5s opacity, 0.3s margin-bottom'
    },
    cardFooterLeft: {
      display: 'flex',
      flexDirection: 'row',
      gridColumnGap: 5
    },
    cardFooterRight: {
      display: 'flex',
      flexDirection: 'row',
      gridColumnGap: 5
    },
    cardChipRoot: {
      fontSize: 13,
      height: 'auto',
      padding: '6px 5px',
      cursor: 'pointer',
      color: colors.title.primary[LIGHT],
      '& span': {
        paddingLeft: 10,
        paddingRight: 7
      }
    },
    cardChipNormal: {
      '& i': {
        transform: 'rotate(0deg) !important'
      }
    },
    cardChipSuccess: {
      background: '#d3e5d1',

      '& i': {
        color: '#4BB543',
        transform: 'rotate(45deg)'
      }
    },
    cardChipDanger: {
      background: '#f1d8d8',

      '& i': {
        color: '#d53d3d',
        transform: 'rotate(-135deg)'
      }
    },
    chipIcon: {
      fontSize: 13,
      marginLeft: 5
    },
    listContainer: {
      padding: '0px',
      position: 'relative',
      overflowY: 'visible',
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: '1fr'
    },
    navigationHoverCard: {
      backgroundColor: palette[type].body.background,
      borderRadius: '10px',
      position: 'absolute',
      top: 0,
      left: 0,
      transitionDuration: '0.7s',
      width: '190px'
    },
    listItem: {
      padding: 10,
      maxHeight: 90,
      alignItems: 'flex-start'
    },
    text: ({ menuItemColor }) => ({
      ...typography.lightText[type],
      fontSize: fontSize.primary,
      lineHeight: lineHeight.primary,
      fontWeight: 600,
      transition: 'opacity .2s',
      whiteSpace: 'nowrap',
      color: menuItemColor
    }),
    textRoot: {
      padding: '2px 0 0',
      margin: '0px'
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none'
    },
    icon: ({ menuItemColor }) => ({
      color: menuItemColor,
      fontSize: '20px',
      width: 20,
      height: 20,
      marginTop: 3
    }),
    popupContentRoot: {
      padding: 20,
      width: 230
    },
    cardHeaderText: {
      ...typography.darkAccent[type],
      fontSize: fontSize.big,
      lineHeight: lineHeight.big,
      textTransform: 'capitalize'
    },
    dashbardCardWrapper: {
      height: '100%'
    },
    dashboardCardContentRoot: {
      flexGrow: 1
    },
    dashboardCardContentWrap: {
      height: '100%'
    }
  })
)

const ColoredBoxCard = ({
  cardTitle = '',
  cardMenuItems = [],
  activeBoxCount,
  activeBoxCountTooltip,
  suffixLabel,
  iconClassName,
  iconColor,
  menuItemColor,
  cardRootClassName = '',
  pulseButtonComponent = null,
  footerLeftChips = [],
  footerRightChips = [],
  hideFooter = false,
  iconComponent,
  children,
  newCardLayout,
  cardHeaderText,
  iconStyle,
  iconSize,
  threeDPieChart = false,
  threeDPieChartData = []
}) => {
  const [hoverCard, setHoverCard] = useState({
    height: '0px',
    transform: `translate(0px, 0px)`
  })
  const classes = useStyles({
    iconColor,
    menuItemColor
  })

  const onResetMouseHover = useCallback(() => {
    setHoverCard({
      height: '0px',
      transform: `translate(0px, 0px)`
    })
  }, [])

  const onMouseOver = useCallback(
    index => () => {
      const x = 0
      const y = index * 54
      setHoverCard({
        height: 50,
        transform: `translate(${x}px, ${y}px)`
      })
    },
    []
  )

  const onLinkClick = useCallback(
    (onClick, isLink) => e => {
      !isLink && e.preventDefault()
      onClick && onClick()
    },
    []
  )

  const renderListItem = useCallback(
    ({ label, url, icon: Icon, iconHelperClass, disabled, onClick }, index) => {
      return (
        <ListItem
          key={label + index}
          className={classNames(classes.listItem, {
            [classes.disabled]: disabled
          })}
          component={Link}
          onClick={onLinkClick(onClick, !!url)}
          to={url || '#'}
          disableGutters
          onMouseOver={onMouseOver(index)}
        >
          <ListItemIcon>
            {Icon ? (
              <Icon className={classes.icon} />
            ) : (
              <i
                className={classNames(iconHelperClass, classes.icon, iconStyle)}
              />
            )}
          </ListItemIcon>
          <ListItemText
            classes={{
              root: classes.textRoot,
              primary: classes.text
            }}
            primary={label}
          />
        </ListItem>
      )
    },
    [classes, iconStyle, onLinkClick, onMouseOver]
  )

  const renderChild = useMemo(() => {
    const activeBoxContent = (
      <Text rootClassName={classes.cardContentText}>{activeBoxCount}</Text>
    )
    return (
      <>
        <div className={classes.cardContent}>
          {iconComponent || (
            <i
              className={classNames(iconClassName, classes.cardIcon, iconSize)}
            />
          )}
          <div className={classes.cardTextRoot}>
            {activeBoxCountTooltip ? (
              <Tooltip arrow title={activeBoxCountTooltip} placement="top">
                {activeBoxContent}
              </Tooltip>
            ) : (
              activeBoxContent
            )}

            {suffixLabel && (
              <Text rootClassName={classes.cardSuffixLabelText}>
                {suffixLabel}
              </Text>
            )}
          </div>
        </div>
        {children && <Grid container>{children}</Grid>}
        {!hideFooter && (
          <div className={classes.cardFooter}>
            <div className={classes.cardFooterLeft}>
              {footerLeftChips.map((chip, index) => (
                <Tooltip
                  title={chip.tooltip}
                  placement="top"
                  key={`dashboard-left-chip-${index}`}
                  arrow
                >
                  <Chip
                    icon={
                      <i
                        className={classNames(
                          chip.icon || 'fa-regular fa-arrow-up',
                          classes.chipIcon
                        )}
                      />
                    }
                    label={chip.label}
                    classes={{
                      root: classNames(
                        classes.cardChipRoot,
                        classes.cardChipSuccess,
                        {
                          [classes.cardChipDanger]: chip.success === false
                        }
                      )
                    }}
                  />
                </Tooltip>
              ))}
            </div>
            <div className={classes.cardFooterRight}>
              {footerRightChips.map((chip, index) => (
                <Tooltip
                  title={chip.tooltip}
                  placement="top"
                  key={`dashboard-right-chip-${index}`}
                  arrow
                >
                  <Chip
                    icon={
                      <i
                        className={classNames(
                          chip.icon || 'fa-solid fa-percentage',
                          classes.chipIcon
                        )}
                      />
                    }
                    label={chip.label}
                    classes={{
                      root: classNames(
                        classes.cardChipRoot,
                        classes.cardChipSuccess,
                        classes.cardChipNormal,
                        {
                          [classes.cardChipDanger]: chip.success === false
                        }
                      )
                    }}
                  />
                </Tooltip>
              ))}
            </div>
          </div>
        )}
      </>
    )
  }, [
    activeBoxCount,
    activeBoxCountTooltip,
    children,
    classes,
    footerLeftChips,
    footerRightChips,
    hideFooter,
    iconClassName,
    iconComponent,
    suffixLabel,
    iconSize
  ])

  const renderThreeDPieChart = useMemo(
    () => <ReportPieChart data={threeDPieChartData} activeColor={iconColor} />,
    [iconColor, threeDPieChartData]
  )

  return newCardLayout ? (
    <DashboardCardBase
      title={cardTitle}
      icon={!!cardMenuItems.length}
      menuItems={cardMenuItems}
      rootClassName={classNames(classes.root, cardRootClassName)}
      menuDropdownContainerClassName={classes.menuContainer}
      pulseButtonComponent={pulseButtonComponent}
      popupPlacement="bottom-end"
      headerTextClasses={[classes.cardHeaderText]}
      menuDropdownComponent={
        <div className={classes.popupContentRoot}>
          <List
            onMouseLeave={onResetMouseHover}
            component="nav"
            className={classes.listContainer}
          >
            <div
              className={classes.navigationHoverCard}
              style={{
                height: hoverCard.height,
                transform: hoverCard.transform
              }}
            />
            {cardMenuItems.map(renderListItem)}
          </List>
        </div>
      }
      cardWrapperClassName={classes.dashbardCardWrapper}
      contentRootClassName={classes.dashboardCardContentRoot}
      contentWrapClassName={classes.dashboardCardContentWrap}
      removeScrollbar
    >
      {renderChild}
    </DashboardCardBase>
  ) : (
    <Card
      title={cardTitle}
      icon={!!cardMenuItems.length}
      menuItems={cardMenuItems}
      rootClassName={classNames(classes.root, cardRootClassName)}
      menuDropdownContainerClassName={classes.menuContainer}
      pulseButtonComponent={pulseButtonComponent}
      popupPlacement="bottom-end"
      headerTextClasses={[classes.cardHeaderText]}
      headerClasses={[cardHeaderText]}
      menuDropdownComponent={
        <div className={classes.popupContentRoot}>
          <List
            onMouseLeave={onResetMouseHover}
            component="nav"
            className={classes.listContainer}
          >
            <div
              className={classes.navigationHoverCard}
              style={{
                height: hoverCard.height,
                transform: hoverCard.transform
              }}
            />
            {cardMenuItems.map(renderListItem)}
          </List>
        </div>
      }
    >
      {!threeDPieChart ? renderChild : renderThreeDPieChart}
    </Card>
  )
}

export default ColoredBoxCard
