export const insetColons = str => {
  if (!str) {
    return str
  }
  const parts = str.replaceAll('-', '').match(/.{1,2}/g)
  return parts?.join(':')
}

export const removeColons = str => {
  return str?.replace(/[:-]/g, '')
}
