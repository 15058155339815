import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const items = new Array(10).fill(' ')

const MediaContentIconTabsLoader = ({ theme }) => {
  return (
    <ContentLoader
      style={{
        width: '100%',
        height: 48
      }}
      foregroundColor={theme.palette[theme.type].loader.background}
      backgroundColor={theme.palette[theme.type].loader.foreground}
    >
      {items.map((item, index) => {
        const initialX = 15.5
        const step = 7.4

        return (
          <rect
            key={index}
            x={`${initialX + step * index}%`}
            y="15"
            width="18"
            height="18"
            rx="3"
            ry="3"
          />
        )
      })}
    </ContentLoader>
  )
}

export default withTheme(MediaContentIconTabsLoader)
