import React from 'react'
import ContentLoader from 'react-content-loader'
import { withTheme } from '@material-ui/core'

const generateRects = () => {
  const rects = []
  let y = 15
  for (let i = 0; i < 45; i++) {
    rects.push(y)
    y += 60
  }
  return rects
}
const toggles = generateRects()

const ListLoader = withTheme(({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 1000
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    {toggles.map((y, index) => (
      <React.Fragment key={index}>
        <rect x="20" y={y} rx="6" ry="6" width="270" height="30" />
        <rect x="330" y={y} rx="6" ry="6" width="30" height="30" />

        <rect x="0" y={y + 42} rx="6" ry="6" width="380" height="5" />
      </React.Fragment>
    ))}
  </ContentLoader>
))

export default ListLoader
