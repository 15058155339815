import React from 'react'
import {
  ListItem,
  ListItemIcon,
  makeStyles,
  withStyles
} from '@material-ui/core'
import classNames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'

import { TextWithTooltip } from 'components/Typography'

const styles = ({ typography, type, fontSize, lineHeight }) => ({
  listItem: {
    padding: 10,
    minHeight: 66,
    maxHeight: 66,
    alignItems: 'flex-start'
  },
  text: {
    ...typography.lightText[type],
    fontSize: fontSize.primary,
    lineHeight: lineHeight.primary,
    fontWeight: 600,
    transition: 'opacity .2s',
    whiteSpace: 'nowrap'
  },
  description: {
    ...typography.lightText[type],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 190,
    margin: 0
  },
  textRoot: {
    padding: '2px 0 0',
    margin: '0px'
  },
  icon: {
    fontSize: 25,
    paddingTop: 5
  },
  listItemViewMore: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row-reverse'
  },
  iconViewMore: {
    marginLeft: 16
  },
  textRootViewMore: {
    paddingTop: 5
  },
  interactiveIconClass: {
    transform: 'scale(1.8)',
    marginTop: 8
  }
})

const dynamicStyles = makeStyles({
  text: ({ color }) => ({
    color: `${color} !important`
  }),
  icon: ({ color }) => ({
    color: `${color} !important`,
    '&:before': {
      color: `${color} !important`
    }
  })
})

const SearchRow = ({
  onMouseOver,
  onLinkClick,
  pathname,
  item = {},
  color,
  classes
}) => {
  const { icon: Icon, iconComponent: IconComponent, name, iconHelperClass } =
    item.icon || {}
  const dynamicClasses = dynamicStyles({
    color
  })
  return (
    <ListItem
      className={classNames(classes.listItem, {
        [classes.listItemViewMore]: item.isViewMore
      })}
      component={RouterLink}
      onClick={onLinkClick}
      to={item.url || '#'}
      disableGutters
      onMouseOver={onMouseOver}
      replace={item.url?.includes(pathname)}
    >
      <ListItemIcon
        className={classNames(classes.icon, {
          [classes.iconViewMore]: item.isViewMore
        })}
      >
        {Icon || IconComponent ? (
          Icon ? (
            <Icon className={dynamicClasses.icon} />
          ) : (
            <IconComponent
              {...(name === 'Interactive' && {
                wrapperClassName: classes.interactiveIconClass
              })}
            />
          )
        ) : (
          <i className={classNames(iconHelperClass, dynamicClasses.icon)} />
        )}
      </ListItemIcon>
      <div
        className={classNames(classes.textRoot, {
          [classes.textRootViewMore]: item.isViewMore
        })}
      >
        <TextWithTooltip
          maxWidth={180}
          rootClassName={classNames(classes.text, dynamicClasses.text)}
        >
          {item.title}
        </TextWithTooltip>

        <p className={classes.description}>{item.subTitle}</p>
      </div>
    </ListItem>
  )
}

export default withStyles(styles)(SearchRow)
