import React, { useMemo } from 'react'
import { Table, TableBody, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import _get from 'lodash/get'
import classNames from 'classnames'

import {
  TableLibraryCell,
  TableLibraryHead,
  TableLibraryRow
} from 'components/TableLibrary'
import Scrollbars from 'components/Scrollbars'
import DeviceTypeIconCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceTypeIconCell'
import DeviceNameViewCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceNameViewCell'
import { useUserRole } from 'hooks/tableLibrary'
import MessageCard from 'components/Card/MessageCard'
import { conflictSources } from 'constants/api'

const styles = ({ type, typography, colors }) => ({
  scrollContent: {
    marginRight: 20
  },
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  },
  name: {
    ...typography.darkAccent[type]
  },
  aliasWrapContent: {
    minWidth: '190px'
  },
  badgeWrapper: {
    width: 0,
    margin: '0px auto'
  }
})

const initialColumns = [
  { id: 'deviceTypeLogo', label: 'Logo', align: 'left' },
  { id: 'deviceType', label: 'Type', align: 'left' },
  { id: 'deviceName', label: 'Device Name', align: 'left' }
]

const columnWidth = {
  deviceTypeLogo: 96,
  deviceType: 120,
  deviceName: 220
}

//TODO refactor for common use
const DeviceHasSchedulesConflictContent = ({
  conflictSource,
  error,
  classes
}) => {
  const { t } = useTranslation('translations')
  const role = useUserRole()

  const data = useMemo(() => error?.data?.Device || [], [error])

  return (
    <Scrollbars autoHeight autoHeightMax="calc(100vh - 190px)">
      <div className={classes.scrollContent}>
        {![
          conflictSources.addEditDevice,
          conflictSources.bulkTagsAssign,
          conflictSources.deviceToGroup,
          conflictSources.deviceLocation
        ].includes(conflictSource) && (
          <MessageCard
            icon="fa-regular fa-circle-info"
            message={`${t(
              'Schedules will be removed from the following devices'
            )}:`}
            marginBottom
          />
        )}
        <Table className={classes.table}>
          <TableLibraryHead
            noType={true}
            editRows={false}
            disableSort
            rowCount={data.length}
            columns={initialColumns}
            columnWidth={columnWidth}
            headerCellClassName={classes.tableHeaderCell}
          />
          <TableBody>
            {data.map((row, index) => (
              <TableLibraryRow
                key={`mac-conflict-${index}`}
                hover
                tabIndex={-1}
              >
                <DeviceTypeIconCell item={row} />
                <TableLibraryCell align="left">
                  {_get(row.deviceType, 'name', t('N/A'))}
                </TableLibraryCell>
                <TableLibraryCell
                  className={classNames(classes.name, classes.aliasWrapContent)}
                >
                  <DeviceNameViewCell role={role} classes={classes} row={row} />
                </TableLibraryCell>
              </TableLibraryRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Scrollbars>
  )
}

export default withStyles(styles)(DeviceHasSchedulesConflictContent)
