import React, { memo, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import ConflictDetailsCard from './ConflictDetailsCard'
import Scrollbars from 'components/Scrollbars'
import { getSchedulePreview } from 'actions/scheduleActions'
import { resourceTypes } from 'constants/api'
import { ScreenPreviewModal } from 'components/Modal'

const useStyles = makeStyles(({ spacing }) => ({
  scrollContent: {
    marginRight: 20,
    marginBottom: spacing(1)
  }
}))

const AssociationHasSchedulesConflictContent = ({ error }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const groupsData = useMemo(() => error?.data?.device_groups || [], [error])
  const tagsData = useMemo(() => error?.data?.device_tags || [], [error])
  const locationsData = useMemo(() => error?.data?.device_locations || [], [
    error
  ])

  const handlePreviewClick = useCallback(
    ({ id, orientation }) => {
      dispatch(getSchedulePreview({ id, orientation }, 'conflict'))
    },
    [dispatch]
  )

  return (
    <>
      <Scrollbars autoHeight autoHeightMax="calc(100vh - 190px)">
        <div className={classes.scrollContent}>
          {!!groupsData.length && (
            <ConflictDetailsCard
              data={groupsData}
              handlePreviewClick={handlePreviewClick}
              resource={resourceTypes.GroupResource}
            />
          )}
          {!!tagsData.length && (
            <ConflictDetailsCard
              data={tagsData}
              handlePreviewClick={handlePreviewClick}
              resource={resourceTypes.TagWithReportResource}
            />
          )}
          {!!locationsData.length && (
            <ConflictDetailsCard
              data={locationsData}
              handlePreviewClick={handlePreviewClick}
              resource={resourceTypes.DeviceLocationResource}
            />
          )}
        </div>
      </Scrollbars>
      <ScreenPreviewModal
        targetReducer="schedule"
        actionKey="conflict"
        bounds="html"
      />
    </>
  )
}

export default memo(AssociationHasSchedulesConflictContent)
