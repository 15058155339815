import React, { useMemo } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { colors } from 'theme'

import Tooltip from 'components/Tooltip'
import Text from 'components/Typography/Text'
import { DateTimeView } from 'components/TableLibrary'

const useStyles = makeStyles(({ palette, type }) => ({
  tooltipWrapper: {
    maxWidth: 'fit-content',
    margin: '0 auto'
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center'
  },
  statusIcon: {
    paddingRight: 5
  },
  activeStatusIcon: {
    color: colors.activeStatus
  },
  inactiveStatusIcon: {
    color: colors.inactiveStatus
  },
  needsAttentionStatusIcon: {
    color: colors.needsAttentionStatus
  },
  greyStatusIcon: {
    color: palette[type].buttons.iconButton.color
  },
  tooltipTextClass: {
    color: `${colors.white} !important`,
    textAlign: 'center'
  },
  clickableText: {
    cursor: 'pointer'
  }
}))

const StatusWithIcon = ({
  status,
  needsAttention,
  headerText,
  title,
  hasTriangleIcon,
  hasGrayIcon,
  hideIcon,
  disabledStatus,
  statusTooltipText,
  onStatusClick,
  t
}) => {
  const classes = useStyles()
  const isActive = status === 'Active'

  const iconContent = useMemo(() => {
    return (
      <div className={classes.contentWrapper}>
        <i
          className={classNames(classes.statusIcon, {
            'fa fa-check-circle': isActive && !needsAttention && !hideIcon,
            'fa fa-exclamation-triangle':
              ((!isActive && !needsAttention) || hasTriangleIcon) && !hideIcon,
            'fa fa-exclamation-circle': needsAttention && !hideIcon,
            [classes.activeStatusIcon]: isActive && !needsAttention,
            [classes.inactiveStatusIcon]: !isActive && !needsAttention,
            [classes.needsAttentionStatusIcon]: needsAttention,
            [classes.greyStatusIcon]: hasGrayIcon
          })}
        />
        {!!statusTooltipText && onStatusClick && !needsAttention ? (
          <Tooltip arrow placement="top" title={statusTooltipText}>
            <Text
              color="light"
              onClick={onStatusClick}
              rootClassName={classes.clickableText}
            >
              {t(status)}
            </Text>
          </Tooltip>
        ) : (
          <Text color="light">{t(status)}</Text>
        )}
      </div>
    )
  }, [
    classes,
    needsAttention,
    isActive,
    status,
    hasGrayIcon,
    hasTriangleIcon,
    hideIcon,
    onStatusClick,
    statusTooltipText,
    t
  ])

  return needsAttention ? (
    <div className={classes.tooltipWrapper}>
      {disabledStatus && !title ? (
        <Tooltip arrow title={headerText}>
          {iconContent}
        </Tooltip>
      ) : (
        <Tooltip
          arrow
          withHeader
          placement="top"
          title={
            title && disabledStatus ? (
              <DateTimeView
                date={title}
                textClass={classes.tooltipTextClass}
                textSmallClass={classes.tooltipTextClass}
              />
            ) : (
              title
            )
          }
          headerText={headerText}
        >
          {iconContent}
        </Tooltip>
      )}
    </div>
  ) : (
    iconContent
  )
}

export default StatusWithIcon
