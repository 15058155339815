const provinces = [
  {
    short: 'AL',
    name: 'Alabama',
    country: 'United States'
  },
  {
    short: 'AK',
    name: 'Alaska',
    country: 'United States'
  },
  {
    short: 'AZ',
    name: 'Arizona',
    country: 'United States'
  },
  {
    short: 'AR',
    name: 'Arkansas',
    country: 'United States'
  },
  {
    short: 'CA',
    name: 'California',
    country: 'United States'
  },
  {
    short: 'CO',
    name: 'Colorado',
    country: 'United States'
  },
  {
    short: 'CT',
    name: 'Connecticut',
    country: 'United States'
  },
  {
    short: 'DC',
    name: ['District of Columbia', 'Washington DC', 'Washington D.C.'],
    country: 'United States'
  },
  {
    short: 'DE',
    name: 'Delaware',
    country: 'United States'
  },
  {
    short: 'FL',
    name: 'Florida',
    country: 'United States'
  },
  {
    short: 'GA',
    name: 'Georgia',
    country: 'United States'
  },
  {
    short: 'HI',
    name: 'Hawaii',
    country: 'United States'
  },
  {
    short: 'ID',
    name: 'Idaho',
    country: 'United States'
  },
  {
    short: 'IL',
    name: 'Illinois',
    country: 'United States'
  },
  {
    short: 'IN',
    name: 'Indiana',
    country: 'United States'
  },
  {
    short: 'IA',
    name: 'Iowa',
    country: 'United States'
  },
  {
    short: 'KS',
    name: 'Kansas',
    country: 'United States'
  },
  {
    short: 'KY',
    name: 'Kentucky',
    country: 'United States'
  },
  {
    short: 'LA',
    name: 'Louisiana',
    country: 'United States'
  },
  {
    short: 'ME',
    name: 'Maine',
    country: 'United States'
  },
  {
    short: 'MD',
    name: 'Maryland',
    country: 'United States'
  },
  {
    short: 'MA',
    name: 'Massachusetts',
    country: 'United States'
  },
  {
    short: 'MI',
    name: 'Michigan',
    country: 'United States'
  },
  {
    short: 'MN',
    name: 'Minnesota',
    country: 'United States'
  },
  {
    short: 'MS',
    name: 'Mississippi',
    country: 'United States'
  },
  {
    short: 'MO',
    name: 'Missouri',
    country: 'United States'
  },
  {
    short: 'MT',
    name: 'Montana',
    country: 'United States'
  },
  {
    short: 'NE',
    name: 'Nebraska',
    country: 'United States'
  },
  {
    short: 'NV',
    name: 'Nevada',
    country: 'United States'
  },
  {
    short: 'NH',
    name: 'New Hampshire',
    country: 'United States'
  },
  {
    short: 'NJ',
    name: 'New Jersey',
    country: 'United States'
  },
  {
    short: 'NM',
    name: 'New Mexico',
    country: 'United States'
  },
  {
    short: 'NY',
    name: 'New York',
    country: 'United States'
  },
  {
    short: 'NC',
    name: 'North Carolina',
    country: 'United States'
  },
  {
    short: 'ND',
    name: 'North Dakota',
    country: 'United States'
  },
  {
    short: 'OH',
    name: 'Ohio',
    country: 'United States'
  },
  {
    short: 'OK',
    name: 'Oklahoma',
    country: 'United States'
  },
  {
    short: 'OR',
    name: 'Oregon',
    country: 'United States'
  },
  {
    short: 'PA',
    name: 'Pennsylvania',
    country: 'United States'
  },
  {
    short: 'RI',
    name: 'Rhode Island',
    country: 'United States'
  },
  {
    short: 'SC',
    name: 'South Carolina',
    country: 'United States'
  },
  {
    short: 'SD',
    name: 'South Dakota',
    country: 'United States'
  },
  {
    short: 'TN',
    name: 'Tennessee',
    country: 'United States'
  },
  {
    short: 'TX',
    name: 'Texas',
    country: 'United States'
  },
  {
    short: 'UT',
    name: 'Utah',
    country: 'United States'
  },
  {
    short: 'VT',
    name: 'Vermont',
    country: 'United States'
  },
  {
    short: 'VA',
    name: 'Virginia',
    country: 'United States'
  },
  {
    short: 'WA',
    name: 'Washington',
    country: 'United States'
  },
  {
    short: 'WV',
    name: 'West Virginia',
    country: 'United States'
  },
  {
    short: 'WI',
    name: 'Wisconsin',
    country: 'United States'
  },
  {
    short: 'WY',
    name: 'Wyoming',
    country: 'United States'
  },
  {
    short: 'AS',
    name: 'American Samoa',
    country: 'United States'
  },
  {
    short: 'GU',
    name: 'Guam',
    country: 'United States'
  },
  {
    short: 'MP',
    name: 'Northern Mariana Islands',
    country: 'United States'
  },
  {
    short: 'PR',
    name: 'Puerto Rico',
    country: 'United States'
  },
  {
    short: 'UM',
    name: 'United States Minor Outlying Islands',
    country: 'United States'
  },
  {
    short: 'VI',
    name: 'Virgin Islands',
    country: 'United States'
  },
  {
    short: 'AB',
    name: 'Alberta',
    country: 'Canada'
  },
  {
    short: 'BC',
    name: 'British Columbia',
    country: 'Canada'
  },
  {
    short: 'MB',
    name: 'Manitoba',
    country: 'Canada'
  },
  {
    short: 'NB',
    name: 'New Brunswick',
    country: 'Canada'
  },
  {
    short: 'NL',
    name: ['Newfoundland and Labrador', 'Newfoundland', 'Labrador'],
    country: 'Canada'
  },
  {
    short: 'NS',
    name: 'Nova Scotia',
    country: 'Canada'
  },
  {
    short: 'NU',
    name: 'Nunavut',
    country: 'Canada'
  },
  {
    short: 'NT',
    name: 'Northwest Territories',
    country: 'Canada'
  },
  {
    short: 'ON',
    name: 'Ontario',
    country: 'Canada'
  },
  {
    short: 'PE',
    name: 'Prince Edward Island',
    country: 'Canada'
  },
  {
    short: 'QC',
    name: 'Quebec',
    country: 'Canada'
  },
  {
    short: 'SK',
    name: 'Saskatchewan',
    country: 'Canada'
  },
  {
    short: 'YT',
    name: 'Yukon',
    country: 'Canada'
  },
  {
    short: 'ACT',
    name: 'Australian Capital Territory',
    country: 'Australia'
  },
  {
    short: 'CX',
    name: 'Christmas Island',
    country: 'Australia'
  },
  {
    short: 'CC',
    name: 'Cocos Islands',
    alt: ['Keeling Islands'],
    country: 'Australia'
  },
  {
    short: 'HM',
    name: 'Heard Island and McDonald Islands',
    country: 'Australia'
  },
  {
    short: 'JBT',
    name: 'Jervis Bay Territory',
    country: 'Australia'
  },
  {
    short: 'NSW',
    name: 'New South Wales',
    country: 'Australia'
  },
  {
    short: 'NF',
    name: 'Norfolk Island',
    country: 'Australia'
  },
  {
    short: 'NT',
    name: 'Northern Territory',
    country: 'Australia'
  },
  {
    short: 'QLD',
    name: 'Queensland',
    country: 'Australia'
  },
  {
    short: 'SA',
    name: 'South Australia',
    country: 'Australia'
  },
  {
    short: 'TAS',
    name: 'Tasmania',
    country: 'Australia'
  },
  {
    short: 'VIC',
    name: 'Victoria',
    country: 'Australia'
  },
  {
    short: 'WA',
    name: 'Western Australia',
    country: 'Australia'
  },
  {
    short: 'AG',
    name: 'Aguascalientes',
    alt: ['AGS'],
    country: 'Mexico'
  },
  {
    short: 'BC',
    name: 'Baja California',
    alt: ['BCN'],
    country: 'Mexico'
  },
  {
    short: 'BS',
    name: 'Baja California Sur',
    alt: ['BCS'],
    country: 'Mexico'
  },
  {
    short: 'CM',
    name: 'Campeche',
    alt: ['Camp', 'CAM'],
    country: 'Mexico'
  },
  {
    short: 'CS',
    name: 'Chiapas',
    alt: ['Chis', 'CHP'],
    country: 'Mexico'
  },
  {
    short: 'CH',
    name: 'Chihuahua',
    alt: ['Chih', 'CHH'],
    country: 'Mexico'
  },
  {
    short: 'MX',
    name: 'Coahuila',
    alt: ['Coah', 'COA'],
    country: 'Mexico'
  },
  {
    short: 'CL',
    name: 'Colima',
    alt: ['COL'],
    country: 'Mexico'
  },
  {
    short: 'DF',
    name: 'Federal District',
    alt: ['DIF'],
    country: 'Mexico'
  },
  {
    short: 'DG',
    name: 'Durango',
    alt: ['Dgo', 'DUR'],
    country: 'Mexico'
  },
  {
    short: 'GT',
    name: 'Guanajuato',
    alt: ['Gto', 'GUA'],
    country: 'Mexico'
  },
  {
    short: 'GR',
    name: 'Guerrero',
    alt: ['Gro', 'GRO'],
    country: 'Mexico'
  },
  {
    short: 'HG',
    name: 'Hidalgo',
    alt: ['Hgo', 'HID'],
    country: 'Mexico'
  },
  {
    short: 'JA',
    name: 'Jalisco',
    alt: ['Jal', 'JAL'],
    country: 'Mexico'
  },
  {
    short: 'ME',
    name: 'Mexico',
    alt: ['Edomex', 'MEX'],
    country: 'Mexico'
  },
  {
    short: 'MI',
    name: 'Michoacán',
    alt: ['Mich', 'MIC'],
    country: 'Mexico'
  },
  {
    short: 'MO',
    name: 'Morelos',
    alt: ['Mor', 'MOR'],
    country: 'Mexico'
  },
  {
    short: 'NA',
    name: 'Nayarit',
    alt: ['Nay', 'NAY'],
    country: 'Mexico'
  },
  {
    short: 'NL',
    name: 'Nuevo León',
    alt: ['NLE'],
    country: 'Mexico'
  },
  {
    short: 'OA',
    name: 'Oaxaca',
    alt: ['Oax', 'OAX'],
    country: 'Mexico'
  },
  {
    short: 'PU',
    name: 'Puebla',
    alt: ['Pue', 'PUE'],
    country: 'Mexico'
  },
  {
    short: 'QE',
    name: 'Querétaro',
    alt: ['Qro', 'QUE'],
    country: 'Mexico'
  },
  {
    short: 'QR',
    name: 'Quintana Roo',
    alt: ['Q Roo', 'ROO'],
    country: 'Mexico'
  },
  {
    short: 'SL',
    name: 'San Luis Potosí',
    alt: ['SLP'],
    country: 'Mexico'
  },
  {
    short: 'SI',
    name: 'Sinaloa',
    alt: ['SIN'],
    country: 'Mexico'
  },
  {
    short: 'SO',
    name: 'Sonora',
    alt: ['SON'],
    country: 'Mexico'
  },
  {
    short: 'TB',
    name: 'Tabasco',
    alt: ['TAB'],
    country: 'Mexico'
  },
  {
    short: 'TM',
    name: 'Tamaulipas',
    alt: ['Tamps', 'TAM'],
    country: 'Mexico'
  },
  {
    short: 'TL',
    name: 'Tlaxcala',
    alt: ['Tlax', 'TLA'],
    country: 'Mexico'
  },
  {
    short: 'VE',
    name: 'Veracruz',
    alt: ['VER'],
    country: 'Mexico'
  },
  {
    short: 'YU',
    name: 'Yucatán',
    alt: ['YUC'],
    country: 'Mexico'
  },
  {
    short: 'ZA',
    name: 'Zacatecas',
    alt: ['ZAC'],
    country: 'Mexico'
  },
  {
    short: '渝',
    name: '重庆',
    english: 'Chongqing',
    country: 'China'
  },
  {
    short: '黑',
    name: '黑龙江',
    english: 'Heilongjiang',
    country: 'China'
  },
  {
    short: '吉',
    name: '吉林',
    english: 'Jilin',
    country: 'China'
  },
  {
    short: '琼',
    name: '海南',
    english: 'Hainan',
    country: 'China'
  },
  {
    short: '京',
    name: '北京',
    english: 'Beijing',
    country: 'China'
  },
  {
    short: '辽',
    name: '辽宁',
    english: 'Liaoning',
    country: 'China'
  },
  {
    short: '蒙',
    name: '内蒙古',
    english: 'Inner Mongolia',
    alt: ['Nei Menggu'],
    country: 'China'
  },
  {
    short: '藏',
    name: '西藏',
    english: 'Xizang',
    alt: ['Tibet'],
    country: 'China'
  },
  {
    short: '青',
    name: '青海',
    english: 'Qinghai',
    country: 'China'
  },
  {
    short: '宁',
    name: '宁夏',
    english: 'Ningxia',
    country: 'China'
  },
  {
    short: '新',
    name: '新疆',
    english: 'Xinjiang',
    alt: ['Uygur'],
    country: 'China'
  },
  {
    short: '甘',
    name: '甘肃',
    english: 'Gansu',
    country: 'China'
  },
  {
    short: '冀',
    name: '河北',
    english: 'Hebei',
    country: 'China'
  },
  {
    short: '豫',
    name: '河南',
    english: 'Henan',
    country: 'China'
  },
  {
    short: '鄂',
    name: '湖北',
    english: 'Hubei',
    country: 'China'
  },
  {
    short: '湘',
    name: '湖南',
    english: 'Hunan',
    country: 'China'
  },
  {
    short: '鲁',
    name: '山东',
    english: 'Shandong',
    country: 'China'
  },
  {
    short: '苏',
    name: '江苏',
    english: 'Jiangsu',
    country: 'China'
  },
  {
    short: '皖',
    name: '安徽',
    english: 'Anhui',
    country: 'China'
  },
  {
    short: '晋',
    name: '山西',
    english: 'Shanxi',
    country: 'China'
  },
  {
    short: '陕',
    name: '陕西',
    english: 'Shaanxi',
    country: 'China'
  },
  {
    short: '川',
    name: '四川',
    english: 'sichuan',
    country: 'China'
  },
  {
    short: '滇',
    name: '云南',
    english: 'Yunnan',
    country: 'China'
  },
  {
    short: '黔',
    name: '贵州',
    english: 'Guizhou',
    country: 'China'
  },
  {
    short: '浙',
    name: '浙江',
    english: 'Zhejiang',
    country: 'China'
  },
  {
    short: '闽',
    name: '福建',
    english: 'Fujian',
    country: 'China'
  },
  {
    short: '桂',
    name: '广西',
    english: 'Guangxi',
    country: 'China'
  },
  {
    short: '沪',
    name: '上海',
    english: 'Shanghai',
    country: 'China'
  },
  {
    short: '津',
    name: '天津',
    english: 'Tianjin',
    country: 'China'
  },
  {
    short: '港',
    name: '香港',
    english: 'Hongkong',
    alt: ['Hong Kong'],
    country: 'China'
  },
  {
    short: '澳',
    name: '澳门',
    english: 'Macau',
    alt: ['Macao'],
    country: 'China'
  },
  {
    short: '台',
    name: '台湾',
    english: 'Taiwan',
    country: 'China'
  },
  {
    short: '赣',
    name: '江西',
    english: 'Jiangxi',
    country: 'China'
  },
  {
    short: '粤',
    name: '广东',
    english: 'Guangdong',
    country: 'China'
  },
  {
    short: 'BW',
    name: 'Baden-Württemberg',
    country: 'Germany'
  },
  {
    short: 'BY',
    name: 'Bayern',
    country: 'Germany'
  },
  {
    short: 'BE',
    name: 'Berlin',
    country: 'Germany'
  },
  {
    short: 'BB',
    name: 'Brandenburg',
    country: 'Germany'
  },
  {
    short: 'HB',
    name: 'Bremen',
    country: 'Germany'
  },
  {
    short: 'HH',
    name: 'Hamburg',
    country: 'Germany'
  },
  {
    short: 'HE',
    name: 'Hessen',
    country: 'Germany'
  },
  {
    short: 'MV',
    name: 'Mecklenburg-Vorpommern',
    country: 'Germany'
  },
  {
    short: 'NI',
    name: 'Niedersachsen',
    country: 'Germany'
  },
  {
    short: 'NW',
    name: 'Nordrhein-Westfalen',
    country: 'Germany'
  },
  {
    short: 'RP',
    name: 'Rheinland-Pfalz',
    country: 'Germany'
  },
  {
    short: 'SL',
    name: 'Saarland',
    country: 'Germany'
  },
  {
    short: 'SN',
    name: 'Sachsen',
    country: 'Germany'
  },
  {
    short: 'ST',
    name: 'Sachsen-Anhalt',
    country: 'Germany'
  },
  {
    short: 'SH',
    name: 'Schleswig-Holstein',
    country: 'Germany'
  },
  {
    short: 'TH',
    name: 'Thüringen',
    country: 'Germany'
  },
  {
    short: 'DR',
    name: 'Drenthe',
    country: 'Netherlands'
  },
  {
    short: 'FL',
    name: 'Flevoland',
    country: 'Netherlands'
  },
  {
    short: 'FR',
    name: 'Friesland',
    country: 'Netherlands',
    alt: ['Fryslân']
  },
  {
    short: 'GD',
    name: 'Gelderland',
    country: 'Netherlands'
  },
  {
    short: 'GR',
    name: 'Groningen',
    country: 'Netherlands'
  },
  {
    short: 'LB',
    name: 'Limburg',
    country: 'Netherlands'
  },
  {
    short: 'NB',
    name: 'Noord-Brabant',
    country: 'Netherlands'
  },
  {
    short: 'NH',
    name: 'Noord-Holland',
    country: 'Netherlands'
  },
  {
    short: 'OV',
    name: 'Overijssel',
    country: 'Netherlands'
  },
  {
    short: 'UT',
    name: 'Utrecht',
    country: 'Netherlands'
  },
  {
    short: 'ZH',
    name: 'Zuid-Holland',
    country: 'Netherlands'
  },
  {
    short: 'ZL',
    name: 'Zeeland',
    country: 'Netherlands'
  },
  {
    short: 'ANT',
    name: 'Antwerpen',
    country: 'Belgium'
  },
  {
    short: 'HAI',
    name: 'Henegouwen',
    country: 'Belgium',
    alt: ['Hainaut']
  },
  {
    short: 'LIE',
    name: 'Luik',
    country: 'Belgium',
    alt: ['Liège']
  },
  {
    short: 'LIM',
    name: 'Limburg',
    country: 'Belgium'
  },
  {
    short: 'LUX',
    name: 'Luxemburg',
    country: 'Belgium'
  },
  {
    short: 'NAM',
    name: 'Namen',
    country: 'Belgium'
  },
  {
    short: 'OVL',
    name: 'Oost-Vlaanderen',
    country: 'Belgium'
  },
  {
    short: 'VBR',
    name: 'Vlaams-Brabant',
    country: 'Belgium'
  },
  {
    short: 'WBR',
    name: 'Waals-Brabant',
    country: 'Belgium'
  },
  {
    short: 'WVL',
    name: 'West-Vlaanderen',
    country: 'Belgium'
  },
  {
    short: 'ID-AC',
    name: 'Special Region of Aceh',
    country: 'Indonesia'
  },
  {
    short: 'ID-BA',
    name: 'Bali',
    country: 'Indonesia'
  },
  {
    short: 'ID-BB',
    name: 'Bangka–Belitung Islands',
    country: 'Indonesia'
  },
  {
    short: 'ID-BT',
    name: 'Banten',
    country: 'Indonesia'
  },
  {
    short: 'ID-BE',
    name: 'Bengkulu',
    country: 'Indonesia'
  },
  {
    short: 'ID-JT',
    name: 'Central Java',
    country: 'Indonesia'
  },
  {
    short: 'ID-KT',
    name: 'Central Kalimantan',
    country: 'Indonesia'
  },
  {
    short: 'ID-ST',
    name: 'Central Sulawesi',
    country: 'Indonesia'
  },
  {
    short: 'ID-JI',
    name: 'East Java',
    country: 'Indonesia'
  },
  {
    short: 'ID-KI',
    name: 'East Kalimantan',
    country: 'Indonesia'
  },
  {
    short: 'ID-NT',
    name: 'East Nusa Tenggara',
    country: 'Indonesia'
  },
  {
    short: 'ID-GO',
    name: 'Gorontalo',
    country: 'Indonesia'
  },
  {
    short: 'ID-JK',
    name: 'Jakarta Special Capital Region',
    country: 'Indonesia'
  },
  {
    short: 'ID-JA',
    name: 'Jambi',
    country: 'Indonesia'
  },
  {
    short: 'ID-LA',
    name: 'Lampung',
    country: 'Indonesia'
  },
  {
    short: 'ID-MA',
    name: 'Maluku',
    country: 'Indonesia'
  },
  {
    short: 'ID-KU',
    name: 'North Kalimantan',
    country: 'Indonesia'
  },
  {
    short: 'ID-MU',
    name: 'North Maluku',
    country: 'Indonesia'
  },
  {
    short: 'ID-SA',
    name: 'North Sulawesi',
    country: 'Indonesia'
  },
  {
    short: 'ID-SU',
    name: 'North Sumatra',
    country: 'Indonesia'
  },
  {
    short: 'ID-PA',
    name: 'Special Region of Papua',
    country: 'Indonesia'
  },
  {
    short: 'ID-RI',
    name: 'Riau',
    country: 'Indonesia'
  },
  {
    short: 'ID-KR',
    name: 'Riau Islands',
    country: 'Indonesia'
  },
  {
    short: 'ID-SG',
    name: 'Southeast Sulawesi',
    country: 'Indonesia'
  },
  {
    short: 'ID-KS',
    name: 'South Kalimantan',
    country: 'Indonesia'
  },
  {
    short: 'ID-SN',
    name: 'South Sulawesi',
    country: 'Indonesia'
  },
  {
    short: 'ID-SS',
    name: 'South Sumatra',
    country: 'Indonesia'
  },
  {
    short: 'ID-JB',
    name: 'West Java',
    country: 'Indonesia'
  },
  {
    short: 'ID-KB',
    name: 'West Kalimantan',
    country: 'Indonesia'
  },
  {
    short: 'ID-NB',
    name: 'West Nusa Tenggara',
    country: 'Indonesia'
  },
  {
    short: 'ID-PB',
    name: 'Special Region of West Papua',
    country: 'Indonesia'
  },
  {
    short: 'ID-SR',
    name: 'West Sulawesi',
    country: 'Indonesia'
  },
  {
    short: 'ID-SB',
    name: 'West Sumatra',
    country: 'Indonesia'
  },
  {
    short: 'ID-YO',
    name: 'Special Region of Yogyakarta',
    country: 'Indonesia'
  },
  {
    short: 'AP',
    name: 'Andhra Pradesh',
    country: 'India'
  },
  {
    short: 'AR',
    name: 'Arunachal Pradesh',
    country: 'India'
  },
  {
    short: 'AS',
    name: 'Assam',
    country: 'India'
  },
  {
    short: 'BR',
    name: 'Bihar',
    country: 'India'
  },
  {
    short: 'CT',
    name: 'Chhattisgarh',
    country: 'India'
  },
  {
    short: 'GA',
    name: 'Goa',
    country: 'India'
  },
  {
    short: 'GJ',
    name: 'Gujarat',
    country: 'India'
  },
  {
    short: 'HR',
    name: 'Haryana',
    country: 'India'
  },
  {
    short: 'HP',
    name: 'Himachal Pradesh',
    country: 'India'
  },
  {
    short: 'JK',
    name: 'Jammu and Kashmir',
    country: 'India'
  },
  {
    short: 'JH',
    name: 'Jharkhand',
    country: 'India'
  },
  {
    short: 'KA',
    name: 'Karnataka',
    country: 'India'
  },
  {
    short: 'KL',
    name: 'Kerala',
    country: 'India'
  },
  {
    short: 'MP',
    name: 'Madhya Pradesh',
    country: 'India'
  },
  {
    short: 'MH',
    name: 'Maharashtra',
    country: 'India'
  },
  {
    short: 'MN',
    name: 'Manipur',
    country: 'India'
  },
  {
    short: 'ML',
    name: 'Meghalaya',
    country: 'India'
  },
  {
    short: 'MZ',
    name: 'Mizoram',
    country: 'India'
  },
  {
    short: 'NL',
    name: 'Nagaland',
    country: 'India'
  },
  {
    short: 'OR',
    name: 'Odisha',
    country: 'India'
  },
  {
    short: 'PB',
    name: 'Punjab',
    country: 'India'
  },
  {
    short: 'RJ',
    name: 'Rajasthan',
    country: 'India'
  },
  {
    short: 'SK',
    name: 'Sikkim',
    country: 'India'
  },
  {
    short: 'TN',
    name: 'Tamil Nadu',
    country: 'India'
  },
  {
    short: 'TG',
    name: 'Telangana',
    country: 'India'
  },
  {
    short: 'TR',
    name: 'Tripura',
    country: 'India'
  },
  {
    short: 'UP',
    name: 'Uttar Pradesh',
    country: 'India'
  },
  {
    short: 'UT',
    name: 'Uttarakhand',
    country: 'India'
  },
  {
    short: 'WB',
    name: 'West Bengal',
    country: 'India'
  },
  {
    short: 'AN',
    name: 'Andaman and Nicobar Islands',
    country: 'India'
  },
  {
    short: 'CH',
    name: 'Chandigarh',
    country: 'India'
  },
  {
    short: 'DN',
    name: 'Dadra and Nagar Haveli',
    country: 'India'
  },
  {
    short: 'DD',
    name: 'Daman and Diu',
    country: 'India'
  },
  {
    short: 'LD',
    name: 'Lakshadweep',
    country: 'India'
  },
  {
    short: 'DL',
    name: 'National Capital Territory of Delhi',
    country: 'India'
  },
  {
    short: 'PY',
    name: 'Puducherry',
    country: 'India'
  },
  {
    short: 'C',
    name: 'La Coruña',
    country: 'Spain'
  },
  {
    short: 'LU',
    name: 'Lugo',
    country: 'Spain'
  },
  {
    short: 'BI',
    name: 'Vizcaya',
    country: 'Spain'
  },
  {
    short: 'SS',
    name: 'Guipúzcoa',
    country: 'Spain'
  },
  {
    short: 'HU',
    name: 'Huesca',
    country: 'Spain'
  },
  {
    short: 'L',
    name: 'Lérida',
    country: 'Spain'
  },
  {
    short: 'GI',
    name: 'Gerona',
    country: 'Spain'
  },
  {
    short: 'B',
    name: 'Barcelona',
    country: 'Spain'
  },
  {
    short: 'T',
    name: 'Tarragona',
    country: 'Spain'
  },
  {
    short: 'CS',
    name: 'Castellón',
    country: 'Spain'
  },
  {
    short: 'V',
    name: 'Valencia',
    country: 'Spain'
  },
  {
    short: 'A',
    name: 'Alicante',
    country: 'Spain'
  },
  {
    short: 'MU',
    name: 'Murcia',
    country: 'Spain'
  },
  {
    short: 'Z',
    name: 'Zaragoza',
    country: 'Spain'
  },
  {
    short: 'TE',
    name: 'Teruel',
    country: 'Spain'
  },
  {
    short: 'CU',
    name: 'Cuenca',
    country: 'Spain'
  },
  {
    short: 'AB',
    name: 'Albacete',
    country: 'Spain'
  },
  {
    short: 'AL',
    name: 'Almeria',
    country: 'Spain'
  },
  {
    short: 'GR',
    name: 'Granada',
    country: 'Spain'
  },
  {
    short: 'MA',
    name: 'Málaga',
    country: 'Spain'
  },
  {
    short: 'TF',
    name: 'Tenerife',
    country: 'Spain'
  },
  {
    short: 'CA',
    name: 'Cádiz',
    country: 'Spain'
  },
  {
    short: 'SE',
    name: 'Sevilla',
    country: 'Spain'
  },
  {
    short: 'H',
    name: 'Huelva',
    country: 'Spain'
  },
  {
    short: 'GC',
    name: 'Las Palmas',
    country: 'Spain'
  },
  {
    short: 'M',
    name: 'Madrid',
    country: 'Spain'
  },
  {
    short: 'BA',
    name: 'Badajoz',
    country: 'Spain'
  },
  {
    short: 'CC',
    name: 'Cáceres',
    country: 'Spain'
  },
  {
    short: 'TO',
    name: 'Toledo',
    country: 'Spain'
  },
  {
    short: 'CR',
    name: 'Ciudad Real',
    country: 'Spain'
  },
  {
    short: 'SA',
    name: 'Salamanca',
    country: 'Spain'
  },
  {
    short: 'CO',
    name: 'Córdoba',
    country: 'Spain'
  },
  {
    short: 'J',
    name: 'Jaén',
    country: 'Spain'
  },
  {
    short: 'AV',
    name: 'Ávila',
    country: 'Spain'
  },
  {
    short: 'VA',
    name: 'Valladolid',
    country: 'Spain'
  },
  {
    short: 'ZA',
    name: 'Zamora',
    country: 'Spain'
  },
  {
    short: 'VI',
    name: 'Álava',
    country: 'Spain'
  },
  {
    short: 'SG',
    name: 'Segovia',
    country: 'Spain'
  },
  {
    short: 'BU',
    name: 'Burgos',
    country: 'Spain'
  },
  {
    short: 'PO',
    name: 'Pontevedra',
    country: 'Spain'
  },
  {
    short: 'LE',
    name: 'León',
    country: 'Spain'
  },
  {
    short: 'OU',
    name: 'Orense',
    country: 'Spain'
  },
  {
    short: 'P',
    name: 'Palencia',
    country: 'Spain'
  },
  {
    short: 'LO',
    name: 'La Rioja',
    country: 'Spain'
  },
  {
    short: 'SO',
    name: 'Soria',
    country: 'Spain'
  },
  {
    short: 'GU',
    name: 'Guadalajara',
    country: 'Spain'
  },
  {
    short: 'AB',
    name: 'Abia',
    country: 'Nigeria'
  },
  {
    short: 'FC',
    name: 'Abuja',
    country: 'Nigeria'
  },
  {
    short: 'AD',
    name: 'Adamawa',
    country: 'Nigeria'
  },
  {
    short: 'AK',
    name: 'Akwa Ibom',
    country: 'Nigeria'
  },
  {
    short: 'AN',
    name: 'Anambra',
    country: 'Nigeria'
  },
  {
    short: 'BA',
    name: 'Bauchi',
    country: 'Nigeria'
  },
  {
    short: 'BY',
    name: 'Bayelsa',
    country: 'Nigeria'
  },
  {
    short: 'BE',
    name: 'Benue',
    country: 'Nigeria'
  },
  {
    short: 'BO',
    name: 'Borno',
    country: 'Nigeria'
  },
  {
    short: 'CR',
    name: 'Cross River',
    country: 'Nigeria'
  },
  {
    short: 'DE',
    name: 'Delta',
    country: 'Nigeria'
  },
  {
    short: 'EB',
    name: 'Ebonyi',
    country: 'Nigeria'
  },
  {
    short: 'ED',
    name: 'Edo',
    country: 'Nigeria'
  },
  {
    short: 'EK',
    name: 'Ekiti',
    country: 'Nigeria'
  },
  {
    short: 'EN',
    name: 'Enugu',
    country: 'Nigeria'
  },
  {
    short: 'GO',
    name: 'Gombe',
    country: 'Nigeria'
  },
  {
    short: 'IM',
    name: 'Imo',
    country: 'Nigeria'
  },
  {
    short: 'JI',
    name: 'Jigawa',
    country: 'Nigeria'
  },
  {
    short: 'KD',
    name: 'Kaduna',
    country: 'Nigeria'
  },
  {
    short: 'KN',
    name: 'Kano',
    country: 'Nigeria'
  },
  {
    short: 'KT',
    name: 'Katsina',
    country: 'Nigeria'
  },
  {
    short: 'KE',
    name: 'Kebbi',
    country: 'Nigeria'
  },
  {
    short: 'KO',
    name: 'Kogi',
    country: 'Nigeria'
  },
  {
    short: 'KW',
    name: 'Kwara',
    country: 'Nigeria'
  },
  {
    short: 'LA',
    name: 'Lagos',
    country: 'Nigeria'
  },
  {
    short: 'NA',
    name: 'Nasarawa',
    country: 'Nigeria'
  },
  {
    short: 'NI',
    name: 'Niger',
    country: 'Nigeria'
  },
  {
    short: 'OG',
    name: 'Ogun',
    country: 'Nigeria'
  },
  {
    short: 'ON',
    name: 'Ondo',
    country: 'Nigeria'
  },
  {
    short: 'OS',
    name: 'Osun',
    country: 'Nigeria'
  },
  {
    short: 'OY',
    name: 'Oyo',
    country: 'Nigeria'
  },
  {
    short: 'PL',
    name: 'Plateau',
    country: 'Nigeria'
  },
  {
    short: 'RI',
    name: 'Rivers',
    country: 'Nigeria'
  },
  {
    short: 'SO',
    name: 'Sokoto',
    country: 'Nigeria'
  },
  {
    short: 'TA',
    name: 'Taraba',
    country: 'Nigeria'
  },
  {
    short: 'YO',
    name: 'Yobe',
    country: 'Nigeria'
  },
  {
    short: 'ZA',
    name: 'Zamfara',
    country: 'Nigeria'
  },
  {
    short: 'B',
    name: 'Burgenland',
    country: 'Austria'
  },
  {
    short: 'K',
    name: 'Kärnten',
    country: 'Austria'
  },
  {
    short: 'NÖ',
    name: 'Niederösterreich',
    country: 'Austria'
  },
  {
    short: 'OÖ',
    name: 'Oberösterreich',
    country: 'Austria'
  },
  {
    short: 'S',
    name: 'Salzburg',
    country: 'Austria'
  },
  {
    short: 'ST',
    name: 'Steiermark',
    country: 'Austria'
  },
  {
    short: 'T',
    name: 'Tirol',
    country: 'Austria'
  },
  {
    short: 'V',
    name: 'Vorarlberg',
    country: 'Austria'
  },
  {
    short: 'W',
    name: 'Wien',
    country: 'Austria'
  },
  {
    short: 'AC',
    name: 'Acre',
    country: 'Brazil'
  },
  {
    short: 'AL',
    name: 'Alagoas',
    country: 'Brazil'
  },
  {
    short: 'AP',
    name: 'Amapá',
    country: 'Brazil'
  },
  {
    short: 'AM',
    name: 'Amazonas',
    country: 'Brazil'
  },
  {
    short: 'BA',
    name: 'Bahia',
    country: 'Brazil'
  },
  {
    short: 'CE',
    name: 'Ceará',
    country: 'Brazil'
  },
  {
    short: 'DF',
    name: 'Distrito Federal',
    country: 'Brazil'
  },
  {
    short: 'ES',
    name: 'Espírito Santo',
    country: 'Brazil'
  },
  {
    short: 'GO',
    name: 'Goiás',
    country: 'Brazil'
  },
  {
    short: 'MA',
    name: 'Maranhão',
    country: 'Brazil'
  },
  {
    short: 'MT',
    name: 'Mato Grosso',
    country: 'Brazil'
  },
  {
    short: 'MS',
    name: 'Mato Grosso do Sul',
    country: 'Brazil'
  },
  {
    short: 'MG',
    name: 'Minas Gerais',
    country: 'Brazil'
  },
  {
    short: 'PA',
    name: 'Pará',
    country: 'Brazil'
  },
  {
    short: 'PB',
    name: 'Paraíba',
    country: 'Brazil'
  },
  {
    short: 'PR',
    name: 'Paraná',
    country: 'Brazil'
  },
  {
    short: 'PE',
    name: 'Pernambuco',
    country: 'Brazil'
  },
  {
    short: 'PI',
    name: 'Piauí',
    country: 'Brazil'
  },
  {
    short: 'RJ',
    name: 'Rio de Janeiro',
    country: 'Brazil'
  },
  {
    short: 'RN',
    name: 'Rio Grande do Norte',
    country: 'Brazil'
  },
  {
    short: 'RS',
    name: 'Rio Grande do Sul',
    country: 'Brazil'
  },
  {
    short: 'RO',
    name: 'Rondônia',
    country: 'Brazil'
  },
  {
    short: 'RR',
    name: 'Roraima',
    country: 'Brazil'
  },
  {
    short: 'SC',
    name: 'Santa Catarina',
    country: 'Brazil'
  },
  {
    short: 'SP',
    name: 'São Paulo',
    country: 'Brazil'
  },
  {
    short: 'SE',
    name: 'Sergipe',
    country: 'Brazil'
  },
  {
    short: 'TO',
    name: 'Tocantins',
    country: 'Brazil'
  }
]

export const getAbbreviatedState = (country, state) => {
  if (!state) return
  const province = provinces.find(province => {
    const countryMatch = country
      ? province.country.toLowerCase() === country.toLowerCase()
      : true
    let provinceMatch = false
    if (typeof province.name === 'string') {
      provinceMatch = province.name.toLowerCase() === state.toLowerCase()
    } else {
      province.name.forEach(name => {
        provinceMatch = name.toLowerCase() === state.toLowerCase()
      })
    }
    return countryMatch && provinceMatch
  })
  return province?.short
}
