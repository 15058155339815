import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mediaAssociation: {
    tokenId: undefined,
    mediaToAssociate: [],
    feature: undefined
  }
}

export const oauth2Slice = createSlice({
  name: 'oauth2Slice',
  initialState,
  reducers: {
    showMediaAssociation: (state, action) => {
      state.mediaAssociation = action.payload
    },
    clearMediaAssociation: state => {
      state.mediaAssociation = initialState.mediaAssociation
    }
  }
})

export const {
  showMediaAssociation,
  clearMediaAssociation
} = oauth2Slice.actions

export default oauth2Slice.reducer
