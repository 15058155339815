import React, { memo } from 'react'
import moment from 'moment'
import classNames from 'classnames'

import {
  DateTimeView,
  TableLibraryCell,
  TableLibraryRow
} from 'components/TableLibrary'
import DeviceNameViewCell from '../DeviceNameViewCell'
import PulseDot from '../PulseDot/PulseDot'
import DeviceTypeIconCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceTypeIconCell'
import Tooltip from 'components/Tooltip'
import StatusWithIcon from 'components/StatusWithIcon'
import { getStatusInfo } from 'utils/deviceUtils'
import { deviceStatus } from 'constants/deviceConstants'
import { columnWidths } from '../../../../TableLibrary/TableLibraryHead'

const TableRow = ({
  row,
  columns,
  classes,
  t,
  role,
  dateTimeFromNow,
  handleChangeFormat = f => f,
  isPublic
}) => {
  const statusInfo = getStatusInfo({
    availableFirmwareList: row.availableFirmwareList,
    status: row.status,
    firmware: row.firmware,
    lastCheckInUTC: row.lastCheckInUTC,
    t
  })
  return (
    <TableLibraryRow
      hover
      role="checkbox"
      tabIndex={-1}
      rootClassName={classes.tableRowRoot}
    >
      <DeviceTypeIconCell
        item={row}
        paddingVariant="big"
        isPopupDisabled={isPublic}
      />

      <TableLibraryCell
        rootClassName={classNames(classes.name, classes.aliasWrapContent)}
        width={columnWidths.alias}
      >
        <DeviceNameViewCell role={role} classes={classes} notes={0} row={row} />
      </TableLibraryCell>

      {columns
        .filter(({ display }) => display)
        .map(column => {
          switch (column.id) {
            case 'lastCheckInUTC':
              return (
                <TableLibraryCell
                  rootClassName={classNames(classes.dateTimeView)}
                  key={column.id}
                  align="center"
                  onClick={handleChangeFormat}
                >
                  {dateTimeFromNow && row.lastCheckInUTC ? (
                    <div>{moment(row.lastCheckInUTC).fromNow()}</div>
                  ) : (
                    <div>
                      <DateTimeView date={row.lastCheckInUTC} />
                    </div>
                  )}
                </TableLibraryCell>
              )
            case 'status':
              return (
                <TableLibraryCell key={column.id} align="center">
                  <StatusWithIcon
                    status={row.status}
                    needsAttention={statusInfo?.needsAttention}
                    headerText={statusInfo?.headerTitle}
                    title={statusInfo?.title}
                    hasTriangleIcon={row.status === deviceStatus.dormant}
                    hasGrayIcon={row.status === deviceStatus.dormant}
                    hideIcon={row.status === deviceStatus.disabled}
                    t={t}
                  />
                </TableLibraryCell>
              )
            case 'lanIP':
              return (
                <TableLibraryCell key={column.id} align="center">
                  {!row.wanIP && !row.lanIP ? (
                    <div className={classes.lanText}>{t('N/A')}</div>
                  ) : (
                    <>
                      <div className={classes.ipText}>
                        {row.wanIP ? row.wanIP : t('N/A')}
                      </div>
                      <div className={classes.lanText}>
                        {row.lanIP ? row.lanIP : t('N/A')}
                      </div>
                    </>
                  )}
                </TableLibraryCell>
              )
            case 'networkConnectivity':
              return (
                <TableLibraryCell key={column.id} align="center">
                  <PulseDot
                    color={
                      row.status === 'Active' &&
                      (row.connectivityToOurServer ||
                        row.connectivityTo3rdPartyServer)
                        ? 'green'
                        : 'red'
                    }
                  />
                </TableLibraryCell>
              )
            case 'synchronousConnection':
              return (
                <TableLibraryCell key={column.id} align="center">
                  {!row.synchronousConnection &&
                  moment(row.synchronousDisconnectedAt).isValid() ? (
                    <Tooltip
                      title={t('Last synchronous connectivity')}
                      placement="top"
                    >
                      <div>
                        <DateTimeView date={row.synchronousDisconnectedAt} />
                      </div>
                    </Tooltip>
                  ) : row.synchronousConnection ? (
                    <PulseDot color={'green'} tooltipText={t('Synced')} />
                  ) : (
                    t('N/A')
                  )}
                </TableLibraryCell>
              )
            default:
              return null
          }
        })}
      <TableLibraryCell />
    </TableLibraryRow>
  )
}

export default memo(TableRow)
