import { useCallback, useMemo, useRef } from 'react'

export default function useTimeout() {
  const timeout = useRef(null)

  const _setTimeout = useCallback((callback, ms) => {
    timeout.current = setTimeout(callback, ms)
  }, [])

  const _clearTimeout = useCallback(() => {
    clearTimeout(timeout.current)
  }, [])

  return useMemo(() => [_setTimeout, _clearTimeout], [
    _setTimeout,
    _clearTimeout
  ])
}
