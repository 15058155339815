import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import NoteIndicator from 'components/NoteIndicator'
import { useUserRole } from 'hooks/tableLibrary'
import { isGalleryTemplate } from 'utils/templateUtils'

const TemplateGalleryIndicator = ({ template = {}, ...props }) => {
  const { t } = useTranslation()
  const role = useUserRole()

  return role.system && isGalleryTemplate(template) ? (
    <NoteIndicator
      icon="fa-sharp fa-light fa-images"
      content={t('Published to Template Gallery')}
      {...props}
    />
  ) : null
}

TemplateGalleryIndicator.propTypes = {
  template: PropTypes.object
}

export default TemplateGalleryIndicator
