import * as types from './index'

const getPlaylistItemsAction = params => ({
  type: types.GET_PLAYLIST_ITEMS,
  params,
  loading: true
})

const getModalPlaylistItemsAction = params => ({
  type: types.GET_MODAL_PLAYLIST_ITEMS,
  params,
  loading: true
})

const resetPlaylistFetchParamsAction = () => ({
  type: types.CLEAR_PLAYLIST_FETCH_PARAMS
})

const addPlaylist = (data, isPlaylist) => ({
  type: types.POST_PLAYLIST,
  data,
  isPlaylist
})

const editPlaylist = (data, id, isPlaylist) => ({
  type: types.PUT_PLAYLIST,
  data,
  meta: {
    id,
    isPlaylist
  }
})

const deletePlaylist = (id, data) => ({
  type: types.DELETE_PLAYLIST,
  id,
  data
})

const approvePlaylist = id => ({
  type: types.APPROVE_PLAYLIST,
  id
})

const deleteSelectedPlaylist = (ids, data) => ({
  type: types.DELETE_SELECTED_PLAYLIST,
  ids,
  data
})

const getPlaylistById = id => ({
  type: types.GET_PLAYLIST_BY_ID,
  data: id
})

const clearAddedPlaylist = meta => ({
  type: types.CLEAR_ADDED_PLAYLIST,
  meta
})

const getPlaylistLibraryPref = () => ({ type: types.GET_PLAYLIST_PREFERENCE })

const putPlaylistLibraryPref = data => ({
  type: types.PUT_PLAYLIST_PREFERENCE,
  payload: data
})

const getPlaylistGroupItemsAction = (id, params) => ({
  type: types.GET_PLAYLIST_GROUP_ITEMS,
  payload: { id, params }
})

const clearGetPlaylistGroupItemsInfoAction = () => ({
  type: types.CLEAR_GET_PLAYLIST_GROUP_ITEMS_INFO
})

const clearPlaylistGroupItemsInfo = () => ({
  type: types.CLEAR_PLAYLIST_GROUP_ITEMS_RESPONSE_INFO
})

const postPlaylistGroupItemAction = data => ({
  type: types.POST_PLAYLIST_GROUP_ITEM,
  payload: data
})

const clearPostPlaylistGroupItemInfoAction = () => ({
  type: types.CLEAR_POST_PLAYLIST_GROUP_ITEM_INFO
})

const deletePlaylistGroupItemAction = data => ({
  type: types.DELETE_PLAYLIST_GROUP_ITEM,
  payload: data
})

const clearDeletePlaylistGroupItemInfoAction = () => ({
  type: types.CLEAR_DELETE_PLAYLIST_GROUP_ITEM_INFO
})

const clearResponseInfo = () => ({
  type: types.CLEAR_PLAYLIST_RESPONSE_INFO
})

const clonePlaylist = data => ({
  type: types.CLONE_PLAYLIST,
  data
})

const getPlaylistPreview = (data, key) => ({
  type: types.GET_PLAYLIST_PREVIEW,
  data,
  meta: { key }
})

const generatePlaylistPreview = data => ({
  type: types.GENERATE_PLAYLIST_PREVIEW,
  data
})

const closePlaylistPreview = () => ({
  type: types.CLOSE_PLAYLIST_PREVIEW
})

const clearPlaylistPreview = () => ({
  type: types.CLEAR_PLAYLIST_PREVIEW
})

const showPlaylistPreview = () => ({
  type: types.SHOW_PLAYLIST_PREVIEW
})

const clearPlaylistItemsAction = params => ({
  type: types.CLEAR_PLAYLIST_ITEMS,
  params
})

const sharePlaylist = (playlistId, data) => ({
  type: types.SHARE_PLAYLIST,
  payload: {
    playlistId,
    data
  }
})

const copyPlaylist = data => ({
  type: types.COPY_PLAYLIST,
  data
})

const getGroupPlaylists = params => ({
  type: types.GET_PLAYLIST_GROUP_PLAYLISTS,
  params
})
const clearPlaylistStatus = () => ({
  type: types.CLEAR_PLAYLIST_STATUS
})

const checkPlaylistAvailability = () => ({
  type: types.GET_ASSIGNABLE_PLAYLIST
})

const getPlaylistAssociations = (id, params) => ({
  type: types.GET_PLAYLIST_ASSOCIATIONS,
  payload: {
    id,
    params
  }
})

const clearPlaylistAssociations = () => ({
  type: types.CLEAR_PLAYLIST_ASSOCIATIONS
})

const postPlaylistBulk = payload => ({
  type: types.POST_PLAYLIST_BULK,
  payload
})

const clearPostPlaylistBulkInfo = () => ({
  type: types.CLEAR_POST_PLAYLIST_BULK_INFO
})

export {
  addPlaylist,
  editPlaylist,
  getPlaylistById,
  clearAddedPlaylist,
  getPlaylistItemsAction,
  getModalPlaylistItemsAction,
  getPlaylistLibraryPref,
  putPlaylistLibraryPref,
  resetPlaylistFetchParamsAction,
  getPlaylistGroupItemsAction,
  clearGetPlaylistGroupItemsInfoAction,
  postPlaylistGroupItemAction,
  clearPostPlaylistGroupItemInfoAction,
  deletePlaylistGroupItemAction,
  clearDeletePlaylistGroupItemInfoAction,
  deletePlaylist,
  approvePlaylist,
  deleteSelectedPlaylist,
  clearResponseInfo,
  clonePlaylist,
  clearPlaylistGroupItemsInfo,
  clearPlaylistStatus,
  getPlaylistPreview,
  generatePlaylistPreview,
  closePlaylistPreview,
  clearPlaylistPreview,
  showPlaylistPreview,
  clearPlaylistItemsAction,
  sharePlaylist,
  copyPlaylist,
  getGroupPlaylists,
  checkPlaylistAvailability,
  getPlaylistAssociations,
  clearPlaylistAssociations,
  postPlaylistBulk,
  clearPostPlaylistBulkInfo
}
