import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { bindActionCreators, compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { hexToRgb, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import { getBackgroundPattern } from 'actions/configActions'
import DefaultModal from 'components/Modal/DefaultModal'
import {
  FormControlBrandGuideColorPicker,
  FormControlReactSelect
} from 'components/Form'
import { BlueButton } from 'components/Buttons'
import BasePaginate from 'components/BasePaginate'
import Scrollbars from 'components/Scrollbars'
import { CircularLoader } from 'components/Loaders'
import Container from 'components/Containers/Container'
import Spacing from 'components/Containers/Spacing'
import MessageCard from 'components/Card/MessageCard'
import useConfigFeatureMedia from 'hooks/api/useConfigFeatureMedia'
import {
  BRANDING_FEATURE,
  enabledBrandingFeatures,
  IMAGE_FEATURE_ID
} from 'constants/featureConstants'
import { backgroundTypes } from 'constants/media'
import { approveStatuses } from 'constants/library'
import useMediaBrandGuide from 'hooks/useMediaBrandGuide'
import { rgbToRgba } from 'utils/rgbaToRgb'
import { getRandomColor } from 'utils/color'
import { isFeatureAvailable } from 'utils/api/featureAvailability'
import { parseCssClass } from 'utils/cssParser'
import GridViewCard, { MediaCardBottomInfo } from 'components/Card/GridViewCard'
import { getThumbnail } from 'utils/mediaUtils'
import isEmpty from 'utils/isEmpty'
import { DEFAULT_BACKGROUND_COLOR } from 'constants/createTemplate'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import { useLazyGetMediaLibraryItemsQuery } from 'api/mediaApi'
import { initialMeta } from 'constants/api'

const IMAGE_CARD_HEIGHT = 249
const GRID_GAP = 16
const VERTICAL_PADDING = 3

const styles = () => ({
  content: {
    overflow: 'visible'
  },
  modalHeader: {
    borderRadius: '8px 8px 0 0',
    padding: '0 32px',
    minHeight: 55
  },
  modalFooter: {
    borderRadius: '0 0 8px 8px'
  },
  modalContainer: {
    overflowY: 'visible'
  },
  scrollbarRoot: {
    maxHeight: 'calc(100vh - 375px)'
  },
  messageRoot: {
    marginTop: '16px'
  },
  messageIconRoot: {
    maxWidth: '3% !important'
  },
  htmlFallback: {
    content: '',
    height: '100% !important',
    width: '100% !important'
  },
  viewArea: {
    width: '100%',
    minWidth: '100%',
    height: '100%',
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden'
  },
  patternRoot: {
    height: '480px'
  },
  gridCardRoot: {
    width: '100%'
  },
  containerWrap: {
    justifyItems: 'center',
    overflow: 'hidden',
    padding: VERTICAL_PADDING
  },
  modalRootClass: {
    zIndex: '1301 !important'
  },
  emptyContentWrap: {
    height: '100%'
  }
})

const scrollbarsStyle = {
  height: IMAGE_CARD_HEIGHT * 2 + GRID_GAP + VERTICAL_PADDING * 2
}

const SelectBackgroundDialog = ({
  t,
  classes,
  patternMeta,
  patterns,
  onClickSave,
  getBackgroundPattern,
  pageRangeDisplayed = 5,
  withoutPattern = false,
  withoutImage = false,
  values = {},
  names,
  onChange,
  returnPatternId = false,
  disableSelectType = false,
  disableImageSize = false,
  isLoading,
  backgroundOptions,
  maxResolution,
  open,
  paginationConfig,
  onCloseModal,
  featureName,
  themeColors,
  randomColorsData = [],
  defaultColor,
  initialBrandValue,
  renderForPreview,
  mode,
  renderDefaultColor = false,
  ...props
}) => {
  const [selected, setSelected] = useState(null)
  const [page, setPage] = useState(
    paginationConfig?.initialPage[paginationConfig.type] || 1
  )

  const [
    getBackgroundImagesFromMedia,
    mediaQuery
  ] = useLazyGetMediaLibraryItemsQuery()
  const { images, imageMeta } = useMemo(
    () => ({
      images: mediaQuery.data?.data || [],
      imageMeta: {
        ...(mediaQuery.data?.meta || initialMeta),
        isLoading: mediaQuery.isFetching || mediaQuery.isUninitialized
      }
    }),
    [mediaQuery]
  )

  const onToggleSelectItem = useCallback(
    (item, type) => {
      if (item.id === selected) setSelected(null)
      else {
        setSelected(item.id)
        onChange({
          target: {
            value: item.id,
            name: type === 'image' ? names.image_id : names.pattern,
            data: item
          }
        })
      }
    },
    [selected, setSelected, onChange, names]
  )

  const [isVisiblePaginate, setVisiblePaginate] = useState(false)
  const [randomColors, setRandomColors] = useState(randomColorsData)

  const configFeatureMedia = useConfigFeatureMedia(false, [], { includeAll: 1 })
  const { isEnabledBrandGuide, brandGuideSettings } = useMediaBrandGuide()

  const imageFeatureId = useMemo(
    () =>
      configFeatureMedia?.response?.find(({ name }) => name === 'Image')?.id ||
      IMAGE_FEATURE_ID,
    [configFeatureMedia]
  )

  const handleChangePage = useCallback(
    ({ selected }) => {
      if (values.type === backgroundTypes.pattern) {
        getBackgroundPattern({
          page: selected + 1,
          type: 'html',
          limit: 8
        })
      } else if (values.type === backgroundTypes.image && imageFeatureId) {
        getBackgroundImagesFromMedia({
          page: selected + 1,
          maxResolution,
          featureId: imageFeatureId,
          approvedStatus: approveStatuses.approve,
          limit: 8
        })
      }
      setPage(selected + 1)
      paginationConfig &&
        paginationConfig.setInitialPage(prevState => ({
          ...prevState,
          [paginationConfig.type]: selected + 1
        }))
    },
    [
      getBackgroundPattern,
      getBackgroundImagesFromMedia,
      values.type,
      maxResolution,
      imageFeatureId,
      paginationConfig
    ]
  )
  const options = useMemo(() => {
    if (backgroundOptions) {
      return backgroundOptions
    } else {
      return [
        { value: 'none', label: 'None' },
        ...(withoutImage
          ? []
          : [{ value: backgroundTypes.image, label: 'Image' }]),
        ...(withoutPattern
          ? []
          : [{ value: backgroundTypes.pattern, label: 'Pattern' }])
      ]
    }
  }, [withoutImage, withoutPattern, backgroundOptions])

  const renderItems = useMemo(
    () => {
      switch (values.type) {
        case backgroundTypes.image:
          return !imageMeta.isLoading && !images?.length ? (
            <EmptyPlaceholder
              text={t('Nothing to show')}
              rootClassName={classes.emptyContentWrap}
            />
          ) : (
            <Container cols="4" customClass={classes.containerWrap}>
              {images?.map(item => (
                <GridViewCard
                  title={item.title}
                  key={`background-image-${item?.id}`}
                  thumbnail={getThumbnail(item)}
                  thumbnailIcon={item.feature?.icon}
                  thumbnailColor={item.feature?.color}
                  item={item}
                  selected={values.image_id === item.id}
                  onSelect={() => {
                    onToggleSelectItem(item, 'image')
                  }}
                  bottomComponent={<MediaCardBottomInfo media={item} />}
                  variant={'normal'}
                  cardRootClassName={classes.gridCardRoot}
                />
              ))}
            </Container>
          )
        case backgroundTypes.pattern:
          return !patternMeta.isLoading && !patterns.length ? (
            <EmptyPlaceholder
              text={t('Nothing to show')}
              rootClassName={classes.emptyContentWrap}
            />
          ) : (
            <Container cols="4" customClass={classes.containerWrap}>
              {patterns.map(item => {
                const { tooltip, id, content } = item
                const patternBgColor =
                  values.color !== DEFAULT_BACKGROUND_COLOR
                    ? values.color
                    : DEFAULT_BACKGROUND_COLOR
                const htmlString = content?.html
                  ? `${content.html}`
                  : `<div class="${classes.htmlFallback} ${parseCssClass(
                      content.css
                    )}" style='background-color: ${patternBgColor}'></div>`
                const renderThumbnail = (
                  <div style={{ overflow: 'hidden', height: '170px' }}>
                    <div id="pattern-view-area">
                      <style>{content.css}</style>
                      <div
                        style={{
                          height: '205px',
                          width: '245px',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: patternBgColor,
                          backgroundImage: 'initial',
                          backgroundSize: 'cover'
                        }}
                        id="create-pattern-pattern"
                        dangerouslySetInnerHTML={{
                          __html: htmlString
                        }}
                      />
                    </div>
                  </div>
                )

                return (
                  <GridViewCard
                    key={`background-image-${id}`}
                    title={tooltip}
                    thumbnail={getThumbnail(item)}
                    thumbnailIcon={item.feature?.icon}
                    thumbnailColor={item.feature?.color}
                    item={item}
                    selected={selected === item.id}
                    customThumbnailComponent={renderThumbnail}
                    onSelect={() => {
                      onToggleSelectItem(item, 'pattern')
                    }}
                    cardRootClassName={classes.gridCardRoot}
                  />
                )
              })}
            </Container>
          )
        default:
          return null
      }
    },
    // eslint-disable-next-line
    [
      imageMeta.isLoading,
      patternMeta.isLoading,
      onChange,
      names.image_id,
      names.pattern,
      patterns,
      images,
      values.image_id,
      values.pattern,
      returnPatternId,
      values.type,
      isLoading,
      onToggleSelectItem,
      selected,
      values.color,
      t
    ]
  )

  useEffect(
    () => {
      if (open) {
        setPage(paginationConfig?.initialPage[paginationConfig.type] || 1)
        if (values.type === backgroundTypes.pattern) {
          getBackgroundPattern({
            type: 'html',
            page: paginationConfig && page,
            limit: 8
          })
        } else if (values.type === backgroundTypes.image && imageFeatureId) {
          getBackgroundImagesFromMedia({
            maxResolution,
            featureId: imageFeatureId,
            approvedStatus: approveStatuses.approve,
            page: paginationConfig && page,
            limit: 8
          })
        }
      }
    },
    // eslint-disable-next-line
    [values.type, imageFeatureId, open]
  )

  useEffect(() => {
    if (values.type === backgroundTypes.image) {
      if (images.length > 0 && imageMeta.lastPage > 1) {
        setVisiblePaginate(true)
      } else {
        setVisiblePaginate(false)
      }
    }
    if (values.type === backgroundTypes.pattern) {
      if (patterns.length > 0 && patternMeta.lastPage > 1) {
        setVisiblePaginate(true)
      } else {
        setVisiblePaginate(false)
      }
    }
  }, [images, patterns, values, imageMeta.lastPage, patternMeta.lastPage])

  const modalWidth = useMemo(() => {
    return [backgroundTypes.image, backgroundTypes.pattern].includes(
      values.type
    )
      ? 'lg'
      : 'sm'
  }, [values.type])

  const calculatedPageRangeDisplayed =
    page <
    (values.type === backgroundTypes.pattern
      ? patternMeta.count
      : imageMeta.count / 2) +
      2
      ? pageRangeDisplayed - 1
      : pageRangeDisplayed

  const handleChangeBackgroundType = useCallback(
    e => {
      onChange(e)
      setPage(1)
      paginationConfig &&
        paginationConfig.setInitialPage(prevState => ({
          ...prevState,
          [paginationConfig.type]: 1
        }))
    },
    [onChange, paginationConfig]
  )

  const handleClickOk = useCallback(() => {
    onCloseModal(true)
  }, [onCloseModal])

  const isBrandGuideEnabled = useMemo(
    () =>
      isFeatureAvailable(BRANDING_FEATURE) &&
      isEnabledBrandGuide &&
      enabledBrandingFeatures.includes(featureName),
    [isEnabledBrandGuide, featureName]
  )

  useEffect(() => {
    if (isEmpty(randomColors)) {
      setRandomColors(
        Array(7)
          .fill()
          .map(_ => rgbToRgba(hexToRgb(getRandomColor())))
      )
    }
    // eslint-disable-next-line
  }, [])

  return (
    <DefaultModal
      modalTitle={t('Background Settings')}
      maxWidth={modalWidth}
      contentClass={classes.content}
      rootClassName={classes.modalRootClass}
      headerClassName={classes.modalHeader}
      footerClassName={classes.modalFooter}
      containerClassName={classes.modalContainer}
      actions={
        <BlueButton
          iconClassName="fa-regular fa-circle-check"
          onClick={handleClickOk}
        >
          {t('Apply')}
        </BlueButton>
      }
      open={open}
      onCloseModal={onCloseModal}
      {...props}
    >
      <Container>
        <FormControlReactSelect
          options={options}
          label={t('Select Background')}
          value={values.type}
          name={names.type}
          disabled={disableSelectType}
          onChange={handleChangeBackgroundType}
          marginBottom={false}
        />
        {[
          backgroundTypes.none,
          backgroundTypes.pattern,
          backgroundTypes.default,
          backgroundTypes.color
        ].includes(values.type) && (
          <FormControlBrandGuideColorPicker
            label={t('Background Color')}
            name={names.color}
            value={values.color}
            onChange={onChange}
            marginBottom={false}
            disabled={values.type === backgroundTypes.default}
            defaultColor={defaultColor}
            renderDefaultColor={renderDefaultColor}
            randomColors={randomColors}
            popUpPlacement="right"
            {...(isBrandGuideEnabled && {
              themeColors,
              ignoreInitial: true
            })}
            {...(isBrandGuideEnabled &&
              brandGuideSettings?.isMandatory && {
                initialValue: initialBrandValue
              })}
          />
        )}
        {values.type === backgroundTypes.image && (
          <FormControlReactSelect
            options={[
              { value: 'original', label: 'Original' },
              { value: 'stretch', label: 'Stretch' }
            ]}
            label={t('Image Size')}
            value={values.size}
            name={names.size}
            onChange={onChange}
            disabled={disableImageSize}
            marginBottom={false}
          />
        )}
      </Container>

      {maxResolution && values.type === backgroundTypes.image && (
        <MessageCard
          icon="fa-regular fa-circle-info"
          message={t('Resolution is less than', {
            maxResolution
          })}
          rootClassName={classes.messageRoot}
          iconRootClassName={classes.messageIconRoot}
        />
      )}

      {[backgroundTypes.image, backgroundTypes.video].includes(values.type) && (
        <>
          <Spacing variant={0} paddingVert={2}>
            {(imageMeta.isLoading || isLoading) && <CircularLoader />}
            <Scrollbars
              className={classes.scrollbarRoot}
              style={scrollbarsStyle}
            >
              {renderItems}
            </Scrollbars>
          </Spacing>
          {isVisiblePaginate && (
            <Spacing variant={0} alignItems="center" paddingBottom={2}>
              <BasePaginate
                page={page}
                pageCount={imageMeta.lastPage}
                pageRangeDisplayed={calculatedPageRangeDisplayed}
                onPageChange={handleChangePage}
              />
            </Spacing>
          )}
        </>
      )}
      {[backgroundTypes.pattern].includes(values.type) && (
        <>
          <Spacing
            variant={0}
            paddingVert={2}
            rootClassName={classes.patternRoot}
          >
            {(patternMeta.isLoading || isLoading) && <CircularLoader />}
            <Scrollbars
              className={classes.scrollbarRoot}
              style={scrollbarsStyle}
            >
              {renderItems}
            </Scrollbars>
          </Spacing>
          {isVisiblePaginate && (
            <Spacing variant={0} alignItems="center" paddingBottom={2}>
              <BasePaginate
                page={page}
                pageCount={patternMeta.lastPage}
                pageRangeDisplayed={calculatedPageRangeDisplayed}
                onPageChange={handleChangePage}
              />
            </Spacing>
          )}
        </>
      )}
    </DefaultModal>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBackgroundPattern
    },
    dispatch
  )

const mapStateToProps = ({
  config: {
    backgroundPatterns: { response: patterns, meta: patternMeta }
  },
  appReducer
}) => ({
  patterns,
  patternMeta,
  isLoading: appReducer.isPending
})

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SelectBackgroundDialog)
