import React, { useEffect, useState, useCallback, useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { withStyles, Grid } from '@material-ui/core'
import { _isEqual } from 'utils/lodash'
import { BlueButton } from 'components/Buttons'
import { CircularLoader } from 'components/Loaders'
import DefaultModal from 'components/Modal/DefaultModal'

import {
  getTemplateItemPreview,
  clearTemplateItemsPreview
} from 'actions/templateActions'
import {
  putDeviceMediaEmergencyAlertAction,
  clearPutDeviceMediaEmergencyAlertInfoAction,
  getDeviceMediaEmergencyAlert
} from 'actions/alertActions'
import { isFalsy, takeTruth } from 'utils/generalUtils'
import BasePaginate from 'components/BasePaginate'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import GridViewCard, { MediaCardBottomInfo } from 'components/Card/GridViewCard'
import { getThumbnail } from 'utils/mediaUtils'
import { useLazyGetMediaGridEmbeddedItemsQuery } from 'api/mediaApi'
import { initialMeta } from 'constants/api'

const styles = () => ({
  stretch: {
    gridColumnStart: 1,
    gridColumnEnd: 3
  },
  items: {
    minHeight: 500,
    marginTop: 10,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: 25
  },
  pagination: {
    margin: '15px auto'
  },
  placeholderWrap: {
    height: 587
  },
  gridCardRoot: {
    minWidth: '100%',
    maxHeight: 245
  }
})

const FETCH_LIMIT = 8

const AlertMediaLibrary = ({
  t,
  id,
  open = false,
  handleClose = f => f,
  classes,
  clearTemplateItemsPreview,
  putDeviceMediaEmergencyAlertReducer,
  putDeviceMediaEmergencyAlertAction,
  clearPutDeviceMediaEmergencyAlertInfoAction,
  deviceId,
  getTemplateItemPreview,
  getDeviceMediaEmergencyAlert,
  selectedMediaId = null,
  onSuccess = f => f,
  onFail = f => f,
  previewItems
}) => {
  const [
    getMediaItemsAction,
    { data: queryData, isFetching, isUninitialized }
  ] = useLazyGetMediaGridEmbeddedItemsQuery()
  const { response, meta } = useMemo(
    () => ({
      response: queryData?.data || [],
      meta: {
        ...(queryData?.meta || initialMeta),
        isLoading: isFetching || isUninitialized
      }
    }),
    [queryData, isFetching, isUninitialized]
  )

  const [data, setData] = useState([])
  const [selectedId, setSelectedId] = useState(selectedMediaId)
  const [page, setPage] = useState(1)

  const handleChangePage = useCallback(
    ({ selected }) => {
      getMediaItemsAction({
        alertTypeId: id,
        limit: FETCH_LIMIT,
        page: selected + 1
      })
      setPage(selected + 1)
    },
    [getMediaItemsAction, id]
  )

  const getPreviewItemById = useCallback(
    id => {
      return previewItems && previewItems.find(p => Number(p.id) === Number(id))
    },
    [previewItems]
  )

  useEffect(() => {
    getMediaItemsAction({
      alertTypeId: id,
      limit: FETCH_LIMIT
    })
  }, [getMediaItemsAction, id])

  useEffect(() => {
    if (response) {
      if (!_isEqual(data, response)) {
        const d = takeTruth(response, [])
        d.forEach(({ id: mediaId, mediaUrl }) => {
          if (!mediaUrl && !getPreviewItemById(mediaId)) {
            getTemplateItemPreview(mediaId, 'media', 0, true)
          }
        })
        setData(d)
      }
    }
    // eslint-disable-next-line
  }, [response])

  useEffect(() => {
    return () => {
      clearTemplateItemsPreview()
    }
  }, [clearTemplateItemsPreview])

  const handleSave = useCallback(() => {
    if (selectedId) {
      putDeviceMediaEmergencyAlertAction({
        deviceId: deviceId,
        alertId: id,
        data: {
          mediaId: selectedId
        }
      })
    }
  }, [selectedId, putDeviceMediaEmergencyAlertAction, deviceId, id])

  useEffect(() => {
    if (putDeviceMediaEmergencyAlertReducer.response) {
      getDeviceMediaEmergencyAlert(deviceId)
      clearPutDeviceMediaEmergencyAlertInfoAction()
      onSuccess()
    } else if (putDeviceMediaEmergencyAlertReducer.error) {
      clearPutDeviceMediaEmergencyAlertInfoAction()
      onFail()
    }
    // eslint-disable-next-line
  }, [putDeviceMediaEmergencyAlertReducer])

  const renderLoader = useMemo(() => {
    if (isFalsy(meta.isLoading)) return null
    return <CircularLoader />
  }, [meta.isLoading])

  const onSelectImage = useCallback(id => setSelectedId(id), [setSelectedId])

  const renderItems = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => (
        <GridViewCard
          key={`alert-${item.id}`}
          title={item.title}
          thumbnail={getThumbnail(item)}
          thumbnailIcon={item.feature?.icon}
          thumbnailColor={item.feature?.color}
          item={item}
          selected={selectedId === item.id}
          onSelect={() => onSelectImage(item.id)}
          bottomComponent={<MediaCardBottomInfo media={item} />}
          cardRootClassName={classes.gridCardRoot}
        />
      ))
    }

    return null
  }, [data, onSelectImage, selectedId, classes.gridCardRoot])

  return (
    <DefaultModal
      open={open}
      maxWidth="lg"
      onCloseModal={handleClose}
      modalTitle={t('Alert media library')}
      actions={
        renderItems && <BlueButton onClick={handleSave}>{t('OK')}</BlueButton>
      }
      hasCancelBtn={false}
      hasSaveBtn={false}
    >
      {renderLoader}
      {!meta.isLoading && !data.length ? (
        <EmptyPlaceholder
          text={t('No Results Found')}
          rootClassName={classes.placeholderWrap}
        />
      ) : (
        <Grid container direction="column">
          <div className={classes.stretch}>
            <div className={classNames(classes.items)}>{renderItems}</div>
          </div>
          <div className={classes.pagination}>
            {meta.lastPage > 1 && (
              <BasePaginate
                page={page}
                pageCount={meta.lastPage}
                onPageChange={handleChangePage}
              />
            )}
          </div>
        </Grid>
      )}
    </DefaultModal>
  )
}

AlertMediaLibrary.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  deviceId: PropTypes.number,
  selectedMediaId: PropTypes.number,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
}

const mapStateToProps = ({ media, alert, template }) => ({
  putDeviceMediaEmergencyAlertReducer: alert.putDeviceMediaEmergencyAlert,
  mediaPreview: media.preview,
  previewItems: template.previewItems
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      putDeviceMediaEmergencyAlertAction,
      clearPutDeviceMediaEmergencyAlertInfoAction,
      getDeviceMediaEmergencyAlert,
      getTemplateItemPreview,
      clearTemplateItemsPreview
    },
    dispatch
  )

export default compose(
  memo,
  withTranslation('translations'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AlertMediaLibrary)
