import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  CLIENT_SETTINGS_PENDING,
  CLIENT_SETTINGS_WITHOUT_PENDING,
  clientSettingsSuccess
} from 'actions/new/client'
import { getClientSettings } from 'services/clientSettingsService'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import { clientsService } from 'services'
import { ORG_ROLE } from 'constants/api'

export default function* clientWatcher() {
  yield all([takeEvery(CLIENT_SETTINGS_PENDING, getClientSettingsWorker)])
  yield all([
    takeEvery(CLIENT_SETTINGS_WITHOUT_PENDING, getClientSettingsWorker)
  ])
}

function* getClientSettingsWorker() {
  try {
    const role = getUserRoleLevel()
    if (role === ORG_ROLE) {
      const data = yield call(clientsService.getClients)
      yield put(clientSettingsSuccess(data))
    } else {
      const data = yield call(getClientSettings)
      yield put(clientSettingsSuccess(data))
    }
  } catch (error) {}
}
