import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'

export const devicePackageApi = createAppApi('devicePackageQuery', {
  apiTags: [apiTags.devicePackage],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      url: '/devicePackage',
      name: 'DevicePackage',
      tags: [apiTags.devicePackage]
    })
  })
})

export const {
  useLazyGetDevicePackagesQuery,
  useGetDevicePackageQuery
} = devicePackageApi
