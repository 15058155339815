import update from 'immutability-helper'

import {
  CLEAR_DEVICE_HEALTH,
  CLEAR_PREVIEW_MODAL,
  DEVICE_HEALTH_FAILURE,
  DEVICE_HEALTH_PENDING,
  DEVICE_HEALTH_SUCCESS,
  OPEN_PREVIEW_MODAL,
  PREVIEW_THUMBNAIL_FAILURE,
  PREVIEW_THUMBNAIL_PENDING,
  PREVIEW_THUMBNAIL_SUCCESS,
  PREVIEWS_FAILURE,
  PREVIEWS_PENDING,
  PREVIEWS_SUCCESS,
  REFRESH_HEALTH_FAILURE,
  TRIGGER_HEALTH_FAILURE,
  TRIGGER_HEALTH_PENDING,
  TRIGGER_HEALTH_SUCCESS
} from 'actions/new/device'
import { FETCH_EXCEPTION_ID } from 'constants/deviceConstants'

const initialState = {
  health: {
    data: {
      id: null,
      name: '',
      alias: '',
      deviceType: {
        id: null,
        name: '',
        alias: '',
        logo: '',
        color: ''
      },
      connectivityGraph: {},
      processorLoad: '',
      logicalProcessors: 0,
      processorCores: 0,
      processorTemperature: '',
      processorClockSpeed: '',
      processorBaseSpeed: '',
      networkSpeed: '',
      networkName: '',
      networkGateway: '',
      networkDNS1: '',
      networkDNS2: '',
      loadGraph: [],
      temperatureGraph: [],
      storageGraph: [],
      memoryGraph: [],
      memoryUtilization: '0%',
      storageUtilization: '0%',
      deviceTime: '',
      downloadSpeed: '',
      uploadSpeed: '',
      ssdReadSpeed: '',
      ssdWriteSpeed: '',
      averageUsage: '',
      logTime: ''
    },
    outdatedFields: [],
    isPending: false,
    isSuccess: false,
    isFailure: false
  },
  triggerHealth: {
    isPending: false,
    isSuccess: false,
    isFailure: false
  },
  refreshHealth: {
    isPending: false,
    isSuccess: false,
    isFailure: false
  },
  previews: {
    isPending: false,
    isSuccess: false,
    isFailure: false,
    data: [],
    meta: {
      page: 1,
      total: 0,
      pagesCount: 0
    },
    filters: {
      name: '',
      location: '',
      status: '',
      clientName: '',
      clientId: '',
      group: [],
      tag: []
    }
  },
  preview: {
    isPending: false,
    isSuccess: false,
    isFailure: false,
    data: {}
  },
  previewModal: {
    isVisible: false,
    deviceId: FETCH_EXCEPTION_ID,
    orientation: 'Landscape'
  }
}

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case DEVICE_HEALTH_PENDING:
      return {
        ...state,
        health: {
          ...state.health,
          isPending: true,
          ...(action.isClear && {
            data: initialState.health.data
          })
        }
      }
    case DEVICE_HEALTH_SUCCESS: {
      const outdatedFields = []
      const data = {
        ...state.health.data
      }

      Object.entries(action.data).forEach(([key, value]) => {
        if (value) {
          data[key] = value
        } else if (data[key]) {
          outdatedFields.push(key)
        }
      })

      return {
        ...state,
        health: {
          ...state.health,
          isPending: false,
          data,
          outdatedFields
        },
        refreshHealth: {
          ...state.refreshHealth,
          isPending: false
        }
      }
    }
    case DEVICE_HEALTH_FAILURE:
      return {
        ...state,
        health: {
          ...state.health,
          isPending: false,
          isFailure: true
        },
        refreshHealth: {
          ...state.refreshHealth,
          isPending: false
        }
      }
    case TRIGGER_HEALTH_PENDING:
      return {
        ...state,
        triggerHealth: {
          ...state.triggerHealth,
          isPending: true
        },
        refreshHealth: {
          ...state.refreshHealth,
          isPending: true
        }
      }
    case TRIGGER_HEALTH_SUCCESS:
    case TRIGGER_HEALTH_FAILURE:
      return {
        ...state,
        triggerHealth: {
          ...state.triggerHealth,
          isPending: false
        }
      }
    case REFRESH_HEALTH_FAILURE:
      return {
        ...state,
        refreshHealth: {
          ...state.refreshHealth,
          isPending: false
        }
      }
    case CLEAR_DEVICE_HEALTH:
      return {
        ...state,
        health: initialState.health,
        triggerHealth: initialState.triggerHealth,
        refreshHealth: initialState.refreshHealth
      }
    case PREVIEWS_PENDING: {
      const { page, sort, order, ...params } = action.payload

      return {
        ...state,
        previews: {
          ...state.previews,
          isPending: true,
          ...(page && {
            meta: {
              ...state.previews.meta,
              page
            }
          }),
          filters: {
            ...state.previews.filters,
            ...params
          }
        }
      }
    }
    case PREVIEWS_SUCCESS:
      return {
        ...state,
        previews: {
          ...state.previews,
          isPending: false,
          isSuccess: true,
          data: action.data,
          meta: {
            ...state.previews.meta,
            total: action.meta.total,
            pagesCount: action.meta.lastPage,
            page: action.meta.currentPage
          }
        }
      }
    case PREVIEWS_FAILURE:
      return {
        ...state,
        previews: {
          ...state.previews,
          isPending: false,
          isFailure: true
        }
      }
    case PREVIEW_THUMBNAIL_PENDING:
      return {
        ...state,
        preview: {
          ...state.preview,
          isPending: true,
          isFailure: false
        }
      }
    case PREVIEW_THUMBNAIL_SUCCESS: {
      const index = state.previews.data.findIndex(
        ({ id }) => id === action.payload.id
      )
      if (index === -1) {
        return {
          ...state,
          preview: {
            ...state.preview,
            isPending: false,
            isSuccess: true,
            data: action.payload
          }
        }
      }
      return update(state, {
        previews: {
          data: {
            [index]: { $set: action.payload }
          }
        },
        preview: {
          $merge: {
            isPending: false,
            isSuccess: true,
            data: action.payload
          }
        }
      })
    }
    case PREVIEW_THUMBNAIL_FAILURE:
      return {
        ...state,
        preview: {
          ...state.preview,
          isPending: false,
          isFailure: true
        }
      }
    case OPEN_PREVIEW_MODAL:
      return {
        ...state,
        previewModal: {
          isVisible: true,
          deviceId: action.payload.id,
          orientation: action.payload.orientation
        }
      }
    case CLEAR_PREVIEW_MODAL:
      return {
        ...state,
        previewModal: {
          isVisible: false,
          deviceId: FETCH_EXCEPTION_ID
        },
        preview: {
          isPending: false,
          isSuccess: false,
          isFailure: false,
          data: {}
        }
      }
    default:
      return state
  }
}
