import React, { memo, useCallback, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import classNames from 'classnames'
import { Tooltip } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

import { FormControlInput } from 'components/Form'
import CustomFormControlLabel from 'components/Form/FormControlLabel'
import { messageCopy, messageCopySuccess } from 'constants/messageConstants'

const styles = ({ palette, type, transitions }) => ({
  formControlInput: {
    cursor: 'pointer',
    height: 40,
    fontSize: 16
  },
  inputRoot: {
    position: 'relative',
    cursor: 'pointer'
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: palette[type].formControls.input.color,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    width: '40px',
    background: palette[type].formControls.input.background,
    justifyContent: 'center',
    border: `1px solid ${palette[type].formControls.input.border}`,
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    transition: transitions.create(['border-color', 'box-shadow'])
  },
  iconActive: {
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
  },
  disabled: {
    cursor: 'auto'
  },
  iconDisabled: {
    background: palette[type].formControls.disabled.background
  }
})

const FormControlCopyInput = ({
  value,
  label,
  marginBottom,
  labelTooltip,
  inputTooltip,
  disabled = false,
  formControlInputClass,
  iconClass,
  classes,
  t
}) => {
  const [tooltip, setTooltip] = useState(inputTooltip || t(messageCopy))
  const [inputFocused, setInputFocused] = useState(false)

  const handleCopyInputValue = useCallback(() => {
    if (value) {
      setInputFocused(true)
      setTooltip(t(messageCopySuccess))
      setTimeout(() => setTooltip(t(messageCopy)), 2000)
    }
  }, [value, t])

  return (
    <>
      {label && (
        <Grid item>
          <CustomFormControlLabel label={t(label)} tooltip={t(labelTooltip)} />
        </Grid>
      )}
      <Tooltip arrow placement="top" title={tooltip}>
        {disabled ? (
          <FormControlInput
            type="text"
            value={value}
            tooltip={inputTooltip}
            formControlInputClass={classNames(
              classes.formControlInput,
              formControlInputClass,
              classes.disabled
            )}
            marginBottom={marginBottom}
            disabled
            readOnly
          />
        ) : (
          <CopyToClipboard onCopy={handleCopyInputValue} text={value}>
            <div>
              <div
                className={classNames(classes.inputRoot, {
                  [classes.disabled]: !value
                })}
              >
                <FormControlInput
                  type="text"
                  value={value}
                  tooltip={inputTooltip}
                  formControlInputClass={classNames(
                    classes.formControlInput,
                    formControlInputClass,
                    {
                      [classes.disabled]: !value
                    }
                  )}
                  marginBottom={marginBottom}
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                  disabled={!value}
                  readOnly
                />
                <div
                  className={classNames(classes.icon, iconClass, {
                    [classes.iconActive]: inputFocused,
                    [classes.iconDisabled]: !value
                  })}
                >
                  <i className="fa-regular fa-paste" />
                </div>
              </div>
            </div>
          </CopyToClipboard>
        )}
      </Tooltip>
    </>
  )
}

export default memo(
  withTranslation('translations')(withStyles(styles)(FormControlCopyInput))
)
