import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ fill = '#fff' }) => (
  <>
    <svg
      width="144px"
      height="120px"
      viewBox="0 0 144 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>05-user-add-2</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="panel/add-user" transform="translate(-363.000000, -196.000000)">
          <g id="05-user-add-2" transform="translate(364.000000, 196.000000)">
            <path
              d="M136.999982,66.5475164 C136.997477,39.3689097 120.730008,14.9227171 95.9075572,4.79523264 C71.0851061,-5.33225185 42.6959189,0.894161094 24.1950752,20.5234577 C5.69423159,40.1527543 0.799606363,69.2402891 11.8310114,94 L131.170553,94 C135.019248,85.3766158 137.006908,76.0161444 136.999982,66.5475164 Z"
              id="Path"
              fill={fill}
            ></path>
            <path
              d="M7,94 L135,94"
              id="Path"
              stroke="#0D7DBC"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M0,94 L4,94"
              id="Path"
              stroke="#0D7DBC"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M138,94 L142,94"
              id="Path"
              stroke="#0D7DBC"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M80,43.7000001 C80,52.1499567 73.0604136,59 64.5,59 C55.9395864,59 49,52.1499567 49,43.7000001 L49,38.3000002 C48.9999999,32.8338366 51.9542832,27.7828932 56.7499999,25.0498114 C61.5457166,22.3167295 67.4542834,22.3167295 72.2500001,25.0498114 C77.0457168,27.7828932 80.0000001,32.8338366 80,38.3000002 L80,43.7000001 Z"
              id="Path"
              fill={fill}
            ></path>
            <path
              d="M88,94 L88,86.9362851 C88,73.7166399 77.4786916,63 64.5,63 C51.5213084,63 41,73.7166399 41,86.9362851 L41,94 L88,94 Z"
              id="Path"
              fill={fill}
            ></path>
            <path
              d="M80,43.7000001 C80,52.1499567 73.0604136,59 64.5,59 C55.9395864,59 49,52.1499567 49,43.7000001 L49,38.3000002 C48.9999999,32.8338366 51.9542832,27.7828932 56.7499999,25.0498114 C61.5457166,22.3167295 67.4542834,22.3167295 72.2500001,25.0498114 C77.0457168,27.7828932 80.0000001,32.8338366 80,38.3000002 L80,43.7000001 Z"
              id="Path"
              stroke="#0D7DBC"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M88,94 L88,86.9362851 C88,73.7166399 77.4786916,63 64.5,63 C51.5213084,63 41,73.7166399 41,86.9362851 L41,94 L88,94 Z"
              id="Path"
              stroke="#0D7DBC"
              strokeLinejoin="round"
            ></path>
            <path
              d="M50,94 L50,87"
              id="Path"
              stroke="#0D7DBC"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M79,94 L79,87"
              id="Path"
              stroke="#4550E5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <ellipse
              id="Oval"
              fill={fill}
              cx="99"
              cy="118.5"
              rx="13"
              ry="1.5"
            ></ellipse>
            <path
              d="M119,89.1872 C119,88.4825728 118.720091,87.8068054 118.22185,87.3085587 C117.723609,86.8103121 117.047849,86.5304 116.34323,86.5304 L105.765348,86.5304 C105.414527,86.5304274 105.078309,86.3899503 104.831787,86.1403441 C104.585265,85.8907379 104.448977,85.5527968 104.453362,85.202 L104.453362,74.6404 C104.444344,73.1794985 103.257505,71.9999722 101.796592,72 L95.2366649,72 C93.7784285,72 92.5962944,73.1821473 92.5962944,74.6404 L92.5962944,85.2184 C92.5962944,85.9520551 92.001556,86.5468 91.2679092,86.5468 L80.6408276,86.5468 C79.1825912,86.5468 78.0004571,87.7289473 78.0004571,89.1872 L78.0004571,95.7472 C77.9872889,96.4574777 78.2591211,97.1434449 78.7552432,97.6518987 C79.2513653,98.1603524 79.9304486,98.4489369 80.6408276,98.4532 L91.2187097,98.4532 C91.5695026,98.4488147 91.9074399,98.5851045 92.1570433,98.8316291 C92.4066467,99.0781538 92.5471223,99.4143758 92.5470949,99.7652 L92.5470949,110.3432 C92.5470671,111.804129 93.7265803,112.990982 95.1874655,113 L101.747392,113 C103.210962,112.991048 104.395211,111.806786 104.404163,110.3432 L104.404163,99.7652 C104.404163,99.0406024 104.991559,98.4532 105.716148,98.4532 L116.29403,98.4532 C117.7576,98.4442482 118.941849,97.2599861 118.950801,95.7964 L119,89.1872 Z"
              id="Path"
              fill={fill}
            ></path>
            <path
              d="M119,89.1872 C119,88.4825728 118.720091,87.8068054 118.22185,87.3085587 C117.723609,86.8103121 117.047849,86.5304 116.34323,86.5304 L105.765348,86.5304 C105.414527,86.5304274 105.078309,86.3899503 104.831787,86.1403441 C104.585265,85.8907379 104.448977,85.5527968 104.453362,85.202 L104.453362,74.6404 C104.444344,73.1794985 103.257505,71.9999722 101.796592,72 L95.2366649,72 C93.7784285,72 92.5962944,73.1821473 92.5962944,74.6404 L92.5962944,85.2184 C92.5962944,85.9520551 92.001556,86.5468 91.2679092,86.5468 L80.6408276,86.5468 C79.1825912,86.5468 78.0004571,87.7289473 78.0004571,89.1872 L78.0004571,95.7472 C77.9872889,96.4574777 78.2591211,97.1434449 78.7552432,97.6518987 C79.2513653,98.1603524 79.9304486,98.4489369 80.6408276,98.4532 L91.2187097,98.4532 C91.5695026,98.4488147 91.9074399,98.5851045 92.1570433,98.8316291 C92.4066467,99.0781538 92.5471223,99.4143758 92.5470949,99.7652 L92.5470949,110.3432 C92.5470671,111.804129 93.7265803,112.990982 95.1874655,113 L101.747392,113 C103.210962,112.991048 104.395211,111.806786 104.404163,110.3432 L104.404163,99.7652 C104.404163,99.0406024 104.991559,98.4532 105.716148,98.4532 L116.29403,98.4532 C117.7576,98.4442482 118.941849,97.2599861 118.950801,95.7964 L119,89.1872 Z"
              id="Path"
              stroke="#0D7DBC"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </>
)

Icon.propTypes = {
  fill: PropTypes.string
}

export default Icon
