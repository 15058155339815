import { _get } from 'utils/lodash'

import store from 'store/configureStore'
import {
  permissionTypes,
  redirectUrlsForUnauthorized
} from 'constants/permissionGroups'
import getUserRoleLevel from 'utils/getUserRoleLevel'

export const getUrlPrefix = url => {
  const level = getUserRoleLevel()

  return `/${level}/${url}`
}

export function getLogoutUrl(role) {
  return role === 'system' ? '/system/sign-in' : '/sign-in'
}

export function getRedirectUrlForUnauthorized() {
  const { user } = store.getState()
  const permissions = _get(user, 'details.response.permissions', [])

  const url = Object.entries(redirectUrlsForUnauthorized).find(([key]) =>
    _get(permissions, `${key}.${permissionTypes.read}`)
  )
  if (!url) {
    return '/no-permissions'
  }
  return url[1].startsWith('/') ? url[1] : getUrlPrefix(url[1])
}

export const getPublicUrl = (url, token) => {
  return `/public/${token}/${url}`
}
