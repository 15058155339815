export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const CLEAR_LOGIN_INFO = 'CLEAR_LOGIN_INFO'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const IMPERSONATE_USER = 'IMPERSONATE_USER'
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS'
export const IMPERSONATE_USER_ERROR = 'IMPERSONATE_USER_ERROR'
export const GET_IMPERSONATE_TOKEN = 'GET_IMPERSONATE_TOKEN'
export const GET_IMPERSONATE_TOKEN_SUCCESS = 'GET_IMPERSONATE_TOKEN_SUCCESS'
export const GET_IMPERSONATE_TOKEN_ERROR = 'GET_IMPERSONATE_TOKEN_ERROR'
export const CLEAR_IMPERSONATE_TOKEN = 'CLEAR_IMPERSONATE_TOKEN'
export const SET_PENDING_STATUS = 'SET_PENDING_STATUS'
export const SET_GROUP_MODAL_HEIGHT = 'SET_GROUP_MODAL_HEIGHT'
export const SET_CONFIRMATION_REQUIRED = 'SET_CONFIRMATION_REQUIRED'
export const SET_NAVIGATION_DISABLED = 'SET_NAVIGATION_DISABLED'
export const ADD_TOOLTIP_ID = 'ADD_TOOLTIP_ID'
export const REMOVE_TOOLTIP_ID = 'REMOVE_TOOLTIP_ID'
export const SET_DND_LAST_DROP_ID = 'SET_DND_LAST_DROP_ID'
export const UPDATE_TEMPLATE_DISPLAY_SIZE = 'UPDATE_TEMPLATE_DISPLAY_SIZE'
export const LOGIN_USER_OKTA = 'LOGIN_USER_OKTA'

export const GET_SSO_DATA = 'GET_SSO_DATA'
export const SSO_DATA_SUCCESS = 'SSO_DATA_SUCCESS'
export const SSO_DATA_FAILURE = 'SSO_DATA_FAILURE'
export const CLEAR_SSO_DATA = 'CLEAR_SSO_DATA'

export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR'
export const GET_ORIGINAL_USER_DETAILS = 'GET_ORIGINAL_USER_DETAILS'
export const GET_ORIGINAL_USER_DETAILS_SUCCESS =
  'GET_ORIGINAL_USER_DETAILS_SUCCESS'
export const GET_ORIGINAL_USER_DETAILS_ERROR = 'GET_ORIGINAL_USER_DETAILS_ERROR'
export const PUT_USER_DETAILS = 'PUT_USER_DETAILS'
export const PUT_USER_DETAILS_SUCCESS = 'PUT_USER_DETAILS_SUCCESS'
export const PUT_USER_DETAILS_ERROR = 'PUT_USER_DETAILS_ERROR'
export const USER_PUT_CLEAR = 'USER_PUT_CLEAR'
export const USER_DETAILS_CLEAR = 'USER_DETAILS_CLEAR'
export const GET_USER_IP = 'GET_USER_IP'
export const GET_USER_IP_SUCCESS = 'GET_USER_IP_SUCCESS'
export const GET_USER_IP_ERROR = 'GET_USER_IP_ERROR'
export const REGENERATE_USER_PIN_CODE = 'REGENERATE_USER_PIN_CODE'

export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_ACCESS = 'LOGOUT_USER_ACCESS'
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR'
export const CLEAR_LOGOUT_INFO = 'CLEAR_LOGOUT_INFO'

export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN'
export const UPDATE_USER_TOKEN_SUCCESS = 'UPDATE_USER_TOKEN_SUCCESS'

export const UPDATE_TEMPLATE_CONTAINER = 'UPDATE_TEMPLATE_CONTAINER'
export const SET_TEMPLATE_CONTAINER_ORIENTATION =
  'SET_TEMPLATE_CONTAINER_ORIENTATION'
export const SET_TEMPLATE_CONTAINER_SIZE = 'SET_TEMPLATE_CONTAINER_SIZE'
export const SET_TEMPLATE_CONTAINER_VIDEO_WALL =
  'SET_TEMPLATE_CONTAINER_VIDEO_WALL'
export const SET_CURRENT_TEMPLATE_ITEM = 'SET_CURRENT_TEMPLATE_ITEM'
export const UPDATE_CURRENT_TEMPLATE_ITEM = 'UPDATE_CURRENT_TEMPLATE_ITEM'
export const SET_CURRENT_TEMPLATE_ITEM_SIZE = 'SET_CURRENT_TEMPLATE_ITEM_SIZE'
export const SET_CURRENT_TEMPLATE_ITEM_POSITION =
  'SET_CURRENT_TEMPLATE_ITEM_POSITION'
export const SET_CURRENT_TEMPLATE_ITEM_ALIGNMENT =
  'SET_CURRENT_TEMPLATE_ITEM_ALIGNMENT'
export const SET_CURRENT_TEMPLATE_ITEM_ROTATION =
  'SET_CURRENT_TEMPLATE_ITEM_ROTATION'
export const FLIP_CURRENT_TEMPLATE_ITEM = 'FLIP_CURRENT_TEMPLATE_ITEM'
export const DELETE_TEMPLATE_ITEMS = 'DELETE_TEMPLATE_ITEMS'
export const DELETE_TEMPLATE_ITEM = 'DELETE_TEMPLATE_ITEM'
export const ADD_TEMPLATE_ITEM = 'ADD_TEMPLATE_ITEM'
export const GET_TOUCH_ZONE_TEMPLATE_ITEM = 'GET_TOUCH_ZONE_TEMPLATE_ITEM'
export const GET_TOUCH_ZONE_TEMPLATE_ITEM_SUCCESS =
  'GET_TOUCH_ZONE_TEMPLATE_ITEM_SUCCESS'
export const GET_TOUCH_ZONE_TEMPLATE_ITEM_ERROR =
  'GET_TOUCH_ZONE_TEMPLATE_ITEM_ERROR'
export const COPY_TEMPLATE_ITEM = 'COPY_TEMPLATE_ITEM'
export const MOVE_TEMPLATE_ITEM_FORWARD = 'MOVE_TEMPLATE_ITEM_FORWARD'
export const MOVE_TEMPLATE_ITEM_BACK = 'MOVE_TEMPLATE_ITEM_BACK'
export const ZOOM_IN_TEMPLATE_CONTAINER = 'ZOOM_IN_TEMPLATE_CONTAINER'
export const ZOOM_OUT_TEMPLATE_CONTAINER = 'ZOOM_OUT_TEMPLATE_CONTAINER'
export const LOCK_TEMPLATE_ITEM = 'LOCK_TEMPLATE_ITEM'
export const UNLOCK_TEMPLATE_ITEM = 'UNLOCK_TEMPLATE_ITEM'
export const TOGGLE_TEMPLATE_ITEM_TO_SELECTED =
  'TOGGLE_TEMPLATE_ITEM_TO_SELECTED'
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS'
export const SET_MULTIPLIER = 'SET_MULTIPLIER'
export const CLEAR_ALL_TEMPLATE_ITEMS = 'CLEAR_ALL_TEMPLATE_ITEMS'
export const UNDO_TEMPLATE = 'UNDO_TEMPLATE'
export const REDO_TEMPLATE = 'REDO_TEMPLATE'
export const UPDATE_TEMPLATE_HISTORY = 'UPDATE_TEMPLATE_HISTORY'
export const CLEAR_TEMPLATE_HISTORY = 'CLEAR_TEMPLATE_HISTORY'
export const GET_TEMPLATE_ITEM_PREVIEW = 'GET_TEMPLATE_ITEM_PREVIEW'
export const GET_TEMPLATE_ITEM_PREVIEW_SUCCESS =
  'GET_TEMPLATE_ITEM_PREVIEW_SUCCESS'
export const GET_TEMPLATE_ITEM_PREVIEW_ERROR = 'GET_TEMPLATE_ITEM_PREVIEW_ERROR'
export const GET_TEMPLATE_ZONE_PREVIEW = 'GET_TEMPLATE_ZONE_PREVIEW'
export const GET_TEMPLATE_ZONE_PREVIEW_SUCCESS =
  'GET_TEMPLATE_ZONE_PREVIEW_SUCCESS'
export const GET_TEMPLATE_ZONE_PREVIEW_ERROR = 'GET_TEMPLATE_ZONE_PREVIEW_ERROR'
export const CLEAR_TEMPLATE_ITEMS_PREVIEW = 'CLEAR_TEMPLATE_ITEMS_PREVIEW'
export const CLEAR_TEMPLATE_ITEM_PREVIEW = 'CLEAR_TEMPLATE_ITEM_PREVIEW'

export const GENERATE_TEMPLATE_ITEM_PREVIEW = 'GENERATE_TEMPLATE_ITEM_PREVIEW'
export const GENERATE_TEMPLATE_ITEM_PREVIEW_SUCCESS =
  'GENERATE_TEMPLATE_ITEM_PREVIEW_SUCCESS'
export const GENERATE_TEMPLATE_ITEM_PREVIEW_ERROR =
  'GENERATE_TEMPLATE_ITEM_PREVIEW_ERROR'

export const APPROVE_TEMPLATE = 'APPROVE_TEMPLATE'
export const APPROVE_TEMPLATE_SUCCESS = 'APPROVE_TEMPLATE_SUCCESS'
export const APPROVE_TEMPLATE_ERROR = 'APPROVE_TEMPLATE_ERROR'

export const BULK_UPDATE_TEMPLATE_ITEM = 'BULK_UPDATE_TEMPLATE_ITEM'
export const BULK_UPDATE_TEMPLATE_ITEM_SUCCESS =
  'BULK_UPDATE_TEMPLATE_ITEM_SUCCESS'
export const BULK_UPDATE_TEMPLATE_ITEM_ERROR = 'BULK_UPDATE_TEMPLATE_ITEM_ERROR'

export const BULK_TEMPLATE_GALLERY_COPY = 'BULK_TEMPLATE_GALLERY_COPY'
export const BULK_TEMPLATE_GALLERY_COPY_SUCCESS =
  'BULK_TEMPLATE_GALLERY_COPY_SUCCESS'
export const BULK_TEMPLATE_GALLERY_COPY_ERROR =
  'BULK_TEMPLATE_GALLERY_COPY_ERROR'

export const SET_SELECT_TEMPLATE_GALLERY = 'SET_SELECT_TEMPLATE_GALLERY'
export const CLEAR_SELECTED_TEMPLATE_GALLERY = 'CLEAR_SELECTED_TEMPLATE_GALLERY'

export const ADD_MEDIA = 'ADD_MEDIA'
export const ADD_MEDIA_SUCCESS = 'ADD_MEDIA_SUCCESS'
export const ADD_MEDIA_ERROR = 'ADD_MEDIA_ERROR'
export const PUT_MEDIA = 'PUT_MEDIA'
export const PUT_MEDIA_SUCCESS = 'PUT_MEDIA_SUCCESS'
export const CLEAR_MEDIA_PUT = 'CLEAR_MEDIA_PUT'
export const PUT_MEDIA_ERROR = 'PUT_MEDIA_ERROR'
export const CREAR_MEDIA_ITEM = 'CREAR_MEDIA_ITEM'
export const CLEAR_MEDIA_ITEM_STATUS = 'CLEAR_MEDIA_ITEM_STATUS'
export const GET_MEDIA_ITEM_BY_ID = 'GET_MEDIA_ITEM_BY_ID'
export const GET_MEDIA_ITEM_BY_ID_SUCCESS = 'GET_MEDIA_ITEM_BY_ID_SUCCESS'
export const GET_MEDIA_ITEM_BY_ID_ERROR = 'GET_MEDIA_ITEM_BY_ID_ERROR'

export const CLONE_MEDIA_AND_UPDATE = 'CLONE_MEDIA_AND_UPDATE'
export const CLONE_MEDIA_AND_UPDATE_SUCCESS = 'CLONE_MEDIA_AND_UPDATE_SUCCESS'
export const CLONE_MEDIA_AND_UPDATE_ERROR = 'CLONE_MEDIA_AND_UPDATE_ERROR'

export const CLEAR_ADDED_MEDIA = 'CLEAR_ADDED_MEDIA'
export const GET_MEDIA_ITEMS = 'GET_MEDIA_ITEMS'
export const GET_MEDIA_ITEMS_SUCCESS = 'GET_MEDIA_ITEMS_SUCCESS'
export const GET_MEDIA_ITEMS_ERROR = 'GET_MEDIA_ITEMS_ERROR'
export const GET_MEDIA_BY_ID = 'GET_MEDIA_BY_ID'
export const GET_MEDIA_BY_ID_SUCCESS = 'GET_MEDIA_BY_ID_SUCCESS'
export const GET_MEDIA_BY_ID_ERROR = 'GET_MEDIA_BY_ID_ERROR'
export const CLEAR_MEDIA_ITEMS = 'CLEAR_MEDIA_ITEMS'
export const CLEAR_GET_MEDIA_ITEMS_INFO = 'CLEAR_GET_MEDIA_ITEMS_INFO'
export const CLEAR_MEDIA_FETCH_PARAMS = 'CLEAR_MEDIA_FETCH_PARAMS'
export const GET_MEDIA_PREFERENCE = 'GET_MEDIA_PREFERENCE'
export const GET_MEDIA_PREFERENCE_SUCCESS = 'GET_MEDIA_PREFERENCE_SUCCESS'
export const GET_MEDIA_PREFERENCE_ERROR = 'GET_MEDIA_PREFERENCE_ERROR'
export const PUT_MEDIA_PREFERENCE = 'PUT_MEDIA_PREFERENCE'
export const PUT_MEDIA_PREFERENCE_SUCCESS = 'PUT_MEDIA_PREFERENCE_SUCCESS'
export const PUT_MEDIA_PREFERENCE_ERROR = 'PUT_MEDIA_PREFERENCE_ERROR'
export const GET_MEDIA_PREVIEW = 'GET_MEDIA_PREVIEW'
export const GET_MEDIA_PREVIEW_SUCCESS = 'GET_MEDIA_PREVIEW_SUCCESS'
export const GET_URL_PREVIEW = 'GET_URL_PREVIEW'
export const GET_MEDIA_PREVIEW_ERROR = 'GET_MEDIA_PREVIEW_ERROR'
export const CLOSE_MEDIA_PREVIEW = 'CLOSE_MEDIA_PREVIEW'
export const SHOW_MEDIA_PREVIEW = 'SHOW_MEDIA_PREVIEW'
export const GENERATE_MEDIA_PREVIEW = 'GENERATE_MEDIA_PREVIEW'
export const GENERATE_MEDIA_PREVIEW_SUCCESS = 'GENERATE_MEDIA_PREVIEW_SUCCESS'
export const GENERATE_MEDIA_PREVIEW_ERROR = 'GENERATE_MEDIA_PREVIEW_ERROR'
export const CLEAR_MEDIA_PREVIEW = 'CLEAR_MEDIA_PREVIEW'
export const CLEAR_MEDIA_GROUP_ITEMS_RESPONSE_INFO =
  'CLEAR_MEDIA_GROUP_ITEMS_RESPONSE_INFO'
export const GET_MEDIA_GROUP_ITEMS = 'GET_MEDIA_GROUP_ITEMS'
export const CLEAR_GET_MEDIA_GROUP_ITEMS_INFO =
  'CLEAR_GET_MEDIA_GROUP_ITEMS_INFO'
export const POST_MEDIA_GROUP_ITEM = 'POST_MEDIA_GROUP_ITEM'
export const CLEAR_POST_MEDIA_GROUP_ITEM_INFO =
  'CLEAR_POST_MEDIA_GROUP_ITEM_INFO'
export const DELETE_MEDIA_GROUP_ITEM = 'DELETE_MEDIA_GROUP_ITEM'
export const CLEAR_DELETE_MEDIA_GROUP_ITEM_INFO =
  'CLEAR_DELETE_MEDIA_GROUP_ITEM_INFO'
export const GET_MEDIA_GROUP_ITEMS_SUCCESS = 'GET_MEDIA_GROUP_ITEMS_SUCCESS'
export const GET_MEDIA_GROUP_ITEMS_ERROR = 'GET_MEDIA_GROUP_ITEMS_ERROR'
export const POST_MEDIA_GROUP_ITEM_SUCCESS = 'POST_MEDIA_GROUP_ITEM_SUCCESS'
export const POST_MEDIA_GROUP_ITEM_ERROR = 'POST_MEDIA_GROUP_ITEM_ERROR'
export const DELETE_MEDIA_GROUP_ITEM_SUCCESS = 'DELETE_MEDIA_GROUP_ITEM_SUCCESS'
export const DELETE_MEDIA_GROUP_ITEM_ERROR = 'DELETE_MEDIA_GROUP_ITEM_ERROR'
export const DELETE_SELECTED_MEDIA = 'DELETE_SELECTED_MEDIA'
export const DELETE_SELECTED_MEDIA_SUCCESS = 'DELETE_SELECTED_MEDIA_SUCCESS'
export const DELETE_SELECTED_MEDIA_ERROR = 'DELETE_SELECTED_MEDIA_ERROR'
export const CLEAR_MEDIA_RESPONSE_INFO = 'CLEAR_MEDIA_RESPONSE_INFO'
export const SHARE_MEDIA = 'SHARE_MEDIA'
export const SHARE_MEDIA_SUCCESS = 'SHARE_MEDIA_SUCCESS'
export const SHARE_MEDIA_ERROR = 'SHARE_MEDIA_ERROR'
export const PUBLISH_DESIGN = 'PUBLISH_DESIGN'
export const PUBLISH_DESIGN_SUCCESS = 'PUBLISH_DESIGN_SUCCESS'
export const PUBLISH_DESIGN_ERROR = 'PUBLISH_DESIGN_ERROR'
export const COPY_MEDIA = 'COPY_MEDIA'
export const COPY_MEDIA_SUCCESS = 'COPY_MEDIA_SUCCESS'
export const COPY_MEDIA_ERROR = 'COPY_MEDIA_ERROR'
export const COPY_DESIGN_TO_SYSTEM = 'COPY_DESIGN_TO_SYSTEM'
export const COPY_DESIGN_TO_SYSTEM_SUCCESS = 'COPY_DESIGN_TO_SYSTEM_SUCCESS'
export const COPY_DESIGN_TO_SYSTEM_ERROR = 'COPY_DESIGN_TO_SYSTEM_ERROR'
export const CLONE_MEDIA_ITEM = 'CLONE_MEDIA_ITEM'
export const CLONE_MEDIA_ITEM_ERROR = 'CLONE_MEDIA_ITEM_ERROR'
export const CLONE_MEDIA_ITEM_SUCCESS = 'CLONE_MEDIA_ITEM_SUCCESS'

export const APPROVE_PLAYLIST = 'APPROVE_PLAYLIST'
export const APPROVE_PLAYLIST_SUCCESS = 'APPROVE_PLAYLIST_SUCCESS'
export const APPROVE_PLAYLIST_ERROR = 'APPROVE_PLAYLIST_ERROR'

export const POST_FAVORITE_MEDIA = 'POST_FAVORITE_MEDIA'
export const POST_FAVORITE_MEDIA_SUCCESS = 'POST_FAVORITE_MEDIA_SUCCESS'
export const POST_FAVORITE_MEDIA_ERROR = 'POST_FAVORITE_MEDIA_ERROR'

export const CLEAR_MEDIA_CONTENT_SOURCE = 'CLEAR_MEDIA_CONTENT_SOURCE'

export const CLEAR_POST_FAVORITE_MEDIA = 'CLEAR_POST_FAVORITE_MEDIA'

export const POST_MEDIA_TO_PLAYLISTS = 'POST_MEDIA_TO_PLAYLISTS'
export const POST_MEDIA_TO_PLAYLISTS_SUCCESS = 'POST_MEDIA_TO_PLAYLISTS_SUCCESS'
export const POST_MEDIA_TO_PLAYLISTS_ERROR = 'POST_MEDIA_TO_PLAYLISTS_ERROR'
export const CLEAR_POST_MEDIA_TO_PLAYLISTS = 'CLEAR_POST_MEDIA_TO_PLAYLISTS'

export const POST_MEDIA_TO_PLAYLIST = 'POST_MEDIA_TO_PLAYLIST'
export const POST_MEDIA_TO_PLAYLIST_SUCCESS = 'POST_MEDIA_TO_PLAYLIST_SUCCESS'
export const POST_MEDIA_TO_PLAYLIST_ERROR = 'POST_MEDIA_TO_PLAYLIST_ERROR'

export const POST_MEDIA_BULK = 'POST_MEDIA_BULK'
export const POST_MEDIA_BULK_SUCCESS = 'POST_MEDIA_BULK_SUCCESS'
export const POST_MEDIA_BULK_ERROR = 'POST_MEDIA_BULK_ERROR'
export const CLEAR_POST_MEDIA_BULK_INFO = 'CLEAR_POST_MEDIA_BULK_INFO'

export const POST_DEVICE_BULK = 'POST_DEVICE_BULK'
export const POST_DEVICE_BULK_SUCCESS = 'POST_DEVICE_BULK_SUCCESS'
export const POST_DEVICE_BULK_ERROR = 'POST_DEVICE_BULK_ERROR'
export const CLEAR_POST_DEVICE_BULK_INFO = 'CLEAR_POST_DEVICE_BULK_INFO'

export const POST_USER_BULK = 'POST_USER_BULK'
export const POST_USER_BULK_SUCCESS = 'POST_USER_BULK_SUCCESS'
export const POST_USER_BULK_ERROR = 'POST_USER_BULK_ERROR'
export const CLEAR_POST_USER_BULK_INFO = 'CLEAR_POST_USER_BULK_INFO'

export const POST_CLIENT_BULK = 'POST_CLIENT_BULK'
export const POST_CLIENT_BULK_SUCCESS = 'POST_CLIENT_BULK_SUCCESS'
export const POST_CLIENT_BULK_ERROR = 'POST_CLIENT_BULK_ERROR'
export const CLEAR_POST_CLIENT_BULK_INFO = 'CLEAR_POST_CLIENT_BULK_INFO'

export const POST_PLAYLIST_BULK = 'POST_PLAYLIST_BULK'
export const POST_PLAYLIST_BULK_SUCCESS = 'POST_PLAYLIST_BULK_SUCCESS'
export const POST_PLAYLIST_BULK_ERROR = 'POST_PLAYLIST_BULK_ERROR'
export const CLEAR_POST_PLAYLIST_BULK_INFO = 'CLEAR_POST_PLAYLIST_BULK_INFO'

export const POST_TEMPLATE_BULK = 'POST_TEMPLATE_BULK'
export const POST_TEMPLATE_BULK_SUCCESS = 'POST_TEMPLATE_BULK_SUCCESS'
export const POST_TEMPLATE_BULK_ERROR = 'POST_TEMPLATE_BULK_ERROR'
export const CLEAR_POST_TEMPLATE_BULK_INFO = 'CLEAR_POST_TEMPLATE_BULK_INFO'

export const POST_SCHEDULE_BULK = 'POST_SCHEDULE_BULK'
export const POST_SCHEDULE_BULK_SUCCESS = 'POST_SCHEDULE_BULK_SUCCESS'
export const POST_SCHEDULE_BULK_ERROR = 'POST_SCHEDULE_BULK_ERROR'
export const CLEAR_POST_SCHEDULE_BULK_INFO = 'CLEAR_POST_SCHEDULE_BULK_INFO'

export const POST_MENU_MAKER_ITEM_BULK = 'POST_MENU_MAKER_ITEM_BULK'
export const POST_MENU_MAKER_ITEM_BULK_SUCCESS =
  'POST_MENU_MAKER_ITEM_BULK_SUCCESS'
export const POST_MENU_MAKER_ITEM_BULK_ERROR = 'POST_MENU_MAKER_ITEM_BULK_ERROR'
export const CLEAR_POST_MENU_MAKER_ITEM_BULK_INFO =
  'CLEAR_POST_MENU_MAKER_ITEM_BULK_INFO'
export const CLEAR_ADDED_PLAYLIST = 'CLEAR_ADDED_PLAYLIST'
export const CLEAR_PLAYLIST_RESPONSE_INFO = 'CLEAR_PLAYLIST_RESPONSE_INFO'
export const CLEAR_PLAYLIST_STATUS = 'CLEAR_PLAYLIST_STATUS'
export const CLONE_PLAYLIST = 'CLONE_PLAYLIST'
export const CLONE_PLAYLIST_SUCCESS = 'CLONE_PLAYLIST_SUCCESS'
export const CLONE_PLAYLIST_ERROR = 'CLONE_PLAYLIST_ERROR'

export const POST_PLAYLIST = 'POST_PLAYLIST'
export const POST_PLAYLIST_SUCCESS = 'POST_PLAYLIST_SUCCESS'
export const POST_PLAYLIST_ERROR = 'POST_PLAYLIST_ERROR'

export const DELETE_PLAYLIST = 'DELETE_PLAYLIST'
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS'
export const DELETE_PLAYLIST_ERROR = 'DELETE_PLAYLIST_ERROR'

export const DELETE_SELECTED_PLAYLIST = 'DELETE_SELECTED_PLAYLIST'
export const DELETE_SELECTED_PLAYLIST_SUCCESS =
  'DELETE_SELECTED_PLAYLIST_SUCCESS'
export const DELETE_SELECTED_PLAYLIST_ERROR = 'DELETE_SELECTED_PLAYLIST_ERROR'

export const GET_PLAYLIST_BY_ID = 'GET_PLAYLIST_BY_ID'
export const GET_PLAYLIST_BY_ID_SUCCESS = 'GET_PLAYLIST_BY_ID_SUCCESS'
export const GET_PLAYLIST_BY_ID_ERROR = 'GET_PLAYLIST_BY_ID_ERROR'
export const PUT_PLAYLIST = 'PUT_PLAYLIST'
export const PUT_PLAYLIST_SUCCESS = 'PUT_PLAYLIST_SUCCESS'
export const PUT_PLAYLIST_ERROR = 'PUT_PLAYLIST_ERROR'
export const GET_PLAYLIST_ITEMS = 'GET_PLAYLIST_ITEMS'
export const GET_PLAYLIST_ITEMS_SUCCESS = 'GET_PLAYLIST_ITEMS_SUCCESS'
export const GET_PLAYLIST_ITEMS_ERROR = 'GET_PLAYLIST_ITEMS_ERROR'
export const GET_MODAL_PLAYLIST_ITEMS = 'GET_MODAL_PLAYLIST_ITEMS'
export const GET_MODAL_PLAYLIST_ITEMS_SUCCESS =
  'GET_MODAL_PLAYLIST_ITEMS_SUCCESS'
export const GET_MODAL_PLAYLIST_ITEMS_ERROR = 'GET_MODAL_PLAYLIST_ITEMS_ERROR'
export const CLEAR_PLAYLIST_ITEMS = 'CLEAR_PLAYLIST_ITEMS'
export const CLEAR_PLAYLIST_FETCH_PARAMS = 'CLEAR_PLAYLIST_FETCH_PARAMS'
export const GET_PLAYLIST_PREFERENCE = 'GET_PLAYLIST_PREFERENCE'
export const GET_PLAYLIST_PREFERENCE_SUCCESS = 'GET_PLAYLIST_PREFERENCE_SUCCESS'
export const GET_PLAYLIST_PREFERENCE_ERROR = 'GET_PLAYLIST_PREFERENCE_ERROR'
export const PUT_PLAYLIST_PREFERENCE = 'PUT_PLAYLIST_PREFERENCE'
export const PUT_PLAYLIST_PREFERENCE_SUCCESS = 'PUT_PLAYLIST_PREFERENCE_SUCCESS'
export const PUT_PLAYLIST_PREFERENCE_ERROR = 'PUT_PLAYLIST_PREFERENCE_ERROR'
export const GET_PLAYLIST_GROUP_PLAYLISTS = 'GET_PLAYLIST_GROUP_PLAYLISTS'
export const GET_PLAYLIST_GROUP_PLAYLISTS_SUCCESS =
  'GET_PLAYLIST_GROUP_PLAYLISTS_SUCCESS'
export const GET_PLAYLIST_GROUP_PLAYLISTS_ERROR =
  'GET_PLAYLIST_GROUP_PLAYLISTS_ERROR'
export const GET_PLAYLIST_GROUP_ITEMS = 'GET_PLAYLIST_GROUP_ITEMS'
export const GET_PLAYLIST_GROUP_ITEMS_SUCCESS =
  'GET_PLAYLIST_GROUP_ITEMS_SUCCESS'
export const GET_PLAYLIST_GROUP_ITEMS_ERROR = 'GET_PLAYLIST_GROUP_ITEMS_ERROR'
export const CLEAR_GET_PLAYLIST_GROUP_ITEMS_INFO =
  'CLEAR_GET_PLAYLIST_GROUP_ITEMS_INFO'
export const CLEAR_PLAYLIST_GROUP_ITEMS_RESPONSE_INFO =
  'CLEAR_PLAYLIST_GROUP_ITEMS_RESPONSE_INFO'
export const POST_PLAYLIST_GROUP_ITEM = 'POST_PLAYLIST_GROUP_ITEM'
export const POST_PLAYLIST_GROUP_ITEM_SUCCESS =
  'POST_PLAYLIST_GROUP_ITEM_SUCCESS'
export const POST_PLAYLIST_GROUP_ITEM_ERROR = 'POST_PLAYLIST_GROUP_ITEM_ERROR'
export const CLEAR_POST_PLAYLIST_GROUP_ITEM_INFO =
  'CLEAR_POST_PLAYLIST_GROUP_ITEM_INFO'
export const DELETE_PLAYLIST_GROUP_ITEM = 'DELETE_PLAYLIST_GROUP_ITEM'
export const DELETE_PLAYLIST_GROUP_ITEM_SUCCESS =
  'DELETE_PLAYLIST_GROUP_ITEM_SUCCESS'
export const DELETE_PLAYLIST_GROUP_ITEM_ERROR =
  'DELETE_PLAYLIST_GROUP_ITEM_ERROR'
export const CLEAR_DELETE_PLAYLIST_GROUP_ITEM_INFO =
  'CLEAR_DELETE_PLAYLIST_GROUP_ITEM_INFO'
export const GET_ASSIGNABLE_PLAYLIST = 'GET_ASSIGNABLE_PLAYLIST'
export const GET_ASSIGNABLE_PLAYLIST_SUCCESS = 'GET_ASSIGNABLE_PLAYLIST_SUCCESS'
export const GET_ASSIGNABLE_PLAYLIST_ERROR = 'GET_ASSIGNABLE_PLAYLIST_ERROR'

export const GET_PLAYLIST_PREVIEW = 'GET_PLAYLIST_PREVIEW'
export const GET_PLAYLIST_PREVIEW_SUCCESS = 'GET_PLAYLIST_PREVIEW_SUCCESS'
export const GET_PLAYLIST_PREVIEW_ERROR = 'GET_PLAYLIST_PREVIEW_ERROR'
export const GENERATE_PLAYLIST_PREVIEW = 'GENERATE_PLAYLIST_PREVIEW'
export const GENERATE_PLAYLIST_PREVIEW_SUCCESS =
  'GENERATE_PLAYLIST_PREVIEW_SUCCESS'
export const GENERATE_PLAYLIST_PREVIEW_ERROR = 'GENERATE_PLAYLIST_PREVIEW_ERROR'
export const CLOSE_PLAYLIST_PREVIEW = 'CLOSE_PLAYLIST_PREVIEW'
export const CLEAR_PLAYLIST_PREVIEW = 'CLEAR_PLAYLIST_PREVIEW'
export const SHOW_PLAYLIST_PREVIEW = 'SHOW_PLAYLIST_PREVIEW'
export const SHARE_PLAYLIST = 'SHARE_PLAYLIST'
export const SHARE_PLAYLIST_SUCCESS = 'SHARE_PLAYLIST_SUCCESS'
export const SHARE_PLAYLIST_ERROR = 'SHARE_PLAYLIST_ERROR'
export const COPY_PLAYLIST = 'COPY_PLAYLIST'
export const COPY_PLAYLIST_SUCCESS = 'COPY_PLAYLIST_SUCCESS'
export const COPY_PLAYLIST_ERROR = 'COPY_PLAYLIST_ERROR'

export const POST_TEMPLATE = 'POST_TEMPLATE'
export const POST_TEMPLATE_SUCCESS = 'POST_TEMPLATE_SUCCESS'
export const POST_TEMPLATE_ERROR = 'POST_TEMPLATE_ERROR'
export const GET_TEMPLATE = 'GET_TEMPLATE'
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS'
export const GET_TEMPLATE_ERROR = 'GET_TEMPLATE_ERROR'
export const PUT_TEMPLATE = 'PUT_TEMPLATE'
export const PUT_TEMPLATE_SUCCESS = 'PUT_TEMPLATE_SUCCESS'
export const PUT_TEMPLATE_ERROR = 'PUT_TEMPLATE_ERROR'
export const CLEAR_TEMPLATE_ITEM = 'CLEAR_TEMPLATE_ITEM'
export const CLEAR_TEMPLATE_INFO = 'CLEAR_TEMPLATE_INFO'
export const CLEAR_TEMPLATE_FETCH_PARAMS = 'CLEAR_TEMPLATE_FETCH_PARAMS'

export const GET_TEMPLATE_ITEMS = 'GET_TEMPLATE_ITEMS'
export const GET_TEMPLATE_ITEMS_SUCCESS = 'GET_TEMPLATE_ITEMS_SUCCESS'
export const GET_TEMPLATE_ITEMS_ERROR = 'GET_TEMPLATE_ITEMS_ERROR'
export const CLEAR_TEMPLATE_ITEMS = 'CLEAR_TEMPLATE_ITEMS'
export const GET_TEMPLATE_PREFERENCE = 'GET_TEMPLATE_PREFERENCE'
export const GET_TEMPLATE_PREFERENCE_SUCCESS = 'GET_TEMPLATE_PREFERENCE_SUCCESS'
export const GET_TEMPLATE_PREFERENCE_ERROR = 'GET_TEMPLATE_PREFERENCE_ERROR'
export const PUT_TEMPLATE_PREFERENCE = 'PUT_TEMPLATE_PREFERENCE'
export const RESET_TEMPLATE_CONTAINER = 'RESET_TEMPLATE_CONTAINER'
export const PUT_TEMPLATE_PREFERENCE_ERROR = 'PUT_TEMPLATE_PREFERENCE_ERROR'
export const GET_TEMPLATE_GROUP_ITEMS = 'GET_TEMPLATE_GROUP_ITEMS'
export const GET_TEMPLATE_GROUP_ITEMS_SUCCESS =
  'GET_TEMPLATE_GROUP_ITEMS_SUCCESS'
export const GET_TEMPLATE_GROUP_ITEMS_ERROR = 'GET_TEMPLATE_GROUP_ITEMS_ERROR'
export const GET_TEMPLATE_GROUP_TEMPLATES = 'GET_TEMPLATE_GROUP_TEMPLATES'
export const GET_TEMPLATE_GROUP_TEMPLATES_SUCCESS =
  'GET_TEMPLATE_GROUP_TEMPLATES_SUCCESS'
export const GET_TEMPLATE_GROUP_TEMPLATES_ERROR =
  'GET_TEMPLATE_GROUP_TEMPLATES_ERROR'
export const CLEAR_GET_TEMPLATE_GROUP_ITEMS_INFO =
  'CLEAR_GET_TEMPLATE_GROUP_ITEMS_INFO'
export const POST_TEMPLATE_GROUP_ITEM = 'POST_TEMPLATE_GROUP_ITEM'
export const POST_TEMPLATE_GROUP_ITEM_SUCCESS =
  'POST_TEMPLATE_GROUP_ITEM_SUCCESS'
export const POST_TEMPLATE_GROUP_ITEM_ERROR = 'POST_TEMPLATE_GROUP_ITEM_ERROR'
export const CLEAR_POST_TEMPLATE_GROUP_ITEM_INFO =
  'CLEAR_POST_TEMPLATE_GROUP_ITEM_INFO'
export const DELETE_TEMPLATE_GROUP_ITEM = 'DELETE_TEMPLATE_GROUP_ITEM'
export const DELETE_TEMPLATE_GROUP_ITEM_SUCCESS =
  'DELETE_TEMPLATE_GROUP_ITEM_SUCCESS'
export const DELETE_TEMPLATE_GROUP_ITEM_ERROR =
  'DELETE_TEMPLATE_GROUP_ITEM_ERROR'
export const CLEAR_DELETE_TEMPLATE_GROUP_ITEM_INFO =
  'CLEAR_DELETE_TEMPLATE_GROUP_ITEM_INFO'
export const CLEAR_TEMPLATE_GROUP_ITEMS_RESPONSE_INFO =
  'CLEAR_TEMPLATE_GROUP_ITEMS_RESPONSE_INFO'

export const CLONE_TEMPLATE = 'CLONE_TEMPLATE'
export const CLONE_TEMPLATE_SUCCESS = 'CLONE_TEMPLATE_SUCCESS'
export const CLONE_TEMPLATE_ERROR = 'CLONE_TEMPLATE_ERROR'

export const SHARE_TEMPLATE = 'SHARE_TEMPLATE'
export const SHARE_TEMPLATE_SUCCESS = 'SHARE_TEMPLATE_SUCCESS'
export const SHARE_TEMPLATE_ERROR = 'SHARE_TEMPLATE_ERROR'
export const COPY_TEMPLATE = 'COPY_TEMPLATE'
export const COPY_TEMPLATE_SUCCESS = 'COPY_TEMPLATE_SUCCESS'
export const COPY_TEMPLATE_ERROR = 'COPY_TEMPLATE_ERROR'
export const GET_TEMPLATE_BACKGROUNDS = 'GET_TEMPLATE_BACKGROUNDS'
export const GET_TEMPLATE_BACKGROUNDS_SUCCESS =
  'GET_TEMPLATE_BACKGROUNDS_SUCCESS'
export const GET_TEMPLATE_BACKGROUNDS_ERROR = 'GET_TEMPLATE_BACKGROUNDS_ERROR'
export const GET_TEMPLATE_BACKGROUND = 'GET_TEMPLATE_BACKGROUND'
export const GET_TEMPLATE_BACKGROUND_SUCCESS = 'GET_TEMPLATE_BACKGROUND_SUCCESS'
export const GET_TEMPLATE_BACKGROUND_ERROR = 'GET_TEMPLATE_BACKGROUND_ERROR'

export const GET_TEMPLATE_BACKGROUND_IMAGE = 'GET_TEMPLATE_BACKGROUND_IMAGE'
export const GET_TEMPLATE_BACKGROUND_IMAGE_SUCCESS =
  'GET_TEMPLATE_BACKGROUND_IMAGE_SUCCESS'
export const GET_TEMPLATE_BACKGROUND_IMAGE_ERROR =
  'GET_TEMPLATE_BACKGROUND_IMAGE_ERROR'
export const CLEAR_TEMPLATE_BACKGROUND_IMAGE = 'CLEAR_TEMPLATE_BACKGROUND_IMAGE'

export const POST_TEMPLATE_BACKGROUND = 'POST_TEMPLATE_BACKGROUND'
export const POST_TEMPLATE_BACKGROUND_SUCCESS =
  'POST_TEMPLATE_BACKGROUND_SUCCESS'
export const POST_TEMPLATE_BACKGROUND_ERROR = 'POST_TEMPLATE_BACKGROUND_ERROR'
export const PUT_TEMPLATE_BACKGROUND = 'PUT_TEMPLATE_BACKGROUND'
export const PUT_TEMPLATE_BACKGROUND_SUCCESS = 'PUT_TEMPLATE_BACKGROUND_SUCCESS'
export const PUT_TEMPLATE_BACKGROUND_ERROR = 'PUT_TEMPLATE_BACKGROUND_ERROR'

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS'
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR'

export const DELETE_SELECTED_TEMPLATE = 'DELETE_SELECTED_TEMPLATE'
export const DELETE_SELECTED_TEMPLATE_SUCCESS =
  'DELETE_SELECTED_TEMPLATE_SUCCESS'
export const DELETE_SELECTED_TEMPLATE_ERROR = 'DELETE_SELECTED_TEMPLATE_ERROR'

export const CLEAR_TEMPLATE_RESPONSE_INFO = 'CLEAR_TEMPLATE_RESPONSE_INFO'

export const GET_TEMPLATE_PREVIEW = 'GET_TEMPLATE_PREVIEW'
export const GET_TEMPLATE_PREVIEW_SUCCESS = 'GET_TEMPLATE_PREVIEW_SUCCESS'
export const GET_TEMPLATE_PREVIEW_ERROR = 'GET_TEMPLATE_PREVIEW_ERROR'
export const GENERATE_TEMPLATE_PREVIEW = 'GENERATE_TEMPLATE_PREVIEW'
export const GENERATE_TEMPLATE_PREVIEW_SUCCESS =
  'GENERATE_TEMPLATE_PREVIEW_SUCCESS'
export const GENERATE_TEMPLATE_PREVIEW_ERROR = 'GENERATE_TEMPLATE_PREVIEW_ERROR'
export const CLOSE_TEMPLATE_PREVIEW = 'CLOSE_TEMPLATE_PREVIEW'
export const CLEAR_TEMPLATE_PREVIEW = 'CLEAR_TEMPLATE_PREVIEW'
export const SHOW_TEMPLATE_PREVIEW = 'SHOW_TEMPLATE_PREVIEW'

export const GET_TEMPLATE_MEDIA = 'GET_TEMPLATE_MEDIA'
export const GET_TEMPLATE_MEDIA_SUCCESS = 'GET_TEMPLATE_MEDIA_SUCCESS'
export const GET_TEMPLATE_MEDIA_ERROR = 'GET_TEMPLATE_MEDIA_ERROR'

export const GET_TEMPLATE_PLAYLIST = 'GET_TEMPLATE_PLAYLIST'
export const GET_TEMPLATE_PLAYLIST_SUCCESS = 'GET_TEMPLATE_PLAYLIST_SUCCESS'
export const GET_TEMPLATE_PLAYLIST_ERROR = 'GET_TEMPLATE_PLAYLIST_ERROR'

export const APPROVE_SCHEDULE = 'APPROVE_SCHEDULE'
export const APPROVE_SCHEDULE_SUCCESS = 'APPROVE_SCHEDULE_SUCCESS'
export const APPROVE_SCHEDULE_ERROR = 'APPROVE_SCHEDULE_ERROR'
export const CLEAR_ADDED_SCHEDULE = 'CLEAR_ADDED_SCHEDULE'
export const CLEAR_SCHEDULE_ERROR = 'CLEAR_SCHEDULE_ERROR'
export const CLEAR_SCHEDULE_STATUS = 'CLEAR_SCHEDULE_STATUS'
export const CLEAR_SCHEDULE_PUT_POST_STATUS = 'CLEAR_SCHEDULE_PUT_POST_STATUS'
export const POST_SCHEDULE = 'POST_SCHEDULE'
export const POST_SCHEDULE_SUCCESS = 'POST_SCHEDULE_SUCCESS'
export const POST_SCHEDULE_ERROR = 'POST_SCHEDULE_ERROR'
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS'
export const DELETE_SCHEDULE_ERROR = 'DELETE_SCHEDULE_ERROR'
export const GET_SCHEDULE_BY_ID = 'GET_SCHEDULE_BY_ID'
export const GET_SCHEDULE_BY_ID_SUCCESS = 'GET_SCHEDULE_BY_ID_SUCCESS'
export const GET_SCHEDULE_BY_ID_ERROR = 'GET_SCHEDULE_BY_ID_ERROR'
export const PUT_SCHEDULE = 'PUT_SCHEDULE'
export const PUT_SCHEDULE_SUCCESS = 'PUT_SCHEDULE_SUCCESS'
export const PUT_SCHEDULE_ERROR = 'PUT_SCHEDULE_ERROR'
export const ASSIGN_DEVICES_SCHEDULE = 'ASSIGN_DEVICES_SCHEDULE'
export const ASSIGN_DEVICES_SCHEDULE_SUCCESS = 'ASSIGN_DEVICES_SCHEDULE_SUCCESS'
export const ASSIGN_DEVICES_SCHEDULE_ERROR = 'ASSIGN_DEVICES_SCHEDULE_ERROR'
export const GET_SCHEDULE_ITEMS = 'GET_SCHEDULE_ITEMS'
export const GET_SCHEDULE_ITEMS_SUCCESS = 'GET_SCHEDULE_ITEMS_SUCCESS'
export const GET_SCHEDULE_ITEMS_ERROR = 'GET_SCHEDULE_ITEMS_ERROR'
export const CLEAR_SCHEDULE_ITEMS = 'CLEAR_SCHEDULE_ITEMS'
export const GET_SCHEDULE_LISTS = 'GET_SCHEDULE_LISTS'
export const GET_SCHEDULE_LISTS_SUCCESS = 'GET_SCHEDULE_LISTS_SUCCESS'
export const GET_SCHEDULE_LISTS_ERROR = 'GET_SCHEDULE_LISTS_ERROR'
export const GET_SCHEDULE_TIMELINE = 'GET_SCHEDULE_TIMELINE'
export const GET_SCHEDULE_TIMELINE_SUCCESS = 'GET_SCHEDULE_TIMELINE_SUCCESS'
export const GET_SCHEDULE_TIMELINE_ERROR = 'GET_SCHEDULE_TIMELINE_ERROR '
export const CLEAR_SCHEDULE_TIMELINE = 'CLEAR_SCHEDULE_TIMELINE'
export const GET_CALENDAR_SCHEDULES = 'GET_CALENDAR_SCHEDULES'
export const GET_CALENDAR_SCHEDULES_SUCCESS = 'GET_CALENDAR_SCHEDULES_SUCCESS'
export const GET_CALENDAR_SCHEDULES_ERROR = 'GET_CALENDAR_SCHEDULES_ERROR '
export const CLEAR_CALENDAR_SCHEDULES = 'CLEAR_CALENDAR_SCHEDULES'
export const GET_SCHEDULE_PREFERENCE = 'GET_SCHEDULE_PREFERENCE'
export const GET_SCHEDULE_PREFERENCE_SUCCESS = 'GET_SCHEDULE_PREFERENCE_SUCCESS'
export const GET_SCHEDULE_PREFERENCE_ERROR = 'GET_SCHEDULE_PREFERENCE_ERROR'
export const PUT_SCHEDULE_PREFERENCE = 'PUT_SCHEDULE_PREFERENCE'
export const PUT_SCHEDULE_PREFERENCE_ERROR = 'PUT_SCHEDULE_PREFERENCE_ERROR'
export const GET_SCHEDULE_GROUP_ITEMS = 'GET_SCHEDULE_GROUP_ITEMS'
export const GET_SCHEDULE_GROUP_ITEMS_SUCCESS =
  'GET_SCHEDULE_GROUP_ITEMS_SUCCESS'
export const GET_SCHEDULE_GROUP_ITEMS_ERROR = 'GET_SCHEDULE_GROUP_ITEMS_ERROR'
export const CLEAR_GET_SCHEDULE_GROUP_ITEMS_INFO =
  'CLEAR_GET_SCHEDULE_GROUP_ITEMS_INFO'
export const CLEAR_SCHEDULE_GROUP_ITEMS_RESPONSE_INFO =
  'CLEAR_SCHEDULE_GROUP_ITEMS_RESPONSE_INFO'
export const POST_SCHEDULE_GROUP_ITEM = 'POST_SCHEDULE_GROUP_ITEM'
export const POST_SCHEDULE_GROUP_ITEM_SUCCESS =
  'POST_SCHEDULE_GROUP_ITEM_SUCCESS'
export const POST_SCHEDULE_GROUP_ITEM_ERROR = 'POST_SCHEDULE_GROUP_ITEM_ERROR'
export const CLEAR_POST_SCHEDULE_GROUP_ITEM_INFO =
  'CLEAR_POST_SCHEDULE_GROUP_ITEM_INFO'
export const DELETE_SCHEDULE_GROUP_ITEM = 'DELETE_SCHEDULE_GROUP_ITEM'
export const DELETE_SCHEDULE_GROUP_ITEM_SUCCESS =
  'DELETE_SCHEDULE_GROUP_ITEM_SUCCESS'
export const DELETE_SCHEDULE_GROUP_ITEM_ERROR =
  'DELETE_SCHEDULE_GROUP_ITEM_ERROR'
export const CLEAR_DELETE_SCHEDULE_GROUP_ITEM_INFO =
  'CLEAR_DELETE_SCHEDULE_GROUP_ITEM_INFO'
export const GET_SCHEDULE_GROUP_SCHEDULES = 'GET_SCHEDULE_GROUP_SCHEDULES'
export const GET_SCHEDULE_GROUP_SCHEDULES_SUCCESS =
  'GET_SCHEDULE_GROUP_SCHEDULES_SUCCESS'
export const GET_SCHEDULE_GROUP_SCHEDULES_ERROR =
  'GET_SCHEDULE_GROUP_SCHEDULES_ERROR'

export const PATCH_SCHEDULE_ITEM = 'PATCH_SCHEDULE_ITEM'
export const PATCH_SCHEDULE_ITEM_SUCCESS = 'PATCH_SCHEDULE_ITEM_SUCCESS'
export const PATCH_SCHEDULE_ITEM_ERROR = 'PATCH_SCHEDULE_ITEM_ERROR'

export const CLONE_SCHEDULE_TIMELINE = 'CLONE_SCHEDULE_TIMELINE'
export const CLONE_SCHEDULE_TIMELINE_SUCCESS = 'CLONE_SCHEDULE_TIMELINE_SUCCESS'
export const CLONE_SCHEDULE_TIMELINE_ERROR = 'CLONE_SCHEDULE_TIMELINE_ERROR'

export const DELETE_SELECTED_SCHEDULES = 'DELETE_SELECTED_SCHEDULES'
export const DELETE_SELECTED_SCHEDULES_SUCCESS =
  'DELETE_SELECTED_SCHEDULES_SUCCESS'
export const DELETE_SELECTED_SCHEDULES_ERROR = 'DELETE_SELECTED_SCHEDULES_ERROR'

export const CLONE_SCHEDULE = 'CLONE_SCHEDULE'
export const CLONE_SCHEDULE_SUCCESS = 'CLONE_SCHEDULE_SUCCESS'
export const CLONE_SCHEDULE_ERROR = 'CLONE_SCHEDULE_ERROR'

export const COPY_SCHEDULE = 'COPY_SCHEDULE'
export const COPY_SCHEDULE_SUCCESS = 'COPY_SCHEDULE_SUCCESS'
export const COPY_SCHEDULE_ERROR = 'COPY_SCHEDULE_ERROR'

export const CLEAR_SCHEDULE_RESPONSE_INFO = 'CLEAR_SCHEDULE_RESPONSE_INFO'
export const CLEAR_SCHEDULE_FETCH_PARAMS = 'CLEAR_SCHEDULE_FETCH_PARAMS'

export const GET_SCHEDULE_PREVIEW = 'GET_SCHEDULE_PREVIEW'
export const GET_SCHEDULE_PREVIEW_SUCCESS = 'GET_SCHEDULE_PREVIEW_SUCCESS'
export const GET_SCHEDULE_PREVIEW_ERROR = 'GET_SCHEDULE_PREVIEW_ERROR'
export const GENERATE_SCHEDULE_PREVIEW = 'GENERATE_SCHEDULE_PREVIEW'
export const GENERATE_SCHEDULE_PREVIEW_SUCCESS =
  'GENERATE_SCHEDULE_PREVIEW_SUCCESS'
export const GENERATE_SCHEDULE_PREVIEW_ERROR = 'GENERATE_SCHEDULE_PREVIEW_ERROR'
export const CLOSE_SCHEDULE_PREVIEW = 'CLOSE_SCHEDULE_PREVIEW'
export const CLEAR_SCHEDULE_PREVIEW = 'CLEAR_SCHEDULE_PREVIEW'
export const SHOW_SCHEDULE_PREVIEW = 'SHOW_SCHEDULE_PREVIEW'

export const GET_ASSIGNABLE_SCHEDULE = 'GET_ASSIGNABLE_SCHEDULE'
export const GET_ASSIGNABLE_SCHEDULE_SUCCESS = 'GET_ASSIGNABLE_SCHEDULE_SUCCESS'
export const GET_ASSIGNABLE_SCHEDULE_ERROR = 'GET_ASSIGNABLE_SCHEDULE_ERROR'

export const POST_DEVICE_ITEM = 'POST_DEVISE_ITEM'
export const POST_DEVICE_ITEM_SUCCESS = 'POST_DEVISE_ITEM_SUCCESS'
export const POST_DEVICE_ITEM_ERROR = 'POST_DEVISE_ITEM_ERROR'
export const CLEAT_POST_DEVICE_INFO = 'CLEAT_POST_DEVICE_ERRORS'
export const CLEAR_DEVICE_FETCH_PARAMS = 'CLEAR_DEVICE_FETCH_PARAMS'

export const CLONE_DEVICE_ITEM = 'CLONE_DEVICE_ITEM'
export const CLONE_DEVICE_ITEM_SUCCESS = 'CLONE_DEVICE_ITEM_SUCCESS'
export const CLONE_DEVICE_ITEM_ERROR = 'CLONE_DEVICE_ITEM_ERROR'
export const CLEAR_CLONE_DEVICE_INFO = 'CLEAR_CLONE_DEVICE_INFO'

export const GET_DEVICE_ITEMS = 'GET_DEVICE_ITEMS'
export const GET_DEVICE_ITEMS_SUCCESS = 'GET_DEVICE_ITEMS_SUCCESS'
export const GET_DEVICE_ITEMS_ERROR = 'GET_DEVICE_ITEMS_ERROR'
export const CLEAR_GET_DEVICE_ITEMS = 'CLEAR_GET_DEVICE_ITEMS'

export const GET_DEVICE_LOCATION_CATEGORIES = 'GET_DEVICE_LOCATION_CATEGORIES'
export const GET_DEVICE_LOCATION_CATEGORIES_SUCCESS =
  'GET_DEVICE_LOCATION_CATEGORIES_SUCCESS'
export const GET_DEVICE_LOCATION_CATEGORIES_ERROR =
  'GET_DEVICE_LOCATION_CATEGORIES_ERROR'
export const CLEAR_GET_DEVICE_LOCATION_CATEGORIES =
  'CLEAR_GET_DEVICE_LOCATION_CATEGORIES'

export const GET_DEVICE_PREFERENCE = 'GET_DEVICE_PREFERENCE'
export const GET_DEVICE_PREFERENCE_SUCCESS = 'GET_DEVICE_PREFERENCE_SUCCESS'
export const GET_DEVICE_PREFERENCE_ERROR = 'GET_DEVICE_PREFERENCE_ERROR'
export const PUT_DEVICE_PREFERENCE = 'PUT_DEVICE_PREFERENCE'
export const PUT_DEVICE_PREFERENCE_ERROR = 'PUT_DEVICE_PREFERENCE_ERROR'

export const GET_PROOF_OF_PLAY_DEVICES = 'GET_PROOF_OF_PLAY_DEVICES'
export const GET_PROOF_OF_PLAY_DEVICES_SUCCESS =
  'GET_PROOF_OF_PLAY_DEVICES_SUCCESS'
export const GET_PROOF_OF_PLAY_DEVICES_ERROR = 'GET_PROOF_OF_PLAY_DEVICES_ERROR'

export const POST_FEATURE_DEVICE_ASSOCIATE = 'POST_FEATURE_DEVICE_ASSOCIATE'
export const POST_FEATURE_DEVICE_ASSOCIATE_SUCCESS =
  'POST_FEATURE_DEVICE_ASSOCIATE_SUCCESS'
export const POST_FEATURE_DEVICE_ASSOCIATE_ERROR =
  'POST_FEATURE_DEVICE_ASSOCIATE_ERROR'
export const CLEAR_PROOF_OF_PLAY_DEVICE_DATA = 'CLEAR_PROOF_OF_PLAY_DEVICE_DATA'

export const GET_DEVICE_GROUPS = 'GET_DEVICE_GROUPS'
export const GET_DEVICE_GROUPS_SUCCESS = 'GET_DEVICE_GROUPS_SUCCESS'
export const GET_DEVICE_GROUPS_ERROR = 'GET_DEVICE_GROUPS_ERROR'
export const CLEAR_GET_DEVICE_GROUPS_INFO = 'CLEAR_GET_DEVICE_GROUPS_INFO'

export const DELETE_DEVICE_ITEM = 'DELETE_DEVICE_ITEM'
export const DELETE_DEVICE_ITEM_SUCCESS = 'DELETE_DEVICE_ITEM_SUCCESS'
export const DELETE_DEVICE_ITEM_ERROR = 'DELETE_DEVICE_ITEM_ERROR'
export const CLEAR_DEVICE_ITEM_INFO = 'CLEAR_DEVICE_ITEM_INFO'

export const GET_DEVICES_BY_FOLDER = 'GET_DEVICES_BY_FOLDER'
export const GET_DEVICES_BY_FOLDER_SUCCESS = 'GET_DEVICES_BY_FOLDER_SUCCESS'
export const GET_DEVICES_BY_FOLDER_ERROR = 'GET_DEVICES_BY_FOLDER_ERROR'

export const PUT_DEVICE_ITEM = 'PUT_DEVICE_ITEM'
export const PUT_DEVICE_ITEM_SUCCESS = 'PUT_DEVICE_ITEM_SUCCESS'
export const PUT_DEVICE_ITEM_ERROR = 'PUT_DEVICE_ITEM_ERROR'
export const CLEAR_PUT_DEVICE_ITEM_INFO = 'CLEAR_PUT_DEVICE_ITEM_INFO'
export const GET_DEVICE_ITEM = 'GET_DEVICE_ITEM'
export const GET_DEVICE_ITEM_SUCCESS = 'GET_DEVICE_ITEM_SUCCESS'
export const GET_DEVICE_ITEM_ERROR = 'GET_DEVICE_ITEM_ERROR'
export const CLEAR_GET_DEVICE_ITEM_INFO = 'CLEAR_GET_DEVICE_ITEM_INFO'
export const GET_DEVICE_PREVIEW = 'GET_DEVICE_PREVIEW'
export const GET_DEVICE_PREVIEW_SUCCESS = 'GET_DEVICE_PREVIEW_SUCCESS'
export const GET_DEVICE_PREVIEW_ERROR = 'GET_DEVICE_PREVIEW_ERROR'
export const SHOW_DEVICE_PREVIEW = 'SHOW_DEVICE_PREVIEW'
export const CLOSE_DEVICE_PREVIEW = 'CLOSE_DEVICE_PREVIEW'
export const CLEAR_GET_DEVICE_PREVIEW_INFO = 'CLEAR_GET_DEVICE_PREVIEW_INFO'
export const GET_DEVICE_GROUP_ITEMS = 'GET_DEVICE_GROUP_ITEMS'
export const GET_DEVICE_GROUP_ITEMS_SUCCESS = 'GET_DEVICE_GROUP_ITEMS_SUCCESS'
export const GET_DEVICE_GROUP_ITEMS_ERROR = 'GET_DEVICE_GROUP_ITEMS_ERROR'
export const GET_DEVICE_GROUP_DEVICES = 'GET_DEVICE_GROUP_DEVICES'
export const GET_DEVICE_GROUP_DEVICES_SUCCESS =
  'GET_DEVICE_GROUP_DEVICES_SUCCESS'
export const GET_DEVICE_GROUP_DEVICES_ERROR = 'GET_DEVICE_GROUP_DEVICES_ERROR'
export const CLEAR_GET_DEVICE_GROUP_ITEMS_INFO =
  'CLEAR_GET_DEVICE_GROUP_ITEMS_INFO'
export const POST_DEVICE_GROUP_ITEM = 'POST_DEVICE_GROUP_ITEM'
export const POST_DEVICE_GROUP_ITEM_SUCCESS = 'POST_DEVICE_GROUP_ITEM_SUCCESS'
export const POST_DEVICE_GROUP_ITEM_ERROR = 'POST_DEVICE_GROUP_ITEM_ERROR'
export const CLEAR_POST_DEVICE_GROUP_ITEM_INFO =
  'CLEAR_POST_DEVICE_GROUP_ITEM_INFO'
export const DELETE_DEVICE_GROUP_ITEM = 'DELETE_DEVICE_GROUP_ITEM'
export const DELETE_DEVICE_GROUP_ITEM_SUCCESS =
  'DELETE_DEVICE_GROUP_ITEM_SUCCESS'
export const DELETE_DEVICE_GROUP_ITEM_ERROR = 'DELETE_DEVICE_GROUP_ITEM_ERROR'
export const CLEAR_DELETE_DEVICE_GROUP_ITEM_INFO =
  'CLEAR_DELETE_DEVICE_GROUP_ITEM_INFO'
export const CLEAR_DEVICE_GROUP_ITEMS_RESPONSE_INFO =
  'CLEAR_DEVICE_GROUP_ITEMS_RESPONSE_INFO'
export const GET_DEVICE_REBOOT = 'GET_DEVICE_REBOOT'
export const GET_DEVICE_REBOOT_SUCCESS = 'GET_DEVICE_REBOOT_SUCCESS'
export const GET_DEVICE_REBOOT_ERROR = 'GET_DEVICE_REBOOT_ERROR'
export const CLEAR_GET_DEVICE_REBOOT_INFO = 'CLEAR_GET_DEVICE_REBOOT_INFO'
export const PUT_DEVICE_REBOOT = 'PUT_DEVICE_REBOOT'
export const PUT_DEVICE_REBOOT_SUCCESS = 'PUT_DEVICE_REBOOT_SUCCESS'
export const PUT_DEVICE_REBOOT_ERROR = 'PUT_DEVICE_REBOOT_ERROR'
export const PUT_DEVICE_BULK_REBOOT = 'PUT_DEVICE_BULK_REBOOT'
export const PUT_DEVICE_BULK_REBOOT_SUCCESS = 'PUT_DEVICE_BULK_REBOOT_SUCCESS'
export const PUT_DEVICE_BULK_REBOOT_ERROR = 'PUT_DEVICE_BULK_REBOOT_ERROR'
export const CLEAR_PUT_DEVICE_REBOOT_INFO = 'CLEAR_DEVICE_REBOOT_INFO'

export const PUT_DEVICE_BULK_PERIODICAL_REBOOT =
  'PUT_DEVICE_BULK_PERIODICAL_REBOOT'
export const PUT_DEVICE_BULK_PERIODICAL_REBOOT_SUCCESS =
  'PUT_DEVICE_BULK_PERIODICAL_REBOOT_SUCCESS'
export const PUT_DEVICE_BULK_PERIODICAL_REBOOT_ERROR =
  'PUT_DEVICE_BULK_PERIODICAL_REBOOT_ERROR'

export const PUT_PERIODICAL_DEVICE_REBOOT = 'PUT_PERIODICAL_DEVICE_REBOOT'
export const PUT_PERIODICAL_DEVICE_REBOOT_SUCCESS =
  'PUT_PERIODICAL_DEVICE_REBOOT_SUCCESS'
export const PUT_PERIODICAL_DEVICE_REBOOT_ERROR =
  'PUT_PERIODICAL_DEVICE_REBOOT_ERROR'

export const GET_SCREEN_POWER_SETTINGS = 'GET_SCREEN_POWER_SETTINGS'
export const GET_SCREEN_POWER_SETTINGS_SUCCESS =
  'GET_SCREEN_POWER_SETTINGS_SUCCESS'
export const GET_SCREEN_POWER_SETTINGS_ERROR = 'GET_SCREEN_POWER_SETTINGS_ERROR'
export const PUT_SCREEN_POWER_SETTINGS = 'PUT_SCREEN_POWER_SETTINGS'
export const PUT_SCREEN_POWER_SETTINGS_SUCCESS =
  'PUT_SCREEN_POWER_SETTINGS_SUCCESS'
export const PUT_SCREEN_POWER_SETTINGS_ERROR = 'PUT_SCREEN_POWER_SETTINGS_ERROR'
export const CLEAR_PUT_SCREEN_POWER_SETTINGS_INFO =
  'CLEAR_PUT_SCREEN_POWER_SETTINGS_INFO'

export const GET_DEVICE_HEALTH = 'GET_DEVICE_HEALTH'
export const GET_DEVICE_HEALTH_SUCCESS = 'GET_DEVICE_HEALTH_SUCCESS'
export const GET_DEVICE_HEALTH_ERROR = 'GET_DEVICE_HEALTH_ERROR'

export const GET_DEVICE_NOTES = 'GET_DEVICE_NOTES'
export const GET_DEVICE_NOTES_SUCCESS = 'GET_DEVICE_NOTES_SUCCESS'
export const GET_DEVICE_NOTES_ERROR = 'GET_DEVICE_NOTES_ERROR'
export const POST_DEVICE_NOTE = 'POST_DEVICE_NOTE'
export const POST_DEVICE_NOTE_SUCCESS = 'POST_DEVICE_NOTE_SUCCESS'
export const POST_DEVICE_NOTE_ERROR = 'POST_DEVICE_NOTE_ERROR'
export const CLEAR_POST_DEVICE_NOTE = 'CLEAR_POST_DEVICE_NOTE'

export const GET_DEVICE_LOGS = 'GET_DEVICE_LOGS'
export const GET_DEVICE_LOGS_SUCCESS = 'GET_DEVICE_LOGS_SUCCESS'
export const GET_DEVICE_LOGS_ERROR = 'GET_DEVICE_LOGS_ERROR'
export const CLEAR_DEVICE_LOGS = 'CLEAR_DEVICE_LOGS'

export const GET_DEVICE_TYPES = 'GET_MEDIA_TYPES'
export const GET_DEVICE_TYPES_SUCCESS = 'GET_MEDIA_TYPES_SUCCESS'
export const GET_DEVICE_TYPES_ERROR = 'GET_MEDIA_TYPES_ERROR'
export const GET_DEVICE_TYPE = 'GET_MEDIA_TYPE'
export const GET_DEVICE_TYPE_SUCCESS = 'GET_MEDIA_TYPE_SUCCESS'
export const GET_DEVICE_TYPE_ERROR = 'GET_MEDIA_TYPE_ERROR'
export const PUT_DEVICE_TYPE = 'PUT_MEDIA_TYPE'
export const PUT_DEVICE_TYPE_SUCCESS = 'PUT_MEDIA_TYPE_SUCCESS'
export const PUT_DEVICE_TYPE_ERROR = 'PUT_MEDIA_TYPE_ERROR'
export const POST_DEVICE_TYPE = 'POST_MEDIA_TYPE'
export const POST_DEVICE_TYPE_SUCCESS = 'POST_MEDIA_TYPE_SUCCESS'
export const POST_DEVICE_TYPE_ERROR = 'POST_MEDIA_TYPE_ERROR'
export const CLEAR_DEVICE_TYPE_INFO = 'CLEAR_DEVICE_TYPE_INFO'

export const GET_DEVICE_MODELS = 'GET_MEDIA_MODELS'
export const GET_DEVICE_MODELS_SUCCESS = 'GET_MEDIA_MODELS_SUCCESS'
export const GET_DEVICE_MODELS_ERROR = 'GET_MEDIA_MODELS_ERROR'
export const GET_DEVICE_MODEL = 'GET_MEDIA_MODEL'
export const GET_DEVICE_MODEL_SUCCESS = 'GET_MEDIA_MODEL_SUCCESS'
export const GET_DEVICE_MODEL_ERROR = 'GET_MEDIA_MODEL_ERROR'
export const PUT_DEVICE_MODEL = 'PUT_MEDIA_MODEL'
export const PUT_DEVICE_MODEL_SUCCESS = 'PUT_MEDIA_MODEL_SUCCESS'
export const PUT_DEVICE_MODEL_ERROR = 'PUT_MEDIA_MODEL_ERROR'
export const POST_DEVICE_MODEL = 'POST_MEDIA_MODEL'
export const POST_DEVICE_MODEL_SUCCESS = 'POST_MEDIA_MODEL_SUCCESS'
export const POST_DEVICE_MODEL_ERROR = 'POST_MEDIA_MODEL_ERROR'
export const CLEAR_DEVICE_MODEL_INFO = 'CLEAR_DEVICE_MODEL_INFO'

export const GET_DEVICE_UPDATES_AVAILABLE = 'GET_DEVICE_UPDATES_AVAILABLE'
export const GET_DEVICE_UPDATES_AVAILABLE_SUCCESS =
  'GET_DEVICE_UPDATES_AVAILABLE_SUCCESS'
export const GET_DEVICE_UPDATES_AVAILABLE_ERROR =
  'GET_DEVICE_UPDATES_AVAILABLE_ERROR'

export const GET_DEVICE_DEPENDENCY_PACKAGES = 'GET_DEVICE_DEPENDENCY_PACKAGES'
export const GET_DEVICE_DEPENDENCY_PACKAGES_SUCCESS =
  'GET_DEVICE_DEPENDENCY_PACKAGES_SUCCESS'
export const GET_DEVICE_DEPENDENCY_PACKAGES_ERROR =
  'GET_DEVICE_DEPENDENCY_PACKAGES_ERROR'

export const GET_IP_CIDR = 'GET_IP_CIDR'
export const GET_IP_CIDR_SUCCESS = 'GET_IP_CIDR_SUCCESS'
export const GET_IP_CIDR_ERROR = 'GET_IP_CIDR_ERROR'

export const POST_IP_CIDR = 'POST_IP_CIDR'
export const POST_IP_CIDR_SUCCESS = 'POST_IP_CIDR_SUCCESS'
export const POST_IP_CIDR_ERROR = 'POST_IP_CIDR_ERROR'

export const PUT_IP_CIDR = 'PUT_IP_CIDR'
export const PUT_IP_CIDR_SUCCESS = 'PUT_IP_CIDR_SUCCESS'
export const PUT_IP_CIDR_ERROR = 'PUT_IP_CIDR_ERROR'

export const DELETE_IP_CIDR = 'DELETE_IP_CIDR'
export const DELETE_IP_CIDR_SUCCESS = 'DELETE_IP_CIDR_SUCCESS'
export const DELETE_IP_CIDR_ERROR = 'DELETE_IP_CIDR_ERROR'

export const CLEAR_IP_CIDR = 'CLEAR_IP_CIDR'

export const GET_DEVICE_GLOBAL_CONFIG = 'GET_DEVICE_GLOBAL_CONFIG'
export const GET_DEVICE_GLOBAL_CONFIG_SUCCESS =
  'GET_DEVICE_GLOBAL_CONFIG_SUCCESS'
export const GET_DEVICE_GLOBAL_CONFIG_ERROR = 'GET_DEVICE_GLOBAL_CONFIG_ERROR'

export const PUT_DEVICE_GLOBAL_CONFIG = 'PUT_DEVICE_GLOBAL_CONFIG'
export const PUT_DEVICE_GLOBAL_CONFIG_SUCCESS =
  'PUT_DEVICE_GLOBAL_CONFIG_SUCCESS'
export const PUT_DEVICE_GLOBAL_CONFIG_ERROR = 'PUT_DEVICE_GLOBAL_CONFIG_ERROR'

export const CLEAR_DEVICE_GLOBAL_CONFIG = 'CLEAR_DEVICE_GLOBAL_CONFIG'

export const UPDATE_SCHEDULE_DEVICE = 'UPDATE_SCHEDULE_DEVICE'
export const UPDATE_SCHEDULE_DEVICE_SUCCESS = 'UPDATE_SCHEDULE_DEVICE_SUCCESS'
export const UPDATE_SCHEDULE_DEVICE_ERROR = 'UPDATE_SCHEDULE_DEVICE_ERROR'
export const CLEAR_UPDATE_SCHEDULE_DEVICE_INFO =
  'CLEAR_UPDATE_SCHEDULE_DEVICE_INFO'

export const GET_SCHEDULED_DEVICE_IDS = 'GET_SCHEDULED_DEVICE_IDS'
export const GET_SCHEDULED_DEVICE_IDS_SUCCESS =
  'GET_SCHEDULED_DEVICE_IDS_SUCCESS'
export const GET_SCHEDULED_DEVICE_IDS_ERROR = 'GET_SCHEDULED_DEVICE_IDS_ERROR'

export const GET_DEVICE_CEC_SETTINGS = 'GET_DEVICE_CEC_SETTINGS'
export const GET_DEVICE_CEC_SETTINGS_SUCCESS = 'GET_DEVICE_CEC_SETTINGS_SUCCESS'
export const GET_DEVICE_CEC_SETTINGS_ERROR = 'GET_DEVICE_CEC_SETTINGS_ERROR'
export const CLEAR_DEVICE_CEC_SETTINGS = 'CLEAR_DEVICE_CEC_SETTINGS'

export const PUT_DEVICE_CEC_SETTINGS = 'PUT_DEVICE_CEC_SETTINGS'
export const PUT_DEVICE_CEC_SETTINGS_SUCCESS = 'PUT_DEVICE_CEC_SETTINGS_SUCCESS'
export const PUT_DEVICE_CEC_SETTINGS_ERROR = 'PUT_DEVICE_CEC_SETTINGS_ERROR'
export const CLEAR_DEVICE_CEC_SETTINGS_INFO = 'CLEAR_DEVICE_CEC_SETTINGS_INFO'

//device locations view

export const GET_DEVICE_LOCATIONS = 'GET_DEVICE_LOCATIONS'
export const GET_DEVICE_LOCATIONS_SUCCESS = 'GET_DEVICE_LOCATIONS_SUCCESS'
export const GET_DEVICE_LOCATIONS_ERROR = 'GET_DEVICE_LOCATIONS_ERROR'

export const POST_DEVICE_LOCATION = 'POST_DEVICE_LOCATION'
export const POST_DEVICE_LOCATION_SUCCESS = 'POST_DEVICE_LOCATION_SUCCESS'
export const POST_DEVICE_LOCATION_ERROR = 'POST_DEVICE_LOCATION_ERROR'

export const PUT_DEVICE_LOCATION = 'PUT_DEVICE_LOCATION'
export const PUT_DEVICE_LOCATION_SUCCESS = 'PUT_DEVICE_LOCATION_SUCCESS'
export const PUT_DEVICE_LOCATION_ERROR = 'PUT_DEVICE_LOCATION_ERROR'

export const DELETE_DEVICE_LOCATION = 'DELETE_DEVICE_LOCATION'
export const DELETE_DEVICE_LOCATION_SUCCESS = 'DELETE_DEVICE_LOCATION_SUCCESS'
export const DELETE_DEVICE_LOCATION_ERROR = 'DELETE_DEVICE_LOCATION_ERROR'

export const GET_DEVICES_BY_LOCATION = 'GET_DEVICES_BY_LOCATION'
export const GET_DEVICES_BY_LOCATION_SUCCESS = 'GET_DEVICES_BY_LOCATION_SUCCESS'
export const GET_DEVICES_BY_LOCATION_ERROR = 'GET_DEVICES_BY_LOCATION_ERROR'

export const CLEAR_DEVICE_LOCATION_INFO = 'CLEAR_DEVICE_LOCATION_INFO'

// device network configuration
export const GET_DEVICE_NETWORK_CONFIG = 'GET_DEVICE_NETWORK_CONFIG'
export const GET_DEVICE_NETWORK_CONFIG_SUCCESS =
  'GET_DEVICE_NETWORK_CONFIG_SUCCESS'
export const GET_DEVICE_NETWORK_CONFIG_ERROR = 'GET_DEVICE_NETWORK_CONFIG_ERROR'

export const PUT_DEVICE_NETWORK_CONFIG = 'PUT_DEVICE_NETWORK_CONFIG'
export const PUT_DEVICE_NETWORK_CONFIG_SUCCESS =
  'PUT_DEVICE_NETWORK_CONFIG_SUCCESS'
export const PUT_DEVICE_NETWORK_CONFIG_ERROR = 'PUT_DEVICE_NETWORK_CONFIG_ERROR'
export const CLEAR_PUT_DEVICE_NETWORK_CONFIG = 'CLEAR_PUT_DEVICE_NETWORK_CONFIG'

// Application versions
export const GET_APP_VERSIONS = 'GET_APP_VERSIONS'
export const GET_APP_VERSIONS_SUCCESS = 'GET_APP_VERSIONS_SUCCESS'
export const GET_APP_VERSIONS_ERROR = 'GET_APP_VERSIONS_ERROR'
export const GET_RELEASE_APP_VERSIONS = 'GET_RELEASE_APP_VERSIONS'
export const GET_RELEASE_APP_VERSIONS_SUCCESS =
  'GET_RELEASE_APP_VERSIONS_SUCCESS'
export const GET_RELEASE_APP_VERSIONS_ERROR = 'GET_RELEASE_APP_VERSIONS_ERROR'
export const GET_APP_VERSION = 'GET_APP_VERSION'
export const GET_APP_VERSION_SUCCESS = 'GET_APP_VERSION_SUCCESS'
export const GET_APP_VERSION_ERROR = 'GET_APP_VERSION_ERROR'
export const PUT_APP_VERSION = 'PUT_APP_VERSION'
export const PUT_APP_VERSION_SUCCESS = 'PUT_APP_VERSION_SUCCESS'
export const PUT_APP_VERSION_ERROR = 'PUT_APP_VERSION_ERROR'
export const POST_APP_VERSION = 'POST_APP_VERSION'
export const POST_APP_VERSION_SUCCESS = 'POST_APP_VERSION_SUCCESS'
export const POST_APP_VERSION_ERROR = 'POST_APP_VERSION_ERROR'
export const DELETE_APP_VERSION = 'DELETE_APP_VERSION'
export const DELETE_APP_VERSION_SUCCESS = 'DELETE_APP_VERSION_SUCCESS'
export const DELETE_APP_VERSION_ERROR = 'DELETE_APP_VERSION_ERROR'
export const DELETE_SELECTED_APP_VERSIONS = 'DELETE_SELECTED_APP_VERSIONS'
export const DELETE_SELECTED_APP_VERSIONS_SUCCESS =
  'DELETE_SELECTED_APP_VERSIONS_SUCCESS'
export const DELETE_SELECTED_APP_VERSIONS_ERROR =
  'DELETE_SELECTED_APP_VERSIONS_ERROR'
export const CLEAR_APP_VERSION = 'CLEAR_APP_VERSION'
export const POST_ASSIGN_APP_VERSION = 'POST_ASSIGN_APP_VERSION'
export const POST_ASSIGN_APP_VERSION_TO_DEVICE_TYPE =
  'POST_ASSIGN_APP_VERSION_TO_DEVICE_TYPE'
export const POST_ASSIGN_APP_VERSION_SUCCESS = 'POST_ASSIGN_APP_VERSION_SUCCESS'
export const POST_ASSIGN_APP_VERSION_ERROR = 'POST_ASSIGN_APP_VERSION_ERROR'
export const CLEAR_ASSIGN_APP_VERSION = 'CLEAR_ASSIGN_APP_VERSION'

export const GET_DEVICES_OFFLINE_NOTIFICATION =
  'GET_DEVICES_OFFLINE_NOTIFICATION'
export const GET_DEVICES_OFFLINE_NOTIFICATION_SUCCESS =
  'GET_DEVICES_OFFLINE_NOTIFICATION_SUCCESS'
export const GET_DEVICES_OFFLINE_NOTIFICATION_ERROR =
  'GET_DEVICES_OFFLINE_NOTIFICATION_ERROR'

export const GET_DEVICE_ITEM_OFFLINE_NOTIFICATION =
  'GET_DEVICE_ITEM_OFFLINE_NOTIFICATION'
export const GET_DEVICE_ITEM_OFFLINE_NOTIFICATION_SUCCESS =
  'GET_DEVICE_ITEM_OFFLINE_NOTIFICATION_SUCCESS'
export const GET_DEVICE_ITEM_OFFLINE_NOTIFICATION_ERROR =
  'GET_DEVICE_ITEM_OFFLINE_NOTIFICATION_ERROR'

export const POST_DEVICES_OFFLINE_NOTIFICATION =
  'POST_DEVICES_OFFLINE_NOTIFICATION'
export const POST_DEVICES_OFFLINE_NOTIFICATION_SUCCESS =
  'POST_DEVICES_OFFLINE_NOTIFICATION_SUCCESS'
export const POST_DEVICES_OFFLINE_NOTIFICATION_ERROR =
  'POST_DEVICES_OFFLINE_NOTIFICATION_ERROR'

export const CLEAR_DEVICES_OFFLINE_NOTIFICATION =
  'CLEAR_DEVICES_OFFLINE_NOTIFICATION'

//Report
export const GET_REPORT_ITEMS = 'GET_REPORT_ITEMS'
export const GET_REPORT_ITEMS_SUCCESS = 'GET_REPORT_ITEMS_SUCCESS'
export const GET_REPORT_ITEMS_ERROR = 'GET_REPORT_ITEMS_ERROR'
export const DELETE_SELECTED_REPORTS = 'DELETE_SELECTED_REPORTS'
export const DELETE_SELECTED_REPORTS_SUCCESS = 'DELETE_SELECTED_REPORTS_SUCCESS'
export const DELETE_SELECTED_REPORTS_ERROR = 'DELETE_SELECTED_REPORTS_ERROR'
export const CLEAR_REPORT_RESPONSE_INFO = 'CLEAR_REPORT_RESPONSE_INFO'
export const GET_REPORT_TABLES = 'GET_REPORT_TABLES'
export const GET_REPORT_TABLES_SUCCESS = 'GET_REPORT_TABLES_SUCCESS'
export const GET_REPORT_TABLES_ERROR = 'GET_REPORT_TABLES_ERROR'
export const POST_RUN_REPORT = 'POST_RUN_REPORT'
export const POST_RUN_REPORT_SUCCESS = 'POST_RUN_REPORT_SUCCESS'
export const POST_RUN_REPORT_ERROR = 'POST_RUN_REPORT_ERROR'
export const POST_REPORT = 'POST_REPORT'
export const POST_REPORT_SUCCESS = 'POST_REPORT_SUCCESS'
export const POST_REPORT_ERROR = 'POST_REPORT_ERROR'
export const PUT_REPORT = 'PUT_REPORT'
export const PUT_REPORT_SUCCESS = 'PUT_REPORT_SUCCESS'
export const PUT_REPORT_ERROR = 'PUT_REPORT_ERROR'
export const GET_REPORT_ITEM = 'GET_REPORT_ITEM'
export const GET_REPORT_ITEM_SUCCESS = 'GET_REPORT_ITEM_SUCCESS'
export const GET_REPORT_ITEM_ERROR = 'GET_REPORT_ITEM_ERROR'
export const GET_REPORT_SCHEDULE = 'GET_REPORT_SCHEDULE'
export const GET_REPORT_SCHEDULE_SUCCESS = 'GET_REPORT_SCHEDULE_SUCCESS'
export const GET_REPORT_SCHEDULE_ERROR = 'GET_REPORT_SCHEDULE_ERROR'
export const POST_REPORT_SCHEDULE = 'POST_REPORT_SCHEDULE'
export const POST_REPORT_SCHEDULE_SUCCESS = 'POST_REPORT_SCHEDULE_SUCCESS'
export const POST_REPORT_SCHEDULE_ERROR = 'POST_REPORT_SCHEDULE_ERROR'
export const GET_REPORT_TEMPLATE = 'GET_REPORT_TEMPLATE'
export const GET_REPORT_TEMPLATE_SUCCESS = 'GET_REPORT_TEMPLATE_SUCCESS'
export const GET_REPORT_TEMPLATE_ERROR = 'GET_REPORT_TEMPLATE_ERROR'
export const SET_REPORT_TEMPLATE_STATE = 'SET_REPORT_TEMPLATE_STATE'
export const SET_REPORT_ITEM_SORTING = 'SET_REPORT_ITEM_SORTING'
export const DELETE_REPORT_COLUMN = 'DELETE_REPORT_COLUMN'
export const GROUP_REPORT_BY_FIELD = 'GROUP_REPORT_BY_FIELD'
export const RESET_REPORT_FILTER = 'RESET_REPORT_FILTER'
export const UPDATE_REPORT_FILTER = 'UPDATE_REPORT_FILTER'
export const GET_TEMPLATE_WITH_REPORT_DATA = 'GET_TEMPLATE_WITH_REPORT_DATA'
export const GET_REPORT_LINK = 'GET_REPORT_LINK'
export const GET_REPORT_LINK_SUCCESS = 'GET_REPORT_LINK_SUCCESS'
export const GET_REPORT_LINK_ERROR = 'GET_REPORT_LINK_ERROR'
export const CLEAR_REPORT_LINK = 'CLEAR_REPORT_LINK'
export const GET_TEMPLATE_WITH_REPORT_DATA_SUCCESS =
  'GET_TEMPLATE_WITH_REPORT_DATA_SUCCESS'
export const GET_TEMPLATE_WITH_REPORT_DATA_ERROR =
  'GET_TEMPLATE_WITH_REPORT_DATA_ERROR'
export const GET_COUNT_API_CALL = 'GET_COUNT_API_CALL'
export const GET_COUNT_API_CALL_SUCCESS = 'GET_COUNT_API_CALL_SUCCESS'
export const GET_COUNT_API_CALL_ERROR = 'GET_COUNT_API_CALL_ERROR'
export const GET_LAST_FAILED_API_CALL = 'GET_LAST_FAILED_API_CALL'
export const GET_LAST_FAILED_API_CALL_SUCCESS =
  'GET_LAST_FAILED_API_CALL_SUCCESS'
export const GET_LAST_FAILED_API_CALL_ERROR = 'GET_LAST_FAILED_API_CALL_ERROR'
export const SET_SELECTED_DATATABLE = 'SET_SELECTED_DATATABLE'
export const GET_COUNT_CLIENT_API_CALL = 'GET_COUNT_CLIENT_API_CALL'
export const GET_COUNT_CLIENT_API_CALL_SUCCESS =
  'GET_COUNT_CLIENT_API_CALL_SUCCESS'
export const GET_COUNT_CLIENT_API_CALL_ERROR = 'GET_COUNT_CLIENT_API_CALL_ERROR'
export const GET_COUNT_DEVICE_API_CALL = 'GET_COUNT_DEVICE_API_CALL'
export const GET_COUNT_DEVICE_API_CALL_SUCCESS =
  'GET_COUNT_DEVICE_API_CALL_SUCCESS'
export const GET_COUNT_DEVICE_API_CALL_ERROR = 'GET_COUNT_DEVICE_API_CALL_ERROR'
export const GET_TOP_CLIENTS_API_CALL = 'GET_TOP_CLIENTS_API_CALL'
export const GET_TOP_CLIENTS_API_CALL_SUCCESS =
  'GET_TOP_CLIENTS_API_CALL_SUCCESS'
export const GET_TOP_CLIENTS_API_CALL_ERROR = 'GET_TOP_CLIENTS_API_CALL_ERROR'
export const GET_TOP_DEVICES_API_CALL = 'GET_TOP_DEVICES_API_CALL'
export const GET_TOP_DEVICES_API_CALL_SUCCESS =
  'GET_TOP_DEVICES_API_CALL_SUCCESS'
export const GET_TOP_DEVICES_API_CALL_ERROR = 'GET_TOP_DEVICES_API_CALL_ERROR'

export const GET_EXPOSURE_COUNT = 'GET_EXPOSURE_COUNT'
export const GET_EXPOSURE_COUNT_SUCCESS = 'GET_EXPOSURE_COUNT_SUCCESS'
export const GET_EXPOSURE_COUNT_ERROR = 'GET_EXPOSURE_COUNT_ERROR'
export const GET_EXPOSURE_TIME = 'GET_EXPOSURE_TIME'
export const GET_EXPOSURE_TIME_SUCCESS = 'GET_EXPOSURE_TIME_SUCCESS'
export const GET_EXPOSURE_TIME_ERROR = 'GET_EXPOSURE_TIME_ERROR'
export const GET_VERBOSE_DATA = 'GET_VERBOSE_DATA'
export const GET_VERBOSE_DATA_SUCCESS = 'GET_VERBOSE_DATA_SUCCESS'
export const GET_VERBOSE_DATA_ERROR = 'GET_VERBOSE_DATA_ERROR'

export const REQUEST_CAP_ALERT_DEVICES = 'REQUEST_CAP_ALERT_DEVICES'
export const CAP_ALERT_DEVICES_SUCCESS = 'CAP_ALERT_DEVICES_SUCCESS'
export const CAP_ALERT_DEVICES_ERROR = 'CAP_ALERT_DEVICES_ERROR'
export const REQUEST_ASSOCIATE_CAP_ALERT = 'REQUEST_ASSOCIATE_CAP_ALERT'
export const ASSOCIATE_CAP_ALERT_SUCCESS = 'ASSOCIATE_CAP_ALERT_SUCCESS'
export const ASSOCIATE_CAP_ALERT_ERROR = 'ASSOCIATE_CAP_ALERT_ERROR'
export const RESET_ASSOCIATE_CAP_ALERT = 'RESET_ASSOCIATE_CAP_ALERT'

//Client Actions
export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR'

export const GET_CLIENT_BY_ID = 'GET_CLIENT_BY_ID'
export const GET_CLIENT_BY_ID_SUCCESS = 'GET_CLIENT_BY_ID_SUCCESS'
export const GET_CLIENT_BY_ID_ERROR = 'GET_CLIENT_BY_ID_ERROR'

export const POST_CLIENT = 'POST_CLIENT'
export const POST_CLIENT_SUCCESS = 'POST_CLIENT_SUCCESS'
export const POST_CLIENT_ERROR = 'POST_CLIENT_ERROR'

export const PUT_CLIENT = 'PUT_CLIENT'
export const PUT_CLIENT_SUCCESS = 'PUT_CLIENT_SUCCESS'
export const PUT_CLIENT_ERROR = 'PUT_CLIENT_ERROR'

export const EDIT_CLIENT_FEATURE_SETTINGS = 'EDIT_CLIENT_FEATURE_SETTINGS'
export const EDIT_CLIENT_FEATURE_SETTINGS_SUCCESS =
  'EDIT_CLIENT_FEATURE_SETTINGS_SUCCESS'
export const EDIT_CLIENT_FEATURE_SETTINGS_ERROR =
  'EDIT_CLIENT_FEATURE_SETTINGS_ERROR'

export const CLEAR_CLIENT_RESPONSE_INFO = 'CLEAR_CLIENT_RESPONSE_INFO'
export const CLEAR_CLIENT_USER_ITEM_INFO = 'CLEAR_CLIENT_USER_ITEM_INFO'

export const GET_CLIENT_GROUP_ITEMS = 'GET_CLIENT_GROUP_ITEMS'
export const GET_CLIENT_GROUP_ITEMS_SUCCESS = 'GET_CLIENT_GROUP_ITEMS_SUCCESS'
export const GET_CLIENT_GROUP_ITEMS_ERROR = 'GET_CLIENT_GROUP_ITEMS_ERROR'

export const POST_CLIENT_GROUP_ITEM = 'POST_CLIENT_GROUP_ITEM'
export const POST_CLIENT_GROUP_ITEM_SUCCESS = 'POST_CLIENT_GROUP_ITEM_SUCCESS'
export const POST_CLIENT_GROUP_ITEM_ERROR = 'POST_CLIENT_GROUP_ITEM_ERROR'

export const DELETE_CLIENT_GROUP_ITEM = 'DELETE_CLIENT_GROUP_ITEM'
export const DELETE_CLIENT_GROUP_ITEM_SUCCESS =
  'DELETE_CLIENT_GROUP_ITEM_SUCCESS'
export const DELETE_CLIENT_GROUP_ITEM_ERROR = 'DELETE_CLIENT_GROUP_ITEM_ERROR'

export const CLEAR_CLIENT_GROUP_ITEMS_RESPONSE_INFO =
  'CLEAR_CLIENT_GROUP_ITEMS_RESPONSE_INFO'
export const CLEAR_GET_CLIENTS_GROUP_ITEMS_INFO =
  'CLEAR_GET_CLIENTS_GROUP_ITEMS_INFO'
export const GET_CLIENT_NOTES = 'GET_CLIENT_NOTES'
export const GET_CLIENT_NOTES_SUCCESS = 'GET_CLIENT_NOTES_SUCCESS'
export const GET_CLIENT_NOTES_ERROR = 'GET_CLIENT_NOTES_ERROR'
export const POST_CLIENT_NOTE = 'POST_CLIENT_NOTE'
export const POST_CLIENT_NOTE_SUCCESS = 'POST_CLIENT_NOTE_SUCCESS'
export const POST_CLIENT_NOTE_ERROR = 'POST_CLIENT_NOTE_ERROR'
export const CLEAR_GET_CLIENT_NOTE_INFO = 'CLEAR_GET_CLIENT_NOTE_INFO'
export const CLEAR_POST_CLIENT_NOTE_INFO = 'CLEAR_POST_CLIENT_NOTE_INFO'
export const GET_CLIENT_LICENSES = 'GET_CLIENT_LICENSES'
export const GET_CLIENT_LICENSES_SUCCESS = 'GET_CLIENT_LICENSES_SUCCESS'
export const GET_CLIENT_LICENSES_ERROR = 'GET_CLIENT_LICENSES_ERROR'
export const POST_CLIENT_LICENSE = 'POST_CLIENT_LICENSE'
export const POST_CLIENT_LICENSE_SUCCESS = 'POST_CLIENT_LICENSE_SUCCESS'
export const POST_CLIENT_LICENSE_ERROR = 'POST_CLIENT_LICENSE_ERROR'
export const PUT_CLIENT_LICENSE = 'PUT_CLIENT_LICENSE'
export const PUT_CLIENT_LICENSE_SUCCESS = 'PUT_CLIENT_LICENSE_SUCCESS'
export const PUT_CLIENT_LICENSE_ERROR = 'PUT_CLIENT_LICENSE_ERROR'
export const DELETE_CLIENT_LICENSE = 'DELETE_CLIENT_LICENSE'
export const DELETE_CLIENT_LICENSE_SUCCESS = 'DELETE_CLIENT_LICENSE_SUCCESS'
export const DELETE_CLIENT_LICENSE_ERROR = 'DELETE_CLIENT_LICENSE_ERROR'
export const CLEAR_CLIENT_LICENSE_ITEMS_INFO = 'CLEAR_CLIENT_LICENSE_ITEMS_INFO'
export const CLEAR_CLIENT_LICENSE_RESPONSE_INFO =
  'CLEAR_CLIENT_LICENSE_RESPONSE_INFO'
export const PUT_CLIENT_BILLING = 'PUT_CLIENT_BILLING'
export const PUT_CLIENT_BILLING_SUCCESS = 'PUT_CLIENT_BILLING_SUCCESS'
export const PUT_CLIENT_BILLING_ERROR = 'PUT_CLIENT_BILLING_ERROR'

//Reseller Clients Actions

export const GET_RESELLER_CLIENTS = 'GET_RESELLER_CLIENTS'
export const GET_RESELLER_CLIENTS_SUCCESS = 'GET_RESELLER_CLIENTS_SUCCESS'
export const GET_RESELLER_CLIENTS_ERROR = 'GET_RESELLER_CLIENTS_ERROR'

export const GET_RESELLER_CLIENT_USERS = 'GET_RESELLER_CLIENT_USERS'
export const GET_RESELLER_CLIENT_USERS_SUCCESS =
  'GET_RESELLER_CLIENT_USERS_SUCCESS'
export const GET_RESELLER_CLIENT_USERS_ERROR = 'GET_RESELLER_CLIENT_USERS_ERROR'

export const IMPERSONATE_RESELLER_CLIENT = 'IMPERSONATE_RESELLER_CLIENT'

export const GET_ASSOCIATED_CLIENTS = 'GET_ASSOCIATED_CLIENTS'
export const GET_ASSOCIATED_CLIENTS_SUCCESS = 'GET_ASSOCIATED_CLIENTS_SUCCESS'
export const GET_ASSOCIATED_CLIENTS_ERROR = 'GET_ASSOCIATED_CLIENTS_ERROR'

export const POST_ASSOCIATED_CLIENTS = 'POST_ASSOCIATED_CLIENTS'
export const POST_ASSOCIATED_CLIENTS_SUCCESS = 'POST_ASSOCIATED_CLIENTS_SUCCESS'
export const POST_ASSOCIATED_CLIENTS_ERROR = 'POST_ASSOCIATED_CLIENTS_ERROR'

export const CLEAR_ASSOCIATED_CLIENTS_RESPONSE =
  'CLEAR_ASSOCIATED_CLIENTS_RESPONSE'

//Users Actions
export const GET_USERS_ITEMS = 'GET_USERS_ITEMS'
export const GET_USERS_ITEMS_SUCCESS = 'GET_USERS_ITEMS_SUCCESS'
export const GET_USERS_ITEMS_ERROR = 'GET_USERS_ITEMS_ERROR'
export const CLEAR_USERS_FETCH_PARAMS = 'CLEAR_USERS_FETCH_PARAMS'

export const POST_USERS_ITEM = 'POST_USERS_ITEM'
export const POST_USERS_ITEM_SUCCESS = 'POST_USERS_ITEM_SUCCESS'
export const POST_USERS_ITEM_ERROR = 'POST_USERS_ITEM_ERROR'

export const DELETE_USERS_ITEM = 'DELETE_USERS_ITEM'
export const DELETE_USERS_ITEM_SUCCESS = 'DELETE_USERS_ITEM_SUCCESS'
export const DELETE_USERS_ITEM_ERROR = 'DELETE_USERS_ITEM_ERROR'

export const DELETE_SELECTED_USERS = 'DELETE_SELECTED_USERS'
export const DELETE_SELECTED_USERS_SUCCESS = 'DELETE_SELECTED_USERS_SUCCESS'
export const DELETE_SELECTED_USERS_ERROR = 'DELETE_SELECTED_USERS_ERROR'

export const GET_USERS_ITEM = 'GET_USERS_ITEM'
export const GET_USERS_ITEM_SUCCESS = 'GET_USERS_ITEM_SUCCESS'
export const GET_USERS_ITEM_ERROR = 'GET_USERS_ITEM_ERROR'

export const PUT_USERS_ITEM = 'PUT_USERS_ITEM'
export const PUT_USERS_ITEM_SUCCESS = 'PUT_USERS_ITEM_SUCCESS'
export const PUT_USERS_ITEM_ERROR = 'PUT_USERS_ITEM_ERROR'

export const GET_UNGROUPED_USERS = 'GET_UNGROUPED_USERS'
export const GET_UNGROUPED_USERS_SUCCESS = 'GET_UNGROUPED_USERS_SUCCESS'
export const GET_UNGROUPED_USERS_ERROR = 'GET_UNGROUPED_USERS_ERROR'

export const GET_USERS_GROUP_ITEMS = 'GET_USERS_GROUP_ITEMS'
export const GET_USERS_GROUP_ITEMS_SUCCESS = 'GET_USERS_GROUP_ITEMS_SUCCESS'
export const GET_USERS_GROUP_ITEMS_ERROR = 'GET_USERS_GROUP_ITEMS_ERROR'

export const POST_USERS_GROUP_ITEM = 'POST_USERS_GROUP_ITEM'
export const POST_USERS_GROUP_ITEM_SUCCESS = 'POST_USERS_GROUP_ITEM_SUCCESS'
export const POST_USERS_GROUP_ITEM_ERROR = 'POST_USERS_GROUP_ITEM_ERROR'

export const DELETE_USERS_GROUP_ITEM = 'DELETE_USERS_GROUP_ITEM'
export const DELETE_USERS_GROUP_ITEM_SUCCESS = 'DELETE_USERS_GROUP_ITEM_SUCCESS'
export const DELETE_USERS_GROUP_ITEM_ERROR = 'DELETE_USERS_GROUP_ITEM_ERROR'
export const CLEAR_GET_USERS_GROUP_ITEMS_INFO =
  'CLEAR_GET_USERS_GROUP_ITEMS_INFO'
export const CLEAR_USERS_GROUP_ITEMS_RESPONSE_INFO =
  'CLEAR_USERS_GROUP_ITEMS_RESPONSE_INFO'

export const GET_USERS_PERMISSION = 'GET_USERS_PERMISSION'
export const GET_USERS_PERMISSION_SUCCESS = 'GET_USERS_PERMISSION_SUCCESS'
export const GET_USERS_PERMISSION_ERROR = 'GET_USERS_PERMISSION_ERROR'

export const PUT_USERS_PERMISSION = 'PUT_USERS_PERMISSION'
export const PUT_USERS_PERMISSION_SUCCESS = 'PUT_USERS_PERMISSION_SUCCESS'
export const PUT_USERS_PERMISSION_ERROR = 'PUT_USERS_PERMISSION_ERROR'

export const GET_USERS_GROUP_PERMISSION = 'GET_USERS_GROUP_PERMISSION'
export const GET_USERS_GROUP_PERMISSION_SUCCESS =
  'GET_USERS_GROUP_PERMISSION_SUCCESS'
export const GET_USERS_GROUP_PERMISSION_ERROR =
  'GET_USERS_GROUP_PERMISSION_ERROR'

export const PUT_USERS_GROUP_PERMISSION = 'PUT_USERS_GROUP_PERMISSION'
export const PUT_USERS_GROUP_PERMISSION_SUCCESS =
  'PUT_USERS_GROUP_PERMISSION_SUCCESS'
export const PUT_USERS_GROUP_PERMISSION_ERROR =
  'PUT_USERS_GROUP_PERMISSION_ERROR'

export const CLEAR_USERS_RESPONSE_INFO = 'CLEAR_USERS_RESPONSE_INFO'
export const CLEAR_GET_USERS_PERMISSION_INFO = 'CLEAR_GET_USERS_PERMISSION_INFO'
export const CLEAR_GET_USERS_GROUP_PERMISSION_INFO =
  'CLEAR_GET_USERS_GROUP_PERMISSION_INFO'
export const CLEAR_GET_USERS_ITEM_INFO = 'CLEAR_GET_USERS_ITEM_INFO'
//Dashboard Actions
export const GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO'
export const GET_DASHBOARD_INFO_SUCCESS = 'GET_DASHBOARD_INFO_SUCCESS'
export const GET_DASHBOARD_INFO_ERROR = 'GET_DASHBOARD_INFO_ERROR'

export const PUT_DASHBOARD_INFO = 'PUT_DASHBOARD_INFO'
export const PUT_DASHBOARD_INFO_SUCCESS = 'PUT_DASHBOARD_INFO_SUCCESS'
export const PUT_DASHBOARD_INFO_ERROR = 'PUT_DASHBOARD_INFO_ERROR'

export const POST_FEEDBACK = 'PUT_FEEDBACK'
export const POST_FEEDBACK_SUCCESS = 'PUT_FEEDBACK_SUCCESS'
export const POST_FEEDBACK_ERROR = 'PUT_FEEDBACK_ERROR'
export const CLEAR_POST_FEEDBACK = 'CLEAR_PUT_FEEDBACK'

export const GET_NEWS_INFO_FOR_DASHBOARD = 'GET_NEWS_INFO_FOR_DASHBOARD'
export const GET_NEWS_INFO_FOR_DASHBOARD_SUCCESS =
  'GET_NEWS_INFO_FOR_DASHBOARD_SUCCESS'
export const GET_NEWS_INFO_FOR_DASHBOARD_ERROR =
  'GET_NEWS_INFO_FOR_DASHBOARD_ERROR'

export const GET_SUPPORT_INFO_FOR_DASHBOARD = 'GET_SUPPORT_INFO_FOR_DASHBOARD'
export const GET_SUPPORT_INFO_FOR_DASHBOARD_SUCCESS =
  'GET_SUPPORT_INFO_FOR_DASHBOARD_SUCCESS'
export const GET_SUPPORT_INFO_FOR_DASHBOARD_ERROR =
  'GET_SUPPORT_INFO_FOR_DASHBOARD_ERROR'

export const GET_DASHBOARD_DEVICE_LOCATIONS = 'GET_DASHBOARD_DEVICE_LOCATIONS'
export const GET_DASHBOARD_DEVICE_LOCATIONS_SUCCESS =
  'GET_DASHBOARD_DEVICE_LOCATIONS_SUCCESS'
export const GET_DASHBOARD_DEVICE_LOCATIONS_ERROR =
  'GET_DASHBOARD_DEVICE_LOCATIONS_ERROR'

export const GET_WEATHER_INFO_FOR_DASHBOARD = 'GET_WEATHER_INFO_FOR_DASHBOARD'
export const GET_WEATHER_INFO_FOR_DASHBOARD_SUCCESS =
  'GET_WEATHER_INFO_FOR_DASHBOARD_SUCCESS'
export const GET_WEATHER_INFO_FOR_DASHBOARD_ERROR =
  'GET_WEATHER_INFO_FOR_DASHBOARD_ERROR'

export const GET_DASHBOARD_TODAY_SCHEDULES = 'GET_DASHBOARD_TODAY_SCHEDULES'
export const GET_DASHBOARD_TODAY_SCHEDULES_SUCCESS =
  'GET_DASHBOARD_TODAY_SCHEDULES_SUCCESS'
export const GET_DASHBOARD_TODAY_SCHEDULES_ERROR =
  'GET_DASHBOARD_TODAY_SCHEDULES_ERROR'
export const CLEAR_DASHBOARD_TODAY_SCHEDULES = 'CLEAR_DASHBOARD_TODAY_SCHEDULES'

export const GET_GROUPS_BY_ENTITY = 'GET_GROUPS_BY_ENTITY'
export const GET_GROUPS_BY_ENTITY_SUCCESS = 'GET_GROUPS_BY_ENTITY_SUCCESS'
export const GET_GROUPS_BY_ENTITY_ERROR = 'GET_GROUPS_BY_ENTITY_ERROR'

export const POST_GROUP = 'POST_DEVICE_GROUP'
export const POST_GROUP_SUCCESS = 'POST_DEVICE_GROUP_SUCCESS'
export const POST_GROUP_ERROR = 'POST_DEVICE_GROUP_ERROR'

export const CLEAR_POST_GROUP_INFO = 'CLEAR_POST_DEVICE_GROUP_INFO'
export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR'

export const CLEAR_DELETE_GROUP_INFO = 'CLEAR_DELETE_GROUP_INFO'

export const PUT_GROUP = 'PUT_GROUP'
export const PUT_GROUP_SUCCESS = 'PUT_GROUP_SUCCESS'
export const PUT_GROUP_ERROR = 'PUT_GROUP_ERROR'

export const CLEAR_PUT_GROUP_INFO = 'CLEAR_PUT_GROUP_INFO'

export const CLEAR_UPDATE_GROUP_INFO = 'CLEAR_UPDATE_GROUP_INFO'

export const GET_BACKGROUND_IMAGES = 'GET_BACKGROUND_IMAGES'
export const GET_BACKGROUND_IMAGES_SUCCESS = 'GET_BACKGROUND_IMAGES_SUCCESS'
export const GET_BACKGROUND_IMAGES_ERROR = 'GET_BACKGROUND_IMAGES_ERROR'
export const GET_PEXEL_BACKGROUND_IMAGES = 'GET_PEXEL_BACKGROUND_IMAGES'
export const GET_PEXEL_BACKGROUND_IMAGES_SUCCESS =
  'GET_PEXEL_BACKGROUND_IMAGES_SUCCESS'
export const GET_PEXEL_BACKGROUND_IMAGES_ERROR =
  'GET_PEXEL_BACKGROUND_IMAGES_ERROR'

export const GET_PATTERNS = 'GET_PATTERNS'
export const GET_PATTERNS_SUCCESS = 'GET_PATTERNS_SUCCESS'
export const GET_PATTERNS_ERROR = 'GET_PATTERNS_ERROR'

export const GET_SHAPES = 'GET_SHAPES'
export const GET_SHAPES_SUCCESS = 'GET_SHAPES_SUCCESS'
export const GET_SHAPES_ERROR = 'GET_SHAPES_ERROR'

export const GET_OBJECTS = 'GET_OBJECTS'
export const GET_OBJECTS_SUCCESS = 'GET_OBJECTS_SUCCESS'
export const GET_OBJECTS_ERROR = 'GET_OBJECTS_ERROR'

export const GET_ICONS = 'GET_ICONS'
export const GET_ICONS_SUCCESS = 'GET_ICONS_SUCCESS'
export const GET_ICONS_ERROR = 'GET_ICONS_ERROR'
export const CLEAR_ICONS = 'CLEAR_ICONS'

export const GET_ICONS_GROUP = 'GET_ICONS_GROUP'
export const GET_ICONS_GROUP_SUCCESS = 'GET_ICONS_GROUP_SUCCESS'
export const GET_ICONS_GROUP_ERROR = 'GET_ICONS_GROUP_ERROR'

export const GET_DESIGNS = 'GET_DESIGNS'
export const GET_DESIGNS_SUCCESS = 'GET_DESIGNS_SUCCESS'
export const GET_DESIGNS_ERROR = 'GET_DESIGNS_ERROR'

export const GET_SHARED_DESIGNS = 'GET_SHARED_DESIGNS'
export const GET_SHARED_DESIGNS_SUCCESS = 'GET_SHARED_DESIGNS_SUCCESS'
export const GET_SHARED_DESIGNS_ERROR = 'GET_SHARED_DESIGNS_ERROR'

export const GET_STOCK_DESIGNS = 'GET_STOCK_DESIGNS'
export const GET_STOCK_DESIGNS_SUCCESS = 'GET_STOCK_DESIGNS_SUCCESS'
export const GET_STOCK_DESIGNS_ERROR = 'GET_STOCK_DESIGNS_ERROR'

export const GET_FAVORITE_DESIGNS = 'GET_FAVORITE_DESIGNS'
export const GET_FAVORITE_SUCCESS = 'GET_FAVORITE_SUCCESS'
export const GET_FAVORITE_ERROR = 'GET_FAVORITE_ERROR'

export const GET_DESIGN_GALLERY_FONTS = 'GET_DESIGN_GALLERY_FONTS'
export const GET_DESIGN_GALLERY_FONTS_SUCCESS =
  'GET_DESIGN_GALLERY_FONTS_SUCCESS'
export const GET_DESIGN_GALLERY_FONTS_ERROR = 'GET_DESIGN_GALLERY_FONTS_ERROR'

export const GET_EMOJIS = 'GET_EMOJIS'
export const GET_EMOJIS_SUCCESS = 'GET_EMOJIS_SUCCESS'
export const GET_EMOJIS_ERROR = 'GET_EMOJIS_ERROR'

export const SET_OPEN_LEFT_SIDEBAR = 'SET_OPEN_LEFT_SIDEBAR'
export const SET_OPEN_RIGHT_SIDEBAR = 'SET_OPEN_RIGHT_SIDEBAR'
export const SET_OPEN_BOTTOM_SIDEBAR = 'SET_OPEN_BOTTOM_SIDEBAR'

export const DG_OBJECT_CHANGED = 'DG_OBJECT_CHANGED'
export const CLEAR_DG_SIDEBAR = 'CLEAR_DG_SIDEBAR'

export const SET_SELECTED_BG = 'SET_SELECTED_BG'
export const SET_SELECTED_BG_SUCCESS = 'SET_SELECTED_BG_SUCCESS'
export const SET_SELECTED_DESIGN = 'SET_SELECTED_DESIGN'
export const SET_MISSED_FONTS = 'SET_MISSED_FONTS'

export const REMOVE_SELECTED_BG_SUCCESS = 'REMOVE_SELECTED_BG_SUCCESS'

export const GET_STOCK_IMAGES = 'GET_STOCK_IMAGES'
export const GET_STOCK_IMAGES_SUCCESS = 'GET_STOCK_IMAGES_SUCCESS'
export const GET_STOCK_IMAGES_ERROR = 'GET_STOCK_IMAGES_ERROR'

export const GET_LIBRARY_IMAGES = 'GET_LIBRARY_IMAGES'
export const GET_LIBRARY_IMAGES_SUCCESS = 'GET_LIBRARY_IMAGES_SUCCESS'

export const GET_ROYALTY_IMAGES = 'GET_ROYALTY_IMAGES'
export const GET_ROYALTY_IMAGES_SUCCESS = 'GET_ROYALTY_IMAGES_SUCCESS'
export const GET_ROYALTY_IMAGES_ERROR = 'GET_ROYALTY_IMAGES_ERROR'

export const GET_GROUP_PERMISSION = 'GET_GROUP_PERMISSION'
export const GET_GROUP_PERMISSION_SUCCESS = 'GET_GROUP_PERMISSION_SUCCESS'
export const GET_GROUP_PERMISSION_ERROR = 'GET_GROUP_PERMISSION_ERROR'
export const CLEAR_GET_GROUP_PERMISSION_INFO = 'CLEAR_GET_GROUP_PERMISSION_INFO'
export const PUT_GROUP_PERMISSION = 'PUT_GROUP_PERMISSION'
export const PUT_GROUP_PERMISSION_SUCCESS = 'PUT_GROUP_PERMISSION_SUCCESS'
export const PUT_GROUP_PERMISSION_ERROR = 'PUT_GROUP_PERMISSION_ERROR'
export const CLEAR_PUT_GROUP_PERMISSION_INFO = 'CLEAR_PUT_GROUP_PERMISSION_INFO'
export const GET_DEFAULT_GROUP = 'GET_DEFAULT_GROUP'
export const GET_DEFAULT_GROUP_SUCCESS = 'GET_DEFAULT_GROUP_SUCCESS'
export const GET_DEFAULT_GROUP_ERROR = 'GET_DEFAULT_GROUP_ERROR'
export const CLEAR_DEFAULT_GROUPS = 'CLEAR_DEFAULT_GROUPS'

export const GET_LOCATION = 'GET_LOCATION'
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS'
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR'
export const GET_CITY = 'GET_CITY'
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'
export const GET_CITY_ERROR = 'GET_CITY_ERROR'
export const CLEAR_MEDIA_CATEGORY_CONFIG = 'CLEAR_MEDIA_CATEGORY_CONFIG'
//Config Actions
export const GET_CONFIG_ORG_ROLE = 'GET_CONFIG_ORG_ROLE'
export const GET_CONFIG_ORG_ROLE_SUCCESS = 'GET_CONFIG_ORG_ROLE_SUCCESS'
export const GET_CONFIG_ORG_ROLE_ERROR = 'GET_CONFIG_ORG_ROLE_ERROR'
export const CLEAR_GET_ORG_CONFIG_ORG_ROLE_INFO =
  'CLEAR_GET_ORG_CONFIG_ORG_ROLE_INFO'
export const GET_CONFIG_ENTERPRISE_ROLE = 'GET_CONFIG_ENTERPRISE_ROLE'
export const GET_CONFIG_ENTERPRISE_ROLE_SUCCESS =
  'GET_CONFIG_ENTERPRISE_ROLE_SUCCESS'
export const GET_CONFIG_ENTERPRISE_ROLE_ERROR =
  'GET_CONFIG_ENTERPRISE_ROLE_ERROR'

export const GET_CONFIG_RESELLER_ROLE = 'GET_CONFIG_RESELLER_ROLE'
export const GET_CONFIG_RESELLER_ROLE_SUCCESS =
  'GET_CONFIG_RESELLER_ROLE_SUCCESS'
export const GET_CONFIG_RESELLER_ROLE_ERROR = 'GET_CONFIG_RESELLER_ROLE_ERROR'

export const GET_OAUTH2_TOKENS = 'GET_OAUTH2_TOKENS'
export const GET_OAUTH2_TOKENS_SUCCESS = 'GET_OAUTH2_TOKENS_SUCCESS'
export const GET_OAUTH2_TOKENS_ERROR = 'GET_OAUTH2_TOKENS_ERROR'

export const POST_FACEBOOK_ACCOUNT = 'POST_FACEBOOK_ACCOUNT'
export const POST_FACEBOOK_ACCOUNT_SUCCESS = 'POST_FACEBOOK_ACCOUNT_SUCCESS'
export const POST_FACEBOOK_ACCOUNT_ERROR = 'POST_FACEBOOK_ACCOUNT_ERROR'

export const POST_INSTAGRAM_ACCOUNT = 'POST_INSTAGRAM_ACCOUNT'
export const POST_INSTAGRAM_ACCOUNT_SUCCESS = 'POST_INSTAGRAM_ACCOUNT_SUCCESS'
export const POST_INSTAGRAM_ACCOUNT_ERROR = 'POST_INSTAGRAM_ACCOUNT_ERROR'

export const POST_GOOGLE_DOCS_ACCOUNT = 'POST_GOOGLE_DOCS_ACCOUNT'
export const POST_GOOGLE_DOCS_ACCOUNT_SUCCESS =
  'POST_GOOGLE_DOCS_ACCOUNT_SUCCESS'
export const POST_GOOGLE_DOCS_ACCOUNT_ERROR = 'POST_GOOGLE_DOCS_ACCOUNT_ERROR'

export const GET_GOOGLE_DOCUMENTS = 'GET_GOOGLE_DOCUMENTS'
export const GET_GOOGLE_DOCUMENTS_SUCCESS = 'GET_GOOGLE_DOCUMENTS_SUCCESS'
export const GET_GOOGLE_DOCUMENTS_ERROR = 'GET_GOOGLE_DOCUMENTS_ERROR'
export const CLEAR_GOOGLE_DOCUMENTS = 'CLEAR_GOOGLE_DOCUMENTS'

export const PUT_OAUTH2_TOKEN = 'PUT_OAUTH2_TOKEN'
export const PUT_OAUTH2_TOKEN_SUCCESS = 'PUT_OAUTH2_TOKEN_SUCCESS'
export const PUT_OAUTH2_TOKEN_ERROR = 'PUT_OAUTH2_TOKENS_ERROR'

export const DELETE_OAUTH2_TOKEN = 'DELETE_OAUTH2_TOKEN'
export const DELETE_OAUTH2_TOKEN_SUCCESS = 'DELETE_OAUTH2_TOKEN_SUCCESS'
export const DELETE_OAUTH2_TOKEN_ERROR = 'DELETE_OAUTH2_TOKEN_ERROR'

export const CLEAR_OAUTH2_TOKEN_RESPONSE_INFO =
  'CLEAR_OAUTH2_TOKEN_RESPONSE_INFO'

export const POST_GOOGLE_CALENDAR_ACCOUNT = 'POST_GOOGLE_CALENDAR_ACCOUNT'
export const POST_GOOGLE_CALENDAR_ACCOUNT_SUCCESS =
  'POST_GOOGLE_CALENDAR_ACCOUNT_SUCCESS'
export const POST_GOOGLE_CALENDAR_ACCOUNT_ERROR =
  'POST_GOOGLE_CALENDAR_ACCOUNT_ERROR'

export const POST_MICROSOFT_OUTLOOK_ACCOUNT = 'POST_MICROSOFT_OUTLOOK_ACCOUNT'
export const POST_MICROSOFT_OUTLOOK_ACCOUNT_SUCCESS =
  'POST_MICROSOFT_OUTLOOK_ACCOUNT_SUCCESS'
export const POST_MICROSOFT_OUTLOOK_ACCOUNT_ERROR =
  'POST_MICROSOFT_OUTLOOK_ACCOUNT_ERROR'

export const GET_REMOTE_CALENDARS = 'GET_REMOTE_CALENDARS'
export const GET_REMOTE_CALENDARS_SUCCESS = 'GET_REMOTE_CALENDARS_SUCCESS'
export const GET_REMOTE_CALENDARS_ERROR = 'GET_REMOTE_CALENDARS_ERROR'

export const GET_APP_CONFIG = 'GET_APP_CONFIG'
export const GET_APP_CONFIG_SUCCESS = 'GET_APP_CONFIG_SUCCESS'
export const GET_APP_CONFIG_ERROR = 'GET_APP_CONFIG_ERROR'

export const CONTENT_APPROVE = 'CONTENT_APPROVE'
export const CONTENT_APPROVE_SUCCESS = 'CONTENT_APPROVE_SUCCESS'
export const CONTENT_APPROVE_ERROR = 'CONTENT_APPROVE_ERROR'
export const CLEAR_CONTENT_APPROVE_DATA = 'CLEAR_CONTENT_APPROVE_DATA'

export const GET_CONTENT_MODERATION_LABELS = 'GET_CONTENT_MODERATION_LABELS'
export const GET_CONTENT_MODERATION_LABELS_SUCCESS =
  'GET_CONTENT_MODERATION_LABELS_SUCCESS'
export const GET_CONTENT_MODERATION_LABELS_ERROR =
  'POST_MICROSOFT_OUTLOOK_ACCOUNT_ERROR'

export const CONTENT_REJECT = 'CONTENT_REJECT'
export const CONTENT_REJECT_SUCCESS = 'CONTENT_REJECT_SUCCESS'
export const CONTENT_REJECT_ERROR = 'CONTENT_REJECT_ERROR'
export const CLEAR_CONTENT_REJECT = 'CLEAR_CONTENT_REJECT'
export const CONTENT_REJECT_AND_DELETE = 'CONTENT_REJECT_AND_DELETE'
export const CONTENT_REJECT_AND_DELETE_SUCCESS =
  'CONTENT_REJECT_AND_DELETE_SUCCESS'
export const CONTENT_REJECT_AND_DELETE_ERROR = 'CONTENT_REJECT_AND_DELETE_ERROR'

export const GET_POWERBI_TOKEN = 'GET_POWERBI_TOKEN'
export const GET_POWERBI_TOKEN_SUCCESS = 'GET_POWERBI_TOKEN_SUCCESS'
export const GET_POWERBI_TOKEN_ERROR = 'GET_POWERBI_TOKEN_ERROR'
export const CLEAR_POWERBI_TOKEN_INFO = 'CLEAR_POWERBI_TOKEN_INFO'
export const POWERBI_LOGOUT = 'POWERBI_LOGOUT'

export const GET_POWERBI_DASHBOARDS = 'GET_POWERBI_DASHBOARDS'
export const GET_POWERBI_DASHBOARDS_SUCCESS = 'GET_POWERBI_DASHBOARDS_SUCCESS'
export const GET_POWERBI_DASHBOARDS_ERROR = 'GET_POWERBI_DASHBOARDS_ERROR'
export const CLEAR_POWERBI_DASHBOARDS = 'CLEAR_POWERBI_DASHBOARDS'

export const GET_MS_TEAMS_CHAT_TOKEN = 'GET_MS_TEAMS_CHAT_TOKEN'
export const GET_MS_TEAMS_CHAT_TOKEN_SUCCESS = 'GET_MS_TEAMS_CHAT_TOKEN_SUCCESS'
export const GET_MS_TEAMS_CHAT_TOKEN_ERROR = 'GET_MS_TEAMS_CHAT_TOKEN_ERROR'
export const CLEAR_MS_TEAMS_CHAT_TOKEN_INFO = 'CLEAR_MS_TEAMS_CHAT_TOKEN_INFO'
export const MS_TEAMS_CHAT_LOGOUT = 'MS_TEAMS_CHAT_LOGOUT'

export const GET_MS_TEAMS_CHAT_CHANNELS = 'GET_MS_TEAMS_CHAT_CHANNELS'
export const GET_MS_TEAMS_CHAT_CHANNELS_SUCCESS =
  'GET_MS_TEAMS_CHAT_CHANNELS_SUCCESS'
export const GET_MS_TEAMS_CHAT_CHANNELS_ERROR =
  'GET_MS_TEAMS_CHAT_CHANNELS_ERROR'
export const CLEAR_MS_TEAMS_CHAT_CHANNELS = 'CLEAR_MS_TEAMS_CHAT_CHANNELS'

export const GET_KLIPFOLIO_TOKEN = 'GET_KLIPFOLIO_TOKEN'
export const GET_KLIPFOLIO_TOKEN_SUCCESS = 'GET_KLIPFOLIO_TOKEN_SUCCESS'
export const GET_KLIPFOLIO_TOKEN_ERROR = 'GET_KLIPFOLIO_TOKEN_ERROR'
export const CLEAR_KLIPFOLIO_TOKEN_INFO = 'CLEAR_KLIPFOLIO_TOKEN_INFO'
export const KLIPFOLIO_LOGOUT = 'KLIPFOLIO_LOGOUT'

export const GET_KLIPFOLIO_DASHBOARDS = 'GET_KLIPFOLIO_DASHBOARDS'
export const GET_KLIPFOLIO_DASHBOARDS_SUCCESS =
  'GET_KLIPFOLIO_DASHBOARDS_SUCCESS'
export const GET_KLIPFOLIO_DASHBOARDS_ERROR = 'GET_KLIPFOLIO_DASHBOARDS_ERROR'
export const CLEAR_KLIPFOLIO_RESPONSE_INFO = 'CLEAR_KLIPFOLIO_RESPONSE_INFO'

export const GET_SHORELINE_EVENTS = 'GET_SHORELINE_EVENTS'
export const GET_SHORELINE_EVENTS_SUCCESS = 'GET_SHORELINE_EVENTS_SUCCESS'
export const GET_SHORELINE_EVENTS_ERROR = 'GET_SHORELINE_EVENTS_ERROR'

export const GET_SHORELINE_PRICE = 'GET_SHORELINE_PRICE'
export const GET_SHORELINE_PRICE_SUCCESS = 'GET_SHORELINE_PRICE_SUCCESS'
export const GET_SHORELINE_PRICE_ERROR = 'GET_SHORELINE_PRICE_ERROR'

export const POST_SHORELINE_PRICE = 'POST_SHORELINE_PRICE'
export const POST_SHORELINE_PRICE_SUCCESS = 'POST_SHORELINE_PRICE_SUCCESS'
export const POST_SHORELINE_PRICE_ERROR = 'POST_SHORELINE_PRICE_ERROR'
export const CLEAR_POST_SHORELINE_PRICE = 'CLEAR_POST_SHORELINE_PRICE'

export const GET_GEO_LOCATION_FROM_IP = 'GET_GEO_LOCATION_FROM_IP'
export const GET_GEO_LOCATION_FROM_IP_SUCCESS =
  'GET_GEO_LOCATION_FROM_IP_SUCCESS'
export const GET_GEO_LOCATION_FROM_IP_ERROR = 'GET_GEO_LOCATION_FROM_IP_ERROR'

export const GET_GLOBAL_SEARCH_ITEMS = 'GET_GLOBAL_SEARCH_ITEMS'
export const GET_GLOBAL_SEARCH_ITEMS_SUCCESS = 'GET_GLOBAL_SEARCH_ITEMS_SUCCESS'
export const GET_GLOBAL_SEARCH_ITEMS_ERROR = 'GET_GLOBAL_SEARCH_ITEMS_ERROR'

export const GET_CONFIG_SYSTEM_ROLE = 'GET_CONFIG_SYSTEM_ROLE'
export const GET_CONFIG_SYSTEM_ROLE_SUCCESS = 'GET_CONFIG_SYSTEM_ROLE_SUCCESS'
export const GET_CONFIG_SYSTEM_ROLE_ERROR = 'GET_CONFIG_SYSTEM_ROLE_ERROR'

export const SET_LEGACY_THEMES_ENABLED = 'SET_LEGACY_THEMES_ENABLED'

export const GET_CONFIG_CLIENT_TYPE = 'GET_CONFIG_CLIENT_TYPES'
export const GET_CONFIG_CLIENT_TYPE_SUCCESS = 'GET_CONFIG_CLIENT_TYPES_SUCCESS'
export const GET_CONFIG_CLIENT_TYPE_ERROR = 'GET_CONFIG_CLIENT_TYPES_ERROR'

export const GET_CONFIG_DEVICE_TYPE = 'GET_CONFIG_DEVICE_TYPE'
export const GET_CONFIG_DEVICE_TYPE_SUCCESS = 'GET_CONFIG_DEVICE_TYPE_SUCCESS'
export const GET_CONFIG_DEVICE_TYPE_ERROR = 'GET_CONFIG_DEVICE_TYPE_ERROR'

export const GET_CONFIG_FEATURE_CLIENT = 'GET_CONFIG_FEATURE_CLIENT'
export const GET_CONFIG_FEATURE_CLIENT_SUCCESS =
  'GET_CONFIG_FEATURE_CLIENT_SUCCESS'
export const GET_CONFIG_FEATURE_CLIENT_ERROR = 'GET_CONFIG_FEATURE_CLIENT_ERROR'

export const GET_CONFIG_FEATURE_DEVICE = 'GET_CONFIG_FEATURE_DEVICE'
export const GET_CONFIG_FEATURE_DEVICE_SUCCESS =
  'GET_CONFIG_FEATURE_DEVICE_SUCCESS'
export const GET_CONFIG_FEATURE_DEVICE_ERROR = 'GET_CONFIG_FEATURE_DEVICE_ERROR'

export const GET_CONFIG_FEATURE_MEDIA = 'GET_CONFIG_FEATURE_MEDIA'
export const GET_CONFIG_FEATURE_MEDIA_SUCCESS =
  'GET_CONFIG_FEATURE_MEDIA_SUCCESS'
export const GET_CONFIG_FEATURE_MEDIA_ERROR = 'GET_CONFIG_FEATURE_MEDIA_ERROR'
export const GET_ALL_CONFIG_FEATURE_MEDIA = 'GET_ALL_CONFIG_FEATURE_MEDIA'
export const GET_ALL_CONFIG_FEATURE_MEDIA_SUCCESS =
  'GET_ALL_CONFIG_FEATURE_MEDIA_SUCCESS'
export const GET_ALL_CONFIG_FEATURE_MEDIA_ERROR =
  'GET_ALL_CONFIG_FEATURE_MEDIA_ERROR'

export const GET_CONFIG_MEDIA_CATEGORY = 'GET_CONFIG_MEDIA_CATEGORY'
export const GET_CONFIG_MEDIA_CATEGORY_SUCCESS =
  'GET_CONFIG_MEDIA_CATEGORY_SUCCESS'
export const GET_CONFIG_MEDIA_CATEGORY_ERROR = 'GET_CONFIG_MEDIA_CATEGORY_ERROR'
export const CLEAR_CONFIG_MEDIA_CATEGORY = 'CLEAR_CONFIG_MEDIA_CATEGORY'

export const GET_YOUTUBE_CAPTIONS = 'GET_YOUTUBE_CAPTIONS'
export const GET_YOUTUBE_CAPTIONS_SUCCESS = 'GET_YOUTUBE_CAPTIONS_SUCCESS'
export const GET_YOUTUBE_CAPTIONS_ERROR = 'GET_YOUTUBE_CAPTIONS_ERROR'

export const GET_CONFIG_TRANSITIONS = 'GET_CONFIG_TRANSITIONS'
export const GET_CONFIG_TRANSITIONS_SUCCESS = 'GET_CONFIG_TRANSITIONS_SUCCESS'
export const GET_CONFIG_TRANSITIONS_ERROR = 'GET_CONFIG_TRANSITIONS_ERROR'

export const GET_CONFIG_CURRENCY = 'GET_CONFIG_CURRENCY'
export const GET_CONFIG_CURRENCY_SUCCESS = 'GET_CONFIG_CURRENCY_SUCCESS'
export const GET_CONFIG_CURRENCY_ERROR = 'GET_CONFIG_CURRENCY_ERROR'

export const GET_AIRLINES = 'GET_AIRLINES'
export const GET_AIRLINES_SUCCESS = 'GET_AIRLINES_SUCCESS'
export const GET_AIRLINES_ERROR = 'GET_AIRLINES_ERROR'
export const CLEAR_AIRLINES_DATA = 'CLEAR_AIRLINES_DATA'

export const GET_AIRPORT_GATE = 'GET_AIRPORT_GATE'
export const GET_AIRPORT_GATE_SUCCESS = 'GET_AIRPORT_GATE_SUCCESS'
export const GET_AIRPORT_GATE_ERROR = 'GET_AIRPORT_GATE_ERROR'

export const GET_AIRPORT_BAGGAGE = 'GET_AIRPORT_BAGGAGE'
export const GET_AIRPORT_BAGGAGE_SUCCESS = 'GET_AIRPORT_BAGGAGE_SUCCESS'
export const GET_AIRPORT_BAGGAGE_ERROR = 'GET_AIRPORT_BAGGAGE_ERROR'

export const GET_AIRPORTS = 'GET_AIRPORTS'
export const GET_AIRPORTS_SUCCESS = 'GET_AIRPORTS_SUCCESS'
export const GET_AIRPORTS_ERROR = 'GET_AIRPORTS_ERROR'

export const GET_CONFIG_ALERT_TYPES = 'GET_CONFIG_ALERT_TYPES'
export const GET_CONFIG_ALERT_TYPES_SUCCESS = 'GET_CONFIG_ALERT_TYPES_SUCCESS'
export const GET_CONFIG_ALERT_TYPES_ERROR = 'GET_CONFIG_ALERT_TYPES_ERROR'
export const CLEAR_GET_CONFIG_ALERT_TYPES_INFO =
  'CLEAR_GET_CONFIG_ALERT_TYPES_INFO'

export const GET_THEME_OF_MEDIA_FEATURE_BY_ID =
  'GET_THEME_OF_MEDIA_FEATURE_BY_ID'
export const GET_THEME_OF_MEDIA_FEATURE_BY_ID_SUCCESS =
  'GET_THEME_OF_MEDIA_FEATURE_BY_ID_SUCCESS'
export const GET_THEME_OF_MEDIA_FEATURE_BY_ID_ERROR =
  'GET_THEME_OF_MEDIA_FEATURE_BY_ID_ERROR'

export const GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID =
  'GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID'
export const GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID_SUCCESS =
  'GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID_SUCCESS'
export const GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID_ERROR =
  'GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID_ERROR'

export const GET_LOCATION_INFO = 'GET_LOCATION_INFO'
export const GET_LOCATION_INFO_SUCCESS = 'GET_LOCATION_INFO_SUCCESS'
export const GET_LOCATION_INFO_ERROR = 'GET_LOCATION_INFO_ERROR'

export const GET_FIPS_LOCATION = 'GET_FIPS_LOCATION'
export const GET_FIPS_LOCATION_SUCCESS = 'GET_FIPS_LOCATION_SUCCESS'
export const GET_FIPS_LOCATION_ERROR = 'GET_FIPS_LOCATION_ERROR'

export const GET_IPAWS_FEEDS = 'GET_IPAWS_FEEDS'
export const GET_IPAWS_FEEDS_SUCCESS = 'GET_IPAWS_FEEDS_SUCCESS'
export const GET_IPAWS_FEEDS_ERROR = 'GET_IPAWS_FEEDS_ERROR'

export const GET_BACKGROUND_PATTERNS = 'GET_BACKGROUND_PATTERNS'
export const GET_BACKGROUND_PATTERNS_SUCCESS = 'GET_BACKGROUND_PATTERNS_SUCCESS'
export const GET_BACKGROUND_PATTERNS_ERROR = 'GET_BACKGROUND_PATTERNS_ERROR'

export const GET_BACKGROUND_PATTERN_BY_ID = 'GET_BACKGROUND_PATTERN_BY_ID'
export const GET_BACKGROUND_PATTERN_BY_ID_SUCCESS =
  'GET_BACKGROUND_PATTERN_BY_ID_SUCCESS'
export const GET_BACKGROUND_PATTERN_BY_ID_ERROR =
  'GET_BACKGROUND_PATTERN_BY_ID_ERROR'

export const CLEAR_BACKGROUND_DATA = 'CLEAR_BACKGROUND_DATA'

//Fonts Actions
export const GET_GOOGLE_FONTS = 'GET_GOOGLE_FONTS'
export const GET_GOOGLE_FONTS_SUCCESS = 'GET_GOOGLE_FONTS_SUCCESS'
export const GET_GOOGLE_FONTS_ERROR = 'GET_GOOGLE_FONTS_ERROR'

export const GET_SAVED_FONTS = 'GET_SAVED_FONTS'
export const GET_SAVED_FONTS_SUCCESS = 'GET_SAVED_FONTS_SUCCESS'
export const GET_SAVED_FONTS_ERROR = 'GET_SAVED_FONTS_ERROR'

export const EXTEND_FONTS_PER_PAGE = 'EXTEND_FONTS_PER_PAGE'
export const MERGE_WEB_FONT_CONFIG = 'MERGE_WEB_FONT_CONFIG'
export const ADD_FONTS_TO_LIST = 'ADD_FONTS_TO_LIST'
export const REMOVE_FONTS_TO_LIST = 'REMOVE_FONTS_TO_LIST'
export const CHANGE_VARIANT_OF_SELECTED_FONT = 'CHANGE_VARIANT_OF_SELECTED_FONT'
export const CLEAR_ADDED_FONTS = 'CLEAR_ADDED_FONTS'
export const DELETE_SAVED_FONTS = 'DELETE_SAVED_FONTS'
export const SET_FILTERS = 'SET_FILTERS'
export const SELECT_FONT = 'SELECT_FONT'
export const UNSELECT_FONT = 'UNSELECT_FONT'
export const SELECT_ALL_FONTS = 'SELECT_ALL_FONTS'
export const DELETE_SELECTED_FONTS = 'DELETE_SELECTED_FONTS'
export const DELETE_SELECTED_FONTS_SUCCESS = 'DELETE_SELECTED_FONTS_SUCCESS'
export const DELETE_SELECTED_FONTS_ERROR = 'DELETE_SELECTED_FONTS_ERROR'
export const ADD_LOADED_FONT = 'ADD_LOADED_FONT'
export const SET_FONT_OPTIONS = 'SET_FONT_OPTIONS'
export const FETCH_GOOGLE_FONTS = 'FETCH_GOOGLE_FONTS'
export const FETCH_GOOGLE_FONTS_SUCCESS = 'FETCH_GOOGLE_FONTS_SUCCESS'
export const FETCH_GOOGLE_FONTS_ERROR = 'FETCH_GOOGLE_FONTS_ERROR'

//HTML Content Actions
export const GET_HTML_CONTENTS = 'GET_HTML_CONTENTS'
export const GET_HTML_CONTENTS_SUCCESS = 'GET_HTML_CONTENTS_SUCCESS'
export const GET_HTML_CONTENTS_ERROR = 'GET_HTML_CONTENTS_ERROR'

export const GET_HTML_CONTENT_BY_ID = 'GET_HTML_CONTENT_BY_ID'
export const GET_HTML_CONTENT_BY_ID_SUCCESS = 'GET_HTML_CONTENT_BY_ID_SUCCESS'
export const GET_HTML_CONTENT_BY_ID_ERROR = 'GET_HTML_CONTENT_BY_ID_ERROR'

export const POST_HTML_CONTENT = 'POST_HTML_CONTENT'
export const POST_HTML_CONTENT_SUCCESS = 'POST_HTML_CONTENT_SUCCESS'
export const POST_HTML_CONTENT_ERROR = 'POST_HTML_CONTENT_ERROR'

export const PUT_HTML_CONTENT = 'PUT_HTML_CONTENT'
export const PUT_HTML_CONTENT_SUCCESS = 'PUT_HTML_CONTENT_SUCCESS'
export const PUT_HTML_CONTENT_ERROR = 'PUT_HTML_CONTENT_ERROR'

export const DELETE_HTML_CONTENT = 'DELETE_HTML_CONTENT'
export const DELETE_HTML_CONTENT_SUCCESS = 'DELETE_HTML_CONTENT_SUCCESS'
export const DELETE_HTML_CONTENT_ERROR = 'DELETE_HTML_CONTENT_ERROR'

export const DELETE_SELECTED_HTML_CONTENTS = 'DELETE_SELECTED_HTML_CONTENTS'
export const DELETE_SELECTED_HTML_CONTENTS_SUCCESS =
  'DELETE_SELECTED_SELECTED_HTML_CONTENTS_SUCCESS'
export const DELETE_SELECTED_HTML_CONTENTS_ERROR =
  'DELETE_SELECTED_HTML_CONTENTS_ERROR'

export const CLEAR_HTML_CONTENT_RESPONSE_INFO =
  'CLEAR_HTML_CONTENT_RESPONSE_INFO'
export const CLEAR_HTML_CONTENT_ITEM_INFO = 'CLEAR_HTML_CONTENT_ITEM_INFO'

//Settings Actions
export const PUT_CLIENT_SETTINGS = 'PUT_CLIENT_SETTINGS'
export const PUT_CLIENT_SETTINGS_SUCCESS = 'PUT_CLIENT_SETTINGS_SUCCESS'
export const PUT_CLIENT_SETTINGS_ERROR = 'PUT_CLIENT_SETTINGS_ERROR'
export const CLEAR_PUT_CLIENT_SETTINGS_INFO = 'CLEAR_PUT_CLIENT_SETTINGS_INFO'

export const PUT_CLIENT_FEATURE_SETTINGS = 'PUT_CLIENT_FEATURE_SETTINGS'
export const PUT_CLIENT_FEATURE_SETTINGS_SUCCESS =
  'PUT_CLIENT_FEATURE_SETTINGS_SUCCESS'
export const PUT_CLIENT_FEATURE_SETTINGS_ERROR =
  'PUT_CLIENT_FEATURE_SETTINGS_ERROR'

export const GET_SETTINGS = 'GET_SETTINGS'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR'
export const CLEAR_SETTINGS = 'CLEAR_SETTINGS'
export const PUT_SETTINGS = 'PUT_SETTINGS'
export const PUT_SETTINGS_SUCCESS = 'PUT_SETTINGS_SUCCESS'
export const PUT_SETTINGS_ERROR = 'PUT_SETTINGS_ERROR'
export const CLEAR_PUT_SETTINGS_INFO = 'CLEAR_PUT_SETTINGS_INFO'

export const SET_LIBRARY_VIEW_SETTINGS = 'SET_LIBRARY_VIEW_SETTINGS'

//Font Actions
export const GET_FONTS = 'GET_FONTS'
export const GET_FONTS_SUCCESS = 'GET_FONTS_SUCCESS'
export const GET_FONTS_ERROR = 'GET_FONTS_ERROR'
export const CLEAR_GET_FONTS_INFO = 'CLEAR_GET_FONTS_INFO'

export const DELETE_FONT = 'DELETE_FONT'
export const DELETE_FONT_SUCCESS = 'DELETE_FONT_SUCCESS'
export const DELETE_FONT_ERROR = 'DELETE_FONT_ERROR'
export const CLEAR_DELETE_FONT_INFO = 'CLEAR_DELETE_FONT_INFO'
export const POST_FONT = 'POST_FONT'
export const CLEAR_POST_FONT_INFO = 'CLEAR_POST_FONT_INFO'
export const POST_FONT_SUCCESS = 'POST_FONT_SUCCESS'
export const POST_FONT_ERROR = 'POST_FONT_ERROR'

//Client Package Actions
export const GET_CLIENT_PACKAGES = 'GET_CLIENT_PACKAGES'
export const GET_CLIENT_PACKAGES_SUCCESS = 'GET_CLIENT_PACKAGES_SUCCESS'
export const GET_CLIENT_PACKAGES_ERROR = 'GET_CLIENT_PACKAGES_ERROR'

export const POST_CLIENT_PACKAGE = 'POST_CLIENT_PACKAGE'
export const POST_CLIENT_PACKAGE_SUCCESS = 'POST_CLIENT_PACKAGE_SUCCESS'
export const POST_CLIENT_PACKAGE_ERROR = 'POST_CLIENT_PACKAGE_ERROR'

export const GET_CLIENT_PACKAGE_BY_ID = 'GET_CLIENT_PACKAGE_BY_ID'
export const GET_CLIENT_PACKAGE_BY_ID_SUCCESS =
  'GET_CLIENT_PACKAGE_BY_ID_SUCCESS'
export const GET_CLIENT_PACKAGE_BY_ID_ERROR = 'GET_CLIENT_PACKAGE_BY_ID_ERROR'

export const PUT_CLIENT_PACKAGE = 'PUT_CLIENT_PACKAGE'
export const PUT_CLIENT_PACKAGE_SUCCESS = 'PUT_CLIENT_PACKAGE_SUCCESS'
export const PUT_CLIENT_PACKAGE_ERROR = 'PUT_CLIENT_PACKAGE_ERROR'

export const DELETE_CLIENT_PACKAGE = 'DELETE_CLIENT_PACKAGE'
export const DELETE_CLIENT_PACKAGE_SUCCESS = 'DELETE_CLIENT_PACKAGE_SUCCESS'
export const DELETE_CLIENT_PACKAGE_ERROR = 'DELETE_CLIENT_PACKAGE_ERROR'

export const DELETE_SELECTED_CLIENT_PACKAGES = 'DELETE_SELECTED_CLIENT_PACKAGES'
export const DELETE_SELECTED_CLIENT_PACKAGES_SUCCESS =
  'DELETE_SELECTED_SELECTED_CLIENT_PACKAGES_SUCCESS'
export const DELETE_SELECTED_CLIENT_PACKAGES_ERROR =
  'DELETE_SELECTED_CLIENT_PACKAGES_ERROR'

export const CLEAR_CLIENT_PACKAGE_RESPONSE_INFO =
  'CLEAR_CLIENT_PACKAGE_RESPONSE_INFO'

//Device Package
export const GET_DEVICE_PACKAGES = 'GET_DEVICE_PACKAGES'
export const GET_DEVICE_PACKAGES_SUCCESS = 'GET_DEVICE_PACKAGES_SUCCESS'
export const GET_DEVICE_PACKAGES_ERROR = 'GET_DEVICE_PACKAGES_ERROR'

export const GET_DEVICE_PACKAGE_BY_ID = 'GET_DEVICE_PACKAGE_BY_ID'
export const GET_DEVICE_PACKAGE_BY_ID_SUCCESS =
  'GET_DEVICE_PACKAGE_BY_ID_SUCCESS'
export const GET_DEVICE_PACKAGE_BY_ID_ERROR = 'GET_DEVICE_PACKAGE_BY_ID_ERROR'

export const DELETE_DEVICE_PACKAGE = 'DELETE_DEVICE_PACKAGE'
export const DELETE_DEVICE_PACKAGE_SUCCESS = 'DELETE_DEVICE_PACKAGE_SUCCESS'
export const DELETE_DEVICE_PACKAGE_ERROR = 'DELETE_DEVICE_PACKAGE_ERROR'

export const POST_DEVICE_PACKAGE = 'POST_DEVICE_PACKAGE'
export const POST_DEVICE_PACKAGE_SUCCESS = 'POST_DEVICE_PACKAGE_SUCCESS'
export const POST_DEVICE_PACKAGE_ERROR = 'POST_DEVICE_PACKAGE_ERROR'

export const PUT_DEVICE_PACKAGE = 'PUT_DEVICE_PACKAGE'
export const PUT_DEVICE_PACKAGE_SUCCESS = 'PUT_DEVICE_PACKAGE_SUCCESS'
export const PUT_DEVICE_PACKAGE_ERROR = 'PUT_DEVICE_PACKAGE_ERROR'

export const DELETE_SELECTED_DEVICE_PACKAGES = 'DELETE_SELECTED_DEVICE_PACKAGES'
export const DELETE_SELECTED_DEVICE_PACKAGES_SUCCESS =
  'DELETE_SELECTED_SELECTED_DEVICE_PACKAGES_SUCCESS'
export const DELETE_SELECTED_DEVICE_PACKAGES_ERROR =
  'DELETE_SELECTED_DEVICE_PACKAGES_ERROR'

export const CLEAR_DEVICE_PACKAGE_RESPONSE_INFO =
  'CLEAR_DEVICE_PACKAGE_RESPONSE_INFO'

//Device Package actions
export const GET_BANDWIDTH_PACKAGES = 'GET_BANDWIDTH_PACKAGES'
export const GET_BANDWIDTH_PACKAGES_SUCCESS = 'GET_BANDWIDTH_PACKAGES_SUCCESS'
export const GET_BANDWIDTH_PACKAGES_ERROR = 'GET_BANDWIDTH_PACKAGES_ERROR'

export const GET_BANDWIDTH_PACKAGE_BY_ID = 'GET_BANDWIDTH_PACKAGE_BY_ID'
export const GET_BANDWIDTH_PACKAGE_BY_ID_SUCCESS =
  'GET_BANDWIDTH_PACKAGE_BY_ID_SUCCESS'
export const GET_BANDWIDTH_PACKAGE_BY_ID_ERROR =
  'GET_BANDWIDTH_PACKAGE_BY_ID_ERROR'

export const DELETE_BANDWIDTH_PACKAGE = 'DELETE_BANDWIDTH_PACKAGE'
export const DELETE_BANDWIDTH_PACKAGE_SUCCESS =
  'DELETE_BANDWIDTH_PACKAGE_SUCCESS'
export const DELETE_BANDWIDTH_PACKAGE_ERROR = 'DELETE_BANDWIDTH_PACKAGE_ERROR'

export const POST_BANDWIDTH_PACKAGE = 'POST_BANDWIDTH_PACKAGE'
export const POST_BANDWIDTH_PACKAGE_SUCCESS = 'POST_BANDWIDTH_PACKAGE_SUCCESS'
export const POST_BANDWIDTH_PACKAGE_ERROR = 'POST_BANDWIDTH_PACKAGE_ERROR'

export const PUT_BANDWIDTH_PACKAGE = 'PUT_BANDWIDTH_PACKAGE'
export const PUT_BANDWIDTH_PACKAGE_SUCCESS = 'PUT_BANDWIDTH_PACKAGE_SUCCESS'
export const PUT_BANDWIDTH_PACKAGE_ERROR = 'PUT_BANDWIDTH_PACKAGE_ERROR'

export const DELETE_SELECTED_BANDWIDTH_PACKAGES =
  'DELETE_SELECTED_BANDWIDTH_PACKAGES'
export const DELETE_SELECTED_BANDWIDTH_PACKAGES_SUCCESS =
  'DELETE_SELECTED_SELECTED_BANDWIDTH_PACKAGES_SUCCESS'
export const DELETE_SELECTED_BANDWIDTH_PACKAGES_ERROR =
  'DELETE_SELECTED_BANDWIDTH_PACKAGES_ERROR'

export const CLEAR_BANDWIDTH_PACKAGE_RESPONSE_INFO =
  'CLEAR_BANDWIDTH_PACKAGE_RESPONSE_INFO'

// Device Noc
export const GET_DEVICE_NOC_GENERAL_ITEMS = 'GET_DEVICE_NOC_GENERAL_ITEMS'
export const GET_DEVICE_NOC_GENERAL_ITEMS_SUCCESS =
  'GET_DEVICE_NOC_GENERAL_ITEMS_SUCCESS'
export const GET_DEVICE_NOC_GENERAL_ITEMS_ERROR =
  'GET_DEVICE_NOC_GENERAL_ITEMS_ERROR'

export const GET_DEVICE_NOC_HEALTH_ITEMS = 'GET_DEVICE_NOC_HEALTH_ITEMS'
export const GET_DEVICE_NOC_HEALTH_ITEMS_SUCCESS =
  'GET_DEVICE_NOC_HEALTH_ITEMS_SUCCESS'
export const GET_DEVICE_NOC_HEALTH_ITEMS_ERROR =
  'GET_DEVICE_NOC_HEALTH_ITEMS_ERROR'

export const GET_DEVICE_NOC_NETWORK_ITEMS = 'GET_DEVICE_NOC_NETWORK_ITEMS'
export const GET_DEVICE_NOC_NETWORK_ITEMS_SUCCESS =
  'GET_DEVICE_NOC_NETWORK_ITEMS_SUCCESS'
export const GET_DEVICE_NOC_NETWORK_ITEMS_ERROR =
  'GET_DEVICE_NOC_NETWORK_ITEMS_ERROR'

export const GET_DEVICE_ALARM_ITEMS = 'GET_DEVICE_ALARM_ITEMS'
export const GET_DEVICE_ALARM_ITEMS_SUCCESS = 'GET_DEVICE_ALARM_ITEMS_SUCCESS'
export const GET_DEVICE_ALARM_ITEMS_ERROR = 'GET_DEVICE_ALARM_ITEMS_ERROR'

export const GET_DEVICE_ALARM_ITEM_BY_ID = 'GET_DEVICE_ALARM_ITEM_BY_ID'
export const GET_DEVICE_ALARM_ITEM_BY_ID_SUCCESS =
  'GET_DEVICE_ALARM_ITEM_BY_ID_SUCCESS'
export const GET_DEVICE_ALARM_ITEM_BY_ID_ERROR =
  'GET_DEVICE_ALARM_ITEM_BY_ID_ERROR'

export const POST_DEVICE_ALARM = 'POST_DEVICE_ALARM'
export const POST_DEVICE_ALARM_SUCCESS = 'POST_DEVICE_ALARM_SUCCESS'
export const POST_DEVICE_ALARM_ERROR = 'POST_DEVICE_ALARM_ERROR'

export const PUT_DEVICE_ALARM = 'PUT_DEVICE_ALARM'
export const PUT_DEVICE_ALARM_SUCCESS = 'PUT_DEVICE_ALARM_SUCCESS'
export const PUT_DEVICE_ALARM_ERROR = 'PUT_DEVICE_ALARM_ERROR'

export const DELETE_DEVICE_ALARM = 'DELETE_DEVICE_ALARM'
export const DELETE_DEVICE_ALARM_SUCCESS = 'DELETE_DEVICE_ALARM_SUCCESS'
export const DELETE_DEVICE_ALARM_ERROR = 'DELETE_DEVICE_ALARM_ERROR'

export const GET_DEVICE_ALARM_COLUMN_LIST = 'GET_DEVICE_ALARM_COLUMN_LIST'
export const GET_DEVICE_ALARM_COLUMN_LIST_SUCCESS =
  'GET_DEVICE_ALARM_COLUMN_LIST_SUCCESS'
export const GET_DEVICE_ALARM_COLUMN_LIST_ERROR =
  'GET_DEVICE_ALARM_COLUMN_LIST_ERROR'

export const CLEAR_DEVICE_ALARM_RESPONSE_INFO =
  'CLEAR_DEVICE_ALARM_RESPONSE_INFO'

//Email Templates actions
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES'
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS'
export const GET_EMAIL_TEMPLATES_ERROR = 'GET_EMAIL_TEMPLATES_ERROR'

export const GET_EMAIL_TEMPLATE_BY_ID = 'GET_EMAIL_TEMPLATE_BY_ID'
export const GET_EMAIL_TEMPLATE_BY_ID_SUCCESS =
  'GET_EMAIL_TEMPLATE_BY_ID_SUCCESS'
export const GET_EMAIL_TEMPLATE_BY_ID_ERROR = 'GET_EMAIL_TEMPLATE_BY_ID_ERROR'

export const PUT_EMAIL_TEMPLATE = 'PUT_EMAIL_TEMPLATE'
export const PUT_EMAIL_TEMPLATE_SUCCESS = 'PUT_EMAIL_TEMPLATE_SUCCESS'
export const PUT_EMAIL_TEMPLATE_ERROR = 'PUT_EMAIL_TEMPLATE_ERROR'

export const CLEAR_EMAIL_TEMPLATE_RESPONSE_INFO =
  'CLEAR_EMAIL_TEMPLATE_RESPONSE_INFO'

// Help Actions
export const GET_HELPS = 'GET_HELPS'
export const GET_HELPS_SUCCESS = 'GET_HELPS_SUCCESS'
export const GET_HELPS_ERROR = 'GET_HELPS_ERROR'

export const GET_HELP = 'GET_HELP'
export const GET_HELP_SUCCESS = 'GET_HELP_SUCCESS'
export const GET_HELP_ERROR = 'GET_HELP_ERROR'
export const CLEAR_GET_HELP_INFO = 'CLEAR_GET_HELP_INFO'

export const PUT_HELP = 'PUT_HELP'
export const PUT_HELP_SUCCESS = 'PUT_HELP_SUCCESS'
export const PUT_HELP_ERROR = 'PUT_HELP_ERROR'
export const CLEAR_PUT_HELP_INFO = 'CLEAR_PUT_HELP_INFO'
export const HANDLE_IS_SCROLLING = 'HANDLE_IS_SCROLLING'

// Content App Help Actions
export const GET_CONTENT_APP_HELPS = 'GET_CONTENT_APP_HELPS'
export const GET_CONTENT_APP_HELPS_SUCCESS = 'GET_CONTENT_APP_HELPS_SUCCESS'
export const GET_CONTENT_APP_HELPS_ERROR = 'GET_CONTENT_APP_HELPS_ERROR'

export const GET_CONTENT_APP_HELP = 'GET_CONTENT_APP_HELP'
export const GET_CONTENT_APP_HELP_SUCCESS = 'GET_CONTENT_APP_HELP_SUCCESS'
export const GET_CONTENT_APP_HELP_ERROR = 'GET_CONTENT_APP_HELP_ERROR'
export const CLEAR_GET_CONTENT_APP_HELP_INFO = 'CLEAR_GET_CONTENT_APP_HELP_INFO'

export const PUT_CONTENT_APP_HELP = 'PUT_CONTENT_APP_HELP'
export const PUT_CONTENT_APP_HELP_SUCCESS = 'PUT_CONTENT_APP_HELP_SUCCESS'
export const PUT_CONTENT_APP_HELP_ERROR = 'PUT_CONTENT_APP_HELP_ERROR'
export const CLEAR_PUT_CONTENT_APP_HELP_INFO = 'CLEAR_PUT_CONTENT_APP_HELP_INFO'

//Role Actions

export const GET_ROLES = 'GET_ROLES'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR'
export const CLEAR_GET_ROLES_INFO = 'CLEAR_GET_ROLES_INFO'

export const GET_ROLE_BY_ID = 'GET_ROLE_BY_ID'
export const GET_ROLE_BY_ID_SUCCESS = 'GET_ROLE_BY_ID_SUCCESS'
export const GET_ROLE_BY_ID_ERROR = 'GET_ROLE_BY_ID_ERROR'
export const CLEAR_GET_ROLE_BY_ID_INFO = 'CLEAR_GET_ROLE_BY_ID_INFO'

export const POST_ROLE = 'POST_ROLE'
export const POST_ROLE_SUCCESS = 'POST_ROLE_SUCCESS'
export const POST_ROLE_ERROR = 'POST_ROLE_ERROR'
export const CLEAR_POST_ROLE_INFO = 'CLEAR_POST_ROLE_INFO'

export const PUT_ROLE_BY_ID = 'PUT_ROLE_BY_ID'
export const PUT_ROLE_BY_ID_SUCCESS = 'PUT_ROLE_BY_ID_SUCCESS'
export const PUT_ROLE_BY_ID_ERROR = 'PUT_ROLE_BY_ID_ERROR'
export const CLEAR_PUT_ROLE_INFO = 'CLEAR_PUT_ROLE_INFO'

export const GET_ROLE_PERMISSION_BY_ID = 'GET_ROLE_PERMISSION_BY_ID'
export const GET_ROLE_PERMISSION_BY_ID_SUCCESS =
  'GET_ROLE_PERMISSION_BY_ID_SUCCESS'
export const GET_ROLE_PERMISSION_BY_ID_ERROR = 'GET_ROLE_PERMISSION_BY_ID_ERROR'
export const CLEAR_GET_ROLE_PERMISSION_BY_ID_INFO =
  'CLEAR_GET_ROLE_PERMISSION_BY_ID_INFO'

export const PUT_ROLE_PERMISSION_BY_ID = 'PUT_ROLE_PERMISSION_BY_ID'
export const PUT_ROLE_PERMISSION_BY_ID_SUCCESS =
  'PUT_ROLE_PERMISSION_BY_ID_SUCCESS'
export const PUT_ROLE_PERMISSION_BY_ID_ERROR = 'PUT_ROLE_PERMISSION_BY_ID_ERROR'
export const CLEAR_PUT_ROLE_PERMISSION_BY_ID_INFO =
  'CLEAR_PUT_ROLE_PERMISSION_BY_ID_INFO'

// Preference Actions

export const GET_PREFERENCE_BY_ENTITY = 'GET_PREFERENCE_BY_ENTITY'
export const GET_PREFERENCE_BY_ENTITY_SUCCESS =
  'GET_PREFERENCE_BY_ENTITY_SUCCESS'
export const GET_PREFERENCE_BY_ENTITY_ERROR = 'GET_PREFERENCE_BY_ENTITY_ERROR'

export const PUT_PREFERENCE_BY_ENTITY = 'PUT_PREFERENCE_BY_ENTITY'
export const PUT_PREFERENCE_BY_ENTITY_SUCCESS =
  'PUT_PREFERENCE_BY_ENTITY_SUCCESS'
export const PUT_PREFERENCE_BY_ENTITY_ERROR = 'PUT_PREFERENCE_BY_ENTITY_ERROR'

export const CLEAR_PREFERENCE_BY_ENTITY = 'CLEAR_PREFERENCE_BY_ENTITY'

export const GET_PREFERENCE_PUBLIC_NOC = 'GET_PREFERENCE_PUBLIC_NOC'
export const GET_PREFERENCE_PUBLIC_NOC_SUCCESS =
  'GET_PREFERENCE_PUBLIC_NOC_SUCCESS'
export const GET_PREFERENCE_PUBLIC_NOC_ERROR = 'GET_PREFERENCE_PUBLIC_NOC_ERROR'

export const PUT_PREFERENCE_PUBLIC_NOC = 'PUT_PREFERENCE_PUBLIC_NOC'
export const PUT_PREFERENCE_PUBLIC_NOC_SUCCESS =
  'PUT_PREFERENCE_PUBLIC_NOC_SUCCESS'
export const PUT_PREFERENCE_PUBLIC_NOC_ERROR = 'PUT_PREFERENCE_PUBLIC_NOC_ERROR'

export const CLEAR_THEME_OF_MEDIA = 'CLEAR_THEME_OF_MEDIA'

export const GET_WHITE_LABEL = 'GET_WHITE_LABEL'
export const GET_WHITE_LABEL_SUCCESS = 'GET_WHITE_LABEL_SUCCESS'
export const GET_WHITE_LABEL_ERROR = 'GET_WHITE_LABEL_ERROR'
export const CLEAR_GET_WHITE_LABEL_INFO = 'CLEAR_GET_WHITE_LABEL_INFO'
export const SET_WHITE_LABEL = 'SET_WHITE_LABEL'

export const GET_ALERT_DEVICES_BY_ID = 'GET_ALERT_DEVICES_BY_ID'
export const GET_ALERT_DEVICES_BY_ID_SUCCESS = 'GET_ALERT_DEVICES_BY_ID_SUCCESS'
export const GET_ALERT_DEVICES_BY_ID_ERROR = 'GET_ALERT_DEVICES_BY_ID_ERROR'
export const CLEAR_GET_ALERT_DEVICES_BY_ID_INFO =
  'CLEAR_GET_ALERT_DEVICES_BY_ID_INFO'
export const POST_ALERT_TRIGGER = 'POST_ALERT_TRIGGER'
export const POST_ALERT_TRIGGER_SUCCESS = 'POST_ALERT_TRIGGER_SUCCESS'
export const POST_ALERT_TRIGGER_ERROR = 'POST_ALERT_TRIGGER_ERROR'
export const CLEAR_POST_ALERT_TRIGGER_INFO = 'CLEAR_POST_ALERT_TRIGGER_INFO'

export const SET_ALERT_TRIGGER_DATA = 'SET_ALERT_TRIGGER_DATA'
export const DELETE_ALERT_TRIGGER_DATA = 'DELETE_ALERT_TRIGGER_DATA'

export const SET_CAP_ALERT_DATA = 'SET_CAP_ALERT_DATA'
export const DELETE_CAP_ALERT_DATA = 'DELETE_CAP_ALERT_DATA'

export const SET_IPAWS_ALERT_DATA = 'SET_IPAWS_ALERT_DATA'
export const DELETE_IPAWS_ALERT_DATA = 'DELETE_IPAWS_ALERT_DATA'

export const DISABLE_ALERT = 'DISABLE_ALERT'
export const DISABLE_ALERT_SUCCESS = 'DISABLE_ALERT_SUCCESS'
export const DISABLE_ALERT_ERROR = 'DISABLE_ALERT_ERROR'
export const CLEAR_DISABLE_ALERT_INFO = 'CLEAR_DISABLE_ALERT_INFO'

export const GET_DEVICE_ACTIVE_EMERGENCY_ALERT =
  'GET_DEVICE_ACTIVE_EMERGENCY_ALERT'
export const GET_DEVICE_ACTIVE_EMERGENCY_ALERT_SUCCESS =
  'GET_DEVICE_ACTIVE_EMERGENCY_ALERT_SUCCESS'
export const GET_DEVICE_ACTIVE_EMERGENCY_ALERT_ERROR =
  'GET_DEVICE_ACTIVE_EMERGENCY_ALERT_ERROR'
export const GET_DEVICE_MEDIA_EMERGENCY_ALERT =
  'GET_DEVICE_MEDIA_EMERGENCY_ALERT'
export const GET_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS =
  'GET_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS'
export const GET_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR =
  'GET_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR'
export const CLEAR_GET_DEVICE_MEDIA_EMERGENCY_ALERT_INFO =
  'CLEAR_GET_DEVICE_MEDIA_EMERGENCY_ALERT_INFO'

export const PUT_DEVICE_MEDIA_EMERGENCY_ALERT =
  'PUT_DEVICE_MEDIA_EMERGENCY_ALERT'
export const PUT_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS =
  'PUT_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS'
export const PUT_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR =
  'PUT_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR'
export const CLEAR_PUT_DEVICE_MEDIA_EMERGENCY_ALERT_INFO =
  'CLEAR_PUT_DEVICE_MEDIA_EMERGENCY_ALERT_INFO'

export const REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT =
  'REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT'
export const REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS =
  'REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS'
export const REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR =
  'REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR'
export const CLEAR_REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_INFO =
  'CLEAR_REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_INFO'

export const GET_DEVICE_MEDIA_CAP_ALERT = 'GET_DEVICE_MEDIA_CAP_ALERT'
export const GET_DEVICE_MEDIA_CAP_ALERT_SUCCESS =
  'GET_DEVICE_MEDIA_CAP_ALERT_SUCCESS'
export const GET_DEVICE_MEDIA_CAP_ALERT_ERROR =
  'GET_DEVICE_MEDIA_CAP_ALERT_ERROR'
export const CLEAR_GET_DEVICE_MEDIA_CAP_ALERT_INFO =
  'CLEAR_GET_DEVICE_MEDIA_CAP_ALERT_INFO'

export const REMOVE_DEVICE_MEDIA_CAP_ALERT = 'REMOVE_DEVICE_MEDIA_CAP_ALERT'
export const REMOVE_DEVICE_MEDIA_CAP_ALERT_SUCCESS =
  'REMOVE_DEVICE_MEDIA_CAP_ALERT_SUCCESS'
export const REMOVE_DEVICE_MEDIA_CAP_ALERT_ERROR =
  'REMOVE_DEVICE_MEDIA_CAP_ALERT_ERROR'
export const CLEAR_REMOVE_DEVICE_MEDIA_CAP_ALERT_INFO =
  'CLEAR_REMOVE_DEVICE_MEDIA_CAP_ALERT_INFO'

export const PUT_DEVICE_MEDIA_CAP_ALERT = 'PUT_DEVICE_MEDIA_CAP_ALERT'
export const PUT_DEVICE_MEDIA_CAP_ALERT_SUCCESS =
  'PUT_DEVICE_MEDIA_CAP_ALERT_SUCCESS'
export const PUT_DEVICE_MEDIA_CAP_ALERT_ERROR =
  'PUT_DEVICE_MEDIA_CAP_ALERT_ERROR'
export const CLEAR_PUT_DEVICE_MEDIA_CAP_ALERT_INFO =
  'CLEAR_PUT_DEVICE_MEDIA_CAP_ALERT_INFO'
export const DISABLE_DEVICE_ALERT = 'DISABLE_DEVICE_ALERT'
export const DISABLE_DEVICE_ALERT_SUCCESS = 'DISABLE_DEVICE_ALERT_SUCCESS'
export const DISABLE_DEVICE_ALERT_ERROR = 'DISABLE_DEVICE_ALERT_ERROR'
export const CLEAR_DISABLE_DEVICE_ALERT_INFO = 'CLEAR_DISABLE_DEVICE_ALERT_INFO'
export const REQUEST_MEDIA_CAP_ALERT = 'REQUEST_MEDIA_CAP_ALERT'
export const MEDIA_CAP_ALERT_SUCCESS = 'MEDIA_CAP_ALERT_SUCCESS'
export const MEDIA_CAP_ALERT_ERROR = 'MEDIA_CAP_ALERT_ERROR'

// IPAWS Alert
export const GET_DEVICE_MEDIA_IPAWS_ALERT = 'GET_DEVICE_MEDIA_IPAWS_ALERT'
export const GET_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS =
  'GET_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS'
export const GET_DEVICE_MEDIA_IPAWS_ALERT_ERROR =
  'GET_DEVICE_MEDIA_IPAWS_ALERT_ERROR'
export const CLEAR_GET_DEVICE_MEDIA_IPAWS_ALERT_INFO =
  'CLEAR_GET_DEVICE_MEDIA_IPAWS_ALERT_INFO'

export const REMOVE_DEVICE_MEDIA_IPAWS_ALERT = 'REMOVE_DEVICE_MEDIA_IPAWS_ALERT'
export const REMOVE_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS =
  'REMOVE_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS'
export const REMOVE_DEVICE_MEDIA_IPAWS_ALERT_ERROR =
  'REMOVE_DEVICE_MEDIA_IPAWS_ALERT_ERROR'
export const CLEAR_REMOVE_DEVICE_MEDIA_IPAWS_ALERT_INFO =
  'CLEAR_REMOVE_DEVICE_MEDIA_IPAWS_ALERT_INFO'

export const PUT_DEVICE_MEDIA_IPAWS_ALERT = 'PUT_DEVICE_MEDIA_IPAWS_ALERT'
export const PUT_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS =
  'PUT_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS'
export const PUT_DEVICE_MEDIA_IPAWS_ALERT_ERROR =
  'PUT_DEVICE_MEDIA_IPAWS_ALERT_ERROR'
export const CLEAR_PUT_DEVICE_MEDIA_IPAWS_ALERT_INFO =
  'CLEAR_PUT_DEVICE_MEDIA_IPAWS_ALERT_INFO'
export const MEDIA_IPAWS_ALERT = 'MEDIA_IPAWS_ALERT'
export const MEDIA_IPAWS_ALERT_SUCCESS = 'MEDIA_IPAWS_ALERT_SUCCESS'
export const MEDIA_IPAWS_ALERT_ERROR = 'MEDIA_IPAWS_ALERT_ERROR'

export const GET_IPAWS_ALERT_DEVICES = 'GET_IPAWS_ALERT_DEVICES'
export const GET_IPAWS_ALERT_DEVICES_SUCCESS = 'GET_IPAWS_ALERT_DEVICES_SUCCESS'
export const GET_IPAWS_ALERT_DEVICES_ERROR = 'GET_IPAWS_ALERT_DEVICES_ERROR'
export const ASSOCIATE_IPAWS_ALERT = 'ASSOCIATE_IPAWS_ALERT'
export const ASSOCIATE_IPAWS_ALERT_SUCCESS = 'ASSOCIATE_IPAWS_ALERT_SUCCESS'
export const ASSOCIATE_IPAWS_ALERT_ERROR = 'ASSOCIATE_IPAWS_ALERT_ERROR'
export const RESET_ASSOCIATE_IPAWS_ALERT = 'RESET_ASSOCIATE_IPAWS_ALERT'

export const GET_FEATURE_MEDIA_ITEMS = 'GET_FEATURE_MEDIA_ITEMS'
export const GET_FEATURE_MEDIA_ITEMS_SUCCESS = 'GET_FEATURE_MEDIA_ITEMS_SUCCESS'
export const GET_FEATURE_MEDIA_ITEMS_ERROR = 'GET_FEATURE_MEDIA_ITEMS_ERROR'
export const CLEAR_GET_FEATURE_MEDIA_ITEMS_INFO =
  'CLEAR_GET_FEATURE_MEDIA_ITEMS_INFO'

export const PUT_ORGANIZE_FEATURE_MEDIA_ITEMS =
  'PUT_ORGANIZE_FEATURE_MEDIA_ITEMS'
export const PUT_ORGANIZE_FEATURE_MEDIA_ITEMS_SUCCESS =
  'PUT_ORGANIZE_FEATURE_MEDIA_ITEMS_SUCCESS'
export const PUT_ORGANIZE_FEATURE_MEDIA_ITEMS_ERROR =
  'PUT_ORGANIZE_FEATURE_MEDIA_ITEMS_ERROR'

//Quote Actions

export const GET_QUOTES = 'GET_QUOTES'
export const GET_QUOTES_SUCCESS = 'GET_QUOTES_SUCCESS'
export const GET_QUOTES_ERROR = 'GET_QUOTES_ERROR'
export const CLEAR_GET_QUOTES_INFO = 'CLEAR_GET_QUOTES_INFO'

export const POST_QUOTE = 'POST_QUOTE'
export const POST_QUOTE_SUCCESS = 'POST_QUOTE_SUCCESS'
export const POST_QUOTE_ERROR = 'POST_QUOTE_ERROR'
export const PUT_QUOTE = 'PUT_QUOTE'
export const PUT_QUOTE_SUCCESS = 'PUT_QUOTE_SUCCESS'
export const PUT_QUOTE_ERROR = 'PUT_QUOTE_ERROR'
export const DELETE_QUOTE = 'DELETE_QUOTE'
export const DELETE_QUOTE_SUCCESS = 'DELETE_QUOTE_SUCCESS'
export const DELETE_QUOTE_ERROR = 'DELETE_QUOTE_ERROR'
export const CLEAR_POST_QUOTE_INFO = 'CLEAR_POST_QUOTE_INFO'
export const CLEAR_QUOTE_INFO = 'CLEAR_QUOTE_INFO'

export const GET_QUOTE_CATEGORIES = 'GET_QUOTE_CATEGORIES'
export const GET_QUOTE_CATEGORIES_SUCCESS = 'GET_QUOTE_CATEGORIES_SUCCESS'
export const GET_QUOTE_CATEGORIES_ERROR = 'GET_QUOTE_CATEGORIES_ERROR'
export const CLEAR_GET_QUOTE_CATEGORIES_INFO = 'CLEAR_GET_QUOTE_CATEGORIES_INFO'

export const GET_DESIGN_GALLERY = 'GET_DESIGN_GALLERY'
export const GET_DESIGN_GALLERY_SUCCESS = 'GET_DESIGN_GALLERY_SUCCESS'
export const GET_DESIGN_GALLERY_ERROR = 'GET_DESIGN_GALLERY_ERROR'
export const POST_DESIGN_GALLERY = 'POST_DESIGN_GALLERY'
export const POST_DESIGN_GALLERY_SUCCESS = 'POST_DESIGN_GALLERY_SUCCESS'
export const POST_DESIGN_GALLERY_ERROR = 'POST_DESIGN_GALLERY_ERROR'
export const PUT_DESIGN_GALLERY = 'PUT_DESIGN_GALLERY'
export const PUT_DESIGN_GALLERY_SUCCESS = 'PUT_DESIGN_GALLERY_SUCCESS'
export const PUT_DESIGN_GALLERY_ERROR = 'PUT_DESIGN_GALLERY_ERROR'
export const CLEAR_DESIGN_GALLERY = 'CLEAR_DESIGN_GALLERY'
export const CLEAR_DESIGN_GALLERY_STATUS = 'CLEAR_DESIGN_GALLERY_STATUS'
export const PUT_DESIGN_GALLERY_FILTER = 'PUT_DESIGN_GALLERY_FILTER'

export const BUILD_SMART_PLAYLIST = 'BUILD_SMART_PLAYLIST'
export const BUILD_SMART_PLAYLIST_SUCCESS = 'BUILD_SMART_PLAYLIST_SUCCESS'
export const BUILD_SMART_PLAYLIST_ERROR = 'BUILD_SMART_PLAYLIST_ERROR'
export const POST_SMART_PLAYLIST = 'POST_SMART_PLAYLIST'
export const POST_SMART_PLAYLIST_SUCCESS = 'POST_SMART_PLAYLIST_SUCCESS'
export const POST_SMART_PLAYLIST_ERROR = 'POST_SMART_PLAYLIST_ERROR'
export const PUT_SMART_PLAYLIST = 'PUT_SMART_PLAYLIST'
export const PUT_SMART_PLAYLIST_SUCCESS = 'PUT_SMART_PLAYLIST_SUCCESS'
export const PUT_SMART_PLAYLIST_ERROR = 'PUT_SMART_PLAYLIST_ERROR'
export const CLEAR_ADDED_SMART_PLAYLIST = 'CLEAR_ADDED_SMART_PLAYLIST'
export const CLEAR_SMART_PLAYLIST_STATUS = 'CLEAR_SMART_PLAYLIST_STATUS'

export const SET_MEDIA_DEPENDENCY = 'SET_MEDIA_DEPENDENCY'
export const CLEAR_MEDIA_DEPENDENCY = 'CLEAR_MEDIA_DEPENDENCY'

export const CLEAR_IP_PROVIDERS_RESPONSE = 'CLEAR_IP_PROVIDERS_RESPONSE'

export const PUT_IP_PROVIDERS = 'PUT_IP_PROVIDERS'
export const PUT_IP_PROVIDERS_SUCCESS = 'PUT_IP_PROVIDERS_SUCCESS'
export const PUT_IP_PROVIDERS_ERROR = 'PUT_IP_PROVIDERS_ERROR'

export const GET_IP_PROVIDERS = 'GET_IP_PROVIDERS'
export const GET_IP_PROVIDERS_SUCCESS = 'GET_IP_PROVIDERS_SUCCESS'
export const GET_IP_PROVIDERS_ERROR = 'GET_IP_PROVIDERS_ERROR'

export const UPDATE_FILTERS_BY_ENTITY = 'UPDATE_FILTERS_BY_ENTITY'
export const RESET_FILTERS_BY_ENTITY = 'RESET_FILTERS_BY_ENTITY'
export const SET_STORED_OPTIONS = 'SET_STORED_OPTIONS'
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS'

//Menu Maker
export const GET_MENU_PRESETS = 'GET_MENU_PRESETS'
export const GET_MENU_PRESETS_SUCCESS = 'GET_MENU_PRESETS_SUCCESS'
export const GET_MENU_PRESETS_ERROR = 'GET_MENU_PRESETS_ERROR'

export const GET_MENU_DESIGN_PREVIEW = 'GET_MENU_DESIGN_PREVIEW'
export const GET_MENU_DESIGN_PREVIEW_SUCCESS = 'GET_MENU_DESIGN_PREVIEW_SUCCESS'
export const GET_MENU_DESIGN_PREVIEW_ERROR = 'GET_MENU_DESIGN_PREVIEW_ERROR'

export const CLOSE_MENU_DESIGN_PREVIEW = 'CLOSE_MENU_DESIGN_PREVIEW'
export const CLEAR_MENU_DESIGN_PREVIEW = 'CLEAR_MENU_DESIGN_PREVIEW'

export const GET_STOCK_MENU_PRESETS = 'GET_STOCK_MENU_PRESETS'
export const GET_STOCK_MENU_PRESETS_SUCCESS = 'GET_STOCK_MENU_PRESETS_SUCCESS'
export const GET_STOCK_MENU_PRESETS_ERROR = 'GET_STOCK_MENU_PRESETS_ERROR'

export const GET_SHARED_MENU_PRESETS = 'GET_SHARED_MENU_PRESETS'
export const GET_SHARED_MENU_PRESETS_SUCCESS = 'GET_SHARED_MENU_PRESETS_SUCCESS'
export const GET_SHARED_MENU_PRESETS_ERROR = 'GET_SHARED_MENU_PRESETS_ERROR'

export const GET_MENU_PRESET_ITEM = 'GET_MENU_PRESET_ITEM'
export const GET_MENU_PRESET_ITEM_SUCCESS = 'GET_MENU_PRESET_ITEM_SUCCESS'
export const GET_MENU_PRESET_ITEM_ERROR = 'GET_MENU_PRESET_ITEM_ERROR'

export const POST_MENU_PRESET = 'POST_MENU_PRESET'
export const POST_MENU_PRESET_SUCCESS = 'POST_MENU_PRESET_SUCCESS'
export const POST_MENU_PRESET_ERROR = 'POST_MENU_PRESET_ERROR'

export const PUT_MENU_PRESET = 'PUT_MENU_PRESET'
export const PUT_MENU_PRESET_SUCCESS = 'PUT_MENU_PRESET_SUCCESS'
export const PUT_MENU_PRESET_ERROR = 'PUT_MENU_PRESET_ERROR'

export const SHARE_MENU_PRESET = 'SHARE_MENU_PRESET'
export const SHARE_MENU_PRESET_SUCCESS = 'SHARE_MENU_PRESET_SUCCESS'
export const SHARE_MENU_PRESET_ERROR = 'SHARE_MENU_PRESET_ERROR'

export const DELETE_MENU_PRESET = 'DELETE_MENU_PRESET'
export const DELETE_MENU_PRESET_SUCCESS = 'DELETE_MENU_PRESET_SUCCESS'
export const DELETE_MENU_PRESET_ERROR = 'DELETE_MENU_PRESET_ERROR'

export const SELECT_MENU_PRESET = 'SELECT_MENU_PRESET'
export const SET_MENU_PRESET = 'SET_MENU_PRESET'
export const CLEAR_MENU_PRESET_RESPONSE_INFO = 'CLEAR_MENU_PRESET_RESPONSE_INFO'

export const GET_MENU_COMPONENTS = 'GET_MENU_COMPONENTS'
export const GET_MENU_COMPONENTS_SUCCESS = 'GET_MENU_COMPONENTS_SUCCESS'
export const GET_MENU_COMPONENTS_ERROR = 'GET_MENU_COMPONENTS_ERROR'

export const GET_STOCK_MENU_COMPONENTS = 'GET_STOCK_MENU_COMPONENTS'
export const GET_STOCK_MENU_COMPONENTS_SUCCESS =
  'GET_STOCK_MENU_COMPONENTS_SUCCESS'
export const GET_STOCK_MENU_COMPONENTS_ERROR = 'GET_STOCK_MENU_COMPONENTS_ERROR'

export const GET_SHARED_MENU_COMPONENTS = 'GET_SHARED_MENU_COMPONENTS'
export const GET_SHARED_MENU_COMPONENTS_SUCCESS =
  'GET_SHARED_MENU_COMPONENTS_SUCCESS'
export const GET_SHARED_MENU_COMPONENTS_ERROR =
  'GET_SHARED_MENU_COMPONENTS_ERROR'

export const GET_MENU_COMPONENT_ITEM = 'GET_MENU_COMPONENT_ITEM'
export const GET_MENU_COMPONENT_ITEM_SUCCESS = 'GET_MENU_COMPONENT_ITEM_SUCCESS'
export const GET_MENU_COMPONENT_ITEM_ERROR = 'GET_MENU_COMPONENT_ITEM_ERROR'

export const POST_MENU_COMPONENT = 'POST_MENU_COMPONENT'
export const POST_MENU_COMPONENT_SUCCESS = 'POST_MENU_COMPONENT_SUCCESS'
export const POST_MENU_COMPONENT_ERROR = 'POST_MENU_COMPONENT_ERROR'

export const PUT_MENU_COMPONENT = 'PUT_MENU_COMPONENT'
export const PUT_MENU_COMPONENT_SUCCESS = 'PUT_MENU_COMPONENT_SUCCESS'
export const PUT_MENU_COMPONENT_ERROR = 'PUT_MENU_COMPONENT_ERROR'

export const DELETE_MENU_COMPONENT = 'DELETE_MENU_COMPONENT'
export const DELETE_MENU_COMPONENT_SUCCESS = 'DELETE_MENU_COMPONENT_SUCCESS'
export const DELETE_MENU_COMPONENT_ERROR = 'DELETE_MENU_COMPONENT_ERROR'

export const SHARE_MENU_COMPONENT = 'SHARE_MENU_COMPONENT'
export const SHARE_MENU_COMPONENT_SUCCESS = 'SHARE_MENU_COMPONENT_SUCCESS'
export const SHARE_MENU_COMPONENT_ERROR = 'SHARE_MENU_COMPONENT_ERROR'

export const SELECT_MENU_COMPONENT = 'SELECT_MENU_COMPONENT'
export const CLEAR_MENU_COMPONENT_RESPONSE_INFO =
  'CLEAR_MENU_COMPONENT_RESPONSE_INFO'

export const GET_MENU_BOARD = 'GET_MENU_BOARD'
export const GET_MENU_BOARD_SUCCESS = 'GET_MENU_BOARD_SUCCESS'
export const GET_MENU_BOARD_ERROR = 'GET_MENU_BOARD_ERROR'

export const SET_MENU_BOARD_ITEM_FROM_MEDIA = 'SET_MENU_BOARD_ITEM_FROM_MEDIA'
export const SET_MENU_BOARD_ITEM = 'SET_MENU_BOARD_ITEM'

export const POST_MENU_BOARD = 'POST_MENU_BOARD'
export const POST_MENU_BOARD_SUCCESS = 'POST_MENU_BOARD_SUCCESS'
export const POST_MENU_BOARD_ERROR = 'POST_MENU_BOARD_ERROR'
export const POST_MENU_BOARD_MEDIA = 'POST_MENU_BOARD_MEDIA'
export const PUT_MENU_BOARD_MEDIA = 'PUT_MENU_BOARD_MEDIA'

export const PUT_MENU_BOARD = 'PUT_MENU_BOARD'
export const PUT_MENU_BOARD_SUCCESS = 'PUT_MENU_BOARD_SUCCESS'
export const PUT_MENU_BOARD_ERROR = 'PUT_MENU_BOARD_ERROR'

export const SELECT_MENU_BOARD = 'SELECT_MENU_BOARD'
export const SET_MENU_BOARD_EDIT = 'SET_MENU_BOARD_EDIT'
export const CLEAR_MENU_BOARD_RESPONSE_INFO = 'CLEAR_MENU_BOARD_RESPONSE_INFO'

export const GET_MENU_MAKER_PATTERNS = 'GET_MENU_MAKER_PATTERNS'
export const GET_MENU_MAKER_PATTERNS_SUCCESS = 'GET_MENU_MAKER_PATTERNS_SUCCESS'
export const GET_MENU_MAKER_PATTERNS_ERROR = 'GET_MENU_MAKER_PATTERNS_ERROR'

export const GET_MENU_MAKER_SHAPES = 'GET_MENU_MAKER_SHAPES'
export const GET_MENU_MAKER_SHAPES_SUCCESS = 'GET_MENU_MAKER_SHAPES_SUCCESS'
export const GET_MENU_MAKER_SHAPES_ERROR = 'GET_MENU_MAKER_SHAPES_ERROR'

export const GET_MENU_MAKER_OBJECTS = 'GET_MENU_MAKER_OBJECTS'
export const GET_MENU_MAKER_OBJECTS_SUCCESS = 'GET_MENU_MAKER_OBJECTS_SUCCESS'
export const GET_MENU_MAKER_OBJECTS_ERROR = 'GET_MENU_MAKER_OBJECTS_ERROR'

export const GET_MENU_MAKER_ICONS = 'GET_MENU_MAKER_ICONS'
export const GET_MENU_MAKER_ICONS_SUCCESS = 'GET_MENU_MAKER_ICONS_SUCCESS'
export const GET_MENU_MAKER_ICONS_ERROR = 'GET_MENU_MAKER_ICONS_ERROR'
export const CLEAR_MENU_MAKER_ICONS = 'CLEAR_MENU_MAKER_ICONS'

export const GET_MENU_MAKER_ICONS_GROUP = 'GET_MENU_MAKER_ICONS_GROUP'
export const GET_MENU_MAKER_ICONS_GROUP_SUCCESS =
  'GET_MENU_MAKER_ICONS_GROUP_SUCCESS'
export const GET_MENU_MAKER_ICONS_GROUP_ERROR =
  'GET_MENU_MAKER_ICONS_GROUP_ERROR'

export const GET_MENU_MAKER_BACKGROUND_IMAGES =
  'GET_MENU_MAKER_BACKGROUND_IMAGES'
export const GET_MENU_MAKER_BACKGROUND_IMAGES_SUCCESS =
  'GET_MENU_MAKER_BACKGROUND_IMAGES_SUCCESS'
export const GET_MENU_MAKER_BACKGROUND_IMAGES_ERROR =
  'GET_MENU_MAKER_BACKGROUND_IMAGES_ERROR'

export const GET_MENU_MAKER_EMOJIS = 'GET_MENU_MAKER_EMOJIS'
export const GET_MENU_MAKER_EMOJIS_SUCCESS = 'GET_MENU_MAKER_EMOJIS_SUCCESS'
export const GET_MENU_MAKER_EMOJIS_ERROR = 'GET_MENU_MAKER_EMOJIS_ERROR'

export const GET_MENU_MAKER_STOCK_IMAGES = 'GET_MENU_MAKER_STOCK_IMAGES'
export const GET_MENU_MAKER_STOCK_IMAGES_SUCCESS =
  'GET_MENU_MAKER_STOCK_IMAGES_SUCCESS'
export const GET_MENU_MAKER_STOCK_IMAGES_ERROR =
  'GET_MENU_MAKER_STOCK_IMAGES_ERROR'

export const CLEAR_MM_SIDEBAR = 'CLEAR_MM_SIDEBAR'

// Menu items
export const GET_MENU_ITEMS = 'GET_MENU_ITEMS'
export const GET_MENU_ITEMS_SUCCESS = 'GET_MENU_ITEMS_SUCCESS'
export const GET_MENU_ITEMS_ERROR = 'GET_MENU_ITEMS_ERROR'

export const GET_MENU_ITEM_BY_ID = 'GET_MENU_ITEM_BY_ID'
export const GET_MENU_ITEM_BY_ID_SUCCESS = 'GET_MENU_ITEM_BY_ID_SUCCESS'
export const GET_MENU_ITEM_BY_ID_ERROR = 'GET_MENU_ITEM_BY_ID_ERROR'

export const POST_MENU_ITEM = 'POST_MENU_ITEM'
export const POST_MENU_ITEM_SUCCESS = 'POST_MENU_ITEM_SUCCESS'
export const POST_MENU_ITEM_ERROR = 'POST_MENU_ITEM_ERROR'

export const POST_MENU_ITEM_BULK = 'POST_MENU_ITEM_BULK'

export const PUT_MENU_ITEM = 'PUT_MENU_ITEM'
export const PUT_MENU_ITEM_SUCCESS = 'PUT_MENU_ITEM_SUCCESS'
export const PUT_MENU_ITEM_ERROR = 'PUT_MENU_ITEM_ERROR'

export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM'
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS'
export const DELETE_MENU_ITEM_ERROR = 'DELETE_MENU_ITEM_ERROR'

export const DELETE_SELECTED_MENU_ITEMS = 'DELETE_SELECTED_MENU_ITEMS'
export const DELETE_SELECTED_MENU_ITEMS_SUCCESS =
  'DELETE_SELECTED_SELECTED_MENU_ITEMS_SUCCESS'
export const DELETE_SELECTED_MENU_ITEMS_ERROR =
  'DELETE_SELECTED_MENU_ITEMS_ERROR'

export const CLEAR_MENU_ITEM_RESPONSE_INFO = 'CLEAR_MENU_ITEM_RESPONSE_INFO'

// Menu Maker Location
export const GET_MENU_LOCATIONS = 'GET_MENU_LOCATIONS'
export const GET_MENU_LOCATIONS_SUCCESS = 'GET_MENU_LOCATIONS_SUCCESS'
export const GET_MENU_LOCATIONS_ERROR = 'GET_MENU_LOCATIONS_ERROR'

export const GET_MENU_LOCATION_BY_ID = 'GET_MENU_LOCATION_BY_ID'
export const GET_MENU_LOCATION_BY_ID_SUCCESS = 'GET_MENU_LOCATION_BY_ID_SUCCESS'
export const GET_MENU_LOCATION_BY_ID_ERROR = 'GET_MENU_LOCATION_BY_ID_ERROR'

export const POST_MENU_LOCATION = 'POST_MENU_LOCATION'
export const POST_MENU_LOCATION_SUCCESS = 'POST_MENU_LOCATION_SUCCESS'
export const POST_MENU_LOCATION_ERROR = 'POST_MENU_LOCATION_ERROR'

export const PUT_MENU_LOCATION = 'PUT_MENU_LOCATION'
export const PUT_MENU_LOCATION_SUCCESS = 'PUT_MENU_LOCATION_SUCCESS'
export const PUT_MENU_LOCATION_ERROR = 'PUT_MENU_LOCATION_ERROR'

export const DELETE_MENU_LOCATION = 'DELETE_MENU_LOCATION'
export const DELETE_MENU_LOCATION_SUCCESS = 'DELETE_MENU_LOCATION_SUCCESS'
export const DELETE_MENU_LOCATION_ERROR = 'DELETE_MENU_LOCATION_ERROR'

export const DELETE_SELECTED_MENU_LOCATIONS = 'DELETE_SELECTED_MENU_LOCATIONS'
export const DELETE_SELECTED_MENU_LOCATIONS_SUCCESS =
  'DELETE_SELECTED_SELECTED_MENU_LOCATIONS_SUCCESS'
export const DELETE_SELECTED_MENU_LOCATIONS_ERROR =
  'DELETE_SELECTED_MENU_LOCATIONS_ERROR'

export const CLEAR_MENU_LOCATION_RESPONSE_INFO =
  'CLEAR_MENU_LOCATION_RESPONSE_INFO'

// Menu Maker Stations
export const GET_MENU_STATIONS = 'GET_MENU_STATIONS'
export const GET_MENU_STATIONS_SUCCESS = 'GET_MENU_STATIONS_SUCCESS'
export const GET_MENU_STATIONS_ERROR = 'GET_MENU_STATIONS_ERROR'

export const GET_MENU_STATION_BY_ID = 'GET_MENU_STATION_BY_ID'
export const GET_MENU_STATION_BY_ID_SUCCESS = 'GET_MENU_STATION_BY_ID_SUCCESS'
export const GET_MENU_STATION_BY_ID_ERROR = 'GET_MENU_STATION_BY_ID_ERROR'

export const POST_MENU_STATION = 'POST_MENU_STATION'
export const POST_MENU_STATION_SUCCESS = 'POST_MENU_STATION_SUCCESS'
export const POST_MENU_STATION_ERROR = 'POST_MENU_STATION_ERROR'

export const PUT_MENU_STATION = 'PUT_MENU_STATION'
export const PUT_MENU_STATION_SUCCESS = 'PUT_MENU_STATION_SUCCESS'
export const PUT_MENU_STATION_ERROR = 'PUT_MENU_STATION_ERROR'

export const DELETE_MENU_STATION = 'DELETE_MENU_STATION'
export const DELETE_MENU_STATION_SUCCESS = 'DELETE_MENU_STATION_SUCCESS'
export const DELETE_MENU_STATION_ERROR = 'DELETE_MENU_STATION_ERROR'

export const DELETE_SELECTED_MENU_STATIONS = 'DELETE_SELECTED_MENU_STATIONS'
export const DELETE_SELECTED_MENU_STATIONS_SUCCESS =
  'DELETE_SELECTED_SELECTED_MENU_STATIONS_SUCCESS'
export const DELETE_SELECTED_MENU_STATIONS_ERROR =
  'DELETE_SELECTED_MENU_STATIONS_ERROR'

export const CLEAR_MENU_STATION_RESPONSE_INFO =
  'CLEAR_MENU_STATION_RESPONSE_INFO'

// Menu Integration
export const GET_MENU_INTEGRATIONS = 'GET_MENU_INTEGRATIONS'
export const GET_MENU_INTEGRATIONS_SUCCESS = 'GET_MENU_INTEGRATIONS_SUCCESS'
export const GET_MENU_INTEGRATIONS_ERROR = 'GET_MENU_INTEGRATIONS_ERROR'

export const POST_MENU_INTEGRATION_CONNECT = 'POST_MENU_INTEGRATION_CONNECT'
export const POST_MENU_INTEGRATION_CONNECT_SUCCESS =
  'POST_MENU_INTEGRATION_CONNECT_SUCCESS'
export const POST_MENU_INTEGRATION_CONNECT_ERROR =
  'POST_MENU_INTEGRATION_CONNECT_ERROR'

export const POST_MENU_INTEGRATION_DISCONNECT =
  'POST_MENU_INTEGRATION_DISCONNECT'
export const POST_MENU_INTEGRATION_DISCONNECT_SUCCESS =
  'POST_MENU_INTEGRATION_DISCONNECT_SUCCESS'
export const POST_MENU_INTEGRATION_DISCONNECT_ERROR =
  'POST_MENU_INTEGRATION_DISCONNECT_ERROR'

export const POST_MENU_INTEGRATION_SYNC = 'POST_MENU_INTEGRATION_SYNC'
export const POST_MENU_INTEGRATION_SYNC_SUCCESS =
  'POST_MENU_INTEGRATION_SYNC_SUCCESS'
export const POST_MENU_INTEGRATION_SYNC_ERROR =
  'POST_MENU_INTEGRATION_SYNC_ERROR'

export const GET_MENU_MAKER_PROVIDERS = 'GET_MENU_MAKER_PROVIDERS'
export const GET_MENU_MAKER_PROVIDERS_SUCCESS =
  'GET_MENU_MAKER_PROVIDERS_SUCCESS'
export const GET_MENU_MAKER_PROVIDERS_ERROR = 'GET_MENU_MAKER_PROVIDERS_ERROR'

export const CLEAR_MENU_INTEGRATION_INFO = 'CLEAR_MENU_INTEGRATION_INFO'

export const CLEAR_CONFIG_REDUCER = 'CLEAR_CONFIG_REDUCER'
export const GET_FACEBOOK_PAGES = 'GET_FACEBOOK_PAGES'
export const GET_FACEBOOK_PAGES_SUCCESS = 'GET_FACEBOOK_PAGES_SUCCESS'
export const GET_FACEBOOK_PAGES_ERROR = 'GET_FACEBOOK_PAGES_ERROR'

// Notifications
export const GET_EMAIL_NOTIFICATION_ITEMS = 'GET_EMAIL_NOTIFICATION_ITEMS'
export const GET_EMAIL_NOTIFICATION_ITEMS_SUCCESS =
  'GET_EMAIL_NOTIFICATION_ITEMS_SUCCESS'
export const GET_EMAIL_NOTIFICATION_ITEMS_ERROR =
  'GET_EMAIL_NOTIFICATION_ITEMS_ERROR'

export const GET_EMAIL_NOTIFICATION_BY_ID = 'GET_EMAIL_NOTIFICATION_BY_ID'
export const GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS =
  'GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS'
export const GET_EMAIL_NOTIFICATION_BY_ID_ERROR =
  'GET_EMAIL_NOTIFICATION_BY_ID_ERROR'

export const POST_EMAIL_NOTIFICATION_ITEM = 'POST_EMAIL_NOTIFICATION_ITEM'
export const POST_EMAIL_NOTIFICATION_ITEM_SUCCESS =
  'POST_EMAIL_NOTIFICATION_ITEM_SUCCESS'
export const POST_EMAIL_NOTIFICATION_ITEM_ERROR =
  'POST_EMAIL_NOTIFICATION_ITEM_ERROR'

export const PUT_EMAIL_NOTIFICATION_ITEM = 'PUT_EMAIL_NOTIFICATION_ITEM'
export const PUT_EMAIL_NOTIFICATION_ITEM_SUCCESS =
  'PUT_EMAIL_NOTIFICATION_ITEM_SUCCESS'
export const PUT_EMAIL_NOTIFICATION_ITEM_ERROR =
  'PUT_EMAIL_NOTIFICATION_ITEM_ERROR'

export const DELETE_EMAIL_NOTIFICATION_ITEM = 'DELETE_EMAIL_NOTIFICATION_ITEM'
export const DELETE_EMAIL_NOTIFICATION_ITEM_SUCCESS =
  'DELETE_EMAIL_NOTIFICATION_ITEM_SUCCESS'
export const DELETE_EMAIL_NOTIFICATION_ITEM_ERROR =
  'DELETE_EMAIL_NOTIFICATION_ITEM_ERROR'

export const DELETE_SELECTED_EMAIL_NOTIFICATION =
  'DELETE_SELECTED_EMAIL_NOTIFICATION'
export const DELETE_SELECTED_EMAIL_NOTIFICATION_SUCCESS =
  'DELETE_SELECTED_EMAIL_NOTIFICATION_SUCCESS'
export const DELETE_SELECTED_EMAIL_NOTIFICATION_ERROR =
  'DELETE_SELECTED_EMAIL_NOTIFICATION_ERROR'

export const CLEAR_EMAIL_NOTIFICATION_INFO = 'CLEAR_EMAIL_NOTIFICATION_INFO'

export const GET_EMAIL_BUILDER_DATA = 'GET_EMAIL_BUILDER_DATA'
export const GET_EMAIL_BUILDER_DATA_SUCCESS = 'GET_EMAIL_BUILDER_DATA_SUCCESS'
export const GET_EMAIL_BUILDER_DATA_ERROR = 'GET_EMAIL_BUILDER_DATA_ERROR'

export const GET_EMAIL_RECEIVER_DATA = 'GET_EMAIL_RECEIVER_DATA'
export const GET_EMAIL_RECEIVER_DATA_SUCCESS = 'GET_EMAIL_RECEIVER_DATA_SUCCESS'
export const GET_EMAIL_RECEIVER_DATA_ERROR = 'GET_EMAIL_RECEIVER_DATA_ERROR'

export const GET_DEVICE_RESOLUTION = 'GET_DEVICE_RESOLUTION'
export const GET_DEVICE_RESOLUTION_SUCCESS = 'GET_DEVICE_RESOLUTION_SUCCESS'
export const GET_DEVICE_RESOLUTION_ERROR = 'GET_DEVICE_RESOLUTION_ERROR'

export const POST_SSO_DETAILS = 'POST_SSO_DETAILS'
export const POST_SSO_DETAILS_SUCCESS = 'POST_SSO_DETAILS_SUCCESS'
export const POST_SSO_DETAILS_FAILURE = 'POST_SSO_DETAILS_FAILURE'
export const CLEAR_SSO_DETAILS = 'CLEAR_SSO_DETAILS'

export const TABLEAU_LOGIN = 'TABLEAU_LOGIN'
export const TABLEAU_LOGIN_SUCCESS = 'TABLEAU_LOGIN_SUCCESS'
export const TABLEAU_LOGIN_FAILURE = 'TABLEAU_LOGIN_FAILURE'

export const TABLEAU_LOGOUT = 'TABLEAU_LOGOUT'
export const TABLEAU_LOGOUT_SUCCESS = 'TABLEAU_LOGOUT_SUCCESS'
export const TABLEAU_LOGOUT_FAILURE = 'TABLEAU_LOGOUT_FAILURE'

export const GET_TABLEAU_VIEWS = 'GET_TABLEAU_VIEWS'
export const GET_TABLEAU_VIEWS_SUCCESS = 'GET_TABLEAU_VIEWS_SUCCESS'
export const GET_TABLEAU_VIEWS_ERROR = 'GET_TABLEAU_VIEWS_ERROR'

export const GET_TABLEAU_WORKBOOK = 'GET_TABLEAU_WORKBOOK'
export const GET_TABLEAU_WORKBOOK_SUCCESS = 'GET_TABLEAU_WORKBOOK_SUCCESS'
export const GET_TABLEAU_WORKBOOK_ERROR = 'GET_TABLEAU_WORKBOOK_ERROR'

export const CLEAR_TABLEAU_RESPONSE_INFO = 'CLEAR_TABLEAU_RESPONSE_INFO'
export const PUT_SSO_DETAILS = 'PUT_SSO_DETAILS'
export const PUT_SSO_DETAILS_SUCCESS = 'PUT_SSO_DETAILS_SUCCESS'
export const PUT_SSO_DETAILS_FAILURE = 'PUT_SSO_DETAILS_FAILURE'

export const GET_MEDIA_ASSOCIATIONS = 'GET_MEDIA_ASSOCIATIONS'
export const GET_MEDIA_ASSOCIATIONS_SUCCESS = 'GET_MEDIA_ASSOCIATIONS_SUCCESS'
export const GET_MEDIA_ASSOCIATIONS_ERROR = 'GET_MEDIA_ASSOCIATIONS_ERROR'
export const CLEAR_MEDIA_ASSOCIATIONS = 'CLEAR_MEDIA_ASSOCIATIONS'

export const GET_TEMPLATE_ASSOCIATIONS = 'GET_TEMPLATE_ASSOCIATIONS'
export const GET_TEMPLATE_ASSOCIATIONS_SUCCESS =
  'GET_TEMPLATE_ASSOCIATIONS_SUCCESS'
export const GET_TEMPLATE_ASSOCIATIONS_ERROR = 'GET_TEMPLATE_ASSOCIATIONS_ERROR'
export const CLEAR_TEMPLATE_ASSOCIATIONS = 'CLEAR_TEMPLATEASSOCIATIONS'

export const GET_PLAYLIST_ASSOCIATIONS = 'GET_PLAYLIST_ASSOCIATIONS'
export const GET_PLAYLIST_ASSOCIATIONS_SUCCESS =
  'GET_PLAYLIST_ASSOCIATIONS_SUCCESS'
export const GET_PLAYLIST_ASSOCIATIONS_ERROR = 'GET_PLAYLIST_ASSOCIATIONS_ERROR'
export const CLEAR_PLAYLIST_ASSOCIATIONS = 'CLEAR_PLAYLIST_ASSOCIATIONS'

export const GET_POSTHOG_EVENTS = 'GET_POSTHOG_EVENTS'
export const GET_POSTHOG_EVENTS_SUCCESS = 'GET_POSTHOG_EVENTS_SUCCESS'
export const GET_POSTHOG_EVENTS_ERROR = 'GET_POSTHOG_EVENTS_ERROR'

export const GET_TEMPLATE_GALLERY_ITEMS = 'GET_TEMPLATE_GALLERY_ITEMS'
export const GET_TEMPLATE_GALLERY_ITEMS_SUCCESS =
  'GET_TEMPLATE_GALLERY_ITEMS_SUCCESS'
export const GET_TEMPLATE_GALLERY_ITEMS_ERROR =
  'GET_TEMPLATE_GALLERY_ITEMS_ERROR'

export const GET_TEMPLATE_GALLERY_BY_ID = 'GET_TEMPLATE_GALLERY_BY_ID'
export const GET_TEMPLATE_GALLERY_BY_ID_SUCCESS =
  'GET_TEMPLATE_GALLERY_BY_ID_SUCCESS'
export const GET_TEMPLATE_GALLERY_BY_ID_ERROR =
  'GET_TEMPLATE_GALLERY_BY_ID_ERROR'

export const CLEAR_TEMPLATE_GALLERY_ITEM_RESPONSE =
  'CLEAR_TEMPLATE_GALLERY_ITEM_RESPONSE'

export const GET_TEMPLATE_GALLERY_PREVIEW = 'GET_TEMPLATE_GALLERY_PREVIEW'
export const GET_TEMPLATE_GALLERY_PREVIEW_SUCCESS =
  'GET_TEMPLATE_GALLERY_PREVIEW_SUCCESS'
export const GET_TEMPLATE_GALLERY_PREVIEW_ERROR =
  'GET_TEMPLATE_GALLERY_PREVIEW_ERROR'

export const COPY_TEMPLATE_GALLERY = 'COPY_TEMPLATE_GALLERY'
export const COPY_TEMPLATE_GALLERY_SUCCESS = 'COPY_TEMPLATE_GALLERY_SUCCESS'
export const COPY_TEMPLATE_GALLERY_ERROR = 'COPY_TEMPLATE_GALLERY_ERROR'
export const CLEAR_TEMPLATE_GALLERY_INFO = 'CLEAR_TEMPLATE_GALLERY_INFO'

export const GET_DEVICE_VIRTUAL_PLAYER_STATS = 'GET_DEVICE_VIRTUAL_PLAYER_STATS'
export const GET_DEVICE_VIRTUAL_PLAYER_STATS_SUCCESS =
  'GET_DEVICE_VIRTUAL_PLAYER_STATS_SUCCESS'
export const GET_DEVICE_VIRTUAL_PLAYER_STATS_ERROR =
  'GET_DEVICE_VIRTUAL_PLAYER_STATS_ERROR'
export const CLEAR_GET_DEVICE_VIRTUAL_PLAYER_STATS =
  'CLEAR_GET_DEVICE_VIRTUAL_PLAYER_STATS'

export const PUT_REGIONAL_SETTINGS = 'PUT_REGIONAL_SETTINGS'
export const PUT_REGIONAL_SETTINGS_SUCCESS = 'PUT_REGIONAL_SETTINGS_SUCCESS'
export const PUT_REGIONAL_SETTINGS_ERROR = 'PUT_REGIONAL_SETTINGS_ERROR'
export const CLEAR_PUT_REGIONAL_SETTINGS = 'CLEAR_PUT_REGIONAL_SETTINGS'

export const REGENERATE_PIN_CODE = 'REGENERATE_PIN_CODE'

export const GET_SFTP_FOLDER = 'GET_SFTP_FOLDER'
export const GET_SFTP_FOLDER_SUCCESS = 'GET_SFTP_FOLDER_SUCCESS'
export const GET_SFTP_FOLDER_ERROR = 'GET_SFTP_FOLDER_ERROR'
export const CLEAR_SFTP_FOLDER_DATA = 'CLEAR_SFTP_FOLDER_DATA'

export const GET_DEVICE_POWER_SCHEDULE = 'GET_DEVICE_POWER_SCHEDULE'
export const GET_DEVICE_POWER_SCHEDULE_SUCCESS =
  'GET_DEVICE_POWER_SCHEDULE_SUCCESS'
export const GET_DEVICE_POWER_SCHEDULE_ERROR = 'GET_DEVICE_POWER_SCHEDULE_ERROR'
export const CLEAR_GET_DEVICE_POWER_SCHEDULE_INFO =
  'CLEAR_GET_DEVICE_POWER_SCHEDULE_INFO'
export const PUT_DEVICE_POWER_SCHEDULE = 'PUT_DEVICE_POWER_SCHEDULE'
export const PUT_DEVICE_POWER_SCHEDULE_SUCCESS =
  'PUT_DEVICE_POWER_SCHEDULE_SUCCESS'
export const PUT_DEVICE_POWER_SCHEDULE_ERROR = 'PUT_DEVICE_POWER_SCHEDULE_ERROR'
export const CLEAR_PUT_DEVICE_POWER_SCHEDULE_INFO =
  'CLEAR_PUT_DEVICE_POWER_SCHEDULE_INFO'

export const GET_AI_VIDEO_AVATARS_LIST = 'GET_AI_VIDEO_AVATARS_LIST'
export const GET_AI_VIDEO_AVATARS_LIST_SUCCESS =
  'GET_AI_VIDEO_AVATARS_LIST_SUCCESS'
export const GET_AI_VIDEO_AVATARS_LIST_ERROR = 'GET_AI_VIDEO_AVATARS_LIST_ERROR'
export const GET_AI_VIDEO_VOICES_LIST = 'GET_AI_VIDEO_VOICES_LIST'
export const GET_AI_VIDEO_VOICES_LIST_SUCCESS =
  'GET_AI_VIDEO_VOICES_LIST_SUCCESS'
export const GET_AI_VIDEO_VOICES_LIST_ERROR = 'GET_AI_VIDEO_VOICES_LIST_ERROR'
export const GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST =
  'GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST'
export const GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST_SUCCESS =
  'GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST_SUCCESS'
export const GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST_ERROR =
  'GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST_ERROR'
