import * as types from './index'

export const getMenuPresets = params => ({
  type: types.GET_MENU_PRESETS,
  params
})

export const getStockMenuPresets = params => ({
  type: types.GET_STOCK_MENU_PRESETS,
  params
})

export const getSharedMenuPresets = params => ({
  type: types.GET_SHARED_MENU_PRESETS,
  params
})

export const getMenuPresetItem = (id, isEdit = false) => ({
  type: types.GET_MENU_PRESET_ITEM,
  id,
  isEdit
})

export const postMenuPreset = data => ({
  type: types.POST_MENU_PRESET,
  data
})

export const putMenuPreset = (id, data) => ({
  type: types.PUT_MENU_PRESET,
  id,
  data
})

export const deleteMenuPreset = (id, name) => ({
  type: types.DELETE_MENU_PRESET,
  id,
  name
})

export const shareMenuPreset = (id, data) => ({
  type: types.SHARE_MENU_PRESET,
  id,
  data
})

export const clearMenuPresetRespnseInfo = () => ({
  type: types.CLEAR_MENU_PRESET_RESPONSE_INFO
})

export const getMenuComponents = params => ({
  type: types.GET_MENU_COMPONENTS,
  params
})

export const getStockMenuComponents = params => ({
  type: types.GET_STOCK_MENU_COMPONENTS,
  params
})

export const getSharedMenuComponents = params => ({
  type: types.GET_SHARED_MENU_COMPONENTS,
  params
})

export const getMenuComponentItem = (id, isEdit = false) => ({
  type: types.GET_MENU_COMPONENT_ITEM,
  id,
  isEdit
})

export const postMenuComponent = data => ({
  type: types.POST_MENU_COMPONENT,
  data
})

export const putMenuComponent = (id, data) => ({
  type: types.PUT_MENU_COMPONENT,
  id,
  data
})

export const deleteMenuComponent = (id, name) => ({
  type: types.DELETE_MENU_COMPONENT,
  id,
  name
})

export const shareMenuComponent = (id, data) => ({
  type: types.SHARE_MENU_COMPONENT,
  id,
  data
})

export const setOpenBottomSidebar = state => ({
  type: types.SET_OPEN_BOTTOM_SIDEBAR,
  payload: state
})

export const selectMenuComponent = item => ({
  type: types.SELECT_MENU_COMPONENT,
  payload: item
})

export const selectMenuPreset = item => ({
  type: types.SELECT_MENU_PRESET,
  payload: item
})

export const setMenuPreset = item => ({
  type: types.SET_MENU_PRESET,
  payload: item
})

export const clearMenuComponentRespnseInfo = () => ({
  type: types.CLEAR_MENU_COMPONENT_RESPONSE_INFO
})

export const getMenuBoard = params => ({
  type: types.GET_MENU_BOARD,
  params
})

export const setMenuBoardItem = data => ({
  type: types.SET_MENU_BOARD_ITEM,
  payload: data
})

export const setMenuBoardItemFromMedia = data => ({
  type: types.SET_MENU_BOARD_ITEM_FROM_MEDIA,
  payload: data
})

export const postMenuBoard = data => ({
  type: types.POST_MENU_BOARD,
  payload: data
})

export const postMenuBoardMedia = data => ({
  type: types.POST_MENU_BOARD_MEDIA,
  payload: data
})

export const putMenuBoard = (id, mediaData, isClone) => ({
  type: types.PUT_MENU_BOARD,
  payload: {
    id,
    mediaData,
    isClone
  }
})

export const putMenuBoardMedia = mediaData => ({
  type: types.PUT_MENU_BOARD_MEDIA,
  payload: {
    mediaData: mediaData.data,
    id: mediaData.id
  }
})

export const clearMenuBoardResponseInfo = () => ({
  type: types.CLEAR_MENU_BOARD_RESPONSE_INFO
})

export const selectMenuBoard = item => ({
  type: types.SELECT_MENU_BOARD,
  payload: item
})

export const setMenuBoardEdit = item => ({
  type: types.SET_MENU_BOARD_EDIT,
  payload: item
})
// images

export const getPatterns = params => ({
  type: types.GET_MENU_MAKER_PATTERNS,
  data: params
})

export const getBackgroundImages = params => ({
  type: types.GET_MENU_MAKER_BACKGROUND_IMAGES,
  data: params
})

// icons

export const getShapes = params => ({
  type: types.GET_MENU_MAKER_SHAPES,
  data: params
})

export const getObjects = params => ({
  type: types.GET_MENU_MAKER_OBJECTS,
  data: params
})

export const getIcons = params => ({
  type: types.GET_MENU_MAKER_ICONS,
  data: params
})

export const clearIcons = () => ({
  type: types.CLEAR_MENU_MAKER_ICONS
})

export const getIconsGroup = params => ({
  type: types.GET_MENU_MAKER_ICONS_GROUP,
  data: params
})

export const getEmojis = params => ({
  type: types.GET_MENU_MAKER_EMOJIS,
  data: params
})

export const getStockImages = params => ({
  type: types.GET_MENU_MAKER_STOCK_IMAGES,
  params
})

export const clearMenuMakerSidebar = () => ({
  type: types.CLEAR_MM_SIDEBAR
})

export const showMenuDesignPreview = (data, key) => ({
  type: types.GET_MENU_DESIGN_PREVIEW,
  data,
  meta: { key }
})
export const closeMenuDesignPreview = () => ({
  type: types.CLOSE_MENU_DESIGN_PREVIEW
})

export const clearMenuDesignPreview = () => ({
  type: types.CLEAR_MENU_DESIGN_PREVIEW
})
