import * as types from './index'

const setPendingStatus = status => ({
  type: types.SET_PENDING_STATUS,
  payload: status
})

const setGroupModalHeight = height => ({
  type: types.SET_GROUP_MODAL_HEIGHT,
  payload: height
})

const setConfirmationRequired = status => ({
  type: types.SET_CONFIRMATION_REQUIRED,
  payload: status
})

const setLastDropId = id => ({
  type: types.SET_DND_LAST_DROP_ID,
  payload: id
})

const setNavigationDisabled = state => ({
  type: types.SET_NAVIGATION_DISABLED,
  payload: state
})

const addOpenedTooltip = id => ({
  type: types.ADD_TOOLTIP_ID,
  payload: id
})

const removeOpenedTooltip = id => ({
  type: types.REMOVE_TOOLTIP_ID,
  payload: id
})

export {
  setPendingStatus,
  setGroupModalHeight,
  setConfirmationRequired,
  setLastDropId,
  setNavigationDisabled,
  addOpenedTooltip,
  removeOpenedTooltip
}
