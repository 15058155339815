import React, { cloneElement, forwardRef, useCallback, useMemo } from 'react'
import { withStyles, Grid, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import FilterPopup from 'components/Popup/FilterPopup'
import { CircleIconButton } from 'components/Buttons'
import HelpIconLink from 'components/HelpIconLink'
import useHelpLink from 'hooks/useHelpLink'
import PageTitle from 'components/PageContainer/PageTitle'
import MaterialPopup from 'components/Popup/MaterialPopup'
import { PAGE_CONTAINER_CLASS_NAME, zIndexes } from 'constants/stylesConstants'
import SubheaderPagination from './SubheaderPagination'

const styles = theme => {
  const { palette, type } = theme
  return {
    pageContainer: {
      position: 'relative',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette[type].pageContainer.border,
      background: palette[type].pageContainer.background,
      borderRadius: 8,
      boxShadow: `0 2px 4px 0 ${palette[type].pageContainer.shadow}`,
      width: 'calc(100% - 2px)',
      boxSizing: 'content-box !important',
      margin: 0
    },
    pageContainerHeader: {
      paddingLeft: 27,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: palette[type].pageContainer.header.border,
      backgroundColor: palette[type].pageContainer.header.background,
      lineHeight: '65px',
      borderRadius: '8px 8px 0 0'
    },
    infoIconWrap: {
      paddingLeft: '7px',
      paddingRight: '7px',
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftColor: palette[type].pageContainer.header.infoIcon.border
    },
    pageContainerSubHeader: {
      paddingLeft: 27,
      borderBottom: `1px solid ${palette[type].pageContainer.subHeader.border}`,
      borderTop: `1px solid ${palette[type].pageContainer.subHeader.border}`,
      backgroundColor: palette[type].pageContainer.subHeader.background
    },
    circleIcon: {
      color: palette[type].pageContainer.header.infoIcon.color
    },
    settingsDropdown: {
      width: '315px'
    },
    isSelecting: {
      backgroundColor: palette[type].pageContainer.header.selecting,
      color: palette[type].pageContainer.header.titleColor
    }
  }
}

const dropdownStyle = {
  borderRadius: 6,
  width: 315,
  animation: 'fade-in 200ms',
  zIndex: zIndexes.tableHeaderFilterPopup
}

const PageContainer = forwardRef(
  (
    {
      classes,
      children,
      pageTitle,
      pageTitleIcon,
      selectedCount,
      MiddleActionComponent,
      ActionButtonsComponent,
      SubHeaderMenuComponent,
      SubHeaderLeftActionComponent,
      SubHeaderMiddleActionComponent,
      SubHeaderRightActionComponent,
      subHeader = true,
      header = true,
      pageContainerClassName = '',
      subHeaderRightActionComponentClassName = '',
      circleIconClickHandler = null,
      circleIconTitle,
      replaceInfoIcon = '',
      isShowSubHeaderComponent = true,
      subHeaderClassName = '',
      helpPageName = '',
      subHeaderPopupStyle = {},
      pageHeaderClassName = '',
      pageHeaderTitleClassName = '',
      filterPopupRootClassName = '',
      circleIconClassName = '',
      hasMaterialPopup = false,
      headerPagination = false,
      subheaderPaginationObj,
      handlePageChange
    },
    ref
  ) => {
    const helpUrl = useHelpLink(helpPageName)
    const renderSubHeaderMenuComponent = useCallback(
      props => cloneElement(SubHeaderMenuComponent, props),
      [SubHeaderMenuComponent]
    )

    const popupTrigger = useMemo(
      () => (
        <CircleIconButton
          className={classNames(
            `hvr-grow ${classes.circleIcon}`,
            circleIconClassName
          )}
        >
          <i className="fa-regular fa-sliders" />
        </CircleIconButton>
      ),
      [circleIconClassName, classes.circleIcon]
    )

    return (
      <div
        ref={ref}
        className={classNames(
          classes.pageContainer,
          pageContainerClassName,
          PAGE_CONTAINER_CLASS_NAME
        )}
      >
        {header && (
          <header
            className={classNames(
              classes.pageContainerHeader,
              pageHeaderClassName
            )}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <PageTitle
                  title={pageTitle}
                  icon={pageTitleIcon}
                  selectedCount={selectedCount}
                  titleClassName={pageHeaderTitleClassName}
                />
              </Grid>
              {MiddleActionComponent && (
                <Grid item>{MiddleActionComponent}</Grid>
              )}
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>{ActionButtonsComponent}</Grid>
                  {!!helpUrl && (
                    <Grid item className={classes.infoIconWrap}>
                      <HelpIconLink
                        link={helpUrl}
                        iconClassName={replaceInfoIcon}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </header>
        )}
        {subHeader && (
          <div
            className={classNames(
              classes.pageContainerSubHeader,
              subHeaderClassName
            )}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>{SubHeaderLeftActionComponent}</Grid>
              {SubHeaderMiddleActionComponent && (
                <Grid item>{SubHeaderMiddleActionComponent}</Grid>
              )}
              <Grid
                xs={headerPagination ? 5 : 2}
                item
                container
                justifyContent="flex-end"
                className={subHeaderRightActionComponentClassName}
              >
                {headerPagination && (
                  <SubheaderPagination
                    paginationObj={subheaderPaginationObj}
                    handlePageChange={handlePageChange}
                    subHeaderPresent={isShowSubHeaderComponent}
                  />
                )}
                {SubHeaderRightActionComponent && (
                  <Grid item>{SubHeaderRightActionComponent}</Grid>
                )}
                <Grid item className={classes.infoIconWrap}>
                  {!circleIconClickHandler &&
                    isShowSubHeaderComponent &&
                    !hasMaterialPopup && (
                      <FilterPopup
                        trigger={popupTrigger}
                        rootClassName={filterPopupRootClassName}
                      >
                        {SubHeaderMenuComponent}
                      </FilterPopup>
                    )}

                  {!circleIconClickHandler &&
                    isShowSubHeaderComponent &&
                    hasMaterialPopup && (
                      <MaterialPopup
                        on="click"
                        placement="bottom-end"
                        style={{
                          ...dropdownStyle,
                          ...subHeaderPopupStyle
                        }}
                        trigger={popupTrigger}
                      >
                        {close => (
                          <div>{renderSubHeaderMenuComponent(close)}</div>
                        )}
                      </MaterialPopup>
                    )}
                  {circleIconClickHandler && (
                    <Tooltip arrow title={circleIconTitle}>
                      <CircleIconButton
                        onClick={circleIconClickHandler}
                        className={classNames(
                          `hvr-grow ${classes.circleIcon}`,
                          circleIconClassName
                        )}
                      >
                        <i className="fa-regular fa-sliders" />
                      </CircleIconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        {children}
      </div>
    )
  }
)

PageContainer.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  pageTitle: PropTypes.string,
  pageTitleIcon: PropTypes.node,
  MiddleActionComponent: PropTypes.node,
  ActionButtonsComponent: PropTypes.node,
  SubHeaderMenuComponent: PropTypes.node,
  SubHeaderLeftActionComponent: PropTypes.node,
  SubHeaderMiddleActionComponent: PropTypes.node,
  SubHeaderRightActionComponent: PropTypes.node,
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  header: PropTypes.bool,
  pageContainerClassName: PropTypes.string,
  subHeaderRightActionComponentClassName: PropTypes.string,
  circleIconClickHandler: PropTypes.func,
  circleIconTitle: PropTypes.string,
  replaceInfoIcon: PropTypes.string,
  isShowSubHeaderComponent: PropTypes.bool,
  helpPageName: PropTypes.string,
  subHeaderClassName: PropTypes.string,
  selectedCount: PropTypes.number,
  subHeaderPopupStyle: PropTypes.object,
  headerPagination: PropTypes.bool,
  handlePageChange: PropTypes.func,
  subheaderPaginationObj: PropTypes.object
}

export default withStyles(styles)(PageContainer)
