import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { userDetailsSelectorMemoized } from 'selectors/userSelectors'
import { useUserRole } from 'hooks/tableLibrary'
import { isFeatureAvailable } from 'utils/featureUtils'

export default function useFeaturesAvailability(...featureNames) {
  const role = useUserRole()

  const { response } = useSelector(userDetailsSelectorMemoized)

  return useMemo(
    () =>
      featureNames.map(featureName =>
        role.system ? true : isFeatureAvailable(featureName, response)
      ),
    [role, response, featureNames]
  )
}
