export const DEVICE_HEALTH_PENDING = 'DEVICE_MODULE/DEVICE_HEALTH_PENDING'
export const DEVICE_HEALTH_SUCCESS = 'DEVICE_MODULE/DEVICE_HEALTH_SUCCESS'
export const DEVICE_HEALTH_FAILURE = 'DEVICE_MODULE/DEVICE_HEALTH_FAILURE'
export const TRIGGER_HEALTH_PENDING = 'DEVICE_MODULE/TRIGGER_HEALTH_PENDING'
export const TRIGGER_HEALTH_SUCCESS = 'DEVICE_MODULE/TRIGGER_HEALTH_SUCCESS'
export const TRIGGER_HEALTH_FAILURE = 'DEVICE_MODULE/TRIGGER_HEALTH_FAILURE'
export const REFRESH_HEALTH_FAILURE = 'DEVICE_MODULE/REFRESH_HEALTH_FAILURE'
export const CLEAR_DEVICE_HEALTH = 'DEVICE_MODULE/CLEAR_DEVICE_HEALTH'
export const PREVIEWS_PENDING = 'DEVICE_MODULE/PREVIEWS_PENDING'
export const PREVIEWS_SUCCESS = 'DEVICE_MODULE/PREVIEWS_SUCCESS'
export const PREVIEWS_FAILURE = 'DEVICE_MODULE/PREVIEWS_FAILURE'
export const TRIGGER_PREVIEW_PENDING = 'DEVICE_MODULE/TRIGGER_PREVIEW_PENDING'
export const TRIGGER_PREVIEW_SUCCESS = 'DEVICE_MODULE/TRIGGER_PREVIEW_SUCCESS'
export const TRIGGER_PREVIEW_FAILURE = 'DEVICE_MODULE/TRIGGER_PREVIEW_FAILURE'
export const PREVIEW_THUMBNAIL_PENDING =
  'DEVICE_MODULE/PREVIEW_THUMBNAIL_PENDING'
export const PREVIEW_THUMBNAIL_SUCCESS =
  'DEVICE_MODULE/PREVIEW_THUMBNAIL_SUCCESS'
export const PREVIEW_THUMBNAIL_FAILURE =
  'DEVICE_MODULE/PREVIEW_THUMBNAIL_FAILURE'
export const OPEN_PREVIEW_MODAL = 'DEVICE_MODULE/OPEN_PREVIEW_MODAL'
export const CLEAR_PREVIEW_MODAL = 'DEVICE_MODULE/CLEAR_PREVIEW_MODAL'

export function fetchDeviceHealth(id, isClear = false, onSuccess) {
  return {
    type: DEVICE_HEALTH_PENDING,
    onSuccess,
    isClear,
    id
  }
}

export function deviceHealthSuccess(data) {
  return {
    type: DEVICE_HEALTH_SUCCESS,
    data
  }
}

export function deviceHealthFailure(error) {
  return {
    type: DEVICE_HEALTH_FAILURE,
    error
  }
}

export function fetchTriggerDeviceHealth(id, onSuccess) {
  return {
    type: TRIGGER_HEALTH_PENDING,
    onSuccess,
    id
  }
}

export function triggerDeviceHealthSuccess() {
  return {
    type: TRIGGER_HEALTH_SUCCESS
  }
}

export function triggerDeviceHealthFailure(error) {
  return {
    type: TRIGGER_HEALTH_FAILURE,
    error
  }
}

export function refreshHealthFailure() {
  return {
    type: REFRESH_HEALTH_FAILURE
  }
}

export function clearDeviceHealth() {
  return {
    type: CLEAR_DEVICE_HEALTH
  }
}

export function fetchTriggerDevicePreview(id, onSuccess) {
  return {
    type: TRIGGER_PREVIEW_PENDING,
    onSuccess,
    id
  }
}

export function triggerDevicePreviewSuccess() {
  return {
    type: TRIGGER_PREVIEW_SUCCESS
  }
}

export function triggerDevicePreviewFailure(error) {
  return {
    type: TRIGGER_PREVIEW_FAILURE,
    error
  }
}

export function fetchPreviews({ meta, ...params }) {
  return {
    type: PREVIEWS_PENDING,
    payload: params,
    meta
  }
}

export function previewsSuccess(data, meta) {
  return {
    type: PREVIEWS_SUCCESS,
    data,
    meta
  }
}

export function previewsFailure() {
  return {
    type: PREVIEWS_FAILURE
  }
}

export function fetchDevicePreviewThumbnail(id, onFinish) {
  return {
    type: PREVIEW_THUMBNAIL_PENDING,
    id,
    onFinish
  }
}

export function previewThumbnailSuccess(data) {
  return {
    type: PREVIEW_THUMBNAIL_SUCCESS,
    payload: data
  }
}

export function previewThumbnailFailure(error) {
  return {
    type: PREVIEW_THUMBNAIL_FAILURE,
    payload: error
  }
}

export function openDevicePreviewModal(id, orientation = 'Landscape') {
  return {
    type: OPEN_PREVIEW_MODAL,
    payload: {
      id,
      orientation
    }
  }
}

export function clearDevicePreviewModal() {
  return {
    type: CLEAR_PREVIEW_MODAL
  }
}
