import { call, put } from 'redux-saga/effects'

import * as types from '../../actions/public'

import * as deviceNocService from '../../services/public/deviceNoc'
import { transformMeta } from 'utils/tableUtils'

function* getPublicNocGeneralItems({ params }) {
  try {
    const { meta, data } = yield call(
      deviceNocService.getPublicNocGeneralItems,
      params
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_PUBLIC_NOC_GENERAL_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_PUBLIC_NOC_GENERAL_ITEMS_ERROR,
      payload: error
    })
  }
}

function* getPublicNocHealthItems({ params }) {
  try {
    const { meta, data } = yield call(
      deviceNocService.getPublicNocHealthItems,
      params
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_PUBLIC_NOC_HEALTH_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_PUBLIC_NOC_HEALTH_ITEMS_ERROR, payload: error })
  }
}

function* getPublicNocNetworkItems({ params }) {
  try {
    const { meta, data } = yield call(
      deviceNocService.getPublicNocNetworkItems,
      params
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_PUBLIC_NOC_NETWORK_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_PUBLIC_NOC_NETWORK_ITEMS_ERROR,
      payload: error
    })
  }
}

function* getPublicNocAccessToken({ token }) {
  try {
    const data = yield call(deviceNocService.getPublicNocAccessToken, token)

    yield put({
      type: types.GET_PUBLIC_NOC_ACCESS_TOKEN_SUCCESS,
      response: data
    })
  } catch (error) {
    yield put({
      type: types.GET_PUBLIC_NOC_ACCESS_TOKEN_ERROR,
      payload: error
    })
  }
}

const deviceNoc = {
  getPublicNocGeneralItems,
  getPublicNocHealthItems,
  getPublicNocNetworkItems,
  getPublicNocAccessToken
}

export default deviceNoc
