import * as types from './index'

const getMediaItemsAction = params => ({
  type: types.GET_MEDIA_ITEMS,
  params,
  loading: true
})

const getMediaByIdAction = id => ({
  type: types.GET_MEDIA_BY_ID,
  id
})

const resetMediaFetchParamsAction = () => ({
  type: types.CLEAR_MEDIA_FETCH_PARAMS
})

const clearGetMediaItemsInfoAction = () => ({
  type: types.CLEAR_GET_MEDIA_ITEMS_INFO
})

const clearMediaItemsAction = params => ({
  type: types.CLEAR_MEDIA_ITEMS,
  params
})

const addMedia = ({ mediaName, tabName, ignoreSnackbarFields, data }) => ({
  type: types.ADD_MEDIA,
  data,
  meta: {
    mediaName: mediaName || '',
    tabName: tabName || '',
    ignoreSnackbarFields
  }
})

const editMedia = ({
  id,
  mediaName,
  tabName,
  ignoreSnackbarFields,
  data,
  method
}) => ({
  type: types.PUT_MEDIA,
  data,
  meta: {
    id,
    mediaName,
    tabName,
    ignoreSnackbarFields,
    method
  }
})

const cloneMediaAndUpdate = ({
  id,
  mediaName,
  tabName,
  ignoreSnackbarFields,
  data
}) => ({
  type: types.CLONE_MEDIA_AND_UPDATE,
  data,
  meta: { id, mediaName, tabName, ignoreSnackbarFields }
})

const clearAddedMedia = meta => ({
  type: types.CLEAR_ADDED_MEDIA,
  meta
})

const clearEditMedia = () => ({
  type: types.CREAR_MEDIA_ITEM
})

const clearMediaPut = () => ({
  type: types.CLEAR_MEDIA_PUT
})

const clearMediaItemStatus = () => ({
  type: types.CLEAR_MEDIA_ITEM_STATUS
})

const getMediaPreview = (id, isZoomToFit, resolution, key, orientation) => ({
  type: types.GET_MEDIA_PREVIEW,
  data: { id, isZoomToFit, resolution, orientation },
  meta: { key }
})

const generateMediaPreview = (data, onFailure, ignoreSnackbarFields) => ({
  type: types.GENERATE_MEDIA_PREVIEW,
  data,
  onFailure,
  meta: { ignoreSnackbarFields }
})

const getPreviewFromUrl = url => ({
  type: types.GET_URL_PREVIEW,
  payload: `<div style='display: flex;justify-content: center;align-items: center;height: 100%;width:100%'>
              <img style='width: 100%;height: 100%;object-fit: contain' alt='preview' src='${url}'/>
            </div>`
})

const closeMediaPreview = () => ({
  type: types.CLOSE_MEDIA_PREVIEW
})

const clearMediaPreview = () => ({
  type: types.CLEAR_MEDIA_PREVIEW
})

const showMediaPreview = () => ({
  type: types.SHOW_MEDIA_PREVIEW
})

const clearMediaGroupItemsInfo = () => ({
  type: types.CLEAR_MEDIA_GROUP_ITEMS_RESPONSE_INFO
})

const getMediaGroupItemsAction = (id, params) => ({
  type: types.GET_MEDIA_GROUP_ITEMS,
  payload: { id, params }
})

const clearGetMediaGroupItemsInfoAction = () => ({
  type: types.CLEAR_GET_MEDIA_GROUP_ITEMS_INFO
})

const postMediaGroupItemAction = data => ({
  type: types.POST_MEDIA_GROUP_ITEM,
  payload: data
})

const clearPostMediaGroupItemInfoAction = () => ({
  type: types.CLEAR_POST_MEDIA_GROUP_ITEM_INFO
})

const deleteMediaGroupItemAction = data => ({
  type: types.DELETE_MEDIA_GROUP_ITEM,
  payload: data
})

const clearDeleteMediaGroupItemInfoAction = () => ({
  type: types.CLEAR_DELETE_MEDIA_GROUP_ITEM_INFO
})

const getMediaItemById = id => ({
  type: types.GET_MEDIA_ITEM_BY_ID,
  data: id
})

const getFeatureMediaItemsAction = data => ({
  type: types.GET_FEATURE_MEDIA_ITEMS,
  payload: data
})

const clearGetFeatureMediaItemsInfoAction = () => ({
  type: types.CLEAR_GET_FEATURE_MEDIA_ITEMS_INFO
})

const putOrganizeFeatureMediaItemsAction = (id, data) => {
  return {
    type: types.PUT_ORGANIZE_FEATURE_MEDIA_ITEMS,
    id: id,
    payload: data
  }
}

export function getMediaCapAlert(params) {
  return {
    type: types.REQUEST_MEDIA_CAP_ALERT,
    params: params
  }
}

export function mediaCapAlertSuccess({ data, meta }) {
  return {
    type: types.MEDIA_CAP_ALERT_SUCCESS,
    payload: {
      data,
      meta
    }
  }
}

export function mediaCapAlertError(error) {
  return {
    type: types.MEDIA_CAP_ALERT_ERROR,
    payload: error
  }
}

export function getMediaIpawsAlert(params) {
  return {
    type: types.MEDIA_IPAWS_ALERT,
    params: params
  }
}

const postFavoriteMedia = data => ({
  type: types.POST_FAVORITE_MEDIA,
  data
})

const clearPostFavoriteMedia = () => ({
  type: types.CLEAR_POST_FAVORITE_MEDIA
})

const setMediaDependency = payload => ({
  type: types.SET_MEDIA_DEPENDENCY,
  payload
})

const clearMediaDependency = () => ({
  type: types.CLEAR_MEDIA_DEPENDENCY
})

const addMediaToPlaylistsAction = ({ playlistIds, mediaIds }) => ({
  type: types.POST_MEDIA_TO_PLAYLISTS,
  payload: { playlistIds: playlistIds, mediaIds: mediaIds },
  meta: { mediaIds, playlistIds }
})

const clearAddMediaToPlaylistsAction = () => ({
  type: types.CLEAR_POST_MEDIA_TO_PLAYLISTS
})

const deleteSelectedMedia = payload => ({
  type: types.DELETE_SELECTED_MEDIA,
  payload
})

const clearMediaResponseInfo = () => ({
  type: types.CLEAR_MEDIA_RESPONSE_INFO
})

const shareMedia = data => ({
  type: types.SHARE_MEDIA,
  data
})

const publishDesign = data => ({
  type: types.PUBLISH_DESIGN,
  data
})

const copyMedia = (data, impersonated) => ({
  type: types.COPY_MEDIA,
  data,
  impersonated
})

const copyDesignToSystem = designGalleryId => ({
  type: types.COPY_DESIGN_TO_SYSTEM,
  designGalleryId
})

const addMediaToPlaylist = data => ({
  type: types.POST_MEDIA_TO_PLAYLIST,
  data
})

const postMediaBulk = payload => ({
  type: types.POST_MEDIA_BULK,
  payload
})

const clearPostMediaBulkInfo = () => ({
  type: types.CLEAR_POST_MEDIA_BULK_INFO
})

const cloneMedia = data => ({
  type: types.CLONE_MEDIA_ITEM,
  payload: data
})

const getMediaAssociations = (id, params) => ({
  type: types.GET_MEDIA_ASSOCIATIONS,
  payload: {
    id,
    params
  }
})

const clearMediaAssociations = () => ({ type: types.CLEAR_MEDIA_ASSOCIATIONS })

const getSftpFolder = data => ({
  type: types.GET_SFTP_FOLDER,
  data
})

const clearSftpFolderData = () => ({
  type: types.CLEAR_SFTP_FOLDER_DATA
})

export {
  getMediaItemsAction,
  getMediaByIdAction,
  resetMediaFetchParamsAction,
  addMedia,
  editMedia,
  cloneMediaAndUpdate,
  clearAddedMedia,
  clearEditMedia,
  clearMediaItemStatus,
  closeMediaPreview,
  showMediaPreview,
  getMediaPreview,
  generateMediaPreview,
  getMediaGroupItemsAction,
  clearGetMediaGroupItemsInfoAction,
  postMediaGroupItemAction,
  clearPostMediaGroupItemInfoAction,
  deleteMediaGroupItemAction,
  clearDeleteMediaGroupItemInfoAction,
  getMediaItemById,
  clearMediaItemsAction,
  clearMediaGroupItemsInfo,
  clearGetMediaItemsInfoAction,
  getFeatureMediaItemsAction,
  clearGetFeatureMediaItemsInfoAction,
  putOrganizeFeatureMediaItemsAction,
  clearMediaPut,
  clearMediaPreview,
  postFavoriteMedia,
  clearPostFavoriteMedia,
  setMediaDependency,
  clearMediaDependency,
  addMediaToPlaylistsAction,
  clearAddMediaToPlaylistsAction,
  deleteSelectedMedia,
  clearMediaResponseInfo,
  shareMedia,
  publishDesign,
  copyMedia,
  copyDesignToSystem,
  addMediaToPlaylist,
  postMediaBulk,
  clearPostMediaBulkInfo,
  cloneMedia,
  getMediaAssociations,
  clearMediaAssociations,
  getPreviewFromUrl,
  getSftpFolder,
  clearSftpFolderData
}
