import { useMemo, useEffect, useCallback } from 'react'
import throttle from 'lodash/throttle'

const useColumnSizeRestrictions = (
  headerElement,
  columnWidth,
  onToggleColumnDisplay = f => f,
  tableMaxWidth,
  withHorizontalScroll
) => {
  const currentWidth = useMemo(
    () => Object.values(columnWidth).reduce((sum, x) => sum + x, 0),
    [columnWidth]
  )

  const canShowColumn = useCallback(
    (colWidth = 0) => {
      if (withHorizontalScroll) {
        return true
      }
      const maxAllowedWidth =
        tableMaxWidth || (headerElement ? headerElement.clientWidth || 0 : 0)
      return !maxAllowedWidth || currentWidth + colWidth < maxAllowedWidth
    },
    [currentWidth, headerElement, tableMaxWidth, withHorizontalScroll]
  )

  const handleResize = useMemo(
    () =>
      throttle(() => {
        const maxAllowedWidth =
          tableMaxWidth || (headerElement ? headerElement?.clientWidth || 0 : 0)
        if (!maxAllowedWidth || currentWidth < maxAllowedWidth) {
          return
        }
        const columnIds = Object.keys(columnWidth)
        const columnToHide = columnIds[columnIds.length - 1]
        onToggleColumnDisplay(columnToHide, false)
      }, 33),
    [
      onToggleColumnDisplay,
      currentWidth,
      headerElement,
      tableMaxWidth,
      columnWidth
    ]
  )

  useEffect(() => {
    if (!withHorizontalScroll) {
      handleResize()
      window.addEventListener('resize', handleResize)
    }
    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, onToggleColumnDisplay, withHorizontalScroll])

  return { canShowColumn }
}

export default useColumnSizeRestrictions
