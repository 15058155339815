import update from 'immutability-helper'

import { shapeOfBody } from 'constants/initialLibraryState'
import * as types from '../actions'

const initialState = {
  events: {
    items: {
      ...shapeOfBody,
      isFetching: false
    }
  }
}

const postHogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_POSTHOG_EVENTS:
      return update(state, {
        events: {
          items: {
            response: { $set: [] },
            isFetching: {
              $set: true
            }
          }
        }
      })
    case types.GET_POSTHOG_EVENTS_SUCCESS:
      return update(state, {
        events: {
          items: {
            response: { $set: action.payload.response },
            isFetching: { $set: false }
          }
        }
      })
    case types.GET_POSTHOG_EVENTS_ERROR:
      return update(state, {
        events: {
          items: {
            error: { $set: action.payload },
            isFetching: { $set: false }
          }
        }
      })

    default:
      return state
  }
}

export default postHogReducer
