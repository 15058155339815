import React from 'react'
import { makeStyles } from '@material-ui/core'

import LogoImage from 'components/Account/LogoImage'
import useWhiteLabel from 'hooks/useWhiteLabel'

const useStyles = makeStyles(() => ({
  logoImage: {
    maxHeight: 95,
    maxWidth: 500
  }
}))

const Logo = () => {
  const classes = useStyles()
  const { darkHeaderLogo } = useWhiteLabel()

  return (
    <LogoImage
      isLightLogo
      headerLogo={darkHeaderLogo}
      darkHeaderLogo={darkHeaderLogo}
      logoImageClassName={classes.logoImage}
    />
  )
}

export default Logo
