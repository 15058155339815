import { createAppApi } from 'services/api'
import { createSuccessInvalidator, getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'

export const featureCategoriesApi = createAppApi('categoryQuery', {
  tagTypes: [apiTags.category],
  endpoints: builder => ({
    getCategoriesByFeature: builder.query({
      query: ({ feature, params }) => ({
        url: `/feature/${feature}/category`,
        params
      }),
      providesTags: [apiTags.category]
    }),
    postCategoryIntoFeature: builder.mutation({
      query: ({ feature, data }) => ({
        url: `/feature/${feature}/category`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: createSuccessInvalidator([apiTags.category])
    }),
    ...getCRUDEndpoints({
      builder,
      tags: [apiTags.category],
      name: 'Category',
      pluralName: 'Categories',
      url: '/feature/category'
    }),
    getMediaCategoryList: builder.query({
      query: () => ({
        url: `/mediaCategory`
      })
    })
  })
})

export const {
  useLazyGetCategoriesByFeatureQuery,
  usePostCategoryIntoFeatureMutation,
  usePutCategoryMutation,
  useDeleteCategoryMutation,
  useLazyGetMediaCategoryListQuery
} = featureCategoriesApi
