import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'

export const clientsApi = createAppApi('clientsQuery', {
  tagTypes: [apiTags.clients],
  endpoints: builder => ({
    getDeviceCount: builder.query({
      query: ({ clientId }) => ({ url: `/client/${clientId}/deviceCount` })
    })
  })
})

export const { useLazyGetDeviceCountQuery } = clientsApi
