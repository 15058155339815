export const GET_PUBLIC_TOKEN = 'GET_PUBLIC_TOKEN'
export const GET_PUBLIC_TOKEN_SUCCESS = 'GET_PUBLIC_TOKEN_SUCCESS'
export const GET_PUBLIC_TOKEN_ERROR = 'GET_PUBLIC_TOKEN_ERROR'

export const POST_PUBLIC_TOKEN = 'POST_PUBLIC_TOKEN'
export const POST_PUBLIC_TOKEN_SUCCESS = 'POST_PUBLIC_TOKEN_SUCCESS'
export const POST_PUBLIC_TOKEN_ERROR = 'POST_PUBLIC_TOKEN_ERROR'

export const DELETE_PUBLIC_TOKEN = 'DELETE_PUBLIC_TOKEN'
export const DELETE_PUBLIC_TOKEN_SUCCESS = 'DELETE_PUBLIC_TOKEN_SUCCESS'
export const DELETE_PUBLIC_TOKEN_ERROR = 'DELETE_PUBLIC_TOKEN_ERROR'

export const GET_PUBLIC_NOC_GENERAL_ITEMS = 'GET_PUBLIC_NOC_GENERAL_ITEMS'
export const GET_PUBLIC_NOC_GENERAL_ITEMS_SUCCESS =
  'GET_PUBLIC_NOC_GENERAL_ITEMS_SUCCESS'
export const GET_PUBLIC_NOC_GENERAL_ITEMS_ERROR =
  'GET_PUBLIC_NOC_GENERAL_ITEMS_ERROR'

export const GET_PUBLIC_NOC_HEALTH_ITEMS = 'GET_PUBLIC_NOC_HEALTH_ITEMS'
export const GET_PUBLIC_NOC_HEALTH_ITEMS_SUCCESS =
  'GET_PUBLIC_NOC_HEALTH_ITEMS_SUCCESS'
export const GET_PUBLIC_NOC_HEALTH_ITEMS_ERROR =
  'GET_PUBLIC_NOC_HEALTH_ITEMS_ERROR'

export const GET_PUBLIC_NOC_NETWORK_ITEMS = 'GET_PUBLIC_NOC_NETWORK_ITEMS'
export const GET_PUBLIC_NOC_NETWORK_ITEMS_SUCCESS =
  'GET_PUBLIC_NOC_NETWORK_ITEMS_SUCCESS'
export const GET_PUBLIC_NOC_NETWORK_ITEMS_ERROR =
  'GET_PUBLIC_NOC_NETWORK_ITEMS_ERROR'

export const CLEAR_PUBLIC_TOKEN_RESPONSE_INFO =
  'CLEAR_PUBLIC_TOKEN_RESPONSE_INFO'

export const GET_PUBLIC_NOC_ACCESS_TOKEN = 'GET_PUBLIC_NOC_ACCESS_TOKEN'
export const GET_PUBLIC_NOC_ACCESS_TOKEN_SUCCESS =
  'GET_PUBLIC_NOC_ACCESS_TOKEN_SUCCESS'
export const GET_PUBLIC_NOC_ACCESS_TOKEN_ERROR =
  'GET_PUBLIC_NOC_ACCESS_TOKEN_ERROR'
