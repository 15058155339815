import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function useRouter() {
  const { push } = useHistory()
  const { state } = useLocation()

  return useMemo(
    () => ({
      routerPush: push,
      routerState: state || {}
    }),
    [push, state]
  )
}
