import React, { useMemo } from 'react'
import { Table, TableBody, withStyles } from '@material-ui/core'

import { TableLibraryHead } from 'components/TableLibrary'
import DeviceScheduleSimpleTableRow from 'components/CommonRows/DeviceScheduleSimpleTableRow'
import MessageCard from 'components/Card/MessageCard'
import { useTranslation } from 'react-i18next'

const styles = ({ type, colors }) => ({
  container: {
    minHeight: 220
  },
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    paddingLeft: 16,
    paddingRight: 16,
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  },
  panelRoot: {
    border: 0,
    marginBottom: 10
  },
  panelSummary: {
    border: '1px solid ' + colors.border[type]
  }
})

const initialColumns = [
  { id: 'title', label: 'Device' },
  { id: 'schedule ', label: 'Associated Schedule' },
  { id: 'workingDays', label: 'Days', align: 'center' },
  { id: 'time', label: 'Time', align: 'center' }
]

const columnWidth = {
  title: 200,
  workingDays: 300,
  time: 150
}

const MultipleCollectionScheduleAssociationContent = ({ error, classes }) => {
  const devices = useMemo(() => error.data, [error])
  const { t } = useTranslation()
  return (
    <div className={classes.container}>
      <MessageCard
        icon="fa-regular fa-circle-info"
        message={t(
          'The following devices are currently assigned to a schedule based on a group, tag or location. The existing schedule(s) must be adjusted or removed in order to continue. This is a temporary limitation to avoid schedule conflicts, and will be improved for more flexible scheduling in the near future.'
        )}
        marginBottom
      />
      <Table className={classes.table}>
        <TableLibraryHead
          noType={true}
          editRows={false}
          disableSort
          columns={initialColumns}
          columnWidth={columnWidth}
          headerCellClassName={classes.tableHeaderCell}
        />
        <TableBody>
          {devices.map(device =>
            device.schedule.map(schedule => (
              <DeviceScheduleSimpleTableRow
                device={device}
                schedule={schedule}
                key={schedule.id}
              />
            ))
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default withStyles(styles)(MultipleCollectionScheduleAssociationContent)
