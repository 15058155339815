import { call, put } from 'redux-saga/effects'
import { preferenceService } from 'services'
import * as types from 'actions'
import { _isEmpty } from 'utils/lodash'

import { preferenceStatus } from 'constants/libraryConstants'
import { entityConstants } from 'constants/index'

function* getPreferenceByEntity({ entity }) {
  try {
    const [response = {}] = yield call(
      preferenceService.getPreferenceByEntity,
      entity
    )

    let status = preferenceStatus.RECEIVED

    if (_isEmpty(response)) {
      status = preferenceStatus.EMPTY
    }

    yield put({
      type: types.GET_PREFERENCE_BY_ENTITY_SUCCESS,
      response,
      status,
      entity
    })
  } catch (error) {
    yield put({
      type: types.GET_PREFERENCE_BY_ENTITY_ERROR,
      entity,
      payload: error
    })
  }
}

function* putPreferenceByEntity({ entity, data }) {
  try {
    yield call(preferenceService.putPreferenceByEntity, entity, data)
    yield put({
      type: types.PUT_PREFERENCE_BY_ENTITY_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_PREFERENCE_BY_ENTITY_ERROR, payload: error })
  }
}

function* getPreferencePublicNoc({ token }) {
  try {
    const [response = {}] = yield call(
      preferenceService.getPreferencePublicNoc,
      token
    )

    let status = preferenceStatus.RECEIVED

    if (_isEmpty(response)) {
      status = preferenceStatus.EMPTY
    }

    yield put({
      type: types.GET_PREFERENCE_PUBLIC_NOC_SUCCESS,
      entity: entityConstants.DeviceNocLibrary,
      response,
      status
    })
  } catch (error) {
    yield put({
      type: types.GET_PREFERENCE_PUBLIC_NOC_ERROR,
      entity: entityConstants.DeviceNocLibrary,
      payload: error
    })
  }
}

function* putPreferencePublicNoc({ data }) {
  try {
    yield call(preferenceService.putPreferencePublicNoc, data)
    yield put({
      type: types.PUT_PREFERENCE_PUBLIC_NOC_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_PREFERENCE_PUBLIC_NOC_ERROR, payload: error })
  }
}

const preferenceSaga = {
  getPreferenceByEntity,
  putPreferenceByEntity,
  getPreferencePublicNoc,
  putPreferencePublicNoc
}

export default preferenceSaga
