import update from 'immutability-helper'

import * as types from '../actions'

const shapeOfBody = {
  response: null,
  error: null
}

//TODO refactor with rtk query
const initialState = {
  addItem: { ...shapeOfBody },
  post: { ...shapeOfBody }
}

const addMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_MEDIA_SUCCESS:
      return update(state, {
        addItem: {
          response: { $set: action.payload }
        },
        post: {
          response: { $set: action.payload }
        }
      })
    case types.ADD_MEDIA_ERROR:
      return update(state, {
        addItem: {
          error: { $set: action.payload },
          meta: { $set: action.meta }
        },
        post: {
          error: { $set: action.payload }
        }
      })

    case types.CLEAR_ADDED_MEDIA:
      return update(state, {
        addItem: {
          $set: shapeOfBody
        },
        post: { $set: shapeOfBody }
      })
    default:
      return state
  }
}

export default addMediaReducer
