export const BORDER_WIDTH = 2
export const SMALL_CARD_WIDTH = 167
export const NORMAL_CARD_WIDTH = 229
export const PORTRAIT_CARD_WIDTH = 160
export const BIG_CARD_WIDTH = 342
export const NORMAL_CARD_THUMBNAIL_HEIGHT = (NORMAL_CARD_WIDTH / 4) * 3
export const PORTRAIT_CARD_THUMBNAIL_HEIGHT = (PORTRAIT_CARD_WIDTH / 9) * 16
export const SMALL_CARD_THUMBNAIL_HEIGHT = (SMALL_CARD_WIDTH / 4) * 3
export const WIDESCREEN_CARD_THUMBNAIL_HEIGHT = (NORMAL_CARD_WIDTH / 16) * 9
export const WIDESCREEN_BIG_CARD_THUMBNAIL_HEIGHT = (BIG_CARD_WIDTH / 16) * 9
export const textMaxWidth = {
  small: SMALL_CARD_WIDTH - 16,
  normal: NORMAL_CARD_WIDTH - 16,
  widescreen: NORMAL_CARD_WIDTH - 16,
  widescreenBig: BIG_CARD_WIDTH - 16,
  portrait: PORTRAIT_CARD_WIDTH - 16
}
export const GLOW_SPREAD = 80
