import React, { Fragment } from 'react'
import ContentLoader from 'react-content-loader'
import { withTheme } from '@material-ui/core'

const TAB_HEIGHT = 25
const TAB_MARGIN_BOTTOM = 16
const ROW_HEIGHT = 57
const ROW_ICON_SIZE = 36
const ROW_TEXT_HEIGHT = 14

const renderRows = Array(1)
  .fill(' ')
  .map((row, index) => {
    const y =
      TAB_HEIGHT +
      TAB_MARGIN_BOTTOM +
      ROW_HEIGHT * index +
      (ROW_HEIGHT - ROW_ICON_SIZE) / 2

    return (
      <Fragment key={index}>
        <rect
          x="18"
          y={y}
          rx="10px"
          width={ROW_ICON_SIZE}
          height={ROW_ICON_SIZE}
        />
        <rect
          x="68"
          y={y}
          rx="6px"
          width="calc(20%)"
          height={ROW_TEXT_HEIGHT}
        />
        <rect
          x="68"
          y={y + ROW_TEXT_HEIGHT + 8}
          rx="6px"
          width="calc(14%)"
          height={ROW_TEXT_HEIGHT}
        />
        <rect
          x="calc(100% - 15% - 18px)"
          y={y}
          rx="6px"
          width="calc(15%)"
          height={ROW_TEXT_HEIGHT}
        />
        <rect
          x="calc(100% - 15% - 18px)"
          y={y + ROW_TEXT_HEIGHT + 8}
          rx="6px"
          width="calc(15%)"
          height={ROW_TEXT_HEIGHT}
        />
      </Fragment>
    )
  })

const AssociationModalLoader = ({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: '241px'
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect
      x="calc(50% - 75px)"
      y="0"
      rx="12px"
      ry="12px"
      width="150"
      height={TAB_HEIGHT}
    />
    {renderRows}
  </ContentLoader>
)

export default withTheme(AssociationModalLoader)
