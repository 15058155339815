import React, { useEffect, useState, useCallback, useMemo, memo } from 'react'
import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  withStyles,
  Grid,
  Typography,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from '@reduxjs/toolkit'
import moment from 'moment'
import isEqual from 'lodash/isEqual'

import { FormControlInput } from 'components/Form'
import { BlueButton, WhiteButton } from 'components/Buttons'
import MediaLibrary from './MediaLibrary'
import { CircularLoader } from 'components/Loaders'
import {
  getDeviceMediaIpawsAlertAction,
  clearGetDeviceMediaIpawsAlertInfoAction,
  putDeviceMediaIpawsAlertAction,
  clearPutDeviceMediaIpawsAlertInfoAction,
  clearRemoveDeviceMediaIpawsAlertInfoAction,
  removeDeviceMediaIpawsAlertAction
} from 'actions/alertActions'
import { useCustomSnackbar, useIpAddress } from 'hooks/index'
import { isFalsy, takeTruth } from 'utils/generalUtils'
import { passwordValidateSchema } from 'constants/validations'
import useConfirmation from 'hooks/useConfirmation'
import { DATE_TIME_VIEW_FORMAT } from 'constants/dateTimeFormats'

const styles = ({ type, palette }) => ({
  inputContainer: {
    width: 'calc(100% - 200px)'
  },
  button: {
    width: 90
  },
  inputWrapper: {
    marginBottom: 20
  },
  passwordLabel: {
    width: 100,
    color: palette[type].formControls.label.color,
    fontWeight: 600
  },
  passwordContainer: {
    width: 'calc(100% - 110px)'
  },
  passwordWrapper: {
    background:
      palette[type].pages.devices.alerts.mediaModal.cap.password.background,
    padding: 20,
    borderRadius: 3,
    marginBottom: 30
  },
  ipText: {
    fontSize: 12,
    color: palette[type].formControls.label.color,
    marginBottom: 20,
    width: '100%',
    textAlign: 'center'
  },
  content: {
    background: palette[type].dialog.background,
    padding: '0px 20px 5px'
  },
  actionBar: {
    display: 'flex',
    alignItems: 'flex-end',
    background: palette[type].dialog.header.background,
    borderTop: `solid 1px ${palette[type].dialog.border}`,
    padding: '20px',
    margin: '0px'
  }
})

const IPAWSAlert = ({
  t,
  id,
  classes,
  disabled,
  deviceMediaIpawsAlertReducer,
  getDeviceMediaIpawsAlertAction,
  clearGetDeviceMediaIpawsAlertInfoAction,
  putDeviceMediaIpawsAlertReducer,
  putDeviceMediaIpawsAlertAction,
  clearPutDeviceMediaIpawsAlertInfoAction,
  enqueueSnackbar,
  closeSnackbar,
  handleClose,
  clearRemoveDeviceMediaIpawsAlertInfoAction,
  removeDeviceMediaIpawsAlertAction,
  removeDeviceMediaIpawsAlertReducer
}) => {
  const showSnackbar = useCustomSnackbar(t, enqueueSnackbar, closeSnackbar)
  const [selectedMedia, setSelectedMedia] = useState({})
  const [newSelectedMedia, setNewSelectedMedia] = useState({})
  const [dialog, setDialog] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { ipAddress } = useIpAddress()
  const { showConfirmation } = useConfirmation()

  const handleSubmit = useCallback(
    ({ password }) => {
      if (isEqual(newSelectedMedia, {})) {
        handleClose()
      } else {
        const { id: mediaId } = newSelectedMedia
        if (mediaId) {
          setLoading(true)
          putDeviceMediaIpawsAlertAction({
            deviceId: id,
            data: {
              mediaId,
              password
            }
          })
        }
      }
    },
    [newSelectedMedia, handleClose, putDeviceMediaIpawsAlertAction, id]
  )

  const form = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object().shape({
      password: passwordValidateSchema
    }),
    onSubmit: handleSubmit
  })

  useEffect(
    () => {
      form.validateForm()
    },
    //eslint-disable-next-line
    []
  )

  useEffect(() => {
    if (!deviceMediaIpawsAlertReducer.response) {
      setLoading(true)
      getDeviceMediaIpawsAlertAction(id)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (deviceMediaIpawsAlertReducer.response) {
      const { media } = deviceMediaIpawsAlertReducer.response
      setSelectedMedia(media)
      clearGetDeviceMediaIpawsAlertInfoAction()
      setLoading(false)
    } else if (deviceMediaIpawsAlertReducer.error) {
      setSelectedMedia({})
      clearGetDeviceMediaIpawsAlertInfoAction()
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [deviceMediaIpawsAlertReducer])

  useEffect(() => {
    if (putDeviceMediaIpawsAlertReducer.response) {
      clearPutDeviceMediaIpawsAlertInfoAction()
      showSnackbar('Successfully changed', 'success')

      form.resetForm()
      handleClose()
      setLoading(false)
    } else if (putDeviceMediaIpawsAlertReducer.error) {
      clearPutDeviceMediaIpawsAlertInfoAction()
      showSnackbar('Error', 'error')
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [putDeviceMediaIpawsAlertReducer])

  useEffect(() => {
    if (removeDeviceMediaIpawsAlertReducer.response) {
      getDeviceMediaIpawsAlertAction(id)
      clearRemoveDeviceMediaIpawsAlertInfoAction()
      showSnackbar('Successfully removed', 'success')
    } else if (removeDeviceMediaIpawsAlertReducer.error) {
      clearRemoveDeviceMediaIpawsAlertInfoAction()
      showSnackbar('Error', 'error')
    }
    // eslint-disable-next-line
  }, [removeDeviceMediaIpawsAlertReducer])

  const handleSelectMedia = useCallback(
    media => {
      setNewSelectedMedia(media)
      setDialog(false)
    },
    [setNewSelectedMedia, setDialog]
  )

  const openDialog = useCallback(() => {
    setDialog(true)
  }, [setDialog])

  const closeDialog = useCallback(() => {
    setDialog(false)
  }, [setDialog])

  const renderDialog = useMemo(() => {
    if (isFalsy(dialog)) return undefined
    return (
      <MediaLibrary
        open={dialog}
        handleClose={closeDialog}
        deviceId={id}
        selectedMediaId={takeTruth(newSelectedMedia.id, selectedMedia.id)}
        onSuccess={handleSelectMedia}
      />
    )
  }, [
    id,
    dialog,
    closeDialog,
    selectedMedia,
    newSelectedMedia,
    handleSelectMedia
  ])

  const renderLoader = useMemo(() => {
    if (isFalsy(isLoading)) return null
    return <CircularLoader />
  }, [isLoading])

  const handleRemoveAlert = useCallback(() => {
    showConfirmation(
      t('remove_modal_text_interpolated', {
        item: takeTruth(newSelectedMedia.title, selectedMedia.title)
      }),
      () => {
        if (!isEqual(newSelectedMedia, {})) {
          setNewSelectedMedia({})
        } else {
          removeDeviceMediaIpawsAlertAction(id)
        }
      }
    )
  }, [
    newSelectedMedia,
    id,
    selectedMedia.title,
    removeDeviceMediaIpawsAlertAction,
    t,
    showConfirmation
  ])

  return (
    <Grid container direction="column">
      <DialogContent className={classes.content}>
        {renderLoader}
        <Grid
          container
          alignItems="flex-end"
          justifyContent="space-between"
          className={classes.inputWrapper}
        >
          <FormControlInput
            label={t('Select Media')}
            value={takeTruth(
              newSelectedMedia.title,
              selectedMedia.title,
              t('No media selected')
            )}
            formControlContainerClass={classes.inputContainer}
            marginBottom={false}
            disabled
            customiseDisabled={false}
          />
          <WhiteButton
            disabled={disabled}
            className={classes.button}
            onClick={openDialog}
          >
            <i className="fa-light fa-pencil" />
          </WhiteButton>
          <WhiteButton
            variant="danger"
            className={classes.button}
            onClick={handleRemoveAlert}
            disabled={!takeTruth(newSelectedMedia.title, selectedMedia.title)}
          >
            <i className="fa-regular fa-trash-can" />
          </WhiteButton>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.passwordWrapper}
        >
          <Typography className={classes.passwordLabel}>
            {t('Password')}:
          </Typography>
          <FormControlInput
            marginBottom={false}
            name="password"
            type="password"
            value={form.values.password}
            error={form.errors.password}
            touched={form.touched.password}
            handleChange={form.handleChange}
            handleBlur={form.handleBlur}
            formControlContainerClass={classes.passwordContainer}
          />
        </Grid>
        <Typography className={classes.ipText}>
          {t('Your IP Address and Current Time will be recorded', {
            IP: ipAddress,
            currentTime: moment().format(DATE_TIME_VIEW_FORMAT)
          })}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actionBar}>
        <Grid container justifyContent="flex-end">
          <BlueButton
            opaque={!form.isValid}
            disabled={disabled}
            onClick={form.submitForm}
            iconClassName="fa-regular fa-circle-check"
          >
            {t('OK')}
          </BlueButton>
        </Grid>
      </DialogActions>
      {renderDialog}
    </Grid>
  )
}

const mapStateToProps = ({ alert }) => ({
  deviceMediaIpawsAlertReducer: alert.deviceMediaIpawsAlert,
  putDeviceMediaIpawsAlertReducer: alert.putDeviceMediaIpawsAlert,
  removeDeviceMediaIpawsAlertReducer: alert.removeDeviceMediaIpawsAlert
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceMediaIpawsAlertAction,
      clearGetDeviceMediaIpawsAlertInfoAction,
      putDeviceMediaIpawsAlertAction,
      clearPutDeviceMediaIpawsAlertInfoAction,
      clearRemoveDeviceMediaIpawsAlertInfoAction,
      removeDeviceMediaIpawsAlertAction
    },
    dispatch
  )

export default memo(
  compose(
    withTranslation('translations'),
    withStyles(styles),
    withSnackbar,
    connect(mapStateToProps, mapDispatchToProps)
  )(IPAWSAlert)
)
