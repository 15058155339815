import { useCallback, useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import Scrollbars from 'components/Scrollbars'
import { AM_PM_FORMAT, AM_PM_S_FORMAT } from 'constants/dateTimeFormats'
import { getTimeOptions } from 'utils/time'

const useStyles = makeStyles(({ typography, type }) => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  listRoot: ({ showSeconds }) => ({
    width: showSeconds ? 99 : 79,
    height: '100%'
  }),
  listTitle: {
    color: '#849095',
    fontWeight: 600,
    padding: '0.833em',
    fontSize: 12,
    textAlign: 'center'
  },
  listBox: ({ showSeconds }) => ({
    cursor: 'pointer',
    padding: '5px 3px',
    margin: '2px 4px',
    width: showSeconds ? 90 : 70,
    height: 27,
    display: 'grid',
    placeItems: 'center',
    ...typography.darkText[type],
    fontSize: 12,
    lineHeight: '0.7em',
    borderRadius: 4,

    '&:hover': {
      background: '#3d91ff',
      color: '#fff'
    }
  }),
  selected: {
    background: '#3d91ff',
    color: '#fff !important'
  },
  listScrollbar: ({ fullHeight }) => ({
    height: fullHeight ? '100% !important' : 'calc(100% - 38px) !important'
  })
}))

const TimeList = ({
  classes,
  value,
  label,
  onChange,
  options,
  listRootClass
}) => {
  const scrollbarRef = useRef()

  useEffect(() => {
    if (value || value === 0) {
      const index = options.findIndex(({ value: _value }) => _value === value)
      scrollbarRef.current?.scrollToTop &&
        index > -1 &&
        scrollbarRef.current.scrollToTop(index * 29)
    }
    // eslint-disable-next-line
  }, [value])

  return (
    <div className={classNames(classes.listRoot, listRootClass)}>
      {!!label && <div className={classes.listTitle}>{label}</div>}
      <Scrollbars ref={scrollbarRef} className={classes.listScrollbar}>
        {options.map(({ label: _label, value: _value }, index) => (
          <div
            className={classNames(classes.listBox, {
              [classes.selected]: _value === value
            })}
            onClick={() => onChange(_value)}
            key={`time-${label}-${index}`}
          >
            {_label}
          </div>
        ))}
      </Scrollbars>
    </div>
  )
}

const TimePickerNew = ({
  value,
  maskValue,
  showSeconds = false,
  onChange = f => f,
  rootClassName,
  label,
  noLabel,
  name
}) => {
  const classes = useStyles({ showSeconds, fullHeight: noLabel })

  const handleChange = useCallback(
    _value => {
      if (name) {
        onChange({ target: { name, value: _value } })
      } else {
        onChange(
          moment(_value, showSeconds ? AM_PM_S_FORMAT : AM_PM_FORMAT).format(
            maskValue
          )
        )
      }
    },
    [name, onChange, maskValue, showSeconds]
  )

  const timeOptions = useMemo(() => getTimeOptions({ showSeconds }), [
    showSeconds
  ])

  return (
    <div className={classNames(classes.root, rootClassName)}>
      <TimeList
        classes={classes}
        label={!noLabel && label}
        value={moment(value, maskValue).format(
          showSeconds ? AM_PM_S_FORMAT : AM_PM_FORMAT
        )}
        options={timeOptions}
        onChange={handleChange}
      />
    </div>
  )
}

TimePickerNew.propTypes = {
  value: PropTypes.string,
  maskValue: PropTypes.string
}

export default TimePickerNew
