import { createContext } from 'react'

export const ThemeContext = createContext({
  currentTheme: 'auto',
  handleThemeChange: () => {}
})

export const ThemeContextProvider = ({ children, contextValue }) => {
  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  )
}
