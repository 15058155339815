import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

import Text from 'components/Typography/Text'
import { FontSize } from 'utils/styles'

function styles({ colors }) {
  return {
    root: {
      color: colors.error
    },
    absolute: {
      position: 'absolute',
      bottom: -16,
      height: 16,
      lineHeight: 'inherit'
    },
    static: {
      lineHeight: '16px'
    },
    optionalError: {
      color: colors.highlight
    }
  }
}

function Error({
  classes,
  condition,
  error,
  rootClassName,
  absolute,
  isOptional,
  variant = 'smallest'
}) {
  if (!condition) {
    return null
  }
  return (
    <Text
      variant={variant}
      {...(!absolute && { whiteSpace: 'pre-line' })}
      rootClassName={classNames(classes.root, rootClassName, {
        [classes.absolute]: absolute,
        [classes.static]: !absolute,
        [classes.optionalError]: isOptional
      })}
    >
      {error}
    </Text>
  )
}

Error.propTypes = {
  condition: PropTypes.bool,
  error: PropTypes.string,
  rootClassName: PropTypes.string,
  absolute: PropTypes.bool,
  variant: FontSize
}

export default withStyles(styles)(Error)
