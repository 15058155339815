import { call, put } from 'redux-saga/effects'
import * as types from 'actions'
import { resellerClientUserService } from 'services'
import { transformMeta } from 'utils/tableUtils'

function* getItems({ params }) {
  try {
    const { data, meta } = yield call(
      resellerClientUserService.getItems,
      params
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_RESELLER_CLIENT_USERS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_RESELLER_CLIENT_USERS_ERROR, payload: error })
  }
}

function* impersonateUser({ id }) {
  try {
    const response = yield call(resellerClientUserService.impersonateUser, id)

    yield put({
      type: types.IMPERSONATE_USER_SUCCESS,
      response
    })
  } catch (error) {
    yield put({ type: types.IMPERSONATE_USER_SUCCESS, payload: error })
  }
}

const resellerClientUserSaga = {
  getItems,
  impersonateUser
}

export default resellerClientUserSaga
