import { call, put } from 'redux-saga/effects'

import * as types from '../actions'

import { reportService } from '../services'
import { transformMeta } from 'utils/tableUtils'

function* getItems({ params }) {
  try {
    const { data, meta } = yield call(reportService.getItems, params)
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_REPORT_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_REPORT_ITEMS_ERROR, payload: error })
  }
}

function* deleteSelectedReports({ ids }) {
  try {
    yield call(reportService.deleteSelectedReports, ids)
    yield put({
      type: types.DELETE_SELECTED_REPORTS_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_REPORTS_ERROR,
      payload: error
    })
  }
}

function* getReportTables() {
  try {
    const response = yield call(reportService.getReportTables)
    yield put({
      type: types.GET_REPORT_TABLES_SUCCESS,
      payload: {
        response: response.data,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_REPORT_TABLES_ERROR,
      payload: error
    })
  }
}

function* runReport({ payload }) {
  try {
    const response = yield call(reportService.runReport, payload)
    yield put({
      type: types.POST_RUN_REPORT_SUCCESS,
      payload: {
        response: response.data,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.POST_RUN_REPORT_ERROR,
      payload: error
    })
  }
}

function* postReport({ payload }) {
  try {
    yield call(reportService.postReport, payload)
    yield put({
      type: types.POST_REPORT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_REPORT_ERROR,
      payload: error
    })
  }
}

function* putReport({ payload }) {
  try {
    yield call(reportService.putReport, payload)
    yield put({
      type: types.PUT_REPORT_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_REPORT_ERROR,
      payload: error
    })
  }
}

function* getReport({ payload: id }) {
  try {
    const response = yield call(reportService.getReport, id)
    yield put({
      type: types.GET_REPORT_ITEM_SUCCESS,
      payload: {
        response: response.data,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_REPORT_ITEM_ERROR,
      payload: error
    })
  }
}

function* getTemplateReport({ payload: id }) {
  try {
    const response = yield call(reportService.getTemplateReport, id)
    yield put({
      type: types.GET_REPORT_TEMPLATE_SUCCESS,
      payload: {
        response: response,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_REPORT_TEMPLATE_ERROR,
      payload: error
    })
  }
}

function* getTemplateWithReportData({ payload: id, limit }) {
  try {
    const templateResponse = yield call(reportService.getTemplateReport, id)
    const requestData = { ...templateResponse, limit }
    const response = yield call(reportService.runReport, requestData)
    yield put({
      type: types.GET_TEMPLATE_WITH_REPORT_DATA_SUCCESS,
      payload: {
        response: response.data,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATE_WITH_REPORT_DATA_ERROR,
      payload: error
    })
  }
}

function* getCountApiCall({ params }) {
  try {
    const data = yield call(reportService.getCountApiCall, params)
    yield put({
      type: types.GET_COUNT_API_CALL_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_COUNT_API_CALL_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

function* getLastFailedApiCall({ params }) {
  try {
    const data = yield call(reportService.getLastFailedApiCall, params)
    yield put({
      type: types.GET_LAST_FAILED_API_CALL_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_LAST_FAILED_API_CALL_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

function* getCountClientApiCall({ params, clientId }) {
  try {
    const data = yield call(
      reportService.getCountClientApiCall,
      clientId,
      params
    )
    yield put({
      type: types.GET_COUNT_CLIENT_API_CALL_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_COUNT_CLIENT_API_CALL_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

function* getCountDeviceApiCall({ params, deviceId }) {
  try {
    const data = yield call(
      reportService.getCountDeviceApiCall,
      deviceId,
      params
    )
    yield put({
      type: types.GET_COUNT_DEVICE_API_CALL_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_COUNT_DEVICE_API_CALL_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

function* getTopClientsApiCall({ params }) {
  try {
    const data = yield call(reportService.getTopClientsApiCall, params)
    yield put({
      type: types.GET_TOP_CLIENTS_API_CALL_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_TOP_CLIENTS_API_CALL_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

function* getTopDevicesApiCall({ params }) {
  try {
    const data = yield call(reportService.getTopDevicesApiCall, params)
    yield put({
      type: types.GET_TOP_DEVICES_API_CALL_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_TOP_DEVICES_API_CALL_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

function* getReportSchedule({ payload: id }) {
  try {
    const response = yield call(reportService.getReportSchedule, id)

    yield put({
      type: types.GET_REPORT_SCHEDULE_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: types.GET_REPORT_SCHEDULE_ERROR,
      payload: error
    })
  }
}

function* postReportSchedule({ payload }) {
  try {
    yield call(reportService.postReportSchedule, payload)
    yield put({
      type: types.POST_REPORT_SCHEDULE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_REPORT_SCHEDULE_ERROR,
      payload: error
    })
  }
}

function* getReportLink({ params }) {
  try {
    const { link } = yield call(reportService.getReportLink, params)

    yield put({
      type: types.GET_REPORT_LINK_SUCCESS,
      payload: link
    })
  } catch (error) {
    yield put({ type: types.GET_REPORT_LINK_ERROR, payload: error })
  }
}

function* getExposureCount({ params }) {
  try {
    const data = yield call(reportService.getExposureCount, params)
    yield put({
      type: types.GET_EXPOSURE_COUNT_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_EXPOSURE_COUNT_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

function* getExposureTime({ params }) {
  try {
    const data = yield call(reportService.getExposureTime, params)
    yield put({
      type: types.GET_EXPOSURE_TIME_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_EXPOSURE_TIME_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

function* getVerboseData({ params }) {
  try {
    const data = yield call(reportService.getVerboseData, params)
    yield put({
      type: types.GET_VERBOSE_DATA_SUCCESS,
      payload: {
        response: data,
        params,
        status: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_VERBOSE_DATA_ERROR,
      payload: {
        error,
        params
      }
    })
  }
}

const reportSaga = {
  getItems,
  deleteSelectedReports,
  getReportTables,
  runReport,
  postReport,
  putReport,
  getReport,
  getTemplateReport,
  getTemplateWithReportData,
  getCountApiCall,
  getLastFailedApiCall,
  getCountClientApiCall,
  getCountDeviceApiCall,
  getTopClientsApiCall,
  getTopDevicesApiCall,
  getReportSchedule,
  postReportSchedule,
  getReportLink,
  getExposureCount,
  getExposureTime,
  getVerboseData
}

export default reportSaga
