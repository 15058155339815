import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'

import IconStack from 'components/Icons/IconStack'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '14px',
    height: '14px',
    lineHeight: '14px'
  },
  mainIcon: ({ iconComponentColor }) => ({
    fontSize: '12px',
    color: iconComponentColor || 'rgb(255, 61, 132)'
  }),
  smallIcon: ({ iconComponentColor }) => ({
    top: 2,
    fontSize: '7px',
    transform: 'rotate(-30deg)',
    color: iconComponentColor || 'rgb(255, 61, 132)'
  })
}))

const TouchScreenIcon = ({
  wrapperClassName,
  iconComponentColor,
  mainClassName,
  secondClassName,
  wrapperStyles,
  className,
  style
}) => {
  const classes = useStyles({ iconComponentColor })

  return (
    <IconStack
      wrapperClassName={classNames(
        classes.wrapper,
        wrapperClassName,
        className
      )}
      wrapperStyles={{
        ...wrapperStyles,
        minWidth: 'unset',
        ...(style?.marginRight && {
          marginRight: style.marginRight
        })
      }}
      mainClassName={classNames(
        'fa-regular fa-tv',
        classes.mainIcon,
        mainClassName
      )}
      secondClassName={classNames(
        'fa-solid fa-hand-back-point-up',
        classes.smallIcon,
        secondClassName
      )}
      {...(style?.color && {
        generalStyles: { color: iconComponentColor || style.color }
      })}
    />
  )
}

export default TouchScreenIcon
