import { getUppyLocale, uppyConfig } from 'config/uppy'
import { useCallback, useEffect, useState } from 'react'
import { sanitizeFileNameBeforeAdded, transformFile } from 'utils/uppy'
import { profileImageTypes } from 'constants/mediaMimeType'
import { useUppyValidationMimeType } from 'hooks/useUppyValidationMimeType'
import useAppConfig from 'hooks/api/useAppConfig'
import { getInitialUppy } from 'utils'

const useUppySingleFileUpload = ({
  allowedFileTypes = profileImageTypes.extensions,
  t = str => str
}) => {
  const initializeUppy = useCallback(
    (companionUrl = process.env.REACT_APP_UPPY_COMPANION_URL) => {
      const uppy = getInitialUppy(
        {
          locale: getUppyLocale(t),
          restrictions: {
            ...uppyConfig.restrictions,
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            allowedFileTypes
          }
        },
        companionUrl
      )

      uppy.setOptions({
        onBeforeFileAdded: (currentFile, files) => {
          if (Object.keys(files).length) {
            Object.keys(files).forEach(id => {
              uppy.removeFile(id)
            })
            setTimeout(() => {
              try {
                uppy.addFile(currentFile)
              } catch (e) {}
            })
          }
          return sanitizeFileNameBeforeAdded(currentFile)
        }
      })

      return uppy
    },
    [t, allowedFileTypes]
  )

  const [uppy, setUppy] = useState(initializeUppy())

  const appConfig = useAppConfig()

  const uppyUpload = useCallback(async () => {
    const files = uppy.getFiles()
    if (files.length) {
      if (files[0]?.file_name) return files[0]

      const { successful } = await uppy.upload()
      return successful?.[0] ? transformFile(successful?.[0]) : null
    }
  }, [uppy])

  useEffect(() => {
    if (uppy && appConfig.REACT_APP_UPPY_COMPANION_URL) {
      setUppy(initializeUppy(appConfig.REACT_APP_UPPY_COMPANION_URL))
    }
    // eslint-disable-next-line
  }, [appConfig])

  useEffect(() => {
    return () => uppy.close()
  }, [uppy])

  const [isReset, setIsReset] = useState(false)

  const onReset = useCallback(async () => {
    await setIsReset(true)
    setIsReset(false)
  }, [setIsReset])

  useEffect(
    () => {
      uppy.setOptions({
        locale: getUppyLocale(t)
      })
    },
    // eslint-disable-next-line
    [t]
  )

  useUppyValidationMimeType(uppy, t)

  return {
    uppy,
    uppyUpload,
    isReset,
    onReset
  }
}

export default useUppySingleFileUpload
