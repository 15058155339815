import moment from 'moment'
import 'moment-recur'
import _get from 'lodash/get'

import {
  recurrenceEndTypes,
  recurrenceIntervals,
  recurrenceMonthlyIntervals,
  recurrenceTypes,
  SCHEDULE_DEFAULT_END_DATE,
  SCHEDULE_MAX_TIME,
  SCHEDULE_MIN_TIME,
  workingDays,
  allWorkingDays,
  scheduleFields,
  SPECIFIC_DATE_FORMAT,
  recurrenceTabs,
  scheduleAssignTypes,
  scheduleTypes
} from 'constants/schedule'
import { MEDIA_DATE_FORMAT } from 'constants/dateTimeFormats'
import { isSomeTruthy, isTruthy } from 'utils/generalUtils'
import { isFeatureAvailable } from '../utils/api/featureAvailability'
import { SCHEDULE_MULTIPLE_ASSIGN_FEATURE } from '../constants/featureConstants'

const initialCustomRecurrence = {
  repeatEvery: 1,
  interval: recurrenceIntervals.week,
  monthlyRecurrence: recurrenceMonthlyIntervals.day,
  endRecurrence: recurrenceEndTypes.never,
  after: 1
}

const initialValues = {
  recurrenceOption: recurrenceTypes.daily,
  allTime: true,
  allDay: true,
  allDate: false,
  startTime: SCHEDULE_MIN_TIME,
  endTime: SCHEDULE_MAX_TIME,
  startDate: moment().format(MEDIA_DATE_FORMAT),
  endDate: SCHEDULE_DEFAULT_END_DATE,
  specificDates: [],
  customRecurrence: initialCustomRecurrence,
  workingDays: allWorkingDays,
  deviceList: [],
  deviceLocations: [],
  deviceTags: [],
  deviceGroups: [],
  assignType: scheduleAssignTypes.DEVICE,
  recurrenceTab: recurrenceTabs.recurring
}

export default class ScheduleHelper {
  static normalizeWorkingDays = (workingDays = '') => {
    if (Array.isArray(workingDays)) {
      return workingDays
    } else {
      return workingDays.split(',').map(day => day.trim())
    }
  }

  static get initialValues() {
    return initialValues
  }

  static get initialCustomRecurrence() {
    return initialCustomRecurrence
  }

  static parseValuesByRecurrenceType = (
    values,
    recurrenceType,
    dateFormat = 'MM/DD/YYYY'
  ) => {
    const baseValues = {
      ...ScheduleHelper.initialValues,
      ...values,
      specificDates: []
    }

    const currentWeekDay = moment(values.startDate, dateFormat).weekday()

    switch (recurrenceType) {
      case recurrenceTypes.daily:
        return {
          ...baseValues,
          recurrenceOption: recurrenceTypes.daily,
          endDate: null,
          allDay: true,
          workingDays: allWorkingDays
        }
      case recurrenceTypes.weekly:
        return {
          ...baseValues,
          recurrenceOption: recurrenceTypes.weekly,
          endDate: null,
          allDay: false,
          workingDays: [
            allWorkingDays[currentWeekDay === 0 ? 6 : currentWeekDay - 1]
          ]
        }
      case recurrenceTypes.monthly:
        return {
          ...baseValues,
          recurrenceOption: recurrenceTypes.monthly,
          endDate: null,
          allDay: false,
          workingDays: [
            allWorkingDays[currentWeekDay === 0 ? 6 : currentWeekDay - 1]
          ],
          weeksOfMonth:
            values.weeksOfMonth ||
            this.getWeekByDate(moment(values.startDate, dateFormat)).toString()
        }
      case recurrenceTypes.yearly:
        return {
          ...baseValues,
          recurrenceOption: recurrenceTypes.yearly,
          endDate: null,
          allDay: true,
          workingDays: allWorkingDays
        }
      case recurrenceTypes.weekday:
        return {
          ...baseValues,
          recurrenceOption: recurrenceTypes.weekday,
          endDate: null,
          allDay: false,
          workingDays: [
            workingDays.Mon,
            workingDays.Tue,
            workingDays.Wed,
            workingDays.Thu,
            workingDays.Fri
          ]
        }
      case recurrenceTypes.weekend:
        return {
          ...baseValues,
          recurrenceOption: recurrenceTypes.weekend,
          endDate: null,
          allDay: false,
          workingDays: [workingDays.Sat, workingDays.Sun]
        }
      case recurrenceTypes.none:
        return {
          ...baseValues,
          recurrenceOption: recurrenceTypes.none,
          endDate: values.startDate,
          allDate: false,
          allDay: true,
          workingDays: allWorkingDays
        }
      case recurrenceTypes.custom:
        return {
          ...baseValues,
          recurrenceOption: recurrenceTypes.custom,
          endDate: null,
          allDay: true,
          allDate: false,
          workingDays: allWorkingDays,
          customRecurrence: initialCustomRecurrence
        }
      default:
        return baseValues
    }
  }

  static parseCustomRecurrenceToData = values => {
    const { customRecurrence, workingDays, endDate, recurrenceOption } = values
    if (!customRecurrence || recurrenceOption !== recurrenceTypes.custom) {
      return null
    }

    const data = {
      ...ScheduleHelper.initialCustomRecurrence,
      ...values.customRecurrence
    }

    const {
      repeatEvery,
      interval,
      monthlyRecurrence,
      endRecurrence,
      after
    } = data

    const daysOfWeek = workingDays.join(',')

    return {
      interval,
      repeatEvery,
      daysOfWeek,
      ...(endRecurrence !== recurrenceEndTypes.after
        ? endDate
          ? {
              endRecurrence: recurrenceEndTypes.on,
              on: endDate
            }
          : { endRecurrence: recurrenceEndTypes.never }
        : {
            endRecurrence,
            after
          }),
      ...(interval === recurrenceIntervals.month && {
        monthlyRecurrence
      })
    }
  }

  static parseAssignListsToValues = ({
    deviceList,
    deviceTags,
    deviceGroups,
    deviceLocations,
    assignType: BEAssignType,
    scheduleType
  }) => {
    const lists = {
      [scheduleAssignTypes.GROUP]: deviceGroups || [],
      [scheduleAssignTypes.TAG]: deviceTags || [],
      [scheduleAssignTypes.LOCATION]: deviceLocations || [],
      [scheduleAssignTypes.DEVICE]: deviceList || []
    }

    //get populated tab
    const assignType =
      scheduleType === scheduleTypes.Failover.name
        ? scheduleAssignTypes.DEVICE
        : BEAssignType ||
          _get(
            Object.entries(lists).filter(
              ([name, value]) =>
                name !== scheduleAssignTypes.DEVICE && (value || []).length
            ),
            '[0][0]'
          ) ||
          scheduleAssignTypes.DEVICE

    if (!isFeatureAvailable(SCHEDULE_MULTIPLE_ASSIGN_FEATURE)) {
      return {
        deviceList: (lists[scheduleAssignTypes.DEVICE] || []).map(
          ({ id }) => id
        ),
        deviceLocations: [],
        deviceTags: [],
        deviceGroups: [],
        assignType: scheduleAssignTypes.DEVICE
      }
    }

    return {
      deviceList: lists[scheduleAssignTypes.DEVICE].map(({ id }) => id),
      deviceLocations: lists[scheduleAssignTypes.LOCATION].map(({ id }) => id),
      deviceTags: lists[scheduleAssignTypes.TAG].map(({ id }) => id),
      deviceGroups: lists[scheduleAssignTypes.GROUP].map(({ id }) => id),
      assignType
    }
  }

  static parseValuesToData = ({ recurrenceTab, ...values }) => {
    const { scheduleType, status } = values

    return {
      ...values,
      ...(scheduleType === scheduleTypes.Failover.name && {
        deviceLocations: [],
        deviceTags: [],
        deviceGroups: []
      }),
      status: ScheduleHelper.hasAssignedItems(values) ? status : 'Inactive',
      scheduleType,
      startTime: values.allTime ? SCHEDULE_MIN_TIME : values.startTime,
      endTime: values.allTime ? SCHEDULE_MAX_TIME : values.endTime,
      workingDays: values.workingDays.join(','),
      allDay: allWorkingDays.every(day => values.workingDays.includes(day)),
      allDate: false, // prevent startDate replacing from BE/Playback
      customRecurrence: ScheduleHelper.parseCustomRecurrenceToData(values),
      specificDates: values.specificDates?.length
        ? values.specificDates.map(date =>
            moment(date).format(SPECIFIC_DATE_FORMAT)
          )
        : []
    }
  }

  static isFieldDisabled = (field, recurrenceType, customRecurrence) => {
    const { monthlyRecurrence, endRecurrence, interval } =
      customRecurrence || {}

    switch (field) {
      case scheduleFields.workingDays:
        return isSomeTruthy(
          ![
            recurrenceTypes.custom,
            recurrenceTypes.daily,
            recurrenceTypes.monthly
          ].includes(recurrenceType),
          isTruthy(
            recurrenceType === recurrenceTypes.custom,
            interval === recurrenceIntervals.month,
            monthlyRecurrence === recurrenceMonthlyIntervals.week
          )
        )
      case scheduleFields.endDate:
        return isSomeTruthy(
          recurrenceType === recurrenceTypes.none,
          isTruthy(
            recurrenceType === recurrenceTypes.custom,
            endRecurrence === recurrenceEndTypes.on
          )
        )
      default:
        return false
    }
  }

  static getDateFromWeekNumberAndWeekDay = (startDate, weekNumber, weekDay) => {
    const weekDayNumber = {
      [workingDays.Sun]: 0,
      [workingDays.Mon]: 1,
      [workingDays.Tue]: 2,
      [workingDays.Wed]: 3,
      [workingDays.Thu]: 4,
      [workingDays.Fri]: 5,
      [workingDays.Sat]: 6
    }

    const year = startDate.year()
    const month = startDate.month() + 1

    const startOfMonth = moment()
      .year(year)
      .month(month - 1)
      .startOf('month')

    const firstWeekdayOfMonth = startOfMonth.clone().day(weekDayNumber[weekDay])

    const weekOffset = (weekNumber - 1) * 7

    const adjustedFirstWeekday = firstWeekdayOfMonth.isBefore(startOfMonth)
      ? firstWeekdayOfMonth.add(1, 'week')
      : firstWeekdayOfMonth

    return adjustedFirstWeekday.clone().add(weekOffset, 'days')
  }

  static isDateInWorkingDays = (date, workingDays) => {
    return workingDays.includes(date.format('ddd'))
  }

  static getInitialRecurrenceDates = (
    startDate,
    endDate,
    endYearsInterval = 2
  ) => {
    const start = moment(startDate).clone()
    const end = endDate
      ? moment(endDate).clone().endOf('date')
      : start.clone().add(endYearsInterval, 'year').endOf('date')

    const date = start.clone()

    const disabledDates = [date.toDate()]

    return { start, end, date, disabledDates }
  }

  static getRecurrenceByDay = ({
    startDate,
    endDate,
    repeatEvery,
    workingDays
  }) => {
    const {
      end,
      date,
      disabledDates
    } = ScheduleHelper.getInitialRecurrenceDates(startDate, endDate)

    while (date.isBefore(end)) {
      if (
        ScheduleHelper.isDateInWorkingDays(
          date.add(repeatEvery * 1, 'day'),
          workingDays
        )
      ) {
        disabledDates.push(date.toDate())
      }
    }
    return disabledDates
  }

  static getRecurrenceByWeek = ({
    startDate,
    endDate,
    repeatEvery,
    workingDays
  }) => {
    const {
      start,
      end,
      date,
      disabledDates
    } = ScheduleHelper.getInitialRecurrenceDates(startDate, endDate)

    while (date.isSameOrBefore(end)) {
      date.startOf('week')

      workingDays.forEach(workday => {
        const dayIndex = allWorkingDays.findIndex(day => day === workday) + 1
        const currentWeekDay = date.weekday(dayIndex === 7 ? 0 : dayIndex)
        if (
          currentWeekDay.isSameOrAfter(start) &&
          currentWeekDay.isSameOrBefore(end)
        ) {
          disabledDates.push(currentWeekDay.toDate())
        }
      })

      date.startOf('week').add(repeatEvery, 'week')
    }
    return disabledDates
  }

  static getRecurrenceByMonth = ({
    startDate,
    endDate,
    repeatEvery,
    workingDays,
    monthlyRecurrence
  }) => {
    const {
      start,
      end,
      date,
      disabledDates
    } = ScheduleHelper.getInitialRecurrenceDates(startDate, endDate)

    while (date.isSameOrBefore(end)) {
      if (monthlyRecurrence === recurrenceMonthlyIntervals.day) {
        const initialDay = start.date()
        date.add(repeatEvery, 'month')
        if (date.daysInMonth() >= initialDay) {
          date.date(initialDay)

          if (ScheduleHelper.isDateInWorkingDays(date, workingDays)) {
            disabledDates.push(date.toDate())
          }
        }
      } else {
        date.add(repeatEvery - 1, 'month')

        //TODO optimize
        for (let i = 0; i < date.daysInMonth(); i++) {
          if (
            date.weekday() === start.weekday() &&
            this.getWeekByDate(date) === this.getWeekByDate(start)
          ) {
            disabledDates.push(date.toDate())
          }
          date.add(1, 'day')
        }
      }
    }
    return disabledDates
  }

  static getRecurrenceByYear = ({
    startDate,
    endDate,
    repeatEvery,
    workingDays
  }) => {
    const {
      start,
      end,
      date,
      disabledDates
    } = ScheduleHelper.getInitialRecurrenceDates(startDate, endDate, 10)

    while (date.isSameOrBefore(end)) {
      date.add(repeatEvery, 'years')
      if (date.daysInMonth() < start.date()) {
        continue
      }
      date.date(start.date())
      if (ScheduleHelper.isDateInWorkingDays(date, workingDays)) {
        disabledDates.push(date.toDate())
      }
    }
    return disabledDates
  }

  static getWeekByDate = date => {
    const dateObject = moment(date).clone()

    const monthStart = dateObject.clone().startOf('month')
    const isDayInFirstWeek = dateObject.day() >= monthStart.day()
    const week = Math.ceil(
      dateObject.diff(monthStart.clone().startOf('week'), 'day', true) / 7
    )

    let weekOffset = -1

    if (isDayInFirstWeek) {
      weekOffset++
    }

    if (dateObject.day() === 0) {
      weekOffset++
    }

    return week + weekOffset
  }

  static hasAssignedItems = ({
    scheduleType,
    deviceList,
    deviceTags,
    deviceGroups,
    deviceLocations
  }) => {
    let assignLists = [deviceList]

    if (scheduleType !== scheduleTypes.Failover.name) {
      assignLists = [...assignLists, deviceTags, deviceGroups, deviceLocations]
    }

    return assignLists.some(list => list?.length)
  }
}
