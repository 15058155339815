import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import DeviceVersionCell from 'components/Pages/Admin/DeviceLibrary/DeviceTable/DeviceVersionCell'

const useStyles = makeStyles(theme => ({
  wrapper: {
    lineHeight: '20px'
  }
}))

const DeviceVersionAgCell = ({ value, column, context }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.wrapper}
    >
      <DeviceVersionCell
        version={value}
        latestVersion={context?.getLatestAPVersionByColumnId(column.colId)}
        latestVersionTooltip={context?.getLatestAPVersionTooltipByColumnId(
          column.colId
        )}
      />
    </Grid>
  )
}

export default DeviceVersionAgCell
