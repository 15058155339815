import { lazy } from 'react'

const ReportsLibrary = lazy(() => import('./Reports'))
const ScheduleLibrary = lazy(() => import('./Schedule'))
const ScheduleTimeline = lazy(() => import('./Schedule/ScheduleTimeline'))
const FontLibrary = lazy(() => import('./Font'))
const CreateReport = lazy(() => import('./CreateReport'))
const CustomReport = lazy(() => import('./CustomReport'))
const DownloadReport = lazy(() => import('./Reports/DownloadReport'))
const RolesAndPermissions = lazy(() => import('./RolesAndPermissions'))
const ApplicationHealth = lazy(() => import('./ApplicationHealth'))
const EulaTerms = lazy(() => import('./EulaTerms'))
const HTMLContentLibrary = lazy(() => import('./HTMLContentLibrary'))
const WorkplacePostersLibrary = lazy(() => import('./WorkplacePostersLibrary'))
const TemplateBackgroundLibrary = lazy(() => import('./TemplateBackground'))
const PlaylistLibrary = lazy(() => import('./Playlist'))
const AccountSettings = lazy(() => import('./AccountSettings'))
const MediaLibrary = lazy(() => import('./Media'))
const UserDashboard = lazy(() => import('./UserDashboard'))
const TemplateLibrary = lazy(() => import('./Template'))
const MenuMakerLibrary = lazy(() => import('./MenuMakerLibrary'))
const DesignGalleryLibrary = lazy(() => import('./DesignGalleryLibrary'))
const RoomsLibrary = lazy(() => import('./RoomsLibrary'))
const ThirdPartyReports = lazy(() => import('./ThirdPartyReports'))
const ProofOfPlayReports = lazy(() => import('./ProofOfPlayReports'))
const SchedulePublish = lazy(() => import('./Schedule/SchedulePublish'))
const CreateTemplate = lazy(() => import('./Template/CreateTemplate'))
const MenuMakerDesign = lazy(() =>
  import('components/CanvasEditors').then(module => ({
    default: module.MenuMakerDesign
  }))
)
const DesignGallery = lazy(() =>
  import('components/CanvasEditors').then(module => ({
    default: module.DesignGallery
  }))
)
const UserProfile = lazy(() => import('./UserProfile/UserProfile'))
const ReportsApiUsage = lazy(() => import('./Reports/ReportsApiUsage'))

export {
  UserDashboard,
  AccountSettings,
  MediaLibrary,
  PlaylistLibrary,
  ReportsLibrary,
  ScheduleLibrary,
  ScheduleTimeline,
  TemplateLibrary,
  FontLibrary,
  CreateReport,
  CustomReport,
  DownloadReport,
  DesignGallery,
  RolesAndPermissions,
  ApplicationHealth,
  EulaTerms,
  HTMLContentLibrary,
  WorkplacePostersLibrary,
  TemplateBackgroundLibrary,
  MenuMakerLibrary,
  MenuMakerDesign,
  RoomsLibrary,
  ThirdPartyReports,
  ProofOfPlayReports,
  SchedulePublish,
  CreateTemplate,
  UserProfile,
  ReportsApiUsage,
  DesignGalleryLibrary
}
