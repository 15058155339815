import { _includes, _reduce, _keys } from 'utils/lodash'
import { getData, getErrors, getException, getMessage } from 'utils/object'
import { colors } from 'theme'

const rawDataErrorCodes = [409]

const defaultError = {
  code: 400,
  message: 'Oops.. Something went wrong',
  errors: [],
  errorFields: []
}

function createErrorFieldObj(name, value) {
  return { name, value }
}

export function parseErrors(errors) {
  return _reduce(
    errors,
    (acc, cur) => {
      return [...acc, cur]
    },
    []
  )
}

function parseErrorFields(errors) {
  return _reduce(
    _keys(errors),
    (acc, cur) => {
      return [...acc, createErrorFieldObj(cur, errors[cur])]
    },
    []
  )
}

function errorHandler({ response }) {
  if (!response) {
    return defaultError
  }

  const { status, data, config } = response
  if (_includes(rawDataErrorCodes, status)) {
    return {
      ...defaultError,
      ...data,
      code: status,
      config,
      message: getMessage(data),
      innerMessage: getMessage(data)
    }
  }

  if (getErrors(getData(response))) {
    return {
      ...defaultError,
      code: status,
      message: getMessage(data),
      errors: parseErrors(getErrors(data)),
      errorFields: parseErrorFields(getErrors(data))
    }
  }

  if (getMessage(data)) {
    return {
      ...defaultError,
      code: status,
      message: getMessage(data),
      exception: getException(data)
    }
  }

  return {
    ...defaultError,
    code: status
  }
}

export default errorHandler

export function isPhoneInvalid(code, message) {
  return code === 400 && message === 'Phone Invalid'
}

export const parseErrorMessage = message => {
  switch (message) {
    case 'Your client account subscription is expired':
    case 'Your account subscription has expired or has been deactivated.':
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <span>{message}</span>
          <span>
            {' '}
            Contact the{' '}
            <a
              style={{ color: colors.highlightDark, fontWeight: 'bold' }}
              href="https://mvixdigitalsignage.com/submit-a-ticket"
              target="_blank"
              rel="noreferrer"
            >
              Client Success Team
            </a>{' '}
            for support
          </span>
        </div>
      )
    default:
      return message
  }
}

const getErrorsFromObject = error => {
  return typeof error === 'object'
    ? Object.values(error)
        .map(data => getErrorsFromObject(data))
        .join(' ')
    : error
}

export const parseNestedError = error => {
  return Array.isArray(error)
    ? error.map(data => getErrorsFromObject(data)).join(' ')
    : error
}

export const parseFeatureSettingError = (error, featureSettings, features) => {
  try {
    const featureIndex = error.name.match(/featureSetting.(\d+)/)[1]
    const featureId = featureSettings[featureIndex].id
    const feature = features.find(({ id }) => id === featureId)

    return error.value
      .join(' ')
      .replaceAll(/The feature setting\.(\d+)\.*/g, `${feature.alias} `)
  } catch (e) {
    return error.value.join(' ')
  }
}
