import React from 'react'
import classNames from 'classnames'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import Spacing from 'components/Containers/Spacing'
import { TextWithTooltip } from 'components/Typography'
import { LIGHT } from 'theme'

const useStyles = makeStyles(({ colors, palette, type }) => ({
  icon: {
    color: colors.light,
    marginRight: 4,
    minWidth: 16
  },
  checkIcon: {
    color: colors.light,
    minWidth: 12,
    marginRight: 0
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor:
        type === LIGHT
          ? 'rgba(0, 0, 0, 0.04)' //TODO fix theme colors
          : palette[type].tableLibrary.body.row.hover
    }
  },
  rowSelected: {
    backgroundColor:
      type === LIGHT
        ? 'rgba(0, 0, 0, 0.04)' //TODO fix theme colors
        : palette[type].tableLibrary.body.row.hover
  }
}))

const PopupRow = ({ option, selected, onSelect }) => {
  const { value, label, meta } = option

  const classes = useStyles()

  return (
    <Spacing
      key={value}
      variant={0}
      paddingVert={1}
      direction="row"
      paddingHor={2}
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
      rootClassName={classNames(classes.row, {
        [classes.rowSelected]: selected
      })}
      onClick={() => onSelect(option)}
    >
      <Grid container item alignItems="center">
        {meta?.icon &&
          (typeof meta?.icon === 'string' ? (
            <i
              className={classNames(classes.icon, meta?.icon)}
              style={{ color: meta?.color }}
            />
          ) : (
            <meta.icon
              className={classes.icon}
              style={{ color: meta?.color, width: 16, marginRight: 6 }}
            />
          ))}
        <TextWithTooltip maxWidth={170}>{label}</TextWithTooltip>
      </Grid>
      {selected && (
        <Grid item>
          <i className={classNames('fa-solid fa-check', classes.checkIcon)} />
        </Grid>
      )}
    </Spacing>
  )
}

PopupRow.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
}

export default PopupRow
