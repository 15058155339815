import { _capitalize } from 'utils/lodash'

const LibraryList = [
  'Device',
  'Media',
  'Font',
  'Playlist',
  'Template',
  'Report',
  'Schedule'
]
const otherList = {
  Application: 'Application Versions',
  Messages: 'Messages',
  'Html Contents': 'HTML Content',
  Youtube: 'Youtube',
  Radio: 'Radio',
  'Roles Permissions': 'Roles And Permissions',
  'Media Rss': 'Media RSS',
  'Workplace Posters': 'Workplace Posters'
}
function parsePathname(pathname) {
  return pathname
    .replace('/org', '')
    .replace('/system', '')
    .replace('/enterprise', '')
    .replace('-library', '')
    .slice(1)
    .split('/')
    .slice(0, 1)[0]
    .split('-')
    .map(_capitalize)
    .join(' ')
    .replace('Eula', 'EULA')
}

function joinTitle(name, title) {
  return `${name} | ${title}`
}

export const setTitle = (pathname, windowTitle) => {
  try {
    if (LibraryList.includes(parsePathname(pathname))) {
      document.title = joinTitle(
        parsePathname(pathname) + ' Library',
        windowTitle
      )
    } else if (Object.keys(otherList).includes(parsePathname(pathname))) {
      document.title = joinTitle(
        otherList[parsePathname(pathname)],
        windowTitle
      )
    } else {
      document.title = joinTitle(parsePathname(pathname), windowTitle)
    }
  } catch (err) {
    console.error(err)
  }
}

export const handleFacebookLogout = () => {
  try {
    window.FB.logout()
  } catch (e) {}
}
