import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { _isEmpty } from 'utils/lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  putPreferenceByEntity,
  getPreferenceByEntity,
  clearPreferenceByEntity
} from 'actions/preferenceActions'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import { stableSort } from 'utils'
import {
  getAllowedGridViewPerPage,
  getAllowedListViewPerPage,
  getAllowedLocationsViewPerPage
} from 'utils/libraryUtils'
import { userClientDetailsSelector } from 'selectors/userSelectors'
import usePreferenceNew from './usePreferenceNew'

//DEPRECATED, use "usePreferenceNew" instead
const usePreference = (
  {
    fetcher,
    initialColumns,
    entity,
    initialPerPage,
    initialOrder,
    initialSort,
    fetchOnResponse = true,
    withClearAction,
    serverSide = true,
    ...fetcherParams
  },
  newColumns
) => {
  const location = useLocation()
  const preference = useSelector(
    ({
      preference: {
        [entity]: { response: preference }
      }
    }) => preference
  )

  const [columns, setColumns] = useState(initialColumns)
  const [perPage, setPerPage] = useState(preference.recordsPerPage || 10)
  const [order, setOrder] = useState(
    preference.defaultSortOrder || initialOrder || 'asc'
  )
  const [sort, setSort] = useState(
    preference.defaultSortColumn || initialSort || null
  )
  const [initialized, setInitialized] = useState(false)

  const role = useUserRole()
  const clientSettings = useSelector(userClientDetailsSelector)

  const userRole = useMemo(() => role?.role, [role?.role])

  const setColumnsWithSortOrder = useCallback(
    cols => {
      if (initialColumns) {
        initialColumns.forEach(i => {
          const col = cols.find(col => col.id === i.id)
          if (col) {
            col.label = i.label
          }
        })
      }
      return setColumns(
        stableSort(
          cols.filter(
            ({ forRoles, forFeatures }) =>
              (!forRoles || forRoles.some(r => r === userRole)) &&
              (!forFeatures ||
                (clientSettings &&
                  forFeatures.some(f =>
                    clientSettings.feature.some(({ name }) => name === f)
                  )))
          ),
          (lhs, rhs) => (lhs.sortOrder || 0) - (rhs.sortOrder || 0)
        )
      )
    },
    [setColumns, initialColumns, userRole, clientSettings]
  )

  const getAllowedLimit = useCallback(
    recordsPerPage => {
      if (location.pathname.includes('/grid')) {
        return getAllowedGridViewPerPage(recordsPerPage, role.role)
      } else if (location.pathname.includes('/location')) {
        return getAllowedLocationsViewPerPage(recordsPerPage, role.role)
      } else {
        return getAllowedListViewPerPage(recordsPerPage, role.role)
      }
    },
    [location, role]
  )

  useEffect(() => {
    if (!_isEmpty(newColumns)) setColumnsWithSortOrder(newColumns)
  }, [newColumns, setColumnsWithSortOrder])

  const status = useSelector(
    ({
      preference: {
        [entity]: { status }
      }
    }) => status
  )

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      if (withClearAction) {
        dispatch(clearPreferenceByEntity(entity))
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if ((role.system || role.org) && (role.system || clientSettings)) {
      const newColumns = columns.map(column =>
        typeof column.display === 'function'
          ? { ...column, display: column.display(role) }
          : column
      )
      setColumnsWithSortOrder(newColumns)
      if (serverSide) {
        dispatch(getPreferenceByEntity(entity))
      } else {
        const limit = getAllowedLimit(perPage)
        fetcher({
          page: 1,
          limit,
          sort: sort || (entity === 'DeviceLibrary' ? 'alias' : null),
          order: order || 'asc'
        })
      }
    }
    // eslint-disable-next-line
  }, [userRole, clientSettings])

  // TODO Remove after will be ready the enterprise preference
  useEffect(() => {
    if (role.enterprise) {
      fetcher()
      setInitialized(true)
    }
    // eslint-disable-next-line
  }, [userRole])

  useEffect(() => {
    const {
      gridColumn,
      recordsPerPage,
      defaultSortColumn,
      defaultSortOrder,
      entity
    } = preference

    const isFetchAllowed =
      fetchOnResponse && !location.pathname.includes('/location')

    if (!_isEmpty(preference)) {
      const limit = getAllowedLimit(recordsPerPage)
      const newColumns = columns.map(column => {
        const gridCol = Array.isArray(gridColumn)
          ? gridColumn.find(gridCol => gridCol.id && gridCol.id === column.id)
          : null
        return gridCol ? { ...column, ...gridCol } : column
      })
      setColumnsWithSortOrder(newColumns)
      setPerPage(limit)
      setOrder(defaultSortOrder || 'asc')
      setSort(defaultSortColumn || null)
      setInitialized(isFetchAllowed)
      if (isFetchAllowed) {
        fetcher({
          ...fetcherParams,
          limit,
          sort:
            defaultSortColumn || (entity === 'DeviceLibrary' ? 'alias' : null),
          order: defaultSortOrder || null
        })
      }
    } else if (status === 'empty' && isFetchAllowed) {
      const limit = getAllowedLimit(perPage)
      setInitialized(isFetchAllowed)
      fetcher({
        page: 1,
        limit,
        sort: sort || (entity === 'DeviceLibrary' ? 'alias' : null),
        order: order || 'asc'
      })
    }
    // eslint-disable-next-line
  }, [preference, setColumnsWithSortOrder])

  const changeColumns = useCallback(
    values => {
      setColumnsWithSortOrder(values)

      if (!role.enterprise && serverSide) {
        dispatch(
          putPreferenceByEntity(entity, {
            recordsPerPage: perPage,
            gridColumn: values
          })
        )
      }
    },
    [dispatch, entity, role, perPage, setColumnsWithSortOrder, serverSide]
  )

  const changeRecordsPerPage = useCallback(
    limit => {
      setPerPage(limit)
      if (!role.enterprise && serverSide) {
        dispatch(
          putPreferenceByEntity(entity, {
            recordsPerPage: limit,
            gridColumn: columns
          })
        )
      }
    },
    [columns, dispatch, entity, role, serverSide]
  )

  const changeSorting = useCallback(
    (sort, order) => {
      setSort(sort)
      setOrder(order)
      if (!role.enterprise && serverSide) {
        dispatch(
          putPreferenceByEntity(entity, {
            recordsPerPage: perPage,
            gridColumn: columns,
            defaultSortColumn: sort,
            defaultSortOrder: order
          })
        )
      }
    },
    [columns, dispatch, entity, role, perPage, serverSide]
  )

  const toggleDisplayColumn = useCallback(
    (id, display) => {
      const modifiedColumns = columns.map(value =>
        id === value.id
          ? {
              ...value,
              display: display == null ? value.display === false : display
            }
          : value
      )

      if (!role.enterprise && serverSide) {
        dispatch(
          putPreferenceByEntity(entity, {
            recordsPerPage: perPage,
            gridColumn: modifiedColumns
          })
        )
      }
      setColumnsWithSortOrder(modifiedColumns)
      return modifiedColumns
    },
    [
      dispatch,
      entity,
      role,
      perPage,
      columns,
      setColumnsWithSortOrder,
      serverSide
    ]
  )

  const resetColumns = useCallback(() => {
    setColumnsWithSortOrder(initialColumns)
    if (!role.enterprise && serverSide) {
      dispatch(
        putPreferenceByEntity(entity, {
          recordsPerPage: perPage,
          gridColumn: initialColumns
        })
      )
    }
  }, [
    initialColumns,
    entity,
    perPage,
    setColumnsWithSortOrder,
    dispatch,
    role,
    serverSide
  ])

  return {
    actions: {
      changeColumns,
      changeRecordsPerPage,
      toggleDisplayColumn,
      changeSorting,
      resetColumns
    },
    columns,
    perPage,
    sort,
    order,
    initialized
  }
}

export default usePreference

export const withPreference = mapPropsToPreference => Component => {
  return props => {
    const { entity, initialColumns, initialSortOptions } = mapPropsToPreference(
      props
    )
    const {
      isFetchAllowed,
      preferenceParams,
      preferenceActions,
      columns
    } = usePreferenceNew({
      entity,
      initialColumns,
      initialSortOptions
    })

    return (
      <Component
        preferenceActions={preferenceActions}
        preferenceColumns={columns}
        preferencePerPage={preferenceParams.limit}
        preferenceSortOptions={preferenceParams}
        isFetchAllowed={isFetchAllowed}
        {...props}
      />
    )
  }
}
