import momentTZ from 'moment-timezone'

import { parseTimezoneName } from 'constants/timezones'
import { selectUtils } from './index'

const filterByValue = data => {
  return data.filter(
    (item, index, array) =>
      array.findIndex(({ value }) => item.value === value) === index &&
      item.value
  )
}

export const transformDataToOptions = (
  valueField,
  labelFields = [valueField],
  joinString = ', '
) => {
  return (data = []) => {
    return filterByValue(
      data.map(item => ({
        value: valueField
          ? item[valueField]
          : labelFields.map(field => item[field]).join(joinString),
        label: labelFields.map(field => item[field]).join(joinString)
      }))
    )
  }
}

export const transformOneLevelDeepDataToOptions = (childField, parentField) => {
  return (data = []) => {
    return filterByValue(
      data.map(item => {
        const childFieldValue = item[parentField]
          ? item[parentField][childField]
          : null
        return {
          value: childFieldValue,
          label: childFieldValue
        }
      })
    )
  }
}

export const transformClientToOptions = data => {
  return filterByValue(
    data.map(({ name }) => ({
      value: name,
      label: name
    }))
  )
}
export const transformClientIdToOptions = data => {
  return filterByValue(
    data.map(({ id }) => ({
      value: id,
      label: id
    }))
  )
}
export const transformClientNameAndIdToOptions = data => {
  return filterByValue(
    data.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  )
}
export const transformClientNameWithIdToOptions = data => {
  return filterByValue(
    data.map(
      ({
        id,
        name,
        type,
        country,
        city,
        address1,
        address2,
        state,
        zipCode,
        feature,
        featureSetting,
        serviceLevel
      }) => ({
        value: id,
        label: `${name} [${id}]`,
        type,
        country,
        city,
        address1,
        address2,
        state,
        zipCode,
        feature,
        featureSetting,
        serviceLevel
      })
    )
  )
}

export const transformClientNameWithIdToOptionsAndOtherValues = data => {
  return filterByValue(
    data.map(({ id, name, ...rest }) => ({
      ...rest,
      value: id,
      label: `${name} [${id}]`
    }))
  )
}

export const transformMediaContentToClientNameOptions = data => {
  return filterByValue(
    data.map(({ client: { name } }) => ({
      value: name,
      label: name
    }))
  )
}

export const transformDeviceNameAndIdToOptions = data => {
  return filterByValue(
    data.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  )
}

export const transformDeviceLocationToOptions = data =>
  transformDataToOptions('city', ['city', 'state'])(data)

export const transformAliasAndIdToOptions = data => {
  return filterByValue(
    data.map(({ id, alias }) => ({
      value: id,
      label: alias
    }))
  )
}

export const transformMigrationToClientNameOptions = data => {
  return filterByValue(
    data.map(({ clientId, clientName, client }) => ({
      value: clientId || client?.id,
      label: clientName || client?.name
    }))
  )
}

export const transformTimezoneNamesToOptions = timezoneNames =>
  timezoneNames
    .sort((a, b) => {
      const numbersRegexp = /^([+-]\d{1,2}):(\d{1,2})/
      const offsetA = parseFloat(
        momentTZ.tz(a).format('Z').replace(numbersRegexp, '$1.$2')
      )
      const offsetB = parseFloat(
        momentTZ.tz(b).format('Z').replace(numbersRegexp, '$1.$2')
      )

      return offsetA < offsetB
        ? -1
        : offsetA > offsetB
        ? 1
        : parseTimezoneName(a).localeCompare(parseTimezoneName(b))
    })
    .map(name => ({
      value: name,
      label: `(GMT ${momentTZ.tz(name).format('Z')}) ${parseTimezoneName(name)}`
    }))

export const transformTagToOptions = data =>
  data.map(tag => selectUtils.tagToChipObj(tag))

export const transformGroupToOptions = data =>
  data.map(group => selectUtils.toGroupChipObj(group))

export const translateOptions = (options, t) =>
  options.map(option => ({ ...option, label: t(option.label) }))
