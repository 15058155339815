import { useMemo } from 'react'

import { featureNames } from 'constants/featureConstants'
import { _isEmpty } from 'utils/lodash'

const useProofOfPlaySettings = ({ client, role }) => {
  return useMemo(() => {
    if (role?.enterprise || !role.role) {
      return {
        proofOfPlayOptions: ['all'],
        proofOfPlayEnabledByClient: false
      }
    }

    const { feature, featureSetting } = client

    const id = feature?.find(
      feature => feature.name === featureNames.ProofOfPlay
    )?.id
    const proofOfPlaySetting = featureSetting?.find(
      setting => setting.id === id
    )

    return {
      proofOfPlayEnabledByClient: !_isEmpty(proofOfPlaySetting?.settings),
      proofOfPlayOptions: proofOfPlaySetting?.settings?.org_settings?.options
    }
  }, [client, role])
}

export default useProofOfPlaySettings
