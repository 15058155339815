import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ChromePicker } from 'react-color'
import { InputBase, Grid, withStyles, Popover } from '@material-ui/core'
import { rgbaObjectToString } from 'utils/color'
import { simulateEvent } from 'utils/formik'
import FormControlLabel from 'components/Form/FormControlLabel'
import Error from './Error'

function styles({
  transitions,
  shapes,
  palette,
  type,
  colors,
  typography,
  formControls
}) {
  return {
    container: {
      width: '100%',
      position: 'relative',
      overflow: 'hidden'
    },
    marginBottom: {
      marginBottom: 16
    },
    inputContainer: {
      position: 'relative',
      width: '100%'
    },
    inputContainerHover: {
      '&:hover': {
        '& > $colorBox': {
          width: 0
        },
        '& > $divider': {
          opacity: 0,
          transform: 'translateX(36px)'
        }
      }
    },
    inputRoot: {
      width: '100%'
    },
    input: {
      width: '100%',
      height: shapes.height.primary,
      backgroundColor: palette[type].formControls.input.background,
      border: `1px solid ${palette[type].formControls.input.border}`,
      color: '#74809A',
      borderRadius: 4,
      paddingRight: 40,
      transition: transitions.create(
        ['border-color', 'box-shadow', 'padding-right'],
        { duration: '.2s' }
      ),
      cursor: 'pointer'
    },
    inputHover: {
      '&:hover': {
        paddingRight: 8
      }
    },
    'input-normal': {
      fontSize: 13,
      paddingLeft: formControls.input.paddingLeft
    },
    'input-small': {
      fontSize: 12,
      paddingLeft: 5
    },
    inputError: {
      borderColor: colors.error
    },
    inputFocus: {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    inputDisabled: {
      color: palette[type].formControls.disabled.color,
      backgroundColor: palette[type].formControls.disabled.background,
      cursor: 'default'
    },
    divider: {
      width: 1,
      height: 30,
      background: palette[type].formControls.input.border,
      position: 'absolute',
      right: 39,
      bottom: 1,
      opacity: 1,
      transition: 'all .2s'
    },
    colorBox: {
      width: 36,
      height: 28,
      position: 'absolute',
      right: 2,
      bottom: 2,
      borderRadius: 3,
      transition: 'width .2s',
      cursor: 'pointer'
    },
    popoverRoot: {
      zIndex: '1400 !important'
    },
    popoverPaper: {
      borderRadius: 4,
      transform: 'translateY(5px) !important',
      padding: 5,
      background: colors.background.primary[type],

      '& .chrome-picker': {
        boxShadow: 'none !important',
        width: '200px !important',
        background: `${colors.background.primary[type]} !important`,
        '& > div > div': {
          borderRadius: 3,
          '& svg': {
            fill: `${palette[type].formControls.input.color} !important`,
            background: 'transparent !important'
          },
          '& > div > div > div ': {
            '& > input': {
              fontSize: '13px !important',
              lineHeight: '22px',
              fontFamily: typography.fontFamily,
              backgroundColor: palette[type].formControls.input.background,
              border: `1px solid ${palette[type].formControls.input.border} !important`,
              color: `${palette[type].formControls.input.color} !important`,
              boxShadow: 'none !important',
              textTransform: 'lowercase',
              height: '32px !important'
            },
            '& > span': {
              fontSize: '12px !important',
              color: `${palette[type].formControls.input.color}  !important`,
              fontFamily: typography.fontFamily
            }
          }
        }
      }
    }
  }
}

/**
 *
 * @param id - string
 * @param classes - object
 * @param name - string
 * @param value - string
 * @param label - string
 * @param onChange - function
 * @param onBlur - function
 * @param hex - boolean
 * @param error - string
 * @param touched - boolean
 * @param disabled - boolean
 * @param inputClass - string
 * @returns string
 * @constructor
 *
 * Since we use only RGBA values on the BE, property @hex might never be used
 *
 * To work with this component, just pass @value property with RGBA string, like
 * 'rgba(0, 0, 0, 1)' and @onChange function. After color changing, component
 * will pass new string RGBA value to the @onChange function as an HTML Event
 *
 * function simulateEvent is called for passing object like { target: { name, value } }
 * to the parent component, which is uses Formik and handleChange function for
 * updating form state.
 */

function FormControlColorPicker({
  id,
  label,
  classes,
  name,
  value,
  onChange = f => f,
  onBlur = f => f,
  hex = false,
  error,
  touched,
  disabled,
  inputClass,
  inputVariant = 'normal',
  labelFontSizeVariant = 'primary',
  hideColorOnHover,
  marginBottom = false,
  labelClassName,
  singleLineLabel = false,
  disableAlpha = false
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [color, setColor] = useState(hex ? '#000' : 'rgba(0, 0, 0, 1)')

  useEffect(() => {
    if (value) {
      setColor(value)
    }
  }, [value, setColor])

  const handleClick = useCallback(
    ({ currentTarget }) => {
      if (!disabled) {
        setAnchorEl(currentTarget)
      }
    },
    [setAnchorEl, disabled]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleChangeComplete = useCallback(
    ({ hex: newHex, rgb }) => {
      onChange(simulateEvent(name, hex ? newHex : rgbaObjectToString(rgb)))
    },
    [onChange, hex, name]
  )

  const handleChange = useCallback(
    ({ hex: newHex, rgb }) => {
      setColor(hex ? newHex : rgbaObjectToString(rgb))
    },
    [setColor, hex]
  )

  const handleBlur = useCallback(() => {
    onBlur(simulateEvent(name, null))
  }, [onBlur, name])

  const open = Boolean(anchorEl)

  return (
    <Grid
      container
      direction="column"
      className={classNames(classes.container, {
        [classes.marginBottom]: marginBottom
      })}
      onBlur={handleBlur}
    >
      <FormControlLabel
        label={label}
        rootClassName={labelClassName}
        error={touched && error}
        fontSizeVariant={labelFontSizeVariant}
        singleLine={singleLineLabel}
      />
      <div
        className={classNames(classes.inputContainer, {
          [classes.inputContainerHover]: hideColorOnHover
        })}
      >
        <InputBase
          name={name}
          classes={{
            input: classNames(
              classes.input,
              classes[`input-${inputVariant}`],
              inputClass,
              {
                [classes.inputFocus]: open,
                [classes.inputDisabled]: disabled,
                [classes.inputError]: touched && error,
                [classes.inputHover]: hideColorOnHover
              }
            )
          }}
          className={classes.inputRoot}
          value={color}
          onClick={handleClick}
          readOnly
        />
        <span className={classes.divider} />
        <div
          className={classes.colorBox}
          style={{ background: color }}
          onClick={handleClick}
        />
      </div>
      <Error error={error} condition={touched} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popoverPaper
        }}
      >
        <ChromePicker
          color={color}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
          disableAlpha={disableAlpha}
        />
      </Popover>
    </Grid>
  )
}

FormControlColorPicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  hex: PropTypes.bool,
  disabled: PropTypes.bool,
  inputClass: PropTypes.string,
  inputVariant: PropTypes.oneOf(['normal', 'small']),
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  hideColorOnHover: PropTypes.bool,
  marginBottom: PropTypes.bool,
  labelClassName: PropTypes.string
}

export default withStyles(styles)(FormControlColorPicker)
