import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { getCRUDEndpoints } from 'utils/apiUtils'

export const designGalleryCategoriesApi = createAppApi(
  'designGalleryCategoriesQuery',
  {
    tagTypes: [apiTags.designCategory],
    endpoints: builder => ({
      ...getCRUDEndpoints({
        builder,
        tags: [apiTags.designCategory],
        name: 'Category',
        pluralName: 'Categories',
        url: '/designGallery/category'
      }),
      getImageLabel: builder.query({
        query: (params = {}) => ({
          url: '/imageLabel',
          params
        })
      })
    })
  }
)

export const {
  useLazyGetCategoriesQuery,
  useLazyGetCategoryQuery,
  usePostCategoryMutation,
  usePutCategoryMutation,
  useDeleteCategoryMutation,
  useLazyGetImageLabelQuery
} = designGalleryCategoriesApi
