import React from 'react'
import PropTypes from 'prop-types'

import { TableLibraryCell } from './index'

const NumberTableLibraryCell = ({ data, fieldName, t, align = 'center' }) => {
  const number = parseFloat((data || {})[fieldName])

  return (
    <TableLibraryCell align={align}>
      {Number.isNaN(number) ? t('N/A') : number}
    </TableLibraryCell>
  )
}

NumberTableLibraryCell.propTypes = {
  data: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  align: PropTypes.string
}

export default NumberTableLibraryCell
