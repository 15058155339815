import React from 'react'
import { withTranslation } from 'react-i18next'

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core'
import trainingIconGridBottom from 'common/assets/images/trainingIconGridBottom.png'
import trainingIconGridTop from 'common/assets/images/trainingIconGridTop.png'
import { ReactComponent as LeftTriangle } from 'common/assets/icons/training-info-left-triangle.svg'
import { ReactComponent as TrophyIcon } from 'common/assets/icons/trophy.svg'
import Text from 'components/Typography/Text'

const styles = ({ typography, colors }) => {
  return {
    infoWrapper: {
      background: '#011875',
      position: 'relative'
    },
    webpageWrapper: {
      position: 'relative',
      height: '100%',
      overflow: 'hidden'
    },
    closeBtn: {
      height: '44px',
      width: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '25px',
      right: '25px'
    },
    mainContainerWrapper: {
      gap: '0',
      gridTemplateColumns: '1fr 420px'
    },
    topLeftIcon: {
      position: 'absolute',
      top: '0',
      left: '0'
    },
    topHeaderBg: {
      width: '100%',
      objectFit: 'cover'
    },
    trophyWrapper: {
      height: '32px',
      width: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto 12px auto'
    },
    headerText: {
      color: '#EFC02E',
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '400',
      textTransform: 'uppercase',
      margin: '0 0 12px 0'
    },
    headerTextSignature: {
      color: colors.white,
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: '700',
      textTransform: 'uppercase',
      margin: '0 0 12px 0'
    },
    detailsText: {
      color: colors.white,
      textAlign: 'center',
      fontFamily: typography.fontFamily,
      fontSize: '16px',
      fontWeight: '200',
      lineHeight: 'normal',
      maxWidth: '297px',
      margin: '0 auto 20px auto'
    },
    listWrap: {
      color: colors.white,
      fontFamily: 'Nunito Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '22px',
      margin: '0 auto 11px auto',
      width: 'fit-content'
    },
    bottomfooterBg: {
      width: '100%',
      objectFit: 'cover'
    },
    detailsMainWrap: {
      height: 'calc(100% - 300px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    topRightIcon: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      transform: 'rotate(180deg)'
    },
    listItemWrap: {
      padding: '0'
    },
    listItemIcon: {
      fontSize: '1.5em',
      color: colors.white
    }
  }
}

const TrainingInfo = ({ t, classes, closeModal }) => {
  const listItems = [
    t('Free Personalized Trainings'),
    t('Free Players every 3-yrs'),
    t('Lifetime Warranty on Players'),
    t('Advance Replacements / Cross-shipping'),
    t('Premium Support / SLA'),
    t('Phone Support'),
    t('Over Dozen Premium Features')
  ]
  return (
    <div className={classes.infoWrapper}>
      <div className={classes.topLeftIcon}>
        <LeftTriangle />
      </div>
      <img
        className={classes.topHeaderBg}
        src={trainingIconGridTop}
        alt="training-icon-top"
      ></img>
      <div className={classes.detailsMainWrap}>
        <div className={classes.trophyWrapper}>
          <TrophyIcon />
        </div>
        <Text component="h3" rootClassName={classes.headerText}>
          {t('Free Professional Training')}
        </Text>
        <Text component="h2" rootClassName={classes.headerTextSignature}>
          {t('Signature Care')}
        </Text>
        <Text rootClassName={classes.detailsText}>
          {t(
            'Thank you for being part of our exclusive Signature Care program'
          )}
        </Text>
        <List className={classes.listWrap}>
          {listItems.map((item, index) => (
            <ListItem
              key={index}
              disableGutters
              disablePadding
              className={classes.listItemWrap}
            >
              <ListItemIcon>
                <span className={classes.listItemIcon}>&#8226;</span>
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classes.topRightIcon}>
        <LeftTriangle />
      </div>
      <img
        className={classes.bottomfooterBg}
        src={trainingIconGridBottom}
        alt="training-icon-bottom"
      ></img>
    </div>
  )
}

export default withTranslation('translations')(withStyles(styles)(TrainingInfo))
