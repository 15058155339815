import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import moment from 'moment/moment'

import DefaultModal from 'components/Modal/DefaultModal'
import { BlueButton } from 'components/Buttons'
import Text from 'components/Typography/Text'
import { AM_PM_FORMAT, TIME_FORMAT } from 'constants/dateTimeFormats'

const useStyles = makeStyles({
  modalContent: {
    padding: '15px 20px'
  },
  row: {
    height: 35
  }
})

const ScheduleConflictModal = ({ open, onCloseModal, data }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <DefaultModal
      open={open}
      onCloseModal={onCloseModal}
      modalTitle={t('Power Off Schedules Overlap')}
      contentClass={classes.modalContent}
      actions={
        <div>
          <BlueButton
            onClick={onCloseModal}
            iconClassName={'fa-regular fa-circle-check'}
          >
            {'Ok'}
          </BlueButton>
        </div>
      }
    >
      {data.map(
        row =>
          !!row && (
            <Text rootClassName={classes.row} key={row.uuid}>
              {`${row.dayFrom} ${moment(row.timeFrom, TIME_FORMAT).format(
                AM_PM_FORMAT
              )} - ${moment(row.timeTo, TIME_FORMAT).format(AM_PM_FORMAT)} ${
                row.dayTo
              }`}
            </Text>
          )
      )}
    </DefaultModal>
  )
}

export default ScheduleConflictModal
