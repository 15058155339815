import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const DeviceGridLoaderFiveColumn = ({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 1200
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect x="20" y="20" rx="6" ry="6" width="294" height="342" />
    <rect x="340" y="20" rx="6" ry="6" width="294" height="342" />
    <rect x="655" y="20" rx="6" ry="6" width="294" height="342" />
    <rect x="969" y="20" rx="6" ry="6" width="294" height="342" />
    <rect x="1285" y="20" rx="6" ry="6" width="294" height="342" />

    <rect x="20" y="379" rx="6" ry="6" width="294" height="342" />
    <rect x="340" y="379" rx="6" ry="6" width="294" height="342" />
    <rect x="655" y="379" rx="6" ry="6" width="294" height="342" />
    <rect x="969" y="379" rx="6" ry="6" width="294" height="342" />
    <rect x="1285" y="379" rx="6" ry="6" width="294" height="342" />
  </ContentLoader>
)

export default withTheme(DeviceGridLoaderFiveColumn)
