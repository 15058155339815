import api from './api'

import { errorHandler } from '../utils'
import { getApiParamsByOriginalUser } from 'utils/localStorage'

const getClients = async (params, silent, impersonated) => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/client',
      params,
      silent,
      ...(impersonated && getApiParamsByOriginalUser('/client'))
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postClient = async data => {
  try {
    return await api({
      method: 'POST',
      url: '/client',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteClient = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/client/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getClientById = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/client/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putClient = async (id, data) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/client/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGroupItems = async (id, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/client/group/${id}`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postGroupItem = async ({ clientId, groupId }) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/client/group/${groupId}`,
      data: { clientId }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteGroupItem = async ({ clientId, groupId }) => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/client/group/${groupId}/${clientId}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getClientNotes = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/client/${id}/note`
    })

    return {
      ...data,
      note: data.note.reverse()
    }
  } catch (error) {
    throw errorHandler(error)
  }
}

const postClientNote = async (id, data) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/client/${id}/note`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getClientLicenses = async (id, params) => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/deviceLicense?clientId=${id}`,
      params,
      ...getApiParamsByOriginalUser(`/deviceLicense?clientId=${id}`)
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postClientLicense = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/deviceLicense`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putClientLicenseById = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/deviceLicense/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteClientLicenseById = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/deviceLicense/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putClientBilling = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/client/deactivate/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function editClientFeatureSettings({ clientId, reqData }) {
  try {
    const { data } = await api({
      method: 'PUT',
      url: `/client/${clientId}/featureSettings`,
      data: reqData
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postClientBulk = async ({ clientIds, data }) => {
  try {
    await api({
      method: 'POST',
      url: `/client/bulkAssignGroupsAndTags`,
      data: {
        clientIds,
        ...data
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const clientsService = {
  getClients,
  getClientById,
  postClient,
  putClient,
  deleteClient,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  getClientNotes,
  postClientNote,
  getClientLicenses,
  postClientLicense,
  putClientLicenseById,
  deleteClientLicenseById,
  putClientBilling,
  editClientFeatureSettings,
  postClientBulk
}

export default clientsService
