import update from 'immutability-helper'
import * as types from 'actions'
import {
  deleteInitialState,
  initialState as state,
  postInitialState,
  putInitialState,
  shapeOfBody,
  shapeOfMeta
} from 'constants/initialLibraryState'

const initialState = {
  ...state,
  builderData: {
    ...shapeOfBody,
    meta: {
      isLoading: false
    }
  },
  receiverData: {
    ...shapeOfBody,
    ...shapeOfMeta
  }
}

const emailNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EMAIL_NOTIFICATION_ITEMS:
      return update(state, {
        items: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_EMAIL_NOTIFICATION_ITEMS_SUCCESS:
      return update(state, {
        items: {
          response: { $set: action.response.data },
          meta: {
            $set: action.modifiedMeta
          }
        }
      })
    case types.GET_EMAIL_NOTIFICATION_ITEMS_ERROR:
      return update(state, {
        items: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS:
      return update(state, {
        item: {
          response: { $set: action.payload }
        }
      })
    case types.GET_EMAIL_NOTIFICATION_BY_ID_ERROR:
      return update(state, {
        item: {
          error: { $set: action.payload }
        }
      })
    case types.POST_EMAIL_NOTIFICATION_ITEM_SUCCESS:
      return update(state, {
        post: {
          response: { $set: action.payload }
        }
      })
    case types.POST_EMAIL_NOTIFICATION_ITEM_ERROR:
      return update(state, {
        post: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_EMAIL_NOTIFICATION_ITEM_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_EMAIL_NOTIFICATION_ITEM_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload }
        }
      })
    case types.DELETE_EMAIL_NOTIFICATION_ITEM_SUCCESS:
    case types.DELETE_SELECTED_EMAIL_NOTIFICATION_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_EMAIL_NOTIFICATION_ITEM_ERROR:
    case types.DELETE_SELECTED_EMAIL_NOTIFICATION_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_EMAIL_NOTIFICATION_INFO:
      const $spec = {
        put: {
          $set: {
            ...putInitialState
          }
        },
        post: {
          $set: {
            ...postInitialState
          }
        },
        del: {
          $set: {
            ...deleteInitialState
          }
        },
        item: {
          $set: {
            ...shapeOfBody,
            response: {}
          }
        }
      }
      return update(state, $spec)

    case types.GET_EMAIL_BUILDER_DATA:
      return update(state, {
        builderData: {
          response: { $set: [] },
          meta: {
            isLoading: {
              $set: true
            }
          }
        }
      })
    case types.GET_EMAIL_BUILDER_DATA_SUCCESS:
      return update(state, {
        builderData: {
          response: { $set: action.response },
          meta: {
            isLoading: {
              $set: false
            }
          }
        }
      })
    case types.GET_EMAIL_BUILDER_DATA_ERROR:
      return update(state, {
        builderData: {
          error: { $set: action.payload },
          meta: {
            isLoading: {
              $set: false
            }
          }
        }
      })
    case types.GET_EMAIL_RECEIVER_DATA_SUCCESS:
      return update(state, {
        receiverData: {
          response: { $set: action.response.data },
          meta: {
            $set: action.modifiedMeta
          }
        }
      })
    case types.GET_EMAIL_RECEIVER_DATA_ERROR:
      return update(state, {
        receiverData: {
          error: { $set: action.payload }
        }
      })
    default:
      return state
  }
}

export default emailNotificationReducer
