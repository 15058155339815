import { all, call, put, takeLatest } from 'redux-saga/effects'

import { mediaFeaturesService } from 'services'
import {
  GET_OFFICE_365_FILES,
  GET_OFFICE_365_SHARED_FILES,
  getOffice365FilesSuccess,
  getOffice365FilesError,
  getOffice365SharedFilesSuccess,
  getOffice365SharedFilesError
} from 'actions/new/mediaFeatures'

export default function* oauth2Watcher() {
  yield all([
    takeLatest(GET_OFFICE_365_FILES, getOffice365FilesWorker),
    takeLatest(GET_OFFICE_365_SHARED_FILES, getOffice365SharedFilesWorker)
  ])
}

function* getOffice365FilesWorker({ payload }) {
  try {
    const response = yield call(
      mediaFeaturesService.office365.getFiles,
      payload
    )
    yield put(
      getOffice365FilesSuccess({ response: response.data, meta: response.meta })
    )
  } catch (error) {
    yield put(getOffice365FilesError(error))
  }
}

function* getOffice365SharedFilesWorker({ payload }) {
  try {
    const response = yield call(
      mediaFeaturesService.office365.getSharedFiles,
      payload
    )
    yield put(
      getOffice365SharedFilesSuccess({
        response: response.data,
        meta: response.meta
      })
    )
  } catch (error) {
    yield put(getOffice365SharedFilesError(error))
  }
}
