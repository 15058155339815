export function deviceMediaEmergencyAlertSelector({ alert }) {
  return alert.deviceMediaEmergencyAlert
}

export function associateCapAlertSelector({ alert }) {
  return alert.associateCapAlert
}

export function ipawsAlertDevicesSelector({ alert }) {
  return alert.ipawsAlertDevices
}

export function associateIpawsAlertSelector({ alert }) {
  return alert.associateIpawsAlert
}

export function deviceActiveEmergencyAlertSelector({ alert }) {
  return alert.deviceActiveEmergencyAlert
}

export function postAlertTriggerSelector({ alert }) {
  return alert.postAlertTrigger
}
