import * as R from 'ramda'

import { whiteLabelConstants } from 'constants/index'

const DATA_NAME = 'xhibit-white-label-info'
const localStorage = window.localStorage

const stringify = data => {
  try {
    return JSON.stringify(data)
  } catch (error) {
    return ''
  }
}
const parse = data => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return {}
  }
}

const getInfoFromLocalStorage = () => {
  return parse(localStorage.getItem(DATA_NAME))
}
const isInfoInLocalStorage = () => {
  return Boolean(getInfoFromLocalStorage())
}
export const setInfoToLocalStorage = data => {
  return localStorage.setItem(DATA_NAME, stringify(data))
}
const isSendRequest = (requestActionFn, reducerActionFn) => {
  return R.ifElse(
    R.partial(R.not, [isInfoInLocalStorage()]),
    R.partial(requestActionFn, []),
    R.partial(reducerActionFn, [getInfoFromLocalStorage()])
  )()
}
export const sendRequestOrGetFromLocalStorage = (
  requestActionFn,
  reducerActionFn
) => {
  return R.partial(isSendRequest, [requestActionFn, reducerActionFn])
}
export const parseReducer = reducer => {
  const returnFallbackObj = () => whiteLabelConstants.fallbackValues
  const getNestedFallbackValue = (value, key) =>
    value == null
      ? whiteLabelConstants.fallbackValues[key]
      : typeof whiteLabelConstants.fallbackValues[key] === 'object'
      ? {
          ...whiteLabelConstants.fallbackValues[key],
          ...(typeof value === 'object' && value)
        }
      : value
  const getFallbackValue = (value, key) =>
    value == null ? whiteLabelConstants.fallbackValues[key] : value

  const reduceResponseWithDefaultValue = keyValuePairs =>
    keyValuePairs.reduce((mergedResponse, [key, value]) => {
      try {
        return {
          ...mergedResponse,
          [key]: getNestedFallbackValue(value, key)
        }
      } catch (e) {
        return {
          ...mergedResponse,
          [key]: getFallbackValue(value, key)
        }
      }
    }, {})

  const returnResponse = response => {
    return R.compose(reduceResponseWithDefaultValue, R.toPairs)(response)
  }
  const isIsEmptyInResponse = response => {
    return R.ifElse(
      R.has('isEmpty'),
      returnFallbackObj,
      returnResponse
    )(response)
  }
  const isEmptyResponse = reducer => {
    return R.ifElse(
      R.isEmpty,
      returnFallbackObj,
      isIsEmptyInResponse
    )(reducer.response)
  }
  return R.ifElse(
    R.has('response'),
    isEmptyResponse,
    returnFallbackObj
  )(reducer)
}

export function isAccessDenied(currentIp, { status, detail = [] }) {
  if (status === 'disabled') {
    return false
  }

  if (
    detail.find(({ status, ip }) => ip === currentIp && status === 'allowed')
  ) {
    return false
  }

  return true
}
