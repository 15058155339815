import {
  Grid,
  IconButton,
  withStyles,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Popup from 'reactjs-popup'
import classNames from 'classnames'
import ResizeObserver from 'react-resize-observer'
import PropTypes from 'prop-types'

import { GroupChip } from '../Chip'
import { sortByTitle, sortByTitleDesc } from 'utils/libraryUtils'

const styles = ({ palette, type }) => ({
  root: {
    position: 'relative',
    gap: 3,
    padding: '3px 0'
  },
  groupRoot: {
    width: 'fit-content',
    '& > span': {
      display: 'inline',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '8ch'
    }
  },
  popupGroupRoot: {
    width: 'fit-content',
    '& > span': {
      display: 'inline',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '195px'
    }
  },
  overflowIconWrap: {
    padding: '0px',
    width: '22px',
    height: '22px'
  },
  overflowIconContent: {
    fontSize: '20px',
    width: 'inherit',
    height: 'inherit',
    color: palette[type].header.rightAction.iconColor
  },
  overflowIcon: {
    transform: 'rotate(90deg)'
  },
  popupContent: {
    padding: '5px'
  },
  popupGroup: {
    margin: '5px 0 5px 5px'
  },
  groupWrap: {
    padding: '0 !important'
  }
})

const dynamicStyle = makeStyles({
  groupRoot: ({ maxWidth }) => ({
    '& > span': {
      maxWidth: `${maxWidth}px !important`
    }
  })
})

const LibraryGroupChips = ({
  maxDisplayedGroups = 5,
  maxWidth = 200,
  descend = false,
  groups = [],
  rootClasses,
  classes,
  theme: { palette, type },
  justifyContent = 'center'
}) => {
  const groupChipRef = useRef()
  const dynamicClasses = dynamicStyle({
    maxWidth
  })
  const [showTooltip, setShowTooltip] = useState(false)
  const [maxDisplayGroups, setMaxDisplayGroups] = useState(
    groups?.length < maxDisplayedGroups ? groups?.length : maxDisplayedGroups
  )

  const [displayGroups, popupGroups] = useMemo(() => {
    const displayGroupsCount = !groups
      ? 0
      : groups.length > maxDisplayGroups
      ? maxDisplayGroups
      : groups.length
    const newGroups = displayGroupsCount ? [...groups] : []

    const sortedGroups = descend
      ? sortByTitleDesc(newGroups)
      : sortByTitle(newGroups)

    return [
      sortedGroups.slice(0, displayGroupsCount),
      sortedGroups.slice(displayGroupsCount)
    ]
  }, [groups, maxDisplayGroups, descend])

  useEffect(() => {
    if (groupChipRef.current) {
      const textElement = groupChipRef.current?.querySelector('span')
      setShowTooltip(
        textElement?.clientWidth !== textElement?.scrollWidth ||
          textElement?.clientWidth > maxWidth
      )
    }
    // eslint-disable-next-line
  }, [groupChipRef.current, maxWidth])

  const handleResize = ({ height }) => {
    if (height >= 70) {
      setMaxDisplayGroups(max => max - 1)
    }
  }

  if (!displayGroups.length) {
    return 'N/A'
  }

  return (
    <Grid
      container
      justifyContent={justifyContent}
      spacing={1}
      className={classNames(classes.root, rootClasses)}
    >
      {displayGroups.map(({ id, title = '', color }) => (
        <Tooltip arrow key={id} title={showTooltip ? title : ''}>
          <Grid item className={classes.groupWrap}>
            <GroupChip
              label={title}
              color={color}
              classes={{
                root: classNames(classes.groupRoot, dynamicClasses.groupRoot)
              }}
              ref={groupChipRef}
            />
          </Grid>
        </Tooltip>
      ))}
      {!!popupGroups.length && (
        <Popup
          on="hover"
          position="bottom center"
          contentStyle={{
            background: palette[type].tableLibrary.body.row.dropdown.background,
            border: 'none',
            borderRadius: 6,
            animation: 'fade-in',
            padding: '0px',
            width: 230
          }}
          arrowStyle={{
            background: palette[type].tableLibrary.body.row.dropdown.background,
            border: 'none'
          }}
          trigger={
            <IconButton
              classes={{
                root: classes.overflowIconWrap,
                label: classes.overflowIconContent
              }}
            >
              <i
                className={[
                  'fa-regular fa-ellipsis-vertical',
                  classes.overflowIcon
                ].join(' ')}
              />
            </IconButton>
          }
        >
          <Grid container className={classes.popupContent} spacing={1}>
            {popupGroups.map(({ id, title, color }, index) => (
              <Grid item className={classes.popupGroup} key={id}>
                <GroupChip
                  label={title}
                  color={color}
                  classes={{
                    root: classes.popupGroupRoot
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Popup>
      )}
      <div className="no-print">
        <ResizeObserver onResize={handleResize} />
      </div>
    </Grid>
  )
}

LibraryGroupChips.propTypes = {
  groups: PropTypes.array,
  descend: PropTypes.bool
}

export default withStyles(styles, { withTheme: true })(LibraryGroupChips)
