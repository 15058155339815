import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'

export const preferenceApi = createAppApi('preferenceQuery', {
  tagTypes: [apiTags.filter],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      url: '/filter',
      builder,
      name: 'Filter',
      tags: [apiTags.filter]
    })
  })
})

export const {
  // Filter
  useGetFilterQuery,
  useGetFiltersQuery,
  useDeleteFilterMutation,
  usePostFilterMutation,
  usePutFilterMutation
} = preferenceApi
