import React, { useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { _get } from 'utils/lodash'
import { useSelector } from 'react-redux'

import { permissionNames, templateConstants } from 'constants/index'
import TemplateMoreInfoCard from './TemplateMoreInfoCard'
import { isMediaFeatureAvailable } from 'utils/mediaUtils'
import { getTemplateFeatureNameByType } from 'utils/templateUtils'
import { templateTypes } from 'constants/template'
import { CLIENT_ADMIN } from 'constants/roles'
import { userDetailsSelectorMemoized } from 'selectors/userSelectors'
import GridViewCard, {
  TemplateCardBottomInfo
} from 'components/Card/GridViewCard'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  interactiveIconWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(255, 61, 132)'
  },
  interactiveIcon: {
    transform: 'scale(5)',
    marginTop: 5
  }
})

const TemplateCard = ({ t, ...props }) => {
  const classes = useStyles()

  const {
    template,
    isSelected = false,
    onToggleSelect = f => f,
    onDelete = f => f,
    onPreview = f => f,
    onApprove = f => f,
    onClone,
    onEdit = f => f,
    onShare = f => f,
    onCopy = f => f,
    getPermissionByName,
    getImpersonatePermissionByName,
    role = {},
    permissions = {},
    isRootPath
  } = props
  const { id, title, templateType, thumbUrl } = template
  const templateTypeInfo =
    templateConstants.templateTypes[templateType] ||
    templateConstants.templateTypes.Standard
  const { response } = useSelector(userDetailsSelectorMemoized)

  const handleSelect = useCallback(() => {
    onToggleSelect(id)
  }, [id, onToggleSelect])

  const actions = useMemo(() => {
    const templateFeatureName = getTemplateFeatureNameByType(
      template.templateType
    )
    return [
      {
        label: t('Approve'),
        clickAction: onApprove,
        icon: 'fa-regular fa-circle-check',
        render:
          permissions.approve &&
          role.level === CLIENT_ADMIN &&
          !template.approved
      },
      {
        label: t('Clone'),
        clickAction: onClone,
        icon: 'fa fa-clone',
        disabled: !_get(template, 'permission.write', false),
        render: template.approved && onClone
      },
      {
        label: t('Edit action'),
        clickAction: onEdit,
        icon: 'fa-sharp fa-regular fa-pen-to-square',
        render:
          permissions.update &&
          (templateTypes.Standard.name === templateFeatureName ||
            isMediaFeatureAvailable(templateFeatureName, response, role)),
        disabled: !_get(template, 'permission.write', false)
      },
      {
        label: t('Share'),
        clickAction: onShare,
        icon: 'fa-regular fa-share-nodes',
        render: (role.system || role.enterprise) && permissions.other
      },
      {
        label: t('Copy'),
        clickAction: onCopy,
        icon: 'fa fa-copy',
        render:
          role.system || role.enterprise
            ? getPermissionByName &&
              getPermissionByName(
                permissionNames.SYSTEM_TEMPLATE_COPY,
                permissionNames.ENTERPRISE_TEMPLATE_COPY
              )
            : getImpersonatePermissionByName &&
              getImpersonatePermissionByName(
                permissionNames.SYSTEM_TEMPLATE_COPY,
                permissionNames.ENTERPRISE_TEMPLATE_COPY
              )
      },
      {
        label: t('Delete action'),
        icon: 'fa-regular fa-trash-can',
        clickAction: onDelete,
        render: permissions.delete,
        disabled: !_get(template, 'permission.delete', false)
      }
    ]
  }, [
    onEdit,
    onDelete,
    onApprove,
    onClone,
    onShare,
    onCopy,
    getImpersonatePermissionByName,
    getPermissionByName,
    permissions,
    template,
    response,
    role,
    t
  ])

  return (
    <GridViewCard
      title={title}
      thumbnail={thumbUrl}
      thumbnailColor={templateTypeInfo.color}
      thumbnailIcon={templateTypeInfo.iconHelperClass}
      onPreview={onPreview}
      onSelect={handleSelect}
      selected={isSelected}
      actions={actions}
      item={template}
      titleHoverComponent={<TemplateMoreInfoCard item={template} />}
      bottomComponent={<TemplateCardBottomInfo template={template} />}
      animationAllowed={isRootPath}
      {...(templateTypeInfo.iconComponent && {
        customThumbnailComponent: (
          <div className={classes.interactiveIconWrapper}>
            <templateTypeInfo.iconComponent
              iconComponentColor="#000"
              wrapperClassName={classes.interactiveIcon}
            />
          </div>
        )
      })}
    />
  )
}

export default withTranslation('translations')(TemplateCard)
