import React, { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InputLabel } from '@material-ui/core'
import Error from 'components/Form/Error'
import { makeStyles } from '@material-ui/styles'
import Tooltip from 'components/Tooltip'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(
  ({ spacing, fontSize, palette, colors, lineHeight, fontWeight, type }) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative'
    },
    fullWidth: {
      width: '100%'
    },
    timePicker: {
      width: '45%'
    },
    datePicker: {
      width: '55%'
    },
    marginBottom: {
      marginBottom: spacing(2)
    },
    labelRoot: {
      fontSize: fontSize.primary,
      lineHeight: lineHeight.primary,
      fontWeight: fontWeight.normal,
      color: palette[type].formControls.label.color,
      transform: 'none',
      width: 'fit-content'
    },
    labelAlign: {
      alignItems: 'center'
    },
    labelError: {
      color: colors.error
    },
    labelTooltip: {
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
      textDecorationColor: colors.highlight,
      textUnderlineOffset: '2px',
      '&:hover': {
        cursor: 'pointer',
        textDecorationStyle: 'solid'
      }
    },
    'labelPosition-top': {
      flexDirection: 'column'
    },
    'labelPosition-left': {
      flexDirection: 'row'
    },
    'labelPosition-right': {
      flexDirection: 'row-reverse'
    },
    'labelMargin-top': {},
    'labelMargin-left': {
      marginRight: spacing(1)
    },
    'labelMargin-right': {
      marginLeft: spacing(1)
    },
    labelSmall: {
      fontSize: `${fontSize.small}px !important`
    },
    labelSmallest: {
      fontSize: `${fontSize.smallest}px !important`
    }
  })
)

const FormControlMaterialDateTimeWrap = forwardRef(
  (
    {
      label,
      tooltip,
      tooltipHeader,
      id,
      error,
      touched,
      containerRootClassName,
      labelPosition = 'top',
      isOptional = false,
      fullWidth = true,
      datePicker,
      timePicker,
      marginBottom,
      labelFontSizeVariant,
      labelRootClassName,
      onClickLabel,
      children,
      errorClassNames = ''
    },
    ref
  ) => {
    const classes = useStyles()
    const handleLabelClick = useCallback((...attr) => onClickLabel?.(...attr), [
      onClickLabel
    ])
    const { t } = useTranslation()
    return (
      <div
        ref={ref}
        className={classNames(
          classes.root,
          classes[`labelPosition-${labelPosition}`],
          containerRootClassName,
          {
            [classes.labelAlign]: ['left', 'right'].includes(labelPosition),
            [classes.fullWidth]: fullWidth,
            [classes.marginBottom]: marginBottom,
            [classes.datePicker]: datePicker,
            [classes.timePicker]: timePicker
          }
        )}
      >
        {label && (
          <Tooltip
            arrow
            title={tooltip || ''}
            {...(tooltipHeader && {
              headerText: tooltipHeader,
              withHeader: true
            })}
            headerText={tooltipHeader}
            disableHoverListener={!tooltip}
            placement="top"
          >
            <InputLabel
              shrink
              htmlFor={id}
              className={classNames(
                classes.labelRoot,
                classes[`labelMargin-${labelPosition}`],
                labelRootClassName,
                {
                  [classes.labelError]: error && touched,
                  [classes.labelSmall]: labelFontSizeVariant === 'small',
                  [classes.labelSmallest]: labelFontSizeVariant === 'smallest'
                }
              )}
              classes={{
                root: classNames({
                  [classes.labelTooltip]: tooltip || !!onClickLabel
                })
              }}
              onClick={handleLabelClick}
            >
              {label} {isOptional && <i>({t('optional')})</i>}
            </InputLabel>
          </Tooltip>
        )}
        {children}
        <Error
          absolute
          condition={!!(error && touched)}
          error={error}
          rootClassName={errorClassNames}
        />
      </div>
    )
  }
)

FormControlMaterialDateTimeWrap.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerRootClassName: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left', 'right']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltip: PropTypes.string,
  tooltipHeader: PropTypes.string,
  labelRootClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  marginBottom: PropTypes.bool,
  onClickLabel: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  errorClassNames: PropTypes.string
}

export default FormControlMaterialDateTimeWrap
