import update from 'immutability-helper'

import * as types from 'actions'
import {
  LOGIN_FAILURE,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  TWO_FACTOR_FAILURE,
  TWO_FACTOR_PENDING,
  TWO_FACTOR_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR
} from 'actions/new/auth'

const initialState = {
  login: {
    isPending: false,
    isSuccess: false,
    isFailure: false,
    error: null
  },
  twoFactor: {
    isPending: false,
    isSuccess: false,
    isFailure: false
  },
  sso: {
    userData: null,
    error: null
  },
  verify: {
    isPending: false,
    response: {},
    error: {}
  }
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_PENDING:
      return {
        ...state,
        login: {
          ...state.login,
          isPending: true
        }
      }
    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          isPending: false,
          error: action.error
        }
      }
    case TWO_FACTOR_PENDING:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isPending: true
        }
      }
    case TWO_FACTOR_SUCCESS:
    case TWO_FACTOR_FAILURE:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isPending: false
        }
      }
    case types.SSO_DATA_SUCCESS:
      return {
        ...state,
        sso: {
          ...state.sso,
          userData: action.payload
        }
      }
    case types.SSO_DATA_FAILURE:
      return {
        ...state,
        sso: {
          ...state.sso,
          error: action.error
        }
      }
    case types.CLEAR_SSO_DATA:
      return {
        ...state,
        sso: {
          userData: null,
          error: null
        }
      }
    case VERIFY_EMAIL:
      return update(state, {
        verify: {
          isPending: { $set: true },
          error: { $set: {} }
        }
      })
    case VERIFY_EMAIL_SUCCESS:
      return update(state, {
        verify: {
          isPending: { $set: false },
          response: { $set: action.payload }
        }
      })
    case VERIFY_EMAIL_ERROR:
      return update(state, {
        verify: {
          isPending: { $set: false },
          error: { $set: action.payload }
        }
      })
    default:
      return state
  }
}
