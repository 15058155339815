import { useEffect } from 'react'
import socket, { SOCKET_THUMBNAIL } from 'services/socket'

export default function useDevicePreviewSocket(listener) {
  useEffect(() => {
    socket.on(SOCKET_THUMBNAIL, listener)

    return () => {
      socket.off(SOCKET_THUMBNAIL)
    }
  }, [listener])
}
