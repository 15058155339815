import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { userRegionalSetting } from 'selectors/userSelectors.js'
import { TEMPERATURE_UNIT } from 'constants/temperatureUnitConstants'

const useRegionalSettings = () => {
  const regionalSetting = useSelector(userRegionalSetting)

  const is24TimeFormat = useMemo(() => {
    return regionalSetting?.timeFormat?.endsWith('tt') ?? false
  }, [regionalSetting])

  const isDaysFirst = useMemo(() => {
    return regionalSetting?.dateFormat?.startsWith('DD') ?? false
  }, [regionalSetting])

  const convertedTimeFormat = useCallback(
    timeFormat => {
      if (timeFormat === 'h:mmA') {
        return is24TimeFormat ? 'H:mm' : 'h:mmA'
      }

      return timeFormat
    },
    [is24TimeFormat]
  )

  const isCelsiusUnit = useMemo(() => {
    const temperatureUnit =
      regionalSetting?.temperatureUnit ?? TEMPERATURE_UNIT.CELSIUS

    return temperatureUnit === TEMPERATURE_UNIT.CELSIUS
  }, [regionalSetting])

  const dateFormat = useMemo(() => {
    return regionalSetting?.dateFormat
  }, [regionalSetting])

  return {
    is24TimeFormat,
    isDaysFirst,
    regionalSetting,
    convertedTimeFormat,
    isCelsiusUnit,
    dateFormat
  }
}

export default useRegionalSettings
