import React from 'react'
import { withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'

import MaterialPopup from 'components/Popup/MaterialPopup'
import { Card } from 'components/Card'
import Spacing from 'components/Containers/Spacing'
import GreyCard from 'components/Card/GreyCard'
import { geoLocationSelector } from 'selectors/configSelectors'
import { getGeoLocationFromIp } from 'actions/configActions'
import { CircularLoader } from 'components/Loaders'
import { _isEmpty } from 'utils/lodash'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import GoogleMapWrapper from 'components/GoogleMapWrapper'

const styles = ({ palette, type, typography }) => ({
  popupRoot: {
    width: 400
  },
  moreInfoCardRoot: {
    padding: 0,
    borderRadius: '7px',
    width: '100%'
  },
  moreInfoCardHeader: {
    padding: '0 20px',
    marginBottom: 0,
    borderBottom: `solid 1px ${palette[type].deviceCard.border}`,
    backgroundColor: palette[type].deviceCard.header.background,
    borderRadius: '8px 8px 0 0'
  },
  moreInfoCardHeaderText: {
    ...typography.darkAccent[type],
    lineHeight: '45px'
  },
  mapRoot: {
    height: 200,
    position: 'relative'
  },
  mapContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  cardContainer: {
    padding: '20px'
  },
  emptyPlaceholderRoot: {
    height: '100%'
  }
})

const MapPopup = ({ children, title, ip, classes, t, visible = false }) => {
  const dispatch = useDispatch()
  const { isFetching, ip: selectorIp, response, error } = useSelector(
    geoLocationSelector
  )

  const handleOpen = () => {
    if (ip && ip !== selectorIp) {
      dispatch(getGeoLocationFromIp(ip))
    }
  }

  return visible ? (
    <MaterialPopup
      placement={'bottom'}
      on={'hover'}
      rootClassName={classes.popupRoot}
      trigger={children}
      onOpen={handleOpen}
      preventOverflow={{
        enabled: true,
        boundariesElement: 'viewport'
      }}
    >
      <Card
        icon={false}
        title={title}
        rootClassName={classes.moreInfoCardRoot}
        headerClasses={[classes.moreInfoCardHeader]}
        headerTextClasses={[classes.moreInfoCardHeaderText]}
      >
        <div className={classes.cardContainer}>
          <GreyCard
            header={false}
            contentSpacingProps={{
              paddingVert: 0,
              paddingHor: 0
            }}
          >
            <Spacing variant={0} rootClassName={classes.mapRoot}>
              {isFetching ? (
                <CircularLoader />
              ) : ip &&
                response?.latitude &&
                response?.longitude &&
                _isEmpty(error) ? (
                <GoogleMapWrapper
                  mapContainerClassName={classes.mapContainer}
                  coords={[{ lat: response.latitude, lng: response.longitude }]}
                  center={{ lat: response.latitude, lng: response.longitude }}
                />
              ) : ip &&
                (!response?.latitude ||
                  !response?.longitude ||
                  !_isEmpty(error)) ? (
                <EmptyPlaceholder
                  rootClassName={classes.emptyPlaceholderRoot}
                  text={t('Unable to identify Geo-location')}
                  requestText={`${t('IP')}: ${ip}`}
                />
              ) : (
                <EmptyPlaceholder
                  rootClassName={classes.emptyPlaceholderRoot}
                  requestText={t('Unable to get the location')}
                />
              )}
            </Spacing>
          </GreyCard>
        </div>
      </Card>
    </MaterialPopup>
  ) : (
    children
  )
}

export default withTranslation('translations')(withStyles(styles)(MapPopup))
