import { _get, _isNull } from 'utils/lodash'
import * as Yup from 'yup'
import moment from 'moment'
import momentTZ from 'moment-timezone'

import { camelCaseToSplitCapitalize } from './index'
import Printer from 'print-html-element'
import { requiredField } from 'constants/validationMessages'
import {
  getDistinctOptionsByFieldAndEntity,
  getOptionsByFieldAndEntity
} from '../services/getOptions'
import {
  transformAliasAndIdToOptions,
  transformDataToOptions
} from './transformToOptionsUtils'
import {
  USER_TABLE,
  CLIENT_TABLE,
  DEVICE_TABLE,
  DEVICE_CONNECT_TABLE,
  DEVICE_LOG_TABLE,
  MEDIA_TABLE,
  PLAYLIST_TABLE,
  PLAYLIST_MEDIA_TABLE,
  USER_ACTIVITY_TABLE,
  USER_LOGIN_TABLE,
  NAME_FIELD,
  FIRST_NAME,
  LAST_NAME,
  ADDRESS_FIELD,
  ADDRESS_1,
  TIMEZONE_FIELD,
  CONNECT_MAC_FIELD,
  DEVICE_NAME_FIELD,
  DEVICE_ALIAS_FIELD,
  DEVICE_DESCRIPTION_FIELD,
  DEVICE_ADDRESS_FIELD,
  DEVICE_ZIP_FIELD,
  DEVICE_CITY_FIELD,
  DEVICE_STATE_FIELD,
  DEVICE_COUNTRY_FIELD,
  STATUS_FIELD,
  TIMEZONE_NAME,
  MEDIA_TITLE_FIELD,
  PLAYLIST_TITLE_FIELD,
  TITLE_FIELD,
  ALIAS_FIELD,
  DESCRIPTION_FIELD,
  ZIP_FIELD,
  CITY_FIELD,
  STATE_FIELD,
  COUNTRY_FIELD,
  PHONE_FIELD,
  PHONE_NO_1,
  PACKAGE_NAME_FIELD,
  TYPE_FIELD,
  USER_NAME,
  USER_EMAIL,
  EMAIL_FIELD,
  CLIENT_PACKAGE_TABLE,
  BANDWIDTH_USAGE_TABLE,
  CLIENT_NAME_FIELD,
  CONFIG_CLIENT_TYPE,
  OPERATOR_OPTIONS,
  fieldTypes,
  CLIENT_FEATURE_IDS_FIELD,
  CONFIG_FEATURE_CLIENT,
  DEVICE_PACKAGE_TABLE,
  recurrenceTypes,
  recurrenceTabs,
  DATE_RANGE
} from 'constants/report'
import {
  DATE_TIME_VIEW_FORMAT,
  DATE_VIEW_FORMAT,
  MEDIA_DATE_FORMAT
} from 'constants/dateTimeFormats'
import { recurrenceEndTypes } from 'constants/report'
import { sanitizeString } from './validationUtils'

export const reportDataLimit = '10000'

export const validationSchema = Yup.object().shape({
  title: Yup.string().restrictedCharacters().required(requiredField),
  description: Yup.string().restrictedCharacters().required(requiredField)
})

export const saveToFile = (
  data,
  type,
  fileName = 'report',
  options,
  addConfidential = false,
  afterSaveCallback
) => {
  import('utils/reportDataParsers').then(reportDataParsers =>
    reportDataParsers.saveToFile(
      data,
      type,
      fileName,
      options,
      addConfidential,
      afterSaveCallback
    )
  )
}

export const openSaveAs = (file, type, fileName) => {
  import('utils/reportDataParsers').then(reportDataParsers =>
    reportDataParsers.openSaveAs(file, type, fileName)
  )
}

export const getFieldsByTable = (table, tables, returnObjects = false) => {
  const fields = _get(
    tables.filter(item => item.table === table)[0],
    'fields',
    []
  )
  return returnObjects ? fields : fields.map(({ field }) => field)
}

const getSubtotalRow = (indexes, groupIndex, items, filter, hidden) => {
  const { detailRows, rowCounts, subtotals } = filter

  if (!subtotals) {
    return []
  }

  const keyItem = { ...items[0] }
  hidden.forEach(field => {
    delete keyItem[field]
  })

  return [
    {
      ...(detailRows ? { detail: '' } : {}),
      ...(rowCounts ? { index: indexes[groupIndex + 1] } : {}),
      [Object.keys(keyItem)[0]]: `Total: ${items.length}`
    }
  ]
}

const getGrandTotal = fields => {
  return {
    owner: 'Total',
    columns: [
      {
        id: 'Total',
        label: ' ',
        display: true
      }
    ],
    data: [
      {
        Total: `Grand total: ${fields.length}`
      }
    ]
  }
}

const getDetailColumn = (detailRows, index, key) => {
  return detailRows
    ? {
        detail:
          index === 0
            ? key === 'null'
              ? 'N/A'
              : camelCaseToSplitCapitalize(key)
            : ' '
      }
    : {}
}

const getIndexColumn = (rowCounts, index, groupIndex, tablesRowIndexes) => {
  return rowCounts ? { index: tablesRowIndexes[groupIndex] + index + 1 } : {}
}

const rowsCompare = (orderBy, order) => (item1, item2) => {
  if (!orderBy) {
    return 0
  }
  const first =
    item1[orderBy] && !_isNull(item1[orderBy])
      ? item1[orderBy].toString().toLowerCase()
      : undefined
  const second =
    item2[orderBy] && !_isNull(item2[orderBy])
      ? item2[orderBy].toString().toLowerCase()
      : undefined

  if (!first && !second) {
    return 0
  } else if (!first) {
    return order === 'asc' ? 1 : -1
  } else if (!second) {
    return order === 'asc' ? -1 : 1
  }

  if (order === 'asc') {
    return first >= second ? 1 : -1
  } else {
    return first >= second ? -1 : 1
  }
}

const getRows = (data, searchTerm) => {
  return searchTerm
    ? data.filter(field => {
        return Object.values(field).some(value =>
          value && !_isNull(value)
            ? value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            : false
        )
      })
    : data
}

const getGroups = (rows, groupBy) => {
  if (!groupBy) {
    return { All: rows }
  }

  const result = {}

  rows.forEach(row => {
    const field = row[groupBy]
    result[field] = [...(result[field] || []), row]
  })

  return result
}

const getTableRowIndexes = (groupsData, subtotals) => {
  const result = [0]
  let sum = 0
  Object.values(groupsData).forEach(item => {
    const itemSum = item.length + (subtotals ? 1 : 0)
    result.push(itemSum + sum)
    sum += itemSum
  })
  return result
}

const getColumns = (rowItem, hiddenColumns, detailRows, rowCounts) => {
  const detailColumn = {
    id: 'detail',
    label: 'Detail',
    display: true
  }

  const countColumn = {
    id: 'index',
    label: ' ',
    display: true
  }

  return [
    ...(detailRows ? [detailColumn] : []),
    ...(rowCounts ? [countColumn] : []),
    ...Object.keys(rowItem).map(key => ({
      id: key,
      label: camelCaseToSplitCapitalize(key),
      display: !hiddenColumns.includes(key)
    }))
  ]
}

export const prepareGridData = (data, filter, searchTerm) => {
  const { groupBy, groups, rowCounts, detailRows, subtotals } = filter
  if (!data.fields) {
    return []
  }
  const rows = getRows(data.fields, searchTerm)
  const groupsData = getGroups(rows, groupBy)
  const tablesRowIndexes = getTableRowIndexes(groupsData, subtotals)

  const groupEntries = Object.entries(groups)
    .filter(([key]) => !!groupsData[key])
    .filter(([key]) => !!groupsData[key][0])

  const tableData = groupEntries.map(([key, value], groupIndex) => {
    const { orderBy, order, hidden } = value

    return {
      owner: key,
      columns: getColumns(groupsData[key][0], hidden, detailRows, rowCounts),
      data: [
        ...groupsData[key]
          .sort(rowsCompare(orderBy, order))
          .map((item, index) => {
            const resultItem = {
              ...getDetailColumn(detailRows, index, key),
              ...getIndexColumn(rowCounts, index, groupIndex, tablesRowIndexes),
              ...item
            }
            hidden.forEach(field => {
              delete resultItem[field]
            })
            return resultItem
          }),
        ...getSubtotalRow(
          tablesRowIndexes,
          groupIndex,
          groupsData[key],
          filter,
          hidden
        )
      ]
    }
  })

  if (filter.grandTotal) {
    tableData.push(getGrandTotal(rows))
  }

  return tableData
}

export const preparePrintData = data => {
  if (!data.length) {
    return ''
  }

  const header = `<tr>${Object.keys(data[0])
    .map(name => `<th>${camelCaseToSplitCapitalize(name)}</th>`)
    .join('\n')}</tr>`

  const rows = data
    .map(
      item =>
        `<tr>${Object.values(item)
          .map(
            value =>
              `<td>${value === null ? 'N/A' : sanitizeString(value)}</td>`
          )
          .join('\n')}</tr>`
    )
    .join('\n')

  return `<table>${header}${rows}</table>`
}

export const printReport = (data, title) => {
  const html = data.map(group => preparePrintData(group.data)).join('\n')

  Printer.printHtml(html, {
    pageTitle: title,
    styles: `
      table {
        width: 100%;
      }
      table, th, td {
        border: 1px solid #E4E9F3;
        border-collapse: collapse;
      }
      td, th {
        color: #74809A;
        font-size: 13px;
        text-align: left;
        font-family: "Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",
          Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji",
          "Segoe UI Emoji","Segoe UI Symbol";
        letter-spacing: -0.01px;
        padding: 5px;
      }
      th {
        font-weight: bold;
      }`
  })
}

const getOptionMapOfUserFields = {
  [USER_NAME]: getOptionsByFieldAndEntity(
    [FIRST_NAME, LAST_NAME],
    USER_TABLE,
    transformDataToOptions(null, [FIRST_NAME, LAST_NAME], ' ')
  ),
  [USER_EMAIL]: getOptionsByFieldAndEntity(EMAIL_FIELD, USER_TABLE)
}

const getOptionMapOfDeviceFields = {
  [DEVICE_NAME_FIELD]: getOptionsByFieldAndEntity(NAME_FIELD, DEVICE_TABLE),
  [DEVICE_ALIAS_FIELD]: getOptionsByFieldAndEntity(ALIAS_FIELD, DEVICE_TABLE),
  [DEVICE_DESCRIPTION_FIELD]: getDistinctOptionsByFieldAndEntity(
    DESCRIPTION_FIELD,
    DEVICE_TABLE
  ),
  [DEVICE_ADDRESS_FIELD]: getDistinctOptionsByFieldAndEntity(
    ADDRESS_1,
    DEVICE_TABLE
  ),
  [DEVICE_ZIP_FIELD]: getDistinctOptionsByFieldAndEntity(
    ZIP_FIELD,
    DEVICE_TABLE
  ),
  [DEVICE_CITY_FIELD]: getDistinctOptionsByFieldAndEntity(
    CITY_FIELD,
    DEVICE_TABLE
  ),
  [DEVICE_STATE_FIELD]: getDistinctOptionsByFieldAndEntity(
    STATE_FIELD,
    DEVICE_TABLE
  ),
  [DEVICE_COUNTRY_FIELD]: getDistinctOptionsByFieldAndEntity(
    COUNTRY_FIELD,
    DEVICE_TABLE
  ),
  [STATUS_FIELD]: getOptionsByFieldAndEntity(STATUS_FIELD, DEVICE_TABLE)
}

export const getOptionsMapByFieldAndEntity = {
  [USER_TABLE]: {
    [NAME_FIELD]: getOptionsByFieldAndEntity(
      [FIRST_NAME, LAST_NAME],
      USER_TABLE,
      transformDataToOptions(null, [FIRST_NAME, LAST_NAME], ' ')
    )
  },
  [USER_ACTIVITY_TABLE]: getOptionMapOfUserFields,
  [USER_LOGIN_TABLE]: getOptionMapOfUserFields,
  [CLIENT_TABLE]: {
    [PHONE_FIELD]: getOptionsByFieldAndEntity(PHONE_NO_1, CLIENT_TABLE),
    [PACKAGE_NAME_FIELD]: getOptionsByFieldAndEntity(
      TITLE_FIELD,
      CLIENT_PACKAGE_TABLE
    ),
    [TYPE_FIELD]: getOptionsByFieldAndEntity(TITLE_FIELD, CONFIG_CLIENT_TYPE),
    [CLIENT_FEATURE_IDS_FIELD]: getOptionsByFieldAndEntity(
      ALIAS_FIELD,
      CONFIG_FEATURE_CLIENT,
      transformAliasAndIdToOptions
    )
  },
  [DEVICE_TABLE]: {
    [ADDRESS_FIELD]: getDistinctOptionsByFieldAndEntity(
      ADDRESS_1,
      DEVICE_TABLE
    ),
    [TIMEZONE_FIELD]: getDistinctOptionsByFieldAndEntity(
      TIMEZONE_NAME,
      DEVICE_TABLE
    ),
    [CONNECT_MAC_FIELD]: getOptionsByFieldAndEntity(
      CONNECT_MAC_FIELD,
      DEVICE_TABLE
    ),
    [PACKAGE_NAME_FIELD]: getOptionsByFieldAndEntity(
      TITLE_FIELD,
      DEVICE_PACKAGE_TABLE
    )
  },
  [DEVICE_CONNECT_TABLE]: getOptionMapOfDeviceFields,
  [DEVICE_LOG_TABLE]: getOptionMapOfDeviceFields,
  [PLAYLIST_MEDIA_TABLE]: {
    [MEDIA_TITLE_FIELD]: getOptionsByFieldAndEntity(TITLE_FIELD, MEDIA_TABLE),
    [PLAYLIST_TITLE_FIELD]: getOptionsByFieldAndEntity(
      TITLE_FIELD,
      PLAYLIST_TABLE
    )
  },
  [BANDWIDTH_USAGE_TABLE]: {
    [CLIENT_NAME_FIELD]: getOptionsByFieldAndEntity(NAME_FIELD, CLIENT_TABLE),
    [DEVICE_NAME_FIELD]: getOptionsByFieldAndEntity(NAME_FIELD, DEVICE_TABLE)
  }
}

export const parseFieldName = field => {
  switch (field) {
    case 'randomOrder':
      return 'shuffle'
    default:
      return field
  }
}

export const getOperatorOptions = type => {
  switch (type) {
    case fieldTypes.string:
      return [
        OPERATOR_OPTIONS.equals,
        OPERATOR_OPTIONS.notEqual,
        OPERATOR_OPTIONS.contains
      ]
    case fieldTypes.boolean:
    case fieldTypes.bool:
      return [OPERATOR_OPTIONS.equals, OPERATOR_OPTIONS.notEqual]
    case fieldTypes.int:
      return [
        OPERATOR_OPTIONS.equals,
        OPERATOR_OPTIONS.notEqual,
        OPERATOR_OPTIONS.between,
        OPERATOR_OPTIONS.greaterThan,
        OPERATOR_OPTIONS.lessThan,
        OPERATOR_OPTIONS.greaterOrEquals,
        OPERATOR_OPTIONS.lessOrEquals
      ]
    case fieldTypes.date:
    case fieldTypes.datetime:
    case fieldTypes.time:
      return [
        OPERATOR_OPTIONS.equals,
        OPERATOR_OPTIONS.notEqual,
        OPERATOR_OPTIONS.before,
        OPERATOR_OPTIONS.after,
        OPERATOR_OPTIONS.between,
        OPERATOR_OPTIONS.dateRange
      ]
    case fieldTypes.having:
      return [OPERATOR_OPTIONS.having, OPERATOR_OPTIONS.notHaving]
    case fieldTypes.json:
    default:
      return []
  }
}

export const prepareAutoRunReportData = values => {
  const {
    reportSendTime,
    startDate,
    endDate,
    recurrenceOption,
    customRecurrence,
    advancedRecurrence,
    workingDays,
    specificDates,
    distribution,
    recurrenceTab
  } = values

  return {
    scheduleDetails: {
      schedule: {
        timezone: momentTZ.tz.guess(),
        reportSendTime,
        startDate,
        endDate,
        workingDays,
        recurrenceOption,
        recurrenceTab,
        ...(recurrenceTypes.custom === recurrenceOption
          ? {
              customRecurrence: {
                repeatCount: customRecurrence.repeatCount,
                monthlyRecurrence: customRecurrence.monthlyRecurrence,
                endRecurrence: customRecurrence.endRecurrence,
                interval: customRecurrence.interval,
                repeatEvery: customRecurrence.repeatEvery,
                ...(customRecurrence.endRecurrence === recurrenceEndTypes.on
                  ? {
                      endDate: customRecurrence.endDate
                        ? moment(customRecurrence.endDate).format(
                            MEDIA_DATE_FORMAT
                          )
                        : null
                    }
                  : {}),
                ...(customRecurrence.endRecurrence === recurrenceEndTypes.after
                  ? {
                      repeatCount: customRecurrence.repeatCount
                    }
                  : {}),
                workingDays
              }
            }
          : null),
        ...(recurrenceTypes.advanced === recurrenceOption
          ? {
              advancedRecurrence: {
                ...advancedRecurrence,
                days: workingDays,
                months: advancedRecurrence.months.map(({ index }) => index)
              }
            }
          : null),
        ...((recurrenceTypes.custom === recurrenceOption ||
          recurrenceTypes.advanced === recurrenceOption) &&
        recurrenceTab === recurrenceTabs.specific
          ? {
              specificDates: specificDates?.length
                ? values.specificDates.map(date =>
                    moment(date).format(MEDIA_DATE_FORMAT)
                  )
                : []
            }
          : {})
      },
      distribution: {
        ...distribution,
        userEmails: distribution.userEmails.map(({ value }) => value)
      }
    }
  }
}

export const reportDateRangeToValue = ({ value, firstDayWeek = 0 }) => {
  switch (value) {
    case DATE_RANGE.LAST_24_HRS: {
      return {
        from: moment().subtract(1, 'day').format(DATE_TIME_VIEW_FORMAT),
        to: moment().format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.LAST_7_DAYS: {
      return {
        from: moment().subtract(6, 'day').format(DATE_TIME_VIEW_FORMAT),
        to: moment().format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.THIS_WEEK: {
      return {
        from: moment()
          .weekday(firstDayWeek === 0 ? 0 : 1)
          .startOf('day')
          .format(DATE_TIME_VIEW_FORMAT),
        to: moment()
          .weekday(firstDayWeek === 0 ? 6 : 7)
          .endOf('day')
          .format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.LAST_WEEK: {
      return {
        from: moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .day(firstDayWeek === 0 ? 0 : 1)
          .format(DATE_TIME_VIEW_FORMAT),
        to: moment()
          .subtract(1, 'weeks')
          .day(firstDayWeek === 0 ? 6 : 7)
          .endOf('day')
          .format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.LAST_30_DAYS: {
      return {
        from: moment().subtract(29, 'day').format(DATE_TIME_VIEW_FORMAT),
        to: moment().format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.THIS_MONTH: {
      return {
        from: moment().startOf('month').format(DATE_TIME_VIEW_FORMAT),
        to: moment().endOf('month').format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.LAST_MONTH: {
      return {
        from: moment()
          .subtract(1, 'months')
          .startOf('month')
          .format(DATE_TIME_VIEW_FORMAT),
        to: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.THIS_QTR: {
      const currentQuarter = moment().quarter()
      return {
        from: moment()
          .quarter(currentQuarter)
          .startOf('quarter')
          .format(DATE_TIME_VIEW_FORMAT),
        to: moment()
          .quarter(currentQuarter)
          .endOf('quarter')
          .format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.LAST_QTR: {
      const currentMoment = moment()
      const currentYear = currentMoment.year()
      const currentQuarter = currentMoment.quarter()

      const lastQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1
      const lastYear = currentQuarter === 1 ? currentYear - 1 : currentYear

      return {
        from: moment()
          .quarter(lastQuarter)
          .year(lastYear)
          .startOf('quarter')
          .format(DATE_TIME_VIEW_FORMAT),
        to: moment()
          .quarter(lastQuarter)
          .year(lastYear)
          .endOf('quarter')
          .format(DATE_TIME_VIEW_FORMAT)
      }
    }
    case DATE_RANGE.THIS_YEAR: {
      return {
        from: moment()
          .quarter(1)
          .startOf('quarter')
          .format(DATE_TIME_VIEW_FORMAT),
        to: moment().quarter(4).endOf('quarter').format(DATE_TIME_VIEW_FORMAT)
      }
    }
    default: {
      return {
        from: moment().format(DATE_TIME_VIEW_FORMAT),
        to: moment().format(DATE_TIME_VIEW_FORMAT)
      }
    }
  }
}

export const convertLogToLocalTime = inputString => {
  const regex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/
  const match = regex.exec(inputString)

  if (match && match[1]) {
    const utcDateTime = moment.utc(match[1])
    const localDateTime = utcDateTime.local().format('YYYY-MM-DD HH:mm:ss')

    return inputString.replace(match[1], localDateTime)
  }
}

const getUniqueDayValues = data => [
  ...new Set(data.map(({ date }) => moment(date).format(DATE_VIEW_FORMAT)))
]

const getEmptyValues = data => data.map(item => ({ x: item, y: 0 }))

const getServiceData = ({ data, featureServiceName }) =>
  data
    .filter(({ featureService }) => featureService === featureServiceName)
    .map(({ date, countApiCall }) => ({
      x: moment(date).format(DATE_VIEW_FORMAT),
      y: countApiCall
    }))

const getSortedServiceData = data =>
  data.sort(
    (a, b) => moment(a.x, DATE_VIEW_FORMAT) - moment(b.x, DATE_VIEW_FORMAT)
  )

const getDays = data => {
  const days = []

  data.forEach(item => {
    if (!days.includes(item.x)) {
      days.push(item.x)
    }
  })

  return days
}

const getDayCountData = ({ days, serviceData }) =>
  days.map(day => {
    const foundByDay = serviceData.filter(({ x, y }) => x === day)
    const counter = foundByDay.reduce((acc, item) => acc + item.y, 0)

    return { x: day, y: counter }
  })

export const getServiceLineChartData = ({ data, featureServiceName }) => {
  const uniqueDaysValues = getUniqueDayValues(data)
  const emptyValues = getEmptyValues(uniqueDaysValues)
  const serviceData = getServiceData({ data, featureServiceName })
  const preparedServiceData = getSortedServiceData([
    ...serviceData,
    ...emptyValues
  ])
  const days = getDays(preparedServiceData)
  const result = getDayCountData({
    days,
    serviceData: preparedServiceData
  })

  return {
    id: featureServiceName,
    data: result
  }
}
