import update from 'immutability-helper'

import * as types from '../actions'
import { shapeOfBodyWithMeta } from 'constants/initialLibraryState'

const initialState = {
  info: {},
  put: {},
  news: {},
  support: {},
  deviceLocations: {
    response: [],
    isFetching: true
  },
  weather: {
    response: {},
    isFetching: false
  },
  todaySchedules: {
    ...shapeOfBodyWithMeta,
    isFetching: false
  }
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_INFO_SUCCESS:
      return update(state, {
        info: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DASHBOARD_INFO_ERROR:
      return update(state, {
        info: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_DASHBOARD_INFO_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_DASHBOARD_INFO_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload }
        }
      })
    case types.GET_NEWS_INFO_FOR_DASHBOARD_SUCCESS:
      return update(state, {
        news: {
          response: { $set: action.payload }
        }
      })
    case types.GET_NEWS_INFO_FOR_DASHBOARD_ERROR:
      return update(state, {
        news: {
          error: { $set: action.payload }
        }
      })
    case types.GET_SUPPORT_INFO_FOR_DASHBOARD_SUCCESS:
      return update(state, {
        support: {
          response: { $set: action.payload }
        }
      })
    case types.GET_SUPPORT_INFO_FOR_DASHBOARD_ERROR:
      return update(state, {
        support: {
          error: { $set: action.payload }
        }
      })
    case types.GET_DASHBOARD_DEVICE_LOCATIONS:
      return update(state, {
        deviceLocations: {
          $set: {
            response: {},
            isFetching: true
          }
        }
      })
    case types.GET_DASHBOARD_DEVICE_LOCATIONS_SUCCESS:
      return update(state, {
        deviceLocations: {
          $set: {
            response: action.payload,
            isFetching: false
          }
        }
      })
    case types.GET_DASHBOARD_DEVICE_LOCATIONS_ERROR:
      return update(state, {
        deviceLocations: {
          $set: {
            error: action.payload,
            isFetching: false
          }
        }
      })
    case types.GET_WEATHER_INFO_FOR_DASHBOARD:
      return update(state, {
        weather: {
          $set: {
            response: {},
            isFetching: true
          }
        }
      })
    case types.GET_WEATHER_INFO_FOR_DASHBOARD_SUCCESS:
      return update(state, {
        weather: {
          $set: {
            response: action.payload,
            isFetching: false
          }
        }
      })
    case types.GET_WEATHER_INFO_FOR_DASHBOARD_ERROR:
      return update(state, {
        weather: {
          $set: {
            error: action.payload,
            isFetching: false
          }
        }
      })
    case types.GET_DASHBOARD_TODAY_SCHEDULES:
      return update(state, {
        todaySchedules: {
          error: { $set: true },
          isFetching: { $set: true }
        }
      })
    case types.GET_DASHBOARD_TODAY_SCHEDULES_SUCCESS:
      return update(state, {
        todaySchedules: {
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_DASHBOARD_TODAY_SCHEDULES_ERROR:
      return update(state, {
        todaySchedules: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_DASHBOARD_TODAY_SCHEDULES:
      return update(state, {
        todaySchedules: { $set: initialState.todaySchedules }
      })
    default:
      return state
  }
}

export default dashboardReducer
