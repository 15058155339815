export const getSearchParams = location => {
  const paramsObj = {}
  const urlParams = new URLSearchParams(location.search)
  urlParams.forEach((value, key) => {
    paramsObj[key] = value
  })
  return paramsObj
}

export const createRoomPermission = hasPermission => {
  return {
    create: hasPermission,
    read: hasPermission,
    update: hasPermission,
    delete: hasPermission
  }
}
