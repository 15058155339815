import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import Color from 'color'

const styles = ({ colors, type }) => ({
  workingDaysRoot: {
    display: 'inline-grid',
    grid: '1fr / repeat(7,min-content)',
    gap: '4px'
  },
  workingDay: {
    display: 'inline-block',
    background: 'transparent',
    color: colors.schedule[type],
    border: `solid 1px ${colors.schedule[type]}`,
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '32px',
    fontWeight: 'bold',
    fontSize: '13px',
    width: '32px',
    height: '32px',
    '&.active': {
      background: new Color(colors.schedule[type]).alpha(0.13).string()
    }
  }
})

const ScheduleWorkingDays = ({
  classes,
  workingDays = [],
  rootClassName,
  iconClassName,
  daysValue = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
}) => (
  <div className={classNames(classes.workingDaysRoot, rootClassName)}>
    {daysValue.map((day, index) => {
      return (
        <span
          key={index}
          className={classNames(classes.workingDay, iconClassName, {
            active: workingDays.length && workingDays.includes(day)
          })}
        >
          {day[0].toUpperCase()}
        </span>
      )
    })}
  </div>
)

ScheduleWorkingDays.propTypes = {
  workingDays: PropTypes.array,
  rootClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  daysValue: PropTypes.array
}

export default withStyles(styles)(ScheduleWorkingDays)
