import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _sortBy } from 'utils/lodash'

import { getBandwidthPackages } from 'actions/bandwidthPackageActions'
import { simulateEvent } from 'utils'
import { BIG_NUMBER } from 'constants/library'
import { useUserRole } from './index'

const useBandwidthPlans = () => {
  const dispatch = useDispatch()
  const role = useUserRole()
  const backPlans = useSelector(
    ({ bandwidthPackage }) => bandwidthPackage.items.response
  )
  useEffect(() => {
    if (backPlans.length === 0 && role.role && !role.enterprise)
      dispatch(
        getBandwidthPackages({
          limit: BIG_NUMBER
        })
      )
    // eslint-disable-next-line
  }, [role.role])
  const [plans, changePlans] = useState([])
  const [bandwidthType, setBandwidthType] = useState(null)
  const [customBandwidthValue, setCustomBandwidthValue] = useState(null)

  useEffect(() => {
    if (plans.length && bandwidthType) {
      handleTogglePlan(simulateEvent(null, bandwidthType))
      if (bandwidthType === 'custom') {
        handleChangeCustomBandwidth(simulateEvent(null, customBandwidthValue))
      }
    }
    // eslint-disable-next-line
  }, [plans])

  const initialPlans = useMemo(() => {
    if (backPlans.length) {
      const sortedPlans = _sortBy(backPlans, ['bandwidth'])
      return [
        ...sortedPlans.map(plan => ({
          label: plan.title,
          value: plan.id,
          selected: plan.bandwidth === 4,
          ...plan
        })),
        {
          label: 'Custom',
          value: 'custom',

          bandwidth: 1,
          selected: false
        }
      ]
    }
    return []
  }, [backPlans])

  const handleReset = useCallback(() => {
    if (initialPlans.length) {
      changePlans(initialPlans)
    }
  }, [initialPlans, changePlans])

  useEffect(() => {
    if (initialPlans.length) {
      changePlans(initialPlans)
    }
    // eslint-disable-next-line
  }, [initialPlans])

  const handleChangeCustomBandwidth = useCallback(({ target: { value } }) => {
    changePlans(plans =>
      plans.map(plan =>
        plan.value === 'custom' ? { ...plan, bandwidth: value } : plan
      )
    )
  }, [])

  const handleCustomBandwidth = useCallback(
    total => {
      if (backPlans.length > 0) {
        changePlans(plans =>
          plans.map(plan =>
            plan.value === 'custom'
              ? { ...plan, selected: true, bandwidth: total }
              : plan
          )
        )
        setBandwidthType(null)
        setCustomBandwidthValue(null)
      } else {
        setBandwidthType('custom')
        setCustomBandwidthValue(total)
      }
    },
    [backPlans.length]
  )

  const handleTogglePlan = useCallback(
    ({ target: { value } }) => {
      if (backPlans.length > 0) {
        changePlans(plans =>
          plans.map(plan =>
            String(plan.value) === String(value)
              ? { ...plan, selected: true }
              : { ...plan, selected: false }
          )
        )
        setBandwidthType(null)
      } else {
        setBandwidthType(value)
      }

      // eslint-disable-next-line
    },
    [backPlans]
  )

  const selectedPlan = useMemo(() => {
    const selected = plans.find(({ selected }) => selected === true)
    if (
      selected &&
      selected.value !== 'unlimitedBandwidth' &&
      selected.value !== 'custom'
    ) {
      return selected.value
    }
    return undefined
  }, [plans])

  const customBandwidth = useMemo(() => {
    const selected = plans.find(({ selected }) => selected === true)
    if (selected && selected.value === 'custom') return selected.bandwidth
    return undefined
  }, [plans])

  return {
    values: plans,
    customBandwidth,
    handleChangeCustomBandwidth,
    togglePlan: handleTogglePlan,
    customToggle: handleCustomBandwidth,
    selectedPlan,
    handleReset
  }
}

export default useBandwidthPlans
