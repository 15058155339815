import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  deviceHealthSelector,
  deviceRefreshHealthSelector
} from 'selectors/new/device'
import {
  clearDeviceHealth,
  fetchDeviceHealth,
  fetchTriggerDeviceHealth,
  refreshHealthFailure
} from 'actions/new/device'
import { dataToCPU, dataToMemory, dataToNetwork } from 'utils/api/deviceHealth'
import { getDeviceScreenConnection, getLogTime, getName } from 'utils/object'
import useSnackbar from 'hooks/useSnackbar'
import useTimeout from 'hooks/useTimeout'
import useDeviceHealthSocket from 'hooks/socket/useDeviceHealthSocket'
import { SOCKET_TIMEOUT } from 'services/socket'

// TODO add translations after i18n is migrated and updated
export default function useDeviceHealth(id) {
  const { showSnackbar } = useSnackbar()
  const { t } = useTranslation('translations')
  const dispatch = useDispatch()
  const { data, isPending, outdatedFields } = useSelector(deviceHealthSelector)

  const { isPending: isRefreshHealthPending } = useSelector(
    deviceRefreshHealthSelector
  )

  const [setTimeout] = useTimeout()
  const [setSocketTimeout, clearSocketTimeout] = useTimeout()
  const [isHealthCalled, setIsHealthCalled] = useState(false)

  const socketHealthListener = useCallback(
    ({ data }) => {
      setTimeout(() => {
        if (data.deviceId === Number(id)) {
          dispatch(fetchDeviceHealth(data.deviceId, false))
        }
      }, 1000)
    },
    [setTimeout, dispatch, id]
  )

  useDeviceHealthSocket(socketHealthListener)

  useEffect(() => {
    setIsHealthCalled(false)
    dispatch(
      fetchDeviceHealth(id, true, () => {
        setIsHealthCalled(true)
      })
    )
  }, [id, dispatch])

  useEffect(() => {
    return () => {
      clearSocketTimeout()
      dispatch(clearDeviceHealth())
    }
  }, [dispatch, clearSocketTimeout])

  const triggerDeviceHealth = useCallback(() => {
    dispatch(
      fetchTriggerDeviceHealth(id, () => {
        setSocketTimeout(() => {
          showSnackbar(
            t(
              'Unable to retrieve data from the device, please try again later'
            ),
            'error'
          )
          dispatch(refreshHealthFailure())
        }, SOCKET_TIMEOUT)
      })
    )
  }, [id, dispatch, showSnackbar, setSocketTimeout, t])

  return useMemo(
    () => ({
      isPending,
      isHealthCalled,
      isRefreshHealthPending,
      network: dataToNetwork(data),
      cpu: dataToCPU(data),
      memory: dataToMemory(data),
      lastUpdate: getLogTime(data),
      name: getName(data),
      deviceScreenConnection: getDeviceScreenConnection(data),
      appReboots: data?.appReboots || [],
      reboots: data?.reboots || [],
      triggerDeviceHealth,
      outdatedFields,
      activeMacAddress: data.activeMacAddress
    }),
    [
      isPending,
      isHealthCalled,
      data,
      triggerDeviceHealth,
      isRefreshHealthPending,
      outdatedFields
    ]
  )
}
