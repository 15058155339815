import socket, { SOCKET_MEDIA_PROCESSED } from 'services/socket'
import { useCallback, useEffect } from 'react'

export default function useMediaProcessedSocket(listener, skip) {
  const handleMessage = useCallback(
    ({ data: { id, processingStatus } }) => {
      listener(id, processingStatus)
    },
    [listener]
  )

  useEffect(() => {
    if (!skip) {
      socket.on(SOCKET_MEDIA_PROCESSED, handleMessage)
    }

    return () => {
      socket.off(SOCKET_MEDIA_PROCESSED, handleMessage)
    }
  }, [handleMessage, skip])
}
