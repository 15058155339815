import { useMemo } from 'react'
import { useLocation } from 'react-router'

export default function useUrlSearchParams() {
  const { search } = useLocation()

  const params = useMemo(() => {
    const paramsObj = {}
    const urlParams = new URLSearchParams(search)
    urlParams.forEach((value, key) => {
      paramsObj[key] = value
    })

    return paramsObj
  }, [search])

  return params
}
