export const parseCssString = str => {
  const styles = {}
  let string = str

  if (str.includes('{') && str.includes('}')) {
    const enter = string.indexOf('{')
    const exit = string.indexOf('}')

    string = string.substring(enter + 1, exit)
  }

  const styleArr = string.split(';')

  styleArr.forEach(style => {
    let key = style.split(':')[0]
    const val = style.split(':')[1]

    if (key.includes('-')) {
      const i = key.indexOf('-')

      key = key.substring(0, i) + key[i + 1].toUpperCase() + key.substr(i + 2)
    }

    styles[key] = val
  })

  return styles
}

export const parseCssClass = (str = '') => {
  const exit = str.indexOf('{')
  if (str.length < 2 && exit === -1) {
    return
  } else {
    return str.substring(1, exit)
  }
}
