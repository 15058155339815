import api from './api'
import { errorHandler } from '../utils'

const getClientLicense = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/clientLicense',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getClientLicenseById = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/clientLicense/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postClientLicense = async data => {
  try {
    await api({
      method: 'POST',
      url: '/clientLicense',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const putClientLicense = async (id, data) => {
  try {
    await api({
      method: 'PUT',
      url: `/clientLicense/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteClientLicense = async id => {
  try {
    await api({
      method: 'DELETE',
      url: `/clientLicense/${id}`
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const clientLicensesService = {
  getClientLicense,
  getClientLicenseById,
  postClientLicense,
  putClientLicense,
  deleteClientLicense
}

export default clientLicensesService
