export const rgbaToRgb = (rgba, returnOpacity = false) => {
  let rgb = rgba
  let opacity = 1
  if (typeof rgba === 'string' && rgba.startsWith('rgba(')) {
    const [r, g, b, a] = rgba.slice(5, rgba.length - 1).split(',')

    rgb = `rgb(${r},${g},${b})`
    opacity = Number(a)
  }

  return {
    rgb,
    ...(returnOpacity ? { opacity } : {})
  }
}

export const rgbToRgba = (rgb, opacity = 1) => {
  let rgba = rgb
  if (typeof rgba === 'string' && rgb.startsWith('rgb(')) {
    const [r, g, b] = rgb.slice(4, rgb.length - 1).split(',')

    rgba = `rgba(${r},${g},${b},${opacity})`
  }

  return rgba
}
