import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import moment from 'moment'

import { DATE_VIEW_FORMAT } from 'constants/dateTimeFormats'
import { deviceSecurityColors } from 'constants/deviceConstants'
import Text from 'components/Typography/Text'
import Tooltip from 'components/Tooltip'
import {
  getDeviceSecurityStatus,
  isDeviceSecurityAvailable,
  isUbuntuDeviceSecurity
} from 'utils/deviceUtils'
import { BIG_NUMBER } from 'constants/library'

const useStyles = makeStyles(() => ({
  securityIconWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  securityIcon: {
    fontSize: 13,
    paddingRight: 5
  },
  tooltipRoot: {
    zIndex: BIG_NUMBER
  }
}))

const DeviceSecurityIcon = ({
  device,
  withStatus = true,
  hide = false,
  iconRootClass = ''
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { name, securityExpirationDate } = device.deviceSecurity || {}
  const isUbuntu = isUbuntuDeviceSecurity(device)
  const status = getDeviceSecurityStatus(device)

  if (hide) {
    return null
  } else if (!isDeviceSecurityAvailable(device)) {
    return t('N/A')
  }

  const maintenanceExpiration = moment(securityExpirationDate).format(
    DATE_VIEW_FORMAT
  )

  return (
    <div className={classes.securityIconWrapper}>
      <Tooltip
        rootClasses={{ popper: classes.tooltipRoot }}
        headerText={t('Device Security status', {
          securityStatus: status
        })}
        title={
          isUbuntu
            ? t('This device uses Linux Operating System message', {
                name,
                maintenanceExpiration
              })
            : t(
                'Check with the hardware manufacturer for more information regarding the latest OS updates and security patches'
              )
        }
        placement="top"
        withHeader
        arrow
      >
        <div className={classes.content}>
          <i
            className={classNames(
              'fa-sharp fa-solid fa-shield-halved',
              classes.securityIcon,
              iconRootClass
            )}
            style={{
              color: deviceSecurityColors[status]
            }}
          />
          {withStatus && <Text color="light">{t(status)}</Text>}
        </div>
      </Tooltip>
    </div>
  )
}

export default DeviceSecurityIcon
