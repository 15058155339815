import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const MediaThemesCarouselLoader = ({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 239
    }}
    foregroundColor={theme.palette[theme.type].loader.background}
    backgroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect x="0.5%" y="11" width="24.2%" height="103" rx="6px" ry="6px" />
    <rect x="25.5%" y="11" width="24.2%" height="103" rx="6px" ry="6px" />
    <rect x="50.5%" y="11" width="24.2%" height="103" rx="6px" ry="6px" />
    <rect x="75.5%" y="11" width="24.2%" height="103" rx="6px" ry="6px" />

    <rect x="0.5%" y="122" width="24.2%" height="103" rx="6px" ry="6px" />
    <rect x="25.5%" y="122" width="24.2%" height="103" rx="6px" ry="6px" />
    <rect x="50.5%" y="122" width="24.2%" height="103" rx="6px" ry="6px" />
    <rect x="75.5%" y="122" width="24.2%" height="103" rx="6px" ry="6px" />
  </ContentLoader>
)

export default withTheme(MediaThemesCarouselLoader)
