import * as types from './index'
import { GET_USER_DETAILS_SUCCESS } from './index'

export const getUserDetailsAction = () => ({ type: types.GET_USER_DETAILS })

export const userDetailsSuccess = data => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: data
})

export const putUserDetailsAction = (data, options = {}) => ({
  type: types.PUT_USER_DETAILS,
  payload: data,
  hideNotification: options?.hideNotification
})

export const clearUserDetailsAction = () => ({ type: types.USER_DETAILS_CLEAR })

export const clearUserPutAction = () => ({ type: types.USER_PUT_CLEAR })

export const getUserIpAction = () => ({ type: types.GET_USER_IP })

export const getOriginalUserDetails = () => ({
  type: types.GET_ORIGINAL_USER_DETAILS
})

export const getUserProfileAction = () => ({ type: types.GET_USER_PROFILE })

export const putRegionalSettingsAction = (data, options = {}) => ({
  type: types.PUT_REGIONAL_SETTINGS,
  payload: data,
  hideNotification: options?.hideNotification
})

export const clearPutRegionalSettingsAction = () => ({
  type: types.CLEAR_PUT_REGIONAL_SETTINGS
})

export const regeneratePIN = ({ userId, onSuccess, onError }) => ({
  type: types.REGENERATE_PIN_CODE,
  userId,
  onSuccess,
  onError
})

export const postUserBulk = payload => ({
  type: types.POST_USER_BULK,
  payload
})

export const clearPostUserBulkInfo = () => ({
  type: types.CLEAR_POST_USER_BULK_INFO
})
