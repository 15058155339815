import { call, put } from 'redux-saga/effects'

import * as types from '../actions'

import { deviceNocService } from '../services'
import { transformMeta } from 'utils/tableUtils'

function* getDeviceNocGeneralItems({ params }) {
  try {
    const { meta, data } = yield call(
      deviceNocService.getDeviceNocGeneralItems,
      params
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_DEVICE_NOC_GENERAL_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_NOC_GENERAL_ITEMS_ERROR,
      payload: error
    })
  }
}

function* getDeviceNocHealthItems({ params }) {
  try {
    const { meta, data } = yield call(
      deviceNocService.getDeviceNocHealthItems,
      params
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_DEVICE_NOC_HEALTH_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_DEVICE_NOC_HEALTH_ITEMS_ERROR, payload: error })
  }
}

function* getDeviceNocNetworkItems({ params }) {
  try {
    const { meta, data } = yield call(
      deviceNocService.getDeviceNocNetworkItems,
      params
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_DEVICE_NOC_NETWORK_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_NOC_NETWORK_ITEMS_ERROR,
      payload: error
    })
  }
}

function* getDeviceAlarmItems({ params }) {
  try {
    const { meta, data } = yield call(
      deviceNocService.getDeviceAlarmItems,
      params
    )

    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_DEVICE_ALARM_ITEMS_SUCCESS,
      response: data,
      modifiedMeta
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_ALARM_ITEMS_ERROR,
      payload: error
    })
  }
}

function* getDeviceAlarmById({ id }) {
  try {
    const response = yield call(deviceNocService.getDeviceAlarmById, id)
    yield put({
      type: types.GET_DEVICE_ALARM_ITEM_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_ALARM_ITEM_BY_ID_ERROR,
      payload: error
    })
  }
}

function* postDeviceAlarm({ data }) {
  try {
    yield call(deviceNocService.postDeviceAlarm, data)
    yield put({
      type: types.POST_DEVICE_ALARM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.POST_DEVICE_ALARM_ERROR,
      payload: error
    })
  }
}

function* putDeviceAlarm({ data, id }) {
  try {
    yield call(deviceNocService.putDeviceAlarm, id, data)
    yield put({
      type: types.PUT_DEVICE_ALARM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.PUT_DEVICE_ALARM_ERROR,
      payload: error
    })
  }
}

function* deleteDeviceAlarm({ id }) {
  try {
    yield call(deviceNocService.deleteDeviceAlarm, id)
    yield put({
      type: types.DELETE_DEVICE_ALARM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_DEVICE_ALARM_ERROR,
      payload: error
    })
  }
}

function* getDeviceAlarmColumnList() {
  try {
    const data = yield call(deviceNocService.getDeviceAlarmColumnList)

    yield put({
      type: types.GET_DEVICE_ALARM_COLUMN_LIST_SUCCESS,
      response: data
    })
  } catch (error) {
    yield put({
      type: types.GET_DEVICE_ALARM_COLUMN_LIST_ERROR,
      payload: error
    })
  }
}

const deviceNoc = {
  getDeviceNocGeneralItems,
  getDeviceNocHealthItems,
  getDeviceNocNetworkItems,
  getDeviceAlarmItems,
  getDeviceAlarmById,
  postDeviceAlarm,
  putDeviceAlarm,
  deleteDeviceAlarm,
  getDeviceAlarmColumnList
}

export default deviceNoc
