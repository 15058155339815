import errorHandler from 'utils/errorHandler'
import api from 'services/api'

export async function getUsers({ firstName, limit }) {
  try {
    const { data } = await api({
      useCache: true,
      method: 'GET',
      url: '/user',
      params: {
        sort: 'firstName',
        order: 'asc',
        fields: 'id,firstName,lastName',
        limit,
        firstName
      }
    })

    return data
  } catch (e) {
    throw errorHandler(e)
  }
}
