import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { withStyles, Grid } from '@material-ui/core'
import classNames from 'classnames'

import Drawer from './Drawer'
import useWhiteLabel from 'hooks/useWhiteLabel'
import { zIndexes } from 'constants/stylesConstants'
import { HeaderLogoLoader } from 'components/Loaders'

const styles = theme => {
  const { palette, type, breakpoints } = theme
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      height: 80,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: palette[type].header.borderColor,
      boxShadow: `0 2px 4px 0 ${palette[type].header.shadow.s}, 0 1px 0 0 ${palette[type].header.shadow.f}`,
      backgroundColor: palette[type].default,
      position: 'fixed',
      zIndex: 111,
      width: '100%',
      top: 0
    },
    hovered: {
      zIndex: zIndexes.confirmation + 1
    },
    contentContainer: {
      margin: '0 12px'
    },
    leftPart: {
      padding: '.5rem 0'
    },
    logoImage: {
      width: 'auto',
      maxWidth: 104,
      height: '51px',
      marginTop: '1px'
    },

    [breakpoints.down('sm')]: {
      root: {
        height: 50
      },
      logoImage: {
        height: 30
      }
    },
    [breakpoints.only('md')]: {
      root: {
        height: 60
      },
      logoImage: {
        height: 37
      }
    },
    [breakpoints.only('lg')]: {
      root: {
        height: 70
      },
      logoImage: {
        height: 42
      }
    }
  }
}

const Header = ({ classes, theme, currentTheme, handleThemeChange }) => {
  const [loading, setLoading] = useState(true)
  const [hovered, setHovered] = useState(false)
  const { darkHeaderLogo, headerLogo } = useWhiteLabel()

  const isLightLogo = theme.type === 'dark' && darkHeaderLogo

  const handleNavigationHover = useCallback(() => {
    if (!hovered) {
      setHovered(true)
    }
  }, [hovered])

  const handleNavigationLeave = useCallback(() => {
    setHovered(false)
  }, [])

  useEffect(() => {
    if (headerLogo) {
      setLoading(false)
    }
  }, [headerLogo])

  return (
    <>
      <header
        onMouseOver={handleNavigationHover}
        onMouseLeave={handleNavigationLeave}
        className={classNames(classes.root, {
          [classes.hovered]: hovered
        })}
      >
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          className={classes.contentContainer}
        >
          <Grid item className={classes.leftPart}>
            <Grid container alignItems="center" direction="row">
              {loading ? (
                <HeaderLogoLoader />
              ) : (
                <>
                  <Grid item>
                    <Drawer
                      color={theme.colors.other.color6[theme.type]}
                      currentTheme={currentTheme}
                      handleThemeChange={handleThemeChange}
                    />
                  </Grid>
                  <Grid item>
                    <RouterLink to={`/sign-in`}>
                      <img
                        className={classes.logoImage}
                        src={isLightLogo ? darkHeaderLogo : headerLogo}
                        alt="Logo"
                      />
                    </RouterLink>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </header>
    </>
  )
}

export default withTranslation('translations')(
  withStyles(styles, { withTheme: true })(withRouter(withSnackbar(Header)))
)
