import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  recentlySearch: {}
}

export const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    syncRecentlySearch: (state, action) => {
      const { entity, name, options } = action.payload

      state.recentlySearch[entity] = {
        ...(state.recentlySearch[entity] || {}),
        [name]: options
      }
    },
    clearRecentlySearch: state =>
      (state.recentlySearch = initialState.recentlySearch)
  }
})

export const { syncRecentlySearch, clearRecentlySearch } = filtersSlice.actions

export default filtersSlice.reducer
