import api from './api'

import { errorHandler, queryParamsHelper } from '../utils'

const getQuotes = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/quote',
      params: queryParamsHelper(params, ['group', 'tag'])
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postQuote = async ({ data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: '/quote',
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putQuote = async ({ data, id }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/quote/${id}`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteQuote = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/quote/${id}`
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getQuoteCategories = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/quoteCategory?limit=512'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const quoteService = {
  getQuotes,
  postQuote,
  putQuote,
  deleteQuote,
  getQuoteCategories
}

export default quoteService
