import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'

import {
  withStyles,
  Grid,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  Link
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { BlueButton } from '../Buttons'

import { FormControlInput } from '../Form'

import { postData, clearPostData } from 'actions/feedbackActions'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import Scrollbars from 'components/Scrollbars'
import Text from 'components/Typography/Text'

const styles = ({ palette, colors, type, typography }) => {
  return {
    container: {
      width: '100%',
      height: '100%',
      padding: '10px 20px 0px',
      background: colors.background.primary[type]
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: palette[type].dialog.header.background,
      borderBottom: `solid 1px ${palette[type].dialog.border}`,
      padding: '10px 20px 5px 20px',
      height: palette[type].dialog.header.height
    },
    title: {
      color: palette[type].dialog.title
    },
    close: {
      width: '46px',
      height: '46px',
      margin: '0px',
      padding: '0px',
      color: palette[type].dialog.closeButton
    },
    actionBar: {
      display: 'flex',
      background: palette[type].dialog.header.background,
      borderTop: `solid 1px ${palette[type].dialog.border}`,
      padding: '0 20px',
      height: '55px',
      margin: '0'
    },
    subtitle: {
      fontSize: 14,
      letterSpacing: '-0.02px',
      color: palette[type].suggestionBox.subtitle,
      marginBottom: 26
    },
    textarea: {
      width: '100%',
      height: 216,
      background: palette[type].formControls.input.background,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette[type].formControls.input.border,
      color: palette[type].formControls.input.color,
      borderRadius: 4,
      resize: 'none',
      padding: '17px 15px',
      fontFamily: typography.fontFamily,
      marginBottom: 16
    },
    textareaError: {
      borderColor: colors.error
    },
    button: {
      width: 142,
      alignSelf: 'center'
    },
    messageError: {
      color: colors.error,
      fontSize: '0.6rem',
      transform: 'translateY(-18px)',
      position: 'absolute'
    },
    scrollbarRoot: {
      height: '355px !important',
      maxHeight: 'calc(100vh - 260px)'
    },
    contentRoot: {
      paddingRight: 5
    },
    linkText: {
      color: '#0076b9'
    }
  }
}

const formConfig = {
  initialValues: {
    subject: '',
    message: ''
  },
  validationSchema: Yup.object().shape({
    subject: Yup.string()
      .restrictedCharacters()
      .required('The subject is required')
      .min(2, 'The subject must be at least 2 characters')
      .max(50, 'The subject can up to 50 characters'),
    message: Yup.string()
      .restrictedCharacters()
      .required('The message is required')
      .min(10, 'The message must be at least 10 characters')
      .max(3000, 'The message can up to 3000 characters')
  })
}

const { initialValues, validationSchema } = formConfig

const SuggestionForm = ({ t, classes, postData, closeModal, errorFields }) => {
  const role = useUserRole()
  const onSubmit = useCallback(
    ({ subject, message }) => {
      postData(
        {
          subject,
          message
        },
        role
      )
    },
    [postData, role]
  )

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  useEffect(
    () => {
      form.validateForm()
    },
    //eslint-disable-next-line
    []
  )

  const {
    values,
    isValid,
    errors,
    setFieldError,
    touched,
    handleChange,
    handleBlur,
    handleSubmit
  } = form

  useEffect(() => {
    errorFields?.forEach(error => {
      setFieldError(error.name, error.value[0])
    })
    // eslint-disable-next-line
  }, [errorFields])

  return (
    <>
      <DialogTitle className={classes.header} disableTypography>
        <Grid item container direction="column">
          <Text
            variant="big"
            weight="bold"
            rootClassName={classes.title}
            component="h2"
          >
            {t('Share an Idea')}
          </Text>
        </Grid>

        <IconButton className={classes.close} onClick={closeModal}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Grid container direction="column" className={classes.container}>
        <Scrollbars className={classes.scrollbarRoot}>
          <div className={classes.contentRoot}>
            <Typography className={classes.subtitle}>
              {t(
                "Send us your product feedback and feature ideas. If you're having an issue and need technical support, please visit our"
              )}{' '}
              <Link href="https://mvix.com/submit-a-ticket/" target="_blank">
                {t('Support Center')}
              </Link>
            </Typography>

            <FormControlInput
              fullWidth
              placeholder={t('Subject')}
              name="subject"
              value={values.subject}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.subject}
              touched={touched.subject}
            />

            <textarea
              className={[
                classes.textarea,
                errors.message && touched.message ? classes.textareaError : ''
              ].join(' ')}
              placeholder={t('Message')}
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.message && touched.message && (
              <Typography className={classes.messageError}>
                {errors.message}
              </Typography>
            )}
          </div>
        </Scrollbars>
      </Grid>
      <DialogActions className={classes.actionBar}>
        <BlueButton
          className={classes.button}
          opaque={!isValid}
          onClick={handleSubmit}
          iconClassName="fa-regular fa-envelope"
        >
          {t('Send Message')}
        </BlueButton>
      </DialogActions>
    </>
  )
}

SuggestionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  post: PropTypes.object,
  postData: PropTypes.func,
  clearPostData: PropTypes.func
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postData,
      clearPostData
    },
    dispatch
  )

export default withTranslation('translations')(
  withStyles(styles)(connect(null, mapDispatchToProps)(SuggestionForm))
)
