import React, { useMemo } from 'react'

import FormControlSelectFont from './FormControlSelectFont'
import { useUserRole } from 'hooks/tableLibrary'
import useMediaBrandGuide from 'hooks/useMediaBrandGuide'

const FormControlBrandGuideSelectFont = ({ ...props }) => {
  const role = useUserRole()
  const { brandGuideSettings, isEnabledBrandGuide } = useMediaBrandGuide()

  const staticGroupOptions = useMemo(() => {
    return [
      {
        label: 'Brand Fonts',
        options:
          brandGuideSettings?.fontFamily?.map(font => ({
            label: font.font,
            value: font.font,
            font
          })) || []
      }
    ]
  }, [brandGuideSettings])

  return (
    <FormControlSelectFont
      {...props}
      isGroupOptions={role.org && isEnabledBrandGuide}
      groupName="Other Fonts"
      staticGroupOptions={staticGroupOptions}
      hideOptionsStrict={staticGroupOptions?.[0]?.options || []}
      restrictFetch={brandGuideSettings.isMandatory}
      isClearable={false}
    />
  )
}

export default FormControlBrandGuideSelectFont
