import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { ReactComponent as WarningIcon } from 'common/assets/icons/ic-round-warning.svg'
import Text from 'components/Typography/Text'

const useStyles = makeStyles(({ colors, spacing, lineHeight }) => ({
  icon: {
    color: colors.highlight,
    width: 38,
    height: 38,
    marginRight: spacing(1)
  },
  fitContent: {
    width: 'fit-content'
  },
  text: {
    lineHeight: lineHeight.small
  },
  subText: {
    lineHeight: lineHeight.smaller
  },
  clickable: {
    cursor: 'pointer'
  }
}))

const SmallPlaceholderContent = ({
  text,
  requestText,
  onClick,
  itemsAlignment
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="center"
      wrap="nowrap"
      {...(itemsAlignment && { alignItems: itemsAlignment })}
    >
      <Grid item>
        <WarningIcon
          className={classNames(classes.icon, {
            [classes.clickable]: !!onClick
          })}
          onClick={onClick}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        className={classNames(classes.fitContent, {
          [classes.clickable]: !!onClick
        })}
        onClick={onClick}
      >
        <Text
          weight="bold"
          color="title.primary"
          display="inline"
          rootClassName={classes.text}
        >
          {text}
        </Text>
        {requestText && (
          <Text
            display="inline"
            variant="small"
            rootClassName={classes.subText}
          >
            {requestText}
          </Text>
        )}
      </Grid>
    </Grid>
  )
}

export default SmallPlaceholderContent
