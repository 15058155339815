import * as Yup from 'yup'
import { filetypeinfo } from 'magic-bytes.js'

import { getBytes } from 'utils/fileUtils'
import { isTruthy } from 'utils/generalUtils'
import { hasRestrictedCharacters, validUrl } from 'constants/validationMessages'
import validationTypes from 'constants/validationTypes'

function urlWithoutDomain() {
  return this.test('isLocalUrl', validUrl, value => {
    if (!value) {
      return false
    }

    try {
      const url = new URL(value)

      return isTruthy(
        ['https:', 'http:', 'ftp:'].includes(url.protocol),
        /\d+|\w+/.test(url.hostname)
      )
    } catch (e) {
      return false
    }
  })
}

function maxFileSize(megabytes) {
  return this.test('fileSize', `File is more than ${megabytes}MB`, value => {
    if (!value?.length) {
      return true
    }
    return value[0].size <= megabytes * 1024 * 1024
  })
}

function allowedMimeType(mimeTypes) {
  return this.test('file', 'Invalid mime type', async value => {
    if (!value?.length) {
      return false
    }
    try {
      const bytes = await getBytes(value[0])
      const fileSignature = filetypeinfo(bytes)[0]

      return mimeTypes
        .map(type => type.toLowerCase())
        .includes(fileSignature?.mime.toLowerCase())
    } catch (e) {
      return false
    }
  })
}

//minimum restriction
function restrictedCharacters() {
  return this.test('restrictedCharacters', hasRestrictedCharacters, value => {
    if (!value) {
      return true
    }

    return !/[><]/.test(value)
  })
}

//restriction of all special characters
function severelyRestrictedCharacters(type = '') {
  return this.test('restrictedCharacters', hasRestrictedCharacters, value => {
    if (!value) {
      return true
    }

    switch (type) {
      // exceptions: -'
      case validationTypes.address:
        return (
          // eslint-disable-next-line
          !/[!@#$%^&*)(_=+\\|\]\[}{;:\/?.><~`]/.test(value) &&
          /^[\p{L}\p{N}\p{M}\-'\s]+$/u.test(value)
        )
      default:
        return (
          // eslint-disable-next-line
          !/[!@#$%^&*)(_=+\\|\]\[}{;:\/?.><~\-'`]/.test(value) &&
          /^[\p{L}\p{N}\p{M}'\s]+$/u.test(value)
        )
    }
  })
}

Yup.addMethod(Yup.string, 'urlWithoutDomain', urlWithoutDomain)
Yup.addMethod(Yup.mixed, 'maxFileSize', maxFileSize)
Yup.addMethod(Yup.mixed, 'allowedMimeType', allowedMimeType)
Yup.addMethod(Yup.array, 'maxFileSize', maxFileSize)
Yup.addMethod(Yup.array, 'allowedMimeType', allowedMimeType)
Yup.addMethod(Yup.string, 'restrictedCharacters', restrictedCharacters)
Yup.addMethod(
  Yup.string,
  'severelyRestrictedCharacters',
  severelyRestrictedCharacters
)
