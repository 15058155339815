import React, { useMemo } from 'react'
import DatePicker from 'react-datepicker'
import CustomInput from './CustomInputDatePicker'
import 'react-datepicker/dist/react-datepicker.css'
import Error from './Error'

const WrapperDatePicker = ({
  alert,
  disabled,
  label,
  isOptional,
  inputClassName = '',
  disabledInputClassName = '',
  iconClassName,
  selected,
  marginBottom,
  isClearable = false,
  onClear,
  error,
  touched,
  ...props
}) => {
  //prevent "Invalid Date" object
  const value = useMemo(
    () =>
      selected !== null && selected instanceof Date && isNaN(selected)
        ? null
        : selected,
    [selected]
  )

  return (
    <>
      <DatePicker
        {...props}
        selected={value}
        disabled={disabled}
        className="customDatePickerWidth"
        customInput={
          <CustomInput
            isOptional={isOptional}
            alert={alert}
            disabled={disabled}
            label={label}
            rootClassName={inputClassName}
            disabledClassName={disabledInputClassName}
            iconClassName={iconClassName}
            marginBottom={marginBottom}
            isClearable={isClearable}
            onClear={onClear}
          />
        }
      />
      <Error absolute error={error} condition={touched} />
    </>
  )
}

export default WrapperDatePicker
