import { createAction } from '../../utils/storeUtils'

export const GET_OFFICE_365_FILES = 'MEDIA_FEATURES/GET_OFFICE_365_FILES'
export const GET_OFFICE_365_FILES_SUCCESS =
  'MEDIA_FEATURES/GET_OFFICE_365_FILES_SUCCESS'
export const GET_OFFICE_365_FILES_ERROR =
  'MEDIA_FEATURES/GET_OFFICE_36_ERROR5_FILES'

export const GET_OFFICE_365_SHARED_FILES =
  'MEDIA_FEATURES/GET_OFFICE_365_SHARED_FILES'
export const GET_OFFICE_365_SHARED_FILES_SUCCESS =
  'MEDIA_FEATURES/GET_OFFICE_365_SHARED_FILES_SUCCESS'
export const GET_OFFICE_365_SHARED_FILES_ERROR =
  'MEDIA_FEATURES/GET_OFFICE_365_SHARED_FILES_ERROR'

export const getOffice365Files = payload =>
  createAction(GET_OFFICE_365_FILES, payload)

export const getOffice365FilesSuccess = payload =>
  createAction(GET_OFFICE_365_FILES_SUCCESS, payload)

export const getOffice365FilesError = payload =>
  createAction(GET_OFFICE_365_FILES_ERROR, payload)

export const getOffice365SharedFiles = payload =>
  createAction(GET_OFFICE_365_SHARED_FILES, payload)

export const getOffice365SharedFilesSuccess = payload =>
  createAction(GET_OFFICE_365_SHARED_FILES_SUCCESS, payload)

export const getOffice365SharedFilesError = payload =>
  createAction(GET_OFFICE_365_SHARED_FILES_ERROR, payload)
