import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { simulateEvent } from 'utils'
import ColorPickerModal from 'components/Modal/ColorPickerModal/ColorPickerModal'
import isShadeOfWhite from 'utils/isShadeOfWhite'
import Tooltip from 'components/Tooltip'
import { zIndexes } from 'constants/stylesConstants'

const useStyles = makeStyles(({ colors, typography, type, palette }) => ({
  colorBox: {
    width: 23,
    height: 23,
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'relative',
    border: `1px solid transparent`
  },
  addColorBox: {
    width: 23,
    height: 23,
    borderRadius: '50%',
    cursor: 'pointer',
    border: `1px dashed ${palette[type].formControls.multipleColorPicker.icon.border}`,
    display: 'grid',
    placeItems: 'center'
  },
  disabledAddColorBox: {
    border: `1px dashed ${palette[type].formControls.multipleColorPicker.icon.disabled}`,
    cursor: 'not-allowed',

    '& $addIcon': {
      color: palette[type].formControls.multipleColorPicker.icon.disabled
    }
  },
  addIcon: {
    ...typography.darkAccent[type],
    color: palette[type].formControls.multipleColorPicker.icon.color,
    fontSize: 14
  },
  popoverPaper: {
    borderRadius: 4,
    padding: 5,
    background: colors.background.primary[type],

    '& .chrome-picker': {
      boxShadow: 'none !important',
      width: '200px !important',
      background: `${colors.background.primary[type]} !important`,
      '& > div > div': {
        borderRadius: 3,
        '& svg': {
          fill: `${palette[type].formControls.input.color} !important`,
          background: 'transparent !important'
        },
        '& > div > div > div ': {
          '& > input': {
            fontSize: '13px !important',
            lineHeight: '22px',
            fontFamily: typography.fontFamily,
            backgroundColor: palette[type].formControls.input.background,
            border: `1px solid ${palette[type].formControls.input.border} !important`,
            color: `${palette[type].formControls.input.color} !important`,
            boxShadow: 'none !important',
            textTransform: 'lowercase',
            height: '32px !important'
          },
          '& > span': {
            fontSize: '12px !important',
            color: `${palette[type].formControls.input.color}  !important`,
            fontFamily: typography.fontFamily
          }
        }
      }
    }
  },
  colorPickerModalRoot: {
    '& #rbgcp-wrapper': {
      '& > div:last-child': {
        '& > div:first-child': {
          width: '44px !important',
          height: '44px !important'
        },
        '& > div:last-child': {
          alignItems: 'center',
          gap: 4,
          justifyContent: 'unset !important',
          '& div': {
            borderRadius: '50% !important',
            height: '20px !important',
            width: '20px !important',
            marginBottom: '0 !important',
            cursor: 'pointer',
            borderColor: 'lightgray !important'
          }
        }
      }
    }
  },
  materialRoot: {
    zIndex: zIndexes.selectMenu + 2
  }
}))

const defaultColor = 'rgba(0, 0, 0, 1)'

const FormColorPicker = ({
  value,
  name,
  handleChange = f => f,
  disabled = false,
  isClearable = false,
  onlyColor = false,
  withTooltip = false,
  clearColorOnClick = false,
  colorBoxClassName,
  onSelect = f => f,
  renderColor,
  colorPresets,
  disablePortal,
  clearColorCb,
  changeOnClose,
  onChangeDelay,
  hideOpacity,
  popUpPlacement
}) => {
  const [color, setColor] = useState(defaultColor)
  const classes = useStyles()

  useEffect(() => {
    if (value || isClearable) {
      setColor(value)
    }
  }, [value, setColor, isClearable])

  const handleChangeCompleteColor = useCallback(
    ({ color: _color }) => {
      if (_color) {
        handleChange(simulateEvent(name, _color))
      }
    },
    [handleChange, name]
  )

  const handleClear = useCallback(
    e => {
      e.stopPropagation()
      clearColorCb
        ? clearColorCb(name)
        : handleChange(simulateEvent(name, null))
    },
    [handleChange, clearColorCb, name]
  )

  const triggerContent = useCallback(
    onClick => {
      return (
        <div
          className={classNames(classes.colorBox, colorBoxClassName)}
          style={{
            background: color,
            borderColor: isShadeOfWhite(color) ? 'lightgray' : 'transparent'
          }}
          {...(onClick && { onClick })}
        >
          {renderColor}
        </div>
      )
    },
    [color, classes, colorBoxClassName, renderColor]
  )

  const showAddColorBox = useMemo(() => {
    return isClearable && !color && !onlyColor
  }, [isClearable, color, onlyColor])

  const trigger = useMemo(
    () =>
      showAddColorBox ? (
        <div
          className={classNames(classes.addColorBox, {
            [classes.disabledAddColorBox]: disabled
          })}
        >
          <i className={classNames('fa-regular fa-plus', classes.addIcon)} />
        </div>
      ) : withTooltip ? (
        <Tooltip
          title={value}
          placement="top"
          arrow
          onClick={() => onSelect(color)}
        >
          {triggerContent(null)}
        </Tooltip>
      ) : (
        triggerContent(() => onSelect(color))
      ),
    [
      color,
      classes,
      disabled,
      onSelect,
      withTooltip,
      value,
      triggerContent,
      showAddColorBox
    ]
  )

  if (!showAddColorBox && clearColorOnClick) {
    return <div onClick={handleClear}>{trigger}</div>
  }

  return disabled || onlyColor ? (
    trigger
  ) : (
    <ColorPickerModal
      value={color || defaultColor}
      onChange={handleChangeCompleteColor}
      trigger={trigger}
      hideColorTypeBtns
      hideAdvancedSliders
      hideColorGuide
      hideControls
      hideEyeDrop
      hideInputType
      hideInputs={false}
      presets={colorPresets}
      disablePortal={disablePortal}
      materialRootClass={classes.materialRoot}
      rootClass={classes.colorPickerModalRoot}
      changeOnClose={changeOnClose}
      onChangeDelay={onChangeDelay}
      hideOpacity={hideOpacity}
      placement={popUpPlacement}
    />
  )
}

export default FormColorPicker
