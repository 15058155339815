import {
  Grid,
  IconButton,
  withStyles,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import ResizeObserver from 'react-resize-observer'
import PropTypes from 'prop-types'

import { TagChip } from '../Chip'
import { sortByTitle, sortByTitleDesc } from 'utils/libraryUtils'
import LockCheckIcon from 'components/Icons/LockCheckIcon'
import { isTRLTag } from 'utils/deviceUtils'
import MaterialPopup from '../Popup/MaterialPopup'

const styles = ({ palette, type, spacing }) => ({
  root: {
    position: 'relative',
    gap: 3,
    padding: '3px 0'
  },
  tagRoot: {
    width: 'fit-content',
    gap: '4px',
    '& > span': {
      display: 'inline',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '8ch'
    }
  },
  popupTagRoot: {
    width: 'fit-content',
    gap: '4px',
    '& > span': {
      display: 'inline',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '195px'
    }
  },
  overflowIconWrap: {
    padding: '0px',
    width: '22px',
    height: '22px'
  },
  overflowIconContent: {
    fontSize: '20px',
    width: 'inherit',
    height: 'inherit',
    color: palette[type].header.rightAction.iconColor
  },
  overflowIcon: {
    transform: 'rotate(90deg)'
  },
  popupContent: {
    padding: spacing(1),
    gap: 3
  },
  popupTag: {
    margin: 0
  },
  tagWrap: {
    padding: '1px'
  },
  expiredIcon: {
    order: 1,
    color: '#fec92e',
    width: '16px',
    marginLeft: 1
  }
})

const dynamicStyle = makeStyles({
  tagRoot: ({ maxWidth }) => ({
    '& > span': {
      maxWidth: `${maxWidth}px !important`
    }
  })
})

const LibraryTagChips = ({
  maxDisplayedTags = 5,
  maxWidth = 188,
  tags = [],
  descend = false,
  rootClasses = null,
  classes,
  theme: { palette, type },
  rightComponent,
  isDevicesLibrary,
  isLicenseExpired,
  justifyContent = 'center'
}) => {
  const tagChipRef = useRef()
  const dynamicClasses = dynamicStyle({
    maxWidth
  })
  const [showTooltip, setShowTooltip] = useState(false)
  const [maxDisplayTags, setMaxDisplayTags] = useState(
    tags?.length < maxDisplayedTags ? tags?.length : maxDisplayedTags
  )

  const [hasTags, displayTags, popupTags] = useMemo(() => {
    const hasTags = tags && tags.length && tags.every(({ title }) => title)
    const displayTagsCount = hasTags
      ? tags.length > maxDisplayTags
        ? maxDisplayTags
        : tags.length
      : 0
    const newTags = hasTags ? [...tags] : []

    const sortedTags = descend ? sortByTitleDesc(newTags) : sortByTitle(newTags)

    return [
      hasTags,
      sortedTags.slice(0, displayTagsCount),
      sortedTags.slice(displayTagsCount)
    ]
  }, [tags, maxDisplayTags, descend])

  useEffect(() => {
    if (tagChipRef.current) {
      const textElement = tagChipRef.current?.querySelector('span')
      setShowTooltip(
        textElement?.clientWidth !== textElement?.scrollWidth ||
          textElement?.clientWidth > maxWidth
      )
    }
    // eslint-disable-next-line
  }, [tagChipRef.current, maxWidth])

  const handleResize = ({ height }) => {
    if (height >= 70) {
      setMaxDisplayTags(max => max - 1)
    }
  }

  const trlIcons = useMemo(() => {
    return (
      <>
        <LockCheckIcon />
        {isLicenseExpired && (
          <i
            className={classNames(
              'fa fa-exclamation-circle',
              classes.expiredIcon
            )}
          />
        )}
      </>
    )
  }, [isLicenseExpired, classes])

  return (
    <Grid
      container
      justifyContent={justifyContent}
      alignItems="center"
      className={classNames(classes.root, rootClasses)}
    >
      {hasTags ? (
        <>
          {displayTags.map((tag, index) => (
            <Tooltip arrow key={index} title={showTooltip ? tag.title : ''}>
              <Grid item>
                <TagChip
                  tag={tag}
                  classes={{
                    root: classNames(classes.tagRoot, dynamicClasses.tagRoot)
                  }}
                  icon={isDevicesLibrary && isTRLTag(tag) && trlIcons}
                  ref={tagChipRef}
                />
              </Grid>
            </Tooltip>
          ))}
          {!!popupTags.length && (
            <MaterialPopup
              style={{
                maxWidth: 230
              }}
              trigger={
                <IconButton
                  classes={{
                    root: classes.overflowIconWrap,
                    label: classes.overflowIconContent
                  }}
                >
                  <i
                    className={[
                      'fa-regular fa-ellipsis-vertical',
                      classes.overflowIcon
                    ].join(' ')}
                  />
                </IconButton>
              }
            >
              <Grid container className={classes.popupContent}>
                {popupTags.map((tag, index) => (
                  <Grid item className={classes.popupTag} key={index}>
                    <TagChip
                      tag={tag}
                      classes={{
                        root: classes.popupTagRoot
                      }}
                      icon={
                        isDevicesLibrary && isTRLTag(tag) && <LockCheckIcon />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </MaterialPopup>
          )}
        </>
      ) : (
        'N/A'
      )}

      {rightComponent && rightComponent}
      <div className="no-print">
        <ResizeObserver onResize={handleResize} />
      </div>
    </Grid>
  )
}

LibraryTagChips.propTypes = {
  tags: PropTypes.array,
  descend: PropTypes.bool,
  rootClasses: PropTypes.string,
  rightComponent: PropTypes.element,
  isDevicesLibrary: PropTypes.bool
}

export default withStyles(styles, { withTheme: true })(LibraryTagChips)
