import api from '../api'
import { errorHandler, queryParamsHelper } from '../../utils'

export const getPublicNocGeneralItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/org/public/noc/general',
      params: queryParamsHelper(params, ['group', 'tag'])
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getPublicNocHealthItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/org/public/noc/health',
      params: queryParamsHelper(params, ['group', 'tag'])
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getPublicNocNetworkItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/org/public/noc/network',
      params: queryParamsHelper(params, ['group', 'tag'])
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const getPublicNocAccessToken = async token => {
  try {
    const response = await api({
      method: 'POST',
      url: '/org/login/noc',
      data: {
        token
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}
