export const popupContentStyle = {
  width: '250px',
  borderRadius: '6px',
  animation: 'fade-in 200ms'
}

export const getPopupArrowStyle = ({ palette, type }) => ({
  background: palette[type].sideModal.groups.header.background,
  borderRight: `solid 1px ${palette[type].deviceCard.border}`,
  borderBottom: `solid 1px ${palette[type].deviceCard.border}`,
  zIndex: 0
})

export const getCountCircleStyle = ({ spacing }) => ({
  borderRadius: '50%',
  height: 28,
  width: 28,
  borderWidth: 3,
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: spacing(1)
})

export const getListContentWrapStyle = () => ({
  height: 40,
  padding: '0 13px !important'
})
