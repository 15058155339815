import update from 'immutability-helper'

import * as types from '../actions'

const initialState = {
  alertDevices: {},
  postAlertTrigger: {},
  deviceActiveEmergencyAlert: {},
  deviceMediaEmergencyAlert: {},
  putDeviceMediaEmergencyAlert: {},
  removeDeviceMediaEmergencyAlert: {},
  deviceMediaCapAlert: {},
  removeDeviceMediaCapAlert: {},
  putDeviceMediaCapAlert: {},
  disableAlert: {},
  disableDeviceAlert: {
    response: {},
    error: {}
  },
  associateCapAlert: {
    isFetching: false,
    isSuccess: false,
    error: null
  },
  ipawsAlertDevices: {
    isFetching: false,
    items: [],
    error: null
  },
  associateIpawsAlert: {
    isFetching: false,
    isSuccess: false,
    error: null
  },
  removeDeviceMediaIpawsAlert: {},
  deviceMediaIpawsAlert: {},
  putDeviceMediaIpawsAlert: {},
  alertDevicesData: {
    alertTriggerDevices: [],
    capAlertDevices: [],
    ipawsAlertDevices: []
  }
}

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALERT_DEVICES_BY_ID_SUCCESS:
      return update(state, {
        alertDevices: {
          response: { $set: action.payload }
        }
      })
    case types.GET_ALERT_DEVICES_BY_ID_ERROR:
      return update(state, {
        alertDevices: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_ALERT_DEVICES_BY_ID_INFO:
      return update(state, {
        alertDevices: { $set: {} }
      })
    case types.POST_ALERT_TRIGGER_SUCCESS:
      return update(state, {
        postAlertTrigger: {
          response: { $set: action.payload }
        }
      })
    case types.POST_ALERT_TRIGGER_ERROR:
      return update(state, {
        postAlertTrigger: {
          error: { $set: action.payload }
        }
      })

    case types.SET_ALERT_TRIGGER_DATA:
      return update(state, {
        alertDevicesData: {
          alertTriggerDevices: { $set: action.payload }
        }
      })
    case types.DELETE_ALERT_TRIGGER_DATA:
      return update(state, {
        alertDevicesData: {
          alertTriggerDevices: { $set: [] }
        }
      })
    case types.SET_CAP_ALERT_DATA:
      return update(state, {
        alertDevicesData: {
          capAlertDevices: { $set: action.payload }
        }
      })
    case types.DELETE_CAP_ALERT_DATA:
      return update(state, {
        alertDevicesData: {
          capAlertDevices: { $set: [] }
        }
      })
    case types.SET_IPAWS_ALERT_DATA:
      return update(state, {
        alertDevicesData: {
          ipawsAlertDevices: { $set: action.payload }
        }
      })
    case types.DELETE_IPAWS_ALERT_DATA:
      return update(state, {
        alertDevicesData: {
          ipawsAlertDevices: { $set: [] }
        }
      })

    case types.CLEAR_POST_ALERT_TRIGGER_INFO:
      return update(state, {
        postAlertTrigger: { $set: {} }
      })
    case types.GET_DEVICE_ACTIVE_EMERGENCY_ALERT_SUCCESS:
      return update(state, {
        deviceActiveEmergencyAlert: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_ACTIVE_EMERGENCY_ALERT_ERROR:
      return update(state, {
        deviceActiveEmergencyAlert: {
          error: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS:
      return update(state, {
        deviceMediaEmergencyAlert: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR:
      return update(state, {
        deviceMediaEmergencyAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_MEDIA_EMERGENCY_ALERT_INFO:
      return update(state, {
        deviceMediaEmergencyAlert: { $set: {} }
      })
    case types.PUT_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS:
      return update(state, {
        putDeviceMediaEmergencyAlert: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR:
      return update(state, {
        putDeviceMediaEmergencyAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_DEVICE_MEDIA_EMERGENCY_ALERT_INFO:
      return update(state, {
        putDeviceMediaEmergencyAlert: { $set: {} }
      })
    case types.REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_SUCCESS:
      return update(state, {
        removeDeviceMediaEmergencyAlert: {
          response: { $set: action.payload }
        }
      })
    case types.REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_ERROR:
      return update(state, {
        removeDeviceMediaEmergencyAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_INFO:
      return update(state, {
        removeDeviceMediaEmergencyAlert: { $set: {} }
      })
    case types.GET_DEVICE_MEDIA_CAP_ALERT_SUCCESS:
      return update(state, {
        deviceMediaCapAlert: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_MEDIA_CAP_ALERT_ERROR:
      return update(state, {
        deviceMediaCapAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_MEDIA_CAP_ALERT_INFO:
      return update(state, {
        deviceMediaCapAlert: { $set: {} }
      })
    case types.REMOVE_DEVICE_MEDIA_CAP_ALERT_SUCCESS:
      return update(state, {
        removeDeviceMediaCapAlert: {
          response: { $set: action.payload }
        }
      })
    case types.REMOVE_DEVICE_MEDIA_CAP_ALERT_ERROR:
      return update(state, {
        removeDeviceMediaCapAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_REMOVE_DEVICE_MEDIA_CAP_ALERT_INFO:
      return update(state, {
        removeDeviceMediaCapAlert: { $set: {} }
      })
    case types.PUT_DEVICE_MEDIA_CAP_ALERT_SUCCESS:
      return update(state, {
        putDeviceMediaCapAlert: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_DEVICE_MEDIA_CAP_ALERT_ERROR:
      return update(state, {
        putDeviceMediaCapAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_DEVICE_MEDIA_CAP_ALERT_INFO:
      return update(state, {
        putDeviceMediaCapAlert: { $set: {} }
      })
    case types.DISABLE_ALERT_SUCCESS:
      return update(state, {
        disableAlert: {
          response: { $set: action.payload }
        }
      })
    case types.DISABLE_ALERT_ERROR:
      return update(state, {
        disableAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DISABLE_ALERT_INFO:
      return update(state, {
        disableAlert: { $set: {} }
      })
    case types.DISABLE_DEVICE_ALERT_SUCCESS:
      return update(state, {
        disableDeviceAlert: {
          response: { $set: action.payload }
        }
      })
    case types.DISABLE_DEVICE_ALERT_ERROR:
      return update(state, {
        disableDeviceAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DISABLE_DEVICE_ALERT_INFO:
      return update(state, {
        $merge: {
          disableDeviceAlert: {
            response: {},
            error: {}
          }
        }
      })
    case types.REQUEST_ASSOCIATE_CAP_ALERT:
      return update(state, {
        associateCapAlert: {
          $merge: {
            isFetching: true,
            isSuccess: false,
            error: null
          }
        }
      })
    case types.ASSOCIATE_CAP_ALERT_SUCCESS:
      return update(state, {
        associateCapAlert: {
          $merge: {
            isFetching: false,
            isSuccess: true,
            error: null
          }
        }
      })
    case types.ASSOCIATE_CAP_ALERT_ERROR:
      const { message } = action.payload
      return update(state, {
        associateCapAlert: {
          $merge: {
            isFetching: false,
            isSuccess: false,
            error: message
          }
        }
      })
    case types.RESET_ASSOCIATE_CAP_ALERT:
      return update(state, {
        associateCapAlert: {
          $merge: {
            isFetching: false,
            isSuccess: false,
            error: null
          }
        }
      })
    case types.GET_IPAWS_ALERT_DEVICES:
      return update(state, {
        ipawsAlertDevices: {
          $merge: {
            isFetching: true
          }
        }
      })
    case types.GET_IPAWS_ALERT_DEVICES_SUCCESS:
      return update(state, {
        ipawsAlertDevices: {
          $merge: {
            isFetching: false,
            items: action.payload,
            error: null
          }
        }
      })
    case types.GET_IPAWS_ALERT_DEVICES_ERROR:
      return update(state, {
        ipawsAlertDevices: {
          $merge: {
            isFetching: false,
            error: action.payload
          }
        }
      })
    case types.ASSOCIATE_IPAWS_ALERT:
      return update(state, {
        associateIpawsAlert: {
          $merge: {
            isFetching: true,
            isSuccess: false,
            error: null
          }
        }
      })
    case types.ASSOCIATE_IPAWS_ALERT_SUCCESS:
      return update(state, {
        associateIpawsAlert: {
          $merge: {
            isFetching: false,
            isSuccess: true,
            error: null
          }
        }
      })
    case types.ASSOCIATE_IPAWS_ALERT_ERROR:
      return update(state, {
        associateIpawsAlert: {
          $merge: {
            isFetching: false,
            isSuccess: false,
            error: action.payload.message
          }
        }
      })
    case types.RESET_ASSOCIATE_IPAWS_ALERT:
      return update(state, {
        associateIpawsAlert: {
          $merge: {
            isFetching: false,
            isSuccess: false,
            error: null
          }
        }
      })
    case types.REMOVE_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS:
      return update(state, {
        removeDeviceMediaIpawsAlert: {
          response: { $set: action.payload }
        }
      })
    case types.REMOVE_DEVICE_MEDIA_IPAWS_ALERT_ERROR:
      return update(state, {
        removeDeviceMediaIpawsAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_REMOVE_DEVICE_MEDIA_IPAWS_ALERT_INFO:
      return update(state, {
        removeDeviceMediaIpawsAlert: { $set: {} }
      })
    case types.GET_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS:
      return update(state, {
        deviceMediaIpawsAlert: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_MEDIA_IPAWS_ALERT_ERROR:
      return update(state, {
        deviceMediaIpawsAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_MEDIA_IPAWS_ALERT_INFO:
      return update(state, {
        deviceMediaIpawsAlert: { $set: {} }
      })
    case types.PUT_DEVICE_MEDIA_IPAWS_ALERT_SUCCESS:
      return update(state, {
        putDeviceMediaIpawsAlert: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_DEVICE_MEDIA_IPAWS_ALERT_ERROR:
      return update(state, {
        putDeviceMediaIpawsAlert: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_DEVICE_MEDIA_IPAWS_ALERT_INFO:
      return update(state, {
        putDeviceMediaIpawsAlert: { $set: {} }
      })
    default:
      return state
  }
}

export default alertReducer
