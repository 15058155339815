import { call, put } from 'redux-saga/effects'
import * as types from '../actions'
import { quoteService } from '../services'
import { transformMeta } from 'utils/tableUtils'

function* getQuotes({ params }) {
  try {
    const response = yield call(quoteService.getQuotes, params)
    yield put({
      type: types.GET_QUOTES_SUCCESS,
      response: { ...response, meta: transformMeta(response.meta) }
    })
  } catch (error) {
    yield put({ type: types.GET_QUOTES_ERROR, error })
  }
}

function* postQuote(data) {
  try {
    yield call(quoteService.postQuote, data)
    yield put({
      type: types.POST_QUOTE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_QUOTE_ERROR, payload: error })
  }
}

function* putQuote({ payload }) {
  try {
    yield call(quoteService.putQuote, payload)
    yield put({
      type: types.PUT_QUOTE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_QUOTE_ERROR, payload: error })
  }
}

function* deleteQuote({ payload }) {
  try {
    yield call(quoteService.deleteQuote, payload)
    yield put({
      type: types.DELETE_QUOTE_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.DELETE_QUOTE_ERROR, payload: error })
  }
}

function* getQuoteCategories() {
  try {
    const response = yield call(quoteService.getQuoteCategories)
    yield put({
      type: types.GET_QUOTE_CATEGORIES_SUCCESS,
      response: { ...response, meta: transformMeta(response.meta) }
    })
  } catch (error) {
    yield put({ type: types.GET_QUOTE_CATEGORIES_ERROR, error })
  }
}

const quoteSaga = {
  getQuotes,
  postQuote,
  putQuote,
  deleteQuote,
  getQuoteCategories
}

export default quoteSaga
