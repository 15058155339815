import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const CPUContentLoader = ({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 306
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect x="72" y="30" rx="6" ry="6" width="700" height="90" />
    <rect x="72" y="170" rx="6" ry="6" width="700" height="90" />
    <rect x="866" y="14" rx="73" ry="73" width="146" height="146" />
    <rect x="1106" y="14" rx="73" ry="73" width="146" height="146" />
    <rect x="1350" y="14" rx="68" ry="68" width="136" height="136" />
    <rect x="815" y="184" rx="6" ry="6" width="240" height="120" />
  </ContentLoader>
)

export default withTheme(CPUContentLoader)
