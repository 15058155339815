import { multipleMimeMap } from 'constants/mimeTypes'

export const getBytes = file =>
  new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader()
      fileReader.onloadend = f => {
        resolve(new Uint8Array(f.target.result))
      }
      fileReader.onerror = e => {
        reject(e)
      }
      fileReader.readAsArrayBuffer(file)
    } catch (e) {
      reject(e)
    }
  })

export const getNormalizedMimeType = mime => {
  const normalizedMime = multipleMimeMap[mime]

  return normalizedMime || mime
}
