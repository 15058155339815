import React from 'react'
import PropTypes from 'prop-types'

import './PulseDot.scss'
import Tooltip from 'components/Tooltip'

const PulseDot = ({ color, styles, tooltipText }) => {
  return tooltipText ? (
    <Tooltip title={tooltipText} placement="top">
      <div className={`blob ${color}`} style={styles} />
    </Tooltip>
  ) : (
    <div className={`blob ${color}`} style={styles} />
  )
}

PulseDot.propTypes = {
  color: PropTypes.string.isRequired,
  styles: PropTypes.object
}

export default PulseDot
