import {
  permissionTypes,
  SYSTEM_REPORT_CONFIG,
  ORG_REPORT_CONFIG,
  RESELLER_CLIENT_USER
} from 'constants/permissionGroups'
import * as permissionNames from 'constants/permissionNames'

const transformPermissions = permissions =>
  permissions
    .map(item => {
      if (
        [
          permissionNames.CLIENT_MEDIA_APPROVAL,
          permissionNames.CLIENT_PLAYLIST_APPROVAL,
          permissionNames.CLIENT_TEMPLATE_APPROVAL,
          permissionNames.CLIENT_SCHEDULE_APPROVAL
        ].includes(item.name)
      ) {
        return {
          ...item,
          action: permissionTypes.approve
        }
      } else if (
        [
          permissionNames.SYSTEM_SHOW_REPORTS_CONFIG,
          permissionNames.SYSTEM_SHOW_REPORT_TABLE_CONFIG
        ].includes(item.name)
      ) {
        return {
          ...item,
          group: SYSTEM_REPORT_CONFIG
        }
      } else if (
        [
          permissionNames.ORG_SHOW_REPORTS_CONFIG,
          permissionNames.ORG_SHOW_REPORT_TABLE_CONFIG
        ].includes(item.name)
      ) {
        return {
          ...item,
          group: ORG_REPORT_CONFIG
        }
      } else if (item.name === permissionNames.RESELLER_CLIENT_USER_READ) {
        return {
          ...item,
          group: RESELLER_CLIENT_USER
        }
      } else if (item.name === permissionNames.RESELLER_CLIENT_IMPERSONATE) {
        return {
          ...item,
          group: 'impersonate'
        }
      }
      return item
    })
    .reduce((permissionsObj, item) => {
      const { group, action } = item
      permissionsObj[group] = {
        [permissionTypes.create]: false,
        [permissionTypes.read]: false,
        [permissionTypes.update]: false,
        [permissionTypes.delete]: false,
        ...permissionsObj[group],
        [action]: true
      }
      return permissionsObj
    }, {})

export default transformPermissions
