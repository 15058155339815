import update from 'immutability-helper'

import {
  GET_OFFICE_365_FILES,
  GET_OFFICE_365_FILES_SUCCESS,
  GET_OFFICE_365_FILES_ERROR,
  GET_OFFICE_365_SHARED_FILES,
  GET_OFFICE_365_SHARED_FILES_SUCCESS,
  GET_OFFICE_365_SHARED_FILES_ERROR
} from 'actions/new/mediaFeatures'
import { shapeOfBodyWithMeta } from 'constants/initialLibraryState'

const initialState = {
  office365Files: {
    ...shapeOfBodyWithMeta,
    isLoading: false
  },
  office365SharedFiles: {
    ...shapeOfBodyWithMeta,
    isLoading: false
  }
}

export default function mediaFeaturesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OFFICE_365_FILES:
      return update(state, {
        office365Files: {
          isLoading: { $set: true },
          error: { $set: initialState.office365Files.error }
        }
      })
    case GET_OFFICE_365_FILES_SUCCESS:
      return update(state, {
        office365Files: {
          isLoading: { $set: false },
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta }
        }
      })
    case GET_OFFICE_365_FILES_ERROR:
      return update(state, {
        office365Files: {
          isLoading: { $set: false },
          response: { $set: [] },
          error: { $set: action.payload }
        }
      })
    case GET_OFFICE_365_SHARED_FILES:
      return update(state, {
        office365SharedFiles: {
          isLoading: { $set: true },
          error: { $set: initialState.office365SharedFiles.error }
        }
      })
    case GET_OFFICE_365_SHARED_FILES_SUCCESS:
      return update(state, {
        office365SharedFiles: {
          isLoading: { $set: false },
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta }
        }
      })
    case GET_OFFICE_365_SHARED_FILES_ERROR:
      return update(state, {
        office365SharedFiles: {
          isLoading: { $set: false },
          response: { $set: [] },
          error: { $set: action.payload }
        }
      })
    default:
      return state
  }
}
