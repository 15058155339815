import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { useFormik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import uuidv4 from 'uuid/v4'
import { withSnackbar } from 'notistack'

import { FormControlInput, FormControlReactSelect } from 'components/Form'
import CheckboxSwitcher from 'components/Checkboxes/Switcher'
import { Text } from 'components/Typography'
import { daysOptions } from 'utils/generalUtils'
import DialogContentLayout from './DialogContentLayout'
import { simulateEvent } from 'utils'
import Scrollbars from 'components/Scrollbars'
import {
  AM_PM_FORMAT,
  AP_TIME_FORMAT,
  TIME_FORMAT,
  TIME_S_FORMAT
} from 'constants/dateTimeFormats'
import { timeOptions } from 'utils/time'
import ScheduleConflictModal from './ScheduleConflictModal'
import { prepareScreenPowerData } from 'utils/deviceReboot'
import { _isEmpty } from 'utils/lodash'
import { daysData } from 'constants/reboot'
import { useCustomSnackbar } from 'hooks'
import MaterialPopup from 'components/Popup/MaterialPopup'
import TimePickerNew from 'components/Form/formControlDateTimePickers/components/TimePickerNew'

const useStyles = makeStyles(({ type, typography }) => ({
  scrollbarRoot: {
    height: 'auto',
    maxHeight: 'calc(100vh - 320px)'
  },
  weeklyScreenContainer: {
    maxWidth: '80%',
    margin: 'auto',
    marginBottom: 16,
    paddingLeft: 10
  },
  contentRow: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    padding: 10
  },
  weeklyScreenLabel: {
    fontSize: 15,
    fontWeight: 700
  },
  rowAction: {
    ...typography.darkText[type],
    display: 'flex',
    gap: '10px !important',

    '& svg': {
      cursor: 'pointer'
    }
  },
  hiddenButton: {
    cursor: 'unset !important',
    opacity: 0
  },
  layoutContent: {
    display: 'block',
    overflow: 'hidden'
  },
  conditionsWrapper: {
    maxWidth: '80%',
    margin: 'auto'
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  lastRow: {
    marginBottom: 30
  },
  rowWrapper: {
    position: 'relative'
  },
  toText: {
    textTransform: 'lowercase'
  },
  daySelect: {
    width: 200
  },
  listRoot: {
    height: 300
  }
}))

const initialConditions = [
  {
    dayFrom: daysOptions[0].value,
    timeFrom: timeOptions[0].value,
    dayTo: daysOptions[0].value,
    timeTo: timeOptions[1].value,
    uuid: uuidv4()
  }
]

const initialValues = {
  scheduleEnabled: false,
  conditions: initialConditions
}

const getIntervalDays = ({ dayFrom, dayTo, timeFrom, timeTo }) => {
  const start = moment()
    .startOf('week')
    .day(dayFrom.substring(0, dayFrom.length - 1))
    .hour(timeFrom.split(':')[0])
    .minute(timeFrom.split(':')[1])
  const end = moment()
    .startOf('week')
    .day(dayTo.substring(0, dayTo.length - 1))
    .hour(timeTo.split(':')[0])
    .minute(timeTo.split(':')[1])

  if (end.isBefore(start)) {
    end.add(1, 'week')
  }

  return Array(Math.abs(start.diff(end, 'days')) + 1)
    .fill(null)
    .map((val, index) => (start.day() + index) % 7)
}
const invalidSlots = []
const ScreenPower = ({
  device,
  tabs,
  handleClose,
  screenPowerReducer,
  putScreenPowerReducer,
  putAction,
  enqueueSnackbar,
  closeSnackbar,
  onClear
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const showSnackbar = useCustomSnackbar(t, enqueueSnackbar, closeSnackbar)

  const [conflictModalOpen, setConflictModalOpen] = useState(false)

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        scheduleEnabled: Yup.boolean(),
        conditions: Yup.array()
          .test('timeOverlap', t('Schedule conflict'), value => {
            return !value.some((interval, index, array) => {
              const days = getIntervalDays(interval)
              const { timeFrom, timeTo, dayFrom, dayTo } = interval
              return array
                .filter((val, i) => i !== index)
                .some(interval => {
                  const compareDays = getIntervalDays(interval)

                  if (days.some(day => compareDays.includes(day))) {
                    const isInvalid =
                      (interval.timeFrom >= timeFrom &&
                        interval.timeFrom < timeTo) ||
                      (interval.timeTo > timeFrom &&
                        interval.timeTo <= timeTo &&
                        interval.dayFrom === dayFrom &&
                        interval.dayTo === dayTo) ||
                      (interval.timeFrom === timeFrom &&
                        interval.dayFrom === dayFrom) ||
                      (interval.timeTo === timeTo && interval.dayTo === dayTo)

                    if (isInvalid) {
                      invalidSlots.push(interval)
                    }

                    return isInvalid
                  }

                  return false
                })
            })
          })
          .of(
            Yup.object().test(function (slot) {
              const error = this.createError({
                path: `${{ this: this }.this?.path}`,
                message: 'Time ranges must not overlap'
              })
              const isInvalid = invalidSlots.includes(slot)
              return isInvalid ? error : true
            })
          )
      }),
    [t]
  )

  const {
    values,
    handleSubmit,
    handleChange,
    isValid,
    errors,
    setValues
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      const data = prepareScreenPowerData(values)
      putAction({ deviceId: device.id, data })
    }
  })

  const { scheduleEnabled, conditions } = values

  useEffect(() => {
    if (screenPowerReducer.response) {
      const { screenPowerSettings } = screenPowerReducer.response
      const preparedBackendSchedule = screenPowerSettings.screenPowerSchedule.map(
        row => {
          return {
            dayFrom: daysData[row.dayFrom],
            timeFrom: moment(row.timeFrom, TIME_S_FORMAT).format(TIME_FORMAT),
            dayTo: daysData[row.dayTo],
            timeTo: moment(row.timeTo, TIME_S_FORMAT).format(TIME_FORMAT),
            uuid: uuidv4()
          }
        }
      )

      setValues({
        scheduleEnabled: screenPowerSettings.scheduleEnabled,
        conditions: preparedBackendSchedule.length
          ? preparedBackendSchedule
          : initialConditions
      })
    }
    // eslint-disable-next-line
  }, [screenPowerReducer])

  useEffect(() => {
    if (
      !_isEmpty(putScreenPowerReducer.response) &&
      putScreenPowerReducer.response.status === 'success'
    ) {
      showSnackbar('Screen Schedule successfully saved', 'success')
      onClear()
      handleClose()
    } else if (!_isEmpty(putScreenPowerReducer.error)) {
      showSnackbar(putScreenPowerReducer.error.message, 'error')
      onClear()
    }
    // eslint-disable-next-line
  }, [putScreenPowerReducer])

  const handleAddRow = useCallback(() => {
    const newConditions = [
      ...conditions,
      {
        dayFrom: conditions[conditions.length - 1].dayFrom,
        timeFrom: conditions[conditions.length - 1].timeFrom,
        dayTo: conditions[conditions.length - 1].dayTo,
        timeTo: conditions[conditions.length - 1].timeTo,
        uuid: uuidv4()
      }
    ]

    handleChange(simulateEvent('conditions', newConditions))
  }, [conditions, handleChange])

  const handleRemoveRow = useCallback(
    index => {
      if (conditions.length > 1) {
        const newConditions = [...conditions]
        newConditions.splice(index, 1)

        handleChange(simulateEvent('conditions', newConditions))
      }
    },
    [conditions, handleChange]
  )

  const handleChangeCondition = useCallback(
    index => ({ target: { name, value } }) => {
      if (name.includes('time')) {
        const preparedTime = moment(value, AP_TIME_FORMAT).format(TIME_FORMAT)
        handleChange(simulateEvent(`conditions.${index}.${name}`, preparedTime))
      } else {
        handleChange(simulateEvent(`conditions.${index}.${name}`, value))
      }
    },
    [handleChange]
  )

  const contentRow = useCallback(
    ({ condition, index }) => {
      return (
        <div className={classes.rowWrapper}>
          <div
            className={classNames(classes.contentRow, {
              [classes.lastRow]: conditions.length - 1 === index
            })}
          >
            <Text>{t('From')}</Text>
            <FormControlReactSelect
              name="dayFrom"
              value={condition.dayFrom}
              options={daysOptions}
              onChange={handleChangeCondition(index)}
              isSort={false}
              marginBottom={0}
              formControlContainerClass={classes.daySelect}
            />
            <MaterialPopup
              on="click"
              placement="top"
              trigger={
                <FormControlInput
                  value={moment(condition.timeFrom, TIME_FORMAT).format(
                    AM_PM_FORMAT
                  )}
                  marginBottom={false}
                  readOnly
                />
              }
              hasArrow={false}
            >
              <TimePickerNew
                maskValue={TIME_FORMAT}
                name="timeFrom"
                value={moment(condition.timeFrom, TIME_FORMAT).format(
                  AM_PM_FORMAT
                )}
                onChange={handleChangeCondition(index)}
                showSeconds={false}
                rootClassName={classes.listRoot}
                noLabel
              />
            </MaterialPopup>
            <Text rootClassName={classes.toText}>{t('To')}</Text>
            <MaterialPopup
              on="click"
              placement="bottom"
              trigger={
                <FormControlInput
                  value={moment(condition.timeTo, TIME_FORMAT).format(
                    AM_PM_FORMAT
                  )}
                  marginBottom={false}
                  readOnly
                />
              }
              hasArrow={false}
            >
              <TimePickerNew
                maskValue={TIME_FORMAT}
                name="timeTo"
                value={moment(condition.timeTo, TIME_FORMAT).format(
                  AM_PM_FORMAT
                )}
                onChange={handleChangeCondition(index)}
                showSeconds={false}
                rootClassName={classes.listRoot}
                noLabel
              />
            </MaterialPopup>
            <FormControlReactSelect
              name="dayTo"
              value={condition.dayTo}
              options={daysOptions}
              onChange={handleChangeCondition(index)}
              isSort={false}
              marginBottom={0}
              formControlContainerClass={classes.daySelect}
            />

            <div className={classes.rowAction}>
              <AddIcon
                onClick={handleAddRow}
                className={classNames({
                  [classes.disabled]: conditions.length === 15
                })}
              />
              <RemoveIcon
                className={classNames({
                  [classes.hiddenButton]: conditions.length <= 1
                })}
                onClick={() => handleRemoveRow(index)}
              />
            </div>
          </div>
        </div>
      )
    },
    [
      handleAddRow,
      handleRemoveRow,
      handleChangeCondition,
      conditions.length,
      classes.contentRow,
      classes.rowAction,
      classes.hiddenButton,
      classes.disabled,
      classes.lastRow,
      classes.rowWrapper,
      classes.toText,
      classes.daySelect,
      classes.listRoot,
      t
    ]
  )

  const onCloseConflictModal = useCallback(
    () => setConflictModalOpen(false),
    []
  )

  const handleSubmitWithValidation = useCallback(() => {
    if (errors?.conditions?.length) {
      setConflictModalOpen(true)
    } else {
      handleSubmit()
    }
  }, [errors, handleSubmit])

  const errorData = useMemo(() => {
    const data = []
    if (Array.isArray(errors?.conditions)) {
      errors.conditions.forEach((error, index) => {
        if (!!error) {
          data.push(conditions[index])
        }
      })
    }

    return data
  }, [conditions, errors])

  return (
    <DialogContentLayout
      onSubmit={handleSubmitWithValidation}
      submitOpaque={!isValid}
      contentClassName={classes.layoutContent}
    >
      {tabs}
      <CheckboxSwitcher
        name="scheduleEnabled"
        value={scheduleEnabled}
        handleChange={handleChange}
        label={t('Screen Off Schedule')}
        switchContainerClass={classes.weeklyScreenContainer}
        formControlLabelClass={classes.weeklyScreenLabel}
        tooltip={t('Schedule to Turn Off Screen Power')}
      />
      <Scrollbars className={classes.scrollbarRoot}>
        <div
          className={classNames(classes.conditionsWrapper, {
            [classes.disabled]: !scheduleEnabled
          })}
        >
          {conditions.map((condition, index) => (
            <React.Fragment key={condition.uuid}>
              {contentRow({ condition, index })}
            </React.Fragment>
          ))}
        </div>
      </Scrollbars>

      <ScheduleConflictModal
        open={conflictModalOpen}
        onCloseModal={onCloseConflictModal}
        data={errorData}
      />
    </DialogContentLayout>
  )
}

export default withSnackbar(ScreenPower)
