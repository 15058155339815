import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { _get } from 'utils/lodash'

import { userDetailsSelectorMemoized } from 'selectors/userSelectors'

export default function useUserPermissionGroupsByType(permissionType) {
  const { response } = useSelector(userDetailsSelectorMemoized)

  return useMemo(() => {
    return Object.entries(_get(response, `permissions`, {}))
      .filter(([, group]) => group[permissionType])
      .map(([name]) => name)
  }, [response, permissionType])
}
