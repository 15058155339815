const companionConfig = {
  companionUrl:
    process.env.REACT_APP_UPPY_COMPANION_URL || process.env.REACT_APP_API_URL
}

export const uppyConfig = {
  autoProceed: false,
  restrictions: {
    maxFileSize: 1073741824 // 1GB
  }
}

export const awsConfig = {
  ...companionConfig
}

export const WebcamConfig = {
  order: 2
}

export const dropboxConfig = {
  ...companionConfig,
  order: 6
}

export const googleDriveConfig = {
  ...companionConfig,
  order: 4
}

export const facebookConfig = {
  ...companionConfig,
  order: 7
}

export const instagramConfig = {
  ...companionConfig,
  order: 8
}

export const oneDriveConfig = {
  ...companionConfig,
  order: 5
}

export const zoomConfig = {
  ...companionConfig,
  order: 9
}

export const urlConfig = {
  ...companionConfig,
  order: 1
}

export const screenCaptureConfig = {
  order: 3
}

export const dragDropConfig = {
  target: '#drag-drop'
}

export const thumbnailGeneratorConfig = {
  thumbnailType: 'image/png',
  thumbnailHeight: 400
}

export const getUppyLocale = t => ({
  strings: {
    browse: t('browse files'),
    dropPasteImport: t('Drop files here, browse or import from', {
      browse: '%{browse}'
    }),
    myDevice: t('My Device'),
    addMoreFiles: t('Add more files'),
    addingMoreFiles: t('Adding more files'),
    importFrom: t('Import from name', { name: '%{name}' }),
    dashboardWindowTitle: t('Uppy Dashboard Window (Press escape to close)'),
    dashboardTitle: t('Uppy Dashboard'),
    copyLinkToClipboardSuccess: t('Link copied to clipboard'),
    copyLinkToClipboardFallback: t('Copy the URL below'),
    copyLink: t('Copy link'),
    fileSource: t('File source name', { name: '%{name}' }),
    done: t('Done'),
    back: t('Back'),
    removeFile: t('Remove file'),
    editFile: t('Edit file'),
    editing: t('Editing file', { file: '%{file}' }),
    edit: t('Edit'),
    finishEditingFile: t('Finish editing file'),
    saveChanges: t('Save changes'),
    dropPaste: t('Drop files here, paste or browse', { browse: '%{browse}' }),
    dropHint: t('Drop your files here'),
    uploadComplete: t('Upload complete'),
    uploadPaused: t('Upload paused'),
    resumeUpload: t('Resume upload'),
    pauseUpload: t('Pause upload'),
    retryUpload: t('Retry upload'),
    cancelUpload: t('Cancel upload'),
    xFilesSelected: {
      0: t('count file selected', { count: 1, countMarkup: '%{smart_count}' }),
      1: t('count file selected', { count: 2, countMarkup: '%{smart_count}' })
    },
    uploadingXFiles: {
      0: t('Uploading count file', { count: 1, countMarkup: '%{smart_count}' }),
      1: t('Uploading count file', { count: 2, countMarkup: '%{smart_count}' })
    },
    processingXFiles: {
      0: t('Processing count file', {
        count: 1,
        countMarkup: '%{smart_count}'
      }),
      1: t('Processing count file', { count: 2, countMarkup: '%{smart_count}' })
    },
    uploading: t('Uploading'),
    complete: t('Complete'),

    closeModal: t('Close Modal'),
    addMore: t('Add more'),
    cancel: t('Cancel'),
    dropPasteFiles: t('Drop files here or browseFiles', {
      browseFiles: '%{browseFiles}'
    }),
    dropPasteFolders: t('Drop files here or browseFolders', {
      browseFolders: '%{browseFolders}'
    }),
    dropPasteBoth: t('Drop files here, browseFiles or browseFolders', {
      browseFiles: '%{browseFiles}',
      browseFolders: '%{browseFolders}'
    }),
    dropPasteImportFiles: t('Drop files here, browseFiles or import from', {
      browseFiles: '%{browseFiles}'
    }),
    dropPasteImportFolders: t('Drop files here, browseFolders or import from', {
      browseFolders: '%{browseFolders}'
    }),
    dropPasteImportBoth: t(
      'Drop files here, browseFiles, browseFolders or import from',
      {
        browseFiles: '%{browseFiles}',
        browseFolders: '%{browseFolders}'
      }
    ),
    importFiles: t('Import files from'),
    browseFiles: t('browse files'),
    browseFolders: t('browse folders'),
    recoveredXFiles: {
      0: t(
        'We could not fully recover count file. Please re-select it and resume the upload.',
        { count: 1, countMarkup: '%{smart_count}' }
      ),
      1: t(
        'We could not fully recover count file. Please re-select it and resume the upload.',
        { count: 2, countMarkup: '%{smart_count}' }
      )
    },
    recoveredAllFiles: t(
      'We restored all files. You can now resume the upload.'
    ),
    sessionRestored: t('Session restored'),
    reSelect: t('Re-select'),
    failedToFetch: t(
      'Unable to fetch this File from remote web server. Its format (or MIME-Type) cannot be verified. Please reformat or save file with correct MIME-type and try again'
    ),
    youCanOnlyUploadFileTypes: t('Acceptable file formats types', {
      typesMarkup: `%{types}`
    }),
    companionUnauthorizeHint: t(
      'To unauthorize to your provider account, please go to url',
      {
        providerMarkup: '%{provider}',
        urlMarkup: '%{url}'
      }
    )
  }
})
