import update from 'immutability-helper'

import * as types from '../actions'
import {
  deleteInitialState,
  shapeOfBody,
  shapeOfBodyWithMeta,
  fetchParamsInitialState,
  postInitialState
} from 'constants/initialLibraryState'
import sortConstants from 'constants/sortConstants'
import { templatePreviewItemCompare } from 'utils/createTemplate'

const initialFetchParams = {
  ...fetchParamsInitialState,
  ...sortConstants.Playlist
}

const initialState = {
  library: shapeOfBodyWithMeta,
  fetchParams: initialFetchParams,
  preference: {},
  groupItems: {},
  groupTemplates: shapeOfBodyWithMeta,
  postGroupItem: {},
  deleteGroupItem: {},
  preview: {
    id: null,
    isVisible: false,
    isLoading: false,
    response: null,
    error: null,
    key: '',
    orientation: ''
  },
  templateItem: {
    status: null,
    response: null,
    error: null
  },
  postPutTemplateItem: {
    status: null,
    response: null,
    error: null
  },
  del: deleteInitialState,
  clone: {
    ...shapeOfBody,
    label: 'clone'
  },
  share: {
    ...shapeOfBody,
    label: 'share'
  },
  approve: {
    ...shapeOfBody,
    label: 'approve'
  },
  copyTemplateItems: {
    ...shapeOfBody,
    label: 'copy'
  },
  templateBackgrounds: shapeOfBodyWithMeta,
  templateBackground: {},
  post: {},
  postBulk: postInitialState,
  put: {},
  previewItems: [],
  templateBackgroundImage: {
    response: null,
    error: null
  },
  associations: {
    ...shapeOfBodyWithMeta,
    isFetching: false
  },
  templateGallery: {
    items: shapeOfBodyWithMeta,
    item: {
      ...shapeOfBody,
      isFetching: false
    },
    copy: {
      ...shapeOfBody,
      status: '',
      isLoading: false
    },
    bulkCopy: {
      ...shapeOfBody,
      status: '',
      isLoading: false
    }
  }
}

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_TEMPLATE_ITEM:
      return update(state, {
        templateItem: {
          status: { $set: 'null' },
          response: { $set: null },
          error: { $set: null }
        },
        postPutTemplateItem: {
          status: { $set: 'null' },
          response: { $set: null },
          error: { $set: null }
        }
      })
    case types.GET_TEMPLATE_SUCCESS:
      return update(state, {
        templateItem: {
          response: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_ERROR:
      return update(state, {
        templateItem: {
          error: { $set: action.payload }
        }
      })
    case types.POST_TEMPLATE_SUCCESS:
      return update(state, {
        postPutTemplateItem: {
          response: { $set: action.payload },
          status: { $set: 'successfully' }
        }
      })
    case types.PUT_TEMPLATE_SUCCESS:
      return update(state, {
        postPutTemplateItem: {
          response: { $set: action.payload },
          status: { $set: 'successfully' }
        },
        templateItem: {
          response: { $set: action.payload },
          status: { $set: 'successfully' }
        }
      })
    case types.POST_TEMPLATE_ERROR:
      return update(state, {
        postPutTemplateItem: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.PUT_TEMPLATE_ERROR:
      return update(state, {
        templateItem: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        },
        postPutTemplateItem: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_TEMPLATE_INFO:
      return update(state, {
        templateItem: {
          status: { $set: 'null' },
          error: { $set: null }
        },
        postPutTemplateItem: {
          status: { $set: 'null' },
          error: { $set: null }
        }
      })
    case types.GET_TEMPLATE_ITEMS:
      return update(state, {
        library: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_TEMPLATE_ITEMS_SUCCESS:
      return update(state, {
        library: {
          response: { $set: action.payload.response },
          meta: {
            $set: action.payload.modifiedMeta
          }
        },
        fetchParams: { $set: action.payload.params }
      })
    case types.GET_TEMPLATE_ITEMS_ERROR:
      return update(state, {
        library: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.CLEAR_TEMPLATE_ITEMS:
      return update(state, {
        library: { $set: initialState.library }
      })
    case types.CLEAR_TEMPLATE_FETCH_PARAMS:
      return update(state, {
        fetchParams: {
          $set: initialFetchParams
        }
      })
    case types.GET_TEMPLATE_PREFERENCE_SUCCESS:
      return update(state, {
        preference: {
          response: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_PREFERENCE_ERROR:
      return update(state, {
        preference: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_TEMPLATE_PREFERENCE_ERROR:
      return update(state, {
        performance: {
          error: { $set: action.payload }
        }
      })
    case types.APPROVE_TEMPLATE_SUCCESS:
      return update(state, {
        approve: {
          response: { $set: action.payload }
        }
      })
    case types.APPROVE_TEMPLATE_ERROR:
      return update(state, {
        approve: {
          error: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_GROUP_ITEMS_SUCCESS:
      return update(state, {
        groupItems: {
          response: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_GROUP_ITEMS_ERROR:
      return update(state, {
        groupItems: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_TEMPLATE_GROUP_ITEMS_INFO:
      return update(state, {
        groupItems: { $set: {} }
      })
    case types.POST_TEMPLATE_GROUP_ITEM_SUCCESS:
      return update(state, {
        postGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.POST_TEMPLATE_GROUP_ITEM_ERROR:
      return update(state, {
        postGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_TEMPLATE_GROUP_ITEM_INFO:
      return update(state, {
        postGroupItem: { $set: {} }
      })
    case types.DELETE_TEMPLATE_GROUP_ITEM_SUCCESS:
      return update(state, {
        deleteGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_TEMPLATE_GROUP_ITEM_ERROR:
      return update(state, {
        deleteGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DELETE_TEMPLATE_GROUP_ITEM_INFO:
      return update(state, {
        deleteGroupItem: { $set: {} }
      })

    case types.DELETE_TEMPLATE_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_TEMPLATE_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })

    case types.CLONE_TEMPLATE_SUCCESS:
      return update(state, {
        clone: {
          response: { $set: action.payload }
        }
      })
    case types.CLONE_TEMPLATE_ERROR:
      return update(state, {
        clone: {
          error: { $set: action.payload }
        }
      })

    case types.SHARE_TEMPLATE_SUCCESS:
      return update(state, {
        share: {
          response: { $set: action.payload }
        }
      })
    case types.SHARE_TEMPLATE_ERROR:
      return update(state, {
        share: {
          error: { $set: action.payload }
        }
      })

    case types.DELETE_SELECTED_TEMPLATE_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_SELECTED_TEMPLATE_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })

    case types.CLEAR_TEMPLATE_RESPONSE_INFO:
      return update(state, {
        del: {
          $set: deleteInitialState
        },
        clone: {
          $set: {
            ...shapeOfBody,
            label: 'clone'
          }
        },
        share: {
          $set: {
            ...shapeOfBody,
            label: 'share'
          }
        },
        approve: {
          $set: {
            ...shapeOfBody,
            label: 'approve'
          }
        },
        copyTemplateItems: {
          $set: {
            ...shapeOfBody,
            label: 'copy'
          }
        },
        put: { $set: {} },
        post: { $set: {} }
      })
    case types.CLEAR_TEMPLATE_GROUP_ITEMS_RESPONSE_INFO:
      return update(state, {
        $merge: {
          groupItems: {},
          postGroupItem: {},
          deleteGroupItem: {}
        }
      })
    case types.GET_TEMPLATE_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: action.data.id },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: action.meta.key },
          orientation: { $set: action.data.orientation }
        }
      })
    case types.GENERATE_TEMPLATE_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: null },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: '' },
          orientation: { $set: action.data.orientation }
        }
      })
    case types.GET_TEMPLATE_PREVIEW_SUCCESS:
    case types.GENERATE_TEMPLATE_PREVIEW_SUCCESS:
      return update(state, {
        preview: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_PREVIEW_ERROR:
    case types.GENERATE_TEMPLATE_PREVIEW_ERROR:
      return update(state, {
        preview: {
          isVisible: { $set: false },
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.SHOW_TEMPLATE_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: true }
        }
      })
    case types.CLOSE_TEMPLATE_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: false }
        }
      })
    case types.CLEAR_TEMPLATE_PREVIEW:
      return update(state, {
        preview: {
          $merge: {
            id: null,
            isVisible: false,
            isLoading: false,
            response: null,
            error: null,
            key: '',
            orientation: ''
          }
        }
      })
    case types.GET_TEMPLATE_BACKGROUNDS:
    case types.GET_USERS_ITEMS:
      return update(state, {
        templateBackgrounds: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_TEMPLATE_BACKGROUNDS_SUCCESS:
      return update(state, {
        templateBackgrounds: {
          response: { $set: action.payload.data },
          meta: { $set: action.payload.meta }
        }
      })
    case types.GET_TEMPLATE_BACKGROUNDS_ERROR:
      return update(state, {
        templateBackgrounds: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.GET_TEMPLATE_BACKGROUND_SUCCESS:
      return update(state, {
        templateBackground: {
          response: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_BACKGROUND_ERROR:
      return update(state, {
        templateBackground: {
          error: { $set: action.payload }
        }
      })
    case types.POST_TEMPLATE_BACKGROUND_SUCCESS:
      return update(state, {
        post: {
          response: { $set: 'successfully' }
        }
      })
    case types.PUT_TEMPLATE_BACKGROUND_SUCCESS:
      return update(state, {
        put: {
          response: { $set: 'successfully' },
          label: { $set: 'update' }
        }
      })
    case types.POST_TEMPLATE_BACKGROUND_ERROR:
      return update(state, {
        post: { error: { $set: action.payload } }
      })
    case types.PUT_TEMPLATE_BACKGROUND_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload },
          label: { $set: 'update' }
        }
      })
    case types.GET_TEMPLATE_GROUP_TEMPLATES:
      return update(state, {
        groupTemplates: {
          $set: initialState.groupTemplates
        }
      })
    case types.GET_TEMPLATE_GROUP_TEMPLATES_SUCCESS:
      return update(state, {
        groupTemplates: {
          $set: action.payload
        }
      })
    case types.GET_TEMPLATE_ZONE_PREVIEW:
    case types.GENERATE_TEMPLATE_ITEM_PREVIEW:
    case types.GET_TEMPLATE_ITEM_PREVIEW:
      return update(state, {
        previewItems: {
          $push: [
            {
              isLoading: true,
              error: false,
              id: action.payload.id,
              type: action.payload.type,
              isZoomToFit: action.payload.isZoomToFit
            }
          ]
        }
      })
    case types.GET_TEMPLATE_ZONE_PREVIEW_SUCCESS:
    case types.GENERATE_TEMPLATE_ITEM_PREVIEW_SUCCESS:
    case types.GET_TEMPLATE_ITEM_PREVIEW_SUCCESS: {
      const index = state.previewItems.findIndex(item => {
        return templatePreviewItemCompare(item, {
          id: action.payload.id,
          type: action.payload.type,
          isZoomToFit: action.payload.isZoomToFit
        })
      })

      if (index === -1) {
        return update(state, {
          previewItems: {
            $push: [
              {
                isLoading: false,
                response: action.payload.response,
                id: action.payload.id,
                type: action.payload.type,
                isZoomToFit: action.payload.isZoomToFit
              }
            ]
          }
        })
      } else {
        return update(state, {
          previewItems: {
            [index]: {
              $set: {
                isLoading: false,
                response: action.payload.response,
                id: action.payload.id,
                type: action.payload.type,
                isZoomToFit: action.payload.isZoomToFit
              }
            }
          }
        })
      }
    }
    case types.GET_TEMPLATE_ZONE_PREVIEW_ERROR:
    case types.GENERATE_TEMPLATE_ITEM_PREVIEW_ERROR:
    case types.GET_TEMPLATE_ITEM_PREVIEW_ERROR:
      const index = state.previewItems.findIndex(item => {
        return templatePreviewItemCompare(item, {
          id: action.payload.id,
          type: action.payload.type,
          isZoomToFit: action.payload.isZoomToFit
        })
      })
      if (index !== -1) {
        return update(state, {
          previewItems: {
            [index]: {
              $set: {
                isLoading: false,
                error: true,
                id: action.payload.id,
                type: action.payload.type,
                isZoomToFit: action.payload.isZoomToFit
              }
            }
          }
        })
      } else {
        return state
      }
    case types.CLEAR_TEMPLATE_ITEMS_PREVIEW:
      return update(state, {
        previewItems: { $set: [] }
      })
    case types.CLEAR_TEMPLATE_ITEM_PREVIEW: {
      const items = state.previewItems.filter(({ id }) => id !== action.payload)
      return update(state, {
        previewItems: { $set: items }
      })
    }
    case types.COPY_TEMPLATE_SUCCESS:
      return update(state, {
        copyTemplateItems: {
          response: { $set: action.payload }
        }
      })
    case types.COPY_TEMPLATE_ERROR:
      return update(state, {
        copyTemplateItems: {
          error: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_BACKGROUND_IMAGE_SUCCESS:
      return update(state, {
        templateBackgroundImage: {
          response: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_BACKGROUND_IMAGE_ERROR:
      return update(state, {
        templateBackgroundImage: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_TEMPLATE_BACKGROUND_IMAGE:
      return update(state, {
        templateBackgroundImage: {
          response: { $set: null }
        }
      })
    case types.GET_TEMPLATE_ASSOCIATIONS:
      return update(state, {
        associations: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_TEMPLATE_ASSOCIATIONS_SUCCESS:
      return update(state, {
        associations: {
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_TEMPLATE_ASSOCIATIONS_ERROR:
      return update(state, {
        associations: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_TEMPLATE_ASSOCIATIONS:
      return update(state, {
        associations: { $set: initialState.associations }
      })
    case types.GET_TEMPLATE_GALLERY_ITEMS:
      return update(state, {
        templateGallery: {
          items: {
            response: { $set: [] },
            meta: {
              isLoading: { $set: true }
            }
          }
        }
      })
    case types.GET_TEMPLATE_GALLERY_ITEMS_SUCCESS:
      return update(state, {
        templateGallery: {
          items: {
            response: { $set: action.payload },
            meta: {
              $set: action.modifiedMeta
            }
          }
        }
      })
    case types.GET_TEMPLATE_GALLERY_ITEMS_ERROR:
      return update(state, {
        templateGallery: {
          items: {
            error: { $set: action.payload },
            meta: {
              isLoading: { $set: false }
            }
          }
        }
      })
    case types.GET_TEMPLATE_GALLERY_BY_ID:
      return update(state, {
        templateGallery: {
          item: {
            isFetching: { $set: true }
          }
        }
      })
    case types.GET_TEMPLATE_GALLERY_BY_ID_SUCCESS:
      return update(state, {
        templateGallery: {
          item: {
            response: { $set: action.payload },
            isFetching: { $set: false }
          }
        }
      })
    case types.GET_TEMPLATE_GALLERY_BY_ID_ERROR:
      return update(state, {
        templateGallery: {
          item: {
            error: { $set: action.payload },
            isFetching: { $set: false }
          }
        }
      })
    case types.CLEAR_TEMPLATE_GALLERY_ITEM_RESPONSE:
      return update(state, {
        templateGallery: {
          item: {
            $set: {
              ...shapeOfBody,
              isFetching: false
            }
          }
        }
      })
    case types.GET_TEMPLATE_GALLERY_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: action.data.id },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          orientation: { $set: action.data.orientation }
        }
      })
    case types.GET_TEMPLATE_GALLERY_PREVIEW_SUCCESS:
      return update(state, {
        preview: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_TEMPLATE_GALLERY_PREVIEW_ERROR:
      return update(state, {
        preview: {
          isVisible: { $set: false },
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.COPY_TEMPLATE_GALLERY:
      return update(state, {
        templateGallery: {
          copy: {
            isLoading: { $set: true }
          }
        }
      })
    case types.COPY_TEMPLATE_GALLERY_SUCCESS:
      return update(state, {
        templateGallery: {
          copy: {
            response: { $set: action.payload },
            status: { $set: 'successfully' },
            isLoading: { $set: false }
          }
        }
      })
    case types.COPY_TEMPLATE_GALLERY_ERROR:
      return update(state, {
        templateGallery: {
          copy: {
            error: { $set: action.payload },
            status: { $set: 'error' },
            isLoading: { $set: false }
          }
        }
      })
    case types.BULK_TEMPLATE_GALLERY_COPY:
      return update(state, {
        templateGallery: {
          bulkCopy: {
            isLoading: { $set: true }
          }
        }
      })
    case types.BULK_TEMPLATE_GALLERY_COPY_SUCCESS:
      return update(state, {
        templateGallery: {
          bulkCopy: {
            response: { $set: action.payload },
            status: { $set: 'success' },
            isLoading: { $set: false }
          }
        }
      })
    case types.BULK_TEMPLATE_GALLERY_COPY_ERROR:
      return update(state, {
        templateGallery: {
          bulkCopy: {
            error: { $set: action.payload },
            status: { $set: 'error' },
            isLoading: { $set: false }
          }
        }
      })
    case types.CLEAR_TEMPLATE_GALLERY_INFO:
      return update(state, {
        templateGallery: {
          copy: {
            $set: {
              ...shapeOfBody,
              status: ''
            }
          },
          bulkCopy: {
            $set: {
              ...shapeOfBody,
              status: ''
            }
          }
        }
      })
    case types.POST_TEMPLATE_BULK_SUCCESS:
      return update(state, {
        postBulk: {
          response: { $set: action.payload }
        }
      })
    case types.POST_TEMPLATE_BULK_ERROR:
      return update(state, {
        postBulk: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_TEMPLATE_BULK_INFO:
      return update(state, {
        postBulk: {
          $set: postInitialState
        }
      })
    default:
      return state
  }
}

export default templateReducer
