import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getConfigResellerRole } from 'actions/configActions'
import { _isEmpty } from 'utils/lodash'

const useResellerRoleOptions = () => {
  const dispatch = useDispatch()

  const roles = useSelector(
    ({
      config: {
        resellerRole: { response: resellerRoles }
      }
    }) => resellerRoles
  ).map(({ displayName: label, id: value, color }) => ({ label, value, color }))

  useEffect(() => {
    if (_isEmpty(roles)) dispatch(getConfigResellerRole())
    // eslint-disable-next-line
  }, [])

  return roles
}

export default useResellerRoleOptions
