import update from 'immutability-helper'

import * as types from '../actions'
import {
  deleteInitialState,
  shapeOfBody,
  shapeOfBodyWithMeta,
  postInitialState,
  shapeOfMeta,
  putInitialState
} from 'constants/initialLibraryState'
import { rowDateFormatter } from 'utils/reportRowUtils'

const initialState = {
  library: shapeOfBodyWithMeta,
  del: deleteInitialState,
  clone: {
    ...shapeOfBody,
    label: 'clone'
  },
  preference: {},
  groups: {},
  groupItems: {},
  postGroupItem: {},
  deleteGroupItem: {},
  tables: shapeOfBody,
  reportData: {
    ...shapeOfBody,
    meta: { isLoading: false, isUninitialized: true }
  },
  gridData: [],
  gridFilters: {},
  item: {
    ...shapeOfBody,
    response: null
  },
  templateState: null,
  post: postInitialState,
  put: putInitialState,
  schedule: {
    ...shapeOfBody,
    isFetching: true
  },
  schedulePost: postInitialState,
  countApiCallData: {
    ...shapeOfBody,
    meta: { range: null, ...shapeOfMeta }
  },
  lastFailedApiCallData: {
    ...shapeOfBody,
    meta: { range: null, ...shapeOfMeta }
  },
  topClientsApiCallData: {
    ...shapeOfBody,
    meta: { range: null, ...shapeOfMeta }
  },
  topDevicesApiCallData: {
    ...shapeOfBody,
    meta: { range: null, ...shapeOfMeta }
  },
  reportLink: {
    response: '',
    error: {}
  },
  countData: {
    ...shapeOfBody,
    meta: { range: null, ...shapeOfMeta }
  },
  exposureTimeData: {
    ...shapeOfBody,
    meta: { range: null, ...shapeOfMeta }
  },
  verboseData: {
    ...shapeOfBody,
    meta: { range: null, ...shapeOfMeta }
  }
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_REPORT_SUCCESS:
      return update(state, {
        post: {
          response: { $set: action.payload }
        }
      })
    case types.POST_REPORT_ERROR:
      return update(state, {
        post: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.PUT_REPORT_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_REPORT_ERROR:
      return update(state, {
        put: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.GET_REPORT_ITEMS:
      return update(state, {
        library: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_REPORT_ITEMS_SUCCESS:
      return update(state, {
        library: {
          response: { $set: action.response },
          meta: {
            $set: action.modifiedMeta
          }
        }
      })
    case types.GET_REPORT_ITEMS_ERROR:
      return update(state, {
        library: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })

    case types.DELETE_SELECTED_REPORTS_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_SELECTED_REPORTS_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_REPORT_RESPONSE_INFO:
      return update(state, {
        del: {
          $set: deleteInitialState
        },
        clone: {
          $set: {
            ...shapeOfBody,
            label: 'clone'
          }
        },
        post: { $set: postInitialState },
        put: { $set: putInitialState },
        schedulePost: { $set: postInitialState },
        schedule: {
          $set: {
            ...initialState.schedule
          }
        }
      })
    case types.GET_REPORT_TABLES_SUCCESS:
      return update(state, {
        tables: {
          $set: action.payload
        }
      })
    case types.SET_REPORT_TEMPLATE_STATE:
      return update(state, {
        templateState: {
          $set: action.payload
        }
      })
    case types.GET_REPORT_TEMPLATE_SUCCESS:
      return update(state, {
        item: {
          $set: action.payload
        }
      })
    case types.GET_TEMPLATE_WITH_REPORT_DATA_ERROR:
    case types.POST_RUN_REPORT_ERROR:
      return update(state, {
        reportData: {
          response: { $set: [] },
          meta: {
            isLoading: { $set: false },
            isUninitialized: { $set: false }
          }
        }
      })
    case types.GET_REPORT_ITEM:
    case types.POST_RUN_REPORT:
    case types.GET_TEMPLATE_WITH_REPORT_DATA:
      return update(state, {
        reportData: {
          response: { $set: [] },
          meta: {
            isLoading: { $set: true },
            isUninitialized: { $set: false }
          }
        }
      })
    case types.GET_REPORT_ITEM_ERROR:
      return update(state, {
        reportData: {
          meta: {
            isLoading: { $set: false },
            isUninitialized: { $set: false }
          }
        }
      })
    case types.POST_RUN_REPORT_SUCCESS:
    case types.GET_REPORT_ITEM_SUCCESS:
    case types.GET_TEMPLATE_WITH_REPORT_DATA_SUCCESS:
      const fieldKeys = Object.keys(action.payload.response.fields[0])
      const gridFilters = state.gridFilters.groups
        ? state.gridFilters
        : {
            groupBy: '',
            rowCounts: false,
            detailRows: false,
            subtotals: false,
            grandTotal: false,
            groups: {
              All: {
                orderBy: fieldKeys[0],
                order: 'asc',
                hidden: []
              }
            }
          }

      return update(state, {
        reportData: {
          $set: {
            response: {
              ...action.payload.response,
              fields: action.payload.response.fields.map(row =>
                rowDateFormatter(row)
              )
            },
            meta: { isLoading: false, isUninitialized: false }
          }
        },
        gridFilters: {
          $set: gridFilters
        }
      })
    case types.SET_REPORT_ITEM_SORTING: {
      const { sort, order, groupName } = action.payload
      return update(state, {
        gridFilters: {
          groups: {
            [groupName]: { $merge: { orderBy: sort, order } }
          }
        }
      })
    }
    case types.DELETE_REPORT_COLUMN: {
      const { groupName, field } = action.payload
      return update(state, {
        gridFilters: {
          groups: {
            [groupName]: {
              hidden: { $push: [field] }
            }
          }
        }
      })
    }
    case types.GROUP_REPORT_BY_FIELD: {
      const { field } = action.payload
      const orderBy = Object.keys(state.reportData.response.fields[0])[0]
      const groups = {}
      state.reportData.response.fields.forEach(item => {
        groups[item[field]] = {
          orderBy,
          order: 'asc',
          hidden: []
        }
      }, {})
      return update(state, {
        gridFilters: {
          groupBy: { $set: field },
          groups: { $set: groups }
        }
      })
    }

    case types.UPDATE_REPORT_FILTER: {
      const { name, value } = action.payload

      return update(state, {
        gridFilters: {
          [name]: { $set: value }
        }
      })
    }

    case types.RESET_REPORT_FILTER: {
      const { orderBy } = action.payload

      return update(state, {
        gridFilters: {
          $set: {
            groupBy: '',
            rowCounts: false,
            detailRows: false,
            subtotals: false,
            grandTotal: false,
            groups: {
              All: {
                orderBy,
                order: 'asc',
                hidden: []
              }
            }
          }
        }
      })
    }

    case types.GET_COUNT_CLIENT_API_CALL:
    case types.GET_COUNT_DEVICE_API_CALL:
    case types.GET_COUNT_API_CALL: {
      const params = action.params
      return update(state, {
        countApiCallData: {
          $set: {
            meta: {
              range: params?.range,
              isLoading: true
            }
          }
        }
      })
    }

    case types.GET_COUNT_CLIENT_API_CALL_SUCCESS:
    case types.GET_COUNT_DEVICE_API_CALL_SUCCESS:
    case types.GET_COUNT_API_CALL_SUCCESS: {
      const { response, status, params } = action.payload
      return update(state, {
        countApiCallData: {
          $set: {
            response,
            status,
            meta: {
              range: params?.range,
              isLoading: false,
              count: response.length
            }
          }
        }
      })
    }

    case types.GET_COUNT_CLIENT_API_CALL_ERROR:
    case types.GET_COUNT_DEVICE_API_CALL_ERROR:
    case types.GET_COUNT_API_CALL_ERROR: {
      const { error, params } = action.payload
      return update(state, {
        countApiCallData: {
          $set: {
            error,
            meta: {
              range: params?.range,
              isLoading: false
            }
          }
        }
      })
    }

    case types.GET_LAST_FAILED_API_CALL: {
      const { params } = action
      return update(state, {
        lastFailedApiCallData: {
          $set: {
            meta: {
              range: params?.range,
              isLoading: true
            }
          }
        }
      })
    }

    case types.GET_LAST_FAILED_API_CALL_SUCCESS: {
      const { response, status, params } = action.payload
      return update(state, {
        lastFailedApiCallData: {
          $set: {
            response,
            status,
            meta: {
              range: params?.range,
              isLoading: false,
              count: response.length
            }
          }
        }
      })
    }

    case types.GET_LAST_FAILED_API_CALL_ERROR: {
      const { error, params } = action.payload
      return update(state, {
        lastFailedApiCallData: {
          $set: {
            error,
            meta: {
              range: params?.range,
              isLoading: false
            }
          }
        }
      })
    }

    case types.GET_TOP_CLIENTS_API_CALL: {
      const { params } = action
      return update(state, {
        topClientsApiCallData: {
          $set: {
            meta: {
              range: params?.range,
              isLoading: true
            }
          }
        }
      })
    }

    case types.GET_TOP_CLIENTS_API_CALL_SUCCESS: {
      const { response, status, params } = action.payload
      return update(state, {
        topClientsApiCallData: {
          $set: {
            response,
            status,
            meta: {
              range: params?.range,
              isLoading: false,
              count: response.length
            }
          }
        }
      })
    }

    case types.GET_TOP_CLIENTS_API_CALL_ERROR: {
      const { error, params } = action.payload
      return update(state, {
        topClientsApiCallData: {
          $set: {
            error,
            meta: {
              range: params?.range,
              isLoading: false
            }
          }
        }
      })
    }

    case types.GET_TOP_DEVICES_API_CALL: {
      const { params } = action
      return update(state, {
        topDevicesApiCallData: {
          $set: {
            meta: {
              range: params?.range,
              isLoading: true
            }
          }
        }
      })
    }

    case types.GET_TOP_DEVICES_API_CALL_SUCCESS: {
      const { response, status, params } = action.payload
      return update(state, {
        topDevicesApiCallData: {
          $set: {
            response,
            status,
            meta: {
              range: params?.range,
              isLoading: false,
              count: response.length
            }
          }
        }
      })
    }

    case types.GET_TOP_DEVICES_API_CALL_ERROR: {
      const { error, params } = action.payload
      return update(state, {
        topDevicesApiCallData: {
          $set: {
            error,
            meta: {
              range: params?.range,
              isLoading: false
            }
          }
        }
      })
    }

    case types.GET_REPORT_SCHEDULE:
      return update(state, {
        schedule: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_REPORT_SCHEDULE_SUCCESS:
      return update(state, {
        schedule: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_REPORT_SCHEDULE_ERROR:
      return update(state, {
        schedule: {
          response: { $set: [] },
          isFetching: { $set: false }
        }
      })

    case types.POST_REPORT_SCHEDULE_SUCCESS:
      return update(state, {
        schedulePost: {
          response: { $set: action.payload }
        }
      })
    case types.POST_REPORT_SCHEDULE_ERROR:
      return update(state, {
        schedulePost: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        },
        schedule: {
          $set: {
            ...shapeOfBody,
            isFetching: false
          }
        }
      })

    case types.GET_REPORT_LINK_SUCCESS:
      return update(state, {
        reportLink: {
          response: { $set: action.payload }
        }
      })
    case types.GET_REPORT_LINK_ERROR:
      return update(state, {
        reportLink: {
          response: { $set: initialState.reportLink.response },
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_REPORT_LINK:
      return update(state, {
        reportLink: { $set: initialState.reportLink }
      })
    case types.GET_EXPOSURE_COUNT: {
      const params = action.params
      return update(state, {
        countData: {
          $set: {
            meta: {
              range: params?.range,
              isLoading: true
            }
          }
        }
      })
    }
    case types.GET_EXPOSURE_COUNT_SUCCESS: {
      const { response, status, params } = action.payload
      return update(state, {
        countData: {
          $set: {
            response,
            status,
            meta: {
              range: params?.range,
              isLoading: false,
              count: response.length
            }
          }
        }
      })
    }
    case types.GET_EXPOSURE_COUNT_ERROR: {
      const { error, params } = action.payload
      return update(state, {
        countData: {
          $set: {
            error,
            meta: {
              range: params?.range,
              isLoading: false
            }
          }
        }
      })
    }
    case types.GET_EXPOSURE_TIME: {
      const params = action.params
      return update(state, {
        exposureTimeData: {
          $set: {
            meta: {
              range: params?.range,
              isLoading: true
            }
          }
        }
      })
    }
    case types.GET_EXPOSURE_TIME_SUCCESS: {
      const { response, status, params } = action.payload
      return update(state, {
        exposureTimeData: {
          $set: {
            response,
            status,
            meta: {
              range: params?.range,
              isLoading: false,
              count: response.length
            }
          }
        }
      })
    }
    case types.GET_EXPOSURE_TIME_ERROR: {
      const { error, params } = action.payload
      return update(state, {
        exposureTimeData: {
          $set: {
            error,
            meta: {
              range: params?.range,
              isLoading: false
            }
          }
        }
      })
    }
    case types.GET_VERBOSE_DATA: {
      const params = action.params
      return update(state, {
        verboseData: {
          $set: {
            meta: {
              range: params?.range,
              isLoading: true
            }
          }
        }
      })
    }
    case types.GET_VERBOSE_DATA_SUCCESS: {
      const { response, status, params } = action.payload
      return update(state, {
        verboseData: {
          $set: {
            response,
            status,
            meta: {
              range: params?.range,
              isLoading: false,
              count: response.length
            }
          }
        }
      })
    }
    case types.GET_VERBOSE_DATA_ERROR: {
      const { error, params } = action.payload
      return update(state, {
        verboseData: {
          $set: {
            error,
            meta: {
              range: params?.range,
              isLoading: false
            }
          }
        }
      })
    }
    default:
      return state
  }
}

export default reportReducer
