import React, { memo } from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

function styles({ palette, type }) {
  return {
    passwordVisibility: {
      color: palette[type].formControls.input.color
    }
  }
}

function createAttrs(className, onClick) {
  return {
    className,
    onClick
  }
}

function PasswordVisibilityButton({ onClick, classes, isVisible }) {
  const attrs = createAttrs(classes.passwordVisibility, onClick)

  if (isVisible) {
    return (
      <IconButton {...attrs} tabIndex="-1">
        <Visibility fontSize="small" />
      </IconButton>
    )
  }

  return (
    <IconButton {...attrs} tabIndex="-1">
      <VisibilityOff fontSize="small" />
    </IconButton>
  )
}

export default memo(withStyles(styles)(PasswordVisibilityButton))
