import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DeviceNameViewCell from '../DeviceTable/DeviceNameViewCell'
import { useUserRole } from 'hooks/tableLibrary'
import DeviceTypeIconCell from '../DeviceTable/DeviceTypeIconCell'

const useStyles = makeStyles(({ typography, type, spacing }) => ({
  aliasWrap: {
    display: 'flex'
  },
  textWrap: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  label: {
    ...typography.subtitle[type]
  },
  deviceNameWrap: {
    display: 'flex',
    width: 'max-content'
  },
  typeIconWrapper: {
    padding: spacing(0, 1, 0, 0)
  }
}))

const DeviceNameViewAgCell = ({ data }) => {
  const classes = useStyles()
  const role = useUserRole()

  return (
    <>
      <DeviceTypeIconCell
        item={data}
        isTableLibraryCell={false}
        wrapperClass={classes.typeIconWrapper}
      />
      <Grid container direction="column">
        <DeviceNameViewCell
          role={role}
          row={data}
          classes={classes}
          notes={data.totalNote}
        />
      </Grid>
    </>
  )
}

export default DeviceNameViewAgCell
