import { isFeatureAvailable } from 'utils/api/featureAvailability'
import { BRANDING_FEATURE } from 'constants/featureConstants'

export const FLASH_DURATION = 700
const BORDER_WIDTH = 12
const INNER_BORDER_WIDTH = 2
export const ADDITIONAL_SIZE = (BORDER_WIDTH + INNER_BORDER_WIDTH) * 2

export const landscapeDefaultDimensions = {
  width: 1440 + ADDITIONAL_SIZE,
  height: 810 + ADDITIONAL_SIZE
}
export const portraitDefaultDimensions = {
  width: 450 + ADDITIONAL_SIZE,
  height: 800 + ADDITIONAL_SIZE
}
export const devicePreviewLandscapeSize = {
  width: 640 + ADDITIONAL_SIZE,
  height: 360 + ADDITIONAL_SIZE
}
export const devicePreviewPortraitSize = {
  width: 360 + ADDITIONAL_SIZE,
  height: 640 + ADDITIONAL_SIZE
}

export const SETTINGS_WIDTH = isFeatureAvailable(BRANDING_FEATURE) ? 410 : 300
