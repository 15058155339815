import React from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { TableLibraryCell } from './index'
import { useUserRole } from 'hooks/tableLibrary'
import { zIndexes } from 'constants/stylesConstants'

const useStyles = makeStyles(({ palette, type }) => ({
  sticky: {
    position: 'sticky',
    background: palette[type].tableLibrary.head.background,
    right: 0,
    zIndex: zIndexes.elevated
  }
}))

const TableLibraryActionsCell = ({ children, ...props }) => {
  const classes = useStyles()
  const role = useUserRole()

  const withHorizontalScroll = role.system
  return (
    <TableLibraryCell
      className={classNames('TableLibraryActionsCellClass', {
        [classes.sticky]: withHorizontalScroll
      })}
      {...props}
    >
      {children}
    </TableLibraryCell>
  )
}

export default TableLibraryActionsCell
