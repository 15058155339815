import moment from 'moment'
import { _get } from 'utils/lodash'
import { labelToSec } from '../secToLabel'
import { mbToSize } from 'utils'

export function parseLogTime(logTime) {
  return logTime ? moment(logTime).format('MM/DD/YYYY hh:mmA') : 'N/A'
}

export const parseGraphData = (graphData, dataPath = 'data') => {
  const result = []
  let date = moment().subtract(1, 'day').format('YYYY-MM-DD')
  _get(graphData, dataPath, []).forEach(({ time, value }, index, arr) => {
    if (index > 1 && labelToSec(time) < labelToSec(arr[index - 1].time)) {
      date = moment(date, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
    }
    result.push({ x: `${date} ${time}`, y: value })
  })
  return result
}

export function dataToNetwork({
  networkConnectionType,
  networkName,
  networkGateway,
  networkDNS1,
  networkDNS2,
  connectivityGraph,
  downloadSpeed,
  uploadSpeed,
  deviceWifiStrength,
  synchronousConnection,
  logTime,
  networkSpeed
}) {
  return {
    networkConnectionType,
    networkName,
    networkGateway,
    networkDNS1,
    networkDNS2,
    connectivityGraph,
    downloadSpeed,
    uploadSpeed,
    deviceWifiStrength,
    synchronousConnection,
    lastUpdate: parseLogTime(logTime),
    networkSpeed
  }
}

export function dataToCPU({
  processorBaseSpeed,
  logicalProcessors,
  processorCores,
  processorClockSpeed,
  logTime,
  processorLoad,
  processorTemperature,
  loadGraph,
  temperatureGraph
}) {
  return {
    processorBaseSpeed,
    logicalProcessors,
    processorCores,
    processorClockSpeed,
    processorLoad,
    processorTemperature,
    lastUpdate: parseLogTime(logTime),
    loadGraph: [
      {
        id: 'load',
        color: 'blue',
        data: parseGraphData(loadGraph)
      }
    ],
    temperatureGraph: [
      {
        id: 'temperature',
        color: 'blue',
        data: parseGraphData(temperatureGraph)
      }
    ]
  }
}

export function calculatePercentageForGraph(
  utilizationData = {},
  graphData,
  dataPath = 'data'
) {
  const { freeSpace = 0, usageSpace = 0 } = utilizationData || {}
  const totalSpaceInGb = Number(
    mbToSize(freeSpace + usageSpace, 'GB', 2, false)
  )

  const items = totalSpaceInGb ? _get(graphData, dataPath, []) : []

  const data = items.map(item => {
    const value = ((item.value || 0) * 100) / totalSpaceInGb

    return {
      ...item,
      value: value > 100 ? 100 : value
    }
  })

  return parseGraphData({ data })
}

export function dataToMemory({
  ssdReadSpeed,
  ssdWriteSpeed,
  storageUtilization,
  memoryUtilization,
  logTime,
  memoryGraph,
  storageGraph
}) {
  return {
    ssdReadSpeed,
    ssdWriteSpeed,
    storageUtilization,
    memoryUtilization,
    lastUpdate: parseLogTime(logTime),
    memoryGraph: [
      {
        id: 'memory',
        color: 'blue',
        data: calculatePercentageForGraph(memoryUtilization, memoryGraph)
      }
    ],
    storageGraph: [
      {
        id: 'storage',
        color: 'blue',
        data: calculatePercentageForGraph(storageUtilization, storageGraph)
      }
    ]
  }
}
