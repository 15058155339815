import update from 'immutability-helper'

import {
  GET_OAUTH2_TOKENS,
  GET_OAUTH2_TOKENS_SUCCESS,
  GET_OAUTH2_TOKENS_ERROR,
  GET_OAUTH2_TOKENS_BY_FEATURE,
  GET_OAUTH2_TOKENS_BY_FEATURE_SUCCESS,
  GET_OAUTH2_TOKENS_BY_FEATURE_ERROR,
  POST_OAUTH2_TOKEN,
  POST_OAUTH2_TOKEN_SUCCESS,
  POST_OAUTH2_TOKEN_ERROR,
  DELETE_OAUTH2_TOKEN,
  DELETE_OAUTH2_TOKEN_SUCCESS,
  DELETE_OAUTH2_TOKEN_ERROR,
  CLEAR_OAUTH2_TOKEN_INFO
} from 'actions/new/oauth2'
import {
  deleteInitialState,
  postInitialState,
  shapeOfBodyWithMeta
} from 'constants/initialLibraryState'
import { oauth2Features } from 'constants/featureConstants'

const initialState = {
  isLoading: false,
  tokens: shapeOfBodyWithMeta,
  tokensByFeature: Object.values(oauth2Features).reduce(
    (acc, next) => ({ ...acc, [next]: shapeOfBodyWithMeta }),
    {}
  ),
  post: {
    ...postInitialState,
    isLoading: false,
    meta: {}
  },
  del: {
    ...deleteInitialState,
    isLoading: false
  }
}

export default function oauth2Reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OAUTH2_TOKENS:
      return update(state, {
        isLoading: { $set: true },
        tokens: {
          error: { $set: {} }
        }
      })
    case GET_OAUTH2_TOKENS_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        tokens: {
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta }
        }
      })
    case GET_OAUTH2_TOKENS_ERROR:
      return update(state, {
        isLoading: { $set: false },
        tokens: {
          error: { $set: action.payload }
        }
      })
    case GET_OAUTH2_TOKENS_BY_FEATURE:
      return update(state, {
        isLoading: { $set: true },
        tokensByFeature: {
          [action.meta.oauth2Feature]: { error: { $set: {} } }
        }
      })
    case GET_OAUTH2_TOKENS_BY_FEATURE_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        tokensByFeature: {
          [action.meta.oauth2Feature]: {
            response: { $set: action.payload.response },
            meta: { $set: action.payload.meta }
          }
        }
      })
    case GET_OAUTH2_TOKENS_BY_FEATURE_ERROR:
      return update(state, {
        isLoading: { $set: false },
        tokensByFeature: {
          [action.meta.oauth2Feature]: { error: { $set: action.payload } }
        }
      })
    case POST_OAUTH2_TOKEN:
      return update(state, {
        post: {
          isLoading: { $set: true },
          error: { $set: {} }
        }
      })
    case POST_OAUTH2_TOKEN_SUCCESS:
      return update(state, {
        post: {
          isLoading: { $set: false },
          response: { $set: action.payload },
          meta: { $set: action.meta }
        }
      })
    case POST_OAUTH2_TOKEN_ERROR:
      return update(state, {
        post: {
          isLoading: { $set: false },
          error: { $set: action.payload },
          meta: { $set: action.meta }
        }
      })
    case DELETE_OAUTH2_TOKEN:
      return update(state, {
        del: {
          isLoading: { $set: true },
          error: { $set: {} }
        }
      })
    case DELETE_OAUTH2_TOKEN_SUCCESS:
      return update(state, {
        del: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case DELETE_OAUTH2_TOKEN_ERROR:
      return update(state, {
        del: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case CLEAR_OAUTH2_TOKEN_INFO:
      return update(state, {
        post: { $set: initialState.post },
        del: { $set: initialState.del }
      })
    default:
      return state
  }
}
