export const API_URL = process.env.REACT_APP_API_URL
export const APP_BASE_URL = process.env.REACT_APP_BASE_URL
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const CANVA_URL = process.env.REACT_APP_CANVA_URL
export const MOCK_API_URL = 'https://demo9384351.mockable.io/'
export const MOCK_API_URL_2 = 'https://demo7677119.mockable.io/'
export const IP_API_URL = 'https://api.ipify.org/'
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
export const ENABLE_LEGACY_THEMES = process.env.REACT_APP_ENABLE_LEGACY_THEMES
