import {
  DROPBOX_LOGOUT_URL,
  GOOGLE_DRIVE_LOGOUT_URL,
  ONEDRIVE_LOGOUT_URL
} from './urls'

export const chunkSize = 20971520

export const pluginImageEditorOpts = {
  id: 'FilerobotImageEditor',
  title: 'Image Editor',
  type: 'editor',
  defaultConfig: {
    /* More settings options are available here https://www.npmjs.com/package/filerobot-image-editor/v/3.12.7#configuration */
    language: 'en',
    tools: [
      'adjust',
      'effects',
      'filters',
      'rotate',
      'crop',
      'resize',
      'shapes',
      'text'
    ]
  }
}

export const logoutUrlsByProvider = {
  Dropbox: DROPBOX_LOGOUT_URL,
  OneDrive: ONEDRIVE_LOGOUT_URL,
  GoogleDrive: GOOGLE_DRIVE_LOGOUT_URL
}

export const uppyEventNames = {
  filesSaved: 'filesSaved',
  resetClick: 'resetClick',
  restrictionFailed: 'restriction-failed',
  upload: 'upload',
  error: 'error',
  complete: 'complete',
  fileRemoved: 'file-removed',
  imageEditorComplete: 'image-editor:complete',
  infoVisible: 'info-visible'
}

export const uppyPlugins = {
  Dashboard: 'Dashboard',
  DragDrop: 'DragDrop',
  Webcam: 'Webcam',
  ScreenCapture: 'ScreenCapture',
  Dropbox: 'Dropbox',
  GoogleDrive: 'GoogleDrive',
  OneDrive: 'OneDrive',
  Url: 'Url',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  FilerobotImageEditor: 'FilerobotImageEditor'
}

export const uppyCoreVersion = '3.2.0'
