import _isNull from 'lodash/isNull'
import moment from 'moment'
import momentTZ from 'moment-timezone'

import {
  DATE_TIME_VIEW_FORMAT,
  DATE_VIEW_FORMAT
} from 'constants/dateTimeFormats'
import { convertLogToLocalTime } from './reportUtils'

//separate report utils for reducing bundle size ( used in report reducer )

export const datetimeFields = [
  'loginDate',
  'time',
  'loginTimestamp',
  'connectTime',
  'logTime',
  'activationDate',
  'startDate',
  'endDate',
  'startTime',
  'endTime',
  'duration'
]

export const getFormat = field => {
  switch (field) {
    case 'connectTime':
    case 'logTime':
      return {
        input: 'YYYY-MM-DD HH:mm:ss',
        output: DATE_TIME_VIEW_FORMAT
      }
    case 'activationDate':
    case 'startDate':
    case 'endDate':
      return {
        input: 'YYYY-MM-DD',
        output: DATE_VIEW_FORMAT
      }
    case 'loginTimestamp':
      return {
        input: 'HH:mm:ss',
        output: 'h:mmA'
      }
    case 'startTime':
    case 'endTime':
      return {
        input: 'HH:mm:ss',
        output: 'h:mm:ssA'
      }
    case 'loginDate':
      return {
        input: 'DD-MM-YYYY',
        output: DATE_VIEW_FORMAT
      }
    case 'duration':
      return {
        input: 'HH:mm:ss',
        output: 'HH:mm:ss'
      }
    default:
      return {
        input: '',
        output: DATE_TIME_VIEW_FORMAT
      }
  }
}

export const rowDateFormatter = item => {
  const result = { ...item }
  const logValue = result['log']

  if (logValue && !_isNull(logValue)) {
    result['log'] = convertLogToLocalTime(logValue)
  }

  datetimeFields.forEach(field => {
    const value = result[field]
    const format = getFormat(field)
    if (value && !_isNull(value)) {
      const unixTime = moment.utc(value, format.input).toISOString()
      const tz = momentTZ.tz(momentTZ.tz.guess()).zoneAbbr()
      result[field] =
        field === 'loginTimestamp'
          ? unixTime
            ? `${moment(unixTime).format(format.output)} ${tz}`
            : 'N/A'
          : moment(value, format.input).format(format.output)
    }
  })

  return result
}
export function splitAndCapitalizeCamelCase(str) {
  if (!str) {
    return ''
  }
  let words = str.match(/^[a-z]+|[A-Z][a-z]*|[0-9]+|[^a-zA-Z0-9]+/g)
  if (!words) {
    return str
  }
  let capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )

  return capitalizedWords.join(' ')
}
