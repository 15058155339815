import _uniqBy from 'lodash/uniqBy'

import {
  defaultFeatureSettingsByFeatureName,
  defaultFeatureSettings,
  defaultVideowallSettings,
  defaultZoneRestrictionSettings,
  featureNames,
  VIDEO_WALL_FEATURE_ID,
  ZONE_RESTRICTION_FEATURE_ID,
  videowallDisplaysToMatrix,
  VIDEOWALL_MATRIX_DELIMITER
} from 'constants/featureConstants'

export const parseFeatureSetting = featureSetting => {
  switch (featureSetting.id) {
    case VIDEO_WALL_FEATURE_ID:
      if (!featureSetting.settings?.number_of_displays) {
        return defaultVideowallSettings
      }
      return featureSetting
    case ZONE_RESTRICTION_FEATURE_ID:
      if (!featureSetting.settings?.number_of_zones) {
        return defaultZoneRestrictionSettings
      }
      return featureSetting
    default:
      return featureSetting
  }
}

export const isFeatureAvailable = (featureName, userDetailsResponse) => {
  const features = userDetailsResponse?.client?.feature || []

  return features.some(({ name }) => name === featureName)
}

export const updateFeatureSettings = (featureSettings = [], featureSetting) => {
  if (!featureSetting?.id) {
    return featureSettings
  }
  return [
    ...featureSettings.filter(setting => setting.id !== featureSetting.id),
    featureSetting
  ]
}

export const getFeatureByFeatureName = (features = [], featureName) => {
  return features.find(({ name }) => name === featureName)
}

export const getFeatureSettingByFeatureName = (
  features = [],
  featureSettings,
  featureName
) => {
  const feature = getFeatureByFeatureName(features, featureName)

  if (
    !feature ||
    !defaultFeatureSettingsByFeatureName.hasOwnProperty(featureName)
  ) {
    return
  }

  const featureSetting =
    featureSettings.find(({ id }) => id === feature.id) || {}

  if (
    featureName === featureNames.ContentApproval &&
    Array.isArray(featureSetting?.settings)
  ) {
    return {
      id: feature.id,
      settings: {
        ...defaultFeatureSettingsByFeatureName[featureName].settings,
        emails: featureSetting.settings
      }
    }
  }

  return {
    ...featureSetting,
    id: feature.id,
    settings: {
      ...defaultFeatureSettingsByFeatureName[featureName].settings,
      ...(featureSetting?.settings || {})
    }
  }
}

export const getVideowallDisplaysFromMatrix = video_wall_matrix => {
  try {
    return video_wall_matrix
      .split(VIDEOWALL_MATRIX_DELIMITER)
      .reduce((acc, next) => parseInt(acc) * parseInt(next))
  } catch (e) {}
}

export const getVideowallDisplaysFromSettingValues = settings =>
  settings?.video_wall_matrix
    ? getVideowallDisplaysFromMatrix(settings.video_wall_matrix)
    : settings?.number_of_displays

export const normalizeVideowallSettingValues = settings => {
  const { number_of_displays, video_wall_matrix } = settings

  if (!number_of_displays && !video_wall_matrix) {
    return defaultFeatureSettings[featureNames.Videowall].settings
  }

  if (!video_wall_matrix) {
    return {
      number_of_displays,
      video_wall_matrix:
        videowallDisplaysToMatrix[number_of_displays] ||
        `1${VIDEOWALL_MATRIX_DELIMITER}1`
    }
  }

  return settings
}

const normalizeFeatureSettingsValuesByFeature = (feature, settings) => {
  switch (feature.name) {
    case featureNames.Videowall:
      return normalizeVideowallSettingValues(settings)
    default:
      return settings
  }
}

export const getFeatureSetting = (featureId, name, value) => {
  return {
    id: featureId,
    settings: {
      ...(name === 'settings' ? value : { [name]: value })
    }
  }
}

export const normalizeFeatureSettings = (
  featureSettings,
  features = [],
  excludeFeatures = []
) => {
  if (!featureSettings) {
    return []
  }

  return _uniqBy(featureSettings, 'id')
    .filter(setting => {
      if (!excludeFeatures.length) {
        return true
      }
      const feature = features.find(({ id }) => setting.id === id)

      return !feature || !excludeFeatures.includes(feature.name)
    })
    .map(setting => {
      const feature = features.find(({ id }) => setting.id === id)

      if (!feature) {
        return setting
      }

      return {
        ...setting,
        ...(defaultFeatureSettings[feature.name] && {
          settings: {
            ...defaultFeatureSettings[feature.name].settings,
            ...normalizeFeatureSettingsValuesByFeature(
              feature,
              setting.settings || {}
            )
          }
        })
      }
    })
}

export const isFontAwesomeIcon = icon =>
  icon && typeof icon === 'string' && icon.startsWith('fa-')

export const emailDataTransformer = {
  fromBE: data => data.map(({ id, email }) => ({ value: id, label: email })),
  toBE: data =>
    data.map(({ value, label }) => ({
      id: value,
      email: label
    }))
}
