import { createContext, useCallback, useMemo, useState } from 'react'

export const ContextMenuContext = createContext({
  openContextMenu: (position, menuItems) => {},
  closeContextMenu: () => {},
  open: false,
  position: { x: 0, y: 0 },
  items: []
})

export const ContextMenuContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [items, setItems] = useState([])

  const openContextMenu = useCallback((position, menuItems) => {
    const items = menuItems.filter(Boolean)

    if (!items.length) {
      setOpen(false)
      return
    }
    setPosition(position)
    setItems(items)
    setOpen(prevState => !prevState)
  }, [])

  const closeContextMenu = useCallback(() => {
    setOpen(false)
    setPosition({ x: 0, y: 0 })
    setItems([])
  }, [])

  const contextValue = useMemo(
    () => ({
      openContextMenu,
      closeContextMenu,
      open,
      position,
      items
    }),
    [openContextMenu, closeContextMenu, open, position, items]
  )

  return (
    <ContextMenuContext.Provider value={contextValue}>
      {children}
    </ContextMenuContext.Provider>
  )
}
