export const getMostPopulatedRegion = markers => {
  let center = { lng: 0, lat: 0 }
  let zoom = 2
  const accuracy = 2
  const zoomMultiplier = 1.13
  const deviceRatio = 0.7

  if (!markers || !markers.length) {
    return {
      center,
      zoom
    }
  }

  const checkRegion = (fromLat, toLat, fromLng, toLng, markers) => {
    const includedMarkersCount = markers.filter(
      ({ long, lat }) =>
        lat >= fromLat && lat <= toLat && long >= fromLng && long <= toLng
    ).length
    if (includedMarkersCount / markers.length >= deviceRatio) {
      const centerLat = (fromLat + toLat) / 2
      const centerLng = (fromLng + toLng) / 2
      center = { lng: centerLng, lat: centerLat }
      zoom *= zoomMultiplier
      if (Math.abs(fromLat - toLat) > accuracy) {
        checkRegion(fromLat, centerLat, fromLng, centerLng, markers)
        checkRegion(centerLat, toLat, fromLng, centerLng, markers)
        checkRegion(centerLat, toLat, centerLng, toLng, markers)
        checkRegion(fromLat, centerLat, centerLng, toLng, markers)
      }
    }
  }
  checkRegion(-90, 90, -180, 180, markers)
  return {
    center,
    zoom: Math.min(Math.floor(zoom), 7)
  }
}
