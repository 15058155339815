import update from 'immutability-helper'

import * as types from '../actions'
import {
  shapeOfBodyWithMeta,
  deleteInitialState,
  shapeOfBody,
  fetchParamsInitialState,
  postInitialState
} from 'constants/initialLibraryState'
import sortConstants from 'constants/sortConstants'

const initialFetchParams = {
  ...fetchParamsInitialState,
  ...sortConstants.Playlist
}

const initialState = {
  library: shapeOfBodyWithMeta,
  modalItems: shapeOfBodyWithMeta,
  fetchParams: initialFetchParams,
  preference: {},
  groupItems: {},
  groupPlaylists: shapeOfBodyWithMeta,
  del: deleteInitialState,
  postGroupItem: {},
  deleteGroupItem: {},
  clone: {
    ...shapeOfBody,
    label: 'clone'
  },
  approve: {
    ...shapeOfBody,
    label: 'approve'
  },
  preview: {
    id: null,
    isVisible: false,
    isLoading: false,
    response: null,
    error: null,
    key: ''
  },
  playlistItem: {
    status: null,
    response: null,
    error: null
  },
  share: {
    ...shapeOfBody,
    label: 'share'
  },
  copyPlaylistItems: {
    ...shapeOfBody,
    label: 'copy'
  },
  assignAvailability: {
    isFetching: false,
    isAvailable: false
  },
  associations: {
    ...shapeOfBodyWithMeta,
    isFetching: false
  },
  put: shapeOfBody,
  post: shapeOfBody,
  postBulk: postInitialState
}

const playlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PLAYLIST_ITEMS_SUCCESS:
      return update(state, {
        library: {
          response: { $set: action.payload.response },
          meta: {
            $set: action.payload.modifiedMeta
          }
        },
        fetchParams: { $set: action.payload.params }
      })
    case types.GET_PLAYLIST_ITEMS:
      return update(state, {
        library: {
          meta: {
            isLoading: { $set: action.loading }
          }
        }
      })
    case types.GET_PLAYLIST_ITEMS_ERROR:
      return update(state, {
        library: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })

    case types.GET_MODAL_PLAYLIST_ITEMS_SUCCESS:
      return update(state, {
        modalItems: {
          response: { $set: action.payload.response },
          meta: {
            $set: action.payload.modifiedMeta
          }
        }
      })
    case types.GET_MODAL_PLAYLIST_ITEMS:
      return update(state, {
        modalItems: {
          meta: {
            isLoading: { $set: action.loading }
          }
        }
      })
    case types.GET_MODAL_PLAYLIST_ITEMS_ERROR:
      return update(state, {
        modalItems: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })

    case types.CLEAR_PLAYLIST_FETCH_PARAMS:
      return update(state, {
        fetchParams: {
          $set: initialFetchParams
        }
      })

    case types.DELETE_PLAYLIST_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        }
      })
    case types.CLEAR_PLAYLIST_ITEMS:
      return update(state, {
        library: { $set: shapeOfBodyWithMeta }
      })
    case types.DELETE_PLAYLIST_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })

    case types.APPROVE_PLAYLIST_SUCCESS:
      return update(state, {
        approve: {
          response: { $set: action.payload }
        }
      })
    case types.APPROVE_PLAYLIST_ERROR:
      return update(state, {
        approve: {
          error: { $set: action.payload }
        }
      })

    case types.CLONE_PLAYLIST_SUCCESS:
      return update(state, {
        clone: {
          error: { $set: {} },
          response: { $set: action.payload }
        }
      })
    case types.CLONE_PLAYLIST_ERROR:
      return update(state, {
        clone: {
          error: { $set: action.payload }
        }
      })

    case types.DELETE_SELECTED_PLAYLIST_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_SELECTED_PLAYLIST_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })

    case types.GET_PLAYLIST_PREFERENCE_SUCCESS:
      return update(state, {
        preference: {
          response: { $set: action.payload }
        }
      })
    case types.GET_PLAYLIST_PREFERENCE_ERROR:
      return update(state, {
        preference: {
          error: { $set: action.payload }
        }
      })

    case types.PUT_PLAYLIST_PREFERENCE_ERROR:
      return update(state, {
        performance: {
          error: { $set: action.payload }
        }
      })
    case types.GET_PLAYLIST_GROUP_ITEMS_SUCCESS:
      return update(state, {
        groupItems: {
          response: { $set: action.payload }
        }
      })
    case types.GET_PLAYLIST_GROUP_ITEMS_ERROR:
      return update(state, {
        groupItems: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_PLAYLIST_GROUP_ITEMS_INFO:
      return update(state, {
        groupItems: { $set: {} }
      })
    case types.POST_PLAYLIST_GROUP_ITEM_SUCCESS:
      return update(state, {
        postGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.POST_PLAYLIST_GROUP_ITEM_ERROR:
      return update(state, {
        postGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_PLAYLIST_GROUP_ITEM_INFO:
      return update(state, {
        postGroupItem: { $set: {} }
      })
    case types.CLEAR_PLAYLIST_GROUP_ITEMS_RESPONSE_INFO:
      return update(state, {
        $merge: {
          groupItems: {},
          postGroupItem: {},
          deleteGroupItem: {}
        }
      })
    case types.DELETE_PLAYLIST_GROUP_ITEM_SUCCESS:
      return update(state, {
        deleteGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_PLAYLIST_GROUP_ITEM_ERROR:
      return update(state, {
        deleteGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.DELETE_PLAYLIST_GROUP_ITEM:
      return update(state, {
        deleteGroupItem: { $set: {} }
      })
    case types.GET_PLAYLIST_BY_ID_SUCCESS:
      return update(state, {
        playlistItem: {
          response: { $set: action.payload }
        }
      })
    case types.GET_PLAYLIST_BY_ID_ERROR:
      return update(state, {
        playlistItem: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_PLAYLIST_SUCCESS:
      return update(state, {
        playlistItem: {
          status: { $set: 'successfully' }
        },
        put: {
          response: { $set: action.payload },
          status: { $set: 'successfully' }
        }
      })
    case types.PUT_SMART_PLAYLIST_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload },
          status: { $set: 'successfully' }
        }
      })
    case types.PUT_PLAYLIST_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload },
          status: { $set: 'error' }
        },
        playlistItem: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.PUT_SMART_PLAYLIST_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload },
          status: { $set: 'error' }
        }
      })
    case types.POST_PLAYLIST_SUCCESS:
    case types.POST_SMART_PLAYLIST_SUCCESS:
      return update(state, {
        post: {
          response: { $set: action.payload },
          status: { $set: 'successfully' }
        }
      })
    case types.POST_PLAYLIST_ERROR:
    case types.POST_SMART_PLAYLIST_ERROR:
      return update(state, {
        post: {
          error: { $set: action.payload },
          status: { $set: 'error' }
        }
      })
    case types.CLEAR_ADDED_PLAYLIST:
    case types.CLEAR_ADDED_SMART_PLAYLIST:
      return update(state, {
        put: { $set: shapeOfBody },
        post: { $set: shapeOfBody },
        playlistItem: {
          status: { $set: null },
          response: { $set: null },
          error: { $set: null }
        }
      })
    case types.CLEAR_PLAYLIST_STATUS:
      return update(state, {
        playlistItem: {
          status: { $set: null }
        }
      })
    case types.CLEAR_PLAYLIST_RESPONSE_INFO:
      return update(state, {
        del: {
          $set: deleteInitialState
        },
        clone: {
          $set: {
            ...shapeOfBody,
            label: 'clone'
          }
        },
        share: {
          $set: {
            ...shapeOfBody,
            label: 'share'
          }
        },
        approve: {
          $set: {
            ...shapeOfBody,
            label: 'approve'
          }
        },
        copyPlaylistItems: {
          $set: {
            ...shapeOfBody,
            label: 'copy'
          }
        },
        put: { $set: shapeOfBody },
        post: { $set: shapeOfBody }
      })

    case types.GET_PLAYLIST_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: action.data },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: action.meta.key }
        }
      })
    case types.GENERATE_PLAYLIST_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: null },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: '' }
        }
      })
    case types.GET_PLAYLIST_PREVIEW_SUCCESS:
    case types.GENERATE_PLAYLIST_PREVIEW_SUCCESS:
      return update(state, {
        preview: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_PLAYLIST_PREVIEW_ERROR:
    case types.GENERATE_PLAYLIST_PREVIEW_ERROR:
      return update(state, {
        preview: {
          isVisible: { $set: false },
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.SHOW_PLAYLIST_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: true }
        }
      })
    case types.CLOSE_PLAYLIST_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: false }
        }
      })
    case types.CLEAR_PLAYLIST_PREVIEW:
      return update(state, {
        preview: {
          $merge: {
            id: null,
            isVisible: false,
            isLoading: false,
            response: null,
            error: null,
            key: ''
          }
        }
      })
    case types.SHARE_PLAYLIST_SUCCESS:
      return update(state, {
        share: {
          response: { $set: action.payload }
        }
      })
    case types.SHARE_PLAYLIST_ERROR:
      return update(state, {
        share: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.GET_PLAYLIST_GROUP_PLAYLISTS:
      return update(state, {
        groupPlaylists: {
          $set: initialState.groupPlaylists
        }
      })
    case types.GET_PLAYLIST_GROUP_PLAYLISTS_SUCCESS:
      return update(state, {
        groupPlaylists: {
          $set: action.payload
        }
      })
    case types.COPY_PLAYLIST_SUCCESS:
      return update(state, {
        copyPlaylistItems: {
          response: { $set: action.payload }
        }
      })
    case types.COPY_PLAYLIST_ERROR:
      return update(state, {
        copyPlaylistItems: {
          error: { $set: action.payload }
        }
      })
    case types.GET_ASSIGNABLE_PLAYLIST:
      return update(state, {
        assignAvailability: { $set: { isFetching: true, isAvailable: false } }
      })
    case types.GET_ASSIGNABLE_PLAYLIST_SUCCESS:
      return update(state, {
        assignAvailability: {
          $set: { isFetching: false, isAvailable: !!action.payload.length }
        }
      })
    case types.GET_ASSIGNABLE_PLAYLIST_ERROR:
      return update(state, {
        assignAvailability: { $set: { isFetching: false, isAvailable: false } }
      })
    case types.GET_PLAYLIST_ASSOCIATIONS:
      return update(state, {
        associations: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_PLAYLIST_ASSOCIATIONS_SUCCESS:
      return update(state, {
        associations: {
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_PLAYLIST_ASSOCIATIONS_ERROR:
      return update(state, {
        associations: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_PLAYLIST_ASSOCIATIONS:
      return update(state, {
        associations: { $set: initialState.associations }
      })
    case types.POST_PLAYLIST_BULK_SUCCESS:
      return update(state, {
        postBulk: {
          response: { $set: action.payload }
        }
      })
    case types.POST_PLAYLIST_BULK_ERROR:
      return update(state, {
        postBulk: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_PLAYLIST_BULK_INFO:
      return update(state, {
        postBulk: {
          $set: postInitialState
        }
      })
    default:
      return state
  }
}

export default playlistReducer
