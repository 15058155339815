import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { userDetailsSelectorMemoized } from 'selectors/userSelectors'
import { _get } from 'utils/lodash'

export default function usePermissions() {
  const { response } = useSelector(userDetailsSelectorMemoized)

  const permissionNames = useMemo(() => {
    return response?.role?.permission.map(({ name }) => name) || []
  }, [response])

  const getPermissionByName = useCallback(
    (...names) => names.some(name => permissionNames.includes(name)),
    [permissionNames]
  )

  return useMemo(
    () => ({
      permissionNames,
      getPermissionByName
    }),
    [permissionNames, getPermissionByName]
  )
}

const getPermissionData = ({ permission, getPermissionByName }) => {
  if (!permission) return false

  return getPermissionByName(...permission)
}

export const withPermissions = findPermission => Component => props => {
  const { getPermissionByName } = usePermissions()

  const permissionsData = Object.entries(findPermission)
  const preparedPermissions = {}

  permissionsData.forEach(item => {
    const key = _get(item, [0])
    const value = _get(item, [1])

    preparedPermissions[key] = {
      approve: getPermissionData({
        permission: value?.approve,
        getPermissionByName
      }),
      read: getPermissionData({
        permission: value?.read,
        getPermissionByName
      }),
      create: getPermissionData({
        permission: value?.create,
        getPermissionByName
      }),
      update: getPermissionData({
        permission: value?.update,
        getPermissionByName
      }),
      delete: getPermissionData({
        permission: value?.delete,
        getPermissionByName
      }),
      other: getPermissionData({
        permission: value?.other,
        getPermissionByName
      })
    }
  })

  return <Component {...props} permissions={preparedPermissions} />
}
