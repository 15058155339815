import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import ThumbnailGenerator from '@uppy/thumbnail-generator'

import { awsConfig, uppyConfig, thumbnailGeneratorConfig } from 'config/uppy'
import ImageEditor from 'utils/uppy-plugins/ImageEditor'
import { sanitizeFileNameBeforeAdded } from './uppy'
import Dashboard from '@uppy/dashboard'

const completeMultipartUpload = AwsS3Multipart.prototype.completeMultipartUpload

// "@uppy/aws-s3-multipart": "^3.12.0" - invalid data in the parts
AwsS3Multipart.prototype.completeMultipartUpload = function (
  file,
  { key, uploadId, parts, signal },
  oldSignal
) {
  return completeMultipartUpload.call(
    this,
    file,
    {
      key,
      uploadId,
      parts: parts.map(({ ETag, PartNumber }) => ({ ETag, PartNumber })),
      signal
    },
    oldSignal
  )
}

const getInitialUppy = (
  config = {},
  companionUrl = process.env.REACT_APP_UPPY_COMPANION_URL
) =>
  new Uppy({
    ...uppyConfig,
    ...config,
    onBeforeFileAdded: config.onBeforeFileAdded || sanitizeFileNameBeforeAdded
  })
    .use(AwsS3Multipart, {
      ...awsConfig,
      companionUrl: companionUrl || process.env.REACT_APP_API_URL
    })
    .use(ImageEditor, { target: Dashboard })
    .use(ThumbnailGenerator, thumbnailGeneratorConfig)

export default getInitialUppy
