import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { _debounce, _uniqBy } from 'utils/lodash'
import { components } from 'react-select'

import FormControlReactSelect from './FormControlReactSelect'
import { simulateEvent } from 'utils/formik'
import { groupField } from 'constants/validationMessages'

const ClearIndicator = ({ onClick, innerValue, innerProps, ...props }) => {
  return (
    <>
      {innerValue && (
        <components.ClearIndicator
          innerProps={{
            ...innerProps,
            onMouseDown: onClick,
            onTouchEnd: onClick
          }}
          {...props}
        />
      )}
    </>
  )
}

const styles = ({ colors }) => ({
  errorText: {
    margin: 0,
    marginTop: -15,
    color: colors.error,
    fontSize: 10,
    lineHeight: 1.5
  }
})

const FormControlAutocomplete = ({
  getOptions = f => f,
  options: staticOptions,
  selectComponent: SelectComponent = FormControlReactSelect,
  isSearchable = true,
  isResettable = false,
  value,
  components,
  withResetValue = false,
  role = '',
  initialOptions = [],
  regExp,
  classes,
  appendOptions = [],
  isSingleCall = false,
  onFocus,
  fetchDependency,
  ...props
}) => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [options, setOptions] = useState(initialOptions)
  const [perPage, setPerPage] = useState(10)
  const [innerValue, setInnerValue] = useState('')

  const updatedOptions = useMemo(() => {
    if (appendOptions && appendOptions.length) {
      return _uniqBy([...options, ...appendOptions], 'value')
    }
    return options
  }, [options, appendOptions])

  const loadNewOptions = useCallback(
    (value, limit = 10, initial = false) => {
      if (
        (!staticOptions || (staticOptions && !staticOptions.length)) &&
        (!isSingleCall || initial)
      ) {
        setLoading(true)

        getOptions &&
          getOptions(value, limit).then(values => {
            values && values.length ? setOptions(values) : setOptions([])
            setLoading(false)
          })
      }
    },
    [getOptions, staticOptions, isSingleCall]
  )

  // TODO Disable new requests if current is pending.
  const onInputChangeHandler = useMemo(
    () =>
      _debounce(value => {
        if (value && !isLoading) {
          if (regExp && !regExp.test(value)) {
            setError(true)
          } else {
            setError(false)
            loadNewOptions(value)
            setInnerValue(value)

            //Set default value
            setPerPage(10)
          }
        }
        if (!value) setError(false)
      }, 300),
    [isLoading, loadNewOptions, regExp]
  )

  const handleMenuScrollToBottom = useCallback(() => {
    setPerPage(value => value + 10)
    loadNewOptions(innerValue, perPage + 10)
  }, [loadNewOptions, perPage, innerValue])

  const handleSearchReset = useCallback(() => {
    setInnerValue('')
    loadNewOptions('')
    if (withResetValue) {
      if (props.handleChange) {
        props.handleChange(simulateEvent(props.name, ''))
      }
    }
  }, [loadNewOptions, props, withResetValue])

  const handleFocus = useCallback(
    e => {
      if (!initialOptions.length && !options.length) {
        loadNewOptions('', 10, true)
      }
      if (onFocus) {
        onFocus(e)
      }
    },
    [initialOptions, options, loadNewOptions, onFocus]
  )

  useEffect(() => {
    if (fetchDependency) {
      setOptions([])
    }
    // eslint-disable-next-line
  }, [fetchDependency])

  useEffect(() => {
    staticOptions && staticOptions.length && setOptions(staticOptions)
    // eslint-disable-next-line
  }, [staticOptions])

  return (
    <>
      <SelectComponent
        {...props}
        onFocus={handleFocus}
        isLoading={isLoading}
        handleInputChange={onInputChangeHandler}
        isSearchable={isSearchable}
        handleMenuScrollToBottom={handleMenuScrollToBottom}
        options={updatedOptions}
        error={error || props.error}
        components={
          isResettable
            ? {
                ...components,
                ClearIndicator: props => (
                  <ClearIndicator
                    innerValue={innerValue}
                    onClick={handleSearchReset}
                    {...props}
                  />
                )
              }
            : components
        }
        value={value}
        useCustomMenuScrollLogic={false}
      />
      {error && <div className={classes.errorText}>{groupField}</div>}
    </>
  )
}

FormControlAutocomplete.propTypes = {
  getOptions: PropTypes.func,
  options: PropTypes.array,
  selectComponent: PropTypes.elementType,
  isSearchable: PropTypes.bool,
  isResettable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  withResetValue: PropTypes.bool,
  disabled: PropTypes.bool,
  role: PropTypes.string
}

export default withStyles(styles)(FormControlAutocomplete)
