import api from './api'
import { errorHandler } from '../utils'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import { ORG_ROLE } from 'constants/api'

export async function getClientSettings() {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/client/setting'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function putClientSettings(reqData) {
  try {
    const role = getUserRoleLevel()
    const url = role === ORG_ROLE ? '/client' : '/client/setting'
    const { data } = await api({
      method: 'PUT',
      url,
      data: reqData
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function postSsoDetails(reqData) {
  try {
    const { data } = await api({
      method: 'POST',
      url: '/client/ssoDetail',
      data: reqData
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function putSsoDetails(reqData) {
  try {
    const { data } = await api({
      method: 'PUT',
      url: '/client/ssoDetail',
      data: reqData
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function putClientFeatureSettings(reqData) {
  try {
    const { data } = await api({
      method: 'PUT',
      url: '/client/featureSettings',
      data: reqData
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}
