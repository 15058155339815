import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _get } from 'utils/lodash'

import {
  devicePreviewSelector,
  devicePreviewsSelector
} from 'selectors/new/device'
import { fetchPreviews } from 'actions/new/device'
import { dataToPreviews } from 'utils/api/devicePreviews'
import useDevicePreviewSocket from 'hooks/socket/useDevicePreviewSocket'
import {
  fetchDevicePreviewThumbnail,
  fetchTriggerDevicePreview
} from 'actions/new/device'
import useTimeout from 'hooks/useTimeout'
import { FETCH_EXCEPTION_ID } from 'constants/deviceConstants'
import { useLazyBulkTriggerDevicePreviewQuery } from 'api/deviceApi'
import { REFRESH_DURATION } from 'constants/devicePreviewRefresh'

const sortParams = {
  sort: 'lastUpdate',
  order: 'desc'
}

export default function useDevicePreviews(id, withoutPreviewsFetch) {
  const dispatch = useDispatch()
  const previews = useSelector(devicePreviewsSelector)
  const preview = useSelector(devicePreviewSelector)

  const [isSocketPending, setSocketPending] = useState(false)
  const [isBulkUpdating, setIsBulkUpdating] = useState(false)
  const [updateDeviceId, setUpdateDeviceId] = useState(null)
  const { isSuccess, isPending, isFailure, meta, data, filters } = useMemo(
    () => (id ? preview : previews),
    [id, preview, previews]
  )
  const [bulkTriggerPreviews] = useLazyBulkTriggerDevicePreviewQuery()
  const [setTimeout, clearTimeout] = useTimeout()
  const [setBulkRefreshTimeout, clearBulkRefreshTimeout] = useTimeout()

  useEffect(() => {
    return () => clearBulkRefreshTimeout()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isBulkUpdating) {
      setBulkRefreshTimeout(() => {
        fetchDevicePreviews({ page: meta.page })
        setIsBulkUpdating(false)
      }, REFRESH_DURATION)
    }
    // eslint-disable-next-line
  }, [isBulkUpdating])

  const handleMessage = useCallback(
    response => {
      const targetId = _get(response, 'data.deviceId')
      if (targetId) {
        clearTimeout()
        dispatch(fetchDevicePreviewThumbnail(targetId))
        setSocketPending(false)
      }
    },
    [clearTimeout, dispatch]
  )

  useDevicePreviewSocket(handleMessage)

  const fetchDevicePreviews = useCallback(
    (params = {}) => {
      dispatch(
        fetchPreviews({
          limit: 6,
          ...sortParams,
          ...filters,
          ...params
        })
      )
    },
    [dispatch, filters]
  )

  const fetchPreview = useCallback(
    id => {
      dispatch(fetchDevicePreviewThumbnail(id))
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (id && !isPending && ![data.id, FETCH_EXCEPTION_ID].includes(id)) {
        fetchPreview(id)
      } else if (
        !withoutPreviewsFetch &&
        !isSuccess &&
        !isPending &&
        !isFailure
      ) {
        fetchDevicePreviews()
      }
    },
    // eslint-disable-next-line
    [isPending, isSuccess, isFailure, fetchDevicePreviews, fetchPreview, id]
  )

  useEffect(() => {
    if (isPending) {
      setSocketPending(false)
    }
  }, [isPending])

  const updatePreview = useCallback(
    id => {
      setSocketPending(true)
      setUpdateDeviceId(id)
      dispatch(
        fetchTriggerDevicePreview(id, () => {
          setTimeout(() => {
            dispatch(fetchDevicePreviewThumbnail(id))
            setSocketPending(false)
          }, 10000)
        })
      )
    },
    [dispatch, setTimeout]
  )

  const updatePreviews = useCallback(() => {
    setIsBulkUpdating(true)
    bulkTriggerPreviews()
  }, [bulkTriggerPreviews])

  return useMemo(
    () => ({
      data: id ? dataToPreviews([data])[0] : dataToPreviews(data),
      meta,
      filters,
      isPending: isPending,
      fetchDevicePreviews,
      updatePreview,
      updatePreviews,
      isPreviewPending: isSocketPending || preview.isPending,
      previewPendingDeviceId: updateDeviceId
    }),
    [
      data,
      meta,
      filters,
      isPending,
      fetchDevicePreviews,
      updatePreview,
      updatePreviews,
      id,
      isSocketPending,
      preview.isPending,
      updateDeviceId
    ]
  )
}
