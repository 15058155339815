import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'

import { FormControlAutocompleteNew } from 'components/Form/index'
import useCustomFontsLoader from 'hooks/useCustomFontsLoader'
import { getFontOptions } from 'services/getOptions'
import { fontOptionsSelector } from 'selectors/fontsSelectors'
import { getVariantStyle } from 'utils/fontUtils'

const FontOption = ({ classes, children, data, ...props }) => {
  return (
    <components.Option {...props}>
      <span
        style={{
          fontFamily: data.value,
          ...(data?.family &&
            getVariantStyle(data.value.toString(), data.family))
        }}
      >
        {data.label}
      </span>
    </components.Option>
  )
}

const SingleValue = ({ classes, children, data, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <input
        value={data.label}
        readOnly
        size={data.label.length}
        style={{
          fontFamily: data.value,
          ...(data?.family &&
            getVariantStyle(data.value.toString(), data.family))
        }}
      />
    </components.SingleValue>
  )
}

const FormControlSelectFont = ({
  withInitialOptions,
  onChange,
  handleChange,
  restrictFetch,
  isClearable = true,
  disableInitialFetchValue = false,
  ...props
}) => {
  const dispatch = useDispatch()
  const response = useSelector(fontOptionsSelector)

  useCustomFontsLoader()

  return (
    <FormControlAutocompleteNew
      {...props}
      {...(withInitialOptions && { initialResponse: response })}
      onChange={onChange || handleChange}
      getOptions={!restrictFetch && getFontOptions(dispatch)}
      components={{ Option: FontOption, SingleValue }}
      limit={20}
      isClearable={isClearable}
      isResettable
      initialFetchValue={disableInitialFetchValue ? null : props.value}
    />
  )
}

FormControlSelectFont.propTypes = {
  withInitialOptions: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

FormControlSelectFont.defaultValues = {
  withInitialOptions: false
}

export default memo(FormControlSelectFont)
