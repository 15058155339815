import React, { useEffect, useMemo, memo, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import { compose } from '@reduxjs/toolkit'
import classNames from 'classnames'
import { _isEmpty } from 'utils/lodash'

import {
  FormControlReactSelect,
  FormControlAutocomplete,
  FormControlSelectClient,
  FormControlAutocompleteNew,
  FormControlSelectGroup,
  FormControlSelectTag,
  FormControlMediaTypeChips
} from 'components/Form'
import Footer from 'components/Filter/Footer'
import FormControlSelectDevice from 'components/Form/FormControlSelectDevice'
import {
  getMacAddressOptions,
  getFirmWareOptions,
  getDeviceLocationOptions,
  getDeviceOptionsByField
} from 'services/getOptions'
import {
  anyParamsModifier,
  deviceFilterValuesTransform
} from 'utils/filterUtils'
import { groupTitleRegExp } from 'constants/regExp'
import { insetColons, removeColons } from 'utils/macAddressUtils'
import { translateOptions } from 'utils/transformToOptionsUtils'
import useUserDetails from 'hooks/useUserDetails'
import {
  getDeviceOptionsByFieldWithMeta,
  getDeviceTypeOptionsWithMeta
} from 'services/getOptionsWithMeta'
import { entityGroupsConstants } from 'constants/index'
import { CheckboxSwitcher } from 'components/Checkboxes'
import Spacing from 'components/Containers/Spacing'
import { filterContentWidth } from 'constants/stylesConstants'
import { searchStatusOptions } from 'constants/deviceConstants'
import { sortOptions } from 'constants/libraryConstants/nocLibraryConstants'

const styles = () => ({
  root: {
    padding: '25px 17px'
  },
  singleColumnRoot: {
    width: 315
  },
  multipleColumnsRoot: {
    padding: '25px 0 9px 17px',
    display: 'flex',
    flexWrap: 'wrap',
    height: 594,
    flexDirection: 'column'
  },
  multipleColumnsCustom: {
    height: 520
  },
  searchAction: {
    width: '90%'
  },
  twoColumnLayoutItem: {
    maxWidth: '50%',
    paddingRight: 17,
    marginBottom: 16,
    minHeight: 54
  },
  twoColumnLayoutCheckbox: {
    padding: '20px 0 0 5px'
  },
  footer: {
    marginTop: 'auto',
    alignItems: 'end'
  }
})

const statusOptions = [...searchStatusOptions, { label: 'Any', value: 'any' }]

const updateAvailableOptions = t => [
  { label: t('Any'), value: 'any' },
  { label: t('Yes'), value: '1' },
  { label: t('No'), value: '0' }
]

const maxColumnItems = 8
const itemsCountExceptions = [9]
const multipleColumnPadding = 17
const singleColumnPadding = 34

const multiFieldsContainer = {
  control: {
    maxHeight: 102,
    overflowY: 'auto',
    scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent'
  }
}

const DeviceSearchForm = ({
  t,
  classes,
  initialValues = {},
  onSubmit,
  onReset,
  withoutGroups,
  close = () => {},
  showDisabled = false,
  showUpdateAvailable = false,
  filterPopupData = {},
  setFilterPopupData,
  role,
  uptime = false
}) => {
  const ref = useRef(null)

  const {
    client: { id }
  } = useUserDetails()

  const translations = useMemo(
    () => ({
      deviceName: t('Device search name'),
      deviceType: t('Device Type'),
      deviceID: t('Device ID'),
      locationCity: t('Device search location city'),
      client: t('Client'),
      status: t('Device search status'),
      group: t('Groups'),
      tags: t('Tags'),
      macAddress: t('MAC Address'),
      firmware: t('Current App Version'),
      appliedFirmware: t('Applied App. Ver.'),
      remoteSupportId: t('Remote Support ID'),
      serialNumber: t('Serial Number'),
      soNumber: t('SO Number'),
      updateAvailable: t('Device search update available'),
      sort: t('Sort Device')
    }),
    [t]
  )

  const modifiedQueryParams = useMemo(
    () => deviceFilterValuesTransform.to(initialValues),
    [initialValues]
  )

  const form = useFormik({
    initialValues: modifiedQueryParams,
    onSubmit: values => {
      onSubmit(deviceFilterValuesTransform.from(values))
      close()
    },
    onReset: () => {
      onReset()
      close()
    }
  })

  useEffect(() => {
    const formData = anyParamsModifier(
      initialValues,
      ['status', 'updateAvailable'],
      true
    )
    form.setValues(formData)
    // eslint-disable-next-line
  }, [initialValues])

  const getMacOptions = useCallback(
    async (value, limit) => await getMacAddressOptions(value, limit),
    []
  )

  const getFirmWareOption = useCallback(
    async (value, limit) => await getFirmWareOptions(value, limit),
    []
  )

  const getDeviceTypeOption = useCallback(
    async (value, params) =>
      await getDeviceTypeOptionsWithMeta(
        value,
        id,
        params,
        role.org || role.enterprise
      ),
    [role.org, role.enterprise, id]
  )

  const getDeviceIdOption = useCallback(async (value, params) => {
    return await getDeviceOptionsByFieldWithMeta({
      field: 'id',
      value,
      params,
      label: 'id'
    })
  }, [])

  const handleDeviceIdChange = ({ target: { value, __isNew__ } }) => {
    form.setFieldValue('id', __isNew__ ? '' : value)
  }

  const getRemoteSupportOption = useCallback(
    async (value, limit) =>
      await getDeviceOptionsByField('remoteSupportId', value, limit),
    []
  )

  const getSoNumberOption = useCallback(
    async (value, limit) =>
      await getDeviceOptionsByField('soNumber', value, limit),
    []
  )

  const getSerialNumberOption = useCallback(
    async (value, limit) =>
      await getDeviceOptionsByField('serialNumber', value, limit),
    []
  )

  const getDeviceLocation = useCallback(
    async (value, limit) => await getDeviceLocationOptions(value, limit),
    []
  )

  const handleClientChange = useCallback(
    ({ target: { value, __isNew__ } }) => {
      form.setFieldValue('clientId', __isNew__ ? '' : value)
      form.setFieldValue('clientName', __isNew__ ? value : '')
    },
    [form]
  )

  const handleDeviceTypeChange = useCallback(
    ({ target: { value, __isNew__ } }) => {
      form.setFieldValue('deviceTypeId', __isNew__ ? '' : value)
      form.setFieldValue('deviceType', __isNew__ ? value : '')
    },
    [form]
  )

  useEffect(() => {
    if (ref.current?.childElementCount > 1) {
      const columnsCount = Math.ceil(
        ref.current.childElementCount / maxColumnItems
      )
      setFilterPopupData &&
        setFilterPopupData({
          columns: columnsCount,
          width:
            filterContentWidth * columnsCount +
            (columnsCount > 1 ? multipleColumnPadding : singleColumnPadding)
        })
    }
    // eslint-disable-next-line
  }, [ref.current?.childElementCount])

  return (
    <div
      className={classNames(classes.root, {
        [classes.singleColumnRoot]:
          _isEmpty(filterPopupData) || filterPopupData.columns === 1,
        [classes.multipleColumnsRoot]: filterPopupData.columns > 1,
        [classes.multipleColumnsCustom]: itemsCountExceptions.includes(
          ref.current?.childElementCount
        )
      })}
      ref={ref}
    >
      <FormControlSelectDevice
        name="name"
        label={translations.deviceName}
        value={form.values.name}
        onChange={form.handleChange}
        formControlContainerClass={classNames({
          [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
        })}
        marginBottom={16}
        isClearable
        hasCopy
      />
      {!role.org && role.role.length > 0 && !uptime && (
        <FormControlSelectClient
          name="clientId"
          label={translations.client}
          idValue={form.values.clientId}
          nameValue={form.values.clientName}
          onChange={handleClientChange}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          fullWidth
        />
      )}

      {role.system > 0 && !uptime && (
        <FormControlAutocompleteNew
          fullWidth
          isClearable
          isCreatable
          name="id"
          label={translations.deviceID}
          value={form.values.id}
          onChange={handleDeviceIdChange}
          getOptions={getDeviceIdOption}
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
        />
      )}
      {role.role.length > 0 && (
        <FormControlAutocompleteNew
          fullWidth
          isClearable
          name="deviceType"
          label={translations.deviceType}
          value={form.values.deviceTypeId || form.values.deviceType}
          onChange={handleDeviceTypeChange}
          getOptions={getDeviceTypeOption}
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          styles={multiFieldsContainer}
          withPortal
          isMulti
        />
      )}
      {role.org && !uptime && (
        <FormControlAutocomplete
          fullWidth
          name="location"
          label={translations.locationCity}
          values={form.values.location}
          handleChange={form.handleChange}
          getOptions={getDeviceLocation}
          isClearable
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
        />
      )}
      {!uptime && (
        <FormControlMediaTypeChips
          name="mediaFeature"
          values={form.values.mediaFeature}
          handleChange={form.handleChange}
          fullWidth
          label={t('Scheduled Media Types')}
          isClearable
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          styles={multiFieldsContainer}
          withPortal
        />
      )}
      {role.system && !uptime && (
        <FormControlAutocomplete
          fullWidth
          isClearable
          name="firmware"
          label={translations.firmware}
          values={form.values.firmware}
          getOptions={getFirmWareOption}
          handleChange={form.handleChange}
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          isCreatable
        />
      )}
      {role.system && !uptime && (
        <FormControlAutocomplete
          fullWidth
          isClearable
          name="remoteSupportId"
          label={translations.remoteSupportId}
          values={form.values.remoteSupportId}
          getOptions={getRemoteSupportOption}
          handleChange={form.handleChange}
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          isCreatable
        />
      )}
      {role.system && !uptime && (
        <FormControlAutocomplete
          fullWidth
          isClearable
          name="macAddress"
          label={translations.macAddress}
          values={insetColons(removeColons(form.values.macAddress))}
          getOptions={getMacOptions}
          handleChange={form.handleChange}
          hasDynamicChipsCreation={false}
          isCreatable
          filterOption={(candidate, input) =>
            candidate.data.__isNew__ ||
            candidate.value
              .toLowerCase()
              .includes(removeColons(input).toLowerCase())
          }
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
        />
      )}
      {role.system && !uptime && (
        <FormControlAutocomplete
          fullWidth
          isClearable
          name="soNumber"
          label={translations.soNumber}
          values={form.values.soNumber}
          getOptions={getSoNumberOption}
          handleChange={form.handleChange}
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          isCreatable
        />
      )}
      {role.system && !uptime && (
        <FormControlAutocomplete
          fullWidth
          isClearable
          name="serialNumber"
          label={translations.serialNumber}
          values={form.values.serialNumber}
          getOptions={getSerialNumberOption}
          handleChange={form.handleChange}
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          isCreatable
        />
      )}
      {(role.org || role.enterprise) && !withoutGroups && !uptime && (
        <FormControlSelectGroup
          entity={entityGroupsConstants.Device}
          fullWidth
          isClearable
          name="group"
          label={translations.group}
          values={form.values.group}
          regExp={groupTitleRegExp}
          onChange={form.handleChange}
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          styles={multiFieldsContainer}
          withPortal
          isMulti
        />
      )}
      {role.org && !uptime && (
        <FormControlSelectTag
          fullWidth
          name="tag"
          label={translations.tags}
          values={form.values.tag}
          onChange={form.handleChange}
          isClearable
          hasDynamicChipsCreation={false}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          styles={multiFieldsContainer}
          withPortal
          isMulti
        />
      )}
      <FormControlReactSelect
        fullWidth
        label={translations.status}
        options={translateOptions(statusOptions, t)}
        name="status"
        value={form.values.status}
        handleChange={form.handleChange}
        formControlContainerClass={classNames({
          [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
        })}
        isClearable
        marginBottom={16}
      />
      {uptime && (
        <FormControlReactSelect
          fullWidth
          label={translations.sort}
          options={translateOptions(sortOptions, t)}
          name="sort"
          value={form.values.sort}
          handleChange={form.handleChange}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          isClearable
          marginBottom={16}
        />
      )}
      {showUpdateAvailable && !uptime && (
        <FormControlReactSelect
          fullWidth
          label={translations.updateAvailable}
          options={updateAvailableOptions(t)}
          name="updateAvailable"
          value={form.values.updateAvailable}
          handleChange={form.handleChange}
          formControlContainerClass={classNames({
            [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
          })}
          isSort={false}
          isClearable
          marginBottom={16}
        />
      )}

      {showDisabled && !uptime && (
        <Spacing variant={filterPopupData.columns > 1 ? 0 : 3}>
          <CheckboxSwitcher
            label={t('Show Disabled Devices')}
            name="disabled"
            handleChange={form.handleChange}
            value={form.values.disabled}
            returnValues={{
              1: true,
              0: false
            }}
            switchContainerClass={classNames({
              [classes.twoColumnLayoutCheckbox]: filterPopupData.columns === 2,
              [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
            })}
          />
        </Spacing>
      )}

      <Footer
        onSubmit={form.handleSubmit}
        onReset={form.handleReset}
        rootClass={classNames(classes.footer, {
          [classes.twoColumnLayoutItem]: filterPopupData.columns === 2
        })}
      />
    </div>
  )
}

DeviceSearchForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  showDisabled: PropTypes.bool,
  showUpdateAvailable: PropTypes.bool,
  close: PropTypes.func
}

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  memo
)(DeviceSearchForm)
