import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles, Tooltip as MaterialTooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { getLanguage } from 'utils/language'
import { addOpenedTooltip, removeOpenedTooltip } from 'actions/appActions'
import { tooltipStateSelector } from 'selectors/appSelectors'
import { zIndexes } from 'constants/stylesConstants'

const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)

const useStyles = makeStyles(({ colors, fontSize }) => ({
  root: {
    display: 'block'
  },
  labelWrapper: {
    cursor: 'pointer',
    position: 'relative',
    display: 'inline-block',
    '&:after': {
      // place dotted underline between bottom of the text
      // and bottom of the container
      // add 1px to compensate for border height
      bottom: 'calc(((100% - 1em) * 0.25) + 1px)',
      left: 0,
      width: '100%',
      borderTop: `1px dotted ${colors.highlight}`,
      position: 'absolute',
      content: '""'
    }
  },
  tooltip: ({ maxWidth }) => ({
    padding: '0px !important',
    maxWidth: maxWidth
  }),
  contentWrapper: {
    padding: 0
  },
  header: {
    padding: '10px 16px',
    fontSize: fontSize.tooltipHeader,
    lineHeight: '18px',
    fontWeight: 600,
    backgroundColor: '#215c95',
    borderRadius: '5px 5px 0 0'
  },
  headerArrow: {
    color: '#215c95 !important'
  },
  content: {
    padding: '10px 16px',
    whiteSpace: 'pre-line'
  },
  hyphensAuto: {
    hyphens: 'auto'
  },
  wordBreakAll: {
    wordBreak: 'break-all'
  },
  wordWrap: {
    hyphens: 'none !important'
  },
  arrowPopper: {
    zIndex: `${zIndexes.tooltip} !important`
  }
}))

const Tooltip = ({
  containerClassName,
  tooltipContentClassName,
  customTooltipClasses,
  arrowWithHeaderColor,
  rootClasses,
  children,
  disableHoverListener = false,
  useUnderline = false,
  withWrapper = false,
  withHeader = false,
  headerText = '',
  title = '',
  maxWidth,
  useHyphens = false,
  id,
  single = false,
  disableTracking = false,
  arrow = true,
  placement = 'top',
  openSingleTooltip = false,
  triggerClose,
  ...props
}) => {
  const dispatch = useDispatch()
  const classes = useStyles({ maxWidth })

  const openedTooltips = useSelector(tooltipStateSelector)

  const [language, setLanguage] = useState('')
  const [open, setOpen] = useState(false)
  const customClasses = useMemo(
    () => ({
      ...{
        popper: classes.arrowPopper,
        tooltip: classNames(classes.tooltip, customTooltipClasses)
      },
      ...(arrowWithHeaderColor && { arrow: classes.headerArrow }),
      ...rootClasses
    }),
    [classes, customTooltipClasses, rootClasses, arrowWithHeaderColor]
  )

  const tooltipId = useMemo(() => id || title, [id, title])

  const changeLanguage = useCallback(() => {
    setLanguage(getLanguage())
  }, [])

  const handleOpen = () => {
    if (!disableTracking && typeof tooltipId === 'string') {
      if (openSingleTooltip) {
        if (single && !openedTooltips.length) {
          dispatch(addOpenedTooltip(tooltipId))
          setOpen(true)
        }
      } else {
        dispatch(addOpenedTooltip(tooltipId))
        if (single && !openedTooltips.length) {
          setOpen(true)
        }
      }
    }
  }

  const handleClose = () => {
    if (!disableTracking && typeof tooltipId === 'string') {
      dispatch(removeOpenedTooltip(tooltipId))
      setOpen(false)
    }
  }

  useEffect(() => {
    if (triggerClose) {
      handleClose()
    }
    // eslint-disable-next-line
  }, [triggerClose])

  useEffect(() => {
    setLanguage(getLanguage())
    window.addEventListener('language-change', changeLanguage)

    return () => {
      window.removeEventListener('language-change', changeLanguage)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (single) {
      if (openedTooltips.length > 1) {
        setOpen(false)
      } else if (openedTooltips.length === 1 && openedTooltips.includes(id)) {
        setOpen(true)
      }
    }
    // eslint-disable-next-line
  }, [openedTooltips])

  const isChromeWithNonEnglishLang = useMemo(() => {
    return isChrome && language !== 'en'
  }, [language])

  const wordBreakClasses = useMemo(() => {
    return {
      [classes.wordBreakAll]: useHyphens && isChromeWithNonEnglishLang,
      [classes.hyphensAuto]: useHyphens && !isChromeWithNonEnglishLang,
      [classes.wordWrap]: !useHyphens
    }
  }, [classes, isChromeWithNonEnglishLang, useHyphens])

  const customTitle = useMemo(() => {
    if (withHeader) {
      return (
        <div className={classes.contentWrapper}>
          <div className={classNames(classes.header, wordBreakClasses)}>
            {headerText}
          </div>
          {title && (
            <div
              className={classNames(
                classes.content,
                tooltipContentClassName,
                wordBreakClasses
              )}
            >
              {title}
            </div>
          )}
        </div>
      )
    } else {
      if (!title) return ''
      return (
        <div className={classes.contentWrapper}>
          <div className={classNames(classes.content, wordBreakClasses)}>
            {title}
          </div>
        </div>
      )
    }
  }, [
    classes,
    withHeader,
    headerText,
    title,
    wordBreakClasses,
    tooltipContentClassName
  ])

  return (
    <MaterialTooltip
      onOpen={handleOpen}
      onClose={handleClose}
      {...(single && { open })}
      disableHoverListener={disableHoverListener}
      classes={customClasses}
      title={customTitle}
      arrow={arrow}
      placement={placement}
      {...props}
    >
      {withWrapper ? (
        <span
          className={classNames(classes.root, containerClassName, {
            [classes.labelWrapper]: useUnderline && !disableHoverListener
          })}
        >
          {children}
        </span>
      ) : (
        children
      )}
    </MaterialTooltip>
  )
}

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default Tooltip
