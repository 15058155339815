import * as types from './index'

const getAlertDevicesByIdAction = id => ({
  type: types.GET_ALERT_DEVICES_BY_ID,
  payload: id
})

const clearGetAlertDevicesByIdInfoAction = () => ({
  type: types.CLEAR_GET_ALERT_DEVICES_BY_ID_INFO
})

const postAlertTriggerAction = data => ({
  type: types.POST_ALERT_TRIGGER,
  payload: data
})

const updateAlertTriggerDataAction = data => ({
  type: types.SET_ALERT_TRIGGER_DATA,
  payload: data
})

const deleteAlertTriggerDataAction = () => ({
  type: types.DELETE_ALERT_TRIGGER_DATA
})

const updateCapAlertDataAction = data => ({
  type: types.SET_CAP_ALERT_DATA,
  payload: data
})

const deleteCapAlertDataAction = () => ({
  type: types.DELETE_CAP_ALERT_DATA
})

const updateIpawsAlertDataAction = data => ({
  type: types.SET_IPAWS_ALERT_DATA,
  payload: data
})

const deleteIpawsAlertDataAction = () => ({
  type: types.DELETE_IPAWS_ALERT_DATA
})

const clearPostAlertTriggerInfoAction = () => ({
  type: types.CLEAR_POST_ALERT_TRIGGER_INFO
})

const getDeviceActiveEmergencyAlert = () => ({
  type: types.GET_DEVICE_ACTIVE_EMERGENCY_ALERT
})

const getDeviceMediaEmergencyAlert = id => ({
  type: types.GET_DEVICE_MEDIA_EMERGENCY_ALERT,
  payload: id
})

const clearGetDeviceMediaEmergencyAlertInfo = () => ({
  type: types.CLEAR_GET_DEVICE_MEDIA_EMERGENCY_ALERT_INFO
})

const putDeviceMediaEmergencyAlertAction = data => ({
  type: types.PUT_DEVICE_MEDIA_EMERGENCY_ALERT,
  payload: data
})

const clearPutDeviceMediaEmergencyAlertInfoAction = () => ({
  type: types.CLEAR_PUT_DEVICE_MEDIA_EMERGENCY_ALERT_INFO
})

const removeDeviceMediaEmergencyAlertAction = data => ({
  type: types.REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT,
  payload: data
})

const clearRemoveDeviceMediaEmergencyAlertInfoAction = () => ({
  type: types.CLEAR_REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT_INFO
})

const getDeviceMediaCapAlertAction = id => ({
  type: types.GET_DEVICE_MEDIA_CAP_ALERT,
  payload: id
})

const clearGetDeviceMediaCapAlertInfoAction = () => ({
  type: types.CLEAR_GET_DEVICE_MEDIA_CAP_ALERT_INFO
})

const removeDeviceMediaCapAlertAction = id => ({
  type: types.REMOVE_DEVICE_MEDIA_CAP_ALERT,
  payload: id
})

const clearRemoveDeviceMediaCapAlertInfoAction = () => ({
  type: types.CLEAR_REMOVE_DEVICE_MEDIA_CAP_ALERT_INFO
})

const putDeviceMediaCapAlertAction = data => ({
  type: types.PUT_DEVICE_MEDIA_CAP_ALERT,
  payload: data
})

const clearPutDeviceMediaCapAlertInfoAction = () => ({
  type: types.CLEAR_PUT_DEVICE_MEDIA_CAP_ALERT_INFO
})

const disableAlertAction = () => ({
  type: types.DISABLE_ALERT
})

const clearDisableAlertInfoAction = () => ({
  type: types.CLEAR_DISABLE_ALERT_INFO
})

const disableDeviceAlertAction = deviceId => ({
  type: types.DISABLE_DEVICE_ALERT,
  payload: deviceId
})

const clearDisableDeviceAlertInfoAction = () => ({
  type: types.CLEAR_DISABLE_DEVICE_ALERT_INFO
})

export function associateCapAlert(data) {
  return {
    type: types.REQUEST_ASSOCIATE_CAP_ALERT,
    payload: data
  }
}

export function associateCapAlertSuccess(data) {
  return {
    type: types.ASSOCIATE_CAP_ALERT_SUCCESS,
    payload: data
  }
}

export function associateCapAlertError(error) {
  return {
    type: types.ASSOCIATE_CAP_ALERT_ERROR,
    payload: error
  }
}

export function resetAssociateCapAlert() {
  return {
    type: types.RESET_ASSOCIATE_CAP_ALERT
  }
}

export function getIpawsAlertDevices() {
  return {
    type: types.GET_IPAWS_ALERT_DEVICES
  }
}

export function associateIpawsAlert(data) {
  return {
    type: types.ASSOCIATE_IPAWS_ALERT,
    payload: data
  }
}

export function resetAssociateIpawsAlert() {
  return {
    type: types.RESET_ASSOCIATE_IPAWS_ALERT
  }
}

export const removeDeviceMediaIpawsAlertAction = id => ({
  type: types.REMOVE_DEVICE_MEDIA_IPAWS_ALERT,
  payload: id
})

export const clearRemoveDeviceMediaIpawsAlertInfoAction = () => ({
  type: types.CLEAR_REMOVE_DEVICE_MEDIA_IPAWS_ALERT_INFO
})

export const getDeviceMediaIpawsAlertAction = id => ({
  type: types.GET_DEVICE_MEDIA_IPAWS_ALERT,
  payload: id
})

export const clearGetDeviceMediaIpawsAlertInfoAction = () => ({
  type: types.CLEAR_GET_DEVICE_MEDIA_IPAWS_ALERT_INFO
})

export const putDeviceMediaIpawsAlertAction = data => ({
  type: types.PUT_DEVICE_MEDIA_IPAWS_ALERT,
  payload: data
})

export const clearPutDeviceMediaIpawsAlertInfoAction = () => ({
  type: types.CLEAR_PUT_DEVICE_MEDIA_IPAWS_ALERT_INFO
})

export {
  getAlertDevicesByIdAction,
  clearGetAlertDevicesByIdInfoAction,
  postAlertTriggerAction,
  updateAlertTriggerDataAction,
  deleteAlertTriggerDataAction,
  updateCapAlertDataAction,
  deleteCapAlertDataAction,
  updateIpawsAlertDataAction,
  deleteIpawsAlertDataAction,
  clearPostAlertTriggerInfoAction,
  getDeviceActiveEmergencyAlert,
  getDeviceMediaEmergencyAlert,
  clearGetDeviceMediaEmergencyAlertInfo,
  putDeviceMediaEmergencyAlertAction,
  clearPutDeviceMediaEmergencyAlertInfoAction,
  removeDeviceMediaEmergencyAlertAction,
  clearRemoveDeviceMediaEmergencyAlertInfoAction,
  getDeviceMediaCapAlertAction,
  clearGetDeviceMediaCapAlertInfoAction,
  removeDeviceMediaCapAlertAction,
  clearRemoveDeviceMediaCapAlertInfoAction,
  putDeviceMediaCapAlertAction,
  clearPutDeviceMediaCapAlertInfoAction,
  disableAlertAction,
  clearDisableAlertInfoAction,
  disableDeviceAlertAction,
  clearDisableDeviceAlertInfoAction
}
