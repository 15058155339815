import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'

export const reportsApi = createAppApi('reportsQuery', {
  tagTypes: [apiTags.report],
  endpoints: builder => ({
    getReports: builder.query({
      providesTags: [apiTags.report],
      query: (params = {}) => ({
        url: `/report/countApiCall`,
        params: { ...params }
      })
    })
  })
})

export const { useLazyGetReportsQuery } = reportsApi
