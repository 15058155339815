import { createContext } from 'react'

export const PreferenceContext = createContext({
  columns: [],
  preferenceParams: { limit: 10, order: 'asc', sort: 'id' },
  isFetchAllowed: false,
  handlePerPageChange: f => f,
  handleSortChange: f => f,
  handleResetColumns: f => f,
  handleColumnsChange: f => f,
  toggleDisplayColumn: f => f,
  handleColumnMove: (columnId, toIndex) => {}
})

export default PreferenceContext
