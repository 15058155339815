import * as types from 'actions/index'

const getPreferenceByEntity = entity => ({
  type: types.GET_PREFERENCE_BY_ENTITY,
  entity
})

const putPreferenceByEntity = (entity, data) => ({
  type: types.PUT_PREFERENCE_BY_ENTITY,
  entity,
  data
})

const clearPreferenceByEntity = entity => ({
  type: types.CLEAR_PREFERENCE_BY_ENTITY,
  entity
})

const getPreferencePublicNoc = token => ({
  type: types.GET_PREFERENCE_PUBLIC_NOC,
  token
})

const putPreferencePublicNoc = data => ({
  type: types.PUT_PREFERENCE_PUBLIC_NOC,
  data
})

export {
  getPreferenceByEntity,
  putPreferenceByEntity,
  clearPreferenceByEntity,
  getPreferencePublicNoc,
  putPreferencePublicNoc
}
