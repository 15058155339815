import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _isEmpty, _get } from 'utils/lodash'

import { getItem, clearGetHelpItemInfo } from 'actions/helpActions'

const useHelpLink = helpPageName => {
  const [item, setItem] = useState({})
  const [helpUrl, setHelpUrl] = useState(null)

  const dispatch = useDispatch()
  const items = useSelector(({ helps }) => helps.itemByPage)

  useEffect(() => {
    if (helpPageName) {
      const item = _get(items, `[${helpPageName}]`, {})
      if (!_isEmpty(item.response) || !_isEmpty(item.error)) {
        setItem(item)
      } else if (!item.isFetching) {
        dispatch(getItem(helpPageName))
      }
    }
    // eslint-disable-next-line
  }, [helpPageName])

  useEffect(() => {
    if (_isEmpty(item)) {
      const item = _get(items, `[${helpPageName}]`, {})
      if (!_isEmpty(item.response) || !_isEmpty(item.error)) {
        setItem(item)
      }
    }
    // eslint-disable-next-line
  }, [items])

  useEffect(() => {
    if (!helpUrl && !_isEmpty(item.response)) {
      const { status, link } = item.response
      setHelpUrl(status === 'Active' ? link : null)
      dispatch(clearGetHelpItemInfo())
    }
    // eslint-disable-next-line
  }, [item])

  useEffect(() => {
    if (!helpUrl && !_isEmpty(item.error)) {
      dispatch(clearGetHelpItemInfo())
    }
    // eslint-disable-next-line
  }, [item])

  return helpUrl
}

export default useHelpLink
