import React from 'react'
import { withStyles } from '@material-ui/core'
import Tooltip from 'components/Tooltip'
import {
  clientTypeColors,
  ENTERPRISE_CLIENT_TYPE,
  OEM_CLIENT_TYPE,
  RESELLER_CLIENT_TYPE,
  STANDARD_CLIENT_TYPE
} from 'constants/clientsConstants'

const styles = theme => ({
  root: {
    margin: 'auto',
    borderRadius: '50%'
  },
  active: {
    backgroundColor: '#4fd688'
  },
  'white-label': {
    backgroundColor: clientTypeColors['white-label']
  },
  STANDARD_CLIENT: {
    backgroundColor: clientTypeColors[STANDARD_CLIENT_TYPE]
  },
  RESELLER_CLIENT: {
    backgroundColor: clientTypeColors[RESELLER_CLIENT_TYPE]
  },
  ENTERPRISE_CLIENT: {
    backgroundColor: clientTypeColors[ENTERPRISE_CLIENT_TYPE]
  },
  OEM_CLIENT: {
    backgroundColor: clientTypeColors[OEM_CLIENT_TYPE]
  },
  inactive: {
    backgroundColor: '#f53923'
  },

  normal: {
    width: '14px',
    height: '14px'
  },
  big: {
    width: '22px',
    height: '22px'
  }
})

const withTooltip = WrapperComponent => {
  return ({ tooltipHeader, tooltipTitle, ...rest }) => {
    if (tooltipTitle) {
      return (
        <Tooltip
          title={tooltipTitle}
          headerText={tooltipHeader}
          placement="top"
          arrow
          withHeader
        >
          <div>
            <WrapperComponent {...rest} />
          </div>
        </Tooltip>
      )
    }

    return <WrapperComponent {...rest} />
  }
}

const TypeCircle = ({
  classes,
  color,
  type = 'STANDARD_CLIENT',
  size = 'normal'
}) => (
  <div
    className={`${classes.root} ${classes[type]} ${classes[size]}`}
    style={{ backgroundColor: color }}
  />
)

export default withTooltip(withStyles(styles)(TypeCircle))
