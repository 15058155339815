import { all, call, put, takeEvery } from 'redux-saga/effects'
import { tableauService } from '../services'
import * as types from '../actions'
import {
  tableauLoginFailure,
  tableauLoginSuccess,
  tableauLogoutFailure,
  tableauLogoutSuccess
} from '../actions/tableauActions'

export default function* tableauAuthWatcher() {
  yield all([
    takeEvery(types.TABLEAU_LOGIN, tableauLoginWorker),
    takeEvery(types.TABLEAU_LOGOUT, tableauLogoutWorker),
    takeEvery(types.GET_TABLEAU_VIEWS, getTableauViews),
    takeEvery(types.GET_TABLEAU_WORKBOOK, getTableauWorkbooks)
  ])
}

function* tableauLoginWorker({ payload }) {
  try {
    yield call(tableauService.loginTableauService, payload)

    yield put(tableauLoginSuccess({ status: 'success' }))
  } catch (error) {
    yield put(tableauLoginFailure(error))
  }
}

function* tableauLogoutWorker({
  tokenId,
  onLogoutSuccess,
  onLogoutError,
  data
}) {
  try {
    yield call(tableauService.logoutTableauService, tokenId, data)

    yield put(tableauLogoutSuccess({ status: 'success' }))
    onLogoutSuccess()
  } catch (error) {
    yield put(tableauLogoutFailure(error))
    onLogoutError(error)
  }
}

function* getTableauViews({ params }) {
  try {
    const response = yield call(tableauService.getTableauViews, params)

    yield put({
      type: types.GET_TABLEAU_VIEWS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_TABLEAU_VIEWS_ERROR,
      payload: error
    })
  }
}

function* getTableauWorkbooks({ params }) {
  try {
    const response = yield call(tableauService.getTableauWorkbooks, params)

    yield put({
      type: types.GET_TABLEAU_WORKBOOK_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_TABLEAU_WORKBOOK_ERROR,
      payload: error
    })
  }
}
