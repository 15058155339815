import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'

import { allConfigFeatureMediaSelector } from 'selectors/configSelectors'
import { getAllConfigFeatureMedia } from 'actions/configActions'

export default function useMediaFeaturesList() {
  const dispatch = useDispatch()

  const { response, isFetching } = useSelector(allConfigFeatureMediaSelector)

  useEffect(() => {
    if (!response.length && !isFetching) {
      dispatch(getAllConfigFeatureMedia())
    }
    // eslint-disable-next-line
  }, [])

  return useMemo(
    () => ({
      features: response,
      isFetching
    }),
    [response, isFetching]
  )
}
