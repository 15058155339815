import React, { useMemo } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import classNames from 'classnames'

import { DATE_VIEW_FORMAT } from 'constants/dateTimeFormats'
import PropTypes from 'prop-types'
import useRegionalSettings from 'hooks/useRegionalSettings'

const styles = ({ type, typography, colors }) => ({
  text: {
    ...typography.darkAccent[type],
    lineHeight: '20px',
    color: colors.title.primary[type],
    '&[data-24-time-format="false"]': {
      fontWeight: 700
    },
    '&[data-24-time-format="true"]': {
      fontWeight: 400,
      color: colors.light
    }
  },
  textSmall: {
    ...typography.subtitle[type],
    lineHeight: '20px',
    color: colors.light,
    '&[data-24-time-format="false"]': {
      fontWeight: 400,
      color: colors.light
    },
    '&[data-24-time-format="true"]': {
      fontWeight: 700,
      color: colors.title.primary[type]
    }
  },
  grey: {
    color: 'rgb(148, 148, 148)'
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  textRow: {
    marginLeft: 5,
    ...typography.darkAccent[type],
    color: colors.title.primary[type],
    fontSize: 12
  },
  reversedRow: {
    flexDirection: 'row-reverse'
  },
  fontWeightNormal: {
    fontWeight: '400 !important'
  }
})

const DateTimeView = ({
  date,
  classes,
  onlyDate = false,
  isUTC = false,
  isUnix = false,
  withSeparator = false,
  separator = '|',
  fallback = 'N/A',
  textClass,
  separatorClass,
  textSmallClass,
  rootClassName,
  timeFormat = 'h:mmA',
  inverseDisplay = false,
  printAsUTC = false,
  direction = 'column',
  reversedRow,
  timeFontWeight = 'bold',
  timeFallback,
  withTimeZone = true,
  fallbackClass = ''
}) => {
  const { convertedTimeFormat } = useRegionalSettings()
  const { formattedDate, formattedTime } = useMemo(() => {
    const res = {
      formattedDate: null,
      formattedTime: null
    }
    if (date) {
      let dateTime = moment(date)
      if (isUTC) {
        dateTime = moment.utc(date).local()
      }
      if (printAsUTC) {
        dateTime = moment.utc(date)
      }
      if (isUnix) {
        dateTime = moment.unix(date)
      }

      const preparedTimeFormat = convertedTimeFormat(timeFormat)

      res.formattedDate = dateTime.format(DATE_VIEW_FORMAT)
      res.formattedTime = dateTime.format(preparedTimeFormat)

      if (timeFallback && dateTime.format('HH:mm:ss') === '00:00:00') {
        res.formattedTime = moment(timeFallback, 'HH:mm:ss').format(
          preparedTimeFormat
        )
      }
    }
    return res
  }, [
    date,
    isUTC,
    isUnix,
    printAsUTC,
    timeFormat,
    convertedTimeFormat,
    timeFallback
  ])

  const isLast24hrs = useMemo(() => {
    const isLast24 =
      moment().diff(moment(date), 'hours') <= 24 &&
      moment().diff(moment(date), 'hours') >= -24
    return inverseDisplay ? !isLast24 : isLast24
  }, [date, inverseDisplay])

  if (!date) {
    return (
      <Grid>
        <Typography
          className={classNames(classes.text, classes.grey, fallbackClass, {
            [classes.textRow]: direction === 'row'
          })}
        >
          {fallback}
        </Typography>
      </Grid>
    )
  }

  const tz = withTimeZone
    ? momentTZ.tz(printAsUTC ? 'UTC' : momentTZ.tz.guess()).zoneAbbr()
    : ''

  return (
    <Grid
      component="span"
      className={classNames(rootClassName, {
        [classes.containerRow]: direction === 'row',
        [classes.reversedRow]: reversedRow
      })}
    >
      <Typography
        data-24-time-format={isLast24hrs}
        component="span"
        className={classNames(
          classes.text,
          {
            [classes.textRow]: direction === 'row',
            [classes.fontWeightNormal]:
              inverseDisplay && !onlyDate && timeFontWeight === 'normal'
          },
          textClass
        )}
      >
        {inverseDisplay && !onlyDate ? formattedTime + ' ' + tz : formattedDate}
      </Typography>
      {withSeparator && (
        <Typography
          component="span"
          className={classNames(
            classes.text,
            {
              [classes.textRow]: direction === 'row',
              [classes.fontWeightNormal]:
                (inverseDisplay && !onlyDate && timeFontWeight === 'normal') ||
                (!inverseDisplay && timeFontWeight === 'normal')
            },
            textClass,
            separatorClass
          )}
        >
          {separator}
        </Typography>
      )}
      <Typography
        data-24-time-format={isLast24hrs}
        component="span"
        className={classNames(
          classes.text,
          classes.textSmall,
          {
            [classes.textRow]: direction === 'row',
            [classes.fontWeightNormal]:
              !inverseDisplay && timeFontWeight === 'normal'
          },
          textSmallClass
        )}
      >
        {!onlyDate &&
          (inverseDisplay ? formattedDate : formattedTime + ' ' + tz)}
      </Typography>
    </Grid>
  )
}

DateTimeView.propTypes = {
  direction: PropTypes.oneOf(['row', 'column']),
  timeFallback: PropTypes.string
}

export default withStyles(styles)(DateTimeView)
