import { useEffect, useState } from 'react'
import posthog from 'posthog-js'
import { useLocation } from 'react-router'

import useUserDetails from 'hooks/useUserDetails'
import { getOriginalUser } from 'utils/localStorage'
import { instanceTypes, LOGOUT_EVENT_NAME, ORG_ROLE } from 'constants/api'

const resetUser = () => {
  if (posthog?.persistence && !posthog.persistence.disabled) {
    posthog.opt_out_capturing()
    posthog.reset()
  }
}

const enableTracking = () => {
  if (posthog.has_opted_out_capturing()) {
    posthog.opt_in_capturing()
  }
}

export default function usePostHog() {
  const location = useLocation()
  const userDetails = useUserDetails()
  const [identifiedId, setIdentifiedId] = useState()

  const identifyUser = () => {
    if (userDetails?.id !== identifiedId) {
      setIdentifiedId(userDetails.id)
      posthog.identify(userDetails.id, { userId: userDetails.id })
    }
  }

  useEffect(() => {
    document.addEventListener(LOGOUT_EVENT_NAME, resetUser)
    return () => {
      document.removeEventListener(LOGOUT_EVENT_NAME, resetUser)
    }
  }, [])

  useEffect(() => {
    const impersonatedData = getOriginalUser()
    if (
      process.env.REACT_APP_INSTANCE === instanceTypes.prod &&
      !impersonatedData &&
      userDetails?.role?.level === ORG_ROLE &&
      process.env.REACT_APP_POSTHOG_APP_ID &&
      process.env.REACT_APP_POSTHOG_API_HOST
    ) {
      if (!posthog?.persistence) {
        posthog.init(process.env.REACT_APP_POSTHOG_APP_ID, {
          api_host: process.env.REACT_APP_POSTHOG_API_HOST,
          autocapture: false,
          loaded: () => {
            enableTracking()
            identifyUser()
          }
        })
      } else {
        enableTracking()
        identifyUser()
      }
    }
    // eslint-disable-next-line
  }, [userDetails])

  useEffect(() => {
    posthog.capture('navigate')
  }, [location.pathname])
}
