import { useCallback, useEffect, useState } from 'react'

const useUppyProgress = (uppy, innitProgress = 0) => {
  const [progress, setProgress] = useState(innitProgress)
  const onProgress = useCallback(
    progress => {
      const currentProgress = progress === 100 ? 0 : progress
      setProgress(currentProgress)
    },
    [setProgress]
  )

  useEffect(() => {
    uppy.on('progress', onProgress)
    return () => {
      uppy.off('progress', onProgress)
    }
  }, [uppy, onProgress])

  return progress
}

export default useUppyProgress
