import update from 'immutability-helper'

import * as types from '../../actions'
import {
  deleteInitialState,
  initialEntityState,
  postInitialState,
  putInitialState,
  shapeOfBody,
  shapeOfBodyWithMeta
} from '../../constants/initialLibraryState'
import { convertToPreviewData } from '../../utils/designGalleryUtils'
import { DESIGN_TYPES, previewGridTypes } from 'constants/canvasConstants'

const initialState = {
  preset: {
    ...initialEntityState,
    stock: {
      ...shapeOfBodyWithMeta
    },
    shared: {
      ...shapeOfBodyWithMeta
    },
    sharePost: {
      ...shapeOfBody,
      label: 'share'
    }
  },
  component: {
    ...initialEntityState,
    stock: {
      ...shapeOfBodyWithMeta
    },
    shared: {
      ...shapeOfBodyWithMeta
    },
    sharePost: {
      ...shapeOfBody,
      label: 'share'
    }
  },
  selectedComponent: null,
  selectedPreset: null,
  selectedBoard: null,
  isBottomSidebarOpen: false,
  board: {
    ...initialEntityState
  },
  preview: {
    id: null,
    isVisible: false,
    isLoading: false,
    response: null,
    error: null,
    key: ''
  },
  isMenuBoardEdit: false
}

const menuDesignReducer = (state = initialState, action) => {
  const { payload } = action
  let data = []
  switch (action.type) {
    case types.GET_MENU_PRESETS:
      return update(state, {
        preset: {
          items: {
            meta: { $set: { isLoading: true } },
            ...(data.page === 1 && { response: { $set: [] } })
          }
        }
      })
    case types.GET_MENU_PRESETS_SUCCESS:
      data = convertToPreviewData(
        payload.data,
        previewGridTypes.myDesign,
        false,
        DESIGN_TYPES.My
      )
      return update(state, {
        preset: {
          items: {
            response: {
              $set:
                payload.meta?.currentPage > 1
                  ? [...state.preset.items.response, ...data]
                  : data
            },
            meta: { $set: payload.meta }
          }
        }
      })
    case types.GET_MENU_PRESETS_ERROR:
      return update(state, {
        preset: {
          items: {
            meta: { $set: { isLoading: false } }
          }
        }
      })
    case types.GET_STOCK_MENU_PRESETS:
      return update(state, {
        preset: {
          stock: {
            meta: { $set: { isLoading: true } },
            ...(data.page === 1 && { response: { $set: [] } })
          }
        }
      })
    case types.GET_STOCK_MENU_PRESETS_SUCCESS:
      data = convertToPreviewData(
        payload.data,
        previewGridTypes.template,
        false,
        DESIGN_TYPES.Stock
      )
      return update(state, {
        preset: {
          stock: {
            response: {
              $set:
                payload.meta?.currentPage > 1
                  ? [...state.preset.stock.response, ...data]
                  : data
            },
            meta: { $set: payload.meta }
          }
        }
      })
    case types.GET_STOCK_MENU_PRESETS_ERROR:
      return update(state, {
        preset: {
          stock: {
            meta: { $set: { isLoading: false } }
          }
        }
      })
    case types.GET_SHARED_MENU_PRESETS:
      return update(state, {
        preset: {
          shared: {
            meta: { $set: { isLoading: true } },
            ...(data.page === 1 && { response: { $set: [] } })
          }
        }
      })
    case types.GET_SHARED_MENU_PRESETS_SUCCESS:
      data = convertToPreviewData(
        payload.data,
        previewGridTypes.template,
        false,
        DESIGN_TYPES.Shared
      )
      return update(state, {
        preset: {
          shared: {
            response: {
              $set:
                payload.meta?.currentPage > 1
                  ? [...state.preset.shared.response, ...data]
                  : data
            },
            meta: { $set: payload.meta }
          }
        }
      })
    case types.GET_SHARED_MENU_PRESETS_ERROR:
      return update(state, {
        preset: {
          shared: {
            meta: { $set: { isLoading: false } }
          }
        }
      })
    case types.GET_MENU_PRESET_ITEM_SUCCESS:
      return update(state, {
        preset: {
          item: {
            response: { $set: action.payload },
            isEdit: { $set: action.isEdit }
          }
        }
      })
    case types.GET_MENU_PRESET_ITEM_ERROR:
      return update(state, {
        preset: {
          item: {
            error: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_PRESET_SUCCESS:
      return update(state, {
        preset: {
          post: {
            response: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_PRESET_ERROR:
      return update(state, {
        preset: {
          post: {
            error: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_PRESET_SUCCESS:
      return update(state, {
        preset: {
          put: {
            response: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_PRESET_ERROR:
      return update(state, {
        preset: {
          put: {
            error: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_PRESET:
      return update(state, {
        preset: {
          del: {
            id: { $set: action.id },
            name: { $set: action.name }
          }
        }
      })
    case types.DELETE_MENU_PRESET_SUCCESS:
      return update(state, {
        preset: {
          del: {
            response: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_PRESET_ERROR:
      return update(state, {
        preset: {
          del: {
            error: { $set: action.payload }
          }
        }
      })
    case types.SHARE_MENU_PRESET_SUCCESS:
      return update(state, {
        preset: {
          sharePost: {
            response: { $set: action.payload }
          }
        }
      })
    case types.SHARE_MENU_PRESET_ERROR:
      return update(state, {
        preset: {
          sharePost: {
            error: { $set: action.payload }
          }
        }
      })
    case types.CLEAR_MENU_PRESET_RESPONSE_INFO:
      return update(state, {
        preset: {
          item: {
            $set: {
              ...shapeOfBody
            }
          },
          post: {
            $set: {
              ...postInitialState
            }
          },
          put: {
            $set: {
              ...putInitialState
            }
          },
          del: {
            $set: {
              ...deleteInitialState
            }
          },
          sharePost: {
            $set: {
              ...shapeOfBody,
              label: 'share'
            }
          }
        }
      })
    case types.GET_MENU_COMPONENTS:
      return update(state, {
        component: {
          items: {
            meta: { $set: { isLoading: true } },
            ...(data.page === 1 && { response: { $set: [] } })
          }
        }
      })
    case types.GET_MENU_COMPONENTS_SUCCESS:
      data = convertToPreviewData(
        payload.data,
        previewGridTypes.component,
        false,
        DESIGN_TYPES.My
      )
      return update(state, {
        component: {
          items: {
            response: {
              $set:
                payload.meta?.currentPage > 1
                  ? [...state.component.items.response, ...data]
                  : data
            },
            meta: { $set: payload.meta }
          }
        }
      })
    case types.GET_MENU_COMPONENTS_ERROR:
      return update(state, {
        component: {
          items: {
            meta: { $set: { isLoading: false } }
          }
        }
      })
    case types.GET_STOCK_MENU_COMPONENTS:
      return update(state, {
        component: {
          stock: {
            meta: { $set: { isLoading: true } },
            ...(data.page === 1 && { response: { $set: [] } })
          }
        }
      })
    case types.GET_STOCK_MENU_COMPONENTS_SUCCESS:
      data = convertToPreviewData(
        payload.data,
        previewGridTypes.component,
        false,
        DESIGN_TYPES.Stock
      )
      return update(state, {
        component: {
          stock: {
            response: {
              $set:
                payload.meta?.currentPage > 1
                  ? [...state.component.stock.response, ...data]
                  : data
            },
            meta: { $set: payload.meta }
          }
        }
      })
    case types.GET_STOCK_MENU_COMPONENTS_ERROR:
      return update(state, {
        component: {
          stock: {
            meta: { $set: { isLoading: false } }
          }
        }
      })
    case types.GET_SHARED_MENU_COMPONENTS:
      return update(state, {
        component: {
          shared: {
            meta: { $set: { isLoading: true } },
            ...(data.page === 1 && { response: { $set: [] } })
          }
        }
      })
    case types.GET_SHARED_MENU_COMPONENTS_SUCCESS:
      data = convertToPreviewData(
        payload.data,
        previewGridTypes.component,
        false,
        DESIGN_TYPES.Shared
      )
      return update(state, {
        component: {
          shared: {
            response: {
              $set:
                payload.meta?.currentPage > 1
                  ? [...state.component.shared.response, ...data]
                  : data
            },
            meta: { $set: payload.meta }
          }
        }
      })
    case types.GET_SHARED_MENU_COMPONENTS_ERROR:
      return update(state, {
        component: {
          shared: {
            meta: { $set: { isLoading: false } }
          }
        }
      })
    case types.GET_MENU_COMPONENT_ITEM_SUCCESS:
      return update(state, {
        component: {
          item: {
            response: { $set: action.payload },
            isEdit: { $set: action.isEdit }
          }
        }
      })
    case types.GET_MENU_COMPONENT_ITEM_ERROR:
      return update(state, {
        component: {
          item: {
            error: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_COMPONENT_SUCCESS:
      return update(state, {
        component: {
          post: {
            response: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_COMPONENT_ERROR:
      return update(state, {
        component: {
          post: {
            error: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_COMPONENT_SUCCESS:
      return update(state, {
        component: {
          put: {
            response: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_COMPONENT_ERROR:
      return update(state, {
        component: {
          put: {
            error: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_COMPONENT:
      return update(state, {
        component: {
          del: {
            id: { $set: action.id },
            name: { $set: action.name }
          }
        }
      })
    case types.DELETE_MENU_COMPONENT_SUCCESS:
      return update(state, {
        component: {
          del: {
            response: { $set: action.payload }
          }
        }
      })
    case types.DELETE_MENU_COMPONENT_ERROR:
      return update(state, {
        component: {
          del: {
            error: { $set: action.payload }
          }
        }
      })
    case types.SHARE_MENU_COMPONENT_SUCCESS:
      return update(state, {
        component: {
          sharePost: {
            response: { $set: action.payload }
          }
        }
      })
    case types.SHARE_MENU_COMPONENT_ERROR:
      return update(state, {
        component: {
          sharePost: {
            error: { $set: action.payload }
          }
        }
      })
    case types.SET_OPEN_BOTTOM_SIDEBAR:
      return update(state, {
        isBottomSidebarOpen: { $set: payload }
      })
    case types.SELECT_MENU_COMPONENT:
      return update(state, {
        selectedComponent: { $set: payload }
      })
    case types.SELECT_MENU_PRESET:
      return update(state, {
        selectedPreset: { $set: payload }
      })
    case types.CLEAR_MENU_COMPONENT_RESPONSE_INFO:
      return update(state, {
        component: {
          item: {
            $set: {
              ...shapeOfBody
            }
          },
          post: {
            $set: {
              ...postInitialState
            }
          },
          put: {
            $set: {
              ...putInitialState
            }
          },
          del: {
            $set: {
              ...deleteInitialState
            }
          },
          sharePost: {
            $set: {
              ...shapeOfBody,
              label: 'share'
            }
          }
        }
      })

    case types.POST_MENU_BOARD_SUCCESS:
      return update(state, {
        board: {
          post: {
            response: { $set: action.payload }
          }
        }
      })
    case types.POST_MENU_BOARD_ERROR:
      return update(state, {
        board: {
          post: {
            error: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_BOARD_SUCCESS:
      return update(state, {
        board: {
          put: {
            response: { $set: action.payload }
          }
        }
      })
    case types.PUT_MENU_BOARD_ERROR:
      return update(state, {
        board: {
          put: {
            error: { $set: action.payload }
          }
        }
      })
    case types.CLEAR_MENU_BOARD_RESPONSE_INFO:
      return update(state, {
        board: {
          post: {
            $set: {
              ...postInitialState
            }
          },
          put: {
            $set: {
              ...putInitialState
            }
          }
        }
      })
    case types.GET_MENU_BOARD:
      return update(state, {
        board: {
          items: {
            meta: { isLoading: { $set: true } },
            ...(data.page === 1 && { response: { $set: [] } })
          }
        }
      })
    case types.GET_MENU_BOARD_SUCCESS:
      data = convertToPreviewData(
        payload.response.data,
        previewGridTypes.myDesign,
        false,
        DESIGN_TYPES.My
      )
      return update(state, {
        board: {
          items: {
            response: {
              $set:
                action.modifiedMeta?.currentPage > 1
                  ? [...state.board.items.response, ...data]
                  : data
            },
            meta: { $set: action.modifiedMeta }
          }
        }
      })
    case types.GET_MENU_BOARD_ERROR:
      return update(state, {
        board: {
          items: {
            meta: { $set: { isLoading: false } }
          }
        }
      })
    case types.SET_MENU_BOARD_ITEM: {
      const boardResponse = state.board.items.response
      const itemIndex = (boardResponse || []).findIndex(
        ({ id }) => id === payload.id
      )
      return update(state, {
        board: {
          items: {
            response: {
              $set:
                itemIndex > -1
                  ? [
                      ...boardResponse.slice(0, itemIndex),
                      payload,
                      ...boardResponse.slice(itemIndex + 1)
                    ]
                  : boardResponse
            }
          }
        }
      })
    }
    case types.SET_MENU_PRESET: {
      const presetResponse = state.preset.items.response
      const itemIndex = (presetResponse || []).findIndex(
        ({ id }) => id === payload.id
      )
      return update(state, {
        preset: {
          items: {
            response: {
              $set:
                itemIndex > -1
                  ? [
                      ...presetResponse.slice(0, itemIndex),
                      payload,
                      ...presetResponse.slice(itemIndex + 1)
                    ]
                  : presetResponse
            }
          }
        }
      })
    }
    case types.SET_MENU_BOARD_ITEM_FROM_MEDIA:
      data = convertToPreviewData(
        [payload],
        previewGridTypes.myDesign,
        false,
        DESIGN_TYPES.My
      )?.[0]
      const boardResponse = state.board.items.response
      const itemIndex = (boardResponse || []).findIndex(
        ({ id }) => id === data.id
      )
      return update(state, {
        board: {
          items: {
            response: {
              $set:
                itemIndex > -1
                  ? [
                      ...boardResponse.slice(0, itemIndex),
                      data,
                      ...boardResponse.slice(itemIndex + 1)
                    ]
                  : boardResponse
            }
          }
        }
      })
    case types.GET_MENU_DESIGN_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: null },
          isZoomToFit: { $set: true },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: false }
        }
      })
    case types.GET_MENU_DESIGN_PREVIEW_SUCCESS:
      return update(state, {
        preview: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_MENU_DESIGN_PREVIEW_ERROR:
      return update(state, {
        preview: {
          isVisible: { $set: false },
          isLoading: { $set: false },
          error: { $set: action.payload },
          meta: { $set: action.meta }
        }
      })
    case types.CLOSE_MENU_DESIGN_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: false }
        }
      })
    case types.CLEAR_MENU_DESIGN_PREVIEW:
      return update(state, {
        preview: {
          $merge: {
            id: null,
            isVisible: false,
            isLoading: false,
            response: null,
            error: null,
            key: ''
          }
        }
      })
    case types.SELECT_MENU_BOARD:
      return update(state, {
        selectedBoard: { $set: payload }
      })
    case types.SET_MENU_BOARD_EDIT:
      return update(state, {
        isMenuBoardEdit: { $set: payload }
      })
    default:
      return state
  }
}

export default menuDesignReducer
