import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import { Text } from 'components/Typography'
import Tooltip from 'components/Tooltip'
import { insetColons } from 'utils/macAddressUtils'
import { useUserRole } from 'hooks/tableLibrary'

const useStyles = makeStyles(theme => ({
  macAddressLineThrough: {
    textDecoration: 'line-through',
    textDecorationColor: '#ff0000'
  }
}))

const VISIBLE_ROWS = 3

const DeviceMacAddressViewAgCell = ({ data }) => {
  const classes = useStyles()
  const role = useUserRole()

  const { macAddress, macAddressReuse } = data

  const rows = useMemo(
    () => [
      ...(role.system
        ? macAddressReuse?.map(data => ({ ...data, reuse: true })) || []
        : []),
      ...(macAddress || [])
    ],
    [macAddressReuse, macAddress, role]
  )

  const renderRow = ({ id, macAddress, reuse }, tooltip) => (
    <Text
      key={id}
      variant="small"
      {...(reuse && { rootClassName: classes.macAddressLineThrough })}
      {...(tooltip && { color: 'white' })}
    >
      {insetColons(macAddress)}
    </Text>
  )

  return (
    <Tooltip
      arrow
      disableHoverListener={rows.length <= VISIBLE_ROWS}
      title={
        <Grid container direction="column">
          {rows.slice(2).map(row => renderRow(row, true))}
        </Grid>
      }
    >
      <Grid container direction="column">
        {rows
          .slice(
            0,
            rows.length > VISIBLE_ROWS ? VISIBLE_ROWS - 1 : VISIBLE_ROWS
          )
          .map(row => renderRow(row, false))}
        {rows.length > VISIBLE_ROWS && <Text>...</Text>}
      </Grid>
    </Tooltip>
  )
}

export default DeviceMacAddressViewAgCell
