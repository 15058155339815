import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _debounce } from 'utils/lodash'

import { FormControlSelectWithSearch } from 'components/Form/index'
import { getLocation } from 'actions/configActions'

const FormControlSelectLocations = ({
  value,
  locationId,
  onChange,
  ...props
}) => {
  const dispatchAction = useDispatch()
  const [receive, toggleReceive] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const locations = useSelector(
    ({ config: { locations } }) => locations.response
  )

  const handleLocationInputChange = useMemo(
    () =>
      _debounce(value => {
        if (value && !value.label && value.length > 1) {
          const type = 'search'

          dispatchAction(
            getLocation(
              {
                [type]: value
              },
              true
            )
          )
          setLoading(true)
        }
      }, 500),
    [dispatchAction]
  )

  const handleChange = useCallback(
    e => onChange({ target: { ...e.target, value: e.target.val } }),
    [onChange]
  )

  useEffect(() => {
    if (!receive && locationId) {
      dispatchAction(
        getLocation(
          {
            id: locationId
          },
          true
        )
      )
      setLoading(true)
      toggleReceive(true)
    }
    // eslint-disable-next-line
  }, [locationId])

  useEffect(() => {
    setLoading(false)
  }, [locations])

  const locationOptions = useMemo(
    () =>
      locations.map(
        ({
          formattedAddress,
          placeId,
          latitude,
          longitude,
          name,
          ...data
        }) => ({
          label: `${formattedAddress}`,
          value: `${formattedAddress}`,
          val: `${formattedAddress}`,
          data: {
            latitude,
            longitude,
            placeId,
            city: name,
            formattedAddress,
            ...data
          }
        })
      ),
    [locations]
  )

  return (
    <FormControlSelectWithSearch
      options={locationOptions}
      handleInputChange={handleLocationInputChange}
      value={value}
      onChange={handleChange}
      isClearable
      isLoading={isLoading}
      filterOption={() => true}
      {...props}
    />
  )
}

export default FormControlSelectLocations
