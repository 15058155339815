import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getUsers } from 'services/search/users'
import {
  USERS_FILTER_PENDING,
  USERS_PENDING,
  usersFailure,
  usersFilterFailure,
  usersFilterSuccess,
  usersSuccess
} from 'actions/new/search'

export default function* searchWatcher() {
  yield all([
    takeEvery(USERS_PENDING, getUsersWorker),
    takeEvery(USERS_FILTER_PENDING, getUsersFilterWorker)
  ])
}

function* getUsersWorker({ limit }) {
  try {
    const { data, meta } = yield call(getUsers, { limit })
    yield put(usersSuccess({ data, meta }))
  } catch (error) {
    yield put(usersFailure(error))
  }
}

function* getUsersFilterWorker({ firstName, limit }) {
  try {
    const { data, meta } = yield call(getUsers, { limit, firstName })
    yield put(usersFilterSuccess({ data, meta }))
  } catch (error) {
    yield put(usersFilterFailure(error))
  }
}
