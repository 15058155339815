import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import PhoneInput from 'react-phone-input-2'
import { isValidPhoneNumber } from 'libphonenumber-js'

import FormControlLabel from 'components/Form/FormControlLabel'
import { simulateEvent } from 'utils/formik'

import 'react-phone-input-2/lib/style.css'
import 'styles/forms/_tel-input.scss'
import { getPlaceholderByCountryCode } from 'utils/phoneUtils'

const styles = ({
  palette,
  type,
  typography,
  transitions,
  fontSize,
  fontWeight,
  lineHeight,
  shapes,
  colors,
  formControls,
  spacing
}) => {
  return {
    root: {
      position: 'relative',
      '& .react-tel-input .form-control': {
        width: '100%',
        height: shapes.height.primary,
        borderRadius: 4,
        position: 'relative',
        backgroundColor: palette[type].formControls.input.background,
        border: `1px solid ${palette[type].formControls.input.border}`,
        color: palette[type].formControls.input.color,
        fontSize: fontSize.small,
        lineHeight: lineHeight.small,
        fontWeight: fontWeight.small,
        fontFamily: typography.fontFamily,
        paddingRight: spacing(1),
        transition: transitions.create(['border-color', 'box-shadow']),
        paddingLeft: formControls.input.paddingLeft + 37,

        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        },

        '&::placeholder': {
          color: colors.light,
          ...formControls.placeholder
        }
      },
      '& .react-tel-input .selected-flag': {
        paddingLeft: formControls.input.paddingLeft
      },
      '& .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus': {
        backgroundColor: `${palette[type].formControls.select.border} !important`
      }
    },
    disabled: {
      '& .react-tel-input .form-control, & .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus': {
        background: palette[type].formControls.disabled.background
      }
    },
    rootMargin: {
      marginBottom: 16
    },
    errorField: {
      borderBottom: `1px solid ${colors.error} !important`
    },
    optionalErrorField: {
      borderBottom: `1px solid ${colors.optionalError} !important`
    },
    buttonContainerClassName: {
      backgroundColor: 'transparent !important',
      borderRight: 'none !important',
      borderRadius: '5px 0 0 5px !important',
      borderColor: 'transparent !important'
    },
    dropdownContainerClassName: {
      width: '210px !important',
      backgroundColor: `${palette[type].formControls.input.background} !important`,
      color: `${palette[type].formControls.input.color} !important`,
      fontSize: `${fontSize.primary}px !important`,
      '&::-webkit-scrollbar': {
        width: '5px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: type === 'dark' ? '#BABABA50' : '#25252550',
        borderRadius: '5px'
      },
      '& li': {
        '& > span': {
          fontSize: `${fontSize.primary}px !important`
        },
        '&:hover,&.highlight': {
          backgroundColor: `${palette[type].formControls.select.border} !important`
        }
      }
    },
    error: {
      color: colors.error,
      fontSize: 10,
      position: 'absolute',
      bottom: -14,
      left: 5,
      height: '14px'
    },
    static: {
      position: 'static',
      lineHeight: '16px'
    },
    optionalError: {
      color: colors.highlight
    }
  }
}

const prefix = '+'

const FormControlTelInput = ({
  classes,
  label = '',
  customClass = {},
  marginBottom = true,
  name,
  value = '',
  isOptional = false,
  absoluteErrorText = true,
  error,
  touched = false,
  onBlur = f => f,
  onChange = f => f,
  disabled
}) => {
  const {
    root: rootClass = '',
    container: containerClass = '',
    label: labelClass = '',
    input: inputClass = '',
    dropDown: dropDownClass = ''
  } = customClass
  const inputRef = useRef(null)
  const [phoneState, setPhoneState] = useState({
    country: 'us',
    dialCode: 1
  })

  const handleBlur = useCallback(() => {
    onBlur({ target: { name } })
  }, [name, onBlur])

  const handleChange = useCallback(
    (number, data, e) => {
      const eventValue =
        !value && number && !isValidPhoneNumber(`${prefix}${number}`)
          ? `${prefix}${data.dialCode}${number}`
          : e.target.value
      const phoneValue = number ? eventValue : !value ? prefix : ''

      if (data) {
        setPhoneState(data)
      }

      if (e.type === 'click') {
        onChange(simulateEvent(name, ''))
        if (inputRef.current) {
          inputRef.current.setState({ formattedNumber: '' })
        }
      } else {
        onChange(simulateEvent(name, phoneValue.replace(' ', '')))
      }
    },
    [name, value, onChange, inputRef]
  )

  const isError = useMemo(() => error && touched, [error, touched])

  //remove initial '+1' if no value
  useEffect(() => {
    if (!value && inputRef.current?.state.formattedNumber) {
      inputRef.current.setState({ formattedNumber: '' })
    }
    // eslint-disable-next-line
  }, [inputRef])

  //prevent cursor jumping on number delete
  const autoFormat = useMemo(() => {
    if (!value) {
      return true
    }
    if (inputRef.current) {
      return (
        value.startsWith(
          `${prefix}${inputRef.current.state.selectedCountry.dialCode}`
        ) || value.length > 4
      )
    }
  }, [value, inputRef])

  const placeholder = useMemo(() => {
    const { dialCode = 1, countryCode } = phoneState || {}
    return `${prefix}${dialCode} ${getPlaceholderByCountryCode(countryCode)}`
  }, [phoneState])

  return (
    <Grid
      container
      wrap="wrap"
      direction="column"
      className={classNames(classes.root, rootClass, {
        [classes.rootMargin]: marginBottom,
        [classes.disabled]: disabled
      })}
    >
      <FormControlLabel
        label={label}
        isOptional={isOptional}
        rootClassName={labelClass}
      />
      <PhoneInput
        ref={inputRef}
        value={value || ''}
        country={phoneState?.country}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        countryCodeEditable
        jumpCursorToEnd
        autoFormat={autoFormat}
        alwaysDefaultMask
        defaultMask=".............."
        prefix={prefix}
        containerClass={classNames(containerClass)}
        inputClass={classNames(classes.inputClassName, inputClass, {
          [classes.errorField]: isError,
          [classes.optionalErrorField]: isError && isOptional
        })}
        buttonClass={classes.buttonContainerClassName}
        dropdownClass={classNames(
          classes.dropdownContainerClassName,
          dropDownClass
        )}
      />
      {error && touched && (
        <Typography
          className={classNames(classes.error, {
            [classes.optionalError]: isOptional,
            [classes.static]: !absoluteErrorText
          })}
        >
          {error}
        </Typography>
      )}
    </Grid>
  )
}

FormControlTelInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  isOptional: PropTypes.bool,
  absoluteErrorText: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool
}

export default withStyles(styles)(FormControlTelInput)
