import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { _get } from 'utils/lodash'

import { userDetailsSelectorMemoized } from 'selectors/userSelectors'

if (process.env.NODE_ENV === 'development') {
  console.warn(
    '"useDeterminePermissions" is deprecated. Check the hook for more info'
  )
}

//DEPRECATED. Use the "usePermissions" instead with individual permission names
const useDeterminePermissions = findPermission => {
  const { response } = useSelector(userDetailsSelectorMemoized)

  return useMemo(() => {
    return Array.isArray(findPermission)
      ? findPermission.map(permission =>
          _get(response, `permissions[${permission}]`, {})
        )
      : _get(response, `permissions[${findPermission}]`, {})
  }, [response, findPermission])
}

export const withDeterminePermissions = findPermission => Component => props => {
  const permissions = useDeterminePermissions(findPermission)

  return <Component {...props} permissions={permissions} />
}

export default useDeterminePermissions
