import React, { useCallback, useMemo } from 'react'
import { Route, useHistory, useLocation } from 'react-router'
import { AlarmAdd } from '@material-ui/icons'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { compose } from '@reduxjs/toolkit'

import { getUrlPrefix } from 'utils'
import { permissionNames, routeByName } from 'constants/index'
import AlarmSettingsModal from './AlarmSettingsModal'
import { CircleIconButton } from 'components/Buttons'
import Tooltip from 'components/Tooltip'
import { DEVICE_NOC_ALERT_FEATURE } from 'constants/featureConstants'
import { isFeatureAvailable } from 'utils/api/featureAvailability'
import usePermissions from 'hooks/api/usePermissions'

const styles = () => ({
  circleButton: {
    color: '#afb7c7',
    '&:hover, &.active': {
      color: '#1c5dca'
    }
  }
})

const AlarmSettings = ({ classes, t }) => {
  const location = useLocation()
  const history = useHistory()
  const { getPermissionByName } = usePermissions()

  const permissions = useMemo(
    () => ({
      read: getPermissionByName(permissionNames.DEVICE_ALARM_NOTIFICATION_SHOW)
    }),
    [getPermissionByName]
  )

  const viewType = useMemo(() => {
    const pathSplit = location.pathname.split('/')
    const index = pathSplit.indexOf('device-noc')
    return pathSplit[index + 1]
  }, [location])

  const handleClickIcon = useCallback(() => {
    history.push(getUrlPrefix(routeByName.deviceNOC.goToAlertAlarm(viewType)))
  }, [history, viewType])

  return isFeatureAvailable(DEVICE_NOC_ALERT_FEATURE) && permissions.read ? (
    <>
      <Tooltip arrow title={t('Alarm add tooltip title')}>
        <CircleIconButton
          className={`hvr-grow ${classes.circleButton}`}
          onClick={handleClickIcon}
        >
          <AlarmAdd />
        </CircleIconButton>
      </Tooltip>
      <Route
        path={getUrlPrefix(routeByName.deviceNOC.alertAlarm)}
        render={props => <AlarmSettingsModal {...props} />}
      />
    </>
  ) : null
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(AlarmSettings)
