import React, { memo } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import { withStyles } from '@material-ui/styles'

import activeServer from 'common/assets/icons/server-active.svg'
import { TextWithTooltip } from 'components/Typography'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    whiteSpace: 'unset',
    lineHeight: '14px',
    margin: '3px 0px',
    fontSize: 11
  }
})

const ServerNodeType = ({ data, classes }) => {
  return (
    <div className={classes.root}>
      <img src={activeServer} alt="server" width="20" height="20" />
      <TextWithTooltip
        rootClassName={classes.text}
        maxWidth={50}
        variant="smaller"
      >
        {data.label}
      </TextWithTooltip>
      <Handle type="target" position={Position.Left} />
    </div>
  )
}

export default withStyles(styles)(memo(ServerNodeType))
