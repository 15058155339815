import { fabric } from 'fabric'
import { _isObject } from 'utils/lodash'
import uuidv4 from 'uuid/v4'
import { transformDesignGalleryFile } from 'utils'
import { rgbaToRgb } from 'utils/rgbaToRgb'
import _isEmpty from 'lodash/isEmpty'
import {
  canvasFontVariantsToLoad,
  fontVariantsMap
} from 'constants/canvasConstants'

export const initCenteringGuidelines = canvas => {
  let canvasWidth = canvas.getWidth(),
    canvasHeight = canvas.getHeight(),
    zoom = canvas.getZoom(),
    canvasWidthCenter = canvasWidth / 2,
    canvasHeightCenter = canvasHeight / 2,
    canvasWidthCenterMap = {},
    canvasHeightCenterMap = {},
    centerLineMargin = 4,
    centerLineColor = 'rgba(255,0,241,0.5)',
    centerLineWidth = 1,
    ctx = canvas.getSelectionContext(),
    viewportTransform

  for (
    let i = canvasWidthCenter - centerLineMargin,
      len = canvasWidthCenter + centerLineMargin;
    i <= len;
    i++
  ) {
    canvasWidthCenterMap[Math.round(i)] = true
  }
  for (
    let i = canvasHeightCenter - centerLineMargin,
      len = canvasHeightCenter + centerLineMargin;
    i <= len;
    i++
  ) {
    canvasHeightCenterMap[Math.round(i)] = true
  }

  function showVerticalCenterLine() {
    showCenterLine(
      canvasWidthCenter / zoom + 0.5,
      0,
      canvasWidthCenter / zoom + 0.5,
      canvasHeight / zoom
    )
  }

  function showHorizontalCenterLine() {
    showCenterLine(
      0,
      canvasHeightCenter / zoom + 0.5,
      canvasWidth / zoom,
      canvasHeightCenter / zoom + 0.5
    )
  }

  function showCenterLine(x1, y1, x2, y2) {
    ctx.save()
    ctx.strokeStyle = centerLineColor
    ctx.lineWidth = centerLineWidth
    ctx.beginPath()
    ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3])
    ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3])
    ctx.stroke()
    ctx.restore()
  }

  let isInVerticalCenter, isInHorizontalCenter

  canvas.on('mouse:down', function () {
    viewportTransform = canvas.viewportTransform
    zoom = canvas.getZoom()
  })

  canvas.on('object:moving', function (e) {
    let object = e.target,
      objectCenter = object.getCenterPoint(),
      transform = canvas._currentTransform

    if (!transform) return

    isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap
    isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap

    if (isInHorizontalCenter || isInVerticalCenter) {
      object.setPositionByOrigin(
        new fabric.Point(
          isInVerticalCenter ? canvasWidthCenter : objectCenter.x,
          isInHorizontalCenter ? canvasHeightCenter : objectCenter.y
        ),
        'center',
        'center'
      )
    }
  })

  canvas.on('before:render', function () {
    if (canvas.contextTop) {
      canvas.clearContext(canvas.contextTop)
    }
  })

  canvas.on('after:render', function () {
    if (isInVerticalCenter) {
      showVerticalCenterLine()
    }
    if (isInHorizontalCenter) {
      showHorizontalCenterLine()
    }
  })

  canvas.on('mouse:up', function () {
    // clear these values, to stop drawing guidelines once mouse is up
    isInVerticalCenter = isInHorizontalCenter = null
    canvas.renderAll()
  })
}

export const initAligningGuidelines = canvas => {
  let ctx = canvas.getSelectionContext(),
    aligningLineOffset = 5,
    aligningLineMargin = 4,
    aligningLineWidth = 1,
    aligningLineColor = '#0a84c9',
    viewportTransform,
    zoom = canvas.getZoom()

  function drawVerticalLine(coords) {
    drawLine(
      coords.x * zoom + 0.5,
      -9999,
      coords.x * zoom + 0.5,
      canvas.height + 9999
    )
  }

  function drawHorizontalLine(coords) {
    drawLine(
      -9999,
      coords.y * zoom + 0.5,
      canvas.width + 9999,
      coords.y * zoom + 0.5
    )
  }

  function drawLine(x1, y1, x2, y2) {
    ctx.save()
    ctx.lineWidth = aligningLineWidth
    ctx.strokeStyle = aligningLineColor
    ctx.beginPath()
    ctx.moveTo(x1 + viewportTransform[4], y1 + viewportTransform[5])
    ctx.lineTo(x2 + viewportTransform[4], y2 + viewportTransform[5])
    ctx.stroke()
    ctx.restore()
  }

  function isInRange(value1, value2) {
    value1 = Math.round(value1)
    value2 = Math.round(value2)
    for (
      let i = value1 - aligningLineMargin, len = value1 + aligningLineMargin;
      i <= len;
      i++
    ) {
      if (i === value2) {
        return true
      }
    }
    return false
  }

  let verticalLines = [],
    horizontalLines = []

  canvas.on('mouse:down', function () {
    viewportTransform = canvas.viewportTransform
    zoom = canvas.getZoom()
  })

  canvas.on('object:moving', function (e) {
    let activeObject = e.target,
      canvasObjects = canvas.getObjects(),
      activeObjectCenter = activeObject.getCenterPoint(),
      activeObjectLeft = activeObjectCenter.x,
      activeObjectTop = activeObjectCenter.y,
      activeObjectBoundingRect = activeObject.getBoundingRect(),
      activeObjectHeight =
        activeObjectBoundingRect.height / viewportTransform[3],
      activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
      horizontalInTheRange = false,
      verticalInTheRange = false,
      transform = canvas._currentTransform

    if (!transform) return

    // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
    // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

    for (let i = canvasObjects.length; i--; ) {
      if (canvasObjects[i] === activeObject) continue

      let objectCenter = canvasObjects[i].getCenterPoint(),
        objectLeft = objectCenter.x,
        objectTop = objectCenter.y,
        objectBoundingRect = canvasObjects[i].getBoundingRect(),
        objectHeight = objectBoundingRect.height / viewportTransform[3],
        objectWidth = objectBoundingRect.width / viewportTransform[0]

      // snap by the horizontal center line
      if (isInRange(objectLeft, activeObjectLeft)) {
        verticalInTheRange = true
        verticalLines.push({
          x: objectLeft,
          y1:
            objectTop < activeObjectTop
              ? objectTop - objectHeight / 2 - aligningLineOffset
              : objectTop + objectHeight / 2 + aligningLineOffset,
          y2:
            activeObjectTop > objectTop
              ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
              : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
        })
        activeObject.setPositionByOrigin(
          new fabric.Point(objectLeft, activeObjectTop),
          'center',
          'center'
        )
      }

      // snap by the left edge
      if (
        isInRange(
          objectLeft - objectWidth / 2,
          activeObjectLeft - activeObjectWidth / 2
        )
      ) {
        verticalInTheRange = true
        verticalLines.push({
          x: objectLeft - objectWidth / 2,
          y1:
            objectTop < activeObjectTop
              ? objectTop - objectHeight / 2 - aligningLineOffset
              : objectTop + objectHeight / 2 + aligningLineOffset,
          y2:
            activeObjectTop > objectTop
              ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
              : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
        })
        activeObject.setPositionByOrigin(
          new fabric.Point(
            objectLeft - objectWidth / 2 + activeObjectWidth / 2,
            activeObjectTop
          ),
          'center',
          'center'
        )
      }

      // snap by the right edge
      if (
        isInRange(
          objectLeft + objectWidth / 2,
          activeObjectLeft + activeObjectWidth / 2
        )
      ) {
        verticalInTheRange = true
        verticalLines.push({
          x: objectLeft + objectWidth / 2,
          y1:
            objectTop < activeObjectTop
              ? objectTop - objectHeight / 2 - aligningLineOffset
              : objectTop + objectHeight / 2 + aligningLineOffset,
          y2:
            activeObjectTop > objectTop
              ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
              : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
        })
        activeObject.setPositionByOrigin(
          new fabric.Point(
            objectLeft + objectWidth / 2 - activeObjectWidth / 2,
            activeObjectTop
          ),
          'center',
          'center'
        )
      }

      // snap by the vertical center line
      if (isInRange(objectTop, activeObjectTop)) {
        horizontalInTheRange = true
        horizontalLines.push({
          y: objectTop,
          x1:
            objectLeft < activeObjectLeft
              ? objectLeft - objectWidth / 2 - aligningLineOffset
              : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2:
            activeObjectLeft > objectLeft
              ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
              : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
        })
        activeObject.setPositionByOrigin(
          new fabric.Point(activeObjectLeft, objectTop),
          'center',
          'center'
        )
      }

      // snap by the top edge
      if (
        isInRange(
          objectTop - objectHeight / 2,
          activeObjectTop - activeObjectHeight / 2
        )
      ) {
        horizontalInTheRange = true
        horizontalLines.push({
          y: objectTop - objectHeight / 2,
          x1:
            objectLeft < activeObjectLeft
              ? objectLeft - objectWidth / 2 - aligningLineOffset
              : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2:
            activeObjectLeft > objectLeft
              ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
              : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
        })
        activeObject.setPositionByOrigin(
          new fabric.Point(
            activeObjectLeft,
            objectTop - objectHeight / 2 + activeObjectHeight / 2
          ),
          'center',
          'center'
        )
      }

      // snap by the bottom edge
      if (
        isInRange(
          objectTop + objectHeight / 2,
          activeObjectTop + activeObjectHeight / 2
        )
      ) {
        horizontalInTheRange = true
        horizontalLines.push({
          y: objectTop + objectHeight / 2,
          x1:
            objectLeft < activeObjectLeft
              ? objectLeft - objectWidth / 2 - aligningLineOffset
              : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2:
            activeObjectLeft > objectLeft
              ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
              : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
        })
        activeObject.setPositionByOrigin(
          new fabric.Point(
            activeObjectLeft,
            objectTop + objectHeight / 2 - activeObjectHeight / 2
          ),
          'center',
          'center'
        )
      }
    }

    if (!horizontalInTheRange) {
      horizontalLines.length = 0
    }

    if (!verticalInTheRange) {
      verticalLines.length = 0
    }
  })

  canvas.on('before:render', function () {
    if (canvas.contextTop) {
      canvas.clearContext(canvas.contextTop)
    }
  })

  canvas.on('after:render', function () {
    for (let i = verticalLines.length; i--; ) {
      drawVerticalLine(verticalLines[i])
    }
    for (let i = horizontalLines.length; i--; ) {
      drawHorizontalLine(horizontalLines[i])
    }

    verticalLines.length = horizontalLines.length = 0
  })

  canvas.on('mouse:up', function () {
    verticalLines.length = horizontalLines.length = 0
    canvas.renderAll()
  })
}

export const getPreviewUrl = (src = {}) => {
  return src.tiny || src.small || src.original
}

export const rgbaToString = color => {
  const { r, g, b, a } = color
  return _isObject(color) ? `rgba(${r},${g},${b},${a})` : color
}

export const hex2rgba = (hex, alpha = 1) => {
  if (hex.includes('rgba')) {
    const hexWithAlpha = rgbaToHex(hex, true)
    hex = hexWithAlpha.hex
    alpha = hexWithAlpha.opacity
  }
  const color =
    hex.length === 4
      ? hex
          .slice(1)
          .split('')
          .map(char => `${char}${char}`)
          .join('')
      : hex
  const [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export const stringToRgba = color => {
  let newColor
  if (color.includes('rgba')) {
    newColor = color.replace('rgba(', '').replace(')', '')
  } else {
    newColor = color.replace('rgb(', '').replace(')', '')
  }
  const colorArr = newColor.split(',')

  return {
    r: +colorArr[0],
    g: +colorArr[1],
    b: +colorArr[2],
    a: +colorArr[3] || 1
  }
}

export const rgbaToHex = (rgba, returnOpacity = false) => {
  const { opacity } = rgbaToRgb(rgba, true)
  rgba = rgba.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  )
  const hex =
    rgba && rgba.length === 4
      ? '#' +
        ('0' + parseInt(rgba[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[3], 10).toString(16)).slice(-2)
      : ''
  return returnOpacity
    ? {
        hex,
        opacity
      }
    : hex
}

export const isRgba = rgba => {
  return rgba.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  )
}

export const cmykToRgba = (cmyk, alpha) => {
  const [c, m, y, k] = cmyk

  const r =
    255 - Math.round(Math.min(1, (c / 100) * (1 - k / 100) + k / 100) * 255)
  const g =
    255 - Math.round(Math.min(1, (m / 100) * (1 - k / 100) + k / 100) * 255)
  const b =
    255 - Math.round(Math.min(1, (y / 100) * (1 - k / 100) + k / 100) * 255)
  const a = alpha || 1

  return `rgba(${r},${g},${b},${a})`
}

export const colorToHex = color => {
  const RegExp = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i
  return RegExp.test(color) ? color : rgbaToHex(color)
}

export const fontSizeFromPsd = (size, yy) => {
  return Math.round(size * yy * 100) * 0.01
}

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const dataURLFromURL = async src => {
  const blob = await fetch(src).then(r => r.blob())
  return await new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const checkTextAlign = (getObjectsStyleValue, prop) => {
  switch (prop) {
    case 'left':
    case 'right':
    case 'center':
    case 'justify':
      return getObjectsStyleValue('textAlign') === prop
    case 'top':
    case 'middle':
    case 'bottom':
      return getObjectsStyleValue('vTextAlign') === prop
    default:
      return false
  }
}

export const checkTextProp = (obj, prop) => {
  switch (prop) {
    case 'bold':
      return obj.fontWeight === prop
    case 'italic':
      return obj.fontStyle === prop
    case 'underline':
      return obj.underline
    case 'through':
      return obj.linethrough
    case 'caps':
      return obj.fontUppercase
    default:
      return false
  }
}

export const loadFileToUppy = async (uppy, file, transform) => {
  await uppy.addFile({
    name: `design_gallery_thumb_${uuidv4()}.png`,
    type: 'image/png',
    data: file,
    source: 'Local',
    isRemote: false,
    meta: {
      isPreview: true
    }
  })

  const { successful } = await uppy.upload()
  return transform ? successful.map(transformDesignGalleryFile) : successful
}

export const isDataUrlImage = ({ type, external, src }) => {
  return type === 'image' && external && src.includes('data:image/')
}

export const getDataImagesUrls = async (objects, uppy) => {
  const dataUrlImages = objects.filter(obj => isDataUrlImage(obj))

  if (!dataUrlImages.length) return

  const imagesUrls = await Promise.all(dataUrlImages.map(({ src }) => src))

  const response = await Promise.all(
    imagesUrls.map(src => loadFileToUppy(uppy, dataURLtoFile(src)))
  )

  return dataUrlImages.map(({ id }, i) => ({
    id,
    uploadURL: response[0][i].uploadURL
  }))
}

export const textStylesFromPsd = text => {
  try {
    const { font, transform, value = '' } = text
    const { sizes, lengthArray } = font

    const styles = {}
    const mainFontSize = fontSizeFromPsd(font.sizes[0], transform.yy)
    let line = 0
    let lineCharIndex = 0

    const getFontIndex = index => {
      let fontIndex = undefined
      let sum = 0

      lengthArray.forEach((length, lengthIndex) => {
        if (index < sum + length) {
          fontIndex = fontIndex ?? lengthIndex
        } else {
          sum += length
        }
      })

      return fontIndex || 0
    }

    value.split('').forEach((char, charIndex) => {
      if (!styles[line]) {
        styles[line] = {}
      }
      if (char === '\r') {
        line++
        lineCharIndex = 0
        return
      }
      const fontSize = fontSizeFromPsd(
        sizes[getFontIndex(charIndex)],
        transform.yy
      )

      if (fontSize !== mainFontSize) {
        styles[line][lineCharIndex] = { fontSize }
      }
      lineCharIndex++
    })

    return Object.fromEntries(
      Object.entries(styles).filter(([, value]) => !_isEmpty(value))
    )
  } catch (e) {}
}

export const getVariantLabelByValue = value => {
  const variant = fontVariantsMap.find(variant => variant.value === value)
  return variant ? variant.label : null
}
export const getFontWeightOptions = (
  loadedFonts,
  fontFamily,
  staticWeights = [{ label: 'Bold', value: 'bold' }]
) => {
  const fontWeightOptions = Array.from(new Set(loadedFonts))
    .filter(
      font =>
        font.startsWith(fontFamily) &&
        !font.includes('italic') &&
        font !== fontFamily &&
        canvasFontVariantsToLoad.includes(font.replace(fontFamily, ''))
    )
    .map(variant => {
      const variantValue = variant.replace(fontFamily, '')
      return {
        label: getVariantLabelByValue(variantValue),
        value: variantValue,
        family: fontFamily
      }
    })

  return [
    ...(fontWeightOptions.length
      ? fontWeightOptions
      : [{ label: 'Regular', value: 'regular', family: fontFamily }]),
    ...staticWeights.map(obj => ({ ...obj, family: fontFamily }))
  ]
}
