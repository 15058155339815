export const getResponsiveEditorSize = ({
  imageWidth: width,
  imageHeight: height,
  offset = 100
}) => {
  if (!width || !height) {
    return { width: 'initial', height: 'initial' }
  }
  const windowRatio = window.innerWidth / window.innerHeight
  const isHorizontal = width / (height + offset) > windowRatio

  return {
    isHorizontal,
    containerSize: {
      width: isHorizontal ? `calc(100% - 40px)` : undefined,
      height: !isHorizontal ? `calc(100% - ${offset}px)` : undefined
    }
  }
}

export const getCenteredCrop = image => {
  const width = image.width
  const height = image.height
  const size = Math.min(width, height)
  const x = Math.max(0, (width - size) / 2)
  const y = Math.max(0, (height - size) / 2)

  return {
    unit: 'px',
    aspect: 1,
    x,
    y,
    width: size,
    height: size
  }
}

export const generateCroppedBlob = (image, crop = getCenteredCrop(image)) =>
  new Promise((resolve, reject) => {
    if (!crop) {
      reject()
      return
    }
    const dpr = window.devicePixelRatio || 1

    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = Math.ceil(crop.width * scaleX)
    canvas.height = Math.ceil(crop.height * scaleY)
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * dpr
    canvas.height = crop.height * dpr

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * dpr,
      crop.height * dpr
    )

    canvas.toBlob(resolve, 'image/png', 1)
  })
