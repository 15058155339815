import { createContext, useCallback, useMemo, useState } from 'react'
import { resourceTypes } from '../constants/api'

export const AssignCreatedEntityContext = createContext({
  targets: [],
  createdItem: null,
  getStateByResource: resourceType => {},
  assignedPlaylists: [],
  setAssignedPlaylists: f => {}
})

export const AssignCreatedEntityContextProvider = ({ children }) => {
  const [targets, setTargets] = useState({
    [resourceTypes.MediaResource]: [],
    [resourceTypes.PlaylistResource]: [],
    [resourceTypes.TemplateResource]: []
  })
  const [createdItem, setCreatedItem] = useState(null)
  const [assignedPlaylists, setAssignedPlaylists] = useState([])

  const getStateByResource = useCallback(
    resourceType => ({
      setTargets: targets =>
        setTargets(prevState => ({
          ...prevState,
          [resourceType]: targets
        })),
      targets: targets[resourceType] || []
    }),
    [targets]
  )

  const contextValue = useMemo(
    () => ({
      createdItem,
      setCreatedItem,
      getStateByResource,
      assignedPlaylists,
      setAssignedPlaylists
    }),
    [
      getStateByResource,
      createdItem,
      setCreatedItem,
      assignedPlaylists,
      setAssignedPlaylists
    ]
  )

  return (
    <AssignCreatedEntityContext.Provider value={contextValue}>
      {children}
    </AssignCreatedEntityContext.Provider>
  )
}
