import React from 'react'
import { withStyles, Checkbox, Tooltip } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const styles = theme => {
  const { palette, type } = theme
  return {
    root: {
      color: palette[type].checkbox.color
    }
  }
}

const CheckboxSelectAll = ({
  t,
  classes,
  indeterminate = null,
  checked = false,
  onChange = f => f,
  tReady,
  ...props
}) => (
  <Tooltip arrow title={t('Select All')}>
    <Checkbox
      indeterminate={indeterminate}
      checked={checked}
      onChange={onChange}
      classes={{ root: classes.root }}
      {...props}
    />
  </Tooltip>
)

export default withTranslation('translations')(
  withStyles(styles)(CheckboxSelectAll)
)
