import i18next from 'i18next'

import { getItemSuccessMessage } from 'utils/generalUtils'
import {
  TAG,
  TAGS,
  GROUPS,
  TAG_AND_GROUP,
  TAG_AND_GROUPS,
  TAGS_AND_GROUP,
  TAGS_AND_GROUPS
} from 'constants/tagsAndGroupsContants'
import { DEVICE_CONFIGURATIONS } from 'constants/deviceConstants'

export const parseLabel = label => {
  switch (label) {
    case 'add':
      return 'added'
    case 'delete':
      return 'deleted'
    case 'update':
      return 'updated'
    case 'share':
      return 'shared'
    case 'copy':
      return 'copied'
    case 'associate':
      return 'associated'
    case 'start':
      return 'started'
    case 'reject':
      return 'rejected'
    default:
      return 'added'
  }
}

const pluralKeywords = [
  TAGS,
  GROUPS,
  TAG_AND_GROUP,
  TAGS_AND_GROUP,
  TAG_AND_GROUPS,
  TAGS_AND_GROUPS,
  DEVICE_CONFIGURATIONS
]
const keywordsWithConflictSupport = [
  'Media',
  'Playlist',
  'Template',
  TAG,
  TAGS,
  TAG_AND_GROUP,
  TAGS_AND_GROUP,
  TAG_AND_GROUPS,
  TAGS_AND_GROUPS
]

/**
 *@param {object} item
 *@param {function} closeFunction
 *@param {function} pusher
 *@param {array} watchArray
 *@param {string} keyWord
 *@param {function} clearItemToRemove
 *@param {boolean} hideErrorNotification
 **/
const notificationAnalyzer = (
  pusher,
  watchArray,
  keyWord,
  closeFunction = f => f,
  item = {},
  clearItemToRemove = f => f,
  hideErrorNotification = false
) => {
  let wasNotify = false

  watchArray.forEach(({ response, error, label }) => {
    const isPlural = pluralKeywords.includes(keyWord)

    if (response && !Array.isArray(response) && Object.keys(response).length) {
      pusher(
        getItemSuccessMessage(
          item?.name?.trim() ? item.name : i18next.t(keyWord),
          i18next.t(parseLabel(label)),
          isPlural
        ),
        {
          variant: 'success',
          autoHideDuration: 3000
        }
      )
      if (clearItemToRemove) clearItemToRemove()
      wasNotify = true
    }
    if (error && Object.keys(error).length && !error.errorFields?.length) {
      if (
        !(
          error.code === 409 && keywordsWithConflictSupport.includes(keyWord)
        ) &&
        !hideErrorNotification
      ) {
        pusher(
          `${i18next.t(
            `${keyWord} ${isPlural ? 'were' : 'was'} not ${parseLabel(label)}`
          )}: ${error.message}`,
          {
            variant: 'error',
            autoHideDuration: 3000
          }
        )
      }
      wasNotify = true
    }
  })

  return wasNotify
}

export default notificationAnalyzer
