import { withStyles, Chip } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

import { getLightFromBase } from 'utils/chipsColorPalette'
import { deviceConstants } from 'constants/index'
import { swapTagData } from 'utils/tagUtils'
import { defaultTag } from 'constants/chipsColorPalette'

export const StatusChip = withStyles(({ typography, type }) => ({
  root: {
    ...typography.lightText[type],
    width: 80,
    height: 25,
    textAlign: 'center',
    color: '#fff'
  }
}))(Chip)

export const ActiveStatusChip = withStyles({
  root: {
    backgroundColor: '#3cd480'
  }
})(StatusChip)

export const InactiveStatusChip = withStyles({
  root: {
    backgroundColor: '#d35e37'
  }
})(StatusChip)

const useActiveInactiveStatusStyles = makeStyles(({ colors }) => ({
  root: ({ width, isActive }) => ({
    backgroundColor: isActive ? colors.green : colors.inactive,
    width
  })
}))

export const ActiveInactiveStatusChip = ({ isActive, label, width = 80 }) => {
  const classes = useActiveInactiveStatusStyles({ isActive, width })

  return <StatusChip classes={classes} label={label} />
}

export const PendingStatusChip = withStyles({
  root: {
    backgroundColor: 'rgba(128, 128, 128, 1)'
  }
})(StatusChip)

export const ExpireStatusChip = withStyles({
  root: {
    backgroundColor: '#c03829'
  }
})(StatusChip)

export const DisabledStatusChip = withStyles({
  root: {
    backgroundColor: '#c03829'
  }
})(StatusChip)

const useDeviceStatusChipStyles = makeStyles({
  root: {
    backgroundColor: ({ color, defaultColor }) => color || defaultColor
  }
})

export const DeviceStatusChip = ({ status, color, t }) => {
  const { active } = deviceConstants.deviceStatus
  const classes = useDeviceStatusChipStyles({
    color,
    defaultColor: status === active ? 'rgb(22,151,158)' : 'rgb(148,148,148)'
  })

  return <StatusChip classes={classes} label={t(status)} />
}

export const TrialChip = withStyles({
  root: {
    width: 'auto',
    height: 17,
    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '3px'
  }
})(Chip)

export const FirmwareChip = withStyles(({ typography, type }) => ({
  root: {
    ...typography.lightText[type],
    minWidth: '100px',
    height: 25,
    borderRadius: '3px',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#3cd480 !important'
  }
}))(Chip)

export const FlexTrialChip = withStyles({
  root: {
    color: '#ff3d84',
    backgroundColor: 'rgba(255, 61, 132, 0.22)'
  }
})(TrialChip)

const useStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    position: 'relative',
    lineHeight: 1,
    padding: 5,
    height: 'auto',
    border: '1px solid',
    borderRadius: 4,
    fontSize: '11px',
    background: ({ background }) => (background ? background : '#d5e5fd'),
    color: ({ color }) => (color ? color : '#619bf9'),
    borderColor: ({ color, border }) =>
      border ? border : color ? color : '#619bf9',

    '&:before': {
      top: '-1px',
      bottom: '-1px',
      right: '-11px',
      borderTop: '11px solid transparent',
      borderBottom: '11px solid transparent',
      borderLeft: '11px solid #d9dfec'
    },

    '&:after': {
      top: 0,
      bottom: 0,
      right: '-10px',
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: ({ background }) =>
        `10px solid ${background ? background : '#d5e5fd'}`
    },

    '& > span': {
      padding: 0
    }
  }
})

export const CommonChip = React.forwardRef(
  ({ color, variant = 'default', ...props }, ref) => {
    const classes = useStyles({
      color:
        variant === 'outlined'
          ? color
          : getLightFromBase(color) || 'rgba(255,255,255,1)',
      background: variant === 'outlined' ? 'transparent' : color,
      border: color
    })
    return <Chip ref={ref} className={classes.root} variant {...props} />
  }
)

export const TagChip = React.forwardRef(({ tag, ...props }, ref) => {
  const { title, textColor, backgroundColor } = swapTagData(tag)

  const classes = useStyles({
    color: textColor || defaultTag.textColor,
    background: backgroundColor || defaultTag.backgroundColor,
    border: backgroundColor || defaultTag.backgroundColor
  })
  return <Chip ref={ref} className={classes.root} label={title} {...props} />
})

export const GroupChip = React.forwardRef(
  ({ color = '#fd7b25', ...props }, ref) => {
    const classes = useStyles({
      color,
      background: getLightFromBase(color) || 'transparent'
    })
    return <Chip ref={ref} className={classes.root} {...props} />
  }
)

export const ColoredTagChip = withStyles({
  tag: {
    height: '25px',
    marginRight: '15px',
    fontSize: '12px',
    borderRadius: '3px',
    fontWeight: 'bold'
  },
  tagRedColor: {
    border: '1px solid #DE5246',
    backgroundColor: 'rgba(222, 82, 70, 0.25)',
    color: '#DE5246'
  },
  tagOrangeColor: {
    border: '1px solid #ff7b25',
    backgroundColor: 'rgba(255, 123, 37, 0.25)',
    color: '#ff7b25'
  },
  tagBlueColor: {
    border: '1px solid #3983ff',
    backgroundColor: 'rgba(57, 131, 255, 0.25)',
    color: '#3983ff'
  },
  tagGreenColor: {
    border: '1px solid #3cd480',
    backgroundColor: 'rgba(60, 212, 128, 0.25)',
    color: '#3cd480'
  }
})(({ rootClassName, classes, color, label }) => (
  <Chip
    className={[
      classes.tag,
      rootClassName,
      color === 'red'
        ? classes.tagRedColor
        : color === 'orange'
        ? classes.tagOrangeColor
        : color === 'blue'
        ? classes.tagBlueColor
        : color === 'green'
        ? classes.tagGreenColor
        : classes.tagGreenColor
    ].join(' ')}
    label={label}
  />
))

export const PermissionChip = withStyles({
  root: {
    maxWidth: '180px',
    maxHeight: '18px',
    margin: '2.5px',
    borderRadius: '3px',
    overflow: 'hidden'
  },
  label: {
    fontSize: '11px',
    fontWeight: 'bold',
    padding: '4px'
  }
})(({ classes, value, clickHandler = f => f, active, disable }) => {
  const colors = {
    read: '#3cd480',
    create: '#ff7b25',
    update: '#3983ff',
    delete: '#DE5246',
    disable: '#808080'
  }
  const picked = disable
    ? colors.disable
    : active
    ? colors[value]
    : colors[value] + '75'

  const style = {
    color: picked,
    backgroundColor: disable ? colors.disable + '25' : `${colors[value]}25`,
    border: `solid 1px ${picked}`,
    opacity: active ? 1 : 0.5
  }
  return (
    <Chip
      classes={{
        root: classNames(classes.root),
        label: classes.label
      }}
      onClick={clickHandler}
      style={style}
      label={value}
      clickable={!disable}
    />
  )
})

const useImageTagStyle = makeStyles({
  root: {
    fontWeight: 'bold',
    position: 'relative',
    lineHeight: 1,
    padding: 5,
    height: '28px',
    borderRadius: 4,
    fontSize: '11px',
    background: ({ background }) => (background ? background : '#F5F6FA'),
    color: ({ color }) => (color ? color : '#74809A'),
    borderColor: ({ color, border }) =>
      border ? border : color ? color : '#F5F6FA',

    '&:focus': {
      backgroundColor: ({ background }) => (background ? background : 'unset')
    },

    '&:before': {
      top: '-1px',
      bottom: '-1px',
      right: '-11px',
      borderTop: '11px solid transparent',
      borderBottom: '11px solid transparent',
      borderLeft: '11px solid #d9dfec'
    },

    '&:after': {
      top: 0,
      bottom: 0,
      right: '-10px',
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: ({ background }) =>
        `10px solid ${background ? background : '#d5e5fd'}`
    },

    '& > span': {
      padding: 0
    }
  }
})

export const ImageGalleryChip = React.forwardRef(
  (
    { color, bgcolor, variant = 'filled', border, isSelected, ...props },
    ref
  ) => {
    const classes = useImageTagStyle({
      background: isSelected ? '#1565C0 !important' : bgcolor,
      color: isSelected && !color ? '#FFFFFF' : color
    })
    return <Chip ref={ref} className={classes.root} {...props} />
  }
)
