import { _isEmpty } from 'utils/lodash'

import {
  defaultMaxHeadDropdownColumnItems,
  headDropdownColumnItemHeight,
  headDropdownOffset
} from 'constants/tableConstants'
import update from 'immutability-helper'
import arrayMove from 'array-move'

const transformerSelectedItems = (initAccum = {}, ids) =>
  ids.reduce(
    (accum, id) => ({
      ...accum,
      [id]: true
    }),
    { ...initAccum }
  )

const unselectItems = (list, ids) => {
  const modifiedList = { ...list }
  ids.forEach(id => {
    delete modifiedList[id]
  })
  return modifiedList
}

const checkData = (data, fallback = 'No data') => {
  return !_isEmpty(data) || data ? data : fallback
}

const transformMeta = meta => ({
  ...(meta || {}),
  isLoading: false,
  perPage: Number.parseInt(meta?.perPage || 0)
})

const maxHeadDropdownColumnItems = Math.max(
  5,
  Math.min(
    defaultMaxHeadDropdownColumnItems,
    (window.innerHeight - headDropdownOffset) / headDropdownColumnItemHeight
  )
)

const getChangedSortParams = (prevSort, nextSort, prevOrder) => {
  return {
    sort: nextSort,
    order:
      nextSort !== prevSort ? prevOrder : prevOrder === 'asc' ? 'desc' : 'asc'
  }
}

const reorderColumns = (source, destination, columns, role, idFilter) => {
  try {
    let visibleColumns = columns.filter(column => column.display !== false)

    if (role) {
      visibleColumns = visibleColumns.filter(
        ({ forRoles }) => !forRoles || forRoles.some(r => role[r])
      )
    }

    if (idFilter) {
      visibleColumns = visibleColumns.filter(column => idFilter(column.id))
    }

    const [, sourceColumn] = source.droppableId.split('-')
    const [, destinationColumn, index] = destination.droppableId.split('-')

    const from = source.index
    const target = parseInt(index) + destination.index
    const to =
      sourceColumn !== destinationColumn && from < target ? target - 1 : target

    const sourceIndex = columns.findIndex(
      ({ id }) => visibleColumns[from].id === id
    )
    const destinationIndex = columns.findIndex(
      ({ id }) => visibleColumns[to].id === id
    )

    return update(columns, {
      $set: arrayMove(columns, sourceIndex, destinationIndex)
    })
  } catch (e) {
    return columns
  }
}

export {
  unselectItems,
  transformerSelectedItems,
  checkData,
  transformMeta,
  maxHeadDropdownColumnItems,
  getChangedSortParams,
  reorderColumns
}
