import isEqual from 'lodash/isEqual'
import { libraryViews } from 'constants/library'

export function isAdd(value) {
  return isEqual(value, 'add')
}

export function isEdit(value) {
  return isEqual(value, 'edit')
}

export function isClone(value) {
  return isEqual(value, 'clone')
}

export function isEditOrClone(value) {
  return isEqual(value, 'edit') || isEqual(value, 'clone')
}

export function isSuccessfully(value) {
  return isEqual(value, 'successfully')
}

export function isMediaInfo(value) {
  return isEqual(value, 'mediaInfo')
}

export function isAlbum(value) {
  return isEqual(value, 'album')
}

export function isFolder(value) {
  return isEqual(value, 'folder')
}

export const isListView = view => {
  return view === libraryViews.list
}

export const isGridView = view => {
  return view === libraryViews.grid
}
