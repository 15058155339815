import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import FormControlMaterialDateTimeWrap from '../FormControlMaterialDateTimeWrap'
import moment from 'moment'
import { simulateEvent } from 'utils/formik'
import Error from 'components/Form/Error'

import styles from '../styles'
import {
  MEDIA_DATE_FORMAT,
  TIME_S_FORMAT
} from '../../../../constants/dateTimeFormats'

const customStyle = ({ palette, type, typography }) => ({
  dialogRoot: {
    '& [class*=MuiPickersModal-dialogRoot]': {
      background: palette[type].secondary
    },
    '& [class*=MuiTypography-body1], & [class*=MuiTypography-body2]': {
      ...typography.darkText[type]
    },
    '& [class*=MuiTypography-caption], & [class*=MuiPickersYear-root]': {
      ...typography.lightAccent[type]
    },
    '& [class*=MuiPickersDay-dayDisabled]': {
      '& [class*=MuiTypography-body2]': {
        ...typography.lightAccent[type]
      }
    },
    '& [class*=MuiPickersDay-daySelected]': {
      backgroundColor: '#3283c7',
      '& [class*=MuiTypography-body2]': {
        color: '#fff'
      }
    },
    '& [class*=MuiPickersClockNumber-clockNumberSelected]': {
      color: '#fff'
    },
    '& [class*=MuiButton-textPrimary]': {
      color: '#3283c7'
    },
    '& [class*=MuiTypography-colorPrimary]': {
      color: '#3283c7 !important',
      fontSize: '1.5rem !important'
    },
    '& .Mui-disabled': {
      ...typography.lightAccent[type],
      color: `${typography.lightAccent[type].color} !important`
    },
    '& [class*=MuiPickersCalendarHeader-iconButton]': {
      background: 'transparent',
      ...typography.darkAccent[type]
    },
    '& [class*=MuiPickersYearSelection-container]': {
      '&::-webkit-scrollbar': {
        width: '7px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: type === 'dark' ? '#BABABA50' : '#25252550',
        borderRadius: '5px'
      }
    }
  }
})

const FormControlMaterialDateTimePicker = ({
  pickerProps,
  tooltip,
  touched,
  value,
  name,
  format,
  error,
  labelRootClassName,
  containerRootClassName,
  inputTextRootClassName,
  hideIcon,
  inputRootClassName,
  fullWidth,
  marginBottom,
  labelPosition,
  onClickLabel,
  classes,
  id,
  disabled,
  handleChange,
  onBlur,
  inputVariant = 'normal',
  labelFontSizeVariant = 'primary',
  dateLabel = 'Date',
  timeLabel = 'Time',
  timeViews = ['hours', 'minutes', 'seconds'],
  timePickerFormat = TIME_S_FORMAT,
  isReadOnly = false,
  ampm
}) => {
  const onChange = useCallback(
    date => {
      const prepareDate = moment(date).format(format)
      if (prepareDate === value) return
      handleChange(name ? simulateEvent(name, prepareDate) : prepareDate)
    },
    [format, handleChange, name, value]
  )

  const handleBlur = useCallback(() => {
    onBlur && onBlur(name ? simulateEvent(name, value) : value)
  }, [onBlur, value, name])

  return (
    <div>
      <div className={classes.dateTimeWrapper}>
        <FormControlMaterialDateTimeWrap
          {...{
            tooltip,
            onClickLabel,
            labelRootClassName,
            containerRootClassName,
            marginBottom,
            labelPosition,
            fullWidth,
            id
          }}
          label={dateLabel}
          datePicker
          labelFontSizeVariant={labelFontSizeVariant}
        >
          <KeyboardDatePicker
            ampm={false}
            inputVariant="standard"
            onChange={onChange}
            format={MEDIA_DATE_FORMAT}
            invalidDateMessage={null}
            value={value}
            DialogProps={{
              className: classes.dialogRoot
            }}
            classes={{
              root: classNames(
                classes.pickerInput,
                classes.datePicker,
                inputRootClassName,
                {
                  [classes.pickerError]: error && touched,
                  [classes.disabled]: disabled
                }
              )
            }}
            KeyboardButtonProps={{
              classes: {
                root: classNames(classes.iconRoot, {
                  [classes.iconHidden]: hideIcon
                })
              }
            }}
            InputAdornmentProps={{
              classes: {
                positionEnd: classNames({
                  [classes.adornmentHidden]: hideIcon
                })
              }
            }}
            inputProps={{
              onBlur: handleBlur
            }}
            InputProps={{
              readOnly: isReadOnly,
              autoComplete: 'off',
              disableUnderline: true,
              classes: {
                input: classNames(
                  classes.textInput,
                  inputTextRootClassName,
                  classes[`input-${inputVariant}`]
                )
              }
            }}
            {...pickerProps}
          />
        </FormControlMaterialDateTimeWrap>
        <FormControlMaterialDateTimeWrap
          {...{
            tooltip,
            onClickLabel,
            labelRootClassName,
            containerRootClassName,
            marginBottom,
            labelPosition,
            fullWidth,
            id
          }}
          label={timeLabel}
          timePicker
          labelFontSizeVariant={labelFontSizeVariant}
        >
          <KeyboardTimePicker
            ampm={ampm}
            inputVariant="standard"
            onChange={onChange}
            format={timePickerFormat}
            views={timeViews}
            invalidDateMessage={null}
            value={value}
            DialogProps={{
              className: classes.dialogRoot
            }}
            classes={{
              root: classNames(
                classes.pickerInput,
                classes.timePicker,
                inputRootClassName,
                {
                  [classes.pickerError]: error && touched,
                  [classes.disabled]: disabled
                }
              )
            }}
            KeyboardButtonProps={{
              classes: {
                root: classNames(classes.iconRoot, {
                  [classes.iconHidden]: hideIcon
                })
              }
            }}
            InputAdornmentProps={{
              classes: {
                positionEnd: classNames({
                  [classes.adornmentHidden]: hideIcon
                })
              }
            }}
            inputProps={{
              readOnly: isReadOnly,
              onBlur: handleBlur
            }}
            InputProps={{
              autoComplete: 'off',
              disableUnderline: true,
              classes: {
                input: classNames(
                  classes.textInput,
                  inputTextRootClassName,
                  classes[`input-${inputVariant}`]
                )
              }
            }}
            {...pickerProps}
          />
        </FormControlMaterialDateTimeWrap>
      </div>
      <Error condition={!!(error && touched)} error={error} />
    </div>
  )
}

FormControlMaterialDateTimePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  inputTextRootClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  pickerProps: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  hideIcon: PropTypes.bool,
  inputRootClassName: PropTypes.string,
  disabled: PropTypes.bool,
  inputVariant: PropTypes.oneOf(['normal', 'small']),
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest'])
}

export default withStyles(theme => ({
  ...styles(theme),
  ...customStyle(theme)
}))(memo(FormControlMaterialDateTimePicker))
