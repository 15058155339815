import React from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router-dom'

import { getToken, getUserType } from '../../utils'

import { apiConstants } from '../../constants'
import AccessDeniedModal from 'components/Modal/AccessDeniedModal/index.js'

const SystemRoute = ({
  component: Component,
  propsComponent,
  allowed = true,
  ...rest
}) => {
  const logged = !!getToken()

  const type = getUserType()
  const system = type === apiConstants.SYSTEM_USER

  return (
    <Route
      {...rest}
      render={props => {
        if (!logged) {
          return <Redirect to="/system/sign-in" />
        } else {
          if (system && allowed) {
            return <Component {...props} {...propsComponent} />
          } else {
            return <AccessDeniedModal />
          }
        }
      }}
    />
  )
}

SystemRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object
  ]),
  allowed: PropTypes.bool
}

export default SystemRoute
