import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { createSuccessInvalidator } from 'utils/apiUtils'

export const featureManagementApi = createAppApi('featureManagementQuery', {
  tagTypes: [apiTags.featureManagement],
  endpoints: builder => ({
    getFeatures: builder.query({
      providesTags: [apiTags.featureManagement],
      query: (params = {}) => ({
        url: '/feature',
        params
      })
    }),
    getFeatureById: builder.query({
      query: id => ({
        url: `/feature/${id}`
      })
    }),
    putFeature: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.featureManagement]),
      query: ({ id, data }) => ({
        method: 'PUT',
        url: `/feature/${id}`,
        data
      })
    })
  })
})

export const {
  useLazyGetFeaturesQuery,
  useLazyGetFeatureByIdQuery,
  usePutFeatureMutation
} = featureManagementApi
