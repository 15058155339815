import headerLogo from 'common/icons/mvix-logo-dark.svg'
import darkHeaderLogo from 'common/icons/mvix-logo-light.svg'
import { APP_BASE_URL } from 'config'

const aboutPageLinkFallback = 'https://mvix.com/about-us/'
const privacyPolicyLinkFallback = 'https://mvix.com/privacy-policy/'
const termsCondition = 'https://mvix.com/terms-of-use/'

const fallbackValues = {
  id: 1,
  name: 'XhibitSignage',
  windowTitle: 'Mvix Digital Signage CMS',
  headerLogo,
  darkHeaderLogo,
  footerLogo: `${APP_BASE_URL}assets/wlprofile/1/settings/RC9DWrZNtjRG6uFzykJzktcU4ANlWTHfNL6HVl2u.png`,
  sso: { google: true, facebook: true, microsoft: true, linkedin: true },
  loginBackgroundImage: {
    night: [
      `${APP_BASE_URL}assets/wlprofile/1/settings/lwmxL5b6DXN6eLhbUnjqAosTgMgG812Lw2ZMnVQT.jpeg`,
      `${APP_BASE_URL}assets/wlprofile/1/settings/YUProXWZtx2UbnmiSk9GY1e8rOx0FVDJpboLYA8S.jpeg`
    ],
    morning: [
      `${APP_BASE_URL}assets/wlprofile/1/settings/cMaAUTp4CRpgojSiNrQFuTiRjkAacfoBenIaI4oa.jpeg`,
      `${APP_BASE_URL}assets/wlprofile/1/settings/RwuLr96o8qlPRokkQp6ToZqZhRWBoCiPKMgHVpF4.jpeg`
    ],
    afternoon: [
      `${APP_BASE_URL}assets/wlprofile/1/settings/S6mwPpmJc73vZ31hn9Pvn0Xv1MF9nAe1iwlRIsMR.jpeg`,
      `${APP_BASE_URL}assets/wlprofile/1/settings/4moI9J5tXDMywJFkCqZNQ35rnvwAZ0SKMLMzPNqh.jpeg`
    ]
  },
  ipRestriction: null,
  ipWhitelist: null,
  copyrightText: '© Mvix Inc. All rights reserved',
  helpPage: 'https://kb.cms.mvix.com/',
  aboutPage: {
    link: aboutPageLinkFallback,
    fallbackLink: aboutPageLinkFallback,
    text: '',
    preferred: 'link'
  },
  privacyPolicy: {
    link: privacyPolicyLinkFallback,
    fallbackLink: privacyPolicyLinkFallback,
    text: '',
    preferred: 'link'
  },
  termsCondition: {
    link: termsCondition,
    fallbackLink: termsCondition,
    text: '',
    preferred: 'link'
  },
  support: {
    email: 'support@mvix.com',
    uri: 'https://mvix.com/knowledgebase/',
    image: `${APP_BASE_URL}assets/wlprofile/1/settings/pSK1gGWIAwdtt8lUIqFck0HX98XVi0rEHh63xnVf.png`,
    type: 'rss'
  }
}

const whiteLabelConstants = {
  fallbackValues
}

export default whiteLabelConstants
