// settings side

// fields
export const SIZE = 'size'
export const POSITION = 'position'
export const STYLES = 'styles'
export const ICON = 'icon'
export const TITLE = 'title'
export const MEDIA = 'media'
export const NESTED = 'nested'
export const TOUCH = 'touchSettingJson'
export const FEATURE_ID = 'featureId'
export const TYPE = 'type'
export const RANDOMIZE_ORDER = 'randomizePlaybackOrder'
export const TRANSITION = 'transition'
export const COMMON_DURATION = 'commonDuration'

// template preferences
export const MAX_WIDTH_LANDSCAPE = 1280
export const MAX_HEIGHT_PORTRAIT = 850

export const MAX_HD_WIDTH = 1920
export const MAX_HD_HEIGHT = 1080

export const PORTRAIT = 'PORTRAIT'
export const LANDSCAPE = 'LANDSCAPE'

// size & position
export const WIDTH = 'WIDTH'
export const HEIGHT = 'HEIGHT'
export const X_AXIS = 'X_AXIS'
export const Y_AXIS = 'Y_AXIS'

// alignment & rotations
export const LEFT = 'LEFT'
export const RIGHT = 'RIGHT'
export const TOP = 'TOP'
export const BOTTOM = 'BOTTOM'
export const V_STRETCH = 'V_STRETCH'
export const H_STRETCH = 'H_STRETCH'
export const L_ROTATE = 'L_ROTATE'
export const R_ROTATE = 'R_ROTATE'
export const V_FLIP = 'V_FLIP'
export const H_FLIP = 'H_FLIP'

// template style
export const TL_RADIUS = 'borderTopLeftRadius'
export const TR_RADIUS = 'borderTopRightRadius'
export const BL_RADIUS = 'borderBottomLeftRadius'
export const BR_RADIUS = 'borderBottomRightRadius'

export const backgroundTypes = {
  NONE: 'none',
  DEFAULT: 'default',
  IMAGE: 'image',
  PATTERN: 'pattern',
  COLOR: 'color'
}

export const orientationTypes = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait'
}

export const DEFAULT_BACKGROUND_COLOR = 'rgba(0, 0, 0, 1)'
export const MIN_TOUCH_CONTENT_DURATION = '00:00:10'

export const maxResolutions = {
  UHD: {
    width: 3840,
    height: 2160
  },
  HD: {
    width: 1920,
    height: 1080
  }
}

export const maxCustomResolutions = {
  width: 10000,
  height: 10000
}

export const POPUP_BUBBLE_HEIGHT = 420
export const POPUP_BUBBLE_WIDTH = 320

export const PLAYLIST_ID = -1

export const SOLID_TEMPLATE_BG_COLORS = [
  'rgba(141,37,65)',
  'rgba(0,255,171,1)',
  'rgba(41,189,193,1)',
  'rgba(2216,66,66,1)',
  'rgba(192,225,192)',
  'rgba(0,54,99,1)',
  'rgba(221,221,221,1)',
  'rgba(255,102,51)',
  'rgba(108,192,112)',
  DEFAULT_BACKGROUND_COLOR
]
