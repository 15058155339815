import './wdyr'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import 'moment/min/locales'

import 'utils/yup/globalMethods'
import App from './App'

import * as serviceWorker from './serviceWorker'
import store from './store/configureStore'

const error = console.error
console.error = (...args) => {
  // remove license warning for local build
  if (/^\*/.test(args[0]) && /AgGridReact/.test(args[1])) {
    return
  }
  error(...args)
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
