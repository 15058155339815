const styles = ({
  fontSize,
  palette,
  type,
  colors,
  typography,
  transitions,
  shapes,
  formControls
}) => ({
  textInput: {
    borderRadius: 4,
    position: 'relative',
    borderRight: `1px solid ${palette[type].formControls.input.border}`,
    color: palette[type].formControls.input.color,
    fontSize: fontSize.primary,
    padding: '9px 15px',
    paddingLeft: formControls.input.paddingLeft,
    transition: transitions.create(['border-color', 'box-shadow']),
    fontFamily: typography.fontFamily,
    height: shapes.height.primary - 2
  },
  pickerInput: {
    backgroundColor: palette[type].formControls.input.background,
    border: `1px solid ${palette[type].formControls.input.border}`,
    borderRadius: 4,
    height: shapes.height.primary,
    '& .Mui-focused': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  dateTimeWrapper: {
    display: 'flex',
    position: 'relative'
  },
  timePicker: {
    borderRadius: '0 4px 4px 0'
  },
  datePicker: {
    borderRadius: '4px 0 0 4px'
  },
  pickerError: {
    borderBottomColor: colors.error,
    '& .Mui-focused': {
      borderBottomColor: palette[type].formControls.input.border
    }
  },
  pickerFullWidth: {
    flexGrow: 1
  },
  disabled: {
    cursor: 'default',
    backgroundColor: palette[type].formControls.disabled.background,
    textShadow: 'unset'
  },
  iconRoot: {
    padding: 4,
    marginRight: 8,
    color: '#74809a',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  iconHidden: {
    display: 'none'
  },
  adornmentHidden: {
    marginLeft: 0
  },
  popoverRoot: {
    zIndex: '2001 !important'
  },
  popoverPaper: {
    backgroundColor: palette[type].secondary
  },
  'input-normal': {
    fontSize: 13,
    paddingLeft: formControls.input.paddingLeft
  },
  'input-small': {
    fontSize: 12,
    paddingLeft: 5
  }
})

export default styles
