import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const SingleGridItemLoader = ({
  x = '0',
  y = '0',
  height = 200,
  width = 200,
  theme,
  rx = '6',
  ry = '6'
}) => (
  <ContentLoader
    style={{
      width: '100%',
      height: height
    }}
    foregroundColor={theme.palette[theme.type].loader.background}
    backgroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect x={x} y={y} rx={rx} ry={ry} width={width} height={height} />
  </ContentLoader>
)

export default withTheme(SingleGridItemLoader)
