import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { FormControlSelectFont } from 'components/Form'
import { LIGHT } from 'theme'

const useStyles = makeStyles(() => ({
  root: {
    width: '150px !important',
    margin: '0 3px 6px 0'
  }
}))

const CustomFontSelect = ({ currentState, onChange, editorRef }) => {
  const classes = useStyles()
  const theme = useTheme()

  const toggleFontFamily = ({ target: { value } }) => {
    onChange(value)
  }

  const handleMenuClose = () => {
    if (editorRef.current.focusEditor) {
      editorRef.current.focusEditor()
    }
  }

  return (
    <FormControlSelectFont
      name="fontFamily"
      value={currentState.fontFamily}
      onChange={toggleFontFamily}
      marginBottom={false}
      placeholder="Font"
      formControlContainerClass={classes.root}
      onMenuClose={handleMenuClose}
      styles={{
        control: {
          minHeight: 30,
          height: 30,
          border: `1px solid ${
            theme.type === LIGHT ? '#F1F1F1' : theme.colors.light
          }`,
          borderRadius: '2px'
        }
      }}
    />
  )
}

export default CustomFontSelect
