import update from 'immutability-helper'

import * as types from '../actions'
import {
  deleteInitialState,
  shapeOfBodyWithMeta,
  postInitialState,
  fetchParamsInitialState,
  shapeOfBody
} from 'constants/initialLibraryState'
import sortConstants from 'constants/sortConstants'

const initialFetchParams = {
  ...fetchParamsInitialState,
  ...sortConstants.Media
}

const initialState = {
  library: shapeOfBodyWithMeta,
  fetchParams: initialFetchParams,
  preference: {},
  preview: {
    id: null,
    isVisible: false,
    isLoading: false,
    response: null,
    error: null,
    key: '',
    resolution: '',
    orientation: 'landscape'
  },
  mediaItem: {
    status: null,
    response: null,
    error: null,
    isFetching: false
  },
  put: {
    response: null,
    error: null
  },
  copyMediaItems: {
    ...shapeOfBody,
    label: 'copy'
  },
  copyDesignToSystem: {
    ...shapeOfBody,
    label: 'copy'
  },
  groupItems: {},
  postGroupItem: {},
  deleteGroupItem: {},
  featureMediaItems: {},
  postToPlaylists: { status: '' },
  postFavorite: {
    ...postInitialState,
    label: 'added favorite tab'
  },
  capAlert: {
    isFetching: false,
    items: [],
    meta: {},
    error: null
  },
  ipawsAlert: {
    isFetching: false,
    items: [],
    meta: {},
    error: null
  },
  dependency: {
    error: null
  },
  del: deleteInitialState,
  postToPlaylist: postInitialState,
  postBulk: postInitialState,
  clone: {},
  share: {
    ...shapeOfBody,
    label: 'share'
  },
  associations: {
    ...shapeOfBodyWithMeta,
    isFetching: false
  },
  sftpFolder: {
    ...shapeOfBody,
    meta: {
      status: '',
      isLoading: false
    }
  }
}

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEDIA_ITEMS:
      return update(state, {
        library: {
          error: { $set: {} },
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_MEDIA_ITEMS_SUCCESS:
      return update(state, {
        library: {
          response: { $set: action.payload.response },
          meta: { $set: action.modifiedMeta }
        },
        fetchParams: { $set: action.payload.params }
      })
    case types.GET_MEDIA_ITEMS_ERROR:
      return update(state, {
        library: {
          error: { $set: action.payload },
          meta: { isLoading: { $set: false } }
        }
      })
    case types.GET_MEDIA_BY_ID_SUCCESS:
      const response = state.library.response.data.find(
        ({ id }) => id === action.payload.id
      )
        ? {
            ...state.library.response,
            data: state.library.response.data.map(item =>
              item.id === action.payload.id
                ? { ...item, ...action.payload.response }
                : item
            )
          }
        : state.library.response
      return update(state, {
        library: {
          response: { $set: response }
        }
      })
    case types.GET_MEDIA_BY_ID_ERROR:
      return update(state, {
        library: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_MEDIA_FETCH_PARAMS:
      return update(state, {
        fetchParams: {
          $set: initialFetchParams
        }
      })
    case types.CLEAR_GET_MEDIA_ITEMS_INFO:
      return update(state, {
        library: { $set: shapeOfBodyWithMeta }
      })
    case types.CLEAR_MEDIA_ITEMS:
      return update(state, {
        library: { $set: shapeOfBodyWithMeta }
      })
    case types.GET_MEDIA_PREFERENCE_SUCCESS:
      return update(state, {
        preference: {
          response: { $set: action.payload }
        }
      })
    case types.GET_MEDIA_PREFERENCE_ERROR:
      return update(state, {
        preference: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_MEDIA_PREFERENCE_SUCCESS:
      return update(state, {
        preference: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_MEDIA_PREFERENCE_ERROR:
      return update(state, {
        preference: {
          error: { $set: action.payload }
        }
      })
    case types.GET_MEDIA_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: action.data.id },
          isZoomToFit: { $set: action.data.isZoomToFit },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: action.meta.key },
          resolution: { $set: action.data.resolution },
          orientation: { $set: action.data.orientation }
        }
      })
    case types.GENERATE_MEDIA_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: null },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: '' }
        }
      })
    case types.GET_MEDIA_PREVIEW_SUCCESS:
    case types.GENERATE_MEDIA_PREVIEW_SUCCESS:
      return update(state, {
        preview: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_URL_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: null },
          isVisible: { $set: true },
          isLoading: { $set: false },
          response: { $set: action.payload },
          error: { $set: null }
        }
      })
    case types.GET_MEDIA_PREVIEW_ERROR:
    case types.GENERATE_MEDIA_PREVIEW_ERROR:
      return update(state, {
        preview: {
          isVisible: { $set: false },
          isLoading: { $set: false },
          error: { $set: action.payload },
          meta: { $set: action.meta }
        }
      })
    case types.SHOW_MEDIA_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: true }
        }
      })
    case types.CLOSE_MEDIA_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: false }
        }
      })
    case types.CLEAR_MEDIA_PREVIEW:
      return update(state, {
        preview: {
          $merge: {
            id: null,
            isVisible: false,
            isLoading: false,
            response: null,
            error: null,
            key: '',
            resolution: ''
          }
        }
      })
    case types.GET_MEDIA_GROUP_ITEMS_SUCCESS:
      return update(state, {
        groupItems: {
          response: { $set: action.payload }
        }
      })
    case types.CLEAR_MEDIA_GROUP_ITEMS_RESPONSE_INFO:
      return update(state, {
        $merge: {
          groupItems: {},
          postGroupItem: {},
          deleteGroupItem: {}
        }
      })
    case types.GET_MEDIA_GROUP_ITEMS_ERROR:
      return update(state, {
        groupItems: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_MEDIA_GROUP_ITEMS_INFO:
      return update(state, {
        groupItems: { $set: {} }
      })
    case types.POST_MEDIA_GROUP_ITEM_SUCCESS:
      return update(state, {
        postGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.POST_MEDIA_GROUP_ITEM_ERROR:
      return update(state, {
        postGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_MEDIA_GROUP_ITEM_INFO:
      return update(state, {
        postGroupItem: { $set: {} }
      })
    case types.DELETE_MEDIA_GROUP_ITEM_SUCCESS:
      return update(state, {
        deleteGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_MEDIA_GROUP_ITEM_ERROR:
      return update(state, {
        deleteGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DELETE_MEDIA_GROUP_ITEM_INFO:
      return update(state, {
        deleteGroupItem: { $set: {} }
      })
    case types.GET_MEDIA_ITEM_BY_ID:
      return update(state, {
        mediaItem: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_MEDIA_ITEM_BY_ID_SUCCESS:
      return update(state, {
        mediaItem: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_MEDIA_ITEM_BY_ID_ERROR:
      return update(state, {
        mediaItem: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.PUT_MEDIA_SUCCESS:
    case types.CLONE_MEDIA_AND_UPDATE_SUCCESS:
      return update(state, {
        mediaItem: {
          status: { $set: 'successfully' }
        },
        put: {
          response: { $set: 'successfully' }
        },
        dependency: {
          error: { $set: null }
        }
      })
    case types.PUT_MEDIA_ERROR:
    case types.CLONE_MEDIA_AND_UPDATE_ERROR:
      return update(state, {
        mediaItem: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        },
        put: {
          error: { $set: action.payload },
          meta: { $set: action.meta }
        },
        dependency: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_MEDIA_ITEM_STATUS:
      return update(state, {
        mediaItem: {
          status: { $set: 'null' }
        }
      })
    case types.CREAR_MEDIA_ITEM:
      return update(state, {
        mediaItem: {
          status: { $set: null },
          response: { $set: null },
          error: { $set: null }
        }
      })
    case types.CLEAR_MEDIA_PUT:
      return update(state, {
        put: {
          response: { $set: null },
          error: { $set: null }
        }
      })
    case types.GET_FEATURE_MEDIA_ITEMS_SUCCESS:
      return update(state, {
        featureMediaItems: {
          response: { $set: action.payload }
        }
      })
    case types.GET_FEATURE_MEDIA_ITEMS_ERROR:
      return update(state, {
        featureMediaItems: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_FEATURE_MEDIA_ITEMS_INFO:
      return update(state, {
        featureMediaItems: { $set: {} }
      })

    case types.PUT_ORGANIZE_FEATURE_MEDIA_ITEMS_SUCCESS:
      return update(state, {
        put: {
          response: { $set: 'success' }
        }
      })
    case types.PUT_ORGANIZE_FEATURE_MEDIA_ITEMS_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload }
        }
      })

    case types.POST_MEDIA_TO_PLAYLISTS_SUCCESS:
      return update(state, {
        postToPlaylists: {
          status: { $set: 'success' },
          response: { $set: action.payload }
        }
      })
    case types.POST_MEDIA_TO_PLAYLISTS_ERROR:
      return update(state, {
        postToPlaylists: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_MEDIA_TO_PLAYLISTS:
      return update(state, {
        postToPlaylists: { $set: { status: '' } }
      })

    case types.POST_FAVORITE_MEDIA_SUCCESS:
      return update(state, {
        postFavorite: {
          response: { $set: action.payload }
        }
      })
    case types.POST_FAVORITE_MEDIA_ERROR:
      return update(state, {
        postFavorite: {
          error: { $set: action.payload }
        }
      })
    case types.REQUEST_MEDIA_CAP_ALERT:
      return update(state, {
        capAlert: {
          isFetching: { $set: true }
        }
      })
    case types.MEDIA_CAP_ALERT_SUCCESS:
      return update(state, {
        capAlert: {
          $merge: {
            isFetching: false,
            items: action.payload.data,
            meta: action.payload.meta,
            error: null
          }
        }
      })
    case types.MEDIA_CAP_ALERT_ERROR:
      return update(state, {
        capAlert: {
          $merge: {
            isFetching: false,
            error: action.payload
          }
        }
      })
    case types.MEDIA_IPAWS_ALERT:
      return update(state, {
        ipawsAlert: {
          isFetching: { $set: true }
        }
      })
    case types.MEDIA_IPAWS_ALERT_SUCCESS:
      return update(state, {
        ipawsAlert: {
          $merge: {
            isFetching: false,
            items: action.payload.data,
            meta: action.payload.meta,
            error: null
          }
        }
      })
    case types.MEDIA_IPAWS_ALERT_ERROR:
      return update(state, {
        ipawsAlert: {
          $merge: {
            isFetching: false,
            error: action.payload
          }
        }
      })
    case types.CLEAR_POST_FAVORITE_MEDIA:
      return update(state, {
        postFavorite: { $set: initialState.postFavorite }
      })

    case types.SET_MEDIA_DEPENDENCY:
      return update(state, {
        dependency: {
          $merge: action.payload
        }
      })
    case types.CLEAR_MEDIA_DEPENDENCY:
      return update(state, {
        dependency: {
          $set: {
            error: null
          }
        }
      })
    case types.DELETE_SELECTED_MEDIA_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        },
        dependency: {
          error: { $set: null }
        }
      })
    case types.DELETE_SELECTED_MEDIA_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        },
        dependency: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_MEDIA_RESPONSE_INFO:
      return update(state, {
        del: {
          $set: deleteInitialState
        },
        postToPlaylist: {
          $set: postInitialState
        },
        postBulk: {
          $set: postInitialState
        },
        clone: {
          $set: {}
        },
        share: {
          $set: {
            ...shapeOfBody,
            label: 'share'
          }
        },
        copyMediaItems: {
          $set: {
            ...shapeOfBody,
            label: 'copy'
          }
        },
        copyDesignToSystem: {
          $set: {
            ...shapeOfBody,
            label: 'copy'
          }
        }
      })
    case types.POST_MEDIA_TO_PLAYLIST_SUCCESS:
      return update(state, {
        postToPlaylist: {
          response: { $set: action.payload }
        }
      })
    case types.POST_MEDIA_TO_PLAYLIST_ERROR:
      return update(state, {
        postToPlaylist: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.POST_MEDIA_BULK_SUCCESS:
      return update(state, {
        postBulk: {
          response: { $set: action.payload }
        },
        dependency: {
          error: { $set: null }
        }
      })
    case types.POST_MEDIA_BULK_ERROR:
      return update(state, {
        postBulk: {
          error: { $set: action.payload }
        },
        dependency: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_MEDIA_BULK_INFO:
      return update(state, {
        postBulk: {
          $set: postInitialState
        }
      })
    case types.CLONE_MEDIA_ITEM_SUCCESS:
      return update(state, {
        clone: {
          response: { $set: action.payload }
        },
        dependency: {
          error: { $set: null }
        }
      })
    case types.CLONE_MEDIA_ITEM_ERROR:
      return update(state, {
        clone: {
          error: { $set: action.payload }
        },
        dependency: {
          error: { $set: action.payload }
        }
      })
    case types.PUBLISH_DESIGN_SUCCESS:
    case types.SHARE_MEDIA_SUCCESS:
      return update(state, {
        share: {
          response: { $set: action.payload }
        }
      })
    case types.PUBLISH_DESIGN_ERROR:
    case types.SHARE_MEDIA_ERROR:
      return update(state, {
        share: {
          error: { $set: action.payload }
        }
      })
    case types.COPY_MEDIA_SUCCESS:
      return update(state, {
        copyMediaItems: {
          response: { $set: action.payload }
        }
      })
    case types.COPY_MEDIA_ERROR:
      return update(state, {
        copyMediaItems: {
          error: { $set: action.payload }
        }
      })
    case types.COPY_DESIGN_TO_SYSTEM_SUCCESS:
      return update(state, {
        copyDesignToSystem: {
          response: { $set: action.payload }
        }
      })
    case types.COPY_DESIGN_TO_SYSTEM_ERROR:
      return update(state, {
        copyDesignToSystem: {
          error: { $set: action.payload }
        }
      })
    case types.GET_MEDIA_ASSOCIATIONS:
      return update(state, {
        associations: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_MEDIA_ASSOCIATIONS_SUCCESS:
      return update(state, {
        associations: {
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_MEDIA_ASSOCIATIONS_ERROR:
      return update(state, {
        associations: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_MEDIA_ASSOCIATIONS:
      return update(state, {
        associations: { $set: initialState.associations }
      })
    case types.GET_SFTP_FOLDER:
      return update(state, {
        sftpFolder: {
          error: { $set: null },
          meta: { isLoading: { $set: true }, status: { $set: '' } }
        }
      })
    case types.GET_SFTP_FOLDER_SUCCESS:
      return update(state, {
        sftpFolder: {
          error: { $set: null },
          response: { $set: action.payload },
          meta: { isLoading: { $set: false }, status: { $set: 'success' } }
        }
      })
    case types.GET_SFTP_FOLDER_ERROR:
      return update(state, {
        sftpFolder: {
          error: { $set: action.payload },
          meta: { isLoading: { $set: false }, status: { $set: 'error' } }
        }
      })
    case types.CLEAR_SFTP_FOLDER_DATA:
      return update(state, {
        sftpFolder: { $set: initialState.sftpFolder }
      })
    default:
      return state
  }
}

export default mediaReducer
