import React, { memo } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { compose } from '@reduxjs/toolkit'

import { Typography } from '@material-ui/core'
import Popup from 'components/Popup'
import classnames from 'classnames'

const styles = ({ typography, type, fontSize }) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: '#0A83C8',
    marginLeft: '6px',
    fontSize: '1.125rem',
    verticalAlign: 'sub',
    transform: 'translateY(1px)'
  },
  content: {
    color: typography.lightText[type].color,
    fontFamily: typography.fontFamily,
    fontSize: fontSize.primary,
    lineHeight: '20px',
    margin: '10px',
    whiteSpace: 'pre-line'
  }
})

const NoteIndicator = ({
  t,
  classes,
  icon = 'fa-light fa-note-sticky',
  popupPosition = 'right center',
  notes = 0,
  content = t('Count Note', { count: notes }),
  className,
  contentStyle = {}
}) => (
  <div className={classnames(classes.root, className)}>
    <Popup
      on="hover"
      trigger={<i className={classnames(icon, classes.icon)} />}
      position={popupPosition}
      contentStyle={contentStyle}
    >
      <Typography className={classes.content}>{content}</Typography>
    </Popup>
  </div>
)

NoteIndicator.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object,
  notes: PropTypes.number,
  className: PropTypes.string
}

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  memo
)(NoteIndicator)
