import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { createSelector } from 'reselect'
import { withStyles, Grid } from '@material-ui/core'

import { BlueButton } from 'components/Buttons'
import { CircularLoader } from 'components/Loaders'
import DefaultModal from 'components/Modal/DefaultModal'

import { getMediaCapAlert } from 'actions/mediaActions'
import { isEqual, isFalsy } from 'utils/generalUtils'
import { mediaCapAlertSelector } from 'selectors/mediaSelectors'
import BasePaginate from 'components/BasePaginate'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import GridViewCard, { MediaCardBottomInfo } from 'components/Card/GridViewCard'
import { getThumbnail } from 'utils/mediaUtils'

const styles = () => ({
  stretch: {
    gridColumnStart: 1,
    gridColumnEnd: 3,

    '& *::-webkit-scrollbar': {
      width: '0px'
    }
  },
  items: {
    minHeight: 500,
    marginTop: 10,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: 25
  },
  pagination: {
    margin: '15px auto'
  },
  placeholderWrap: {
    height: 587
  },
  gridCardRoot: {
    minWidth: '100%',
    maxHeight: 245
  }
})

const selector = createSelector(
  mediaCapAlertSelector,
  ({ isFetching, items, error, meta }) => ({
    isFetching,
    items,
    meta,
    error
  })
)

const FETCH_LIMIT = 8

function CapAlertMediaLibrary({
  t,
  classes,
  open = false,
  selectedMediaId = null,
  handleClose,
  onSuccess = f => f
}) {
  const dispatch = useDispatch()
  const { isFetching, items, meta } = useSelector(selector)
  const [selectedId, setSelectedId] = useState(selectedMediaId)

  const [page, setPage] = useState(1)

  const handleChangePage = useCallback(
    ({ selected }) => {
      dispatch(
        getMediaCapAlert({
          page: selected + 1,
          limit: FETCH_LIMIT
        })
      )
      setPage(selected + 1)
    },
    [dispatch]
  )
  useEffect(() => {
    setSelectedId(selectedMediaId)
  }, [selectedMediaId])

  useEffect(() => {
    dispatch(
      getMediaCapAlert({
        page: 1,
        limit: FETCH_LIMIT
      })
    )
  }, [dispatch])

  const onSelectImage = useCallback(id => setSelectedId(id), [setSelectedId])

  const handleSave = useCallback(() => {
    if (selectedId) {
      onSuccess(
        items.find(({ id }) => {
          return isEqual(id, selectedId)
        })
      )
    }
  }, [selectedId, onSuccess, items])

  const renderLoader = useMemo(() => {
    if (isFalsy(isFetching)) return null
    return <CircularLoader />
  }, [isFetching])

  const renderItems = useMemo(() => {
    if (items.length > 0) {
      return items.map(item => (
        <GridViewCard
          key={`cap-alert-${item.id}`}
          title={item.title}
          thumbnail={getThumbnail(item)}
          thumbnailIcon={item.feature?.icon}
          thumbnailColor={item.feature?.color}
          item={item}
          selected={selectedId === item.id}
          onSelect={() => onSelectImage(item.id)}
          bottomComponent={<MediaCardBottomInfo media={item} />}
          cardRootClassName={classes.gridCardRoot}
        />
      ))
    }

    return null
  }, [items, onSelectImage, selectedId, classes.gridCardRoot])

  return (
    <DefaultModal
      open={open}
      maxWidth="lg"
      onCloseModal={handleClose}
      modalTitle={t('Cap Alert Media Library')}
      actions={
        renderItems && (
          <BlueButton
            iconClassName="fa-regular fa-circle-check"
            onClick={handleSave}
          >
            {t('OK')}
          </BlueButton>
        )
      }
      hasCancelBtn={false}
      hasSaveBtn={false}
    >
      {renderLoader}
      {!isFetching && !items.length ? (
        <EmptyPlaceholder
          text={t('No Results Found')}
          rootClassName={classes.placeholderWrap}
        />
      ) : (
        <Grid container direction="column">
          <div className={classes.stretch}>
            <div className={classes.items}>{renderItems}</div>
          </div>
          <div className={classes.pagination}>
            {meta.lastPage > 1 && (
              <BasePaginate
                page={page}
                pageCount={meta.lastPage || 0}
                onPageChange={handleChangePage}
              />
            )}
          </div>
        </Grid>
      )}
    </DefaultModal>
  )
}

CapAlertMediaLibrary.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  deviceId: PropTypes.number,
  selectedMediaId: PropTypes.number,
  onSuccess: PropTypes.func
}

export default memo(
  compose(
    withTranslation('translations'),
    withStyles(styles)
  )(CapAlertMediaLibrary)
)
