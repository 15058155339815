export const VERTICAL_SPACING = 40
export const HORIZONTAL_SPACING = 145
export const NODE_HEIGHT = 70
export const PADDING_LEFT = 30
export const NODE_HEIGHT_WITH_SPACING = 75
export const NUMBER_OF_ROUTES = 10

export const deviceNocView = {
  OVERVIEW: 'overview',
  PREVIEW: 'preview',
  HEALTH: 'health',
  DIAGRAM: 'diagram',
  UPTIME: 'uptime'
}

export const ALARM_ALERT_COLORS = [
  '#7669ec',
  '#e17055',
  '#0984e3',
  '#ff7675',
  '#a29bfe',
  '#fd79a8',
  '#74b9ff',
  '#00b894',
  '#fdcb6e',
  '#55efc4',
  '#00cec9',
  '#fab1a0',
  '#b2bec3',
  '#dfe6e9',
  '#ffeaa7',
  '#81ecec'
]

export const alertAlarmViaType = {
  SMS: 'sms',
  EMAIL: 'email',
  NOC: 'noc'
}

export const alertAlarmColumnTypes = {
  int: 'int',
  boolean: 'boolean'
}

export const booleanOptions = [
  {
    value: 'true',
    label: 'true'
  },
  {
    value: 'false',
    label: 'false'
  }
]

export const alarmRuleValues = {
  AND: 'AND',
  OR: 'OR'
}
