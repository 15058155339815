export const uptimeSortTypes = {
  alphabetical: 'alias',
  leastActive: 'leastActive',
  mostActive: 'mostActive'
}

export const sortOptions = [
  { label: 'Alphabetical', value: uptimeSortTypes.alphabetical },
  { label: 'Least Active', value: uptimeSortTypes.leastActive },
  { label: 'Most Active', value: uptimeSortTypes.mostActive }
]
