import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CircleIconButton } from 'components/Buttons'
import handleBottomScroll from 'utils/handleBottomScroll'
import Scrollbars from 'components/Scrollbars'
import Text from 'components/Typography/Text'
import { TextWithTooltip } from 'components/Typography'
import Spacing from 'components/Containers/Spacing'
import RectListLoader from 'components/Loaders/RectListLoader'

const styles = ({ palette, type }) => ({
  root: {
    border: `solid 1px ${palette[type].deviceCard.border}`,
    borderRadius: 6,
    width: 325
  },
  contentWrapper: {
    padding: '10px 5px 10px 15px'
  },
  headerWrapper: {
    padding: '15px 15px',
    borderRadius: '6px 6px 0 0',
    background: palette[type].sideModal.groups.header.background
  },
  groupRow: {
    display: 'flex'
  },
  groupItemsWrap: {
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`
  },
  groupItemLabel: {
    lineHeight: '42px'
  },
  groupItemDelete: {
    fontSize: '16px',
    color: '#d35e37'
  },
  loaderContainer: {
    minHeight: 50,
    padding: 0,
    alignItems: 'center'
  },
  loaderTitle: {
    fontSize: 15
  },
  deleteIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 10
  },
  groupsLoader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  groupsLoaderItem: {
    height: '38px'
  }
})

const ContentWrapper = ({
  useScrollbar = false,
  children,
  handleFetchMore,
  ...props
}) =>
  useScrollbar ? (
    <Scrollbars
      style={{ height: 330 }}
      onUpdate={handleBottomScroll(handleFetchMore)}
    >
      <Grid container {...props}>
        {children}
      </Grid>
    </Scrollbars>
  ) : (
    <Grid container {...props}>
      {children}
    </Grid>
  )

const ItemsPopupContent = ({
  classes,
  id,
  groupItemsReducer,
  deleteGroupItemReducer,
  getGroupItems,
  onDeleteItem,
  clearGroupItemsInfo,
  renderFieldName,
  render,
  title,
  groupCardPopupRow: GroupCardPopupRow,
  initItemsCount,
  t
}) => {
  const [groupItems, setGroupItems] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!groupItemsReducer.response?.data?.length) {
      getGroupItems(id, 1)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (groupItemsReducer.response?.meta?.currentPage) {
      if (groupItemsReducer.response.meta.currentPage > 1) {
        setGroupItems(groupItems.concat(groupItemsReducer.response.data))
      } else {
        setGroupItems(groupItemsReducer.response.data)
      }
      setLoading(false)
    } else if (groupItemsReducer.error) {
      clearGroupItemsInfo()
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [groupItemsReducer])

  useEffect(() => {
    if (deleteGroupItemReducer?.response) {
      getGroupItems(id, 1)
    }
    // eslint-disable-next-line
  }, [deleteGroupItemReducer])

  const handleDelete = groupItemId => {
    onDeleteItem({
      groupId: id,
      itemId: groupItemId
    })
  }

  const handleFetchMore = useCallback(() => {
    if (
      groupItemsReducer.response.meta.currentPage <
      groupItemsReducer.response.meta.lastPage
    ) {
      getGroupItems(id, groupItemsReducer.response.meta.currentPage + 1)
    }
  }, [groupItemsReducer, getGroupItems, id])

  return (
    <Grid container className={classes.root}>
      {title && (
        <Grid container className={classes.headerWrapper}>
          <TextWithTooltip
            maxWidth={250}
            color="title.primary"
            weight="bold"
            variant="secondary"
          >
            {title}
          </TextWithTooltip>
        </Grid>
      )}
      <Grid container className={classes.contentWrapper}>
        {loading ? (
          <RectListLoader
            count={initItemsCount >= 8 ? 8 : initItemsCount}
            classes={{
              root: classes.groupsLoader,
              item: classes.groupsLoaderItem
            }}
          />
        ) : (
          <ContentWrapper
            useScrollbar={groupItems.length > 8}
            handleFetchMore={handleFetchMore}
          >
            {!groupItems.length && (
              <Spacing variant={0} paddingVert={2} alignItems="center">
                <Text>{t('Nothing to show')}</Text>
              </Spacing>
            )}
            {groupItems.map((groupItem, index) => {
              const title = render
                ? render(groupItem)
                : renderFieldName
                ? groupItem[renderFieldName]
                : groupItem.name || groupItem.title || groupItem.fileName
              return (
                <Grid
                  key={`group-item-${index}`}
                  container
                  className={classNames(classes.groupRow, {
                    [classes.groupItemsWrap]: index !== groupItems.length - 1
                  })}
                >
                  {GroupCardPopupRow ? (
                    <GroupCardPopupRow item={groupItem} />
                  ) : (
                    <Grid item>
                      <TextWithTooltip
                        maxWidth={240}
                        rootClassName={classes.groupItemLabel}
                      >
                        {title}
                      </TextWithTooltip>
                    </Grid>
                  )}
                  {onDeleteItem && (
                    <Grid item className={classes.deleteIconWrapper}>
                      <CircleIconButton
                        className={classes.groupItemDelete}
                        onClick={() => handleDelete(groupItem.id)}
                      >
                        <i className="fa-regular fa-trash-can" />
                      </CircleIconButton>
                    </Grid>
                  )}
                </Grid>
              )
            })}
          </ContentWrapper>
        )}
      </Grid>
    </Grid>
  )
}

ItemsPopupContent.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  groupItemsReducer: PropTypes.object.isRequired,
  deleteGroupItemReducer: PropTypes.object,
  getGroupItems: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func,
  groupCardPopupRow: PropTypes.elementType,
  title: PropTypes.string
}

export default withTranslation('translations')(
  withStyles(styles)(ItemsPopupContent)
)
