import React from 'react'
import ContentLoader from 'react-content-loader'
import { withTheme } from '@material-ui/core'

const rows = (
  length,
  yPositionStart,
  yPositionStep,
  xFirstRect,
  xSecondRect,
  width,
  height,
  rx,
  ry
) =>
  Array.from({ length }, (_, i) => i + 1).map(item => {
    const y = yPositionStart + yPositionStep * item
    return (
      <React.Fragment key={item}>
        <rect
          x={xFirstRect}
          y={y}
          width={width}
          height={height}
          rx={rx}
          ry={ry}
        />
        <rect
          x={xSecondRect}
          y={y}
          width={width}
          height={height}
          rx={rx}
          ry={ry}
        />
      </React.Fragment>
    )
  })

const DeviceMoreInfoCardLoader = ({
  theme,
  height = '100%',
  isPortraitImg,
  rx = 4,
  ry = 4
}) => {
  return (
    <ContentLoader
      style={{ width: '100%', height, borderRadius: 5 }}
      backgroundColor={theme.palette[theme.type].loader.background}
      foregroundColor={theme.palette[theme.type].loader.foreground}
    >
      {isPortraitImg ? (
        <>
          <rect x="0" y="0" width="100%" height="45" />
          <rect x="20" y="68" width="180" height="375" rx={rx} ry={ry} />
          {rows(9, 26, 43, 231, 430, 170, 30, rx, ry)}
        </>
      ) : (
        <>
          <rect x="0" y="0" width="100%" height="46" />
          <rect x="20" y="66" width="320" height="218" rx={rx} ry={ry} />
          {rows(9, 255, 36, 20, 190, 150, 27, rx, ry)}
        </>
      )}
    </ContentLoader>
  )
}

export default withTheme(DeviceMoreInfoCardLoader)
