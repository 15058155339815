import api from './api'

import { errorHandler } from 'utils/index'
import transformPermissions from 'utils/transformPermissions'
import { getApiParamsByOriginalUser } from 'utils/localStorage'

export async function getDetails() {
  try {
    const response = await api({
      method: 'GET',
      url: '/me'
    })

    const permissions = transformPermissions(response.data.role.permission)
    return { ...response.data, permissions }
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function getOriginalUserDetails() {
  try {
    const response = await api({
      method: 'GET',
      ...getApiParamsByOriginalUser('/me')
    })

    const permissions = transformPermissions(response.data.role.permission)
    return { ...response.data, permissions }
  } catch (error) {
    throw errorHandler(error)
  }
}

const putDetails = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/me',
      data: data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function getProfile() {
  try {
    const response = await api({
      method: 'GET',
      url: '/user/profile'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putRegionalSettings = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/regionalSettings',
      data: data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const regeneratePIN = async userId => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/user/${userId}/updatePinCode`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postUserBulk = async ({ userIds, data }) => {
  try {
    await api({
      method: 'POST',
      url: `/user/bulkAssignGroupsAndTags`,
      data: {
        userIds,
        ...data
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const userService = {
  getDetails,
  putDetails,
  getOriginalUserDetails,
  getProfile,
  putRegionalSettings,
  regeneratePIN,
  postUserBulk
}

export default userService
