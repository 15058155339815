import useUserRole from 'hooks/tableLibrary/useUserRole'
import useUserFeatures from 'hooks/useUserFeatures'
import { CLIENT_ADMIN } from 'constants/roles'
import { featureNames } from 'constants/featureConstants'
import { createRoomPermission } from 'utils/roomUtils'

const useSpaceBookingPermissions = () => {
  const role = useUserRole()

  const userFeatures = useUserFeatures()
  const isSpaceBookingEnabled = userFeatures.find(
    ({ name }) => name === featureNames.SpaceBooking
  )

  const hasPermission =
    isSpaceBookingEnabled && role.org && role.level === CLIENT_ADMIN
  const roomPermission = createRoomPermission(hasPermission)

  return {
    isSpaceBookingEnabled,
    hasPermission,
    roomPermission
  }
}

export default useSpaceBookingPermissions
