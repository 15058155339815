import React, { memo, useCallback, useMemo } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import Tooltip from 'components/Tooltip'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { DateTimeView } from 'components/TableLibrary'
import { TextWithTooltip } from 'components/Typography'
import { routeByName } from 'constants/index'
import { secToLabel, labelToSec } from 'utils/secToLabel'
import { getUrlPrefix } from 'utils'
import { getMediaPreview } from 'actions/mediaActions'
import { getIconInfo } from 'utils/mediaUtils'

const useStyles = makeStyles(({ palette, type, typography }) => ({
  listItem: {
    padding: '5px 5px 5px 16px',
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`
  },
  tooltip: {
    margin: '5px 20px',
    '&:after': {
      borderTop: 'none'
    }
  },
  tooltipWithoutSelect: {
    margin: '5px 20px 5px 0px'
  },
  title: {
    ...typography.darkAccent[type],
    textDecoration: 'none'
  },
  textSmall: {
    ...typography.subtitle[type]
  },
  dateWrap: {
    textAlign: 'center',
    marginRight: '30px'
  },
  pointer: {
    cursor: 'pointer'
  }
}))

const ModalMediaItem = ({ item }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { id, title, updatedAt, duration, feature } = item

  const iconInfo = useMemo(() => {
    return getIconInfo(item, 'media')
  }, [item])

  const renderTitle = useMemo(() => {
    return (
      <Link
        to={getUrlPrefix(`${routeByName.media.root}?title=${title}&exact=true`)}
        target="_blank"
        className={classNames(classes.title, classes.pointer)}
      >
        <TextWithTooltip
          maxWidth={250}
          placement="bottom"
          color="title.primary"
          weight="bold"
        >
          {title}
        </TextWithTooltip>
      </Link>
    )
  }, [title, classes])

  const handlePreviewClick = useCallback(() => dispatch(getMediaPreview(id)), [
    id,
    dispatch
  ])

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      key={id}
      className={classes.listItem}
    >
      <Grid item>
        <Grid container alignItems="center">
          <Tooltip
            arrow
            withWrapper
            title={feature.alias}
            className={classNames(
              classes.tooltip,
              classes.tooltipWithoutSelect
            )}
          >
            <LibraryTypeIcon
              onClick={handlePreviewClick}
              iconHelperClass={iconInfo.iconHelperClass}
              color={iconInfo.color}
              type={item.type ? item.type : item.feature}
              wrapHelperClass={classes.typeIconWrap}
              icon={iconInfo.icon}
            />
          </Tooltip>
          <div>
            {renderTitle}
            <Typography className={classes.textSmall}>
              {duration === '00:00:00'
                ? 'N/A'
                : secToLabel(labelToSec(duration))}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid item className={classes.dateWrap}>
        <DateTimeView date={updatedAt} />
      </Grid>
    </Grid>
  )
}

export default memo(ModalMediaItem)
