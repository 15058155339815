import { storageGetExpires } from 'utils/localStorage'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import i18next from 'i18next'

export const secToMs = sec => sec * 1000

export const calculateExpires = sec => {
  const ms = secToMs(sec)
  return Date.now() + ms
}

export function getNow() {
  return Date.now()
}

export function getFormattedDateWithTimeZone(date, format = 'lll') {
  const tz = momentTZ.tz(momentTZ.tz.guess()).zoneAbbr()

  return `${moment(date)
    .locale(i18next?.language || 'en')
    .format(format)} ${tz}`
}

export const isExpired = () => Date.now() > storageGetExpires()

export const replaceTimezone = (date, timezone) => {
  return momentTZ(momentTZ(date).format('YYYY-MM-DDTHH:mm:ss'))
    .tz(timezone, true)
    .format()
}
