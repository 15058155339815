import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { withStyles, Paper, Typography, RootRef } from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import classNames from 'classnames'

import { WhiteButton } from 'components/Buttons'
import { FormControlInput } from 'components/Form'
import ItemsPopupContent from 'components/Group/ItemsPopupContent'
import MaterialPopup from 'components/Popup/MaterialPopup'
import { TextWithTooltip } from 'components/Typography'
import { groupTitleMax, requiredField } from 'constants/validationMessages'
import Error from 'components/Form/Error'
import MaterialPopupV2 from 'components/Popup/MaterialPopupV2/MaterialPopupV2'

const styles = ({
  colors,
  palette,
  type,
  fontSize,
  lineHeight,
  fontWeight
}) => ({
  root: {
    padding: '25px',
    marginBottom: '15px',
    border: `1px solid ${palette[type].groupCard.border}`,
    borderLeft: '5px solid #3983ff',
    backgroundColor: palette[type].groupCard.background,
    borderRadius: '7px',
    boxShadow: `0 1px 4px 0 ${palette[type].groupCard.shadow}`,
    display: 'flex',
    alignItems: 'center'
  },
  groupTitle: {
    fontSize: fontSize.secondary,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.secondary,
    color: palette[type].groupCard.titleColor
  },
  groupTitleNotEditable: {
    cursor: 'unset'
  },
  groupItemsWrapper: {
    width: 325
  },
  groupItemsContainer: {
    padding: '10px 15px'
  },
  groupItemsLabel: {
    fontSize: fontSize.primary,
    lineHeight: lineHeight.primary,
    fontWeight: fontWeight.bold,
    color: palette[type].groupCard.item.label,
    marginTop: 10,
    width: 'fit-content'
  },
  groupItemsLabelUnderline: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: colors.highlight,
    textUnderlineOffset: '2px',
    '&:hover': {
      cursor: 'pointer',
      textDecorationStyle: 'solid'
    }
  },
  popupRoot: {
    zIndex: 1399
  },
  actionBtn: {
    minWidth: '32px',
    paddingLeft: '5px',
    paddingRight: '5px',
    boxShadow: '0 1px 0 0 rgba(216, 222, 234, 0.5)',
    color: '#0a83c8',

    '&:hover': {
      borderColor: '#1c5dca',
      backgroundColor: '#1c5dca',
      color: '#f5f6fa'
    }
  },
  actionBtnIcon: {
    width: 18,
    height: 18,
    color: palette[type].groupCard.button.color
  },
  userPermissionsDropdownContainer: {
    width: '400px'
  },
  userPermissionsDropdown: {},
  groupItemRoot: {
    width: '85%',
    flex: 1,
    paddingRight: 20,
    '& > div': {
      cursor: 'pointer'
    }
  },
  groupItemContent: {
    maxWidth: 'fit-content'
  }
})

const GroupCard = ({
  id,
  classes,
  rootClassName = '',
  title = '',
  itemsCount = 0,
  groupItemsLabel = 'Items',
  color = '#3983ff',
  ActionDropdownComponent,
  dropItemType = '',
  onMoveItem = f => f,
  onChangeGroupTitle = f => f,
  itemsPopupProps = {},
  useActionDropdown = true,
  actionLink = '',
  groupTitleEditable = true,
  hideItemCount = false,
  actionDropdownOn = 'hover',
  error
}) => {
  const [groupTitle, setGroupTitle] = useState(title)
  const [editTitle, setEditTitle] = useState(false)
  const [titleError, setTitleError] = useState(null)

  const [{ isOver }, drop] = useDrop({
    accept: dropItemType || '',
    drop: e => onMoveItem(e.id, id),
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  })

  const handleInputChange = useCallback(({ currentTarget }) => {
    setGroupTitle(currentTarget.value)
  }, [])

  useEffect(() => {
    if (groupTitle.length <= 25 && groupTitle.length !== 0) {
      setTitleError(null)
    } else {
      if (groupTitle.length === 0) setTitleError(requiredField)
      else setTitleError(groupTitleMax)
    }
  }, [groupTitle])

  const handleChangeTitle = useCallback(() => {
    setEditTitle(false)
    if (title !== groupTitle && !titleError) {
      onChangeGroupTitle(id, groupTitle)
    }
  }, [title, groupTitle, titleError, onChangeGroupTitle, id])

  const handleEnterKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleChangeTitle()
      }
    },
    [handleChangeTitle]
  )

  const handleFocus = useCallback(() => {
    setGroupTitle(title)
    setEditTitle(true)
  }, [title])

  const handleBlur = useCallback(() => {
    handleChangeTitle()
  }, [handleChangeTitle])

  return (
    <RootRef rootRef={drop}>
      <Paper
        style={{
          borderLeftColor: color,
          borderRightColor: isOver ? color : '',
          borderTopColor: isOver ? color : '',
          borderBottomColor: isOver ? color : ''
        }}
        className={[classes.root, rootClassName].join(' ')}
      >
        <div className={classes.groupItemRoot}>
          <div
            onClick={() => {
              if (groupTitleEditable) {
                setEditTitle(true)
              }
            }}
            onKeyDown={handleEnterKeyPress}
          >
            {groupTitleEditable && editTitle ? (
              <FormControlInput
                autoFocus
                id="group-title"
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={groupTitle}
                onChange={handleInputChange}
                error={titleError}
                touched
              />
            ) : (
              <div className={classes.groupItemContent}>
                <TextWithTooltip
                  title={title}
                  maxWidth={250}
                  color="title.primary"
                  rootClassName={classNames(classes.groupTitle, {
                    [classes.groupTitleNotEditable]: !groupTitleEditable
                  })}
                >
                  {title}
                </TextWithTooltip>
              </div>
            )}
          </div>
          <Error error={error} condition={!!error} />
          {!hideItemCount && (
            <MaterialPopupV2
              disabled={itemsCount < 1}
              onClose={() => itemsPopupProps.clearGroupItemsInfo()}
              placement="bottom-end"
              trigger={
                <Typography
                  className={classNames(
                    classes.groupItemsLabel,
                    classes.groupItemsLabelUnderline
                  )}
                >
                  {itemsCount} {groupItemsLabel}
                </Typography>
              }
            >
              <ItemsPopupContent
                initItemsCount={itemsCount}
                {...itemsPopupProps}
                id={id}
              />
            </MaterialPopupV2>
          )}
        </div>
        {useActionDropdown ? (
          <MaterialPopup
            on={actionDropdownOn}
            placement="right"
            preventOverflow={{
              enabled: true,
              priority: ['top', 'bottom'],
              boundariesElement: 'viewport'
            }}
            trigger={
              <WhiteButton className={classes.actionBtn}>
                <Settings className={classes.actionBtnIcon} />
              </WhiteButton>
            }
            rootClassName={classes.popupRoot}
          >
            {ActionDropdownComponent}
          </MaterialPopup>
        ) : (
          <WhiteButton
            disabled={itemsCount < 1}
            className={classes.actionBtn}
            component={Link}
            to={{
              pathname: actionLink,
              state: {
                name: title
              }
            }}
          >
            <Settings className={classes.actionBtnIcon} />
          </WhiteButton>
        )}
      </Paper>
    </RootRef>
  )
}

GroupCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  classes: PropTypes.object.isRequired,
  rootClassName: PropTypes.string,
  title: PropTypes.string,
  itemsCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  groupItemsLabel: PropTypes.string,
  color: PropTypes.string,
  ActionDropdownComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
    .isRequired,
  dropItemType: PropTypes.string,
  onMoveItem: PropTypes.func,
  onChangeGroupTitle: PropTypes.func,
  itemsPopupProps: PropTypes.object,
  useActionDropdown: PropTypes.bool,
  actionLink: PropTypes.string,
  groupTitleEditable: PropTypes.bool,
  hideItemCount: PropTypes.bool
}

export default withStyles(styles)(GroupCard)
