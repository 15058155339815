import React, { useCallback, useEffect, useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import DefaultModal from './DefaultModal'
import { BlueButton } from 'components/Buttons'
import Spacing from 'components/Containers/Spacing'
import Container from 'components/Containers/Container'
import { TextWithTooltip, Text } from 'components/Typography'
import { FormControlSelectFont } from 'components/Form'
import Scrollbars from '../Scrollbars'
import { withStyles } from '@material-ui/core'

const styles = () => ({
  modalContent: {
    padding: '20px 8px 0px 40px'
  }
})

const initialFormValues = {
  fonts: []
}

const MissingFontsModal = ({ open, fonts, onSave, classes, t }) => {
  const handleFormSubmit = useCallback(
    values => {
      onSave(values.fonts)
    },
    [onSave]
  )

  const { values, handleChange, setValues, handleSubmit } = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleFormSubmit
  })

  useEffect(
    () => {
      setValues({
        fonts: fonts.map(font => ({
          ...font,
          selectedFontFamily: 'Nunito Sans'
        }))
      })
    },
    // eslint-disable-next-line
    [fonts]
  )

  const renderTextRows = useMemo(
    () =>
      values.fonts.map(
        ({ id, text, fontFamily, selectedFontFamily }, index) => (
          <React.Fragment key={id}>
            <TextWithTooltip maxWidth={176} color="title.primary">
              {text}
            </TextWithTooltip>
            <TextWithTooltip maxWidth={176}>{fontFamily}</TextWithTooltip>
            <FormControlSelectFont
              name={`fonts[${index}].selectedFontFamily`}
              value={selectedFontFamily}
              onChange={handleChange}
              withInitialOptions
              marginBottom={false}
              menuPosition="fixed"
            />
          </React.Fragment>
        )
      ),
    [values, handleChange]
  )

  return (
    <DefaultModal
      open={open}
      modalTitle={t('Missing Fonts')}
      contentClass={classes.modalContent}
      actions={<BlueButton onClick={handleSubmit}>{t('OK')}</BlueButton>}
      overflowVisible
      hasCloseIcon={false}
    >
      <Spacing paddingVert={1}>
        <Text>
          {t(
            'The following fonts are not available. Please choose replacement fonts'
          )}
        </Text>
      </Spacing>
      <Spacing>
        <Scrollbars autoHeight autoHeightMax={610}>
          <Spacing paddingRight={4}>
            <Container cols="3" alignItems="center">
              {renderTextRows}
            </Container>
          </Spacing>
        </Scrollbars>
      </Spacing>
    </DefaultModal>
  )
}

MissingFontsModal.propTypes = {
  fonts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string.isRequired,
      text: PropTypes.string
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
}

export default withTranslation('translations')(
  withStyles(styles)(memo(MissingFontsModal))
)
