import {
  ENTERPRISE_TOKEN,
  instanceTypes,
  ORG_TOKEN,
  SYSTEM_TOKEN,
  vuePreviews
} from '../constants/api'
import { VUE_PREVIEW } from '../constants/localStorage'

export const getBaseUrl = () => {
  if (!process.env.REACT_APP_BASE_URL) {
    return ''
  }
  return process.env.REACT_APP_BASE_URL.endsWith('/')
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL + '/'
}

export const createSuccessInvalidator = tags => (response, error) =>
  !error && tags

export const getCRUDEndpoints = ({ builder, tags, url, name, pluralName }) => ({
  //GET LIST
  [`get${pluralName ? pluralName : `${name}s`}`]: builder.query({
    ...(tags && { providesTags: tags }),
    query: (params = {}) => ({
      url,
      params
    })
  }),
  //GET ITEM
  [`get${name}`]: builder.query({
    query: id => ({
      url: `${url}/${id}`
    })
  }),
  //POST
  [`post${name}`]: builder.mutation({
    ...(tags && { invalidatesTags: createSuccessInvalidator(tags) }),
    query: data => ({
      method: 'POST',
      url,
      data
    })
  }),
  //PUT
  [`put${name}`]: builder.mutation({
    ...(tags && { invalidatesTags: createSuccessInvalidator(tags) }),
    query: ({ id, ...data }) => ({
      method: 'PUT',
      url: `${url}/${id}`,
      data
    })
  }),
  //DELETE
  [`delete${name}`]: builder.mutation({
    ...(tags && { invalidatesTags: createSuccessInvalidator(tags) }),
    query: ({ id, ...data }) => ({
      method: 'DELETE',
      url: `${url}/${id}`,
      data
    })
  })
})

export const isStorageHasToken = () =>
  !!localStorage.getItem(ORG_TOKEN) ||
  !!localStorage.getItem(SYSTEM_TOKEN) ||
  !!localStorage.getItem(ENTERPRISE_TOKEN)

export const isVueV3Preview = () =>
  [instanceTypes.dev, instanceTypes.local].includes(
    process.env.REACT_APP_INSTANCE
  ) && localStorage.getItem(VUE_PREVIEW) === vuePreviews.v3
