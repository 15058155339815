import { useDispatch, useSelector } from 'react-redux'
import { configMediaCategorySelector } from 'selectors/configSelectors'
import { useCallback, useEffect, useMemo } from 'react'

import { getConfigMediaCategory } from 'actions/configActions'
import { featuresToExclude } from 'constants/media'
import {
  GALLERY_MEDIA_CATEGORY_ID,
  galleryMediaCategory,
  designGalleryFeature,
  featureNames,
  menuMakerFeature
} from 'constants/featureConstants'
import useFeaturesAvailability from 'hooks/api/useFeaturesAvailability'

export default function useConfigMediaCategory(
  exclude = [],
  options = {
    withDesignGallery: false,
    withMenuMaker: false,
    excludeAlertAndEmail: false,
    preventInitialFetch: false
  }
) {
  const dispatch = useDispatch()

  const {
    withDesignGallery,
    withMenuMaker,
    excludeAlertAndEmail,
    preventInitialFetch
  } = options || {}
  const { response, error, isFetching, isUninitialized } = useSelector(
    configMediaCategorySelector
  )

  const excludeFeatures = useMemo(() => {
    if (excludeAlertAndEmail) {
      return featuresToExclude
    }
    if (exclude && exclude.length) {
      return exclude
    }
    return []
  }, [excludeAlertAndEmail, exclude])

  const [isDesignGalleryEnable, isMenuMakerEnable] = useFeaturesAvailability(
    featureNames.DesignGallery,
    featureNames.MenuMaker
  )

  const filteredResponse = useMemo(() => {
    if (
      (!excludeFeatures || !excludeFeatures.length) &&
      !withDesignGallery &&
      !withMenuMaker
    ) {
      return response
    }

    const mediaCategories = [...(response || [])]

    if (
      (isDesignGalleryEnable && withDesignGallery) ||
      (isMenuMakerEnable && withMenuMaker)
    ) {
      const galleryCategory = mediaCategories.find(
        ({ id }) => id === GALLERY_MEDIA_CATEGORY_ID
      )

      if (!galleryCategory) {
        mediaCategories.push(galleryMediaCategory)
      }
    }

    if (!mediaCategories || !mediaCategories.length) {
      return []
    }

    return mediaCategories.map(category => {
      if (
        ((withDesignGallery && isDesignGalleryEnable) ||
          (isMenuMakerEnable && withMenuMaker)) &&
        category.name === 'Gallery'
      ) {
        return {
          ...category,
          feature: [
            ...(withDesignGallery && isDesignGalleryEnable
              ? [designGalleryFeature]
              : []),
            ...(withMenuMaker && isMenuMakerEnable ? [menuMakerFeature] : []),
            ...category.feature.filter(
              ({ name }) => !excludeFeatures.includes(name)
            )
          ]
        }
      } else {
        return {
          ...category,
          feature: category.feature.filter(
            ({ name }) => !excludeFeatures.includes(name)
          )
        }
      }
    })
  }, [
    response,
    excludeFeatures,
    withDesignGallery,
    withMenuMaker,
    isDesignGalleryEnable,
    isMenuMakerEnable
  ])

  const fetcher = useCallback(() => {
    dispatch(getConfigMediaCategory())
  }, [dispatch])

  useEffect(
    () => {
      if (!response.length && !isFetching && !preventInitialFetch) {
        fetcher()
      }
    },
    // eslint-disable-next-line
    []
  )

  return useMemo(
    () => ({
      response: filteredResponse,
      error,
      isFetching,
      isUninitialized,
      getConfigMediaCategory: fetcher
    }),
    [filteredResponse, error, isFetching, isUninitialized, fetcher]
  )
}
