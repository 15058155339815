import moment from 'moment'

import { updateTokenService } from 'services'
import { getOriginalUser, storageSetToken } from 'utils/localStorage'
import getTokenName from 'utils/getTokenName'
import store from 'store/configureStore'
import { UPDATE_USER_TOKEN_SUCCESS } from 'actions'
import getToken from 'utils/getToken'
import api from 'services/api'
import { handleUnauthorized } from 'utils'
import { calculateExpires } from 'utils/date'

const refreshingTypes = {
  current: 'current',
  original: 'original'
}
const refreshingState = {
  [refreshingTypes.current]: false,
  [refreshingTypes.original]: false
}
let refreshSubscribers = []
let originalUserRefreshSubscribers = []

let lastRefreshDate = moment()
let refreshCount = 0

const onRefreshed = impersonated => {
  const subscribersArray = impersonated
    ? originalUserRefreshSubscribers
    : refreshSubscribers

  if (refreshCount <= 3) {
    subscribersArray.forEach(cb => cb())
  }

  if (impersonated) {
    originalUserRefreshSubscribers = []
  } else {
    refreshSubscribers = []
  }
}

const subscribeTokenRefresh = (callback, impersonated) => {
  const subscribersArray = impersonated
    ? originalUserRefreshSubscribers
    : refreshSubscribers

  subscribersArray.push(callback)
}

const asyncRefreshToken = (originalRequest, err) => {
  const impersonated = originalRequest.impersonated
  if (
    originalRequest.url.includes('/refresh') ||
    originalRequest.url.includes('/public')
  ) {
    if (impersonated) {
      localStorage.removeItem('originalUsers')
    }
    handleUnauthorized()
    return Promise.reject(err)
  }

  const refreshingType = impersonated
    ? refreshingTypes.original
    : refreshingTypes.current

  if (!refreshingState[refreshingType]) {
    refreshingState[refreshingType] = true

    updateTokenService(impersonated)
      .then(({ accessToken, expiresIn, tokenType }) => {
        if (Math.abs(moment().diff(lastRefreshDate, 'minutes')) < 1) {
          refreshCount++
        } else {
          refreshCount = 0
        }
        lastRefreshDate = moment()
        if (impersonated) {
          const { type } = getOriginalUser() || {}
          localStorage.setItem(
            'originalUsers',
            JSON.stringify([
              {
                type,
                token: `${tokenType} ${accessToken}`,
                expiresIn: calculateExpires(expiresIn)
              }
            ])
          )
        } else {
          storageSetToken(getTokenName(), tokenType, accessToken, expiresIn)
          store.dispatch({ type: UPDATE_USER_TOKEN_SUCCESS })
        }
        onRefreshed(impersonated)
      })
      .finally(() => {
        refreshingState[refreshingType] = false
      })
  }

  return new Promise(resolve => {
    subscribeTokenRefresh(() => {
      originalRequest.headers['Authorization'] = impersonated
        ? getOriginalUser()?.token
        : getToken()
      resolve(api(originalRequest))
    }, impersonated)
  })
}

export default asyncRefreshToken
