import { _add } from 'utils/lodash'
import { UPDATE_USER_TOKEN_SUCCESS } from 'actions'

const initialState = {
  counter: 0,
  impersonatedCounter: 0
}

export default function refreshTokenReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_TOKEN_SUCCESS:
      return {
        ...state,
        ...(action.impersonated
          ? { impersonatedCounter: _add(state.impersonatedCounter, 1) }
          : { counter: _add(state.counter, 1) })
      }
    default:
      return state
  }
}
