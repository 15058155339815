import React from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(({ colors }) => ({
  iconStack: {
    position: 'relative',
    width: '2em',
    height: '5em',
    lineHeight: '4em'
  },
  iconStack1x: {
    lineHeight: 'inherit',
    position: 'absolute',
    width: '100%',
    textAlign: 'right',
    right: '-2px',
    top: '-3px',
    color: colors.light
  },
  iconStack3x: {
    fontSize: '24px',
    position: 'absolute',
    left: 0,
    width: '100%',
    textAlign: 'right',
    color: colors.light
  }
}))

const IconStack = ({
  wrapperClassName = '',
  mainClassName = '',
  secondClassName = '',
  wrapperStyles,
  generalStyles
}) => {
  const classes = useStyles()

  return (
    <span
      className={classNames(classes.iconStack, wrapperClassName)}
      style={wrapperStyles}
    >
      <i
        className={classNames(classes.iconStack3x, mainClassName)}
        style={generalStyles}
      />
      <i
        className={classNames(classes.iconStack1x, secondClassName)}
        style={generalStyles}
      />
    </span>
  )
}

export default IconStack
