import { colorPalette } from 'constants/chipsColorPalette'

const getLightFromBase = color => {
  if (!color) {
    return null
  }
  const item = colorPalette.find(i => i.base === color.replaceAll(' ', ''))
  if (item) {
    return item.light
  }
  return null
}

export { getLightFromBase }
