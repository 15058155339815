import * as types from './index'

const getDeviceItemsAction = params => ({
  type: types.GET_DEVICE_ITEMS,
  params
})

const resetDeviceFetchParamsAction = () => ({
  type: types.CLEAR_DEVICE_FETCH_PARAMS
})

const clearGetDeviceItemsAction = () => ({
  type: types.CLEAR_GET_DEVICE_ITEMS
})

const getDeviceGroupsAction = (data = {}) => ({
  type: types.GET_DEVICE_GROUPS,
  payload: data
})

const clearGetDeviceGroupsInfoAction = () => ({
  type: types.CLEAR_GET_DEVICE_GROUPS_INFO
})

const putDeviceItemAction = data => ({
  type: types.PUT_DEVICE_ITEM,
  payload: data
})

const postDeviceItemAction = data => ({
  type: types.POST_DEVICE_ITEM,
  payload: data
})

const clearPutDeviceItemInfoAction = () => ({
  type: types.CLEAR_PUT_DEVICE_ITEM_INFO
})

const clearPutDeviceNetworkConfigAction = () => ({
  type: types.CLEAR_PUT_DEVICE_NETWORK_CONFIG
})

const clearPostDeviceInfo = () => ({
  type: types.CLEAT_POST_DEVICE_INFO
})

const getDeviceItemAction = id => ({
  type: types.GET_DEVICE_ITEM,
  payload: id
})

const deleteDeviceItemAction = id => ({
  type: types.DELETE_DEVICE_ITEM,
  payload: id
})

const clearDeviceItemInfo = () => ({
  type: types.CLEAR_DEVICE_ITEM_INFO
})

const clearGetDeviceItemInfoAction = () => ({
  type: types.CLEAR_GET_DEVICE_ITEM_INFO
})

const getDevicePreviewAction = id => ({
  type: types.GET_DEVICE_PREVIEW,
  payload: id
})

const getDeviceLocationCategories = () => ({
  type: types.GET_DEVICE_LOCATION_CATEGORIES
})

const clearDeviceLocationCategoriesInfo = () => ({
  type: types.CLEAR_GET_DEVICE_LOCATION_CATEGORIES
})

const closeDevicePreview = () => ({
  type: types.CLOSE_DEVICE_PREVIEW
})

const showDevicePreview = () => ({
  type: types.SHOW_DEVICE_PREVIEW
})

const clearGetDevicePreviewInfoAction = () => ({
  type: types.CLEAR_GET_DEVICE_PREVIEW_INFO
})

const getDeviceGroupItemsAction = (id, params) => ({
  type: types.GET_DEVICE_GROUP_ITEMS,
  payload: { id, params }
})

const clearGetDeviceGroupItemsInfoAction = () => ({
  type: types.CLEAR_GET_DEVICE_GROUP_ITEMS_INFO
})

const postDeviceGroupItemAction = data => ({
  type: types.POST_DEVICE_GROUP_ITEM,
  payload: data
})

const clearPostDeviceGroupItemInfoAction = () => ({
  type: types.CLEAR_POST_DEVICE_GROUP_ITEM_INFO
})

const deleteDeviceGroupItemAction = data => ({
  type: types.DELETE_DEVICE_GROUP_ITEM,
  payload: data
})

const clearDeleteDeviceGroupItemInfoAction = () => ({
  type: types.CLEAR_DELETE_DEVICE_GROUP_ITEM_INFO
})

const clearDeviceGroupItemsInfo = () => ({
  type: types.CLEAR_DEVICE_GROUP_ITEMS_RESPONSE_INFO
})

const getDeviceReboot = id => ({
  type: types.GET_DEVICE_REBOOT,
  payload: id
})

const clearGetDeviceRebootInfo = () => ({
  type: types.CLEAR_GET_DEVICE_REBOOT_INFO
})

const putDeviceReboot = data => ({
  type: types.PUT_DEVICE_REBOOT,
  payload: data
})

const putDevicePeriodicalReboot = data => ({
  type: types.PUT_PERIODICAL_DEVICE_REBOOT,
  payload: data
})

const getScreenPowerSettings = id => ({
  type: types.GET_SCREEN_POWER_SETTINGS,
  payload: id
})

const putScreenPowerSettings = ({ deviceId, data }) => ({
  type: types.PUT_SCREEN_POWER_SETTINGS,
  payload: { deviceId, data }
})

const putDeviceBulkReboot = data => ({
  type: types.PUT_DEVICE_BULK_REBOOT,
  payload: data
})

const putDeviceBulkPeriodicalReboot = data => ({
  type: types.PUT_DEVICE_BULK_PERIODICAL_REBOOT,
  payload: data
})

const clearPutDeviceRebootInfo = () => ({
  type: types.CLEAR_PUT_DEVICE_REBOOT_INFO
})

const clearPutScreenPowerSettingsInfo = () => ({
  type: types.CLEAR_PUT_SCREEN_POWER_SETTINGS_INFO
})

const postDeviceNoteAction = (id, data) => ({
  type: types.POST_DEVICE_NOTE,
  data,
  id
})
const clearPostDeviceNoteAction = () => ({
  type: types.CLEAR_POST_DEVICE_NOTE
})

const getDeviceNotesAction = id => ({
  type: types.GET_DEVICE_NOTES,
  id
})

export function getCapAlertDevices() {
  return {
    type: types.REQUEST_CAP_ALERT_DEVICES
  }
}

export function capAlertDevicesSuccess(data) {
  return {
    type: types.CAP_ALERT_DEVICES_SUCCESS,
    payload: data
  }
}

export function capAlertDevicesError(error) {
  return {
    type: types.CAP_ALERT_DEVICES_ERROR,
    payload: error
  }
}

const getDeviceTypes = params => {
  return {
    type: types.GET_DEVICE_TYPES,
    payload: params
  }
}

const getDeviceType = (id, appendToLibrary = false) => {
  return {
    type: types.GET_DEVICE_TYPE,
    payload: id,
    appendToLibrary
  }
}

const putDeviceType = (id, data) => {
  return {
    type: types.PUT_DEVICE_TYPE,
    payload: {
      id,
      data
    }
  }
}

const postDeviceType = data => {
  return {
    type: types.POST_DEVICE_TYPE,
    payload: data
  }
}

const clearDeviceTypeInfo = () => {
  return {
    type: types.CLEAR_DEVICE_TYPE_INFO
  }
}

const getDeviceModels = params => {
  return {
    type: types.GET_DEVICE_MODELS,
    payload: params
  }
}

const getDeviceModel = (id, appendToLibrary = false) => {
  return {
    type: types.GET_DEVICE_MODEL,
    payload: id,
    appendToLibrary
  }
}

const putDeviceModel = (id, data) => {
  return {
    type: types.PUT_DEVICE_MODEL,
    payload: {
      id,
      data
    }
  }
}

const postDeviceModel = data => {
  return {
    type: types.POST_DEVICE_MODEL,
    payload: data
  }
}

const clearDeviceModelInfo = () => {
  return {
    type: types.CLEAR_DEVICE_MODEL_INFO
  }
}

const getAppVersions = params => {
  return {
    type: types.GET_APP_VERSIONS,
    payload: params
  }
}

export const getReleaseAppVersions = params => ({
  type: types.GET_RELEASE_APP_VERSIONS,
  payload: params
})

const getAppVersion = id => {
  return {
    type: types.GET_APP_VERSION,
    payload: id
  }
}

const putAppVersion = (id, data, method) => {
  return {
    type: types.PUT_APP_VERSION,
    payload: {
      id,
      data,
      method
    }
  }
}

const postAppVersion = data => {
  return {
    type: types.POST_APP_VERSION,
    payload: data
  }
}

const deleteAppVersion = id => {
  return {
    type: types.DELETE_APP_VERSION,
    payload: id
  }
}

const deleteSelectedAppVersions = ids => ({
  type: types.DELETE_SELECTED_APP_VERSIONS,
  ids
})

const clearAppVersion = () => {
  return {
    type: types.CLEAR_APP_VERSION
  }
}

const getDeviceUpdatesAvailable = () => {
  return {
    type: types.GET_DEVICE_UPDATES_AVAILABLE
  }
}

const postAssignAppVersion = data => {
  return {
    type: types.POST_ASSIGN_APP_VERSION,
    payload: data
  }
}
const postAssignAppVersionToDeviceType = data => {
  return {
    type: types.POST_ASSIGN_APP_VERSION_TO_DEVICE_TYPE,
    payload: data
  }
}
const clearAssignAppVersion = () => {
  return {
    type: types.CLEAR_ASSIGN_APP_VERSION
  }
}

const getDeviceHealth = id => ({
  type: types.GET_DEVICE_HEALTH,
  id
})

const getGroupDevices = params => ({
  type: types.GET_DEVICE_GROUP_DEVICES,
  params
})

const getOfflineNotificationDevices = params => ({
  type: types.GET_DEVICES_OFFLINE_NOTIFICATION,
  params
})

const getOfflineNotificationDeviceItem = id => ({
  type: types.GET_DEVICE_ITEM_OFFLINE_NOTIFICATION,
  id
})

const postOfflineNotificationDevices = data => ({
  type: types.POST_DEVICES_OFFLINE_NOTIFICATION,
  payload: data
})

const clearOfflineNotificationDevices = () => ({
  type: types.CLEAR_DEVICES_OFFLINE_NOTIFICATION
})

const postDeviceBulk = payload => ({
  type: types.POST_DEVICE_BULK,
  payload
})

const clearPostDeviceBulkInfo = () => ({
  type: types.CLEAR_POST_DEVICE_BULK_INFO
})

const getDeviceLogs = (id, logType, params) => ({
  type: types.GET_DEVICE_LOGS,
  payload: {
    id,
    params
  },
  meta: {
    logType
  }
})

const clearDeviceLogs = () => ({
  type: types.CLEAR_DEVICE_LOGS
})

export const cloneDeviceItemAction = (deviceId, data) => ({
  type: types.CLONE_DEVICE_ITEM,
  payload: data,
  deviceId
})

export const clearCloneDeviceInfo = () => ({
  type: types.CLEAR_CLONE_DEVICE_INFO
})

const getDeviceResolution = () => ({
  type: types.GET_DEVICE_RESOLUTION
})

export const getDeviceLocations = params => ({
  type: types.GET_DEVICE_LOCATIONS,
  payload: params
})

export const postDeviceLocation = data => ({
  type: types.POST_DEVICE_LOCATION,
  payload: data
})

export const putDeviceLocation = data => ({
  type: types.PUT_DEVICE_LOCATION,
  payload: data
})

export const deleteDeviceLocation = id => ({
  type: types.DELETE_DEVICE_LOCATION,
  payload: id
})

export const clearDeviceLocationInfo = () => ({
  type: types.CLEAR_DEVICE_LOCATION_INFO
})

export const getDevicesByLocation = (id, params) => ({
  type: types.GET_DEVICES_BY_LOCATION,
  payload: { id, params }
})

export const updateScheduleDevice = (device1, device2) => ({
  type: types.UPDATE_SCHEDULE_DEVICE,
  device1,
  device2
})

export const clearUpdateScheduleDeviceInfo = () => ({
  type: types.CLEAR_UPDATE_SCHEDULE_DEVICE_INFO
})

export const getScheduledDeviceIds = params => ({
  type: types.GET_SCHEDULED_DEVICE_IDS,
  payload: params
})

export const getDeviceCecSettings = id => ({
  type: types.GET_DEVICE_CEC_SETTINGS,
  payload: id
})

export const clearDeviceCecSettings = () => ({
  type: types.CLEAR_DEVICE_CEC_SETTINGS
})

export const putDeviceCecSettings = (id, data) => ({
  type: types.PUT_DEVICE_CEC_SETTINGS,
  payload: { id, data }
})

export const clearDeviceCecSettingsInfo = () => ({
  type: types.CLEAR_DEVICE_CEC_SETTINGS_INFO
})

export const getDeviceNetworkConfig = id => ({
  type: types.GET_DEVICE_NETWORK_CONFIG,
  id
})

export const putDeviceNetworkConfig = (id, data) => ({
  type: types.PUT_DEVICE_NETWORK_CONFIG,
  payload: {
    id,
    data
  }
})

export const getDeviceVirtualPlayerStatsAction = id => ({
  type: types.GET_DEVICE_VIRTUAL_PLAYER_STATS,
  payload: id
})

export const clearDeviceVirtualPlayerStatsAction = () => ({
  type: types.CLEAR_GET_DEVICE_VIRTUAL_PLAYER_STATS
})

export const postIpCIDR = data => ({
  type: types.POST_IP_CIDR,
  payload: data
})

export const putIpCIDR = data => ({
  type: types.PUT_IP_CIDR,
  payload: data
})

export const deleteIpCIDR = data => ({
  type: types.DELETE_IP_CIDR,
  payload: data
})

export const clearIpCIDR = () => ({
  type: types.CLEAR_IP_CIDR
})

export const getIpCIDR = params => ({
  type: types.GET_IP_CIDR,
  payload: params
})

export const getDeviceDependencyPackages = () => ({
  type: types.GET_DEVICE_DEPENDENCY_PACKAGES
})

export const getDeviceGlobalConfig = () => ({
  type: types.GET_DEVICE_GLOBAL_CONFIG
})

export const putDeviceGlobalConfig = data => ({
  type: types.PUT_DEVICE_GLOBAL_CONFIG,
  payload: data
})

export const clearDeviceGlobalConfig = () => ({
  type: types.CLEAR_DEVICE_GLOBAL_CONFIG
})

export const getProofOfPlayDevices = params => ({
  type: types.GET_PROOF_OF_PLAY_DEVICES,
  params
})

export const featureDeviceAssociate = data => ({
  type: types.POST_FEATURE_DEVICE_ASSOCIATE,
  payload: data
})

export const clearProofOfPlayDeviceData = () => ({
  type: types.CLEAR_PROOF_OF_PLAY_DEVICE_DATA
})

export const getDevicePowerSchedule = id => ({
  type: types.GET_DEVICE_POWER_SCHEDULE,
  payload: id
})

export const clearGetDevicePowerScheduleInfo = () => ({
  type: types.CLEAR_GET_DEVICE_POWER_SCHEDULE_INFO
})

export const putDevicePowerSchedule = data => ({
  type: types.PUT_DEVICE_POWER_SCHEDULE,
  payload: data
})

export const clearPutDevicePowerScheduleInfo = () => ({
  type: types.CLEAR_PUT_DEVICE_POWER_SCHEDULE_INFO
})

export {
  resetDeviceFetchParamsAction,
  getDeviceItemsAction,
  clearGetDeviceItemsAction,
  getDeviceGroupsAction,
  clearGetDeviceGroupsInfoAction,
  putDeviceItemAction,
  postDeviceItemAction,
  clearPutDeviceItemInfoAction,
  clearPutDeviceNetworkConfigAction,
  getDeviceItemAction,
  clearGetDeviceItemInfoAction,
  getDevicePreviewAction,
  getDeviceLocationCategories,
  clearDeviceLocationCategoriesInfo,
  clearGetDevicePreviewInfoAction,
  getDeviceGroupItemsAction,
  clearGetDeviceGroupItemsInfoAction,
  postDeviceGroupItemAction,
  clearPostDeviceGroupItemInfoAction,
  deleteDeviceGroupItemAction,
  clearDeleteDeviceGroupItemInfoAction,
  getDeviceReboot,
  clearGetDeviceRebootInfo,
  putDeviceReboot,
  putDevicePeriodicalReboot,
  getScreenPowerSettings,
  putScreenPowerSettings,
  putDeviceBulkReboot,
  putDeviceBulkPeriodicalReboot,
  clearPutDeviceRebootInfo,
  clearPutScreenPowerSettingsInfo,
  getDeviceNotesAction,
  postDeviceNoteAction,
  clearPostDeviceNoteAction,
  clearDeviceGroupItemsInfo,
  closeDevicePreview,
  showDevicePreview,
  getDeviceTypes,
  getDeviceType,
  putDeviceType,
  postDeviceType,
  clearDeviceTypeInfo,
  getDeviceModels,
  getDeviceModel,
  putDeviceModel,
  postDeviceModel,
  clearDeviceModelInfo,
  getAppVersions,
  getAppVersion,
  putAppVersion,
  postAppVersion,
  deleteAppVersion,
  deleteSelectedAppVersions,
  clearAppVersion,
  getDeviceUpdatesAvailable,
  postAssignAppVersion,
  postAssignAppVersionToDeviceType,
  clearAssignAppVersion,
  clearPostDeviceInfo,
  getDeviceHealth,
  getGroupDevices,
  getOfflineNotificationDevices,
  getOfflineNotificationDeviceItem,
  postOfflineNotificationDevices,
  postDeviceBulk,
  clearPostDeviceBulkInfo,
  getDeviceLogs,
  deleteDeviceItemAction,
  clearDeviceItemInfo,
  getDeviceResolution,
  clearOfflineNotificationDevices,
  clearDeviceLogs
}
