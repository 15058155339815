import update from 'immutability-helper'

import * as types from '../actions'
import {
  shapeOfBody,
  shapeOfBodyWithMeta,
  putInitialState
} from 'constants/initialLibraryState'

const initialState = {
  items: shapeOfBodyWithMeta,
  item: shapeOfBody,
  put: putInitialState,
  itemByPage: {
    isFetching: false
  }
}

const helpReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HELPS:
      return update(state, {
        items: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_HELPS_SUCCESS:
      return update(state, {
        items: {
          response: { $set: action.response },
          meta: {
            $set: action.modifiedMeta
          }
        }
      })
    case types.GET_HELPS_ERROR:
      return update(state, {
        items: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.GET_HELP:
      return update(state, {
        itemByPage: {
          isFetching: { $set: true },
          [action.page]: { $set: { ...shapeOfBody, isFetching: true } }
        }
      })
    case types.GET_HELP_SUCCESS:
      return update(state, {
        item: {
          response: { $set: action.payload },
          loading: { $set: false }
        },
        itemByPage: {
          [action.page]: {
            response: { $set: action.payload },
            isFetching: { $set: false }
          },
          isFetching: { $set: false }
        }
      })
    case types.GET_HELP_ERROR:
      return update(state, {
        item: {
          error: { $set: action.payload },
          loading: { $set: false }
        },
        itemByPage: {
          [action.page]: {
            error: { $set: action.payload },
            isFetching: { $set: false }
          },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_GET_HELP_INFO:
      return update(state, {
        item: { $set: shapeOfBody }
      })
    case types.PUT_HELP_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_HELP_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_HELP_INFO:
      return update(state, {
        put: {
          $set: putInitialState
        }
      })
    default:
      return state
  }
}

export default helpReducer
