import moment from 'moment'

import loadImage from 'utils/loadImage'
import snakeCaseToSplitCapitalize from './snakeCaseToSplitCapitalize'

export const validateImage = async (
  file,
  { minHeight, minWidth, ratio },
  t = f => f
) => {
  if (!file) {
    return
  }
  const errors = []
  try {
    const { width, height } = await loadImage(file)
    if (minHeight && minHeight > height) {
      errors.push(
        t('The minimum height of the image must be minHeight px.', {
          minHeight
        })
      )
    }
    if (minWidth && minWidth > width) {
      errors.push(
        t('The minimum width of the image must be minWidth px.', { minWidth })
      )
    }
    if (ratio) {
      const [widthRatio, heightRatio] = ratio.split('/')
      if (parseInt(widthRatio) / parseInt(heightRatio) !== width / height) {
        errors.push(`${t('Allowed image aspect ratio is')} ${ratio}`)
      }
    }
    return errors
  } catch (e) {}
}

export const getFieldNameFromPath = (path = 'field') => {
  const pathParts = path.split('.')
  return snakeCaseToSplitCapitalize(pathParts[pathParts.length - 1] || '')
}

export const baseDateRangeOutsideValidator = (
  day,
  focusedInput,
  startDate,
  endDate,
  minimumNights
) =>
  (focusedInput === 'endDate' && startDate ? startDate : moment()).diff(day) >
  minimumNights

export const sanitizeString = string => {
  if (!string || typeof string !== 'string') {
    return string
  }

  return string.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
}
