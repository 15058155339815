import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from '@reduxjs/toolkit'
import { withStyles } from '@material-ui/core'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Share } from '@material-ui/icons'
import { _get } from 'utils/lodash'

import { CircleIconButton } from 'components/Buttons'
import Tooltip from 'components/Tooltip'
import DefaultModal from 'components/Modal/DefaultModal'
import {
  clearPublicTokenResponseInfo,
  deletePublicToken,
  getPublicToken,
  postPublicToken
} from 'actions/public/auth'
import { BIG_NUMBER } from 'constants/library'
import useSnackbar from 'hooks/useSnackbar'
import Spacing from 'components/Containers/Spacing'
import { APP_BASE_URL } from 'config'
import FormControlCopyInput from 'components/Form/FormControlCopyInput'
import useFeaturesId from 'hooks/api/useFeaturesId'
import { featureNames } from 'constants/featureConstants'
import { CircularLoader } from 'components/Loaders'
import usePermissions from 'hooks/api/usePermissions'
import { ORG_DEVICE_NOC } from 'constants/permissionNames'

const styles = () => {
  return {
    circleButton: {
      color: '#afb7c7',
      '&:hover, &.active': {
        color: '#1c5dca'
      }
    },
    loaderRoot: {
      minHeight: 100
    }
  }
}

const ShareNoc = ({ t, classes }) => {
  const [openModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()
  const { items, post, del } = useSelector(({ public: { auth } }) => auth)

  const { getPermissionByName } = usePermissions()
  const { showSnackbar } = useSnackbar()
  const [deviceNocId] = useFeaturesId(featureNames.DeviceNoc)

  const toggleModal = useCallback(
    (open = !openModal) => () => {
      setOpenModal(open)
    },
    [openModal]
  )

  const fetcher = useCallback(() => {
    dispatch(
      getPublicToken({
        page: 1,
        limit: BIG_NUMBER
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (
      _get(post, 'response.status', _get(del, 'response.status')) === 'success'
    ) {
      fetcher()
      dispatch(clearPublicTokenResponseInfo())
      if (_get(del, 'response.status') === 'success') {
        toggleModal(false)()
        showSnackbar(t('Public Link has been deleted'), 'success')
      } else {
        showSnackbar(t('Public Link has been generated'), 'success')
      }
    } else if (_get(post, 'error.message', _get(del, 'error.message'))) {
      showSnackbar(
        _get(post, 'error.message', _get(del, 'error.message')),
        'error'
      )
      dispatch(clearPublicTokenResponseInfo())
    }
    // eslint-disable-next-line
  }, [post, del])

  const handleShareClick = useCallback(() => {
    fetcher()
    toggleModal(true)()
  }, [fetcher, toggleModal])

  const generatePublicLink = useCallback(() => {
    dispatch(
      postPublicToken({
        name: 'DeviceNOC',
        type: 'token',
        featureId: deviceNocId
      })
    )
  }, [dispatch, deviceNocId])

  const sharedRow = useMemo(() => {
    if (
      items.response &&
      items.response.length &&
      !items.isFetching &&
      deviceNocId
    ) {
      const deviceNoc = items.response.find(
        ({ feature }) => feature.id === deviceNocId
      )
      return deviceNoc
    }

    return null
  }, [items, deviceNocId])

  const handleRestrictClick = useCallback(() => {
    if (sharedRow?.id) {
      dispatch(deletePublicToken(sharedRow.id))
    }
  }, [dispatch, sharedRow])

  return getPermissionByName(ORG_DEVICE_NOC) ? (
    <>
      <Tooltip arrow title={t('Share NOC tooltip title')}>
        <CircleIconButton
          className={`hvr-grow ${classes.circleButton}`}
          onClick={handleShareClick}
        >
          <Share />
        </CircleIconButton>
      </Tooltip>
      {openModal && (
        <DefaultModal
          modalTitle={t('Share NOC View')}
          onCloseModal={toggleModal(false)}
          open={openModal}
          maxWidth="xs"
          buttonPrimaryText={
            sharedRow ? t('Deactivate Link') : t('Generate Link')
          }
          buttonPrimaryIcon="icon-expand-4"
          onClickSave={sharedRow ? handleRestrictClick : generatePublicLink}
        >
          {items.isFetching ? (
            <div className={classes.loaderRoot}>
              <CircularLoader />
            </div>
          ) : (
            <Spacing>
              <FormControlCopyInput
                label={t('Public Link')}
                value={
                  sharedRow
                    ? `${APP_BASE_URL}public/${sharedRow.token}/device-noc`
                    : ''
                }
                inputTooltip={
                  !sharedRow &&
                  t('Click on Generate Link Button to Generate Public Link')
                }
                disabledCopy={!sharedRow}
                marginBottom={false}
              />
            </Spacing>
          )}
        </DefaultModal>
      )}
    </>
  ) : null
}

ShareNoc.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

const mapStateToProps = ({ alert }) => ({
  deviceActiveEmergencyAlert: alert.deviceActiveEmergencyAlert
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default withTranslation('translations')(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ShareNoc))
)
