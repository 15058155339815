import update from 'immutability-helper'

import * as types from 'actions'
import { shapeOfBody } from 'constants/initialLibraryState'

const initialState = {
  token: {
    ...shapeOfBody,
    isLoading: false
  }
}

const impersonateReducer = (state = initialState, action) => {
  const response = action.response
  const error = action.error

  switch (action.type) {
    case types.IMPERSONATE_USER_SUCCESS:
      return { ...state, response }
    case types.IMPERSONATE_USER_ERROR:
      return { ...state, error }
    case types.GET_IMPERSONATE_TOKEN:
      return update(state, {
        token: {
          isLoading: { $set: true },
          error: { $set: initialState.token.error }
        }
      })
    case types.GET_IMPERSONATE_TOKEN_SUCCESS:
      return update(state, {
        token: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_IMPERSONATE_TOKEN_ERROR:
      return update(state, {
        token: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_IMPERSONATE_TOKEN:
      return update(state, {
        token: { $set: initialState.token }
      })
    default:
      return state
  }
}

export default impersonateReducer
