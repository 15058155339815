import api from './api'
import { errorHandler } from '../utils'

const getHelps = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/contentAppHelp',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getHelp = async featureId => {
  try {
    const response = await api({
      method: 'GET',
      url: `/contentAppHelp/${featureId}`
    })

    return { ...response.data, featureId: featureId }
  } catch (error) {
    throw errorHandler({ ...error, featureId })
  }
}

const putHelp = async (featureId, data) => {
  try {
    await api({
      method: 'PUT',
      url: `/contentAppHelp/${featureId}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const contentAppHelpService = {
  getHelps,
  getHelp,
  putHelp
}

export default contentAppHelpService
