import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { getCRUDEndpoints } from 'utils/apiUtils'

export const supportTokenApi = createAppApi('supportTokenQuery', {
  tagTypes: [apiTags.supportToken],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      name: 'SupportTokenAllowedIp',
      url: '/permittedIp',
      builder,
      tags: [apiTags.clientUsers]
    })
  })
})

export const {
  useGetSupportTokenAllowedIpsQuery,
  usePostSupportTokenAllowedIpMutation,
  usePutSupportTokenAllowedIpMutation,
  useDeleteSupportTokenAllowedIpMutation
} = supportTokenApi
