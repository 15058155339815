import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

import Text from 'components/Typography/Text'
import Spacing from 'components/Containers/Spacing'
import { TextButton } from 'components/Buttons'
import Container from 'components/Containers/Container'
import FormControlInput from 'components/Form/FormControlInput'
import SubmitButton from 'components/Account/SignIn/SubmitButton'
import useRefCallback from 'hooks/useRefCallback'
import { requiredField } from 'constants/validationMessages'
import 'utils/yup/numbers'
import { SYSTEM_ROLE } from '../../../constants/api'

function styles({ fontSize }) {
  return {
    input: {
      height: 50,
      fontSize: fontSize.big
    }
  }
}

const orgFormConfig = {
  initialValues: {
    code: ''
  },
  validationSchema: Yup.object().shape({
    code: Yup.string().required(requiredField).numbers().min(4).max(4)
  })
}

const systemUserFormConfig = {
  initialValues: {
    code: ''
  },
  validationSchema: Yup.object().shape({
    code: Yup.string().required(requiredField).numbers().min(6).max(6)
  })
}
function TwoFactorAuth({
  t,
  classes,
  onCancel,
  onCodeInputRef,
  isTwoFactorLoading,
  onValidateCode,
  role
}) {
  const [, getInputRef] = useRefCallback(onCodeInputRef)

  const onSubmit = useCallback(
    ({ code }) => {
      onValidateCode(code, role)
    },
    [onValidateCode, role]
  )

  const formConfig = useMemo(() => {
    return role === SYSTEM_ROLE ? systemUserFormConfig : orgFormConfig
  }, [role])

  const {
    isValid,
    values,
    errors,
    touched,
    validateForm,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    ...formConfig,
    onSubmit
  })

  useEffect(
    () => {
      validateForm()
    },
    //eslint-disable-next-line
    []
  )

  const handleKeyDown = async event => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Spacing variant={6}>
      <Spacing variant={6}>
        <Text whiteSpace="pre-line" variant="big">
          {t('login_two_factor_text')}
        </Text>
        <Spacing variant={0} paddingTop={2}>
          <FormControlInput
            fullWidth
            name="code"
            value={values.code}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.code}
            touched={touched.code}
            placeholder={t('placeholder_email_code')}
            formControlInputClass={classes.input}
            inputRef={getInputRef}
            onKeyPress={handleKeyDown}
          />
        </Spacing>
      </Spacing>
      <Container>
        <SubmitButton
          opaque={!isValid}
          isLoading={isTwoFactorLoading}
          onClick={handleSubmit}
        >
          {t('button_send_code')}
        </SubmitButton>
        <Grid container alignItems="center">
          <TextButton onClick={onCancel}>
            <Text variant="big">{t('button_cancel')}</Text>
          </TextButton>
        </Grid>
      </Container>
    </Spacing>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(TwoFactorAuth)
)
