import { _isArray } from 'utils/lodash'
import { getLightFromBase } from 'utils/chipsColorPalette'
import {
  AUTO_TAG_BACKGROUND_COLOR,
  AUTO_TAG_COLOR,
  defaultTag
} from 'constants/chipsColorPalette'
import { swapTagData } from 'utils/tagUtils'

export const nameToChipObj = obj => ({ label: obj || '', value: obj })
export const toChipObj = obj => ({ label: obj.title || '', value: obj.id })

export const tagToChipObj = tagData => {
  const { tag, title, id, textColor, backgroundColor } = swapTagData(tagData)

  return {
    label: tag || title || '',
    value: id,
    color: textColor || defaultTag.textColor,
    background: backgroundColor || defaultTag.backgroundColor,
    border: backgroundColor || defaultTag.backgroundColor,
    meta: {
      icon: 'fa-solid fa-tag',
      color: backgroundColor || defaultTag.backgroundColor
    }
  }
}
export const posterTagToChipObj = tag => {
  const { title, id, textColor, backgroundColor } = swapTagData(tag)

  return {
    label: title || '',
    value: id,
    color: textColor,
    background: backgroundColor,
    border: textColor
  }
}
export const macToChipObj = obj => ({
  label: obj.macAddress || '',
  value: obj.macAddress,
  temporary: obj.temporary
})
export const toGroupChipObj = ({ id, title, color }) => ({
  label: title || '',
  value: id,
  color: color,
  background: getLightFromBase(color),
  meta: {
    icon: 'fa-solid fa-object-union',
    color
  }
})

export const fromChipObj = obj => ({ title: obj.label, id: obj.value })
export const tagFromChipObj = obj => ({ tag: obj.label, id: obj.value })
export const macFromChipObj = obj => ({
  macAddress: obj.label,
  temporary: obj.temporary
})

export const convertInput = input => (_isArray(input) ? input : [input])

export const convertArr = (arr, converter) =>
  arr ? convertInput(arr).map(converter) : []

export const optionsFormatOnlyWithId = (value, id) => ({
  value: value,
  label: `${id}`
})

export const autoTagToChipObj = autoTag => {
  const { label } = autoTag

  return {
    label: label || '',
    value: label,
    color: AUTO_TAG_COLOR,
    background: AUTO_TAG_BACKGROUND_COLOR,
    border: AUTO_TAG_BACKGROUND_COLOR
  }
}
