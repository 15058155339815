import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'

import { truncateWithEllipsis } from 'utils/truncateStringUtils'
import Tooltip from 'components/Tooltip'
import { truncateLength } from 'components/Card/consts'

const styles = ({ palette, type, typography }) => ({
  detailRow: {
    minHeight: '36px',
    padding: '5px 0px'
  },
  corner: {
    borderBottom: `1px solid ${palette[type].deviceCard.row.background}`
  },
  detailLabel: {
    ...typography.lightText[type]
  },
  detailValue: {
    ...typography.darkAccent[type],
    fontWeight: 700,
    textAlign: 'right'
  }
})

const PlaylistInfoRow = ({
  classes,
  title,
  children,
  hasCorner = true,
  customValueType = false,
  isTruncate = false
}) => (
  <Grid
    className={classNames(classes.detailRow, { [classes.corner]: hasCorner })}
    container
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid item>
      <Typography className={classes.detailLabel}>{title}</Typography>
    </Grid>
    {customValueType ? (
      children
    ) : (
      <Grid item>
        {isTruncate ? (
          <Tooltip arrow title={children}>
            <Typography className={classes.detailValue}>
              {truncateWithEllipsis(children, truncateLength)}
            </Typography>
          </Tooltip>
        ) : (
          <Typography className={classes.detailValue}>{children}</Typography>
        )}
      </Grid>
    )}
  </Grid>
)

PlaylistInfoRow.propTypes = {
  title: PropTypes.string.isRequired,
  customValueType: PropTypes.bool,
  isTruncate: PropTypes.bool
}

export default withStyles(styles)(PlaylistInfoRow)
