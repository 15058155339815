import update from 'immutability-helper'

import * as types from '../actions'

import {
  shapeOfBodyWithMeta,
  initialEntityState,
  shapeOfBody,
  deleteInitialState,
  postInitialState,
  putInitialState
} from '../constants/initialLibraryState'

const initialState = {
  general: {
    ...shapeOfBodyWithMeta
  },
  health: {
    ...shapeOfBodyWithMeta
  },
  network: {
    ...shapeOfBodyWithMeta
  },
  alarm: {
    ...initialEntityState,
    columns: {
      ...shapeOfBody,
      isFetching: false
    }
  }
}

const deviceNocReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEVICE_NOC_GENERAL_ITEMS:
      return update(state, {
        general: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_DEVICE_NOC_GENERAL_ITEMS_SUCCESS:
      return update(state, {
        general: {
          response: { $set: action.response },
          isFetching: { $set: false },
          meta: { $set: action.modifiedMeta }
        }
      })
    case types.GET_DEVICE_NOC_GENERAL_ITEMS_ERROR:
      return update(state, {
        general: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_DEVICE_NOC_HEALTH_ITEMS:
      return update(state, {
        health: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_DEVICE_NOC_HEALTH_ITEMS_SUCCESS:
      return update(state, {
        health: {
          response: { $set: action.response },
          isFetching: { $set: false },
          meta: { $set: action.modifiedMeta }
        }
      })
    case types.GET_DEVICE_NOC_HEALTH_ITEMS_ERROR:
      return update(state, {
        health: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_DEVICE_NOC_NETWORK_ITEMS:
      return update(state, {
        network: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_DEVICE_NOC_NETWORK_ITEMS_SUCCESS:
      return update(state, {
        network: {
          response: { $set: action.response },
          meta: { $set: action.modifiedMeta },
          isFetching: { $set: false }
        }
      })
    case types.GET_DEVICE_NOC_NETWORK_ITEMS_ERROR:
      return update(state, {
        network: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_DEVICE_ALARM_ITEMS:
      return update(state, {
        alarm: {
          items: {
            meta: {
              isLoading: { $set: true }
            }
          }
        }
      })
    case types.GET_DEVICE_ALARM_ITEMS_SUCCESS:
      return update(state, {
        alarm: {
          items: {
            response: { $set: action.response },
            meta: {
              $set: action.modifiedMeta
            }
          }
        }
      })
    case types.GET_DEVICE_ALARM_ITEMS_ERROR:
      return update(state, {
        alarm: {
          items: {
            error: { $set: action.payload },
            meta: {
              isLoading: { $set: false }
            }
          }
        }
      })
    case types.GET_DEVICE_ALARM_ITEM_BY_ID_SUCCESS:
      return update(state, {
        alarm: {
          item: {
            response: { $set: action.payload }
          }
        }
      })
    case types.GET_DEVICE_ALARM_ITEM_BY_ID_ERROR:
      return update(state, {
        alarm: {
          item: {
            error: { $set: action.payload }
          }
        }
      })
    case types.POST_DEVICE_ALARM_SUCCESS:
      return update(state, {
        alarm: {
          post: {
            response: { $set: action.payload }
          }
        }
      })
    case types.POST_DEVICE_ALARM_ERROR:
      return update(state, {
        alarm: {
          post: {
            error: { $set: action.payload }
          }
        }
      })
    case types.PUT_DEVICE_ALARM_SUCCESS:
      return update(state, {
        alarm: {
          put: {
            response: { $set: action.payload }
          }
        }
      })
    case types.PUT_DEVICE_ALARM_ERROR:
      return update(state, {
        alarm: {
          put: {
            error: { $set: action.payload }
          }
        }
      })
    case types.DELETE_DEVICE_ALARM_SUCCESS:
      return update(state, {
        alarm: {
          del: {
            response: { $set: action.payload }
          }
        }
      })
    case types.DELETE_DEVICE_ALARM_ERROR:
      return update(state, {
        alarm: {
          del: {
            error: { $set: action.payload }
          }
        }
      })
    case types.GET_DEVICE_ALARM_COLUMN_LIST:
      return update(state, {
        alarm: {
          columns: {
            response: { $set: [] },
            isFetching: { $set: true }
          }
        }
      })
    case types.GET_DEVICE_ALARM_COLUMN_LIST_SUCCESS:
      return update(state, {
        alarm: {
          columns: {
            response: { $set: action.response },
            isFetching: { $set: false }
          }
        }
      })
    case types.GET_DEVICE_ALARM_COLUMN_LIST_ERROR:
      return update(state, {
        alarm: {
          columns: {
            error: { $set: action.payload },
            isFetching: { $set: false }
          }
        }
      })
    case types.CLEAR_DEVICE_ALARM_RESPONSE_INFO:
      const $spec = {
        put: {
          $set: {
            ...putInitialState
          }
        },
        post: {
          $set: {
            ...postInitialState
          }
        },
        del: {
          $set: {
            ...deleteInitialState
          }
        },
        item: {
          $set: {
            ...shapeOfBody,
            response: {}
          }
        }
      }
      return update(state, {
        alarm: $spec
      })
    default:
      return state
  }
}

export default deviceNocReducer
