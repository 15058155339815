import { createAppApi } from 'services/api'

export const oauth2Api = createAppApi('oauth2Query', {
  endpoints: builder => ({
    associateMedia: builder.mutation({
      query: ({ tokenId, mediaIds }) => ({
        url: `/oauth2/${tokenId}/associateMedia`,
        method: 'POST',
        data: {
          mediaIds
        }
      })
    })
  })
})

export const { useAssociateMediaMutation } = oauth2Api
