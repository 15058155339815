import { TouchApp, Subscriptions } from '@material-ui/icons'

export const playlistTypes = {
  Folder: {
    color: 'rgb(48, 63, 159)',
    iconHelperClass: 'fas fa-folder-download',
    title: 'Magic Folders Playlist',
    name: 'Folder',
    fontSize: 16
  },
  Standard: {
    color: 'rgb(74, 130, 238)',
    icon: Subscriptions,
    title: 'Standard Playlist',
    name: 'Standard'
  },
  Smart: {
    color: 'rgb(80, 227, 194)',
    iconHelperClass: 'fa-light fa-lightbulb-on',
    title: 'Smart Playlist',
    name: 'Smart'
  },
  Interactive: {
    color: 'rgb(255, 61, 132)',
    icon: TouchApp,
    title: 'Interactive Playlist',
    name: 'Interactive'
  }
}

export const MAX_ITEMS_PER_PLAYLIST = 250

export const ADD_MEDIA_TAB_NAME = 'Add New Media'

export const playlistStatus = {
  expired: 'expired',
  expires: 'expires',
  activates: 'activates',
  notApproved: 'notApproved'
}

export const unprocessablePlaylistStatuses = [
  playlistStatus.expired,
  playlistStatus.notApproved
]

export const expiresPlaylistStatuses = [
  playlistStatus.expires,
  playlistStatus.expired
]

export const searchStatusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' }
]

export const mediaOrders = [
  { value: 'random', label: 'Random' },
  { value: 'nto', label: 'Newest to Oldest' },
  { value: 'otn', label: 'Oldest to Newest' },
  { value: 'atz', label: 'Alphabetical A-Z' },
  { value: 'zta', label: 'Alphabetical Z-A' }
]
