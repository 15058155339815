import React from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { EXPRESS_SUPPORT, SIGNATURE_CARE } from 'constants/clientsConstants'
import LibraryTypeIcon from 'components/LibraryTypeIcon'

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'unset',
    fontSize: 17
  }
}))

const ClientIcon = ({ serviceLevel }) => {
  const classes = useStyles()

  switch (serviceLevel) {
    case SIGNATURE_CARE:
      return (
        <LibraryTypeIcon
          iconHelperClass={classNames(
            'fa-sharp fa-solid fa-ranking-star',
            classes.root
          )}
        />
      )
    case EXPRESS_SUPPORT:
      return (
        <LibraryTypeIcon
          iconHelperClass={classNames(
            'fa-sharp fa-regular fa-circle-star',
            classes.root
          )}
        />
      )
    default:
      return (
        <LibraryTypeIcon
          iconHelperClass={classNames(
            'fa-sharp fa-solid fa-messages-question',
            classes.root
          )}
        />
      )
  }
}

export default ClientIcon
