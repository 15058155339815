import React from 'react'
import { Grid } from '@material-ui/core'

import NoteIndicator from 'components/NoteIndicator'
import LegacyIndicator from '../LegacyIndicator'
import { isLegacyBrowserVersion } from 'utils/deviceUtils'
import { TextWithTooltip } from 'components/Typography'
import { defaultColumnWidth } from 'constants/libraryConstants/deviceLibraryConstants'

const DeviceNameViewCell = ({
  row,
  role = {},
  classes,
  notes,
  columnWidth
}) => {
  const isLegacy = isLegacyBrowserVersion(row.browserVersion)
  const fieldWidth = columnWidth || defaultColumnWidth.alias
  return role.org ? (
    <>
      <div className={classes.aliasWrap}>
        <Grid
          container
          className={classes.textWrap}
          style={{ width: fieldWidth }}
        >
          <TextWithTooltip
            maxWidth={fieldWidth}
            color="title.primary"
            weight="bold"
            rootClassName={classes.titleText}
          >
            {row.alias}
          </TextWithTooltip>

          <LegacyIndicator isLegacy={isLegacy} />
        </Grid>
      </div>
      <Grid container style={{ width: fieldWidth }}>
        <TextWithTooltip
          maxWidth={fieldWidth}
          color="light"
          rootClassName={classes.label}
        >
          {row.name}
        </TextWithTooltip>
      </Grid>
    </>
  ) : (
    <>
      <div className={classes.deviceNameWrap}>
        <Grid
          container
          className={classes.textWrap}
          style={{ width: fieldWidth }}
        >
          <TextWithTooltip
            maxWidth={fieldWidth}
            color="title.primary"
            weight="bold"
            rootClassName={classes.titleText}
          >
            {row.name}
          </TextWithTooltip>
          {role.system && notes > 0 && <NoteIndicator notes={notes} />}{' '}
        </Grid>
      </div>
      <Grid container style={{ width: fieldWidth }}>
        <TextWithTooltip
          maxWidth={fieldWidth}
          color="light"
          rootClassName={classes.label}
        >
          {row.alias}
        </TextWithTooltip>
      </Grid>
    </>
  )
}

export default DeviceNameViewCell
