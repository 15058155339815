import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import _get from 'lodash/get'

import { useUserRole } from 'hooks/tableLibrary'
import useMediaFeaturesList from 'hooks/api/useMediaFeaturesList'
import { userDetailsSelector } from 'selectors/userSelectors'
import { featureNames, fileSubFeatures } from 'constants/featureConstants'
import { hiddenFeatures } from 'constants/media'

const commonExclude = [featureNames.File, ...hiddenFeatures]

const systemExclude = [
  featureNames.AlertSystem,
  featureNames.CapAlert,
  featureNames.IPAWS
]

const filterByClientFeatures = (features, clientFeatures) => {
  return features.filter(feature => {
    if (fileSubFeatures.includes(feature.name)) {
      return clientFeatures.some(({ name }) => name === featureNames.File)
    }
    return clientFeatures.some(({ name }) => name === feature.name)
  })
}

export default function useMediaFeatureOptions(
  exclude = [],
  additionalOptions = [],
  transformData
) {
  const { response: userResponse } = useSelector(userDetailsSelector)

  const role = useUserRole()
  const { features, isFetching } = useMediaFeaturesList()

  const excludeFeatures = useMemo(
    () => [...commonExclude, ...(role.system ? systemExclude : []), ...exclude],
    [exclude, role]
  )

  const transform = useCallback(
    feature => {
      const { id, alias, icon, color } = feature
      if (transformData) return transformData(feature)

      return { value: id, label: alias, meta: { icon, color } }
    },
    [transformData]
  )

  const featureOptions = useMemo(() => {
    const activeFeatures = role.system
      ? features
      : filterByClientFeatures(
          features,
          _get(userResponse, 'client.feature', [])
        )

    return [
      ...activeFeatures
        .filter(feature => !excludeFeatures.includes(feature.name))
        .map(transform),
      ...additionalOptions
    ]
  }, [
    features,
    excludeFeatures,
    role,
    userResponse,
    additionalOptions,
    transform
  ])

  return useMemo(
    () => ({
      featureOptions,
      isFetching
    }),
    [featureOptions, isFetching]
  )
}
