import React from 'react'
import { InputLabel, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'
import Error from './Error'

const useStyles = makeStyles(
  ({ palette, type, fontSize, lineHeight, fontWeight, colors, spacing }) => ({
    root: {
      position: 'relative',
      width: '100%'
    },
    rightLabel: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center'
    },
    topLabel: {
      display: 'flex',
      flexDirection: 'column'
    },
    leftLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    bottomLabel: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    bootstrapFormLabel: {
      fontSize: fontSize.primary,
      lineHeight: lineHeight.primary,
      fontWeight: fontWeight.normal,
      color: palette[type].formControls.label.color,
      whiteSpace: 'pre',
      transform: 'none'
    },
    alignLabel: {
      alignSelf: 'flex-start'
    },
    topLabelMargin: {
      marginBottom: 0
    },
    bottomLabelMargin: {
      marginTop: '7px'
    },
    label: {
      position: 'unset !important'
    },
    labelSmall: {
      fontSize: `${fontSize.small}px !important`
    },
    labelSmallest: {
      fontSize: `${fontSize.smallest}px !important`
    },
    bootstrapFormLabelFocus: {
      color: `${palette[type].formControls.label.color} !important`
    },
    labelLink: {
      display: 'unset',
      position: 'unset !important',
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
      textDecorationColor: colors.highlight,
      textUnderlineOffset: '2px',
      '&:hover': {
        cursor: 'pointer',
        textDecorationStyle: 'solid'
      }
    },
    marginBottom: {
      marginBottom: spacing(2)
    }
  })
)

const BaseFormControl = ({
  t,
  error,
  touched,
  label,
  marginBottom = false,
  labelPosition = 'top',
  labelFontSizeVariant,
  tooltip,
  formControlContainerClass,
  formControlLabelClass,
  isOptional = false,
  errorTextClass,
  absoluteErrorText = true,
  children
}) => {
  const classes = useStyles()

  return (
    <div
      onMouseDown={e => e.stopPropagation()}
      className={classNames(classes.root, formControlContainerClass, {
        [classes.leftLabel]: labelPosition === 'left',
        [classes.topLabel]: labelPosition === 'top',
        [classes.bottomLabel]: labelPosition === 'bottom',
        [classes.rightLabel]: labelPosition === 'right',
        [classes.marginBottom]: marginBottom
      })}
    >
      {label && (
        <Tooltip
          arrow
          title={tooltip}
          disableHoverListener={!tooltip}
          placement="top"
        >
          <InputLabel
            shrink
            className={classNames(
              classes.bootstrapFormLabel,
              formControlLabelClass,
              {
                [classes.alignLabel]:
                  labelPosition === 'top' || labelPosition === 'bottom',
                [classes.topLabelMargin]: labelPosition === 'top',
                [classes.bottomLabelMargin]: labelPosition === 'bottom',
                [classes.labelSmall]: labelFontSizeVariant === 'small',
                [classes.labelSmallest]: labelFontSizeVariant === 'smallest'
              }
            )}
            classes={{
              focused: classes.bootstrapFormLabelFocus,
              root: tooltip ? classes.labelLink : classes.label
            }}
          >
            {label} {isOptional && <i>({t('optional')})</i>}
          </InputLabel>
        </Tooltip>
      )}
      {children}
      <Error
        isOptional={isOptional}
        absolute={absoluteErrorText}
        condition={touched && !!error}
        error={error}
        rootClassName={errorTextClass}
      />
    </div>
  )
}

export default withTranslation('translations')(BaseFormControl)
