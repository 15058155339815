import api from './api'

import { errorHandler } from '../utils'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import { SYSTEM_ROLE } from 'constants/api'

const getItems = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/client',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAssociatedClients = async resellerId => {
  try {
    const role = getUserRoleLevel()
    const { data } = await api({
      method: 'GET',
      url: `/${
        role === SYSTEM_ROLE ? 'resellerClient' : 'client'
      }/${resellerId}/associatedClient?limit=0`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postAssociatedClients = async (resellerId, clientId) => {
  try {
    const role = getUserRoleLevel()
    const response = await api({
      method: 'POST',
      url: `/${
        role === SYSTEM_ROLE ? 'resellerClient' : 'client'
      }/${resellerId}/associatedClient`,
      data: {
        clientId
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putAssociatedClients = async (resellerId, clientId) => {
  try {
    const role = getUserRoleLevel()
    const response = await api({
      method: 'PUT',
      url: `/${
        role === SYSTEM_ROLE ? 'resellerClient' : 'client'
      }/${resellerId}/associatedClient`,
      data: {
        clientId
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const resellerClientsService = {
  getItems,
  getAssociatedClients,
  postAssociatedClients,
  putAssociatedClients
}

export default resellerClientsService
