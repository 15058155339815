import React, { memo, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { WhiteButton } from 'components/Buttons'
import { SelectMediaImageDialog } from 'components/Modal'
import { simulateEvent } from 'utils/formik'
import Text from 'components/Typography/Text'
import Error from './Error'
import FormControlLabel from './FormControlLabel'

const useStyles = makeStyles({
  wrap: {
    position: 'relative',
    width: '100%'
  },
  imageWrap: {
    height: 100,
    width: '100%',
    cursor: 'pointer',
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }
  },
  noPreview: {
    height: '100%',
    cursor: 'pointer',
    backgroundColor: 'black'
  }
})

const FormControlImage = ({
  buttonText = 'Select image',
  handleChange,
  name,
  isPreview = false,
  imageUrl = null,
  prepareSelectedData = v => v,
  error = null,
  touched = null,
  labelFontSizeVariant = 'primary',
  errorTextClass = null,
  label = null,
  setImageUrl
}) => {
  const classes = useStyles()
  const { t } = useTranslation('translations')
  const [selectedImage, setSelectedImage] = useState(imageUrl)
  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = useCallback(() => {
    setOpenModal(true)
  }, [setOpenModal])

  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
  }, [setOpenModal])

  const onChange = useCallback(
    e => {
      const url = e?.mediaUrl || e?.thumbnail
      setSelectedImage(url)
      setImageUrl && setImageUrl(url)
      handleChange(simulateEvent(name, prepareSelectedData(e)))
      handleCloseModal()
    },
    [handleChange, name, prepareSelectedData, handleCloseModal, setImageUrl]
  )

  const activeImageUrl = useMemo(() => imageUrl || selectedImage, [
    selectedImage,
    imageUrl
  ])

  const imagePreview = useMemo(() => {
    if (!isPreview) return null
    if (activeImageUrl) {
      return (
        <img src={activeImageUrl} alt="background" onClick={handleOpenModal} />
      )
    }
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.noPreview}
        onClick={handleOpenModal}
      >
        <Text weight="bold">{t('No preview available')}</Text>
      </Grid>
    )
  }, [isPreview, activeImageUrl, classes.noPreview, t, handleOpenModal])

  return (
    <Grid container direction="column" className={classes.wrap}>
      {label && (
        <FormControlLabel
          label={label}
          error={touched && error}
          fontSizeVariant={labelFontSizeVariant}
        />
      )}
      <div>
        {imagePreview ? (
          <div className={classes.imageWrap}>{imagePreview}</div>
        ) : (
          <WhiteButton
            onClick={handleOpenModal}
            iconClassName="fa-regular fa-folder-image"
          >
            {buttonText}
          </WhiteButton>
        )}
      </div>

      <Error
        absolute
        condition={!!(error && touched)}
        error={error}
        rootClassName={errorTextClass}
      />

      <SelectMediaImageDialog
        open={openModal}
        onSelect={onChange}
        onCloseModal={handleCloseModal}
      />
    </Grid>
  )
}

FormControlImage.propTypes = {
  buttonText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  prepareSelectedData: PropTypes.func,
  imageUrl: PropTypes.string,
  isPreview: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  errorTextClass: PropTypes.string,
  label: PropTypes.string
}

export default memo(FormControlImage)
