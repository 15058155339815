import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { InfoTwoTone } from '@material-ui/icons'
// UTILS
import { _isEmpty } from 'utils/lodash'
import { PaddingHor, PaddingVert } from 'utils/styles'
// COMPONENTS
import Popup from 'components/Popup'
import Spacing from 'components/Containers/Spacing'
import Tooltip from 'components/Tooltip/index.js'

const styles = ({ fontSize, colors }) => {
  function _fontSize(size) {
    return { fontSize: fontSize[size] }
  }

  return {
    icon: {
      color: colors.light
    },
    'size-normal': _fontSize('secondary'),
    'size-small': _fontSize('small'),
    'size-big': _fontSize('big'),
    iconWrapper: {
      display: 'flex',
      alignItems: 'center'
    }
  }
}

const InfoIcon = ({
  text,
  size = 'normal',
  paddingHor = 1,
  paddingVert = 0,
  popupPosition = 'right center',
  className,
  iconWrapperClass,
  contentStyle = {},
  classes,
  typographyComponent = 'p',
  type = 'tooltip',
  statusInfo = {}
}) => {
  return (
    <Grid className={className}>
      <Spacing
        variant={0}
        paddingHor={paddingHor}
        paddingVert={paddingVert}
        justifyContent="center"
      >
        {type === 'popup' && (
          <Popup
            on="hover"
            trigger={
              <div
                className={classNames(classes.iconWrapper, iconWrapperClass)}
              >
                <InfoTwoTone
                  className={classNames(classes.icon, classes[`size-${size}`])}
                />
              </div>
            }
            position={popupPosition}
            {...(!_isEmpty(contentStyle) && {
              contentStyle: {
                ...contentStyle
              }
            })}
          >
            <Spacing variant={0} paddingVert={2} paddingHor={2}>
              <Typography component={typographyComponent}>
                {statusInfo?.text ?? text}
              </Typography>
            </Spacing>
          </Popup>
        )}

        {type === 'tooltip' && (
          <Tooltip
            title={
              <Typography component={typographyComponent}>
                {statusInfo?.content ?? text}
              </Typography>
            }
            headerText={statusInfo?.label}
            arrowWithHeaderColor={statusInfo?.label}
            placement="bottom"
            withHeader={statusInfo?.label}
            arrow
          >
            <div className={classNames(classes.iconWrapper, iconWrapperClass)}>
              <InfoTwoTone
                className={classNames(classes.icon, classes[`size-${size}`])}
              />
            </div>
          </Tooltip>
        )}
      </Spacing>
    </Grid>
  )
}

InfoIcon.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['normal', 'small', 'big']),
  type: PropTypes.oneOf(['tooltip', 'popup']),
  paddingVert: PaddingVert,
  paddingHor: PaddingHor,
  popupPosition: PropTypes.string,
  className: PropTypes.string,
  iconWrapperClass: PropTypes.string,
  contentStyle: PropTypes.object,
  statusInfo: PropTypes.shape({
    status: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  })
}

export default withStyles(styles)(InfoIcon)
