import update from 'immutability-helper'

import * as types from '../actions'

const initialState = {
  isPending: false,
  groupModalHeight: undefined,
  isConfirmationRequired: false,
  dndLastDropId: '',
  isNavigationDisabled: false,
  openedTooltips: []
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PENDING_STATUS:
      return update(state, {
        isPending: { $set: action.payload }
      })
    case types.SET_GROUP_MODAL_HEIGHT:
      return update(state, {
        groupModalHeight: { $set: action.payload }
      })
    case types.SET_CONFIRMATION_REQUIRED:
      return update(state, {
        isConfirmationRequired: { $set: action.payload }
      })
    case types.SET_NAVIGATION_DISABLED:
      return update(state, {
        isNavigationDisabled: { $set: action.payload }
      })
    case types.SET_DND_LAST_DROP_ID:
      return update(state, {
        dndLastDropId: { $set: action.payload }
      })
    case types.ADD_TOOLTIP_ID: {
      if (state.openedTooltips.includes(action.payload)) {
        return state
      }
      return update(state, {
        openedTooltips: { $push: [action.payload] }
      })
    }
    case types.REMOVE_TOOLTIP_ID: {
      return update(state, {
        openedTooltips: {
          $set: state.openedTooltips.filter(id => id !== action.payload)
        }
      })
    }
    default:
      return state
  }
}

export default appReducer
