import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/core'
import { FormControlReactSelect } from 'components/Form/index'
import classNames from 'classnames'

const fonts = [
  { label: 'Check out the bake sale...', value: 1 },
  { label: 'Check out the bake sale...', value: 2 },
  { label: 'Check out the bake sale...', value: 3 }
]

const styles = ({ type, palette, colors }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px 4px 0',
    backgroundColor: palette[type].formControls.input.background
  },
  triangle2: {
    width: 0,
    height: 0,
    backgroundColor: palette[type].formControls.input.background,
    padding: '0 6px',
    borderTop: '9px solid transparent',
    borderLeft: `9px solid ${colors.error}`,
    borderBottom: '9px solid transparent'
  },
  triangle3: {
    width: 0,
    height: 0,
    backgroundColor: palette[type].formControls.input.background,
    padding: '0 6px',
    borderTop: '9px solid transparent',
    borderLeft: '9px solid green',
    borderBottom: '9px solid transparent'
  },
  greenBorder: {
    border: '2px solid green'
  },
  inputTriangle2: {
    '& .react-select__value-container': {
      paddingLeft: 10,

      '&:before': {
        content: '""',
        position: 'absolute',
        width: 12,
        height: 12,
        left: -6,
        top: 'calc(50% - 6px)',
        transform: 'rotate(45deg)',
        background: colors.error
      }
    }
  },
  inputTriangle3: {
    '& .react-select__value-container': {
      padding: '2px 0 2px 8px',
      border: '2px solid green',

      '&:before': {
        content: '""',
        position: 'absolute',
        width: 12,
        height: 12,
        left: -8,
        top: 'calc(50% - 6px)',
        transform: 'rotate(45deg)',
        background: 'green'
      }
    }
  }
})

const FormControlSelectTextTheme = ({
  options = fonts,
  value,
  classes,
  ...props
}) => {
  const formatOptionLabel = useCallback(
    ({ value, label }) => {
      return (
        <div
          className={classNames(classes.root, {
            [classes.greenBorder]: value === 3
          })}
        >
          {value !== 1 && (
            <div className={classNames(classes[`triangle${value}`])} />
          )}

          <div>{label}</div>
        </div>
      )
    },
    [classes]
  )

  return (
    <FormControlReactSelect
      formatOptionLabel={formatOptionLabel}
      options={options}
      formControlContainerClass={classes[`inputTriangle${value}`]}
      value={value}
      {...props}
    />
  )
}

export default withStyles(styles)(FormControlSelectTextTheme)
