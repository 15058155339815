import React, { useState } from 'react'
import { withStyles } from '@material-ui/styles'
import { Card, Grid } from '@material-ui/core'
import Tooltip from 'components/Tooltip'
import { DeviceMoreInfoCard } from 'components/Card'
import { getDeviceCardPopupSize } from 'utils/deviceUtils'
import MaterialPopup from 'components/Popup/MaterialPopup'
import { withTranslation } from 'react-i18next'
import PulseDot from '../PulseDot/PulseDot'
import DeviceTableIcon from '../../DeviceLibrary/DeviceTableIcon'
import { TextWithTooltip } from 'components/Typography'
import UptimeLineCell from './UptimeLineCell'
import { DateTimeView } from 'components/TableLibrary'
import { useParams } from 'react-router'

const styles = ({ palette, type }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    margin: '10px',
    gap: '10px'
  },
  statusCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette[type].pageContainer.header.background,
    padding: '15px',
    borderRadius: '8px',
    color: palette[type].pageContainer.header.titleColor,
    gap: '12px'
  },
  infoSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '35%'
  },
  infoLable: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    cursor: 'pointer'
  },
  statusIndicators: {
    display: 'flex',
    width: '68%',
    columnGap: '2px',
    cursor: 'pointer'
  },
  statusLabels: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  status: {
    cursor: 'pointer',
    paddingRight: '10px'
  },
  dateText: {
    color: '#fff',
    lineHeight: '18px',
    textAlign: 'center',
    '&[data-24-time-format="false"]': {
      color: '#fff',
      lineHeight: '18px'
    },
    '&[data-24-time-format="true"]': {
      color: '#fff',
      lineHeight: '18px'
    }
  }
})

const TimeLine = ({ data, t, classes }) => {
  const { token } = useParams()

  const [isPortraitImg, setIsPortraitImg] = useState(false)
  const popupSize = getDeviceCardPopupSize(isPortraitImg)

  return (
    <div className={classes.container}>
      {data.map((device, index) => (
        <Card key={index} className={classes.statusCard}>
          <div className={classes.infoSection}>
            <div className={classes.infoLable}>
              <MaterialPopup
                placement="right"
                on="hover"
                trigger={
                  <div>
                    <DeviceTableIcon deviceType={device.deviceType} />
                  </div>
                }
                disabled={token}
              >
                <DeviceMoreInfoCard
                  device={device}
                  isPortraitImg={isPortraitImg}
                  setIsPortraitImg={setIsPortraitImg}
                  loaderSize={popupSize}
                />
              </MaterialPopup>
              <Grid container direction="column">
                <TextWithTooltip
                  maxWidth={device.uptimeTimeline?.length ? 180 : '100%'}
                  color="title.primary"
                  weight="bold"
                  rootClassName={classes.titleText}
                >
                  {device.alias || device.name}
                </TextWithTooltip>
                <TextWithTooltip
                  maxWidth={device.uptimeTimeline?.length ? 180 : '100%'}
                  color="light"
                  variant="small"
                >
                  {device.id}
                </TextWithTooltip>
              </Grid>
            </div>
          </div>
          <div className={classes.statusIndicators}>
            {device.uptimeTimeline?.map((deviceData, index) => (
              <UptimeLineCell key={index} data={deviceData} />
            ))}
          </div>
          <div className={classes.statusLabels}>
            <Tooltip
              headerText={t('Last Check-In')}
              title={
                <Grid container justifyContnent="center">
                  <DateTimeView
                    date={device.lastCheckIn}
                    textClass={classes.dateText}
                    textSmallClass={classes.dateText}
                  />
                </Grid>
              }
              placement="bottom"
              withHeader
              arrowWithHeaderColor
              arrow
            >
              <div className={classes.status}>
                <PulseDot
                  color={device.status === 'Active' ? 'green' : 'red'}
                />
              </div>
            </Tooltip>
          </div>
        </Card>
      ))}
    </div>
  )
}

export default withTranslation('translations')(withStyles(styles)(TimeLine))
