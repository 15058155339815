import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import update from 'immutability-helper'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from '@reduxjs/toolkit'
import { _isEmpty } from 'utils/lodash'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import SliderWrapper from './SliderWrapper'
import { getDeviceNocGeneralItems } from 'actions/deviceNocActions'
import { queryParamsHelper } from 'utils'
import GridLoader from './GridLoader'
import DevicePreviewCard from 'components/Card/DevicePreviewCard'
import useDevicePreviews from 'hooks/api/useDevicePreviews'
import Container from 'components/Containers/Container'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import usePermissions from 'hooks/api/usePermissions'
import { permissionNames } from 'constants/index'

const styles = makeStyles({
  container: {
    gap: 0,
    gridTemplateColumns: ({ cols }) => `repeat(${cols}, 1fr)`,

    '& .slick-dots': {
      bottom: '-36px'
    }
  },
  imageContainer: {
    height: ({ imageHeight }) => imageHeight
  }
})

const MAIN_FOOTER_HEIGHT = 50
const PAGE_FOOTER_HEIGHT = 50
const ITEM_HEIGHT = 325
const ITEM_WIDTH = 400
const ITEM_HEADER_HEIGHT = 57
const ITEM_FOOTER_HEIGHT = 38

const DEFAULT_NUMBER_OF_COLS = 4
const DEFAULT_NUMBER_OF_ROWS = 4

const DevicePreview = ({
  t,
  filterParams,
  searchParams,
  library,
  preview,
  getDeviceNocGeneralItems = f => f,
  fetcher: fetchItems,
  itemReducer,
  isPublic,
  isFetchAllowed,
  preferenceParams
}) => {
  const {
    isPending,
    updatePreview,
    isPreviewPending,
    previewPendingDeviceId
  } = useDevicePreviews(null, true)
  const { getPermissionByName } = usePermissions()

  const [data, setData] = useState({})
  const [page, setPage] = useState(1)
  const [initialized, setInitialized] = useState(false)
  const [fetchRequestAt, setFetchRequestAt] = useState(0)
  const [isAllDataFetched, setAllDataFetched] = useState(false)
  const [matrix, setMatrix] = useState({
    rows: DEFAULT_NUMBER_OF_ROWS,
    cols: DEFAULT_NUMBER_OF_COLS,
    itemHeight: ITEM_HEIGHT,
    itemWidth: ITEM_WIDTH
  })
  const timerInterval = useRef()
  const classes = styles({
    cols: matrix.cols,
    imageHeight: matrix.itemHeight - (ITEM_HEADER_HEIGHT + ITEM_FOOTER_HEIGHT)
  })

  const sliderRef = useRef(null)
  const containrRef = useRef()

  const { response: items, isFetching = true, meta } = useMemo(
    () => (itemReducer ? itemReducer : library),
    [itemReducer, library]
  )

  const fetcher = useCallback(
    (params = {}) => {
      const { filters, ...restPreferenceParams } = preferenceParams
      fetchItems
        ? fetchItems(
            queryParamsHelper({
              ...restPreferenceParams,
              ...searchParams,
              ...filterParams,
              limit: matrix.rows * matrix.cols,
              ...params
            })
          )
        : getDeviceNocGeneralItems(
            queryParamsHelper({
              ...searchParams,
              ...filterParams,
              sort: 'updatedAt',
              order: 'desc',
              limit: matrix.rows * matrix.cols,
              ...params
            })
          )
      if (params.page !== 2) {
        setFetchRequestAt(Date.now())
      }
    },
    [
      getDeviceNocGeneralItems,
      searchParams,
      filterParams,
      matrix,
      fetchItems,
      preferenceParams
    ]
  )

  const handleResizeWindow = useCallback(() => {
    const { top } = containrRef.current
      ? containrRef.current.getBoundingClientRect()
      : { top: 0 }
    let itemHeight = ITEM_HEIGHT,
      itemWidth = ITEM_WIDTH

    // for dynamic height & width
    if (isPublic && window.innerWidth <= 1600) {
      itemWidth = (ITEM_WIDTH / 3) * 2
    }

    let cols = DEFAULT_NUMBER_OF_COLS
    if (isPublic) {
      const totalCols = Math.floor(window.innerWidth / itemWidth)
      cols = totalCols < 1 ? 1 : totalCols
    }

    if (isPublic) {
      itemWidth = window.innerWidth / cols
      itemHeight =
        ITEM_HEADER_HEIGHT + ITEM_FOOTER_HEIGHT + (itemWidth * 9) / 16
    }

    const remainingHeight =
      window.innerHeight -
      (top + (isPublic ? 20 : MAIN_FOOTER_HEIGHT + PAGE_FOOTER_HEIGHT))
    const totalRows = Math.round(remainingHeight / itemHeight)
    const rows =
      totalRows < 1 ? 1 : isPublic ? totalRows : totalRows > 4 ? 4 : totalRows

    if (isPublic && itemHeight * rows > remainingHeight) {
      itemHeight = Math.round(remainingHeight / totalRows)
    }

    setMatrix({
      rows,
      cols,
      itemHeight,
      itemWidth,
      refresh:
        rows === DEFAULT_NUMBER_OF_ROWS && cols === DEFAULT_NUMBER_OF_COLS
    })
  }, [isPublic])

  useEffect(() => {
    if (initialized && isFetchAllowed) {
      clearInterval(timerInterval.current)
      setData({})
      fetcher({
        limit: matrix.rows * matrix.cols,
        page: 1
      })
    }
    // eslint-disable-next-line
  }, [matrix.rows, matrix.cols, matrix.refresh, filterParams, isFetchAllowed])

  useEffect(() => {
    handleResizeWindow()
    setInitialized(true)

    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
  }, [handleResizeWindow])

  useEffect(() => {
    if (initialized && !isFetching && items && items.length) {
      if (_isEmpty(data) && meta.currentPage === 1) {
        setData({
          ...Object.assign(
            {},
            Array(Math.ceil(meta.total / (matrix.rows * matrix.cols))).fill([])
          ),
          [meta.currentPage - 1]: items
        })
        if (meta.lastPage > 1) {
          fetcher({ page: 2 })
        }
      } else {
        setData({
          ...data,
          [meta.currentPage - 1]: items
        })
      }
    }
    // eslint-disable-next-line
  }, [items])

  useEffect(() => {
    if (preview && preview.id && !_isEmpty(data)) {
      let index = data?.[page - 1]?.findIndex(({ id }) => id === preview.id)
      let _page = page - 1

      if (index <= -1) {
        for (let key in data) {
          index = data?.[key]?.findIndex(({ id }) => id === preview.id)
          if (index > -1) {
            _page = key
            break
          }
        }
      }

      if (index > -1) {
        setData(
          update(data, {
            [_page]: {
              [index]: { $set: preview }
            }
          })
        )
      }
    }
    // eslint-disable-next-line
  }, [preview])

  const deviceThumbnailPermission = useMemo(
    () => ({
      other: getPermissionByName(permissionNames.ORG_DEVICE_THUMBNAIL_TRIGGER)
    }),
    [getPermissionByName]
  )

  const beforeChange = useCallback(
    (current, next) => {
      setPage(next + 1)

      if (current !== next) {
        fetcher({
          page: ((next + 1) % Object.keys(data).length) + 1
        })
      }
    },
    [fetcher, data]
  )

  const settings = useMemo(
    () => ({
      beforeChange,
      dots: false,
      autoplay: false
    }),
    [beforeChange]
  )

  const renderChunks = useMemo(
    () => (
      <div
        style={{
          height: matrix.rows * matrix.itemHeight
        }}
      >
        <SliderWrapper ref={sliderRef} customSettings={settings}>
          {Object.entries(data).map(([key, values]) =>
            isFetching && !values.length ? (
              <GridLoader
                key={`device-preview-loader-${key}`}
                cols={matrix.cols}
                rows={matrix.rows}
                boxHeight={matrix.itemHeight - 20}
              />
            ) : (
              <Container key={key} customClass={classes.container}>
                {values.map(device => (
                  <DevicePreviewCard
                    key={device.id}
                    device={device}
                    onUpdate={updatePreview}
                    isUpdateAllowed={deviceThumbnailPermission.other}
                    isPending={
                      previewPendingDeviceId === device.id
                        ? isPreviewPending
                        : false
                    }
                    imageContainerClassName={classes.imageContainer}
                    maxWidth={matrix.itemWidth - 35}
                  />
                ))}
              </Container>
            )
          )}
        </SliderWrapper>
      </div>
    ),
    [
      classes.container,
      classes.imageContainer,
      settings,
      data,
      updatePreview,
      deviceThumbnailPermission.other,
      previewPendingDeviceId,
      isPreviewPending,
      isFetching,
      matrix
    ]
  )

  const dataLength = useMemo(() => {
    return Object.keys(data).filter(key => data[key].length).length
  }, [data])

  useEffect(() => {
    if (dataLength > 1 && !isAllDataFetched) {
      const diffInMilliseconds = Date.now() - fetchRequestAt
      const timeLeft = Math.max(10000 - diffInMilliseconds, 0)

      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)

        sliderRef.current?.next()

        if (dataLength && meta.lastPage && dataLength === meta.lastPage) {
          setAllDataFetched(true)
          timerInterval.current = setInterval(() => {
            sliderRef.current?.next()
          }, 10000)
        }
      }, timeLeft)
    }

    return () => {
      clearInterval(timerInterval.current)
    }
    // eslint-disable-next-line
  }, [dataLength])

  return (
    <div ref={containrRef}>
      {isFetching && !Object.keys(data).length ? (
        <GridLoader
          cols={matrix.cols}
          rows={matrix.rows}
          boxHeight={matrix.itemHeight - 20}
        />
      ) : _isEmpty(data) && !meta.total && !isPending ? (
        <EmptyPlaceholder text={t('No Previews Available')} />
      ) : (
        <>{renderChunks}</>
      )}
    </div>
  )
}

const mapStateToProps = ({ deviceNoc, new: { device } }) => ({
  library: deviceNoc.general,
  preview: device.preview.data
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceNocGeneralItems
    },
    dispatch
  )

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(DevicePreview)
)
