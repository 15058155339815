import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Typography, withStyles, Button, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import classNames from 'classnames'
import * as Yup from 'yup'

import { FormControlInput } from 'components/Form'
import Spacing from 'components/Containers/Spacing'
import { requiredField, emailField } from 'constants/validationMessages'
import { instanceTypes, ORG_ROLE, SYSTEM_ROLE } from 'constants/api'
import { simulateEvent } from 'utils'
import PasswordVisibilityButton from './PasswordVisibilityButton'
import SocialPanel from '../SocialLogin/SocialPanel'
import SubmitButton from './SubmitButton'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'
import useSnackbar from 'hooks/useSnackbar'
import { useCheckLoginMutation } from 'api/authenticationApi'
import useMutationResultHandler from 'hooks/useMutationResultHandler'

function styles({ palette, type }) {
  return {
    inputWrap: {
      position: 'relative'
    },
    formControlInput: {
      height: '48px',
      fontSize: '15px'
    },
    formControlContainer: {
      marginBottom: '10px'
    },
    formLink: {
      margin: '0 14px 0 3px',
      display: 'inline-block',
      textDecoration: 'none'
    },
    formLinkText: {
      fontSize: '13px',
      color: '#0076b9'
    },
    forgotPassLink: {
      top: '16px',
      right: '12px'
    },
    submitWrap: {
      marginBottom: 30
    },
    submitWrapWithIp: {
      marginBottom: '20px'
    },
    dontHaveAccountText: {
      color: '#888996',
      textAlign: 'center',
      marginTop: 30
    },
    dontHaveAccountLink: {
      color: '#0076b9'
    },
    eyeContentWrap: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: 3,
      right: 8
    },
    signInContainer: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between'
    },
    alignBaseline: {
      alignItems: 'baseline'
    },
    submitButtonRoot: {
      width: '100%',
      maxWidth: 320,
      marginRight: 10
    },
    socialButtonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 20
    },
    socialButton: {
      margin: '10px 0',
      width: 180,
      height: 50,
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 'bold'
    },
    dividerContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '14px 0',
      textAlign: 'center'
    },
    dividerLine: {
      flex: 1,
      borderBottom: '1px solid #ccc',
      margin: '0 10px'
    },
    dividerText: {
      fontSize: '14px',
      color: '#5f6368'
    },
    signInWithEmailButton: {
      width: '100%',
      maxWidth: '100%',
      height: '48px',
      backgroundColor: '#0076b9',
      '&:hover': {
        backgroundColor: '#006198'
      }
    },
    disabled: {
      width: '100%',
      maxWidth: '100%',
      height: '48px',
      color: '#fff !important',
      background: '#0076b9 !important',
      opacity: 0.5
    },
    manualSignInTextWrap: {
      background: palette[type].sideModal.footer.background,
      padding: '10px',
      borderRadius: '5px',
      width: '80%',
      margin: '14px auto',
      transition: 'all 0.5s ease-in-out'
    },
    manualSignInText: {
      textAlign: 'left',
      width: '100%',
      maxWidth: '300px',
      margin: '0 auto',
      color: '#888888', // Dark gray color
      fontSize: '14px'
    },
    manualSignInLink: {
      color: '#0076b9',
      transitions: 'all 0.5s ease-in-out',
      '&:hover': {
        textDecoration: 'underline',
        color: '#006198'
      }
    },
    magiclink: {
      maxWidth: '400px'
    }
  }
}

const isProduction = process.env.REACT_APP_INSTANCE === instanceTypes.prod

const formConfig = {
  initialValues: {
    username: ''
  },
  validationSchema: Yup.object().shape({
    username: Yup.string().required(requiredField).email(emailField)
  })
}

const { initialValues, validationSchema } = formConfig

const FormWrapper = props => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
    >
      <Form {...props} />
    </GoogleReCaptchaProvider>
  )
}

function Form({
  t,
  role,
  sso,
  classes,
  isIpVisible,
  isLoading,
  onSubmit,
  isManually
}) {
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [loginWithAnotherMethod, setLoginWithAnotherMethod] = useState(false)

  const { showSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [checkLogin, loginResult] = useCheckLoginMutation()

  const {
    isValid,
    values,
    touched,
    errors,
    handleChange,
    validateForm,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ username, password, rememberMe }) => {
      onSubmit(username, password, rememberMe, role)
    }
  })

  useEffect(
    () => {
      validateForm()
    },
    //eslint-disable-next-line
    []
  )

  useMutationResultHandler({
    result: loginResult,
    keyWord: 'A Magic Link email',
    actionText: 'sent successfully. This Magic Link will expire in 15 minutes'
  })

  const handleEmailSignIn = useCallback(
    async e => {
      e.preventDefault()

      if (!executeRecaptcha) {
        showSnackbar(t('Recaptcha is not yet available'), 'error')
        return
      }

      if (isValid) {
        const recaptchaToken = await executeRecaptcha('checkLogin')
        checkLogin({
          email: values.username,
          recaptchaToken
        })
      }
    },
    [executeRecaptcha, isValid, values.username, checkLogin, showSnackbar, t]
  )

  const handleInputChange = useCallback(
    e => {
      const { value, name } = e.target
      if (value) {
        handleChange(simulateEvent(name, value.replaceAll(' ', '')))
      } else {
        handleChange(e)
      }
    },
    [handleChange]
  )

  const signInButton = useMemo(
    () => (
      <SubmitButton
        opaque={!isValid}
        isLoading={isLoading}
        rootClass={role === SYSTEM_ROLE && classes.submitButtonRoot}
      >
        {t('Sign In')}
      </SubmitButton>
    ),
    [isValid, isLoading, role, t, classes.submitButtonRoot]
  )

  return role === ORG_ROLE && !isManually ? (
    <div>
      <Spacing
        variant={0}
        rootClassName={classNames({
          [classes.submitWrap]: !isIpVisible,
          [classes.submitWrapWithIp]: isIpVisible
        })}
      >
        <SocialPanel
          renderOptions={sso}
          role={role}
          loginWithAnotherMethod={loginWithAnotherMethod}
          setLoginWithAnotherMethod={setLoginWithAnotherMethod}
        />
        <div className={classes.dividerContainer}>
          <div className={classes.dividerLine}></div>
          <Typography className={classes.dividerText}>{t('OR')}</Typography>
          <div className={classes.dividerLine}></div>
        </div>
        <form onSubmit={handleEmailSignIn} className={classes.magiclink}>
          <div className={classes.inputWrap}>
            <FormControlInput
              id="username"
              type="text"
              autocomplete="email"
              name="username"
              value={values.username}
              error={errors.username}
              handleChange={handleInputChange}
              handleBlur={handleBlur}
              touched={touched.username}
              placeholder={t('Email')}
              formControlInputClass={classes.formControlInput}
              fullWidth
            />
          </div>
          <Button
            className={`${
              !isValid ? classes.disabled : classes.signInWithEmailButton
            }`}
            color="primary"
            variant="contained"
            type="submit"
            disabled={isLoading || loginResult.isLoading}
          >
            {t('Sign in with Email')}
          </Button>
          <div className={classes.manualSignInTextWrap}>
            <Typography className={classes.manualSignInText}>
              {t(
                'We’ll email you a magic link for a password-free sign-in.Or you can'
              )}
              &nbsp;
              <Link
                to="/sign-in-manually"
                component={RouterLink}
                className={classes.manualSignInLink}
              >
                {t('Sign in manually instead.')}
              </Link>
            </Typography>
          </div>
        </form>
        {isProduction && role === ORG_ROLE && (
          <Spacing>
            <Typography className={classes.dontHaveAccountText}>
              {t('Want to try?')}{' '}
              <Link
                to="/sign-up"
                component={RouterLink}
                className={classes.dontHaveAccountLink}
              >
                {t('Sign up for a free trial!')}
              </Link>
            </Typography>
          </Spacing>
        )}
      </Spacing>
    </div>
  ) : (
    <form onSubmit={handleSubmit}>
      <div className={classes.inputWrap}>
        <FormControlInput
          id="username"
          type="text"
          autocomplete="email"
          name="username"
          value={values.username}
          handleChange={handleInputChange}
          error={errors.username}
          handleBlur={handleBlur}
          touched={touched.username}
          placeholder={t('Email')}
          formControlInputClass={classes.formControlInput}
          formControlContainerClass={classes.formControlContainer}
          fullWidth
        />
      </div>
      <div className={classes.inputWrap}>
        <FormControlInput
          id="password"
          type={passwordVisibility ? 'text' : 'password'}
          name="password"
          value={values.password}
          handleChange={handleInputChange}
          error={errors.password}
          handleBlur={handleBlur}
          touched={touched.password}
          placeholder={t('Password')}
          formControlInputClass={classes.formControlInput}
          formControlContainerClass={classes.formControlContainer}
          fullWidth
        />
        <div className={classes.eyeContentWrap}>
          <PasswordVisibilityButton
            onClick={() => setPasswordVisibility(!passwordVisibility)}
            isVisible={passwordVisibility}
          />
          <Link
            to={
              role === SYSTEM_ROLE
                ? '/system/forgot-password'
                : '/forgot-password'
            }
            component={RouterLink}
            className={classNames(classes.formLink, classes.forgotPassLink)}
          >
            <Typography className={classes.formLinkText}>
              {t('Reset Password')}
            </Typography>
          </Link>
        </div>
      </div>
      {isManually ? (
        <Spacing
          variant={0}
          rootClassName={classNames({
            [classes.submitWrap]: !isIpVisible,
            [classes.submitWrapWithIp]: isIpVisible
          })}
        >
          <Grid item>{signInButton}</Grid>
          {isProduction && role === ORG_ROLE && (
            <Spacing>
              <Typography className={classes.dontHaveAccountText}>
                {t('Want to try?')}{' '}
                <Link
                  to="/sign-up"
                  component={RouterLink}
                  className={classes.dontHaveAccountLink}
                >
                  {t('Sign up for a free trial!')}
                </Link>
              </Typography>
            </Spacing>
          )}
        </Spacing>
      ) : (
        <Spacing
          variant={0}
          rootClassName={classNames({
            [classes.submitWrap]: !isIpVisible,
            [classes.submitWrapWithIp]: isIpVisible
          })}
        >
          <Spacing variant={0}>
            <Grid container className={classNames(classes.signInContainer, {})}>
              <SocialPanel
                renderOptions={sso}
                role={role}
                loginWithAnotherMethod={loginWithAnotherMethod}
                setLoginWithAnotherMethod={setLoginWithAnotherMethod}
                signInButton={signInButton}
              />
            </Grid>
          </Spacing>
        </Spacing>
      )}
    </form>
  )
}

Form.propTypes = {
  role: PropTypes.string,
  sso: PropTypes.object,
  isIpVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
}

export default withTranslation('translations')(withStyles(styles)(FormWrapper))
