const exceptionNames = {
  YouTube: 'youtube'
}

export const transformArray = arr => {
  const newArr = []
  let str = ''
  arr.forEach(a => {
    if (a.trim()) {
      if (a.trim().length === 1) {
        str += a
      } else {
        if (str) newArr.push(str)
        newArr.push(a.trim())
        str = ''
      }
    }
  })
  if (str) newArr.push(str)
  return newArr
}

const getMediaUrlName = (name, reverse) => {
  if (!name || typeof name !== 'string') return name
  if (reverse && reverse === -1) {
    return name.replace(/_/g, '')
  } else {
    if (exceptionNames[name]) {
      return exceptionNames[name]
    }

    const splitName = transformArray(name.split(/(?=[A-Z])/))

    return splitName.join('_').toLowerCase()
  }
}

export default getMediaUrlName
