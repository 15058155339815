import { ORG_ROLE, SYSTEM_ROLE } from 'constants/api'
import { createSelector } from 'reselect'

export function alertTypesSelectors({ config }) {
  return config.alertTypes
}

const _configWorkplacePosterTagsSelector = ({ config }) =>
  config.workplacePosterTags

export const configWorkplacePosterTagsSelector = createSelector(
  _configWorkplacePosterTagsSelector,
  tags => tags
)

const _configMediaCategorySelector = ({ config }) => config.configMediaCategory

export const configMediaCategorySelector = createSelector(
  _configMediaCategorySelector,
  configMediaCategory => configMediaCategory
)

const _configFeatureMediaSelector = ({ config }) => config.configFeatureMedia

export const configFeatureMediaSelector = createSelector(
  _configFeatureMediaSelector,
  configFeatureMedia => configFeatureMedia
)

const _oauth2TokensSelector = ({ config }) => config.oauth2Tokens.items

export const oauth2TokensSelector = createSelector(
  _oauth2TokensSelector,
  oauth2Tokens => oauth2Tokens
)

const _legacyThemesEnabledSelector = ({ config }) => config.legacyThemesEnabled

export const legacyThemesEnabledSelector = createSelector(
  _legacyThemesEnabledSelector,
  legacyThemesEnabled => legacyThemesEnabled
)

const _appConfigSelector = ({ config }) => config.appConfig

export const appConfigSelector = createSelector(
  _appConfigSelector,
  appConfig => appConfig
)

const _contentApproveSelector = ({ config }) => config.contentApprove

export const contentApproveSelector = createSelector(
  _contentApproveSelector,
  contentApprove => contentApprove
)

const _allConfigFeatureMediaSelector = ({ config }) =>
  config.allConfigFeatureMedia

export const allConfigFeatureMediaSelector = createSelector(
  _allConfigFeatureMediaSelector,
  allConfigFeatureMedia => allConfigFeatureMedia
)

export const shorelineEventsSelector = ({ config }) => config.shorelineEvents

export const shorelinePricesSelector = ({ config }) => config.shorelinePrices

export const shorelinePriceUploadSelector = ({ config }) =>
  config.shorelinePriceUpload

export const backgroundImagesSelector = ({ config }) => config.backgroundImages

export const backgroundPatternsSelector = ({ config }) =>
  config.backgroundPatterns

export const geoLocationSelector = ({ config }) => config.geoLocation

export const globalSearchSelector = ({ config }) => config.globalSearch

export const orgRoleSelector = ({ config }) => config.configOrgRole

export const oauth2TokenPostSelector = ({ config }) => config.oauth2Tokens.post

export const oauth2TokenPutSelector = ({ config }) => config.oauth2Tokens.put

export const oauth2TokenDelSelector = ({ config }) => config.oauth2Tokens.del

export const moderationLabelSelector = ({ config }) => config.moderationLabel

export const rolesSelector = ({
  user: {
    details: {
      response: {
        role: { level }
      }
    }
  },
  config: {
    systemRole: { response: systemRoles },
    configOrgRole: { response: orgRoles },
    enterpriseRole: { response: enterpriseRoles }
  }
}) =>
  level === ORG_ROLE
    ? orgRoles
    : level === SYSTEM_ROLE
    ? systemRoles
    : enterpriseRoles
