import {
  disabledForProd,
  disabledForStaging,
  disabledForDev
} from 'constants/featureConstants'
import { instanceTypes } from 'constants/api'

export const isDisabledForDev = featureName => {
  return disabledForDev.includes(featureName)
}

export const isDisabledForStaging = featureName => {
  return disabledForStaging.includes(featureName)
}

export const isDisabledForProd = featureName => {
  return disabledForProd.includes(featureName)
}

export const isFeatureAvailable = featureName => {
  if (process.env.NODE_ENV === 'development') {
    return !isDisabledForDev(featureName)
  }

  switch (process.env.REACT_APP_INSTANCE) {
    case instanceTypes.prod:
      return !isDisabledForProd(featureName)
    case instanceTypes.qa:
      return !isDisabledForStaging(featureName)
    case instanceTypes.dev:
      return !isDisabledForDev(featureName)
    default:
      return false
  }
}
