import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

function styles({ spacing }) {
  return {
    root: {
      display: 'grid !important',
      gap: spacing(2)
    },
    'cols-1': {
      gridTemplateColumns: '1fr'
    },
    'cols-2': {
      gridTemplateColumns: '1fr 1fr'
    },
    'cols-3': {
      gridTemplateColumns: '1fr 1fr 1fr'
    },
    'cols-3-1': {
      gridTemplateColumns: '3fr 1fr'
    },
    'cols-3-2': {
      gridTemplateColumns: '3fr 2fr'
    },
    'cols-3-4': {
      gridTemplateColumns: '3fr 4fr'
    },
    'cols-4': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    },
    'cols-4-3': {
      gridTemplateColumns: '4fr 3fr'
    },
    'cols-2-1': {
      gridTemplateColumns: '2fr 1fr'
    },
    'cols-2-3': {
      gridTemplateColumns: '2fr 3fr'
    },
    'cols-1-2': {
      gridTemplateColumns: '1fr 2fr'
    },
    'cols-1-3': {
      gridTemplateColumns: '1fr 3fr'
    },
    'cols-1-4': {
      gridTemplateColumns: '1fr 4fr'
    },
    'cols-1-3-3': {
      gridTemplateColumns: '1fr 3fr 3fr'
    },
    'cols-1-1-2': {
      gridTemplateColumns: '1fr 1fr 2fr'
    },
    'cols-1-2-1': {
      gridTemplateColumns: '1fr 2fr 1fr'
    },
    'cols-2-1-1': {
      gridTemplateColumns: '2fr 1fr 1fr'
    },
    'cols-2-2-1': {
      gridTemplateColumns: '2fr 2fr 1fr'
    },
    'cols-2-5': {
      gridTemplateColumns: '2fr 5fr'
    },
    'cols-3-5': {
      gridTemplateColumns: '3fr 5fr'
    },
    'cols-4-5': {
      gridTemplateColumns: '4fr 5fr'
    },
    'cols-4-1': {
      gridTemplateColumns: '4fr 1fr'
    },
    'cols-4-6-1': {
      gridTemplateColumns: '4fr 6fr 1fr'
    },
    'cols-5': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
    },
    'cols-6': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr'
    },
    'cols-7': {
      gridTemplateColumns: 'repeat(7, 1fr)'
    },
    'cols-8': {
      gridTemplateColumns: 'repeat(8, 1fr)'
    }
  }
}

const Container = forwardRef(
  ({ classes, children, cols = '2', customClass, ...props }, ref) => {
    return (
      <Grid
        ref={ref}
        container
        className={classNames(
          classes.root,
          classes[`cols-${cols}`],
          customClass
        )}
        {...props}
      >
        {children}
      </Grid>
    )
  }
)

Container.propTypes = {
  cols: PropTypes.oneOf([
    '1',
    '2',
    '3',
    '4',
    '2-1',
    '2-3',
    '1-2',
    '1-3',
    '1-2-1',
    '1-4',
    '2-1-1',
    '2-2-1',
    '2-5',
    '3-5',
    '4-5',
    '5',
    '3-1',
    '3-2',
    '3-4',
    '4-3',
    '4-1',
    '4-6-1',
    '1-3-3',
    '6',
    '7',
    '8'
  ])
}

export default withStyles(styles)(Container)
