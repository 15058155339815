import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { ENABLE_LEGACY_THEMES } from 'config/index'
import { instanceTypes, LEGACY_THEMES_ENABLED } from 'constants/api'
import { setLegacyThemesEnabled } from 'actions/configActions'

const allowedInstances = [
  instanceTypes.local,
  instanceTypes.dev,
  instanceTypes.qa
]

const POST_MESSAGE_TRIGGER = 'enableLegacyThemes'

export default function useLegacyThemesState() {
  const dispatch = useDispatch()
  const storageState = sessionStorage.getItem(LEGACY_THEMES_ENABLED)

  // run "window.postMessage('enableLegacyThemes')" in the
  // browser console to display legacy themes
  const onMessageReceive = useCallback(
    ({ data }) => {
      if (
        allowedInstances.includes(process.env.REACT_APP_INSTANCE) &&
        data === POST_MESSAGE_TRIGGER
      ) {
        const enabled = sessionStorage.getItem(LEGACY_THEMES_ENABLED) === 'true'
        sessionStorage.setItem(
          LEGACY_THEMES_ENABLED,
          enabled ? 'false' : 'true'
        )
        dispatch(setLegacyThemesEnabled(!enabled))
        // eslint-disable-next-line
        console.log(`Legacy themes ${!enabled ? 'enabled' : 'disabled'}`)
      }
    },
    [dispatch]
  )

  useEffect(
    () => {
      dispatch(
        setLegacyThemesEnabled(
          ENABLE_LEGACY_THEMES === 'true' || storageState === 'true'
        )
      )
      window.addEventListener('message', onMessageReceive)
      return () => {
        window.removeEventListener('message', onMessageReceive)
      }
    },
    // eslint-disable-next-line
    []
  )
}
