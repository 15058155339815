import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  getDeviceHealth,
  triggerDeviceHealth,
  getDevicePreview,
  triggerDevicePreview,
  getPreviewThumbnail
} from 'services/deviceService'
import {
  DEVICE_HEALTH_PENDING,
  deviceHealthFailure,
  deviceHealthSuccess,
  PREVIEW_THUMBNAIL_PENDING,
  PREVIEWS_PENDING,
  previewsFailure,
  previewsSuccess,
  previewThumbnailFailure,
  previewThumbnailSuccess,
  TRIGGER_HEALTH_PENDING,
  TRIGGER_PREVIEW_PENDING,
  triggerDeviceHealthFailure,
  triggerDeviceHealthSuccess,
  triggerDevicePreviewFailure,
  triggerDevicePreviewSuccess
} from 'actions/new/device'
import isArray from 'lodash/isArray'

export default function* deviceWatcher() {
  yield all([
    takeEvery(DEVICE_HEALTH_PENDING, getDeviceHealthWorker),
    takeEvery(TRIGGER_HEALTH_PENDING, triggerDeviceHealthWorker),
    takeEvery(PREVIEWS_PENDING, previewsWorker),
    takeEvery(TRIGGER_PREVIEW_PENDING, triggerDevicePreviewWorker),
    takeEvery(PREVIEW_THUMBNAIL_PENDING, devicePreviewThumbnailWorker)
  ])
}

function* getDeviceHealthWorker({ id, onSuccess }) {
  try {
    const data = yield call(getDeviceHealth, id)
    yield put(deviceHealthSuccess(isArray(data) ? {} : data))
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(deviceHealthFailure(error))
  }
}

function* triggerDeviceHealthWorker({ id, onSuccess }) {
  try {
    yield call(triggerDeviceHealth, id)
    yield put(triggerDeviceHealthSuccess())
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(triggerDeviceHealthFailure(error))
  }
}

function* triggerDevicePreviewWorker({ id, onSuccess }) {
  try {
    yield call(triggerDevicePreview, id)
    yield put(triggerDevicePreviewSuccess())
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(triggerDevicePreviewFailure(error))
  }
}

function* previewsWorker({ payload }) {
  try {
    const { data, meta } = yield call(getDevicePreview, payload)
    yield put(previewsSuccess(data, meta))
  } catch (error) {
    yield put(previewsFailure())
  }
}

function* devicePreviewThumbnailWorker({ id, onFinish }) {
  try {
    const data = yield call(getPreviewThumbnail, id)
    yield put(previewThumbnailSuccess(data))
    if (onFinish) {
      onFinish()
    }
  } catch (error) {
    yield put(previewThumbnailFailure(error))
  }
}
