import React from 'react'
import PropTypes from 'prop-types'

import { TableLibraryCell } from './index'
import { TextWithTooltip } from 'components/Typography'

const DefaultTableLibraryCell = ({
  data,
  fieldName,
  t,
  align = 'center',
  maxWidth
}) => {
  const text = (data || {})[fieldName] ?? t('N/A')

  return (
    <TableLibraryCell align={align}>
      {maxWidth ? (
        <TextWithTooltip maxWidth={maxWidth}>{text}</TextWithTooltip>
      ) : (
        text
      )}
    </TableLibraryCell>
  )
}

DefaultTableLibraryCell.propTypes = {
  data: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  align: PropTypes.string,
  maxWidth: PropTypes.number
}

export default DefaultTableLibraryCell
