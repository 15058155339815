import { call, put } from 'redux-saga/effects'

import * as types from '../actions'
import { mediaCapAlertSuccess, mediaCapAlertError } from 'actions/mediaActions'
import { mediaService } from '../services'
import { transformMeta } from 'utils/tableUtils'
import { mediaApi } from 'api/mediaApi'
import apiTags from 'constants/apiTags'

function* getItems({ params }) {
  try {
    const response = yield call(mediaService.getMediaLibraryItems, params)

    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_MEDIA_ITEMS_SUCCESS,
      payload: { response, params },
      modifiedMeta
    })
  } catch (error) {
    yield put({ type: types.GET_MEDIA_ITEMS_ERROR, payload: error })
  }
}

function* getItemById({ id }) {
  try {
    const response = yield call(mediaService.getMediaLibraryItems, {
      id,
      exact: true,
      limit: 1
    })

    if (response.data[0]) {
      yield put({
        type: types.GET_MEDIA_BY_ID_SUCCESS,
        payload: { response: response.data[0], id }
      })
    }
  } catch (error) {
    yield put({ type: types.GET_MEDIA_BY_ID_ERROR, payload: error })
  }
}

function* getPreference() {
  try {
    const response = yield call(mediaService.getMediaLibraryPref)
    yield put({ type: types.GET_MEDIA_PREFERENCE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_MEDIA_PREFERENCE_ERROR, payload: error })
  }
}

function* putPreference(action) {
  try {
    yield call(mediaService.putMediaLibraryPref, action.payload)
    yield put({ type: types.GET_MEDIA_PREFERENCE })
  } catch (error) {
    yield put({ type: types.PUT_MEDIA_PREFERENCE_ERROR, payload: error })
  }
}

function* addMedia({ data, meta }) {
  try {
    const response = yield call(mediaService.addMedia, data, meta)
    yield put({
      type: types.ADD_MEDIA_SUCCESS,
      payload: response,
      meta
    })
    yield put({
      type: types.SET_MEDIA_DEPENDENCY,
      payload: { error: null }
    })
  } catch (error) {
    yield put({
      type: types.ADD_MEDIA_ERROR,
      payload: error,
      meta
    })
    yield put({
      type: types.SET_MEDIA_DEPENDENCY,
      payload: { error: { meta, ...error } }
    })
  }
}

function* getMediaPreview(action) {
  try {
    const response = yield call(
      mediaService.getMediaPreview,
      action.data.id,
      action.data.isZoomToFit
    )
    yield put({
      type: types.GET_MEDIA_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_MEDIA_PREVIEW_ERROR, payload: error })
  }
}

function* generateMediaPreview(action) {
  try {
    const response = yield call(mediaService.generateMediaPreview, action.data)
    yield put({
      type: types.GENERATE_MEDIA_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    if (action.onFailure) {
      action.onFailure(error?.message, error)
      error.message = null
    }
    yield put({
      type: types.GENERATE_MEDIA_PREVIEW_ERROR,
      payload: error,
      meta: action.meta
    })
  }
}

function* getGroupItems(action) {
  try {
    const response = yield call(
      mediaService.getGroupItems,
      action.payload.id,
      action.payload.params
    )
    yield put({ type: types.GET_MEDIA_GROUP_ITEMS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_MEDIA_GROUP_ITEMS_ERROR, payload: error })
  }
}

function* postGroupItem(action) {
  try {
    const response = yield call(mediaService.postGroupItem, action.payload)
    yield put({ type: types.POST_MEDIA_GROUP_ITEM_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.POST_MEDIA_GROUP_ITEM_ERROR, payload: error })
  }
}

function* deleteGroupItem(action) {
  try {
    const response = yield call(mediaService.deleteGroupItem, action.payload)
    yield put({
      type: types.DELETE_MEDIA_GROUP_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.DELETE_MEDIA_GROUP_ITEM_ERROR, payload: error })
  }
}

function* getMediaItemById(action) {
  try {
    const response = yield call(mediaService.getMediaItemById, action.data)
    yield put({
      type: types.GET_MEDIA_ITEM_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_MEDIA_ITEM_BY_ID_ERROR, payload: error })
  }
}

function* putMediaItemById(action) {
  try {
    const response = yield call(mediaService.putMediaItemById, {
      id: action.meta.id,
      data: action.data,
      method: action.meta.method
    })
    yield put({
      type: types.PUT_MEDIA_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.PUT_MEDIA_ERROR,
      payload: error,
      meta: action.meta
    })
  }
}

function* cloneMediaAndUpdate(action) {
  try {
    const response = yield call(mediaService.cloneMediaAndUpdate, {
      id: action.meta.id,
      data: action.data
    })
    yield put({
      type: types.CLONE_MEDIA_AND_UPDATE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.CLONE_MEDIA_AND_UPDATE_ERROR,
      payload: error,
      meta: action.meta
    })
  }
}

function* getFeatureMediaItems(action) {
  try {
    const response = yield call(
      mediaService.getFeatureMediaItems,
      action.payload
    )
    yield put({
      type: types.GET_FEATURE_MEDIA_ITEMS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_FEATURE_MEDIA_ITEMS_ERROR, payload: error })
  }
}

function* putOrganizeFeatureMediaItems({ id, payload }) {
  try {
    const response = yield call(mediaService.putOrganizeFeatureMediaItems, {
      id,
      payload
    })
    yield put({
      type: types.PUT_ORGANIZE_FEATURE_MEDIA_ITEMS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.PUT_ORGANIZE_FEATURE_MEDIA_ITEMS_ERROR,
      payload: error
    })
  }
}

function* getMediaCapAlertWorker({ params }) {
  try {
    const { data, meta } = yield call(mediaService.getMediaCapAlert, params)
    yield put(mediaCapAlertSuccess({ data, meta }))
  } catch (error) {
    yield put(mediaCapAlertError(error))
  }
}

function* getMediaIpawsAlert({ params }) {
  try {
    const { data, meta } = yield call(mediaService.getMediaIpawsAlert, params)
    yield put({
      type: types.MEDIA_IPAWS_ALERT_SUCCESS,
      payload: {
        data,
        meta
      }
    })
  } catch (error) {
    yield put({
      type: types.MEDIA_IPAWS_ALERT_ERROR,
      payload: error
    })
  }
}

function* postFavoriteMedia({ data }) {
  try {
    yield call(mediaService.postFavoriteMedia, data)

    yield put({
      type: types.POST_FAVORITE_MEDIA_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_FAVORITE_MEDIA_ERROR, payload: error })
  }
}

function* addMediaToPlaylists(action) {
  try {
    const response = yield call(
      mediaService.addMediaToPlaylists,
      action.payload
    )
    yield put({
      type: types.POST_MEDIA_TO_PLAYLISTS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.POST_MEDIA_TO_PLAYLISTS_ERROR, payload: error })
  }
}

function* deleteSelectedMedia(action) {
  try {
    yield call(mediaService.deleteSelectedMedia, action.payload)
    yield put({
      type: types.DELETE_SELECTED_MEDIA_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_MEDIA_ERROR,
      payload: error
    })
  }
}

function* shareMedia({ data }) {
  try {
    yield call(mediaService.shareMedia, data)
    yield put({
      type: types.SHARE_MEDIA_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.SHARE_MEDIA_ERROR, payload: error })
  }
}

function* publishDesign({ data }) {
  try {
    yield call(mediaService.publishDesign, data)
    yield put({
      type: types.PUBLISH_DESIGN_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUBLISH_DESIGN_ERROR, payload: error })
  }
}

function* copyMedia({ data, impersonated }) {
  try {
    yield call(mediaService.copyMedia, data, impersonated)
    yield put({
      type: types.COPY_MEDIA_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.COPY_MEDIA_ERROR, payload: error })
  }
}

function* copyDesignToSystem({ designGalleryId }) {
  try {
    yield call(mediaService.copyDesignToSystem, designGalleryId)
    yield put({
      type: types.COPY_DESIGN_TO_SYSTEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.COPY_DESIGN_TO_SYSTEM_ERROR, payload: error })
  }
}

function* addMediaToPlaylist({ data }) {
  try {
    yield call(mediaService.addMediaToPlaylist, data)
    yield put({
      type: types.POST_MEDIA_TO_PLAYLIST_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_MEDIA_TO_PLAYLIST_ERROR, payload: error })
  }
}

function* postMediaBulk({ payload }) {
  try {
    yield call(mediaService.postMediaBulk, payload)
    yield put({
      type: types.POST_MEDIA_BULK_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_MEDIA_BULK_ERROR, payload: error })
  }
}

function* cloneMedia({ payload: data }) {
  try {
    yield call(mediaService.cloneMedia, data)
    yield put({
      type: types.CLONE_MEDIA_ITEM_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    if (error.code === 409) {
      yield put({ type: types.CLONE_MEDIA_ITEM_ERROR, payload: error })
    } else {
      const { errors, message: responseMessage } = error
      const message =
        errors?.length > 0 ? errors[errors.length - 1] : responseMessage
      yield put({ type: types.CLONE_MEDIA_ITEM_ERROR, payload: { message } })
    }
  }
}

function* getMediaAssociations({ payload: { id, params } }) {
  try {
    const response = yield call(mediaService.getMediaAssociations, id, params)

    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_MEDIA_ASSOCIATIONS_SUCCESS,
      payload: {
        response: response.data,
        meta: modifiedMeta
      }
    })
  } catch (error) {
    yield put({ type: types.GET_MEDIA_ASSOCIATIONS_ERROR, payload: error })
  }
}

function* getSftpFolders({ data }) {
  try {
    const response = yield call(mediaService.getSftpFoldersList, data)
    yield put({
      type: types.GET_SFTP_FOLDER_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_SFTP_FOLDER_ERROR, payload: error })
  }
}

// remove after full implementation of RTK Query for Media Library page
function* invalidateMediaTags() {
  yield put(mediaApi.util.invalidateTags([apiTags.mediaLibrary]))
}

const mediaSaga = {
  addMedia,
  getItems,
  getItemById,
  getMediaItemById,
  putMediaItemById,
  cloneMediaAndUpdate,
  getPreference,
  putPreference,
  getMediaPreview,
  generateMediaPreview,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  getFeatureMediaItems,
  putOrganizeFeatureMediaItems,
  postFavoriteMedia,
  getMediaCapAlertWorker,
  addMediaToPlaylists,
  deleteSelectedMedia,
  shareMedia,
  publishDesign,
  copyMedia,
  copyDesignToSystem,
  addMediaToPlaylist,
  postMediaBulk,
  cloneMedia,
  getMediaIpawsAlert,
  getMediaAssociations,
  getSftpFolders,
  invalidateMediaTags
}

export default mediaSaga
