import { _flatten, _isEmpty, _isEqual } from 'utils/lodash'
import * as selectUtils from './select'

export const getUpdatedClientSettings = ({ currentData, backendData }) => {
  const res = {}
  // to compare empty strings and null/undefined values
  const shallowComparisonAllowed = [
    'address2',
    'phoneNo2',
    'zohoCrmLink',
    'expirationDate'
  ]

  const compareExceptions = {
    bandwidthPackageId: 'bandwidthPackageId',
    clientFeature: 'clientFeature',
    group: 'group',
    tag: 'tag'
  }

  Object.entries(currentData).forEach(([key, value]) => {
    if (key === compareExceptions.bandwidthPackageId) {
      if (backendData?.bandwidthPackage?.id === currentData[key]) {
        return
      }

      res[key] = value
    }

    if (key === compareExceptions.clientFeature) {
      const backendFeatureIds = backendData?.feature
        .map(feature => feature.id)
        .sort()
      const currentFeatureIds = value?.sort()

      if (
        JSON.stringify(backendFeatureIds) === JSON.stringify(currentFeatureIds)
      ) {
        return
      }
      res[key] = value
    }

    if (
      Array.isArray(backendData[key]) ||
      [compareExceptions.group, compareExceptions.tag].includes(key)
    ) {
      const backendIds = backendData[key]?.map(({ id }) => id).sort()
      const currentIds = value?.map(({ id }) => id).sort()

      if (
        JSON.stringify(backendIds) === JSON.stringify(currentIds) ||
        !value ||
        (_isEmpty(backendData[key]) && _isEmpty(value))
      ) {
        return
      }
    }

    if (
      shallowComparisonAllowed.includes(key) &&
      !!value === !!backendData[key]
    ) {
      return
    }

    if (backendData.hasOwnProperty(key) && !_isEqual(value, backendData[key])) {
      res[key] = value
    }
  })

  return res
}

const prepareEntityData = ({ entityData = [], selectedIds = [] }) => {
  const data = _flatten(entityData)
  const entityDataObj = {}
  const commonEntityData = []

  data.forEach(entity => {
    if (!entityDataObj.hasOwnProperty(entity.title)) {
      entityDataObj[entity.title] = 1
    } else {
      entityDataObj[entity.title] = entityDataObj[entity.title] + 1
    }
  })

  Object.entries(entityDataObj).forEach(([key, value]) => {
    if (selectedIds.length === value) {
      commonEntityData.push(key)
    }
  })

  return Array.from(new Set(data.map(JSON.stringify)))
    .map(JSON.parse)
    .filter(entity => commonEntityData.includes(entity.title))
}

export const getCommonBulkData = ({ data, selectedIds }) => {
  const tagsArr = []
  const groupsArr = []

  data.forEach(({ id, tag, tags, group }) => {
    if (selectedIds.includes(id)) {
      if (tag || tags) {
        tag ? tagsArr.push(tag) : tagsArr.push(tags)
      }
      group && groupsArr.push(group)
    }
  })

  const uniqueTags = prepareEntityData({ entityData: tagsArr, selectedIds })
  const uniqueGroups = prepareEntityData({ entityData: groupsArr, selectedIds })

  const convertedTags = uniqueTags.map(tag => selectUtils.tagToChipObj(tag))
  const convertedGroups = uniqueGroups.map(group =>
    selectUtils.toGroupChipObj(group)
  )

  return { tags: convertedTags, groups: convertedGroups }
}
