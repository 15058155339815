import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'

const useStyles = makeStyles(({ colors, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${spacing(1)}px`,
    color: colors.warning
  }
}))

const EmptySetIcon = ({
  tooltipTitle = "Doesn't contain media",
  tooltipPlacement = 'bottom',
  rootClassName
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const icon = (
    <i
      className={classNames(
        'fa-solid fa-empty-set',
        classes.root,
        rootClassName
      )}
    />
  )

  if (!tooltipTitle) {
    return icon
  }

  return (
    <Tooltip arrow placement={tooltipPlacement} title={t(tooltipTitle)}>
      {icon}
    </Tooltip>
  )
}

export default EmptySetIcon
