import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

import Scrollbars from 'components/Scrollbars'

const useStyles = makeStyles(({ palette, type }) => ({
  root: {
    width: 450,
    height: 410
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: palette[type].dialog.header.background,
    borderBottom: `solid 1px ${palette[type].dialog.border}`,
    padding: '15px 20px'
  },
  content: {
    flexGrow: 1,
    padding: '10px 20px 5px'
  },
  actionBar: {
    background: palette[type].dialog.header.background,
    borderTop: `solid 1px ${palette[type].dialog.border}`,
    padding: '24px 16px',
    margin: '0px'
  },
  scrollbarWrapper: {
    flexGrow: 1,
    padding: 0
  },
  contentWrapper: {
    margin: '10px 20px',
    maxWidth: 410
  }
}))

const BulkEditPopupContent = ({
  headerContent,
  content,
  footerContent,
  rootClassName,
  contentMaxHeight
}) => {
  const classes = useStyles()

  const renderContent = useMemo(
    () =>
      contentMaxHeight ? (
        <Grid className={classes.scrollbarWrapper}>
          <Scrollbars autoHeight autoHeightMax={contentMaxHeight}>
            <div className={classes.contentWrapper}>{content}</div>
          </Scrollbars>
        </Grid>
      ) : (
        <Grid className={classes.content}>{content}</Grid>
      ),
    [
      content,
      contentMaxHeight,
      classes.scrollbarWrapper,
      classes.contentWrapper,
      classes.content
    ]
  )

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classNames(classes.root, rootClassName)}
    >
      <Grid className={classes.header}>{headerContent}</Grid>
      {renderContent}
      <Grid container className={classes.actionBar} justifyContent="flex-end">
        {footerContent}
      </Grid>
    </Grid>
  )
}

export default BulkEditPopupContent
