import { createAppApi } from 'services/api'

export const office365Api = createAppApi('office365Query', {
  endpoints: builder => ({
    getOffice365Files: builder.query({
      tag: ['office365Files'],
      query: params => {
        return {
          url: `/office365/myFiles`,
          params
        }
      }
    }),
    getOffice365ItemInfo: builder.query({
      tag: ['office365FilesForBreadcrumbs'],
      query: params => {
        return {
          url: `/office365/itemInfo`,
          params
        }
      }
    })
  })
})

export const {
  useGetOffice365FilesQuery,
  useLazyGetOffice365ItemInfoQuery
} = office365Api
