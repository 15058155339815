import * as types from './index'

const postDesignGallery = ({ data }) => ({
  type: types.POST_DESIGN_GALLERY,
  data
})

const putDesignGallery = payload => ({
  type: types.PUT_DESIGN_GALLERY,
  payload
})

const getDesignGallery = (id, isEdit = false) => ({
  type: types.GET_DESIGN_GALLERY,
  data: id,
  isEdit
})

const clearDesignGallery = meta => ({
  type: types.CLEAR_DESIGN_GALLERY,
  meta
})

const clearDesignGalleryStatus = () => ({
  type: types.CLEAR_DESIGN_GALLERY_STATUS
})

const putDesignGalleryFilter = data => {
  return {
    type: types.PUT_DESIGN_GALLERY_FILTER,
    payload: data
  }
}

// images

const getPatterns = params => ({
  type: types.GET_PATTERNS,
  data: params
})

const getBackgroundImages = params => ({
  type: types.GET_BACKGROUND_IMAGES,
  data: params
})

const getPexelBackgroundImages = (
  { query, photosPage, perPage, changeImages = false },
  config
) => ({
  type: types.GET_PEXEL_BACKGROUND_IMAGES,
  data: { query, photosPage, perPage, changeImages },
  meta: config
})

const setSelectedBg = id => ({
  type: types.SET_SELECTED_BG,
  data: { id }
})

const removeSelectedBg = () => ({
  type: types.REMOVE_SELECTED_BG_SUCCESS
})

// icons

const getShapes = params => ({
  type: types.GET_SHAPES,
  data: params
})

const getObjects = params => ({
  type: types.GET_OBJECTS,
  data: params
})

const getFonts = params => ({
  type: types.GET_DESIGN_GALLERY_FONTS,
  payload: params
})

const getIcons = params => ({
  type: types.GET_ICONS,
  data: params
})

const clearIcons = () => ({
  type: types.CLEAR_ICONS
})

const getIconsGroup = params => ({
  type: types.GET_ICONS_GROUP,
  data: params
})

const getEmojis = params => ({
  type: types.GET_EMOJIS,
  data: params
})

const getStockImages = params => ({
  type: types.GET_STOCK_IMAGES,
  params
})

const getDesigns = data => ({
  type: types.GET_DESIGNS,
  data
})

const getStockDesigns = id => ({
  type: types.GET_STOCK_DESIGNS,
  data: id
})

const getFavoriteDesign = id => ({
  type: types.GET_FAVORITE_DESIGNS,
  data: id
})

const getSharedDesigns = id => ({
  type: types.GET_SHARED_DESIGNS,
  data: id
})

const setOpenLeftSidebar = data => ({
  type: types.SET_OPEN_LEFT_SIDEBAR,
  data
})

const setOpenRightSidebar = data => ({
  type: types.SET_OPEN_RIGHT_SIDEBAR,
  data
})

const setObjectChanged = () => ({
  type: types.DG_OBJECT_CHANGED
})

const clearDesignGallerySidebar = () => ({
  type: types.CLEAR_DG_SIDEBAR
})

const setSelectedDesign = id => ({
  type: types.SET_SELECTED_DESIGN,
  payload: id
})

const setMissedFonts = fonts => ({
  type: types.SET_MISSED_FONTS,
  payload: fonts
})

const getRoyaltyImages = (
  { query, royaltyImagesPage, perPage, orientation },
  appConfig
) => ({
  type: types.GET_ROYALTY_IMAGES,
  data: { query, royaltyImagesPage, perPage, orientation },
  appConfig
})

export {
  postDesignGallery,
  putDesignGallery,
  getDesignGallery,
  clearDesignGallery,
  clearDesignGalleryStatus,
  putDesignGalleryFilter,
  setOpenLeftSidebar,
  setOpenRightSidebar,
  // images
  getPatterns,
  getBackgroundImages,
  getObjects,
  getShapes,
  getIcons,
  getEmojis,
  getPexelBackgroundImages,
  setSelectedBg,
  removeSelectedBg,
  getStockImages,
  getRoyaltyImages,
  getIconsGroup,
  clearIcons,
  // designs
  getDesigns,
  getStockDesigns,
  getSharedDesigns,
  setObjectChanged,
  getFonts,
  clearDesignGallerySidebar,
  setSelectedDesign,
  setMissedFonts,
  getFavoriteDesign
}
