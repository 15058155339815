import React, { useMemo } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

import { workingDays as scheduleWorkingDays } from 'constants/report'
import { WhiteButton } from 'components/Buttons'
import { colors } from 'theme'

const useStyles = makeStyles(({ palette, type }) => ({
  daySelectorContainer: {
    margin: '0 auto',
    display: 'grid',
    grid: '1fr / repeat(7,32px)',
    gap: '5px'
  },
  daySelector: {
    background: palette[type].formControls.select.background,
    width: 32,
    minWidth: 0,
    height: 32,
    borderRadius: '50%',
    border: `1px solid ${palette[type].formControls.input.border} !important`,
    padding: 0,

    '&:before': {
      background: colors.highlight
    }
  },
  daySelected: {
    background: colors.highlight,
    color: '#fff !important'
  },
  daySelectorLabel: {
    fontWeight: 'bold',
    lineHeight: 32
  }
}))

const Workdays = ({ name, workingDays, setFieldValue, xs = 12 }) => {
  const classes = useStyles()

  const renderWorkingDays = useMemo(() => {
    return [
      scheduleWorkingDays.Sun,
      scheduleWorkingDays.Mon,
      scheduleWorkingDays.Tue,
      scheduleWorkingDays.Wed,
      scheduleWorkingDays.Thu,
      scheduleWorkingDays.Fri,
      scheduleWorkingDays.Sat
    ].map(day => (
      <WhiteButton
        key={day}
        classes={{
          root: classNames(classes.daySelector, {
            [classes.daySelected]: workingDays?.includes(day)
          }),
          label: classes.daySelectorLabel
        }}
        onClick={() => {
          const newWorkingDays = workingDays?.includes(day)
            ? workingDays.filter(value => day !== value)
            : [...workingDays, day]

          if (newWorkingDays.length) {
            setFieldValue(name, newWorkingDays)
          }
        }}
      >
        {day[0]}
      </WhiteButton>
    ))
  }, [workingDays, name, setFieldValue, classes])

  return (
    <Grid container item xs={xs} className={classes.daySelectorContainer}>
      {renderWorkingDays}
    </Grid>
  )
}

export default Workdays
