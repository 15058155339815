import { errorHandler } from 'utils'
import api from './api'

export async function loginTableauService(data) {
  try {
    const response = await api({
      method: 'POST',
      url: `/oauth2/tableau`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function logoutTableauService(tokenId, data) {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/oauth2/${tokenId}`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function getTableauViews(params) {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/tableau/views`,
      params
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export async function getTableauWorkbooks(params) {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/tableau/workbooks`,
      params
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}
