import api from './api'

import { errorHandler } from '../utils'

export const approveContent = async (content, id, data) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/${content}/${id}/Approve`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const rejectContent = async (content, id, data) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/${content}/${id}/Disapprove`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const rejectAndDeleteContent = async (content, id, data) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/${content}/${id}/Reject`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}
