import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useActions } from 'hooks/index'
import { getWhiteLabel } from 'actions/whiteLabelActions'
import { reducerUtils, whiteLabelUtils } from 'utils/index'

const overlayStyles = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1111,
  background: '#fff'
}

const WhiteLabelProvider = () => {
  const [whiteLabelReducer] = useSelector(state => [state.whiteLabel])
  const getWhiteLabelAction = useActions(getWhiteLabel)
  const { pathname } = useLocation()

  const [isLoading, setIsLoading] = useState(true)

  const checkWhiteLabelReducer = useCallback(() => {
    reducerUtils.checkReducer(whiteLabelReducer, getWhiteLabelAction)
  }, [whiteLabelReducer, getWhiteLabelAction])
  const handleWhiteLabelReducerResponse = useCallback(
    data => {
      whiteLabelUtils.setInfoToLocalStorage(data)
      setIsLoading(false)
    },
    [setIsLoading]
  )
  const handleWhiteLabelReducerError = useCallback(() => {
    whiteLabelUtils.setInfoToLocalStorage({})
    setIsLoading(false)
  }, [setIsLoading])
  const onWhiteLabelReducerChange = useCallback(() => {
    reducerUtils.parse(
      whiteLabelReducer,
      handleWhiteLabelReducerResponse,
      handleWhiteLabelReducerError
    )
  }, [
    whiteLabelReducer,
    handleWhiteLabelReducerResponse,
    handleWhiteLabelReducerError
  ])
  useEffect(checkWhiteLabelReducer, [checkWhiteLabelReducer])
  useEffect(onWhiteLabelReducerChange, [onWhiteLabelReducerChange])

  const isSystemLogin = useMemo(() => pathname === '/system/sign-in', [
    pathname
  ])

  return isSystemLogin && isLoading ? <div style={overlayStyles} /> : <div />
}

export default WhiteLabelProvider
