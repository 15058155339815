import React from 'react'

import { Text } from 'components/Typography'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  code: {
    position: 'absolute',
    top: '13%',
    fontFamily: 'Montserrat',
    fontWeight: 100,
    fontSize: '480px',
    lineHeight: '585px',
    background:
      'linear-gradient(180deg, rgba(111, 207, 244, 0.2) 18.54%, rgba(111, 207, 244, 0.1) 46.62%, rgba(111, 207, 244, 0) 78.29%)',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    color: 'transparent',
    height: 'fit-content'
  }
}))

const CodeText = () => {
  const classes = useStyles()
  const { code = 400 } = useUrlSearchParams()

  return <Text rootClassName={classes.code}>{code}</Text>
}

export default CodeText
