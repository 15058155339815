import { withStyles, TableCell } from '@material-ui/core'
import classNames from 'classnames'

const styles = ({ palette, type }) => ({
  root: {
    color: palette[type].tableLibrary.body.cell.color,
    borderBottomColor: palette[type].tableLibrary.body.cell.border,
    padding: '0 5px'
  },
  paddingMedium: {
    padding: '0 10px'
  },
  paddingBig: {
    padding: '0 15px'
  }
})

const TableLibraryCell = ({
  classes,
  children,
  className,
  rootClassName,
  paddingVariant,
  ...props
}) => {
  return (
    <TableCell
      className={classNames(classes.root, className, rootClassName, {
        [classes.paddingMedium]: paddingVariant === 'medium',
        [classes.paddingBig]: paddingVariant === 'big'
      })}
      {...props}
    >
      {children}
    </TableCell>
  )
}

export default withStyles(styles)(TableLibraryCell)
