import React, { useCallback, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { FormControlInput } from 'components/Form'
import { BlueButton } from 'components/Buttons'
import { ipRegExp } from 'constants/regExp'
import { TableLibraryRowActionButton } from 'components/TableLibrary'
import { TextWithTooltip } from 'components/Typography'

const styles = ({ palette, type }) => ({
  root: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  adornmentBtn: {
    fontSize: '18px'
  },
  ip: {
    color: palette[type].formControls.input.color
  },
  edit: {
    color: '#0a84c8'
  },
  delete: {
    color: '#d0021b'
  },
  icon: {
    marginRight: '20px',
    color: palette[type].formControls.input.color
  },
  controlInput: {
    flexGrow: 2,
    marginRight: '10px'
  }
})

const UrlCard = ({
  classes,
  index,
  t,
  values,
  onDelete = f => f,
  onUpdate = f => f
}) => {
  const [IP, setIP] = useState(values)
  const [error, setError] = useState('')
  const [isActiveEdit, toggleActiveEdit] = useState(false)

  const handleClickDelete = useCallback(() => {
    onDelete(index)
  }, [index, onDelete])

  const handleChangeIP = useCallback(({ target: { value } }) => {
    setIP(value)
  }, [])

  const handleClickEdit = useCallback(() => {
    if (isActiveEdit) {
      if (values.includes(IP) && IP !== values[index])
        return setError('URL already exist')

      if (IP && IP.match(ipRegExp)) {
        toggleActiveEdit(false)
        setError('')
        onUpdate({
          id: index,
          title: `Title_${IP}`,
          ip: IP
        })
      } else {
        setError('Please enter valid IP Address')
      }
    } else {
      toggleActiveEdit(true)
    }
  }, [IP, index, isActiveEdit, onUpdate, values])

  return (
    <div className={classes.root}>
      {isActiveEdit ? (
        <FormControlInput
          marginBottom={false}
          value={IP}
          handleChange={handleChangeIP}
          touched
          error={error}
          formControlContainerClass={classes.controlInput}
        />
      ) : (
        <div className={classes.wrapper}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextWithTooltip maxWidth={300} rootClassName={classes.ip}>
              {IP}
            </TextWithTooltip>
          </div>
        </div>
      )}
      {isActiveEdit ? (
        <BlueButton
          className={classes.submit}
          type="submit"
          onClick={handleClickEdit}
          iconClassName="fa-regular fa-circle-plus"
        >
          {t('Update')}
        </BlueButton>
      ) : (
        <TableLibraryRowActionButton
          actionLinks={[
            {
              label: t('Edit'),
              clickAction: handleClickEdit
            },
            { divider: true },
            {
              label: t('Delete'),
              icon: 'fa-regular fa-trash-can',
              clickAction: handleClickDelete
            }
          ]}
          position="bottom right"
        />
      )}
    </div>
  )
}

export default withTranslation('translations')(withStyles(styles)(UrlCard))
