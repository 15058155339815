import * as Yup from 'yup'

import { requiredField, numberField } from 'constants/validationMessages'
import { CUSTOM_RECEIVER_OPTION } from 'constants/emailNotification'

export const validationSchema = t =>
  Yup.object().shape({
    from: Yup.number(t(numberField)).required(t(requiredField)),
    to: Yup.string().required(t(requiredField)),
    userId: Yup.mixed().when('to', {
      is: val => val < 0,
      then: Yup.number(t(numberField)).required(t(requiredField)),
      otherwise: Yup.string().nullable()
    }),
    specificEmail: Yup.string().when('to', {
      is: val => val === CUSTOM_RECEIVER_OPTION,
      then: Yup.string().required(t(requiredField)),
      otherwise: Yup.string().nullable()
    }),
    criteriaRule: Yup.array().of(Yup.string()),
    emailTemplate: Yup.object().required(t(requiredField)),
    eventType: Yup.string().required(t(requiredField)),
    entityTableName: Yup.string().required(t(requiredField)),
    conditions: Yup.array().of(
      Yup.object().shape({
        columnName: Yup.string().required(t(requiredField)),
        operator: Yup.string().required(t(requiredField)),
        value: Yup.mixed().required(t(requiredField))
      })
    )
  })

export const saveDialogValidationnnSchema = t =>
  Yup.object().shape({
    name: Yup.string().restrictedCharacters().required(t(requiredField)),
    status: Yup.string().oneOf(['Active', 'Inactive'])
  })

export const initialConditions = [
  {
    columnName: '',
    operator: '',
    value: ''
  }
]
