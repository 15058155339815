import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from '@reduxjs/toolkit'
import { withStyles } from '@material-ui/core'

import { getDeviceNocGeneralItems } from 'actions/deviceNocActions'
import DevicePreviewModal from 'components/Modal/DevicePreviewModal'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import queryParamsHelper from 'utils/queryParamsHelper'
import TableRow from './TableRow'
import BaseTable from 'components/TableLibrary/BaseTable'
import Scrollbars from 'components/Scrollbars'
import handleBottomScroll from 'utils/handleBottomScroll'
import { LibraryLoader } from 'components/Loaders'
import { useDebouncedCallback } from 'use-debounce'

const styles = theme => {
  const { typography, type } = theme
  return {
    name: {
      ...typography.darkAccent[type]
    },
    label: {
      ...typography.subtitle[type]
    },
    aliasWrap: {
      display: 'flex'
    },
    aliasWrapContent: {
      minWidth: '190px'
    },
    dateTimeView: {
      cursor: 'pointer',
      position: 'relative'
    },
    deviceNameWrap: {
      display: 'flex',
      width: 'max-content'
    },
    ipText: {
      ...typography.darkAccent[type]
    },
    lanText: {
      ...typography.lightAccent[type]
    },
    tableRowRoot: {
      height: '4.4rem'
    },
    deviceIconWrapper: {
      width: '2.25rem',
      height: '2.25rem',

      '& img': {
        width: '2.25rem'
      }
    },
    scrollbarRoot: {
      '@supports ( -moz-appearance:none )': {
        '& > div': {
          marginBottom: '0px !important'
        }
      }
    }
  }
}
const defaultColumnWidth = {
  alias: 150,
  lastCheckInUTC: 200,
  status: 170,
  lanIP: 200,
  networkConnectivity: 200,
  synchronousConnection: 200
}

const LIBRARY_EXTRA_PAGE_HEIGHT = 350
const PUBLIC_EXTRA_PAGE_HEIGHT = 150
const ROW_HEIGHT = 72

const DeviceOverview = ({
  t,
  classes,
  getDeviceNocGeneralItems,
  library,
  customColumnWidth = null,
  searchParams,
  filterParams,
  fetcher: fetchItems,
  itemReducer,
  isPublic,
  parentClasses,
  autoScroll,
  isFetchAllowed,
  preferenceParams,
  preferenceActions,
  columns,
  staticColumns
}) => {
  const [data, setData] = useState([])
  const [dateTimeFromNow, toggleFormat] = useState(true)
  const [initialized, setInitialized] = useState(false)
  const [pageLimit, setPageLimit] = useState(0)

  const scrollInterval = useRef()
  const scrollRef = useRef()

  const role = useUserRole()

  const { response, isFetching, meta } = useMemo(
    () => (isPublic ? itemReducer : library),
    [isPublic, itemReducer, library]
  )

  const getPageLimit = useCallback(() => {
    let height = 400
    if (scrollRef.current) {
      height =
        window.innerHeight -
        (isPublic ? PUBLIC_EXTRA_PAGE_HEIGHT : LIBRARY_EXTRA_PAGE_HEIGHT)
    }

    return Math.ceil(height / ROW_HEIGHT) + 1
  }, [isPublic])

  const fetcher = useCallback(
    (params = {}) => {
      const { filters, autoScroll, ...restPreferenceParams } = preferenceParams

      fetchItems
        ? fetchItems(
            queryParamsHelper({
              ...restPreferenceParams,
              ...searchParams,
              ...filterParams,
              limit: pageLimit,
              ...params
            })
          )
        : getDeviceNocGeneralItems(
            queryParamsHelper({
              ...preferenceParams,
              ...searchParams,
              ...filterParams,
              limit: pageLimit,
              ...params
            })
          )
    },
    [
      getDeviceNocGeneralItems,
      searchParams,
      filterParams,
      preferenceParams,
      fetchItems,
      pageLimit
    ]
  )

  const debounceFetcher = useDebouncedCallback(fetcher, 500)

  const handleReset = useCallback(() => {
    scrollRef.current && scrollRef.current.scrollToTop()
    debounceFetcher.callback()
  }, [debounceFetcher])

  const handleResizeWindow = useCallback(() => {
    const limit = getPageLimit()
    setPageLimit(limit)
  }, [getPageLimit])

  useEffect(() => {
    handleResizeWindow()
    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
    // eslint-disable-next-line
  }, [handleResizeWindow])

  useEffect(
    () => {
      if (isFetchAllowed && pageLimit) {
        setInitialized(false)
        handleReset()
      }
    },
    // eslint-disable-next-line
    [isFetchAllowed, filterParams, pageLimit]
  )

  useEffect(() => {
    if (!isFetching && response && response.length) {
      if (meta.currentPage > 1) {
        setData(_data => [..._data, ...response])
      } else {
        setData(response)
        setInitialized(true)
      }
    }
    // eslint-disable-next-line
  }, [response, role.system])

  useEffect(() => {
    if (meta.lastPage > 0 && meta.currentPage === meta.lastPage) {
      setTimeout(() => {
        handleReset()
      }, [30000])
    }
    if (meta.currentPage === 1 && autoScroll) {
      handleFetchMore()
    }
    // eslint-disable-next-line
  }, [meta])

  const handleAutoScroll = useCallback(() => {
    if (scrollRef.current) {
      const { getClientHeight, getScrollTop, scrollToTop } = scrollRef.current
      const clientHeight = getClientHeight()
      const scrollTop = getScrollTop() || 0

      scrollToTop(scrollTop + clientHeight)
    }
  }, [])

  useEffect(() => {
    if (autoScroll && !scrollInterval.current) {
      handleFetchMore()
      scrollInterval.current = setInterval(handleAutoScroll, 10000)
    } else if (!autoScroll && scrollInterval.current) {
      clearInterval(scrollInterval.current)
      scrollInterval.current = null
    }
    // eslint-disable-next-line
  }, [autoScroll])

  const handleChangeFormat = () => {
    toggleFormat(!dateTimeFromNow)
  }

  const handleFetchMore = () => {
    if (!isFetching && meta.currentPage + 1 <= meta.lastPage) {
      fetcher({
        page: meta.currentPage + 1
      })
    }
  }

  const columnWidth = useMemo(
    () => (customColumnWidth ? customColumnWidth : defaultColumnWidth),
    [customColumnWidth]
  )

  const noHideColumns = useMemo(() => {
    const cols = columns.filter(({ display }) => display)

    if (cols.length <= 4) {
      return cols.map(({ id }) => id)
    }

    return []
  }, [columns])

  return (
    <>
      <Scrollbars
        ref={scrollRef}
        onUpdate={handleBottomScroll(
          handleFetchMore,
          autoScroll ? scrollRef.current?.getClientHeight() || 50 : 50
        )}
        autoHeight
        autoHeightMax={`calc(100vh - ${
          isPublic ? PUBLIC_EXTRA_PAGE_HEIGHT : LIBRARY_EXTRA_PAGE_HEIGHT
        }px)`}
        renderHorizontalScroll
        className={classes.scrollbarRoot}
      >
        <BaseTable
          meta={meta}
          loading={!initialized && isFetching}
          isCustomLoading={true}
          fetcher={fetcher}
          columns={columns}
          noType={false}
          preferenceActions={preferenceActions}
          selectedListClearAllowed={false}
          placeholderMessage="No Results Found"
          columnWidth={columnWidth}
          sortOptions={preferenceParams}
          hasDelete={false}
          noHideColumns={noHideColumns}
          tableRootClass={parentClasses.overviewTableRoot}
          tableFooterWrapClasName={parentClasses.overviewTableFooterWrap}
          paginationClasses={parentClasses.overviewPagination}
          tablePaperWrapperClass={parentClasses.overviewTablePaperWrapper}
          tableMaxWidth={isPublic ? 1600 : undefined}
          staticColumns={staticColumns}
          hideTableFooter
          isStickyHeader
        >
          {data.map((row, index) => (
            <TableRow
              key={row.id}
              row={row}
              columns={columns}
              classes={classes}
              t={t}
              role={role}
              dateTimeFromNow={dateTimeFromNow}
              handleChangeFormat={handleChangeFormat}
              isPublic={isPublic}
            />
          ))}
        </BaseTable>
        {initialized && isFetching && (
          <LibraryLoader rowCount={1} footerHeight={0} hideHeader />
        )}
      </Scrollbars>
      <DevicePreviewModal />
    </>
  )
}

DeviceOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  getDeviceNocGeneralItems: PropTypes.func,
  library: PropTypes.object
}

const mapStateToProps = ({ deviceNoc }) => ({
  library: deviceNoc.general
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceNocGeneralItems
    },
    dispatch
  )

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DeviceOverview)
