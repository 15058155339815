import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { getCRUDEndpoints } from 'utils/apiUtils'

export const emailTemplateApi = createAppApi('emailTemplateQuery', {
  tagTypes: [apiTags.emailTemplate],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      name: 'EmailTemplate',
      url: '/customEmailTemplate',
      builder,
      tags: [apiTags.emailTemplate]
    })
  })
})

export const {
  useLazyGetEmailTemplatesQuery,
  usePostEmailTemplateMutation,
  usePutEmailTemplateMutation,
  useGetEmailTemplateQuery,
  useDeleteEmailTemplateMutation
} = emailTemplateApi
