import moment from 'moment'

const getDateRangeInfo = ({ startDate, endDate }) => ({
  startDate: startDate && startDate.toDate ? startDate.toDate() : startDate,
  endDate: endDate?.toDate ? endDate.toDate() : endDate,
  minStartDate: new Date(),
  maxStartDate: endDate
    ? moment(endDate).subtract(0, 'days').toDate()
    : endDate,
  minEndDate: startDate
    ? moment(startDate).add(0, 'days').toDate()
    : moment().add(0, 'days').toDate()
})

export default getDateRangeInfo
