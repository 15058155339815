import { fork } from 'redux-saga/effects'
import {
  watchUser,
  watchUserLogout,
  watchUpdateToken,
  watchOktaAuthentication,
  watchReset,
  watchImpersonateUser,
  watchMedia,
  watchPlaylist,
  watchTemplate,
  watchSchedule,
  watchDevice,
  watchReport,
  watchClient,
  watchUsers,
  watchDashboard,
  watchFeedback,
  watchGroups,
  watchFonts,
  watchConfig,
  watchClientPackage,
  watchHTMLContent,
  watchClientSettings,
  watchDevicePackage,
  watchBandwidthPackage,
  watchEmailTemplate,
  watchHelp,
  watchContentAppHelp,
  watchSettings,
  watchAlert,
  watchRole,
  watchPreference,
  watchWhiteLabel,
  watchSmartPlaylist,
  watchQuotes,
  watchDesignGallery,
  watchResellerClients,
  watchResellerClientUsers,
  watchIPProvidersSaga,
  watchMenuMaker,
  watchEmailNotification,
  watchCalendarAggregatorSaga,
  watchDeviceNoc,
  watchMenuDesign,
  watchPostHogSaga,
  watchGoogleDocumentSaga
} from './watchers'
import clientWatcher from 'sagas/new/client'
import deviceWatcher from 'sagas/new/device'
import authWatcher from 'sagas/new/auth'
import searchWatcher from 'sagas/new/search'
import tableauAuthWatcher from './tableauSaga'
import watchPublic from './public/watchers'
import oauth2Watcher from './new/oauth2'
import mediaFeatures from './new/mediaFeatures'

export default function* rootSaga() {
  yield fork(watchOktaAuthentication)
  yield fork(watchUser)
  yield fork(watchUserLogout)
  yield fork(watchUpdateToken)
  yield fork(watchReset)
  yield fork(watchImpersonateUser)
  yield fork(watchMedia)
  yield fork(watchPlaylist)
  yield fork(watchTemplate)
  yield fork(watchSchedule)
  yield fork(watchDevice)
  yield fork(watchReport)
  yield fork(watchClient)
  yield fork(watchUsers)
  yield fork(watchFeedback)
  yield fork(watchDashboard)
  yield fork(watchGroups)
  yield fork(watchConfig)
  yield fork(watchFonts)
  yield fork(watchClientSettings)
  yield fork(watchSettings)
  yield fork(watchHTMLContent)
  yield fork(watchClientPackage)
  yield fork(watchDevicePackage)
  yield fork(watchBandwidthPackage)
  yield fork(watchEmailTemplate)
  yield fork(watchHelp)
  yield fork(watchContentAppHelp)
  yield fork(watchRole)
  yield fork(watchPreference)
  yield fork(watchWhiteLabel)
  yield fork(watchQuotes)
  yield fork(watchSmartPlaylist)
  yield fork(watchAlert)
  yield fork(watchDesignGallery)
  yield fork(watchResellerClients)
  yield fork(watchResellerClientUsers)
  yield fork(watchIPProvidersSaga)
  yield fork(watchMenuMaker)
  yield fork(clientWatcher)
  yield fork(deviceWatcher)
  yield fork(authWatcher)
  yield fork(searchWatcher)
  yield fork(tableauAuthWatcher)
  yield fork(watchEmailNotification)
  yield fork(watchCalendarAggregatorSaga)
  yield fork(watchDeviceNoc)
  yield fork(watchPublic)
  yield fork(watchMenuDesign)
  yield fork(watchPostHogSaga)
  yield fork(watchGoogleDocumentSaga)
  yield fork(oauth2Watcher)
  yield fork(mediaFeatures)
}
