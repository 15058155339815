import Color from 'color'

export function createRgbaObject(r, g, b, a) {
  return { r, g, b, a }
}

export function rgbaObjectToString({ r, g, b, a }) {
  return `rgba(${r}, ${g}, ${b}, ${a})`
}

const getColorArrayFromRgb = (rgbString = 'rgb(255, 255, 255)') => {
  try {
    return Color(rgbString).rgb().array().slice(0, 3)
  } catch (e) {
    return [255, 255, 255]
  }
}

export const getLightenColorFromRgb = (rgbString, ratio = 0.2) => {
  const rgbArray = getColorArrayFromRgb(rgbString)
  return Color(rgbArray).lighten(ratio).string()
}

export const getDarkenColorFromRgb = (rgbString, ratio = 0.2) => {
  const rgbArray = getColorArrayFromRgb(rgbString)
  return Color(rgbArray).darken(ratio).string()
}

// minContrastRatio [1-21]
export const getContrastTextFromRgb = (rgbString, minContrastRatio = 4.1) => {
  const rgbArray = getColorArrayFromRgb(rgbString)
  const color = Color(rgbArray)
  return color.contrast(Color('#fff')) > minContrastRatio ? '#fff' : '#2C2D3A'
}

export const getRgbaFromRgb = (rgbString, opacity = 1) => {
  try {
    return `rgba(${[...getColorArrayFromRgb(rgbString), opacity].join(',')})`
  } catch (e) {
    const fallbackColor = `rgba(1, 87, 155, ${opacity})`
    console.error(
      `Unable to parse "${rgbString}". Fallback "${fallbackColor}" color was used.`
    )
    return fallbackColor
  }
}

export const getColorBasedOnTheme = (rgbString, theme) => {
  let color

  try {
    color = Color(rgbString)
  } catch (e) {
    color = Color('rgb(255, 255, 255)')
  }

  if (theme === 'dark') {
    return color.isDark() ? getLightenColorFromRgb(rgbString, 0.3) : color
  } else {
    return color.isLight() ? getDarkenColorFromRgb(rgbString, 0.3) : color
  }
}

export const getRandomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`
