import { createSelector } from 'reselect'

export function userPutSelector({ user }) {
  return user.put
}
export const userPutSelectorMemoized = createSelector(
  userPutSelector,
  put => put
)

export function userDetailsSelector({ user }) {
  return user.details
}

export const userDetailsSelectorMemoized = createSelector(
  userDetailsSelector,
  details => details
)

export const userRegionalSetting = createSelector(
  userDetailsSelector,
  details => details?.response?.regionalSetting ?? {}
)

export function _originalUserDetailsSelector({ user }) {
  return user.originalUser
}

export const originalUserDetailsSelector = createSelector(
  _originalUserDetailsSelector,
  originalUser => originalUser
)

export function userProfileSelector({ user }) {
  return user.profile
}

export function _userClientDetailsSelector(state) {
  const details = userDetailsSelector(state)
  return details.response?.client
}

export const userClientDetailsSelector = createSelector(
  _userClientDetailsSelector,
  client => client
)

export function regionalSettingPutSelector({ user }) {
  return user.putRegionalSetting
}

export const regionalSettingPutMemoized = createSelector(
  regionalSettingPutSelector,
  put => put
)
