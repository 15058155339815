import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import PopupButton from './PopupButton'
import PopupRow from './PopupRow'
import MaterialPopup from 'components/Popup/MaterialPopup'
import Spacing from 'components/Containers/Spacing'
import Scrollbars from 'components/Scrollbars'
import { Text } from 'components/Typography'
import { FormControlAutocompleteQuery } from 'components/Form'
import useRecentlySearchedOptions from 'hooks/options/useRecentlySearchedOptions'
import { simulateEvent } from 'utils'
import { _isNull } from 'utils/lodash'
import { isNumber } from 'utils/generalUtils'

const useStyles = makeStyles(({ colors, spacing }) => ({
  icon: {
    color: colors.light,
    minWidth: 16
  },
  popupContainer: {
    width: 250
  },
  'horizontalMargin-0': {
    marginLeft: 0,
    marginRight: 0
  },
  'horizontalMargin-0.5': {
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5)
  }
}))

const FormControlButtonAutocomplete = ({
  onChange,
  value,
  name,
  label,
  isMulti,
  useLazyQuery,
  searchField,
  sort,
  responseParser,
  staticRequestParams,
  horizontalMargin,
  entity
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    recentlySearched,
    addRecentlySearchedOption,
    addRecentlySearchedOptions
  } = useRecentlySearchedOptions(entity, name)

  const getChangedValues = option =>
    Array.isArray(value)
      ? (value || []).some(value => value.value === option.value)
        ? (value || []).filter(value => value.value !== option.value)
        : [...(value || []), option]
      : isNumber(value)
      ? recentlySearched.filter(({ value }) => option.value === value)
      : recentlySearched.filter(({ label }) => option.label === label)

  const handleChange = option => {
    addRecentlySearchedOption(option)

    onChange(
      simulateEvent(name, isMulti ? getChangedValues(option) : option.value)
    )
  }

  const handleSelect = option => {
    onChange(
      simulateEvent(name, isMulti ? getChangedValues(option) : option.value)
    )
  }

  const handleClear = () => {
    onChange(simulateEvent(name, isMulti ? [] : null))
  }

  useEffect(() => {
    const anyValue = isMulti ? (value || [])[0] : value

    if (typeof anyValue === 'object' && !_isNull(anyValue)) {
      if (isMulti) {
        addRecentlySearchedOptions(value)
      } else {
        addRecentlySearchedOption(value)
      }
    }
    // eslint-disable-next-line
  }, [value])

  return (
    <MaterialPopup
      on="click"
      placement="bottom-start"
      trigger={
        <span className={classes[`horizontalMargin-${horizontalMargin}`]}>
          <PopupButton
            value={value}
            isMulti={isMulti}
            label={label}
            options={recentlySearched}
            onClear={handleClear}
          />
        </span>
      }
    >
      <Spacing variant={0} rootClassName={classes.popupContainer}>
        <Spacing
          variant={0}
          paddingHor={2}
          paddingVert={3}
          borderBottom={!!recentlySearched.length ? 1 : 0}
        >
          <FormControlAutocompleteQuery
            placeholder={t('Search')}
            name={name}
            fullWidth
            value={''}
            onChange={({ target }) => handleChange(target)}
            useLazyQuery={useLazyQuery}
            searchField={searchField}
            sort={sort}
            marginBottom={false}
            responseParser={responseParser}
            staticRequestParams={staticRequestParams}
          />
        </Spacing>

        {!!recentlySearched.length && (
          <Spacing variant={3}>
            <Spacing paddingHor={2} variant={1} paddingTop={1}>
              <Text>{`${t('Recently searched')}:`}</Text>
            </Spacing>
            <Scrollbars autoHeight autoHeightMax={300}>
              {recentlySearched.map((option, index, array) => {
                const selected =
                  isMulti && Array.isArray(value)
                    ? value.some(({ value }) => value === option.value)
                    : value === option.value

                return (
                  <PopupRow
                    key={option.value}
                    selected={selected}
                    option={option}
                    options={recentlySearched}
                    onSelect={handleSelect}
                    isLast={array.length === index + 1}
                  />
                )
              })}
            </Scrollbars>
          </Spacing>
        )}
      </Spacing>
    </MaterialPopup>
  )
}

FormControlButtonAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  searchable: PropTypes.bool,
  horizontalMargin: PropTypes.oneOf([0, 0.5]),
  useLazyQuery: PropTypes.func.isRequired,
  searchField: PropTypes.string,
  sort: PropTypes.string,
  responseParser: PropTypes.func,
  staticRequestParams: PropTypes.object,
  entity: PropTypes.string.isRequired
}

export default FormControlButtonAutocomplete
