import * as types from './index'

export const putClientSettingsAction = data => ({
  type: types.PUT_CLIENT_SETTINGS,
  payload: data
})

export const clearPutClientSettingsInfoAction = () => ({
  type: types.CLEAR_PUT_CLIENT_SETTINGS_INFO
})

export const putClientFeatureSettingsAction = data => ({
  type: types.PUT_CLIENT_FEATURE_SETTINGS,
  payload: data
})

export const postSsoDetails = data => {
  return {
    type: types.POST_SSO_DETAILS,
    payload: data
  }
}

export const postSsoDetailsSuccess = data => {
  return {
    type: types.POST_SSO_DETAILS_SUCCESS,
    payload: data
  }
}

export const postSsoDetailsFailure = error => {
  return {
    type: types.POST_SSO_DETAILS_FAILURE,
    payload: error
  }
}

export const putSsoDetails = data => {
  return {
    type: types.PUT_SSO_DETAILS,
    payload: data
  }
}

export const putSsoDetailsSuccess = data => {
  return {
    type: types.PUT_SSO_DETAILS_SUCCESS,
    payload: data
  }
}

export const putSsoDetailsFailure = error => {
  return {
    type: types.PUT_SSO_DETAILS_FAILURE,
    payload: error
  }
}

export const clearSsoDetails = () => {
  return {
    type: types.CLEAR_SSO_DETAILS
  }
}
