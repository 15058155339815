import React, { useCallback, useState, Fragment } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import InactivityModal from 'components/Modal/InactivityModal'
import { secToMs } from 'utils/date'
import { ACTIVITY_CONFIRMATION_DURATION } from 'constants/api'
import useUserDetails from 'hooks/useUserDetails'
import useSnackbar from 'hooks/useSnackbar'
import { logoutUserAction } from 'actions/authenticationActions'
import { resetAllFilters } from 'actions/filtersActions'

function InactivityTimer() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { showSnackbar } = useSnackbar()

  const userDetails = useUserDetails()

  const [prompt, setPrompt] = useState(false)

  const onIdle = useCallback(() => {
    dispatch(logoutUserAction())
    dispatch(resetAllFilters())
    showSnackbar(t('Your session has timed out. Please login.'), 'success')
  }, [dispatch, showSnackbar, t])

  const onPrompt = useCallback(() => {
    setPrompt(true)
  }, [])

  const onActive = useCallback(e => {
    if (!e) {
      setPrompt(false)
    }
  }, [])

  const { activate } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    debounce: 1000,
    timeout: secToMs(
      Math.max(
        userDetails.client?.autoLogoutTime || 0,
        ACTIVITY_CONFIRMATION_DURATION + 1
      )
    ),
    promptBeforeIdle: secToMs(ACTIVITY_CONFIRMATION_DURATION),
    name: userDetails.id,
    crossTab: true,
    syncTimers: 300000, // 5 minutes
    disabled:
      !userDetails.client?.autoLogout || !userDetails.client?.autoLogoutTime
  })

  const handleConfirmActive = () => {
    setPrompt(false)
    activate()
  }

  return (
    <Fragment>
      <InactivityModal open={prompt} onConfirmActive={handleConfirmActive} />
    </Fragment>
  )
}

export default InactivityTimer
