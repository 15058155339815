import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import rootReducer from '../reducers'
import rootSaga from '../sagas'
import { registeredApi } from '../api'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    ...registeredApi.reduce(
      (acc, api) => ({ ...acc, [api.reducerPath]: api.reducer }),
      {}
    ),
    ...rootReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(sagaMiddleware, ...registeredApi.map(api => api.middleware))
})

sagaMiddleware.run(rootSaga)

export default store
