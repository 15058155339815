import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel } from '@material-ui/core'

import { FormControlInput, FormControlTelInput } from 'components/Form'
import FormControlSelectLocations from 'components/Form/FormControlSelectLocations'
import FormControlPasswordInput from 'components/Form/FormControlPasswordInput'
import Spacing from 'components/Containers/Spacing'
import useSelectLocations from 'hooks/options/useSelectLocations'

const Form = ({
  t,
  handleChange,
  setFieldValue,
  handleBlur,
  agreedToPolicies,
  hasSSOData,
  disabledSSOFields,
  fullAddress,
  setFullAddress,
  setAgreed,
  values,
  classes,
  errors,
  touched
}) => {
  const translate = useMemo(
    () => ({
      name: t('Company / Account Name'),
      address: t('Address'),
      addressTooltip: t('Enter address, zip code or city name'),
      state: t('State'),
      zip: t('Zip'),
      city: t('City'),
      country: t('Country'),
      phoneNo1: t('Company Phone'),
      image: t('Image'),
      firstName: t('First Name'),
      lastName: t('Last Name'),
      email: t('Email'),
      phone: t('Phone'),
      password: t('Password'),
      confirmPassword: t('Confirm Password'),
      agreedToPolicies: t(
        'Mvix Digital Signage Terms and Conditions and Privacy Policy'
      )
    }),
    [t]
  )

  const {
    filterOption,
    setAddressFields,
    clearAddressFields
  } = useSelectLocations()

  const handleChangeLocations = useCallback(
    ({ target: { value, data } }) => {
      if (data) {
        setAddressFields({ data, setFieldValue })
        setFullAddress(value)
      } else {
        clearAddressFields({ setFieldValue })
        setFullAddress('')
      }
    },
    [setFieldValue, setFullAddress, setAddressFields, clearAddressFields]
  )

  const handlePhoneChange = useCallback(
    e => {
      handleChange(e)
      setFieldValue('user.phone', e.target.value)
    },
    [handleChange, setFieldValue]
  )

  const handlePolicyCheckboxChange = useCallback(
    e => {
      setAgreed(e.target.checked)
    },
    [setAgreed]
  )

  return (
    <div className={classes.clientDetailCardRoot}>
      <FormControlInput
        fullWidth
        label={translate.name}
        name="name"
        value={values.name}
        handleChange={handleChange}
        formControlContainerClass={classes.stretch}
        formControlInputClass={classes.formControlInput}
        formControlLabelClass={classes.formControlLabel}
        error={errors.name}
        handleBlur={handleBlur}
        touched={touched.name}
        marginBottom={false}
      />
      <FormControlSelectLocations
        fullWidth
        name="address1"
        label={translate.address}
        value={fullAddress}
        onChange={handleChangeLocations}
        tooltip={translate.addressTooltip}
        filterOption={filterOption}
        customClass={classes.formControlInput}
        formControlContainerClass={classes.stretch}
        formControlLabelClass={classes.formControlLabel}
        errorClassNames={classes.locationFieldError}
        error={errors.address1}
        handleBlur={handleBlur}
        touched={touched.address1}
        marginBottom={false}
      />
      <FormControlInput
        label={translate.firstName}
        name="user.firstName"
        value={values.user.firstName}
        error={errors.user?.firstName}
        touched={touched.user?.firstName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={hasSSOData && disabledSSOFields['firstName']}
        formControlInputClass={classes.formControlInput}
        formControlLabelClass={classes.formControlLabel}
        marginBottom={false}
      />
      <FormControlInput
        label={translate.lastName}
        name="user.lastName"
        value={values.user.lastName}
        error={errors.user?.lastName}
        touched={touched.user?.lastName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={hasSSOData && disabledSSOFields['lastName']}
        formControlInputClass={classes.formControlInput}
        formControlLabelClass={classes.formControlLabel}
        marginBottom={false}
      />
      <FormControlInput
        label={translate.email}
        name="user.email"
        id="user.email"
        autocomplete="email"
        value={values.user.email}
        error={errors.user?.email}
        touched={touched.user?.email}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={hasSSOData && disabledSSOFields['email']}
        formControlInputClass={classes.formControlInput}
        formControlLabelClass={classes.formControlLabel}
        marginBottom={false}
      />
      <FormControlTelInput
        label={translate.phone}
        name="phoneNo1"
        fieldId="phoneNo1"
        value={values.phoneNo1}
        onChange={handlePhoneChange}
        error={errors.phoneNo1}
        onBlur={handleBlur}
        touched={touched.phoneNo1}
        customClass={{
          input: classes.formControlInput,
          label: classes.formControlLabel
        }}
        marginBottom={false}
      />
      <FormControlPasswordInput
        label={translate.password}
        name="user.password"
        value={values.user.password}
        error={errors.user?.password}
        touched={touched.user?.password}
        onChange={handleChange}
        handleBlur={handleBlur}
        formControlInputClass={classes.formControlInput}
        formControlLabelClass={classes.formControlLabel}
        iconClass={classes.passwordIcon}
        marginBottom={false}
      />
      <FormControlPasswordInput
        label={translate.confirmPassword}
        name="user.passwordConfirmation"
        value={values.user.passwordConfirmation}
        error={errors.user?.passwordConfirmation}
        touched={touched.user?.passwordConfirmation}
        onChange={handleChange}
        handleBlur={handleBlur}
        formControlInputClass={classes.formControlInput}
        formControlLabelClass={classes.formControlLabel}
        iconClass={classes.passwordIcon}
        marginBottom={false}
      />
      <Spacing
        variant={0}
        rootClassName={classNames(classes.stretch, classes.checkBoxWrapper)}
      >
        <FormControlLabel
          control={
            <Checkbox
              value={agreedToPolicies}
              onChange={handlePolicyCheckboxChange}
              className={classes.checkBox}
            />
          }
          label={translate.agreedToPolicies}
        />
      </Spacing>
    </div>
  )
}

export default withTranslation('translations')(Form)
