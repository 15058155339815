export const playlistsSelector = ({ playlist }) => playlist.library

export const modalPlaylistsSelector = ({ playlist }) => playlist.modalItems

export const playlistAssignAvailabilitySelector = ({ playlist }) =>
  playlist.assignAvailability

export function playlistAssociationsSelector({ playlist }) {
  return playlist.associations
}

export function playlistPreviewSelector({ playlist }) {
  return playlist.preview
}

export function playlistItemSelector({ playlist }) {
  return playlist.playlistItem
}
