import { useMemo } from 'react'

export default function useSearchParams({ search, data }) {
  return useMemo(() => {
    const paramsObj = {}
    const urlParams = new URLSearchParams(search)
    urlParams.forEach((value, key) => {
      paramsObj[key] = value
    })
    if (data?.tag) {
      paramsObj.tag = [data.tag]
      paramsObj.exact = true
    }
    return paramsObj
  }, [search, data?.tag])
}
