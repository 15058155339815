import api from './api'
import { errorHandler } from '../utils'

const getEmailNotificationItems = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/emailNotification',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getEmailNotificationById = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/emailNotification/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postEmailNotificationItem = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/emailNotification',
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putEmailNotificationItem = async (id, data) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/emailNotification/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteEmailNotificationItem = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/emailNotification/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getEmailBuilderData = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/emailNotification/getBuilderData'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getEmailReceiverData = async (roleId, params) => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/emailNotification/receivers/${roleId}`,
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getEmailNotificationTimeZones = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/emailNotification/timezone',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const emailNotificationService = {
  getEmailNotificationItems,
  getEmailNotificationById,
  getEmailBuilderData,
  getEmailReceiverData,
  postEmailNotificationItem,
  putEmailNotificationItem,
  deleteEmailNotificationItem,
  getEmailNotificationTimeZones
}

export default emailNotificationService
