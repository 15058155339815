import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'

import Text from './Text'
import { ClassName, Color, FontSize, FontStyle, FontWeight } from 'utils/styles'

const TextWithTooltip = ({
  maxWidth = 0,
  placement = 'top',
  children,
  whiteSpace,
  styles,
  title = '',
  ...props
}) => {
  const ref = useRef(null)
  const [tooltipText, setTooltipText] = useState('')

  useEffect(
    () => {
      const isTextHidden =
        ref.current?.scrollWidth !== ref.current?.clientWidth ||
        ref.current?.clientWidth > maxWidth

      setTooltipText(isTextHidden ? (title ? title : children) : '')
    },
    // eslint-disable-next-line
    [ref.current, maxWidth, children]
  )

  return (
    <Tooltip arrow title={tooltipText || ''} placement={placement}>
      <Text
        ref={ref}
        whiteSpace="no-wrap"
        noWrap
        {...props}
        style={{ ...styles, maxWidth }}
      >
        {children}
      </Text>
    </Tooltip>
  )
}

TextWithTooltip.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  variant: FontSize,
  weight: FontWeight,
  fontStyle: FontStyle,
  color: Color,
  rootClassName: ClassName,
  styles: PropTypes.object
}

export default TextWithTooltip
