import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

import { TableLibraryRowActionButton } from 'components/TableLibrary'
import { TextWithTooltip } from 'components/Typography'

const styles = ({ palette, type, typography, colors }) => ({
  alertItem: {
    borderBottom: `1px solid ${colors.border[type]}`,
    padding: '10px 5px 10px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    position: 'relative'
  },
  alertItemActive: {
    background: palette[type].table.body.row.selected.background,
    transitionDuration: '0.3s'
  },
  alertTitle: {
    marginRight: 'auto',
    ...typography.darkAccent[type]
  },
  circleButton: {
    color: palette[type].dialog.closeButton,
    fontSize: 17,
    margin: '0 0 0 15px',

    '&:hover, &.active': {
      color: '#1c5dca'
    }
  },
  activeIcon: {
    height: 10,
    width: 10,
    position: 'absolute',
    background: palette[type].table.body.row.selected.background,
    transform: 'rotate(225deg)',
    margin: '-5px',
    zIndex: -1,
    top: '50%',
    right: '0px'
  },
  actionBtn: {
    zIndex: 102
  }
})

const AlarmAlert = ({
  item,
  permissions,
  active = false,
  handleEditClick,
  handleDeleteClick,
  t,
  classes
}) => {
  const actionLinks = useMemo(() => {
    return [
      {
        label: t('Edit'),
        clickAction: handleEditClick,
        render: permissions.update
      },
      {
        label: t('Delete'),
        icon: 'fa-regular fa-trash-can',
        clickAction: handleDeleteClick,
        render: permissions.delete
      }
    ]
  }, [t, handleEditClick, handleDeleteClick, permissions])

  return (
    <div
      className={classNames(classes.alertItem, {
        [classes.alertItemActive]: active
      })}
    >
      <TextWithTooltip rootClassName={classes.alertTitle} maxWidth={280}>
        {`${t('Device Alert')}: ${item.name}`}
      </TextWithTooltip>
      <TableLibraryRowActionButton
        actionLinks={actionLinks}
        position={['bottom right']}
        keepTooltipInside=".alarm-popup-boundary"
        rootClass={classes.actionBtn}
        hideArrowDownIcon
      />
      {active && <div className={classes.activeIcon} />}
    </div>
  )
}

export default withTranslation('translations')(withStyles(styles)(AlarmAlert))
