import { _padStart } from 'utils/lodash'

export const secToLabel = secs => {
  let minutes = Math.floor(secs / 60)
  secs = secs % 60
  const hours = Math.floor(minutes / 60)
  minutes = minutes % 60

  const hoursString = _padStart(String(hours), 2, '0')
  const minutesString = _padStart(String(minutes), 2, '0')
  const secsString = _padStart(String(secs), 2, '0')

  return `${hoursString}:${minutesString}:${secsString}`
}

export const labelToSec = label => {
  const times = label.split(':')
  times.reverse()
  const x = times.length
  let y = 0,
    z
  for (let i = 0; i < x; i++) {
    z = times[i] * Math.pow(60, i)
    y += z
  }
  return y
}
