import { takeLatest } from 'redux-saga/effects'
import * as types from 'actions'
import mediaSaga from 'sagas/mediaSaga'

const actions = [
  types.ADD_MEDIA_SUCCESS,
  types.PUT_MEDIA_SUCCESS,
  types.POST_MEDIA_BULK_SUCCESS,
  types.COPY_MEDIA_SUCCESS,
  types.CLONE_MEDIA_AND_UPDATE_SUCCESS,
  types.CLONE_MEDIA_ITEM_SUCCESS,
  types.POST_DESIGN_GALLERY_SUCCESS,
  types.PUT_DESIGN_GALLERY_SUCCESS,
  types.SHARE_MEDIA_SUCCESS
]

const mediaInvalidateCache = () => {
  return actions.map(action => {
    return takeLatest(action, mediaSaga.invalidateMediaTags)
  })
}

export default mediaInvalidateCache
