import React, { memo, useCallback, useState, Fragment } from 'react'
import { simulateEvent } from 'utils/formik'
import FormControlChips from './FormControlChips'
import { withTranslation } from 'react-i18next'

const Menu = () => {
  return <Fragment></Fragment>
}

const NoOptionsMessage = () => {
  return <Fragment></Fragment>
}

const FormControlMultyInput = ({
  t,
  id,
  name,
  values,
  marginBottom,
  onChange,
  ...props
}) => {
  const [createdTag, setCreatedTag] = useState({})

  const onHandleCreateNewValue = useCallback(newValue => {
    setCreatedTag({ data: { value: newValue, label: newValue } })
  }, [])

  const onHandleChange = useCallback(
    event => {
      const { value } = event.target
      onChange(simulateEvent(name, [...value]))
    },
    [name, onChange]
  )

  return (
    <FormControlChips
      noOptionsMessage={t('Press Enter to add new Email')}
      createdValue={createdTag}
      createNewValue={onHandleCreateNewValue}
      hasDynamicChipsCreation={true}
      values={values}
      handleChange={onHandleChange}
      components={{
        Menu,
        NoOptionsMessage
      }}
      marginBottom
      {...props}
    />
  )
}

export default withTranslation('translations')(memo(FormControlMultyInput))
