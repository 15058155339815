import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import StatusWithIcon from 'components/StatusWithIcon'
import { useUserRole } from 'hooks/tableLibrary'
import { getStatusInfo } from 'utils/deviceUtils'
import { deviceStatus } from 'constants/deviceConstants'

const DeviceStatusAgCell = ({ data }) => {
  const { t } = useTranslation()
  const role = useUserRole()

  const {
    availableFirmwareList,
    status,
    firmware,
    lastCheckInUTC,
    disabledAt,
    disabledBy
  } = data

  const statusInfo = getStatusInfo({
    availableFirmwareList,
    status,
    firmware,
    lastCheckInUTC,
    t,
    disabledAt,
    disabledBy,
    role
  })

  return (
    <Grid container justifyContent="center">
      <StatusWithIcon
        status={status}
        needsAttention={statusInfo?.needsAttention}
        headerText={statusInfo?.headerTitle}
        title={statusInfo?.title}
        hasTriangleIcon={status === deviceStatus.dormant}
        hasGrayIcon={status === deviceStatus.dormant}
        hideIcon={status === deviceStatus.disabled}
        disabledStatus={status === deviceStatus.disabled}
        role={role}
        t={t}
      />
    </Grid>
  )
}

export default DeviceStatusAgCell
