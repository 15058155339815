import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import ReactFlow, { Background } from 'react-flow-renderer'
import { useDispatch, useSelector } from 'react-redux'
import uuidv4 from 'uuid/v4'
import { _uniqBy, _isEmpty } from 'utils/lodash'

import ServerNodeType from './NodeType/ServerNodeType'
import DeviceNodeType from './NodeType/DeviceNodeType'
import { getDeviceNocNetworkItems } from 'actions/deviceNocActions'
import { queryParamsHelper } from 'utils'
import RouterNodeType from './NodeType/RouterNodeType'
import Scrollbars from 'components/Scrollbars'
import { prepareFlowData } from 'utils/deviceNocUtils'
import handleBottomScroll from 'utils/handleBottomScroll'
import { CircularLoader } from 'components/Loaders'
import ArcEdgeType from './EdgeType/ArcEdgeType'
import {
  HORIZONTAL_SPACING,
  NODE_HEIGHT_WITH_SPACING
} from 'constants/deviceNoc'
import WrongNodeType from './NodeType/WrongNodeType'
import CustomEdgeType from './EdgeType/CustomEdgeType'
import { isString } from 'utils/generalUtils'
import EmptyPlaceholder from 'components/EmptyPlaceholder'

const styles = () => ({
  root: {
    position: 'relative'
  },
  container: {
    minHeight: '100%',
    minWidth: '100%',
    height: '100%',
    width: '100%',
    position: 'relative'
  }
})

const nodeTypes = {
  server: ServerNodeType,
  router: RouterNodeType,
  device: DeviceNodeType,
  wrong: WrongNodeType
}

const edgeTypes = {
  arc: ArcEdgeType,
  custom: CustomEdgeType
}

const MAIN_FOOTER_HEIGHT = 50
const PAGE_FOOTER_HEIGHT = 50

const DeviceDiagram = ({
  t,
  classes,
  filterParams,
  searchParams,
  autoScroll = false,
  fetcher: fetchItems,
  itemReducer,
  isPublic,
  isFetchAllowed,
  preferenceParams
}) => {
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const [deviceConfig, setDeviceConfig] = useState({})
  const [flowInstance, setFlowInstance] = useState()
  const [containerSize, setContainerSize] = useState({
    height: 0,
    width: 0
  })
  const dispatch = useDispatch()
  const network = useSelector(({ deviceNoc: { network } }) => network)
  const containerRef = useRef()
  const scrollInterval = useRef()
  const scrollRef = useRef()

  const { response: data, isFetching, meta } = useMemo(
    () => (itemReducer ? itemReducer : network),
    [itemReducer, network]
  )

  const handleWheelEvent = useCallback(({ wheelDeltaY }) => {
    scrollRef.current.scrollToTop(
      (scrollRef.current ? scrollRef.current.getScrollTop() : 0) - wheelDeltaY
    )
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('wheel', handleWheelEvent)
    }
    // eslint-disable-next-line
  }, [])

  const handleResizeWindow = useCallback(() => {
    let height = 400
    if (containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect()
      height =
        window.innerHeight -
        (top + (isPublic ? 0 : MAIN_FOOTER_HEIGHT + PAGE_FOOTER_HEIGHT) + 25)
    }

    let width = window.innerWidth
    if (!isPublic && width > 1600) {
      width = 1600
    }

    setContainerSize({
      height,
      width
    })
  }, [isPublic])

  useEffect(() => {
    handleResizeWindow()
    window.addEventListener('resize', handleResizeWindow)

    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
  }, [handleResizeWindow])

  const limit = useMemo(
    () => {
      if (containerRef.current) {
        return Math.ceil(containerSize.height / NODE_HEIGHT_WITH_SPACING) + 1
      }
      return
    },
    // eslint-disable-next-line
    [containerRef.current, containerSize.height]
  )

  const fetcher = useCallback(
    (params = {}) => {
      const { filters, ...restPreferenceParams } = preferenceParams
      fetchItems
        ? fetchItems(
            queryParamsHelper({
              ...restPreferenceParams,
              limit,
              ...filterParams,
              ...searchParams,
              ...params
            })
          )
        : dispatch(
            getDeviceNocNetworkItems(
              queryParamsHelper({
                sort: 'status',
                order: 'asc',
                limit,
                ...filterParams,
                ...searchParams,
                ...params
              })
            )
          )
    },
    [dispatch, searchParams, filterParams, limit, fetchItems, preferenceParams]
  )

  const convertToRouteObject = useCallback(routeStr => {
    try {
      const split = routeStr.split(/( {2}| )/).filter(str => str.trim())
      return {
        domain: split[1],
        ip: split[2].replace('(', '').replace(')', ''),
        delay: parseInt(split[3]),
        uid: uuidv4()
      }
    } catch (e) {
      if (routeStr === 'no connect') {
        return {
          domain: 'No connect',
          delay: 0
        }
      }
    }
  }, [])

  const handleReset = useCallback(() => {
    setNodes([])
    setEdges([])
    setDeviceConfig({})
    scrollRef.current && scrollRef.current.scrollToTop()
    if (isFetchAllowed) {
      fetcher({
        page: 1
      })
    }
  }, [fetcher, isFetchAllowed])

  useEffect(() => {
    if (limit && isFetchAllowed) {
      handleReset()
    }
    // eslint-disable-next-line
  }, [filterParams, limit, isFetchAllowed])

  const removeMiddleNodes = useCallback(
    (nodes, numberOfRoutes = 10) => {
      if (!nodes) {
        return []
      }

      const nodesData = isString(nodes) ? JSON.parse(nodes) : nodes

      const filteredNodes = nodesData.filter(
        route =>
          route &&
          !route.includes('*') &&
          !route.includes('0.0 ms') &&
          !route.startsWith('traceroute to')
      )

      const removeNodes = filteredNodes.length - numberOfRoutes
      if (removeNodes > 0) {
        const startIndex =
          Math.floor(filteredNodes.length / 2) - Math.floor(removeNodes / 2)

        filteredNodes.splice(
          startIndex === 0 ? startIndex : startIndex - 1,
          removeNodes
        )
      }

      return _uniqBy(
        filteredNodes.map(route => convertToRouteObject(route)).filter(Boolean),
        'ip'
      )
    },
    [convertToRouteObject]
  )

  useEffect(
    () => {
      if (data) {
        if (containerRef.current) {
          containerRef.current.style.height = `${
            (deviceConfig.length + data.length) * NODE_HEIGHT_WITH_SPACING
          }px`
        }

        let parsedData = data.map((row, index) => ({
          ...row,
          reachedToServer: row.traceroute
            ? !row.traceroute?.[row.traceroute.length - 1]?.includes('*') ||
              false
            : false,
          traceroute: removeMiddleNodes(
            row.traceroute,
            isPublic
              ? Math.floor(containerSize.width / HORIZONTAL_SPACING) - 1
              : 10
          )
        }))

        const { nodes: _nodes, edges: _edges } = prepareFlowData(
          parsedData,
          deviceConfig,
          containerSize.width,
          isPublic
        )

        setNodes([...nodes, ..._nodes])

        setEdges([...edges, ..._edges])

        setDeviceConfig({
          length: (deviceConfig.length || 0) + data.length
        })
      }
    },
    // eslint-disable-next-line
    [data]
  )

  const handleFetchMoreDevices = useCallback(() => {
    if (meta.currentPage < meta.lastPage) {
      fetcher({
        page: meta.currentPage + 1
      })
    }
  }, [fetcher, meta.currentPage, meta.lastPage])

  const handleAutoScroll = useCallback(() => {
    if (scrollRef.current) {
      const { getClientHeight, getScrollTop, scrollToTop } = scrollRef.current
      const clientHeight = getClientHeight()
      const scrollTop = getScrollTop() || 0

      scrollToTop(scrollTop + clientHeight)
    }
  }, [])

  useEffect(() => {
    if (flowInstance && autoScroll && !scrollInterval.current) {
      handleFetchMoreDevices()
      scrollInterval.current = setInterval(handleAutoScroll, 10000)
    } else if (!autoScroll && scrollInterval.current) {
      clearInterval(scrollInterval.current)
      scrollInterval.current = null
    }
    // eslint-disable-next-line
  }, [autoScroll, flowInstance])

  useEffect(() => {
    if (meta.lastPage > 0 && meta.currentPage === meta.lastPage) {
      setTimeout(() => {
        handleReset()
      }, [30000])
    }
    if (meta.currentPage === 1 && autoScroll) {
      handleFetchMoreDevices()
    }
    // eslint-disable-next-line
  }, [meta])

  const handleInitFlow = useCallback(instance => {
    setFlowInstance(instance)
  }, [])

  return (
    <div className={classes.root}>
      {isFetching && !autoScroll && <CircularLoader />}
      <Scrollbars
        ref={scrollRef}
        onUpdate={handleBottomScroll(
          handleFetchMoreDevices,
          autoScroll ? scrollRef.current?.getClientHeight() || 50 : 50
        )}
        style={{
          height: containerSize.height
        }}
        renderHorizontalScroll
      >
        <div ref={containerRef} className={classes.container}>
          {!isFetching && _isEmpty(data) ? (
            <EmptyPlaceholder text={t('No Results Found')} />
          ) : (
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              panOnDrag={false}
              zoomOnScroll={false}
              zoomOnPinch={false}
              onInit={handleInitFlow}
              zoomOnDoubleClick={false}
              nodesConnectable={false}
            >
              <Background color="#aaa" gap={16} />
            </ReactFlow>
          )}
        </div>
      </Scrollbars>
    </div>
  )
}

DeviceDiagram.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default withTranslation('translations')(
  withStyles(styles)(DeviceDiagram)
)
