import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import update from 'immutability-helper'
import { useHistory } from 'react-router'

import {
  updateFiltersByEntity,
  resetFiltersByEntity
} from 'actions/filtersActions'
import {
  getSearchParamsObject,
  getUnknownSearchParams,
  removeSearchParamsWithoutRefresh
} from 'utils/urlUtils'
import { initialState } from '../reducers/filters'

const useFilter = (
  entity,
  predefine = {},
  onValuesChangeCallback,
  keepUnknownParams
) => {
  const values = useSelector(({ filters }) => filters[entity])
  const [location, setLocation] = useState()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    setLocation(history.location.pathname)
  }, [history.location.pathname])

  const initialValues = useMemo(
    () =>
      initialState.hasOwnProperty(entity)
        ? update(initialState[entity], { $merge: predefine })
        : predefine,
    [entity, predefine]
  )

  const updateByEntity = useCallback(
    (data = {}, resetSearchParams) => {
      dispatch(updateFiltersByEntity(entity, data))
      if (resetSearchParams && history.location.search) {
        if (keepUnknownParams) {
          removeSearchParamsWithoutRefresh(
            getUnknownSearchParams(
              getSearchParamsObject(history.location.search),
              initialValues
            )
          )
        } else {
          removeSearchParamsWithoutRefresh()
        }
      }
      onValuesChangeCallback && onValuesChangeCallback()
    },
    [
      entity,
      dispatch,
      history,
      onValuesChangeCallback,
      initialValues,
      keepUnknownParams
    ]
  )

  const resetByEntity = useCallback(() => {
    dispatch(resetFiltersByEntity(entity))
    //reset query params
    if (history.location.search && location === history.location.pathname) {
      history.push(history.location.pathname)
    }
    onValuesChangeCallback && onValuesChangeCallback()
  }, [entity, dispatch, history, onValuesChangeCallback, location])

  const unionValues = useMemo(() => update(values, { $merge: predefine }), [
    values,
    predefine
  ])

  return [unionValues, updateByEntity, resetByEntity, initialValues]
}

export default useFilter
