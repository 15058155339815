import * as R from 'ramda'

import { getUrlPrefix, isNotEmpty, capitalize } from 'utils/index'
import {
  permissionPageGroups,
  CLIENT_DEVICE_ASSIGN_APP_VERSION,
  SYSTEM_DEVICE_ASSIGN_APP_VERSION,
  SYSTEM_DEVICE_TYPE_ASSIGN_APP_VERSION,
  permissionTypes
} from 'constants/permissionGroups'
import * as permissionNames from 'constants/permissionNames'
import { featuresToExcludeByRole } from 'constants/media'
import {
  ENTERPRISE_BANNER_CLONE,
  ENTERPRISE_DESIGN_GALLERY_SHARE,
  ENTERPRISE_DEVICE_THUMBNAIL_TRIGGER,
  ENTERPRISE_MEDIA_SHARE,
  ENTERPRISE_PLAYLIST_SHARE,
  ENTERPRISE_SCHEDULE_COPY,
  ENTERPRISE_TEMPLATE_SHARE,
  SYSTEM_BANNER_CLONE,
  SYSTEM_DESIGN_GALLERY_SHARE,
  SYSTEM_DEVICE_THUMBNAIL_TRIGGER,
  SYSTEM_MEDIA_SHARE,
  SYSTEM_PLAYLIST_SHARE,
  SYSTEM_SCHEDULE_COPY,
  SYSTEM_TEMPLATE_SHARE,
  ORG_DEVICE_NOC
} from 'constants/permissionNames'

export function routesConfig(isGroups, id) {
  function base(url = '') {
    return (
      getUrlPrefix(isGroups ? 'users-library/group/' : 'users-library/') + url
    )
  }

  function getLink(entity) {
    return base(`${id}/permissions/${entity}`)
  }

  function getMatch(entity) {
    return base(`:id/permissions/${entity}`)
  }

  function getCloseLink() {
    return base()
  }

  return {
    getLink,
    getMatch,
    getCloseLink
  }
}

// TODO Rename
function appendTo(acc, cur) {
  return R.apply(R.append, [cur, acc])
}

function invertAppendTo(cur, acc) {
  return R.apply(R.append, [cur, acc])
}

function push(toArr, fromArr) {
  return R.reduce(appendTo, toArr, fromArr)
}

function findId(element, arr) {
  return R.apply(R.find(R.propEq('id', R.prop('id', element))), [arr])
}

function isIdIn(element, arr) {
  return !!R.apply(findId, [element, arr])
}

function indexOf(element, arr) {
  return R.indexOf(R.apply(findId, [element, arr]), arr)
}

function replaceId(element, arr) {
  return R.update(R.apply(indexOf, [element, arr]), element, arr)
}

function pushOrReplace(acc, cur) {
  return R.apply(R.ifElse(isIdIn, replaceId, invertAppendTo), [cur, acc])
}

function concat(toArr, fromArr) {
  return R.reduce(pushOrReplace, toArr, fromArr)
}

function updateArr(arr, newArr) {
  return R.apply(R.ifElse(R.isEmpty, push, concat), [arr, newArr])
}

export function update(arr, newArr, entity) {
  return R.apply(
    R.ifElse(
      isNotEmpty,
      R.partial(updateArr, [arr]),
      R.partial(filter, [entity, arr, filterWithoutEntity])
    ),
    [newArr]
  )
}

function elementEntity(element) {
  return R.apply(
    R.compose(
      R.toLower,
      R.partial(R.prop, ['entity']),
      R.partial(R.prop, ['group'])
    ),
    [element]
  )
}

function filterFn(entity, element) {
  return R.equals(entity, elementEntity(element))
}

function filterWithoutEntity(entity, element) {
  return R.apply(R.compose(R.not, R.partial(R.equals, [entity])), [
    elementEntity(element)
  ])
}

export function filter(entity, arr, callback = filterFn) {
  return R.filter(R.partial(callback, [entity]), arr)
}

export function checkboxValue(isRead, id, permissions) {
  function findFn(element) {
    return R.equals(id, element.groupId)
  }

  function element() {
    return R.find(findFn, permissions)
  }

  function permissionName() {
    return isRead ? 'readPermission' : 'writePermission'
  }

  function isPermissionEqual() {
    return R.apply(R.propEq(permissionName(), 1), [element()])
  }

  return R.apply(
    R.ifElse(element, isPermissionEqual, () => false),
    []
  )
}

function idPropName(isGroups) {
  return isGroups ? 'groupId' : 'id'
}

export function createGetReqObj(isGroups, id, entity, params) {
  return {
    [idPropName(isGroups)]: id,
    entity: capitalize(entity),
    params
  }
}

export function createPutReqObj(isGroups, id, data) {
  return {
    [idPropName(isGroups)]: id,
    data: data
  }
}

function requestObj(element) {
  return {
    groupId: R.prop('id', R.prop('group', element)),
    readPermission: R.prop('readPermission', element),
    writePermission: R.prop('writePermission', element),
    entity: R.prop('entity', R.prop('group', element))
  }
}

export function toRequestFormat(permissions) {
  return R.map(requestObj, permissions)
}

export function change(isRead, value, groupId, permissions) {
  function numValue() {
    return value ? 1 : 0
  }

  function invertNumValue() {
    return !value ? 1 : 0
  }

  function permissionName() {
    return isRead ? 'readPermission' : 'writePermission'
  }

  function invertPermissionName() {
    return isRead ? 'writePermission' : 'readPermission'
  }

  function createObj() {
    return R.apply(
      R.pipe(
        R.partial(R.assoc, ['groupId', groupId]),
        R.partial(R.assoc, [permissionName(), numValue()]),
        !isRead && value
          ? R.partial(R.assoc, [invertPermissionName(), 1])
          : R.partial(R.assoc, [invertPermissionName(), 0])
      ),
      [{}]
    )
  }

  function findById() {
    return R.find(R.propEq('groupId', groupId), permissions)
  }

  function isIdIn() {
    return Boolean(findById())
  }

  function indexOf() {
    return R.indexOf(findById(), permissions)
  }

  function assocObj() {
    return R.apply(
      R.pipe(
        R.partial(R.assoc, [permissionName(), invertNumValue()]),
        R.partial(R.assoc, [permissionName(), numValue()])
      ),
      [findById()]
    )
  }

  function update() {
    const indexOfRes = indexOf()
    const assocObjRes = assocObj()
    if (!isRead && value) {
      assocObjRes[permissionName()] = 1
      assocObjRes[invertPermissionName()] = 1
    }
    return R.update(indexOfRes, assocObjRes, permissions)
  }

  function push() {
    return R.append(createObj(), permissions)
  }

  function change() {
    return R.apply(R.ifElse(isIdIn, update, push), [])
  }

  return change()
}

export function checkPermissions(groups, permissions) {
  return groups.some(group => permissions.includes(group))
}

export const getPermissionGroup = (permissionPageGroup, name) => {
  return `${permissionPageGroup} ${name}`
}

export const parsePermissionByGroup = permission => {
  switch (permission.group) {
    case SYSTEM_DEVICE_ASSIGN_APP_VERSION:
      return {
        ...permission,
        group: getPermissionGroup(
          permissionPageGroups.device,
          'assign app version'
        ),
        action: 'update'
      }
    case SYSTEM_DEVICE_TYPE_ASSIGN_APP_VERSION:
      return {
        ...permission,
        group: getPermissionGroup(
          permissionPageGroups.device,
          'type assign app version'
        ),
        action: 'update'
      }
    case CLIENT_DEVICE_ASSIGN_APP_VERSION:
      return {
        ...permission,
        group: getPermissionGroup(
          permissionPageGroups.device,
          'assign app version'
        ),
        action: 'update'
      }
    default:
      return permission
  }
}

export const parsePermissionByName = permission => {
  switch (permission.name) {
    case permissionNames.CLIENT_MEDIA_APPROVAL:
      return {
        ...permission,
        group: getPermissionGroup(permissionPageGroups.media, 'approve')
      }
    case permissionNames.CLIENT_PLAYLIST_APPROVAL:
      return {
        ...permission,
        group: getPermissionGroup('playlist', 'approve')
      }
    case permissionNames.CLIENT_TEMPLATE_APPROVAL:
      return {
        ...permission,
        group: getPermissionGroup(permissionPageGroups.template, 'approve')
      }
    case permissionNames.CLIENT_SCHEDULE_APPROVAL:
      return {
        ...permission,
        group: getPermissionGroup(permissionPageGroups.schedule, 'approve')
      }
    case permissionNames.ENTERPRISE_CLIENT_IMPERSONATE:
    case permissionNames.SYSTEM_CLIENT_IMPERSONATE:
    case permissionNames.RESELLER_CLIENT_IMPERSONATE:
      return {
        ...permission,
        group: getPermissionGroup('impersonate', '')
      }
    case permissionNames.ENTERPRISE_MEDIA_COPY:
    case permissionNames.SYSTEM_MEDIA_COPY:
      return {
        ...permission,
        group: getPermissionGroup(permissionPageGroups.media, 'copy'),
        action: 'create'
      }
    case permissionNames.SYSTEM_PLAYLIST_COPY:
    case permissionNames.ENTERPRISE_PLAYLIST_COPY:
      return {
        ...permission,
        group: getPermissionGroup('playlist', 'copy'),
        action: 'create'
      }
    case permissionNames.SYSTEM_TEMPLATE_COPY:
    case permissionNames.ENTERPRISE_TEMPLATE_COPY:
      return {
        ...permission,
        group: getPermissionGroup(permissionPageGroups.template, 'copy'),
        action: 'create'
      }
    case permissionNames.RESELLER_CLIENT_USER_READ:
      return {
        ...permission,
        group: getPermissionGroup('reseller client user', '')
      }
    case permissionNames.SYSTEM_DEVICE_ERROR_LOG:
    case permissionNames.ORG_DEVICE_ERROR_LOG:
      return {
        ...permission,
        group: getPermissionGroup(permissionPageGroups.device, 'logs')
      }
    case permissionNames.SYSTEM_CLIENT_BLOCKING:
      return {
        ...permission,
        group: getPermissionGroup('client billing', ''),
        action: 'update'
      }
    case permissionNames.ORG_MEDIA_SHORELINE_PRICE_SHOW:
    case permissionNames.ORG_MEDIA_SHORELINE_PRICE_STORE:
      return {
        ...permission,
        group: getPermissionGroup('shoreline', 'Price')
      }
    case permissionNames.ORG_MEDIA_SHORELINE_EVENT_SHOW:
    case permissionNames.ORG_MEDIA_SHORELINE_EVENT_STORE:
      return {
        ...permission,
        group: getPermissionGroup('shoreline', 'Events')
      }
    case permissionNames.SYSTEM_RUN_REPORT:
    case permissionNames.ORG_RUN_REPORT:
      return {
        ...permission,
        action: 'create'
      }
    default:
      return permission
  }
}

export const getFeaturesToExcludeByRole = role =>
  featuresToExcludeByRole[role] || []

export const getOtherPermissionRows = permissions =>
  permissions
    .filter(({ action }) => action === permissionTypes.other)
    .map(permission => ({ [permission.name]: permission }))

export const getCRUDPermissions = permissions =>
  permissions.filter(({ action }) => action !== permissionTypes.other)

export const isOtherPermissionRow = row =>
  Object.values(row)[0].action === permissionTypes.other

export const permissionRowsComparer = (row1, row2) => {
  if (isOtherPermissionRow(row1) && isOtherPermissionRow(row2)) {
    return 0
  }
  return isOtherPermissionRow(row1) ? 1 : -1
}

export const permissionNameToDisplayName = {
  [SYSTEM_DESIGN_GALLERY_SHARE]: 'Share Design Gallery',
  [SYSTEM_BANNER_CLONE]: 'Clone Banner',
  [SYSTEM_DEVICE_THUMBNAIL_TRIGGER]: 'Trigger Device Preview',
  [SYSTEM_MEDIA_SHARE]: 'Share Media',
  [SYSTEM_PLAYLIST_SHARE]: 'Share Playlist',
  [SYSTEM_SCHEDULE_COPY]: 'Copy Schedule',
  [SYSTEM_TEMPLATE_SHARE]: 'Share Template',
  [ENTERPRISE_DESIGN_GALLERY_SHARE]: 'Share Design Gallery',
  [ENTERPRISE_BANNER_CLONE]: 'Clone Banner',
  [ENTERPRISE_DEVICE_THUMBNAIL_TRIGGER]: 'Trigger Device Preview',
  [ENTERPRISE_MEDIA_SHARE]: 'Share Media',
  [ENTERPRISE_PLAYLIST_SHARE]: 'Share Playlist',
  [ENTERPRISE_SCHEDULE_COPY]: 'Copy Schedule',
  [ENTERPRISE_TEMPLATE_SHARE]: 'Share Template',
  [ORG_DEVICE_NOC]: 'Device NOC Public URL'
}
