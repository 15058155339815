import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { withSnackbar } from 'notistack'
import { useCustomSnackbar } from 'hooks'
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { withTranslation } from 'react-i18next'
import { compose } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import {
  Grid,
  IconButton,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core'
import { _chunk } from 'utils/lodash'

import { TableLibraryCell } from './index'
import { CheckboxSelectAll, CheckboxSwitcher } from 'components/Checkboxes'
import useColumnSizeRestrictions from 'hooks/tableLibrary/useColumnSizeRestrictions'
import { WhiteButton } from 'components/Buttons'
import Spacing from 'components/Containers/Spacing'
import { TextSizeTooltip } from 'utils'
import Text from 'components/Typography/Text'
import { maxHeadDropdownColumnItems } from 'utils/tableUtils'
import MaterialPopup from 'components/Popup/MaterialPopup'
import PortalAwareItem from 'components/Media/Local/Interest/PortalAwareItem'
import TableLibrarySortLabel from './TableLibrarySortLabel'
import { overlayStyles } from 'constants/materialPopup'
import { useUserRole } from 'hooks/tableLibrary'
import { zIndexes } from 'constants/stylesConstants'
import { getOptimalChunkSize } from 'utils/arrayUtils'

const portal = document.createElement('div')
document.body.appendChild(portal)

const tableHeadStyles = theme => {
  const { palette, type, colors } = theme
  return {
    headerCell: {
      padding: '0 10px 8px 10px'
    },
    filterIconRoot: {
      marginRight: 14,
      padding: '11px 12px',
      color: palette[type].tableLibrary.head.iconColor
    },
    filterSwitchContainer: {
      width: '100%'
    },
    filterSwitchLabel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'grab',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      margin: '8px 0'
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    draggable: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'grab'
    },
    dragIcon: {
      width: '40px',
      opacity: 0.5,
      color: colors.title.primary[type]
    },
    headerCellCentered: {
      '& > span': {
        paddingLeft: 20
      }
    },
    headerCellCustom: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important'
    },
    headerSticky: {
      position: 'sticky',
      top: '0',
      zIndex: '1',
      backgroundColor: palette[type].tableLibrary.body.row.dropdown.background
    },
    columnsContainer: {
      flexWrap: 'nowrap'
    },
    columnsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column'
    },
    hiddenColumnsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column'
    },
    disabled: {
      '& span svg': {
        fill: '#f5f3f0'
      }
    },
    sticky: {
      position: 'sticky',
      background: palette[type].tableLibrary.head.background,
      zIndex: zIndexes.stickyTableHeaderColumn
    },
    stickyLeft: {
      left: 0
    },
    stickyRight: {
      right: 0
    },
    bulkActionsWrapper: {
      position: 'absolute',
      height: 47,
      zIndex: 3,
      backgroundColor: palette[type].pageContainer.header.background
    }
  }
}

export const columnWidths = {
  feature: 50,
  duration: 170,
  title: 200,
  alias: 200,
  total: 36,
  size: 170,
  noOfFiles: 170,
  valid: 150,
  invalid: 170,
  action: 180,
  noTypeColumn: 64,
  emptyHeadColumn: 50,
  actionsColumn: 80,
  checkboxColumn: 40,
  playlistType: 100
}

const dropdownColumnWidth = 200
const dropdownColumnGap = 30
const dropdownColumnWidthWithGap = dropdownColumnWidth + dropdownColumnGap

const TableLibraryHead = ({
  t,
  classes,
  allSelected,
  onSelectAllClick,
  order,
  orderBy,
  columns,
  noType,
  editRows,
  actionRow,
  handleColumnChange,
  handleReorder = f => f,
  handleColumnReset,
  filter = f => f,
  columnWidth: columnWidthProp,
  onRequestSort = f => f,
  enqueueSnackbar,
  closeSnackbar,
  isStickyHeader = false,
  isSsoVisible = false,
  tableMaxWidth,
  disableSort = false,
  hasMultiSelect = true,
  headerCellClassName = '',
  maxColumnTooltip = 'To show additional columns, at least 1 existing column must be hidden',
  tooltipUnderLine = false,
  noHideColumns = [],
  hasEmptyHeadColumn,
  headerRootClassName,
  hasEnabledCheckBox = true,
  staticColumns = [],
  hasHorizontalScroll,
  checkboxPaddingVariant = 'medium',
  bulkActions,
  numSelected,
  noTypeColumnWidth
}) => {
  const headerRef = useRef()
  const role = useUserRole()

  const withHorizontalScroll = useMemo(
    () => role.system || hasHorizontalScroll,
    [role, hasHorizontalScroll]
  )
  const [, setRendered] = useState(false)
  const createSortHandler = useCallback(
    property => event => {
      !disableSort && onRequestSort(event, property)
    },
    [disableSort, onRequestSort]
  )
  const displayedColumns = useMemo(
    () =>
      columns.filter(column =>
        column.hasOwnProperty('display') ? column.display : filter(column.id)
      ),
    [columns, filter]
  )

  const hiddenColumns = useMemo(
    () =>
      columns
        .filter(column =>
          column.hasOwnProperty('display')
            ? !column.display
            : !filter(column.id)
        )
        .sort((a, b) => t(a.label).localeCompare(t(b.label))),
    [columns, filter, t]
  )

  const splitedColumns = useMemo(() => {
    const optimalChunkSize = Math.max(
      getOptimalChunkSize(displayedColumns, 5, maxHeadDropdownColumnItems),
      getOptimalChunkSize(hiddenColumns, 5, maxHeadDropdownColumnItems)
    )

    return {
      displayed: _chunk(displayedColumns, optimalChunkSize),
      hidden: _chunk(hiddenColumns, optimalChunkSize)
    }
  }, [displayedColumns, hiddenColumns])

  const numberOfColumns =
    splitedColumns.hidden.length + splitedColumns.displayed.length

  const dropdownStyle = useMemo(
    () => ({
      width:
        numberOfColumns > 1
          ? dropdownColumnWidth +
            dropdownColumnWidthWithGap * (numberOfColumns - 1)
          : hiddenColumns.length
          ? dropdownColumnWidth * 2 + dropdownColumnGap
          : dropdownColumnWidth,
      zIndex: zIndexes.materialPopup
    }),
    [numberOfColumns, hiddenColumns.length]
  )

  const columnWidth = useMemo(() => ({ ...columnWidths, ...columnWidthProp }), [
    columnWidthProp
  ])

  const staticColumnWidth = useMemo(() => {
    if (!staticColumns.length) {
      return {}
    }

    return staticColumns.reduce(
      (acc, c) =>
        columnWidth[c.id] ? { ...acc, [c.id]: columnWidth[c.id] } : acc,
      {}
    )
  }, [columnWidth, staticColumns])

  const displayedColumnWidth = useMemo(
    () => ({
      noTypeColumn: noType ? 0 : noTypeColumnWidth || columnWidth.noTypeColumn,
      checkboxColumn: onSelectAllClick
        ? noTypeColumnWidth || columnWidth.noTypeColumn
        : 0,
      actionsColumn: editRows ? columnWidth.actionsColumn : 0,
      ...staticColumnWidth,
      ...columns
        .filter(c => filter(c.id) && c.display !== false)
        .reduce(
          (acc, c) =>
            columnWidth[c.id] ? { ...acc, [c.id]: columnWidth[c.id] } : acc,
          {}
        )
    }),
    [
      columnWidth,
      columns,
      filter,
      noType,
      onSelectAllClick,
      editRows,
      staticColumnWidth,
      noTypeColumnWidth
    ]
  )

  const { canShowColumn } = useColumnSizeRestrictions(
    headerRef.current,
    displayedColumnWidth,
    handleColumnChange,
    tableMaxWidth,
    withHorizontalScroll
  )

  const showSnackbar = useCustomSnackbar(t, enqueueSnackbar, closeSnackbar)

  const handleColumnDisplayChange = useCallback(
    colId => display => {
      if (display && !canShowColumn(columnWidth[colId])) {
        showSnackbar('Show column error message', 'warning')
        return
      }

      handleColumnChange(colId, display)
    },
    [canShowColumn, handleColumnChange, columnWidth, showSnackbar]
  )

  useEffect(() => {
    setRendered(true)
  }, [])

  const renderColumns = useCallback(
    ({ columns, isStatic = false }) =>
      columns
        .filter(c => filter(c.id))
        .map(column => {
          const hasDisplay = column.hasOwnProperty('display')
          if (column.id === 'account') {
            column.id = 'clientId'
          }
          if (hasDisplay && !column.display && !isStatic) return null

          return (
            <TableLibraryCell
              key={column.id}
              align={column.align || 'left'}
              sortDirection={orderBy === column.id ? order : false}
              padding={column.noPaddings ? 'none' : null}
              className={classNames(
                classes.headerCell,
                {
                  [classes.headerCellCentered]: column.align === 'center',
                  [classes.headerCellCustom]: column.customPadding
                },
                headerCellClassName
              )}
            >
              <TableLibrarySortLabel
                column={column}
                createSortHandler={createSortHandler}
                order={order}
                orderBy={orderBy}
                hideSortIcon={disableSort}
              />
            </TableLibraryCell>
          )
        }),
    [
      order,
      orderBy,
      filter,
      disableSort,
      createSortHandler,
      headerCellClassName,
      classes.headerCell,
      classes.headerCellCentered,
      classes.headerCellCustom
    ]
  )

  const filteredStaticColumns = staticColumns.filter(column =>
    column.hasOwnProperty('forRoles')
      ? column.forRoles.includes(role.role)
      : true
  )

  return (
    <TableHead
      ref={headerRef}
      className={classNames(
        {
          [classes.headerSticky]: isStickyHeader
        },
        headerRootClassName
      )}
    >
      <TableRow>
        {hasMultiSelect && onSelectAllClick && (!numSelected || role.system) ? (
          <TableLibraryCell
            className={classNames(headerCellClassName, {
              [classes.sticky]: withHorizontalScroll,
              [classes.stickyLeft]: withHorizontalScroll
            })}
            paddingVariant={checkboxPaddingVariant}
            width={columnWidth.checkboxColumn}
          >
            <CheckboxSelectAll
              disabled={!hasEnabledCheckBox}
              className={classNames({
                [classes.disabled]: !hasEnabledCheckBox
              })}
              indeterminate={false}
              checked={allSelected}
              onChange={onSelectAllClick}
            />
          </TableLibraryCell>
        ) : hasMultiSelect && numSelected ? (
          <TableLibraryCell
            className={classNames(classes.bulkActionsWrapper, {
              [classes.sticky]: withHorizontalScroll,
              [classes.stickyLeft]: withHorizontalScroll
            })}
          >
            {bulkActions && bulkActions}
          </TableLibraryCell>
        ) : null}
        {hasEmptyHeadColumn && (
          <TableLibraryCell style={{ width: columnWidth.emptyHeadColumn }} />
        )}
        {noType ? null : (
          <TableLibraryCell
            style={{ width: noTypeColumnWidth || columnWidth.noTypeColumn }}
            padding="none"
          />
        )}

        {renderColumns({ columns: filteredStaticColumns, isStatic: true })}
        {renderColumns({ columns })}

        {isSsoVisible && (
          <TableLibraryCell align="center">
            <Text color="title.primary">SSO</Text>
          </TableLibraryCell>
        )}
        {editRows ? (
          <TableLibraryCell
            align="right"
            padding="none"
            style={{ width: columnWidth.actionsColumn }}
            className={classNames({
              [classes.sticky]: withHorizontalScroll,
              [classes.stickyRight]: withHorizontalScroll
            })}
            width={columnWidths.actionsColumn}
          >
            <div>
              <MaterialPopup
                placement="bottom-end"
                on="click"
                style={dropdownStyle}
                trigger={
                  <IconButton className={`hvr-grow ${classes.filterIconRoot}`}>
                    <i className="fa-regular fa-ellipsis-vertical" />
                  </IconButton>
                }
                overlayStyles={overlayStyles}
              >
                <DragDropContext onDragEnd={handleReorder}>
                  <Grid container className={classes.columnsContainer}>
                    {splitedColumns.displayed.map(
                      (chunk, chunkIndex, chunks) => (
                        <Droppable
                          key={`droppable-${chunkIndex}`}
                          droppableId={`droppable-${chunkIndex}-${
                            chunkIndex === 0
                              ? chunkIndex
                              : chunks
                                  .slice(0, chunkIndex)
                                  .reduce(
                                    (acc, next) => (acc += next.length),
                                    0
                                  )
                          }`}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              className={classes.columnsWrapper}
                            >
                              {chunk.map((column, index) => (
                                <Draggable
                                  key={`table-head-row-filter-${column.id}`}
                                  draggableId={`table-head-row-filter-${column.id}`}
                                  index={
                                    chunkIndex === 0
                                      ? index
                                      : chunks
                                          .slice(0, chunkIndex)
                                          .reduce(
                                            (acc, next) => (acc += next.length),
                                            index
                                          )
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <PortalAwareItem
                                      provided={provided}
                                      snapshot={snapshot}
                                      portal={portal}
                                    >
                                      <div
                                        className={classes.draggable}
                                        style={{
                                          width: dropdownColumnWidth
                                        }}
                                      >
                                        <DragIndicatorIcon
                                          classes={{
                                            root: classes.dragIcon
                                          }}
                                        />
                                        <CheckboxSwitcher
                                          key={`table-head-row-filter-${column.id}`}
                                          switchContainerClass={
                                            classes.filterSwitchContainer
                                          }
                                          formControlRootClass={
                                            classes.filterSwitchLabel
                                          }
                                          id="template-status"
                                          label={
                                            <TextSizeTooltip
                                              title={t(column.label)}
                                              maxTitleWidth={12}
                                              width={100}
                                            />
                                          }
                                          value={
                                            column.hasOwnProperty('display')
                                              ? column.display
                                              : filter(column.id)
                                          }
                                          handleChange={handleColumnDisplayChange(
                                            column.id
                                          )}
                                          isFormLabel={false}
                                          disabled={
                                            noHideColumns.includes(column.id) ||
                                            (column.display === false &&
                                              !canShowColumn(
                                                columnWidth[column.id]
                                              )) ||
                                            displayedColumns.length === 1
                                          }
                                          tooltip={
                                            column.display === false &&
                                            !canShowColumn(
                                              columnWidth[column.id]
                                            )
                                              ? maxColumnTooltip
                                              : ''
                                          }
                                          tooltipUnderLine={tooltipUnderLine}
                                        />
                                      </div>
                                    </PortalAwareItem>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )
                    )}
                    {splitedColumns.hidden.map((chunk, index) => (
                      <div
                        key={index}
                        className={classNames(classes.hiddenColumnsWrapper)}
                      >
                        {chunk.map((column, i) => (
                          <div
                            key={i}
                            style={{
                              width: dropdownColumnWidth,
                              marginLeft: dropdownColumnGap,
                              height: 38
                            }}
                          >
                            <CheckboxSwitcher
                              key={`table-head-row-filter-${column.id}`}
                              switchContainerClass={
                                classes.filterSwitchContainer
                              }
                              formControlRootClass={classNames(
                                classes.filterSwitchLabel,
                                classes.cursorPointer
                              )}
                              id="template-status"
                              label={
                                <TextSizeTooltip
                                  title={t(column.label)}
                                  maxTitleWidth={12}
                                  width={100}
                                />
                              }
                              value={
                                column.hasOwnProperty('display')
                                  ? column.display
                                  : filter(column.id)
                              }
                              handleChange={handleColumnDisplayChange(
                                column.id
                              )}
                              isFormLabel={false}
                              disabled={
                                !withHorizontalScroll &&
                                (noHideColumns.includes(column.id) ||
                                  (column.display === false &&
                                    !canShowColumn(columnWidth[column.id])))
                              }
                              tooltip={
                                column.display === false &&
                                !canShowColumn(columnWidth[column.id])
                                  ? maxColumnTooltip
                                  : ''
                              }
                              tooltipUnderLine={tooltipUnderLine}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </Grid>
                  {handleColumnReset && (
                    <Spacing
                      variant={0}
                      paddingHor={1}
                      paddingVert={1}
                      direction="row"
                      justifyContent={'flex-end'}
                    >
                      <WhiteButton
                        onClick={handleColumnReset}
                        iconClassName="fa-regular fa-circle-arrow-left"
                        variant="danger"
                      >
                        {t('Reset')}
                      </WhiteButton>
                    </Spacing>
                  )}
                </DragDropContext>
              </MaterialPopup>
            </div>
          </TableLibraryCell>
        ) : actionRow ? (
          <TableLibraryCell />
        ) : null}
      </TableRow>
    </TableHead>
  )
}

TableLibraryHead.propTypes = {
  onRequestSort: PropTypes.func,
  rowCount: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  allSelected: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  handleColumnChange: PropTypes.func,
  handleReorder: PropTypes.func,
  handleColumnReset: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  filter: PropTypes.func,
  tableMaxWidth: PropTypes.number,
  disableSort: PropTypes.bool,
  headerCellClassName: PropTypes.string,
  maxColumnTooltip: PropTypes.string,
  tooltipUnderLine: PropTypes.bool
}

export default compose(
  withTranslation('translations'),
  withStyles(tableHeadStyles),
  withSnackbar
)(TableLibraryHead)
