import React, { useCallback, useMemo } from 'react'
import {
  FormControl,
  InputLabel,
  Tooltip,
  withStyles,
  Grid
} from '@material-ui/core'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { simulateEvent } from 'utils'
import { WhiteButton } from 'components/Buttons'
import Error from './Error'

const styles = ({
  palette,
  type,
  spacing,
  fontSize,
  lineHeight,
  fontWeight,
  colors
}) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  rootContainer: {
    width: '100%'
  },
  formControlRoot: {
    width: '100%',
    position: 'relative'
  },
  formControlMargin: {
    marginBottom: spacing(2)
  },
  labelSmall: {
    fontSize: `${fontSize.small}px !important`
  },
  labelSmallest: {
    fontSize: `${fontSize.smallest}px !important`
  },
  bootstrapFormLabel: {
    fontSize: fontSize.primary,
    lineHeight: lineHeight.primary,
    fontWeight: fontWeight.normal,
    color: palette[type].formControls.label.color,
    transform: 'none'
  },
  labelRightComponentContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  bootstrapFormLabelFocus: {
    color: `${palette[type].formControls.label.color} !important`
  },
  rightLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center'
  },
  topLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  leftLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bottomLabel: {
    display: 'flex',
    flexDirection: 'column-reverse'
  },
  label: {
    position: 'unset !important'
  },
  alignLabel: {
    alignSelf: 'flex-start'
  },
  errorWithoutIcon: {
    marginLeft: 5
  },
  daySelectorContainer: {
    display: 'grid',
    grid: '1fr / repeat(7,32px)',
    gap: '5px',
    paddingTop: '8px'
  },
  daySelector: {
    background: palette[type].formControls.select.background,
    width: '32px',
    minWidth: '0px',
    height: '32px',
    borderRadius: '50%',
    border: `1px solid ${palette[type].formControls.input.border}`,
    padding: '0px',
    '&:before': {
      background: colors.highlight
    }
  },
  daySelectorLabel: {
    fontWeight: 'bold',
    lineHeight: '32px'
  },
  daySelected: {
    background: colors.highlight,
    color: '#FFF !important'
  },
  errorButton: {
    borderColor: `${colors.error} !important`,
    color: `${colors.error} !important`
  }
})

const FormControlDaysSelection = ({
  t,
  classes,
  id,
  label = '',
  isOptional = false,
  fullWidth = false,
  formControlContainerClass = '',
  formControlRootClass = '',
  formControlLabelClass = '',
  errorTextClass = '',
  icon = null,
  error = '',
  labelRightComponent = null,
  marginBottom = true,
  onClickLabel,
  labelPosition = 'top',
  tooltip = '',
  labelFontSizeVariant = 'primary',
  absoluteErrorText = true,
  daysValue = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  name,
  value = '',
  handleChange = f => f,
  showErrorText = true,
  touched = false
}) => {
  const showError = useMemo(() => {
    return !!(showErrorText && error && touched)
  }, [showErrorText, error, touched])

  const onChange = useCallback(
    day => () => {
      handleChange(
        simulateEvent(
          name,
          value.includes(day)
            ? value.filter(value => day !== value)
            : [...value, day]
        )
      )
    },
    [handleChange, value, name]
  )

  return (
    <div
      className={classNames(classes.root, formControlContainerClass, {
        [classes.rootContainer]: fullWidth
      })}
    >
      <FormControl
        className={classNames(classes.formControlRoot, formControlRootClass, {
          [classes.formControlMargin]:
            !absoluteErrorText && showError ? false : marginBottom,
          [classes.leftLabel]: labelPosition === 'left',
          [classes.topLabel]: labelPosition === 'top',
          [classes.bottomLabel]: labelPosition === 'bottom',
          [classes.rightLabel]: labelPosition === 'right'
        })}
      >
        {labelRightComponent && (
          <div className={classes.labelRightComponentContainer}>
            {labelRightComponent}
          </div>
        )}

        {label && (
          <Tooltip
            arrow
            title={tooltip}
            disableHoverListener={!tooltip}
            placement="top"
          >
            <InputLabel
              shrink
              htmlFor={id}
              className={classNames(
                classes.bootstrapFormLabel,
                formControlLabelClass,
                {
                  [classes.alignLabel]:
                    labelPosition === 'top' || labelPosition === 'bottom',
                  [classes.labelSmall]: labelFontSizeVariant === 'small',
                  [classes.labelSmallest]: labelFontSizeVariant === 'smallest'
                }
              )}
              classes={{
                focused: classes.bootstrapFormLabelFocus,
                root:
                  tooltip || !!onClickLabel ? classes.labelLink : classes.label
              }}
              onClick={() => onClickLabel && onClickLabel()}
            >
              {label} {isOptional && <i>({t('optional')})</i>}
            </InputLabel>
          </Tooltip>
        )}
        <Grid container className={classes.daySelectorContainer}>
          {daysValue.map(day => (
            <WhiteButton
              key={day}
              classes={{
                root: classNames(classes.daySelector, {
                  [classes.errorButton]: showError && !value.includes(day),
                  [classes.daySelected]: value.includes(day)
                }),
                label: classes.daySelectorLabel
              }}
              onClick={onChange(day)}
            >
              {day?.[0]?.toUpperCase()}
            </WhiteButton>
          ))}
        </Grid>
        <Error
          isOptional={isOptional}
          absolute={absoluteErrorText}
          condition={showError}
          error={error}
          rootClassName={classNames(
            {
              [classes.errorWithoutIcon]: !icon
            },
            errorTextClass
          )}
        />
      </FormControl>
    </div>
  )
}

FormControlDaysSelection.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array,
  isOptional: PropTypes.bool,
  fullWidth: PropTypes.bool,
  formControlContainerClass: PropTypes.string,
  formControlRootClass: PropTypes.string,
  formControlLabelClass: PropTypes.string,
  errorTextClass: PropTypes.string,
  handleChange: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.string,
  touched: PropTypes.bool,
  labelRightComponent: PropTypes.node,
  marginBottom: PropTypes.bool,
  labelPosition: PropTypes.string,
  tooltip: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onClickLabel: PropTypes.func,
  absoluteErrorText: PropTypes.bool,
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  daysValue: PropTypes.array,
  showErrorText: PropTypes.bool
}

export default withTranslation('translations')(
  withStyles(styles)(FormControlDaysSelection)
)
