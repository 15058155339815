import api from './api'

import { errorHandler } from '../utils'

const postInfo = async (data, role) => {
  try {
    const response = await api({
      method: 'POST',
      url: `${role.role === 'enterprise' ? 'org' : role.role}/feedback`,
      data: data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const feedbackService = {
  postInfo
}

export default feedbackService
