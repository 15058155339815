import React, { useCallback } from 'react'
import { withStyles } from '@material-ui/core'
import { _get } from 'utils/lodash'

import SendAlertViaRow from './SendAlertViaRow'
import { Card } from 'components/Card'
import Scrollbars from 'components/Scrollbars'
import BaseSection from '../BaseSection'
import { withTranslation } from 'react-i18next'

const styles = ({ palette, type }) => ({
  root: {
    margin: '0 0 15px',
    border: `5px solid ${palette[type].sideModal.content.border}`,
    background: palette[type].card.greyHeader.background,
    borderRadius: 4,
    maxHeight: 'calc(100% - 16px)',
    height: 'calc(100% - 78px)',
    padding: 0
  },
  sendViaWrapper: {
    padding: 15
  }
})

const SendAlertViaSection = ({
  values,
  errors,
  touched,
  setFieldValue = f => f,
  classes,
  t
}) => {
  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setFieldValue(`details.${name}`, value)
    },
    [setFieldValue]
  )

  return (
    <BaseSection
      title={t('Send Alert Via')}
      error={touched.details && errors.details?.root}
    >
      <Card
        icon={false}
        grayHeader
        shadow={false}
        radius={false}
        removeSidePaddings
        headerSidePaddings
        removeNegativeHeaderSideMargins
        rootClassName={classes.root}
      >
        <Scrollbars>
          <div className={classes.sendViaWrapper}>
            {Object.entries(values.details).map(([type, value]) => (
              <SendAlertViaRow
                key={`send-alert-via-${type}`}
                type={type}
                value={value}
                errors={_get(errors, `details.${type}`, {})}
                touched={_get(touched, `details.${type}`, {})}
                onChange={handleChange}
                t={t}
              />
            ))}
          </div>
        </Scrollbars>
      </Card>
    </BaseSection>
  )
}
export default withTranslation('translations')(
  withStyles(styles)(SendAlertViaSection)
)
