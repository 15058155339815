import update from 'immutability-helper'

import * as types from '../actions'
import {
  deleteInitialState,
  shapeOfBody,
  shapeOfBodyWithMeta,
  putInitialState,
  postInitialState,
  fetchParamsInitialState,
  shapeOfMeta
} from 'constants/initialLibraryState'
import sortConstants from 'constants/sortConstants'

const initialFetchParams = {
  ...fetchParamsInitialState,
  ...sortConstants.Schedule
}

const initialState = {
  library: shapeOfBodyWithMeta,
  calendarSchedules: {
    ...shapeOfBody,
    isLoading: false
  },
  listSchedule: shapeOfBodyWithMeta,
  items: shapeOfBodyWithMeta,
  timeline: {
    data: [],
    error: {},
    isLoading: true,
    ...shapeOfMeta
  },
  fetchParams: initialFetchParams,
  del: deleteInitialState,
  clone: {
    ...shapeOfBody,
    label: 'clone'
  },
  copy: {
    ...shapeOfBody,
    label: 'copy'
  },
  approve: {
    ...shapeOfBody,
    label: 'approve'
  },
  assignDevices: {
    ...shapeOfBody,
    label: 'assignDevices'
  },
  preference: {},
  groupItems: {},
  groupSchedules: shapeOfBodyWithMeta,
  postGroupItem: {},
  deleteGroupItem: {},
  scheduleItem: {
    status: null,
    response: null,
    error: null
  },
  put: putInitialState,
  post: postInitialState,
  postBulk: postInitialState,
  patch: putInitialState,
  preview: {
    id: null,
    isVisible: false,
    isLoading: false,
    response: null,
    error: null,
    key: '',
    orientation: ''
  },
  assignAvailability: {
    isFetching: false,
    isAvailable: false
  },
  cloneScheduleTimeline: {
    ...shapeOfBody,
    label: 'clone'
  }
}

const scheduleReducer = (state = initialState, action) => {
  let key = ''
  switch (action.type) {
    case types.APPROVE_SCHEDULE_SUCCESS:
      return update(state, {
        approve: {
          response: { $set: action.payload }
        }
      })
    case types.APPROVE_SCHEDULE_ERROR:
      return update(state, {
        approve: {
          error: { $set: action.payload }
        }
      })

    case types.ASSIGN_DEVICES_SCHEDULE_SUCCESS:
      return update(state, {
        assignDevices: {
          status: { $set: 'success' }
        }
      })
    case types.ASSIGN_DEVICES_SCHEDULE_ERROR:
      return update(state, {
        assignDevices: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        },
        scheduleItem: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })

    case types.CLEAR_ADDED_SCHEDULE:
      return update(state, {
        scheduleItem: { $set: initialState.scheduleItem }
      })
    case types.CLEAR_SCHEDULE_STATUS:
      return update(state, {
        scheduleItem: {
          status: { $set: null }
        }
      })
    case types.CLEAR_SCHEDULE_PUT_POST_STATUS:
      return update(state, {
        put: {
          response: {
            status: { $set: null }
          }
        },
        post: {
          response: {
            status: { $set: null }
          }
        }
      })
    case types.GET_SCHEDULE_BY_ID:
      return update(state, {
        scheduleItem: {
          isFetching: { $set: true },
          error: { $set: null }
        }
      })
    case types.GET_SCHEDULE_BY_ID_SUCCESS:
      return update(state, {
        scheduleItem: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_SCHEDULE_BY_ID_ERROR:
      return update(state, {
        scheduleItem: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.POST_SCHEDULE_SUCCESS:
      return update(state, {
        post: {
          response: { $set: action.payload }
        },
        scheduleItem: {
          status: { $set: 'successfully' }
        }
      })
    case types.PUT_SCHEDULE_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        },
        scheduleItem: {
          status: { $set: 'successfully' },
          name: { $set: action.payload.name }
        }
      })
    case types.CLEAR_SCHEDULE_ERROR:
      return update(state, {
        assignDevices: {
          status: { $set: null },
          error: { $set: null }
        },
        put: {
          status: { $set: null },
          error: { $set: null }
        },
        post: {
          status: { $set: null },
          error: { $set: null }
        },
        scheduleItem: {
          status: { $set: null },
          error: { $set: null }
        }
      })
    case types.POST_SCHEDULE_ERROR:
      return update(state, {
        post: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        },
        scheduleItem: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.PUT_SCHEDULE_ERROR:
      return update(state, {
        put: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        },
        scheduleItem: {
          status: { $set: 'error' },
          error: { $set: action.payload }
        }
      })
    case types.GET_SCHEDULE_ITEMS:
      key = !action?.isLibrary ? 'items' : 'library'
      return update(state, {
        [key]: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_SCHEDULE_ITEMS_SUCCESS:
      key = !action?.isLibrary ? 'items' : 'library'
      return update(state, {
        [key]: {
          response: { $set: action.payload.response },
          meta: {
            $set: action.payload.modifiedMeta
          }
        },
        ...(action.payload.storeFetchParams && {
          fetchParams: {
            $set: action.payload.params
          }
        })
      })
    case types.GET_SCHEDULE_ITEMS_ERROR:
      key = !action?.isLibrary ? 'items' : 'library'
      return update(state, {
        [key]: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.CLEAR_SCHEDULE_ITEMS: {
      return update(state, {
        library: { $set: shapeOfBodyWithMeta }
      })
    }
    case types.GET_SCHEDULE_LISTS:
      return update(state, {
        listSchedule: {
          isLoading: { $set: true }
        }
      })
    case types.GET_SCHEDULE_LISTS_SUCCESS:
      return update(state, {
        listSchedule: {
          response: { $set: action.payload.response },
          meta: {
            $set: action.payload.modifiedMeta
          },
          isLoading: { $set: false }
        }
      })
    case types.GET_SCHEDULE_LISTS_ERROR:
      return update(state, {
        listSchedule: {
          error: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.GET_SCHEDULE_TIMELINE:
      return update(state, {
        timeline: {
          isLoading: { $set: true }
        }
      })
    case types.GET_SCHEDULE_TIMELINE_SUCCESS:
      return update(state, {
        timeline: {
          data: { $set: action.payload.data },
          meta: {
            $set: action.payload.meta
          },
          isLoading: { $set: false }
        },
        fetchParams: { $set: action.payload.params }
      })
    case types.GET_SCHEDULE_TIMELINE_ERROR:
      return update(state, {
        timeline: {
          error: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.CLEAR_SCHEDULE_TIMELINE:
      return update(state, {
        timeline: {
          $set: initialState.timeline
        }
      })
    case types.GET_SCHEDULE_PREFERENCE_SUCCESS:
      return update(state, {
        preference: {
          response: { $set: action.payload }
        }
      })
    case types.GET_SCHEDULE_PREFERENCE_ERROR:
      return update(state, {
        preference: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_SCHEDULE_PREFERENCE_ERROR:
      return update(state, {
        performance: {
          error: { $set: action.payload }
        }
      })
    case types.GET_SCHEDULE_GROUP_ITEMS_SUCCESS:
      return update(state, {
        groupItems: {
          response: { $set: action.payload }
        }
      })
    case types.GET_SCHEDULE_GROUP_ITEMS_ERROR:
      return update(state, {
        groupItems: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_SCHEDULE_GROUP_ITEMS_INFO:
      return update(state, {
        groupItems: { $set: {} }
      })
    case types.CLEAR_SCHEDULE_GROUP_ITEMS_RESPONSE_INFO:
      return update(state, {
        $merge: {
          groupItems: {},
          postGroupItem: {},
          deleteGroupItem: {}
        }
      })
    case types.POST_SCHEDULE_GROUP_ITEM_SUCCESS:
      return update(state, {
        postGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.POST_SCHEDULE_GROUP_ITEM_ERROR:
      return update(state, {
        postGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_SCHEDULE_GROUP_ITEM_INFO:
      return update(state, {
        postGroupItem: { $set: {} }
      })
    case types.DELETE_SCHEDULE_GROUP_ITEM_SUCCESS:
      return update(state, {
        deleteGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_SCHEDULE_GROUP_ITEM_ERROR:
      return update(state, {
        deleteGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DELETE_SCHEDULE_GROUP_ITEM_INFO:
      return update(state, {
        deleteGroupItem: { $set: {} }
      })

    case types.DELETE_SCHEDULE_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_SCHEDULE_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })

    case types.CLONE_SCHEDULE_SUCCESS:
      return update(state, {
        clone: {
          response: { $set: action.payload }
        }
      })
    case types.CLONE_SCHEDULE_ERROR:
      return update(state, {
        clone: {
          error: { $set: action.payload }
        }
      })

    case types.COPY_SCHEDULE_SUCCESS:
      return update(state, {
        copy: {
          response: { $set: action.payload }
        }
      })
    case types.COPY_SCHEDULE_ERROR:
      return update(state, {
        copy: {
          error: { $set: action.payload }
        }
      })

    case types.DELETE_SELECTED_SCHEDULES_SUCCESS:
      return update(state, {
        del: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_SELECTED_SCHEDULES_ERROR:
      return update(state, {
        del: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_SCHEDULE_RESPONSE_INFO:
      return update(state, {
        del: {
          $set: deleteInitialState
        },
        clone: {
          $set: {
            ...shapeOfBody,
            label: 'clone'
          }
        },
        copy: {
          $set: {
            ...shapeOfBody,
            label: 'copy'
          }
        },
        approve: {
          $set: {
            ...shapeOfBody,
            label: 'approve'
          }
        },
        assignDevices: {
          $set: {
            ...shapeOfBody,
            label: 'assignDevices'
          }
        },
        put: { $set: putInitialState },
        post: { $set: postInitialState },
        patch: { $set: putInitialState },
        cloneScheduleTimeline: {
          $set: {
            ...shapeOfBody,
            label: 'clone'
          }
        }
      })

    case types.GET_SCHEDULE_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: action.data.id },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: action.meta.key },
          orientation: { $set: action.data.orientation }
        }
      })
    case types.GENERATE_SCHEDULE_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: null },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null },
          key: { $set: '' },
          orientation: { $set: action.data.orientation }
        }
      })
    case types.GET_SCHEDULE_PREVIEW_SUCCESS:
    case types.GENERATE_SCHEDULE_PREVIEW_SUCCESS:
      return update(state, {
        preview: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_SCHEDULE_PREVIEW_ERROR:
    case types.GENERATE_SCHEDULE_PREVIEW_ERROR:
      return update(state, {
        preview: {
          isVisible: { $set: false },
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.SHOW_SCHEDULE_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: true }
        }
      })
    case types.CLOSE_SCHEDULE_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: false }
        }
      })
    case types.CLEAR_SCHEDULE_PREVIEW:
      return update(state, {
        preview: {
          $merge: {
            id: null,
            isVisible: false,
            isLoading: false,
            response: null,
            error: null,
            key: '',
            orientation: ''
          }
        }
      })
    case types.CLEAR_SCHEDULE_FETCH_PARAMS:
      return update(state, {
        fetchParams: {
          $set: initialFetchParams
        }
      })
    case types.GET_SCHEDULE_GROUP_SCHEDULES:
      return update(state, {
        groupSchedules: {
          $set: initialState.groupSchedules
        }
      })
    case types.GET_SCHEDULE_GROUP_SCHEDULES_SUCCESS:
      return update(state, {
        groupSchedules: {
          $set: action.payload
        }
      })
    case types.GET_ASSIGNABLE_SCHEDULE:
      return update(state, {
        assignAvailability: { $set: { isFetching: true, isAvailable: false } }
      })
    case types.GET_ASSIGNABLE_SCHEDULE_SUCCESS:
      return update(state, {
        assignAvailability: {
          $set: { isFetching: false, isAvailable: !!action.payload.length }
        }
      })
    case types.GET_ASSIGNABLE_SCHEDULE_ERROR:
      return update(state, {
        assignAvailability: { $set: { isFetching: false, isAvailable: false } }
      })
    case types.PATCH_SCHEDULE_ITEM_SUCCESS:
      return update(state, {
        patch: {
          response: {
            $set: action.payload
          }
        }
      })
    case types.PATCH_SCHEDULE_ITEM_ERROR:
      return update(state, {
        patch: {
          error: {
            $set: action.payload
          }
        }
      })
    case types.CLONE_SCHEDULE_TIMELINE_SUCCESS:
      return update(state, {
        cloneScheduleTimeline: {
          response: { $set: action.payload }
        }
      })
    case types.CLONE_SCHEDULE_TIMELINE_ERROR:
      return update(state, {
        cloneScheduleTimeline: {
          error: { $set: action.payload }
        }
      })
    case types.GET_CALENDAR_SCHEDULES:
      return update(state, {
        calendarSchedules: {
          error: { $set: initialState.calendarSchedules.error },
          isLoading: { $set: true }
        }
      })
    case types.GET_CALENDAR_SCHEDULES_SUCCESS:
      return update(state, {
        calendarSchedules: {
          response: { $set: action.payload.response },
          isLoading: { $set: false }
        }
      })
    case types.GET_CALENDAR_SCHEDULES_ERROR:
      return update(state, {
        calendarSchedules: {
          error: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.CLEAR_CALENDAR_SCHEDULES:
      return update(state, {
        calendarSchedules: {
          $set: initialState.calendarSchedules
        }
      })
    case types.POST_SCHEDULE_BULK_SUCCESS:
      return update(state, {
        postBulk: {
          response: { $set: action.payload }
        }
      })
    case types.POST_SCHEDULE_BULK_ERROR:
      return update(state, {
        postBulk: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_SCHEDULE_BULK_INFO:
      return update(state, {
        postBulk: {
          $set: postInitialState
        }
      })
    default:
      return state
  }
}

export default scheduleReducer
