import { withTranslation } from 'react-i18next'
import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles
} from '@material-ui/core'
import { ReactComponent as TrophyIcon } from 'common/assets/icons/trophy.svg'
import { ReactComponent as TickIcon } from 'common/assets/icons/tickIcon.svg'
import List from 'components/List'
import Text from 'components/Typography/Text'
import React from 'react'

const styles = ({ colors }) => {
  return {
    trophyWrapper: {
      height: '32px',
      width: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 auto'
    },
    headerText: {
      color: '#EFC02E',
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '400',
      textTransform: 'uppercase',
      margin: '12px 0 12px 0'
    },
    headerTextSignature: {
      color: colors.white,
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: '700',
      textTransform: 'uppercase',
      margin: '0 0 12px 0'
    },
    detailsText: {
      color: colors.white,
      textAlign: 'center',
      fontFamily: 'Nunito Sans',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: 'normal',
      maxWidth: '297px',
      margin: '0 auto 24px auto'
    },
    listWrap: {
      color: colors.white,
      fontFamily: 'Nunito Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '22px',
      margin: '0 auto 0px auto',
      width: 'fit-content',
      padding: '0',
      background: 'transparent'
    },
    detailsMainWrap: {
      padding: '24px 0px'
    },
    messageError: {
      color: colors.error,
      paddingLeft: '8px',
      fontSize: 'x-small',
      height: '0px'
    },
    listItemWrap: {
      padding: '0'
    },
    listIcon: {
      marginRight: '0',
      paddingRight: '12px'
    },
    listText: {
      margin: '0',
      padding: '3px 0px'
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '24px'
    },
    contactSalesButton: {
      background: '#EA8537',
      color: colors.white,
      padding: '8px 16px',
      textTransform: 'capitalize',
      fontSize: '16px',
      fontWeight: 700,
      border: '1px solid transparent',
      position: 'relative',
      zIndex: '1',
      '&:hover': {
        background: 'transparent',
        border: '1px solid #EA8537'
      }
    },
    sendRequestButton: {
      color: colors.white,
      padding: '8px 16px',
      background: 'linear-gradient(167.95deg, #00E7C1 0%, #00D0E6 100%)',
      fontSize: '16px',
      fontWeight: 700,
      textTransform: 'capitalize',
      border: '1px solid transparent',
      position: 'relative',
      zIndex: '1',
      '&:hover': {
        background: 'transparent',
        border: '1px solid #00D0E6'
      }
    },
    textareaWrap: {
      padding: '0px 40px',
      '& textarea': {
        width: '100%',
        height: '178px',
        border: `1px solid #00E7C1`,
        borderRadius: '8px',
        backgroundColor: 'transparent',
        padding: '12px 16px',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Nunito Sans',
        color: colors.white,
        '&::placeholder': {
          color: '#c3c3c3'
        }
      }
    },
    errorTextWrap: {
      padding: '0px 40px',
      '& textarea': {
        width: '100%',
        height: '178px',
        border: `1px solid ${colors.error}`,
        borderRadius: '8px',
        backgroundColor: 'transparent',
        padding: '12px 16px',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Nunito Sans',
        color: colors.white,
        '&::placeholder': {
          color: '#c3c3c3'
        }
      }
    }
  }
}
const UpgradeRequestModal = ({
  t,
  classes,
  form,
  setRequestModal,
  openRequestModal
}) => {
  const listItems = [
    t('Lifetime warranty on all Players'),
    t('Free Replacement of Players, anytime'),
    t('New Player Hardware every 3-yrs'),
    t('Eliminate Deprecated OS, Browser, Hardware'),
    t('Free Personalized Trainings, unlimited'),
    t('Cross-Ships / Advance Replacements'),
    t('Dedicated Client Success Team'),
    t('Phone Support')
  ]

  const onClickContactSales = () => {
    setRequestModal(true)
  }
  return (
    <div>
      <div className={classes.detailsMainWrap}>
        <div>
          <div className={classes.trophyWrapper}>
            <TrophyIcon />
          </div>

          <Text component="h3" rootClassName={classes.headerText}>
            {t('Lifetime Warranties')}
          </Text>
          <Text component="h2" rootClassName={classes.headerTextSignature}>
            {t('Signature Care')}
          </Text>
          <Text rootClassName={classes.detailsText}>
            {t('Ensuring a Secure and Compliant Network')}
          </Text>
        </div>

        <div>
          {!openRequestModal ? (
            <div>
              <List className={classes.listWrap}>
                {listItems.map((item, index) => (
                  <ListItem
                    key={index}
                    disableGutters
                    className={classes.listItemWrap}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <TickIcon />
                    </ListItemIcon>
                    <ListItemText primary={item} className={classes.listText} />
                  </ListItem>
                ))}
              </List>
              <div className={classes.buttonWrap}>
                <Button
                  className={classes.contactSalesButton}
                  onClick={onClickContactSales}
                >
                  {t(`Contact Us`)}
                </Button>
              </div>
            </div>
          ) : (
            <div
              className={[
                form.errors.message
                  ? classes.errorTextWrap
                  : classes.textareaWrap
              ]}
            >
              <textarea
                rows="8"
                cols="20"
                className={[classes.textarea].join(' ')}
                placeholder={t('Interested in Signature Care?')}
                name="message"
                value={form.values.message}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
              {form.errors.message && form.touched.message && (
                <Typography className={classes.messageError}>
                  {form.errors.message}
                </Typography>
              )}
              <div className={classes.buttonWrap}>
                <Button
                  className={classes.sendRequestButton}
                  onClick={form.handleSubmit}
                >
                  {t(`Send Request`)}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default withTranslation('translations')(
  withStyles(styles)(UpgradeRequestModal)
)
