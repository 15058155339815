import React from 'react'
import { withStyles } from '@material-ui/core'
import { Route } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import AccountModal from '../AccountModal'
import RecoveryForm from './RecoveryForm'
import ResetForm from './ResetForm'
import ExpiredForm from './ExpiredForm'

function styles() {
  return {
    formWrap: {
      paddingTop: 120
    }
  }
}

function ForgotPassword({ classes }) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
    >
      <AccountModal formWrapClassName={classes.formWrap}>
        <Route path="/forgot-password" component={RecoveryForm} />
        <Route path="/system/forgot-password" component={RecoveryForm} />
        <Route
          path={[
            '/password-reset/:token/:email',
            '/system/password-reset/:token/:email'
          ]}
          component={ResetForm}
        />
        <Route path="/password-expired/:token/:email" component={ExpiredForm} />
      </AccountModal>
    </GoogleReCaptchaProvider>
  )
}

export default withStyles(styles)(ForgotPassword)
