import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'

const useStyles = makeStyles({
  dotFlashing: {
    margin: '0 auto',
    position: 'relative',
    width: '8px',
    height: '8px',
    borderRadius: '5px',
    backgroundColor: '#s',
    color: '#s',
    animation: '$flash 1s infinite linear alternate',
    animationDelay: '0.5s',

    '&::before, &::after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: '0'
    },

    '&::before': {
      left: '-12px',
      width: '8px',
      height: '8px',
      borderRadius: '5px',
      backgroundColor: '#1c5dca52',
      color: '#1c5dca52',
      animation: '$flash 1s infinite alternate',
      animationDelay: '0s'
    },

    '&::after': {
      left: '12px',
      width: '8px',
      height: '8px',
      borderRadius: '5px',
      backgroundColor: '#1c5dca52',
      color: '#1c5dca52',
      animation: '$flash 1s infinite alternate',
      animationDelay: '1s'
    }
  },
  '@keyframes flash': {
    '0%': {
      backgroundColor: '#1c5dca'
    },
    '50%, 100%': {
      backgroundColor: ' #1c5dca52'
    }
  }
})

function ProcessingFlashDots({ headerText, title }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Tooltip
      arrow
      withHeader
      headerText={headerText || t('Optimizing Media')}
      title={title || t('file_is_being_optimized_message')}
      arrowWithHeaderColor
    >
      <div className={classes.dotFlashing} />
    </Tooltip>
  )
}

export default ProcessingFlashDots
