import { createSelector } from 'reselect'

export function _scheduleItemSelector({ schedule }) {
  return schedule.scheduleItem
}

export const scheduleItemSelector = createSelector(
  _scheduleItemSelector,
  item => item
)

export function schedulesSelector({ schedule }) {
  return schedule.library
}

export const scheduleAssignAvailabilitySelector = ({ schedule }) =>
  schedule.assignAvailability

export function scheduleTimelineSelector({ schedule }) {
  return schedule.timeline
}

export function calendarSchedulesSelector({ schedule }) {
  return schedule.calendarSchedules
}
