import update from 'immutability-helper'

import * as types from '../actions'

const initialState = {
  isScrolling: false
}

const scrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDLE_IS_SCROLLING:
      return update(state, {
        isScrolling: { $set: action.isScrolling }
      })
    default:
      return state
  }
}

export default scrollReducer
