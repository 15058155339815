import React, { memo, useCallback, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import _get from 'lodash/get'
import classNames from 'classnames'

import Icon from 'components/Icons/Icon'
import Text from 'components/Typography/Text'
import { GroupChip, TagChip } from 'components/Chip'
import GreyCard from 'components/Card/GreyCard'
import DependencyRow from 'components/Modal/DependencyModal/DependencyRow'
import Spacing from 'components/Containers/Spacing'
import { scheduleConstants } from 'constants/index'
import { SCHEDULE_MAX_TIME, SCHEDULE_MIN_TIME } from 'constants/schedule'
import { resourceTypes } from 'constants/api'
import { TextWithTooltip } from 'components/Typography'

const useStyles = makeStyles(({ palette, type, colors }) => ({
  listItem: {
    justifyContent: 'center',
    minWidth: '81px !important',
    borderBottom: `1px solid ${palette[type].sideModal.content.border}`
  },
  customChip: {
    '& > div': {
      maxWidth: '81px !important'
    }
  },
  icon: {
    fontSize: '18px',
    color: colors.light,
    margin: '0 4px'
  }
}))

const ConflictDetailsCard = ({ data, resource, handlePreviewClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderEntity = useCallback(
    ({ group, tag, location }) => {
      switch (resource) {
        case resourceTypes.GroupResource:
          return (
            <TextWithTooltip title={group.title} maxWidth={81}>
              <div className={classes.customChip}>
                <GroupChip label={group.title} color={group.color} />
              </div>
            </TextWithTooltip>
          )
        case resourceTypes.TagWithReportResource:
          return (
            <TextWithTooltip title={tag.title} maxWidth={81}>
              <div className={classes.customChip}>
                <TagChip tag={tag} />
              </div>
            </TextWithTooltip>
          )
        case resourceTypes.DeviceLocationResource:
          return (
            <Grid container alignItems="center">
              <i className={classNames(classes.icon, location?.icon)} />
              <TextWithTooltip maxWidth={55}>{location?.name}</TextWithTooltip>
            </Grid>
          )
        default:
          return <Text>{group.title || tag.title}</Text>
      }
    },
    [resource, classes]
  )

  const cardText = useMemo(() => {
    switch (resource) {
      case resourceTypes.GroupResource:
        return `${t(
          'Schedules will be removed from the following'
        )} ${t('group', { count: data.length })}:`
      case resourceTypes.TagWithReportResource:
        return `${t('Schedules will be removed from the following')} ${t(
          'tag',
          { count: data.length }
        )}:`
      case resourceTypes.DeviceLocationResource:
        return `${t(
          'Schedules will be removed from the following'
        )} ${t('location', { count: data.length })}:`
      default:
        return ''
    }
  }, [resource, data, t])

  return (
    <>
      <GreyCard
        header={false}
        marginBottom
        contentSpacingProps={{
          paddingVert: 1,
          paddingHor: 1
        }}
      >
        <Grid container>
          <Grid container item xs={1}>
            <Icon icon="fa-regular fa-circle-info" color="blue" />
          </Grid>
          <Grid container item xs={11}>
            <Text color="light">{cardText}</Text>
            {data.map((row, index) => (
              <Spacing
                item
                container={false}
                direction={undefined}
                variant={0}
                paddingLeft={1}
                key={index}
              >
                {renderEntity(row)}
              </Spacing>
            ))}
          </Grid>
        </Grid>
      </GreyCard>
      {data
        .filter(({ schedule }) => !!schedule)
        .map(({ schedule, ...rest }, index) => (
          <Grid container wrap="nowrap" key={`dependency-${index}`}>
            <Grid
              item
              container
              alignItems="center"
              xs={2}
              className={classes.listItem}
            >
              {renderEntity(rest)}
            </Grid>
            <DependencyRow
              item={{
                icon:
                  scheduleConstants.scheduleTypes[
                    _get(schedule, 'scheduleType', 'Timed')
                  ],
                title: schedule.title,
                subTitle: schedule.allDay
                  ? `${SCHEDULE_MIN_TIME} - ${SCHEDULE_MAX_TIME}`
                  : `${schedule.startTime} - ${schedule.endTime}`,
                date: schedule.updatedAt
              }}
              onPreviewClick={() =>
                handlePreviewClick({
                  id: schedule.id,
                  orientation: schedule.orientation
                })
              }
            />
          </Grid>
        ))}
    </>
  )
}

export default memo(ConflictDetailsCard)
