import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { useTranslation, withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { CircleLibraryIconButton } from 'components/Buttons'
import { useEventListener } from 'hooks'

const styles = ({ palette, type }) => ({
  button: {
    background: `${palette[type].tableLibrary.footer.pagination.button.background}`,
    border: `${palette[type].tableLibrary.footer.pagination.button.border}`,
    color: `${palette[type].tableLibrary.footer.pagination.button.color}`,
    shadow: `${palette[type].tableLibrary.footer.pagination.button.shadow}`
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    justifyItems: 'center',
    color: `${palette[type].tableLibrary.footer.pagination.button.color}`,
    borderRight: `1px solid ${palette[type].tableLibrary.footer.pagination.border}`,
    borderLeft: `1px solid ${palette[type].tableLibrary.footer.pagination.border}`,
    padding: '0 15px',
    height: '100%'
  },
  noSubheader: {
    borderRight: 'unset',
    paddingRight: '0px',
    gap: '0px'
  },
  buttonSize: {
    fontSize: '14px'
  },
  arrowDisabled: {
    color: `${palette[type].tableLibrary.footer.pagination.button.disabled.color}`
  },
  itemsInfo: {
    marginTop: '4px'
  }
})

const SubheaderPagination = ({
  classes,
  paginationObj = {},
  handlePageChange,
  subHeaderPresent = true
}) => {
  const { t } = useTranslation()
  const {
    from: startingFrom = 0,
    to: endingTo = 0,
    total: totalItems = 0,
    currentPage: currentPageNo,
    lastPage,
    loading
  } = paginationObj
  const isMetaNull = Object.values(paginationObj).some(value => value === null)
  const isStartingPage = currentPageNo + 1 === 1
  const isLastPage = currentPageNo + 1 === lastPage
  const handleKeyDown = ({ key, target }) => {
    if (!['ArrowLeft', 'ArrowRight'].includes(key)) {
      return
    }

    const { type, tagName } = target
    const allowedTags = ['TR', 'A', 'BUTTON', 'BODY']
    const isCheckbox = type === 'checkbox'
    const sideModel = document.getElementById('sideModal')
    const defaultModal = document.getElementById('defaultModal')

    if (
      (allowedTags.includes(tagName) || isCheckbox) &&
      !(sideModel || defaultModal)
    ) {
      if (key === 'ArrowLeft' && !isStartingPage && !loading) {
        handlePageChange(currentPageNo - 1)
      }
      if (key === 'ArrowRight' && !isLastPage && !loading) {
        handlePageChange(currentPageNo + 1)
      }
    }
  }

  useEventListener('keydown', handleKeyDown)

  return !loading && !isMetaNull ? (
    <Grid item>
      <div
        className={classNames(classes.pagination, {
          [classes.noSubheader]: !subHeaderPresent
        })}
      >
        <Typography className={classes.itemsInfo}>
          {startingFrom} - {endingTo} {t('of')} {totalItems}
        </Typography>
        <CircleLibraryIconButton
          onClick={() => {
            handlePageChange(currentPageNo - 1)
          }}
          disabled={isStartingPage}
          className={classes.button}
        >
          <KeyboardArrowLeft
            className={classNames({ [classes.arrowDisabled]: isStartingPage })}
          />
        </CircleLibraryIconButton>
        <CircleLibraryIconButton
          onClick={() => handlePageChange(currentPageNo + 1)}
          disabled={isLastPage}
          className={classes.button}
        >
          <KeyboardArrowRight
            className={classNames({ [classes.arrowDisabled]: isLastPage })}
          />
        </CircleLibraryIconButton>
      </div>
    </Grid>
  ) : null
}

SubheaderPagination.propTypes = {
  paginationObj: PropTypes.object.isRequired
}

export default withTranslation('translations')(
  withStyles(styles)(SubheaderPagination)
)
