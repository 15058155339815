import update from 'immutability-helper'
import * as types from 'actions'
import {
  shapeOfMeta,
  shapeOfBody,
  shapeOfBodyWithMeta
} from 'constants/initialLibraryState'

const initialState = {
  items: [],
  ...shapeOfMeta,
  associatedClients: {
    items: {
      ...shapeOfBodyWithMeta
    },
    post: {
      ...shapeOfBody,
      label: 'associate'
    }
  }
}

const resellerClientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESELLER_CLIENTS:
      return update(state, {
        meta: {
          isLoading: { $set: true }
        }
      })
    case types.GET_RESELLER_CLIENTS_SUCCESS:
      return update(state, {
        $set: {
          items: action.response,
          meta: action.modifiedMeta
        }
      })
    case types.GET_RESELLER_CLIENTS_ERROR:
      return update(state, {
        items: {
          error: { $set: action.payload }
        }
      })
    case types.GET_ASSOCIATED_CLIENTS_SUCCESS:
      return update(state, {
        associatedClients: {
          items: {
            response: {
              $set: action.response
            },
            meta: {
              $set: action.modifiedMeta
            }
          }
        }
      })
    case types.GET_ASSOCIATED_CLIENTS_ERROR:
      return update(state, {
        associatedClients: {
          items: {
            error: { $set: action.payload }
          }
        }
      })
    case types.POST_ASSOCIATED_CLIENTS_SUCCESS:
      return update(state, {
        associatedClients: {
          post: {
            response: {
              $set: action.payload
            }
          }
        }
      })
    case types.POST_ASSOCIATED_CLIENTS_ERROR:
      return update(state, {
        associatedClients: {
          post: {
            error: { $set: action.payload }
          }
        }
      })
    case types.CLEAR_ASSOCIATED_CLIENTS_RESPONSE:
      return update(state, {
        associatedClients: {
          items: {
            $set: {
              ...shapeOfBodyWithMeta
            }
          },
          post: {
            $set: {
              ...shapeOfBody,
              label: 'associate'
            }
          }
        }
      })
    default:
      return state
  }
}

export default resellerClientsReducer
