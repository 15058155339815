import { UIPlugin } from '@uppy/core'
import { pluginImageEditorOpts } from 'constants/uppyConstants'
import { isEditableImage } from 'utils/uppy'

class UppyPluginImageEditor extends UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts)
    this.id = pluginImageEditorOpts.id
    this.title = pluginImageEditorOpts.title
    this.type = pluginImageEditorOpts.type

    this.opts = {
      ...opts
    }

    this.initialState = {
      currentImage: null
    }
  }

  setOptions(newOpts) {
    super.setOptions(newOpts)
  }

  canEditFile(file) {
    if (!file.type || file.isRemote) {
      return false
    }

    return isEditableImage(file)
  }

  close = () => {
    this.uppy.emit('image-editor:close')
  }

  save = blob => {
    const { currentImage } = this.getPluginState()

    this.uppy.setFileState(currentImage.id, {
      data: blob,
      size: blob.size,
      preview: null
    })

    const updatedFile = this.uppy.getFile(currentImage.id)
    this.setPluginState({
      currentImage: updatedFile
    })
    this.uppy.emit('image-editor:complete', updatedFile)
  }

  selectFile = file => {
    this.uppy.emit('image-editor:start', file)
    this.setPluginState({
      currentImage: file
    })
  }

  install() {
    this.setPluginState(this.initialState)
  }

  uninstall() {}

  mount(target) {
    super.mount(target, this)
  }

  render(state) {}
}

export default UppyPluginImageEditor
