import React, { useCallback } from 'react'
import { FormControlAutocomplete } from 'components/Form/index'
import { countries } from 'constants/countryConstants'

const FormControlCountrySelect = ({ handleChange, ...props }) => {
  const getCountryOptions = useCallback(
    async () => countries.map(i => ({ value: i.label, label: i.label })),
    []
  )

  return (
    <FormControlAutocomplete
      {...props}
      fullWidth
      isClearable
      getOptions={getCountryOptions}
      handleChange={handleChange}
    />
  )
}

export default FormControlCountrySelect
