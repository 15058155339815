import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { capitalize } from 'utils'

const DeviceLogLevelCell = ({ value, column }) => {
  const { t } = useTranslation()

  return (
    <Grid container direction="column" alignItems={column.colDef.align}>
      {!!value ? capitalize(value) : t('N/A')}
    </Grid>
  )
}

export default DeviceLogLevelCell
