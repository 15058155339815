import {
  DEVICE_TABLE,
  ALIAS_FIELD,
  CLIENT_TABLE,
  TITLE_FIELD,
  NAME_FIELD,
  TYPE_FIELD,
  ADDRESS_FIELD,
  DEVICE_CONNECT_TABLE,
  CONNECT_MAC_FIELD,
  TIMEZONE_FIELD
} from './report'
import {
  getMacAddressOptions,
  getOptionsByFieldAndEntity,
  getTimeZoneOptionsForEmailNotification
} from 'services/getOptions'
import { transformDataToOptions } from 'utils/transformToOptionsUtils'

export const DEVICE_TYPE_TABLE = 'deviceTypes'
export const CLIENT_PACKAGE_TABLE = 'clientPackage'
export const TEMPLATE_TABLE = 'template'
export const TEMPLATE_BACKGROUND_TABLE = 'templateBackground'
export const USER_TABLE = 'user'
export const ORG_ROLE = 'configOrgRole'
export const SCHEDULE_TABLE = 'schedule'
export const TIME_ZONE_TABLE = 'emailNotificationTimeZone'

export const DEVICE_TYPE = 'deviceType'
export const PACKAGE_NAME_FIELD = 'packageName'
export const BACKGROUND_PATTERN_FIELD = 'backgroundPattern'
export const ROLE_FIELD = 'role'
export const DISPLAY_NAME_FIELD = 'displayName'
export const WORKING_DAYS_FIELD = 'workingDays'
export const DEVICE_TIMEZONE_FIELD = 'deviceTimezone'
export const MAC_FIELD = 'mac'

export const CUSTOM_RECEIVER_OPTION = 'custom'

export const getRelatedTableOptions = {
  [DEVICE_TABLE]: {
    [DEVICE_TYPE]: getOptionsByFieldAndEntity(
      [ALIAS_FIELD],
      DEVICE_TYPE_TABLE,
      transformDataToOptions(null, [ALIAS_FIELD], ' ')
    ),
    [CONNECT_MAC_FIELD]: getMacAddressOptions,
    [TIMEZONE_FIELD]: getTimeZoneOptionsForEmailNotification
  },
  [DEVICE_CONNECT_TABLE]: {
    [DEVICE_TIMEZONE_FIELD]: getTimeZoneOptionsForEmailNotification,
    [MAC_FIELD]: getMacAddressOptions
  },
  [CLIENT_TABLE]: {
    [PACKAGE_NAME_FIELD]: getOptionsByFieldAndEntity(
      [TITLE_FIELD],
      CLIENT_PACKAGE_TABLE,
      transformDataToOptions(null, [TITLE_FIELD], ' ')
    )
  },
  [TEMPLATE_TABLE]: {
    [BACKGROUND_PATTERN_FIELD]: getOptionsByFieldAndEntity(
      [NAME_FIELD],
      TEMPLATE_BACKGROUND_TABLE,
      transformDataToOptions(null, [NAME_FIELD], ' ')
    )
  },
  [USER_TABLE]: {
    [ROLE_FIELD]: getOptionsByFieldAndEntity(
      [DISPLAY_NAME_FIELD],
      ORG_ROLE,
      transformDataToOptions(null, [DISPLAY_NAME_FIELD], ' ')
    )
  },
  [SCHEDULE_TABLE]: {
    [TITLE_FIELD]: null,
    [WORKING_DAYS_FIELD]: null
  }
}

export const rules = {
  and: 'AND',
  or: 'OR'
}

export const fieldTypes = {
  numeric: 'numeric',
  text: 'text',
  dateTime: 'dateTime',
  boolean: 'boolean',
  date: 'date',
  time: 'time',
  json: 'json'
}

export const operatorTypesMapping = {
  int: fieldTypes.numeric,
  string: fieldTypes.text,
  datetime: [fieldTypes.date, fieldTypes.time],
  json: fieldTypes.text,
  boolean: fieldTypes.boolean,
  dateTime: [fieldTypes.date, fieldTypes.time],
  date: fieldTypes.date,
  time: fieldTypes.time,
  float: fieldTypes.numeric,
  double: fieldTypes.numeric,
  bool: fieldTypes.boolean
}

export const fieldTypesMapping = {
  int: fieldTypes.numeric,
  string: fieldTypes.text,
  datetime: fieldTypes.dateTime,
  json: fieldTypes.json,
  boolean: fieldTypes.boolean,
  dateTime: fieldTypes.dateTime,
  date: fieldTypes.date,
  time: fieldTypes.time,
  float: fieldTypes.numeric,
  double: fieldTypes.numeric,
  bool: fieldTypes.boolean
}

export const booleanOptions = [
  {
    value: 'true',
    label: 'true'
  },
  {
    value: 'false',
    label: 'false'
  }
]

export const relatedTableStaticOptionsMapping = {
  [USER_TABLE]: [ROLE_FIELD],
  [DEVICE_CONNECT_TABLE]: [DEVICE_TIMEZONE_FIELD],
  [DEVICE_TABLE]: [TIMEZONE_FIELD]
}

export const relatedTableNotCreateableFieldMapping = {
  [CLIENT_TABLE]: [PACKAGE_NAME_FIELD, TYPE_FIELD],
  [DEVICE_TABLE]: [ADDRESS_FIELD, NAME_FIELD, DEVICE_TYPE],
  [SCHEDULE_TABLE]: [WORKING_DAYS_FIELD]
}

export const relatedTablePassStaticProps = {
  [SCHEDULE_TABLE]: {
    [WORKING_DAYS_FIELD]: {
      options: [
        {
          label: 'Sunday',
          value: 'Sun',
          sortOrder: 1
        },
        {
          label: 'Monday',
          value: 'Mon',
          sortOrder: 2
        },
        {
          label: 'Tuesday',
          value: 'Tue',
          sortOrder: 3
        },
        {
          label: 'Wednesday',
          value: 'Wed',
          sortOrder: 4
        },
        {
          label: 'Thursday',
          value: 'Thu',
          sortOrder: 5
        },
        {
          label: 'Friday',
          value: 'Fri',
          sortOrder: 6
        },
        {
          label: 'Saturday',
          value: 'Sat',
          sortOrder: 7
        }
      ],
      isMulti: true,
      isSort: false
    }
  }
}

export const emailTemplateTypes = {
  predefined: 'Predefined',
  special: 'Special'
}

export const emailTemplateModes = {
  edit: 'edit',
  clone: 'clone',
  add: 'add'
}
