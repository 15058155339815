import { createSelector } from 'reselect'

function _loginSelector(state) {
  return state.new.auth.login
}

function _ssoSelector(state) {
  return state.new.auth.sso
}

function _twoFactorSelector(state) {
  return state.new.auth.twoFactor
}

export const loginSelector = createSelector(_loginSelector, login => login)
export const ssoSelector = createSelector(_ssoSelector, sso => sso)
export const twoFactorSelector = createSelector(
  _twoFactorSelector,
  twoFactor => twoFactor
)

export function verifyEmailSelector(state) {
  return state.new.auth.verify
}

export function resetPasswordSelector(state) {
  return state.resetPassword
}
