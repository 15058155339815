import axios from 'axios'

import api from './api'
import { errorHandler } from '../utils'
import { getApiParamsByOriginalUser } from '../utils/localStorage'

const getConfigOrgRole = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/org/role'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigEnterpriseRole = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/enterprise/role'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigResellerRole = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/reseller/role'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigSystemRole = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/system/role'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigClientType = async impersonated => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/client/type',
      ...(impersonated && getApiParamsByOriginalUser('/config/client/type'))
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigDeviceType = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/device/type'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigFeatureClient = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/feature/client'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigFeatureDevice = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/feature/device'
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigFeatureMedia = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/feature/media',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getConfigMediaCategory = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/mediaCategory'
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getThemeOfMediaFeatureById = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/config/mediaFeature/${id}/theme`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getContentSourceOfMediaFeatureById = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/config/mediaFeature/${id}/contentSource`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMediaCategory = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/mediaCategory'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getLocation = async (params, silent) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/config/findLocation`,
      params,
      silent
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getCity = async (params, silent) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/config/findCity`,
      params,
      silent
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getTransitions = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/transition'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAlertTypes = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/alertType'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAirports = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/airport',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAirlines = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/airline',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getLocationInfo = async location => {
  try {
    return await api({
      method: 'POST',
      url: '/locationInfo',
      data: {
        location
      }
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getBackgroundPattern = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/backgroundPattern',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getBackgroundPatternById = async ({ id }) => {
  try {
    return await api({
      method: 'GET',
      url: `/config/backgroundPattern/${id}`
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getBackgroundImagesFromMedia = async () => {
  try {
    return await api({
      method: 'GET',
      url: '/config/feature/media?includeAll=1'
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getCurrency = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/config/currency'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getYoutubeCaptions = async id => {
  try {
    const response = await axios.get(
      `https://youtube.googleapis.com/youtube/v3/captions?part=snippet&videoId=${id}&key=${process.env.REACT_APP_YOUTUBE_CAPTION_VALIDATION_API_KEY}`
    )
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getOauth2Tokens = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/oauth2',
      params
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postFacebookAccount = async data => {
  try {
    await api({
      method: 'POST',
      url: '/oauth2/facebook',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const postInstagramAccount = async data => {
  try {
    await api({
      method: 'POST',
      url: '/oauth2/instagram',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const postGoogleDocsAccount = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/oauth2/GoogleDocs',
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putOauth2Token = async (id, data) => {
  try {
    await api({
      method: 'PUT',
      url: `/oauth2/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteOauth2Token = async (id, data) => {
  try {
    await api({
      method: 'DELETE',
      url: `/oauth2/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getPowerBiToken = async code => {
  try {
    const response = await api({
      method: 'POST',
      url: '/oauth2/powerBI',
      data: {
        code
      }
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const oauthLogout = async (tokenId, data) => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/oauth2/${tokenId}`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getPowerBiDashboards = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/powerBI/dashboards'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

// MS TEAMS CHAT

const getMsTeamsChatToken = async code => {
  try {
    const response = await api({
      method: 'POST',
      url: '/oauth2/MsTeams',
      data: {
        code
      }
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMsTeamsChatChannels = async () => {
  try {
    const response = await api({
      useCache: true,
      method: 'GET',
      url: '/msTeams/channels'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getInstagramToken = async code => {
  try {
    const { data } = await api({
      method: 'POST',
      url: '/instagram/getToken',
      data: {
        code
      },
      silent: true
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getFacebookPages = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/facebook/pages',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAppConfig = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/feature/accounts'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getFipsLocation = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/config/findFipsLocation`,
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getIpawsFeeds = async () => {
  try {
    const data = await api({
      method: 'GET',
      url: `/config/ipawsFeeds`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

// Klipfolio

const getKlipfolioToken = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/oauth2/klipfolio',
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const klipfolioLogout = async (tokenId, data) => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/oauth2/${tokenId}`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getKlipfolioDashboards = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/klipfolio/dashboards'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getKlipfolioPublishedLinks = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/klipfolio/dashboardPublishedLinks',
      params
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const approveContent = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/content/approve',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const preloadUrl = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/media/urlPreload',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAirportGate = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/airportGate',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAirportBaggage = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/airportBaggage',
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getShorelineEvents = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/shoreline/eventType',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getShorelinePrice = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/shoreline/price',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const uploadShorelinePrice = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/shoreline/price/uploadPrice',
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGeoLocationFromIp = async ip => {
  try {
    const response = await api({
      method: 'GET',
      url: `/config/getGeoLocation/${ip}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGlobalSearchItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/search',
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postGoogleCalendarAccount = async data => {
  try {
    await api({
      method: 'POST',
      url: '/oauth2/GoogleCalendar',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMicrosoftOutlookAccount = async data => {
  try {
    await api({
      method: 'POST',
      url: '/oauth2/OutlookCalendar',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getContentModerationLabels = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/config/moderationLabel'
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAirportCities = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/config/airportCity`,
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAIVideoAvatarsList = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/aiVideoCreator/avatars`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAIVideoVoicesList = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/aiVideoCreator/voices`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getAIVideoPresetBackgroundsList = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/aiVideoCreator/presetBackgrounds`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const configService = {
  getMediaCategory,
  getConfigOrgRole,
  getConfigEnterpriseRole,
  getConfigResellerRole,
  getConfigSystemRole,
  getConfigClientType,
  getConfigDeviceType,
  getConfigFeatureClient,
  getConfigFeatureDevice,
  getConfigFeatureMedia,
  getConfigMediaCategory,
  getThemeOfMediaFeatureById,
  getContentSourceOfMediaFeatureById,
  getLocation,
  getCity,
  getTransitions,
  getAirports,
  getAirlines,
  getAlertTypes,
  getLocationInfo,
  getBackgroundPattern,
  getBackgroundImagesFromMedia,
  // getMediaFeatures,
  getBackgroundPatternById,
  getCurrency,
  getYoutubeCaptions,
  getPowerBiToken,
  oauthLogout,
  getOauth2Tokens,
  getPowerBiDashboards,
  getInstagramToken,
  getFacebookPages,
  getAppConfig,
  getFipsLocation,
  getIpawsFeeds,
  getKlipfolioToken,
  klipfolioLogout,
  getKlipfolioDashboards,
  getKlipfolioPublishedLinks,
  approveContent,
  preloadUrl,
  postFacebookAccount,
  postInstagramAccount,
  postGoogleDocsAccount,
  putOauth2Token,
  deleteOauth2Token,
  getAirportGate,
  getAirportBaggage,
  getShorelineEvents,
  getShorelinePrice,
  uploadShorelinePrice,
  postGoogleCalendarAccount,
  postMicrosoftOutlookAccount,
  getGeoLocationFromIp,
  getGlobalSearchItems,
  getContentModerationLabels,
  getMsTeamsChatToken,
  getMsTeamsChatChannels,
  getAirportCities,
  getAIVideoAvatarsList,
  getAIVideoVoicesList,
  getAIVideoPresetBackgroundsList
}

export default configService
