import * as types from './index'

const postTemplate = data => ({
  type: types.POST_TEMPLATE,
  data
})

const editTemplate = ({ id, data }) => ({
  type: types.PUT_TEMPLATE,
  data,
  meta: {
    id
  }
})
const approveTemplate = id => ({
  type: types.APPROVE_TEMPLATE,
  id
})

const getTemplate = id => ({
  type: types.GET_TEMPLATE,
  data: id
})

const getTemplateItemsAction = params => ({
  type: types.GET_TEMPLATE_ITEMS,
  params
})

const clearTemplateItemsAction = params => ({
  type: types.CLEAR_TEMPLATE_ITEMS,
  params
})

const resetTemplateFetchParamsAction = () => ({
  type: types.CLEAR_TEMPLATE_FETCH_PARAMS
})

const getTemplateLibraryPrefAction = () => ({
  type: types.GET_TEMPLATE_PREFERENCE
})

const putTemplateLibraryPrefAction = data => ({
  type: types.PUT_TEMPLATE_PREFERENCE,
  payload: data
})

const getTemplateGroupItemsAction = (id, params) => ({
  type: types.GET_TEMPLATE_GROUP_ITEMS,
  payload: { id, params }
})

const clearGetTemplateGroupItemsInfoAction = () => ({
  type: types.CLEAR_GET_TEMPLATE_GROUP_ITEMS_INFO
})

const postTemplateGroupItemAction = data => ({
  type: types.POST_TEMPLATE_GROUP_ITEM,
  payload: data
})

const clearPostTemplateGroupItemInfoAction = () => ({
  type: types.CLEAR_POST_TEMPLATE_GROUP_ITEM_INFO
})

const deleteTemplateGroupItemAction = data => ({
  type: types.DELETE_TEMPLATE_GROUP_ITEM,
  payload: data
})

const clearDeleteTemplateGroupItemInfoAction = () => ({
  type: types.CLEAR_DELETE_TEMPLATE_GROUP_ITEM_INFO
})

const clearTemplateItem = () => ({
  type: types.CLEAR_TEMPLATE_ITEM
})

const clearResponseInfo = () => ({
  type: types.CLEAR_TEMPLATE_RESPONSE_INFO
})

const cloneTemplate = data => ({
  type: types.CLONE_TEMPLATE,
  data
})

const deleteTemplate = (id, data) => ({
  type: types.DELETE_TEMPLATE,
  id,
  data
})

const deleteSelectedTemplate = (ids, data) => ({
  type: types.DELETE_SELECTED_TEMPLATE,
  ids,
  data
})

const clearTemplateGroupItemsInfo = () => ({
  type: types.CLEAR_TEMPLATE_GROUP_ITEMS_RESPONSE_INFO
})

const getTemplatePreview = (data, key) => ({
  type: types.GET_TEMPLATE_PREVIEW,
  data,
  meta: { key }
})

const generateTemplatePreview = data => ({
  type: types.GENERATE_TEMPLATE_PREVIEW,
  data
})

const closeTemplatePreview = () => ({
  type: types.CLOSE_TEMPLATE_PREVIEW
})

const clearTemplatePreview = () => ({
  type: types.CLEAR_TEMPLATE_PREVIEW
})

const showTemplatePreview = () => ({
  type: types.SHOW_TEMPLATE_PREVIEW
})

const shareTemplate = data => ({
  type: types.SHARE_TEMPLATE,
  data
})

const copyTemplate = data => ({
  type: types.COPY_TEMPLATE,
  data
})

const getTemplateBackgrounds = params => ({
  type: types.GET_TEMPLATE_BACKGROUNDS,
  payload: params
})

const getTemplateBackground = id => ({
  type: types.GET_TEMPLATE_BACKGROUND,
  payload: id
})

const postTemplateBackground = data => ({
  type: types.POST_TEMPLATE_BACKGROUND,
  payload: data
})

const putTemplateBackground = (id, data) => ({
  type: types.PUT_TEMPLATE_BACKGROUND,
  payload: { id, data }
})

const getGroupTemplates = params => ({
  type: types.GET_TEMPLATE_GROUP_TEMPLATES,
  params
})

const getTemplateItemPreview = (id, type, isZoomToFit, muteAudio) => ({
  type: types.GET_TEMPLATE_ITEM_PREVIEW,
  payload: { id, type, isZoomToFit, muteAudio }
})

const getTemplateZonePreview = (
  id,
  type,
  isZoomToFit,
  contentId,
  muteAudio
) => ({
  type: types.GET_TEMPLATE_ZONE_PREVIEW,
  payload: { id, type, isZoomToFit, contentId, muteAudio }
})
const generateTemplateItemPreview = (
  data,
  id,
  type,
  isZoomToFit,
  muteAudio
) => ({
  type: types.GENERATE_TEMPLATE_ITEM_PREVIEW,
  payload: { data, id, type, isZoomToFit, muteAudio }
})

const clearTemplateItemsPreview = () => ({
  type: types.CLEAR_TEMPLATE_ITEMS_PREVIEW
})

const clearTemplateInfo = () => ({
  type: types.CLEAR_TEMPLATE_INFO
})

const clearTemplateItemPreview = id => ({
  type: types.CLEAR_TEMPLATE_ITEM_PREVIEW,
  payload: id
})

const getTemplateMedia = id => ({
  type: types.GET_TEMPLATE_MEDIA,
  payload: id
})

const getTemplatePlaylist = id => ({
  type: types.GET_TEMPLATE_PLAYLIST,
  payload: id
})

const getTemplateBackgroundImage = id => ({
  type: types.GET_TEMPLATE_BACKGROUND_IMAGE,
  payload: id
})

const clearTemplateBackgroundImage = id => ({
  type: types.CLEAR_TEMPLATE_BACKGROUND_IMAGE,
  payload: id
})

const getTemplateAssociations = (id, params) => ({
  type: types.GET_TEMPLATE_ASSOCIATIONS,
  payload: {
    id,
    params
  }
})

const clearTemplateAssociations = () => ({
  type: types.CLEAR_TEMPLATE_ASSOCIATIONS
})

export const getTemplateGalleryItems = params => ({
  type: types.GET_TEMPLATE_GALLERY_ITEMS,
  params
})

export const getTemplateGalleryById = id => ({
  type: types.GET_TEMPLATE_GALLERY_BY_ID,
  id
})

export const clearTemplateGalleryItemResponse = () => ({
  type: types.CLEAR_TEMPLATE_GALLERY_ITEM_RESPONSE
})

export const getTemplateGalleryPreview = data => ({
  type: types.GET_TEMPLATE_GALLERY_PREVIEW,
  data
})

export const copyTemplateGallery = data => ({
  type: types.COPY_TEMPLATE_GALLERY,
  data
})

export const clearTemplateGalleryInfo = () => ({
  type: types.CLEAR_TEMPLATE_GALLERY_INFO
})

const postTemplateBulk = payload => ({
  type: types.POST_TEMPLATE_BULK,
  payload
})

const clearPostTemplateBulkInfo = () => ({
  type: types.CLEAR_POST_TEMPLATE_BULK_INFO
})

export {
  approveTemplate,
  clearTemplateItem,
  postTemplate,
  editTemplate,
  getTemplate,
  getTemplateItemsAction,
  getTemplateLibraryPrefAction,
  putTemplateLibraryPrefAction,
  resetTemplateFetchParamsAction,
  getTemplateGroupItemsAction,
  clearGetTemplateGroupItemsInfoAction,
  postTemplateGroupItemAction,
  clearPostTemplateGroupItemInfoAction,
  deleteTemplateGroupItemAction,
  clearDeleteTemplateGroupItemInfoAction,
  clearResponseInfo,
  cloneTemplate,
  deleteTemplate,
  deleteSelectedTemplate,
  clearTemplateGroupItemsInfo,
  getTemplatePreview,
  generateTemplatePreview,
  closeTemplatePreview,
  clearTemplatePreview,
  showTemplatePreview,
  shareTemplate,
  copyTemplate,
  getTemplateBackgrounds,
  getTemplateBackground,
  postTemplateBackground,
  putTemplateBackground,
  getGroupTemplates,
  getTemplateItemPreview,
  clearTemplateItemsPreview,
  clearTemplateInfo,
  clearTemplateItemPreview,
  generateTemplateItemPreview,
  getTemplateMedia,
  getTemplatePlaylist,
  getTemplateBackgroundImage,
  clearTemplateBackgroundImage,
  getTemplateAssociations,
  clearTemplateAssociations,
  getTemplateZonePreview,
  postTemplateBulk,
  clearPostTemplateBulkInfo,
  clearTemplateItemsAction
}
