import React, { useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { _get } from 'utils/lodash'

import { permissionNames } from 'constants/index'
import PlaylistMoreInfoCard from './PlaylistMoreInfoCard'
import { getPlaylistFeatureNameByType } from 'utils/playlistUtils'
import { CLIENT_ADMIN } from 'constants/roles'
import { userDetailsSelectorMemoized } from 'selectors/userSelectors'
import { playlistTypes } from 'constants/playlist'
import { useUserRole } from 'hooks/tableLibrary'
import GridViewCard, {
  PlaylistCardBottomInfo
} from 'components/Card/GridViewCard'
import usePlaylistThumbnails from 'hooks/playlist/usePlaylistThumbnails'

const PlaylistCard = ({
  t,
  item,
  isSelected = false,
  onToggleSelect = f => f,
  onDelete = f => f,
  onEdit = f => f,
  playlistPermissions,
  onApprove = f => f,
  onClone = f => f,
  onShare = f => f,
  onCopy = f => f,
  onPreview = f => f,
  getPermissionByName,
  interactivePlaylistPermissions,
  smartPlaylistPermissions,
  getImpersonatePermissionByName,
  isRootPath
}) => {
  const { id, title } = item
  const { response } = useSelector(userDetailsSelectorMemoized)

  const {
    startSlideshow,
    stopSlideshow,
    thumbnailData,
    loading
  } = usePlaylistThumbnails({
    playlist: item
  })

  const role = useUserRole()

  const handleSelect = useCallback(
    event => {
      onToggleSelect(event, id)
    },
    [id, onToggleSelect]
  )

  const actions = useMemo(() => {
    const playlistFeatureName = getPlaylistFeatureNameByType(item.playlistType)

    let canUpdate = playlistPermissions.update
    if (item.playlistType === 'Smart') {
      canUpdate = smartPlaylistPermissions.update
    }
    if (item.playlistType === 'Interactive') {
      canUpdate = interactivePlaylistPermissions.update
    }

    return [
      {
        label: t('Approve'),
        clickAction: onApprove,
        icon: 'fa-regular fa-circle-check',
        render:
          playlistPermissions.approve &&
          role.level === CLIENT_ADMIN &&
          !item.approved
      },
      {
        label: t('Clone'),
        clickAction: onClone,
        icon: 'fa fa-clone',
        disabled: !_get(item, 'permission.write', false),
        render: item.approved
      },
      {
        label: t('Edit action'),
        clickAction: onEdit,
        icon: 'fa-sharp fa-regular fa-pen-to-square',
        render:
          canUpdate &&
          (_get(response, 'client.feature', []).some(
            ({ name }) =>
              name === playlistFeatureName ||
              playlistFeatureName === playlistTypes.Standard.name
          ) ||
            role.system),
        disabled: !_get(item, 'permission.write', false)
      },
      {
        label: t('Share'),
        clickAction: onShare,
        icon: 'fa-regular fa-share-nodes',
        render: (role.system || role.enterprise) && playlistPermissions.other
      },
      {
        label: t('Copy'),
        clickAction: onCopy,
        icon: 'fa fa-copy',
        render:
          role.system || role.enterprise
            ? getPermissionByName(
                permissionNames.SYSTEM_PLAYLIST_COPY,
                permissionNames.ENTERPRISE_PLAYLIST_COPY
              )
            : getImpersonatePermissionByName(
                permissionNames.SYSTEM_PLAYLIST_COPY,
                permissionNames.ENTERPRISE_PLAYLIST_COPY
              )
      },
      {
        label: t('Delete action'),
        icon: 'fa-regular fa-trash-can',
        clickAction: onDelete,
        render: playlistPermissions.delete,
        disabled: !_get(item, 'permission.delete', false)
      }
    ]
  }, [
    onEdit,
    onDelete,
    onApprove,
    onClone,
    onShare,
    onCopy,
    getImpersonatePermissionByName,
    getPermissionByName,
    playlistPermissions,
    interactivePlaylistPermissions,
    smartPlaylistPermissions,
    item,
    role,
    response,
    t
  ])

  return (
    <GridViewCard
      title={title}
      item={item}
      actions={actions}
      selected={isSelected}
      onSelect={handleSelect}
      onPreview={onPreview}
      thumbnail={thumbnailData.thumbnail}
      thumbnailColor={thumbnailData.thumbnailColor}
      thumbnailIcon={thumbnailData.thumbnailIcon}
      thumbnailLoading={loading}
      titleHoverComponent={<PlaylistMoreInfoCard item={item} />}
      bottomComponent={<PlaylistCardBottomInfo playlist={item} />}
      onThumbnailMouseEnter={startSlideshow}
      onThumbnailMouseLeave={stopSlideshow}
      animationAllowed={isRootPath}
    />
  )
}

export default withTranslation('translations')(PlaylistCard)
