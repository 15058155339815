import * as types from './index'

export const getPublicNocGeneralItems = params => ({
  type: types.GET_PUBLIC_NOC_GENERAL_ITEMS,
  params
})

export const getPublicNocHealthItems = params => ({
  type: types.GET_PUBLIC_NOC_HEALTH_ITEMS,
  params
})

export const getPublicNocNetworkItems = params => ({
  type: types.GET_PUBLIC_NOC_NETWORK_ITEMS,
  params
})

export const getPublicNocAccessToken = token => ({
  type: types.GET_PUBLIC_NOC_ACCESS_TOKEN,
  token
})
