import React, { useEffect, useMemo } from 'react'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'

import {
  billingNotificationMessages,
  billingNotificationTypes
} from 'constants/api'
import useTopBanner from 'hooks/api/useTopBanner'
import { TextWithTooltip } from 'components/Typography'

const styles = () => {
  return {
    root: {
      top: 0,
      width: '100%',
      height: 35,
      display: 'flex',
      zIndex: 112,
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#cb3939',
      overflow: 'hidden'
    },
    text: {
      fontSize: 16
    }
  }
}

const TopBanner = ({ classes, t }) => {
  const { visible, data, hidePreview } = useTopBanner()

  const message = useMemo(() => {
    const { deactivateNotificationType, deactivateMessage } = data

    return deactivateNotificationType === billingNotificationTypes.custom &&
      deactivateMessage
      ? deactivateMessage
      : t(
          billingNotificationMessages[deactivateNotificationType] ||
            billingNotificationMessages[billingNotificationTypes.normal]
        )
  }, [data, t])

  useEffect(() => {
    hidePreview()
    // eslint-disable-next-line
  }, [data])

  if (!visible) {
    return null
  }

  return (
    <div className={classes.root}>
      <TextWithTooltip
        maxWidth={1600}
        color="white"
        weight="bold"
        rootClassName={classes.text}
      >
        {message}
      </TextWithTooltip>
    </div>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(TopBanner)
