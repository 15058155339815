import { put, call } from 'redux-saga/effects'
import * as types from '../actions'
import * as clientSettingsService from 'services/clientSettingsService'
import {
  postSsoDetailsFailure,
  postSsoDetailsSuccess,
  putSsoDetailsFailure,
  putSsoDetailsSuccess
} from 'actions/clientSettingsActions'

function* putClientSettings(action) {
  try {
    yield call(clientSettingsService.putClientSettings, action.payload)
    yield put({
      type: types.PUT_CLIENT_SETTINGS_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_CLIENT_SETTINGS_ERROR, payload: error })
  }
}

function* putClientFeatureSettings(action) {
  try {
    yield call(clientSettingsService.putClientFeatureSettings, action.payload)
    yield put({
      type: types.PUT_CLIENT_FEATURE_SETTINGS_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.PUT_CLIENT_FEATURE_SETTINGS_ERROR, payload: error })
  }
}

function* postSsoDetails(action) {
  try {
    yield call(clientSettingsService.postSsoDetails, action.payload)
    yield put(
      postSsoDetailsSuccess({
        status: 'success'
      })
    )
  } catch (error) {
    yield put(postSsoDetailsFailure(error))
  }
}

function* putSsoDetails(action) {
  try {
    yield call(clientSettingsService.putSsoDetails, action.payload)
    yield put(
      putSsoDetailsSuccess({
        status: 'success'
      })
    )
  } catch (error) {
    yield put(putSsoDetailsFailure(error))
  }
}

const clientSettingsSaga = {
  putClientSettings,
  putClientFeatureSettings,
  postSsoDetails,
  putSsoDetails
}

export default clientSettingsSaga
