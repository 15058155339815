import Badge from '@material-ui/core/Badge'
import React from 'react'
import { withStyles } from '@material-ui/core'

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  badge: {
    fontWeight: 700,
    height: 32,
    fontSize: 14,
    minWidth: 32,
    borderRadius: 16
  }
})

const CustomBadge = ({
  content = 0,
  color = 'secondary',
  max = 100000,
  children = <div> </div>,
  ...props
}) => {
  return (
    <Badge
      {...props}
      color={color}
      min={0}
      max={max}
      badgeContent={content}
      id="customBadge"
    >
      {children}
    </Badge>
  )
}

export default withStyles(styles)(CustomBadge)
