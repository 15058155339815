import { combineReducers } from '@reduxjs/toolkit'

import auth from './auth'
import deviceNoc from './deviceNoc'

const publicReducer = combineReducers({
  auth,
  deviceNoc
})

export default publicReducer
