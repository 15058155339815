import moment from 'moment/moment'

import { TIME_FORMAT, TIME_S_FORMAT } from 'constants/dateTimeFormats'
import { HOUR_MINUTES } from 'constants/general'

export const prepareScreenPowerData = values => {
  const { conditions, scheduleEnabled } = values

  const screenPowerSchedule = conditions.map(condition => ({
    dayFrom: condition.dayFrom.slice(0, 3),
    dayTo: condition.dayTo.slice(0, 3),
    timeFrom: moment(condition.timeFrom, TIME_FORMAT).format(TIME_S_FORMAT),
    timeTo: moment(condition.timeTo, TIME_FORMAT).format(TIME_S_FORMAT)
  }))

  return {
    screenPowerSettings: {
      scheduleEnabled,
      screenPowerSchedule
    }
  }
}

const convertRangeTime = ({
  range = '01:00-03:00',
  is24TimeFormat = false
}) => {
  if (is24TimeFormat) {
    return range
  }

  const [startTime, endTime] = range.split('-')
  const [startHours] = startTime.split(':')
  const [endHours] = endTime.split(':')

  const convertedStartTime = moment()
    .set('hour', +startHours)
    .format('hA')

  const convertedEndTime = moment()
    .set('hour', +endHours)
    .format('hA')

  return `${convertedStartTime}-${convertedEndTime}`
}

export const getAutoUpdateTimeOptions = ({ is24TimeFormat }) => [
  {
    label: convertRangeTime({ range: '01:00-03:00', is24TimeFormat }),
    value: HOUR_MINUTES
  },
  {
    label: convertRangeTime({ range: '21:00-23:00', is24TimeFormat }),
    value: HOUR_MINUTES * 21
  },
  {
    label: convertRangeTime({ range: '23:00-01:00', is24TimeFormat }),
    value: HOUR_MINUTES * 23
  }
]
