import React, { useEffect, useState, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { _get } from 'utils/lodash'
import { withStyles } from '@material-ui/core'
import { Base64 } from 'js-base64'

import DefaultModal from 'components/Modal/DefaultModal'
import useUserRole from 'hooks/tableLibrary/useUserRole'
import { BlueButton } from 'components/Buttons'
import { putUserDetailsAction, clearUserPutAction } from 'actions/userActions'
import { isFeatureAvailable } from 'utils/api/featureAvailability'
import { EULA_APP_FEATURE } from 'constants/featureConstants'
import { getOriginalUser } from 'utils/localStorage'
import { getEulaIframeStyles } from 'constants/stylesConstants'

const styles = () => ({
  contentClass: {
    minHeight: 600,
    maxHeight: 600,
    padding: 0,
    overflow: 'hidden'
  },
  iframe: {
    border: 'none',
    minHeight: 'inherit',
    width: '100%'
  }
})

const EulaModal = ({ children, classes, t }) => {
  const [eulaAccept, setEulaAccept] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [
    userData,
    putUserReducer
  ] = useSelector(({ user: { details, put } }) => [details.response, put])

  const onAccept = useCallback(() => {
    setLoading(true)
    dispatch(
      putUserDetailsAction({
        acceptedEulaVer: userData.latestEulaVer
      })
    )

    // eslint-disable-next-line
  }, [putUserDetailsAction])

  useEffect(() => {
    const { acceptedEulaVer, latestEulaVer, latestEulaContent } = userData
    if (!latestEulaContent || !latestEulaVer) {
      setEulaAccept(false)
      return
    }

    if (acceptedEulaVer !== latestEulaVer && !getOriginalUser()) {
      if (isFeatureAvailable(EULA_APP_FEATURE)) {
        setEulaAccept(true)
      }
    }
  }, [userData])

  useEffect(() => {
    if (_get(putUserReducer, 'response.status') === 'success') {
      setEulaAccept(false)
      dispatch(clearUserPutAction())
    }
    setLoading(false)
    // eslint-disable-next-line
  }, [putUserReducer])

  const role = useUserRole().role

  return (
    <>
      {eulaAccept && role !== 'system' ? (
        <DefaultModal
          open={true}
          maxWidth="lg"
          modalTitle={t('Terms And Condition')}
          hasCloseIcon={false}
          contentClass={classes.contentClass}
          actions={
            <BlueButton
              onClick={onAccept}
              iconClassName="fa-regular fa-circle-check"
              progress={loading}
            >
              {t('Accept')}
            </BlueButton>
          }
        >
          {!!userData?.latestEulaContent && (
            <iframe
              className={classes.iframe}
              title={t('Terms And Condition')}
              scrolling="no"
              srcDoc={`
              <div class='htmlContent'>${
                userData?.latestEulaContent?.html
                  ? Base64.decode(userData.latestEulaContent.html)
                  : ''
              }
              ${
                userData?.latestEulaContent?.text
                  ? Base64.decode(userData.latestEulaContent.text)
                  : ''
              }
              </div>
              <style> ${Base64.decode(
                _get(userData, 'latestEulaContent.css')
              )} ${getEulaIframeStyles(
                !!userData?.latestEulaContent?.text
              )} </style>
            `}
            />
          )}
        </DefaultModal>
      ) : (
        children
      )}
    </>
  )
}

export default withTranslation('translations')(withStyles(styles)(EulaModal))
