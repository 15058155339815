import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'

import useConfirmation from 'hooks/useConfirmation'

const styles = () => ({
  container: {
    height: 'calc(94vh - 137px)'
  }
})

const ChunkErrorPage = ({ classes, t }) => {
  const { showConfirmation } = useConfirmation({
    defaultMessage: t('New updates are available. Please reload the page'),
    singleButton: true,
    confirmButtonText: t('Reload'),
    defaultOnConfirm: () => window.location.reload(),
    variant: 'info',
    confirmButtonIconClassName: 'fa fa-repeat'
  })

  useEffect(
    () => {
      showConfirmation()
    },
    // eslint-disable-next-line
    []
  )

  return <div className={classes.container} />
}

export default withTranslation('translations')(
  withStyles(styles)(ChunkErrorPage)
)
