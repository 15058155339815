import { useEffect } from 'react'

import socket, { SOCKET_NOC_ALARM } from 'services/socket'

export default function useDeviceNocAlarmSocket(listener) {
  useEffect(() => {
    socket.on(SOCKET_NOC_ALARM, listener)

    return () => {
      socket.off(SOCKET_NOC_ALARM, listener)
    }
  }, [listener])
}
