import { errorHandler } from 'utils'
import api from './api'

const getRemoteCalendars = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/calendarAggregator/remote/calendars',
      params
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const calendarAggregatorService = {
  getRemoteCalendars
}

export default calendarAggregatorService
