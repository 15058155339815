import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'

import {
  getDeviceMediaEmergencyAlert,
  removeDeviceMediaEmergencyAlertAction,
  clearRemoveDeviceMediaEmergencyAlertInfoAction
} from 'actions/alertActions'
import useConfirmation from 'hooks/useConfirmation'

const RemoveAlertMediaType = ({
  t,
  id,
  typeName,
  deviceId,
  open = false,
  onClose = f => f,
  onSuccess = f => f,
  onFail = f => f,
  removeDeviceMediaEmergencyAlertAction,
  getDeviceMediaEmergencyAlert,
  removeDeviceMediaEmergencyAlertReducer,
  clearRemoveDeviceMediaEmergencyAlertInfoAction
}) => {
  const handleDelete = useCallback(() => {
    removeDeviceMediaEmergencyAlertAction({
      deviceId: deviceId,
      alertId: id
    })
  }, [removeDeviceMediaEmergencyAlertAction, deviceId, id])

  const handleClose = useCallback(() => onClose(), [onClose])

  const { showConfirmation } = useConfirmation({
    onCancel: handleClose
  })

  useEffect(() => {
    open &&
      showConfirmation(
        t('remove_modal_text_interpolated', { item: typeName }),
        handleDelete
      )
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (removeDeviceMediaEmergencyAlertReducer.response) {
      getDeviceMediaEmergencyAlert(deviceId)
      clearRemoveDeviceMediaEmergencyAlertInfoAction()
      onSuccess()
    } else if (removeDeviceMediaEmergencyAlertReducer.error) {
      clearRemoveDeviceMediaEmergencyAlertInfoAction()
      onFail()
    }
    // eslint-disable-next-line
  }, [removeDeviceMediaEmergencyAlertReducer])

  return null
}

RemoveAlertMediaType.propTypes = {
  id: PropTypes.number,
  typeName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  deviceId: PropTypes.number,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
}

const mapStateToProps = ({ alert }) => ({
  removeDeviceMediaEmergencyAlertReducer: alert.removeDeviceMediaEmergencyAlert
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDeviceMediaEmergencyAlert,
      clearRemoveDeviceMediaEmergencyAlertInfoAction,
      removeDeviceMediaEmergencyAlertAction
    },
    dispatch
  )

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(RemoveAlertMediaType)
