import React from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

import MessageCard from 'components/Card/MessageCard'

const useStyles = makeStyles({
  alertNotificationWrapper: {
    paddingRight: 20,
    paddingTop: 16
  }
})

const AlertNotification = ({
  message,
  notificationWrapperClass,
  messageCardClass
}) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(
        classes.alertNotificationWrapper,
        notificationWrapperClass
      )}
    >
      <MessageCard
        icon="fa-regular fa-circle-info"
        message={message}
        rootClassName={messageCardClass}
        marginBottom
      />
    </div>
  )
}

export default AlertNotification
