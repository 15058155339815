import { useEffect } from 'react'
import { _isEmpty } from 'utils/lodash'
import { BIG_NUMBER } from 'constants/library'
import { useLazyGetCategoriesByFeatureQuery } from 'api/featureCategoriesApi'

const useCategoryOptions = feature => {
  useEffect(() => {
    // eslint-disable-next-line
    if (!feature) throw console.log('Please pass feature')
  })
  const [
    getCategoryByFeature,
    { data: categoriesData }
  ] = useLazyGetCategoriesByFeatureQuery()

  const categories =
    categoriesData?.data.map(({ name, id }) => ({ label: name, value: id })) ||
    []
  const meta = categoriesData?.meta || []

  useEffect(() => {
    if (_isEmpty(categories) && feature)
      getCategoryByFeature({ feature, params: { limit: BIG_NUMBER } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (meta.total > meta.count) {
      getCategoryByFeature({ feature, params: { limit: meta.total } })
    }
    // eslint-disable-next-line
  }, [feature, meta.count, meta.total])

  return categories
}

export default useCategoryOptions
