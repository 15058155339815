import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const GlobalSearchLoader = ({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 380
    }}
    foregroundColor={theme.palette[theme.type].loader.background}
    backgroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect x="10" y="10" rx="6" ry="6" width="530" height="30" />
    <rect x="40" y="56" rx="6" ry="6" width="200" height="66" />
    <rect x="301" y="56" rx="6" ry="6" width="200" height="66" />
    <rect x="40" y="138" rx="6" ry="6" width="200" height="66" />
    <rect x="301" y="138" rx="6" ry="6" width="200" height="66" />
    <rect x="10" y="230" rx="6" ry="6" width="530" height="30" />
    <rect x="40" y="276" rx="6" ry="6" width="200" height="66" />
    <rect x="301" y="276" rx="6" ry="6" width="200" height="66" />
  </ContentLoader>
)

export default withTheme(GlobalSearchLoader)
