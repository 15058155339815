import api from './api'

import { errorHandler, queryParamsHelper } from '../utils'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { deviceQueryHelper } from 'utils/scheduleUtils'
import { isVueV3Preview } from 'utils/apiUtils'

const postSchedule = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/schedule`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getSchedule = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/schedule/${id}`
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const editSchedule = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/schedule/${id}`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getItems = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/schedule',
      params: {
        ...queryParamsHelper(
          params,
          ['group', 'tag', 'status'],
          [],
          ['mediaFeature', 'scheduleType']
        ),
        ...(params.device && deviceQueryHelper(params.device))
      }
    })

    return data
  } catch (error) {
    throw errorHandler(getItems)
  }
}
const getTimeline = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/scheduleTimeline',
      params: {
        ...queryParamsHelper(
          params,
          ['group', 'tag', 'status'],
          [],
          ['scheduleType']
        ),
        ...(params.device && deviceQueryHelper(params.device))
      }
    })

    return data
  } catch (error) {
    throw errorHandler(getItems)
  }
}

const getCalendarSchedules = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/scheduleListForCalendarView',
      params: {
        ...queryParamsHelper(
          params,
          ['group', 'tag', 'status'],
          [],
          ['mediaFeature', 'scheduleType']
        ),
        ...(params.device && deviceQueryHelper(params.device))
      }
    })

    return data
  } catch (error) {
    throw errorHandler(getItems)
  }
}

const getPreference = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/preference',
      params: {
        entity: 'ScheduleLibrary'
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putPreference = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/preference/ScheduleLibrary',
      data: {
        recordsPerPage: 10,
        gridColumn: data
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGroupItems = async (id, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/schedule/group/${id}`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postGroupItem = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/schedule/group/${data.groupId}`,
      data: {
        scheduleId: data.scheduleId
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteGroupItem = async data => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/schedule/group/${data.groupId}/${data.scheduleId}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const cloneSchedule = async data => {
  try {
    const { data: _data } = await api({
      method: 'POST',
      url: `/schedule/clone`,
      data
    })
    return _data
  } catch (e) {
    throw errorHandler(e)
  }
}

const copySchedule = async data => {
  const { id, impersonated, ...rest } = data
  try {
    await api({
      method: 'POST',
      url: `/schedule/${id}/copy`,
      data: rest,
      ...(impersonated && getApiParamsByOriginalUser(`/schedule/${id}/copy`))
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const deleteSelectedSchedules = async ids => {
  try {
    await api({
      method: 'DELETE',
      url: `/schedule/bulk`,
      params: {
        ids: ids.join(',')
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const deleteScheduleById = async id => {
  try {
    await api({
      method: 'DELETE',
      url: `/schedule/${id}`
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getPreview = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/schedule/preview/${id}`,
      params: {
        ...(isVueV3Preview() && { vue3: 1 })
      },
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const generatePreview = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/schedule/preview',
      params: {
        ...(isVueV3Preview() && { vue3: 1 })
      },
      data,
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const assignDevicesSchedule = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/schedule/${id}/associateDevices`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const patchScheduleItem = async (id, data, returnHandlerError = false) => {
  try {
    const response = await api({
      method: 'PATCH',
      url: `/schedule/${id}`,
      data
    })
    return response.data
  } catch (error) {
    if (error.response.data && !returnHandlerError) {
      throw error.response.data
    } else throw errorHandler(error)
  }
}

const postScheduleBulk = async ({ scheduleIds, data }) => {
  try {
    await api({
      method: 'POST',
      url: `/schedule/bulkAssignGroupsAndTags`,
      data: {
        scheduleIds,
        ...data
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const scheduleService = {
  getTimeline,
  postSchedule,
  getSchedule,
  editSchedule,
  getItems,
  getPreference,
  putPreference,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  cloneSchedule,
  copySchedule,
  deleteSelectedSchedules,
  deleteScheduleById,
  getPreview,
  generatePreview,
  assignDevicesSchedule,
  patchScheduleItem,
  getCalendarSchedules,
  postScheduleBulk
}

export default scheduleService
