import React, { useMemo } from 'react'
import {
  Link,
  Table,
  TableBody,
  Typography,
  withStyles
} from '@material-ui/core'

import {
  TableLibraryCell,
  TableLibraryHead,
  TableLibraryRow
} from 'components/TableLibrary'
import { checkData } from 'utils/tableUtils'
import { getUrlPrefix } from 'utils/index'
import routeByName from 'constants/routes'
import { TextWithTooltip } from 'components/Typography'
import { libraryViews } from 'constants/library'
import { filterStatuses } from 'constants/libraryConstants'
import Scrollbars from 'components/Scrollbars'

const styles = ({ type, typography, colors }) => ({
  scrollContent: {
    marginRight: 20
  },
  cellPaddings: {
    padding: '10px 20px !important'
  },
  cellText: {
    ...typography.lightAccent[type],
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  clientNameWrap: {
    display: 'flex'
  },
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  }
})

const initialColumns = [
  { id: 'mac', label: 'MAC', align: 'center' },
  { id: 'deviceName', label: 'Device Name', align: 'center' },
  { id: 'client', label: 'Client', align: 'center' }
]

const columnWidth = {
  deviceName: 220,
  mac: 220,
  client: 200
}

const MacAddressConflictContent = ({
  error,
  classes,
  deviceLibraryView = libraryViews.list
}) => {
  const data = useMemo(() => error?.data || [], [error])

  return (
    <Scrollbars autoHeight autoHeightMax="calc(100vh - 190px)">
      <div className={classes.scrollContent}>
        <Table className={classes.table}>
          <TableLibraryHead
            noType={true}
            editRows={false}
            disableSort
            rowCount={data.length}
            columns={initialColumns}
            columnWidth={columnWidth}
            headerCellClassName={classes.tableHeaderCell}
          />
          <TableBody>
            {data.map((row, index) => (
              <TableLibraryRow
                key={`mac-conflict-${index}`}
                hover
                tabIndex={-1}
              >
                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  <Typography className={classes.cellText}>
                    {checkData(row.macAddress, 'N/A')}
                  </Typography>
                </TableLibraryCell>

                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  <Link
                    href={getUrlPrefix(
                      `${routeByName.device[deviceLibraryView]}?id=${row.device.id}&exact=true&disabled=1&includeDormant=1`
                    )}
                    target="_blank"
                    underline="always"
                  >
                    <TextWithTooltip maxWidth={180}>
                      {row.device.name}
                    </TextWithTooltip>
                  </Link>
                </TableLibraryCell>
                <TableLibraryCell
                  className={classes.cellPaddings}
                  align="center"
                >
                  <Link
                    href={getUrlPrefix(
                      `${routeByName.clients.root}?id=${row.client.id}&exact=true&status=${filterStatuses.any}`
                    )}
                    target="_blank"
                    underline="always"
                  >
                    <div className={classes.clientNameWrap}>
                      <TextWithTooltip maxWidth={180}>
                        {row.client.name}
                      </TextWithTooltip>
                      {' [' + row.client.id + ']'}
                    </div>
                  </Link>
                </TableLibraryCell>
              </TableLibraryRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Scrollbars>
  )
}

export default withStyles(styles)(MacAddressConflictContent)
