import api from './api'
import sortConstants from 'constants/sortConstants'

import { errorHandler } from '../utils'
import { getApiParamsByOriginalUser } from '../utils/localStorage'

const getTags = async (entity, params, silent, impersonated) => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/tag',
      params: { ...sortConstants.Tag, ...params, entity },
      silent,
      ...(impersonated && getApiParamsByOriginalUser('/tag'))
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postTag = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/tag',
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteTag = async ({ id, data }) => {
  try {
    await api({
      method: 'DELETE',
      url: `/tag/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteSelectedTags = async ({ ids, data }) => {
  try {
    await api({
      method: 'DELETE',
      url: `/tag/bulk`,
      params: {
        ids: ids.join(',')
      },
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getTagById = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/tag/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putTag = async (id, data) => {
  try {
    await api({
      method: 'PUT',
      url: `/tag/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const tagsService = {
  getTags,
  getTagById,
  postTag,
  putTag,
  deleteTag,
  deleteSelectedTags
}

export default tagsService
