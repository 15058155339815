import { useCallback, useMemo, useState } from 'react'

export default function useRefCallback(onRef) {
  const [ref, setRef] = useState({ current: null })

  const getRef = useCallback(
    value => {
      if (ref.current || !value) {
        return null
      }

      setRef({ current: value })
      onRef && onRef({ current: value })
    },
    [ref, onRef]
  )

  return useMemo(() => [ref, getRef], [ref, getRef])
}
