import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from '../utils/apiUtils'
import apiTags from 'constants/apiTags'

export const menuMakerApi = createAppApi('menuMakerQuery', {
  tagTypes: [
    apiTags.menuItemCategory,
    apiTags.menuItemVariantSet,
    apiTags.menuItemVariant
  ],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      builder,
      tags: [apiTags.menuItemCategory],
      name: 'MenuItemCategory',
      pluralName: 'MenuItemCategories',
      url: '/menuMaker/categories'
    }),
    ...getCRUDEndpoints({
      builder,
      tags: [apiTags.menuItemVariantSet],
      name: 'VariantSet',
      url: '/menuMaker/variantSets'
    }),
    ...getCRUDEndpoints({
      builder,
      tags: [apiTags.menuItemVariant],
      name: 'Variant',
      url: '/menuMaker/variants'
    })
  })
})

export const {
  //MenuItemCategory
  useLazyGetMenuItemCategoriesQuery,
  useGetMenuItemCategoryQuery,
  usePostMenuItemCategoryMutation,
  usePutMenuItemCategoryMutation,
  useDeleteMenuItemCategoryMutation,
  //VariantSet
  useLazyGetVariantSetsQuery,
  useGetVariantSetQuery,
  usePostVariantSetMutation,
  usePutVariantSetMutation,
  useDeleteVariantSetMutation,
  //Variant
  useLazyGetVariantsQuery,
  useGetVariantQuery,
  usePostVariantMutation,
  usePutVariantMutation,
  useDeleteVariantMutation
} = menuMakerApi
