import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'
import { queryParamsHelper } from 'utils'

export const deviceApi = createAppApi('deviceQuery', {
  tagTypes: [apiTags.device],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      url: '/device',
      builder,
      tags: [apiTags.device],
      name: 'Device'
    }),
    getDevices: builder.query({
      providesTags: [apiTags.device],
      query: (params = {}) => ({
        url: '/device',
        params: queryParamsHelper(
          {
            ...params,
            ...(!!params.dormant && { status: null }),
            ...(!!params.firmware && { exact: 1 })
          },
          ['group', 'tag'],
          [],
          ['mediaFeature', 'deviceTypeId']
        )
      })
    }),
    bulkTriggerDevicePreview: builder.query({
      query: (params = { limit: 9999 }) => ({
        url: `/device/preview/bulkTriggerDevice`,
        params
      })
    }),
    deviceList: builder.query({
      query: (params = {}) => ({
        url: '/device/list',
        params
      })
    }),
    deviceGrid: builder.query({
      query: (params = {}) => ({
        url: '/device/gridView',
        params: queryParamsHelper(
          {
            ...params,
            ...(!!params.dormant && { status: null }),
            ...(!!params.firmware && { exact: 1 })
          },
          ['group', 'tag'],
          [],
          ['mediaFeature', 'deviceTypeId']
        )
      })
    }),
    deviceNoc: builder.query({
      query: (params = {}) => ({
        url: '/org/public/noc/general',
        params
      })
    }),
    publicUptime: builder.query({
      query: (params = {}) => ({
        url: '/public/noc/uptime',
        params
      })
    }),
    fetchSupportToken: builder.mutation({
      query: (data = {}) => ({
        url: '/device/fetchSupportToken',
        method: 'POST',
        data
      })
    }),
    getDeviceUpTime: builder.query({
      query: (params = {}) => ({ url: `/device/noc/uptime`, params })
    })
  })
})

export const {
  usePostDeviceMutation,
  useLazyBulkTriggerDevicePreviewQuery,
  useLazyDeviceListQuery,
  useLazyDeviceGridQuery,
  useLazyDeviceNocQuery,
  useLazyPublicUptimeQuery,
  useLazyGetDevicesQuery,
  useGetDevicesQuery,
  useFetchSupportTokenMutation,
  useLazyGetDeviceUpTimeQuery
} = deviceApi
