import { Tooltip } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { withSnackbar } from 'notistack'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { devicePreviewModalSelector } from 'selectors/new/device'

import useWindowDimensions from 'hooks/useWindowDimensions'
import useDevicePreviews from 'hooks/api/useDevicePreviews'
import useEscKeyDownListener from 'hooks/useEscKeyDownListener'

import { clearDevicePreviewModal } from 'actions/new/device'
import PreviewModalBase from './PreviewModalBase'
import {
  FLASH_DURATION,
  ADDITIONAL_SIZE,
  devicePreviewLandscapeSize,
  devicePreviewPortraitSize
} from 'constants/previewModal'
import DevicePreviewRefreshIcon from 'components/Icons/DevicePreviewRefreshIcon'
import usePreviewModalFeatures from 'hooks/usePreviewModalDimentions'
import { getDevicePreviewUrl } from 'utils/deviceUtils'
import usePermissions from 'hooks/api/usePermissions'
import { permissionNames } from 'constants/index'

const useStyles = makeStyles({
  frameRoot: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    background: 'black',
    visibility: 'hidden'
  },
  frameRootLight: {
    background: 'white'
  },
  frameRootVisible: {
    visibility: 'visible'
  },
  frameFullScreen: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none'
  },
  previewImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    margin: 'auto',
    right: 0,
    top: 0,
    boxSizing: 'border-box',
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    width: '100%'
  },
  controlsItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ palette, type }) => palette[type].card.greyHeader.color,
    padding: '2px',
    transition: 'opacity .25s ease, color .25s ease',
    height: '20px',
    width: '20px',
    '& svg': {
      height: 20,
      width: 20
    },
    '& i': {
      fontSize: 20
    },
    '&.draggable-handle': {
      cursor: 'move',
      '&:hover': {
        cursor: 'move'
      }
    },
    '&:hover, &.is-active': {
      cursor: 'pointer',
      color: '#3f51b5'
    },
    '&.is-disable': {
      color: 'inherit',
      opacity: '0.5',
      cursor: 'default !important'
    }
  },
  lastControlItem: {
    padding: '2.5px',
    '& svg': {
      width: '16px',
      height: '16px'
    }
  },
  lightIcon: {
    animation: '$pulse linear 4s infinite'
  },
  '@keyframes pulse': {
    '0%, 20%': {
      color: ({ palette, type }) => palette[type].card.greyHeader.color
    },
    '30%': {
      color: 'black'
    },
    '40%, 60%': {
      color: ({ palette, type }) => palette[type].card.greyHeader.color
    },
    '70%': {
      color: 'white'
    },
    '80%, 100%': {
      color: ({ palette, type }) => palette[type].card.greyHeader.color
    }
  }
})

const DevicePreviewModal = ({ t, theme }) => {
  const [isResizing, setResizing] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)
  const [isClosing, setClosing] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [lightMode, setLightMode] = useState(false)
  const [oldDimensions, setOldDimensions] = useState(null)
  const [modalPosition, setModalPosition] = useState({})
  const { palette, type, typography } = theme
  const classes = useStyles({ isFullScreen, palette, type, typography })
  const dispatchAction = useDispatch()
  const windowDimensions = useWindowDimensions()
  const { getNewDimensions } = usePreviewModalFeatures()
  const { getPermissionByName } = usePermissions()
  const [isPlaceholder, setPlaceholder] = useState(false)
  const resizableRef = useRef()

  const deviceThumbnailPermission = useMemo(
    () => ({
      other: getPermissionByName(
        permissionNames.ORG_DEVICE_THUMBNAIL_TRIGGER,
        permissionNames.SYSTEM_DEVICE_THUMBNAIL_TRIGGER,
        permissionNames.ENTERPRISE_DEVICE_THUMBNAIL_TRIGGER
      )
    }),
    [getPermissionByName]
  )

  const { isVisible, deviceId, orientation } = useSelector(
    devicePreviewModalSelector
  )

  const [dimensions, setDimensions] = useState(
    orientation === 'Portrait'
      ? devicePreviewPortraitSize
      : devicePreviewLandscapeSize
  )

  const {
    data,
    isPreviewPending: isPending,
    updatePreview
  } = useDevicePreviews(deviceId, true)

  const setModalCenter = useCallback(
    sizes => {
      if (!sizes) sizes = dimensions
      const { width, height } = sizes
      const x = (windowDimensions.width - width) / 2
      const y = (windowDimensions.height - height) / 2
      setModalPosition({ x, y })
    },
    [dimensions, windowDimensions.height, windowDimensions.width]
  )

  const handleFullScreen = useCallback(() => {
    const body = document.querySelector('body')

    if (isFullScreen) {
      body.style.overflowY = 'auto'
      setDimensions(oldDimensions)
      setOldDimensions(null)
    } else {
      body.style.overflowY = 'hidden'
      setOldDimensions(dimensions)
      const { innerWidth, innerHeight } = window
      setDimensions({
        width: innerWidth,
        height: innerHeight
      })
    }

    setFullScreen(!isFullScreen)
  }, [dimensions, isFullScreen, oldDimensions])

  const handleModalClose = useCallback(() => {
    setClosing(true)
    setTimeout(() => {
      if (isFullScreen) handleFullScreen()
      dispatchAction(clearDevicePreviewModal())
      setClosing(false)
      setShowContent(false)
    }, FLASH_DURATION)
  }, [dispatchAction, handleFullScreen, isFullScreen])

  const handleUpdate = useCallback(() => {
    setPlaceholder(true)
    updatePreview(deviceId)
  }, [deviceId, updatePreview])

  useEscKeyDownListener(handleModalClose)

  useEffect(() => {
    if (!isPending) {
      setPlaceholder(false)
    }
    // eslint-disable-next-line
  }, [isPending])

  useEffect(() => {
    if (isFullScreen) {
      const { width, height } = windowDimensions
      setDimensions({ width, height })
    }
    // eslint-disable-next-line
  }, [windowDimensions])

  useEffect(() => {
    setModalCenter()
    // eslint-disable-next-line
  }, [isFullScreen])

  useEffect(() => {
    if (isFullScreen) {
      setModalCenter()
    }
    // eslint-disable-next-line
  }, [dimensions])

  useEffect(() => {
    if (isVisible) {
      resetPreviewDimention()
    }
    // eslint-disable-next-line
  }, [isVisible])

  useEffect(() => {
    const size =
      orientation === 'Portrait'
        ? devicePreviewPortraitSize
        : devicePreviewLandscapeSize
    const newDimensions = getNewDimensions(size)
    setDimensions(newDimensions)
    setModalCenter(newDimensions)
    // eslint-disable-next-line
  }, [orientation])

  const dimensionsWithWrapper = useMemo(
    () => ({
      width: dimensions.width + ADDITIONAL_SIZE,
      height: dimensions.height + ADDITIONAL_SIZE
    }),
    [dimensions]
  )

  const toggleLightMode = useCallback(() => {
    setLightMode(prevState => !prevState)
  }, [])

  const handleResizeStart = useCallback(() => {
    setResizing(true)
  }, [])

  const handleResize = useCallback(
    (e, direction, ref, delta) => {
      if (direction.toLowerCase().includes('right')) {
        resizableRef.current?.updateSize({
          height:
            dimensionsWithWrapper.height +
            delta.width * (dimensions.height / dimensions.width),
          width: dimensionsWithWrapper.width + delta.width
        })
      } else {
        resizableRef.current?.updateSize({
          height: dimensionsWithWrapper.height + delta.height,
          width:
            dimensionsWithWrapper.width +
            delta.height * (dimensions.width / dimensions.height)
        })
      }
    },
    [dimensionsWithWrapper, dimensions]
  )

  const resetPreviewDimention = () => {
    const size =
      orientation === 'Portrait'
        ? devicePreviewPortraitSize
        : devicePreviewLandscapeSize
    const nextDimensions = getNewDimensions(size)
    setDimensions(nextDimensions)
    setModalCenter()
  }
  const handleResizeStop = useCallback((e, direction, ref, delta) => {
    setDimensions(dimensions => ({
      width: dimensions.width + delta.width,
      height:
        dimensions.height + delta.width * (dimensions.height / dimensions.width)
    }))
    setResizing(false)
  }, [])

  const previewUrl = useMemo(() => getDevicePreviewUrl(data), [data])

  const isContentReady = useMemo(() => !!(isVisible && previewUrl), [
    isVisible,
    previewUrl
  ])

  useEffect(
    () => {
      resetPreviewDimention()
      if (isContentReady) {
        setTimeout(() => {
          setShowContent(true)
        }, FLASH_DURATION)
      }
    },
    // eslint-disable-next-line
    [isContentReady]
  )

  const onClickAway = useCallback(
    e => {
      const elements = e.composedPath ? e.composedPath() : e.path
      if (!isResizing && elements.length > 3) handleModalClose()
    },
    [handleModalClose, isResizing]
  )

  const controls = useMemo(() => {
    return (
      <>
        {!isFullScreen && (
          <div className={classNames(classes.controlsItem, 'draggable-handle')}>
            <Tooltip arrow title={t('Move')}>
              <i className="fa-solid fa-grip-dots-vertical" />
            </Tooltip>
          </div>
        )}
        <div
          className={classNames(classes.controlsItem, classes.lightIcon, {
            'is-disable': !showContent
          })}
          onClick={toggleLightMode}
        >
          <Tooltip
            arrow
            title={t('Change background color (affects preview only)')}
          >
            <i className="fa-solid fa-moon-over-sun fa-fade" />
          </Tooltip>
        </div>
        {deviceThumbnailPermission.other && (
          <div className={classNames(classes.controlsItem, 'refresh-icon')}>
            <DevicePreviewRefreshIcon
              deviceId={data?.id}
              onUpdate={handleUpdate}
            />
          </div>
        )}
        <div
          className={classNames(classes.controlsItem)}
          onClick={() => handleFullScreen()}
        >
          <Tooltip arrow title={t(`${isFullScreen ? 'Collapse' : 'Maximize'}`)}>
            <i
              className={`fa-sharp fa-solid ${
                isFullScreen ? 'fa-minimize' : 'fa-maximize'
              }`}
            />
          </Tooltip>
        </div>

        <div
          className={classNames(classes.controlsItem, classes.lastControlItem)}
          onClick={() => handleModalClose()}
        >
          <Tooltip arrow title={t('Close')}>
            <i className="fa-duotone fa-power-off" />
          </Tooltip>
        </div>
      </>
    )
  }, [
    t,
    classes,
    data?.id,
    showContent,
    toggleLightMode,
    handleFullScreen,
    handleModalClose,
    handleUpdate,
    isFullScreen,
    deviceThumbnailPermission
  ])

  const content = useMemo(() => {
    return (
      <div
        className={classNames(classes.frameRoot, {
          [classes.frameFullScreen]: isFullScreen,
          [classes.frameRootLight]: lightMode,
          [classes.frameRootVisible]: showContent
        })}
      >
        <img src={previewUrl} alt="" className={classes.previewImage} />
      </div>
    )
  }, [classes, isFullScreen, lightMode, showContent, previewUrl])

  return (
    <PreviewModalBase
      isVisible={isVisible}
      isLoading={isPending || isPlaceholder}
      isFullScreen={isFullScreen}
      controls={controls}
      content={content}
      isContentReady={isContentReady}
      isClosing={isClosing}
      onClickAway={onClickAway}
      setModalPosition={setModalPosition}
      updatedModalPosition={modalPosition}
      dimensions={dimensions}
      onResizeStart={handleResizeStart}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      bounds="parent"
      customWidth={true}
    />
  )
}

export default withTranslation('translations')(
  withSnackbar(withTheme(memo(DevicePreviewModal)))
)
