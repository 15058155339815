import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { _isEmpty, _isObject } from 'utils/lodash'

import {
  AVI,
  BIN,
  BMP,
  CSV,
  FALLBACK_EXTENSION,
  FLV,
  GIF,
  ICS,
  JPEG,
  JPG,
  JSON,
  MOV,
  MP3,
  MP4,
  MPEG,
  OGG,
  PDF,
  PNG,
  SVG,
  TEXT_XML,
  WAV,
  WEBM,
  WMV,
  XML,
  PPTX,
  PPT
} from 'constants/mimeTypes'
import { CLIENT_ADMIN } from 'constants/roles'
import { isAdd } from './compare'
import { whiteColor } from 'constants/colorConstants'
import { viewTypesKeys } from 'constants/libraryConstants'
import { tagToChipObj, toGroupChipObj } from 'utils/select'

const videMimeTypes = [WEBM, MP4, AVI, FLV, MOV, MPEG, WMV]

export const videoFileExtensions = videMimeTypes.map(mimeType => mimeType.ext)

export function isTruthy(...args) {
  return args.every(arg => !!arg)
}

export function isFalsy(...args) {
  return args.every(arg => !arg)
}

export function isSomeTruthy(...args) {
  return args.some(arg => !!arg)
}

export function isEmpty(arg) {
  if (Object.prototype.toString.call(arg) === '[object Object]') {
    return Object.keys(arg).length === 0
  }
  return arg.length === 0
}

export function takeTruth(...args) {
  return args.find(arg => !!arg)
}

export function isNumber(arg) {
  return typeof arg === 'number'
}

export function isString(arg) {
  return typeof arg === 'string'
}

export function length(arg) {
  return arg.length
}

export function isPositiveNumber(arg) {
  return typeof arg === 'number' && arg > 0
}

export function isLast(index, array) {
  return index === decr(length(array))
}

export function multiply(number, multiplier) {
  return number * multiplier
}

export function decr(number, arg = 1) {
  return number - arg
}

export function isEqual(value, other) {
  const type = Object.prototype.toString.call(value)
  if (type !== Object.prototype.toString.call(other)) return false
  if (['[object String]', '[object Number]', '[object Boolean]'].includes(type))
    return value === other
  if (type === '[object Function]') return toString(value) === toString(other)
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false
  const valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length
  const otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  const compare = function (item1, item2) {
    const itemType = Object.prototype.toString.call(item1)
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false
    } else {
      if (itemType !== Object.prototype.toString.call(item2)) return false
      if (itemType === '[object Function]') {
        if (toString(item1) !== toString(item2)) return false
      } else {
        if (item1 !== item2) return false
      }
    }
  }
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (const key in value) {
      // eslint-disable-next-line
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }
  return true
}

export function isEqualShallow(a, b) {
  for (let key in a) {
    if (a[key] !== b[key]) {
      return false
    }
  }
  return true
}

export function parsePhoneFromBE(phone) {
  if (!phone) {
    return ''
  }
  if (phone.length > 0 && phone[0] === '+') {
    return '+' + phone.slice(1).replace(/\D/g, '')
  } else {
    return '+1' + phone.replace(/\D/g, '')
  }
}

export function findStringsDiff(str1, str2) {
  let diff = ''
  str2.split('').forEach((val, i) => {
    if (val !== str1.charAt(i)) {
      diff += val
    }
  })
  return diff
}

export function getAddress1(data = {}) {
  const { house, street, formattedAddress } = data

  return house ? `${house} ${street}` : street || formattedAddress
}

export const getDeleteConfirmationMessage = (name, t, additionalText) => {
  name = name ? name : t('Item')
  return (
    <span>
      <Trans
        i18nKey="Are you sure you want to delete name"
        defaults="Are you sure you want to delete: <bold>{{name}}</bold>"
        values={{ name }}
        components={{ bold: <b /> }}
      />
      {additionalText && additionalText}
    </span>
  )
}

export const getItemSuccessMessage = (name, status, isPlural) => (
  <span>
    <Trans
      i18nKey="name has been status"
      defaults="<bold>{{name}}</bold> {{verb}} been {{status}}."
      values={{
        name,
        verb: isPlural ? 'have' : 'has',
        status
      }}
      components={{ bold: <b /> }}
    />
  </span>
)

export const getItemAddSuccessMessage = mediaName => (
  <span>
    <Trans
      i18nKey="Media mediaName saved"
      defaults="Media <bold>{{mediaName}}</bold> saved. Would you like to stay on this page to add more media?"
      values={{ mediaName }}
      components={{ bold: <b /> }}
    />
  </span>
)

export const getMessageWithBoldEntityName = (key, values) => (
  <span>
    <Trans i18nKey={key} values={values} components={{ bold: <b /> }} />
  </span>
)

export const pascalToKebabCase = text =>
  text.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()

export const kebabCaseToPascal = text => {
  return text.replace(/(^\w|-\w)/g, txt => {
    return txt.replace(/-/, '').toUpperCase()
  })
}

export const getFileExtensionFromUrl = url => {
  if (url) {
    const urlParts = url.split('.')
    if (urlParts.length > 1) {
      return `.${urlParts[urlParts.length - 1].split('?')[0]}`.toLowerCase()
    }
  }
  return FALLBACK_EXTENSION
}

export const getExtensionFromPath = path => {
  if (!path) {
    return
  }

  return `.${path.split('.').pop().split('?')[0].toLowerCase()}`
}

export const extensionToMimeMap = ext => {
  switch (ext) {
    case CSV.ext:
      return CSV.mime
    case JSON.ext:
      return JSON.mime
    case XML.ext:
      return XML.mime
    case TEXT_XML.ext:
      return TEXT_XML.mime
    case ICS.ext:
      return ICS.mime
    case BMP.ext:
      return BMP.mime
    case GIF.ext:
      return GIF.mime
    case JPEG.ext:
      return JPEG.mime
    case JPG.ext:
      return JPG.mime
    case MP3.ext:
      return MP3.mime
    case MP4.ext:
      return MP4.mime
    case OGG.ext:
      return OGG.mime
    case PDF.ext:
      return PDF.mime
    case PNG.ext:
      return PNG.mime
    case SVG.ext:
      return SVG.mime
    case WAV.ext:
      return WAV.mime
    case WEBM.ext:
      return WEBM.mime
    case PPTX.ext:
      return PPTX.mime
    case PPT.ext:
      return PPT.mime
    case MOV.ext:
      return MOV.mime
    default:
      return undefined
  }
}

export const getFileType = (type, url) => {
  if (type === BIN.mime) {
    return extensionToMimeMap(getFileExtensionFromUrl(url))
  }
  return type
}

export const getLinkProps = (currentPathname, nextPathname) => {
  return currentPathname !== nextPathname
    ? {
        component: Link,
        to: nextPathname
      }
    : {}
}

export const validateFileExtension = (url, allowedDataMimeTypes) => {
  if (!url) {
    return true
  }

  const type = extensionToMimeMap(getFileExtensionFromUrl(url))
  if (type) {
    if (allowedDataMimeTypes.some(mime => type.includes(mime))) {
      return true
    } else {
      return false
    }
  } else return true
}

export const typesToMimeTypes = types => types.map(({ mime }) => mime)

export const typesToExtensions = types => types.map(({ ext }) => ext)

export const getDefaultTitleValue = type => {
  const dateTime = moment().format('DD-MM-YYYY-HH-mm-ss')
  return `New${type}_${dateTime}`
}

export const replaceMarkup = (text, data) =>
  text.replace(/#(.+?)#/g, (match, name) => {
    return data.hasOwnProperty(name) && data[name] !== null ? data[name] : match
  })

export const stripTags = string => string.replace(/(<([^>]+)>)/gi, '')

export const hasOwnNestedProperty = (object = {}, path) => {
  const [property, ...rest] = path.split('.')

  return object.hasOwnProperty(property)
    ? rest.length && typeof object[property] === 'object'
      ? hasOwnNestedProperty(object[property], rest.join('.'))
      : true
    : false
}

export const isNumericString = str => {
  if (typeof str !== 'string') {
    return false
  }
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const isExpired = ({ expireOn }) =>
  expireOn && moment(expireOn).isBefore(moment())

export const replaceNullValuesWithEmptyString = obj => {
  try {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        value === null ? '' : value
      ])
    )
  } catch (e) {
    return obj
  }
}

export const initialTimezone = momentTZ.tz.guess()

export const getObjectValues = obj =>
  Object.values(obj).filter(n => !_isEmpty(n))

export const stringIncludesSubstr = (str = '', substr = '') =>
  str.trim().toLowerCase().includes(substr.trim().toLowerCase())

export const isTrialClient = (role, client) =>
  role.org &&
  role.level === CLIENT_ADMIN &&
  client?.license?.isTrial &&
  client.status === 'Active'

export const removeSpacing = str => str?.replace(/\s/g, '')

export const monthOptions = moment.months().map((month, i) => ({
  index: i,
  label: month,
  value: month
}))

export const prepareColorData = data => {
  if (typeof data === 'string') {
    return removeSpacing(data)
  }

  const obj = {}

  Object.entries(data).forEach(([key, value]) => {
    if (key.endsWith('_color')) {
      if (typeof value === 'string') {
        obj[key] = removeSpacing(value)
      }
    } else if (key.endsWith('_background')) {
      obj[key] = {
        ...value,
        color: removeSpacing(value.color)
      }
    } else {
      obj[key] = value
    }
  })

  return obj
}

export const prepareBgColors = ({
  data,
  isEnabledBrandGuide,
  brandGuideSettings,
  brandGuideColors,
  mode
}) => {
  let brandingBgColors = {}
  const dataToMap = _isObject(data) ? Object.entries(data) : data

  dataToMap.forEach(([key, value]) => {
    if (
      isEnabledBrandGuide &&
      brandGuideSettings?.isMandatory &&
      isAdd(mode) &&
      !brandGuideColors.includes(value.color) &&
      value.color !== whiteColor &&
      key.endsWith('_background') &&
      value.type === 'color'
    ) {
      brandingBgColors[key] = { ...value, color: brandGuideColors[0] }
    }
  })

  return brandingBgColors
}

export const dayOptions = moment.weekdays().map((day, i) => ({
  index: i,
  label: day,
  value: day
}))

export const daysOptions = moment.weekdays().map((day, i) => ({
  index: i,
  label: `${day}s`,
  value: `${day}s`
}))

export const momentDayOptions = moment.weekdays().map((day, i) => ({
  index: i,
  name: day,
  value: moment().day(day)
}))

export const stringContainsNumber = str => /\d/.test(str)

export const isLegacyIcon = icon => !/\w+\s\w+/.test(icon)

export const getValueWithoutUnit = value => {
  return typeof value === 'string' && value.endsWith('px')
    ? value.slice(0, -2)
    : value
}

export const getLibraryView = ({ viewSettings, type, defaultView }) =>
  viewSettings[viewTypesKeys[type]] || defaultView

const getOptionsToHide = ({ items, selectedIds, commonItems }) => {
  if (!items.length || !commonItems.length) return []

  const removedIds = items
    .filter(({ value }) => !selectedIds.includes(value))
    .map(({ value }) => value)

  return commonItems.filter(({ value }) => !removedIds.includes(value))
}

export const getGroupTagHideOptions = ({
  defaultSelectedData,
  selectedGroups = [],
  selectedTags = [],
  commonGroups = [],
  commonTags = []
}) => {
  const { groups = [], tags = [] } = defaultSelectedData

  const selectedTagIds = selectedTags.map(tag => tag.value)
  const selectedGroupIds = selectedGroups.map(group => group.value)

  const hideTagOptions = getOptionsToHide({
    items: tags,
    selectedIds: selectedTagIds,
    commonItems: commonTags
  })

  const hideGroupOptions = getOptionsToHide({
    items: groups,
    selectedIds: selectedGroupIds,
    commonItems: commonGroups
  })

  return [hideTagOptions, hideGroupOptions]
}

export const getSearchEntityData = item => {
  if (!item || item.isViewMore) return null

  const { id, title, icon, filterKey, filterValue } = item

  switch (filterKey) {
    case 'group':
      return toGroupChipObj({
        id,
        title,
        color: icon.color
      })
    case 'tag':
      return tagToChipObj(item)
    case 'table':
      return {
        label: filterValue,
        name: filterKey,
        value: filterValue
      }
    case 'scheduleType':
      return {
        value: filterValue,
        label: filterValue
      }
    case 'deviceTypeId':
      return {
        name: filterKey,
        value: id,
        label: title,
        data: {
          id,
          name: title,
          alias: title,
          color: icon.color
        }
      }
    default:
      return null
  }
}

export const getAspectRatio = ({ w, h }) => {
  let aspectRatio

  if (w > h) {
    aspectRatio = w / h
  } else {
    aspectRatio = h / w
  }

  return aspectRatio
}
