import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw } from 'draft-js'
import classNames from 'classnames'
import { Typography, makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { simulateEvent } from 'utils'
import { DARK } from 'theme'
import CustomFontSelect from './CustomFontSelect'
import { fontsService } from 'services'
import * as types from 'actions'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import CustomFontSizeSelect from './CustomFontSizeSelect'
import CustomBlockTypeSelect from './CustomBlockTypeSelect'

const DEFAULT_FONT_SIZE = 16
const DEFAULT_FONT_FAMILY = 'Times New Roman'

const useStyles = makeStyles(
  ({ palette, colors, type, typography, spacing }) => ({
    root: {
      border: `5px solid ${palette[type].pages.rss.addRss.upload.border}`,
      backgroundImage: palette[type].pages.rss.addRss.upload.background,
      borderRadius: '4px'
    },
    editorHeader: {
      padding: '20px 0 25px 15px',
      background: palette[type].pages.rss.addRss.upload.background,
      borderRadius: '5px'
    },
    editorHeaderText: typography.header[type],
    editorWrap: {
      ...(type === DARK
        ? {
            '& .rdw-dropdown-optionwrapper': {
              background: '#112034',

              '& .rdw-dropdownoption-active, & .rdw-dropdownoption-highlighted': {
                background: palette[DARK].formControls.select.active.background
              }
            },

            '& .rdw-colorpicker-modal, & .rdw-link-modal, & .rdw-embedded-modal, & .rdw-emoji-modal, & .rdw-image-modal': {
              background: colors.background.third[DARK],
              color: colors.light,
              border: `1px solid ${colors.light}`,
              boxShadow: 'none',

              '& .rdw-image-modal-btn:disabled, & .rdw-link-modal-btn:disabled, & .rdw-embedded-modal-btn:disabled': {
                opacity: 0.5
              },

              '& input, & button': {
                background: palette[DARK].formControls.input.background,
                color: palette[DARK].formControls.input.color,
                border: `1px solid ${colors.light}`
              }
            },

            '& .rdw-option-wrapper': {
              backgroundColor: 'transparent',
              border: `1px solid ${colors.light}`,

              '& img': {
                filter:
                  'invert(46%) sepia(29%) saturate(310%) hue-rotate(183deg) brightness(85%) contrast(86%)'
              }
            },
            '& .rdw-dropdown-wrapper': {
              backgroundColor: 'transparent',
              color: colors.light,
              border: `1px solid ${colors.light}`
            }
          }
        : {})
    },
    editorToolbarWrap: {
      border: 'none',

      ...(type === DARK
        ? {
            backgroundColor:
              palette[DARK].pages.rss.addRss.editorToolbar.background,

            '& .rdw-dropdown-carettoopen, & .rdw-dropdown-carettoclose, & img': {
              filter:
                'invert(46%) sepia(29%) saturate(310%) hue-rotate(183deg) brightness(85%) contrast(86%)'
            }
          }
        : {})
    },
    linkModalAutoHeight: {
      '& .rdw-link-modal': {
        height: 'auto'
      }
    },
    embeddedModalAutoHeight: {
      '& .rdw-embedded-modal': {
        height: 'auto'
      }
    },
    colorpickerModalAutoHeight: {
      '& .rdw-colorpicker-modal': {
        height: 'auto'
      }
    },
    imageModalAutoHeight: {
      '& .rdw-image-modal': {
        height: 'auto'
      }
    },
    emojiModalAutoHeight: {
      '& .rdw-emoji-modal': {
        height: 'auto'
      }
    },
    linkModalRevert: {
      '& .rdw-link-modal': {
        right: 40,
        left: 'auto'
      }
    },
    embeddedModalRevert: {
      '& .rdw-embedded-modal': {
        right: 0,
        left: 'auto'
      }
    },
    colorpickerModalRevert: {
      '& .rdw-colorpicker-modal': {
        right: 0,
        left: 'auto'
      }
    },
    imageModalRevert: {
      '& .rdw-image-modal': {
        right: 0,
        left: 'auto'
      }
    },
    emojiModalRevert: {
      '& .rdw-emoji-modal': {
        right: 0,
        left: 'auto'
      }
    },
    editor: ({ minHeight, maxHeight, height, maxWidth }) => ({
      padding: spacing(1),
      background: palette[type].formControls.input.background,
      borderRadius: '4px',
      overflow: 'auto',
      minHeight: minHeight,
      maxHeight: maxHeight,
      maxWidth: maxWidth,
      height: height,

      '&::-webkit-scrollbar': {
        width: 6
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette[type].scrollbar.background,
        borderRadius: 5
      }
    }),
    error: {
      color: colors.error,
      fontSize: 9,
      position: 'absolute',
      left: '5px',
      bottom: '-17px'
    },
    errorLabel: {
      color: colors.error
    },
    container: ({ withCustomFontFamily, withCustomFontSize }) => ({
      ...(withCustomFontSize && {
        fontSize: DEFAULT_FONT_SIZE
      }),
      ...(withCustomFontFamily && {
        fontFamily: DEFAULT_FONT_FAMILY
      }),
      position: 'relative'
    }),
    marginBottom: {
      marginBottom: 15
    },
    fullHeight: {
      height: '100%'
    },
    rootFullHeight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    editorWrapFullHeight: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    }
  })
)

const WysiwygEditor = ({
  label,
  name,
  editorState,
  onChange = f => f,
  onBlur = f => f,
  error,
  toolbar = {},
  touched,
  marginBottom = true,
  fullHeight = false,
  iconSettings,
  minHeight,
  maxHeight,
  withCustomFontFamily,
  withCustomFontSize = false,
  withCustomBlockType = false
}) => {
  const dispatch = useDispatch()
  const editorWrapperRef = useRef()
  const editorRef = useRef()
  const { width: editorWrapperWidth } =
    editorWrapperRef.current?.getBoundingClientRect() || {}

  const classes = useStyles({
    minHeight,
    maxHeight: maxHeight || 525,
    height: fullHeight ? 'calc(100% - 15px)' : 'auto',
    maxWidth: editorWrapperWidth && editorWrapperWidth - 10,
    withCustomFontFamily,
    withCustomFontSize
  })

  const [initialized, setInitialized] = useState(false)

  const handleChange = useCallback(
    value => {
      onChange({ target: { name, value } })
    },
    [name, onChange]
  )

  const handleBlur = useCallback(() => {
    onBlur(simulateEvent(name, null))
  }, [onBlur, name])

  const getFonts = useCallback(
    async fonts => {
      if (fonts.length) {
        const response = await fontsService.getFonts({
          family: fonts.join(',')
        })
        dispatch({ type: types.GET_FONTS_SUCCESS, payload: response })
      }
    },
    [dispatch]
  )

  useEffect(() => {
    try {
      if (!initialized) {
        const fonts = []
        const { blocks = [] } = convertToRaw(editorState.getCurrentContent())

        if (blocks.some(({ text }) => !!text)) {
          setInitialized(true)
        }

        blocks.forEach(({ inlineStyleRanges }) => {
          if (!Array.isArray(inlineStyleRanges)) {
            return
          }
          inlineStyleRanges.forEach(({ style }) => {
            if (style && style.startsWith('fontfamily-')) {
              const font = style.replace('fontfamily-', '')
              if (!fonts.includes(font)) {
                fonts.push(font)
              }
            }
          })
        })

        getFonts(fonts)
      }
    } catch (e) {}
    // eslint-disable-next-line
  }, [editorState])

  return (
    <div
      className={classNames(classes.container, {
        [classes.marginBottom]: marginBottom,
        [classes.fullHeight]: fullHeight
      })}
      onBlur={handleBlur}
    >
      <div
        className={classNames(classes.root, {
          [classes.rootFullHeight]: fullHeight
        })}
        ref={editorWrapperRef}
      >
        {label && (
          <header className={classes.editorHeader}>
            <Typography
              className={classNames(classes.editorHeaderText, {
                [classes.errorLabel]: error && touched
              })}
            >
              {label}
            </Typography>
          </header>
        )}
        <Editor
          ref={editorRef}
          editorState={editorState}
          // contentState={contentState}
          onEditorStateChange={handleChange}
          editorClassName={classes.editor}
          wrapperClassName={classNames(classes.editorWrap, {
            [classes.editorWrapFullHeight]: fullHeight
          })}
          toolbarClassName={classNames(classes.editorToolbarWrap, {
            [classes.linkModalAutoHeight]: iconSettings?.link?.isAutoHeight,
            [classes.linkModalRevert]: iconSettings?.link?.revert,
            [classes.embeddedModalAutoHeight]:
              iconSettings?.embedded_link?.isAutoHeight,
            [classes.embeddedModalRevert]: iconSettings?.embedded_link?.revert,
            [classes.colorpickerModalAutoHeight]:
              iconSettings?.colorpicker?.isAutoHeight,
            [classes.colorpickerModalRevert]: iconSettings?.colorpicker?.revert,
            [classes.imageModalAutoHeight]: iconSettings?.image?.isAutoHeight,
            [classes.imageModalRevert]: iconSettings?.image?.revert,
            [classes.emojiModalAutoHeight]: iconSettings?.emoji?.isAutoHeight,
            [classes.emojiModalRevert]: iconSettings?.emoji?.revert
          })}
          toolbar={{
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline']
            },
            textAlign: { inDropdown: false },
            fontSize: {
              options: [
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                16,
                18,
                24,
                30,
                36,
                48,
                60,
                72,
                96
              ]
            },
            blockType: {
              inDropdown: true,
              options: [
                'Normal',
                'H1',
                'H2',
                'H3',
                'H4',
                'H5',
                'H6',
                'Blockquote',
                'Code'
              ]
            },
            fontFamily: {
              options: [
                'Arial',
                'Georgia',
                'Impact',
                'Nunito Sans',
                'Tahoma',
                'Times New Roman',
                'Verdana'
              ]
            },
            ...(withCustomBlockType && {
              blockType: {
                component: props => (
                  <CustomBlockTypeSelect {...props} editorRef={editorRef} />
                )
              }
            }),
            ...(withCustomFontSize && {
              fontSize: {
                component: props => {
                  const { fontSize } = props.currentState

                  return (
                    <CustomFontSizeSelect
                      {...props}
                      editorRef={editorRef}
                      currentState={{
                        fontSize: DEFAULT_FONT_SIZE,
                        ...(fontSize && { fontSize })
                      }}
                    />
                  )
                }
              }
            }),
            ...(withCustomFontFamily && {
              fontFamily: {
                component: props => {
                  const { fontFamily } = props.currentState

                  return (
                    <CustomFontSelect
                      {...props}
                      editorRef={editorRef}
                      currentState={{
                        fontFamily: DEFAULT_FONT_FAMILY,
                        ...(fontFamily && { fontFamily })
                      }}
                    />
                  )
                }
              }
            }),
            ...toolbar
          }}
          stripPastedStyles
        />
      </div>
      {error && touched ? (
        <Typography className={classes.error}>{error}</Typography>
      ) : null}
    </div>
  )
}

export default WysiwygEditor
