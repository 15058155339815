import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Text } from 'components/Typography'

const PageTitle = ({
  t,
  title = '',
  icon,
  selectedCount = 0,
  titleClassName = ''
}) => {
  return (
    <div key="selectTitle" style={{ display: 'flex', alignItems: 'center' }}>
      <Text
        component="h2"
        className={titleClassName}
        color="title.primary"
        variant="bigger"
        weight="bold"
      >
        {title}
      </Text>
      {'\u00A0'}

      {!selectedCount || (
        <Text
          component="h3"
          variant="bigger"
          color="title.primary"
          weight="bold"
        >
          &nbsp;
          {`| ${selectedCount} ${t('selected')}`}
        </Text>
      )}
      {icon}
    </div>
  )
}

PageTitle.propTypes = {
  selectedCount: PropTypes.number,
  title: PropTypes.string.isRequired
}

export default withTranslation('translations')(PageTitle)
