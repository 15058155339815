import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  LOGIN_PENDING,
  loginFailure,
  loginSuccess,
  REGISTER,
  TWO_FACTOR_PENDING,
  twoFactorFailure,
  twoFactorSuccess,
  VERIFY_EMAIL,
  verifyEmailError,
  verifyEmailSuccess
} from 'actions/new/auth'
import {
  loginUserService,
  twoFactorService,
  register,
  emailVerify
} from 'services/authenticationService'
import {
  getTokenNameByRole,
  storageRemoveItem,
  storageSetToken
} from 'utils/localStorage'
import { ORIGINAL_USERS } from 'constants/api'
import { getDetails } from 'services/userService'
import { userDetailsSuccess } from 'actions/userActions'

export default function* authWatcher() {
  yield all([
    takeEvery(LOGIN_PENDING, loginWorker),
    takeEvery(TWO_FACTOR_PENDING, twoFactorWorker),
    takeEvery(REGISTER, registerWorker),
    takeLatest(VERIFY_EMAIL, verifyEmailWorker)
  ])
}

function* loginSuccessHandler({
  role,
  tokenType,
  accessToken,
  expiresIn,
  onSuccess
}) {
  storageRemoveItem(ORIGINAL_USERS)
  storageSetToken(getTokenNameByRole(role), tokenType, accessToken, expiresIn)

  const user = yield call(getDetails)

  if (role !== user.role.level) {
    storageSetToken(
      getTokenNameByRole(user.role.level),
      tokenType,
      accessToken,
      expiresIn
    )
  }

  yield put(userDetailsSuccess(user))
  yield onSuccess(user.role.level)
}

function* loginWorker({
  username,
  password,
  rememberMe,
  role,
  onSuccess,
  onFailure,
  on2FA
}) {
  const authData = { username, password, rememberMe, role }

  try {
    const { accessToken, tokenType, expiresIn } = yield call(loginUserService, {
      ...authData
    })

    if (tokenType === '2FA') {
      yield put(loginFailure())
      return on2FA && on2FA(authData)
    }

    yield loginSuccessHandler({
      role,
      expiresIn,
      accessToken,
      tokenType,
      onSuccess: function* (role) {
        yield put(loginSuccess())
        if (onSuccess) onSuccess(role)
      }
    })
  } catch (error) {
    yield put(loginFailure(error))
    if (onFailure) onFailure(error, authData)
  }
}

function* twoFactorWorker({ email, code, onSuccess, onFailure, role }) {
  try {
    const { accessToken, tokenType, expiresIn } = yield call(twoFactorService, {
      email,
      code,
      role
    })

    yield loginSuccessHandler({
      role: role,
      accessToken,
      tokenType,
      expiresIn,
      onSuccess: function* (role) {
        yield put(twoFactorSuccess())
        onSuccess && onSuccess(role)
      }
    })
  } catch (error) {
    yield put(twoFactorFailure())
    onFailure && onFailure(error)
  }
}

function* registerWorker({ data, onSuccess, onFailure }) {
  try {
    yield call(register, data)
    if (onSuccess) onSuccess()
  } catch (err) {
    if (onFailure) onFailure(err)
  }
}

function* verifyEmailWorker({ payload }) {
  try {
    const data = yield call(emailVerify, payload)

    yield put(verifyEmailSuccess(data))
  } catch (error) {
    yield put(verifyEmailError(error))
  }
}
