import { useLocation } from 'react-router'
import { useMemo } from 'react'

import useBrowserWarning from 'hooks/useBrowserWarning'
import useSmallScreenWarning from 'hooks/useSmallScreenWarning'
import { routeByName } from 'constants/index'

const pathExceptions = [routeByName.contentApprove.root]

const ServiceNotifications = () => {
  const location = useLocation()

  const hideNotification = useMemo(() => {
    return (
      location.pathname.startsWith('/public') ||
      pathExceptions.includes(location.pathname)
    )
  }, [location.pathname])

  useBrowserWarning(hideNotification)
  useSmallScreenWarning(!hideNotification)

  return null
}

export default ServiceNotifications
