import sortBy from 'lodash/sortBy'

const languages = [
  {
    code: 'af',
    name: 'Afrikaans',
    alias: 'Afrikaans',
    icon: require('../common/assets/icons/flags/za.png')
  },
  {
    code: 'sq',
    name: 'Albanian',
    alias: 'shqiptare',
    icon: require('../common/assets/icons/flags/al.png')
  },
  {
    code: 'ar',
    name: 'Arabic',
    alias: 'عربي',
    icon: require('../common/assets/icons/flags/sa.png')
  },
  {
    code: 'bs',
    name: 'Bosnian',
    alias: 'Bosanski',
    icon: require('../common/assets/icons/flags/ba.png')
  },
  {
    code: 'bg',
    name: 'Bulgarian',
    alias: 'български',
    icon: require('../common/assets/icons/flags/bg.png')
  },
  {
    code: 'zh',
    name: 'Chinese (Simplified)',
    alias: '简体中文)',
    icon: require('../common/assets/icons/flags/cn.png')
  },
  {
    code: 'hr',
    name: 'Croatian',
    alias: 'Hrvatski',
    icon: require('../common/assets/icons/flags/hr.png')
  },
  {
    code: 'cs',
    name: 'Czech',
    alias: 'čeština',
    icon: require('../common/assets/icons/flags/cz.png')
  },
  {
    code: 'da',
    name: 'Danish',
    alias: 'Dansk',
    icon: require('../common/assets/icons/flags/dk.png')
  },
  {
    code: 'nl',
    name: 'Dutch',
    alias: 'Nederlands',
    icon: require('../common/assets/icons/flags/nl.png')
  },
  {
    code: 'en',
    name: 'English',
    alias: 'English',
    icon: require('../common/assets/icons/flags/us.png')
  },
  {
    code: 'et',
    name: 'Estonian',
    alias: 'Eestlane',
    icon: require('../common/assets/icons/flags/ee.png')
  },
  {
    code: 'fil',
    name: 'Filipino (Tagalog)',
    alias: 'Filipino (Tagalog)',
    icon: require('../common/assets/icons/flags/ph.png')
  },
  {
    code: 'fi',
    name: 'Finnish',
    alias: 'Suomalainen',
    icon: require('../common/assets/icons/flags/fi.png')
  },
  {
    code: 'fr',
    name: 'French',
    alias: 'Français',
    icon: require('../common/assets/icons/flags/fr.png')
  },
  {
    code: 'de',
    name: 'German',
    alias: 'Deutsche',
    icon: require('../common/assets/icons/flags/de.png')
  },
  {
    code: 'el',
    name: 'Greek',
    alias: 'Ελληνικά',
    icon: require('../common/assets/icons/flags/gr.png')
  },
  {
    code: 'he',
    name: 'Hebrew',
    alias: 'עִברִית',
    icon: require('../common/assets/icons/flags/il.png')
  },
  {
    code: 'hi',
    name: 'Hindi',
    alias: 'हिन्दी',
    icon: require('../common/assets/icons/flags/in.png')
  },
  {
    code: 'hu',
    name: 'Hungarian',
    alias: 'Magyar',
    icon: require('../common/assets/icons/flags/hu.png')
  },
  {
    code: 'is',
    name: 'Icelandic',
    alias: 'íslenskur',
    icon: require('../common/assets/icons/flags/is.png')
  },
  {
    code: 'id',
    name: 'Indonesian',
    alias: 'bahasa Indonesia',
    icon: require('../common/assets/icons/flags/id.png')
  },
  {
    code: 'ga',
    name: 'Irish',
    alias: 'Gaeilge',
    icon: require('../common/assets/icons/flags/ie.png')
  },
  {
    code: 'it',
    name: 'Italian',
    alias: 'Italiano(a)',
    icon: require('../common/assets/icons/flags/it.png')
  },
  {
    code: 'ja',
    name: 'Japanese',
    alias: '日本語',
    icon: require('../common/assets/icons/flags/jp.png')
  },
  {
    code: 'ko',
    name: 'Korean',
    alias: '한국어',
    icon: require('../common/assets/icons/flags/kr.png')
  },
  {
    code: 'la',
    name: 'Latin',
    alias: 'Latinus',
    icon: require('../common/assets/icons/flags/va.png')
  },
  {
    code: 'mn',
    name: 'Mongolian',
    alias: 'Монгол',
    icon: require('../common/assets/icons/flags/mn.png')
  },
  {
    code: 'no',
    name: 'Norwegian',
    alias: 'norsk',
    icon: require('../common/assets/icons/flags/no.png')
  },
  {
    code: 'fa',
    name: 'Persian',
    alias: 'فارسی',
    icon: require('../common/assets/icons/flags/ir.png')
  },
  {
    code: 'pl',
    name: 'Polish',
    alias: 'Polskie',
    icon: require('../common/assets/icons/flags/pl.png')
  },
  {
    code: 'pt',
    name: 'Portuguese',
    alias: 'Português',
    icon: require('../common/assets/icons/flags/pt.png')
  },
  {
    code: 'ro',
    name: 'Romanian',
    alias: 'Română',
    icon: require('../common/assets/icons/flags/ro.png')
  },
  {
    code: 'ru',
    name: 'Russian',
    alias: 'Русский',
    icon: require('../common/assets/icons/flags/ru.png')
  },
  {
    code: 'sr',
    name: 'Serbian',
    alias: 'Српски',
    icon: require('../common/assets/icons/flags/rs.png')
  },
  {
    code: 'sl',
    name: 'Slovenian',
    alias: 'Slovenščina',
    icon: require('../common/assets/icons/flags/sl.png')
  },
  {
    code: 'es',
    name: 'Spanish',
    alias: 'Español',
    icon: require('../common/assets/icons/flags/es.png')
  },
  {
    code: 'sw',
    name: 'Swahili',
    alias: 'kiswahili',
    icon: require('../common/assets/icons/flags/ke.png')
  },
  {
    code: 'sv',
    name: 'Swedish',
    alias: 'Svenska',
    icon: require('../common/assets/icons/flags/se.png')
  },
  {
    code: 'th',
    name: 'Thai',
    alias: 'ไทย',
    icon: require('../common/assets/icons/flags/th.png')
  },
  {
    code: 'tr',
    name: 'Turkish',
    alias: 'Türk',
    icon: require('../common/assets/icons/flags/tr.png')
  },
  {
    code: 'uk',
    name: 'Ukrainian',
    alias: 'Українська',
    icon: require('../common/assets/icons/flags/ua.png')
  },
  {
    code: 'vi',
    name: 'Vietnamese',
    alias: 'Tiếng Việt',
    icon: require('../common/assets/icons/flags/vn.png')
  },
  {
    code: 'cy',
    name: 'Welsh',
    alias: 'Cymraeg',
    icon: require('../common/assets/icons/flags/gb-wls.png')
  },
  {
    code: 'zu',
    name: 'Zulu',
    alias: 'Zulu',
    icon: require('../common/assets/icons/flags/za.png')
  }
]

const themeLanguages = [
  {
    code: 'af',
    name: 'Afrikaans',
    alias: 'Afrikaans',
    icon: require('../common/assets/icons/flags/za.png')
  },
  {
    code: 'sq',
    name: 'Albanian',
    alias: 'shqiptare',
    icon: require('../common/assets/icons/flags/al.png')
  },
  {
    code: 'ar',
    name: 'Arabic',
    alias: 'عربي',
    icon: require('../common/assets/icons/flags/sa.png')
  },
  {
    code: 'bs',
    name: 'Bosnian',
    alias: 'Bosanski',
    icon: require('../common/assets/icons/flags/ba.png')
  },
  {
    code: 'bg',
    name: 'Bulgarian',
    alias: 'български',
    icon: require('../common/assets/icons/flags/bg.png')
  },
  {
    code: 'zh',
    name: 'Chinese (Simplified)',
    alias: '简体中文)',
    icon: require('../common/assets/icons/flags/cn.png')
  },
  {
    code: 'hr',
    name: 'Croatian',
    alias: 'Hrvatski',
    icon: require('../common/assets/icons/flags/hr.png')
  },
  {
    code: 'cs',
    name: 'Czech',
    alias: 'čeština',
    icon: require('../common/assets/icons/flags/cz.png')
  },
  {
    code: 'da',
    name: 'Danish',
    alias: 'Dansk',
    icon: require('../common/assets/icons/flags/dk.png')
  },
  {
    code: 'nl',
    name: 'Dutch',
    alias: 'Nederlands',
    icon: require('../common/assets/icons/flags/nl.png')
  },
  {
    code: 'en',
    name: 'English',
    alias: 'English',
    icon: require('../common/assets/icons/flags/us.png')
  },
  {
    code: 'et',
    name: 'Estonian',
    alias: 'Eestlane',
    icon: require('../common/assets/icons/flags/ee.png')
  },
  {
    code: 'fil',
    name: 'Filipino (Tagalog)',
    alias: 'Filipino (Tagalog)',
    icon: require('../common/assets/icons/flags/ph.png')
  },
  {
    code: 'fi',
    name: 'Finnish',
    alias: 'Suomalainen',
    icon: require('../common/assets/icons/flags/fi.png')
  },
  {
    code: 'fr',
    name: 'French',
    alias: 'Français',
    icon: require('../common/assets/icons/flags/fr.png')
  },
  {
    code: 'de',
    name: 'German',
    alias: 'Deutsche',
    icon: require('../common/assets/icons/flags/de.png')
  },
  {
    code: 'el',
    name: 'Greek',
    alias: 'Ελληνικά',
    icon: require('../common/assets/icons/flags/gr.png')
  },
  {
    code: 'he',
    name: 'Hebrew',
    alias: 'עִברִית',
    icon: require('../common/assets/icons/flags/il.png')
  },
  {
    code: 'hi',
    name: 'Hindi',
    alias: 'हिन्दी',
    icon: require('../common/assets/icons/flags/in.png')
  },
  {
    code: 'hu',
    name: 'Hungarian',
    alias: 'Magyar',
    icon: require('../common/assets/icons/flags/hu.png')
  },
  {
    code: 'is',
    name: 'Icelandic',
    alias: 'íslenskur',
    icon: require('../common/assets/icons/flags/is.png')
  },
  {
    code: 'id',
    name: 'Indonesian',
    alias: 'bahasa Indonesia',
    icon: require('../common/assets/icons/flags/id.png')
  },
  {
    code: 'ga',
    name: 'Irish',
    alias: 'Gaeilge',
    icon: require('../common/assets/icons/flags/ie.png')
  },
  {
    code: 'it',
    name: 'Italian',
    alias: 'Italiano(a)',
    icon: require('../common/assets/icons/flags/it.png')
  },
  {
    code: 'ja',
    name: 'Japanese',
    alias: '日本語',
    icon: require('../common/assets/icons/flags/jp.png')
  },
  {
    code: 'ko',
    name: 'Korean',
    alias: '한국어',
    icon: require('../common/assets/icons/flags/kr.png')
  },
  {
    code: 'la',
    name: 'Latin',
    alias: 'Latinus',
    icon: require('../common/assets/icons/flags/va.png')
  },
  {
    code: 'mn',
    name: 'Mongolian',
    alias: 'Монгол',
    icon: require('../common/assets/icons/flags/mn.png')
  },
  {
    code: 'no',
    name: 'Norwegian',
    alias: 'norsk',
    icon: require('../common/assets/icons/flags/no.png')
  },
  {
    code: 'fa',
    name: 'Persian',
    alias: 'فارسی',
    icon: require('../common/assets/icons/flags/ir.png')
  },
  {
    code: 'pl',
    name: 'Polish',
    alias: 'Polskie',
    icon: require('../common/assets/icons/flags/pl.png')
  },
  {
    code: 'pt',
    name: 'Portuguese',
    alias: 'Português',
    icon: require('../common/assets/icons/flags/pt.png')
  },
  {
    code: 'ro',
    name: 'Romanian',
    alias: 'Română',
    icon: require('../common/assets/icons/flags/ro.png')
  },
  {
    code: 'ru',
    name: 'Russian',
    alias: 'Русский',
    icon: require('../common/assets/icons/flags/ru.png')
  },
  {
    code: 'sr',
    name: 'Serbian',
    alias: 'Српски',
    icon: require('../common/assets/icons/flags/rs.png')
  },
  {
    code: 'sl',
    name: 'Slovenian',
    alias: 'Slovenščina',
    icon: require('../common/assets/icons/flags/sl.png')
  },
  {
    code: 'es',
    name: 'Spanish',
    alias: 'Español',
    icon: require('../common/assets/icons/flags/es.png')
  },
  {
    code: 'sw',
    name: 'Swahili',
    alias: 'kiswahili',
    icon: require('../common/assets/icons/flags/ke.png')
  },
  {
    code: 'sv',
    name: 'Swedish',
    alias: 'Svenska',
    icon: require('../common/assets/icons/flags/se.png')
  },
  {
    code: 'th',
    name: 'Thai',
    alias: 'ไทย',
    icon: require('../common/assets/icons/flags/th.png')
  },
  {
    code: 'tr',
    name: 'Turkish',
    alias: 'Türk',
    icon: require('../common/assets/icons/flags/tr.png')
  },
  {
    code: 'uk',
    name: 'Ukrainian',
    alias: 'Українська',
    icon: require('../common/assets/icons/flags/ua.png')
  },
  {
    code: 'vi',
    name: 'Vietnamese',
    alias: 'Tiếng Việt',
    icon: require('../common/assets/icons/flags/vn.png')
  },
  {
    code: 'cy',
    name: 'Welsh',
    alias: 'Cymraeg',
    icon: require('../common/assets/icons/flags/gb-wls.png')
  },
  {
    code: 'zu',
    name: 'Zulu',
    alias: 'Zulu',
    icon: require('../common/assets/icons/flags/za.png')
  }
]

const sortLanguages = languages => sortBy(languages, ['name'])

export const sortedLanguages = sortLanguages(languages)

export const sortedThemeLanguages = sortLanguages(themeLanguages)

export default languages
