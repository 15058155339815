import React, { useMemo } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'
import Text from 'components/Typography/Text'
import { getAvgMemory } from '../../../../../utils/deviceUtils'

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline'
  },
  iconExclamation: {
    margin: '5px'
  },
  usageTitle: {
    padding: '3px 0',
    fontSize: '12px'
  },
  usageValue: {
    fontWeight: '600',
    fontSize: '13px'
  }
}))

const DeviceRamViewCell = ({
  memory,
  osVersion,
  totalMemory,
  avgMemory,
  memoryAverageUsage,
  memoryTotalSpace,
  memoryUtilization
}) => {
  const averageUsage = memoryAverageUsage?.replace('%', '')
  const classes = useStyles()
  const { t } = useTranslation()
  const calculatedAvgMemory = useMemo(
    () =>
      getAvgMemory({
        avgMemory,
        totalMemory,
        memoryUtilization: memory
      }),
    [avgMemory, memory, totalMemory]
  )

  const freeSpace = useMemo(() => {
    if (totalMemory && memory) {
      const freeSpaceInGb =
        totalMemory?.toLowerCase().replace('gb', '') -
        memory?.toLowerCase().replace('gb', '')
      if (freeSpaceInGb >= 1) {
        return (Math.round(freeSpaceInGb * 100) / 100).toFixed(2) + 'GB'
      } else {
        return (Math.round(freeSpaceInGb * 100) / 100).toFixed(2) * 1024 + 'MB'
      }
    }
  }, [memory, totalMemory])

  const memoryFreeSpace = useMemo(() => {
    if (memoryTotalSpace && memoryUtilization) {
      const freeSpaceInGb = memoryTotalSpace - memoryUtilization
      if (freeSpaceInGb >= 1) {
        return (Math.round(freeSpaceInGb * 100) / 100).toFixed(2) + 'GB'
      } else {
        return (Math.round(freeSpaceInGb * 100) / 100).toFixed(2) * 1024 + 'MB'
      }
    }
  }, [memoryUtilization, memoryTotalSpace])

  const color = useMemo(() => {
    if (
      (calculatedAvgMemory >= 70 && calculatedAvgMemory <= 89) ||
      (averageUsage >= 70 && averageUsage <= 89)
    ) {
      return 'needsAttentionStatus'
    } else if (calculatedAvgMemory >= 90 || averageUsage >= 90) {
      return 'warning'
    }
  }, [averageUsage, calculatedAvgMemory])

  const avg = calculatedAvgMemory || averageUsage
  const isHighRamUtilization = avg >= 70

  return (
    <>
      <div className={classes.contentWrapper}>
        <Text color={color}>
          {osVersion &&
            (((osVersion.includes('Android') ||
              osVersion.includes('BrightSign')) &&
              parseFloat(totalMemory) <= 2) ||
            parseFloat(memoryTotalSpace) <= 2 ||
            (osVersion.includes('Ubuntu') && parseFloat(totalMemory) <= 3.5) ||
            parseFloat(memoryTotalSpace) <= 4 ||
            (!(
              osVersion.includes('Ubuntu') &&
              osVersion.includes('Android') &&
              osVersion.includes('BrightSign')
            ) &&
              parseFloat(totalMemory) <= 2 &&
              parseFloat(memoryTotalSpace) <= 2) ? (
              <Tooltip
                arrow
                withHeader
                placement="top"
                title={t(
                  'This device has low system memory (RAM), which is below the recommended hardware configuration and may affect playback performance.'
                )}
                headerText={t('Device Memory is not optimal')}
              >
                <i
                  className={classNames(
                    'fa-solid fa-octagon-exclamation',
                    classes.iconExclamation
                  )}
                />
              </Tooltip>
            ) : null)}
          {calculatedAvgMemory || memoryAverageUsage ? (
            <Tooltip
              arrow
              placement="top"
              withHeader={isHighRamUtilization}
              headerText={isHighRamUtilization ? t('High RAM Utilization') : ''}
              title={
                isHighRamUtilization ? (
                  t(
                    'This device may be experiencing performance degradation, typically due to unoptimized content.'
                  )
                ) : (
                  <div>
                    <div className={classes.usageTitle}>
                      Average Usage:{' '}
                      <span className={classes.usageValue}>{`${
                        calculatedAvgMemory || averageUsage
                      }%`}</span>
                    </div>
                    <div className={classes.usageTitle}>
                      Usage Space:{' '}
                      <span className={classes.usageValue}>
                        {memory || memoryUtilization
                          ? memory || `${memoryUtilization.toFixed(2)}GB`
                          : 'N/A'}
                      </span>
                    </div>
                    <div className={classes.usageTitle}>
                      Free Space:{' '}
                      <span className={classes.usageValue}>
                        {freeSpace || memoryFreeSpace
                          ? freeSpace || memoryFreeSpace
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                )
              }
            >
              <span>{`${avg}%`}</span>
            </Tooltip>
          ) : (
            'N/A'
          )}
        </Text>
      </div>
    </>
  )
}

export default DeviceRamViewCell
