import React, { memo, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { compose } from '@reduxjs/toolkit'

import NoteIndicator from 'components/NoteIndicator'
import { useUserRole } from 'hooks/tableLibrary'

const ShareIndicator = ({ t, item = {}, contentStyle, ...props }) => {
  const role = useUserRole()
  const numberOfShareClients = useMemo(() => {
    const { shareClients, shareWithAllClients, sharedDirectly } = item

    if ((role.system || role.enterprise) && sharedDirectly) {
      return (shareWithAllClients ? -1 : shareClients?.length) || false
    }
    return false
  }, [item, role.enterprise, role.system])

  const content = useMemo(() => {
    return (role.org || role.enterprise) && item.sharedBy
      ? t('Preloaded Content')
      : false
  }, [role.org, role.enterprise, item.sharedBy, t])

  const designIndicatorText = item.shareWithAllClients
    ? t('Published with a Stock Design')
    : t('Published with a Stock Design to clients', {
        count: item.shareClients?.length || 0
      })

  const mediaIndicatorText = numberOfShareClients
    ? t(`Shared with client${numberOfShareClients === -1 ? '_all' : ''}`, {
        count: numberOfShareClients || 0
      })
    : content

  const icon =
    role.system && item.isRelatedToStockDesign
      ? 'fa-regular fa-palette'
      : 'fa-regular fa-share-nodes'

  return numberOfShareClients || content ? (
    <NoteIndicator
      icon={icon}
      content={
        item.isRelatedToStockDesign ? designIndicatorText : mediaIndicatorText
      }
      contentStyle={contentStyle}
      {...props}
    />
  ) : null
}

ShareIndicator.propTypes = {
  t: PropTypes.func,
  item: PropTypes.object
}

export default compose(withTranslation('translations'), memo)(ShareIndicator)
