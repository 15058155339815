import { object, string, mixed } from 'yup'
import { _get } from 'utils/lodash'

import {
  isDurationGreaterThanAnimationDuration,
  requiredField
} from 'constants/validationMessages'
import { mediaInfoCommonValidationSchema } from 'constants/validations'
import { labelToSec } from 'utils/secToLabel'
import { isNumber } from 'utils/generalUtils'
import { MEDIA_MIN_DURATION } from 'constants/media'

export const validationSchema = object().shape({
  title: object({
    fontFamily: string().required(requiredField).nullable()
  }),
  text: object({
    content: string().restrictedCharacters().required(requiredField),
    fontFamily: string().required(requiredField).nullable(),
    animation: string().required(requiredField).nullable()
  }),
  mediaInfo: object().shape({
    ...mediaInfoCommonValidationSchema,
    duration: mixed().test(
      'isLessThanAnimationDuration',
      isDurationGreaterThanAnimationDuration,
      function (value) {
        if (value === MEDIA_MIN_DURATION) return true

        const { text } = this.options.context
        return isNumber(value)
          ? value >= text?.duration
          : labelToSec(value) >= text?.duration
      }
    )
  })
})

export function isTabHasError(name, errors, touched, selectedTextType) {
  return (
    ((_get(errors, `${name}.content`) && _get(touched, `${name}.content`)) ||
      (_get(errors, `${name}.fontFamily`) &&
        _get(touched, `${name}.fontFamily`))) &&
    selectedTextType !== name
  )
}

export const paletteTypes = {
  PRESET: 'preset',
  CUSTOM: 'custom'
}
