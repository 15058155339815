import update from 'immutability-helper'

import { filterStatuses } from 'constants/libraryConstants'
import { exactIdSearchRegExp, squareBracketsRegExp } from 'constants/regExp'
import { queryParamsHelper, selectUtils } from './index'
import { deviceLibraryInitialFilter } from '../reducers/filters'
import { removeColons } from './macAddressUtils'

const forwardAnyModifier = val => (val ? val : filterStatuses.any)
const backwardAnyModifier = val => (val === filterStatuses.any ? '' : val)

export const anyParamsModifier = (params, fields, isForward) =>
  update(
    params,
    fields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: {
          $apply: value =>
            isForward ? forwardAnyModifier(value) : backwardAnyModifier(value)
        }
      }),
      {}
    )
  )

export const getSearchParams = (value, valueName, idName = 'id') => {
  if (typeof value == 'number') {
    return { [valueName]: value }
  }

  // eslint-disable-next-line
  if (!value || !value.replace(squareBracketsRegExp, '')) {
    return {}
  }

  if (exactIdSearchRegExp.test(value)) {
    return {
      [idName]: value.match(exactIdSearchRegExp)[1],
      exact: true
    }
  }

  return {
    // eslint-disable-next-line
    [valueName]: value.replace(squareBracketsRegExp, '')
  }
}

export const serializeFilterValues = values =>
  Object.fromEntries(
    Object.entries(queryParamsHelper(values)).map(([key, value]) => [
      key,
      Array.isArray(value) ? value.map(({ value }) => value) : value
    ])
  )

//for common fields only
export const deserializeFilterValues = ({ values, preloaded }) => {
  const parsedValues = { ...values }

  if (preloaded.tag) {
    parsedValues.tag = selectUtils.convertArr(
      preloaded.tag,
      selectUtils.tagToChipObj
    )
  }

  if (preloaded.group) {
    parsedValues.group = selectUtils.convertArr(
      preloaded.group,
      selectUtils.toGroupChipObj
    )
  }

  return parsedValues
}

export const deviceFilterValuesTransform = {
  to: values => anyParamsModifier(values, ['status', 'updateAvailable'], true),
  from: values => {
    const modifiedValues = anyParamsModifier(
      {
        ...deviceLibraryInitialFilter,
        ...values
      },
      ['status', 'updateAvailable'],
      false
    )

    const updateValues = { ...modifiedValues }

    updateValues.macAddress =
      updateValues.macAddress && removeColons(updateValues.macAddress)

    if (updateValues?.status === 'Dormant') {
      updateValues.dormant = 1
    } else if (updateValues?.status !== 'Dormant' && !!updateValues.dormant) {
      updateValues.dormant = 0
    }

    return updateValues
  }
}
