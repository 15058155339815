import { storageClear } from 'utils/localStorage'
import { EXPIRES_IN } from 'constants/api'

const restoreLastOriginalUser = () => {
  storageClear()
  const storageData = JSON.parse(localStorage.getItem('originalUsers'))
  const originalUsersList = Array.isArray(storageData) ? storageData : []
  const user = originalUsersList.pop()

  if (user) {
    const { type, token, expiresIn } = user

    localStorage.setItem(type, token)
    localStorage.setItem(EXPIRES_IN, expiresIn)
  }

  if (originalUsersList.length > 0) {
    localStorage.setItem('originalUsers', JSON.stringify(originalUsersList))
  } else {
    localStorage.removeItem('originalUsers')
  }
}

export default restoreLastOriginalUser
