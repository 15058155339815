import React from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router-dom'
import { _get } from 'utils/lodash'
import { getToken, getUserType } from '../../utils'

import { apiConstants } from '../../constants'
import { useSelector } from 'react-redux'
import AccessDeniedModal from 'components/Modal/AccessDeniedModal/index.js'

const ResellerRoute = ({ component: Component, allowed = true, ...rest }) => {
  const [user] = useSelector(state => [state.user.details.response])

  const logged = !!getToken() && _get(user, 'role.level') === 'reseller'

  const type = getUserType()
  const org = type === apiConstants.ORG_USER

  return (
    <Route
      {...rest}
      render={props =>
        !logged ? (
          <Redirect to="/sign-in" />
        ) : org && allowed ? (
          <Component {...props} />
        ) : (
          <AccessDeniedModal />
        )
      }
    />
  )
}

ResellerRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object
  ]),
  allowed: PropTypes.bool
}

export default ResellerRoute
