import React, { useCallback, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useUserRole } from 'hooks/tableLibrary'
import BaseFormControl from './BaseFormControl'
import FormColorPicker from './FormControlMultipleColorPicker/FormColorPicker'
import { simulateEvent } from 'utils'
import rainbowColorImg from 'common/assets/images/rainbow-color.jpg'
import Tooltip from 'components/Tooltip'
import useMediaBrandGuide from 'hooks/useMediaBrandGuide'
import isShadeOfWhite from 'utils/isShadeOfWhite'
import { removeSpacing } from 'utils/generalUtils'
import { whiteColor } from 'constants/colorConstants'
import { DEFAULT_BACKGROUND_COLOR } from 'constants/createTemplate'

const useStyles = makeStyles(({ type }) => ({
  colorRoot: {
    display: 'flex',
    height: 32,
    gridTemplateColumns: 'repeat(8, 1fr)',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderRadius: 4
  },
  colorBoxWrap: {
    height: 18,
    width: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:not(:last-child)': {
      marginRight: 2
    }
  },
  colorBox: {
    width: 17,
    height: 17,
    borderRadius: '4px'
  },
  selectedColorBox: {
    width: 10,
    height: 10,
    border: `0.1px solid ${type === 'light' ? 'lightgray' : '#fff'} !important`
  },
  colorSelected: {
    borderColor: '#0a83c8'
  },
  rainbowColor: {
    border: 'none',
    overflow: 'hidden'
  },
  colorPickerRoot: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  colorPickerIconBackground: {
    width: 11,
    height: 11,
    position: 'absolute',
    top: 3,
    left: 3,
    background: '#fff',
    borderRadius: '50%'
  },
  colorPickerIcon: {
    color: 'grey',
    position: 'absolute',
    top: 1.5,
    left: 1.5,
    fontSize: 9
  },
  selectedColorPickerIcon: {
    fontSize: 7
  },
  selected: {
    border: `1px solid ${type === 'light' ? 'lightgray' : '#fff'}`,
    borderRadius: '4px'
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
}))

const FormControlBrandGuideColorPicker = ({
  label,
  name,
  value: currentValue,
  initialValue: currentInitialValue,
  onChange,
  error,
  touched,
  labelFontSizeVariant,
  marginBottom,
  labelPosition,
  tooltip,
  themeColors: currentThemeColors,
  defaultColor: currentDefaultColor,
  randomColors: currentRandomColors,
  selectDefaultColor,
  ignoreInitial,
  renderDefaultColor = false,
  ignoreColorSet,
  hideOpacity = false,
  disabled,
  popUpPlacement
}) => {
  const classes = useStyles()
  const role = useUserRole()
  const { t } = useTranslation()

  const value = removeSpacing(currentValue)
  const initialValue = removeSpacing(currentInitialValue)
  const defaultColor = removeSpacing(currentDefaultColor)
  const themeColors = useMemo(
    () => currentThemeColors?.map(color => removeSpacing(color)) || [],
    [currentThemeColors]
  )
  const randomColors = useMemo(
    () => currentRandomColors?.map(color => removeSpacing(color)) || [],
    [currentRandomColors]
  )

  const {
    brandGuideSettings,
    brandGuideColors,
    isEnabledBrandGuide
  } = useMediaBrandGuide()

  const handleSelectColor = useCallback(
    color => {
      setTimeout(() => {
        onChange(simulateEvent(name, color))
      }, 0)
    },
    [name, onChange]
  )

  useEffect(() => {
    if (
      isEnabledBrandGuide &&
      brandGuideSettings?.isMandatory &&
      !brandGuideColors.includes(value) &&
      value !== initialValue &&
      value !== whiteColor &&
      (!renderDefaultColor || value !== defaultColor) &&
      !ignoreColorSet
    ) {
      handleSelectColor(brandGuideColors[0])
    }
    // eslint-disable-next-line
  }, [brandGuideColors, themeColors, value, selectDefaultColor])

  useEffect(() => {
    if (initialValue && !ignoreInitial && !ignoreColorSet) {
      handleSelectColor(initialValue)
    }
    // eslint-disable-next-line
  }, [initialValue])

  const handleColorPickerChange = useCallback(
    e => {
      onChange(e)
    },
    [onChange]
  )

  const colorPickerRender = useCallback(
    colors => {
      return (
        <div className={classes.colorPickerRoot}>
          {colors.includes(value) && (
            <>
              <img
                src={rainbowColorImg}
                alt="color"
                width="100%"
                height="100%"
              />
              <div className={classes.colorPickerIconBackground}>
                <i
                  className={classNames(
                    'fa-regular fa-sharp fa-plus',
                    classes.colorPickerIcon
                  )}
                />
              </div>
            </>
          )}
        </div>
      )
    },
    [classes, value]
  )

  const renderBrandGuideColorPicker = useMemo(
    () => (
      <BaseFormControl
        error={error}
        touched={touched}
        label={label}
        marginBottom={marginBottom}
        labelPosition={labelPosition}
        labelFontSizeVariant={labelFontSizeVariant}
        tooltip={tooltip}
      >
        <div className={classes.colorRoot}>
          {!brandGuideSettings.isMandatory && (
            <div
              className={classNames(classes.colorBoxWrap, {
                [classes.selected]: !brandGuideColors.includes(value)
              })}
              {...(!isShadeOfWhite(value) && {
                style: {
                  borderColor: value
                }
              })}
            >
              <FormColorPicker
                value={value}
                colorBoxClassName={classNames(
                  classes.colorBox,
                  classes.rainbowColor,
                  {
                    [classes.selectedColorBox]: !brandGuideColors.includes(
                      value
                    ),
                    [classes.disabled]: disabled
                  }
                )}
                name={name}
                handleChange={handleColorPickerChange}
                showMulti
                onlyColor={brandGuideSettings.isMandatory}
                renderColor={
                  !brandGuideSettings.isMandatory &&
                  colorPickerRender(brandGuideColors)
                }
                colorPresets={
                  role.org && isEnabledBrandGuide
                    ? brandGuideColors
                    : themeColors
                }
                popUpPlacement={popUpPlacement}
                hideOpacity={hideOpacity}
                disablePortal
                withTooltip
              />
            </div>
          )}

          {brandGuideColors.map((_, index) => (
            <Tooltip
              title={brandGuideColors?.[index]}
              key={`multiple-color-${index}`}
              placement="top"
              arrow
            >
              <div
                className={classNames(classes.colorBoxWrap, {
                  [classes.selected]: value === brandGuideColors?.[index]
                })}
                {...(!isShadeOfWhite(value) && {
                  style: {
                    borderColor: value
                  }
                })}
              >
                <FormColorPicker
                  value={brandGuideColors?.[index]}
                  colorBoxClassName={classNames(classes.colorBox, {
                    [classes.selectedColorBox]:
                      value === brandGuideColors?.[index],
                    [classes.selected]: isShadeOfWhite(
                      brandGuideColors?.[index]
                    ),
                    [classes.disabled]: disabled
                  })}
                  onSelect={handleSelectColor}
                  selected={value === brandGuideColors?.[index]}
                  popUpPlacement={popUpPlacement}
                  hideOpacity={hideOpacity}
                  onlyColor
                />
              </div>
            </Tooltip>
          ))}

          {brandGuideSettings?.isMandatory &&
            initialValue &&
            initialValue !== defaultColor &&
            !brandGuideColors.includes(initialValue) && (
              <Tooltip
                title={initialValue}
                headerText={t('Previously saved color')}
                placement="top"
                withHeader
                arrow
              >
                <div
                  className={classNames(classes.colorBoxWrap, {
                    [classes.selected]: value === initialValue
                  })}
                  {...(!isShadeOfWhite(value) && {
                    style: {
                      borderColor: value
                    }
                  })}
                >
                  <FormColorPicker
                    value={initialValue}
                    colorBoxClassName={classNames(classes.colorBox, {
                      [classes.selectedColorBox]: value === initialValue,
                      [classes.selected]: isShadeOfWhite(initialValue),
                      [classes.disabled]: disabled
                    })}
                    onSelect={handleSelectColor}
                    selected={value === initialValue}
                    popUpPlacement={popUpPlacement}
                    hideOpacity={hideOpacity}
                    onlyColor
                  />
                </div>
              </Tooltip>
            )}
          {brandGuideSettings?.isMandatory && (
            <Tooltip title={whiteColor} placement="top" arrow>
              <div
                className={classNames(classes.colorBoxWrap, {
                  [classes.selected]: value === whiteColor
                })}
                {...(!isShadeOfWhite(value) && {
                  style: {
                    borderColor: value
                  }
                })}
              >
                <FormColorPicker
                  value={whiteColor}
                  colorBoxClassName={classNames(classes.colorBox, {
                    [classes.selectedColorBox]: value === whiteColor,
                    [classes.selected]: isShadeOfWhite(whiteColor),
                    [classes.disabled]: disabled
                  })}
                  onSelect={handleSelectColor}
                  selected={value === whiteColor}
                  popUpPlacement={popUpPlacement}
                  hideOpacity={hideOpacity}
                  onlyColor
                />
              </div>
            </Tooltip>
          )}
          {renderDefaultColor && (
            <Tooltip title={t('Default Color')} placement="top" arrow>
              <div
                className={classNames(classes.colorBoxWrap, {
                  [classes.selected]: value === defaultColor
                })}
                {...(!isShadeOfWhite(value) && {
                  style: {
                    borderColor: value
                  }
                })}
              >
                <FormColorPicker
                  value={defaultColor}
                  colorBoxClassName={classNames(classes.colorBox, {
                    [classes.selectedColorBox]: value === defaultColor,
                    [classes.selected]: isShadeOfWhite(defaultColor),
                    [classes.disabled]: disabled
                  })}
                  onSelect={handleSelectColor}
                  selected={value === defaultColor}
                  popUpPlacement={popUpPlacement}
                  hideOpacity={hideOpacity}
                  onlyColor
                />
              </div>
            </Tooltip>
          )}
        </div>
      </BaseFormControl>
    ),
    [
      name,
      label,
      value,
      error,
      touched,
      marginBottom,
      labelPosition,
      tooltip,
      brandGuideColors,
      classes,
      handleSelectColor,
      brandGuideSettings.isMandatory,
      labelFontSizeVariant,
      handleColorPickerChange,
      colorPickerRender,
      themeColors,
      role.org,
      isEnabledBrandGuide,
      initialValue,
      defaultColor,
      renderDefaultColor,
      popUpPlacement,
      hideOpacity,
      disabled,
      t
    ]
  )

  const renderColorPicker = useMemo(
    () => (
      <BaseFormControl
        error={error}
        touched={touched}
        label={label}
        marginBottom={marginBottom}
        labelPosition={labelPosition}
        labelFontSizeVariant={labelFontSizeVariant}
        tooltip={tooltip}
      >
        <div className={classes.colorRoot}>
          <div
            className={classNames(classes.colorBoxWrap, {
              [classes.selected]: !randomColors.includes(value)
            })}
            {...(!isShadeOfWhite(value) && {
              style: {
                borderColor: value
              }
            })}
          >
            <FormColorPicker
              value={value}
              colorBoxClassName={classNames(
                classes.colorBox,
                classes.rainbowColor,
                {
                  [classes.selectedColorBox]: !randomColors.includes(value),
                  [classes.selected]: isShadeOfWhite(value),
                  [classes.disabled]: disabled
                }
              )}
              name={name}
              handleChange={handleColorPickerChange}
              showMulti
              renderColor={colorPickerRender(randomColors)}
              popUpPlacement={popUpPlacement}
              hideOpacity={hideOpacity}
              disablePortal
              withTooltip
            />
          </div>

          {randomColors.map((_, index) => (
            <Tooltip
              title={
                randomColors?.[index] ===
                removeSpacing(DEFAULT_BACKGROUND_COLOR)
                  ? 'Default Color'
                  : randomColors?.[index]
              }
              key={`multiple-color-${index}`}
              placement="top"
              arrow
            >
              <div
                className={classNames(classes.colorBoxWrap, {
                  [classes.selected]: value === randomColors?.[index]
                })}
                {...(!isShadeOfWhite(value) && {
                  style: {
                    borderColor: value
                  }
                })}
              >
                <FormColorPicker
                  value={randomColors?.[index]}
                  colorBoxClassName={classNames(classes.colorBox, {
                    [classes.selectedColorBox]: value === randomColors?.[index],
                    [classes.selected]: isShadeOfWhite(randomColors?.[index]),
                    [classes.disabled]: disabled
                  })}
                  onSelect={handleSelectColor}
                  selected={value === randomColors?.[index]}
                  popUpPlacement={popUpPlacement}
                  hideOpacity={hideOpacity}
                  onlyColor
                />
              </div>
            </Tooltip>
          ))}
        </div>
      </BaseFormControl>
    ),
    [
      name,
      label,
      value,
      error,
      touched,
      tooltip,
      classes,
      disabled,
      hideOpacity,
      randomColors,
      marginBottom,
      labelPosition,
      popUpPlacement,
      handleSelectColor,
      colorPickerRender,
      labelFontSizeVariant,
      handleColorPickerChange
    ]
  )

  return role.org && isEnabledBrandGuide
    ? renderBrandGuideColorPicker
    : renderColorPicker
}

export default FormControlBrandGuideColorPicker
