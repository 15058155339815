import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import classNames from 'classnames'
import { _isEmpty, _get } from 'utils/lodash'
import { useLocation } from 'react-router'

import { SideModal } from 'components/Modal'
import { FormControlInput, FormControlTelInput } from 'components/Form'
import FooterLayout from 'components/Modal/FooterLayout'
import ImageUpload from 'components/Uppy/ImageUpload'
import FormControlPasswordInput from 'components/Form/FormControlPasswordInput'
import ProfileImage, { defaultAvatar } from 'components/ProfileImage'
import queryParamsHelper from 'utils/queryParamsHelper'
import {
  putUserDetailsAction,
  clearUserPutAction,
  getUserDetailsAction
} from 'actions/userActions'
import { BlueButton } from 'components/Buttons'
import { checkData } from 'utils/tableUtils'
import {
  imageValidateSchema,
  passwordConfirmValidateSchema,
  passwordValidateSchema,
  phoneValidationSchema
} from 'constants/validations'
import { parsePhoneFromBE } from 'utils/generalUtils'
import { requiredField } from 'constants/validationMessages'
import useUppySingleFileUpload from 'hooks/useUppySingleFileUpload'
import useFormErrorHandler from 'hooks/useFormErrorHandler'
import useNotifyAnalyzer from 'hooks/tableLibrary/useNotifyAnalyzer'
import useUserDetails from 'hooks/useUserDetails'
import ProfileImageUploadLoader from 'components/Loaders/ProfileImageUploadLoader'

const styles = ({ palette, type, spacing }) => ({
  contentWrap: {
    position: 'fixed',
    top: 78,
    height: 'calc(100% - 78px)',
    left: 0,
    right: 0,
    zIndex: 100,
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: -1
    }
  },
  sideModalWrap: {
    left: 0,
    right: 0,
    margin: '0 auto',
    background: 'none!important'
  },
  wrapContent: {
    padding: '20px 40px',
    width: '100%'
  },
  inputLabel: {
    fontSize: 18
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '16px'
  },
  stretch: {
    gridColumnStart: 1,
    gridColumnEnd: 3
  },

  passwordsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    background: palette[type].pages.profile.passwords.background,
    gridColumnGap: '16px',
    padding: 20,
    borderRadius: 5,
    marginBottom: 16,
    gridTemplateAreas: "'current current''new confirm''btn btn'"
  },
  errorTextClass: {
    whiteSpace: 'nowrap'
  },
  profileWrapper: {
    paddingTop: '0 !important'
  },
  currentPassword: {
    gridArea: 'current'
  },
  newPasswordInput: {
    gridArea: 'new'
  },
  confirmPassword: {
    gridArea: 'confirm'
  },
  buttonWrapper: {
    gridArea: 'btn',
    width: 'fit-content',
    marginLeft: 'auto'
  },
  inputWrap: {
    padding: `0 35px 0 ${spacing(1)}px`
  },
  imageWrap: {
    height: '350px'
  }
})

const formConfig = {
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    profile: null,
    croppedProfile: null
  },
  validationSchema: Yup.object().shape({
    phone: phoneValidationSchema,
    firstName: Yup.string().restrictedCharacters().required(requiredField),
    lastName: Yup.string().restrictedCharacters().required(requiredField),
    email: Yup.string().email().required(requiredField),
    profile: imageValidateSchema
  })
}

const passFormConfig = {
  initialValues: {
    password: '',
    passwordConfirmation: '',
    currentPassword: ''
  },
  validationSchema: Yup.object().shape({
    password: passwordValidateSchema,
    passwordConfirmation: passwordConfirmValidateSchema
  })
}

const Profile = ({
  t,
  classes,
  enqueueSnackbar,
  closeSnackbar,
  setProfileSelectorIsOpen
}) => {
  const [details, put] = useSelector(({ user: { details, put } }) => [
    details,
    put
  ])
  const dispatch = useDispatch()
  const [uploadRef, setUploadRef] = useState(null)
  const { uppy, uppyUpload, isReset, onReset } = useUppySingleFileUpload({ t })
  const [isPending, setIsPending] = useState(false)
  const [imageUploadIsLoading, setImageUploadIsLoading] = useState(false)
  const isFirstRun = useRef(true)
  const [customAvatarSelected, setCustomAvatarSelected] = useState(null)
  const [selectedProfileImage, setSelectedProfileImage] = useState(null)
  const [profileAvatarUrl, setProfileAvatarUrl] = useState(null)

  const { profileDetails } = useUserDetails()
  const location = useLocation()
  const translate = useMemo(
    () => ({
      title: t('User Profile'),
      firstName: t('First Name'),
      lastName: t('Last Name'),
      email: t('Email'),
      phone: t('Phone'),
      password: t('Password'),
      confirmPassword: t('Confirm Password'),
      roleId: t('User Type'),
      uploadError: t('An error occurred')
    }),
    [t]
  )

  const handleDropzoneRef = useCallback(ref => setUploadRef(ref), [])

  const handleSubmit = useCallback(
    async ({ profile, defaultAvatarId, ...values }, { setFieldError }) => {
      const data = {}
      setIsPending(true)

      if (defaultAvatarId && !profile) {
        data.profile = { id: defaultAvatarId }
      } else if (profile || customAvatarSelected === defaultAvatar) {
        const file = await uppyUpload()

        if (file === null) {
          setFieldError('profile', translate.uploadError)
          setIsPending(false)
          return
        } else {
          data.profile = file || null
        }
      }

      Object.keys(queryParamsHelper(values, [], ['phone'])).forEach(key => {
        if (values[key] !== initialFormValues.current[key])
          data[key] = values[key]
      })

      await dispatch(putUserDetailsAction(data))
      setIsPending(false)
    },
    [uppyUpload, dispatch, translate.uploadError, customAvatarSelected]
  )

  const form = useFormik({
    initialValues: formConfig.initialValues,
    validationSchema: formConfig.validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit
  })

  const initialFormValues = useRef(form.values)

  const errorFields = useMemo(() => put?.error?.errorFields, [put])

  useFormErrorHandler({
    setFieldError: form.setFieldError,
    initialFormValues: formConfig.initialValues,
    t,
    errorFields
  })

  useNotifyAnalyzer(
    () => {
      dispatch(getUserDetailsAction())
      setProfileSelectorIsOpen(false)
    },
    () => dispatch(clearUserPutAction()),
    enqueueSnackbar,
    closeSnackbar,
    'User',
    [put]
  )

  const passForm = useFormik({
    initialValues: passFormConfig.initialValues,
    validationSchema: passFormConfig.validationSchema,
    onSubmit: values => dispatch(putUserDetailsAction(values))
  })

  useEffect(
    () => {
      form.validateForm()
      passForm.validateForm()
    },
    //eslint-disable-next-line
    []
  )

  useFormErrorHandler({
    setFieldError: passForm.setFieldError,
    initialFormValues: passFormConfig.initialValues,
    t,
    errorFields
  })

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    setProfileSelectorIsOpen()
    //eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    if (!_isEmpty(details)) {
      const { firstName, lastName, phone, email } = details.response

      initialFormValues.current = {
        firstName,
        lastName,
        phone: parsePhoneFromBE(checkData(phone, '')),
        email
      }
      form.setValues(initialFormValues.current)
    }
    //eslint-disable-next-line
  }, [details])

  const initialProfileImage = useMemo(() => {
    const image = _get(details, 'response.profile', null)
    return image && !image.includes('user1.png') ? image : ''
  }, [details])

  const handleReset = useCallback(() => {
    form.setValues(initialFormValues.current)
    setCustomAvatarSelected(null)
    setProfileAvatarUrl(null)

    if (profileDetails?.id) {
      setSelectedProfileImage(profileDetails?.id)
    } else if (!profileDetails?.isCustom) {
      setSelectedProfileImage(defaultAvatar)
    } else {
      setSelectedProfileImage(null)
    }

    onReset()
  }, [form, onReset, profileDetails, setProfileAvatarUrl])

  return (
    <div className={classes.contentWrap}>
      <SideModal
        width="60%"
        classes={{
          sideModalWrap: classes.sideModalWrap
        }}
        title={translate.title}
        handleClose={() => setProfileSelectorIsOpen(false)}
        footerLayout={
          <FooterLayout
            isUpdate
            isPending={isPending}
            opaqueSubmit={!form.isValid}
            onSubmit={form.handleSubmit}
            onReset={handleReset}
          />
        }
      >
        <Grid
          container
          direction="row"
          className={classes.wrapContent}
          spacing={2}
        >
          <Grid item xs={6}>
            {imageUploadIsLoading ? (
              <ProfileImageUploadLoader />
            ) : (
              <ImageUpload
                uppy={uppy}
                name="profile"
                value={form.values.profile}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.errors.profile}
                touched={!!form.touched.profile}
                handleDropzoneRef={handleDropzoneRef}
                defaultImagePreview={profileAvatarUrl || initialProfileImage}
                customAvatarSelected={customAvatarSelected}
                isReset={isReset}
                dragDropWrapperClass={classes.imageWrap}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <form className={classes.grid}>
              <FormControlInput
                label={translate.firstName}
                placeholder={translate.firstName}
                autocomplete="first-name"
                name="firstName"
                value={form.values.firstName}
                error={form.errors.firstName}
                touched={form.touched.firstName}
                handleChange={form.handleChange}
                handleBlur={form.handleBlur}
              />
              <FormControlInput
                label={translate.lastName}
                placeholder={translate.lastName}
                autocomplete="last-name"
                name="lastName"
                value={form.values.lastName}
                error={form.errors.lastName}
                touched={form.touched.lastName}
                handleChange={form.handleChange}
                handleBlur={form.handleBlur}
              />
              <FormControlInput
                label={translate.email}
                placeholder={translate.email}
                autocomplete="email"
                name="email"
                value={form.values.email}
                error={form.errors.email}
                touched={form.touched.email}
                handleChange={form.handleChange}
                handleBlur={form.handleBlur}
              />
              <FormControlTelInput
                label={translate.phone}
                autocomplete="phone"
                name="phone"
                value={form.values.phone}
                error={form.errors.phone}
                touched={form.touched.phone}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                isOptional
              />
              <div
                className={classNames(
                  classes.passwordsContainer,
                  classes.stretch
                )}
              >
                <FormControlPasswordInput
                  fullWidth
                  name="currentPassword"
                  autocomplete="current-password"
                  label={t('Current Password')}
                  value={passForm.values.currentPassword}
                  error={passForm.errors.currentPassword}
                  errorTextClass={classes.errorTextClass}
                  touched={passForm.touched.currentPassword}
                  handleChange={passForm.handleChange}
                  handleBlur={passForm.handleBlur}
                  containerClasses={classes.currentPassword}
                  formControlInputClass={classes.inputWrap}
                />

                <FormControlPasswordInput
                  fullWidth
                  name="password"
                  autocomplete="new-password"
                  label={t('New Password')}
                  value={passForm.values.password}
                  error={passForm.errors.password}
                  touched={passForm.touched.password}
                  handleChange={passForm.handleChange}
                  handleBlur={passForm.handleBlur}
                  containerClasses={classes.newPasswordInput}
                  formControlInputClass={classes.inputWrap}
                />
                <FormControlPasswordInput
                  fullWidth
                  name="passwordConfirmation"
                  autocomplete="confirm-password"
                  label={t('Confirm Password')}
                  value={passForm.values.passwordConfirmation}
                  error={passForm.errors.passwordConfirmation}
                  errorTextClass={classes.errorTextClass}
                  touched={passForm.touched.passwordConfirmation}
                  handleChange={passForm.handleChange}
                  handleBlur={passForm.handleBlur}
                  containerClasses={classes.confirmPassword}
                  formControlInputClass={classes.inputWrap}
                />

                <BlueButton
                  className={classes.buttonWrapper}
                  opaque={!passForm.isValid}
                  onClick={passForm.submitForm}
                  iconClassName="fa-regular fa-shield-keyhole"
                >
                  {t('Update Password')}
                </BlueButton>
              </div>
            </form>
          </Grid>

          <Grid item xs={12} className={classes.profileWrapper}>
            <ProfileImage
              name="profile"
              className={classes.stretch}
              onChange={setCustomAvatarSelected}
              onImageUpload={() => uploadRef?.click?.()}
              userName={form.values.firstName}
              selected={selectedProfileImage}
              setSelected={setSelectedProfileImage}
              setFieldValue={form.setFieldValue}
              profileDetails={profileDetails}
              setImageUploadIsLoading={setImageUploadIsLoading}
              setProfileAvatarUrl={setProfileAvatarUrl}
            />
          </Grid>
        </Grid>
      </SideModal>
    </div>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(withSnackbar(Profile))
)
