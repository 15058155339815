import { featureNames } from '../featureConstants'

export const deviceModelFields = {
  modelNo: 'modelNo',
  name: 'name',
  alias: 'alias',
  logo: 'logo',
  color: 'color',
  status: 'status',
  description: 'description',
  operatingSystem: 'operationSystem',
  hdmiPorts: 'hdmiPorts',
  networkPorts: 'networkPorts',
  wireless: 'hasWireless',
  storage: 'diskSize',
  systemMemory: 'systemMemory',
  processor: 'processor',
  datasheet: 'datasheet',
  features: 'features',
  launchDate: 'launchDate',
  endOfLifeDate: 'endOfLifeDate',
  devices: 'devices',
  createdAt: 'createdAt',
  isAvailableForTrial: 'isAvailableForTrial'
}

export const LINUX_OS = 'Linux OS'

export const operatingSystemOptions = [
  LINUX_OS,
  'BrightSign',
  'Android OS',
  'Tizen',
  'WebOS',
  'Others'
].map(value => ({ label: value, value }))

export const ALL_FEATURES_VALUE = 'All'

export const customFeatureLabels = {
  [featureNames.UltraHD]: '4K Playback',
  [featureNames.InteractiveContent]: 'Touch Interactivity',
  [featureNames.VideoInput]: 'Video Input',
  [featureNames.Videowall]: 'Video Wall',
  [featureNames.ZoneRestriction]: 'Zones Compatibility'
}

export const MAX_HDMI_PORTS = 20
export const MAX_NETWORK_PORTS = 10
