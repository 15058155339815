import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as selectUtils from 'utils/select'
import useDeterminePermissions from 'hooks/useDeterminePermissions'
import { parseNestedError } from 'utils/errorHandler'
import { FormControlAutocompleteQuery } from 'components/Form'
import { tagCustomStyles } from 'constants/stylesConstants'

const FormControlCreatableAutocomplete = ({
  useLazyQuery,
  usePostMutation,
  permissionGroup,
  error: rawError,
  preparePostData = value => ({ name: value }),
  responseParser = data =>
    data.map(({ id, name }) => ({ label: name, value: id })),
  styles = {},
  ...props
}) => {
  const { t } = useTranslation()
  const [postData, postResult] = usePostMutation()

  const [createdData, setCreatedData] = useState({})

  const permission = useDeterminePermissions(permissionGroup)

  useEffect(() => {
    if (postResult.isSuccess) {
      const newTag = selectUtils.tagToChipObj(postResult.data)
      setCreatedData({ data: newTag })
    } else if (postResult.isError) {
      setCreatedData({ error: postResult.error })
    }
    // eslint-disable-next-line
  }, [postResult])

  const handleCreate = useCallback(
    value => {
      postData(preparePostData(value))
    },
    [postData, preparePostData]
  )

  const handleClear = useCallback(() => postResult.reset(), [postResult])

  const customStyles = useMemo(() => ({ ...tagCustomStyles, ...styles }), [
    styles
  ])

  const isCreationAllowed = useMemo(() => {
    return !permissionGroup || permission.create
  }, [permission, permissionGroup])

  const noOptionsMessage = useMemo(() => {
    if (permissionGroup && !permission.read) {
      return t('No permissions available')
    } else if (!isCreationAllowed) {
      return value => (value ? `No Options for "${value}"` : t('No Options'))
    } else {
      return t(`Press Enter to add new item`, { item: props.label || 'item' })
    }
  }, [permissionGroup, permission, isCreationAllowed, t, props.label])

  return (
    <FormControlAutocompleteQuery
      useLazyQuery={useLazyQuery}
      responseParser={responseParser}
      isResettable
      isMulti
      styles={customStyles}
      noOptionsMessage={noOptionsMessage}
      createdValue={createdData}
      createNewValue={handleCreate}
      clearCreatedValue={handleClear}
      hasDynamicChipsCreation={isCreationAllowed}
      error={parseNestedError(rawError)}
      {...props}
    />
  )
}

FormControlCreatableAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  styles: PropTypes.object,
  responseParser: PropTypes.func,
  preparePostData: PropTypes.func,
  permissionGroup: PropTypes.string,
  useLazyQuery: PropTypes.func.isRequired,
  usePostMutation: PropTypes.func.isRequired
}

export default memo(FormControlCreatableAutocomplete)
