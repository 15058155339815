import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SYSTEM_ROLE } from 'constants/api'
import { userDetailsSelector } from 'selectors/userSelectors'

const useUserDetails = () => {
  const { response } = useSelector(userDetailsSelector)

  return useMemo(() => {
    if (response && response?.role?.level === SYSTEM_ROLE) {
      return {
        ...response,
        client: {
          autoLogout: true,
          autoLogoutTime: 5400
        }
      }
    }
    return response
  }, [response])
}

export default useUserDetails
