import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _toNumber, _subtract } from 'utils/lodash'
import { createSelector } from 'reselect'
import { getOriginalUser, storageGetExpires } from 'utils/localStorage'
import { getNow } from 'utils/date'
import { updateTokenAction } from 'actions/authenticationActions'
import { refreshTokenSelector } from 'selectors/refreshTokenSelectors'

const getRefreshToken = createSelector(
  refreshTokenSelector,
  ({ counter, impersonatedCounter }) => ({
    counter,
    impersonatedCounter
  })
)

function useRefreshToken(isLogged) {
  const dispatch = useDispatch()
  const { counter, impersonatedCounter } = useSelector(getRefreshToken)
  useEffect(() => {
    if (isLogged) {
      const millisecondsToExpire = _subtract(
        _toNumber(storageGetExpires()),
        getNow()
      )
      const timeout = setTimeout(() => {
        dispatch(updateTokenAction())
      }, _subtract(millisecondsToExpire, 60000))

      return () => clearTimeout(timeout)
    }
  }, [dispatch, counter, isLogged])

  useEffect(() => {
    const originalUser = getOriginalUser()
    if (isLogged && originalUser) {
      const millisecondsToExpire = _subtract(
        _toNumber(originalUser.expiresIn),
        getNow()
      )
      const timeout = setTimeout(() => {
        dispatch(updateTokenAction(true))
      }, _subtract(millisecondsToExpire, 60000))

      return () => clearTimeout(timeout)
    }
  }, [dispatch, impersonatedCounter, isLogged])
}

export default useRefreshToken
