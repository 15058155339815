import { useCallback, useEffect } from 'react'
import { filetypeinfo } from 'magic-bytes.js'
import isSvg from 'is-svg'
import { XMLValidator } from 'fast-xml-parser'
import _get from 'lodash/get'

import { isFileData, readFile } from 'utils'
import { getMimeValidationError, validateImageDimensions } from '../utils/uppy'
import { BMP, GIF, JPEG, JPG, PNG, WEBM, WEBM_AUDIO } from 'constants/mimeTypes'
import { getMessageWithBoldEntityName } from 'utils/generalUtils'
import { getBytes, getNormalizedMimeType } from 'utils/fileUtils'
import useSnackbar from './useSnackbar'

const isNotAcceptedFormat = ['doc', 'xls', 'ppt', 'csv', 'mpga'] // Todo: add an additional check for these extensions
const isReadFiles = ['svg', 'json', 'xml']
const imageWithDimensionValidationExtensions = [
  JPEG.ext,
  JPG.ext,
  BMP.ext,
  GIF.ext,
  PNG.ext
].map(ext => ext.substr(1))

export const useUppyValidationMimeType = (uppy, t, dashboardValidator) => {
  const { showSnackbar } = useSnackbar()

  const createValidationError = useCallback(
    (fileId, error = t('File mime/type is invalid')) => ({
      fileId,
      error
    }),
    [t]
  )

  const handleAddedFile = useCallback(
    async addedFiles => {
      uppy.emit('validation-files', addedFiles, true)

      const invalidFilesData = []
      const warningFilesData = []

      await Promise.all(
        addedFiles.map(async file => {
          if (file.type.includes('text/html')) {
            invalidFilesData.push(
              createValidationError(file.id, t('URL is invalid'))
            )
            return
          }
          if (
            file.source === 'react:Dashboard' &&
            !file.meta?.isPreview &&
            !isNotAcceptedFormat.includes(file.extension) &&
            isFileData(file.data)
          ) {
            if (
              imageWithDimensionValidationExtensions.includes(file.extension)
            ) {
              const { error, warning } = await validateImageDimensions(file)

              if (error) {
                invalidFilesData.push(
                  createValidationError(
                    file.id,
                    getMessageWithBoldEntityName(
                      'This file exceeds the max supported resolution. Please reduce the file resolution to proceed.',
                      { fileName: file.name || t('This file') }
                    )
                  )
                )
              } else if (warning) {
                warningFilesData.push({
                  message: getMessageWithBoldEntityName(
                    'This file exceeds the recommended max resolution of 4K. Please reduce the file resolution if you notice any performance issues during playback.',
                    { fileName: file.name || t('This file') }
                  )
                })
              }
            }
            if (isReadFiles.includes(file.extension)) {
              const data = await readFile(file.data)

              switch (file.extension) {
                case 'svg': {
                  if (!isSvg(data)) {
                    invalidFilesData.push(
                      createValidationError(
                        file.id,
                        getMimeValidationError('svg', t)
                      )
                    )
                  }
                  break
                }
                case 'json': {
                  try {
                    JSON.parse(data)
                  } catch (e) {
                    invalidFilesData.push(
                      createValidationError(
                        file.id,
                        getMimeValidationError('json', t)
                      )
                    )
                  }
                  break
                }
                case 'xml': {
                  if (_get(XMLValidator.validate(data), 'err')) {
                    invalidFilesData.push(
                      createValidationError(
                        file.id,
                        getMimeValidationError('xml', t)
                      )
                    )
                  }
                  break
                }
                default: {
                }
              }
            } else {
              const bytes = await getBytes(file.data)
              const fileSignatures = filetypeinfo(bytes) ?? []

              if (
                fileSignatures?.some(({ mime }) => mime === WEBM_AUDIO.mime)
              ) {
                if (file.type === WEBM.mime) {
                  fileSignatures.push(WEBM)
                }
              }

              if (fileSignatures?.length) {
                const isSameExtension = fileSignatures.some(
                  ({ extension }) => extension === file.extension.toLowerCase()
                )

                const isSameMimeType = fileSignatures.some(
                  ({ mime }) =>
                    getNormalizedMimeType(mime) ===
                    getNormalizedMimeType(file.type)
                )

                if (
                  !(
                    isSameExtension ||
                    isSameMimeType ||
                    dashboardValidator(fileSignatures, file)
                  )
                ) {
                  invalidFilesData.push(
                    createValidationError(
                      file.id,
                      getMimeValidationError(file.extension, t)
                    )
                  )
                }
              }
            }
          }

          //All file sources
          if (
            !file.meta?.isPreview &&
            !isNotAcceptedFormat.includes(file.extension)
          ) {
            switch (file.extension) {
              case 'svg': {
                //25 MB
                if (file.size > 26214400) {
                  warningFilesData.push({
                    message: t(
                      'Extra large SVG format files are not suitable for digital display screens. SVG files greater than 25MB will lead to poor playback performance. Consider resizing the graphic and/or reformat to PNG or JPEG file for optimal performance.'
                    )
                  })
                }
                break
              }
              default: {
              }
            }
          }
        })
      )

      if (invalidFilesData.length) {
        invalidFilesData.forEach(({ fileId, error }) => {
          uppy.removeFile(fileId)
          if (typeof error === 'object') {
            showSnackbar(error, 'error')
          } else {
            uppy.info(error, 'error')
          }
        })
      }

      if (warningFilesData.length) {
        warningFilesData.forEach(({ message }) => {
          showSnackbar(message, 'warning')
        })
      }

      uppy.emit('validation-files', addedFiles, false)
    },
    [uppy, t, dashboardValidator, createValidationError, showSnackbar]
  )
  useEffect(() => {
    uppy.on('files-added', handleAddedFile)
    return () => {
      uppy.off('files-added', handleAddedFile)
    }
  })
}
