import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'

import { truncateWithEllipsis } from 'utils/truncateStringUtils'
import Tooltip from 'components/Tooltip'
import { truncateLength } from 'components/Card/consts'
import RectLoader from 'components/Loaders/RectLoader'

const styles = ({ palette, type, typography }) => ({
  detailRow: {
    minHeight: '36px',
    padding: '5px 0px'
  },
  corner: {
    borderBottom: `1px solid ${palette[type].deviceCard.row.background}`
  },
  detailLabel: {
    ...typography.lightText[type]
  },
  detailValue: {
    ...typography.darkAccent[type],
    fontWeight: 700,
    textAlign: 'right'
  }
})

const MediaInfoRow = ({
  classes,
  title,
  children,
  hasCorner = true,
  customValueType = false,
  isTruncate = false,
  isLoading
}) => {
  const renderValue = customValueType ? (
    children
  ) : (
    <Grid item>
      {isTruncate ? (
        <Tooltip arrow title={children}>
          <Typography className={classes.detailValue}>
            {truncateWithEllipsis(children, truncateLength)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography className={classes.detailValue}>{children}</Typography>
      )}
    </Grid>
  )

  return (
    <Grid
      className={classNames(classes.detailRow, { [classes.corner]: hasCorner })}
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography className={classes.detailLabel}>{title}</Typography>
      </Grid>
      {isLoading ? (
        <RectLoader height="28px" width="40%" padding={7} />
      ) : (
        renderValue
      )}
    </Grid>
  )
}

MediaInfoRow.propTypes = {
  title: PropTypes.string.isRequired,
  customValueType: PropTypes.bool,
  isTruncate: PropTypes.bool
}

export default withStyles(styles)(MediaInfoRow)
