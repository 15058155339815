import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import RectLoader from './RectLoader'

const RectListLoader = ({
  classes,
  count = 1,
  rectConfig = {
    height: '100%',
    width: '100%',
    radius: 6,
    padding: 0
  }
}) => {
  return (
    <div className={classes.root}>
      {new Array(count).fill(' ').map((value, index) => {
        return (
          <div className={classNames(classes.item)} key={index}>
            <RectLoader {...rectConfig} />
          </div>
        )
      })}
    </div>
  )
}

RectListLoader.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    item: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  count: PropTypes.number,
  rectConfig: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    radius: PropTypes.number,
    padding: PropTypes.number
  })
}

export default RectListLoader
