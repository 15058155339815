import { useLocation } from 'react-router'
import { useEffect, useState } from 'react'
import moment from 'moment'

import useLogout from 'hooks/api/useLogout'
import useUserDetails from 'hooks/useUserDetails'
import {
  storageGetItem,
  storageRemoveItem,
  storageSetItem
} from 'utils/localStorage'
import { LAST_ACTIVITY } from 'constants/api'

export default function useAutoLogout() {
  const location = useLocation()
  const userDetails = useUserDetails()
  const handleLogout = useLogout()

  const [initialized, setInitialized] = useState(false)

  useEffect(
    () => {
      if (initialized && userDetails && userDetails.client?.autoLogout) {
        storageSetItem(LAST_ACTIVITY, moment().format())
      }
    },
    // eslint-disable-next-line
    [location, userDetails]
  )

  useEffect(
    () => {
      const lastActivity = storageGetItem(LAST_ACTIVITY)
      if (userDetails) {
        if (!userDetails.client?.autoLogout) {
          storageRemoveItem(LAST_ACTIVITY)
        } else if (userDetails.client?.autoLogoutTime && lastActivity) {
          const diff = moment().diff(lastActivity, 'seconds')
          if (diff > userDetails.client.autoLogoutTime) {
            handleLogout()
          }
          setInitialized(true)
        } else {
          setInitialized(true)
        }
      }
    },
    // eslint-disable-next-line
    [userDetails]
  )
}
