import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import { DateTimeView } from 'components/TableLibrary'
import { TextWithTooltip } from 'components/Typography'
import Spacing from 'components/Containers/Spacing'
import { Checkbox } from '../../Checkboxes'
import Error from '../../Form/Error'

const styles = ({ typography, type }) => ({
  listItem: {
    padding: '10px'
  },
  mediaTooltip: {
    margin: '3px 0px',
    '&:after': {
      borderTop: 'none'
    }
  },
  typeIconWrap: {
    cursor: 'pointer',
    textAlign: 'center'
  },
  textSmall: {
    ...typography.subtitle[type]
  },
  rightSide: {
    textAlign: 'right'
  },
  titleWrap: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
})

const DependencyRow = ({
  item = {},
  onPreviewClick = f => f,
  classes,
  borderBottom = 1,
  onSelect,
  selected,
  error
}) => {
  return (
    <Spacing
      item
      variant={0}
      rootClassName={classes.listItem}
      borderBottom={borderBottom}
    >
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Grid
            container
            alignContent="center"
            wrap="nowrap"
            justifyContent="flex-start"
          >
            {onSelect && (
              <Spacing
                direction="row"
                alignItems="center"
                variant={0}
                paddingRight={1}
              >
                <Checkbox
                  checked={selected}
                  onChange={e => onSelect(item, e)}
                />
              </Spacing>
            )}
            {item.iconComponent ? (
              item.iconComponent
            ) : (
              <Tooltip
                arrow
                withWrapper
                title={item.icon?.title}
                className={classes.mediaTooltip}
              >
                <LibraryTypeIcon
                  color={item.icon?.color}
                  iconHelperClass={item.icon?.iconHelperClass}
                  icon={item.icon?.icon}
                  {...(item.icon?.style ? { style: item.icon?.style } : {})}
                  wrapHelperClass={classes.typeIconWrap}
                  onClick={onPreviewClick}
                />
              </Tooltip>
            )}
            <div className={classes.titleWrap}>
              <TextWithTooltip
                color="title.primary"
                weight="bold"
                maxWidth={280}
              >
                {item.title}
              </TextWithTooltip>

              <span className={classes.textSmall}>{item.subTitle}</span>
            </div>
          </Grid>
        </Grid>
        <Grid item className={classes.rightSide}>
          {!item.noDate && <DateTimeView date={item.date} />}
        </Grid>
      </Grid>
      {error && <Error error={error} condition={!!error} />}
    </Spacing>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(DependencyRow)
)
