import * as types from './index'

export const getPublicToken = params => ({
  type: types.GET_PUBLIC_TOKEN,
  params
})

export const postPublicToken = data => ({
  type: types.POST_PUBLIC_TOKEN,
  data
})

export const deletePublicToken = id => ({
  type: types.DELETE_PUBLIC_TOKEN,
  id
})

export const clearPublicTokenResponseInfo = () => ({
  type: types.CLEAR_PUBLIC_TOKEN_RESPONSE_INFO
})
