import { useCallback, useEffect } from 'react'

import { instanceTypes, vuePreviews } from 'constants/api'
import { VUE_PREVIEW } from 'constants/localStorage'

const VUE_API_TRIGGER = 'toggleVuePreview'

const toggleVuePreview = () => {
  const currentPreview = localStorage.getItem(VUE_PREVIEW)
  let enabledPreview

  if (!currentPreview || currentPreview === vuePreviews.v2) {
    enabledPreview = vuePreviews.v3
  } else {
    enabledPreview = vuePreviews.v2
  }

  localStorage.setItem(VUE_PREVIEW, enabledPreview)

  // eslint-disable-next-line
  console.info(`Enabled ${enabledPreview} preview`)
}

export default function useWindowMessageListeners() {
  const onMessageReceive = useCallback(({ data }) => {
    if (
      data === VUE_API_TRIGGER &&
      [instanceTypes.dev, instanceTypes.local].includes(
        process.env.REACT_APP_INSTANCE
      )
    ) {
      toggleVuePreview()
    }
  }, [])

  useEffect(
    () => {
      window.addEventListener('message', onMessageReceive)
      return () => {
        window.removeEventListener('message', onMessageReceive)
      }
    },
    // eslint-disable-next-line
    []
  )
}
