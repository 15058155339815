import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'

export const aiVideoCreatorApi = createAppApi('aiVideoCreatorQuery', {
  tagTypes: [apiTags.aiVideoCreator],
  endpoints: builder => ({
    getCreditUsage: builder.query({
      query: () => ({
        url: `/aiVideoCreator/creditUsage`
      })
    }),
    checkIsVideoAvailable: builder.mutation({
      query: (data = {}) => ({
        url: `/aiVideoCreator/checkIsAvailableVideo`,
        method: 'POST',
        data
      })
    })
  })
})

export const {
  useLazyGetCreditUsageQuery,
  useCheckIsVideoAvailableMutation
} = aiVideoCreatorApi
