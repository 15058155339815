import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { Grid, makeStyles, Paper, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import ImageWithPlaceholder from 'components/Loaders/ImageWithPlaceholder'
import { Text, TextWithTooltip } from 'components/Typography'
import Spacing from 'components/Containers/Spacing'
import { Checkbox } from 'components/Checkboxes'
import Popup from 'components/Popup'
import MaterialPopup from 'components/Popup/MaterialPopup'
import { TableLibraryRowActionButton } from 'components/TableLibrary'
import { CircleIconButton } from 'components/Buttons'
import LibraryTypeIcon from 'components/LibraryTypeIcon'
import Tooltip from 'components/Tooltip'
import { DARK, LIGHT } from 'theme'
import useElementProximity from 'hooks/useElementProximity'
import { zIndexes } from 'constants/stylesConstants'
import {
  BIG_CARD_WIDTH,
  BORDER_WIDTH,
  GLOW_SPREAD,
  NORMAL_CARD_THUMBNAIL_HEIGHT,
  NORMAL_CARD_WIDTH,
  PORTRAIT_CARD_THUMBNAIL_HEIGHT,
  PORTRAIT_CARD_WIDTH,
  SMALL_CARD_THUMBNAIL_HEIGHT,
  SMALL_CARD_WIDTH,
  textMaxWidth,
  WIDESCREEN_BIG_CARD_THUMBNAIL_HEIGHT,
  WIDESCREEN_CARD_THUMBNAIL_HEIGHT
} from 'constants/gridCard'

const useStyles = makeStyles(({ colors, type, palette, spacing }) => ({
  root: ({ selected, angle, inProximity }) => ({
    backgroundColor: colors.background.primary[type],
    boxShadow: palette[type].card.boxShadow,
    position: 'relative',
    borderRadius: '0 0 4px 4px',
    ...(selected && {
      border: `${BORDER_WIDTH}px solid ${colors.highlight}`,
      boxShadow: palette[type].card.selectedBoxShadow
    }),
    '&::before': {
      position: 'absolute',
      inset: selected ? -2 : 0,
      zIndex: 2,
      content: '""',
      pointerEvents: 'none',
      background: palette[type].card.glowBackground,
      border: '2px solid transparent',
      borderRadius: '0 0 4px 4px',
      opacity: inProximity ? 1 : 0,
      transition: 'opacity 1s',
      maskClip: 'padding-box, border-box',
      maskComposite: 'intersect',
      mask: `linear-gradient(#0000, #0000), conic-gradient(from calc((${angle} - (${GLOW_SPREAD} * 0.5)) * 1deg), #0000 0deg, #fff, #0000 calc(${GLOW_SPREAD} * 1deg))`,
      filter: 'brightness(1.5)'
    }
  }),
  'root-normal': {
    width: NORMAL_CARD_WIDTH
  },
  'root-small': {
    width: SMALL_CARD_WIDTH
  },
  'root-widescreen': {
    width: NORMAL_CARD_WIDTH
  },
  'root-widescreenBig': {
    width: BIG_CARD_WIDTH
  },
  'root-portrait': {
    width: PORTRAIT_CARD_WIDTH
  },
  thumbnailWrapper: {
    width: '100%',
    position: 'relative'
  },
  'thumbnailWrapper-normal': ({ selected }) => ({
    height: selected
      ? NORMAL_CARD_THUMBNAIL_HEIGHT - BORDER_WIDTH * 2
      : NORMAL_CARD_THUMBNAIL_HEIGHT
  }),
  'thumbnailWrapper-small': ({ selected }) => ({
    height: selected
      ? SMALL_CARD_THUMBNAIL_HEIGHT - BORDER_WIDTH * 2
      : SMALL_CARD_THUMBNAIL_HEIGHT
  }),
  'thumbnailWrapper-widescreen': ({ selected }) => ({
    height: selected
      ? WIDESCREEN_CARD_THUMBNAIL_HEIGHT - BORDER_WIDTH * 2
      : WIDESCREEN_CARD_THUMBNAIL_HEIGHT
  }),
  'thumbnailWrapper-widescreenBig': ({ selected }) => ({
    height: selected
      ? WIDESCREEN_BIG_CARD_THUMBNAIL_HEIGHT - BORDER_WIDTH * 2
      : WIDESCREEN_BIG_CARD_THUMBNAIL_HEIGHT
  }),
  'thumbnailWrapper-portrait': ({ selected }) => ({
    height: selected
      ? PORTRAIT_CARD_THUMBNAIL_HEIGHT - BORDER_WIDTH * 2
      : PORTRAIT_CARD_THUMBNAIL_HEIGHT
  }),
  textWithPopup: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    width: 'fit-content'
  },
  'textWithPopup-normal': {
    maxWidth: textMaxWidth.normal
  },
  'textWithPopup-small': {
    maxWidth: textMaxWidth.small
  },
  'textWithPopup-widescreen': {
    maxWidth: textMaxWidth.widescreen
  },
  'textWithPopup-widescreenBig': {
    maxWidth: textMaxWidth.widescreenBig
  },
  'textWithPopup-portrait': {
    maxWidth: textMaxWidth.portrait
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  checkbox: {
    height: 34,
    width: 34,
    backgroundColor: '#7a6565',
    color: colors.title.primary[DARK],
    '&:hover': {
      backgroundColor: '#625252 !important'
    }
  },
  cardBody: {
    boxShadow: palette[type].card.body.boxShadow
  },
  hiddenContainer: ({ selected }) => ({
    position: 'absolute',
    inset: 0,
    backgroundColor: '#7A656500',
    transition: 'all .2s',
    '& > .buttonContainer': {
      transition: 'opacity .2s',
      opacity: selected ? 1 : 0
    },
    '&:hover': {
      backgroundColor: '#7a656570',
      '& > .buttonContainer': {
        opacity: 1
      }
    }
  }),
  circleIconButton: {
    height: 34,
    width: 34,
    fontSize: 18,
    padding: spacing(1),
    color: colors.title.primary[DARK],
    backgroundColor: '#7a6565',
    marginLeft: 2,
    '& > span': {
      marginBottom: 0
    },
    '&:hover': {
      backgroundColor: '#625252'
    }
  },
  thumbnailIconWrapper: {
    height: '100%',
    width: '100%',
    borderRadius: 0
  },
  thumbnailIcon: {
    fontSize: '64px'
  },
  displayNone: {
    display: 'none'
  },
  glow: ({ angle, inProximity }) => ({
    position: 'absolute',
    inset: 0,
    zIndex: 2,
    content: '""',
    pointerEvents: 'none',
    opacity: inProximity ? 1 : 0,
    transition: 'opacity 1s',
    filter: 'blur(15px)',
    '&::before': {
      position: 'absolute',
      inset: -5,
      content: '""',
      background: palette[type].card.glowBackground,
      border: '10px solid transparent',
      maskClip: 'padding-box, border-box',
      maskComposite: 'intersect',
      mask: `linear-gradient(#0000, #0000), conic-gradient(from calc((${angle} - (${GLOW_SPREAD} * 0.5)) * 1deg), #0000 0deg, #fff, #0000 calc(${GLOW_SPREAD} * 1deg))`
    }
  })
}))

const popupTypes = {
  popup: 'popup',
  materialPopup: 'materialPopup'
}

const popupsByType = {
  [popupTypes.popup]: Popup,
  [popupTypes.materialPopup]: MaterialPopup
}

const popupPropsByType = {
  [popupTypes.popup]: {
    keepTooltipInside: 'body',
    contentStyle: {
      animation: 'fade-in 200ms',
      width: 350,
      zIndex: 200
    }
  },
  [popupTypes.materialPopup]: {
    style: {
      zIndex: zIndexes.materialPopup,
      width: 350,
      animation: 'fade-in 200ms'
    },
    placement: 'bottom',
    on: 'hover'
  }
}

const GridViewCard = ({
  thumbnail,
  thumbnailIcon = 'fa-solid fa-image-slash',
  thumbnailColor,
  thumbnailLoading,
  title,
  titleHoverComponent,
  bottomComponent,
  rightActionComponent,
  selected,
  onSelect,
  onPreview,
  showCheckbox = true,
  hoverVariant = 'notEmpty',
  item,
  variant = 'normal',
  actions,
  titlePopupType = popupTypes.popup,
  onThumbnailMouseEnter,
  onThumbnailMouseLeave,
  customThumbnailComponent,
  cardRootClassName = '',
  customTitleClassName = '',
  animationAllowed = true
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const ref = useRef()

  const animationDisabled = useMemo(
    () => theme.type === LIGHT || !animationAllowed,
    [theme, animationAllowed]
  )

  const { inProximity, angle } = useElementProximity(
    ref,
    undefined,
    animationDisabled
  )

  const classes = useStyles({ selected, angle, inProximity })

  const [error, setError] = useState(false)

  const handleSelect = useCallback(
    event => {
      event.stopPropagation()
      if (!event._fromAction) {
        onSelect(event, item.id)
      }
    },
    [item, onSelect]
  )

  const handlePreview = useCallback(
    event => {
      event.stopPropagation()
      onPreview(event, item)
    },
    [item, onPreview]
  )

  useEffect(() => {
    if (error) {
      setError(false)
    }
    // eslint-disable-next-line
  }, [thumbnail])

  const TitlePopupComponent = popupsByType[titlePopupType]

  return (
    <Paper
      ref={ref}
      className={classNames(
        classes.root,
        classes[`root-${variant}`],
        cardRootClassName
      )}
      elevation={2}
      {...(onSelect && { onClick: handleSelect })}
    >
      <div className={classes.glow} />
      <Grid container direction="column">
        <Grid
          item
          className={classNames(
            classes.thumbnailWrapper,
            classes[`thumbnailWrapper-${variant}`]
          )}
          onMouseEnter={onThumbnailMouseEnter}
          onMouseLeave={onThumbnailMouseLeave}
        >
          {customThumbnailComponent ||
            (!thumbnail || error ? (
              <LibraryTypeIcon
                color={thumbnailColor || theme.colors.light}
                iconHelperClass={classNames(
                  thumbnailIcon,
                  classes.thumbnailIcon
                )}
                wrapHelperClass={classes.thumbnailIconWrapper}
              />
            ) : (
              <ImageWithPlaceholder
                className={classes.thumbnail}
                src={thumbnail}
                onError={() => setError(true)}
                externalLoading={thumbnailLoading}
                placeHolderClass={classes[`thumbnailWrapper-${variant}`]}
                withoutRadius
              />
            ))}
          <Spacing
            direction="row"
            variant={0}
            paddingVert={1}
            paddingHor={1}
            justifyContent="space-between"
            alignItems="flex-start"
            rootClassName={classNames(classes.hiddenContainer, {
              [classes.displayNone]:
                hoverVariant === 'notEmpty' &&
                !(showCheckbox && onSelect) &&
                !onPreview &&
                !actions?.length &&
                !rightActionComponent
            })}
          >
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              className="buttonContainer"
            >
              <Grid item>
                {onSelect && showCheckbox && (
                  <Checkbox
                    checked={selected}
                    onClick={handleSelect}
                    className={classes.checkbox}
                  />
                )}
              </Grid>
              <Grid
                container
                item
                justifyContent="flex-end"
                alignItems="center"
              >
                {rightActionComponent && rightActionComponent}
                {onPreview && (
                  <Tooltip arrow title={t('Preview')}>
                    <CircleIconButton
                      onClick={handlePreview}
                      className={classNames(
                        'hvr-grow',
                        classes.circleIconButton
                      )}
                    >
                      <i className="fa-regular fa-image" />
                    </CircleIconButton>
                  </Tooltip>
                )}
                {!!actions?.length && (
                  <TableLibraryRowActionButton
                    onClick={e => e.stopPropagation()}
                    circleIconButtonClass={classes.circleIconButton}
                    actionLinks={actions}
                    circleButton
                    icon="fa-regular fa-ellipsis-vertical"
                  />
                )}
              </Grid>
            </Grid>
          </Spacing>
        </Grid>
        <Spacing
          variant={0}
          paddingVert={1}
          paddingHor={1}
          rootClassName={classes.cardBody}
        >
          {titleHoverComponent ? (
            <TitlePopupComponent
              {...popupPropsByType[titlePopupType]}
              trigger={
                <Text
                  rootClassName={classNames(
                    classes.textWithPopup,
                    classes[`textWithPopup-${variant}`],
                    customTitleClassName
                  )}
                  color="title.primary"
                  weight="bold"
                >
                  {title}
                </Text>
              }
            >
              {titleHoverComponent}
            </TitlePopupComponent>
          ) : (
            <TextWithTooltip
              maxWidth={textMaxWidth[variant]}
              color="title.primary"
              weight="bold"
            >
              {title}
            </TextWithTooltip>
          )}
        </Spacing>
        {bottomComponent}
      </Grid>
    </Paper>
  )
}

GridViewCard.propTypes = {
  thumbnail: PropTypes.string,
  thumbnailIcon: PropTypes.string,
  thumbnailColor: PropTypes.string,
  thumbnailLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleHoverComponent: PropTypes.element,
  bottomComponent: PropTypes.element,
  rightActionComponent: PropTypes.element,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  showCheckbox: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  hoverVariant: PropTypes.oneOf(['notEmpty', 'always']),
  variant: PropTypes.oneOf([
    'small',
    'normal',
    'widescreen',
    'widescreenBig',
    'portrait'
  ]),
  actions: PropTypes.array,
  titlePopupType: PropTypes.oneOf([popupTypes.popup, popupTypes.materialPopup]),
  customThumbnailComponent: PropTypes.element,
  cardRootClassName: PropTypes.string,
  animationAllowed: PropTypes.bool
}

export default GridViewCard
