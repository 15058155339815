import React, {
  Fragment,
  useMemo,
  useCallback,
  memo,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, Grid } from '@material-ui/core'
import FormControlInput from 'components/Form/FormControlInput'
import { simulateEvent } from 'utils/formik'
import FormControlLabel from 'components/Form/FormControlLabel'
import Error from 'components/Form/Error'
import MuiInputSlider, {
  MuiInputSliderProps
} from 'components/Form/MuiInputSlider/MuiInputSlider.js'

const styles = ({ colors }) => ({
  root: {
    position: 'relative'
  },
  marginBottom: {
    marginBottom: 16
  },
  input: {
    maxWidth: 76,
    height: 28,
    paddingRight: 0,
    textAlign: 'center',
    padding: '0 14px 0 0 !important'
  },
  inputContainer: {
    '& .react-numeric-input': {
      width: 68
    }
  },
  inputContainerBig: {
    '& .react-numeric-input': {
      width: 76
    }
  },
  error: {
    color: colors.error,
    fontSize: 9
  },
  labelTopContainer: {
    width: '100%',
    paddingLeft: '4px',
    height: 22
  },
  sliderContainer: {
    paddingLeft: 10,
    paddingRight: 10
  },
  labelTopSliderDefault: {
    width: 'calc(100% - 60px)'
  },
  labelTopSlider: {
    width: 'calc(100% - 68px)'
  },
  labelTopSliderBigContainer: {
    width: 'calc(100% - 76px)'
  },
  horizontalSlider: {
    width: 120
  },
  fullWidth: {
    '& .input-range': {
      width: '100%',
      height: '100%'
    }
  }
})

const SliderInputRange = ({
  classes,
  id,
  maxValue = 100,
  minValue = 0,
  step = 1,
  label = 'GB',
  labelPlacement = 'right',
  rootClass = '',
  inputRangeContainerClass = '',
  inputRangeContainerSASS = '',
  inputContainerClass = '',
  inputRootClass = '',
  inputClass = '',
  labelClass = '',
  labelTopContainerClass = '',
  tooltip = '',
  name,
  input = true,
  value = 0,
  disabled = false,
  onChange = () => {},
  horizontalSliderClass = '',
  errorClass,
  fontSizeVariant = 'primary',
  labelFontSizeVariant = 'primary',
  color = 'blue',
  error,
  touched,
  fullWidth,
  marginBottom = false,
  errorAbsolute = true,
  marks = false,
  valueLabelComponent,
  ThumbComponent,
  valueLabelDisplay = 'off',
  valueLabelFormat,
  track,
  sliderClasses
}) => {
  const [innerValue, setInnerValue] = useState(value)

  const { labelAtEnd, labelAtTop, labelAtStart } = useMemo(
    () => ({
      labelAtEnd: labelPlacement === 'right',
      labelAtTop: labelPlacement === 'top',
      labelAtStart: labelPlacement === 'left'
    }),
    [labelPlacement]
  )

  useEffect(() => {
    setInnerValue(value)
  }, [value, setInnerValue])

  const handleChange = useCallback(
    value => {
      if (name) {
        onChange(simulateEvent(name, value))
      } else {
        onChange(value)
      }
    },
    [name, onChange]
  )

  const handleChangeMuiSlider = useCallback(
    (e, value) => {
      if (name) {
        onChange(simulateEvent(name, value))
      } else {
        onChange(value)
      }
    },
    [name, onChange]
  )

  const handleBootstrapInputBaseChange = useCallback(
    ({ target: { value } }) => {
      handleChange(+value > maxValue ? maxValue : +value)
    },
    [handleChange, maxValue]
  )

  const handleSliderChange = useCallback((e, value) => {
    setInnerValue(value)
  }, [])

  return (
    <Grid
      container
      className={classNames(classes.root, rootClass, {
        [classes.marginBottom]: marginBottom
      })}
      wrap={!labelAtTop ? 'nowrap' : 'wrap'}
      alignItems="center"
      justifyContent="space-between"
    >
      {label && (labelAtStart || labelAtTop) && (
        <Grid
          item
          className={classNames(labelTopContainerClass, {
            [classes.labelTopContainer]: labelAtTop
          })}
        >
          <FormControlLabel
            label={label}
            tooltip={tooltip}
            rootClassName={labelClass}
            fontSizeVariant={labelFontSizeVariant}
          />
        </Grid>
      )}
      <Grid
        item
        className={classNames(
          classes.sliderContainer,
          inputRangeContainerClass,
          inputRangeContainerSASS,
          {
            [classes.labelTopSliderDefault]: labelAtTop,
            [classes.labelTopSlider]: labelAtTop && maxValue >= 1000,
            [classes.labelTopSliderBigContainer]: maxValue >= 10000,
            [classes.horizontalSlider]: !labelAtTop,
            [classes.fullWidth]: fullWidth
          },
          horizontalSliderClass
        )}
      >
        <MuiInputSlider
          name={name}
          max={maxValue}
          min={minValue}
          value={innerValue}
          step={step}
          onChange={handleSliderChange}
          onChangeCommitted={handleChangeMuiSlider}
          disabled={disabled}
          color={color}
          ValueLabelComponent={valueLabelComponent}
          ThumbComponent={ThumbComponent}
          valueLabelDisplay={valueLabelDisplay}
          valueLabelFormat={valueLabelFormat}
          marks={marks}
          track={track}
          classes={sliderClasses}
        />
      </Grid>
      {input && (
        <Fragment>
          <Grid
            item
            className={classNames(
              {
                [classes.inputContainer]: maxValue >= 1000,
                [classes.inputContainerBig]: maxValue >= 10000
              },
              inputContainerClass
            )}
          >
            <FormControlInput
              custom
              id={id}
              formikMode
              name={name}
              min={minValue}
              max={maxValue}
              step={step}
              formControlRootClass={classNames(inputRootClass)}
              formControlInputClass={classNames(classes.input, inputClass)}
              handleChange={handleBootstrapInputBaseChange}
              value={innerValue}
              disabled={disabled}
              fontSizeVariant={fontSizeVariant}
              marginBottom={false}
            />
          </Grid>
          {label && labelAtEnd && (
            <Grid item>
              <FormControlLabel label={label} rootClassName={labelClass} />
            </Grid>
          )}
        </Fragment>
      )}
      <Error
        absolute={errorAbsolute}
        error={error}
        condition={error && touched}
        rootClassName={errorClass}
      />
    </Grid>
  )
}

SliderInputRange.propTypes = {
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  rootClass: PropTypes.string,
  inputRangeContainerClass: PropTypes.string,
  inputRangeContainerSASS: PropTypes.string,
  inputContainerClass: PropTypes.string,
  inputRootClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  labelTopContainerClass: PropTypes.string,
  tooltip: PropTypes.string,
  input: PropTypes.bool,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  numberWraperStyles: PropTypes.object,
  horizontalSliderClass: PropTypes.string,
  fontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  labelFontSizeVariant: PropTypes.oneOf(['primary', 'small', 'smallest']),
  color: MuiInputSliderProps.color,
  error: PropTypes.string,
  touched: PropTypes.bool,
  fullWidth: PropTypes.bool,
  marginBottom: PropTypes.bool,
  errorAbsolute: PropTypes.bool,
  marks: PropTypes.bool,
  valueLabelComponent: PropTypes.func,
  ThumbComponent: PropTypes.func,
  valueLabelDisplay: PropTypes.oneOf(['on', 'auto', 'off']),
  valueLabelFormat: PropTypes.func
}

export default withStyles(styles)(memo(SliderInputRange))
