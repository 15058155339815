import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import socket from 'services/socket'
import { ALERT_FAILURE, ALERT_FAILURES } from 'constants/deviceConstants'
import useTimeout from './useTimeout'

function useAlertNotification({
  triggerReducer,
  devicesList,
  updateDeviceListAction,
  clearDeviceListAction,
  showSnackbar
}) {
  const dispatch = useDispatch()
  const devicesRef = useRef([])
  const [setTimeout, clearTimeout] = useTimeout()

  useEffect(() => {
    devicesRef.current = devicesList || []
  }, [devicesList])

  useEffect(() => {
    const alertProcessedCallback = ({ data: { status, id } }) => {
      if (status === 'successfully') {
        showSnackbar('Alert successfully sent', 'success')
        if (
          !_isEmpty(devicesRef.current) &&
          devicesRef.current.find(device => device.id === id)
        ) {
          const processingDevices = devicesRef.current.filter(
            device => device.id !== id
          )
          updateDeviceListAction &&
            dispatch(updateDeviceListAction(processingDevices))
        }
      }
    }
    socket.on('alertProcessed', alertProcessedCallback)

    return () => {
      socket.off(alertProcessedCallback)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (triggerReducer?.response?.isSuccess || triggerReducer?.isSuccess) {
      clearTimeout()

      setTimeout(() => {
        if (devicesRef.current.length) {
          if (devicesRef.current.length === 1) {
            showSnackbar(ALERT_FAILURE, 'warning', null, {
              name: devicesRef.current[0].name
            })
          } else {
            const deviceNames = devicesRef.current
              .map(device => device.name)
              .join(', ')
            showSnackbar(ALERT_FAILURES, 'warning', null, { deviceNames })
          }
          clearDeviceListAction && dispatch(clearDeviceListAction())
        }
      }, 60000)
    }

    // eslint-disable-next-line
  }, [triggerReducer])

  useEffect(() => {
    return () => {
      clearTimeout()
    }
    // eslint-disable-next-line
  }, [])
}

export default useAlertNotification
