import React, { useCallback, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, Grid, Typography } from '@material-ui/core'

import { FormControlTimeDurationPicker } from 'components/Form'
import { stringToSec, secToString, stringToHms } from 'utils/time'

const styles = theme => {
  const { palette, type } = theme
  return {
    container: {
      background:
        palette[type].formControls.multipleTimePicker.input.background,
      border: `1px solid ${palette[type].formControls.multipleTimePicker.input.border}`,
      borderRadius: 4,
      height: 32,
      width: '100%',
      overflow: 'hidden',
      flexWrap: 'nowrap'
    },
    divider: {
      width: 30,
      height: '100%',
      border: `1px solid ${palette[type].formControls.multipleTimePicker.input.border}`,
      background: palette[type].formControls.multipleTimePicker.input.border
    },
    dividerLabel: {
      fontSize: 11,
      fontWeight: 600,
      letterSpacing: '-0.02px',
      color: palette[type].formControls.multipleTimePicker.label.color
    },
    pickerInput: {
      border: 'none !important',
      '& .Mui-focused': {
        boxShadow: 'none !important'
      }
    },
    pickerTextInput: {
      borderRight: 'none !important'
    },
    pickerContainer: {
      width: 83
    },
    pickerContainerWidthLarge: {
      width: 110
    }
  }
}

const FormControlMultipleTimePicker = ({
  classes,
  small = false,
  disabled = false,
  onChange = f => f,
  fromValue: from = '00:00:00',
  toValue: to = '00:00:00',
  toValueStep = 3600,
  strictMode = false,
  displayWithSeconds = true,
  ampm = false,
  min,
  max
}) => {
  const toValueMin = useMemo(() => {
    if (strictMode) {
      return secToString(stringToSec(from) + 1)
    }
    return '00:00:00'
  }, [from, strictMode])

  const calcTimeValues = useCallback(
    fromValue => {
      if (fromValue === '23:59:59') {
        return ['00:00:00', secToString(stringToSec('00:00:00') + toValueStep)]
      }
      if (stringToHms(fromValue)[0] === 23) {
        return [fromValue, '23:59:59']
      }

      const maxSec = stringToSec(max)
      const toValueCalculated = stringToSec(fromValue) + toValueStep

      const toValue =
        toValueCalculated < maxSec || max === undefined
          ? toValueCalculated
          : maxSec

      return [fromValue, secToString(toValue)]
    },
    [toValueStep, max]
  )

  useEffect(() => {
    if (strictMode && stringToSec(to) <= stringToSec(from)) {
      onChange(calcTimeValues(from))
    }
    // eslint-disable-next-line
  }, [from, to])

  const selectOption = useCallback(
    field => value => {
      if (field === 'from') {
        if (strictMode) {
          onChange(calcTimeValues(value))
        } else {
          onChange([value, to])
        }
      } else {
        onChange([from, value])
      }
    },
    [from, to, onChange, strictMode, calcTimeValues]
  )

  const ref = useRef(null)

  return (
    <Grid
      container
      justifyContent="space-between"
      className={classNames(classes.container)}
    >
      <Grid item ref={ref}>
        <FormControlTimeDurationPicker
          value={from}
          anchorRef={ref}
          onChange={selectOption('from')}
          inputRootClassName={classes.pickerInput}
          inputTextRootClassName={classes.pickerTextInput}
          containerRootClassName={classNames(classes.pickerContainer, {
            [classes.pickerContainerWidthLarge]: ampm && displayWithSeconds
          })}
          hideIcon
          fullWidth={false}
          displayWithSeconds={displayWithSeconds}
          ampm={ampm}
          disabled={disabled}
          {...(min && { min })}
          {...(max && { max })}
        />
      </Grid>
      <Grid
        item
        className={classes.divider}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.dividerLabel}>TO</Typography>
      </Grid>
      <Grid item>
        <FormControlTimeDurationPicker
          value={to}
          onChange={selectOption('to')}
          inputRootClassName={classes.pickerInput}
          inputTextRootClassName={classes.pickerTextInput}
          containerRootClassName={classNames(classes.pickerContainer, {
            [classes.pickerContainerWidthLarge]: ampm && displayWithSeconds
          })}
          hideIcon
          fullWidth={false}
          displayWithSeconds={displayWithSeconds}
          ampm={ampm}
          disabled={disabled}
          {...(min ? { min } : { min: toValueMin })}
          {...(max && { max })}
        />
      </Grid>
    </Grid>
  )
}

FormControlMultipleTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  small: PropTypes.bool,
  isSeconds: PropTypes.bool,
  disabled: PropTypes.bool
}

export default withStyles(styles)(FormControlMultipleTimePicker)
