import update from 'immutability-helper'

import * as types from '../actions'
import {
  deleteInitialState,
  initialEntityState,
  postInitialState,
  putInitialState,
  shapeOfBodyWithMeta,
  shapeOfMeta
} from 'constants/initialLibraryState'
import { featureNames } from 'constants/featureConstants'

const fieldBodyShape = {
  response: [],
  error: {}
}

const initialState = {
  orgConfigOrgRole: {},
  mediaCategory: {},
  configOrgRole: {
    ...fieldBodyShape
  },
  enterpriseRole: {
    ...fieldBodyShape
  },
  resellerRole: {
    ...fieldBodyShape
  },
  systemRole: {
    ...fieldBodyShape
  },
  clientTypes: {
    ...fieldBodyShape
  },
  deviceTypes: {
    ...fieldBodyShape
  },
  configFeatureClient: {
    ...fieldBodyShape
  },
  configFeatureDevice: {
    ...fieldBodyShape,
    isFetching: false
  },
  configFeatureMedia: {
    ...fieldBodyShape
  },
  allConfigFeatureMedia: {
    ...fieldBodyShape,
    isFetching: false
  },
  configMediaCategory: {
    ...fieldBodyShape,
    cities: [],
    isFetching: false,
    isUninitialized: true
  },
  themeOfMedia: {
    ...fieldBodyShape,
    response: {},
    isFetching: false
  },
  contentSourceOfMediaFeature: {
    ...fieldBodyShape,
    isFetching: false
  },
  transitions: {
    ...fieldBodyShape
  },
  alertTypes: {
    ...fieldBodyShape
  },
  airlines: fieldBodyShape,
  airportGate: {
    ...fieldBodyShape,
    isFetching: false
  },
  airportBaggage: {
    ...fieldBodyShape
  },
  airports: fieldBodyShape,
  locations: fieldBodyShape,
  locationsInfo: fieldBodyShape,
  backgroundPatterns: {
    ...shapeOfBodyWithMeta,
    meta: {
      ...shapeOfMeta.meta,
      isLoading: false
    },
    isLoading: false
  },
  currency: fieldBodyShape,
  youtubeCaptions: {
    response: [],
    isFetching: false
  },
  oauth2Tokens: {
    ...initialEntityState
  },
  powerBiToken: {
    ...fieldBodyShape,
    response: { status: '' }
  },
  powerBiDashboards: {
    ...fieldBodyShape,
    isFetching: true
  },
  msTeamsChatToken: {
    ...fieldBodyShape,
    response: { status: '' },
    isFetching: false
  },
  msTeamsChatGroups: {
    ...fieldBodyShape,
    isFetching: true
  },
  workplacePosterTags: shapeOfBodyWithMeta,
  facebookPages: {
    response: [],
    isFetching: false,
    error: {}
  },
  legacyThemesEnabled: false,
  appConfig: {
    response: {}
  },
  fipsLocation: {
    ...fieldBodyShape,
    isFetching: false
  },
  ipawsFeeds: {
    ...fieldBodyShape,
    isFetching: false
  },
  klipfolioToken: {
    ...fieldBodyShape,
    response: { status: '' }
  },
  klipfolioDashboards: {
    ...fieldBodyShape,
    isFetching: true
  },
  contentApprove: {
    response: {},
    error: {},
    isFetching: false
  },
  contentReject: {
    response: {},
    error: {},
    isFetching: false,
    label: 'reject'
  },
  shorelineEvents: {
    ...fieldBodyShape,
    isFetching: false
  },
  shorelinePrices: {
    ...shapeOfBodyWithMeta,
    isFetching: false
  },
  shorelinePriceUpload: {
    ...fieldBodyShape,
    isFetching: false
  },
  geoLocation: {
    ...fieldBodyShape,
    ip: '',
    isFetching: false
  },
  globalSearch: {
    ...fieldBodyShape,
    isFetching: false
  },
  moderationLabel: {
    ...fieldBodyShape,
    isFetching: false
  },
  aiVideoAvatarsList: {
    ...fieldBodyShape,
    isFetching: false
  },
  aiVideoVoicesList: {
    ...fieldBodyShape,
    isFetching: false
  },
  aiVideoPresetBackgroundsList: {
    ...fieldBodyShape,
    isFetching: false
  }
}

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FACEBOOK_PAGES:
      return update(state, {
        facebookPages: {
          isFetching: { $set: true },
          error: { $set: {} }
        }
      })
    case types.GET_FACEBOOK_PAGES_SUCCESS:
      return update(state, {
        facebookPages: {
          isFetching: { $set: false },
          response: { $set: action.payload }
        }
      })

    case types.GET_FACEBOOK_PAGES_ERROR:
      return update(state, {
        facebookPages: {
          $set: {
            isFetching: false,
            response: [],
            error: action.payload
          }
        }
      })

    case types.GET_CONFIG_ORG_ROLE_SUCCESS:
      return update(state, {
        configOrgRole: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_ORG_ROLE_ERROR:
      return update(state, {
        configOrgRole: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_ORG_CONFIG_ORG_ROLE_INFO:
      return update(state, {
        configOrgRole: { $set: {} }
      })
    case types.GET_CONFIG_ENTERPRISE_ROLE_SUCCESS:
      return update(state, {
        enterpriseRole: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_ENTERPRISE_ROLE_ERROR:
      return update(state, {
        enterpriseRole: {
          error: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_RESELLER_ROLE_SUCCESS:
      return update(state, {
        resellerRole: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_RESELLER_ROLE_ERROR:
      return update(state, {
        resellerRole: {
          error: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_SYSTEM_ROLE_SUCCESS:
      return update(state, {
        systemRole: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_SYSTEM_ROLE_ERROR:
      return update(state, {
        systemRole: {
          error: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_CLIENT_TYPE_SUCCESS:
      return update(state, {
        clientTypes: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_CLIENT_TYPE_ERROR:
      return update(state, {
        clientTypes: {
          error: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_DEVICE_TYPE_SUCCESS:
      return update(state, {
        deviceTypes: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_DEVICE_TYPE_ERROR:
      return update(state, {
        deviceTypes: {
          error: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_FEATURE_CLIENT_SUCCESS:
      return update(state, {
        configFeatureClient: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_FEATURE_CLIENT_ERROR:
      return update(state, {
        configFeatureClient: {
          error: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_FEATURE_DEVICE:
      return update(state, {
        configFeatureDevice: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_CONFIG_FEATURE_DEVICE_SUCCESS:
      return update(state, {
        configFeatureDevice: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_CONFIG_FEATURE_DEVICE_ERROR:
      return update(state, {
        configFeatureDevice: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_CONFIG_FEATURE_MEDIA_SUCCESS:
      return update(state, {
        configFeatureMedia: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_FEATURE_MEDIA_ERROR:
      return update(state, {
        configFeatureMedia: {
          error: { $set: action.payload }
        }
      })
    case types.GET_ALL_CONFIG_FEATURE_MEDIA: {
      return update(state, {
        allConfigFeatureMedia: {
          isFetching: { $set: true },
          error: { $set: {} }
        }
      })
    }
    case types.GET_ALL_CONFIG_FEATURE_MEDIA_SUCCESS: {
      return update(state, {
        allConfigFeatureMedia: {
          isFetching: { $set: false },
          response: { $set: action.payload }
        }
      })
    }
    case types.GET_ALL_CONFIG_FEATURE_MEDIA_ERROR: {
      return update(state, {
        allConfigFeatureMedia: {
          isFetching: { $set: false },
          error: { $set: action.payload }
        }
      })
    }
    case types.GET_CONFIG_MEDIA_CATEGORY:
      return update(state, {
        configMediaCategory: {
          isFetching: { $set: true },
          isUninitialized: { $set: false },
          error: { $set: {} }
        }
      })
    case types.GET_CONFIG_MEDIA_CATEGORY_SUCCESS:
      return update(state, {
        configMediaCategory: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_CONFIG_MEDIA_CATEGORY_ERROR:
      return update(state, {
        configMediaCategory: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_THEME_OF_MEDIA_FEATURE_BY_ID:
      return update(state, {
        themeOfMedia: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_THEME_OF_MEDIA_FEATURE_BY_ID_SUCCESS:
      return update(state, {
        themeOfMedia: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_THEME_OF_MEDIA_FEATURE_BY_ID_ERROR:
      return update(state, {
        themeOfMedia: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID:
      return update(state, {
        contentSourceOfMediaFeature: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID_SUCCESS:
      return update(state, {
        contentSourceOfMediaFeature: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID_ERROR:
      return update(state, {
        contentSourceOfMediaFeature: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_CONFIG_ALERT_TYPES_SUCCESS:
      return update(state, {
        alertTypes: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_ALERT_TYPES_ERROR:
      return update(state, {
        alertTypes: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_CONFIG_ALERT_TYPES_INFO:
      return update(state, {
        alertTypes: { $set: {} }
      })
    case types.CLEAR_MEDIA_CATEGORY_CONFIG:
      return update(state, {
        mediaCategory: { $set: {} }
      })
    case types.GET_LOCATION_SUCCESS: {
      return update(state, {
        locations: {
          response: { $set: action.payload }
        }
      })
    }
    case types.GET_CITY_SUCCESS: {
      return update(state, {
        configMediaCategory: {
          cities: { $set: action.payload }
        }
      })
    }
    case types.GET_LOCATION_ERROR: {
      return update(state, {
        locations: {
          error: { $set: action.payload }
        }
      })
    }
    case types.GET_CITY_ERROR: {
      return update(state, {
        configMediaCategory: {
          error: { $set: action.payload }
        }
      })
    }
    case types.CLEAR_CONFIG_MEDIA_CATEGORY: {
      return update(state, {
        configMediaCategory: {
          error: { $set: {} }
        }
      })
    }
    case types.CLEAR_THEME_OF_MEDIA: {
      return update(state, {
        themeOfMedia: {
          $set: {
            ...fieldBodyShape,
            response: {}
          }
        }
      })
    }
    case types.GET_CONFIG_TRANSITIONS_SUCCESS:
      return update(state, {
        transitions: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_TRANSITIONS_ERROR:
      return update(state, {
        transitions: {
          error: { $set: action.payload }
        }
      })
    case types.GET_LOCATION_INFO_SUCCESS:
      return update(state, {
        locationsInfo: {
          response: { $set: action.payload }
        }
      })
    case types.GET_LOCATION_INFO_ERROR:
      return update(state, {
        locationsInfo: {
          error: { $set: action.payload }
        }
      })

    case types.GET_AIRLINES_SUCCESS:
      return update(state, {
        airlines: {
          response: { $set: action.payload }
        }
      })
    case types.CLEAR_AIRLINES_DATA:
      return update(state, {
        airlines: {
          $set: fieldBodyShape
        }
      })

    case types.GET_AIRLINES_ERROR:
      return update(state, {
        airlines: {
          error: { $set: action.payload }
        }
      })

    case types.GET_AIRPORTS_SUCCESS:
      return update(state, {
        airports: {
          response: { $set: action.payload }
        }
      })
    case types.GET_AIRPORTS_ERROR:
      return update(state, {
        airports: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_MEDIA_CONTENT_SOURCE:
      return update(state, {
        contentSourceOfMediaFeature: {
          $set: fieldBodyShape
        }
      })
    case types.GET_BACKGROUND_PATTERNS:
      return update(state, {
        backgroundPatterns: {
          isLoading: { $set: true },
          error: { $set: initialState.backgroundPatterns.error }
        }
      })
    case types.GET_BACKGROUND_PATTERNS_SUCCESS:
      return update(state, {
        backgroundPatterns: {
          isLoading: { $set: false },
          response: { $set: action.payload.data },
          meta: { $set: action.payload.meta }
        }
      })
    case types.GET_BACKGROUND_PATTERNS_ERROR:
      return update(state, {
        backgroundPatterns: {
          isLoading: { $set: false },
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.CLEAR_BACKGROUND_DATA:
      return update(state, {
        backgroundPatterns: { $set: initialState.backgroundPatterns }
      })
    case types.GET_CONFIG_CURRENCY_SUCCESS:
      return update(state, {
        currency: {
          response: { $set: action.payload }
        }
      })
    case types.GET_CONFIG_CURRENCY_ERROR:
      return update(state, {
        currency: {
          error: { $set: action.payload }
        }
      })
    case types.GET_POWERBI_TOKEN_SUCCESS:
      return update(state, {
        powerBiToken: {
          response: { $set: action.payload }
        }
      })
    case types.GET_POWERBI_TOKEN_ERROR:
      return update(state, {
        powerBiToken: {
          response: { $set: { status: 'error' } },
          error: { $set: action.payload }
        }
      })

    case types.GET_OAUTH2_TOKENS:
      return update(state, {
        oauth2Tokens: {
          items: {
            response: { $set: [] },
            isFetching: { $set: true },
            feature: { $set: action.params.feature }
          }
        }
      })

    case types.GET_OAUTH2_TOKENS_SUCCESS:
      return update(state, {
        oauth2Tokens: {
          items: {
            response: { $set: action.payload },
            meta: { $set: action.modifiedMeta },
            isFetching: { $set: false }
          }
        }
      })
    case types.GET_OAUTH2_TOKENS_ERROR:
      return update(state, {
        oauth2Tokens: {
          items: {
            response: { $set: [] },
            error: { $set: action.payload },
            isFetching: { $set: false }
          }
        }
      })

    case types.POST_FACEBOOK_ACCOUNT_SUCCESS:
    case types.POST_INSTAGRAM_ACCOUNT_SUCCESS:
    case types.POST_GOOGLE_CALENDAR_ACCOUNT_SUCCESS:
    case types.POST_MICROSOFT_OUTLOOK_ACCOUNT_SUCCESS:
    case types.POST_GOOGLE_DOCS_ACCOUNT_SUCCESS:
      return update(state, {
        oauth2Tokens: {
          post: {
            response: { $set: action.payload }
          }
        }
      })

    case types.POST_GOOGLE_DOCS_ACCOUNT_ERROR:
      return update(state, {
        oauth2Tokens: {
          post: {
            error: { $set: action.payload }
          }
        }
      })

    case types.POST_FACEBOOK_ACCOUNT_ERROR:
    case types.POST_INSTAGRAM_ACCOUNT_ERROR:
      return update(state, {
        oauth2Tokens: {
          post: {
            error: { $set: action.payload.error },
            response: {
              featureName: {
                $set: action.payload.featureName
              }
            }
          }
        }
      })

    case types.POST_GOOGLE_CALENDAR_ACCOUNT_ERROR:
    case types.POST_MICROSOFT_OUTLOOK_ACCOUNT_ERROR:
      return update(state, {
        oauth2Tokens: {
          post: {
            error: { $set: action.payload },
            response: {
              featureName: {
                $set: featureNames.CalendarAggregator
              }
            }
          }
        }
      })

    case types.PUT_OAUTH2_TOKEN_SUCCESS:
      return update(state, {
        oauth2Tokens: {
          put: {
            response: { $set: action.payload }
          }
        }
      })
    case types.PUT_OAUTH2_TOKEN_ERROR:
      return update(state, {
        oauth2Tokens: {
          put: {
            error: { $set: action.payload.error },
            response: {
              featureName: {
                $set: action.payload.featureName
              }
            }
          }
        }
      })
    case types.DELETE_OAUTH2_TOKEN:
      return update(state, {
        oauth2Tokens: {
          del: {
            id: { $set: action.id },
            name: { $set: action.name }
          }
        }
      })
    case types.DELETE_OAUTH2_TOKEN_SUCCESS:
      return update(state, {
        oauth2Tokens: {
          del: {
            response: { $set: action.payload }
          }
        }
      })
    case types.DELETE_OAUTH2_TOKEN_ERROR:
      return update(state, {
        oauth2Tokens: {
          del: {
            error: { $set: action.payload.error },
            response: {
              featureName: {
                $set: action.payload.featureName
              }
            }
          }
        }
      })

    case types.CLEAR_OAUTH2_TOKEN_RESPONSE_INFO:
      return update(state, {
        oauth2Tokens: {
          post: {
            $set: postInitialState
          },
          put: {
            $set: putInitialState
          },
          del: {
            $set: deleteInitialState
          }
        }
      })

    case types.GET_MS_TEAMS_CHAT_TOKEN:
      return update(state, {
        msTeamsChatToken: {
          isFetching: { $set: true }
        }
      })
    case types.GET_MS_TEAMS_CHAT_TOKEN_SUCCESS:
      return update(state, {
        msTeamsChatToken: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_MS_TEAMS_CHAT_TOKEN_ERROR:
      return update(state, {
        msTeamsChatToken: {
          response: { $set: { status: 'error' } },
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_MS_TEAMS_CHAT_TOKEN_INFO:
      return update(state, {
        msTeamsChatToken: {
          $set: {
            response: {
              status: ''
            },
            error: {},
            isFetching: false
          }
        }
      })
    case types.GET_MS_TEAMS_CHAT_CHANNELS:
    case types.CLEAR_MS_TEAMS_CHAT_CHANNELS:
      return update(state, {
        msTeamsChatGroups: {
          $set: {
            ...fieldBodyShape,
            isFetching: true
          }
        }
      })
    case types.GET_MS_TEAMS_CHAT_CHANNELS_SUCCESS:
      return update(state, {
        msTeamsChatGroups: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_MS_TEAMS_CHAT_CHANNELS_ERROR:
      return update(state, {
        msTeamsChatGroups: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })

    case types.GET_YOUTUBE_CAPTIONS:
      return update(state, {
        youtubeCaptions: {
          $set: {
            response: [],
            isFetching: true
          }
        }
      })
    case types.GET_YOUTUBE_CAPTIONS_SUCCESS: {
      return update(state, {
        youtubeCaptions: {
          isFetching: { $set: false },
          response: { $set: action.payload }
        }
      })
    }
    case types.GET_YOUTUBE_CAPTIONS_ERROR:
      return update(state, {
        youtubeCaptions: {
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_POWERBI_TOKEN_INFO:
      return update(state, {
        powerBiToken: {
          $set: {
            response: {
              status: ''
            },
            error: {}
          }
        }
      })
    case types.GET_POWERBI_DASHBOARDS:
    case types.CLEAR_POWERBI_DASHBOARDS:
      return update(state, {
        powerBiDashboards: {
          $set: {
            ...fieldBodyShape,
            isFetching: true
          }
        }
      })
    case types.GET_POWERBI_DASHBOARDS_SUCCESS:
      return update(state, {
        powerBiDashboards: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_POWERBI_DASHBOARDS_ERROR:
      return update(state, {
        powerBiDashboards: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })

    case types.GET_FIPS_LOCATION:
      return update(state, {
        fipsLocation: {
          isFetching: { $set: true }
        }
      })
    case types.GET_FIPS_LOCATION_SUCCESS:
      return update(state, {
        fipsLocation: {
          $set: {
            isFetching: false,
            response: action.payload
          }
        }
      })
    case types.GET_FIPS_LOCATION_ERROR:
      return update(state, {
        fipsLocation: {
          isFetching: { $set: false },
          error: {
            $set: action.payload
          }
        }
      })

    case types.GET_IPAWS_FEEDS:
      return update(state, {
        ipawsFeeds: {
          isFetching: { $set: true }
        }
      })
    case types.GET_IPAWS_FEEDS_SUCCESS:
      return update(state, {
        ipawsFeeds: {
          $set: {
            isFetching: false,
            response: action.payload
          }
        }
      })
    case types.GET_IPAWS_FEEDS_ERROR:
      return update(state, {
        ipawsFeeds: {
          isFetching: { $set: false },
          error: {
            $set: action.payload
          }
        }
      })

    case types.CLEAR_CONFIG_REDUCER:
      return update(state, { $set: initialState })
    case types.SET_LEGACY_THEMES_ENABLED: {
      return update(state, {
        legacyThemesEnabled: { $set: action.payload }
      })
    }
    case types.GET_APP_CONFIG_SUCCESS:
      return update(state, {
        appConfig: {
          response: { $set: action.payload }
        }
      })

    // Klipfolio
    case types.GET_KLIPFOLIO_TOKEN_SUCCESS:
      return update(state, {
        klipfolioToken: {
          response: { $set: action.payload }
        }
      })
    case types.GET_KLIPFOLIO_TOKEN_ERROR:
      return update(state, {
        klipfolioToken: {
          response: { $set: { status: 'error' } },
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_KLIPFOLIO_TOKEN_INFO:
      return update(state, {
        klipfolioToken: {
          $set: {
            response: {
              status: ''
            },
            error: {}
          }
        }
      })
    case types.CLEAR_KLIPFOLIO_RESPONSE_INFO:
    case types.GET_KLIPFOLIO_DASHBOARDS:
      return update(state, {
        klipfolioDashboards: {
          $set: {
            ...fieldBodyShape,
            isFetching: true
          }
        }
      })
    case types.GET_KLIPFOLIO_DASHBOARDS_SUCCESS:
      return update(state, {
        klipfolioDashboards: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_KLIPFOLIO_DASHBOARDS_ERROR:
      return update(state, {
        klipfolioDashboards: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CONTENT_APPROVE:
      return update(state, {
        contentApprove: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.CONTENT_APPROVE_SUCCESS:
      return update(state, {
        contentApprove: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CONTENT_APPROVE_ERROR:
      return update(state, {
        contentApprove: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_CONTENT_APPROVE_DATA:
      return update(state, {
        contentApprove: { $set: initialState.contentApprove }
      })
    case types.CONTENT_REJECT:
    case types.CONTENT_REJECT_AND_DELETE:
      return update(state, {
        contentReject: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.CONTENT_REJECT_SUCCESS:
    case types.CONTENT_REJECT_AND_DELETE_SUCCESS:
      return update(state, {
        contentReject: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CONTENT_REJECT_ERROR:
    case types.CONTENT_REJECT_AND_DELETE_ERROR:
      return update(state, {
        contentReject: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_CONTENT_REJECT:
      return update(state, {
        contentReject: { $set: initialState.contentReject }
      })
    case types.GET_AIRPORT_GATE:
      return update(state, {
        airportGate: {
          isFetching: { $set: true }
        }
      })
    case types.GET_AIRPORT_GATE_SUCCESS:
      return update(state, {
        airportGate: {
          response: { $set: action.payload.gates },
          isFetching: { $set: false }
        }
      })
    case types.GET_AIRPORT_GATE_ERROR:
      return update(state, {
        airportGate: {
          response: [],
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_AIRPORT_BAGGAGE_SUCCESS:
      return update(state, {
        airportBaggage: {
          response: { $set: action.payload.baggage }
        }
      })
    case types.GET_AIRPORT_BAGGAGE_ERROR:
      return update(state, {
        airportBaggage: {
          error: { $set: action.payload }
        }
      })
    case types.GET_SHORELINE_EVENTS:
      return update(state, {
        shorelineEvents: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_SHORELINE_EVENTS_SUCCESS:
      return update(state, {
        shorelineEvents: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_SHORELINE_EVENTS_ERROR:
      return update(state, {
        shorelineEvents: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_SHORELINE_PRICE:
      return update(state, {
        shorelinePrices: {
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.GET_SHORELINE_PRICE_SUCCESS:
      return update(state, {
        shorelinePrices: {
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_SHORELINE_PRICE_ERROR:
      return update(state, {
        shorelinePrices: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.POST_SHORELINE_PRICE:
      return update(state, {
        shorelinePriceUpload: {
          response: { $set: [] },
          error: { $set: {} },
          isFetching: { $set: true }
        }
      })
    case types.POST_SHORELINE_PRICE_SUCCESS:
      return update(state, {
        shorelinePriceUpload: {
          response: { $set: { status: 'success' } },
          meta: { $set: action.payload.meta },
          isFetching: { $set: false }
        }
      })
    case types.POST_SHORELINE_PRICE_ERROR:
      return update(state, {
        shorelinePriceUpload: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_POST_SHORELINE_PRICE:
      return update(state, {
        shorelinePriceUpload: {
          $set: {
            ...fieldBodyShape,
            isFetching: false
          }
        }
      })
    case types.GET_GEO_LOCATION_FROM_IP:
      return update(state, {
        geoLocation: {
          $set: {
            ...fieldBodyShape,
            ip: action.payload,
            isFetching: true
          }
        }
      })
    case types.GET_GEO_LOCATION_FROM_IP_SUCCESS:
      return update(state, {
        geoLocation: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_GEO_LOCATION_FROM_IP_ERROR:
      return update(state, {
        geoLocation: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_GLOBAL_SEARCH_ITEMS:
      return update(state, {
        globalSearch: {
          $set: {
            ...fieldBodyShape,
            isFetching: true
          }
        }
      })
    case types.GET_GLOBAL_SEARCH_ITEMS_SUCCESS:
      return update(state, {
        globalSearch: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_GLOBAL_SEARCH_ITEMS_ERROR:
      return update(state, {
        globalSearch: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_CONTENT_MODERATION_LABELS:
      return update(state, {
        moderationLabel: {
          isFetching: { $set: true }
        }
      })
    case types.GET_CONTENT_MODERATION_LABELS_SUCCESS:
      return update(state, {
        moderationLabel: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_CONTENT_MODERATION_LABELS_ERROR:
      return update(state, {
        moderationLabel: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.GET_AI_VIDEO_AVATARS_LIST:
      return update(state, {
        aiVideoAvatarsList: {
          isFetching: { $set: true }
        }
      })
    case types.GET_AI_VIDEO_AVATARS_LIST_SUCCESS:
      return update(state, {
        aiVideoAvatarsList: {
          response: { $set: action.payload }
        }
      })
    case types.GET_AI_VIDEO_AVATARS_LIST_ERROR:
      return update(state, {
        aiVideoAvatarsList: {
          error: { $set: action.payload }
        }
      })
    case types.GET_AI_VIDEO_VOICES_LIST:
      return update(state, {
        aiVideoVoicesList: {
          isFetching: { $set: true }
        }
      })
    case types.GET_AI_VIDEO_VOICES_LIST_SUCCESS:
      return update(state, {
        aiVideoVoicesList: {
          response: { $set: action.payload }
        }
      })
    case types.GET_AI_VIDEO_VOICES_LIST_ERROR:
      return update(state, {
        aiVideoVoicesList: {
          error: { $set: action.payload }
        }
      })
    case types.GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST:
      return update(state, {
        aiVideoPresetBackgroundsList: {
          isFetching: { $set: true }
        }
      })
    case types.GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST_SUCCESS:
      return update(state, {
        aiVideoPresetBackgroundsList: {
          response: { $set: action.payload }
        }
      })
    case types.GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST_ERROR:
      return update(state, {
        aiVideoPresetBackgroundsList: {
          error: { $set: action.payload }
        }
      })
    default:
      return state
  }
}

export default configReducer
