import React from 'react'

import ContentLoader from 'react-content-loader'

import { withTheme } from '@material-ui/core'

const NetworkContentLoader = ({ theme }) => (
  <ContentLoader
    style={{
      width: '100%',
      height: 423
    }}
    backgroundColor={theme.palette[theme.type].loader.background}
    foregroundColor={theme.palette[theme.type].loader.foreground}
  >
    <rect x="40" y="40" rx="6" ry="6" width="1000" height="160" />
    <rect x="40" y="220" rx="6" ry="6" width="1000" height="170" />
    <rect x="1110" y="225" rx="6" ry="6" width="164" height="32" />
    <rect x="1110" y="267" rx="6" ry="6" width="164" height="32" />
    <rect x="1110" y="309" rx="6" ry="6" width="164" height="32" />
    <rect x="1110" y="351" rx="6" ry="6" width="164" height="32" />
    <rect x="1120" y="40" rx="68" ry="68" width="136" height="136" />
    <rect x="1335" y="40" rx="71" ry="71" width="142" height="142" />
    <rect x="1300" y="220" rx="6" ry="6" width="216" height="175" />
  </ContentLoader>
)

export default withTheme(NetworkContentLoader)
