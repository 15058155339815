import { createAppApi } from 'services/api'

export const oneDriveApi = createAppApi('oneDriveQuery', {
  endpoints: builder => ({
    getOneDriveFiles: builder.query({
      tag: ['oneDriveFiles'],
      query: params => {
        return {
          url: `/oneDrive/myFiles`,
          params
        }
      }
    })
  })
})

export const {
  useGetOneDriveFilesQuery,
  useLazyGetOneDriveFilesQuery
} = oneDriveApi
