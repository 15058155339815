import { getBaseUrl } from './apiUtils'
export default function getVirtualDeviceLink({ virtualDevice }) {
  try {
    if (!virtualDevice?.subdomain || !getBaseUrl()) {
      return
    }
    return `https://vp-${virtualDevice?.subdomain}.${
      new URL(getBaseUrl()).host
    }/playback`
  } catch (e) {}
}
