import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import { Text } from 'components/Typography'
import {
  Logo,
  PageWrapper,
  CodeText,
  ShadowedErrorText,
  ErrorSubDescription
} from './components'

const useStyles = makeStyles(() => ({
  image: {
    position: 'absolute',
    top: '15%'
  },
  logoImageWrapper: {
    position: 'absolute',
    top: '12%'
  },
  errorTextWrapper: {
    position: 'absolute',
    top: '65%',
    minHeight: 242
  },
  text: {
    fontFamily: 'Montserrat'
  },
  errorText: {
    fontSize: '56px',
    lineHeight: '68px',
    fontWeight: 300
  },
  errorDescriptionText: {
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'center'
  }
}))

const ErrorPage = ({
  title,
  description,
  autoPing,
  ImageComponent,
  DescriptionComponent
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <PageWrapper autoPing={autoPing}>
      <Grid
        container
        item
        justifyContent="center"
        className={classes.logoImageWrapper}
      >
        <Logo />
      </Grid>
      <CodeText />
      <ImageComponent className={classes.image} />
      <Grid
        container
        item
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.errorTextWrapper}
      >
        <Grid container item justifyContent="center">
          <ShadowedErrorText>{title}</ShadowedErrorText>
          <Text
            rootClassName={classNames(classes.errorText, classes.text)}
            color="white"
          >
            {t('Error')}
          </Text>
        </Grid>
        {DescriptionComponent ? (
          <DescriptionComponent
            className={classNames(classes.errorDescriptionText, classes.text)}
            color="white"
          />
        ) : (
          <Text
            rootClassName={classNames(
              classes.errorDescriptionText,
              classes.text
            )}
            color="white"
          >
            {description}
          </Text>
        )}

        <ErrorSubDescription />
      </Grid>
    </PageWrapper>
  )
}

export default ErrorPage
