import { call, put } from 'redux-saga/effects'

import * as types from '../actions'

import { playlistService } from '../services'
import { transformMeta } from 'utils/tableUtils'
import { approveContent } from 'services/approveService'
import { playlistTypes } from 'constants/playlist'

function* addPlaylist({ data, isPlaylist }) {
  try {
    const response = yield call(playlistService.addPlaylist, data, isPlaylist)
    yield put({
      type: types.POST_PLAYLIST_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    yield put({
      type: types.POST_PLAYLIST_ERROR,
      payload: error
    })
  }
}

function* approvePlaylist({ id }) {
  try {
    yield call(approveContent, 'playlist', id)
    yield put({
      type: types.APPROVE_PLAYLIST_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.APPROVE_PLAYLIST_ERROR,
      payload: error
    })
  }
}

function* deletePlaylistById({ id, data }) {
  try {
    yield call(playlistService.deletePlaylistById, { id, data })
    yield put({
      type: types.DELETE_PLAYLIST_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_PLAYLIST_ERROR,
      payload: {
        ...(data || {}),
        ...error
      }
    })
  }
}

function* deleteSelectedPlaylist({ ids, data }) {
  try {
    yield call(playlistService.deleteSelectedPlaylist, { ids, data })
    yield put({
      type: types.DELETE_SELECTED_PLAYLIST_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({
      type: types.DELETE_SELECTED_PLAYLIST_ERROR,
      payload: error
    })
  }
}

function* putPlaylistById({ meta, data }) {
  try {
    const response = yield call(
      playlistService.putPlaylistById,
      {
        id: meta.id,
        data: data
      },
      meta.isPlaylist
    )
    yield put({
      type: types.PUT_PLAYLIST_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.PUT_PLAYLIST_ERROR,
      payload: error
    })
  }
}

function* getPlaylistById(action) {
  try {
    const response = yield call(playlistService.getPlaylistById, action.data)
    yield put({
      type: types.GET_PLAYLIST_BY_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({
      type: types.GET_PLAYLIST_BY_ID_ERROR,
      payload: error
    })
  }
}

function* getItems({ params }) {
  try {
    const { data = [], meta = {} } = yield call(
      playlistService.getItems,
      params
    )
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_PLAYLIST_ITEMS_SUCCESS,
      payload: {
        response: data,
        params: params,
        modifiedMeta
      }
    })
  } catch (error) {
    yield put({ type: types.GET_PLAYLIST_ITEMS_ERROR, payload: error })
  }
}

function* getModalItems({ params }) {
  try {
    const { data = [], meta = {} } = yield call(
      playlistService.getItems,
      params
    )
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_MODAL_PLAYLIST_ITEMS_SUCCESS,
      payload: {
        response: data,
        params: params,
        modifiedMeta
      }
    })
  } catch (error) {
    yield put({ type: types.GET_MODAL_PLAYLIST_ITEMS_ERROR, payload: error })
  }
}

function* getPreference() {
  try {
    const response = yield call(playlistService.getPreference)
    yield put({
      type: types.GET_PLAYLIST_PREFERENCE_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_PLAYLIST_PREFERENCE_ERROR, payload: error })
  }
}

function* putPreference(action) {
  try {
    yield call(playlistService.putPreference, action.payload)
    yield put({ type: types.GET_PLAYLIST_PREFERENCE })
  } catch (error) {
    yield put({ type: types.PUT_PLAYLIST_PREFERENCE_ERROR, payload: error })
  }
}

function* getGroupItems(action) {
  try {
    const response = yield call(
      playlistService.getGroupItems,
      action.payload.id,
      action.payload.params
    )
    yield put({
      type: types.GET_PLAYLIST_GROUP_ITEMS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_PLAYLIST_GROUP_ITEMS_ERROR, payload: error })
  }
}

function* postGroupItem(action) {
  try {
    const response = yield call(playlistService.postGroupItem, action.payload)
    yield put({
      type: types.POST_PLAYLIST_GROUP_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.POST_PLAYLIST_GROUP_ITEM_ERROR, payload: error })
  }
}

function* deleteGroupItem(action) {
  try {
    const response = yield call(playlistService.deleteGroupItem, action.payload)
    yield put({
      type: types.DELETE_PLAYLIST_GROUP_ITEM_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.DELETE_PLAYLIST_GROUP_ITEM_ERROR, payload: error })
  }
}

function* clonePlaylist({ data }) {
  try {
    yield call(playlistService.clonePlaylist, data)
    yield put({
      type: types.CLONE_PLAYLIST_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.CLONE_PLAYLIST_ERROR, payload: error })
  }
}

function* getPlaylistPreview(action) {
  try {
    const response = yield call(playlistService.getPreview, action.data)
    yield put({
      type: types.GET_PLAYLIST_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GET_PLAYLIST_PREVIEW_ERROR, payload: error })
  }
}

function* generatePlaylistPreview(action) {
  try {
    const response = yield call(playlistService.generatePreview, action.data)
    yield put({
      type: types.GENERATE_PLAYLIST_PREVIEW_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: types.GENERATE_PLAYLIST_PREVIEW_ERROR, payload: error })
  }
}

function* sharePlaylist({ payload }) {
  try {
    yield call(playlistService.sharePlaylist, payload)
    yield put({
      type: types.SHARE_PLAYLIST_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.SHARE_PLAYLIST_ERROR, payload: error })
  }
}

function* copyPlaylist({ data }) {
  try {
    yield call(playlistService.copyPlaylist, data)
    yield put({
      type: types.COPY_PLAYLIST_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.COPY_PLAYLIST_ERROR, payload: error })
  }
}

function* getGroupPlaylists({ params }) {
  try {
    const { data, meta } = yield call(playlistService.getItems, params)
    const modifiedMeta = transformMeta(meta)

    yield put({
      type: types.GET_PLAYLIST_GROUP_PLAYLISTS_SUCCESS,
      payload: {
        response: data,
        meta: modifiedMeta
      }
    })
  } catch (error) {
    yield put({
      type: types.GET_PLAYLIST_GROUP_PLAYLISTS_ERROR,
      payload: error
    })
  }
}

function* getAssignablePlaylist() {
  try {
    const { data } = yield call(playlistService.getItems, {
      limit: 1,
      playlistType: [
        playlistTypes.Standard.name,
        playlistTypes.Interactive.name
      ].join(',')
    })

    yield put({
      type: types.GET_ASSIGNABLE_PLAYLIST_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: types.GET_ASSIGNABLE_PLAYLIST_ERROR,
      payload: error
    })
  }
}

function* getPlaylistAssociations({ payload: { id, params } }) {
  try {
    const response = yield call(
      playlistService.getPlaylistAssociations,
      id,
      params
    )

    const modifiedMeta = transformMeta(response.meta)
    yield put({
      type: types.GET_PLAYLIST_ASSOCIATIONS_SUCCESS,
      payload: {
        response: response.data,
        meta: modifiedMeta
      }
    })
  } catch (error) {
    yield put({ type: types.GET_PLAYLIST_ASSOCIATIONS_ERROR, payload: error })
  }
}

function* postPlaylistBulk({ payload }) {
  try {
    yield call(playlistService.postPlaylistBulk, payload)
    yield put({
      type: types.POST_PLAYLIST_BULK_SUCCESS,
      payload: { status: 'success' }
    })
  } catch (error) {
    yield put({ type: types.POST_PLAYLIST_BULK_ERROR, payload: error })
  }
}

const playlistSaga = {
  addPlaylist,
  approvePlaylist,
  getPlaylistById,
  putPlaylistById,
  deletePlaylistById,
  getItems,
  getModalItems,
  getPreference,
  putPreference,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  deleteSelectedPlaylist,
  clonePlaylist,
  getPlaylistPreview,
  generatePlaylistPreview,
  sharePlaylist,
  copyPlaylist,
  getGroupPlaylists,
  getAssignablePlaylist,
  getPlaylistAssociations,
  postPlaylistBulk
}

export default playlistSaga
