import * as types from './index'

const getItems = (params, impersonated) => ({
  type: types.GET_CLIENTS,
  params,
  impersonated
})

const clearResponseInfo = () => ({
  type: types.CLEAR_CLIENT_RESPONSE_INFO
})

const clearItemInfo = () => ({
  type: types.CLEAR_CLIENT_USER_ITEM_INFO
})

const getItem = id => ({
  type: types.GET_CLIENT_BY_ID,
  id
})

const postItem = data => {
  return {
    type: types.POST_CLIENT,
    data
  }
}
const putItem = (id, data) => ({
  type: types.PUT_CLIENT,
  id,
  data
})

const getClientGroupItems = (id, params) => ({
  type: types.GET_CLIENT_GROUP_ITEMS,
  payload: { id, params }
})

const postClientGroupItem = data => ({
  type: types.POST_CLIENT_GROUP_ITEM,
  payload: data
})

const deleteClientGroupItem = data => ({
  type: types.DELETE_CLIENT_GROUP_ITEM,
  payload: data
})

const clearClientGroupItemsInfo = () => ({
  type: types.CLEAR_CLIENT_GROUP_ITEMS_RESPONSE_INFO
})

const clearGetClientsGroupItemsInfo = () => ({
  type: types.CLEAR_GET_CLIENTS_GROUP_ITEMS_INFO
})

const getClientNotes = id => ({
  type: types.GET_CLIENT_NOTES,
  payload: id
})

const postClientNote = ({ id, message }) => ({
  type: types.POST_CLIENT_NOTE,
  payload: {
    id,
    data: { message }
  }
})

const clearGetClientNoteInfo = () => ({
  type: types.CLEAR_GET_CLIENT_NOTE_INFO
})

const clearPostClientNoteInfo = () => ({
  type: types.CLEAR_POST_CLIENT_NOTE_INFO
})

const getClientLicenses = (id, params) => {
  return {
    type: types.GET_CLIENT_LICENSES,
    id,
    params
  }
}

const postClientLicense = payload => ({
  type: types.POST_CLIENT_LICENSE,
  payload
})

const putClientLicenseById = payload => ({
  type: types.PUT_CLIENT_LICENSE,
  payload
})

const deleteClientLicenseById = id => ({
  type: types.DELETE_CLIENT_LICENSE,
  id
})

const clearClientLicenseItemsInfo = () => ({
  type: types.CLEAR_CLIENT_LICENSE_ITEMS_INFO
})

const clearClientLicenseResponseInfo = () => ({
  type: types.CLEAR_CLIENT_LICENSE_RESPONSE_INFO
})

const putClientBilling = (id, data) => ({
  type: types.PUT_CLIENT_BILLING,
  payload: { id, data }
})

const editClientFeatureSettings = data => ({
  type: types.EDIT_CLIENT_FEATURE_SETTINGS,
  payload: data
})

const postClientBulk = payload => ({
  type: types.POST_CLIENT_BULK,
  payload
})

const clearPostClientBulkInfo = () => ({
  type: types.CLEAR_POST_CLIENT_BULK_INFO
})

export {
  getItems,
  getItem,
  postItem,
  putItem,
  clearResponseInfo,
  getClientGroupItems,
  postClientGroupItem,
  deleteClientGroupItem,
  clearClientGroupItemsInfo,
  getClientNotes,
  postClientNote,
  clearGetClientNoteInfo,
  clearPostClientNoteInfo,
  getClientLicenses,
  postClientLicense,
  putClientLicenseById,
  deleteClientLicenseById,
  clearClientLicenseItemsInfo,
  clearClientLicenseResponseInfo,
  clearGetClientsGroupItemsInfo,
  clearItemInfo,
  putClientBilling,
  editClientFeatureSettings,
  postClientBulk,
  clearPostClientBulkInfo
}
