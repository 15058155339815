export const parseTimezoneName = name => {
  const nameParts = name.split('/')

  const parsedName = nameParts[nameParts.length - 1].replaceAll('_', ' ')

  if (/^GMT/.test(parsedName)) {
    return parsedName.replace('GMT', 'Greenwich Mean Time')
  } else if (/^UTC/.test(parsedName)) {
    return parsedName.replace('UTC', 'Coordinated Universal Time')
  }
  return parsedName
}
