import { requestHandler } from 'utils/requestHandlers'

const office365 = {
  getFiles: async params =>
    await requestHandler({
      method: 'GET',
      url: '/office365/myFiles',
      params
    }),
  getSharedFiles: async params =>
    await requestHandler({
      method: 'GET',
      url: '/office365/sharedFiles',
      params
    })
}

const mediaFeaturesService = {
  office365
}

export default mediaFeaturesService
