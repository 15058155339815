import { _get } from 'utils/lodash'

import mockApi from './mockApi'
import api from './api'

import { errorHandler, queryParamsHelper } from '../utils'
import { deviceQueryHelper } from 'utils/scheduleUtils'

export const getItems = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/report',
      params
    })

    const data = _get(response, 'data.data', response.data)
    const meta = _get(response, 'data.meta', {
      currentPage: 1,
      from: 1,
      lastPage: 1,
      perPage: '10',
      to: 10,
      total: data.length,
      count: 10
    })

    return { data, meta }
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteSelectedReports = async ids => {
  try {
    await mockApi({
      method: 'DELETE',
      url: `/reports/bulk`,
      params: {
        ids: ids.join(',')
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getReportTables = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/table`
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const runReport = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/report/run`,
      data
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const postReport = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/report',
      data
    })
    return response
  } catch (e) {
    throw errorHandler(e)
  }
}

export const putReport = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/report/${id}`,
      data
    })

    return response
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getReport = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/${id}`,
      params: {
        limit: 9999
      }
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getTemplateReport = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/${id}/template`
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getCountApiCall = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/countApiCall`,
      params
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getLastFailedApiCall = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/lastFailedApiCall`,
      params
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getCountClientApiCall = async (clientId, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/countClientApiCall/${clientId}`,
      params
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getCountDeviceApiCall = async (deviceId, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/countDeviceApiCall/${deviceId}`,
      params
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getTopClientsApiCall = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/topClientsMostApiCall`,
      params
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getTopDevicesApiCall = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/topDevicesMostApiCall`,
      params
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getReportSchedule = async reportId => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/${reportId}/schedule`
    })
    return response
  } catch (e) {
    throw errorHandler(e)
  }
}

export const postReportSchedule = async ({ reportId, data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/report/${reportId}/schedule`,
      data
    })
    return response
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getReportLink = async ({ reportId, fileId }) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/${reportId}/download/${fileId}`
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getExposureCount = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/playbackPlayingLog/exposureCount`,
      params: queryParamsHelper({
        ...params,
        ...(params.device && deviceQueryHelper(params.device))
      })
    })

    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getExposureTime = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/playbackPlayingLog/exposureTime`,
      params: queryParamsHelper({
        ...params,
        ...(params.device && deviceQueryHelper(params.device))
      })
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}

export const getVerboseData = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/report/playbackPlayingLog/getVerboseData`,
      params: queryParamsHelper({
        ...params,
        ...(params.device && deviceQueryHelper(params.device))
      })
    })
    return response.data
  } catch (e) {
    throw errorHandler(e)
  }
}
