import * as types from './index'

const getConfigOrgRole = () => ({
  type: types.GET_CONFIG_ORG_ROLE
})

const clearGetOrgConfigOrgRoleInfo = () => ({
  type: types.CLEAR_GET_ORG_CONFIG_ORG_ROLE_INFO
})

const getConfigEnterpriseRole = () => ({
  type: types.GET_CONFIG_ENTERPRISE_ROLE
})

const getConfigResellerRole = () => ({
  type: types.GET_CONFIG_RESELLER_ROLE
})

const getConfigSystemRole = () => ({
  type: types.GET_CONFIG_SYSTEM_ROLE
})

const getConfigClientType = impersonated => ({
  type: types.GET_CONFIG_CLIENT_TYPE,
  impersonated
})

const getConfigDeviceType = () => ({
  type: types.GET_CONFIG_DEVICE_TYPE
})

const getConfigFeatureClient = () => ({
  type: types.GET_CONFIG_FEATURE_CLIENT
})

const getConfigFeatureDevice = () => ({
  type: types.GET_CONFIG_FEATURE_DEVICE
})

const getConfigFeatureMedia = params => ({
  type: types.GET_CONFIG_FEATURE_MEDIA,
  params
})

const getAllConfigFeatureMedia = params => ({
  type: types.GET_ALL_CONFIG_FEATURE_MEDIA,
  params
})

const getConfigMediaCategory = () => ({
  type: types.GET_CONFIG_MEDIA_CATEGORY
})

const getThemeOfMediaFeatureById = id => ({
  type: types.GET_THEME_OF_MEDIA_FEATURE_BY_ID,
  id
})

const clearMediaThemes = () => ({
  type: types.CLEAR_THEME_OF_MEDIA
})

const getContentSourceOfMediaFeatureById = id => ({
  type: types.GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID,
  id
})

const getAlertTypesAction = () => ({
  type: types.GET_CONFIG_ALERT_TYPES
})

const clearGetAlertTypesInfoAction = () => ({
  type: types.CLEAR_GET_CONFIG_ALERT_TYPES_INFO
})

const getLocation = (data, silent) => ({
  type: types.GET_LOCATION,
  data,
  silent
})

const getCity = (data, silent) => ({
  type: types.GET_CITY,
  data,
  silent
})

const getTransitions = () => ({
  type: types.GET_CONFIG_TRANSITIONS
})

const getAirlines = params => ({
  type: types.GET_AIRLINES,
  params
})

const clearAirlinesData = () => ({
  type: types.CLEAR_AIRLINES_DATA
})

const getAirports = params => ({
  type: types.GET_AIRPORTS,
  params
})

const getLocationInfo = location => ({
  type: types.GET_LOCATION_INFO,
  location
})

const clearMediaContentSource = location => ({
  type: types.CLEAR_MEDIA_CONTENT_SOURCE,
  location
})

const getBackgroundPattern = params => ({
  type: types.GET_BACKGROUND_PATTERNS,
  params
})

const getBackgroundPatternById = id => ({
  type: types.GET_BACKGROUND_PATTERN_BY_ID,
  id
})

const clearBackgroundData = () => ({
  type: types.CLEAR_BACKGROUND_DATA
})

const clearConfigMediaCategory = () => ({
  type: types.CLEAR_CONFIG_MEDIA_CATEGORY
})

const getCurrency = () => ({
  type: types.GET_CONFIG_CURRENCY
})

const getYoutubeCaptions = id => ({
  type: types.GET_YOUTUBE_CAPTIONS,
  payload: id
})

const getOauth2Tokens = params => ({
  type: types.GET_OAUTH2_TOKENS,
  params
})

const postFacebookAccount = data => ({
  type: types.POST_FACEBOOK_ACCOUNT,
  data
})

const postInstagramAccount = data => ({
  type: types.POST_INSTAGRAM_ACCOUNT,
  data
})

const postGoogleDocsAccount = data => ({
  type: types.POST_GOOGLE_DOCS_ACCOUNT,
  data
})

const putOauth2Token = (id, data, featureName) => ({
  type: types.PUT_OAUTH2_TOKEN,
  id,
  data,
  featureName
})

const deleteOauth2Token = (id, data, name, featureName) => ({
  type: types.DELETE_OAUTH2_TOKEN,
  id,
  name,
  data,
  featureName
})

const clearOauth2TokenResponseInfo = () => ({
  type: types.CLEAR_OAUTH2_TOKEN_RESPONSE_INFO
})

const getPowerBiToken = code => ({
  type: types.GET_POWERBI_TOKEN,
  code
})

const powerBiLogout = ({ tokenId, onLogoutSuccess, onLogoutError, data }) => ({
  type: types.POWERBI_LOGOUT,
  tokenId,
  onLogoutSuccess,
  onLogoutError,
  data
})

const clearPowerBiInfo = () => ({
  type: types.CLEAR_POWERBI_TOKEN_INFO
})

const getPowerBiDashboards = () => ({
  type: types.GET_POWERBI_DASHBOARDS
})

const clearPowerBiDashboards = () => ({
  type: types.CLEAR_POWERBI_DASHBOARDS
})

const clearConfigReducer = () => ({
  type: types.CLEAR_CONFIG_REDUCER
})

const getFacebookPages = params => ({
  type: types.GET_FACEBOOK_PAGES,
  params
})

// MS Teams Chat

const getMsTeamsChatToken = code => ({
  type: types.GET_MS_TEAMS_CHAT_TOKEN,
  code
})

const clearMsTeamsChatInfo = () => ({
  type: types.CLEAR_MS_TEAMS_CHAT_TOKEN_INFO
})

const getMsTeamsChatChannels = () => ({
  type: types.GET_MS_TEAMS_CHAT_CHANNELS
})

const clearMsTeamsChatChannels = () => ({
  type: types.CLEAR_MS_TEAMS_CHAT_CHANNELS
})

const msTeamsChatLogout = ({
  tokenId,
  onLogoutSuccess,
  onLogoutError,
  data
}) => ({
  type: types.MS_TEAMS_CHAT_LOGOUT,
  tokenId,
  onLogoutSuccess,
  onLogoutError,
  data
})

const setLegacyThemesEnabled = enabled => ({
  type: types.SET_LEGACY_THEMES_ENABLED,
  payload: enabled
})

const getAppConfig = () => ({
  type: types.GET_APP_CONFIG
})

const getFipsLocation = params => ({
  type: types.GET_FIPS_LOCATION,
  params
})

const getIpawsFeeds = () => ({
  type: types.GET_IPAWS_FEEDS
})

// Klipfolio
const getKlipfolioToken = data => ({
  type: types.GET_KLIPFOLIO_TOKEN,
  data
})

const klipfolioLogout = ({
  tokenId,
  onLogoutSuccess,
  onLogoutError,
  data
}) => ({
  type: types.KLIPFOLIO_LOGOUT,
  tokenId,
  data,
  onLogoutSuccess,
  onLogoutError
})

const clearKlipfolioInfo = () => ({
  type: types.CLEAR_KLIPFOLIO_TOKEN_INFO
})

const getKlipfolioDashboards = () => ({
  type: types.GET_KLIPFOLIO_DASHBOARDS
})

const clearKlipfolioResponseInfo = () => ({
  type: types.CLEAR_KLIPFOLIO_RESPONSE_INFO
})

const approveContent = params => ({
  type: types.CONTENT_APPROVE,
  payload: params
})

const clearContentApproveData = () => ({
  type: types.CLEAR_CONTENT_APPROVE_DATA
})

const rejectContent = (content, id) => ({
  type: types.CONTENT_REJECT,
  payload: { content, id }
})

const rejectAndDeleteContent = (content, id) => ({
  type: types.CONTENT_REJECT_AND_DELETE,
  payload: { content, id }
})

const clearContentReject = () => ({
  type: types.CLEAR_CONTENT_REJECT
})

export const getAirportGate = params => ({
  type: types.GET_AIRPORT_GATE,
  params
})

export const getAirportBaggage = params => ({
  type: types.GET_AIRPORT_BAGGAGE,
  params
})

export const getShorelineEvents = (params = {}) => ({
  type: types.GET_SHORELINE_EVENTS,
  payload: { params }
})

export const getShorelinePrice = (params = {}) => ({
  type: types.GET_SHORELINE_PRICE,
  payload: { params }
})

export const uploadShorelinePrice = data => ({
  type: types.POST_SHORELINE_PRICE,
  payload: data
})

export const clearUploadShorelinePrice = () => ({
  type: types.CLEAR_POST_SHORELINE_PRICE
})

export const getGeoLocationFromIp = ip => ({
  type: types.GET_GEO_LOCATION_FROM_IP,
  payload: ip
})

export const getGlobalSearchItems = params => ({
  type: types.GET_GLOBAL_SEARCH_ITEMS,
  params
})

export const postGoogleCalendarAccount = data => ({
  type: types.POST_GOOGLE_CALENDAR_ACCOUNT,
  data
})

export const postMicrosoftOutlookAccount = data => ({
  type: types.POST_MICROSOFT_OUTLOOK_ACCOUNT,
  data
})

export const getContentModerationLabels = () => ({
  type: types.GET_CONTENT_MODERATION_LABELS
})

//AI Video Creator
const getAIVideoAvatarsList = () => ({
  type: types.GET_AI_VIDEO_AVATARS_LIST
})

const getAIVideoVoicesList = () => ({
  type: types.GET_AI_VIDEO_VOICES_LIST
})

const getAIVideoPresetBackgroundsList = () => ({
  type: types.GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST
})

export {
  getConfigOrgRole,
  clearGetOrgConfigOrgRoleInfo,
  getConfigEnterpriseRole,
  getConfigResellerRole,
  getConfigSystemRole,
  getConfigClientType,
  getConfigDeviceType,
  getConfigFeatureClient,
  getConfigFeatureDevice,
  getConfigFeatureMedia,
  getConfigMediaCategory,
  getThemeOfMediaFeatureById,
  getContentSourceOfMediaFeatureById,
  getLocation,
  getCity,
  clearMediaThemes,
  getTransitions,
  getAlertTypesAction,
  clearGetAlertTypesInfoAction,
  getAirlines,
  clearAirlinesData,
  getAirports,
  getLocationInfo,
  clearMediaContentSource,
  getBackgroundPattern,
  clearBackgroundData,
  getBackgroundPatternById,
  clearConfigMediaCategory,
  getCurrency,
  getYoutubeCaptions,
  getPowerBiToken,
  powerBiLogout,
  getOauth2Tokens,
  clearPowerBiInfo,
  getPowerBiDashboards,
  clearPowerBiDashboards,
  clearConfigReducer,
  getFacebookPages,
  setLegacyThemesEnabled,
  getAppConfig,
  getFipsLocation,
  getIpawsFeeds,
  getKlipfolioToken,
  getKlipfolioDashboards,
  clearKlipfolioResponseInfo,
  clearKlipfolioInfo,
  klipfolioLogout,
  approveContent,
  clearContentApproveData,
  rejectContent,
  rejectAndDeleteContent,
  clearContentReject,
  getAllConfigFeatureMedia,
  postFacebookAccount,
  postInstagramAccount,
  postGoogleDocsAccount,
  putOauth2Token,
  deleteOauth2Token,
  clearOauth2TokenResponseInfo,
  getMsTeamsChatToken,
  clearMsTeamsChatInfo,
  getMsTeamsChatChannels,
  clearMsTeamsChatChannels,
  msTeamsChatLogout,
  getAIVideoAvatarsList,
  getAIVideoVoicesList,
  getAIVideoPresetBackgroundsList
}
