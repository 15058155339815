import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects'

import * as types from '../../actions/public'
import deviceNocSaga from './deviceNoc'
import authSaga from './auth'

function* watchDeviceNoc() {
  yield all([
    takeEvery(
      types.GET_PUBLIC_NOC_GENERAL_ITEMS,
      deviceNocSaga.getPublicNocGeneralItems
    ),
    takeEvery(
      types.GET_PUBLIC_NOC_HEALTH_ITEMS,
      deviceNocSaga.getPublicNocHealthItems
    ),
    takeLatest(
      types.GET_PUBLIC_NOC_NETWORK_ITEMS,
      deviceNocSaga.getPublicNocNetworkItems
    ),
    takeLatest(
      types.GET_PUBLIC_NOC_ACCESS_TOKEN,
      deviceNocSaga.getPublicNocAccessToken
    )
  ])
}

function* watchAuth() {
  yield all([
    takeEvery(types.GET_PUBLIC_TOKEN, authSaga.getPublicToken),
    takeEvery(types.POST_PUBLIC_TOKEN, authSaga.postPublicToken),
    takeLatest(types.DELETE_PUBLIC_TOKEN, authSaga.deletePublicToken)
  ])
}

export default function* publicWatcher() {
  yield fork(watchAuth)
  yield fork(watchDeviceNoc)
}
