import React from 'react'
import { makeStyles, TableSortLabel, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

const useStyles = makeStyles(({ palette, type, spacing, colors }) => ({
  tableHeaderCellLabel: {
    whiteSpace: 'nowrap',

    '&:hover': {
      fontWeight: 700,
      '& > i': {
        opacity: 0.5
      }
    }
  },
  tableHeaderCellLabelActive: {
    color: colors.title.primary[type] + ' !important',
    fontWeight: 700,

    '& svg': {
      color: palette[type].table.head.color + ' !important'
    }
  },
  nestedStyle: {
    display: 'flex',
    justifyContent: 'center',
    '& >div': {
      padding: '0 6px'
    }
  },
  icon: {
    opacity: 0,
    marginLeft: spacing(1),
    color: colors.light,
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  iconActive: {
    color: 'inherit',
    opacity: '1 !important'
  },
  iconVisible: {
    opacity: 0.5
  }
}))

const TableLibrarySortLabel = ({
  column,
  orderBy,
  order,
  hideSortIcon,
  createSortHandler
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (column.id === 'action' || column.label === 'Logo') {
    return null
  }

  return (
    <TableSortLabel
      className={classNames(
        'Enhanced-table-head__table-sort-label',
        classes.tableHeaderCellLabel
      )}
      classes={{
        active: classes.tableHeaderCellLabelActive
      }}
      active={[
        column.id,
        ...(column.sortIcons ? column.sortIcons.map(({ id }) => id) : [])
      ].includes(orderBy)}
      direction={order || 'asc'}
      onClick={
        column.sortIcons?.length
          ? createSortHandler(column.sortIcons[0].id)
          : createSortHandler(column.id)
      }
      hideSortIcon={hideSortIcon}
    >
      <div>
        <Tooltip
          arrow
          title={column.tooltip || ''}
          placement={column.tooltipPlacement || 'top'}
          disableHoverListener={!column.tooltip}
        >
          <span>{t(column.label)}</span>
        </Tooltip>
        {column.isNested && (
          <div className={classes.nestedStyle}>
            {column.nestedData.map(listData => (
              <div key={listData.id}>{listData.label}</div>
            ))}
          </div>
        )}
      </div>
      {(column.sortIcons || []).map(({ id, icon }) => (
        <i
          key={id}
          onClick={e => {
            e.stopPropagation()
            createSortHandler(id)(e)
          }}
          className={classNames(icon, classes.icon, {
            [classes.iconVisible]: column.sortIcons.some(
              item => item.id === orderBy
            ),
            [classes.iconActive]: orderBy === id
          })}
        />
      ))}
    </TableSortLabel>
  )
}

export default TableLibrarySortLabel
