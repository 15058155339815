const splitVariant = str => {
  let array = str.match(/[\d.]+|\D+/g)
  let weight = 400
  let style = 'regular'
  if (array.length === 2) {
    array[0] = Number.parseInt(array[0])
    weight = array[0]
    style = array[1]
  }
  if (array.length === 1 && array[0] !== 'regular' && array[0] !== 'italic') {
    array[0] = Number.parseInt(array[0])
    weight = array[0]
  } else if (array.length === 1) {
    style = array[0]
  }
  return {
    weight,
    style,
    variant: str
  }
}

export const getFontDescriptors = variant => {
  return {
    style: variant.includes('italic') ? 'italic' : 'normal',
    weight: parseInt(variant) || 400
  }
}

export const combineFontName = (fontFamily, variant) =>
  `${fontFamily}${variant}`

export default splitVariant

export const getVariantStyle = (variant, family) => {
  const isVariantItalic = variant?.startsWith('italic')
  const isVariantBold = variant?.startsWith('bold')
  return {
    fontStyle: isVariantItalic ? 'italic' : 'normal',
    fontWeight: isVariantItalic
      ? variant.replace('italic', '')
      : parseInt(variant) || (isVariantBold ? 700 : 400),
    fontFamily: family
  }
}
