export const MENU_ITEMS = 'menuItem'
export const LOCATIONS = 'location'
export const STATIONS = 'station'
export const INITIAL_SOLD_OUT_ITEM_VIEW = 'remove'

export const menuItemSourceTypes = {
  manual: 'Manual',
  pos: '3rd Party API'
}

export const weekDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

export const inventoryOptions = t => [
  {
    label: t('StockOut'),
    value: '1'
  },
  {
    label: t('InStock'),
    value: '0'
  }
]

export const statusOptions = t => [
  { label: t('Active'), value: 'Active' },
  { label: t('Inactive'), value: 'Inactive' }
]

export const allergenIcons = t => [
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/nuts-amber.png',
    name: 'Tree Nuts',
    label: t('Tree Nuts')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/peanuts-amber.png',
    name: 'Peanuts',
    label: t('Peanuts')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/eggs-amber.png',
    name: 'Egg',
    label: t('Egg')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/sesame-amber.png',
    name: 'Sesame',
    label: t('Sesame')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/milk-amber.png',
    name: 'Milk',
    label: t('Milk')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/cereal-amber.png',
    name: 'Wheat',
    label: t('Wheat')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/soya-amber.png',
    name: 'Soy',
    label: t('Soy')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/molluscs-amber.png',
    name: 'Shellfish',
    label: t('Shellfish')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/fish-amber.png',
    name: 'Fish',
    label: t('Fish')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/celery-amber.png',
    name: 'Celery',
    label: t('Celery')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/crustacean-amber.png',
    name: 'Crustaceans',
    label: t('Crustaceans')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/mustard-amber.png',
    name: 'Mustard',
    label: t('Mustard')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/gluten-amber.png',
    name: 'Gluten',
    label: t('Gluten')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/lupin-amber.png',
    name: 'Lupin',
    label: t('Lupin')
  },
  {
    icon:
      'https://raw.githubusercontent.com/Erudus/erudus-icons/master/examples/png/so2-amber.png',
    name: 'Sulphur Dioxide',
    label: t('Sulphur Dioxide')
  }
]

export const allergenOptions = t =>
  allergenIcons(t).map(({ name, label }) => ({ label, value: name }))

export const providerConstants = {
  webtrition: 'Webtrition',
  aldelo: 'Aldelo',
  appetize: 'Appetize',
  clover: 'Clover',
  esposnow: 'Esposnow',
  lavu: 'Lavu',
  mjfreeway: 'MJFreeway',
  omnivore: 'Omnivore',
  posist: 'POSist',
  revel: 'Revel',
  simplicity: 'Simplicity',
  square: 'Square',
  toast: 'Toast',
  vend: 'Vend',
  focuspos: 'FocusPOS',
  googlesheets: 'GoogleSheets',
  json: 'JSON',
  csv: 'CSV',
  xml: 'XML',
  xls: 'XLS',
  itsaCheckmate: 'ItsaCheckmate'
}

export const integrationMappingFields = {
  name: 'Name',
  allergens: 'Allergens',
  nutrients: 'Nutrients',
  venues: 'Venues',
  stations: 'Stations',
  ingredients: 'Ingredients',
  description: 'Description',
  portion: 'Portion',
  price: 'Price',
  mealPeriod: 'Meal Period',
  photo: 'Main Image',
  photoGallery: 'Image Gallery',
  category: 'Category'
}

export const componentsParams = {
  sort: 'updatedAt',
  order: 'desc'
}

export const soldOutItemViewOptions = t => [
  {
    label: t('Remove item from Menu Board'),
    value: 'remove'
  },
  {
    label: t('Cross out item from Menu Board'),
    value: 'crossout'
  },
  {
    label: t('Cross out and show SOLD OUT label'),
    value: 'crossout_label'
  }
]
export const itsaCheckmateWebhookURL = `${window.location.origin}/api/v1/webhook/menuMaker/itsacheckmate`

export const integrationSyncStatuses = {
  finished: 'Finished',
  pending: 'Pending',
  progress: 'Progress',
  failed: 'Failed'
}

export const menuItemChipsColor = 'rgba(60, 212, 128 )'
