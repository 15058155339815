export const DESIGN_GALLERY_SETTINGS = 'DESIGN_GALLERY_SETTINGS'
export const MENU_MAKER_SETTINGS = 'MENU_MAKER_SETTINGS'

export const TABS_NAMES = {
  patterns: 'Patterns',
  background: 'Background',
  shapes: 'Shapes',
  icons: 'Icons',
  objects: 'Objects',
  group: 'Group',
  style1: 'Set 1',
  style2: 'Set 2',
  style3: 'Set 3',
  style4: 'Set 4',
  style5: 'Set 5',
  emojis: 'Emojis',
  fonts: 'Fonts',
  libraryImages: 'Library Images',
  stockImages: 'Stock Images',
  royaltyFreeImages: 'Royalty Free Images',
  giphyImages: 'GIPHY Images',
  templates: 'Templates',
  myDesigns: 'My Designs',
  stockDesigns: 'Stock',
  sharedDesigns: 'Shared',
  customFonts: 'Custom Fonts',
  systemFonts: 'System Fonts',
  fontsSubTab: 'fonts',
  fontCombinationsSubTab: 'fontCombinations',
  google: 'google',
  custom: 'custom'
}

export const MENU_MAKER_TAB_NAMES = {
  menuBoards: 'Menu Boards',
  components: 'Components',
  images: 'Images',
  shapes: 'Shapes',
  icons: 'Icons'
}

export const DESIGN_TAB_NAMES = {
  myDesigns: 'Created by you',
  stockDesigns: 'Stock Designs',
  sharedDesigns: 'Shared with you'
}

export const FAVORITE_TAB = {
  favoriteTab: 'Favorite Designs'
}

export const MENU_BOARD_TAB_NAMES = {
  myBoard: 'Created by you'
}

export const MENU_PRESETS_TAB_NAMES = {
  myPresets: 'Created by you',
  stockPresets: 'Stock Presets',
  sharedPresets: 'Shared with you'
}

export const MENU_COMPONENTS_TAB_NAMES = {
  myComponents: 'Created by you',
  stockComponents: 'Stock Components',
  sharedComponents: 'Shared with you'
}

export const DESIGN_TAB_EMPTY = {
  myDesigns: "Let's Create Something!",
  stockDesigns: 'Nothing to show',
  sharedDesigns: 'No shared designs available at this time'
}

export const FAVORITE_TAB_EMPTY = {
  favoriteTab: "Let's Add Some Design To Favorite List!"
}

export const DESIGN_TYPES = {
  My: 'My',
  Stock: 'Stock',
  Shared: 'Shared'
}

export const previewGridTypes = {
  svg: 'svg',
  image: 'image',
  bg: 'bg',
  template: 'template',
  font: 'font',
  fontCombination: 'fontCombination',
  component: 'component',
  myDesign: 'myDesign'
}

export const GRID_COLUMN_WIDTH = 170
export const GRID_COLUMN_HEIGHT = 60
export const GRID_SPACING = 5

export const FRAME_PADDING = 25
export const TEXT_PROPERTY_PADDING = 12.5

export const SIDEBAR_WIDTH = 300
export const SIDEBAR_SMALL_WIDTH = 60
export const FOOTER_HEIGHT = 50

export const MAX_CANVAS_WIDTH = 3840
export const MAX_CANVAS_HEIGHT = 2160
export const MIN_CANVAS_SIZE = 100

export const DEFAULT_PATTERN_COLOR = 'rgba(236, 236, 236, 1)'
export const INITIAL_SNAP_TO_GRID_VALUE = true
export const INITIAL_TEXT_SCALING_VALUE = true

export const MIN_ZOOM = 0.4
export const MAX_ZOOM = 10
export const RULER_HEIGHT = 25
export const FABRIC_EXAMPLE_TEXT = 'The quick brown fox jumps over the lazy dog'
export const DEFAULT_TEXT_SPEED = 0
export const DEFAULT_FONT_SIZE = 100
export const BLACK_FRAME_EXTRA_SPACE = 6
export const ALLOWED_SELECTION_STYLES = [
  'fontSize',
  'fill',
  'fontFamily',
  'fontWeight',
  'fontStyle',
  'fontUppercase',
  'linethrough',
  'underline'
]

export const TEMPLATES_GROUPS = ['All', 'Media', 'Playlist']
export const SORTING_TYPES = {
  grids: 'grids',
  row: 'row'
}

export const SAVED_OBJECT_OPTIONS = [
  'selectable',
  'backgroundColor',
  'isBackground',
  'isGradient',
  'isFrame',
  'isBlackFrame',
  // 'clipPath',
  'absolutePositioned',
  'hoverCursor',
  'scaleX',
  'scaleY',
  'strokeWidth',
  'lockMovementY',
  'lockMovementX',
  'lockScalingY',
  'lockScalingX',
  'hasControls',
  'hasBorders',
  'hasCorners',
  'locked',
  'lockedleft',
  'lockedtop',
  'subTargetCheck',
  'id',
  'groupedId',
  'fill',
  'isGuideLine',
  'isDraggable',
  'crossOrigin',
  'originalText',
  'fontUppercase',
  'isDragging',
  'imageId',
  'external',
  'lockedBy',
  'filters',
  'propertyBackground',
  'isProperty',
  'isComponent',
  'componentId',
  'borderDashArray',
  'propertyType',
  'wrapped',
  'overlay',
  'isOverlayGroup',
  'isOverlay',
  'isCropped',
  'isFreeCropped',
  'stretchX',
  'stretchY',
  'isGroupedObject',
  'isPureShape',
  'typeList',
  'patternSettings',
  'isClone',
  'parentId',
  'cloneId',
  'outerDiv',
  'showAs',
  'showGrid',
  'componentSpacing',
  'padding',
  'cornerSize',
  'styles',
  'lastText',
  'animation',
  'opacity',
  'shadow',
  'rx',
  'ry',
  'borderColor',
  'editable',
  'propertyTypeId',
  'isPlaceholder',
  'elementId',
  'scaling',
  'visible',
  'isOuterFrame',
  'isComponentBackground',
  'isGridGroup',
  'menuItemVariant',
  'menuItemVariantSet',
  // BORDERS SECTION
  'isBorderGroup',
  'isBorder',
  'borderState',
  'isTextBorderGroup',
  // Menu Items and Categories
  'menuItemIds',
  'categoryIds',
  'menuOptions',
  'url',
  'vTextAlign'
]

export const FONT_COMBINATIONS = [
  // Fonts missed on the BE side
  // {
  //   main: 'Evolve',
  //   secondary: 'Hazard'
  // },
  {
    main: 'Acme',
    secondary: 'Abel'
  },
  {
    main: 'Josefin Sans',
    secondary: 'Playfair'
  },
  {
    main: 'Montserrat',
    secondary: 'Cardo'
  },
  {
    main: 'Montserrat',
    secondary: 'Playfair Display'
  },
  {
    main: 'Nunito',
    secondary: 'Lora'
  },
  {
    main: 'Open Sans',
    secondary: 'Libre Baskerville'
  },
  {
    main: 'Open Sans',
    secondary: 'Lora'
  },
  {
    main: 'Oswald',
    secondary: 'Merriweather'
  },
  {
    main: 'Oswald',
    secondary: 'Old Standard TT'
  },
  {
    main: 'PT Sans',
    secondary: 'PT Serif'
  },
  {
    main: 'Raleway',
    secondary: 'Merriweather'
  },
  {
    main: 'Ubuntu',
    secondary: 'Lora'
  },
  {
    main: 'League Spartan',
    secondary: 'Libre Baskerville'
  },
  {
    main: 'Cardo',
    secondary: 'Josefin Sans'
  },
  {
    main: 'Libre Baskerville',
    secondary: 'Montserrat'
  },
  {
    main: 'Lora',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Merriweather',
    secondary: 'Open Sans'
  },
  {
    main: 'Merriweather',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Playfair Display',
    secondary: 'Open Sans'
  },
  {
    main: 'PT Serif',
    secondary: 'Open Sans'
  },
  {
    main: 'Roboto Slab',
    secondary: 'Open Sans'
  },
  {
    main: 'Roboto Slab',
    secondary: 'Roboto'
  },
  {
    main: 'Source Serif Pro',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Abril Fatface',
    secondary: 'Josefin Sans'
  },
  {
    main: 'Abril Fatface',
    secondary: 'Lato'
  },
  {
    main: 'Amatic SC',
    secondary: 'Josefin Sans'
  },
  {
    main: 'Lobster',
    secondary: 'Arimo'
  },
  {
    main: 'Lobster',
    secondary: 'Open Sans'
  },
  {
    main: 'Pacifico',
    secondary: 'Arimo'
  },
  {
    main: 'Pacifico',
    secondary: 'Josefin Sans'
  },
  {
    main: 'Patua One',
    secondary: 'Oswald'
  },
  {
    main: 'Shadows',
    secondary: 'Roboto'
  },
  {
    main: 'Stint Ultra',
    secondary: 'Roboto'
  },
  {
    main: 'Sacramento',
    secondary: 'Montserrat Light'
  },
  {
    main: 'Yellowtail',
    secondary: 'Open Sans'
  },
  {
    main: 'Patua One',
    secondary: 'Lora'
  },
  {
    main: 'Nixie One',
    secondary: 'Libre Baskerville'
  },
  {
    main: 'Nunito',
    secondary: 'Open Sans'
  },
  {
    main: 'Open Sans',
    secondary: 'Nunito'
  },
  {
    main: 'Oswald',
    secondary: 'Open Sans'
  },
  {
    main: 'Raleway',
    secondary: 'Roboto'
  },
  {
    main: 'Roboto',
    secondary: 'Nunito'
  },
  {
    main: 'Oswald',
    secondary: 'Montserrat Light'
  },
  {
    main: 'Open Sans',
    secondary: 'Cooper Hewitt'
  },
  {
    main: 'Playfair Display',
    secondary: 'Fauna One'
  },
  {
    main: 'Quando',
    secondary: 'Judson'
  },
  {
    main: 'Aileron Heavy',
    secondary: 'Aileron Light'
  },
  {
    main: 'Archivo Black',
    secondary: 'Archivo Narrow'
  },
  {
    main: 'Cooper Hewitt Heavy',
    secondary: 'Cooper Hewitt'
  },
  {
    main: 'Source Serif Pro Blacko',
    secondary: 'Source Serif Pr'
  },
  {
    main: 'Lora',
    secondary: 'Merriweather'
  },
  {
    main: 'Proza Libre',
    secondary: 'Open Sans'
  },
  {
    main: 'Libre Baskerville',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Rubik',
    secondary: 'Karla'
  },
  {
    main: 'Asap',
    secondary: 'Asap'
  },
  {
    main: 'Work Sans',
    secondary: 'Work Sans'
  },
  {
    main: 'Domine',
    secondary: 'Roboto'
  },
  {
    main: 'Fira Sans',
    secondary: 'Merriweather'
  },
  {
    main: 'Karla',
    secondary: 'Karla'
  },
  {
    main: 'Nunito',
    secondary: 'Nunito'
  },
  {
    main: 'Space Mono',
    secondary: 'Space Mono'
  },
  {
    main: 'Poppins',
    secondary: 'PT Serif'
  },
  {
    main: 'Muli',
    secondary: 'Muli'
  },
  {
    main: 'BioRhyme',
    secondary: 'Space Mono'
  },
  {
    main: 'Saira',
    secondary: 'Saira'
  },
  {
    main: 'IBM Plex Sans',
    secondary: 'IBM Plex Serif'
  },
  {
    main: 'Archivo Black',
    secondary: 'Roboto'
  },
  {
    main: 'Halant',
    secondary: 'Nunito Sans'
  },
  {
    main: 'Rubik',
    secondary: 'Rubik'
  },
  {
    main: 'Spectral',
    secondary: 'Karla'
  },
  {
    main: 'Maven Pro',
    secondary: 'Maven Pro'
  },
  {
    main: 'Lora',
    secondary: 'Roboto'
  },
  {
    main: 'Alegreya',
    secondary: 'Alegreya'
  },
  {
    main: 'Montserrat',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Raleway',
    secondary: 'Raleway'
  },
  {
    main: 'Chivo',
    secondary: 'Overpass'
  },
  {
    main: 'Domine',
    secondary: 'Open Sans'
  },
  {
    main: 'Dosis',
    secondary: 'Dosis'
  },
  {
    main: 'Work Sans',
    secondary: 'Bitter'
  },
  {
    main: 'Inconsolata',
    secondary: 'Inconsolata'
  },
  {
    main: 'Alegreya Sans',
    secondary: 'Alegreya'
  },
  {
    main: 'Archivo Narrow',
    secondary: 'Merriweather'
  },
  {
    main: 'Cabin',
    secondary: 'Old Standard TT'
  },
  {
    main: 'Fjalla One',
    secondary: 'Average'
  },
  {
    main: 'Istok Web',
    secondary: 'Lora'
  },
  {
    main: 'Lato',
    secondary: 'Merriweather'
  },
  {
    main: 'Libre Franklin',
    secondary: 'Libre Baskerville'
  },
  {
    main: 'Merriweather Sans',
    secondary: 'Merriweather'
  },
  {
    main: 'Montserrat',
    secondary: 'Crimson Text'
  },
  {
    main: 'Montserrat',
    secondary: 'Domine'
  },
  {
    main: 'Montserrat',
    secondary: 'Neuton'
  },
  {
    main: 'Nunito',
    secondary: 'Alegreya'
  },
  {
    main: 'Open Sans',
    secondary: 'Gentium Book Basic'
  },
  {
    main: 'Oswald',
    secondary: 'Quattrocento'
  },
  {
    main: 'Quicksand',
    secondary: 'EB Garamond'
  },
  {
    main: 'Source Sans Pro',
    secondary: 'Source Serif Pro'
  },
  {
    main: 'Work Sans',
    secondary: 'Taviraj'
  },
  {
    main: 'BioRhyme',
    secondary: 'Cabin'
  },
  {
    main: 'Cormorant Garamond',
    secondary: 'Proza Libre'
  },
  {
    main: 'Alegreya',
    secondary: 'Open Sans'
  },
  {
    main: 'Alegreya',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Bitter',
    secondary: 'Raleway'
  },
  {
    main: 'Bree Serif',
    secondary: 'Open Sans'
  },
  {
    main: 'Cantata One',
    secondary: 'Imprima'
  },
  {
    main: 'Crete Round',
    secondary: 'AbeeZee'
  },
  {
    main: 'Josefin Slab',
    secondary: 'Josefin Sans'
  },
  {
    main: 'Kreon',
    secondary: 'Ubuntu'
  },
  {
    main: 'Libre Baskerville',
    secondary: 'Open Sans'
  },
  {
    main: 'Lustria',
    secondary: 'Lato'
  },
  {
    main: 'Old Standard TT',
    secondary: 'Questrial'
  },
  {
    main: 'Ovo',
    secondary: 'Muli'
  },
  {
    main: 'Quattrocento',
    secondary: 'Quattrocento Sans'
  },
  {
    main: 'Rokkitt',
    secondary: 'Roboto'
  },
  {
    main: 'Rokkitt',
    secondary: 'Ubuntu'
  },
  {
    main: 'Rufina',
    secondary: 'Sintony'
  },
  {
    main: 'Trirong',
    secondary: 'Rubik'
  },
  {
    main: 'Spectral',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Vollkorn',
    secondary: 'Exo'
  },
  {
    main: 'Abel',
    secondary: 'Ubuntu'
  },
  {
    main: 'Amaranth',
    secondary: 'Titillium Web'
  },
  {
    main: 'Didact Gothic',
    secondary: 'Arimo'
  },
  {
    main: 'Dosis',
    secondary: 'Open Sans'
  },
  {
    main: 'Droid Sans',
    secondary: 'Cabin'
  },
  {
    main: 'Fira Sans',
    secondary: 'Montserrat'
  },
  {
    main: 'Fjalla One',
    secondary: 'Cantarell'
  },
  {
    main: 'Francois One',
    secondary: 'Didact Gothic'
  },
  {
    main: 'Francois One',
    secondary: 'Lato'
  },
  {
    main: 'Francois One',
    secondary: 'Open Sans'
  },
  {
    main: 'Hind',
    secondary: 'Open Sans'
  },
  {
    main: 'Karla',
    secondary: 'Montserrat'
  },
  {
    main: 'Karla',
    secondary: 'Lato'
  },
  {
    main: 'Montserrat',
    secondary: 'Hind'
  },
  {
    main: 'Montserrat',
    secondary: 'Istok Web'
  },
  {
    main: 'Nunito Sans',
    secondary: 'Nunito'
  },
  {
    main: 'Open Sans',
    secondary: 'Oswald'
  },
  {
    main: 'Oswald',
    secondary: 'Droid Sans'
  },
  {
    main: 'Oxygen',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Philosopher',
    secondary: 'Muli'
  },
  {
    main: 'Poppins',
    secondary: 'Open Sans'
  },
  {
    main: 'PT Sans',
    secondary: 'Cabin'
  },
  {
    main: 'PT Sans',
    secondary: 'Didact Gothic'
  },
  {
    main: 'Raleway',
    secondary: 'Cabin'
  },
  {
    main: 'Signika',
    secondary: 'Open Sans'
  },
  {
    main: 'Ubuntu',
    secondary: 'Cabin'
  },
  {
    main: 'Ubuntu',
    secondary: 'Didact Gothic'
  },
  {
    main: 'Ubuntu',
    secondary: 'Hind'
  },
  {
    main: 'Ubuntu',
    secondary: 'Source Sans Pro'
  },
  {
    main: 'Walter Turncoat',
    secondary: 'Kreon'
  },
  {
    main: 'Yeseva One',
    secondary: 'Crimson Text'
  },
  {
    main: 'Abril Fatface',
    secondary: 'Droid Sans'
  },
  {
    main: 'Amatic SC',
    secondary: 'Andika'
  },
  {
    main: 'Bevan',
    secondary: 'Pontano Sans'
  },
  {
    main: 'Flamenco',
    secondary: 'Asap'
  },
  {
    main: 'Lobster',
    secondary: 'Cabin'
  },
  {
    main: 'Medula One',
    secondary: 'Lato'
  },
  {
    main: 'Rancho',
    secondary: 'Gudea'
  },
  {
    main: 'Squada One',
    secondary: 'Allerta'
  },
  {
    main: 'Stint Ultra',
    secondary: 'Pontano Sans'
  },
  {
    main: 'Yeseva One',
    secondary: 'Josefin Sans'
  },
  {
    main: 'Alfaslab One',
    secondary: 'Gentium Book'
  },
  {
    main: 'Clicker Script',
    secondary: 'EB Garamond'
  },
  {
    main: 'Dancing Script',
    secondary: 'Ledger'
  },
  {
    main: 'Dancing Script',
    secondary: 'EB Garamond'
  },
  {
    main: 'Nixie One',
    secondary: 'Ledger'
  },
  {
    main: 'Sacramento',
    secondary: 'Alice'
  },
  {
    main: 'Sansita One',
    secondary: 'Kameron'
  },
  {
    main: 'Unica One',
    secondary: 'Vollkorn'
  },
  {
    main: 'Bree Serif',
    secondary: 'Lora'
  },
  {
    main: 'Eczar',
    secondary: 'Gentium Basic'
  },
  {
    main: 'Playfair Display',
    secondary: 'Alice'
  },
  {
    main: 'Quattrocento',
    secondary: 'Fanwood Text'
  },
  {
    main: 'Ultra',
    secondary: 'Slabo 13px'
  },
  {
    main: 'Sans-Serif',
    secondary: 'Monospace'
  },
  {
    main: 'Poppins',
    secondary: 'Anonymous Pro'
  },
  {
    main: 'Karla',
    secondary: 'Inconsolata'
  },
  {
    main: 'Monospace',
    secondary: 'Sans-Serif'
  },
  {
    main: 'Inconsolata',
    secondary: 'Montserrat'
  },
  {
    main: 'Space Mono',
    secondary: 'Work Sans'
  },
  {
    main: 'Space Mono',
    secondary: 'Roboto'
  }
]

export const fontWeightConstants = {
  normal: 400,
  bold: 700,
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
  1000: 1000
}

export const fontWeightConstantsForFabric = {
  400: 'normal',
  700: 'bold',
  100: 100,
  200: 200,
  300: 300,
  500: 500,
  600: 600,
  800: 800,
  900: 900,
  1000: 1000
}

export const contentTypeConstants = {
  text: 'textbox',
  textbox: 'text',
  group: 'shapes',
  shapes: 'group',
  image: 'image',
  cropped: 'shapes',
  path: 'shapes',
  polygon: 'shapes',
  rect: 'shapes'
}

export const menuMakerContentTypeConstants = {
  text: 'textbox',
  textbox: 'text',
  group: 'shapes',
  shapes: 'group',
  image: 'image',
  cropped: 'shapes',
  path: 'shapes',
  polygon: 'shapes',
  rect: 'rect'
}

export const variantSetTypes = {
  variantSetName: 'variantSetName',
  variantSetDescription: 'variantSetDescription'
}

export const variantTypes = {
  variantName: 'variantName',
  variantDescription: 'variantDescription',
  variantPrice: 'variantPrice'
}

export const componentContentTypeConstants = {
  name: 'textbox',
  allergens: 'textbox',
  calories: 'textbox',
  description: 'textbox',
  nutrients: 'textbox',
  portionSize: 'textbox',
  price: 'textbox',
  photo: 'group',
  photoGallery: 'group',
  text: 'textbox',
  [variantSetTypes.variantSetName]: 'textbox',
  [variantSetTypes.variantSetDescription]: 'textbox',
  [variantTypes.variantName]: 'textbox',
  [variantTypes.variantDescription]: 'textbox',
  [variantTypes.variantPrice]: 'textbox'
}

export const commonTypes = {
  name: 'name',
  allergens: 'allergens',
  calories: 'calories',
  description: 'description',
  nutrients: 'nutrients',
  portionSize: 'portionSize',
  price: 'price',
  photo: 'photo',
  photoGallery: 'photoGallery',
  text: 'text'
}

export const componentContentTypeConstantsv2 = Object.fromEntries(
  [
    ...Object.values(commonTypes),
    ...Object.values(variantSetTypes),
    ...Object.values(variantTypes)
  ].map(type => [type, 'group'])
)

export const componentPropertySections = {
  common: 'common',
  variantSet: 'variantSet'
}

export const componentPropertyTypes = {
  [commonTypes.name]: {
    label: 'Name',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 1
  },
  [commonTypes.allergens]: {
    label: 'Allergens',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 2
  },
  [commonTypes.calories]: {
    label: 'Calories',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 3
  },
  [commonTypes.description]: {
    label: 'Description',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 4
  },
  [commonTypes.nutrients]: {
    label: 'Nutrients',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 5
  },
  [commonTypes.portionSize]: {
    label: 'Portion Size',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 6
  },
  [commonTypes.price]: {
    label: 'Price',
    width: GRID_COLUMN_WIDTH - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 7
  },
  [commonTypes.photo]: {
    label: 'Main Image',
    width: GRID_COLUMN_WIDTH - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT * 3 - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 8
  },
  [commonTypes.photoGallery]: {
    label: 'Image Gallery',
    width: GRID_COLUMN_WIDTH - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT * 3 - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 11
  },
  [commonTypes.text]: {
    label: 'Text',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    section: componentPropertySections.common,
    displayOrder: 10
  },
  [variantSetTypes.variantSetName]: {
    label: 'Set Name',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    multiple: true,
    displayOrder: 9,
    section: componentPropertySections.common
  },
  [variantSetTypes.variantSetDescription]: {
    label: 'Set Description',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    multiple: true,
    displayOrder: 12,
    section: componentPropertySections.common
  },
  [variantTypes.variantName]: {
    label: 'Variant Name',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    multiple: true,
    displayOrder: 13,
    section: componentPropertySections.variantSet
  },
  [variantTypes.variantDescription]: {
    label: 'Variant Description',
    width: GRID_COLUMN_WIDTH * 2 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    multiple: true,
    displayOrder: 14,
    section: componentPropertySections.variantSet
  },
  [variantTypes.variantPrice]: {
    label: 'Variant Price',
    width: GRID_COLUMN_WIDTH * 1.5 - GRID_SPACING * 2,
    height: GRID_COLUMN_HEIGHT - GRID_SPACING * 2,
    multiple: true,
    displayOrder: 15,
    section: componentPropertySections.variantSet
  }
}

export const SC_DESIGN_LANDSCAPE_WIDTH = 1024
export const SC_DESIGN_PORTRAIT_WIDTH = 576

export const propertyTextBoxSaveFields = [
  'type',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'linethrough',
  'overline',
  'shadow',
  'text',
  'textAlign',
  'underline',
  'propertyType',
  'animation',
  'scaling',
  'overlay',
  'fontUppercase'
]

export const imageOrientations = {
  ALL: 'all',
  PORTRAIT: 'Portrait',
  LANDSCAPE: 'Landscape'
}

export const imageOrientationOptions = t => [
  { value: imageOrientations.ALL, label: t('All') },
  { value: imageOrientations.LANDSCAPE, label: t('Landscape') },
  { value: imageOrientations.PORTRAIT, label: t('Portrait') }
]

export const designOrientations = {
  PORTRAIT: 'Portrait',
  LANDSCAPE: 'Landscape'
}

export const designOrientationOptions = t => [
  { value: designOrientations.LANDSCAPE, label: t('Landscape') },
  { value: designOrientations.PORTRAIT, label: t('Portrait') }
]

export const giphyRatingOptions = [
  { value: 'y', label: 'Y' },
  { value: 'g', label: 'G' },
  { value: 'pg', label: 'PG' },
  { value: 'pg-13', label: 'PG-13' },
  { value: 'r', label: 'R' }
]

export const listStyleSymbols = ['\u25E6']

export const listTypes = {
  BULLET: 'bullet',
  NUMBER: 'number',
  NONE: 'none'
}

export const listStyles = {
  [listTypes.BULLET]: '\u25E6',
  [listTypes.NONE]: ''
}

export const DEFAULT_COMPONENT_SPACING = 2
export const PRESET_COMPONENT_DISTANCE = 20

export const DESCRIPTION_FIELD = 'description'
export const NAME_FIELD = 'name'
export const PRICE_FIELD = 'price'
export const COMPONENT_TEXT_FIELD = 'text'

export const IMAGE_GALLERY_FIELD = 'photoGallery'
export const imageTypes = ['photo', 'photoGallery']

export const COMPONENT_ZOOM = 0.6

export const GRID_BOX = 50

export const objectTypes = {
  image: 'image',
  textbox: 'textbox',
  group: 'group',
  activeSelection: 'activeSelection'
}

export const objectCornerKeys = [
  'br',
  'bl',
  'tr',
  'tl',
  'mt',
  'mr',
  'mb',
  'ml',
  'mtr'
]

export const replaceValueForBE = [
  {
    property: 'textAlign',
    feValue: 'justify',
    beValue: 'justified'
  }
]

export const canvasFontVariantsToLoad = [
  '100',
  '200',
  '300',
  'regular',
  '400',
  'italic',
  '400italic',
  '500',
  '600',
  '700',
  '700italic',
  '800',
  '900'
]

export const componentResizeModes = {
  clone: 'clone',
  stretch: 'stretch'
}

export const FRAME_ZERO_OPACITY = 0.001

export const fontVariantsMap = [
  { label: 'Regular', value: 'regular' },
  { label: 'Thin - 100', value: '100' },
  { label: 'Extra Light - 200', value: '200' },
  { label: 'Light - 300', value: '300' },
  { label: 'Medium - 500', value: '500' },
  { label: 'Semi Bold - 600', value: '600' },
  { label: 'Bold - 700', value: '700' },
  { label: 'Extra Bold - 800', value: '800' },
  { label: 'Black - 900', value: '900' }
]
