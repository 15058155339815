import React, { memo } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import { withStyles } from '@material-ui/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import Tooltip from 'components/Tooltip'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  inactiveIcon: {
    marginLeft: '-21px',
    zIndex: 1,
    width: 40,
    height: 40,
    color: 'red'
  }
})

const WrongNodeType = ({ classes, data: { tooltipText } = {} }) => {
  return (
    <div className={classes.root}>
      <Tooltip title={tooltipText} placement="top" arrow>
        <CloseIcon className={classes.inactiveIcon}></CloseIcon>
      </Tooltip>
      <Handle type="target" position={Position.Left} />
    </div>
  )
}

export default withStyles(styles)(memo(WrongNodeType))
