import { errorHandler } from '../utils'
import api from './api'

const getMenuItems = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/menuItems`,
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuItem = async data => {
  try {
    await api({
      method: 'POST',
      url: '/menuMaker/menuItems',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteMenuItem = async id => {
  try {
    await api({
      method: 'DELETE',
      url: `/menuMaker/menuItems/${id}`
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuItem = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/menuItems/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putMenuItem = async (id, data) => {
  try {
    await api({
      method: 'PUT',
      url: `/menuMaker/menuItems/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuItemBulk = async data => {
  try {
    await api({
      method: 'POST',
      url: `/menuMaker/menuItems/bulk`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getMenuLocations = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/locations`,
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuLocation = async data => {
  try {
    await api({
      method: 'POST',
      url: '/menuMaker/locations',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteMenuLocation = async (id, data) => {
  try {
    await api({
      method: 'DELETE',
      url: `/menuMaker/locations/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuLocation = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/locations/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putMenuLocation = async (id, data) => {
  try {
    await api({
      method: 'PUT',
      url: `/menuMaker/locations/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuStations = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/stations`,
      params
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuStation = async data => {
  try {
    await api({
      method: 'POST',
      url: '/menuMaker/stations',
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteMenuStation = async id => {
  try {
    await api({
      method: 'DELETE',
      url: `/menuMaker/stations/${id}`
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuStation = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/stations/${id}`
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putMenuStation = async (id, data) => {
  try {
    await api({
      method: 'PUT',
      url: `/menuMaker/stations/${id}`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuIntegrations = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/integrations`
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getMenuMakerProviders = async () => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/menuMaker/providers`
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuIntegrationConnect = async data => {
  try {
    await api({
      method: 'POST',
      url: `/menuMaker/integrationConnect`,
      data
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuIntegrationDisconnect = async id => {
  try {
    await api({
      method: 'POST',
      url: `/menuMaker/integrationDisconnect/${id}`
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuIntegrationSync = async providerId => {
  try {
    await api({
      method: 'POST',
      url: `/menuMaker/integrationSync/${providerId}`
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

const postMenuMakerItemBulk = async ({ menuItemIds, data }) => {
  try {
    await api({
      method: 'POST',
      url: 'menuMaker/menuItems/bulkAssignTags',
      data: {
        menuItemIds,
        ...data
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const menuMakerService = {
  getMenuItems,
  getMenuItem,
  putMenuItem,
  deleteMenuItem,
  postMenuItem,
  postMenuItemBulk,
  getMenuLocations,
  getMenuLocation,
  postMenuLocation,
  putMenuLocation,
  deleteMenuLocation,
  getMenuStations,
  getMenuStation,
  postMenuStation,
  putMenuStation,
  deleteMenuStation,
  postMenuIntegrationConnect,
  postMenuIntegrationDisconnect,
  postMenuIntegrationSync,
  getMenuIntegrations,
  getMenuMakerProviders,
  postMenuMakerItemBulk
}

export default menuMakerService
