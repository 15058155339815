import React, { useMemo } from 'react'
import { Table, TableBody, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { TableLibraryHead } from 'components/TableLibrary'
import Scrollbars from 'components/Scrollbars'
import MessageCard from 'components/Card/MessageCard'
import NotificationSimpleTableRow from 'components/CommonRows/NotificationSimpleTableRow'

const styles = ({ type, colors }) => ({
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  }
})

const initialColumns = [
  { id: 'name', label: 'Name' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'createdBy', label: 'Created By', align: 'center' }
]

const columnWidth = {
  name: 200,
  status: 200,
  createdBy: 200
}

const DeleteEmailTemplateConflictContent = ({ error, classes }) => {
  const { t } = useTranslation()

  const notifications = useMemo(
    () => error?.data?.DynamicEmailNotifications || [],
    [error]
  )

  return (
    <Scrollbars autoHeight autoHeightMax="calc(100vh - 190px)">
      <div>
        {error.innerMessage && (
          <MessageCard
            icon="fa-regular fa-circle-info"
            message={t('The following notifications will be deleted')}
            marginBottom
          />
        )}
        <Table className={classes.table}>
          <TableLibraryHead
            noType={true}
            editRows={false}
            disableSort
            rowCount={1}
            columns={initialColumns}
            columnWidth={columnWidth}
            headerCellClassName={classes.tableHeaderCell}
          />
          <TableBody>
            {notifications.map(notification => (
              <NotificationSimpleTableRow
                key={notification.id}
                data={notification}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </Scrollbars>
  )
}

export default withStyles(styles)(DeleteEmailTemplateConflictContent)
