import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useUserDetails from 'hooks/useUserDetails'
import { checkScheduleAvailability } from 'actions/scheduleActions'
import { scheduleAssignAvailabilitySelector } from 'selectors/scheduleSelectors'
import { ORG_ROLE } from 'constants/api'
import { CLIENT_ADMIN } from 'constants/roles'
import usePermissions from 'hooks/api/usePermissions'
import { permissionNames } from 'constants/index'

export default function useAddToAvailability() {
  const dispatch = useDispatch()
  const userDetails = useUserDetails()
  const { getPermissionByName } = usePermissions()

  const schedulePermissions = useMemo(
    () => ({
      read: getPermissionByName(permissionNames.CLIENT_SCHEDULE_SHOW),
      update: getPermissionByName(
        permissionNames.ORG_DEVICE_SCHEDULE_UPDATE,
        permissionNames.SYSTEM_DEVICE_SCHEDULE_UPDATE,
        permissionNames.ENTERPRISE_DEVICE_SCHEDULE_UPDATE
      )
    }),
    [getPermissionByName]
  )

  const schedulesAvailability = useSelector(scheduleAssignAvailabilitySelector)

  const [hasSchedules, setHasSchedules] = useState(false)

  const hasSchedulePermissions = useMemo(
    () => schedulePermissions.read && schedulePermissions.update,
    [schedulePermissions]
  )

  const addToSectionAvailable = useMemo(() => {
    const {
      role: { name, level }
    } = userDetails
    return (
      hasSchedulePermissions && (level !== ORG_ROLE || name === CLIENT_ADMIN)
    )
  }, [userDetails, hasSchedulePermissions])

  useEffect(() => {
    if (!schedulesAvailability.isAvailable && schedulePermissions.read) {
      dispatch(checkScheduleAvailability())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!hasSchedules && schedulesAvailability.isAvailable) {
      setHasSchedules(true)
    }
    // eslint-disable-next-line
  }, [schedulesAvailability])

  return useMemo(
    () => ({
      hasSchedulePermissions,
      hasSchedules,
      addToSectionAvailable
    }),
    [hasSchedulePermissions, hasSchedules, addToSectionAvailable]
  )
}
