import {
  CLEAR_CLIENT_STORE,
  CLIENT_SETTINGS_PENDING,
  CLIENT_SETTINGS_SUCCESS
} from 'actions/new/client'

const initialState = {
  settings: {
    data: {
      id: null,
      name: '',
      type: {
        id: null,
        level: '',
        title: '',
        alias: '',
        color: null
      },
      address1: '',
      address2: null,
      city: '',
      state: '',
      country: '',
      zipCode: '',
      geolocation: {
        lat: null,
        long: null
      },
      phoneNo1: '',
      phoneNo2: '',
      featurePackage: {
        id: null,
        title: '',
        bandwidth: ''
      },
      feature: [],
      defaultScreen: {
        allowSubstituteImage: false,
        isDisplayBlackScreen: false,
        imageId: null,
        imageTitle: '',
        imageSize: ''
      },
      playbackReporting: false,
      emergencyNotification: false,
      is2faEnabled: false,
      autoLogout: false,
      autoLogoutTime: null,
      expirePasswordAfter: null,
      expirationDate: null,
      sso: {
        status: false,
        ssoSetting: {
          entityId: '',
          singleLogoutService: '',
          singleSignOnService: '',
          x509cert: ''
        },
        loginThrough: 'Both',
        url: '',
        type: 'web',
        ssoMetaUrls: {
          metadata: '',
          acs: '',
          sls: ''
        }
      },
      isUniqueClient: false,
      uniqueClientDescription: null,
      unlimitedBandwidth: false,
      bandwidthDetail: {
        total: '',
        lastAllocated: '',
        used: '',
        remaining: '',
        renewalDate: ''
      },
      status: '',
      createdBy: {
        id: null,
        firstName: '',
        lastName: '',
        status: ''
      },
      updatedBy: {
        id: null,
        firstName: '',
        lastName: '',
        status: ''
      },
      createdAt: '',
      updatedAt: ''
    },
    isPending: false,
    isSuccess: false,
    isFailure: false
  }
}

export default function clientReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENT_SETTINGS_PENDING:
      return {
        ...state,
        settings: {
          ...state.settings,
          isPending: true
        }
      }
    case CLIENT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isPending: false,
          isSuccess: true,
          data: {
            ...state.settings.data,
            ...action.data
          }
        }
      }
    case CLEAR_CLIENT_STORE:
      return initialState
    default:
      return state
  }
}
