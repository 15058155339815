import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import DefaultModal from './DefaultModal'
import { requiredField } from 'constants/validationMessages'
import { FormControlReactSelect } from '../Form'
import { selectUtils } from 'utils'
import { tagCustomStyles } from 'constants/stylesConstants'
import { postMediaBulk, clearPostMediaBulkInfo } from 'actions/mediaActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNotifyAnalyzer } from '../../hooks/tableLibrary'
import { useSnackbar } from 'notistack'
import ConflictsModal from './ConflictsModal'
import {
  conflictForceTypes,
  conflictSources,
  conflictTypes
} from '../../constants/api'

const fieldNames = {
  tags: 'tags'
}

const AssignAutoTagModal = ({
  open,
  onClose,
  autoTags = [],
  item,
  itemsFetcher
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const postBulkReducer = useSelector(store => store.media.postBulk)

  const handleFormSubmit = ({ tags, ...rest }) => {
    dispatch(
      postMediaBulk({
        mediaIds: item?.id,
        data: {
          tag: tags.map(({ value }) => ({
            title: value,
            isAutoTag: true
          })),
          ...rest
        }
      })
    )
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: {
      [fieldNames.tags]: []
    },
    validationSchema: Yup.array().min(1, t(requiredField)),
    onSubmit: handleFormSubmit
  })

  const handleConflictConfirmation = ({ conflictType }) => {
    if (conflictType === conflictTypes.multipleScheduleAssociation) {
      setFieldValue(conflictForceTypes.forceAssociateConflict, true)
      handleSubmit()
    }
  }

  useEffect(() => {
    setFieldValue(
      fieldNames.tags,
      selectUtils.convertArr(autoTags, selectUtils.autoTagToChipObj)
    )
    // eslint-disable-next-line
  }, [autoTags])

  useNotifyAnalyzer(
    itemsFetcher,
    () => dispatch(clearPostMediaBulkInfo()),
    enqueueSnackbar,
    closeSnackbar,
    'Tag',
    [postBulkReducer]
  )

  const buttonPrimaryText =
    autoTags.length && autoTags.length === values[fieldNames.tags].length
      ? t('Apply All')
      : t('Apply Selected')

  const options = selectUtils.convertArr(autoTags, selectUtils.autoTagToChipObj)

  return (
    <>
      <DefaultModal
        modalTitle={t('Apply AI-generated Tags')}
        hasCancelBtn={false}
        open={open}
        buttonPrimaryText={buttonPrimaryText}
        onCloseModal={onClose}
        overflowVisible
        onClickSave={handleSubmit}
      >
        <FormControlReactSelect
          name={fieldNames.tags}
          error={errors[fieldNames.tags]}
          touched={touched[fieldNames.tags]}
          value={values[fieldNames.tags]}
          options={options}
          onChange={handleChange}
          isMulti
          styles={tagCustomStyles}
          marginBottom={false}
        />
      </DefaultModal>
      <ConflictsModal
        errors={[postBulkReducer.error]}
        conflictSource={conflictSources.bulkTagsAssign}
        onConfirm={handleConflictConfirmation}
      />
    </>
  )
}

export default AssignAutoTagModal
