import api from './api'

import { errorHandler, queryParamsHelper } from '../utils'
import { getApiParamsByOriginalUser } from 'utils/localStorage'
import { isVueV3Preview } from 'utils/apiUtils'

const postTemplate = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/template`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getTemplate = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/template/${id}`
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const editTemplate = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/template/${id}`,
      data
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getItems = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/template',
      params: queryParamsHelper(
        params,
        [
          'group',
          'tag',
          'mediaFeatureNames',
          'updatedAt-to',
          'updatedAt-from',
          'status'
        ],
        [],
        ['templateType']
      )
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getPreference = async () => {
  try {
    const response = await api({
      method: 'GET',
      url: '/preference',
      params: {
        entity: 'TemplateLibrary'
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putPreference = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/preference/TemplateLibrary',
      data: {
        recordsPerPage: 10,
        gridColumn: data
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getGroupItems = async (id, params) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/template/group/${id}`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postGroupItem = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/template/group/${data.groupId}`,
      data: {
        templateId: data.templateId
      }
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteGroupItem = async data => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/template/group/${data.groupId}/${data.templateId}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const cloneTemplate = async data => {
  try {
    await api({
      method: 'POST',
      url: `/template/clone`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const deleteTemplateById = async ({ id, data }) => {
  try {
    await api({
      method: 'DELETE',
      url: `/template/${id}`,
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const deleteSelectedTemplate = async (ids, data) => {
  try {
    await api({
      method: 'DELETE',
      url: `/template/bulk`,
      params: {
        ids: ids.join(',')
      },
      data
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getPreview = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/template/preview/${id}`,
      params: {
        ...(isVueV3Preview() && { vue3: 1 })
      },
      responseType: 'text'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const generatePreview = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/template/preview`,
      params: {
        ...(isVueV3Preview() && { vue3: 1 })
      },
      data,
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const shareTemplate = async data => {
  const { id, ...rest } = data
  try {
    await api({
      method: 'POST',
      url: `/template/${id}/share`,
      data: rest
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const copyTemplate = async data => {
  const { id, impersonated, ...rest } = data
  try {
    await api({
      method: 'POST',
      url: `/template/${id}/copy`,
      data: rest,
      ...(impersonated && getApiParamsByOriginalUser(`/template/${id}/copy`))
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getTemplateBackgrounds = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: `/template/backgroundPattern`,
      params
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getTemplateBackground = async id => {
  try {
    const response = await api({
      method: 'GET',
      url: `/template/backgroundPattern/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postTemplateBackground = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: `/template/backgroundPattern`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const putTemplateBackground = async ({ id, data }) => {
  try {
    const response = await api({
      method: 'POST',
      url: `/template/backgroundPattern/${id}`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getItemPreview = async ({ id, type, isZoomToFit, muteAudio }) => {
  try {
    const previewType = type === 'playlist' ? 'playlist' : 'media'

    const response = await api({
      method: 'GET',
      url: `/${previewType}/preview/${id}`,
      responseType: 'text',
      params: {
        ...(isZoomToFit === 1 && { isZoomToFit }),
        ...(muteAudio && { isEditTemplateZone: 1 }),
        ...(isVueV3Preview() && { vue3: 1 })
      }
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const generateItemPreview = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/templateZone/preview',
      params: {
        ...(isVueV3Preview() && { vue3: 1 })
      },
      data: { isEditTemplateZone: 1, ...data },
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getTemplateZonePreview = async ({ id }) => {
  try {
    const response = await api({
      method: 'GET',
      url: `/templateZone/preview/${id}`,
      params: {
        ...(isVueV3Preview() && { vue3: 1 })
      },
      responseType: 'text'
    })
    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getTemplateAssociations = async (id, params) => {
  try {
    return await api({
      method: 'GET',
      url: `/template/${id}/association`,
      params
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const getTemplateGalleryItems = async params => {
  try {
    const { data } = await api({
      method: 'GET',
      url: '/templateGallery',
      params: queryParamsHelper(params, [
        'group',
        'tag',
        'mediaFeatureNames',
        'updatedAt-to',
        'updatedAt-from'
      ])
    })

    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getTemplateGalleryById = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/templateGallery/${id}`
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const getTemplateGalleryPreview = async id => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `/templateGallery/preview/${id}`,
      params: {
        ...(isVueV3Preview() && { vue3: 1 })
      },
      responseType: 'text'
    })
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

const copyTemplateGallery = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/templateGallery/copy`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const bulkTemplateGalleryCopy = async data => {
  try {
    const response = await api({
      method: 'PUT',
      url: `/templateGallery/copy/bulk`,
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const postTemplateBulk = async ({ templateIds, data }) => {
  try {
    await api({
      method: 'POST',
      url: `/template/bulkAssignGroupsAndTags`,
      data: {
        templateIds,
        ...data
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const templateService = {
  postTemplate,
  getTemplate,
  editTemplate,
  getItems,
  getPreference,
  putPreference,
  getGroupItems,
  postGroupItem,
  deleteGroupItem,
  cloneTemplate,
  deleteSelectedTemplate,
  deleteTemplateById,
  getPreview,
  generatePreview,
  shareTemplate,
  copyTemplate,
  getTemplateBackgrounds,
  getTemplateBackground,
  postTemplateBackground,
  putTemplateBackground,
  getItemPreview,
  generateItemPreview,
  getTemplateAssociations,
  getTemplateGalleryItems,
  getTemplateGalleryById,
  getTemplateGalleryPreview,
  copyTemplateGallery,
  bulkTemplateGalleryCopy,
  getTemplateZonePreview,
  postTemplateBulk
}

export default templateService
