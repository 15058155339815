import React, { useCallback, useMemo } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'

import { CircleIconButton } from 'components/Buttons'
import Spacing from 'components/Containers/Spacing'
import Container from 'components/Containers/Container'
import { FormControlInput, FormControlReactSelect } from 'components/Form'
import { ruleOptions } from 'utils/deviceNocUtils'
import { camelCaseToSplitCapitalize } from 'utils'
import { alertAlarmColumnTypes, booleanOptions } from 'constants/deviceNoc'

const styles = ({ palette, type, typography }) => ({
  btnBox: {
    position: 'absolute',
    top: 0,
    right: 7
  },
  rulesWrapper: {
    position: 'relative',
    backgroundColor: palette[type].alertAlarmCard.background,
    borderRadius: 10,
    padding: '12px 10px 4px 10px'
  },
  conditionWrapper: {
    fontWeight: 'bold',
    color: palette[type].alertAlarmCard.color,
    padding: '15px 0 15px 20px',

    '&>span': {
      cursor: 'pointer'
    }
  },
  activeCondition: {
    ...typography.darkAccent[type]
  },
  alarmSettingsConfigWrap: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  componentWrapper: {
    padding: 5,
    width: '100%'
  },
  halfWidth: {
    width: '50%'
  },
  circleButton: {
    color: palette[type].alertAlarmCard.color,
    padding: 7,
    margin: 5,
    maxWidth: 35,
    maxHeight: 35,
    fontSize: 17,

    '&:hover, &.active': {
      color: palette[type].alertAlarmCard.hoverColor
    }
  }
})

const AlertRule = ({
  condition,
  rule,
  onChangeCondition = f => f,
  onChangeRule = f => f,
  onRemove = f => f,
  index,
  errors,
  touched,
  conditionLength = 0,
  columns = [],
  isLoading = false,
  classes,
  t
}) => {
  const handleChangeRule = useCallback(
    _rule => () => {
      onChangeRule(_rule)
    },
    [onChangeRule]
  )

  const columnOptions = useMemo(() => {
    return columns.map(({ name }) => ({
      label: camelCaseToSplitCapitalize(name),
      value: name
    }))
  }, [columns])

  const selectedColumn = useMemo(() => {
    return (
      condition.columnName &&
      columns.find(({ name }) => name === condition.columnName)
    )
  }, [columns, condition.columnName])

  const operatorsOptions = useMemo(() => {
    if (!selectedColumn) {
      return []
    }

    return selectedColumn.operators.map(opt => ({
      label: opt,
      value: opt
    }))
  }, [selectedColumn])

  const renderValueField = useMemo(() => {
    const { type, value } = selectedColumn || {}

    const valueOptions = value
      ? value.map(v => ({
          label: v,
          value: v
        }))
      : []
    if (type === alertAlarmColumnTypes.int) {
      return (
        <FormControlInput
          type="number"
          name="value"
          label={t('Value')}
          value={condition.value}
          handleChange={onChangeCondition}
          error={errors.value}
          touched={touched.value}
          custom
          formikMode
          fullWidth
          marginBottom={false}
        />
      )
    } else if (type === alertAlarmColumnTypes.boolean) {
      return (
        <FormControlReactSelect
          name="value"
          label={t('Value')}
          value={condition.value}
          options={booleanOptions}
          handleChange={onChangeCondition}
          error={errors.value}
          touched={touched.value}
        />
      )
    } else if (valueOptions?.length) {
      return (
        <FormControlReactSelect
          name="value"
          label={t('Value')}
          value={condition.value}
          options={valueOptions}
          handleChange={onChangeCondition}
          error={errors.value}
          touched={touched.value}
        />
      )
    } else {
      return (
        <FormControlInput
          name="value"
          label={t('Value')}
          value={condition.value}
          handleChange={onChangeCondition}
          error={errors.value}
          touched={touched.value}
          marginBottom={false}
        />
      )
    }
  }, [
    selectedColumn,
    condition.value,
    t,
    errors.value,
    onChangeCondition,
    touched.value
  ])

  return (
    <>
      {index > 0 && (
        <div>
          <Typography className={classes.conditionWrapper}>
            {ruleOptions(t).map(({ name, label }, ruleIndex) => (
              <React.Fragment key={`alert-rule-${ruleIndex}`}>
                <span
                  className={classNames({
                    [classes.activeCondition]: name === rule
                  })}
                  onClick={handleChangeRule(name)}
                >
                  {label}
                </span>
                {ruleIndex < ruleOptions(t).length - 1 && <span> | </span>}
              </React.Fragment>
            ))}
          </Typography>
        </div>
      )}
      <div
        className={classes.rulesWrapper}
        style={{ borderLeft: `10px solid ${condition.color}` }}
      >
        <div className={classes.alarmSettingsConfigWrap}>
          <Spacing>
            <Container cols="1">
              <FormControlReactSelect
                name="columnName"
                label={t('Performance Metric')}
                value={condition.columnName}
                onChange={onChangeCondition}
                options={columnOptions}
                error={errors.columnName}
                touched={touched.columnName}
                marginBottom={false}
                isLoading={isLoading}
              />
              <Container>
                <FormControlReactSelect
                  name="operator"
                  label={t('Operator')}
                  value={condition.operator}
                  onChange={onChangeCondition}
                  options={operatorsOptions}
                  error={errors.operator}
                  touched={touched.operator}
                  marginBottom={false}
                />
                {renderValueField}
              </Container>
            </Container>
          </Spacing>
        </div>

        <div className={classes.btnBox}>
          {conditionLength > 1 && (
            <CircleIconButton
              className={classes.circleButton}
              onClick={onRemove}
            >
              <i className={'fa-regular fa-trash-can'} />
            </CircleIconButton>
          )}
        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(AlertRule)
