import { createAppApi } from 'services/api'

export const featureApi = createAppApi('featureQuery', {
  endpoints: builder => ({
    getFeatureAccounts: builder.query({
      query: params => ({ url: '/feature/accounts', params })
    })
  })
})

export const { useGetFeatureAccountsQuery } = featureApi
