import update from 'immutability-helper'

import * as types from '../actions'
import { PREVIEW_THUMBNAIL_SUCCESS } from 'actions/new/device'
import {
  shapeOfBodyWithMeta,
  shapeOfBody,
  fetchParamsInitialState,
  postInitialState,
  putInitialState
} from 'constants/initialLibraryState'
import sortConstants from 'constants/sortConstants'
import { deviceLogsType } from '../constants/deviceConstants'

const initialFetchParams = {
  ...fetchParamsInitialState,
  ...sortConstants.Device
}

const initialState = {
  library: {
    response: [],
    isFetching: true
  },
  meta: {
    currentPage: 1,
    from: 0,
    lastPage: 1,
    perPage: 10,
    to: 10,
    total: 0,
    count: 0
  },
  fetchParams: initialFetchParams,
  preference: {},
  groups: {},
  groupDevices: shapeOfBodyWithMeta,
  locationGroupDevices: {
    response: {
      data: [],
      meta: {}
    }
  },
  put: {},
  postItem: {},
  item: {},
  delete: { label: 'delete' },
  preview: {
    id: null,
    isVisible: false,
    isLoading: false,
    response: null,
    error: null
  },
  locationCategories: {
    response: [],
    error: null
  },
  groupItems: {},
  postGroupItem: {},
  deleteGroupItem: {},
  reboot: {},
  putReboot: {
    ...putInitialState,
    isLoading: false
  },
  screenPower: {},
  putScreenPower: {},
  powerSchedule: {},
  putPowerSchedule: {},
  note: {
    isLoading: false
  },
  postNote: {},
  networkConfig: {
    isLoading: false
  },
  putNetworkConfig: {},
  capAlertDevices: {
    isFetching: false,
    items: [],
    error: null
  },
  deviceTypes: shapeOfBodyWithMeta,
  deviceType: shapeOfBody,
  deviceModels: shapeOfBodyWithMeta,
  deviceModel: shapeOfBody,
  deviceModelPost: {},
  deviceModelPut: {},
  post: {},
  postBulk: postInitialState,
  deviceHealth: {},
  virtualPlayerStats: {
    response: [],
    error: null,
    isLoading: false
  },
  devicesOfflineNotification: {
    items: {
      ...shapeOfBodyWithMeta
    },
    post: {
      ...putInitialState,
      isLoading: false
    }
  },
  appVersions: shapeOfBodyWithMeta,
  releaseAppVersions: shapeOfBodyWithMeta,
  appVersion: shapeOfBody,
  postAppVersion: {},
  putAppVersion: {},
  deleteAppVersion: {},
  updateAvailable: {},
  postAssignAppVersion: {},
  clone: {},
  updateScheduleDevice: putInitialState,
  resolution: {},
  deviceLocations: {
    response: [],
    isFetching: true
  },
  postDeviceLocation: {},
  putDeviceLocation: {},
  deleteDeviceLocation: {},
  scheduledDeviceIds: {
    ...shapeOfBodyWithMeta,
    isFetching: false
  },
  deviceLogs: {
    [deviceLogsType.application]: {
      ...shapeOfBodyWithMeta,
      isLoading: false
    },
    [deviceLogsType.playbackNode]: {
      ...shapeOfBodyWithMeta,
      isLoading: false
    },
    [deviceLogsType.playbackRender]: {
      ...shapeOfBodyWithMeta,
      isLoading: false
    }
  },
  cecSettings: {
    ...shapeOfBody,
    isLoading: false
  },
  putCecSettings: {
    ...putInitialState,
    isLoading: false
  },
  postIpCIDR: { ...shapeOfBody, label: 'create', isLoading: false },
  putIpCIDR: { ...shapeOfBody, label: 'update', isLoading: false },
  deleteIpCIDR: { ...shapeOfBody, label: 'delete', isLoading: false },
  ipCIDRItems: {
    ...shapeOfBodyWithMeta,
    isLoading: false
  },
  dependencyPackages: shapeOfBody,
  config: { ...shapeOfBody, isLoading: false },
  putConfig: {
    ...putInitialState,
    isLoading: false
  },
  proofOfPlayLibrary: {
    ...shapeOfBodyWithMeta,
    isFetching: true,
    post: {
      status: '',
      error: null
    }
  }
}

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEVICE_RESOLUTION_SUCCESS:
      return update(state, {
        resolution: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_RESOLUTION_ERROR:
      return update(state, {
        resolution: {
          error: { $set: action.payload }
        }
      })
    case types.GET_DEVICES_BY_LOCATION:
    case types.GET_DEVICE_ITEMS:
      return update(state, {
        library: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_DEVICE_ITEMS_SUCCESS:
      return update(state, {
        library: {
          response: { $set: action.payload.response.data },
          isFetching: { $set: false }
        },
        meta: { $set: action.payload.response.meta },
        fetchParams: { $set: action.payload.params }
      })
    case PREVIEW_THUMBNAIL_SUCCESS: {
      const { id, lastUpdate } = action.payload

      const deviceIndex = state.library.response.findIndex(
        device => device.id === id
      )

      if (deviceIndex !== -1) {
        return update(state, {
          library: {
            response: {
              [deviceIndex]: { lastThumbnailUpdated: { $set: lastUpdate } }
            }
          }
        })
      }

      return state
    }
    case types.GET_DEVICES_BY_LOCATION_SUCCESS:
      return update(state, {
        library: {
          response: { $set: action.payload.response.data },
          isFetching: { $set: false }
        },
        meta: { $set: action.payload.response.meta },
        fetchParams: { $set: action.payload.params },
        locationGroupDevices: {
          response: {
            $set: {
              data: action.payload.response.data,
              meta: action.payload.response.meta
            }
          }
        }
      })
    case types.GET_DEVICES_BY_LOCATION_ERROR:
    case types.GET_DEVICE_ITEMS_ERROR:
      return update(state, {
        library: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_DEVICE_FETCH_PARAMS:
      return update(state, {
        fetchParams: {
          $set: initialFetchParams
        }
      })

    case types.CLEAR_GET_DEVICE_ITEMS:
      return update(state, {
        library: {
          response: { $set: [] },
          isFetching: { $set: true }
        },
        meta: { $set: {} },
        locationGroupDevices: {
          $set: {
            response: {
              data: [],
              meta: {}
            }
          }
        }
      })
    case types.GET_DEVICE_PREFERENCE_SUCCESS:
      return update(state, {
        preference: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_PREFERENCE_ERROR:
      return update(state, {
        preference: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_DEVICE_PREFERENCE_ERROR:
      return update(state, {
        performance: {
          error: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_GROUPS:
      return update(state, {
        groups: {
          isFetching: { $set: true },
          error: { $set: {} }
        }
      })
    case types.GET_DEVICE_GROUPS_SUCCESS:
      return update(state, {
        groups: {
          isFetching: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_GROUPS_ERROR:
      return update(state, {
        groups: {
          isFetching: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_GROUPS_INFO:
      return update(state, {
        groups: { $set: {} }
      })
    case types.PUT_DEVICE_ITEM_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_DEVICE_ITEM_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_DEVICE_ITEM_INFO:
      return update(state, {
        put: { $set: {} }
      })
    case types.POST_DEVICE_ITEM_SUCCESS:
      return update(state, {
        postItem: {
          response: { $set: 'successful' }
        }
      })
    case types.POST_DEVICE_ITEM_ERROR:
      return update(state, {
        postItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAT_POST_DEVICE_INFO:
      return update(state, {
        postItem: { $set: {} }
      })
    case types.GET_DEVICE_ITEM_SUCCESS:
      return update(state, {
        item: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_ITEM_ERROR:
      return update(state, {
        item: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_ITEM_INFO:
      return update(state, {
        item: { $set: {} }
      })
    case types.GET_DEVICE_PREVIEW:
      return update(state, {
        preview: {
          id: { $set: action.data },
          isVisible: { $set: true },
          isLoading: { $set: true },
          response: { $set: null },
          error: { $set: null }
        }
      })
    case types.GET_DEVICE_PREVIEW_SUCCESS:
      return update(state, {
        preview: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_PREVIEW_ERROR:
      return update(state, {
        preview: {
          isVisible: { $set: false },
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_LOCATION_CATEGORIES_SUCCESS:
      return update(state, {
        locationCategories: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_LOCATION_CATEGORIES_ERROR:
      return update(state, {
        locationCategories: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_LOCATION_CATEGORIES:
      return update(state, {
        locationCategories: {
          response: { $set: [] },
          error: { $set: null }
        }
      })
    case types.SHOW_DEVICE_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: true }
        }
      })
    case types.CLOSE_DEVICE_PREVIEW:
      return update(state, {
        preview: {
          isVisible: { $set: false }
        }
      })
    case types.CLEAR_GET_DEVICE_PREVIEW_INFO:
      return update(state, {
        preview: {
          $merge: {
            id: null,
            isVisible: false,
            isLoading: false,
            response: null,
            error: null
          }
        }
      })
    case types.GET_DEVICE_GROUP_ITEMS_SUCCESS:
      return update(state, {
        groupItems: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_GROUP_ITEMS_ERROR:
      return update(state, {
        groupItems: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_GROUP_ITEMS_INFO:
      return update(state, {
        groupItems: { $set: {} }
      })
    case types.POST_DEVICE_GROUP_ITEM_SUCCESS:
      return update(state, {
        postGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.POST_DEVICE_GROUP_ITEM_ERROR:
      return update(state, {
        postGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_DEVICE_GROUP_ITEM_INFO:
      return update(state, {
        postGroupItem: { $set: {} }
      })
    case types.DELETE_DEVICE_GROUP_ITEM_SUCCESS:
      return update(state, {
        deleteGroupItem: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_DEVICE_GROUP_ITEM_ERROR:
      return update(state, {
        deleteGroupItem: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DELETE_DEVICE_GROUP_ITEM_INFO:
      return update(state, {
        deleteGroupItem: { $set: {} }
      })
    case types.CLEAR_DEVICE_GROUP_ITEMS_RESPONSE_INFO:
      return update(state, {
        $merge: {
          groupItems: {},
          postGroupItem: {},
          deleteGroupItem: {}
        }
      })
    case types.GET_DEVICE_REBOOT_SUCCESS:
      return update(state, {
        reboot: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_REBOOT_ERROR:
      return update(state, {
        reboot: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_REBOOT_INFO:
      return update(state, {
        reboot: { $set: {} }
      })
    case types.PUT_DEVICE_REBOOT:
    case types.PUT_DEVICE_BULK_REBOOT:
      return update(state, {
        putReboot: {
          isLoading: { $set: true }
        }
      })
    case types.PUT_DEVICE_REBOOT_SUCCESS:
    case types.PUT_DEVICE_BULK_REBOOT_SUCCESS:
    case types.PUT_PERIODICAL_DEVICE_REBOOT_SUCCESS:
    case types.PUT_DEVICE_BULK_PERIODICAL_REBOOT_SUCCESS:
      return update(state, {
        putReboot: {
          response: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.PUT_DEVICE_REBOOT_ERROR:
    case types.PUT_DEVICE_BULK_REBOOT_ERROR:
    case types.PUT_PERIODICAL_DEVICE_REBOOT_ERROR:
    case types.PUT_DEVICE_BULK_PERIODICAL_REBOOT_ERROR:
      return update(state, {
        putReboot: {
          error: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.CLEAR_PUT_DEVICE_REBOOT_INFO:
      return update(state, {
        putReboot: { $set: initialState.putReboot }
      })
    case types.GET_SCREEN_POWER_SETTINGS_SUCCESS:
      return update(state, {
        screenPower: {
          response: { $set: action.payload }
        }
      })
    case types.GET_SCREEN_POWER_SETTINGS_ERROR:
      return update(state, {
        screenPower: {
          error: { $set: action.payload }
        }
      })
    case types.PUT_SCREEN_POWER_SETTINGS_SUCCESS:
      return update(state, {
        putScreenPower: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_SCREEN_POWER_SETTINGS_ERROR:
      return update(state, {
        putScreenPower: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_SCREEN_POWER_SETTINGS_INFO:
      return update(state, {
        putScreenPower: { $set: {} }
      })
    case types.GET_DEVICE_POWER_SCHEDULE_SUCCESS:
      return update(state, {
        powerSchedule: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_POWER_SCHEDULE_ERROR:
      return update(state, {
        powerSchedule: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_GET_DEVICE_POWER_SCHEDULE_INFO:
      return update(state, {
        powerSchedule: { $set: {} }
      })
    case types.PUT_DEVICE_POWER_SCHEDULE_SUCCESS:
      return update(state, {
        putPowerSchedule: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_DEVICE_POWER_SCHEDULE_ERROR:
      return update(state, {
        putPowerSchedule: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_DEVICE_POWER_SCHEDULE_INFO:
      return update(state, {
        putPowerSchedule: { $set: {} }
      })
    case types.GET_DEVICE_NOTES:
      return update(state, {
        note: {
          isLoading: { $set: true }
        }
      })
    case types.GET_DEVICE_NOTES_SUCCESS:
      return update(state, {
        note: {
          response: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.GET_DEVICE_NOTES_ERROR:
      return update(state, {
        note: {
          error: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.POST_DEVICE_NOTE_SUCCESS:
      return update(state, {
        postNote: {
          response: { $set: action.payload }
        }
      })
    case types.POST_DEVICE_NOTE_ERROR:
      return update(state, {
        postNote: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_DEVICE_NOTE:
      return update(state, {
        postNote: { $set: {} }
      })

    case types.REQUEST_CAP_ALERT_DEVICES:
      return update(state, {
        capAlertDevices: {
          $merge: {
            isFetching: true
          }
        }
      })
    case types.CAP_ALERT_DEVICES_SUCCESS:
      return update(state, {
        capAlertDevices: {
          $merge: {
            isFetching: false,
            items: action.payload,
            error: null
          }
        }
      })
    case types.CAP_ALERT_DEVICES_ERROR:
      return update(state, {
        capAlertDevices: {
          $merge: {
            isFetching: false,
            error: action.payload
          }
        }
      })
    case types.GET_DEVICE_TYPES:
      return update(state, {
        deviceTypes: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_DEVICE_TYPES_SUCCESS:
      return update(state, {
        deviceTypes: {
          response: { $set: action.payload.data },
          meta: { $set: action.payload.meta }
        }
      })
    case types.GET_DEVICE_TYPES_ERROR:
      return update(state, {
        deviceTypes: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.GET_DEVICE_TYPE_SUCCESS:
      if (action.appendToLibrary) {
        const response = state.deviceTypes.response.find(
          ({ id }) => id === action.payload.id
        )
          ? state.deviceTypes.response.map(item =>
              item.id === action.payload.id
                ? { ...item, ...action.payload }
                : item
            )
          : state.deviceTypes.response
        return update(state, {
          deviceTypes: {
            response: { $set: response }
          }
        })
      }
      return update(state, {
        deviceType: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_TYPE_ERROR:
      if (action.appendToLibrary) {
        return update(state, {
          deviceTypes: {
            error: { $set: action.payload }
          }
        })
      }
      return update(state, {
        deviceType: {
          error: { $set: action.payload }
        }
      })
    case types.POST_DEVICE_TYPE_SUCCESS:
      return update(state, {
        post: {
          response: { $set: 'successfully' }
        }
      })
    case types.PUT_DEVICE_TYPE_SUCCESS:
      return update(state, {
        put: {
          response: { $set: 'successfully' },
          label: { $set: 'update' }
        }
      })
    case types.POST_DEVICE_TYPE_ERROR:
      return update(state, {
        post: { error: { $set: action.payload } }
      })
    case types.PUT_DEVICE_TYPE_ERROR:
      return update(state, {
        put: { error: { $set: action.payload } }
      })
    case types.CLEAR_DEVICE_TYPE_INFO:
      return update(state, {
        put: { $set: {} },
        post: { $set: {} }
      })
    case types.GET_DEVICE_MODELS:
      return update(state, {
        deviceModels: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_DEVICE_MODELS_SUCCESS:
      return update(state, {
        deviceModels: {
          response: { $set: action.payload.data },
          meta: { $set: action.payload.meta }
        }
      })
    case types.GET_DEVICE_MODELS_ERROR:
      return update(state, {
        deviceModels: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.GET_DEVICE_MODEL_SUCCESS:
      if (action.appendToLibrary) {
        const response = state.deviceModels.response.find(
          ({ id }) => id === action.payload.id
        )
          ? state.deviceModels.response.map(item =>
              item.id === action.payload.id
                ? { ...item, ...action.payload }
                : item
            )
          : state.deviceModels.response
        return update(state, {
          deviceModels: {
            response: { $set: response }
          }
        })
      }
      return update(state, {
        deviceModel: {
          response: { $set: action.payload }
        }
      })
    case types.GET_DEVICE_MODEL_ERROR:
      if (action.appendToLibrary) {
        return update(state, {
          deviceModels: {
            error: { $set: action.payload }
          }
        })
      }
      return update(state, {
        deviceModel: {
          error: { $set: action.payload }
        }
      })
    case types.POST_DEVICE_MODEL_SUCCESS:
      return update(state, {
        deviceModelPost: {
          response: { $set: 'successfully' }
        }
      })
    case types.PUT_DEVICE_MODEL_SUCCESS:
      return update(state, {
        deviceModelPut: {
          response: { $set: 'successfully' },
          label: { $set: 'update' }
        }
      })
    case types.POST_DEVICE_MODEL_ERROR:
      return update(state, {
        deviceModelPost: { error: { $set: action.payload } }
      })
    case types.PUT_DEVICE_MODEL_ERROR:
      return update(state, {
        deviceModelPut: { error: { $set: action.payload } }
      })
    case types.CLEAR_DEVICE_MODEL_INFO:
      return update(state, {
        deviceModelPut: { $set: {} },
        deviceModelPost: { $set: {} }
      })
    case types.GET_APP_VERSIONS:
      return update(state, {
        appVersions: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_APP_VERSIONS_SUCCESS:
      return update(state, {
        appVersions: {
          response: { $set: action.payload.data },
          meta: { $set: action.payload.meta }
        }
      })
    case types.GET_APP_VERSIONS_ERROR:
      return update(state, {
        appVersions: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.GET_RELEASE_APP_VERSIONS:
      return update(state, {
        releaseAppVersions: {
          meta: {
            isLoading: { $set: true }
          }
        }
      })
    case types.GET_RELEASE_APP_VERSIONS_SUCCESS:
      return update(state, {
        releaseAppVersions: {
          response: { $set: action.payload.data },
          meta: { $set: action.payload.meta }
        }
      })
    case types.GET_RELEASE_APP_VERSIONS_ERROR:
      return update(state, {
        releaseAppVersions: {
          error: { $set: action.payload },
          meta: {
            isLoading: { $set: false }
          }
        }
      })
    case types.GET_APP_VERSION_SUCCESS:
      return update(state, {
        appVersion: {
          response: { $set: action.payload }
        }
      })
    case types.GET_APP_VERSION_ERROR:
      return update(state, {
        appVersion: {
          error: { $set: action.payload }
        }
      })
    case types.POST_APP_VERSION_SUCCESS:
      return update(state, {
        postAppVersion: {
          response: { $set: 'successfully' }
        }
      })
    case types.POST_APP_VERSION_ERROR:
      return update(state, {
        postAppVersion: { error: { $set: action.payload } }
      })
    case types.PUT_APP_VERSION_SUCCESS:
      return update(state, {
        putAppVersion: {
          response: { $set: 'successfully' },
          label: { $set: 'update' }
        }
      })
    case types.PUT_APP_VERSION_ERROR:
      return update(state, {
        putAppVersion: {
          error: { $set: action.payload },
          label: { $set: 'update' }
        }
      })
    case types.DELETE_APP_VERSION_SUCCESS:
      return update(state, {
        deleteAppVersion: {
          response: { $set: 'successfully' },
          label: { $set: 'delete' }
        }
      })
    case types.DELETE_APP_VERSION_ERROR:
      return update(state, {
        deleteAppVersion: {
          error: { $set: action.payload },
          label: { $set: 'delete' }
        }
      })
    case types.DELETE_SELECTED_APP_VERSIONS_SUCCESS:
      return update(state, {
        deleteAppVersion: {
          response: { $set: 'successfully' },
          label: { $set: 'delete' }
        }
      })
    case types.DELETE_SELECTED_APP_VERSIONS_ERROR:
      return update(state, {
        deleteAppVersion: {
          error: { $set: action.payload },
          label: { $set: 'delete' }
        }
      })
    case types.CLEAR_APP_VERSION:
      return update(state, {
        putAppVersion: { $set: {} },
        postAppVersion: { $set: {} },
        deleteAppVersion: { $set: {} }
      })
    case types.GET_DEVICE_UPDATES_AVAILABLE_SUCCESS:
      return update(state, {
        updateAvailable: { $set: action.payload }
      })
    case types.POST_ASSIGN_APP_VERSION_SUCCESS:
      return update(state, {
        postAssignAppVersion: {
          response: { $set: 'successfully' },
          error: {}
        }
      })
    case types.POST_ASSIGN_APP_VERSION_ERROR:
      return update(state, {
        postAssignAppVersion: {
          response: {},
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_ASSIGN_APP_VERSION:
      return update(state, {
        postAssignAppVersion: { $set: {} }
      })

    case types.GET_DEVICE_GROUP_DEVICES:
      return update(state, {
        groupDevices: {
          $set: initialState.groupDevices
        }
      })
    case types.GET_DEVICE_GROUP_DEVICES_SUCCESS:
      return update(state, {
        groupDevices: {
          $set: action.payload
        }
      })
    case types.GET_DEVICES_OFFLINE_NOTIFICATION:
      return update(state, {
        devicesOfflineNotification: {
          items: {
            response: { $set: [] },
            meta: {
              isLoading: { $set: true }
            }
          }
        }
      })
    case types.GET_DEVICES_OFFLINE_NOTIFICATION_SUCCESS:
      return update(state, {
        devicesOfflineNotification: {
          items: {
            response: { $set: action.payload.data },
            meta: { $set: action.payload.meta }
          }
        }
      })
    case types.GET_DEVICES_OFFLINE_NOTIFICATION_ERROR:
      return update(state, {
        devicesOfflineNotification: {
          items: {
            error: { $set: action.payload.error }
          }
        }
      })
    case types.POST_DEVICES_OFFLINE_NOTIFICATION:
      return update(state, {
        devicesOfflineNotification: {
          post: {
            isLoading: { $set: true }
          }
        }
      })
    case types.POST_DEVICES_OFFLINE_NOTIFICATION_SUCCESS:
      return update(state, {
        devicesOfflineNotification: {
          post: {
            response: { $set: action.payload },
            isLoading: { $set: false }
          }
        }
      })
    case types.POST_DEVICES_OFFLINE_NOTIFICATION_ERROR:
      return update(state, {
        devicesOfflineNotification: {
          post: {
            error: { $set: action.payload.error },
            isLoading: { $set: false }
          }
        }
      })
    case types.CLEAR_DEVICES_OFFLINE_NOTIFICATION:
      return update(state, {
        devicesOfflineNotification: {
          post: {
            $set: {
              ...putInitialState,
              isLoading: false
            }
          }
        }
      })
    case types.POST_DEVICE_BULK_SUCCESS:
      return update(state, {
        postBulk: {
          response: { $set: action.payload }
        }
      })
    case types.POST_DEVICE_BULK_ERROR:
      return update(state, {
        postBulk: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_POST_DEVICE_BULK_INFO:
      return update(state, {
        postBulk: {
          $set: postInitialState
        }
      })
    case types.DELETE_DEVICE_ITEM_SUCCESS:
      return update(state, {
        delete: {
          response: { $set: action.payload }
        }
      })
    case types.DELETE_DEVICE_ITEM_ERROR:
      return update(state, {
        delete: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DEVICE_ITEM_INFO:
      return update(state, {
        delete: {
          $set: { label: 'delete' }
        },
        postBulk: {
          $set: postInitialState
        }
      })
    case types.CLONE_DEVICE_ITEM_SUCCESS:
      return update(state, {
        clone: {
          response: { $set: action.payload }
        }
      })
    case types.CLONE_DEVICE_ITEM_ERROR:
      return update(state, {
        clone: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_CLONE_DEVICE_INFO:
      return update(state, {
        clone: { $set: {} }
      })
    case types.GET_DEVICE_LOCATIONS:
      return update(state, {
        deviceLocations: {
          isFetching: { $set: true }
        }
      })
    case types.GET_DEVICE_LOCATIONS_SUCCESS:
      return update(state, {
        deviceLocations: {
          $set: {
            response: action.payload,
            isFetching: false
          }
        }
      })
    case types.GET_DEVICE_LOCATIONS_ERROR:
      return update(state, {
        deviceLocations: {
          isFetching: { $set: false }
        }
      })
    case types.POST_DEVICE_LOCATION_SUCCESS:
    case types.POST_DEVICE_LOCATION_ERROR:
      return update(state, {
        postDeviceLocation: { $set: action.payload }
      })
    case types.PUT_DEVICE_LOCATION_SUCCESS:
    case types.PUT_DEVICE_LOCATION_ERROR:
      return update(state, {
        putDeviceLocation: { $set: action.payload }
      })
    case types.DELETE_DEVICE_LOCATION_SUCCESS:
    case types.DELETE_DEVICE_LOCATION_ERROR:
      return update(state, {
        deleteDeviceLocation: { $set: action.payload }
      })
    case types.CLEAR_DEVICE_LOCATION_INFO:
      return update(state, {
        postDeviceLocation: { $set: {} },
        putDeviceLocation: { $set: {} },
        deleteDeviceLocation: { $set: {} }
      })
    case types.UPDATE_SCHEDULE_DEVICE_SUCCESS:
      return update(state, {
        updateScheduleDevice: {
          response: { $set: action.payload }
        }
      })
    case types.UPDATE_SCHEDULE_DEVICE_ERROR:
      return update(state, {
        updateScheduleDevice: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_UPDATE_SCHEDULE_DEVICE_INFO:
      return update(state, {
        updateScheduleDevice: { $set: putInitialState }
      })
    case types.GET_SCHEDULED_DEVICE_IDS:
      return update(state, {
        scheduledDeviceIds: { isFetching: { $set: true }, error: { $set: {} } }
      })
    case types.GET_SCHEDULED_DEVICE_IDS_SUCCESS: {
      const meta = action.payload.meta

      const response =
        meta.page === 1 || !meta.page
          ? action.payload.response
          : [...state.scheduledDeviceIds.response, ...action.payload.response]

      return update(state, {
        scheduledDeviceIds: {
          response: { $set: response },
          meta: { $set: meta },
          isFetching: { $set: false }
        }
      })
    }
    case types.GET_DEVICE_LOGS:
      return update(state, {
        deviceLogs: {
          [action.meta.logType]: {
            isLoading: { $set: true },
            error: { $set: shapeOfBody.error }
          }
        }
      })
    case types.GET_DEVICE_LOGS_SUCCESS:
      return update(state, {
        deviceLogs: {
          [action.meta.logType]: {
            isLoading: { $set: false },
            response: { $set: action.payload.response },
            meta: { $set: action.payload.meta }
          }
        }
      })
    case types.GET_DEVICE_LOGS_ERROR:
      return update(state, {
        deviceLogs: {
          [action.meta.logType]: {
            isLoading: { $set: false },
            error: { $set: action.payload }
          }
        }
      })
    case types.CLEAR_DEVICE_LOGS:
      return update(state, {
        deviceLogs: { $set: initialState.deviceLogs }
      })
    case types.GET_DEVICE_CEC_SETTINGS:
      return update(state, {
        cecSettings: {
          isLoading: { $set: true },
          error: { $set: initialState.cecSettings.error }
        }
      })
    case types.GET_DEVICE_CEC_SETTINGS_SUCCESS:
      return update(state, {
        cecSettings: {
          isLoading: { $set: false },
          response: { $set: action.payload.response }
        }
      })
    case types.GET_DEVICE_CEC_SETTINGS_ERROR:
      return update(state, {
        cecSettings: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DEVICE_CEC_SETTINGS:
      return update(state, {
        cecSettings: { $set: initialState.cecSettings }
      })
    case types.PUT_DEVICE_CEC_SETTINGS:
      return update(state, {
        putCecSettings: {
          isLoading: { $set: true },
          error: { $set: initialState.cecSettings.error }
        }
      })
    case types.PUT_DEVICE_CEC_SETTINGS_SUCCESS:
      return update(state, {
        putCecSettings: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    case types.PUT_DEVICE_CEC_SETTINGS_ERROR:
      return update(state, {
        putCecSettings: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_DEVICE_CEC_SETTINGS_INFO:
      return update(state, {
        putCecSettings: { $set: initialState.putCecSettings }
      })
    case types.GET_DEVICE_NETWORK_CONFIG:
      return update(state, {
        networkConfig: {
          isLoading: { $set: true }
        }
      })
    case types.GET_DEVICE_NETWORK_CONFIG_SUCCESS:
      return update(state, {
        networkConfig: {
          response: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.GET_DEVICE_NETWORK_CONFIG_ERROR:
      return update(state, {
        networkConfig: {
          error: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.PUT_DEVICE_NETWORK_CONFIG_SUCCESS:
      return update(state, {
        putNetworkConfig: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_DEVICE_NETWORK_CONFIG_ERROR:
      return update(state, {
        putNetworkConfig: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_DEVICE_NETWORK_CONFIG:
      return update(state, {
        putNetworkConfig: { $set: {} }
      })

    case types.GET_DEVICE_VIRTUAL_PLAYER_STATS:
      return update(state, {
        virtualPlayerStats: {
          response: { $set: [] },
          error: { $set: null },
          isLoading: { $set: true }
        }
      })
    case types.GET_DEVICE_VIRTUAL_PLAYER_STATS_SUCCESS:
      return update(state, {
        virtualPlayerStats: {
          response: { $set: action.payload },
          error: { $set: null },
          isLoading: { $set: false }
        }
      })
    case types.CLEAR_GET_DEVICE_VIRTUAL_PLAYER_STATS:
      return update(state, {
        virtualPlayerStats: {
          response: { $set: [] },
          error: { $set: null },
          isLoading: { $set: false }
        }
      })
    case types.GET_DEVICE_VIRTUAL_PLAYER_STATS_ERROR:
      return update(state, {
        virtualPlayerStats: {
          error: { $set: action.payload },
          isLoading: { $set: false }
        }
      })
    case types.POST_IP_CIDR: {
      return update(state, {
        postIpCIDR: {
          error: { $set: initialState.postIpCIDR.error },
          isLoading: { $set: true }
        }
      })
    }
    case types.POST_IP_CIDR_SUCCESS: {
      return update(state, {
        postIpCIDR: {
          isLoading: { $set: false },
          response: { $set: action.payload.response }
        }
      })
    }
    case types.POST_IP_CIDR_ERROR: {
      return update(state, {
        postIpCIDR: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    }
    case types.PUT_IP_CIDR: {
      return update(state, {
        putIpCIDR: {
          error: { $set: initialState.putIpCIDR.error },
          isLoading: { $set: true }
        }
      })
    }
    case types.PUT_IP_CIDR_SUCCESS: {
      return update(state, {
        putIpCIDR: {
          isLoading: { $set: false },
          response: { $set: action.payload.response }
        }
      })
    }
    case types.PUT_IP_CIDR_ERROR: {
      return update(state, {
        putIpCIDR: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    }
    case types.DELETE_IP_CIDR: {
      return update(state, {
        deleteIpCIDR: {
          error: { $set: initialState.deleteIpCIDR.error },
          isLoading: { $set: true }
        }
      })
    }
    case types.DELETE_IP_CIDR_SUCCESS: {
      return update(state, {
        deleteIpCIDR: {
          isLoading: { $set: false },
          response: { $set: action.payload.response }
        }
      })
    }
    case types.DELETE_IP_CIDR_ERROR: {
      return update(state, {
        deleteIpCIDR: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    }
    case types.CLEAR_IP_CIDR: {
      return update(state, {
        postIpCIDR: { $set: initialState.postIpCIDR },
        putIpCIDR: { $set: initialState.putIpCIDR },
        deleteIpCIDR: { $set: initialState.deleteIpCIDR }
      })
    }
    case types.GET_IP_CIDR: {
      return update(state, {
        ipCIDRItems: {
          error: { $set: initialState.ipCIDRItems.error },
          isLoading: { $set: true }
        }
      })
    }
    case types.GET_IP_CIDR_SUCCESS: {
      return update(state, {
        ipCIDRItems: {
          isLoading: { $set: false },
          response: { $set: action.payload.response },
          meta: { $set: action.payload.meta }
        }
      })
    }
    case types.GET_IP_CIDR_ERROR: {
      return update(state, {
        ipCIDRItems: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    }
    case types.GET_DEVICE_DEPENDENCY_PACKAGES_SUCCESS: {
      return update(state, {
        dependencyPackages: {
          response: { $set: action.payload.response }
        }
      })
    }
    case types.GET_DEVICE_DEPENDENCY_PACKAGES_ERROR: {
      return update(state, {
        dependencyPackages: {
          error: { $set: action.payload }
        }
      })
    }
    case types.GET_DEVICE_GLOBAL_CONFIG: {
      return update(state, {
        config: {
          error: { $set: initialState.config.error },
          isLoading: { $set: true }
        }
      })
    }
    case types.GET_DEVICE_GLOBAL_CONFIG_SUCCESS: {
      return update(state, {
        config: {
          isLoading: { $set: false },
          response: { $set: action.payload.response }
        }
      })
    }
    case types.GET_DEVICE_GLOBAL_CONFIG_ERROR: {
      return update(state, {
        config: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    }
    case types.PUT_DEVICE_GLOBAL_CONFIG_SUCCESS: {
      return update(state, {
        putConfig: {
          isLoading: { $set: false },
          response: { $set: action.payload }
        }
      })
    }
    case types.PUT_DEVICE_GLOBAL_CONFIG_ERROR: {
      return update(state, {
        putConfig: {
          isLoading: { $set: false },
          error: { $set: action.payload }
        }
      })
    }
    case types.CLEAR_DEVICE_GLOBAL_CONFIG: {
      return update(state, {
        config: { $set: initialState.config },
        putConfig: { $set: initialState.putConfig }
      })
    }
    case types.GET_PROOF_OF_PLAY_DEVICES:
      return update(state, {
        proofOfPlayLibrary: {
          response: { $set: [] },
          isFetching: { $set: true }
        }
      })
    case types.GET_PROOF_OF_PLAY_DEVICES_SUCCESS:
      return update(state, {
        proofOfPlayLibrary: {
          response: { $set: action.payload.response.data },
          meta: { $set: action.payload.response.meta },
          isFetching: { $set: false }
        }
      })
    case types.GET_PROOF_OF_PLAY_DEVICES_ERROR:
      return update(state, {
        proofOfPlayLibrary: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.POST_FEATURE_DEVICE_ASSOCIATE_SUCCESS:
      return update(state, {
        proofOfPlayLibrary: {
          post: { status: { $set: action.payload.status } }
        }
      })
    case types.POST_FEATURE_DEVICE_ASSOCIATE_ERROR:
      return update(state, {
        proofOfPlayLibrary: {
          post: { error: { $set: action.payload } }
        }
      })
    case types.CLEAR_PROOF_OF_PLAY_DEVICE_DATA: {
      return update(state, {
        proofOfPlayLibrary: { $set: initialState.proofOfPlayLibrary }
      })
    }

    default:
      return state
  }
}

export default deviceReducer
