import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

const styles = () => ({
  img: {
    width: 36,
    cursor: 'pointer'
  }
})

const deviceIcons = {
  1: require('common/icons/device-icon-xhibit.png'),
  5: require('common/icons/device-icon-android.png'),
  6: require('common/icons/device-icon-brightsign.png'),
  8: require('common/icons/device-icon-chromeos.png'),
  9: require('common/icons/device-icon-LG.png'),
  10: require('common/icons/device-icon-raspberrypi.png')
}

const DeviceTableIcon = ({
  classes,
  onClick = f => f,
  deviceType = {},
  className
}) => {
  const [error, setError] = useState(false)
  return (
    <img
      onClick={onClick}
      className={classNames(classes.img, className)}
      alt={deviceType.name || 'Device type'}
      src={deviceType.logo && !error ? deviceType.logo : deviceIcons[1]}
      onError={() => setError(true)}
    />
  )
}
export default withStyles(styles)(DeviceTableIcon)
