import * as types from './index'

export const updateFiltersByEntity = (entity, data) => ({
  type: types.UPDATE_FILTERS_BY_ENTITY,
  payload: {
    data,
    entity
  }
})

export const resetFiltersByEntity = entity => ({
  type: types.RESET_FILTERS_BY_ENTITY,
  payload: { entity }
})

export const setStoredOptions = (name, options) => ({
  type: types.SET_STORED_OPTIONS,
  payload: { [name]: options }
})

export const resetAllFilters = () => ({
  type: types.RESET_ALL_FILTERS
})
