import { TableRow, withStyles } from '@material-ui/core'

export default withStyles(theme => {
  const { palette, type } = theme
  return {
    root: {
      backgroundColor: palette[type].tableLibrary.body.row.background,
      height: 70,
      '&:hover': {
        '& > .TableLibraryActionsCellClass, & > .TableLibraryCheckboxCellClass': {
          backgroundColor: palette[type].tableLibrary.body.row.stickyHover
        }
      }
    },
    hover: {
      backgroundColor: palette[type].tableLibrary.body.row.hover
    },
    selected: {
      backgroundColor: palette[type].tableLibrary.body.row.hover,
      '& > .TableLibraryActionsCellClass, & > .TableLibraryCheckboxCellClass': {
        backgroundColor: palette[type].tableLibrary.body.row.stickySelect
      }
    }
  }
})(({ classes, rootClassName, ...props }) => (
  <TableRow
    {...props}
    classes={{
      ...classes,
      root: rootClassName || classes.root
    }}
  />
))
