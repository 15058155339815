import {
  JPEG,
  JPG,
  PNG,
  SVG,
  BMP,
  GIF,
  MP3,
  MP4,
  OGG,
  PDF,
  WAV,
  WEBM,
  CSV,
  XML,
  JSON,
  DOC,
  DOCX,
  PPT,
  PPTX,
  XLS,
  XLSX,
  ICS,
  ZIP,
  CSS,
  JS,
  TPL,
  HTML,
  SWF,
  LESS,
  SCSS,
  MAP,
  OTF,
  EOT,
  TTF,
  WOFF,
  WOFF2,
  TXT,
  ICO,
  MOV
} from './mimeTypes'

const createContentTypes = types => ({
  mime: types.map(type => type.mime),
  extensions: types.map(type => type.ext).sort()
})

export const workplacePosterTypes = createContentTypes([
  JPEG,
  JPG,
  PNG,
  GIF,
  MOV,
  MP4
])
export const profileImageTypes = createContentTypes([JPEG, JPG, PNG, SVG])
export const deviceLocationTypes = createContentTypes([JPEG, JPG, PNG])
export const generalUploadContentTypes = createContentTypes([
  BMP,
  GIF,
  JPG,
  JPEG,
  SVG,
  MOV,
  MP3,
  MP4,
  OGG,
  PDF,
  PNG,
  WAV,
  WEBM
])

export const analyticsContentTypes = createContentTypes([CSV, XML, JSON])
export const msOfficeContentTypes = createContentTypes([
  DOC,
  DOCX,
  PPT,
  PPTX,
  XLS,
  XLSX
])
export const tablesContentTypes = createContentTypes([CSV, XML, JSON])
export const eventsContentTypes = createContentTypes([CSV, XML, JSON, ICS])
export const profilesContentTypes = createContentTypes([CSV])
export const docketContentTypes = createContentTypes([CSV, XML, JSON])
export const designGalleryTypes = createContentTypes([JPG, JPEG, PNG, SVG, GIF])
export const canvaContentTypes = createContentTypes([PNG])
export const htmlContentTypes = createContentTypes([ZIP])
export const htmlInnerZipContentTypes = createContentTypes([
  CSS,
  JS,
  PNG,
  GIF,
  JPEG,
  JPG,
  TPL,
  HTML,
  SWF,
  LESS,
  JSON,
  SCSS,
  MAP,
  OTF,
  EOT,
  SVG,
  TTF,
  WOFF,
  WOFF2,
  TXT,
  ICO,
  MP4
])
export const donorWallsContentTypes = createContentTypes([CSV])
export const leaderBoardContentTypes = createContentTypes([CSV])
export const boxOfficeContentTypes = createContentTypes([CSV, XML, JSON])
export const queueManagementContentTypes = createContentTypes([CSV, XML, JSON])
export const progressMeterContentTypes = createContentTypes([CSV])
export const reviewsContentTypes = createContentTypes([CSV, XML])
export const digitalBuildingDirectoryContentTypes = createContentTypes([
  CSV,
  JSON,
  XML
])
export const directionalSignageContentTypes = createContentTypes([
  CSV,
  XML,
  JSON
])

export const uploadContentTypesForDesignGallery = createContentTypes([
  BMP,
  JPG,
  JPEG,
  SVG,
  PNG,
  GIF
])

export const uploadContentTypesForTemplateThumb = createContentTypes([
  JPG,
  JPEG,
  PNG
])
