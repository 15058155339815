export function templateAssociationsSelector({ template }) {
  return template.associations
}

export function templatePreviewSelector({ template }) {
  return template.preview
}

export function templateGalleryItemsSelector({
  template: { templateGallery }
}) {
  return templateGallery.items
}

export function templateGalleryItemSelector({ template: { templateGallery } }) {
  return templateGallery.item
}

export function postPutTemplateItemSelector({ template }) {
  return template.postPutTemplateItem
}
