import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { withStyles } from '@material-ui/core'

import { BlueButton, WhiteButton } from 'components/Buttons'
import { TextSizeTooltip } from 'utils'
const styles = () => ({
  root: {
    padding: '25px 0 20px 20px',
    display: 'flex',
    gridColumnGap: '12px'
  },
  action: {
    paddingTop: '9px',
    paddingBottom: '9px'
  }
})

const Actions = ({
  t,
  classes,
  onSave = () => {},
  onCancel = () => {},
  opaque,
  saveBtnText = 'Save Schedule',
  disabled = false
}) => (
  <div className={classes.root}>
    <WhiteButton
      className={classes.action}
      onClick={onCancel}
      iconClassName="fa-regular fa-circle-arrow-left"
      variant="danger"
    >
      <TextSizeTooltip title={t('Reset')} maxTitleWidth={12} />
    </WhiteButton>
    <BlueButton
      opaque={opaque}
      className={classes.action}
      onClick={() => onSave(true)}
      iconClassName="fa-regular fa-circle-plus"
      disabled={disabled}
    >
      <TextSizeTooltip title={t(saveBtnText)} maxTitleWidth={15} />
    </BlueButton>
  </div>
)

Actions.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withTranslation('translations')(withStyles(styles)(Actions))
