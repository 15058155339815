import React from 'react'
import PropTypes from 'prop-types'
import { Grid, LinearProgress, makeStyles } from '@material-ui/core'

import { Text } from 'components/Typography'

const useStyles = makeStyles(() => ({
  label: {
    width: 50,
    textAlign: 'center'
  },
  progress: {
    flexGrow: 1
  }
}))

const LinearProgressWithLabel = ({ progress }) => {
  const classes = useStyles()

  const value = Math.min(progress, 100)

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <LinearProgress
        value={value}
        variant="determinate"
        className={classes.progress}
      />
      <Text rootClassName={classes.label}>{`${value}%`}</Text>
    </Grid>
  )
}

LinearProgressWithLabel.propTypes = {
  progress: PropTypes.number
}

export default LinearProgressWithLabel
