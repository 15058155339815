import axios from 'axios'
import * as config from '../config'

import api from './api'

import { errorHandler } from '../utils'
const googleApi = 'https://www.googleapis.com/webfonts/v1/webfonts?key='

const fetchFonts = async () => {
  try {
    const response = await axios.get(`${googleApi}${config.GOOGLE_API_KEY}`)
    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

const saveFonts = addedFonts => {
  try {
    // TODO Implement save request
  } catch (error) {
    throw new Error(error)
  }
}

const getFonts = async params => {
  try {
    const response = await api({
      method: 'GET',
      url: '/font',
      params,
      useGroup: true
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteFont = async id => {
  try {
    const response = await api({
      method: 'DELETE',
      url: `/font/${id}`
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const deleteSelectedFonts = async ({ ids }) => {
  try {
    await api({
      method: 'DELETE',
      url: `/font/bulk`,
      data: {
        ids: ids.join(',')
      }
    })
  } catch (e) {
    throw errorHandler(e)
  }
}

const postFont = async data => {
  try {
    const response = await api({
      method: 'POST',
      url: '/font',
      data
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const fetchGoogleFonts = async () => {
  try {
    const response = await api({
      method: 'PUT',
      url: '/font/sync'
    })

    return response.data
  } catch (error) {
    throw errorHandler(error)
  }
}

const fontsService = {
  fetchFonts,
  saveFonts,
  deleteFont,
  deleteSelectedFonts,
  getFonts,
  postFont,
  fetchGoogleFonts
}

export default fontsService
