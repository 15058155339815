import { urlDecode } from './parseTxtForMedia'
import { _isArray, _isObject } from 'utils/lodash'
import { getFileType } from './generalUtils'
import * as mimeTypes from 'constants/mimeTypes'
import { uppyFilenameRegExp } from 'constants/regExp'
import { UPPY_RESTORE_STATE } from '../constants/api'
import moment from 'moment'
import loadImage from './loadImage'
import { uppyCoreVersion } from 'constants/uppyConstants'

export function transformFile({
  name,
  extension,
  type,
  size,
  uuid,
  uploadURL,
  duration,
  meta
}) {
  const res = {
    name: name.endsWith(`.${extension}`) ? name : `${name}.${extension}`,
    extension,
    type: getFileType(type, uploadURL),
    size,
    uuid
  }

  if (uploadURL) {
    res.file_name = urlDecode(uploadURL).split('/').pop()
  }

  if (uuid) {
    res.file_name = uuid
  }

  if (duration) {
    res.duration = duration
  }

  if (meta?.isPreview) {
    res.isPreview = true
  }

  if (!res.file_name) {
    res.file_name = name
  }

  return res
}

export function transformDesignGalleryFile({
  name,
  extension,
  type,
  size,
  uploadURL,
  meta,
  id
}) {
  const res = {
    name,
    extension,
    type: getFileType(type, uploadURL),
    size,
    id,
    uploadURL
  }

  if (uploadURL) {
    res.uploadURL = urlDecode(uploadURL).split('/').pop()
  }

  if (meta?.isPreview) {
    res.isPreview = true
  }

  return res
}

export const transformFileMap = (files = []) => files.map(transformFile)

export const checkFirstFileIsUpdate = files => {
  if (_isArray(files)) {
    return (
      files?.length && !(files?.[0]?.meta?.isPreview || files?.[0]?.isPreview)
    )
  }
  if (_isObject(files)) {
    return !(files?.meta?.isPreview || files?.isPreview)
  }

  return false
}

export const preparePayloadFileDataForUppy = (
  backendData,
  file,
  isTransform = false,
  withName = true,
  isObjectResponse = false
) => {
  const firstFile = file?.[0]

  if (!firstFile) return []

  if (firstFile.meta?.isPreview || firstFile?.isPreview) {
    const response = {
      media_id: backendData?.id,
      ...(withName ? { name: backendData.file?.[0]?.name } : {})
    }

    if (isObjectResponse) return response

    return [response]
  }

  return isTransform ? file.map(transformFile) : file
}

export const getMimeValidationError = (ext = 'file', t) => {
  const mimeType =
    Object.values(mimeTypes)
      .filter(
        value => typeof value === 'object' && value.hasOwnProperty('mime')
      )
      .find(type => type.ext.includes(ext.toLowerCase()))?.mime || ''

  const fileType = mimeType.split('/')[0]

  const errorDescription = t('file mime validation error description', {
    ext: ext.replace('.', '').toUpperCase()
  })

  switch (fileType) {
    case 'video':
      return `${t('file mime validation error')} ${errorDescription}`
    default:
      return `${t('media mime validation error', {
        type: 'file'
      })} ${errorDescription}`
  }
}

export const defaultDashboardValidator = () => false

//issue #3024
export const mimeTypeValidator = allowedMimeTypes => (fileSignatures, file) => {
  return (
    allowedMimeTypes.includes(file.type) &&
    fileSignatures.some(({ mime }) => allowedMimeTypes.includes(mime))
  )
}

export const sanitizeFileNameBeforeAdded = file => {
  const modifiedName = file.name
    ?.replace(uppyFilenameRegExp, '?')
    .replaceAll(' ', '_')
  return {
    ...file,
    name: modifiedName,
    ...(file.meta && {
      meta: {
        ...file.meta,
        name: modifiedName
      }
    })
  }
}

export const validateImageDimensions = async file => {
  try {
    const { width, height } = await loadImage(file.data)

    if (Math.max(width, height) > 10000) {
      return { error: true }
    } else if (
      Math.max(width, height) > 3840 ||
      Math.min(width, height) > 2160
    ) {
      return { warning: true }
    }
    return {}
  } catch (e) {
    return {}
  }
}

export const getUppyRestoreState = userId => {
  try {
    const state = JSON.parse(localStorage.getItem(UPPY_RESTORE_STATE)) || []
    const filteredState = state.filter(
      featureState =>
        featureState.userId === userId &&
        moment().diff(moment(featureState.createdAt), 'hours') < 24 &&
        featureState.uppy === uppyCoreVersion
    )

    if (state.length !== filteredState.length) {
      localStorage.setItem(UPPY_RESTORE_STATE, JSON.stringify(filteredState))
    }

    return filteredState
  } catch (e) {
    return []
  }
}

export const getUppyRestoreFeatureState = (key, userId) => {
  const state = getUppyRestoreState(userId)

  return {
    key,
    userId,
    createdAt: moment().toString(),
    uppy: uppyCoreVersion,
    files: [],
    ...(state.find(featureState => featureState.key === key) || {})
  }
}

export const isEditableImage = file => {
  const fileTypeSpecific = (file.type || '').split('/')[1]

  return /^(jpe?g|gif|png|bmp|webp)$/.test(fileTypeSpecific)
}
