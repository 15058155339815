import React, { forwardRef, useRef, useImperativeHandle, useMemo } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

const defaultSettings = {
  dots: false,
  lazyLoad: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 10000,
  nextArrow: <></>,
  prevArrow: <></>
}

const SliderWrapper = forwardRef(({ customSettings, children }, ref) => {
  const sliderRef = useRef()

  useImperativeHandle(ref, () => ({
    next: () => {
      sliderRef.current.slickNext()
    },
    prev: () => {
      sliderRef.current.slickPrev()
    }
  }))

  const settings = useMemo(
    () => ({
      ...defaultSettings,
      ...customSettings
    }),
    [customSettings]
  )

  return (
    <Slider ref={sliderRef} {...settings}>
      {children}
    </Slider>
  )
})

SliderWrapper.propTypes = {
  classes: PropTypes.object,
  customSettings: PropTypes.object
}

export default SliderWrapper
