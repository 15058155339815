import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useUserDetails from 'hooks/useUserDetails'
import { clearRecentlySearch, syncRecentlySearch } from 'slices/filtersSlice'
import { recentlySearchSelector } from 'selectors/filtersSelector'

export default function useRecentlySearchedOptions(entity, name) {
  const dispatch = useDispatch()
  const recentlySearch = useSelector(recentlySearchSelector)

  const recentlySearched = (recentlySearch[entity] || {})[name] || []
  const [userId, setUserId] = useState()
  const userDetails = useUserDetails()

  const setRecentlySearched = options => {
    dispatch(syncRecentlySearch({ entity, name, options }))
  }

  const addRecentlySearchedOption = option => {
    setRecentlySearched([
      option,
      ...recentlySearched.filter(search => search.value !== option.value)
    ])
  }

  const addRecentlySearchedOptions = options => {
    setRecentlySearched([
      ...options.filter(
        option =>
          !recentlySearched.some(
            prevOption => prevOption.value === option.value
          )
      ),
      ...recentlySearched
    ])
  }

  useEffect(() => {
    if (userId && userId !== userDetails.id) {
      dispatch(clearRecentlySearch())
      setUserId(userDetails.id)
    }
    // eslint-disable-next-line
  }, [userDetails])

  return {
    recentlySearched,
    addRecentlySearchedOption,
    addRecentlySearchedOptions
  }
}
