import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import AccessDeniedModal from 'components/Modal/AccessDeniedModal'

const PermissionRoute = ({
  component: Component,
  allowed = false,
  lazyLoad = false,
  ...rest
}) => {
  if (!allowed) {
    return <Route {...rest} render={() => <AccessDeniedModal />} />
  }

  if (lazyLoad) {
    return <Route {...rest} render={props => <Component {...props} />} />
  } else {
    return <Route {...rest} component={Component} />
  }
}

PermissionRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object
  ]),
  allowed: PropTypes.bool,
  lazyLoad: PropTypes.bool
}

export default PermissionRoute
