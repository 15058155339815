import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _isEmpty } from 'utils/lodash'
import { getConfigOrgRole } from 'actions/configActions'
import { orgRoleSelector } from 'selectors/configSelectors'

const useOrgRoleOptions = () => {
  const dispatch = useDispatch()

  const { response: roles } = useSelector(orgRoleSelector)

  useEffect(() => {
    if (_isEmpty(roles)) dispatch(getConfigOrgRole())
    // eslint-disable-next-line
  }, [])

  return useMemo(
    () =>
      roles.map(({ displayName: label, id: value, color, name }) => ({
        label,
        value,
        color,
        roleName: name
      })),
    [roles]
  )
}

export default useOrgRoleOptions
