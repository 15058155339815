import { useEffect, useMemo, useState } from 'react'

import { _throttle } from 'utils/lodash'

export default function useElementProximity(ref, proximity = 40, disabled) {
  const [angle, setAngle] = useState(90)
  const [inProximity, setInProximity] = useState(false)

  const handlePointerMove = useMemo(
    () =>
      _throttle(e => {
        if (!ref.current) {
          return
        }

        const cardBounds = ref.current.getBoundingClientRect()

        if (
          e?.x > cardBounds.left - proximity &&
          e?.x < cardBounds.left + cardBounds.width + proximity &&
          e?.y > cardBounds.top - proximity &&
          e?.y < cardBounds.top + cardBounds.height + proximity
        ) {
          setInProximity(true)
        } else {
          setInProximity(false)
          return
        }

        const cardCenter = {
          x: cardBounds.left + cardBounds.width * 0.5,
          y: cardBounds.top + cardBounds.height * 0.5
        }

        let currentAngle =
          (Math.atan2(e?.y - cardCenter.y, e?.x - cardCenter.x) * 180) / Math.PI
        currentAngle = currentAngle < 0 ? currentAngle + 360 : currentAngle

        setAngle(currentAngle + 90)
      }, 25),
    [ref, proximity]
  )

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('pointermove', handlePointerMove)
    }
    return () => {
      if (!disabled) {
        document.removeEventListener('pointermove', handlePointerMove)
      }
    }
  }, [handlePointerMove, disabled])

  return {
    angle,
    inProximity
  }
}
