import React, { useEffect, useState } from 'react'
import { IconButton, Link, Typography, withStyles } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _isEmpty from 'lodash/isEmpty'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

import AccountModal from './AccountModal'
import { FabBlueButton } from 'components/Buttons'
import { FormControlInput } from 'components/Form'
import useUrlSearchParams from 'hooks/useUrlSearchParams'
import { resetPasswordSelector, verifyEmailSelector } from 'selectors/new/auth'
import { verifyEmail } from 'actions/new/auth'
import { resetAction } from 'actions/authenticationActions'
import { passwordValidateSchema } from 'constants/validations'
import { requiredField } from 'constants/validationMessages'

function styles({ palette, type, colors }) {
  return {
    formWrap: {
      paddingTop: 202
    },
    form: {
      width: '569px',
      padding: '0 65px'
    },
    formTitle: {
      fontSize: '30px',
      fontWeight: 'bold',
      marginBottom: '88px',
      textAlign: 'center',
      color: palette[type].pages.singIn.color
    },
    formSuccessMessage: {
      fontSize: '20px',
      marginBottom: '15px',
      textAlign: 'center',
      color: palette[type].pages.singIn.color
    },
    backToText: {
      padding: '40px 0 170px',
      color: '#888996',
      textAlign: 'center'
    },
    backToLink: {
      color: '#0076b9'
    },
    inputWrap: {
      marginBottom: '16px'
    },
    error: {
      color: colors.error,
      marginBottom: '10px'
    },
    formControlInput: {
      height: '50px',
      fontSize: '18px'
    },
    formControlContainer: {
      marginBottom: '10px'
    },
    passwordVisibility: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      padding: '15px'
    },
    actionLink: {
      margin: '30px 0',
      width: '100%',
      height: 40,
      borderRadius: 4
    }
  }
}

const verifyStatuses = {
  pending: 'pending',
  completed: 'completed',
  passwordRequired: 'passwordRequired',
  failed: 'failed',
  passwordChanged: 'passwordChanged'
}

const EmailVerifiedWrapper = props => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
    >
      <EmailVerified {...props} />
    </GoogleReCaptchaProvider>
  )
}

function EmailVerified({ t, classes }) {
  const dispatch = useDispatch()
  const params = useUrlSearchParams()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [status, setStatus] = useState(verifyStatuses.pending)
  const { response, isFetching, error } = useSelector(verifyEmailSelector)
  const resetPassword = useSelector(resetPasswordSelector)

  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(
    false
  )
  const [resetError, setResetError] = useState('')

  const form = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object().shape({
      password: passwordValidateSchema,
      confirmPassword: Yup.string()
        .required(requiredField)
        .oneOf(
          [Yup.ref('password')],
          'New Password and Confirm Password does not match.'
        )
    }),
    onSubmit: async ({ password, confirmPassword }) => {
      if (!executeRecaptcha) {
        setResetError(t('Recaptcha is not yet available'))
        return
      }

      const { token, email } = response

      setResetError('')

      try {
        const recaptchaToken = await executeRecaptcha('resetPassword')

        const data = {
          password,
          passwordConfirmation: confirmPassword,
          email: email,
          token: token,
          system: false,
          recaptchaToken
        }

        dispatch(resetAction(data))
      } catch (e) {
        console.error(e)
      }
    }
  })

  useEffect(
    () => {
      form.validateForm()
    },
    //eslint-disable-next-line
    []
  )

  useEffect(() => {
    if (resetPassword.response) {
      setStatus(verifyStatuses.passwordChanged)
    } else if (resetPassword.error) {
      if (resetPassword.error.errors && resetPassword.error.errors.length) {
        setResetError(resetPassword.error.errors[0])
      } else if (resetPassword.error.message) {
        setResetError(resetPassword.error.message)
      }
    }
  }, [resetPassword])

  useEffect(() => {
    if (!_isEmpty(params)) {
      dispatch(verifyEmail(params))
    }
    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    if (isFetching) {
      setStatus(verifyStatuses.pending)
      return
    }
    if (error?.message) {
      setStatus(verifyStatuses.failed)
      return
    }

    if (response.status === 'successful') {
      setStatus(
        response.token
          ? verifyStatuses.passwordRequired
          : verifyStatuses.completed
      )
    }
  }, [isFetching, response, error])

  const messageByStatus = {
    [verifyStatuses.pending]: {
      title: t('Email Address in the verification process'),
      description: t('Please wait')
    },
    [verifyStatuses.failed]: {
      title: t('Email Address is not verified'),
      description: t('The validity of the confirmation link may have expired')
    },
    [verifyStatuses.completed]: {
      title: t('Email Address Verified'),
      description: t('Email Address Verified Success Message')
    },
    [verifyStatuses.passwordRequired]: {
      title: t('Email Address Verified'),
      description: `${t(
        'Your email address has been verified and you may now set your password below'
      )}:`
    },
    [verifyStatuses.passwordChanged]: {
      title: t('Password successfully set!'),
      description: ''
    }
  }

  return (
    <AccountModal formWrapClassName={classes.formWrap}>
      <div className={classes.form}>
        <header>
          <Typography className={classes.formTitle} gutterBottom>
            {messageByStatus[status].title}
          </Typography>
        </header>
        <Typography className={classes.formSuccessMessage} gutterBottom>
          {messageByStatus[status].description}
        </Typography>

        {verifyStatuses.passwordRequired === status && (
          <form onSubmit={form.handleSubmit}>
            <div className={classes.inputWrap}>
              {resetError && (
                <Typography className={classes.error}>{resetError}</Typography>
              )}
              <FormControlInput
                id="password"
                name="password"
                type={passwordVisibility ? 'text' : 'password'}
                value={form.values.password}
                handleChange={form.handleChange}
                error={form.errors.password}
                handleBlur={form.handleBlur}
                touched={form.touched.password}
                placeholder={t('New Password')}
                formControlInputClass={classes.formControlInput}
                formControlContainerClass={classes.formControlContainer}
                fullWidth
                icon={
                  <IconButton
                    className={classes.passwordVisibility}
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    {passwordVisibility ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                }
              />
              <FormControlInput
                id="confirmPassword"
                name="confirmPassword"
                type={confirmPasswordVisibility ? 'text' : 'password'}
                value={form.values.confirmPassword}
                placeholder={t('Confirm Password')}
                formControlInputClass={classes.formControlInput}
                formControlContainerClass={classes.formControlContainer}
                handleChange={form.handleChange}
                error={form.errors.confirmPassword}
                handleBlur={form.handleBlur}
                touched={form.touched.confirmPassword}
                fullWidth
                icon={
                  <IconButton
                    className={classes.passwordVisibility}
                    onClick={() =>
                      setConfirmPasswordVisibility(!confirmPasswordVisibility)
                    }
                  >
                    {confirmPasswordVisibility ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                }
              />
            </div>
            <FabBlueButton
              className={classes.actionLink}
              opaque={!form.isValid}
              variant="extended"
              type="submit"
            >
              {t('Submit')}
            </FabBlueButton>
          </form>
        )}

        {[
          verifyStatuses.completed,
          verifyStatuses.failed,
          verifyStatuses.passwordChanged
        ].includes(status) && (
          <Typography className={classes.backToText}>
            {t('Back to')}{' '}
            <Link
              to="/sign-in"
              component={RouterLink}
              className={classes.backToLink}
            >
              {t('Sign In')}
            </Link>
          </Typography>
        )}
      </div>
    </AccountModal>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(EmailVerifiedWrapper)
)
