import React from 'react'
import ContentLoader from 'react-content-loader'

const GoogleCalendarIframeLoader = props => {
  return (
    <ContentLoader
      speed={2}
      style={{
        width: '100%',
        height: '100%'
      }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="80" cy="60" r="28" />
      <rect x="120" y="36" rx="0" ry="0" width="150" height="50" />
      <rect x="296" y="36" rx="0" ry="0" width="678" height="50" />
      <rect x="296" y="106" rx="0" ry="0" width="678" height="50" />

      <rect x="50" y="204" rx="0" ry="0" width="900" height="4" />

      <rect x="100" y="229" rx="0" ry="0" width="200" height="40" />
      <rect x="700" y="229" rx="0" ry="0" width="200" height="40" />
      <rect x="100" y="288" rx="0" ry="0" width="200" height="220" />

      <circle cx="415" cy="322" r="24" />
      <circle cx="550" cy="322" r="24" />
      <circle cx="685" cy="322" r="24" />
      <circle cx="820" cy="322" r="24" />
      <circle cx="1055" cy="322" r="24" />

      <rect x="470" y="400" rx="0" ry="0" width="300" height="53" />

      <rect x="50" y="204" rx="0" ry="0" width="4" height="330" />
      <rect x="950" y="204" rx="0" ry="0" width="4" height="328" />
      <rect x="50" y="530" rx="0" ry="0" width="900" height="4" />

      <rect x="300" y="575" rx="0" ry="0" width="450" height="59" />
    </ContentLoader>
  )
}
export default GoogleCalendarIframeLoader
