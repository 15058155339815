import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  DialogActions,
  DialogContent,
  Grid,
  makeStyles
} from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { BlueButton, WhiteButton } from 'components/Buttons'

const useStyles = makeStyles(({ palette, type, spacing }) => ({
  root: {
    height: '100%'
  },
  content: {
    background: palette[type].dialog.background,
    padding: `0px ${spacing(4)}px 20px`,
    display: 'flex'
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px',
    height: '55px',
    padding: '0 20px',
    backgroundColor: palette[type].sideModal.action.background,
    borderTop: `1px solid ${palette[type].card.greyHeader.border}`,
    borderRadius: '0 0 6px 6px'
  },
  button: {
    '&:first-child': {
      marginRight: 10
    }
  }
}))

const DialogContentLayout = ({
  onClose,
  onSubmit,
  submitOpaque,
  submitDisabled,
  submitLoading,
  contentClassName,
  children
}) => {
  const classes = useStyles({})
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <DialogContent className={classNames(classes.content, contentClassName)}>
        {children}
      </DialogContent>
      <DialogActions className={classes.actionBar}>
        <Grid container justifyContent="flex-end">
          {onClose && (
            <WhiteButton
              className={classes.button}
              onClick={onClose}
              variant="danger"
              iconClassName="fa-regular fa-circle-xmark"
            >
              {t('Cancel')}
            </WhiteButton>
          )}

          <BlueButton
            opaque={submitOpaque}
            disabled={submitDisabled}
            className={classes.button}
            onClick={onSubmit}
            progress={submitLoading}
            iconClassName="fa-regular fa-circle-check"
          >
            {t('Save')}
          </BlueButton>
        </Grid>
      </DialogActions>
    </Grid>
  )
}

DialogContentLayout.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitOpaque: PropTypes.bool,
  submitDisabled: PropTypes.bool
}

export default DialogContentLayout
