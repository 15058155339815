import { createAction } from 'utils/storeUtils'

export const GET_OAUTH2_TOKENS = 'AUTH2_MODULE/GET_OAUTH2_TOKENS'
export const GET_OAUTH2_TOKENS_SUCCESS =
  'AUTH2_MODULE/GET_OAUTH2_TOKENS_SUCCESS'
export const GET_OAUTH2_TOKENS_ERROR = 'AUTH2_MODULE/GET_OAUTH2_TOKENS_ERROR'

export const GET_OAUTH2_TOKENS_BY_FEATURE =
  'AUTH2_MODULE/GET_OAUTH2_TOKENS_BY_FEATURE'
export const GET_OAUTH2_TOKENS_BY_FEATURE_SUCCESS =
  'AUTH2_MODULE/GET_OAUTH2_TOKENS_BY_FEATURE_SUCCESS'
export const GET_OAUTH2_TOKENS_BY_FEATURE_ERROR =
  'AUTH2_MODULE/GET_OAUTH2_TOKENS_BY_FEATURE_ERROR'

export const POST_OAUTH2_TOKEN = 'AUTH2_MODULE/POST_OAUTH2_TOKEN'
export const POST_OAUTH2_TOKEN_SUCCESS =
  'AUTH2_MODULE/POST_OAUTH2_TOKEN_SUCCESS'
export const POST_OAUTH2_TOKEN_ERROR = 'AUTH2_MODULE/POST_OAUTH2_TOKEN_ERROR'

export const DELETE_OAUTH2_TOKEN = 'AUTH2_MODULE/DELETE_OAUTH2_TOKEN'
export const DELETE_OAUTH2_TOKEN_SUCCESS =
  'AUTH2_MODULE/DELETE_OAUTH2_TOKEN_SUCCESS'
export const DELETE_OAUTH2_TOKEN_ERROR =
  'AUTH2_MODULE/DELETE_OAUTH2_TOKEN_ERROR'

export const CLEAR_OAUTH2_TOKEN_INFO = 'AUTH2_MODULE/CLEAR_OAUTH2_TOKEN_INFO'

export const getOauth2Tokens = params =>
  createAction(GET_OAUTH2_TOKENS, params, { params })

export const getOauth2TokensSuccess = (payload, meta) =>
  createAction(GET_OAUTH2_TOKENS_SUCCESS, payload, meta)

export const getOauth2TokensError = (payload, meta) =>
  createAction(GET_OAUTH2_TOKENS_ERROR, payload, meta)

export const getOauth2TokensByFeature = (oauth2Feature, params) =>
  createAction(
    GET_OAUTH2_TOKENS_BY_FEATURE,
    { oauth2Feature, params },
    { oauth2Feature, params }
  )

export const getOauth2TokensByFeatureSuccess = (payload, meta) =>
  createAction(GET_OAUTH2_TOKENS_BY_FEATURE_SUCCESS, payload, meta)

export const getOauth2TokensByFeatureError = (payload, meta) =>
  createAction(GET_OAUTH2_TOKENS_BY_FEATURE_ERROR, payload, meta)

export const postOauth2Token = ({ oauth2Feature, code, name, isPrivate }) =>
  createAction(
    POST_OAUTH2_TOKEN,
    { oauth2Feature, code, name, isPrivate },
    { oauth2Feature }
  )

export const postOauth2TokenSuccess = (payload, meta) =>
  createAction(POST_OAUTH2_TOKEN_SUCCESS, payload, meta)

export const postOauth2TokenError = (payload, meta) =>
  createAction(POST_OAUTH2_TOKEN_ERROR, payload, meta)

export const deleteOauth2Token = (tokenId, data) =>
  createAction(DELETE_OAUTH2_TOKEN, { tokenId, data })

export const deleteOauth2TokenSuccess = payload =>
  createAction(DELETE_OAUTH2_TOKEN_SUCCESS, payload)

export const deleteOauth2TokenError = payload =>
  createAction(DELETE_OAUTH2_TOKEN_ERROR, payload)

export const clearTokenInfo = () => createAction(CLEAR_OAUTH2_TOKEN_INFO)
