import update from 'immutability-helper'

import * as types from '../actions'

const initialSsoDetails = {
  response: {},
  error: {},
  isFetching: false
}

const initialState = {
  put: {},
  putSsoDetails: initialSsoDetails
}

const clientSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUT_CLIENT_SETTINGS_SUCCESS:
    case types.PUT_CLIENT_FEATURE_SETTINGS_SUCCESS:
      return update(state, {
        put: {
          response: { $set: action.payload }
        }
      })
    case types.PUT_CLIENT_SETTINGS_ERROR:
    case types.PUT_CLIENT_FEATURE_SETTINGS_ERROR:
      return update(state, {
        put: {
          error: { $set: action.payload }
        }
      })
    case types.CLEAR_PUT_CLIENT_SETTINGS_INFO:
      return update(state, {
        put: { $set: {} }
      })
    case types.PUT_SSO_DETAILS:
    case types.POST_SSO_DETAILS:
      return update(state, {
        putSsoDetails: {
          isFetching: { $set: true },
          error: { $set: {} }
        }
      })
    case types.PUT_SSO_DETAILS_SUCCESS:
    case types.POST_SSO_DETAILS_SUCCESS:
      return update(state, {
        putSsoDetails: {
          response: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.PUT_SSO_DETAILS_FAILURE:
    case types.POST_SSO_DETAILS_FAILURE:
      return update(state, {
        putSsoDetails: {
          error: { $set: action.payload },
          isFetching: { $set: false }
        }
      })
    case types.CLEAR_SSO_DETAILS:
      return update(state, {
        putSsoDetails: { $set: initialSsoDetails }
      })
    default:
      return state
  }
}

export default clientSettingsReducer
