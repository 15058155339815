const durationArray = [
  { value: 10, label: '00:00:10' },
  { value: 20, label: '00:00:20' },
  { value: 30, label: '00:00:30' },
  { value: 40, label: '00:00:40' },
  { value: 50, label: '00:00:50' },
  { value: 60, label: '00:01:00' },
  { value: 70, label: '00:01:10' },
  { value: 80, label: '00:01:20' },
  { value: 90, label: '00:01:30' },
  { value: 100, label: '00:01:40' },
  { value: 110, label: '00:01:50' },
  { value: 120, label: '00:02:00' },
  { value: 130, label: '00:02:10' },
  { value: 140, label: '00:02:20' },
  { value: 150, label: '00:02:30' },
  { value: 160, label: '00:02:40' },
  { value: 170, label: '00:02:50' },
  { value: 180, label: '00:03:00' },
  { value: 190, label: '00:03:10' },
  { value: 200, label: '00:03:20' },
  { value: 210, label: '00:03:30' },
  { value: 220, label: '00:03:40' },
  { value: 230, label: '00:03:50' },
  { value: 240, label: '00:04:00' },
  { value: 250, label: '00:04:10' },
  { value: 260, label: '00:04:20' },
  { value: 270, label: '00:04:30' },
  { value: 280, label: '00:04:40' },
  { value: 290, label: '00:04:50' },
  { value: 300, label: '00:05:00' },
  { value: 310, label: '00:05:10' },
  { value: 320, label: '00:05:20' },
  { value: 330, label: '00:05:30' },
  { value: 340, label: '00:05:40' },
  { value: 350, label: '00:05:50' },
  { value: 360, label: '00:06:00' },
  { value: 370, label: '00:06:10' },
  { value: 380, label: '00:06:20' },
  { value: 390, label: '00:06:30' },
  { value: 400, label: '00:06:40' },
  { value: 410, label: '00:06:50' },
  { value: 420, label: '00:07:00' },
  { value: 430, label: '00:07:10' },
  { value: 440, label: '00:07:20' },
  { value: 450, label: '00:07:30' },
  { value: 460, label: '00:07:40' },
  { value: 470, label: '00:07:50' },
  { value: 480, label: '00:08:00' },
  { value: 490, label: '00:08:10' },
  { value: 500, label: '00:08:20' },
  { value: 510, label: '00:08:30' },
  { value: 520, label: '00:08:40' },
  { value: 530, label: '00:08:50' },
  { value: 540, label: '00:09:00' },
  { value: 550, label: '00:09:10' },
  { value: 560, label: '00:09:20' },
  { value: 570, label: '00:09:30' },
  { value: 580, label: '00:09:40' },
  { value: 590, label: '00:09:50' },
  { value: 600, label: '00:10:00' },
  { value: 610, label: '00:10:10' },
  { value: 620, label: '00:10:20' },
  { value: 630, label: '00:10:30' },
  { value: 640, label: '00:10:40' },
  { value: 650, label: '00:10:50' },
  { value: 660, label: '00:11:00' },
  { value: 670, label: '00:11:10' },
  { value: 680, label: '00:11:20' },
  { value: 690, label: '00:11:30' },
  { value: 700, label: '00:11:40' },
  { value: 710, label: '00:11:50' },
  { value: 720, label: '00:12:00' },
  { value: 730, label: '00:12:10' },
  { value: 740, label: '00:12:20' },
  { value: 750, label: '00:12:30' },
  { value: 760, label: '00:12:40' },
  { value: 770, label: '00:12:50' },
  { value: 780, label: '00:13:00' },
  { value: 790, label: '00:13:10' },
  { value: 800, label: '00:13:20' },
  { value: 810, label: '00:13:30' },
  { value: 820, label: '00:13:40' },
  { value: 830, label: '00:13:50' },
  { value: 840, label: '00:14:00' },
  { value: 850, label: '00:14:10' },
  { value: 860, label: '00:14:20' },
  { value: 870, label: '00:14:30' },
  { value: 880, label: '00:14:40' },
  { value: 890, label: '00:14:50' },
  { value: 900, label: '00:15:00' },
  { value: 910, label: '00:15:10' },
  { value: 920, label: '00:15:20' },
  { value: 930, label: '00:15:30' },
  { value: 940, label: '00:15:40' },
  { value: 950, label: '00:15:50' },
  { value: 960, label: '00:16:00' },
  { value: 970, label: '00:16:10' },
  { value: 980, label: '00:16:20' },
  { value: 990, label: '00:16:30' },
  { value: 1000, label: '00:16:40' },
  { value: 1010, label: '00:16:50' },
  { value: 1020, label: '00:17:00' },
  { value: 1030, label: '00:17:10' },
  { value: 1040, label: '00:17:20' },
  { value: 1050, label: '00:17:30' },
  { value: 1060, label: '00:17:40' },
  { value: 1070, label: '00:17:50' },
  { value: 1080, label: '00:18:00' },
  { value: 1090, label: '00:18:10' },
  { value: 1100, label: '00:18:20' },
  { value: 1110, label: '00:18:30' },
  { value: 1120, label: '00:18:40' },
  { value: 1130, label: '00:18:50' },
  { value: 1140, label: '00:19:00' },
  { value: 1150, label: '00:19:10' },
  { value: 1160, label: '00:19:20' },
  { value: 1170, label: '00:19:30' },
  { value: 1180, label: '00:19:40' },
  { value: 1190, label: '00:19:50' },
  { value: 1200, label: '00:20:00' },
  { value: 1210, label: '00:20:10' },
  { value: 1220, label: '00:20:20' },
  { value: 1230, label: '00:20:30' },
  { value: 1240, label: '00:20:40' },
  { value: 1250, label: '00:20:50' },
  { value: 1260, label: '00:21:00' },
  { value: 1270, label: '00:21:10' },
  { value: 1280, label: '00:21:20' },
  { value: 1290, label: '00:21:30' },
  { value: 1300, label: '00:21:40' },
  { value: 1310, label: '00:21:50' },
  { value: 1320, label: '00:22:00' },
  { value: 1330, label: '00:22:10' },
  { value: 1340, label: '00:22:20' },
  { value: 1350, label: '00:22:30' },
  { value: 1360, label: '00:22:40' },
  { value: 1370, label: '00:22:50' },
  { value: 1380, label: '00:23:00' },
  { value: 1390, label: '00:23:10' },
  { value: 1400, label: '00:23:20' },
  { value: 1410, label: '00:23:30' },
  { value: 1420, label: '00:23:40' },
  { value: 1430, label: '00:23:50' },
  { value: 1440, label: '00:24:00' },
  { value: 1450, label: '00:24:10' },
  { value: 1460, label: '00:24:20' },
  { value: 1470, label: '00:24:30' },
  { value: 1480, label: '00:24:40' },
  { value: 1490, label: '00:24:50' },
  { value: 1500, label: '00:25:00' },
  { value: 1510, label: '00:25:10' },
  { value: 1520, label: '00:25:20' },
  { value: 1530, label: '00:25:30' },
  { value: 1540, label: '00:25:40' },
  { value: 1550, label: '00:25:50' },
  { value: 1560, label: '00:26:00' },
  { value: 1570, label: '00:26:10' },
  { value: 1580, label: '00:26:20' },
  { value: 1590, label: '00:26:30' },
  { value: 1600, label: '00:26:40' },
  { value: 1610, label: '00:26:50' },
  { value: 1620, label: '00:27:00' },
  { value: 1630, label: '00:27:10' },
  { value: 1640, label: '00:27:20' },
  { value: 1650, label: '00:27:30' },
  { value: 1660, label: '00:27:40' },
  { value: 1670, label: '00:27:50' },
  { value: 1680, label: '00:28:00' },
  { value: 1690, label: '00:28:10' },
  { value: 1700, label: '00:28:20' },
  { value: 1710, label: '00:28:30' },
  { value: 1720, label: '00:28:40' },
  { value: 1730, label: '00:28:50' },
  { value: 1740, label: '00:29:00' },
  { value: 1750, label: '00:29:10' },
  { value: 1760, label: '00:29:20' },
  { value: 1770, label: '00:29:30' },
  { value: 1780, label: '00:29:40' },
  { value: 1790, label: '00:29:50' },
  { value: 1800, label: '00:30:00' },
  { value: 1810, label: '00:30:10' },
  { value: 1820, label: '00:30:20' },
  { value: 1830, label: '00:30:30' },
  { value: 1840, label: '00:30:40' },
  { value: 1850, label: '00:30:50' },
  { value: 1860, label: '00:31:00' },
  { value: 1870, label: '00:31:10' },
  { value: 1880, label: '00:31:20' },
  { value: 1890, label: '00:31:30' },
  { value: 1900, label: '00:31:40' },
  { value: 1910, label: '00:31:50' },
  { value: 1920, label: '00:32:00' },
  { value: 1930, label: '00:32:10' },
  { value: 1940, label: '00:32:20' },
  { value: 1950, label: '00:32:30' },
  { value: 1960, label: '00:32:40' },
  { value: 1970, label: '00:32:50' },
  { value: 1980, label: '00:33:00' },
  { value: 1990, label: '00:33:10' },
  { value: 2000, label: '00:33:20' },
  { value: 2010, label: '00:33:30' },
  { value: 2020, label: '00:33:40' },
  { value: 2030, label: '00:33:50' },
  { value: 2040, label: '00:34:00' },
  { value: 2050, label: '00:34:10' },
  { value: 2060, label: '00:34:20' },
  { value: 2070, label: '00:34:30' },
  { value: 2080, label: '00:34:40' },
  { value: 2090, label: '00:34:50' },
  { value: 2100, label: '00:35:00' },
  { value: 2110, label: '00:35:10' },
  { value: 2120, label: '00:35:20' },
  { value: 2130, label: '00:35:30' },
  { value: 2140, label: '00:35:40' },
  { value: 2150, label: '00:35:50' },
  { value: 2160, label: '00:36:00' },
  { value: 2170, label: '00:36:10' },
  { value: 2180, label: '00:36:20' },
  { value: 2190, label: '00:36:30' },
  { value: 2200, label: '00:36:40' },
  { value: 2210, label: '00:36:50' },
  { value: 2220, label: '00:37:00' },
  { value: 2230, label: '00:37:10' },
  { value: 2240, label: '00:37:20' },
  { value: 2250, label: '00:37:30' },
  { value: 2260, label: '00:37:40' },
  { value: 2270, label: '00:37:50' },
  { value: 2280, label: '00:38:00' },
  { value: 2290, label: '00:38:10' },
  { value: 2300, label: '00:38:20' },
  { value: 2310, label: '00:38:30' },
  { value: 2320, label: '00:38:40' },
  { value: 2330, label: '00:38:50' },
  { value: 2340, label: '00:39:00' },
  { value: 2350, label: '00:39:10' },
  { value: 2360, label: '00:39:20' },
  { value: 2370, label: '00:39:30' },
  { value: 2380, label: '00:39:40' },
  { value: 2390, label: '00:39:50' },
  { value: 2400, label: '00:40:00' },
  { value: 2410, label: '00:40:10' },
  { value: 2420, label: '00:40:20' },
  { value: 2430, label: '00:40:30' },
  { value: 2440, label: '00:40:40' },
  { value: 2450, label: '00:40:50' },
  { value: 2460, label: '00:41:00' },
  { value: 2470, label: '00:41:10' },
  { value: 2480, label: '00:41:20' },
  { value: 2490, label: '00:41:30' },
  { value: 2500, label: '00:41:40' },
  { value: 2510, label: '00:41:50' },
  { value: 2520, label: '00:42:00' },
  { value: 2530, label: '00:42:10' },
  { value: 2540, label: '00:42:20' },
  { value: 2550, label: '00:42:30' },
  { value: 2560, label: '00:42:40' },
  { value: 2570, label: '00:42:50' },
  { value: 2580, label: '00:43:00' },
  { value: 2590, label: '00:43:10' },
  { value: 2600, label: '00:43:20' },
  { value: 2610, label: '00:43:30' },
  { value: 2620, label: '00:43:40' },
  { value: 2630, label: '00:43:50' },
  { value: 2640, label: '00:44:00' },
  { value: 2650, label: '00:44:10' },
  { value: 2660, label: '00:44:20' },
  { value: 2670, label: '00:44:30' },
  { value: 2680, label: '00:44:40' },
  { value: 2690, label: '00:44:50' },
  { value: 2700, label: '00:45:00' },
  { value: 2710, label: '00:45:10' },
  { value: 2720, label: '00:45:20' },
  { value: 2730, label: '00:45:30' },
  { value: 2740, label: '00:45:40' },
  { value: 2750, label: '00:45:50' },
  { value: 2760, label: '00:46:00' },
  { value: 2770, label: '00:46:10' },
  { value: 2780, label: '00:46:20' },
  { value: 2790, label: '00:46:30' },
  { value: 2800, label: '00:46:40' },
  { value: 2810, label: '00:46:50' },
  { value: 2820, label: '00:47:00' },
  { value: 2830, label: '00:47:10' },
  { value: 2840, label: '00:47:20' },
  { value: 2850, label: '00:47:30' },
  { value: 2860, label: '00:47:40' },
  { value: 2870, label: '00:47:50' },
  { value: 2880, label: '00:48:00' },
  { value: 2890, label: '00:48:10' },
  { value: 2900, label: '00:48:20' },
  { value: 2910, label: '00:48:30' },
  { value: 2920, label: '00:48:40' },
  { value: 2930, label: '00:48:50' },
  { value: 2940, label: '00:49:00' },
  { value: 2950, label: '00:49:10' },
  { value: 2960, label: '00:49:20' },
  { value: 2970, label: '00:49:30' },
  { value: 2980, label: '00:49:40' },
  { value: 2990, label: '00:49:50' },
  { value: 3000, label: '00:50:00' },
  { value: 3010, label: '00:50:10' },
  { value: 3020, label: '00:50:20' },
  { value: 3030, label: '00:50:30' },
  { value: 3040, label: '00:50:40' },
  { value: 3050, label: '00:50:50' },
  { value: 3060, label: '00:51:00' },
  { value: 3070, label: '00:51:10' },
  { value: 3080, label: '00:51:20' },
  { value: 3090, label: '00:51:30' },
  { value: 3100, label: '00:51:40' },
  { value: 3110, label: '00:51:50' },
  { value: 3120, label: '00:52:00' },
  { value: 3130, label: '00:52:10' },
  { value: 3140, label: '00:52:20' },
  { value: 3150, label: '00:52:30' },
  { value: 3160, label: '00:52:40' },
  { value: 3170, label: '00:52:50' },
  { value: 3180, label: '00:53:00' },
  { value: 3190, label: '00:53:10' },
  { value: 3200, label: '00:53:20' },
  { value: 3210, label: '00:53:30' },
  { value: 3220, label: '00:53:40' },
  { value: 3230, label: '00:53:50' },
  { value: 3240, label: '00:54:00' },
  { value: 3250, label: '00:54:10' },
  { value: 3260, label: '00:54:20' },
  { value: 3270, label: '00:54:30' },
  { value: 3280, label: '00:54:40' },
  { value: 3290, label: '00:54:50' },
  { value: 3300, label: '00:55:00' },
  { value: 3310, label: '00:55:10' },
  { value: 3320, label: '00:55:20' },
  { value: 3330, label: '00:55:30' },
  { value: 3340, label: '00:55:40' },
  { value: 3350, label: '00:55:50' },
  { value: 3360, label: '00:56:00' },
  { value: 3370, label: '00:56:10' },
  { value: 3380, label: '00:56:20' },
  { value: 3390, label: '00:56:30' },
  { value: 3400, label: '00:56:40' },
  { value: 3410, label: '00:56:50' },
  { value: 3420, label: '00:57:00' },
  { value: 3430, label: '00:57:10' },
  { value: 3440, label: '00:57:20' },
  { value: 3450, label: '00:57:30' },
  { value: 3460, label: '00:57:40' },
  { value: 3470, label: '00:57:50' },
  { value: 3480, label: '00:58:00' },
  { value: 3490, label: '00:58:10' },
  { value: 3500, label: '00:58:20' },
  { value: 3510, label: '00:58:30' },
  { value: 3520, label: '00:58:40' },
  { value: 3530, label: '00:58:50' },
  { value: 3540, label: '00:59:00' },
  { value: 3550, label: '00:59:10' },
  { value: 3560, label: '00:59:20' },
  { value: 3570, label: '00:59:30' },
  { value: 3580, label: '00:59:40' },
  { value: 3590, label: '00:59:50' },
  { value: 3600, label: '01:00:00' }
]

export default durationArray
