import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { AddOutlined as AddOutlinedIcon } from '@material-ui/icons'

import AlarmAlert from './AlarmAlert'
import Scrollbars from 'components/Scrollbars'
import BaseSection from '../BaseSection'
import { CircleIconButton } from 'components/Buttons'
import { useSelector } from 'react-redux'
import handleBottomScroll from 'utils/handleBottomScroll'
import ListLoader from './ListLoader'
import EmptyPlaceholder from 'components/EmptyPlaceholder'

const styles = () => ({
  rootWithScrollbar: {
    paddingRight: '0px'
  },
  headerWithScrollbar: {
    marginRight: 10
  },
  alertItemRoot: {
    marginRight: 10
  },
  container: {
    height: 'calc(100% - 78px)',
    overflow: 'hidden'
  },
  emptyPlaceholderRoot: {
    height: '100%'
  }
})

const AlarmAlertSection = ({
  fetcher,
  permissions,
  selectedId,
  onAdd = f => f,
  onEdit = f => f,
  onDelete = f => f,
  classes,
  t
}) => {
  const [alertAlarms, setAlertAlarms] = useState([])
  const { response: items, meta } = useSelector(
    ({
      deviceNoc: {
        alarm: { items }
      }
    }) => items
  )

  useEffect(() => {
    if (items && !meta.isLoading) {
      setAlertAlarms(prevState => [
        ...(meta.currentPage === 1 ? [] : prevState),
        ...items
      ])
    }
  }, [items, meta])

  const renderAddAlertItemIcon = useMemo(
    () => (
      <CircleIconButton
        className={`hvr-grow ${classes.circleButton}`}
        onClick={onAdd}
      >
        <AddOutlinedIcon />
      </CircleIconButton>
    ),
    [classes.circleButton, onAdd]
  )

  const handleEditClick = useCallback(
    id => () => {
      onEdit(id)
    },
    [onEdit]
  )

  const handleDeleteClick = useCallback(
    (id, name) => () => {
      onDelete(id, name)
    },
    [onDelete]
  )

  const handleFetchMore = useCallback(() => {
    if (meta.lastPage > meta.currentPage) {
      fetcher({
        page: meta.currentPage + 1
      })
    }
  }, [fetcher, meta])

  return (
    <BaseSection
      rootClassName={classes.rootWithScrollbar}
      title={t('Alerts')}
      headerClasses={[classes.headerWithScrollbar]}
      iconButtonComponent={permissions.create && renderAddAlertItemIcon}
      icon
    >
      <div className={classes.container}>
        {meta.isLoading && <ListLoader />}
        {!meta.isLoading && !alertAlarms.length ? (
          <EmptyPlaceholder
            text={t('No Data')}
            rootClassName={classes.emptyPlaceholderRoot}
          />
        ) : (
          <Scrollbars
            className="alarm-popup-boundary"
            onUpdate={handleBottomScroll(handleFetchMore)}
          >
            <div className={classes.alertItemRoot}>
              {alertAlarms.map(item => (
                <AlarmAlert
                  key={`alert-alarm-list-${item.id}`}
                  item={item}
                  handleEditClick={handleEditClick(item.id)}
                  handleDeleteClick={handleDeleteClick(item.id, item.name)}
                  permissions={permissions}
                  active={selectedId === item.id}
                />
              ))}
            </div>
          </Scrollbars>
        )}
      </div>
    </BaseSection>
  )
}
export default withTranslation('translations')(
  withStyles(styles)(AlarmAlertSection)
)
