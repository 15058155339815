import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { getCRUDEndpoints } from '../utils/apiUtils'

export const tablesApi = createAppApi('tableQuery', {
  tagTypes: [apiTags.table],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      name: 'Table',
      url: '/report/table',
      builder
    })
  })
})

export const { useLazyGetTableQuery, useLazyGetTablesQuery } = tablesApi
