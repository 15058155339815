import React, { memo } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import { withStyles } from '@material-ui/styles'
import { Close as CloseIcon } from '@material-ui/icons'

import activeRouter from 'common/assets/icons/router-node-active.svg'
import MapPopup from './components/MapPopup'
import { truncateWithEllipsis } from 'utils/truncateStringUtils'
import { Text, TextWithTooltip } from 'components/Typography'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    whiteSpace: 'unset',
    fontSize: 11,
    lineHeight: '14px',
    margin: '3px 0px',
    maxWidth: 50
  },
  imgRoot: {
    position: 'relative'
  },
  activeStatus: {
    width: 7,
    height: 7,
    borderRadius: 7,
    background: 'green',
    position: 'absolute',
    top: -10,
    right: -10
  },
  inactiveIcon: {
    position: 'absolute',
    top: -10,
    right: -10,
    width: 20,
    height: 20,
    color: 'red'
  }
})

const DeviceNodeType = ({ data, classes }) => {
  return (
    <MapPopup title={data.label} ip={data.ip} visible={data.showMapPopup}>
      <div className={classes.root}>
        <div className={classes.imgRoot}>
          {data.isActive ? (
            <div className={classes.activeStatus}></div>
          ) : (
            <CloseIcon className={classes.inactiveIcon}></CloseIcon>
          )}
          <img src={activeRouter} alt="router" width="25" height="17" />
        </div>
        {data.showMapPopup ? (
          <Text rootClassName={classes.text} variant="smaller">
            {truncateWithEllipsis(data.label, 15)}
          </Text>
        ) : (
          <TextWithTooltip
            rootClassName={classes.text}
            variant="smaller"
            maxWidth={50}
          >
            {data.label}
          </TextWithTooltip>
        )}
        {data.isActive && <Handle type="source" position={Position.Right} />}
        <Handle type="target" position={Position.Left} />
      </div>
    </MapPopup>
  )
}

export default withStyles(styles)(memo(DeviceNodeType))
