export const createTemplateItemTypes = {
  MEDIA_ITEM: 'mediaItem',
  PLAYLIST_ITEM: 'playlistItem'
}

export const playlistGroupsItemTypes = {
  PLAYLIST_ITEM: 'PLAYLIST_ITEM'
}

export const mediaGroupsItemTypes = {
  MEDIA_ITEM: 'MEDIA_ITEM'
}

export const deviceGroupsItemTypes = {
  DEVICE_ITEM: 'DEVICE_ITEM'
}

export const templateGroupsItemTypes = {
  TEMPLATE_ITEM: 'TEMPLATE_ITEM'
}

export const scheduleGroupsItemTypes = {
  SCHEDULE_ITEM: 'SCHEDULE_ITEM'
}

export const schedulePublishItemTypes = {
  MEDIA_ITEM: 'schedulePublishMediaItem',
  PLAYLIST_ITEM: 'schedulePublishPlaylistItem'
}

export const dashboardItemTypes = {
  SMALL_ITEM: 'DASHBOARD_SMALL_ITEM',
  BIG_ITEM: 'DASHBOARD_BIG_ITEM'
}

export const usersItemTypes = {
  USER_ITEM: 'USER_ITEM'
}

export const clientItemTypes = {
  CLIENT_ITEM: 'CLIENT_ITEM'
}

export const designGalleryTypes = {
  DESIGN_TAB: 'DESIGN_TAB'
}

export const workPlacePosterItemTypes = {
  WORKPLACE_POSTER_ITEM: 'WORKPLACE_POSTER_ITEM'
}

export const progressMeterItemTypes = {
  PROGRESS_METER_ITEM: 'PROGRESS_METER_ITEM'
}

export const currencyItemTypes = {
  CURRENCY_ITEM: 'CURRENCY_ITEM'
}

export const deviceLibraryTypes = {
  DEVICE_CARD: 'DEVICE_CARD',
  LOCATION_ITEM: 'LOCATION_ITEM'
}

export const menuIntegrationItemTypes = {
  FIELD_MAPPING: 'fieldItem'
}

export const menuMakerTypes = {
  BOARD_TAB: 'BOARD_TAB',
  PRESET_TAB: 'PRESET_TAB',
  COMPONENT_TAB: 'COMPONENT_TAB'
}

export const reviewsItemTypes = {
  REVIEW_ITEM: 'REVIEW_ITEM'
}

export const digitalBuildingTypes = {
  DIGITAL_BUILDING_ITEM: 'DIGITAL_BUILDING_ITEM'
}

export const progressMeterTypes = {
  PROGRESS_METER_ITEM: 'PROGRESS_METER_ITEM'
}

export const directionalSignageTypes = {
  DIRECTIONAL_SIGNAGE_ITEM: 'DIRECTIONAL_SIGNAGE_ITEM'
}

export const profileTypes = {
  PROFILE_ITEM: 'PROFILE_ITEM'
}

export const leaderboardTypes = {
  LEADERBOARD_ITEM: 'LEADERBOARD_ITEM'
}

export const donorWallsTypes = {
  DONOR_WALLS_ITEM: 'DONOR_WALLS_ITEM'
}
